<div mat-dialog-title class="popup_border_icd">
    <h5 class="header_txt">Product details</h5>
    <div mat-dialog-actions>
      <img src="../../../assets/img/close_icon.png" width="20px" height="20px" id="close_icd"  (click)="closePopup()"/>
    </div>
  </div>
  <div mat-dialog-content class="diag_content">
    <div class="container">
      <div class="row">
        <table>
            <thead>
                <tr class="background-color_b" style="height: 35px;">
                  <th style="font-weight: 400;text-align: center;">S.no</th>
                  <th style="width:20%;font-weight: 400;text-align: center;">Medicine</th>
                  <th style="width:20%;font-weight: 400;text-align: center;">Supplier</th>
                  <!-- <th style="font-weight: 400;width: 16%;text-align: center;">
                    Batch</th> -->
                  <!-- <th style="font-weight: 400;width: 10%;text-align: center;">
                    Rack</th> -->
                    <!-- <th style="font-weight: 400;width:10%;text-align: center;">Bin</th> -->
                  <th style="font-weight: 400;text-align: center;">Expiry date</th>
                  <th style="font-weight: 400;text-align: center;">Min</th>
                    <th style="font-weight: 400;text-align: center;">Max</th>
                    <th style="font-weight: 400;text-align: center;">Danger</th>
                    <th style="font-weight: 400;text-align: center;">Avbl qty</th>
                </tr>
              </thead>
    
              <tbody>
                <tr *ngFor="let stock of productdetails; let i = index" style="height: 30px;">
                  <td>{{stock.sn}}</td>
                  <td
                    style="font-size: 12px; text-align: left;padding: 5px;">
                    {{stock.short_name}} - {{stock.name}}
                  </td>
                  <td style="font-size: 12px;text-align: left;padding: 5px;">
                    {{stock.supplier}}
                  </td>
                  <!-- <td style="font-size: 12px;text-align: center;">
                    {{stock.batch_no}}
                  </td> -->
                    <!-- <td style="text-align: center;">
                      {{stock.rack}}
                      </td> -->
                  <!-- <td style="text-align: center;">
                    {{stock.bin}}
                    
                    </td> -->
                  <td style="text-align: center;">
                    {{stock.exp_date}}
                    </td>

                    <td style="text-align: center;">
                    {{stock.min_level}}
                  </td>

                    <td style="text-align: center;">
                    {{stock.max_level}}
                  </td>
                    <td style="text-align: center;">
                    {{stock.danger_level}}
                  </td>
                    <td style="text-align: center;">
                    {{stock.qty_onhand}}
                  </td>

                </tr>   
            </table>
    </div>
    </div>
  </div>
  <div mat-dialog-actions align="end" style="width: auto;right: 19px;">
  </div>