import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { FrontDesk_Helper } from '../FrontDesk_Helper';
import { MatDialog } from '@angular/material/dialog';
import { ipaddress } from '../../ipaddress';
import { Helper_Class } from '../../..//app/helper_class';
import { MenuViewService } from '../../../app/common-module/menu-view/menu-view.service';
import { ConvertTimeformat, Date_Formate, Time_Formate } from '../../../assets/js/common';
import { Message_data } from 'src/assets/js/Message_data';
import { error } from 'console';

@Component({
  selector: 'app-other-expenses',
  templateUrl: './other-expenses.component.html',
  styleUrls: ['./other-expenses.component.scss']
})

export class OtherExpensesComponent implements OnInit {
  @ViewChild('printbanner') printbanner: ElementRef;
  @ViewChild('printnoheader') printnoheader: ElementRef;
  dtOptions: DataTables.Settings = {};
  public printbutton: boolean = false;
  public savebutton: boolean = false;
  public editbutton: boolean = false;
  public deletebutton: boolean = false;
  public exp_name: string;
  public exp_date;
  public currentDate;
  public expense_time1: string;
  public expense_time2: string;
  public expense_time3: string;
  public expense_hrs = [];
  public expense_min = [];
  public exp_amount;
  public exp_desc;
  public currentTime: any;
  public timevalue: string;
  public noExpFlag: boolean = false;
  public expList: any[];
  public expense_time: any;
  public userInfo: any;
  public hptl_clinic_id: any;
  public logUserName: any;
  public country: any;
  public accounts_flag: boolean = false;
  public approved_flag: boolean = false;
  public exp_id: any;
  public hospital_logo;
  public printlogostyle: any;
  public print_template: any;
  public paymentArray: any = [];
  public select_all: boolean = false;

  constructor(public http: HttpClient,public toastr: ToastrService,public service: MenuViewService,public dialog:MatDialog,public adminservice:MenuViewService) {
    for (var i = 1; i <= 12; i++) {
      if (i < 10) 
        this.expense_hrs.push('0' + i);
      else 
        this.expense_hrs.push(i);
    }
    for (var i = 0; i <= 59; i += 1) {
      if (i < 10) 
        this.expense_min.push('0' + i);
      else 
        this.expense_min.push(i);
    }
  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [3] },
      ],
      "language": {
        "search": "<img src='../../../assets/ui_icons/Bill_list/Filter_Icon.svg' width='18px'/>",
        searchPlaceholder: "Filter by expense name or date"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"l><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    this.userInfo = Helper_Class.getInfo();
    console.log(this.userInfo);
    this.hptl_clinic_id = this.userInfo.hospitals[0].hptl_clinic_id;
    if (this.userInfo.first_name != undefined) {
      this.logUserName = this.userInfo.first_name;
      if (this.userInfo.last_name != undefined) 
        this.logUserName = this.userInfo.first_name+" "+this.userInfo.last_name;
    }
    // if (this.userInfo.user_type == 'Accounts') 
    //   this.accounts_flag = true;
    // else 
    //   this.accounts_flag = false;
    if (this.userInfo.user_type == 'Admin')
      this.approved_flag = true;
    else
      this.approved_flag = false;
    this.country = this.userInfo.country_desc;
    this.hospital_logo = ipaddress.Ip_with_img_address + this.userInfo.bill_print_logo;
    this.printlogostyle = this.userInfo.bill_print_logo_style;
    this.print_template = this.userInfo.bill_print_template;
    this.printbutton = true;
    this.savebutton = true;
    this.editbutton = true;
    this.deletebutton = true;
    this.getCurrentDate(); 
    this.getPaymentType();  
    this.getExpenses();
  }

  getCurrentDate() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
          { country: ipaddress.country_code },
          { headers: headers })
          .subscribe(
            response => {
            var obj = JSON.parse(JSON.stringify(response));
            console.log(obj);
            if (obj.current_date != null) {
              this.currentDate = obj.current_date;
              this.currentTime = obj.current_time;
              this.exp_date = obj.current_date;
              this.timevalue = Time_Formate(this.currentTime);
              console.log(this.timevalue);
              var exp_time = this.timevalue.split(':');
              console.log(exp_time);
              this.expense_time1 = exp_time[0];
              var exp_time1 = exp_time[1].split(' '); 
              this.expense_time2 = exp_time1[0];
              this.expense_time3 = exp_time1[1];        
            }
          },
          (error) => {});
  }

  getPaymentType() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/paytype',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.paymentArray = obj.payments;
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }

  addExpense() { 
    if (this.expense_time1 != undefined && this.expense_time2 != undefined) {     
      this.expense_time = ConvertTimeformat('',this.expense_time1 + ':' + this.expense_time2 + ' ' + this.expense_time3);
    }
    var flag = true;
    if ((this.exp_date == undefined || this.exp_date == '') || (this.expense_time == undefined || this.expense_time == '') ||
        (this.exp_name == undefined || this.exp_name == '') || (this.exp_amount == undefined || this.exp_amount == '') ||
        (this.exp_desc == undefined || this.exp_desc == '')) {
      flag = false;
      this.toastr.error(Message_data.mandatory);
    }
    if (flag == true) {
      var fieldvalue,save_update_msg;
      if (this.exp_id != undefined) {
        fieldvalue = {
          hptl_clinic_id: this.hptl_clinic_id,
          expense_date: this.exp_date,
          expense_time: this.expense_time,
          expenses_id: this.exp_id,
          expense_name: this.exp_name,
          expense_desc: this.exp_desc,
          created_by: this.logUserName,
          amount: this.exp_amount,
          country: ipaddress.country_code
        }
        save_update_msg = 'Successfully updated the details';
      } else {
        fieldvalue = {
          hptl_clinic_id: this.hptl_clinic_id,
          expense_date: this.exp_date,
          expense_time: this.expense_time,
          expense_name: this.exp_name,
          expense_desc: this.exp_desc,
          created_by: this.logUserName,
          amount: this.exp_amount,
          country: ipaddress.country_code
        }
        save_update_msg = 'Successfully saved the details';
      }
      console.log(fieldvalue);
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'bill/shoe', fieldvalue,
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            console.log(obj);
            if (obj.key == '1') {
              this.getCurrentDate();
              this.exp_name = '';
              this.exp_amount = '';
              this.exp_desc = '';
              this.exp_id = undefined;
              this.toastr.success(save_update_msg);
              this.getExpenses();
            } else {
              this.toastr.error('Unable to save the expense,try later');
            }
          },
          error => {
            this.toastr.error(Message_data.defaultErr);
          });
    }
  }

  getExpenses() {
    var fieldvalue = {
      hptl_clinic_id: this.hptl_clinic_id
    }
    console.log(fieldvalue);
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/ghoe', fieldvalue,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log(obj);
          this.expList = [];
          if (obj.hptl_expenses != undefined && obj.hptl_expenses.length != 0) {
            for (var i = 0; i < obj.hptl_expenses.length; i++) {
              if (obj.hptl_expenses[i].created_date != undefined) 
                var get_created_date = obj.hptl_expenses[i].created_date.split('-');
              if (obj.hptl_expenses[i].created_time != undefined) 
                var get_created_time = Time_Formate(obj.hptl_expenses[i].created_time);
              if (obj.hptl_expenses[i].expense_date != undefined) 
                var get_expense_date = obj.hptl_expenses[i].expense_date.split('-');
              if (obj.hptl_expenses[i].expense_time != undefined) 
                var get_expense_time = Time_Formate(obj.hptl_expenses[i].expense_time);

              var exp_approved_flag;
              if (obj.hptl_expenses[i].approved != undefined) {
                if (obj.hptl_expenses[i].approved == "") 
                  exp_approved_flag = false;
                else if (obj.hptl_expenses[i].approved == "Yes") 
                  exp_approved_flag = true;
              }
              
              this.expList.push({
                sno: i+1,
                created_date: get_created_date[2] + "-" + get_created_date[1] + "-" + get_created_date[0],
                created_time: get_created_time,
                exp_date: get_expense_date[2] + "-" + get_expense_date[1] + "-" + get_expense_date[0],
                exp_time: get_expense_time,
                exp_id: obj.hptl_expenses[i].expenses_id,
                exp_name: obj.hptl_expenses[i].expense_name,
                exp_amount: obj.hptl_expenses[i].amount,
                exp_desc: obj.hptl_expenses[i].expense_desc,
                exp_approved: exp_approved_flag, 
                exp_approved_status: obj.hptl_expenses[i].approved          
              })
            }  
            console.log(this.expList);      
          } else {
            this.noExpFlag = true;
            this.expList = [];
          }
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  editExpense(expList) {
    this.exp_date = '';
    this.expense_time1 = '';
    this.expense_time2 = '';
    this.expense_time3 = '';
    this.exp_name = '';
    this.exp_amount = '';
    this.exp_desc = '';
    this.exp_id = '';
    for (var i = 0; i < this.expList.length; i++) {
      if (expList.exp_id == this.expList[i].exp_id) {
        this.expList.splice(i, 1);
        this.exp_date = Date_Formate(expList.exp_date);
        var exp_time = expList.exp_time.split(':');
        console.log(exp_time);
        this.expense_time1 = exp_time[0];
        var exp_time1 = exp_time[1].split(' '); 
        this.expense_time2 = exp_time1[0];
        this.expense_time3 = exp_time1[1];
        this.exp_name = expList.exp_name;
        this.exp_amount = expList.exp_amount;
        this.exp_desc = expList.exp_desc;
        this.exp_id = expList.exp_id;
      }
    }
  }

  selectAll() {
    if (this.select_all == true) {
      for (var i = 0; i < this.expList.length; i++) {
        this.expList[i].exp_approved = true;
      }
    } else if (this.select_all == false) {
      for (var i = 0; i < this.expList.length; i++) {
        this.expList[i].exp_approved = false;
      }
    }
  }

  approvedSave() { 
    console.log(this.expList);
    var sendData,approved_id_array = [];
    if (this.expList.length != 0) {
      for (var i = 0; i < this.expList.length; i++) {
        if (this.expList[i].exp_approved == true) 
          approved_id_array.push(this.expList[i].exp_id);
      }    
    } 
    sendData = {
      approved_by: this.logUserName,
      expenses: approved_id_array,
      country: ipaddress.country_code
    } 
    console.log("sendData -- ",sendData);
    var headers = new HttpHeaders();
    headers.append('Content-Type','application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/ahoe', sendData,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log(obj);
          if (obj.key == "1") {
            this.toastr.success(obj.result);
            this.getExpenses();
          } else if (obj.key == "0") {
            this.toastr.error(obj.result);
          }
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }
  
  cancelExpenses() {
    console.log(this.expList);
    var sendData,approved_id_array = [];
    if (this.expList.length != 0) {
      for (var i = 0; i < this.expList.length; i++) {
        if (this.expList[i].exp_approved == true) 
          approved_id_array.push(this.expList[i].exp_id);
      }    
    } 
    sendData = {
      approved_by: this.logUserName,
      expenses: approved_id_array,
      country: ipaddress.country_code
    }
    console.log("sendData -- ",sendData);
    var headers = new HttpHeaders();
    headers.append('Content-Type','application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/choe', sendData,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log(obj);
          if (obj.key == "1") {
            this.toastr.success(obj.result);
            this.getExpenses();
          } else if (obj.key == "0") {
            this.toastr.error(obj.result);
          }
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  printExpenses() {
    var margin_top;
    let printContents, popupWin;
    if (this.expList.length != 0) {
      if (this.print_template != undefined && this.print_template == 'banner') {
        printContents = this.printbanner.nativeElement.innerHTML;
        margin_top = '0px';
      } else {
        printContents = this.printnoheader.nativeElement.innerHTML;
        margin_top = '122px';
      }
      popupWin = window.open(
        '',
        '_blank',
        'top=20,left=10,height=100%,width=auto'
      );
      popupWin.document.open();
      popupWin.document.write(`
      <html>
        <head>
          <title>Bill</title>
          <link rel="stylesheet" media="screen" href="">
          <style>                        
            @page {
              size: auto;
              margin-top: ${margin_top};
              margin-right: 0px;
              margin-left: 0px;
              margin-bottom: 0px;
              overflow: visible;
            } 
            * {
                font-size: 12px !important;
                line-height: 1 !important;
                page-break-inside: always;
                overflow: visible;
              }                                     
            @media print {   
              body {
                height: auto; /* Allow body height to adjust dynamically */
              }          
              .page-break {
                page-break-before: always; /* or page-break-after: always; */
                page-break-inside: always;
              }
            }           
            .address_visibility {
              visibility: hidden;
            }        
            table.report-container {
              page-break-after: always;
            }
            thead.report-header {
              display: table-header-group;
            }
            tfoot.report-footer {
              display: table-footer-group;
            }
            .print:last-child {
              page-break-after: auto;
            }
            .alignRight {
              text-align: right;
            }
            .col-print-1 {width:8%;  float:left;}
            .col-print-2 {width:16%; float:left;}
            .col-print-3 {width:25%; float:left;}
            .col-print-4 {width:33%; float:left;}
            .col-print-5 {width:42%; float:left;}
            .col-print-6 {width:50%; float:left;}
            .col-print-7 {width:58%; float:left;}
            .col-print-8 {width:66%; float:left;}
            .col-print-9 {width:75%; float:left;}
            .col-print-10 {width:83%; float:left;}
            .col-print-11 {width:92%; float:left;}
            .col-print-12 {width:100%; float:left;}
          </style>
        </head>
        <body onload="window.print(); window.onfocus=function(){ window.close();}">${printContents}</body>
      </html>`);
      popupWin.document.close();
    }  
  }
}
