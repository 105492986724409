<div class="Details container" id="Begin_content_others" style="padding: 0 15px 0 15px; max-width: 100%;">
    <div class="row">
        <div class="col-12 p-0">
            <mdb-card>
                <mdb-card-header class="bg-white">
                    <div class="headerCover">
                        <div class="headerTilte app_list_header">
                            <h5 class="m-0" class="mainHeadingStyle">Doctor list</h5>
                        </div>
                        <div class="headerButtons">
                        </div>
                    </div>
                    <div class="col-1" style="position: relative;bottom: 2px;width: 8%;top:4px" *ngIf="is_admin">
                        <mat-label class="matlabel">Nurse list</mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3" *ngIf="is_admin">
                        <select required [(ngModel)]="doctorid" (change)="changeNurse()" class="ipcss widthappt">
                            <option *ngFor="let Nurse of NursenameList" value={{Nurse.Nursedata_id}}>{{Nurse.NurseName}}
                            </option>
                        </select>
                    </div> 
                </mdb-card-header>

                <mdb-card-body>                  
                    <div *ngIf="doctorArray.length == 0" class="nodata">No appointments(s) found</div>
                    <div class="row"  *ngIf="doctorArray.length != 0">
                        <div class="col-12 col-sm-6 col-md-3 col-lg-2 col-xl-2" *ngFor="let doctor of doctorArray">
                            <div class="doctorCover">
                                <div class="doctorImgDiv">
                                    <img src="{{doctor.Prof_image}}" class="profile_image">
                                </div>
                                <div>
                                    <p class="app_names"> {{doctor.Doctor_Name}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </mdb-card-body>
            </mdb-card>
        </div>
    </div>
</div>