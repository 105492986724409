import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { Helper_Class } from '../../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../../providers/common-data.service';
import { Date_Formate } from '../../../../assets/js/common';
// import { PharmacyService } from '../pharmacy/pharmacy.service';
import { PharmacyService } from 'src/app/pharma/pharmacy/pharmacy.service';
import { ipaddress } from '../../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
import moment from 'moment';
import { convertNumberToWords } from '../../../../assets/js/common';

@Component({
  selector: 'app-pharma-inventory-receivables',
  templateUrl: './pharma-inventory-receivables.component.html',
  styleUrls: ['./pharma-inventory-receivables.component.css']
})
export class PharmaInventoryReceivablesComponent implements OnInit {
  public countryID: any;
  public mgfID: any;
  public getPurchaseArray: any = [];
  public inventory: any = [];
  public inventoryitems: any = [];
  public pharmacyLocationArray: any;
  public locationIP = "";
  public manufacturer: any;
  public gst_no: string;
  public product_data: any;
  public pharmacistID;
  public invoiceNO: number;
  public poID: any;
  public purchasePoID;
  public batchNO: any;
  public locationArray: any = [];
  public locationName;
  public hsnNO;
  public packageCodeArray: any = [];
  public productArray: any = [];
  public receivedQuantity;
  public freeQuantity;
  public discount;
  public recvDate;
  public uom;
  public productName;
  public productID;
  public orderProduct;
  public unitPrice;
  public userInfo;
  public userID;
  public pharmacyID;
  public currentDate;
  public suppID;
  public inventoryLength;
  public currentMonthYear;
  public package_data;
  public tax_data;
  public uom_list_data;
  public invoiceAmount: number = 0;
  public invDate: string;
  public poapproved;
  public inventrydepartname: string = "All";
  public inventdepartmentarray = [];
  public pobase;
  public inventrystorename: string = "All";
  public inventrystorenamearray = [];
  public hsp_id;
  public user_type;
  public editbutton: boolean = false;
  public newbutton: boolean = false;
  public deletebutton: boolean = false;
  public printbutton: boolean = false;
  public viewbutton: boolean = false;
  public pagetitle;
  public Totalcostpirce: number = 0;
  public tcprice: string;
  public constpriceinwords;
  public supplierList: any = [];
  public poarray: any = [];
  public productdata_detal: any = [];
  public clickCount: number = 0;
  public inventorydetailsexp = [];
  public poorder: any = [];
  public checkboxarry = [];
  public filtercheckbox;
  public multicheck: boolean;
  public MedicineNameArray: any = [];
  public medicineArray: any = [];
  public MedicneData: any = [];
  public discamount = 0;
  public expiry_flag: boolean;
  public discountamount;
  public Discount_value;
  public discount_option;
  public discountpercentageflag: boolean;
  public totalCostForCheckedItems: number = 0;
  public product_wise_discountflag: boolean = true;
  public discountpriceflag: boolean = false;
  public discounttype = [];
  public podataid;
  public check_product_wise: number = 0;
  public disamt: number = 0;
  public aftdiscamt: number = 0;
  public GSTamount: number = 0;
  public Cgst:number =0;
  public Sgst:number =0;
  public Cgstper:number = 0;
  public Sgstper:number = 0;
  public invoice_editflag:boolean;
  public po_required;
  public returnAmount:number = 0;
  public receivedAmount:number = 0;
  public balance:number=0;
  public cgst:number=0;
  public sgst:number=0;
  public product_returns_id;
  public invoiceAmountbe:number=0;
  public receivedamoutsupp:number=0;
  public supplierflag:boolean;
  public inputsupplierflag:boolean;
  public batchNo_flag:boolean;
  public data_type;
  public hospitalid;
  public accepted: boolean;
  public save_dataflag:boolean;
  public perunitflag:boolean;
  public rec_qtyflag:boolean;



  constructor(public gservice: CommonDataService, public http: Http, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService,
    public messageservice: MenuViewService) {
    this.getDate();
  }

  ngOnInit(): void {
    this.save_dataflag=false;
    this.supplierflag = false;
    this.inputsupplierflag=true;
    this.discounttype = ["", "", ""]
    this.userInfo = Helper_Class.getInfo();
    this.user_type = Helper_Class.getInfo().user_type;
    this.expiry_flag = true
    this.Discount_value
    if (Helper_Class.getInfo().user_type == 'Admin') {
      this.pharmacyID = Helper_Class.getInfo().pharma_id;
      this.poapproved = Helper_Class.getInfo().po_approval_req;
      this.userID = Helper_Class.getInfo().po_approver;
      this.pharmacistID = Helper_Class.getInfo().po_approver;
      this.hsp_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.po_required=Helper_Class.getInfo().hospitals[0].po_required;
      this.data_type="hospital";
      this.hospitalid=Helper_Class.getInfo().hospitals[0].hosp_id
    }else if(Helper_Class.getInfo().user_type == 'pharmacy'){
      this.data_type="hospital"
      this.hsp_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.pharmacyID = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.poapproved = Helper_Class.getInfo().hospitals[0].po_approval_req;
      this.userID = Helper_Class.getInfo().user_id;
      this.pharmacistID = Helper_Class.getInfo().user_id;
      this.po_required=Helper_Class.getInfo().hospitals[0].po_required;
      this.hospitalid=Helper_Class.getInfo().hospitals[0].hptl_clinic_id
    } 
    else {
      this.hsp_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.pharmacyID = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.poapproved = Helper_Class.getInfo().hospitals[0].po_approval_req;
      this.userID = Helper_Class.getInfo().user_id;
      this.pharmacistID = Helper_Class.getInfo().user_id;
      this.po_required=Helper_Class.getInfo().hospitals[0].po_required;
      this.data_type="hospital"
      this.hospitalid=Helper_Class.getInfo().hospitals[0].hosp_id
    }
    
    
    if(this.userInfo.hospitals[0].receiv_invoice_edit == "0"){
      this.invoice_editflag=true
    }else{
      this.invoice_editflag=false
    }
    
   
    //this.productOrder();
    this.getPackages();
    this.getPharmacy();
    this.getSuppliers();
    this.freeQuantity = "0";
    if (Helper_Class.getmodulelist() != undefined) {
      for (var i = 0; i < Helper_Class.getmodulelist().length; i++) {
        if (Helper_Class.getmodulelist()[i].module_id == "57") {
          if (Helper_Class.getmodulelist()[i].edit == "1") {
            this.editbutton = true;
          }
          if (Helper_Class.getmodulelist()[i].create == "1") {
            this.newbutton = true;
          }
          if (Helper_Class.getmodulelist()[i].delete == "1") {
            this.deletebutton = true;
          }
          if (Helper_Class.getmodulelist()[i].print == "1") {
            this.printbutton = true;
          }
          if (Helper_Class.getmodulelist()[i].view == "1") {
            this.viewbutton = true;
          }
        }
      }
    }

    this.discount_option = "select"
    this.isDiscountOptionSelected(this.discount_option)
  }

  getSuppliers() {
    // if(this.supp_name != undefined && this.supp_name.length >2){
   
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "inv/gsup/",
      JSON.stringify({
        pharmacy_id: this.pharmacyID,
        supp_name: this.manufacturer

      }), { headers: headers }).subscribe(
        data => {
          var obj = data.json();
          console.log("supplier name  = " + JSON.stringify(obj))
          for (var i = 0; i < obj.supplier_details.length; i++) {
            this.supplierList.push({
              name: obj.supplier_details[i].name,
              supp_id: obj.supplier_details[i].supp_id,
              supp_code: obj.supplier_details[i].supp_code,
              gst_no: obj.supplier_details[i].gst_no,
              refund_amount:obj.supplier_details[i].refund_amount,
              product_returns_id:obj.supplier_details[i].product_returns_id,
              received_amount:obj.supplier_details[i].received_amount
            })
          }
        },
        error => {
          this.toastr.error(Message_data.unabletofetsupl);
        }
      )
  }

  supplier_change(supplier) {
    this.suppID = supplier.supp_id;
    this.manufacturer = supplier.name;
    this.gst_no = supplier.gst_no;
    this.returnAmount = parseFloat(supplier.refund_amount);
    // this.inventory=[];
    this.supplier_changee()
  }

  supplier_changee() {
    const selectedSupplier = this.supplierList.find(supplier => supplier.name === this.manufacturer);
    if (selectedSupplier) {
      this.suppID = selectedSupplier.supp_id;
      this.gst_no = selectedSupplier.gst_no;
      if(selectedSupplier.refund_amount != undefined){
        this.returnAmount = parseFloat(selectedSupplier.refund_amount);
      }else{
        this.returnAmount = 0;
      }
      this.product_returns_id=selectedSupplier.product_returns_id;
      if(selectedSupplier.received_amount != undefined && selectedSupplier.received_amount  != ""){
        this.receivedamoutsupp=parseFloat(selectedSupplier.refund_amount);
      }else{
        this.receivedamoutsupp=0;
      }
    }
   
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/gpbs",
      JSON.stringify({
        supp_id: this.suppID
      }), { headers: headers }).subscribe(
        data => {
          this.product_data = [];
          this.productdata_detal = [];
          this.inventory = [];
          this.tcprice = "0";
          this.constpriceinwords = "zero";
          this.updateTotalCost()

          var obj = data.json();
          console.log("supplier change = " + JSON.stringify(obj));

          for (var i = 0; i < obj.po_details.length; i++) {
            this.product_data.push({
              purchase_order_id: obj.po_details[i].po_id,
              date: obj.po_details[i].po_date,
              checked: false
            })
            this.productdata_detal.push({
              purchase_order_id: obj.po_details[i].po_id,
              date: obj.po_details[i].po_date,
              checked: false
            })
          }
          console.log("supplier data = " + JSON.stringify(obj))
        }, error => {
          console.log("error in retriveing the supplier name")
        }
      )
  }

  changeMedicineName(e, type) {
    console.log("type...........", type)
    var senddetails = JSON.stringify({
      drug_name: e.target.value.toString(),
      pharmacy_id: this.pharmacyID,
      medicare_name: "1",
      type: type
    })
    if (e.target.value.toString() != null && e.target.value.toString().length > 2) {
      console.log("send dtat" + JSON.stringify(senddetails))
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/nmeddetails/', senddetails,
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            console.log("obj" + JSON.stringify(obj));

            if (obj.med_details != null) {
              this.MedicineNameArray = [];
              this.medicineArray = [];
              this.MedicneData = [];
              for (var i = 0; i < obj.med_details.length; i++) {
                var medname = obj.med_details[i].short_name + " " + obj.med_details[i].med_name
                // this.MedicneData.push(medname);
                this.MedicneData.push({ name: medname, quantity: obj.med_details[i].quantity, generic: obj.med_details[i].generic_name });
                this.medicineArray.push({
                  med_id: obj.med_details[i].med_id,
                  med_name: obj.med_details[i].med_name,
                  med_type_id: obj.med_details[i].med_type_id,
                  type_name: obj.med_details[i].type_name,
                  short_name: obj.med_details[i].short_name,
                  short_form: obj.med_details[i].short_form,
                  show_short_form: obj.med_details[i].show_short_form,
                  show_intake: obj.med_details[i].show_intake,
                  medname: medname,
                  cgst: obj.med_details[i].cgst,
                  sgst: obj.med_details[i].sgst,
                  rack_id: obj.med_details[i].rack_id,
                  bin_id: obj.med_details[i].bin_id
                })
              }
            }
          },
          error => { });
    } else {
      this.MedicineNameArray = [];
    }
  }

  selectMedicineItem(item, inventorySendArray) {
    console.log("drugh detail" + JSON.stringify(inventorySendArray))
    // this.medicinenameflag = true;
    // this.selectedbatch_flag = false;
    for (var i = 0; i < this.medicineArray.length; i++) {
      if (item.name == this.medicineArray[i].medname) {
        console.log("log data = " + JSON.stringify(this.medicineArray[i]))
        inventorySendArray.product_id = this.medicineArray[i].med_id

        // drug_detailsarray.drug_name = item.name;
        // drug_detailsarray.gen_name = item.generic;
        // drug_detailsarray.drug_disp_name = this.medicineArray[i].short_name + " " + this.medicineArray[i].med_name;
        // drug_detailsarray.drug_id = this.medicineArray[i].med_id;
        // this.medicineName = this.medicineArray[i].med_name;
        // drug_detailsarray.cgst = this.medicineArray[i].cgst;
        // drug_detailsarray.sgst = this.medicineArray[i].sgst;
        // drug_detailsarray.rack_id = this.medicineArray[i].rack_id;
        // drug_detailsarray.bin_id = this.medicineArray[i].bin_id;
        // sno: i + 1,
        // purchase_request_id: obj.product_details[i].purchase_request_id,
        // product_id: obj.product_details[i].product_id,
        // hsn_no: obj.product_details[i].hsn_no,
        // product_name: obj.product_details[i].product_name,
        // uom_code: obj.product_details[i].uom_code,
        // uom_desc: obj.product_details[i].uom_desc,
        // quantity: obj.product_details[i].quantity,
        // unit_price: obj.product_details[i].sales_price,
        // sales_price: obj.product_details[i].sales_price,
        // cost_price: obj.product_details[i].cost_price,
        // pharma_id: this.pharmacyID,
        // cgst: obj.product_details[i].cgst,
        // sgst: obj.product_details[i].sgst,
        // location: this.locationName,
        // expiry_date: "",//this.currentMonthYear,
        // index: index,
        // checked: false,
        // previousValue:0,
        // type:"noinputbox"
      }
    }
    console.log("DRUG DETAILS " + JSON.stringify(inventorySendArray))
    // this.getItemPrices(drug_detailsarray);
    // drug_detailsarray.batcharray = [];
    // drug_detailsarray.batch_no = "";
    // drug_detailsarray.quantityDisable = true;
    this.MedicineNameArray = [];
  }


  // rowcheck(po,checked){
  //     if(checked == false){
  //       for(var i=0;i<this.product_data.length;i++){
  //         if(po.purchase_order_id == this.product_data[i].purchase_order_id){
  //           this.product_data.push({
  //             purchase_order_id:this.product_data[i].po_id,
  //             date:this.product_data[i].po_date,
  //             checked:true
  //           })
  //         }
  //       }

  //     }else{
  //       for(var i=0;i<this.product_data.length;i++){
  //         this.product_data.push({
  //           purchase_order_id:this.product_data[i].po_id,
  //           date:this.product_data[i].po_date,
  //           checked:false
  //         })
  //       }

  //     }
  //     this.isAllSelectedPO(po,po.checked)
  //   }

  isAllSelectedPO(po, checked) {
    console.log("purchase = " + JSON.stringify(po) + "========" + JSON.stringify(checked));
    // alert(this.accepted)
    
    po.checked = !checked;
    this.Totalcostpirce = 0;
    this.Discount_value=0;
    this.inventory = [];
    // this.poorder=[];
    console.log("product details  = " + JSON.stringify(this.productdata_detal))
    var poorder: any = [];
    for (var i = 0; i < this.productdata_detal.length; i++) {
      if (po.purchase_order_id == this.productdata_detal[i].purchase_order_id) {
        if (checked == true) {
          this.poorder = this.poorder.filter(orderId => orderId !== po.purchase_order_id);
          if(this.poorder.length == 0){
            this.inputsupplierflag=true;
            this.supplierflag=false;
          }
          console.log("SELECTED PO " + JSON.stringify(this.poorder))
          // var send_data = {
          //   pharma_id: this.hospitalid,
          //   po_no: this.poorder,
          //   type:this.data_type
          // }

           var send_data = {
            pharma_id: this.pharmacyID,
            po_no: this.poorder,
            type:this.user_type
            // type:this.data_type
          }
          console.log("data " + JSON.stringify(send_data))
          var headers = new Headers();
          headers.append('Content-Type', 'application/json');
          this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/gibp', send_data,
            { headers: headers })
            .subscribe(
              response => {
                var obj = response.json();
                console.log("durg list" + JSON.stringify(obj))
                var index = 0;
                for (var i = 0; i < obj.product_details.length; i++) {
                  var discount;
                  var perunit;
                  if (obj.product_details[i].discount != undefined) {
                    discount = obj.product_details[i].discount
                  } else {
                    discount = 0
                  }
                  if(obj.product_details[i].perunit != undefined){
                    perunit=obj.product_details[i].perunit
                  }else{
                    perunit=0
                  }
                  this.inventory.push({
                    sno: i + 1,
                    purchase_request_id: obj.product_details[i].purchase_request_id,
                    product_id: obj.product_details[i].product_id,
                    hsn_no: obj.product_details[i].hsn_no,
                    product_name: obj.product_details[i].product_name,
                    uom_code: obj.product_details[i].uom_code,
                    uom_desc: obj.product_details[i].uom_desc,
                    quantity: obj.product_details[i].quantity,
                    unit_price: obj.product_details[i].sales_price,
                    sales_price: obj.product_details[i].sales_price,
                    cost_price: obj.product_details[i].cost_price,
                    pharma_id: this.pharmacyID,
                    cgst: obj.product_details[i].cgst,
                    sgst: obj.product_details[i].sgst,
                    location: this.locationName,
                    discount: discount,
                    expiry_date: "",//this.currentMonthYear,
                    index: index,
                    checked: true,
                    previousValue: 0,
                    type: "noinputbox",
                    perunit:perunit
                  });
                  this.cost_price(obj.product_details[i])

                  index++;
                }
                this.inventoryLength = this.inventory.length;
              },
              error => {

              });
             


        } else {
          this.poorder.push(this.productdata_detal[i].purchase_order_id);
          this.supplierflag=true;
          this.inputsupplierflag=false;
          this.podataid = this.productdata_detal[i].purchase_order_id;
          console.log("SELECTED PO " + JSON.stringify(this.poorder))
          // var send_data = {
          //   pharma_id: this.hospitalid,
          //   po_no: this.poorder,
          //   type:this.data_type
          // }
                    var send_data = {
            pharma_id: this.pharmacyID,
            po_no: this.poorder,
            type:this.user_type
            // type:this.data_type
          }
          console.log("data " + JSON.stringify(send_data))
          var headers = new Headers();
          headers.append('Content-Type', 'application/json');
          this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/gibp', send_data,
            { headers: headers })
            .subscribe(
              response => {
                var obj = response.json();
                console.log("durg list" + JSON.stringify(obj))
                var index = 0;
                var perunit;
                for (var i = 0; i < obj.product_details.length; i++) {
                  if(obj.product_details[i].perunit != undefined){
                    perunit=obj.product_details[i].perunit
                  }else{
                    perunit=0
                  }
                  this.inventory.push({
                    sno: i + 1,
                    purchase_request_id: obj.product_details[i].purchase_request_id,
                    product_id: obj.product_details[i].product_id,
                    hsn_no: obj.product_details[i].hsn_no,
                    product_name: obj.product_details[i].product_name,
                    uom_code: obj.product_details[i].uom_code,
                    uom_desc: obj.product_details[i].uom_desc,
                    quantity: obj.product_details[i].quantity,
                    unit_price: obj.product_details[i].sales_price,
                    sales_price: obj.product_details[i].sales_price,
                    cost_price: obj.product_details[i].cost_price,
                    pharma_id: this.pharmacyID,
                    cgst: obj.product_details[i].cgst,
                    sgst: obj.product_details[i].sgst,
                    location: this.locationName,
                    expiry_date: "",//this.currentMonthYear,
                    index: index,
                    checked: true,
                    previousValue: 0,
                    type: "noinputbox",
                    perunit:perunit
                  });
                  this.cost_price(obj.product_details[i])
                  index++;
                }
                this.inventoryLength = this.inventory.length;
              
              },
              error => {

              });
              // setTimeout(()=>{
              //   if(this.poorder.length != 0){
              //     if(this.inventory.length != 0 ){
              //       this.save_dataflag=true
              //   }else{
              //     this.save_dataflag=false
              //   }
              //   alert(this.save_dataflag +" "+this.inventory.length)
              //   this.save_dataflag=true
              //   }else{
              //     this.save_dataflag=false
              //   }
              // },100)
          
        }
      }
    }
    
    setTimeout(()=>{
      if(this.poorder.length != 0){
        if(this.inventory.length != 0 ){
          this.save_dataflag=true
      }else{
        this.save_dataflag=false
      }
      }else{
        this.save_dataflag=false
      }
    },200)
   
  }

  checkboxfunction() {


    if (this.filtercheckbox == true) {


      this.checkboxarry = this.product_data;

      this.product_data = [];
      this.productdata_detal = [];
      for (var i = 0; i < this.checkboxarry.length; i++) {
        // this.product_data.checked=true;
        // this.productdata_detal.checked=true;

        this.product_data.push({
          purchase_order_id: this.checkboxarry[i].purchase_order_id,
          date: this.checkboxarry[i].date,
          checked: true
        })
        this.productdata_detal.push({
          purchase_order_id: this.checkboxarry[i].purchase_order_id,
          date: this.checkboxarry[i].date,
          checked: true
        })
      }
      this.selectallpoorder(true);
      //   for (var j=0 ;j < this.product_data.length;j++){
      //     this.isAllSelectedPO(this.product_data[j],false)
      //  }

    } else if (this.filtercheckbox == false) {
      this.checkboxarry = this.product_data
      this.product_data = [];
      this.productdata_detal = [];
      for (var i = 0; i < this.checkboxarry.length; i++) {
        this.product_data.checked = false;
        this.productdata_detal.checked = false;

        this.product_data.push({
          purchase_order_id: this.checkboxarry[i].purchase_order_id,
          date: this.checkboxarry[i].date,
          checked: false
        })
        this.productdata_detal.push({
          purchase_order_id: this.checkboxarry[i].purchase_order_id,
          date: this.checkboxarry[i].date,
          checked: false
        })
      }
      this.selectallpoorder(false);
    }

  }

  selectallpoorder(value) {
    this.poorder = [];
    this.inventory = [];
    this.Totalcostpirce = 0;
    // let selectallpurchaseid=[];
    if (value == true) {
      for (var k = 0; k < this.productdata_detal.length; k++) {
        this.poorder.push(this.productdata_detal[k].purchase_order_id);
      }
      var send_data = {
        pharma_id: this.pharmacyID,
        po_no: this.poorder
      }
      console.log("data " + JSON.stringify(send_data))
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/gibp', send_data,
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            console.log("durg list" + JSON.stringify(obj))
            var index = 0;
            var perunit
            for (var i = 0; i < obj.product_details.length; i++) {
              if(obj.product_details[i].perunit != undefined){
                perunit=obj.product_details[i].perunit
              }else{
                perunit=0
              }
              this.inventory.push({
                sno: i + 1,
                purchase_request_id: obj.product_details[i].purchase_request_id,
                product_id: obj.product_details[i].product_id,
                hsn_no: obj.product_details[i].hsn_no,
                product_name: obj.product_details[i].product_name,
                uom_code: obj.product_details[i].uom_code,
                uom_desc: obj.product_details[i].uom_desc,
                quantity: obj.product_details[i].quantity,
                unit_price: obj.product_details[i].sales_price,
                sales_price: obj.product_details[i].sales_price,
                cost_price: obj.product_details[i].cost_price,
                pharma_id: this.pharmacyID,
                cgst: obj.product_details[i].cgst,
                sgst: obj.product_details[i].sgst,
                location: this.locationName,
                expiry_date: "",//this.currentMonthYear,
                index: index,
                checked: false,
                previousValue: 0,
                type: "noinputbox",
                perunit:perunit
              });
              this.cost_price(obj.product_details[i])
              index++;
            }
            this.inventoryLength = this.inventory.length;
          },
          error => {

          });
    } else {
      this.inventory = [];
    }

    // this.poorder.push(this.productdata_detal[i].purchase_order_id);
    // console.log("SELECTED PO "+JSON.stringify(poorder))

  }

  checkeddata(item) {
    console.log("item 1 =" + JSON.stringify(item))
    var id = item.purchase_order_id;
    this.poarray.push(
      item.purchase_order_id
    )
    console.log("item 2 =" + JSON.stringify(this.poarray))
  }

  productOrder() {
    //     if(this.poapproved == "1"){
    //       senddata= { 
    //         pharmacy_id: this.pharmacyID,
    //         is_approve:"1" 
    //       }
    // }
    // else
    // {
    //      senddata= { 
    //       pharmacy_id: this.pharmacyID
    //     }
    //   } 
    var senddata;
    senddata = {
      pharmacy_id: this.pharmacyID,
      is_approve: this.poapproved === "1" ? "1" : undefined
    };

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/gpos/", JSON.stringify(senddata),

      { headers: headers }).subscribe(
        data => {
          var obj = data.json();
          console.log("po id =" + JSON.stringify(obj))
          if (data.json().purchase_orders != null) {
            this.product_data = data.json().purchase_orders;
          }
        },
        error => { });
  }

  inventrybase_department() {
    var senddata = {
      po_base: this.pobase,
      po_id: this.poID

    }
    console.log("pharmacy Id =" + JSON.stringify(senddata))
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "gen/gdpo ", JSON.stringify({ po_base: this.pobase, po_id: this.poID, type: this.user_type }), { headers: headers }).subscribe(
      response => {
        var obj = response.json()
        console.log(" DEPARTMENT DATA" + JSON.stringify(obj))
        this.inventdepartmentarray = obj.departments;
      }
    )
  }

  pobase_department(value) {
    this.getstorename(this.inventrydepartname)
    this.getPurchaseDetails()
  }

  getstorename(value) {
    var senddata = {
      hptl_clinic_id: this.pharmacyID,
      department_id: value
    }
    console.log("send data" + JSON.stringify(senddata))
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "gen/gsdbh", JSON.stringify({ hptl_clinic_id: this.hsp_id, department_id: value }), { headers: headers }).subscribe(
      response => {
        var obj = response.json()
        console.log(" store DATA" + JSON.stringify(obj));
        this.inventrystorenamearray = obj.stores;
      },
      error => {
        console.log("error on retriving the store name")
      }
    )
  }

  pobase_store(value) {
    console.log("pobase_store value" + value)
    this.getPurchaseDetails()
  }

  getPackages() {
    this.http.get(ipaddress.getIp + "pharmacontrol/pkgcode/").subscribe(
      data => {
        if (data.json().product_packages != null) {
          this.package_data = data.json().product_packages;
        }
      },
      error => { });
  }

  getsupplierid() {
    this.inventory = [];
    this.purchasePoID = "";
    this.purchasePoID = this.poID;

    if (this.poID != undefined) {
      for (var j = 0; j < this.product_data.length; j++) {
        if (this.poID == this.product_data[j].purchase_order_id) {
          this.pobase = this.product_data[j].po_base
        }
      }
    }
    var inventorydatasenddata = {
      purchase_order_id: this.poID, department_id: this.inventrydepartname,
      store_id: this.inventrystorename,
      po_base: this.pobase
    }
    console.log("inverntry " + JSON.stringify(inventorydatasenddata))
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/gpo/", JSON.stringify({
      purchase_order_id: this.poID,
      // department_id:this.inventrydepartname,
      // store_id:this.inventrystorename,
      // po_base:this.pobase
    }), { headers: headers }).subscribe(
      data => {
        if (data.json() != null) {
          var obj = data.json();
          console.log("porder" + JSON.stringify(obj))
          this.mgfID = obj.mfg_id;
          this.suppID = obj.supp_id;
          this.manufacturer = obj.name;
          this.gst_no = obj.gst_no;
          this.countryID = obj.country;
          var index = 0;
        }
      },
    )
    // this.inventrybase_department()
    // }else{
    //   console.log("poid is undef")
    // }
  }



  getPurchaseDetails() {


    this.inventory = [];
    this.purchasePoID = "";
    this.purchasePoID = this.poID;




    if (this.poID != undefined) {
      for (var j = 0; j < this.product_data.length; j++) {
        if (this.poID == this.product_data[j].purchase_order_id) {
          this.pobase = this.product_data[j].po_base
        }
      }
    }
    // if (this.poID != undefined) {
    var inventorydatasenddata = {
      purchase_order_id: this.poID, department_id: this.inventrydepartname,
      store_id: this.inventrystorename,
      po_base: this.pobase
    }
    console.log("inverntry " + JSON.stringify(inventorydatasenddata))
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/gibpo/", JSON.stringify({
      po_id: this.poID, department_id: this.inventrydepartname,
      store_id: this.inventrystorename, po_base: this.pobase
    }), { headers: headers }).subscribe(
      data => {
        if (data.json() != null) {
          var obj = data.json();
          console.log("porder" + JSON.stringify(obj))
          // this.mgfID = obj.mfg_id;
          // this.suppID = obj.supp_id;
          // this.manufacturer = obj.name;
          // this.gst_no=obj.gst_no;
          // this.countryID = obj.country;
          var index = 0;
          var perunit
          for (var i = 0; i < obj.purchase_items.length; i++) {
            if(obj.product_details[i].perunit != undefined){
              perunit=obj.product_details[i].perunit
            }else{
              perunit=0
            }
            this.inventory.push({
              purchase_request_id: obj.purchase_items[i].purchase_request_id,
              product_id: obj.purchase_items[i].product_id,
              hsn_no: obj.purchase_items[i].hsn_no,
              product_name: obj.purchase_items[i].product_name,
              uom_code: obj.purchase_items[i].uom_code,
              uom_desc: obj.purchase_items[i].uom_desc,
              quantity: obj.purchase_items[i].quantity,
              unit_price: obj.purchase_items[i].unit_price,
              sales_price: "0",
              cost_price: "0",
              pharma_id: this.pharmacyID,
              cgst: obj.purchase_items[i].cgst,
              sgst: obj.purchase_items[i].sgst,
              location: this.locationName,
              expiry_date: "",//this.currentMonthYear,
              index: index,
              checked: false,
              previousValue: 0,
              type: "noinputbox",
              perunit:perunit
            });
            index++;
          }
          this.inventoryLength = this.inventory.length;
        }
      },
    )

    this.inventrybase_department()
    // }else{
    //   console.log("poid is undef")
    // }

  }

  fillOtherDetails() {
    if (this.productName != undefined) {
      for (var i = 0; i < this.getPurchaseArray.length; i++) {
        if (this.productName == this.getPurchaseArray[i].product_name) {
          this.receivedQuantity = this.getPurchaseArray[i].quantity;
          if (this.getPurchaseArray[i].uom_desc != undefined) {
            if (this.uom != undefined) {
              this.uom.uom_desc = this.getPurchaseArray[i].uom_desc;
            }
          }
        }
      }
    }
  }

  getPharmacy() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/pharlocbyid/",
      JSON.stringify({
        pharmacy_id: this.pharmacyID,
        pharmacist_id: this.pharmacistID,
      }), { headers: headers }).subscribe(
        data => {
          if (data.json().pharma_locations != null) {
            this.pharmacyLocationArray = data.json().pharma_locations;
            this.locationArray = this.pharmacyLocationArray;
            this.locationName = this.locationArray[0].location;
          }
        },
        error => { });
  }

  isDiscountOptionSelected(value) {
    if (value == "percentage") {
      this.discountpercentageflag = true
      this.discountpriceflag = false
      this.product_wise_discountflag = true
      for(var i=0;i<this.inventory.length;i++){
        this.inventory[i].discount=0;
      }
      // this.GSTamount = 0;
      // this.invoiceAmount = 0;

    } else if (value == "price") {
      this.discountpriceflag = true
      this.discountpercentageflag = false
      this.product_wise_discountflag = true
      // this.GSTamount = 0;
      // this.invoiceAmount = 0;

    } else if (value == "product_wise") {
      this.product_wise_discountflag = false
      this.discountpercentageflag = false
      this.discountpriceflag = false
      // this.GSTamount = 0;
      // this.invoiceAmount = 0;
    }
  }

  cost_price(data: any) {
    this.updateTotalCost();
  }

  updateTotalCost() {
    if(this.discount_option == "select"){
      this.discountpercentageflag=false
    }


    this.totalCostForCheckedItems = 0;
    this.GSTamount = 0;
    this.invoiceAmount = 0;
    this.disamt = 0;
    this.Cgst=0;
    this.Cgstper=0;
    this.Sgst=0;
    this.Sgstper=0;
    for (let data of this.inventory) {
      if (data.checked) {
        // Assuming data.quantity and data.cost_price are numeric values
        var discgst = 0;
        var dissgst = 0;
        var cgstcal = 0;
        var sgstcal = 0;
        console.log("data" + JSON.stringify(data))




        if (data.cost_price != undefined) {
          this.totalCostForCheckedItems += data.quantity * data.cost_price;
        } else {
          this.totalCostForCheckedItems += data.quantity * 0;

        }

        if (data.cost_price != undefined) {
          var itemvalue = data.quantity * data.cost_price;
        } else {
          var itemvalue = data.quantity * 0;

        }


        if (data.cgst != undefined && data.cgst != null && data.cgst != "") {
          discgst = parseFloat(data.cgst)
        } else {
          discgst = 0
        }
        if (data.sgst != undefined && data.sgst != null && data.sgst != "") {
          dissgst = parseFloat(data.sgst)
        } else {
          dissgst = 0
        }
        var afterdisamt = itemvalue;
        console.log("afterdisamt = " + afterdisamt)
        this.Cgstper+=discgst;
        this.Sgstper+=dissgst;
        this.Cgst+=afterdisamt * (discgst / 100)
        this.Sgst+=afterdisamt * (dissgst / 100)
        var itemgst = (afterdisamt * (discgst / 100)) + (afterdisamt * (dissgst / 100));
        console.log("itemgst = " + itemgst)
        var total_item_value = afterdisamt + itemgst
        console.log("total_item_VALUE =  " + total_item_value)
        this.GSTamount += itemgst;
        this.GSTamount = parseFloat(this.GSTamount.toFixed(2))
        this.invoiceAmount += total_item_value;
        this.invoiceAmount = parseFloat(this.invoiceAmount.toFixed(2))
        this.refundamountcal()
        console.log("INVOCICE AMOUNT  = " + this.invoiceAmount)


        console.log("discount = " + this.discamount)

        this.tcprice = (this.totalCostForCheckedItems).toFixed(2)
        this.constpriceinwords = convertNumberToWords(this.invoiceAmount, ipaddress.country_code);
      }
    }

    // this.discountcal();
  }

  discountcal() {
    this.invoiceAmount = 0;
    this.discamount = 0;
   
    
    if (this.product_wise_discountflag == false) {
   
      // this.GSTamount = 0;
      // this.discamount = 0;
      this.totalCostForCheckedItems = 0;
      this.aftdiscamt = 0;
      this.GSTamount = 0;
      this.disamt = 0;
      this.invoiceAmount = 0;
      this.cgst=0;
      this.sgst=0;
      for (let data of this.inventory) {

        if (data.checked) {
          if (data.discount != undefined && data.discount != "") {

            this.aftdiscamt = 0;
            var discgst = 0;
            var dissgst = 0;
            var cgstcal = 0;
            var sgstcal = 0;

            if (data.cgst != undefined && data.cgst != null && data.cgst != "") {
              discgst = parseFloat(data.cgst)
            } else {
              discgst = 0
            }
            if (data.sgst != undefined && data.sgst != null && data.sgst != "") {
              dissgst = parseFloat(data.sgst)
            } else {
              dissgst = 0
            }
            var itemvalue = data.quantity * data.cost_price;
            console.log("item value  = " + itemvalue)
            // var discamt = itemvalue*(data.dis  count/100)

            if (data.discount != undefined) {
              var discamt = itemvalue * (parseFloat(data.discount) / 100);
            } else {
              var discamt = itemvalue * (1 / 100);
            }
            console.log("disc amt =- " + discamt)
            var afterdisamt = itemvalue - discamt
            var itemgst = (afterdisamt * (discgst / 100)) + (afterdisamt * (dissgst / 100))
            console.log("gst = " + itemgst)
            var total_item_value = afterdisamt + itemgst

            this.disamt += discamt;
            this.disamt = parseFloat(this.disamt.toFixed(2))
            this.cgst += (afterdisamt * (discgst / 100))
            this.sgst += (afterdisamt * (dissgst / 100))
            this.cgst = parseFloat(this.cgst .toFixed(2))
            this.sgst = parseFloat(this.sgst.toFixed(2))
            this.GSTamount += itemgst;
            this.GSTamount = parseFloat(this.GSTamount.toFixed(2))
            this.invoiceAmount += total_item_value;
            console.log("invoice = " + this.invoiceAmount)
            this.invoiceAmount = parseFloat(this.invoiceAmount.toFixed(2))
            this.refundamountcal()
            console.log("invoice 2 = " + this.invoiceAmount)
            this.disamt.toFixed(2);
            // this.aftdiscamt += (this.check_product_wise - disamt)
            // var cgstcal = (this.aftdiscamt * discgst / 100)
            // var sgstcal = (this.aftdiscamt * discgst / 100)
            // this.GSTamount = parseFloat((cgstcal + sgstcal).toFixed(2));
            // this.invoiceAmount += (this.aftdiscamt + cgstcal + sgstcal)
            // this.invoiceAmount = parseFloat(this.invoiceAmount.toFixed(2));
            console.log("PRICE CALCULATION " + this.aftdiscamt + " = " + cgstcal + " = " + sgstcal + " = " + this.GSTamount + " = " + (this.aftdiscamt + cgstcal + sgstcal))
          }
        }
      }

    } else if (this.discountpercentageflag == true) {
    
      this.totalCostForCheckedItems = 0;
      this.aftdiscamt = 0;
      this.GSTamount = 0;
      this.disamt = 0;
      this.invoiceAmount = 0;
      this.cgst=0;
      this.sgst=0;
      var disamount = 0;
      for (let data of this.inventory) {
        if (data.checked) {
          if (this.Discount_value != undefined && this.Discount_value.length >= 1) {
            this.aftdiscamt = 0;
            var discgst = 0;
            var dissgst = 0;
            var cgstcal = 0;
            var sgstcal = 0;

            if (data.cgst != undefined && data.cgst != null && data.cgst != "") {
              discgst = parseFloat(data.cgst)
            } else {
              discgst = 0
            }
            if (data.sgst != undefined && data.sgst != null && data.sgst != "") {
              dissgst = parseFloat(data.sgst)
            } else {
              dissgst = 0
            }
            console.log("DETAILS " + data.quantity + " === " + data.cost_price + " === " + (data.quantity * data.cost_price))

            var itemvalue = data.quantity * data.cost_price;
            var discamt = itemvalue * (this.Discount_value / 100)
            var afterdisamt = itemvalue - discamt;
            var itemgst = (afterdisamt * (discgst / 100)) + (afterdisamt * (dissgst / 100));
            var total_item_value = afterdisamt + itemgst;

            this.disamt += discamt;
            this.disamt = parseFloat(this.disamt.toFixed(2))
            this.cgst += (afterdisamt * (discgst / 100))
            this.sgst += (afterdisamt * (dissgst / 100))
            this.cgst = parseFloat(this.cgst .toFixed(2))
            this.sgst = parseFloat(this.sgst.toFixed(2))
            this.GSTamount += itemgst;
            this.GSTamount = parseFloat(this.GSTamount.toFixed(2))
            this.invoiceAmount += total_item_value;
            this.invoiceAmount = parseFloat(this.invoiceAmount.toFixed(2))
            this.refundamountcal()

            this.disamt.toFixed(2);

            console.log("PRICE CALCULATION " + afterdisamt + " = " + discamt + " = " + itemgst + " = " + total_item_value)

            // this.totalCostForCheckedItems += (data.quantity * data.cost_price);
            // disamount = disamount + this.totalCostForCheckedItems * (parseFloat(this.Discount_value) / 100);
            // const discountAmount: number = this.totalCostForCheckedItems * (parseFloat(this.Discount_value) / 100);

            // // Format the discount amount for display
            // this.disamt = parseFloat(discountAmount.toFixed(2));
            // var aft = (this.totalCostForCheckedItems - disamount)
            // this.aftdiscamt += (this.totalCostForCheckedItems - disamount)
            // var cgstcal = (this.aftdiscamt * discgst / 100)
            // var sgstcal = (this.aftdiscamt * discgst / 100)
            // this.GSTamount = parseFloat((cgstcal + sgstcal).toFixed(2));
            // this.invoiceAmount += this.aftdiscamt + cgstcal + sgstcal
            // this.invoiceAmount = parseFloat(this.invoiceAmount.toFixed(2));
            console.log("PRICE CALCULATION " + (this.totalCostForCheckedItems - disamount) + " = " + cgstcal + " = " + sgstcal + " = " + this.GSTamount + " = " + (this.aftdiscamt + cgstcal + sgstcal))
          } else {
            
            this.updateTotalCost()
            this.refundamountcal()
          }
        }
      }

    } else if (this.discountpriceflag == true) {
  
      this.GSTamount = 0;
      this.totalCostForCheckedItems = 0;
      this.aftdiscamt = 0;
      this.cgst=0;
      this.sgst=0;
      this.invoiceAmount = 0;
      for (let data of this.inventory) {
        if (data.checked) {

          if (this.discountamount != undefined && this.discountamount.length >= 1) {
            var discgst = 0;
            var dissgst = 0;
            var cgstcal = 0;
            var sgstcal = 0;
            if (data.cgst != undefined && data.cgst != null && data.cgst != "") {
              discgst = parseFloat(data.cgst)
            } else {
              discgst = 0
            }
            if (data.sgst != undefined && data.sgst != null && data.sgst != "") {
              dissgst = parseFloat(data.sgst)
            } else {
              dissgst = 0
            }
            var itemvalue = data.quantity * data.cost_price;
            var itemgst = (itemvalue * (discgst / 100)) + (itemvalue * (dissgst / 100));
            data.itemvalue = itemvalue;
            data.gstvalue = itemgst;
          } else {
            this.invoiceAmount = 0
            this.refundamountcal()
          }

        }
      }
      var totalval = 0;
      var totalgst = 0;
      for (let data of this.inventory) {
        if (data.checked) {
          if (this.discountamount != undefined && this.discountamount.length >= 1) {
            totalval += data.itemvalue;
            totalgst += data.itemgst;
          }
        }
      }
      this.disamt = this.discountamount.toFixed(2);
      this.GSTamount = totalgst;
      this.cgst += (itemvalue * (discgst / 100))
      this.sgst += (itemvalue * (dissgst / 100))
      this.cgst = parseFloat(this.cgst .toFixed(2))
      this.sgst = parseFloat(this.sgst.toFixed(2))
      this.invoiceAmount = (totalval - this.disamt) + this.GSTamount
      this.refundamountcal()
    }else{
      this.updateTotalCost()
    }
    this.invoiceamount(this.invoiceAmount)
  }

  deleteItemReceive(data) {
    if (this.inventory.length != 0) {
      for (var i = 0; i < this.inventory.length; i++) {
        if (this.inventory[i].product_id == data.product_id && this.inventory[i].quantity == data.quantity) {
          if (this.inventory[i].index == data.index)
            this.inventory.splice(i, 1);
        }
      }
      this.updateTotalCost();
    }
  }

  deleteMedicine(drug_id, index) {

  }

  getProductDetails() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/gpcbypharn/",
      JSON.stringify({
        pharmacist_id: this.userID,
        prod_name: this.productName,
        mfg_id: this.mgfID,
        pharmacy_id: this.pharmacyID
      }), { headers: headers }).subscribe(
        data => {
          var dataval = JSON.parse(data["_body"]);
          if (dataval != undefined && dataval.products != null) {
            this.productArray = dataval.products;
          }
        });
  }

  getProductName(data) {
    this.productName = data.name;
    this.uom = data.uom_desc;
    this.productID = data.product_id;
    this.orderProduct = data.product_code;
    this.unitPrice = data.cost_price;
    this.hsnNO = data.hsn_no;
    this.productArray = [];
  }

  save_data() {
    var save_flag = true;
    console.log("inventory = " + JSON.stringify(this.inventory))
    if (this.invoiceNO == undefined) {
      save_flag = false;
    }
    if (this.inventory.length != 0) {
      var res = this.inventory.filter(o => o.expiry_date_flag == false);
      if (res.length != 0) {
        save_flag = false;
        this.toastr.error("Check given expiry date in format mm-yyyy");
      }
    }
    if (save_flag != false) {
      if (this.inventory.length != 0) {
        this.inventorydetailsexp = this.inventory
        var inventorySendArray = this.inventorydetailsexp.filter((x) => { return x.checked == true });
        for (var i = 0; i < inventorySendArray.length; i++) {
          this.expiry_flag = true
          this.batchNo_flag = true
          this.perunitflag = true;
          this.rec_qtyflag=true;

          if (inventorySendArray[i].expiry_date != "" && inventorySendArray[i].expiry_date != undefined) {
            this.expiry_flag = false
            //var expdate = inventorySendArray[i].expiry_date.split("-");
            //inventorySendArray[i].expiry_date = expdate[1] + "-" + expdate[0] + "-" + "28";
          }
          if(inventorySendArray[i].batch_no !="" && inventorySendArray[i].batch_no != undefined){
            this.batchNo_flag = false
          }

          if(inventorySendArray[i].quantity != 0 && inventorySendArray[i].quantity != '' && inventorySendArray[i].quantity != undefined){
            if(inventorySendArray[i].perunit != 0 && inventorySendArray[i].perunit != '' && inventorySendArray[i].perunit != undefined){
              if(this.expiry_flag != true && this.batchNo_flag !=true){
                inventorySendArray[i].quantity = parseFloat(inventorySendArray[i].quantity) * parseFloat(inventorySendArray[i].perunit)
              }
              this.perunitflag = false;
              this.rec_qtyflag=false;
            }else{
              this.perunitflag = true;
            }
          }else{
            this.rec_qtyflag=true;
          }

          if(inventorySendArray[i].cost_price != undefined){
            inventorySendArray[i].cost_price=parseFloat(inventorySendArray[i].cost_price) * parseFloat(inventorySendArray[i].perunit)
          }


          // if(inventorySendArray[i].perunit != 0){
          //   inventorySendArray[i].quantity = inventorySendArray[i].quantity * 
          // }
        }
        console.log("inventorySendArray" + JSON.stringify(this.inventorydetailsexp));

        if (this.expiry_flag == false && this.batchNo_flag == false && this.perunitflag == false && this.rec_qtyflag == false) {
          var podataid = "";
          var send_data;
          if(this.po_required == "1"){
            for (var i = 0; i < this.poorder.length; i++) {
              // Add comma if not the last element
              podataid += this.poorder[i];
              if (i < this.poorder.length - 1) {
                podataid += ",";
              }
            }
            var receivedamount:any;
            receivedamount = this.receivedAmount + this.receivedamoutsupp
            receivedamount = parseFloat(receivedamount).toFixed(2)
            if(this.returnAmount != 0){
              send_data = JSON.stringify({
                invoice_no: this.invoiceNO,
                amount: this.invoiceAmountbe,
                country: ipaddress.country_code,
                balance: Math.abs(this.balance),
                disc_amount:this.disamt,
                gst_amount:this.GSTamount,
                refund_amount:receivedamount,
                mfg_id: this.mgfID,
                supp_id: this.suppID,
                recieved_date: this.recvDate,
                invoice_date: this.invDate,
                product_details: inventorySendArray,
                purchase_order_id: podataid,
              
                product_returns_id:this.product_returns_id,
                po_required:this.po_required,
                pharma_id:this.pharmacyID
              });
            }
            else{
              send_data = JSON.stringify({
                invoice_no: this.invoiceNO,
                amount: this.invoiceAmountbe,
                country: ipaddress.country_code,
                // balance: Math.abs(this.balance),
                disc_amount:this.disamt,
                gst_amount:this.GSTamount,
                // refund_amount:receivedamount,
                mfg_id: this.mgfID,
                supp_id: this.suppID,
                recieved_date: this.recvDate,
                invoice_date: this.invDate,
                product_details: inventorySendArray,
                purchase_order_id: podataid,
                // product_returns_id:this.product_returns_id,
                po_required:this.po_required,
                pharma_id:this.pharmacyID
              });
            }

          }else{
            // var receivedamount = this.receivedAmount + this.receivedamoutsupp
            var receivedamount:any;
            receivedamount = this.receivedAmount + this.receivedamoutsupp
            receivedamount = parseFloat(receivedamount).toFixed(2)
            if(this.returnAmount  != 0){
              send_data = JSON.stringify({
                invoice_no: this.invoiceNO,
                amount: this.invoiceAmountbe,
                balance:Math.abs(this.balance),
                refund_amount:receivedamount,
                disc_amount:this.disamt,
                gst_amount:this.GSTamount,
                country: ipaddress.country_code,
                mfg_id: this.mgfID,
                supp_id: this.suppID,
                recieved_date: this.recvDate,
                invoice_date: this.invDate,
                product_details: inventorySendArray,
                // purchase_order_id: podataid,
                
                product_returns_id:this.product_returns_id,
                po_required:this.po_required,
                pharma_id:this.pharmacyID
              });
            }else{
              send_data = JSON.stringify({
                invoice_no: this.invoiceNO,
                amount: this.invoiceAmountbe,
                // balance:Math.abs(this.balance),
                // refund_amount:receivedamount,
                disc_amount:this.disamt,
                gst_amount:this.GSTamount,
                country: ipaddress.country_code,
                mfg_id: this.mgfID,
                supp_id: this.suppID,
                recieved_date: this.recvDate,
                invoice_date: this.invDate,
                product_details: inventorySendArray,
                 purchase_order_id: podataid,
                // product_returns_id:this.product_returns_id,
                po_required:this.po_required,
                pharma_id:this.pharmacyID
              });
            }
          }

          if(podataid == "" && this.po_required == "1"){
            this.toastr.error("Select purchase order number")
          }else{
            var headers = new Headers();
            headers.append('Content-Type', 'application/json');
            this.http.post(ipaddress.getIp + "inv/upprod/", send_data, { headers: headers }).subscribe(
              data => {
                var obj = JSON.parse(data["_body"]);
                if (obj != undefined && obj.key == "1") {
                  this.toastr.success(Message_data.saveSuccess);
                  // this.manufacturer;
                  // this.poID;
                  // this.invoiceNO
                  // this.invDate
                  // this.gst_no
                  // this.recvDate
                  // this.discount_option
                  // this.messageservice.sendMessage("pharmacycurentinformation");
                  this.messageservice.sendMessage("stock_status")
                }
                else if (obj != undefined && obj.key == "2") {
                  this.inventorydetailsexp = this.inventory
                  var inventorySendArray = this.inventorydetailsexp.filter((x) => { return x.checked == true });
                  // for (var i = 0; i < inventorySendArray.length; i++) {
                  //   if (inventorySendArray[i].expiry_date != undefined) {
                  //     var expdate = inventorySendArray[i].expiry_date.split("-");
                  //     inventorySendArray[i].expiry_date = expdate[1] + "-" + expdate[0] + "-" + "28";
                  //   }
                  // }
                  this.toastr.success(Message_data.invoiceExist);
  
                } else {
                  this.inventorydetailsexp = this.inventory
                  var inventorySendArray = this.inventorydetailsexp.filter((x) => { return x.checked == true });
                  // for (var i = 0; i < inventorySendArray.length; i++) {
                  //   var expdate = inventorySendArray[i].expiry_date.split("-");
                  //   inventorySendArray[i].expiry_date = expdate[1] + "-" + expdate[0] + "-" + "28";
                  // }
                  this.toastr.error(Message_data.unableUpldInventory);
                }
              })
          }
          console.log("send_data" + send_data)
        //
        } else {
          if(this.expiry_flag == true){
            this.toastr.error("Expiry date field is empty");
          }
          if(this.batchNo_flag == true){
            this.toastr.error("Batch no should not be empty");
          }

          if(this.perunitflag == true){
            this.toastr.error("Per unit  should not be empty or Per unit  should not be zero");
          }

          if(this.rec_qtyflag == true){
            this.toastr.error("Received quanity should not be empty or Received quanity should not be zero");
          }

        }
         


      } else {
        this.toastr.error(Message_data.addInventory);
      }

    } else {
      this.toastr.error(Message_data.addMandatory);
    }
  }

  edit(data) {
    this.inventory.splice(data, 1);
  }

  selectLocation() {
    this.locationName = this.locationName;
  }

  clickReceviedDate(date, dataval) {
    dataval.qty_recieved_date1 = (date);
    dataval.qty_recieved_date = Date_Formate(date);
  }

  clearProdName() {
    this.productName = "";
  }

  importFromExcel() {
    var send = { pharma_fac_id: this.locationArray.pharma_facility_id }
    Helper_Class.set_inventory_upload(send);
    this.messageservice.sendMessage("inventory_upload");
  }

  getDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate', { country: ipaddress.country_code },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.currentDate = obj.current_date;
          this.recvDate = this.currentDate;
          this.invDate = this.currentDate;
          var mY = this.currentDate.split("-");
          this.currentMonthYear = mY[0] + "-" + mY[1];
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  addInventory() {
    var index = this.inventory.length + 1;
    console.log("inventrory = " + JSON.stringify(this.inventory))

    this.inventory.push({
      sno: index,
      purchase_request_id: "",
      perunit:"",
      product_id: "",
      hsn_no: "",
      product_name: "",
      uom_code: "",
      uom_desc: "",
      quantity: "",
      unit_price: "",
      pharma_id: this.pharmacyID,
      cgst: "",
      sgst: "",
      location: this.locationName,
      expiry_date: "",//this.currentMonthYear,
      index: index - 1,
      checked: true,
      type: "input",
      delete: false
    });
  }



  batchNo(inventory) {
    var bool: boolean = false;
    for (var i = 0; i < this.inventory.length; i++) {
      if (this.inventory[i].index != inventory.index) {
        if (this.inventory[i].product_id == inventory.product_id) {
          if (this.inventory[i].batch_no == inventory.batch_no) {
            this.toastr.error(Message_data.sameBatchNo);
            bool = true;
          }
        }
      }
      if (bool == true) {
        if (this.inventory[i].index == inventory.index) {
          this.inventory[i].batch_no = "";
        }
      }
    }
  }

  changeExpiryDate(e, data) {
    console.log("ExpiryDate  === > " + e);
    var len = e.length;
    var flag: boolean = false;
    if (len <= 2 && len != 0) {
      e = this.checkValue(e, 12);
      if (e.length > 1)
        flag = true;
    }
    if (len === 2 && flag == true) {
      e += '/';
    }
    data.expiry_date = e;

    if (length > (length = data.expiry_date.length)) return;
    var mapped = data.expiry_date.split("").map(function (this, char, i) {
      switch (i) {
        case 0:
          return char == "1" || char == "0" ? char : "";
          break;
        case 1:
          return !isNaN(parseInt(char, 10)) ? (this[0] == "0" ? char : (+char < 3 ? char : "")) : "";
          break;
        case 2:
          return "-";
          break;
        case 3:
        case 4:
        case 5:
        case 6:
          return !isNaN(parseInt(char, 10)) ? char : "";
          break;
        default:
          return "";
          break;
      }
    }, data.expiry_date);
    if (mapped.length === 2) mapped.push("-");
    data.expiry_date = mapped.join("");
  }

  validateExpiryDate(date, data) {
    var m = [];
    m = date.split("-");
    if (m[0] == "00" || m[0] == "0" || m[0].length < 2) {
      if (m[1] == "0000" || m[1] == "0" || m[1].length < 4) {
        this.toastr.error("Check Expiry year and date");
        data.expiry_date_flag = false;

      } else {
        this.toastr.error("Check Expiry date");
        data.expiry_date_flag = false;
      }

    } else if (m[1] == "0000" || m[1] == "0" || m[1].length < 4) {
      this.toastr.error("Check Expiry year and date");
      data.expiry_date_flag = false;

    } else {
      data.expiry_date_flag = true;
    }
  }

  checkValue(str, max) {
    if (str.charAt(0) !== '0' || str == '00') {
      var num = parseInt(str);
      if (isNaN(num) || num <= 0 || num > max) num = 1;
      str = num > parseInt(max.toString().charAt(0)) && num.toString().length == 1 ? '0' + num : num.toString();
    };
    return str;
  }

  isAllSelected(list, action) {
    list.checked = action == "select" ? true : false;

    console.log("this.inventor" + JSON.stringify(this.inventory))

    //   var inventorySendArray = this.inventory.filter((x) => { return x.checked == true });
    //   for(var i=0;i<inventorySendArray.length;i++) {
    //     var expdate = inventorySendArray[i].expiry_date.split("-");
    //     inventorySendArray[i].expiry_date = expdate[1]+"-"+expdate[0]+"-"+"28";

    // }
    // this.inventorydetailsexp=inventorySendArray
    // console.log(JSON.stringify(this.inventorydetailsexp))
  }

  setSalesPrice(data) {
    for (var i = 0; i < this.inventory.length; i++) {
      if (this.inventory[i].product_id == data.product_id) {
        this.inventory[i].unit_price = data.sales_price;
      }
    }
  }

  setgstdata(data) {
    for (var i = 0; i < this.inventory.length; i++) {
      if (this.inventory[i].product_id == data.product_id) {
        this.inventory[i].sgst = data.cgst;
      }
    }
  }

  invoiceamount(value){
    this.constpriceinwords = convertNumberToWords(value, ipaddress.country_code);
  }

  refundamountcal(){
    console.log("scenario 1= "+this.returnAmount +" "+ this.invoiceAmount)
   
    if(this.returnAmount > this.invoiceAmount){
      this.balance = this.invoiceAmount  - this.returnAmount;
      this.balance = this.spliceDecimal(this.balance)
      if(this.invoiceAmount != 0){
        this.receivedAmount = this.invoiceAmount;
      }
      // this.invoiceAmount = this.receivedamoutsupp + 0;
      this.invoiceAmountbe =  0;
      // this.invoiceAmount= this.invoiceAmount - this.returnAmount;
    }else{
      // this.receivedAmount = this.returnAmount;
      console.log("scenario 2 = "+this.returnAmount +" "+ this.invoiceAmount)
      // this.invoiceAmount= this.invoiceAmount - this.returnAmount
      this.invoiceAmountbe = (this.invoiceAmount  - this.returnAmount)
      // this.invoiceAmount= this.spliceDecimal(this.invoiceAmount)
      this.invoiceAmountbe= this.spliceDecimal(this.invoiceAmountbe)
      this.balance=0;
    }
    console.log("return amount = "+this.returnAmount)
  }



  spliceDecimal(number) {
    // Convert the number to a string
    let numberStr = number.toString();
    // Split the number into integer and decimal parts
    let parts = numberStr.split('.');
    // Check if there's a decimal part
    
    if (parts.length > 1) {
      // Take the first two digits of the decimal part
      let newDecimal = parts[1].slice(0, 2);
      // Combine the integer part with the truncated decimal part
      var newNumberStr = parts[0] + '.' + newDecimal;
      // Convert back to a number
      return parseFloat(newNumberStr);
      
    } else {
      // If there's no decimal part, return the original number
      return number;
    }
    // 
  }

  clearSupplier() {
    this.manufacturer=undefined;
    this.productdata_detal=[];
    this.product_data=[];
    this.poorder=[];
  }
}
