<div class="row" style="margin:0 auto; width:100%">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Consingment</h5>
                    </div>
                    <div class="headerButtons" >
                        <img style="float: right;" src="../../../assets/ui_icons/buttons/save_button.svg"
                            class="saveimgbtn_inpatinfo" (click)="saveData()">
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>

               
                
                <div class="cover_div"  [ngClass]="{'cover-hide': !isCoverVisible, 'cover-show': isCoverVisible}" style="margin-top: 0;">
                   
                    <div class="header_lable">
                        Product details
                    </div>
                    <div class="content_cover">
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel" style="margin-top: 7px;">HSN No
                                    <input type="text" class="ipcss " required [(ngModel)]="hsnNO" [disabled]="newFlag"
                                        maxlength="10" matInput
                                        onkeypress="return (event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122) || (event.charCode >= 48 && event.charCode <= 57)" />
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel" style="margin-top: 7px;">Product name
                                    <input type="text" class="ipcss " [(ngModel)]="productName" matInput />
                                </mat-label>
                            </div>

                            <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel" style="margin-top: 7px;">Short name
                                    <input type="text" class="ipcss " [(ngModel)]="shortName" matInput />
                                </mat-label>
                            </div> -->
                            
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel" style="margin-top: 7px;">Batch no
                                    <input type="text" class="ipcss " [(ngModel)]="batch_no" matInput />
                                </mat-label>
                            </div>
                            


                          
                            <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">Product type<br>
                                    <select disableOptionCentering class="ipcss " [(ngModel)]="productTypeID">
                                        <option disabled>Select</option>
                                        <option *ngFor="let pro_type of productTypeArray" value={{pro_type.med_code}}>
                                            {{pro_type.med_name}}</option>
                                    </select>
                                </mat-label>
                            </div> -->

                            <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">Category type<br>
                                    <select disableOptionCentering class="ipcss " [(ngModel)]="categoryCtgID">
                                        <option disabled>Select</option>
                                        <option *ngFor="let category of categoryTypeArray"
                                            value={{category.category_id}}>
                                            {{category.category_name}}</option>
                                    </select>
                                </mat-label>
                            </div> -->
                            <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">UOM<br>
                                    <select disableOptionCentering class="ipcss" [(ngModel)]="uomCode">
                                        <option disabled>Select</option>
                                        <option *ngFor="let uom of uomListArray" value={{uom.uom_code}}>
                                            {{uom.uom_desc}}
                                        </option>
                                    </select>
                                </mat-label>
                            </div> -->

                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <div class="row">
                                    <div class="col-11">
                                        <mat-label class="matlabel">Supplier / distributor
                                            name<br>
                                            <mat-select class="ipcss " [(ngModel)]="supp_name">
                                                <mat-option *ngFor="let supplier of supplierList" (click)="supplierdatafunction(supplier)" value={{supplier.name}}>
                                                    {{supplier.name}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-label>
                                    </div>
                                    <div class="col-1" style="padding: 0;">
                                        <img class="manuf_add" style="width: 25px;position: relative;top: 24px;left: 0px;" src="../../../assets/img/captcha_refresh.png" (click)="cleararrayconsign('')" class="refresh_btn" alt="clear data">
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">Schedule type<br>
                                    <select disableOptionCentering class="ipcss " [(ngModel)]="scheduleType">
                                        <option disabled>Select</option>
                                        <option *ngFor="let schedule of scheduleList"
                                            value={{schedule.schedule_type_id}}>{{schedule.category}}</option>
                                    </select>
                                </mat-label>
                            </div>-->
                         

                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel" style="margin-top: 7px;">Avaliable quantity
                                            <input type="text" class="ipcss "  matInput [(ngModel)]="avail_qty"
                                                onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                                        </mat-label>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-1 col-xl-1" style="width:10%">
                                <mat-label class="matlabel">MRP
                                    <input type="text" class="ipcss" [(ngModel)]="MRP" required matInput />
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-1 col-xl-1" style="width:10%">
                                <mat-label class="matlabel">Sale price
                                    <input type="text" class="ipcss" [(ngModel)]="s_price" required matInput />
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-1 col-xl-1" style="width:10%">
                                <mat-label class="matlabel">Cost price
                                    <input type="text" class="ipcss" [(ngModel)]="c_price" required matInput />
                                </mat-label>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-1 col-xl-1" style="width:10%">
                                <mat-label class="matlabel">CGST
                                    <input type="text" class="ipcss" [(ngModel)]="cgst" (keyup)='cgstcal(cgst)' required matInput  />
                                </mat-label>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-1 col-xl-1" style="width:10%">
                                <mat-label class="matlabel">SGST
                                    <input type="text" class="ipcss" [(ngModel)]="sgst" (keyup)='gstcal(sgst)' required matInput />
                                </mat-label>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-1 col-xl-1" style="width:10%">
                                <mat-label class="matlabel">GST
                                    <input type="text" class="ipcss" disable [(ngModel)]="gst" matInput />
                                </mat-label>
                            </div>





                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel" style="margin-top: 7px;">Expiry Duration
                                    <input type="date" class="ipcss " [(ngModel)]="expirydura" matInput/>
                                </mat-label>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2" style="margin-bottom: auto;margin-top: auto;">
                                 
                                <div style="position: relative;top: 10px;">
                                    <input type="checkbox" [checked]="returnedaccepted" [(ngModel)]="returnedprod">  Returned
                                </div>

                            </div>

                            <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-1 col-xl-1" style="margin-bottom: auto;margin-top: auto;">
                                <div style="position: relative;top: 10px;">
                                    <input type="checkbox" style="position:relative;top:1px" [checked]="discountaccepted"  [(ngModel)]="look_alike" (change)="look_alikevalue(look_alike)"> Look a like
                                </div>
                            </div> -->
                            <!-- <div class="col-12 col-sm-6 col-md-1 col-lg-3 col-xl-1" style="margin-bottom: auto;margin-top: auto;">
                                <div style="position: relative;top: 10px;">
                                    <input type="checkbox" style="position:relative;top:1px" [checked]="discountaccepted"  [(ngModel)]="sound_alike" (change)="sound_alikevalue(sound_alike)"> Sound a like
                                </div>
                            </div> -->
                            <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-1 col-xl-1" style="margin-bottom: auto;margin-top: auto;">
                                <div style="position: relative;top: 10px;">
                                    <input type="checkbox" style="position:relative;top:1px" [checked]="discountaccepted"  [(ngModel)]="hight_risk" (change)="high_riskvalue(hight_risk)"> High risk
                                </div>
                            </div> -->
                            <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-1 col-xl-1" style="margin-bottom: auto;margin-top: auto;">
                                <div style="position: relative;top: 10px;">
                                    <input type="checkbox" style="position:relative;top:1px" [checked]="discountaccepted"  [(ngModel)]="ward_only" (change)="ward_onlyvalue(ward_only)"> ICU & Ward
                                </div>
                            </div> -->

                            <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <div class="row">

                                    <div class="col-6">




                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12" style="display: flex;
                    flex-direction: row-reverse;
                    padding: 3px;">
                    <img src={{hide_show_btn}}  style="width:27px;margin-right: 5px;" (click)="toggleCover()"/>
                        </div>

                <!-- <button class="saveimgbtn_inpatinfo" (click)="producttableview()" style="width:5%;height:20px"></button> -->
                </div>

                <div class="row" style="margin-top: 10px;" [ngClass]="{'table-expand': !isCoverVisible, 'table-collapse': isCoverVisible}">

                    <!-- <div class="col-12">
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <mat-label class="matlabel">Filter products
                                        <input type="text" class="ipcss"  maxlength="50" matInput />
                                    </mat-label>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    
                    <div class="col-12">
                        <table style="font-size: 13px;" id="product_table" 
                        class="table table-hover table-dynamic">
                        <thead>
                            <tr>
                                <th>Sno</th>
                                <th>HSN no</th>
                                <th>Product name</th>
                                <th>Batch no</th>
                                <th>Supplier</th>
                                <th>Quantity</th>
                                <th>MRP</th>
                                <th>Sales price</th>
                                <th>Cost price</th>
                                <th>CGST</th>
                                <th>SGST</th>
                                <th>GST</th>
                                <th>Expiry</th>
                                <th>Received by</th>
                                <th>Return</th>
                                <th>Edit</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let prod of consing_data">
                               <td>{{prod.sn}}</td>
                               <td>{{prod.hsn_no}}</td>
                               <td>{{prod.consignment_name}}</td>
                               <td>{{prod.batch_no}}</td>
                               <td>{{prod.supp_name}}</td>
                               <td>{{prod.quantity}}</td>
                               <td>{{prod.mrp}}</td>
                               <td>{{prod.sale_price}}</td>
                               <td>{{prod.cost_price}}</td>
                               <td>{{prod.cgst}}</td>
                               <td>{{prod.sgst}}</td>
                               <td>{{prod.gst}}</td>
                               <td>{{prod.expiry_date}}</td>
                               <td>{{prod.received_by}}</td>
                               <td>
                                <input type="checkbox" [(ngModel)]="prod.is_prod_returned">
                               </td>
                               <td>
                                <a (click)="edit_consign_Product(prod)" id="view" style="color: #2c7fe1;"><i class="fas fa-edit"></i></a>
                               </td>
                            </tr>
                        </tbody>
                    </table> 
                    </div>
                    <div  class="col-12">

                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>
