<div mat-dialog-title class="popup_border_icd">
  <h5 class="header_txt">Prescription details</h5>
  <div mat-dialog-actions>
    <img src="../../../assets/img/close_icon.png" (click)="closePopup()" width="20px" height="20px" id="close_icd" />
  </div>
</div>
<div mat-dialog-content class="diag_content">
  <div class="container">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="cover_div" style="padding-bottom: 13px;">
          <div class="header_lable">
            Pharmacy details
          </div>
          <div class="content_cover">
            <div class="row">
              <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                <img src="../../../assets/img/HospitalIcon.png"
                  ng-hide="prescrption_list_data.hosp_logo != null && prescrption_list_data.hosp_logo != 'default'"
                  class="hosp_image_class" />
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-8 col-xl-8" [hidden]="hospitalView">
                <div>
                  <div class="row">
                    <div class="col-12 col-sm-12">
                      <mat-label class="matlabel"><b>{{hospName}}</b></mat-label>
                      <mat-label class="matlabel">{{tot_address}}</mat-label><br>
                      <mat-label class="matlabel">{{hossLocation +", " + hossCitypin}}</mat-label><br>
                      <mat-label class="matlabel">{{stateTXT}}</mat-label><br>
                      <mat-label class="matlabel"> <img src="../../../assets/img/cantact_us.png"
                          style="height: 15px;" /> {{contactNO}}</mat-label>
                          <!-- <br> -->
                    </div>
                  </div>
                  <div>
                    <div class="details1" *ngFor="let pharma of pharcy_details">
                      <mat-label><b>{{pharma.pharmacy_name}}</b></mat-label>
                      <mat-label>{{pharma.phar_addr1}}, {{pharma.phar_addr2}}</mat-label>
                      <mat-label>{{pharma.phar_loc}}</mat-label>
                      <mat-label>{{pharma.phar_city}} - {{pharma.phar_zipcode}}</mat-label>
                      <mat-label>{{pharma.phar_state}}, {{pharma.phar_cnty}}</mat-label>
                      <mat-label>{{pharma.telephone}}</mat-label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="cover_div" style="padding-bottom: 13px;">
          <div class="header_lable">
            Patient details
          </div>
          <div class="content_cover">
            <div class="row">
              <div class="col-12">
                <mat-label><b>Patient :</b> {{totalName}}</mat-label><br>
                <mat-label><b>Diagnosis :</b> <span [innerHTML]="diseaseName"></span> {{disease_describtion}}</mat-label><br>
                <mat-label [hidden]="instr_flag"> <b *ngIf="instructions!= undefined">{{'Complaints'}}: </b>
                  {{instructions}}</mat-label>
                  <!-- <br> -->
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <mat-label><b>Doctor :</b> {{drTotalName}}
                </mat-label><br>
                <mat-label><b>Date : </b>{{datePrescription}}</mat-label>
                <br>
                <br [hidden]="!instr_flag">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12" style="margin-top: 15px;">
        <div class="table-responsive dig_table_overflow">
          <table id="card_tbl" class="table table-hover table-dynamic">
            <thead>
              <tr>
                <th>Medicine Name</th>
                <th>Dosage</th>
                <th>Duration</th>
                <!-- <th>Intake</th> -->
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let medicene of listProducts;  let i=index">
                <!-- <td>{{medicene.medicene_name}}</td>
                <td>{{medicene.medicene_dosage}}</td>
                <td>{{medicene.medicene_intakedays}}</td> -->
                <td class="align_left">{{medicene.drug_name}}</td>
                <td class="align_left">{{medicene.every_six}}</td>
                <td>{{medicene.days}} {{medicene.period}}</td>	
                <!-- <td>{{medicene.dure_txt_table}} {{medicene.drug_intake }}</td> -->
                <!-- <td>{{medicene.days}} {{medicene.day_dur}}</td>
                <td>{{medicene.dure_txt_table}} {{medicene.drug_intake }}</td> -->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions align="end" style="width: auto;right: 19px;">
</div>