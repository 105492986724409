import { Component, OnInit, ViewChild, Input, ElementRef } from '@angular/core';
import {Helper_Class} from '../../helper_class';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DoctorViewService } from '../doctor-view/doctor-view.service';
declare var $ : any; 
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { Doc_Helper } from '../Doc_Helper';
import { ipaddress } from '../../ipaddress' ;import { Message_data } from 'src/assets/js/Message_data';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';

@Component({
  selector: 'app-cardio-casesheet-view',
  templateUrl: './cardio-casesheet-view.component.html',
  styleUrls: ['./cardio-casesheet-view.component.scss']
})
export class CardioCasesheetViewComponent implements OnInit {
  @ViewChild('printnoheader') printnoheader: ElementRef;

  @ViewChild('casesheetsummary') casesheetsummary: ElementRef;
  //cardio //ent //general //others
  public patient_id: string;
  public patient_name: string;
  public gender: string;
  public ageflag:boolean;
  public patient_age;
  public age: string;
  public current_occup: string;
  public doctor_name: string;
  public doc_qualif: string;
  public height: string;
  public weight: string;
  public bmi: string;
  public body_nature: string;
  public body_type: string;
  public main_concerns: string;
  public med_hist: string;
  public bp_right: string;
  public bp_left: string;
  public surgery: string;
  public surgery_proc: string;

  public mainarray=[];
  public medhist_array=[];
  public druglist=[];
  public patient_gender;
  public genderflag:boolean;
  public header_footer_flag: boolean;
  public medhist_flag: boolean;
  public complaints_flag:boolean;
  public complaints;
  public mainconc_flag: boolean;
  public bp_flag: boolean;
  public surgery_flag: boolean;

  public hosp_name: string;
  public hosp_addr: string;
  public hosp_csz: string;
  public hosp_tel: string;
  public hosp_web: string;
  public hosp_web_flag: boolean;
  public hosp_count: string;
  public curr_date: string;
  public symptoms: string;
  public disease: string;
  public diet_habits: string;
  public stress_factor: string;
  public heart_sound: string;
  public pain_location: string;
  public pain_character: string;
  public pain_radiation: string;
  public pain_duration: string;
  public pain_aggravat: string;
  public pain_relieve: string;
  public pain_intensity: string;
  public pain_cause:string;
  public breath_trigers: string;
  public breath_onset: string;
  public breath_cold_sweat: string;
  public breath_diziness: string;
  public breath_vomiting: string;
  public breath_palpitation: string;
  public breath_palp_type: string;
  public breath_cough: string;
  public breath_mucus: string;
  public med_flag;
  public getCasesheetData;
  public personalList;
  public userinfo;
  public spl_name;
  //diab
  public diab_type: string;
  public cardiodis: string;
  public mobility: string;
  public neprhodis: string;
  public neurodis: string;
  public retinodis: string;
  public skindis: string;
  public hearingdis: string;
  public footdis: string;
  public comorb_flag: boolean;
  public cardio_flag: boolean;
  public mobility_flag: boolean;
  public nephro_flag: boolean;
  public neuro_flag: boolean;
  public retino_flag: boolean;
  public skin_flag: boolean;
  public hearing_flag: boolean;
  public foot_flag: boolean;
  public rec_excercise: string;
  public rec_dur: string;
  public rec_freq: string;

  //gyno
  public marital_status: string;
  public visit_purpose: string;
  public mens_stage: string;
  public mens_periods: string;
  public last_mens_period: string;
  public mens_cycle_length: string;
  public mens_pain: string;
  public mens_pain_freq: string;
  public mens_pain_time: string;
  public mens_pain_char: string;

  //others
  public others:boolean = false;

  //ped
  public bp: string;
  public maternal_illness=[];
  public materill_flag: boolean;
  public ped_complic: string;
  public mother_age: string;
  public temparature: string;
  public delivery_type: string;
  public gestational_age: string;
  public allergies: string;
  public feeding_habit: string;
  
  constructor(public doctorviewservice:MenuViewService,public http:HttpClient,public routes:ActivatedRoute,public router:Router,public toastr:ToastrService) {
    this.medhist_flag=true;
    this.mainconc_flag=true;
    this.bp_flag=true;

    //diab //ent
    this.cardio_flag = true;
    this.mobility_flag = true;
    this.nephro_flag = true;
    this.neuro_flag = true;
    this.retino_flag = true;
    this.skin_flag = true;
    this.hearing_flag = true;
    this.foot_flag = true;
    this.comorb_flag = true;

    //ped
    this.materill_flag=true;
  }

  ngOnInit(): void {
    this.personalList = Doc_Helper.getClient_Info();
    this.userinfo = Helper_Class.getInfo(); 
    this.doctor_name = this.userinfo.middle_name != undefined ? this.userinfo.first_name + " " + this.userinfo.middle_name + " " + this.userinfo.last_name : this.userinfo.first_name + " " + this.userinfo.last_name; 
    this.doc_qualif = this.userinfo.qualifications;
    this.getCasesheetDetails();
  }

  getCasesheetDetails() {
    var send_data;
    var subrelid;
    this.getCasesheetData = Doc_Helper.getViewCasesheet();
    this.spl_name = this.getCasesheetData.casesheet;
    this.patient_name = this.getCasesheetData.name;
    this.others = false;

    if(this.getCasesheetData.sub_rel_id != null){
      subrelid = this.getCasesheetData.sub_rel_id;
    }
    var mofdate;
    if(this.getCasesheetData.modified != null){
      mofdate = this.getCasesheetData.modified;
    }

    var cdate;
    if(this.getCasesheetData.date != null){
      cdate = this.getCasesheetData.date;
    }

    this.curr_date = cdate;
    if(this.getCasesheetData.gender != null){
      this.gender = this.getCasesheetData.gender;
    }
  
    if (this.personalList.Age_data != "") {
      this.ageflag = true;
      this.patient_age = this.personalList.Age_data;
    }

    if (this.personalList.Gender_data != "") {
      this.genderflag = true;
      var gender = this.personalList.Gender_data;
      if (gender == "Male") {
        this.patient_gender = "M"
      } else if (gender == "Female") {
        this.patient_gender = "F"
      } else {
        this.patient_gender = "T"
      }

    }
    if (this.personalList.doctor_name != "") {
      this.doctor_name ="Dr"+" "+this.personalList.doctor_name;

    }
    var ctime;
    if(this.getCasesheetData.created_time != null){
      ctime = this.getCasesheetData.created_time;
    }

    var casesheet;
    if(this.getCasesheetData.case_hist_id != null){
      casesheet = this.getCasesheetData.case_hist_id;
    } 

    if (mofdate != "0") {
      send_data={
        doc_reg_id:this.personalList.user_id,
        client_reg_id: this.personalList.Client_id,
        case_hist_id: casesheet,
        relation_id: this.personalList.rel_id,
        sub_rel_id: subrelid,
        created_date: cdate,
        country: ipaddress.country_code
      }
    } else {
      send_data={
        doc_reg_id:this.personalList.user_id,
        client_reg_id: this.personalList.Client_id,
        case_hist_id: casesheet,
        relation_id: this.personalList.rel_id,
        sub_rel_id: subrelid,
        created_date: cdate.split('-')[2] + "-" + cdate.split('-')[1] + "-" + cdate.split('-')[0],
        created_time: ctime,
        country: ipaddress.country_code
      }
    }
    console.log("this.getCasesheetData.casesheet-"+JSON.stringify(this.getCasesheetData))

    if(this.getCasesheetData.casesheet == "Cardiology"){
      var chisturl=ipaddress.getIp.toString() + 'cardio/chist';
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(chisturl, send_data,
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            console.log("main comncerns --"+JSON.stringify(obj))
            this.patient_id = this.checkData(obj.pat_id);
            this.gender = obj.gender != undefined && encrypt_decript.Decript(obj.gender) != "" ? encrypt_decript.Decript(obj.gender) : "";
            this.current_occup = obj.current_occupation != undefined && encrypt_decript.Decript(obj.current_occupation) != "" ? encrypt_decript.Decript(obj.current_occupation) : "";           
            this.disease = this.checkData(obj.disease);
            this.symptoms = this.checkData(obj.symptoms);
            this.stress_factor = this.checkData(obj.stress_factor);     
            this.age = this.checkData(obj.age);
            this.height = obj.height != undefined && obj.height != null && obj.height != "" ?  obj.height+" "+ obj.height_measure : "";
            this.weight = obj.weight != undefined && obj.weight != null && obj.weight != "" ? obj.weight+" "+obj.weight_measure : "";            
            this.bmi = this.checkData(obj.bmi);
            this.body_nature = this.checkData(obj.body_nature);
            this.body_type = this.checkData(obj.body_type);
            this.diet_habits = this.checkData(obj.diet_habits);
            this.heart_sound = this.checkData(obj.hsound);
            this.pain_location = this.checkData(obj.pain_location);           
            this.pain_character = this.checkData(obj.pain_character);
            this.pain_radiation = this.checkData(obj.pain_radiation);
            this.pain_duration = this.checkData(obj.pain_duration);
            this.pain_aggravat = this.checkData(obj.pain_aggr_factor);
            this.pain_relieve = this.checkData(obj.pain_rel_factor);
            this.pain_intensity = this.checkData(obj.pain_intensity);
            this.pain_cause = this.checkData(obj.pain_cause);
            this.breath_trigers = this.checkData(obj.breath_triggers);
            this.breath_onset = this.checkData(obj.breath_onset);
            this.breath_cold_sweat = this.checkData(obj.breath_cold_sweat);
            this.breath_diziness = this.checkData(obj.breath_dizziness);
            this.breath_vomiting = this.checkData(obj.breath_vomiting);
            this.breath_palpitation = this.checkData(obj.breath_palpitation);
            this.breath_palp_type = this.checkData(obj.breath_palp_type);
            this.breath_cough = this.checkData(obj.breath_cough);
            this.breath_mucus = this.checkData(obj.breath_mucus);

            if(obj.complaints != undefined){
              // for(var i=0;i<obj.main_concerns.length;i++){
              //   this.mainarray.push(obj.main_concerns[i].description);
              // }
              this.complaints=this.checkData(obj.complaints);
              this.complaints_flag=false;
            }
            if(obj.past_illness != undefined ){
              // for(var i=0;i<obj.main_concerns.length;i++){
              //   this.mainarray.push(obj.main_concerns[i].description);
              // }
              this.mainconc_flag=false;
              this.main_concerns=this.checkData(obj.past_illness);
             // this.main_concerns=obj.past_illness;
             
              console.log("this.main_concerns"+this.main_concerns)
            }
            if(obj.present_illness != undefined){
              // for(var i=0;i<obj.main_concerns.length;i++){
              //   this.mainarray.push(obj.main_concerns[i].description);
              // }
              this.med_hist=this.checkData(obj.past_illness);
            //  this.med_hist=obj.present_illness;
              this.medhist_flag=false;
            }
                      
            // if(obj.medical_history != undefined && obj.medical_history != null && obj.medical_history != ""){
            //   for(var i=0;i<obj.medical_history.length;i++){
            //     this.medhist_array.push(obj.medical_history[i].description);
            //   }
            //   this.med_hist = this.medhist_array.toString();
            //   this.medhist_flag=false;
            // }

            if(obj.bp_right_arm_dia != undefined && obj.bp_right_arm_dia != null && obj.bp_right_arm_dia != ""){
              this.bp_flag = false;
              this.bp_right = obj.bp_right_arm_sys + "/" + obj.bp_right_arm_dia;
              this.bp_left = obj.bp_left_arm_sys + "/" + obj.bp_left_arm_dia;
            }else{
              this.bp_flag = true;
              this.bp_right = "";
              this.bp_left = "";
            }

            this.disease = this.checkData(obj.cardio_disease);
            this.symptoms = this.checkData(obj.symptoms);

            if(obj.drug_list != undefined){
              for(var i=0;i<obj.drug_list.length;i++){
                var master_tablet_data = obj.drug_list[i];
                var medicine_dosage_data = "";
                if (master_tablet_data.intake_desc == "Every 6 hours") {
                  medicine_dosage_data = master_tablet_data.morning +master_tablet_data.short_form+ '-' + master_tablet_data.morning +master_tablet_data.short_form+ '-' + master_tablet_data.morning+master_tablet_data.short_form+master_tablet_data.short_form + '-' + master_tablet_data.morning+master_tablet_data.short_form;
                } else {
                  medicine_dosage_data = master_tablet_data.morning +master_tablet_data.short_form+ '-' + master_tablet_data.afternoon +master_tablet_data.short_form+ '-' + master_tablet_data.evening+master_tablet_data.short_form;
                }

                var master_timeDuration = "";
                if (master_tablet_data.drug_intake == "With food") {
                  master_timeDuration = master_tablet_data.drug_intake;
                } else {
                  if (master_tablet_data.time_duration != null && master_tablet_data.time_duration != "0") {
                    master_timeDuration = master_tablet_data.time_duration + ' mins ' + master_tablet_data.drug_intake;
                  } else {
                    master_timeDuration = master_tablet_data.drug_intake;
                  }
                }

                this.druglist.push({
                  medicine_name: master_tablet_data.short_name+" "+master_tablet_data.drug_name,
                  medicine_dosage: medicine_dosage_data,
                  medicine_intakedays: master_tablet_data.days + ' days ',
                  medicine_food: master_timeDuration
                });               
              }
            }

            this.hosp_name = this.checkData(obj.hptl_name);
            var addval = obj.address2 != undefined && obj.address2 != null && obj.address2 != "" ? obj.address1 + "," + obj.address2 : obj.address1;
            this.hosp_addr = addval;
            this.hosp_csz = this.checkData(obj.city) + ", " + this.checkData(obj.state) + " - " + this.checkData(obj.zipcode) + "  " + this.checkData(obj.country);
            this.hosp_count = this.checkData(obj.country);
            this.hosp_tel = obj.telephone != undefined && obj.telephone != null && obj.telephone != "" ? obj.telephone : "-";
          
            if(obj.website != undefined){
              this.hosp_web = obj.website;
              this.hosp_web_flag=false;
            } else {
              this.hosp_web = "";
              this.hosp_web_flag= true;
            }
          },
          error => {
            this.toastr.error(Message_data.unableToFetchCasesheet);
          }
        );
    } else if(this.getCasesheetData.casesheet == "Gastroenterology"){
      var chisturl=ipaddress.getIp.toString() + 'gast/chist';
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(chisturl, send_data,
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            console.log("GASTRO CASE HIST "+JSON.stringify(obj))
            this.patient_id = this.checkData(obj.pat_id);
            this.gender = obj.gender != undefined && obj.gender != "" ? obj.gender : "";
            this.current_occup = obj.current_occupation != undefined && encrypt_decript.Decript(obj.current_occupation) != "" ? encrypt_decript.Decript(obj.current_occupation) : "";           
            this.disease = this.checkData(obj.disease);
            this.symptoms = this.checkData(obj.symptoms);
            this.stress_factor = this.checkData(obj.stress_factor);     
            this.age = this.checkData(obj.gastro_age);
            this.height = obj.height != undefined && obj.height != null && obj.height != "" ?  obj.height+" "+ obj.height_measure : "";
            this.weight = obj.weight != undefined && obj.weight != null && obj.weight != "" ? obj.weight+" "+obj.weight_measure : "";            
            this.bmi = this.checkData(obj.bmi);
            this.body_nature = this.checkData(obj.body_nature);
            this.body_type = this.checkData(obj.body_type);
            this.main_concerns = this.checkData(obj.main_concern);
            this.diet_habits = this.checkData(obj.diet_habits);
            this.pain_location = this.checkData(obj.chest_pain_loc);           
            this.pain_character = this.checkData(obj.chest_pain_char);
            this.pain_radiation = this.checkData(obj.chest_pain_rad);
            this.pain_duration = this.checkData(obj.chest_pain_dur);
            this.pain_aggravat = this.checkData(obj.chest_pain_aggr);
            this.pain_relieve = this.checkData(obj.chest_pain_reliev);
            this.pain_intensity = this.checkData(obj.chest_pain_intense);
            this.pain_cause = this.checkData(obj.chest_pain_cause);
            

            if(obj.bp_right_arm_dia != undefined && obj.bp_right_arm_dia != null && obj.bp_right_arm_dia != ""){
              this.bp_flag = false;
              this.bp_right = obj.bp_right_arm_sys + "/" + obj.bp_right_arm_dia;
              this.bp_left = obj.bp_left_arm_sys + "/" + obj.bp_left_arm_dia;
            }else{
              this.bp_flag = true;
              this.bp_right = "";
              this.bp_left = "";
            }

            this.disease = this.checkData(obj.disease);
            this.symptoms = this.checkData(obj.symptoms);

            if(obj.drug_list != undefined){
              for(var i=0;i<obj.drug_list.length;i++){
                var master_tablet_data = obj.drug_list[i];
                var medicine_dosage_data = "";
                if (master_tablet_data.intake_desc == "Every 6 hours") {
                  medicine_dosage_data = master_tablet_data.morning +master_tablet_data.short_form+ '-' + master_tablet_data.morning +master_tablet_data.short_form+ '-' + master_tablet_data.morning+master_tablet_data.short_form+master_tablet_data.short_form + '-' + master_tablet_data.morning+master_tablet_data.short_form;
                } else {
                  medicine_dosage_data = master_tablet_data.morning +master_tablet_data.short_form+ '-' + master_tablet_data.afternoon +master_tablet_data.short_form+ '-' + master_tablet_data.evening+master_tablet_data.short_form;
                }

                var master_timeDuration = "";
                if (master_tablet_data.drug_intake == "With food") {
                  master_timeDuration = master_tablet_data.drug_intake;
                } else {
                  if (master_tablet_data.time_duration != null && master_tablet_data.time_duration != "0") {
                    master_timeDuration = master_tablet_data.time_duration + ' mins ' + master_tablet_data.drug_intake;
                  } else {
                    master_timeDuration = master_tablet_data.drug_intake;
                  }
                }

                this.druglist.push({
                  medicine_name: master_tablet_data.short_name+" "+master_tablet_data.drug_name,
                  medicine_dosage: medicine_dosage_data,
                  medicine_intakedays: master_tablet_data.days + ' days ',
                  medicine_food: master_timeDuration
                });               
              }
            }

            this.hosp_name = this.checkData(obj.hptl_name);
            var addval = obj.address2 != undefined && obj.address2 != null && obj.address2 != "" ? obj.address1 + "," + obj.address2 : obj.address1;
            this.hosp_addr = addval;
            this.hosp_csz = this.checkData(obj.city) + ", " + this.checkData(obj.state) + " - " + this.checkData(obj.zipcode) + "  " + this.checkData(obj.country);
            this.hosp_count = this.checkData(obj.country);
            this.hosp_tel = obj.telephone != undefined && obj.telephone != null && obj.telephone != "" ? obj.telephone : "-";
          
            if(obj.website != undefined){
              this.hosp_web = obj.website;
              this.hosp_web_flag=false;
            } else {
              this.hosp_web = "";
              this.hosp_web_flag= true;
            }
          },
          error => {
            this.toastr.error(Message_data.unableToFetchCasesheet);
          }
        );
    } else if(this.getCasesheetData.casesheet == "Diabetes"){
      var chisturl=ipaddress.getIp.toString() + 'diab/chist';
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(chisturl, send_data,
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            
            this.patient_id = this.checkData(obj.pat_id);
            this.gender = obj.gender != undefined && encrypt_decript.Decript(obj.gender) != "" ? encrypt_decript.Decript(obj.gender) : "";
            this.current_occup = obj.current_occupation != undefined && encrypt_decript.Decript(obj.current_occupation) != "" ? encrypt_decript.Decript(obj.current_occupation) : "";
            this.disease = this.checkData(obj.disease);
            this.symptoms = this.checkData(obj.symptoms);
            this.stress_factor = this.checkData(obj.stress_factor);    
            this.age = this.checkData(obj.age);
            this.height = obj.height != undefined && obj.height != null && obj.height != "" ?  obj.height+" "+ obj.height_measure : "";
            this.weight = obj.weight != undefined && obj.weight != null && obj.weight != "" ? obj.weight+" "+obj.weight_measure : "";            
            this.bmi = this.checkData(obj.bmi);
            this.body_nature = this.checkData(obj.body_nature);
            this.body_type = this.checkData(obj.body_type);
            this.diab_type = this.checkData(obj.dis_desc);
            this.rec_excercise = this.checkData(obj.exerc_name);
            this.rec_dur = this.checkData(obj.recom_exec_dur);
            this.rec_freq = this.checkData(obj.recom_exec_freq);
            
            if(obj.main_concerns != undefined){
              this.arrayToString(obj.main_concerns,"main_concern"); 
              this.mainconc_flag=false;
            }
  
            if(obj.medical_history != undefined){
              this.arrayToString(obj.medical_history,"medical_history"); 
              this.medhist_flag=false;
            }
  
            if(obj.cardio_dis != undefined && obj.cardio_dis.length !=0){
              this.arrayToString(obj.cardio_dis,"cardio"); 
              this.cardio_flag=false;
              this.comorb_flag = false;
            }
  
            if(obj.mobility != undefined && obj.mobility.length !=0){
              this.arrayToString(obj.mobility,"mobility"); 
              this.mobility_flag=false;
              this.comorb_flag = false;
            }
  
            if(obj.neprho_dis != undefined && obj.neprho_dis.length !=0){
              this.arrayToString(obj.neprho_dis,"nephro"); 
              this.nephro_flag=false;
              this.comorb_flag = false;
            }
  
            if(obj.neuro_dis != undefined && obj.neuro_dis.length !=0){
              this.arrayToString(obj.neuro_dis,"neuro"); 
              this.neuro_flag=false;
              this.comorb_flag = false;
            }
  
            if(obj.retino_dis != undefined && obj.retino_dis.length !=0){
              this.arrayToString(obj.retino_dis,"retino"); 
              this.retino_flag=false;
              this.comorb_flag = false;
            }
  
             if(obj.skin_dis != undefined && obj.skin_dis.length !=0){
              this.arrayToString(obj.skin_dis,"skin"); 
              this.skin_flag=false;
              this.comorb_flag = false;
            }
  
            if(obj.hearing_dis != undefined && obj.hearing_dis.length !=0){
              this.arrayToString(obj.hearing_dis,"hearing"); 
              this.hearing_flag=false;
              this.comorb_flag = false;
            }
  
            if(obj.foot_dis != undefined && obj.foot_dis.length !=0){
              this.arrayToString(obj.foot_dis,"foot"); 
              this.foot_flag=false;
              this.comorb_flag = false;
            }
  
            if(obj.bp_right_arm_dia != undefined && obj.bp_right_arm_dia != null && obj.bp_right_arm_dia != ""){
              this.bp_flag = false;
              this.bp_right = obj.bp_right_arm_sys+"/"+obj.bp_right_arm_dia;
              this.bp_left = obj.bp_left_arm_sys+"/"+obj.bp_left_arm_dia;
            }else{
              this.bp_flag = true;
              this.bp_right = "";
              this.bp_left = "";
            }
  
            if(obj.drug_list != undefined){
              for(var i=0;i<obj.drug_list.length;i++){
                var master_tablet_data = obj.drug_list[i];
                var medicine_dosage_data = "";
  
                if (master_tablet_data.intake_desc == "Every 6 hours") {
                  medicine_dosage_data = master_tablet_data.morning +master_tablet_data.short_form+ '-' + master_tablet_data.morning +master_tablet_data.short_form+ '-' + master_tablet_data.morning+master_tablet_data.short_form+master_tablet_data.short_form + '-' + master_tablet_data.morning+master_tablet_data.short_form;
                } else {
                  medicine_dosage_data = master_tablet_data.morning +master_tablet_data.short_form+ '-' + master_tablet_data.afternoon +master_tablet_data.short_form+ '-' + master_tablet_data.evening+master_tablet_data.short_form;
                }
  
                var master_timeDuration = "";
                if (master_tablet_data.drug_intake == "With food") {
                  master_timeDuration = master_tablet_data.drug_intake;
                } else {
                  if (master_tablet_data.time_duration != null && master_tablet_data.time_duration != "0") {
                    master_timeDuration = master_tablet_data.time_duration + ' mins ' + master_tablet_data.drug_intake;
                  } else {
                    master_timeDuration = master_tablet_data.drug_intake;
                  }
                }
  
                this.druglist.push({
                  medicine_name: master_tablet_data.short_name+" "+master_tablet_data.drug_name,
                  medicine_dosage: medicine_dosage_data,
                  medicine_intakedays: master_tablet_data.days + ' days ',
                  medicine_food: master_timeDuration
                });               
              }
            }
  
            this.hosp_name = this.checkData(obj.hptl_name);
            var addval = obj.address2 != undefined && obj.address2 != null && obj.address2 != "" ? obj.address1 + "," + obj.address2 : obj.address1;
            this.hosp_addr = addval;
            this.hosp_csz = this.checkData(obj.city) + ", " + this.checkData(obj.state) + " - " + this.checkData(obj.zipcode) + "  " + this.checkData(obj.country);
            this.hosp_count = this.checkData(obj.country);
            this.hosp_tel = obj.telephone != undefined && obj.telephone != null && obj.telephone != "" ? obj.telephone : "-";
                  
            if(obj.website != undefined && obj.website != null && obj.website != ""){
              this.hosp_web = obj.website;
              this.hosp_web_flag=false;
            } else {
              this.hosp_web = "";
              this.hosp_web_flag= true;
            }
          },
          error => {
            this.toastr.error(Message_data.unableToFetchCasesheet);
          }
        )
    } else if(this.getCasesheetData.casesheet == "ENT"){
      var chisturl=ipaddress.getIp.toString() + 'ent/chist';
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(chisturl, send_data,
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            
            this.patient_id = this.checkData(obj.pat_id);
            this.gender = obj.gender != undefined && encrypt_decript.Decript(obj.gender) != "" ? encrypt_decript.Decript(obj.gender) : "";
            this.current_occup = obj.current_occupation != undefined && encrypt_decript.Decript(obj.current_occupation) != "" ? encrypt_decript.Decript(obj.current_occupation) : "";                       
            this.disease = this.checkData(obj.disease);
            this.symptoms = this.checkData(obj.symptoms);
            this.stress_factor = this.checkData(obj.stress_factor);     
            this.age = this.checkData(obj.age);
            this.height = obj.height != undefined && obj.height != null && obj.height != "" ?  obj.height+" "+ obj.height_measure : "";
            this.weight = obj.weight != undefined && obj.weight != null && obj.weight != "" ? obj.weight+" "+obj.weight_measure : "";            
            this.bmi = this.checkData(obj.bmi);
            this.body_nature = this.checkData(obj.body_nature);
            this.body_type = this.checkData(obj.body_type);
            this.diab_type = this.checkData(obj.dis_desc);
            this.rec_excercise = this.checkData(obj.exerc_name);
            this.rec_dur = this.checkData(obj.recom_exec_dur);
            this.rec_freq = this.checkData(obj.recom_exec_freq);
            
            if(obj.main_concerns != undefined){
             //this.main_concerns =obj.main_concerns;
              this.arrayToString(obj.main_concerns,"main_concern"); 
              this.mainconc_flag=false;
            }
  
            if(obj.medical_history != undefined){
              this.arrayToString(obj.medical_history,"medical_history"); 
              this.medhist_flag=false;
            }
  
            if(obj.cardio_dis != undefined && obj.cardio_dis.length !=0){
              this.arrayToString(obj.cardio_dis,"cardio"); 
              this.cardio_flag=false;
              this.comorb_flag = false;
            }
  
            if(obj.mobility != undefined && obj.mobility.length !=0){
              this.arrayToString(obj.mobility,"mobility"); 
              this.mobility_flag=false;
              this.comorb_flag = false;
            }
  
            if(obj.neprho_dis != undefined && obj.neprho_dis.length !=0){
              this.arrayToString(obj.neprho_dis,"nephro"); 
              this.nephro_flag=false;
              this.comorb_flag = false;
            }
  
            if(obj.neuro_dis != undefined && obj.neuro_dis.length !=0){
              this.arrayToString(obj.neuro_dis,"neuro"); 
              this.neuro_flag=false;
              this.comorb_flag = false;
            }
  
            if(obj.retino_dis != undefined && obj.retino_dis.length !=0){
              this.arrayToString(obj.retino_dis,"retino"); 
              this.retino_flag=false;
              this.comorb_flag = false;
            }
  
             if(obj.skin_dis != undefined && obj.skin_dis.length !=0){
              this.arrayToString(obj.skin_dis,"skin"); 
              this.skin_flag=false;
              this.comorb_flag = false;
            }
  
            if(obj.hearing_dis != undefined && obj.hearing_dis.length !=0){
              this.arrayToString(obj.hearing_dis,"hearing"); 
              this.hearing_flag=false;
              this.comorb_flag = false;
            }
  
            if(obj.foot_dis != undefined && obj.foot_dis.length !=0){
              this.arrayToString(obj.foot_dis,"foot"); 
              this.foot_flag=false;
              this.comorb_flag = false;
            }
  
            if(obj.bp_right_arm_dia != undefined && obj.bp_right_arm_dia != null && obj.bp_right_arm_dia != ""){
              this.bp_flag = false;
              this.bp_right = obj.bp_right_arm_sys+"/"+obj.bp_right_arm_dia;
              this.bp_left = obj.bp_left_arm_sys+"/"+obj.bp_left_arm_dia;
            }else{
              this.bp_flag = true;
              this.bp_right = "";
              this.bp_left = "";
            }
  
            if(obj.drug_list != undefined){
              for(var i=0;i<obj.drug_list.length;i++){
                var master_tablet_data = obj.drug_list[i];
                var medicine_dosage_data = "";
  
                if (master_tablet_data.intake_desc == "Every 6 hours") {
                  medicine_dosage_data = master_tablet_data.morning +master_tablet_data.short_form+ '-' + master_tablet_data.morning +master_tablet_data.short_form+ '-' + master_tablet_data.morning+master_tablet_data.short_form+master_tablet_data.short_form + '-' + master_tablet_data.morning+master_tablet_data.short_form;
                } else {
                  medicine_dosage_data = master_tablet_data.morning +master_tablet_data.short_form+ '-' + master_tablet_data.afternoon +master_tablet_data.short_form+ '-' + master_tablet_data.evening+master_tablet_data.short_form;
                }
  
                var master_timeDuration = "";
                if (master_tablet_data.drug_intake == "With food") {
                  master_timeDuration = master_tablet_data.drug_intake;
                } else {
                  if (master_tablet_data.time_duration != null && master_tablet_data.time_duration != "0") {
                    master_timeDuration = master_tablet_data.time_duration + ' mins ' + master_tablet_data.drug_intake;
                  } else {
                    master_timeDuration = master_tablet_data.drug_intake;
                  }
                }
  
                this.druglist.push({
                  medicine_name: master_tablet_data.short_name+" "+master_tablet_data.drug_name,
                  medicine_dosage: medicine_dosage_data,
                  medicine_intakedays: master_tablet_data.days + ' days ',
                  medicine_food: master_timeDuration
                })               
              }
            }
  
            this.hosp_name = this.checkData(obj.hptl_name);
            var addval = obj.address2 != undefined && obj.address2 != null && obj.address2 != "" ? obj.address1 + "," + obj.address2 : obj.address1;
            this.hosp_addr = addval;
            this.hosp_csz = this.checkData(obj.city) + ", " + this.checkData(obj.state) + " - " + this.checkData(obj.zipcode) + "  " + this.checkData(obj.country);
            this.hosp_count = this.checkData(obj.country);
            this.hosp_tel = obj.telephone != undefined && obj.telephone != null && obj.telephone != "" ? obj.telephone : "-";
            
            if(obj.website != undefined){
              this.hosp_web = obj.website;
              this.hosp_web_flag = false;
            } else {
              this.hosp_web = "";
              this.hosp_web_flag= true;
            }
          },
          error => {
            this.toastr.error(Message_data.unableToFetchCasesheet);
          }
        )
    } else if(this.getCasesheetData.casesheet == "General" || this.getCasesheetData.casesheet == "General surgery"){
      var chisturl=ipaddress.getIp.toString() + 'gen/chist';

      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(chisturl, send_data,
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            
            this.patient_id = this.checkData(obj.pat_id);
            this.gender = obj.gender != undefined && encrypt_decript.Decript(obj.gender) != "" ? encrypt_decript.Decript(obj.gender) : "";
            this.current_occup = obj.current_occupation != undefined && encrypt_decript.Decript(obj.current_occupation) != "" ? encrypt_decript.Decript(obj.current_occupation) : "";                 
            this.age = this.checkData(obj.age);
            this.height = obj.height != undefined && obj.height != null && obj.height != "" ?  obj.height+" "+ obj.height_measure : "";
            this.weight = obj.weight != undefined && obj.weight != null && obj.weight != "" ? obj.weight+" "+obj.weight_measure : "";      
            this.bmi = this.checkData(obj.bmi);
            this.body_nature = this.checkData(obj.body_nature);
            this.body_type = this.checkData(obj.body_type);
            this.disease = this.checkData(obj.disease);
            this.symptoms = this.checkData(obj.symptoms);
            this.stress_factor = this.checkData(obj.stress_factor);     
            
            if(obj.complaints != undefined){
              this.complaints =obj.complaints;
              this.complaints_flag=false;
            }
  
            if(obj.past_illness != undefined){
              this.med_hist=obj.past_illness;
              this.medhist_flag=false;
            }
              if(obj.present_illness != undefined){
              this.main_concerns=obj.present_illness;
              this.medhist_flag=false;
            }


            if(obj.bp_right_arm_dia != undefined && obj.bp_right_arm_dia != null && obj.bp_right_arm_dia != ""){
              this.bp_flag = false;
              this.bp_right = obj.bp_right_arm_sys+"/"+obj.bp_right_arm_dia;
              this.bp_left = obj.bp_left_arm_sys+"/"+obj.bp_left_arm_dia;
            }else{
              this.bp_flag = true;
              this.bp_right = "";
              this.bp_left = "";
            }
  
            if(obj.surgery != undefined && obj.surgery != null && obj.surgery != ""){
              this.surgery_flag = false;
              this.surgery = obj.surgery_desc;
              this.surgery_proc = obj.surg_proc;
            }else{
              this.surgery_flag = true;
              this.surgery = "";
              this.surgery_proc = "";
            }
  
            if(obj.drug_list != undefined){
              for(var i=0;i<obj.drug_list.length;i++){
                var master_tablet_data = obj.drug_list[i];
                var medicine_dosage_data = "";
  
                if (master_tablet_data.intake_desc == "Every 6 hours") {
                  medicine_dosage_data = master_tablet_data.morning +master_tablet_data.short_form+ '-' + master_tablet_data.morning +master_tablet_data.short_form+ '-' + master_tablet_data.morning+master_tablet_data.short_form+master_tablet_data.short_form + '-' + master_tablet_data.morning+master_tablet_data.short_form;
                } else {
                  medicine_dosage_data = master_tablet_data.morning +master_tablet_data.short_form+ '-' + master_tablet_data.afternoon +master_tablet_data.short_form+ '-' + master_tablet_data.evening+master_tablet_data.short_form;
                }
  
                var master_timeDuration = "";
                if (master_tablet_data.drug_intake == "With food") {
                  master_timeDuration = master_tablet_data.drug_intake;
                } else {
                  if (master_tablet_data.time_duration != null && master_tablet_data.time_duration != "0") {
                    master_timeDuration = master_tablet_data.time_duration + ' mins ' + master_tablet_data.drug_intake;
                  } else {
                    master_timeDuration = master_tablet_data.drug_intake;
                  }
                }
  
                this.druglist.push({
                  medicine_name: master_tablet_data.short_name+" "+master_tablet_data.drug_name,
                  medicine_dosage: medicine_dosage_data,
                  medicine_intakedays: master_tablet_data.days + ' days ',
                  medicine_food: master_timeDuration
                })               
              }
            }
  
            this.hosp_name = this.checkData(obj.hptl_name);
            var addval = obj.address2 != undefined && obj.address2 != null && obj.address2 != "" ? obj.address1 + "," + obj.address2 : obj.address1;
            this.hosp_addr = addval;
            this.hosp_csz = this.checkData(obj.city) + ", " + this.checkData(obj.state) + " - " + this.checkData(obj.zipcode) + "  " + this.checkData(obj.country);
            this.hosp_count = this.checkData(obj.country);
            this.hosp_tel = obj.telephone != undefined && obj.telephone != null && obj.telephone != "" ? obj.telephone : "-";
            
            if(obj.website != undefined && obj.website != null && obj.website != ""){
              this.hosp_web = obj.website;
              this.hosp_web_flag=false;
            } else {
              this.hosp_web = "";
              this.hosp_web_flag= true;
            }
          },
          error => {
            this.toastr.error(Message_data.unableToFetchCasesheet);
          }
        )
    } else if(this.getCasesheetData.casesheet == "Gynecology"){
      var chisturl=ipaddress.getIp.toString() + 'goi/chist';
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(chisturl, send_data,
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            
            this.patient_id = this.checkData(obj.pat_id);
            this.gender = obj.gender != undefined && encrypt_decript.Decript(obj.gender) != "" ? encrypt_decript.Decript(obj.gender) : "";
            this.current_occup = obj.current_occupation != undefined && encrypt_decript.Decript(obj.current_occupation) != "" ? encrypt_decript.Decript(obj.current_occupation) : "";           
            this.disease = this.checkData(obj.disease);
            this.symptoms = this.checkData(obj.symptoms);
            this.stress_factor = this.checkData(obj.stress_factor);     
            this.age = this.checkData(obj.age);
            this.height = obj.height != undefined && obj.height != null && obj.height != "" ?  obj.height+" "+ obj.height_measure : "";
            this.weight = obj.weight != undefined && obj.weight != null && obj.weight != "" ? obj.weight+" "+obj.weight_measure : "";            
            this.bmi = this.checkData(obj.bmi);
            this.body_nature = this.checkData(obj.body_nature);
            this.body_type = this.checkData(obj.body_type);
            this.marital_status = this.checkData(obj.marital_status);
            this.visit_purpose = this.checkData(obj.visit_purpose);
            this.mens_stage = this.checkData(obj.mens_stage_desc);
            this.mens_periods = this.checkData(obj.gync_periods);
            var last_date = obj.gync_last_mens_period.split("-");
            this.last_mens_period = this.checkData(last_date[2] + "-" + last_date[1] + "-" + last_date[0]);
            var clength = obj.gync_cycle_length.split(" "); 
            this.mens_cycle_length = this.checkData(clength[0] + "/" + clength[1]);
            this.mens_pain = this.checkData(obj.mens_pain);
            this.mens_pain_freq = this.checkData(obj.pain_freq_desc);
            this.mens_pain_time = this.checkData(obj.pain_timing);
            this.mens_pain_char = this.checkData(obj.pain_character);
            
            if(obj.main_concern != undefined){
              this.main_concerns =obj.main_concern;
              this.mainconc_flag=false;
            }
  
            if(obj.medical_history != undefined){
              this.arrayToString(obj.medical_history,"medical_history");
              //this.med_hist=obj.medical_history;
              this.medhist_flag=false;
            }
            
            if(obj.clinical_bp != undefined && obj.clinical_bp != null && obj.clinical_bp != ""){
              this.bp_flag = false;
              this.bp_right = obj.clinical_bp;
            }else{
              this.bp_flag = true;
              this.bp_right = "";
            }
  
            if(obj.drug_list != undefined){
              for(var i=0;i<obj.drug_list.length;i++){
                var master_tablet_data = obj.drug_list[i];
                var medicine_dosage_data = "";
  
                if (master_tablet_data.intake_desc == "Every 6 hours") {
                  medicine_dosage_data = master_tablet_data.morning +master_tablet_data.short_form+ '-' + master_tablet_data.morning +master_tablet_data.short_form+ '-' + master_tablet_data.morning+master_tablet_data.short_form+master_tablet_data.short_form + '-' + master_tablet_data.morning+master_tablet_data.short_form;
                } else {
                  medicine_dosage_data = master_tablet_data.morning +master_tablet_data.short_form+ '-' + master_tablet_data.afternoon +master_tablet_data.short_form+ '-' + master_tablet_data.evening+master_tablet_data.short_form;
                }
  
                var master_timeDuration = "";
                if (master_tablet_data.drug_intake == "With food") {
                  master_timeDuration = master_tablet_data.drug_intake;
                } else {
                  if (master_tablet_data.time_duration != null && master_tablet_data.time_duration != "0") {
                    master_timeDuration = master_tablet_data.time_duration + ' mins ' + master_tablet_data.drug_intake;
                  } else {
                    master_timeDuration = master_tablet_data.drug_intake;
                  }
                }
  
                this.druglist.push({
                  medicine_name: master_tablet_data.short_name+" "+master_tablet_data.drug_name,
                  medicine_dosage: medicine_dosage_data,
                  medicine_intakedays: master_tablet_data.days + ' days ',
                  medicine_food: master_timeDuration
                })               
              }
            }
  
            this.hosp_name = this.checkData(obj.hptl_name);
            var addval = obj.address2 != undefined && obj.address2 != null && obj.address2 != "" ? obj.address1 + "," + obj.address2 : obj.address1;
            this.hosp_addr = addval;
            this.hosp_csz = this.checkData(obj.city) + ", " + this.checkData(obj.state) + " - " + this.checkData(obj.zipcode) + "  " + this.checkData(obj.country);
            this.hosp_count = this.checkData(obj.country);
            this.hosp_tel = obj.telephone != undefined && obj.telephone != null && obj.telephone != "" ? obj.telephone : "-";
            
            if(obj.website != undefined && obj.website != null && obj.website != ""){
              this.hosp_web = obj.website;
              this.hosp_web_flag=false;
            } else {
              this.hosp_web = "";
              this.hosp_web_flag= true;
            }
          },
          error => {
            this.toastr.error(Message_data.unableToFetchCasesheet);
          }
        )
    } else if(this.getCasesheetData.casesheet == "Pediatrics"){
      var chisturl=ipaddress.getIp.toString() + 'pedia/chist';
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(chisturl, send_data,
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            
            this.patient_id = this.checkData(obj.pat_id);
            this.gender = obj.gender != undefined && encrypt_decript.Decript(obj.gender) != "" ? encrypt_decript.Decript(obj.gender) : "";
            this.current_occup = obj.current_occupation != undefined && encrypt_decript.Decript(obj.current_occupation) != "" ? encrypt_decript.Decript(obj.current_occupation) : "";
            this.disease = this.checkData(obj.disease);
            this.symptoms = this.checkData(obj.symptoms);
            this.stress_factor = this.checkData(obj.stress_factor);     
            this.age = this.checkData(obj.age);
            this.temparature = this.checkData(obj.temparature);
            this.ped_complic = this.checkData(obj.complications);
            this.mother_age = this.checkData(obj.mother_age);
            this.delivery_type = this.checkData(obj.delivery_type);
            this.gestational_age = this.checkData(obj.gestational_age);
            this.allergies = this.checkData(obj.allergies);
            this.feeding_habit = this.checkData(obj.feeding_habit);
           
            if(obj.concerns != undefined){
              this.arrayToString(obj.concerns,"main_concern"); 
              this.mainconc_flag = false;
            }
  
            if(obj.medical_history != undefined){
              this.arrayToString(obj.medical_history,"medical_history"); 
              this.medhist_flag = false;
            }
            
            if(obj.maternal_illness != undefined){
              this.arrayToString(obj.maternal_illness,"maternal_illness"); 
              this.materill_flag = false;
            }
  
            if(obj.blood_pressure_sit != undefined){
              this.bp_flag = false;
              this.bp = obj.blood_pressure_sit;
            }else{
              this.bp_flag = true;
              this.bp = "";
            }
  
            if(obj.drug_list != undefined){
              for(var i=0;i<obj.drug_list.length;i++){
                var master_tablet_data = obj.drug_list[i];
                var medicine_dosage_data = "";
  
                if (master_tablet_data.intake_desc == "Every 6 hours") {
                  medicine_dosage_data = master_tablet_data.morning +master_tablet_data.short_form+ '-' + master_tablet_data.morning +master_tablet_data.short_form+ '-' + master_tablet_data.morning+master_tablet_data.short_form+master_tablet_data.short_form + '-' + master_tablet_data.morning+master_tablet_data.short_form;
                } else {
                  medicine_dosage_data = master_tablet_data.morning +master_tablet_data.short_form+ '-' + master_tablet_data.afternoon +master_tablet_data.short_form+ '-' + master_tablet_data.evening+master_tablet_data.short_form;
                }
  
                var master_timeDuration = "";
                if (master_tablet_data.drug_intake == "With food") {
                  master_timeDuration = master_tablet_data.drug_intake;
                } else {
                  if (master_tablet_data.time_duration != null && master_tablet_data.time_duration != "0") {
                    master_timeDuration = master_tablet_data.time_duration + ' mins ' + master_tablet_data.drug_intake;
                  } else {
                    master_timeDuration = master_tablet_data.drug_intake;
                  }
                }
  
                this.druglist.push({
                  medicine_name: master_tablet_data.short_name+" "+master_tablet_data.drug_name,
                  medicine_dosage: medicine_dosage_data,
                  medicine_intakedays: master_tablet_data.days + ' days ',
                  medicine_food: master_timeDuration
                })               
              }
            }
  
            this.hosp_name = this.checkData(obj.hptl_name);
            var addval = obj.address2 != undefined && obj.address2 != null && obj.address2 != "" ? obj.address1 + "," + obj.address2 : obj.address1;
            this.hosp_addr = addval;
            this.hosp_csz = this.checkData(obj.city) + ", " + this.checkData(obj.state) + " - " + this.checkData(obj.zipcode) + "  " + this.checkData(obj.country);
            this.hosp_count = this.checkData(obj.country);
            this.hosp_tel = obj.telephone != undefined && obj.telephone != null && obj.telephone != "" ? obj.telephone : "-";
            
            if(obj.website != undefined && obj.website != null && obj.website != ""){
                this.hosp_web = obj.website;
              this.hosp_web_flag=false;
            } else {
              this.hosp_web = "";
              this.hosp_web_flag= true;
            }
          },
          error => {
            this.toastr.error(Message_data.unableToFetchCasesheet);
          }
        )
    } else{
      this.others = true;
      var chisturl=ipaddress.getIp.toString() + 'other/chist';
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(chisturl, send_data,
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            
            this.patient_id = this.checkData(obj.pat_id);
            this.gender = obj.gender != undefined && encrypt_decript.Decript(obj.gender) != "" ? encrypt_decript.Decript(obj.gender) : "";
            this.current_occup = obj.current_occupation != undefined && encrypt_decript.Decript(obj.current_occupation) != "" ? encrypt_decript.Decript(obj.current_occupation) : "";           
            this.disease = this.checkData(obj.disease);
            this.symptoms = this.checkData(obj.symptoms);
            this.stress_factor = this.checkData(obj.stress_factor);         
            this.age = this.checkData(obj.age);
            this.age = this.checkData(obj.age);
            this.height = obj.height != undefined && obj.height != null && obj.height != "" ?  obj.height+" "+ obj.height_measure : "";
            this.weight = obj.weight != undefined && obj.weight != null && obj.weight != "" ? obj.weight+" "+obj.weight_measure : "";          
            this.bmi = this.checkData(obj.bmi);
            this.body_nature = this.checkData(obj.body_nature);
            this.body_type = this.checkData(obj.body_type);
            if(obj.main_concerns != undefined){
              this.arrayToString(obj.main_concerns,"main_concern"); 
              this.mainconc_flag=false;
            }
  
            if(obj.medical_history != undefined){
              this.arrayToString(obj.medical_history,"medical_history"); 
             // this.med_hist=obj.medical_history;
              this.medhist_flag=false;
            }

            if(obj.bp_right_arm_dia != undefined && obj.bp_right_arm_dia != null && obj.bp_right_arm_dia != ""){
              this.bp_flag = false;
              this.bp_right = obj.bp_right_arm_sys+"/"+obj.bp_right_arm_dia;
              this.bp_left = obj.bp_left_arm_sys+"/"+obj.bp_left_arm_dia;
            }else{
              this.bp_flag = true;
              this.bp_right = "";
              this.bp_left = "";
            }
  
            if(obj.surgery != undefined && obj.surgery != null && obj.surgery != ""){
              this.surgery_flag = false;
              this.surgery = obj.surgery_desc;
              this.surgery_proc = obj.surg_proc;
            }else{
              this.surgery_flag = true;
              this.surgery = "";
              this.surgery_proc = "";
            }
  
            if(obj.drug_list != undefined){
              for(var i=0;i<obj.drug_list.length;i++){
                var master_tablet_data = obj.drug_list[i];
                var medicine_dosage_data = "";
  
                if (master_tablet_data.intake_desc == "Every 6 hours") {
                  medicine_dosage_data = master_tablet_data.morning +master_tablet_data.short_form+ '-' + master_tablet_data.morning +master_tablet_data.short_form+ '-' + master_tablet_data.morning+master_tablet_data.short_form+master_tablet_data.short_form + '-' + master_tablet_data.morning+master_tablet_data.short_form;
                } else {
                  medicine_dosage_data = master_tablet_data.morning +master_tablet_data.short_form+ '-' + master_tablet_data.afternoon +master_tablet_data.short_form+ '-' + master_tablet_data.evening+master_tablet_data.short_form;
                }
  
                var master_timeDuration = "";
                if (master_tablet_data.drug_intake == "With food") {
                  master_timeDuration = master_tablet_data.drug_intake;
                } else {
                  if (master_tablet_data.time_duration != null && master_tablet_data.time_duration != "0") {
                    master_timeDuration = master_tablet_data.time_duration + ' mins ' + master_tablet_data.drug_intake;
                  } else {
                    master_timeDuration = master_tablet_data.drug_intake;
                  }
                }
  
                this.druglist.push({
                  medicine_name: master_tablet_data.short_name+" "+master_tablet_data.drug_name,
                  medicine_dosage: medicine_dosage_data,
                  medicine_intakedays: master_tablet_data.days + ' days ',
                  medicine_food: master_timeDuration
                })               
              }
            }
  
            this.hosp_name = this.checkData(obj.hptl_name);
            var addval = obj.address2 != undefined && obj.address2 != null && obj.address2 != "" ? obj.address1 + "," + obj.address2 : obj.address1;
            this.hosp_addr = addval;
            this.hosp_csz = this.checkData(obj.city) + ", " + this.checkData(obj.state) + " - " + this.checkData(obj.zipcode) + "  " + this.checkData(obj.country);
            this.hosp_count = this.checkData(obj.country);
            this.hosp_tel = obj.telephone != undefined && obj.telephone != null && obj.telephone != "" ? obj.telephone : "-";
            
            if(obj.website != undefined && obj.website != null && obj.website != ""){
                this.hosp_web = obj.website;
              this.hosp_web_flag=false;
            } else {
              this.hosp_web = "";
              this.hosp_web_flag= true;
            }
          },
          error => {
            this.toastr.error(Message_data.unableToFetchCasesheet);
          }
        )
    }
  }

  backClicked() {
    this.doctorviewservice.sendMessage("casesheet_list");
  }

  print_area(){
    let printContents, popupWin;
    //printContents = document.getElementById('discharge_summary').innerHTML;
    printContents = this.casesheetsummary.nativeElement.innerHTML;
    popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Casesheet</title>
          <link rel="stylesheet" media="screen" href="">
          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
          <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
  
          <style>
          @page { size: auto;  margin: 0mm; }
          .table_class {
            background-color: rgb(108, 180, 248);
          }
          .casesheet_container {
            width: 211px;
            padding: 1px;
            background: #277196;
            display: inline-flex;
            position: relative;
            padding-left: 5px;
            top: 21px;
            color: #FFFFFF;
          }
          .border_style{
            border:solid 1px;
            border-color: #488aff;
          }
          .width_style{
            margin-top:4px;
            width:600px;
          }
          .rowformat{
            display: flex;
            flex-wrap: wrap;
          }
          tr:nth-child(even) {background-color: #f2f2f2;}
          </style>
        </head>
        <body onload="window.print();window.onfocus=function(){ window.close();}">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }

  //diab
  arrayToString(selected, flag) {
    var selected_array;
    this.med_hist = "";

    function StringBuilder(this:any,value) {
        this.strings = new Array("");
        this.append(value);
    }

    // Appends the given value to the end of this instance.
    StringBuilder.prototype.append = function (value) {
        if (value) {
            this.strings.push(value);
        }
    }

    // Clears the string buffer
    StringBuilder.prototype.clear = function () {
        this.strings.length = 1;
    }

    // Converts this instance to a String.
    StringBuilder.prototype.toString = function () {
        return this.strings.join("");
    }

    var selected_data = new StringBuilder("");
    selected_array = selected;

    if (selected_array.length != 0) {
      var n = 0;
      for (var i = 0; i < selected_array.length; i++) {
        n = n + 1;
        if (n > 1) {
            selected_data.append(",");
            selected_data.append("\n");
        }
        selected_data.append(selected_array[i].description);
          
      }
      if(flag =="medical_history"){
        this.med_hist = selected_data.toString();
      } else if(flag =="main_concern"){
        this.main_concerns = selected_data.toString();
      } else if(flag =="cardio"){
        this.cardiodis = selected_data.toString();
      } else if(flag =="mobility"){
        this.mobility = selected_data.toString();
      } else if(flag =="nephro"){
        this.neprhodis = selected_data.toString();
      } else if(flag =="neuro"){
        this.neurodis = selected_data.toString();
      } else if(flag =="retino"){
        this.retinodis = selected_data.toString();
      } else if(flag =="skin"){
        this.skindis = selected_data.toString();
      } else if(flag =="hearing"){
        this.hearingdis = selected_data.toString();
      } else if(flag =="foot"){
        this.footdis = selected_data.toString();
      }
    }
  }

  checkData(value){
    var data = value != undefined && value != null && value != "" && value != "undefined" && value != "null" ? value : "";
    return data;
  }
}
