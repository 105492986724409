import { Component, OnInit } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../../app/providers/common-data.service';
import { Helper_Class } from '../../../app/helper_class';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';

@Component({
  selector: 'app-weightage-master',
  templateUrl: './weightage-master.component.html',
  styleUrls: ['./weightage-master.component.scss']
})
export class WeightageMasterComponent implements OnInit {
  public suppID;
  public userInfo;
  public pharmacyID;
  public hsp_id;
  public PharmauserID;
  public department_id;
  public store_id;
  public supplierList: any = [];
  public price: any = 0;
  public delivery: any = 0;
  public short_exp: any = 0;
  public Damage: any = 0;
  public total: any = 0;
  public rateingarray: number[] = [];
  public pagetype = "suppro";
  public productMasterAddArray: any = [];
  public productids = [];
  public productMasterIndex = 0;
  public deliverylistarray: number[] = [];
  errorMessage: string;
  public tablearray = [];
  public supplier_rating_id;


  constructor(public gservice: CommonDataService, public http: Http, public toastr: ToastrService) { }

  ngOnInit(): void {
    this.userInfo = Helper_Class.getInfo();
    if (Helper_Class.getInfo().user_type == 'Admin') {
      this.pharmacyID = this.userInfo.pharma_id;
      this.hsp_id = this.userInfo.hptl_clinic_id;
      this.PharmauserID = this.userInfo.po_approver;
    } else if (Helper_Class.getInfo().user_type == 'pharmacy') {
      this.pharmacyID = this.userInfo.hospitals[0].hptl_clinic_id;
      this.hsp_id = this.userInfo.hosp_id;
      this.PharmauserID = this.userInfo.user_id;
    }
    else {
      this.pharmacyID = this.userInfo.hospitals[0].hptl_clinic_id;
      this.hsp_id = this.userInfo.hosp_id;
      this.PharmauserID = this.userInfo.user_id;
    }
    this.department_id = this.userInfo.hospitals[0].department_id;
    this.store_id = this.userInfo.hospitals[0].store_id;
    this.getSuppliers();
    this.getpricearray();
    this.retrivedata();
  }

  retrivedata(){
    console.log("send_data = "+JSON.stringify({
      pharma_id:this.pharmacyID,
      dept_id:this.department_id,
      store_id:this.store_id
      }))
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "mas/gsra",
      JSON.stringify({
      pharma_id:this.pharmacyID,
      dept_id:this.department_id,
      store_id:this.store_id
        // pricing: this.price,
        // delivery: this.delivery,
        // short_expiry: this.short_exp,
        // damaged_return: this.Damage,
        // status: 1,
        // pharma_id: this.pharmacyID,
        // dept_id: this.department_id,
        // store_id: this.store_id,
        // supplier_rating_id (for update)
      }),
      { headers: headers }).subscribe(
        data => {
          console.log()
          var obj = data.json();
          if(obj.pricing != undefined){
            this.price=obj.pricing
          }
          if(obj.delivery != undefined){
            this.delivery=obj.delivery
          }
          if(obj.short_expiry != undefined){
            this.short_exp=obj.short_expiry
          }
          if(obj.damaged_return != undefined){
            this.Damage=obj.damaged_return
          }
          if(obj.supplier_rating_id != undefined){
            this.supplier_rating_id=obj.supplier_rating_id
          }
          console.log("supplier rating retrive= "+JSON.stringify(obj))
        },
        error => {
        }
      )
  }



  getSuppliers() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "inv/gsup/",
      JSON.stringify({
        pharmacy_id: this.pharmacyID,
        dept_id: this.department_id,
        store_id: this.store_id
      }), { headers: headers }).subscribe(
        data => {
          this.supplierList = [];
          var obj = data.json().supplier_details;
          console.log("SUPPLIERS ((())) " + JSON.stringify(obj))
          if (data.json().supplier_details != null && data.json().supplier_details.length != 0) {


            // this.supplierList = data.json().supplier_details;

            for (var i = 0; i < obj.length; i++) {
              var exists = this.supplierList.some(function (supplier) {
                return supplier.supp_id === obj[i].supp_id;
              });

              // If not present, push the object into supplierList
              if (!exists) {
                this.supplierList.push({
                  supp_id: obj[i].supp_id,
                  supp_code: obj[i].supp_code,
                  name: obj[i].name,
                  gst_no: obj[i].gst_no,
                  received_amount: obj[i].received_amount,
                  refund_amount: obj[i].refund_amount,
                  product_returns_id: obj[i].product_returns_id
                });
              }
            }



            console.log("supplier list" + JSON.stringify(this.supplierList))
            this.suppID = data.json().supplier_details[0].supp_id;
            this.retrieveProduct()
            // this.getProductCount();
            //this.getSuppliersProduct();
          }
        },
        error => {
          this.toastr.error(Message_data.unableToFetchsupp);
        }
      )
  }


  getpricearray() {
    for (let i = 1; i <= 20; i++) {
      let value = i * 5;
      this.rateingarray.push(value);
    }
    console.log("price array " + this.rateingarray)
  }

  // deliveryarray(){
  //   for (let i = 1; i <= 20; i++) {
  //     let value = i * 5;
  //     this.deliverylistarray.push(value);
  //   }
  //   console.log("price array "+this.pricearray)
  // }
  // short_exp_array(){
  //   for (let i = 1; i <= 20; i++) {
  //     let value = i * 5;
  //     this.shrt_exp_listarray.push(value);
  //   }
  //   console.log("price array "+this.pricearray)
  // }
  // damagearray(){
  //   for (let i = 1; i <= 20; i++) {
  //     let value = i * 5;
  //     this.damage_listarray.push(value);
  //   }
  //   console.log("price array "+this.pricearray)
  // }


  retrieveProduct() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "inv/gpcbyphar",
      JSON.stringify({
        pharmacist_id: this.PharmauserID,
        pharmacy_id: this.pharmacyID,
        imei: Helper_Class.getIPAddress(),
        // limit: this.pageSize,
        // start_no: this.currentPage,
        supp_id: this.suppID,
        dept_id: this.department_id,
        store_id: this.store_id
      }),
      { headers: headers }).subscribe(
        data => {
          var obj = data.json();
          if (obj != null && obj.products != null) {
            console.log("PRODUCTS " + JSON.stringify(obj))
            this.productMasterAddArray = [];
            for (var i = 0; i < obj.products.length; i++) {
              this.productids.push({
                product_id: obj.products[i].product_id,
                is_new: "0",
              })
              var pharid;
              if (this.PharmauserID != undefined) {
                pharid = this.PharmauserID.toString();
              }
              if (this.pagetype == "suppro") {
                this.productMasterAddArray.push({
                  Index: this.productMasterIndex,
                  hsn_no: obj.products[i].hsn_no,
                  product_id: obj.products[i].product_id,
                  pharmacist_id: pharid,
                  name: obj.products[i].name,
                  short_name: obj.products[i].short_name,
                  generic_name: obj.products[i].generic_name,
                  medicare_id: obj.products[i].medicare_id,
                  medicare_name: obj.products[i].medicare_name,
                  product_type_id: obj.products[i].product_type_id,
                  product_type_desc: obj.products[i].product_type_desc,
                  category_type_id: obj.products[i].category_type_id,
                  category_name: obj.products[i].category_name,
                  uom_code: obj.products[i].uom_code,
                  uom_desc: obj.products[i].uom_desc,
                  mfg_id: obj.products[i].mfg_id,
                  mfg_name: obj.products[i].mfg_name,
                  per_unit: obj.products[i].per_unit,
                  cost_price: obj.products[i].cost_price,
                  sales_price: obj.products[i].sales_price,
                  MRP: obj.products[i].MRP,
                  prod_gst: obj.products[i].prod_gst,
                  prod_disc: obj.products[i].prod_disc,
                  supp_id: obj.products[i].supp_id,
                  supp_name: obj.products[i].supp_name,
                  checked: true
                });

              }
            }
          }
        },
        error => {
        }
      )
  }

  validateTotal(type, value) {
    var priceValue: any = '0', deliveryValue: any = '0', expiryValue: any = '0', damgeValue: any = '0', total: number = 0;
    var flag = false;

    if (type == "price") {
      this.price = parseInt(value);
    }
    if (type == "delivery") {
      this.delivery = parseInt(value);
    }
    if (type == "short_exp") {
      this.short_exp = parseInt(value);
    }
    if (type == "damage") {
      this.Damage = parseInt(value);
    }

    // this.price = priceValue;
    // this.delivery = deliveryValue;
    // this.short_exp = expiryValue;
    // this.Damage = damgeValue;

    total = parseInt(this.price) + parseInt(this.delivery) + parseInt(this.short_exp) + parseInt(this.Damage);

    if (total > 100) {
      if (type == "price") {
        this.price =0;
      }
      if (type == "delivery") {
        this.delivery =0;
      }
      if (type == "short_exp") {
        this.short_exp =0;
      }
      if (type == "damage") {
        this.Damage =0;
      }
      this.toastr.error("Total percentage should not exceed 100%.");
    } else {

      if(this.tablearray.length != 0){
        for (var i = 0; i < this.tablearray.length; i++) {
          if (type == this.tablearray[i].type) {
            this.tablearray[i].value = value;
            flag = true;
            break
          } 
        }

        if(flag != true){
          this.tablearray.push({
            type: type,
            value: value
          })
        }

      } else {
        this.tablearray.push({
          type: type,
          value: value
        })
      }

      
    }
  }

  saveSupplerrating() {
    var send_data;
    if(this.supplier_rating_id != undefined){
      send_data={
        pricing: this.price,
        delivery: this.delivery,
        short_expiry: this.short_exp,
        damaged_return: this.Damage,
        status: 1,
        pharma_id: this.pharmacyID,
        dept_id: this.department_id,
        store_id: this.store_id,
        supplier_rating_id:this.supplier_rating_id
      }
    }else{
      send_data={
        pricing: this.price,
        delivery: this.delivery,
        short_expiry: this.short_exp,
        damaged_return: this.Damage,
        pharma_id: this.pharmacyID,
        dept_id: this.department_id,
        store_id: this.store_id,
      }
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "mas/ssra",send_data,{ headers: headers }).subscribe(
        data => {
          var obj = data.json();
          this.toastr.success("Saved successfully")
          this.retrivedata()
          console.log("save supplier rating = "+JSON.stringify(obj))
        },
        error => {
          this.toastr.error("Error in save")
        }
      )

  }


}
