
import { CommonModule,DatePipe } from '@angular/common';
import { InventoryModuleRoutingModule } from './inventory-module-routing.module';
import { AssetsDataInventoryComponent } from './assets-data-inventory/assets-data-inventory.component';
import { NgModule,CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatSliderModule } from '@angular/material/slider';
import { RouterModule } from '@angular/router';
import { MatSelectModule } from '@angular/material/select';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { HttpModule } from '@angular/http';
import { FormsModule } from '@angular/forms'; 
import { ReactiveFormsModule } from '@angular/forms';
import {MatListModule} from '@angular/material/list';
import { ToastrModule ,ToastNoAnimation, ToastNoAnimationModule } from 'ngx-toastr';
import {MatTabsModule} from '@angular/material/tabs';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import {MatTableModule} from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import {NgxPrintModule} from 'ngx-print';
import { AngularSplitModule } from 'angular-split';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {MatCardModule} from '@angular/material/card';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { DataTablesModule } from 'angular-datatables';
import {TabsModule } from 'ngx-bootstrap/tabs';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { HttpClientModule } from '@angular/common/http';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { RouterModule } from '@angular/router';
import { AssetsListComponent } from './assets-list/assets-list.component';
import { PhramaManufacturerMstComponent } from './phrama-manufacturer-mst/phrama-manufacturer-mst.component';
import { StockreorderlevelComponent } from './stockreorderlevel/stockreorderlevel.component';
import { PhramaSupplierProductMstComponent } from './phrama-supplier-product-mst/phrama-supplier-product-mst.component';
import { PharmaSupplierMstComponent } from './pharma-supplier-mst/pharma-supplier-mst.component';
import { PharmaDiscountMstComponent } from './pharma-discount-mst/pharma-discount-mst.component';
import { PharmaProductMstComponent } from './pharma-product-mst/pharma-product-mst.component';
import { PurchaserequestlistComponent } from './purchaserequestlist/purchaserequestlist.component';
import { PharmapurchaserequestComponent } from './pharmapurchaserequest/pharmapurchaserequest.component';
import { PharmapurchaseordermstComponent } from './pharmapurchaseordermst/pharmapurchaseordermst.component';
import { PharmaInventoryReceivablesComponent } from './pharma-inventory-receivables/pharma-inventory-receivables.component';
import { PharmaInventoryUploadComponent } from './pharma-inventory-upload/pharma-inventory-upload.component';
import { PharmaReturnsComponent } from './pharma-returns/pharma-returns.component';
import { StockadjustmentComponent } from './stockadjustment/stockadjustment.component';
import { PharmaSupplierPaymentComponent } from './pharma-supplier-payment/pharma-supplier-payment.component';
import { PurchaseorderviewComponent } from './purchaseorderview/purchaseorderview.component';
import { PharmaRackbinComponent } from './pharma-rackbin/pharma-rackbin.component';
import { PharmaRacksBinsComponent } from './pharma-racks-bins/pharma-racks-bins.component'; 
// import { PhramaManufacturerMstComponent } from 'src/app/pharma/phrama-manufacturer-mst/phrama-manufacturer-mst.component';
// import { StockreorderlevelComponent } from 'src/app/pharma/stockreorderlevel/stockreorderlevel.component';
// import { CustomeCommonModule } from 'src/app/common-module/common-module.module';
import { PurchaseOrderpopupComponent } from './purchase-orderpopup/purchase-orderpopup.component';
import { PharmaStockStatusAdminComponent } from './pharma-stock-status-admin/pharma-stock-status-admin.component';
import { ConsingmentComponent } from './consingment/consingment.component';
import { ConsingmentlistComponent } from './consingmentlist/consingmentlist.component';




@NgModule({
  declarations: [
    AssetsDataInventoryComponent,
    AssetsListComponent,
    PhramaManufacturerMstComponent,
    StockreorderlevelComponent,
    PhramaSupplierProductMstComponent,
    PharmaSupplierMstComponent,
    PharmaDiscountMstComponent,
    PharmaProductMstComponent,
    PurchaserequestlistComponent,
    PharmapurchaserequestComponent,
    PharmapurchaseordermstComponent,

    PharmaInventoryReceivablesComponent,
    PharmaInventoryUploadComponent,
    PharmaReturnsComponent,
    StockadjustmentComponent,
    PharmaSupplierPaymentComponent,
     PurchaseorderviewComponent,
     PharmaRackbinComponent,
     PharmaRacksBinsComponent,
    PharmaStockStatusAdminComponent,
    PurchaseOrderpopupComponent,
    ConsingmentComponent,
    ConsingmentlistComponent

  ],
  imports: [
    // MatDialog,
    // MatDialogRef, 
    RouterModule,
    ToastNoAnimationModule,
    ReactiveFormsModule,
    // ToastNoAnimation,
    CommonModule,
    MatListModule,
    ToastrModule,
    HttpModule,
    MatDatepickerModule,
    MatTabsModule,
    MatNativeDateModule,
    MatTableModule,
    MatTooltipModule,
    NgxPrintModule,
    AngularSplitModule,
    MatCheckboxModule,
    MatCardModule,
    FormsModule,
    MDBBootstrapModule,
    DataTablesModule,
    TabsModule,
    MatAutocompleteModule,
    HttpClientModule,
    MatRadioModule ,
    MatFormFieldModule,
    MatInputModule,
    MatDividerModule,
    MatGridListModule,
    MatIconModule,
    MatButtonModule,
    ScrollingModule,
    MatSelectModule,
    MatSelectModule,
    MatSliderModule,
    BrowserModule,
    MatExpansionModule,
    MatDialogModule,
    InventoryModuleRoutingModule,
   
    

    ToastrModule.forRoot({
      timeOut: 1000,
      positionClass: 'toast-top-center'     
    }),
    ToastNoAnimationModule.forRoot(),
  ],
  exports:[
    AssetsDataInventoryComponent,
    AssetsListComponent,
    PhramaManufacturerMstComponent,
    StockreorderlevelComponent,
    PhramaSupplierProductMstComponent,
    PharmaSupplierMstComponent,
    PharmaDiscountMstComponent,
    PharmaProductMstComponent,
    PurchaserequestlistComponent,
    PharmapurchaserequestComponent,
    PharmapurchaseordermstComponent,
    PharmaInventoryReceivablesComponent,
    PharmaInventoryUploadComponent,
    PharmaReturnsComponent,
    StockadjustmentComponent,
    PharmaSupplierPaymentComponent,
    PharmaRackbinComponent,
    PharmaRacksBinsComponent,
    PharmaStockStatusAdminComponent,
    PurchaseorderviewComponent,
    PurchaseOrderpopupComponent,
    ConsingmentComponent,
    ConsingmentlistComponent
  ],

  providers: [DatePipe],
  schemas: [ NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA ],
})
export class InventoryModuleModule {}
