import { Component, OnInit } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Date_Formate, Ignorecase_data } from '../../../assets/js/common';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { CommonDataService } from '../../providers/common-data.service';
import { ToastrService } from 'ngx-toastr';
import { Diagnosis_Helper } from '../Diagnosis_Helper';
import { DiagnosisService } from '../diagnosis/diagnosis.service';
import { Helper_Class } from '../../helper_class';
import { ipaddress } from '../../ipaddress'; import { FrontDeskService } from '../../../app/FrontDesk_module/front-desk/front-desk.service';
import { Message_data } from '../../../assets/js/Message_data';
import { MenuViewService } from '../../../app/common-module/menu-view/menu-view.service';

@Component({
  selector: 'app-diag-app-edit',
  templateUrl: './diag-app-edit.component.html',
  styleUrls: ['./diag-app-edit.component.scss']
})
export class DiagAppEditComponent implements OnInit {
  public disableDOBFlag: boolean = false;
  public disableAgeFlag: boolean = false;
  public somePlaceholder;
  public typeFilter;
  public firstNamePatient;
  public diagId;
  public clientDetailsArray = [];
  public clientFirstName;
  public clientMidName;
  public clientLastName;
  public appointmentValue;
  public clientID;
  public conformFlag: boolean;
  public userType: boolean;
  public newUserFlag: boolean;
  public existuserFlag: boolean;
  public searchFlag: boolean;
  public bookNowFlag: boolean;
  public showUltraTestFlag: boolean;
  public showCultureTestFlag: boolean;
  public showBloodTestFlag: boolean;
  public showUrineTestFlag: boolean;
  public showFeaceTestFlag: boolean;
  public showXrayTestFlag: boolean;
  public showScanTestFlag: boolean;
  public showElectroCardioTestFlag: boolean;
  public typeApp;
  public Filter;
  public referedNameArray;
  public locationArray = [];
  public cityArray = [];
  public stateArray = [];
  public countryArray = [];
  public referedDoctorArray = [];
  public salListArray = [];
  public testListArray = [];
  
  public bloodTestArray: any = [];
  public urineTestArray: any = [];
  public feaceTestArray: any = [];
  public XrayTestArray: any = [];
  public scanTestArray: any = [];
  public ultraTestArray: any = [];
  public CultureTestArray: any = [];
  public electroCardioTestArray: any = [];
  public bloodTestlistArray: any = [];
  public urineTestlistArray: any = [];
  public faceTestlistArray: any = [];
  public xrayTestlistArray: any = [];
  public scanTestlistArray: any = [];
  public ultraTestlistArray: any = [];
  public cultureTestlistArray: any = [];
  public electroCardioTestlistArray: any = [];
  public testGroupArray: any = [];
  public sendData: any = {};
  public testNameArray;
  public visitSession;
  public getVisitingSessionArray;
  public diagTest: string;
  public clientAddress1: string;
  public clientAddress2: string;
  public clientLocation: string;
  public clientCity: string;
  public clientState: string;
  public clientCountry: string;
  public clientLocationId: string;
  public clientCityId: string;
  public clientStateId: string;
  public clientcountryId: string;
  public clientZipCode: string;
  public referedBy: string;
  public clientNumber: number;
  public clientSymptom: string;
  public clientDOB: string;
  public clnt_sal: string;
  public BP_01: string;
  public BP_02: string;
  public ageURL: string;
  public appDate: string;
  public locationURL: string;
  public locationNameURL: string;
  public cityURL: string;
  public stateURL: string;
  public countryURL: string;
  public DoctorIDURL: string;
  public dateURL: string;
  public saveURL: string;
  public salURL: string;
  public splURL: string;
  public zipcodeURL: string;
  public docHospitalURL: string;
  public hospId: string;
  public clientId: string;
  public relationId: string;
  public subRelationId: string;
  public clientGender: string;
  public clientAge;
  public clientType: string;
  public currentDate: string;
  public currentTime: string;
  public currentYear: string;
  public homecare: string;
  public appointmentDate;
  public Select = "Select";
  public maxLength: number = 0;

  public concession;
  public amount;
  public inGST: boolean;
  public amountAfternoon;
  public priceTable: any = [];
  public balance: any = "0.00";
  public splArray;
  public speclization;
  public sessionFlag: boolean = true
  public timeSession;
  public startTime;
  public endTime;
  public sessionTimeStart: any = [];
  public sessionTimeEnd: any = [];
  public paymentArray: any = [];
  public insuranceFlag;
  public payType: string;
  private pay_desc: string;
  public insuranceId: string;
  public insurancyArray: any = [];
  public ynopt2: boolean;
  public ynopt1: boolean;
  public tranFlag: boolean;
  public transactionID;
  public cardFlag: boolean;
  public cardNumber;
  public cardHolderName;
  public chequeFlag: boolean;
  public bankName;
  public inpatient: boolean = false;
  public doctorName;
  public doctorRegId;
  public billingListArray = [];
  public selectedDocName;
  public appoint;
  public appID;
  public diagStatus;
  public patinetName;
  public savePrescriptionFlag: boolean;
  public presID;
  public advance = "0.00";
  public remaingBalance = "0.00";
  public test_name;
  // include biapsy
  
  public biopsyTestlistArray: any = [];
  public biopsyTestArray: any = [];
  public showBiopsyTestFlag: boolean;
  public diagTestGroup;
  public test_group;
  public diagtest_flag: boolean = false;
  public selTestGroupArray: any = [];
  public showTestGroupFlag;
  public userinfo;
  private billid;
  private finyear;
  public pat_type;
  public testexists: boolean;
  private sno: number = 1;
  public paytypreq: boolean = false;
  public isoutsource: boolean = false;
  public outarray: any = [];
  private outpayarray: any = [];
  public paymentsarray: any = [];
  public paytypelist: any = [];
  public payType1: string;
  public amountval: any;

  // New Appointment Edit
  public mrno;
  public clntSal;
  public clntFirstName;
  public clntMiddleName;
  public clntLastName;
  public clntGender;
  public clntContNumber;
  public clntEmergNumber;
  public clntEmergNumber1;
  public clientname;
  public clntDOB;
  public dobDisabledFlag: boolean;
  public clntAge;
  public clntFatherOrGuardName;
  public clntAddress1;
  public clntAddress2;
  public aadharNumber;
  public address;
  public clntLocation;
  public clntCountry;
  public clntState;
  public clntCity;
  public clntZipcode;
  public ref_source;
  public whatsappNumber;
  public referredDoctor;

  public doctorID: string;
  doctorList: any[];
  docflag: boolean;
  addtest_Flag: boolean;
  public LabtestName: any;
  public filteredLabArray: any = [];
  public filteredRadioArray: any = [];
  public filteredMicroArray: any = [];
  public filteredProfilesArray: any = [];
  filteredPackagesArray: any;

  public labTest_array: any = [];
  public radioTest_array: any = [];
  public microTest_array: any = [];
  public profiles: any = [];
  public radiotestName: any;
  public microtestName: any;
  public profilesName: any;
  selected_profiles: any = [];
  packages_array: any = [];
  diag_center_id: any;
  showTest_type: any;
  Blood_test: any = [];
  labarray: any = [];
  radioarray: any = [];
  selected_packages: any = [];
  clientRegId: any;
  public test_exists: boolean = false;
  isTestVal: boolean;
  selected_profileIds: any = [];
  selected_packageIds: any = [];
  labTestArray: any = [];
  radioTestArray: any = [];
  profileImg: any;
  diag_app_show_test_det: boolean;
  status: any;
  saveFlag: boolean;
  priority: any = "0";
  public discount_amt: any = '0.00';
  public bill_amount: any = '0.00';

  public addPackageBillArray: any = [];
  public addBillArray: any = [];
  package_balance: string;
  package_bill_amount: any;
  package_discount_amt: string;
  test_balance: string;
  test_bill_amount: any;
  test_discount_amt: string;

  package_paidBill: string;
  package_remainingBalance: string;
  test_paidBill: string;
  test_remainingBalance: string;
  test_paid_percentage: any = [];
  test_paymentsarray: any = [];
  package_paid_percentage: any = [];
  package_paymentsarray: any = [];
  testEqualSplitValue: any;
  test_pay_arrry_tot_amt: any;
  package_pay_arrry_tot_amt: any;
  packageEqualSplitValue: any;
  hptl_clinic_id: any;
  package_id: any;
  print_template: any;
  hospitalLogo: any;
  printlogostyle: any;
  billId: any;

  constructor(public diagnosisservice: MenuViewService, public toastr: ToastrService, public gservice: CommonDataService,
    public http: HttpClient, public dialog: MatDialog, public frontdeskservice: MenuViewService) {
    this.insuranceFlag = true;
    this.tranFlag = true;
    this.cardFlag = true;
    this.chequeFlag = true;
    this.typeFilter = "0";
    this.concession = "0";
    this.conformFlag = true;
    this.searchFlag = false;
    this.typeApp = "New";
    this.userType = true;
    this.clientType = "Existing";
    this.existuserFlag = false;
    this.newUserFlag = true;
    this.bookNowFlag = true;
    this.zipcodeURL = ipaddress.getIp + "usercontroller/locbyzipcode";
    this.locationURL = ipaddress.getIp + "usercontroller/loc";
    this.locationNameURL = ipaddress.getIp + "usercontroller/locbyname";
    this.cityURL = ipaddress.getIp + "gen/city";
    this.stateURL = ipaddress.getIp + "gen/state";
    this.countryURL = ipaddress.getIp + "gen/count";
    this.salURL = ipaddress.getIp + "gen/sal";
    this.splURL = ipaddress.getIp + "adm/gsplbyhptl";
    this.docHospitalURL = ipaddress.getIp + "search/docbyhs";
    this.saveURL = ipaddress.getIp + "appointment/rapp";
    this.DoctorIDURL = ipaddress.getIp + "search/doctorbyid/";
    this.dateURL = ipaddress.getIp + "adm/curdate";
    this.ageURL = ipaddress.getIp + "adm/ddif";
  }

  ngOnInit(): void {
    var hospital = Helper_Class.getInfo().hospitals;
    this.userinfo = Helper_Class.getInfo();
    this.appID = Helper_Class.getdiagnosisappview().diag_id;
    this.diagStatus = Helper_Class.getdiagnosisappview().status_txt;
    this.diagId = Helper_Class.getdiagnosisappview().centre_id;
    this.showBloodTestFlag = true;
    this.showUrineTestFlag = true;
    this.showFeaceTestFlag = true;
    this.showXrayTestFlag = true;
    this.showScanTestFlag = true;
    this.showUltraTestFlag = true;
    this.showCultureTestFlag = true;
    this.showElectroCardioTestFlag = true;
    for (var i = 1; i <= 12; i++) {
      if (i < 10) {
        this.sessionTimeStart.push("0" + i);
      } else {
        this.sessionTimeStart.push(i);
      }
    }

    for (var i = 0; i <= 55; i += 5) {
      if (i < 10) {
        this.sessionTimeEnd.push("0" + i);
      } else {
        this.sessionTimeEnd.push(i);
      }
    }

    if (hospital[0].bill_pay_type_req == "1") {
      this.paytypreq = false;
    } else {
      this.paytypreq = true;
    }

    if (Diagnosis_Helper.getCreateDiagAppt() != undefined
      && Diagnosis_Helper.getCreateDiagAppt().flow == "frontdesk") {
      this.diagId = Diagnosis_Helper.getCreateDiagAppt().center_id;
    }
    if (Helper_Class.getInfo().user_type == 'Admin') {
      this.diagId = Helper_Class.getInfo().diag_center_id;
    }

    if (Diagnosis_Helper.getCreateDiagAppt().flow == "frontdesk" || this.userinfo.user_type == "Admin") {
      this.showTest_type = this.userinfo.show_by_test_type;
      this.diagId = this.userinfo.diag_center_id;
      this.diag_center_id = this.userinfo.diag_center_id;
      this.diag_app_show_test_det = this.userinfo.diag_app_show_test_det == 1 ? true : false;

    } else {
      this.diagId = this.userinfo.hospitals[0].hptl_clinic_id;
      this.diag_app_show_test_det = this.userinfo.diag_app_show_test_det == 1 ? true : false;
    }

    this.search_test()

    this.ynopt1 = true;
    this.insuranceFlag = true;
    this.savePrescriptionFlag = false;
    this.diagTestGroup = "test";
    this.pat_type = "";
    this.getDoctor();
    this.getDiagTestTypes();
    this.getPaymentType();
    this.getInsurers();
    this.appGetdate();
  }

  //Get payment type
  getInsurers() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/gins',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.insurancyArray = obj.insurers;
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }

  backOption() {
    if (Diagnosis_Helper.getCreateDiagAppt().flow == "frontdesk") {
      this.frontdeskservice.sendMessage("manageappointments");

    }
    else if (Diagnosis_Helper.getCreateDiagAppt().flow == "diagnosis") {
      this.diagnosisservice.sendMessage("manageappointments");

    }
    else {
      this.diagnosisservice.sendMessage("appointment");
    }
  }

  getDiagTestTypes() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/dtests/',
      {
        diag_centre_id: this.diagId
      },
      { headers: headers })
      .subscribe(
        response => {
          this.testListArray = [];
          var obj = JSON.parse(JSON.stringify(response));

          if (obj.test_types != null) {
            this.testListArray = obj.test_types;
          }
          if (obj.outsource != undefined) {
            this.isoutsource = true;
          }
          this.diagTest = "Blood Test";
          this.diagTestChange();
        },
        error => { });
  }

  getTestGroups() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/gpkgd/',
      {
        diag_centre_id: this.diagId
      },
      { headers: headers })
      .subscribe(
        response => {
          this.testGroupArray = [];
          var obj = JSON.parse(JSON.stringify(response));

          if (obj.packages != null) {
            this.testGroupArray = obj.packages;
          }
        },
        error => { });
  }

  getAppDetails() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'appointment/viewapp/',
      {
        diag_app_id: this.appID,
        type: "diagnosis",
        home_care: "0",
        country: "IN",
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("check ---" + JSON.stringify(obj))

          // New Additions
          this.mrno = obj.patient_id != undefined ? obj.patient_id : "";
          this.profileImg = ipaddress.Ip_with_img_address + obj.profile_image;
          this.clntAge = obj.age != undefined ? obj.age : "";
          if (obj.salutation != undefined) {
            this.clntSal = obj.salutation + ".";
          } else {
            this.clntSal = "";
          }
          this.clntFirstName = obj.first_name != null && (encrypt_decript.Decript(obj.first_name) != "") ? encrypt_decript.Decript(obj.first_name) : "";
          this.clntMiddleName = obj.middle_name != null && (encrypt_decript.Decript(obj.middle_name) != "") ? encrypt_decript.Decript(obj.middle_name) : "";
          this.clntLastName = obj.last_name != null && (encrypt_decript.Decript(obj.last_name) != "") ? encrypt_decript.Decript(obj.last_name) : "";
          if (this.clntMiddleName != "") {
            this.clientname = this.clntSal + this.clntFirstName + " " + this.clntLastName;
          } else {
            this.clientname = this.clntSal + this.clntFirstName + " " + this.clntMiddleName + " " + this.clntLastName;
          }

          if (obj.dob != null) {
            this.clntDOB = Date_Formate(obj.dob);

          } else {
            this.clntDOB = null;
            this.dobDisabledFlag = true;
          }

          this.clntGender = obj.gender != null && (encrypt_decript.Decript(obj.gender) != "") ? encrypt_decript.Decript(obj.gender) : "";
          this.clntContNumber = obj.mobile != null && obj.mobile != "" && obj.mobile != undefined && (encrypt_decript.Decript(obj.mobile) != "") ? encrypt_decript.Decript(obj.mobile) : "";

          this.clntEmergNumber = obj.emerg_contact1 != null && obj.emerg_contact1 != "" && obj.emerg_contact1 != undefined && (encrypt_decript.Decript(obj.emerg_contact1) != "") ? encrypt_decript.Decript(obj.emerg_contact1) : "";
          this.clntEmergNumber1 = obj.emerg_contact2 != null && obj.emerg_contact2 != "" && obj.emerg_contact2 != undefined && (encrypt_decript.Decript(obj.emerg_contact2) != "") ? encrypt_decript.Decript(obj.emerg_contact2) : "";

          if (obj.SSN_PAN_number != undefined) {
            this.aadharNumber = encrypt_decript.Decript(obj.SSN_PAN_number);
          }

          this.whatsappNumber = '';

          if (obj.address1 != null && obj.address1 != "") {
            this.clntAddress1 = encrypt_decript.Decript(obj.address1);
          }
          if (obj.address2 != null && obj.address2 != "") {
            this.clntAddress2 = encrypt_decript.Decript(obj.address2);
            this.address = this.clntAddress1 + "" + this.clntAddress2
          }

          if (obj.ref_source != null) {
            this.ref_source = obj.ref_source;
          }


          this.clientCityId = obj.city;
          this.clientStateId = obj.state;
          this.clientcountryId = obj.country;
          this.clntLocation = this.checkData(obj.location_desc);
          this.clntCountry = this.checkData(obj.country_desc);
          this.clntState = this.checkData(obj.state_desc);
          this.clntCity = this.checkData(obj.city_desc);
          if(obj.zipcode != undefined) {
            this.clntZipcode = encrypt_decript.Decript(obj.zipcode);
          }

          this.addtest_Flag = true;
          this.clientRegId = obj.client_reg_id;
          this.relationId = obj.relation_id;
          this.subRelationId = obj.sub_rel_id;
          this.doctorID = obj.doc_reg_id;
          // New Additions End----------------


          this.clnt_sal = obj.salutation;
          this.presID = obj.pres_diag_id;
          this.clientFirstName = encrypt_decript.Decript(obj.first_name);
          if (obj.middle_name != undefined) {
            this.clientMidName = encrypt_decript.Decript(obj.middle_name);
          }
          this.clientLastName = encrypt_decript.Decript(obj.last_name);
          this.clientNumber = encrypt_decript.Decript(obj.mobile);
          this.clientAge = obj.age;
          this.clientGender = encrypt_decript.Decript(obj.gender);
          this.clientAddress1 = obj.address1;
          if (obj.address2 != undefined) {
            this.clientAddress2 = obj.address2;
          }
          this.clientZipCode = obj.zipcode;
          this.clientLocation = obj.location;
          this.clientCity = obj.city;
          this.clientState = obj.state;
          this.clientCountry = obj.country;
          if (obj.spl_name != undefined) {
            this.speclization = obj.spl_name;
          }
          if (obj.referred_by != undefined) {
            if (obj.referred_by != "null") {
              this.referedBy = obj.referred_by;
            } else {
              this.referedBy = "";
            }
          }
          this.appDate = obj.date;
          this.clickAppDate(this.appDate);
          this.visitSession = obj.session;
          this.startTime = this.gservice.get_time_form_session(obj.f_time, obj.session);
          this.pat_type = obj.pat_type;

          if (obj.lab_test != undefined) {
            for (var i = 0; i < obj.lab_test.length; i++) {
              this.labarray.push({
                test_id: obj.lab_test[i].diag_test_id,
                test_name: obj.lab_test[i].diag_test_name,
                price: obj.lab_test[i].cost,
                diag_type: obj.lab_test[i].type,
                TAT: obj.lab_test[i].Tat_val
              })

              this.labTestArray.push({
                test_id: obj.lab_test[i].diag_test_id,
                test_name: obj.lab_test[i].diag_test_name,
                price: obj.lab_test[i].cost,
                diag_type: obj.lab_test[i].type,
                TAT: obj.lab_test[i].Tat_val
              })

              for (var k = 0; k < this.labTest_array.length; k++) {
                if (this.labTest_array[k].diag_tests_id == obj.lab_test[i].diag_test_id) {
                  this.filteredLabArray[k].checked = true;
                }
              }
              for (var k = 0; k < this.microTest_array.length; k++) {
                if (this.microTest_array[k].diag_tests_id == obj.lab_test[i].diag_test_id) {
                  this.filteredMicroArray[k].checked = true;
                }
              }
            }
          }

          if (obj.radio_test != undefined) {
            for (var i = 0; i < obj.radio_test.length; i++) {
              this.radioarray.push({
                test_id: obj.radio_test[i].diag_test_id,
                test_name: obj.radio_test[i].diag_test_name,
                price: obj.radio_test[i].cost,
                diag_type: obj.radio_test[i].type,
                TAT: obj.radio_test[i].Tat_val
              })

              this.radioTestArray.push({
                test_id: obj.radio_test[i].diag_test_id,
                test_name: obj.radio_test[i].diag_test_name,
                price: obj.radio_test[i].cost,
                diag_type: obj.radio_test[i].type,
                TAT: obj.radio_test[i].Tat_val
              })

              for (var k = 0; k < this.radioTest_array.length; k++) {
                if (this.radioTest_array[k].diag_tests_id == obj.radio_test[i].diag_test_id) {
                  this.filteredRadioArray[k].checked = true;
                }
              }
            }
          }

          if (obj.profile_test != undefined) {
            for (var i = 0; i < obj.profile_test.length; i++) {
              this.selected_profiles.push({
                diag_profile_id: obj.profile_test[i].diag_test_id,
                profile_name: obj.profile_test[i].diag_test_name,
                price: obj.profile_test[i].cost,
                diag_type: obj.profile_test[i].type,
                TAT: obj.profile_test[i].Tat_val
              })
              this.selected_profileIds.push(obj.profile_test[i].diag_test_id)

              for (var k = 0; k < this.profiles.length; k++) {
                if (this.profiles[k].diag_profile_id == obj.profile_test[i].diag_test_id) {
                  this.filteredProfilesArray[k].checked = true;
                }
              }
            }
          }
          if (obj.packages != undefined) {
            for (var i = 0; i < obj.packages.length; i++) {
              this.selected_packages.push({
                health_pkg_id: obj.packages[i].diag_test_id,
                package_name: obj.packages[i].diag_test_name,
                price: obj.packages[i].cost,
                diag_type: obj.packages[i].type,
                TAT: obj.packages[i].Tat_val
              })
              this.selected_packageIds.push(obj.packages[i].diag_test_id)

              for (var k = 0; k < this.packages_array.length; k++) {
                if (this.packages_array[k].health_pkg_id == obj.packages[i].diag_test_id) {
                  this.filteredPackagesArray[k].checked = true;
                }
              }
            }
          }

          console.log("this.labarray", this.labarray)
          console.log("this.radioarray", this.radioarray)
          console.log("this.selected_profiles", this.selected_profiles)
          console.log("this.selected_packages", this.selected_packages)

          if (obj.payments != undefined) {
            for (var i = 0; i < obj.payments.length; i++) {
              this.paymentsarray.push({
                pay_id: obj.payments[i].pay_id,
                pay_desc: obj.payments[i].pay_desc,
                amount: parseFloat(obj.payments[i].amount.toString()).toFixed(2),
              })
            }
          }

          if (obj.bills != undefined && obj.bills.length != 0) {
            this.balance = "0.00";
            console.log("PRICE TABLE " + JSON.stringify(obj.bills))
            this.remaingBalance = obj.balance;
            this.bill_amount = encrypt_decript.Decript(obj.bill_amount);
            this.discount_amt = obj.discount != undefined ? obj.discount : "0.00";

            this.advance = parseFloat(obj.paid_amount).toFixed(2);
            this.billid = obj.bill_id;
            this.finyear = obj.fin_year;
            for (var i = 0; i < obj.bills.length; i++) {
              this.balance = (parseFloat(this.balance) + parseFloat(obj.bills[i].amount_txt)).toFixed(2);
              // var exists = this.isTestAvailable(obj.bills[i].test_id, obj.bills[i].test_cat);
              var exists = true;
              if (exists == true) {
                this.priceTable.push({
                  sno: this.sno,
                  bill_det_id: obj.bills[i].bill_details_id,
                  test_id: obj.bills[i].test_id,
                  test_name: obj.bills[i].test_name,
                  price: obj.bills[i].fee,
                  amount_txt: parseFloat(obj.bills[i].amount_txt).toFixed(2),
                  concession: parseFloat(obj.bills[i].concession),
                  fee: parseFloat(obj.bills[i].fee).toFixed(2),
                  charge_type: obj.bills[i].test_name,
                  type_of_test: obj.bills[i].test_cat,
                  pay_type: obj.bills[i].pay_type,
                  paytype: obj.bills[i].pay_id,
                  insurer: this.insuranceId,
                  quantity: obj.bills[i].quantity,
                  priority: this.priority
                });

                if (obj.bills[i].test_cat == 'package') {
                  this.addPackageBillArray.push({
                    sno: this.sno,
                    bill_det_id: obj.bills[i].bill_details_id,
                    test_id: obj.bills[i].test_id,
                    test_name: obj.bills[i].test_name,
                    price: obj.bills[i].fee,
                    amount_txt: parseFloat(obj.bills[i].amount_txt).toFixed(2),
                    concession: parseFloat(obj.bills[i].concession),
                    fee: parseFloat(obj.bills[i].fee).toFixed(2),
                    charge_type: obj.bills[i].test_name,
                    type_of_test: obj.bills[i].test_cat,
                    pay_type: obj.bills[i].pay_type,
                    paytype: obj.bills[i].pay_id,
                    insurer: this.insuranceId,
                    quantity: obj.bills[i].quantity,
                    priority: this.priority
                  });
                  console.log("addPackageBillArray", this.addPackageBillArray);
                  this.changePackageAmount(1, obj.bills[i].amount_txt, parseFloat(obj.bills[i].concession), obj.bills[i].test_id, obj.bills[i].test_cat)
                } else {
                  this.addBillArray.push({
                    sno: this.sno,
                    bill_det_id: obj.bills[i].bill_details_id,
                    test_id: obj.bills[i].test_id,
                    test_name: obj.bills[i].test_name,
                    price: obj.bills[i].fee,
                    amount_txt: parseFloat(obj.bills[i].amount_txt).toFixed(2),
                    concession: parseFloat(obj.bills[i].concession),
                    fee: parseFloat(obj.bills[i].fee).toFixed(2),
                    charge_type: obj.bills[i].test_name,
                    type_of_test: obj.bills[i].test_cat,
                    pay_type: obj.bills[i].pay_type,
                    paytype: obj.bills[i].pay_id,
                    insurer: this.insuranceId,
                    quantity: obj.bills[i].quantity,
                    priority: this.priority
                  });
                  console.log("addBillArray", this.addBillArray);
                  this.changeTestAmount(1, obj.bills[i].amount_txt, parseFloat(obj.bills[i].concession), obj.bills[i].test_id, obj.bills[i].test_cat);
                }
                this.sno += 1;
                
              }
            }
            if(this.priceTable.length != 0){
              for(var d = 0; d < this.priceTable.length; d++){
                this.discount_amt = (
                  parseFloat(this.discount_amt) +
                  parseFloat(this.priceTable[d].amount_txt) *
                  parseFloat(this.priceTable[d].quantity) *
                  (parseFloat(this.priceTable[d].concession) / 100)
                ).toFixed(2);
              }
            }           
          }
        }, error => {
          this.toastr.error(Message_data.error);
        });
  }
  //This function checks whether the test exists in any of the test array
  isTestAvailable(testid, type) {
    var exists;
    if (type == "Blood") {
      if (this.bloodTestArray.length != 0) {
        for (var k = 0; k < this.bloodTestArray.length; k++) {
          if (testid == this.bloodTestArray[k].test_id) {
            exists = true;
          }
        }
      }
    }

    if (type == "Urine") {
      if (this.urineTestArray.length != 0) {
        for (var k = 0; k < this.urineTestArray.length; k++) {
          if (testid == this.urineTestArray[k].test_id) {
            exists = true;
          }
        }
      }
    }

    if (type == "Feaces") {
      if (this.feaceTestArray.length != 0) {
        for (var k = 0; k < this.feaceTestArray.length; k++) {
          if (testid == this.feaceTestArray[k].test_id) {
            exists = true;
          }
        }
      }
    }

    if (type == "Scan") {
      if (this.scanTestArray.length != 0) {
        for (var k = 0; k < this.scanTestArray.length; k++) {
          if (testid == this.scanTestArray[k].test_id) {
            exists = true;
          }
        }
      }
    }

    if (type == "Xray") {
      if (this.XrayTestArray.length != 0) {
        for (var k = 0; k < this.XrayTestArray.length; k++) {
          if (testid == this.XrayTestArray[k].test_id) {
            exists = true;
          }
        }
      }
    }

    if (type == "Ultra") {
      if (this.ultraTestArray.length != 0) {
        for (var k = 0; k < this.ultraTestArray.length; k++) {
          if (testid == this.ultraTestArray[k].test_id) {
            exists = true;
          }
        }
      }
    }

    if (type == "Culture") {
      if (this.CultureTestArray.length != 0) {
        for (var k = 0; k < this.CultureTestArray.length; k++) {
          if (testid == this.CultureTestArray[k].test_id) {
            exists = true;
          }
        }
      }
    }

    if (type == "Biopsy") {
      if (this.biopsyTestArray.length != 0) {
        for (var k = 0; k < this.biopsyTestArray.length; k++) {
          if (testid == this.biopsyTestArray[k].test_id) {
            exists = true;
          }
        }
      }
    }

    if (type == "Package") {
      if (this.selTestGroupArray.length != 0) {
        for (var k = 0; k < this.selTestGroupArray.length; k++) {
          if (testid == this.selTestGroupArray[k].test_id) {
            exists = true;
          }
        }
      }
    }
    return exists;
  }

  // concession calculation
  changeAmount(quantity, amount, concession, testId, type_of_test) {
    var perce: any = null;
    perce = (concession / 100);
    var amt = amount;
    if (quantity > 1) {
      amt = amt * quantity;
    }
    var amt_cons = this.amountAfternoon;
    var net_pay = parseFloat(amt) * parseFloat(perce.toString());
    var tot = amt - net_pay;
    if (isNaN(tot)) {
      tot = 0;
    }
    this.amountAfternoon = Math.round(tot);
    var balance = "0";
    for (var i = 0; i < this.priceTable.length; i++) {
      if (testId == this.priceTable[i].test_id && type_of_test == this.priceTable[i].type_of_test) {
        this.priceTable[i].fee = Math.round(tot);
        this.priceTable[i].fee = parseFloat(this.priceTable[i].fee).toFixed(2);
      }
    }
    this.balance = "0.00";
    this.bill_amount = "0.00";
    this.discount_amt = "0.00";

    for (var i = 0; i < this.priceTable.length; i++) {
      // this.balance = (parseFloat(this.balance) + parseFloat(this.priceTable[i].fee)).toFixed(2);
      if (this.priceTable[i].test_id == testId && type_of_test == this.priceTable[i].type_of_test) {
        this.priceTable[i].quantity = quantity;
      }

      this.balance = (
        parseFloat(this.balance) +
        parseFloat(this.priceTable[i].amount_txt) *
        parseFloat(this.priceTable[i].quantity)
      ).toFixed(2);

      this.discount_amt = (
        parseFloat(this.discount_amt) +
        parseFloat(this.priceTable[i].amount_txt) *
        parseFloat(this.priceTable[i].quantity) *
        (parseFloat(this.priceTable[i].concession) / 100)
      ).toFixed(2);

      this.bill_amount = (
        parseFloat(this.bill_amount) + parseFloat(this.priceTable[i].fee)
      ).toFixed(2);
    }
    
    if(type_of_test == 'Package'){
      this.changePackageAmount(quantity, amount, concession, testId, type_of_test);      
    } else {
      this.changeTestAmount(quantity, amount, concession, testId, type_of_test);   
    }
    this.changeAdvance();
  }

  changeAdvance() { //add the bill details
    this.paidCalculation();
    if (this.advance != undefined && this.advance != "") {
      this.remaingBalance = (parseFloat(this.bill_amount) - parseFloat(this.advance)).toFixed(2);

      if (parseFloat(this.advance) > parseFloat(this.bill_amount)) {
        if (this.paymentsarray.length == 0) {
          this.amountval = parseFloat(this.bill_amount).toFixed(2);
        } else {
          var payArr_Amt = 0;
          for (var i = 0; i < this.paymentsarray.length; i++) {
            payArr_Amt += parseFloat(this.paymentsarray[i].amount);
          }

          if (payArr_Amt > parseFloat(this.bill_amount)) {
            this.amountval = parseFloat(this.bill_amount).toFixed(2);
          } else {
            this.amountval = (parseFloat(this.bill_amount) - payArr_Amt).toFixed(2);
          }
        }
      } else {
        if (this.paymentsarray.length == 0) {
          this.amountval = parseFloat(this.advance).toFixed(2);
        } else {
          var payArr_Amt = 0;
          for (var i = 0; i < this.paymentsarray.length; i++) {
            payArr_Amt += parseFloat(this.paymentsarray[i].amount);
          }

          if (payArr_Amt > parseFloat(this.advance)) {
            this.amountval = parseFloat(this.advance).toFixed(2);
          } else {
            this.amountval = (parseFloat(this.advance) - payArr_Amt).toFixed(2);
          }
        }
      }

      // if (parseFloat(this.advance ) > parseFloat(this.bill_amount)) {
      //   this.amountval = parseFloat(this.bill_amount).toFixed(2);

      // } else {
      //   this.amountval = parseFloat(this.advance).toFixed(2);

      // }
    } else {
      this.advance = "0.00";
      this.remaingBalance = this.bill_amount;
    }
  }

  paidCalculation() {
    var temp_paidBill: any = parseFloat(this.advance);
    if (this.addBillArray != undefined && this.addBillArray.length != 0 && this.test_bill_amount != '0.00') {
      if (temp_paidBill != '') {
        console.log(temp_paidBill + " " + this.test_bill_amount)
        if (temp_paidBill >= this.test_bill_amount) {
          this.test_paidBill = this.test_bill_amount;
          this.test_remainingBalance = '0.00';
          temp_paidBill = (parseFloat(temp_paidBill) - parseFloat(this.test_bill_amount)).toFixed(2);

        } else {
          this.test_paidBill = parseFloat(temp_paidBill).toFixed(2);
          this.test_remainingBalance = (parseFloat(this.test_bill_amount) - parseFloat(temp_paidBill)).toFixed(2);
          temp_paidBill = '0.00';
        }
      }
    }

    if (this.addPackageBillArray != undefined && this.addPackageBillArray.length != 0 && this.package_bill_amount != '0.00') {
      if (temp_paidBill != '') {
        if (temp_paidBill >= this.package_bill_amount) {
          this.package_paidBill = this.package_bill_amount;
          this.package_remainingBalance = '0.00';
          temp_paidBill = (parseFloat(temp_paidBill) - parseFloat(this.package_bill_amount)).toFixed(2);
        } else {
          this.package_paidBill = parseFloat(temp_paidBill).toFixed(2);
          this.package_remainingBalance = Math.ceil(parseFloat(this.package_bill_amount) - parseFloat(temp_paidBill)).toFixed(2);
          temp_paidBill = '0.00';
        }
      }
    }
  }

  paymentArrayCalculation() {
    this.test_paid_percentage = [];
    this.test_paymentsarray = [];
    this.package_paid_percentage = [];
    this.package_paymentsarray = [];
    this.test_pay_arrry_tot_amt = '0.00';
    this.testEqualSplitValue = '0.00';
    this.package_pay_arrry_tot_amt = '0.00';
    this.packageEqualSplitValue = '0.00';

    console.log("Function Called.....");
    if (this.paymentsarray.length != 0) {
      if (this.addBillArray.length != 0) {
        console.log("paymentsarray ", this.paymentsarray)
        console.log("test_bill_amount ", this.test_bill_amount)
        console.log("test_paidBill ", this.test_paidBill)
        if (this.test_bill_amount != '' && this.test_bill_amount != '0.00') {
          if (this.test_paidBill != '' && this.test_paidBill != '0.00') {
            for (var i = 0; i < this.paymentsarray.length; i++) {
              if (this.paymentsarray[i].amount != '0.00') {
                this.test_paymentsarray.push({
                  pay_id: this.paymentsarray[i].pay_id,
                  pay_desc: this.paymentsarray[i].pay_desc,
                  amount: Math.round((this.test_bill_amount / this.bill_amount) * parseFloat(this.paymentsarray[i].amount)).toFixed(2)
                })
              }
            }

            if (this.test_paymentsarray.length != 0) {
              for (var i = 0; i < this.test_paymentsarray.length; i++) {
                this.test_pay_arrry_tot_amt = parseFloat(this.test_pay_arrry_tot_amt) + parseFloat(this.test_paymentsarray[i].amount);
              }
              console.log("test_pay_arrry_tot_amt", this.test_pay_arrry_tot_amt);
              if (this.test_pay_arrry_tot_amt < this.test_paidBill) {
                this.testEqualSplitValue = ((parseFloat(this.test_paidBill) - parseFloat(this.test_pay_arrry_tot_amt)) / this.test_paymentsarray.length).toFixed(2);
                console.log("testEqualSplitValue", this.testEqualSplitValue);
                for (var i = 0; i < this.test_paymentsarray.length; i++) {
                  this.test_paymentsarray[i].amount = (parseFloat(this.test_paymentsarray[i].amount) + parseFloat(this.testEqualSplitValue)).toFixed(2);
                  console.log(this.test_paymentsarray[i].amount);
                }
              } else if (this.test_pay_arrry_tot_amt > this.test_paidBill) {
                this.testEqualSplitValue = ((parseFloat(this.test_pay_arrry_tot_amt) - parseFloat(this.test_paidBill)) / this.test_paymentsarray.length).toFixed(2);
                console.log("testEqualSplitValue", this.testEqualSplitValue);
                for (var i = 0; i < this.test_paymentsarray.length; i++) {
                  this.test_paymentsarray[i].amount = (parseFloat(this.test_paymentsarray[i].amount) - parseFloat(this.testEqualSplitValue)).toFixed(2);
                  console.log(this.test_paymentsarray[i].amount);
                }
              }
            }
            console.log('DiagPaymentsArray', this.test_paymentsarray);
          }
        }
      }

      if (this.addPackageBillArray.length != 0) {
        if (this.package_bill_amount != '' && this.package_bill_amount != '0.00') {
          if (this.package_paidBill != '' && this.package_paidBill != '0.00') {
            for (var i = 0; i < this.paymentsarray.length; i++) {
              if (this.paymentsarray[i].amount != '0.00') {
                this.package_paymentsarray.push({
                  pay_id: this.paymentsarray[i].pay_id,
                  pay_desc: this.paymentsarray[i].pay_desc,
                  amount: Math.round((this.package_bill_amount / this.bill_amount) * parseFloat(this.paymentsarray[i].amount)).toFixed(2)
                })
              }
            }

            if (this.package_paymentsarray.length != 0) {
              for (var i = 0; i < this.package_paymentsarray.length; i++) {
                this.package_pay_arrry_tot_amt = parseFloat(this.package_pay_arrry_tot_amt) + parseFloat(this.package_paymentsarray[i].amount);
              }
              console.log("package_pay_arrry_tot_amt", this.package_pay_arrry_tot_amt);
              if (this.package_pay_arrry_tot_amt < this.package_paidBill) {
                this.packageEqualSplitValue = ((parseFloat(this.package_paidBill) - parseFloat(this.package_pay_arrry_tot_amt)) / this.package_paymentsarray.length).toFixed(2);
                console.log("packageEqualSplitValue", this.packageEqualSplitValue);
                for (var i = 0; i < this.package_paymentsarray.length; i++) {
                  this.package_paymentsarray[i].amount = (parseFloat(this.package_paymentsarray[i].amount) + parseFloat(this.packageEqualSplitValue)).toFixed(2);
                  console.log(this.package_paymentsarray[i].amount);
                }
              } else if (this.package_pay_arrry_tot_amt > this.package_paidBill) {
                this.packageEqualSplitValue = ((parseFloat(this.package_pay_arrry_tot_amt) - parseFloat(this.package_paidBill)) / this.package_paymentsarray.length).toFixed(2);
                console.log("packageEqualSplitValue", this.packageEqualSplitValue);
                for (var i = 0; i < this.package_paymentsarray.length; i++) {
                  this.package_paymentsarray[i].amount = (parseFloat(this.package_paymentsarray[i].amount) - parseFloat(this.packageEqualSplitValue)).toFixed(2);
                  console.log(this.package_paymentsarray[i].amount);
                }
              }
            }
            console.log('PackagePaymentsArray', this.package_paymentsarray);
          }
        }
      }
    }
  }

  changePay() {
    document.getElementById("paytype")!.style.borderColor = '#2ca8fe';
    this.insuranceFlag = true;
    this.tranFlag = true;
    this.cardFlag = true;
    this.chequeFlag = true;
    if (this.payType == "3") {
      this.insuranceFlag = false;
    } else if (this.payType == "4") {
      this.tranFlag = false;

    } else if (this.payType == "2") {
      this.tranFlag = false;
      this.cardFlag = false;

    } else if (this.payType == "5") {
      this.chequeFlag = false;

    } else {
      this.insuranceFlag = true;
    }

    for (var i = 0; i < this.paymentArray.length; i++) {
      if (this.payType == this.paymentArray[i].pay_id) {
        this.pay_desc = this.paymentArray[i].pay_desc;
        break;
      }
    }
  }

  getTestGroup(test) {
    if (test.length >= 3) {
      if (this.testGroupArray.length != 0) {
        if (this.testGroupArray.filter(o => o.package_name.toLowerCase().startsWith(test.toLowerCase()))) {
          var chg = this.testGroupArray.filter(o => o.package_name.toLowerCase().startsWith(test.toLowerCase()));
          for (var j = 0; j < this.testGroupArray.length; j++) {
            this.testGroupArray[j].show = true;
          }
          for (var i = 0; i < chg.length; i++) {
            for (var j = 0; j < this.testGroupArray.length; j++) {
              if (this.testGroupArray[j].package_name.includes(chg[i].package_name) == true) {
                this.testGroupArray[j].show = false;
              }
            }
          }
        } else {
        }
      }
    } else {
      for (var j = 0; j < this.testGroupArray.length; j++) {
        this.testGroupArray[j].show = false;
      }
    }
  }

  getTest(test) {
    if (test.length >= 3) {
      if (this.testNameArray.length != 0) {
        if (this.testNameArray.filter(o => o.test_name.toLowerCase().startsWith(test.toLowerCase()))) {
          var chg = this.testNameArray.filter(o => o.test_name.toLowerCase().startsWith(test.toLowerCase()));
          for (var j = 0; j < this.testNameArray.length; j++) {
            this.testNameArray[j].show = true;
          }
          for (var i = 0; i < chg.length; i++) {
            for (var j = 0; j < this.testNameArray.length; j++) {
              if (this.testNameArray[j].test_name.includes(chg[i].test_name) == true) {
                this.testNameArray[j].show = false;
              }
            }
          }
        } else {
        }
      }
    } else {
      for (var j = 0; j < this.testNameArray.length; j++) {
        this.testNameArray[j].show = false;
      }
    }
  }

  clickTest(e: any, data) {

    if (e == true) {
      for (var i = 0; i < this.profiles.length; i++) {
        if (this.profiles[i].diag_profile_id == data) {
          var results
          results = this.check_addedTests(this.profiles[i], this.labarray, this.radioarray, this.selected_packages);


          if (results != true) {
            this.profiles[i].checked = true;
            this.selected_profiles.push(this.profiles[i])
            this.selected_profileIds.push(this.profiles[i].diag_profile_id)
          } else {
            if (this.isTestVal == true) {
              this.toastr.error("Selected test is in this profile", null, { timeOut: 3000 });
            } else {
              this.toastr.error("The profile is in the selected package", null, { timeOut: 3000 });
            }
          }
        }
      }

    } else {
      for (var i = 0; i < this.profiles.length; i++) {
        if (this.profiles[i].diag_profile_id == data) {
          this.profiles[i].checked = false;
          for (var h = 0; h < this.selected_profiles.length; h++) {
            if (this.selected_profiles[h].diag_profile_id == data) {
              this.selected_profiles.splice(h, 1)
            }
          }

          for (var k = 0; k < this.selected_profileIds.length; k++) {
            if (this.selected_profileIds[k] == data) {
              this.selected_profileIds.splice(k, 1)
            }
          }
        }
      }
    }
    console.log("this.selected_profiles", this.selected_profiles)
  }




  diagTestChange() {
    if (this.diagTest != undefined) {
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/dtestdetails/',
        {
          diag_centre_id: this.diagId,
          test_type: this.diagTest
        },
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            if (obj.test_details != null) {
              if (Ignorecase_data("Blood Test", this.diagTest) == true) {
                if (this.bloodTestlistArray.length == 0) {
                  for (var i = 0; i < obj.test_details.length; i++) {
                    this.bloodTestlistArray.push({
                      test_id: obj.test_details[i].diag_test_id,
                      test_name: obj.test_details[i].test_name,
                      checked: false,
                      show: false,
                    });
                  }
                  this.testNameArray = this.bloodTestlistArray;
                } else {
                  this.testNameArray = this.bloodTestlistArray;
                }

              } else if (Ignorecase_data("Urine Test", this.diagTest) == true) {
                if (this.urineTestlistArray.length == 0) {
                  for (var i = 0; i < obj.test_details.length; i++) {
                    this.urineTestlistArray.push({
                      test_id: obj.test_details[i].diag_test_id,
                      test_name: obj.test_details[i].test_name,
                      checked: false,
                      show: false,
                    });
                  }
                  this.testNameArray = this.urineTestlistArray;
                } else {
                  this.testNameArray = this.urineTestlistArray;
                }

              } else if (Ignorecase_data("Faeces Test", this.diagTest) == true) {
                if (this.faceTestlistArray.length == 0) {
                  for (var i = 0; i < obj.test_details.length; i++) {
                    this.faceTestlistArray.push({
                      test_id: obj.test_details[i].diag_test_id,
                      test_name: obj.test_details[i].test_name,
                      checked: false,
                      show: false,
                    });
                  }
                  this.testNameArray = this.faceTestlistArray;
                } else {
                  this.testNameArray = this.faceTestlistArray;
                }

              } else if (Ignorecase_data("Scan", this.diagTest) == true) {
                if (this.scanTestlistArray.length == 0) {
                  for (var i = 0; i < obj.test_details.length; i++) {
                    this.scanTestlistArray.push({
                      test_id: obj.test_details[i].diag_test_id,
                      test_name: obj.test_details[i].test_name,
                      checked: false,
                      show: false,
                    });
                  }
                  this.testNameArray = this.scanTestlistArray;
                } else {
                  this.testNameArray = this.scanTestlistArray;
                }

              } else if (Ignorecase_data("X-ray", this.diagTest) == true) {
                if (this.xrayTestlistArray.length == 0) {
                  for (var i = 0; i < obj.test_details.length; i++) {
                    this.xrayTestlistArray.push({
                      test_id: obj.test_details[i].diag_test_id,
                      test_name: obj.test_details[i].test_name,
                      checked: false,
                      show: false,
                    });
                  }
                  this.testNameArray = this.xrayTestlistArray;
                } else {
                  this.testNameArray = this.xrayTestlistArray;
                }

              } else if (Ignorecase_data("Ultra Sound", this.diagTest) == true) {
                if (this.ultraTestlistArray.length == 0) {
                  for (var i = 0; i < obj.test_details.length; i++) {
                    this.ultraTestlistArray.push({
                      test_id: obj.test_details[i].diag_test_id,
                      test_name: obj.test_details[i].test_name,
                      checked: false,
                      show: false,
                    });
                  }
                  this.testNameArray = this.ultraTestlistArray;
                } else {
                  this.testNameArray = this.ultraTestlistArray;
                }
              } else if (Ignorecase_data("Electrocardio", this.diagTest) == true) {
                if (this.electroCardioTestlistArray.length == 0) {
                  for (var i = 0; i < obj.test_details.length; i++) {
                    this.electroCardioTestlistArray.push({
                      test_id: obj.test_details[i].diag_test_id,
                      test_name: obj.test_details[i].test_name,
                      checked: false,
                      show: false,
                    });
                  }
                  this.testNameArray = this.electroCardioTestlistArray;
                } else {
                  this.testNameArray = this.electroCardioTestlistArray;
                }
              } else if (Ignorecase_data("Culture", this.diagTest) == true) {
                if (this.cultureTestlistArray.length == 0) {
                  for (var i = 0; i < obj.test_details.length; i++) {
                    this.cultureTestlistArray.push({
                      test_id: obj.test_details[i].diag_test_id,
                      test_name: obj.test_details[i].test_name,
                      checked: false,
                      show: false,
                    });
                  }
                  this.testNameArray = this.cultureTestlistArray;
                } else {
                  this.testNameArray = this.cultureTestlistArray;
                }
              } else if (Ignorecase_data("Biopsy", this.diagTest) == true) {
                if (this.biopsyTestlistArray.length == 0) {
                  for (var i = 0; i < obj.test_details.length; i++) {
                    this.biopsyTestlistArray.push({
                      test_id: obj.test_details[i].diag_test_id,
                      test_name: obj.test_details[i].test_name,
                      checked: false,
                      show: false,
                    });
                  }
                  this.testNameArray = this.biopsyTestlistArray;
                } else {
                  this.testNameArray = this.biopsyTestlistArray;
                }
              }
            }
          },
          error => { });
    } else {
      this.testNameArray = [];
    }
  }

  diagTestGroupChange() {
    if (this.diagTestGroup == "package") {
      if (this.testGroupArray.length == 0) {
        this.getTestGroups();
      }
      this.diagtest_flag = true;

    } else {
      this.getDiagTestTypes();
      this.diagtest_flag = false;
    }
  }

  addTest() {
    var results;
    if (this.diagTestGroup == "test") {
      if (this.bloodTestlistArray.length != 0 || this.urineTestlistArray.length != 0 || this.faceTestlistArray.length != 0 || this.xrayTestlistArray.length != 0 || this.scanTestlistArray.length != 0 || this.ultraTestlistArray.length != 0 || this.cultureTestlistArray.length != 0 || this.biopsyTestlistArray.length != 0) {
        if (Ignorecase_data("Blood Test", this.diagTest) == true) {
          for (var i = 0; i < this.bloodTestlistArray.length; i++) {
            if (this.bloodTestlistArray[i].checked == true) {
              if (this.bloodTestArray.length != 0) {
                results = this.finekeyInArray(this.bloodTestArray, this.bloodTestlistArray[i].test_id);
                if (results != true) {
                  this.bloodTestArray.push({
                    test_id: this.bloodTestlistArray[i].test_id,
                    test_name: this.bloodTestlistArray[i].test_name,
                    diag_type: this.bloodTestlistArray[i].diag_type,
                  });
                }
              } else {
                this.bloodTestArray.push({
                  test_id: this.bloodTestlistArray[i].test_id,
                  test_name: this.bloodTestlistArray[i].test_name,
                  diag_type: this.bloodTestlistArray[i].diag_type,
                });
              }
            }
          }
          this.addAmount(this.bloodTestArray, "Blood");

        } else if (Ignorecase_data("Urine Test", this.diagTest) == true) {
          for (var i = 0; i < this.urineTestlistArray.length; i++) {
            if (this.urineTestlistArray[i].checked == true) {
              if (this.urineTestArray.length != 0) {
                results = this.finekeyInArray(this.urineTestArray, this.urineTestlistArray[i].test_id);
                if (results != true) {
                  this.urineTestArray.push({
                    test_id: this.urineTestlistArray[i].test_id,
                    test_name: this.urineTestlistArray[i].test_name,
                    diag_type: this.urineTestlistArray[i].diag_type,
                  });
                }
              } else {
                this.urineTestArray.push({
                  test_id: this.urineTestlistArray[i].test_id,
                  test_name: this.urineTestlistArray[i].test_name,
                  diag_type: this.urineTestlistArray[i].diag_type,
                });
              }
            }
          }
          this.addAmount(this.urineTestArray, "Urine");

        } else if (Ignorecase_data("Faeces Test", this.diagTest) == true) {
          for (var i = 0; i < this.faceTestlistArray.length; i++) {
            if (this.faceTestlistArray[i].checked == true) {
              if (this.feaceTestArray.length != 0) {
                results = this.finekeyInArray(this.feaceTestArray, this.faceTestlistArray[i].test_id);
                if (results != true) {
                  this.feaceTestArray.push({
                    test_id: this.faceTestlistArray[i].test_id,
                    test_name: this.faceTestlistArray[i].test_name,
                    diag_type: this.faceTestlistArray[i].diag_type,
                  });
                }
              } else {
                this.feaceTestArray.push({
                  test_id: this.faceTestlistArray[i].test_id,
                  test_name: this.faceTestlistArray[i].test_name,
                  diag_type: this.faceTestlistArray[i].diag_type,
                });
              }
            }
          }
          this.addAmount(this.feaceTestArray, "Feaces");

        } else if (Ignorecase_data("X-ray", this.diagTest) == true) {
          for (var i = 0; i < this.xrayTestlistArray.length; i++) {
            if (this.xrayTestlistArray[i].checked == true) {
              if (this.XrayTestArray.length != 0) {
                results = this.finekeyInArray(this.XrayTestArray, this.xrayTestlistArray[i].test_id);

                if (results != true) {
                  this.XrayTestArray.push({
                    test_id: this.xrayTestlistArray[i].test_id,
                    test_name: this.xrayTestlistArray[i].test_name,
                    diag_type: this.xrayTestlistArray[i].diag_type,
                  });
                }
              } else {
                this.XrayTestArray.push({
                  test_id: this.xrayTestlistArray[i].test_id,
                  test_name: this.xrayTestlistArray[i].test_name,
                  diag_type: this.xrayTestlistArray[i].diag_type,
                });
              }
            }
          }
          this.addAmount(this.XrayTestArray, "Xray");

        } else if (Ignorecase_data("Scan", this.diagTest) == true) {
          for (var i = 0; i < this.scanTestlistArray.length; i++) {
            if (this.scanTestlistArray[i].checked == true) {
              if (this.scanTestArray.length != 0) {
                results = this.finekeyInArray(this.scanTestArray, this.scanTestlistArray[i].test_id);

                if (results != true) {
                  this.scanTestArray.push({
                    test_id: this.scanTestlistArray[i].test_id,
                    test_name: this.scanTestlistArray[i].test_name,
                    diag_type: this.scanTestlistArray[i].diag_type,
                  });
                }
              } else {
                this.scanTestArray.push({
                  test_id: this.scanTestlistArray[i].test_id,
                  test_name: this.scanTestlistArray[i].test_name,
                  diag_type: this.scanTestlistArray[i].diag_type,
                });
              }
            }
          }
          this.addAmount(this.scanTestArray, "Scan");

        } else if (Ignorecase_data("Ultra Sound", this.diagTest) == true) {
          for (var i = 0; i < this.ultraTestlistArray.length; i++) {
            if (this.ultraTestlistArray[i].checked == true) {
              if (this.ultraTestArray.length != 0) {
                results = this.finekeyInArray(this.ultraTestArray, this.ultraTestlistArray[i].test_id);

                if (results != true) {
                  this.ultraTestArray.push({
                    test_id: this.ultraTestlistArray[i].test_id,
                    test_name: this.ultraTestlistArray[i].test_name,
                    diag_type: this.ultraTestlistArray[i].diag_type,
                  });
                }
              } else {
                this.ultraTestArray.push({
                  test_id: this.ultraTestlistArray[i].test_id,
                  test_name: this.ultraTestlistArray[i].test_name,
                  diag_type: this.ultraTestlistArray[i].diag_type,
                });
              }
            }
          }
          this.addAmount(this.ultraTestArray, "Ultra");

        } else if (Ignorecase_data("Electrocardio", this.diagTest) == true) {
          for (var i = 0; i < this.electroCardioTestlistArray.length; i++) {
            if (this.electroCardioTestlistArray[i].checked == true) {
              if (this.electroCardioTestArray.length != 0) {
                results = this.finekeyInArray(this.electroCardioTestArray, this.electroCardioTestlistArray[i].test_id);

                if (results != true) {
                  this.electroCardioTestArray.push({
                    test_id: this.electroCardioTestlistArray[i].test_id,
                    test_name: this.electroCardioTestlistArray[i].test_name,
                    diag_type: this.electroCardioTestlistArray[i].diag_type,
                  });
                }
              } else {
                this.electroCardioTestArray.push({
                  test_id: this.electroCardioTestlistArray[i].test_id,
                  test_name: this.electroCardioTestlistArray[i].test_name,
                  diag_type: this.electroCardioTestlistArray[i].diag_type,
                });
              }
            }
          }
          this.addAmount(this.electroCardioTestArray, "Electrocardio");

        } else if (Ignorecase_data("Culture", this.diagTest) == true) {
          for (var i = 0; i < this.cultureTestlistArray.length; i++) {
            if (this.cultureTestlistArray[i].checked == true) {
              if (this.CultureTestArray.length != 0) {
                results = this.finekeyInArray(this.CultureTestArray, this.cultureTestlistArray[i].test_id);

                if (results != true) {
                  this.CultureTestArray.push({
                    test_id: this.cultureTestlistArray[i].test_id,
                    test_name: this.cultureTestlistArray[i].test_name,
                    diag_type: this.cultureTestlistArray[i].diag_type,
                  });
                }
              } else {
                this.CultureTestArray.push({
                  test_id: this.cultureTestlistArray[i].test_id,
                  test_name: this.cultureTestlistArray[i].test_name,
                  diag_type: this.cultureTestlistArray[i].diag_type,
                });
              }
            }
          }
          this.addAmount(this.CultureTestArray, "Culture");

        } else if (Ignorecase_data("Biopsy", this.diagTest) == true) {
          for (var i = 0; i < this.biopsyTestlistArray.length; i++) {
            if (this.biopsyTestlistArray[i].checked == true) {
              if (this.biopsyTestArray.length != 0) {
                results = this.finekeyInArray(this.biopsyTestArray, this.biopsyTestlistArray[i].test_id);

                if (results != true) {
                  this.biopsyTestArray.push({
                    test_id: this.biopsyTestlistArray[i].test_id,
                    test_name: this.biopsyTestlistArray[i].test_name,
                    diag_type: this.biopsyTestlistArray[i].diag_type,
                  });
                }
              } else {
                this.biopsyTestArray.push({
                  test_id: this.biopsyTestlistArray[i].test_id,
                  test_name: this.biopsyTestlistArray[i].test_name,
                  diag_type: this.biopsyTestlistArray[i].diag_type,
                });
              }
            }
          }
          this.addAmount(this.biopsyTestArray, "Biopsy");
        }

        if (this.bloodTestArray.length != 0) {
          this.showBloodTestFlag = false;
        }
        if (this.urineTestArray.length != 0) {
          this.showUrineTestFlag = false;
        }
        if (this.feaceTestArray.length != 0) {
          this.showFeaceTestFlag = false;
        }
        if (this.XrayTestArray.length != 0) {
          this.showXrayTestFlag = false;
        }
        if (this.scanTestArray.length != 0) {
          this.showScanTestFlag = false;
        }
        if (this.ultraTestArray.length != 0) {
          this.showUltraTestFlag = false;
        }
        if (this.electroCardioTestArray.length != 0) {
          this.showElectroCardioTestFlag = false;
        }
        if (this.CultureTestArray.length != 0) {
          this.showCultureTestFlag = false;
        }
        if (this.biopsyTestArray.length != 0) {
          this.showBiopsyTestFlag = false;
        }
      }

    } else {
      for (var i = 0; i < this.testGroupArray.length; i++) {
        if (this.testGroupArray[i].checked == true) {
          if (this.selTestGroupArray.length != 0) {
            results = this.finekeyInArray(this.selTestGroupArray, this.testGroupArray[i].diag_packages_id);
            if (results != true) {
              this.selTestGroupArray.push({
                test_id: this.testGroupArray[i].diag_packages_id,
                test_name: this.testGroupArray[i].package_name,
                price: this.testGroupArray[i].price,
                //diag_type: this.testGroupArray[i].diag_type,
              });
            }
          } else {
            this.selTestGroupArray.push({
              test_id: this.testGroupArray[i].diag_packages_id,
              test_name: this.testGroupArray[i].package_name,
              price: this.testGroupArray[i].price,
              //diag_type: this.testGroupArray[i].diag_type,
            });
          }
        }
      }
      this.addAmount(this.selTestGroupArray, "Package");

      if (this.selTestGroupArray.length != 0) {
        this.showTestGroupFlag = false;
      }
    }

  }

  addAmount(test, type_of_test) {
    if (type_of_test != "Profile" && type_of_test != "Package") {
      var subTest;

      for (var i = 0; i < test.length; i++) {
        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/tbyid',
          {
            test_id: test[i].test_id,
            type: "diagnosis",
            country: "IN"
          },
          { headers: headers })
          .subscribe(
            response => {
              var obj = JSON.parse(JSON.stringify(response));
              console.log("priceTable------->", obj)
              this.amount = obj.price;
              var perce = this.concession / 100;
              var amt = this.amount;
              var amt_cons = this.amountAfternoon;
              var net_pay = parseFloat(amt) * parseFloat(perce.toString());
              var tot = amt - net_pay;
              if (isNaN(tot)) {
                tot = 0;
              }
              var paydesc;
              if (this.paymentArray.length != 0) {
                for (var i = 0; i < this.paymentArray.length; i++) {
                  if (this.payType == this.paymentArray[i].pay_id) {
                    paydesc = this.paymentArray[i].pay_desc;
                  }
                }
              }
              this.amountAfternoon = Math.round(tot);
              var results;
              if (this.priceTable.length != 0) {
                for (var j = 0; j < this.priceTable.length; j++) {
                  results = this.finekeyInArray_frPriceTab(this.priceTable, obj.diag_tests_id, type_of_test);

                  if (results != true) {
                    var amt_txt = parseFloat(this.amount).toFixed(2) == "NaN" ? '' : parseFloat(this.amount).toFixed(2);
                    this.priceTable.push({
                      sno: this.sno,
                      test_id: obj.diag_tests_id,
                      test_name: obj.test_name,
                      price: obj.price,
                      amount_txt: amt_txt,
                      concession: parseFloat(this.concession),
                      fee: parseFloat(this.amountAfternoon).toFixed(2),
                      charge_type: obj.test_name,
                      type_of_test: type_of_test,
                      pay_type: paydesc,
                      paytype: this.payType,
                      insurer: this.insuranceId,
                      quantity: 1,
                      priority: this.priority,
                    });
                    this.addBillArray.push({
                      sno: this.sno,
                      test_id: obj.diag_tests_id,
                      test_name: obj.test_name,
                      price: obj.price,
                      amount_txt: amt_txt,
                      concession: parseFloat(this.concession),
                      fee: parseFloat(this.amountAfternoon).toFixed(2),
                      charge_type: obj.test_name,
                      type_of_test: type_of_test,
                      pay_type: paydesc,
                      paytype: this.payType,
                      insurer: this.insuranceId,
                      quantity: 1,
                      priority: this.priority,
                    });
                    this.changeTestAmount(1, amt_txt, parseFloat(this.concession), obj.diag_tests_id, type_of_test);
                    this.changeAmount(1, amt_txt, parseFloat(this.concession), obj.diag_tests_id, type_of_test);
                    this.sno += 1;
                  }
                }

              } else {
                var amt_txt = parseFloat(this.amount).toFixed(2) == "NaN" ? '' : parseFloat(this.amount).toFixed(2);
                this.priceTable.push({
                  sno: this.sno,
                  test_id: obj.diag_tests_id,
                  test_name: obj.test_name,
                  price: obj.price,
                  amount_txt: amt_txt,
                  concession: parseFloat(this.concession),
                  fee: parseFloat(this.amountAfternoon).toFixed(2),
                  charge_type: obj.test_name,
                  type_of_test: type_of_test,
                  pay_type: paydesc,
                  paytype: this.payType,
                  insurer: this.insuranceId,
                  quantity: 1,
                  priority: this.priority,
                });

                this.addBillArray.push({
                  sno: this.sno,
                  test_id: obj.diag_tests_id,
                  test_name: obj.test_name,
                  price: obj.price,
                  amount_txt: amt_txt,
                  concession: parseFloat(this.concession),
                  fee: parseFloat(this.amountAfternoon).toFixed(2),
                  charge_type: obj.test_name,
                  type_of_test: type_of_test,
                  pay_type: paydesc,
                  paytype: this.payType,
                  insurer: this.insuranceId,
                  quantity: 1,
                  priority: this.priority,
                });

                this.sno += 1;
                this.changeTestAmount(1, amt_txt, parseFloat(this.concession), obj.diag_tests_id, type_of_test);
                this.changeAmount(1, amt_txt, parseFloat(this.concession), obj.diag_tests_id, type_of_test);
              }

              this.balance = "0.00";
              for (var i = 0; i < this.priceTable.length; i++) {
                this.balance = (parseFloat(this.balance) + parseFloat(this.priceTable[i].amount_txt)).toFixed(2);
              }
              this.changeAdvance();
            });
      }
      this.saveFlag = false;
    } else if (type_of_test == "Package") {
      for (var i = 0; i < test.length; i++) {
        this.amount = test[i].price;
        var perce = this.concession / 100;
        var amt = this.amount;
        var amt_cons = this.amountAfternoon;
        var net_pay = parseFloat(amt) * parseFloat(perce.toString());
        var tot = amt - net_pay;
        if (isNaN(tot)) {
          tot = 0;
        }

        this.amountAfternoon = Math.round(tot);
        this.amountAfternoon = Math.round(tot);

        var results;
        if (this.priceTable.length != 0) {
          for (var j = 0; j < this.priceTable.length; j++) {
            // results = this.finekeyInArray(this.priceTable, test[i].test_id);
            results = this.finekeyInArray_frPriceTab(this.priceTable, test[i].test_id, type_of_test);
            if (results != true) {
              var amt_txt = parseFloat(this.amount).toFixed(2) == "NaN" ? '' : parseFloat(this.amount).toFixed(2);
              this.priceTable.push({
                sno: this.sno,
                test_id: test[i].test_id,
                test_name: test[i].test_name,
                price: amt,
                amount_txt: amt_txt,
                concession: parseFloat(this.concession),
                fee: parseFloat(this.amountAfternoon).toFixed(2),
                charge_type: test[i].test_name,
                type_of_test: type_of_test,
                pay_type: this.pay_desc,
                paytype: this.payType,
                insurer: this.insuranceId,
                quantity: 1,
                priority: this.priority,
              });
              this.addPackageBillArray.push({
                sno: this.sno,
                test_id: test[i].test_id,
                test_name: test[i].test_name,
                price: amt,
                amount_txt: amt_txt,
                concession: parseFloat(this.concession),
                fee: parseFloat(this.amountAfternoon).toFixed(2),
                charge_type: test[i].test_name,
                type_of_test: type_of_test,
                pay_type: this.pay_desc,
                paytype: this.payType,
                insurer: this.insuranceId,
                quantity: 1,
                priority: this.priority,
              });

              this.changePackageAmount(1, amt_txt, parseFloat(this.concession), test[i].test_id, type_of_test);
              this.changeAmount(1, amt_txt, parseFloat(this.concession), test[i].test_id, type_of_test);
              this.sno += 1;
            }
          }
          console.log(this.priceTable);

        } else {
          var amt_txt = parseFloat(this.amount).toFixed(2) == "NaN" ? '' : parseFloat(this.amount).toFixed(2);
          this.priceTable.push({
            sno: this.sno,
            test_id: test[i].test_id,
            test_name: test[i].test_name,
            price: amt,
            amount_txt: amt_txt,
            concession: parseFloat(this.concession),
            fee: parseFloat(this.amountAfternoon).toFixed(2),
            charge_type: test[i].test_name,
            type_of_test: type_of_test,
            pay_type: this.pay_desc,
            paytype: this.payType,
            insurer: this.insuranceId,
            quantity: 1,
            priority: this.priority,
          });

          this.addPackageBillArray.push({
            sno: this.sno,
            test_id: test[i].test_id,
            test_name: test[i].test_name,
            price: amt,
            amount_txt: amt_txt,
            concession: parseFloat(this.concession),
            fee: parseFloat(this.amountAfternoon).toFixed(2),
            charge_type: test[i].test_name,
            type_of_test: type_of_test,
            pay_type: this.pay_desc,
            paytype: this.payType,
            insurer: this.insuranceId,
            quantity: 1,
            priority: this.priority,
          });
          this.changePackageAmount(1, amt_txt, parseFloat(this.concession), test[i].test_id, type_of_test);
          this.changeAmount(1, amt_txt, parseFloat(this.concession), test[i].test_id, type_of_test);
          this.sno += 1;
        }
      }
      this.balance = "0.00";
      if (this.priceTable.length != 0) {
        for (var i = 0; i < this.priceTable.length; i++) {
          this.balance = (parseFloat(this.balance) + parseFloat(this.priceTable[i].amount_txt)).toFixed(2);
        }
        this.changeAdvance();
      }
      this.saveFlag = false;

    } else {
      for (var i = 0; i < test.length; i++) {
        this.amount = test[i].price;
        var perce = this.concession / 100;
        var amt = this.amount;
        var amt_cons = this.amountAfternoon;
        var net_pay = parseFloat(amt) * parseFloat(perce.toString());
        var tot = amt - net_pay;
        if (isNaN(tot)) {
          tot = 0;
        }

        this.amountAfternoon = Math.round(tot);
        this.amountAfternoon = Math.round(tot);

        var results;
        if (this.priceTable.length != 0) {
          for (var j = 0; j < this.priceTable.length; j++) {
            // results = this.finekeyInArray(this.priceTable, test[i].test_id);
            results = this.finekeyInArray_frPriceTab(this.priceTable, test[i].test_id, type_of_test);
            if (results != true) {
              var amt_txt = parseFloat(this.amount).toFixed(2) == "NaN" ? '' : parseFloat(this.amount).toFixed(2);
              this.priceTable.push({
                sno: this.sno,
                test_id: test[i].test_id,
                test_name: test[i].test_name,
                price: amt,
                amount_txt: amt_txt,
                concession: parseFloat(this.concession),
                fee: parseFloat(this.amountAfternoon).toFixed(2),
                charge_type: test[i].test_name,
                type_of_test: type_of_test,
                pay_type: this.pay_desc,
                paytype: this.payType,
                insurer: this.insuranceId,
                quantity: 1,
                priority: this.priority,
              });
              this.addBillArray.push({
                sno: this.sno,
                test_id: test[i].test_id,
                test_name: test[i].test_name,
                price: amt,
                amount_txt: amt_txt,
                concession: parseFloat(this.concession),
                fee: parseFloat(this.amountAfternoon).toFixed(2),
                charge_type: test[i].test_name,
                type_of_test: type_of_test,
                pay_type: this.pay_desc,
                paytype: this.payType,
                insurer: this.insuranceId,
                quantity: 1,
                priority: this.priority,
              });
              this.changeTestAmount(1, amt_txt, parseFloat(this.concession), test[i].test_id, type_of_test);
              this.changeAmount(1, amt_txt, parseFloat(this.concession), test[i].test_id, type_of_test);
              this.sno += 1;
            }
          }
          console.log(this.priceTable);

        } else {
          var amt_txt = parseFloat(this.amount).toFixed(2) == "NaN" ? '' : parseFloat(this.amount).toFixed(2);
          this.priceTable.push({
            sno: this.sno,
            test_id: test[i].test_id,
            test_name: test[i].test_name,
            price: amt,
            amount_txt: amt_txt,
            concession: parseFloat(this.concession),
            fee: parseFloat(this.amountAfternoon).toFixed(2),
            charge_type: test[i].test_name,
            type_of_test: type_of_test,
            pay_type: this.pay_desc,
            paytype: this.payType,
            insurer: this.insuranceId,
            quantity: 1,
            priority: this.priority,
          });
          this.addBillArray.push({
            sno: this.sno,
            test_id: test[i].test_id,
            test_name: test[i].test_name,
            price: amt,
            amount_txt: amt_txt,
            concession: parseFloat(this.concession),
            fee: parseFloat(this.amountAfternoon).toFixed(2),
            charge_type: test[i].test_name,
            type_of_test: type_of_test,
            pay_type: this.pay_desc,
            paytype: this.payType,
            insurer: this.insuranceId,
            quantity: 1,
            priority: this.priority,
          });
          this.sno += 1;
          this.changeTestAmount(1, amt_txt, parseFloat(this.concession), test[i].test_id, type_of_test);
          this.changeAmount(1, amt_txt, parseFloat(this.concession), test[i].test_id, type_of_test);
        }
      }
      this.balance = "0.00";
      if (this.priceTable.length != 0) {
        for (var i = 0; i < this.priceTable.length; i++) {
          this.balance = (parseFloat(this.balance) + parseFloat(this.priceTable[i].amount_txt)).toFixed(2);
        }
        this.changeAdvance();
      }
      this.saveFlag = false;
    }
  }

  changeTestAmount(quantity, amount, concession, testId, type_of_test) {
    var perce;
    perce = (concession / 100);
    var amt = amount;
    if (quantity > 1) {
      amt = amt * quantity;
    }
    var amt_cons = this.amountAfternoon;
    var net_pay = parseFloat(amt) * parseFloat(perce.toString());
    var tot = amt - net_pay;
    if (isNaN(tot)) {
      tot = 0;
    }
    this.amountAfternoon = Math.round(tot);
    var balance = "0";
    for (var i = 0; i < this.addBillArray.length; i++) {
      if (testId == this.addBillArray[i].test_id && type_of_test == this.addBillArray[i].type_of_test) {
        this.addBillArray[i].fee = Math.round(tot);
        this.addBillArray[i].fee = parseFloat(this.addBillArray[i].fee).toFixed(2);
      }
    }
    this.test_balance = "0.00";
    this.test_bill_amount = "0.00";
    this.test_discount_amt = "0.00";
    for (var i = 0; i < this.addBillArray.length; i++) {
      // this.balance = (parseFloat(this.balance) + parseFloat(this.priceTable[i].fee)).toFixed(2);
      if (this.addBillArray[i].test_id == testId && type_of_test == this.addBillArray[i].type_of_test) {
        this.addBillArray[i].quantity = quantity;
      }

      this.test_balance = (
        parseFloat(this.test_balance) +
        parseFloat(this.addBillArray[i].amount_txt) *
        parseFloat(this.addBillArray[i].quantity)
      ).toFixed(2);

      this.test_discount_amt = (
        parseFloat(this.test_discount_amt) +
        parseFloat(this.addBillArray[i].amount_txt) *
        parseFloat(this.addBillArray[i].quantity) *
        (parseFloat(this.addBillArray[i].concession) / 100)
      ).toFixed(2);

      this.test_bill_amount = (
        parseFloat(this.test_bill_amount) + parseFloat(this.addBillArray[i].fee)
      ).toFixed(2);
    }
    this.changeAdvance();
  }

  changePackageAmount(quantity, amount, concession, testId, type_of_test) {
    var perce;
    perce = (concession / 100);
    var amt = amount;
    if (quantity > 1) {
      amt = amt * quantity;
    }
    var amt_cons = this.amountAfternoon;
    var net_pay = parseFloat(amt) * parseFloat(perce.toString());
    var tot = amt - net_pay;
    if (isNaN(tot)) {
      tot = 0;
    }
    this.amountAfternoon = Math.round(tot);
    var balance = "0";
    for (var i = 0; i < this.addPackageBillArray.length; i++) {
      if (testId == this.addPackageBillArray[i].test_id && type_of_test == this.addPackageBillArray[i].type_of_test) {
        this.addPackageBillArray[i].fee = Math.round(tot);
        this.addPackageBillArray[i].fee = parseFloat(this.addPackageBillArray[i].fee).toFixed(2);        
        this.package_id = this.addPackageBillArray[i].test_id;
        console.log(this.package_id);
      }
    }
    this.package_balance = "0.00";
    this.package_bill_amount = "0.00";
    this.package_discount_amt = "0.00";
    for (var i = 0; i < this.addPackageBillArray.length; i++) {
      // this.balance = (parseFloat(this.balance) + parseFloat(this.priceTable[i].fee)).toFixed(2);
      if (this.addPackageBillArray[i].test_id == testId && type_of_test == this.addPackageBillArray[i].type_of_test) {
        this.addPackageBillArray[i].quantity = quantity;
      }

      this.package_balance = (
        parseFloat(this.package_balance) +
        parseFloat(this.addPackageBillArray[i].amount_txt) *
        parseFloat(this.addPackageBillArray[i].quantity)
      ).toFixed(2);

      this.package_discount_amt = (
        parseFloat(this.package_discount_amt) +
        parseFloat(this.addPackageBillArray[i].amount_txt) *
        parseFloat(this.addPackageBillArray[i].quantity) *
        (parseFloat(this.addPackageBillArray[i].concession) / 100)
      ).toFixed(2);

      this.package_bill_amount = (
        parseFloat(this.package_bill_amount) + parseFloat(this.addPackageBillArray[i].fee)
      ).toFixed(2);
    }
    this.changeAdvance();
  }

  finekeyInArray_frPriceTab(array, id, type_of_test) {
    var flag = false;
    for (var i = 0; i < array.length; i++) {
      if (array[i].test_id === id && array[i].type_of_test === type_of_test) {
        flag = true;
        break;
      }
    }
    return flag;
  }

  finekeyInArray(array, id) {
    var flag = false;
    for (var i in array) {
      if (array[i].test_id === id) {
        flag = true;
        break;
      }
    }
    return flag;
  }

  contains(elem) {
    for (var i in this) {
      if (this[i] == elem) return true;
    }
    return false;
  }

  //Get payment type
  getPaymentType() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/paytype',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.paymentArray = obj.payments;
          this.payType = this.paymentArray[0].pay_id;
          this.pay_desc = this.paymentArray[0].pay_desc;

        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      )
  }

  
  saveApp2() {
    this.paymentArrayCalculation();
    this.sendData = {};
    
    var package_test: any = [];
    var valid_flag = false;

    var test_outsource: any = [];
    var priority_List: any = [];
    var labarray: any = [];
    var radioarray: any = [];

    if (this.priceTable.length == 0) {
      this.toastr.error(Message_data.plsAddTestBfrSave);
      valid_flag = true;
    }
    if (valid_flag == false) {
      if (this.savePrescriptionFlag == true) {
        //  this.saveDiagnosisPrescription()
      } else {
        // New App edit save Code

        if (this.labTestArray.length != 0) {
          for (var i = 0; i < this.labTestArray.length; i++) {
            labarray.push(this.labTestArray[i].test_id);
          }
        }

        if (this.radioTestArray.length != 0) {
          for (var i = 0; i < this.radioTestArray.length; i++) {
            radioarray.push(this.radioTestArray[i].test_id);
          }
        }

        // New App edit save Code --- End

        if (this.selTestGroupArray.length != 0) {
          for (var i = 0; i < this.selTestGroupArray.length; i++) {
            package_test.push(this.selTestGroupArray[i].test_id);
          }
        }
        this.sendData = {
          "profiles": this.selected_profileIds,
          "labtest": labarray,
          "radiotest": radioarray,
          "package": this.selected_packageIds,
          // "package": package_test,
        }

        this.sendData.diag_centre_id = this.diagId;
        this.sendData.pres_id = this.presID;
        this.sendData.pres_diag_id = this.presID;
        this.sendData.client_reg_id = this.clientRegId;
        this.sendData.diag_app_id = this.appID;
        this.sendData.relation_id = this.relationId;
        this.sendData.sub_rel_id = this.subRelationId;
        this.sendData.provider = "diagnosis";
        this.sendData.bill_id = this.billid;
        this.sendData.bills = this.priceTable;
        this.sendData.country = "IN";
        var balance = "0";
        var paid_flag;
        for (var i = 0; i < this.priceTable.length; i++) {
          balance = (parseFloat(balance) + parseFloat(this.priceTable[i].fee)).toFixed(2);
        }
        if (this.bill_amount == this.remaingBalance) {
          paid_flag = "Un paid";
        } else if (this.bill_amount != "0.00" && (this.advance != "0.00") && this.bill_amount != this.advance) {
          paid_flag = "Partially paid";
        } else if (this.remaingBalance == "0.00" || this.remaingBalance == "0") {
          paid_flag = "Fully paid";
        }

        this.sendData.balance = this.remaingBalance;
        this.sendData.paid_amt = this.advance,
          this.sendData.paid_flag = paid_flag;
        this.sendData.bill_amount = encrypt_decript.Encript(this.bill_amount).toString();
        this.sendData.card_no = this.cardNumber;
        this.sendData.card_holder_name = this.cardHolderName;
        this.sendData.transaction_no = this.transactionID;
        this.sendData.bank_name = this.bankName;
        this.sendData.fin_year = this.finyear;
        this.sendData.created_by = Diagnosis_Helper.getUserID_Type().user_id;
        this.sendData.payments = this.paymentsarray;
        this.sendData.discount = this.discount_amt,

          // if(this.remaingBalance == "0.00" || this.remaingBalance == "0"){
          //   this.status = "0"
          // } else {
          //   this.status = "1"
          // }

          console.log("DIAG APP BOOKING " + JSON.stringify(this.sendData))

        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp + "", this.sendData, { headers: headers }).subscribe(
          data => {
            var response_data = JSON.parse(JSON.stringify(data));

            if (response_data.key == "1") {
              this.toastr.success(response_data.result);

              this.labarray = [];
              this.radioarray = [];
              this.selected_profiles = [];
              this.selected_packages = [];

              var billdetail = {
                bill_view_id: this.billid,
                Diag_center_id: this.diagId,
                flow: "dig_walking",
                fin_year: this.finyear,
              }
              Diagnosis_Helper.setbilldetailview(billdetail);
              if (Diagnosis_Helper.getCreateDiagAppt() != undefined
                && Diagnosis_Helper.getCreateDiagAppt().flow == "frontdesk") {
                this.frontdeskservice.sendMessage("diaBillingDetailsView");

              } else {
                this.diagnosisservice.sendMessage("diagbilldetailview");
              }

            } else {
              this.toastr.error(response_data.result);
            }
          }, error => {

          }
        );
      }
    }
  }

  saveApp() {
    this.paymentArrayCalculation();
    this.sendData = {};
    var package_test: any = [];
    var valid_flag = false;

    var test_outsource: any = [];
    var priority_List: any = [];
    var labarray: any = [];
    var radioarray: any = [];

    if (this.priceTable.length == 0) {
      this.toastr.error(Message_data.plsAddTestBfrSave);
      valid_flag = true;
    }
    if (valid_flag == false) {
      if (this.savePrescriptionFlag == true) {
        //  this.saveDiagnosisPrescription()
      } else {
        // New App edit save Code

        if (this.labTestArray.length != 0) {
          for (var i = 0; i < this.labTestArray.length; i++) {
            labarray.push(this.labTestArray[i].test_id);
          }
        }

        if (this.radioTestArray.length != 0) {
          for (var i = 0; i < this.radioTestArray.length; i++) {
            radioarray.push(this.radioTestArray[i].test_id);
          }
        }

        // New App edit save Code --- End

        if (this.selTestGroupArray.length != 0) {
          for (var i = 0; i < this.selTestGroupArray.length; i++) {
            package_test.push(this.selTestGroupArray[i].test_id);
          }
        }
        this.sendData = {
          "profiles": this.selected_profileIds,
          "labtest": labarray,
          "radiotest": radioarray,
          "package": this.selected_packageIds,
          // "package": package_test,
        }

        this.sendData.diag_centre_id = this.diagId;
        this.sendData.pres_id = this.presID;
        this.sendData.pres_diag_id = this.presID;
        this.sendData.client_reg_id = this.clientRegId;
        this.sendData.diag_app_id = this.appID;
        this.sendData.relation_id = this.relationId;
        this.sendData.sub_rel_id = this.subRelationId;
        this.sendData.provider = "diagnosis";
        this.sendData.bill_id = this.billid;
        this.sendData.country = "IN";
        this.sendData.card_no = this.cardNumber;
        this.sendData.card_holder_name = this.cardHolderName;
        this.sendData.transaction_no = this.transactionID;
        this.sendData.bank_name = this.bankName;
        this.sendData.fin_year = this.finyear;
        this.sendData.rate_card = "Service wise";

        if (this.addBillArray.length != 0) {
          var balance = "0.00";
          for (var i = 0; i < this.addBillArray.length; i++) {
            balance = (parseFloat(balance) + parseFloat(this.addBillArray[i].fee)).toFixed(2);
          }
          var paid_flag;
          if (balance == this.test_remainingBalance) {
            paid_flag = "Un paid";
          } else if (balance != "0.00" && (this.test_paidBill != "0.00") && balance != this.test_paidBill) {
            paid_flag = "Partially paid";
          } else if (this.test_remainingBalance == "0.00" || this.test_remainingBalance == "0") {
            paid_flag = "Fully paid";
          }

          this.sendData.diagnosis = [{
            bills: this.addBillArray,
            balance: this.test_remainingBalance,
            paid_amt: this.test_paidBill,
            paid_flag: paid_flag,
            bill_amount: encrypt_decript.Encript(this.test_bill_amount).toString(),
            created_by: Diagnosis_Helper.getUserID_Type().user_id,
            payments: this.test_paymentsarray,
            discount: this.test_discount_amt,
          }]
        }

        if (this.addPackageBillArray.length != 0) {
          var userid = Helper_Class.getInfo().user_id;
          var package_status = parseInt(this.package_remainingBalance) <= 0 ? '0' : '1';

          var balance = "0.00";
          for (var i = 0; i < this.addPackageBillArray.length; i++) {
            balance = (parseFloat(balance) + parseFloat(this.addPackageBillArray[i].fee)).toFixed(2);
          }
          var paid_flag;
          if (balance == this.package_remainingBalance) {
            paid_flag = "Un paid";
          } else if (balance != "0.00" && (this.package_paidBill != "0.00") && balance != this.package_paidBill) {
            paid_flag = "Partially paid";
          } else if (this.package_remainingBalance == "0.00" || this.package_remainingBalance == "0") {
            paid_flag = "Fully paid";
          }

          this.sendData.package = [{
            bills: this.addPackageBillArray,
            balance: this.package_remainingBalance,
            paid_amt: this.package_paidBill,
            paid_flag: paid_flag,
            bill_amount: encrypt_decript.Encript(this.package_bill_amount).toString(),
            created_by: Diagnosis_Helper.getUserID_Type().user_id,
            payments: this.package_paymentsarray,
            discount: this.package_discount_amt,
            bill_package_id: this.package_id,
            userid: userid,
            status: package_status,
          }]
        }

        console.log("DIAG APP BOOKING " + JSON.stringify(this.sendData))

        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp + "bill/udbill", this.sendData, { headers: headers }).subscribe(
          data => {
            var response_data = JSON.parse(JSON.stringify(data));

            if (response_data.key == "1") {
              this.toastr.success(response_data.result);

              this.labarray = [];
              this.radioarray = [];
              this.selected_profiles = [];
              this.selected_packages = [];

              var billdetail = {
                bill_view_id: this.billid,
                Diag_center_id: this.diagId,
                flow: "dig_walking",
                fin_year: this.finyear,
              }
              Diagnosis_Helper.setbilldetailview(billdetail);
              if (Diagnosis_Helper.getCreateDiagAppt() != undefined
                && Diagnosis_Helper.getCreateDiagAppt().flow == "frontdesk") {
                this.frontdeskservice.sendMessage("diaBillingDetailsView");

              } else {
                this.diagnosisservice.sendMessage("diagbilldetailview");
              }

            } else {
              this.toastr.error(response_data.result);
            }
          }, error => {

          }
        );
      }
    }
  }

  calculateOutPrice() {
    this.outpayarray = [];
    if (this.priceTable.length != 0) {
      for (var p = 0; p < this.priceTable.length; p++) {
        var outarray = this.priceTable[p].outarray;
        if (outarray != undefined) {
          for (var oa = 0; oa < outarray.length; oa++) {
            if (this.priceTable[p].outsource == outarray[oa].out_id) {
              if (this.outpayarray.length != 0) {
                if (this.isExists(this.outpayarray, this.priceTable[p].outsource) == true) {
                  for (var i = 0; i < this.outpayarray.length; i++) {
                    if (this.outpayarray[i].outsource == this.priceTable[p].outsource) {
                      var bill_amt = parseFloat(this.outpayarray[i].amount) + (parseFloat(this.priceTable[p].fee) * ((parseFloat(outarray[oa].perc_amt) / 100)));
                      this.outpayarray[i].amount = bill_amt;
                      break;
                    }
                  }

                } else {
                  this.outpayarray.push({
                    outsource: this.priceTable[p].outsource,
                    amount: parseFloat(this.priceTable[p].fee) * ((parseFloat(outarray[oa].perc_amt) / 100))
                  })
                  break;
                }

              } else {
                this.outpayarray.push({
                  outsource: this.priceTable[p].outsource,
                  amount: parseFloat(this.priceTable[p].fee) * ((parseFloat(outarray[oa].perc_amt) / 100))
                })
              }
            }
          }
        }
      }
    }
  }

  isExists(data, val) {
    var exitval;
    for (var i = 0; i < data.length; i++) {
      if (data[i].outsource == val) {
        exitval = true;
        break;
      }
    }
    return exitval;
  }

  addPaymentDetails() {
    var bamt = 0;
    for (var i = 0; i < this.paymentArray.length; i++) {
      if (this.payType1 == this.paymentArray[i].pay_id) {
        if (this.paymentsarray.length != 0) {
          for (var j = 0; j < this.paymentsarray.length; j++) {
            bamt += parseInt(this.paymentsarray[j].amount);
          }
          bamt += parseFloat(this.amountval.toString());
        } else {
          bamt += parseFloat(this.amountval.toString());
        }

        if (bamt > parseInt(this.advance)) {
          this.toastr.error("Amount cannot be more than paid amount")
        } else {

          // this.paymentsarray.push({
          //   pay_id: this.paymentArray[i].pay_id,
          //   pay_desc: this.paymentArray[i].pay_desc,
          //   amount: parseFloat(this.amountval.toString()).toFixed(2),
          // })
          // this.amountval = 0;

          if (this.amountval < parseFloat(this.advance) || this.amountval == parseFloat(this.advance)) {
            if (parseFloat(this.advance) > parseFloat(this.balance)) {
              if (bamt > parseFloat(this.balance)) {
                this.toastr.error('Amount cannot be more than bill amount');
              } else {
                this.paymentsarray.push({
                  pay_id: this.paymentArray[i].pay_id,
                  pay_desc: this.paymentArray[i].pay_desc,
                  amount: parseFloat(this.amountval).toFixed(2),
                });
                this.amountval = parseFloat(this.balance) - bamt;
                this.amountval = parseFloat(this.amountval).toFixed(2);
                this.payType1 = '';
              }
            } else {
              if (bamt > parseFloat(this.advance)) {
                this.toastr.error('Amount cannot be more than bill amount');
              } else {
                this.paymentsarray.push({
                  pay_id: this.paymentArray[i].pay_id,
                  pay_desc: this.paymentArray[i].pay_desc,
                  amount: parseFloat(this.amountval).toFixed(2),
                });
                this.amountval = parseFloat(this.advance) - bamt;
                this.amountval = parseFloat(this.amountval).toFixed(2);
                this.payType1 = '';
              }
            }
          } else {
            this.amountval = 0;
          }
        }
      }
    }
  }

  editPayment(data) {
    this.payType1 = data.pay_id;
    this.amountval = data.amount;
    for (var i = 0; i < this.paymentsarray.length; i++) {
      if (data.pay_id == this.paymentsarray[i].pay_id) {
        this.paymentsarray.splice(i, 1);
      }
    }
  }

  deletePayment(data) {
    for (var i = 0; i < this.paymentsarray.length; i++) {
      if (data.pay_id == this.paymentsarray[i].pay_id) {
        // if(this.amountval == undefined){
        //   this.amountval = parseFloat(this.paymentsarray[i].amount).toFixed(2);
        // } else {
        //   this.amountval = (parseFloat(this.amountval) + parseFloat(this.paymentsarray[i].amount)).toFixed(2);
        // }

        this.paymentsarray.splice(i, 1);
      }
    }
  }

  checkData(value) {
    var data = "";
    if (value != undefined && value != null && value != "" && value != "undefined" && value != "null")
      data = value;
    else
      data = "";
    return data;
  }

  getDoctor() {
    var senddata = {
      hptl_clinic_id: this.userinfo.diag_centre_id,
      type: "diagnosis",
    }
    console.log('.......senddata....' + JSON.stringify(senddata))

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'search/docbyhs/', senddata,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.doctorList = [];
          console.log('...........', obj)
          if (obj.doctors != null) {
            this.docflag = false;
            for (var i = 0; i < obj.doctors.length; i++) {
              var doc_name = "";
              if (obj.doctors[i].middle_name != undefined || obj.doctors[i].middle_name != null) {
                doc_name = obj.doctors[i].first_name + " " + obj.doctors[i].middle_name + " " + obj.doctors[i].last_name;
              } else {
                doc_name = obj.doctors[i].first_name + " " + obj.doctors[i].last_name;
              }
              this.doctorList.push({
                prov_id: obj.doctors[i].prov_id,
                name: doc_name,
              })
            }
          }
        }, error => {
          this.toastr.error(Message_data.unableToFetchDocDet);
        });
  }

  clickAppDate(data) {
    this.appDate = data;
    var dataval = Date_Formate(this.currentDate);

    if (data != dataval) {
      this.getVisitingSessionArray = [{ "description": "Select" }, { "description": "Morning" }, { "description": "Afternoon" }, { "description": "Evening" }];
      this.visitSession = this.getVisitingSessionArray[0].description;
    }
  }

  selectSession() {
    if (this.visitSession == "Morning") {
      this.timeSession = "AM";
      this.startTime = "06";
      this.endTime = "00";
    } else if (this.visitSession == "Evening") {
      this.startTime = "06";
      this.endTime = "00";
      this.timeSession = "PM";
    } else {
      this.timeSession = "PM";
      this.startTime = "12";
      this.endTime = "00";
    }
    this.sessionFlag = false;
    // this.enableBooking();
  }

  mainFilter(testName) {
    this.filterLabArray(testName)
    this.filterRadioArray(testName)
    this.filterMicroArray(testName)
    this.filterProfileArray(testName)
    this.filterPackageArray(testName)
  }

  filterLabArray(LabtestName) {
    this.filteredLabArray = this.labTest_array.filter(item =>
      item.test_name.toLowerCase().includes(LabtestName.toLowerCase())
    );
  }

  filterRadioArray(radiotestName) {
    this.filteredRadioArray = this.radioTest_array.filter(item =>
      item.test_name.toLowerCase().includes(radiotestName.toLowerCase())
    );
  }

  filterMicroArray(microtestName) {
    this.filteredMicroArray = this.microTest_array.filter(item =>
      item.test_name.toLowerCase().includes(microtestName.toLowerCase())
    );
  }

  filterProfileArray(profileName) {
    this.filteredProfilesArray = this.profiles.filter(item =>
      item.profile_name.toLowerCase().includes(profileName.toLowerCase())
    );
  }

  filterPackageArray(packageName) {
    this.filteredPackagesArray = this.packages_array.filter(item =>
      item.package_name.toLowerCase().includes(packageName.toLowerCase())
    );
  }

  search_test() {
    this.Blood_test = [];
    this.testGroupArray = [];

    this.labarray = [];
    this.radioarray = [];
    this.selected_profiles = [];
    this.selected_packages = [];

    var order_by;
    if (this.showTest_type == "0") {
      order_by = "sub_dept";
    } else {
      order_by = "test_type";
    }

    // if (Diagnosis_Helper.getCreateDiagAppt().flow == "frontdesk") {
    //   this.diagId = this.userinfo.diag_centre_id;
    // } else {
    //   this.diagId = this.diagId;
    // }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/dtsub/', {

      diag_centre_id: this.diag_center_id,
      hptl_clinic_id: this.diagId,
      orderby: order_by,
    },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("search_test----obj", obj)

          this.labTest_array = obj.labtests;
          this.radioTest_array = obj.radiotests;
          this.microTest_array = obj.microtests;
          this.profiles = obj.profiles;
          this.packages_array = obj.packages;

          this.filteredLabArray = obj.labtests;
          this.filteredRadioArray = obj.radiotests;
          this.filteredMicroArray = obj.microtests;
          this.filteredProfilesArray = obj.profiles;
          this.filteredPackagesArray = obj.packages;

          this.getAppDetails();
        },
        error => { })
  }

  async ChangeTestType(event, id, data, type, subtest, test_type, testdept) {
    try {
      this.getFrequency(event, id, data, subtest, type, test_type, testdept);
    }
    catch (err) { }
  }

  getFrequency(event, id, data, subtest, type, test_type, testdept) {
    var send_data = {
      client_reg_id: this.clientRegId,
      relation_id: this.relationId,
      sub_rel_id: this.subRelationId,
      date: this.currentDate,
      test_id: subtest.diag_tests_id,
      interval: subtest.frequency_val,
      test_type: test_type,
    }

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/gftt', send_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("getFrequnecy--send data", send_data)
          console.log(obj)
          if (obj.test_exists != undefined) {
            if (obj.test_exists == false) {
              if (event == true) {
                if (this.test_exists == false) {
                  var results, presults;
                  results = this.finekeyInArray_addTest(this.selected_profiles, id);
                  presults = this.finekeyInArray_addTest(this.selected_packages, id);
                  if (results != true && presults != true) {
                    if (testdept == 'labtest') {
                      for (var k = 0; k < this.labTest_array.length; k++) {
                        if (this.labTest_array[k].diag_tests_id == id) {
                          this.labTest_array[k].checked = true;
                        }
                      }
                    } else if (testdept == 'radiotest') {
                      for (var k = 0; k < this.radioTest_array.length; k++) {
                        if (this.radioTest_array[k].diag_tests_id == id) {
                          this.radioTest_array[k].checked = true;
                        }
                      }
                    } else if (testdept == 'microtest') {
                      for (var k = 0; k < this.microTest_array.length; k++) {
                        if (this.microTest_array[k].diag_tests_id == id) {
                          this.microTest_array[k].checked = true;
                        }
                      }
                    } else {
                      for (var k = 0; k < this.profiles.length; k++) {
                        if (this.profiles[k].diag_tests_id == id) {
                          this.profiles[k].checked = true;
                        }
                      }
                    }

                    var Tat_val
                    if (subtest.total_TAT != undefined && subtest.total_TAT != null) {
                      Tat_val = 'TAT - ' + subtest.total_TAT;
                    } else {
                      Tat_val = '';
                    }

                    if (subtest.department == "Laboratory") {
                      // new array
                      this.labarray.push({
                        test_id: id,
                        test_name: data,
                        price: subtest.price,
                        diag_type: type,
                        TAT: Tat_val
                      })
                    } else {
                      this.radioarray.push({
                        test_id: id,
                        test_name: data,
                        price: subtest.price,
                        diag_type: type,
                        TAT: Tat_val
                      })
                    }
                    console.log("New Arrays", this.labarray, this.radioarray)

                  } else {
                    this.toastr.error("Selected test is already added in selected profile", null, { timeOut: 3000 });
                  }
                } else {
                  this.toastr.error("Cannot book the test as it doesn't meet the frequency criteria");
                }
              } else if (event == false) {

                if (testdept == 'labtest') {
                  for (var k = 0; k < this.labTest_array.length; k++) {
                    if (this.labTest_array[k].diag_tests_id == id) {
                      this.labTest_array[k].checked = false;
                    }
                  }
                } else if (testdept == 'radiotest') {
                  for (var k = 0; k < this.radioTest_array.length; k++) {
                    if (this.radioTest_array[k].diag_tests_id == id) {
                      this.radioTest_array[k].checked = false;
                    }
                  }
                } else if (testdept == 'microtest') {
                  for (var k = 0; k < this.microTest_array.length; k++) {
                    if (this.microTest_array[k].diag_tests_id == id) {
                      this.microTest_array[k].checked = false;
                    }
                  }
                } else {
                  for (var k = 0; k < this.profiles.length; k++) {
                    if (this.profiles[k].diag_tests_id == id) {
                      this.profiles[k].checked = false;
                    }
                  }
                }

                for (var h = 0; h < this.labarray.length; h++) {
                  if (this.labarray[h].test_id == id) {
                    this.labarray.splice(h, 1);
                  }
                }

                for (var h = 0; h < this.radioarray.length; h++) {
                  if (this.radioarray[h].test_id == id) {
                    this.radioarray.splice(h, 1);
                  }
                }
              }

              console.log("New Arrays", this.labarray, this.radioarray)
            } else {
              this.toastr.error("Cannot book the test as it doesn't meet the frequency criteria");
            }
          }
        }, error => { });
  }

  finekeyInArray_addTest(array, id) {
    var flag = false;
    for (var i = 0; i < array.length; i++) {
      if (array[i].tests != undefined) {
        for (var j = 0; j < array[i].tests.length; j++) {
          if (array[i].tests[j].diag_tests_id === id) {
            flag = true;
            break;
          }
        }
      }
    }
    return flag;
  }

  check_addedTests(profile_array, lab_array, radio_array, package_array) {
    var flag = false;
    for (var i = 0; i < profile_array.tests.length; i++) {
      if (lab_array != undefined && lab_array.length != 0) {
        for (var j = 0; j < lab_array.length; j++) {
          if (profile_array.tests[i].diag_tests_id == lab_array[j].test_id) {
            flag = true;
            this.isTestVal = true;
            break;
          }
        }
      }

      if (radio_array != undefined && radio_array.length != 0) {
        for (var k = 0; k < radio_array.length; k++) {
          if (profile_array.tests[i].diag_tests_id == radio_array[k].test_id) {
            flag = true;
            this.isTestVal = true;
            break;
          }
        }
      }

    }

    if (package_array != undefined && package_array.length != 0) {
      for (var l = 0; l < package_array.length; l++) {
        if (package_array[l].tests != undefined) {
          for (var m = 0; m < package_array[l].tests.length; m++) {
            if (profile_array.diag_profile_id == package_array[l].tests[m].diag_tests_id) {
              flag = true;
              this.isTestVal = false;
              break;
            }
          }
        }
      }
    }

    return flag;
  }

  clickPackage(e: any, data) {

    if (e == true) {
      for (var i = 0; i < this.packages_array.length; i++) {
        if (this.packages_array[i].health_pkg_id == data) {
          var results, test_results, pack_results;
          results = this.packndprofileVal(this.packages_array[i], this.selected_profileIds);
          test_results = this.packndtestVal(this.packages_array[i], this.labarray, this.radioarray);
          pack_results = this.packndpackVal(this.packages_array[i], this.selected_packageIds);

          if (results != true && test_results != true) {
            this.packages_array[i].checked = true;
            this.selected_packages.push(this.packages_array[i])
            this.selected_packageIds.push(this.packages_array[i].health_pkg_id)
          } else {
            this.toastr.error("Package contains a selected profile", null, { timeOut: 3000 });
          }
        }
      }

    } else {
      for (var i = 0; i < this.packages_array.length; i++) {
        if (this.packages_array[i].health_pkg_id == data) {
          this.packages_array[i].checked = false;
          for (var h = 0; h < this.selected_packages.length; h++) {
            if (this.selected_packages[h].health_pkg_id == data) {
              this.selected_packages.splice(h, 1)
            }
          }

          for (var k = 0; k < this.selected_packageIds.length; k++) {
            if (this.selected_packageIds[k] == data) {
              this.selected_packageIds.splice(k, 1)
            }
          }
        }
      }
    }
    console.log("this.selected_profiles", this.selected_packages)
  }

  packndpackVal(packagesArr, packIds) {
    var flag = false;
    if (this.packages_array != undefined && this.packages_array.length != 0) {
      for (var i = 0; i < this.packages_array.length; i++) {
        for (var j = 0; j < packIds.length; j++) {
          if(this.packages_array[i].health_pkg_id == packIds[j]){
            for(var k = 0; k < packagesArr.tests.length; k++){
              for(var l = 0; l < this.packages_array[i].tests.length; l++){
                if(packagesArr.tests[k].diag_tests_id == this.packages_array[i].tests[l].diag_tests_id) {
                  flag = true;
                  break;
                }
              }
            }
          }
        }
      }
    }
    
    return flag;
  }


  packndprofileVal(packagesArr, profIds) {
    var flag = false;
    for (var i = 0; i < packagesArr.tests.length; i++) {
      if (profIds != undefined && profIds.length != 0) {
        for (var j = 0; j < profIds.length; j++) {
          if (packagesArr.tests[i].diag_tests_id == profIds[j]) {
            flag = true;
            break;
          }
        }
      }
    }

    return flag;
  }

  packndtestVal(packagesArr, labarray, radioarray) {
    var flag = false;
    for (var i = 0; i < packagesArr.tests.length; i++) {
      if (labarray != undefined && labarray.length != 0) {
        for (var j = 0; j < labarray.length; j++) {
          if (packagesArr.tests[i].diag_tests_id == labarray[j].test_id) {
            flag = true;
            break;
          }
        }
      }

      if (radioarray != undefined && radioarray.length != 0) {
        for (var k = 0; k < radioarray.length; k++) {
          if (packagesArr.tests[i].diag_tests_id == radioarray[k].test_id) {
            flag = true;
            break;
          }
        }
      }
    }

    return flag;
  }

  addTest2() {
    var results;
    var check_Package;
    var check_test;
    // this.subtestname = [];

    console.log("this.labTestArray", this.labTestArray)
    console.log("this.radioTestArray", this.radioTestArray)
    console.log("this.labTestArray", this.labTestArray)

    console.log("this.labarray", this.labarray)


    // if (this.diagTestGroup == "test") {
    if (this.labarray.length != 0 || this.radioarray.length != 0) {
      if (this.labarray.length != 0) {
        for (var i = 0; i < this.labarray.length; i++) {
          console.log("BEFORE REMOVAL " + JSON.stringify(this.labarray))
          if (this.labTestArray.length != 0) {
            results = this.finekeyInArray(this.labTestArray, this.labarray[i].test_id);
            check_Package = this.checkPackId(this.labarray[i].test_id);

            if (results != true && check_Package != true) {
              this.labTestArray.push({
                test_id: this.labarray[i].test_id,
                test_name: this.labarray[i].test_name,
                diag_type: this.labarray[i].diag_type,
              });
            } else {
              // this.labarray.splice(i, 1);

            }

          } else {
            check_Package = this.checkPackId(this.labarray[i].test_id);
            if (check_Package != true) {
              this.labTestArray.push({
                test_id: this.labarray[i].test_id,
                test_name: this.labarray[i].test_name,
                diag_type: this.labarray[i].diag_type,
              });
            } else {
              // this.labarray.splice(i, 1);

            }
          }
          console.log("after removal " + JSON.stringify(this.labarray))
        }
        this.addAmount(this.labarray, "Laboratory");

      } if (this.radioarray.length != 0) {
        for (var i = 0; i < this.radioarray.length; i++) {

          if (this.radioTestArray.length != 0) {
            results = this.finekeyInArray(this.radioTestArray, this.radioarray[i].test_id);
            check_Package = this.checkPackId(this.radioarray[i].test_id);

            if (results != true && check_Package != true) {
              this.radioTestArray.push({
                test_id: this.radioarray[i].test_id,
                test_name: this.radioarray[i].test_name,
                diag_type: this.radioarray[i].diag_type,
              });
            } else {
              // this.radioarray.splice(i, 1);
              // break;
            }
          } else {
            check_Package = this.checkPackId(this.radioarray[i].test_id);
            if (check_Package != true) {
              this.radioTestArray.push({
                test_id: this.radioarray[i].test_id,
                test_name: this.radioarray[i].test_name,
                diag_type: this.radioarray[i].diag_type,
              });
            } else {
              // this.radioarray.splice(i, 1);
              // break;
            }
          }
        }
        this.addAmount(this.radioarray, "Radiology");
      }
    }

    console.log("selected test" + JSON.stringify(this.labTestArray) + JSON.stringify(this.radioTestArray))



    // } else {
    if (this.profiles != undefined) {
      for (var i = 0; i < this.profiles.length; i++) {
        if (this.profiles[i].checked == true) {
          if (this.selTestGroupArray.length != 0) {
            results = this.finekeyInArray(this.selTestGroupArray, this.profiles[i].diag_profile_id);
            check_test = this.checkTestId(this.profiles[i]);

            if (results != true) {
              if (check_test != true) {
                this.selTestGroupArray.push({
                  test_id: this.profiles[i].diag_profile_id,
                  test_name: this.profiles[i].profile_name,
                  price: this.profiles[i].price,
                  tests: this.profiles[i].tests,
                  diag_type: this.profiles[i].diag_type,
                  department: this.profiles[i].department,
                });
              } else {
                this.toastr.error("A test in profile is already been added");
              }
            }

          } else {
            check_test = this.checkTestId(this.profiles[i]);
            if (check_test != true) {
              this.selTestGroupArray.push({
                test_id: this.profiles[i].diag_profile_id,
                test_name: this.profiles[i].profile_name,
                price: this.profiles[i].price,
                tests: this.profiles[i].tests,
                diag_type: this.profiles[i].diag_type,
              });

            } else {
              this.toastr.error("Selected test is already added in selected profile");
            }
          }
          console.log("INSIDE PROFILE ADD ", JSON.stringify(this.selTestGroupArray))
        }
      }
      this.addAmount(this.selTestGroupArray, "Profile");
      console.log("selected test" + JSON.stringify(this.labTestArray) + JSON.stringify(this.radioTestArray))
      // console.log("selected test lan" + JSON.stringify(this.labTestArray) + JSON.stringify(this.radioTestArray))
      if (this.selTestGroupArray.length != 0) {
        this.showTestGroupFlag = false;
      }

    }

    if (this.packages_array != undefined) {
      for (var i = 0; i < this.packages_array.length; i++) {
        if (this.packages_array[i].checked == true) {
          if (this.selTestGroupArray.length != 0) {
            // results = this.finekeyInArray(this.selTestGroupArray, this.profiles[i].diag_profile_id);
            // check_test = this.checkTestId(this.profiles[i]);
            this.selTestGroupArray.push({
              test_id: this.packages_array[i].health_pkg_id,
              test_name: this.packages_array[i].package_name,
              price: this.packages_array[i].price,
              tests: this.packages_array[i].tests,
              diag_type: this.packages_array[i].diag_type,
              department: this.packages_array[i].department,
            });
          } else {
            this.selTestGroupArray.push({
              test_id: this.packages_array[i].health_pkg_id,
              test_name: this.packages_array[i].package_name,
              price: this.packages_array[i].price,
              tests: this.packages_array[i].tests,
              diag_type: this.packages_array[i].diag_type,
            });
          }
          console.log("INSIDE PACKAGE ADD ", JSON.stringify(this.selTestGroupArray))
        }
      }
      this.addAmount(this.selTestGroupArray, "Profile");
      if (this.selTestGroupArray.length != 0) {
        this.showTestGroupFlag = false;
      }

    }
  }

  checkPackId(id) {
    var Pack_flag = false;

    if (this.selTestGroupArray.length != 0) {
      for (var j = 0; j < this.selTestGroupArray.length; j++) {
        if (this.selTestGroupArray[j].tests != undefined) {
          for (var k = 0; k < this.selTestGroupArray[j].tests.length; k++) {
            if (this.selTestGroupArray[j].tests[k].diag_tests_id == id) {
              Pack_flag = true;
              setTimeout(() => {
                this.toastr.error("The test is added in profile");
              }, 1000)
              break;
            }
          }
        }
      }
    }
    return Pack_flag;
  }

  checkTestId(data) {
    var test_flag = false;

    if (this.priceTable.length != 0 && data.tests != undefined) {
      for (var i = 0; i < this.priceTable.length; i++) {
        for (var j = 0; j < data.tests.length; j++) {
          if (this.priceTable.length != 0 && this.priceTable[i].test_id == data.tests[j].diag_tests_id) {
            test_flag = true;
            setTimeout(() => {
              this.toastr.error("A test in profile is already been added");
            }, 1000)
          }
        }
      }
    }
    return test_flag;
  }

  appGetdate() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.dateURL, { country: ipaddress.country_code }, { headers: headers }).subscribe(
      data => {
        var dataval = JSON.parse(JSON.stringify(data))
        this.currentDate = Date_Formate(dataval.current_date);
        this.currentTime = dataval.current_time;
        var timeval = this.currentTime.split(":");
        if (parseInt(timeval[0]) > 12) {
          this.startTime = (parseInt(timeval[0]) - 12).toString();
          if (this.startTime.length == 1) {
            this.startTime = "0" + this.startTime;
          }

        } else {
          this.startTime = timeval[0];
        }
        //this.startTime = timeval[0];
        this.endTime = timeval[1];
        this.currentYear = dataval.current_date.split('-')[0];
        this.appointmentDate = this.currentDate;
        // this.appDate = dataval.current_date;
        this.currentDate = dataval.current_date;
        // this.getVisitingSessionArray = [{ "description": "Morning" }, { "description": "Afternoon" }, { "description": "Evening" }];
        // if (parseInt(this.startTime) < 12) {
        //   this.visitSession = this.getVisitingSessionArray[0].description;
        // } else if (parseInt(this.startTime) >= 12 && parseInt(this.startTime) <= 16) {
        //   this.visitSession = this.getVisitingSessionArray[1].description;
        // } else {
        //   this.visitSession = this.getVisitingSessionArray[2].description;
        // }
      },
      error => { });
  }

  
  removeTest(testid, type, remove_test) {
    if (this.priceTable.length == 1) {
      this.advance = "0.00";
      this.paymentsarray = [];
    }

    if (type == "Laboratory") {
      for (var i = 0; i < this.labTestArray.length; i++) {
        if (this.labTestArray[i].test_id == testid) {
          this.labTestArray.splice(i, 1);
          break;
        }
      }
      for (var i = 0; i < this.priceTable.length; i++) {
        if (this.priceTable[i].test_id == testid && this.priceTable[i].type_of_test == "Laboratory") {
          this.priceTable.splice(i, 1);
          break;
        }
      }

      for (var j = 0; j < this.labarray.length; j++) {
        if (this.labarray[j].test_id == testid) {
          this.labarray.splice(j, 1);
          break;
        }
      }

      for (var k = 0; k < this.labTest_array.length; k++) {
        if (this.labTest_array[k].diag_tests_id == testid) {
          this.filteredLabArray[k].checked = false;
        }
      }

      for (var k = 0; k < this.microTest_array.length; k++) {
        if (this.microTest_array[k].diag_tests_id == testid) {
          this.filteredMicroArray[k].checked = false;
        }
      }

      console.log("this.labarray" + JSON.stringify(this.labarray))

    } else if (type == "Radiology") {
      for (var i = 0; i < this.radioTestArray.length; i++) {
        if (this.radioTestArray[i].test_id == testid) {
          this.radioTestArray.splice(i, 1);
          break;
        }
      }
      for (var i = 0; i < this.priceTable.length; i++) {
        if (this.priceTable[i].test_id == testid && this.priceTable[i].type_of_test == "Radiology") {
          this.priceTable.splice(i, 1);
          break;
        }
      }

      for (var j = 0; j < this.radioarray.length; j++) {
        if (this.radioarray[j].test_id == testid) {
          this.radioarray.splice(j, 1);
          break;
        }
      }

      for (var k = 0; k < this.radioTest_array.length; k++) {
        if (this.radioTest_array[k].diag_tests_id == testid) {
          this.filteredRadioArray[k].checked = false;
        }
      }
    
     } else if (type == "Profile" || type == "Package" || type == "package") {
      if (this.profiles != undefined) {
        for (var k = 0; k < this.profiles.length; k++) {
          if (this.profiles[k].diag_profile_id == testid) {
            this.filteredProfilesArray[k].checked = false;
          }
        }
      }


      if (this.packages_array != undefined) {
        for (var k = 0; k < this.packages_array.length; k++) {
          if (this.packages_array[k].health_pkg_id == testid) {
            this.filteredPackagesArray[k].checked = false;
          }
        }
      }

      if (this.selected_profiles != undefined) {
        for (var i = 0; i < this.selected_profiles.length; i++) {
          if (this.selected_profiles[i].diag_profile_id == testid) {
            this.selected_profiles.splice(i, 1);
            break;
          }
        }
      }

      if (this.selected_packages != undefined) {
        for (var i = 0; i < this.selected_packages.length; i++) {
          if (this.selected_packages[i].health_pkg_id == testid) {
            this.selected_packages.splice(i, 1);
            break;
          }
        }
      }

      if (this.selected_profileIds != undefined) {
        for (var i = 0; i < this.selected_profileIds.length; i++) {
          if (this.selected_profileIds[i] == testid) {
            this.selected_profileIds.splice(i, 1);
            break;
          }
        }
      }

      if (this.selected_packageIds != undefined) {
        for (var i = 0; i < this.selected_packageIds.length; i++) {
          if (this.selected_packageIds[i] == testid) {
            this.selected_packageIds.splice(i, 1);
            break;
          }
        }
      }

      if (this.selTestGroupArray != undefined) {
        for (var i = 0; i < this.selTestGroupArray.length; i++) {
          if (this.selTestGroupArray[i].test_id == testid) {
            this.selTestGroupArray.splice(i, 1);
            break;
          }
        }
      }

      for (var i = 0; i < this.priceTable.length; i++) {
        if (this.priceTable[i].test_id == testid && (this.priceTable[i].type_of_test == "Profile" || this.priceTable[i].type_of_test == "Package" || this.priceTable[i].type_of_test == "package")) {
          this.priceTable.splice(i, 1);
          break;
        }
      }
      
      if (this.selTestGroupArray.length == 0) {
        this.showUltraTestFlag = true;
      }

      if (this.testGroupArray != undefined) {
        for (var i = 0; i < this.testGroupArray.length; i++) {
          if (this.testGroupArray[i].diag_profile_id == testid) {
            this.testGroupArray[i].checked = false;
            break;
          }
        }
      }
      console.log("remove test" + JSON.stringify(this.labTestArray) + JSON.stringify(this.radioTestArray))
    }
    var sno = 1; this.sno = 1;
    for (var i = 0; i < this.priceTable.length; i++) {
      this.priceTable[i].sno = sno;
      sno += 1;
      this.sno += 1;
    }
    this.balance = "0";
    this.bill_amount = "0.00"
    for (var i = 0; i < this.priceTable.length; i++) {
      this.balance = (parseFloat(this.balance) + parseFloat(this.priceTable[i].amount_txt)).toFixed(2);
      this.bill_amount = (parseFloat(this.bill_amount) + parseFloat(this.priceTable[i].fee)).toFixed(2);
    }
    this.changeAdvance();
    this.changeAmount(remove_test.quantity, remove_test.amount_txt, remove_test.concession, remove_test.test_id, remove_test.type_of_test);
    this.calculateOutPrice();
  }

}
