
import { Component, ElementRef, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Helper_Class } from '../../helper_class';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Doc_Helper } from '../Doc_Helper';
import { Http, Headers } from '@angular/http';
import { InpatMedprescViewComponent } from '../inpat-medpresc-view/inpat-medpresc-view.component';
import { MatDialog } from '@angular/material/dialog';
import { ipaddress } from '../../ipaddress';
 import { Message_data } from '../../../assets/js/Message_data';
import { MenuViewService } from '../../common-module/menu-view/menu-view.service';
import { DomSanitizer } from '@angular/platform-browser';
import moment from 'moment';
import { Date_Formate } from '../../../assets/js/common';

@Component({
  selector: 'app-inpat-discharge-summary',
  templateUrl: './inpat-discharge-summary.component.html',
  styleUrls: ['./inpat-discharge-summary.component.css']
})
export class InpatDischargeSummaryComponent implements OnInit {
  maxLength = 10;
  @ViewChild('printlogowithname') printlogowithname: ElementRef;
  @ViewChild('printbanner') printbanner: ElementRef;
  @ViewChild('printnoheader') printnoheader: ElementRef;
  @ViewChild('dischargesummary') dischargesummary: ElementRef;
  @ViewChild('printheader') printheader: ElementRef;
  @ViewChild('printbanner_llh') printbanner_llh: ElementRef;
  @ViewChild('vitalstemp', { static: true }) vitalstemp: TemplateRef<any>;
  @ViewChild('diagnosis', { static: true }) diagnosis: TemplateRef<any>;
  @ViewChild('treatment', { static: true }) treatment: TemplateRef<any>;
  @ViewChild('chief_complaints', { static: true }) chief_complaints: TemplateRef<any>;
  @ViewChild('medical_histtemp', { static: true }) medical_histtemp: TemplateRef<any>;
  @ViewChild('physical_examtemp', { static: true }) physical_examtemp: TemplateRef<any>;
  @ViewChild('investigationtemp', { static: true }) investigationtemp: TemplateRef<any>;
  @ViewChild('ot_notestemp', { static: true }) ot_notestemp: TemplateRef<any>;
  @ViewChild('discharge_adv', { static: true }) discharge_adv: TemplateRef<any>;
  @ViewChild('follow_sum', { static: true }) follow_sum: TemplateRef<any>;
  @ViewChild('present_illne', { static: true }) present_illne: TemplateRef<any>;
  @ViewChild('diet_advice', { static: true }) diet_advice: TemplateRef<any>;
  @ViewChild('med_hist_data', { static: true }) med_hist_data: TemplateRef<any>;
  @ViewChild('drug_hist_order',{ static: true }) drug_hist_order: TemplateRef<any>;
  @ViewChild('followup_order' ,{ static: true }) followup_order: TemplateRef<any>;
  @ViewChild('surgery_pro_order' ,{ static: true }) surgery_pro_order: TemplateRef<any>;
  @ViewChild('emergencyies' ,{ static: true }) emergencyies: TemplateRef<any>;


  format: '' | 'CODE128' | 'CODE128A' | 'CODE128B' | 'CODE128C' | 'EAN' | 'UPC' | 'EAN8' | 'EAN5' | 'EAN2' | 'CODE39' | 'ITF14' | 'MSI' | 'MSI10' | 'MSI11' | 'MSI1010' | 'MSI1110' | 'pharmacode' | 'codabar';
  public viewpres: boolean = false;
  public clntgender;
  elementType: 'svg';
  public barcode;
  public discharge_time;
  lineColor = '#000000';
  width = 1.0;
  barcodeheight = 20;
  displayValue = true;
  fontOptions = '';
  font = 'monospace';
  textAlign = 'center';
  textPosition = 'bottom';
  textMargin = 2;
  fontSize = 15;
  background = '#ffffff';
  margin = 10;
  marginTop = 10;
  marginBottom = 10;
  marginLeft = 0;
  marginRight = 10;
  public currentDate;
  tomorrow = new Date();
  public salutation;
  public fulladdress;
  public header_footer_flag: boolean;
  public patient_name: string;
  public patient_identifier: string;
  public gender: string;
  public age: string;
  public admis_date: string;
  public doctor_name: string;
  public mobile_no: string;
  public symptoms: string;
  public complaints: string;
  public medical_hist: string;
  public physical_exam: string;

  public loader: boolean;
  public pres_pop_flag: string;
  public mobile: string;
  public next_data;
  public next_data1;
  public inpatient_id: string;

  public dis_diag: string;
  public hosp_course: string;
  public hospital_followup: string;
  public confirm_btn: boolean;
  public druglist:any = [];


  public hosp_name: string;
  public hosp_addr: string;
  public hosp_csz: string;
  public hosp_tel: string;
  public hosp_web: string;
  public hosp_web_flag: boolean;
  public hosp_count: string;

  public userinfo: any;
  public user_id: any;
  public first_name: string;
  public last_name: string;
  public hospital_logo;
  public hospital_name;
  public hospital_details;
  public messages;
  public msgflag: boolean = false;
  public notifications;
  public notifyflag: boolean = false;
  public profile_image: string;
  public submenu_flag: boolean = false;
  public submenu_flag1: boolean = false;
  public dis_date;
  public dis_date1;
  public personal_information: any = [];
  public print_drug: boolean;
  public Treatmetnt_done;
  public investigation;
  public ot_notes;
  public emergency_notes;
  public updateFlag: boolean = false;
  public presID;
  public drungID;
  public discharge;
  public retainValue: boolean = false;
  public hptl_pres_logo;
  public print_template;
  public headerstyle;
  public footerstyle;

  public anaest_types: any = [];
  public anaest_effects: any = [];
  public anaest_doctors: any = [];
  public consultarray: any = [];
  public anaest_procedure: string;
  public anaesttype: string;
  public anaest_effect: string;
  public anaest_doc: string;
  public surgeon_list: any = [];
  public surgery_date: string;
  public surgery_name: string;
  public req_sur_flag: boolean;
  public surgery_proc: string;
  public surgery_time: string;
  public inpId: string;
  public admDate;
  public flagvalue;
  public surgeon: any = [];
  public anaestetist: any = [];
  public anaestetype: string;
  public anaest_proc: string;
  public anaest_findings: string;
  public inpno: string;
  public admis_time: string;
  public dis_time: string;
  public client_address: string;
  public sum_course_flag: boolean = false;
  public inv_flag: boolean = false;
  public dis_adv_flag: boolean = false;
  public dis_diag_flag: boolean = false;
  public treat_flag: boolean = false;
  public prev_med_flag: boolean = false;
  public phy_exam_flag: boolean = false;
  public otnote_flag: boolean = false;
  public comp_flag: boolean = false;
  public tittle_a: boolean = false;
  public tittle_b: boolean = false;
  reportlistarray: any = [];
  public centerid: any;
  public personalinfo;
  public hospId;

  public barcode_flag: boolean = false;
  public barcode_url: any;
  public print_font: string;
  public sections: any[] = [];
  public med_history:any[]=[];

  //Editor
  @ViewChild('editor', { static: false }) editor: ElementRef;
  editorStyle = {
    height: '300px;',
  };
  content;
  htmlString: string = '';
  modules = {
    toolbar: [
      ['bold', 'italic', 'underline',], // toggled buttons
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'align': [] }],
    ],

  };
  cns: any;
  rstxt: any;
  cvs: any;
  resp_rate: any;
  pulse: any;
  blood_pressure: any;
  temparature: any;
  weight_measure: any;
  weight: any;
  height_measure: any;
  height: any;
  public bmi: any;
  public bmr: any;
  public prtxt: any;
  public spo2: any;
  public cbg: any;
  public abdtxt: string;
  public medicalprescription: boolean = false;
  public printlogostyle;
  public ct_scan;
  public colonoscopy;
  public endoscopy;
  public mri_scan;
  public doppler;
  public endoscopy_flag: boolean = false;
  public colonoscopy_flag: boolean = false;
  public ct_scan_flag: boolean = false;
  public mri_scan_flag: boolean = false;
  public doppler_flag: boolean = false;
  public gen_exam_flag: boolean = false;
  public dietaryadviceflag:boolean=false;
  public presentillness:boolean=false;
  public emerg_contact: string;
  public header_table_style: string;
  public dietaryadvice: string;
  public gen_exam: string;
  public pres_ill: string;
  public emergency_flag:boolean=false;

  constructor(public sanitize: DomSanitizer, public dialog: MatDialog, public http: Http, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService, public messageService: MenuViewService) {

  }

  ngOnInit(): void {
    this.personalinfo = Helper_Class.getInfo()
    if (Helper_Class.getInfo().user_type == "front-desk") {
      this.centerid = Helper_Class.getInfo().hptl_clinic_id;
      this.printlogostyle = Helper_Class.getInfo().bill_print_logo_style;
      this.print_font = Helper_Class.getInfo().print_font_size;

    } else {
      this.printlogostyle = Helper_Class.getInfo().hospitals[0].bill_print_logo_style;
      this.hospital_details = this.personalinfo.hospitals[0];
      this.hospital_name = this.hospital_details.hptl_name;
      this.hospId = this.hospital_details.hptl_clinic_id;
      this.centerid = this.hospital_details.hptl_clinic_id;
      this.print_font = this.hospital_details.print_font_size;
    }

    this.hospital_details = this.personalinfo.hospitals[0];
    this.hospital_name = this.hospital_details.hptl_name;
    this.hospId = this.hospital_details.hptl_clinic_id;
    if (Helper_Class.getInfo().both_modules.length != 0) {
      var Moduleidlist
      Moduleidlist = Helper_Class.getInfo().both_modules;
      for (var i = 0; i < Moduleidlist.length; i++) {
        if (Moduleidlist[i].module_id == "6" || Moduleidlist[i].module_id == "119") {
          this.medicalprescription = true;
        }
        if (Moduleidlist[i].module_id == "157") {
          this.viewpres = true;
        }
      }
    }
    this.getCurrentDate();
    this.getfullreportdata();
    this.mobile = Doc_Helper.getMobile_no();
    this.inpatient_id = Doc_Helper.getInpatientId();
    this.confirm_btn = false;
    console.log("Doc_Helper.getAdmission_id()" + JSON.stringify(Doc_Helper.getAdmission_id()));
    console.log("Doc_Helper.getAdmission_id()" + JSON.stringify(Doc_Helper.getAdmission_id()));
    this.hospital_logo = ipaddress.Ip_with_img_address + Helper_Class.getInfo().hospitals[0].logo;
    if (Doc_Helper.getAdmission_id() != undefined && Doc_Helper.getAdmission_id().flag == "edit") {
      console.log("jkkj" + JSON.stringify(Doc_Helper.getAdmission_id()) + Doc_Helper.getAdmission_id().flag)
      this.confirm_btn = true;
      this.updateFlag = true;
      this.getRetrievalData();

    } else {
      if (this.mobile != undefined || this.inpatient_id != undefined) {
        this.getDischargeSummaryDetails();
      }
      Helper_Class.setPageFlow("dischargesum");
    }
    this.header_table_style = "font-weight: 600;";

    this.personal_information = Doc_Helper.getClient_Info();
    this.flagvalue = this.personal_information.flag;
    if (Helper_Class.getInfo().user_type == "front-desk") {
      this.print_template = Helper_Class.getInfo().pres_print_template;
      this.hptl_pres_logo = ipaddress.Ip_with_img_address + Helper_Class.getInfo().dis_sum_logo;
      this.headerstyle = this.sanitize.bypassSecurityTrustHtml(Helper_Class.getInfo().dis_sum_heading);
      this.footerstyle = this.sanitize.bypassSecurityTrustHtml(Helper_Class.getInfo().dis_sum_footer);
    } else {
      this.print_template = Helper_Class.getHospital()[0].pres_print_template;
      this.hptl_pres_logo = ipaddress.Ip_with_img_address + Helper_Class.getHospital()[0].dis_sum_logo;
      this.headerstyle = this.sanitize.bypassSecurityTrustHtml(Helper_Class.getHospital()[0].dis_sum_heading);
      this.footerstyle = this.sanitize.bypassSecurityTrustHtml(Helper_Class.getHospital()[0].dis_sum_footer);
    }

    if (this.hospId == '201') {
      this.print_font = "14px";

    } else {
      this.print_font = this.userinfo.print_font_size;
    }
  }

  setDischargeDate() {
    var disdata = this.dis_date.split("-");
    this.dis_date1 = disdata[2] + "-" + disdata[1] + "-" + disdata[0];
  }

  getRetrievalData() {

    var send = {
      inpatient_id: Doc_Helper.getAdmission_id().admission_id,
      hptl_clinic_id: this.hospital_details.hptl_clinic_id
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "inpat/gdsv", send,
      { headers: headers })
      .subscribe(
        response => {
          var values = response.json();
          this.druglist = [];
          this.consultarray = [];
          console.log("check valuobj from all weighht..es" + JSON.stringify(values.length))
          console.log("obj from all weighht....." + JSON.stringify(values));
          // alert("this.dis_date"+this.dis_date)
          if (values != undefined || values != null) {
            this.loader = true;
            if (values.middle_name != undefined) {
              this.patient_name = encrypt_decript.Decript(values.first_name) + " " + encrypt_decript.Decript(values.middle_name) + " " + encrypt_decript.Decript(values.last_name);

            } else {
              this.patient_name = encrypt_decript.Decript(values.first_name) + " " + encrypt_decript.Decript(values.last_name);
            }
            this.inpId = values.inp_hosp_id;
            this.patient_identifier = values.patient_id;
            this.clntgender = encrypt_decript.Decript(values.gender);
            if (this.clntgender == "Male") {
              this.gender = "M";
            } else if (this.gender == "Female") {
              this.gender = "F";
            }
            this.age = values.age;
            var dateval = values.admission_date.split('-');
            this.admDate = values.admission_date;
            this.admis_date = dateval[2] + "-" + dateval[1] + "-" + dateval[0];
            this.emerg_contact = values.emergency_contact;
            var docqualif;
            if (values.dr_qualif != undefined) {
              docqualif = values.dr_qualif
            } else {
              docqualif = "";
            }
            if (values.dr_mname != undefined) {
              this.doctor_name = "Dr. " + values.dr_fname + " " + values.dr_mname + " " + values.dr_lname + " " + docqualif;

            } else {
              this.doctor_name = "Dr. " + values.dr_fname + " " + values.dr_lname + " " + docqualif;
            }
            this.consultarray.push({ name: this.doctor_name });
            if (values.consultants != undefined) {
              var cname;
              for (var i = 0; i < values.consultants.length; i++) {
                if (values.consultants[i].middle_name != undefined) {
                  cname = "Dr." + values.consultants[i].first_name + " " + values.consultants[i].middle_name + " " + values.consultants[i].last_name;
                } else {
                  cname = "Dr." + values.consultants[i].first_name + " " + values.consultants[i].last_name;
                }
                if (values.consultants[i].qualification != undefined) {
                  cname = cname + " " + values.consultants[i].qualification;
                }
                this.consultarray.push({ name: cname });
              }
            }
            this.symptoms = values.symptoms;
            this.complaints = values.complaints;
            this.pres_ill= values.present_illness;
            this.medical_hist = values.medical_history;
            this.physical_exam = values.physical_exam;
            this.inpatient_id = values.inpatient_id;
            this.hosp_name = values.hptl_name;
            this.medical_hist = values.medical_history;
            this.physical_exam = values.physical_exam;
            this.discharge = values.discharge_id;
            this.dis_diag = values.discharge_diagnosis;
            this.hosp_course = values.hospital_course;
            this.Treatmetnt_done = values.treat_proc;
            this.investigation = values.investigation;
            this.ot_notes = values.ot_notes;
            this.emergency_notes = values.emergencies;
            this.hospital_followup = values.follow_summary;
            this.dietaryadvice = values.diet_advice
            this.endoscopy = values.endoscopy;
            this.colonoscopy = values.colonoscopy;
            this.ct_scan = values.ct_scan;
            this.mri_scan = values.mri_scan;
            this.doppler = values.doppler;

            this.presID = values.pres_drug_id;
            this.dis_date = Date_Formate(values.discharge_date);

            if (values.discharge_date != undefined) {
              var disdata = values.discharge_date.split("-");
              this.dis_date1 = disdata[2] + "-" + disdata[1] + "-" + disdata[0];
            }
            this.dis_date1 = Date_Formate(this.dis_date)
            // newly added
            this.height = values.height;
            this.height_measure = values.height_measure;
            this.weight = values.weight;
            this.weight_measure = values.weight_measure;
            this.temparature = values.temparature;
            this.blood_pressure = values.blood_pressure;
            this.pulse = values.pulse;
            this.resp_rate = values.resp_rate;
            this.cvs = values.cvs;
            this.rstxt = values.rstxt;
            this.cns = values.cns;
            this.bmi = values.bmi;
            this.bmr = values.bmr;
            this.prtxt = values.prtxt;
            this.spo2 = values.spo2;
            var addval;
            if (values.address2 != undefined) {
              addval = values.address1 + "," + values.address2;

            } else {
              addval = values.address1;
            }
            this.hosp_addr = addval;
            this.hosp_csz = values.city + ", " + values.state + " - " + values.zipcode + "  " + values.country;
            this.hosp_count = values.country;
            if (values.telephone != undefined) {
              this.hosp_tel = values.telephone;

            } else {
              this.hosp_tel = "-";
            }

            if (values.website != undefined) {
              this.hosp_web = values.website;
              this.hosp_web_flag = false;

            } else {
              this.hosp_web_flag = true;
            }

            if (values.next_visit != undefined && values.next_visit != '' && values.next_visit != null) {
              this.next_data = values.next_visit;
              var ndata = values.next_visit.split("-");
              this.next_data1 = ndata[2] + "-" + ndata[1] + "-" + ndata[0];
            }
            if (values.drug_list.length != 0) {
              for (var i = 0; i < values.drug_list.length; i++) {
                var durval;
                if (values.drug_list[i].time_duration != undefined) {
                  durval = values.drug_list[i].time_duration + " mins";

                } else {
                  durval = "";
                }
                var shortName = values.drug_list[i].short_name != undefined ? values.drug_list[i].short_name : "";
                this.druglist.push({
                  medicine_name: shortName + " " + values.drug_list[i].drug_name,
                  medicine_dosage: values.drug_list[i].every_six,
                  medicine_intakedays: values.drug_list[i].days + ' days ',
                  medicine_food: durval + " " + values.drug_list[i].drug_intake
                });
              }
            }

            if (values.surgery_date != undefined) {
              this.surgery_name = values.surgery_name != undefined ? values.surgery_name : "";
              this.surgery_date = values.surgery_date;
              this.req_sur_flag = true;
              this.surgery_proc = values.surgery_proc;
              if (values.surgeons != undefined) {
                for (var i = 0; i < values.surgeons.length; i++) {
                  var name;
                  if (values.surgeons[i].middle_name != undefined) {
                    name = "Dr. " + values.surgeons[i].first_name + " " + values.surgeons[i].middle_name + " " + values.surgeons[i].last_name + " " + values.surgeons[i].qualification;
                  } else {
                    name = "Dr. " + values.surgeons[i].first_name + " " + values.surgeons[i].last_name + " " + values.surgeons[i].qualification;
                  }
                  this.surgeon_list.push({ name: name });
                }
              }

              if (values.anaest_doc != undefined) {
                for (var i = 0; i < values.anaest_doc.length; i++) {
                  var name;
                  if (values.anaest_doc[i].middle_name != undefined) {
                    console.log("a0000 ---" + JSON.stringify(values.anaest_doc[i].qualification) + values.anaest_doc[i].middle_name)
                    if (values.anaest_doc[i].qualification != undefined || values.anaest_doc[i].qualification != "") {
                      name = "Dr. " + values.anaest_doc[i].first_name + " " + values.anaest_doc[i].middle_name + " " + values.anaest_doc[i].last_name + " " + values.anaest_doc[i].qualification;

                    } else {
                      name = "Dr. " + values.anaest_doc[i].first_name + " " + values.anaest_doc[i].middle_name + " " + values.anaest_doc[i].last_name;

                    }
                  } else {
                    console.log("else 000 ---" + JSON.stringify(values.anaest_doc[i].qualification) + values.anaest_doc[i].middle_name)

                    if (values.anaest_doc[i].qualification != undefined || values.anaest_doc[i].qualification != "") {
                      name = "Dr. " + values.anaest_doc[i].first_name + " " + values.anaest_doc[i].last_name + " " + values.anaest_doc[i].qualification;
                    } else {
                      name = "Dr. " + values.anaest_doc[i].first_name + " " + values.anaest_doc[i].last_name;

                    }
                  }
                  this.anaestetist.push({ name: name });
                  console.log("a0000 ---" + JSON.stringify(this.anaestetist))
                }
              }
            }

            if (values.surgery_time != undefined) {
              this.surgery_time = values.surgery_time;
            }
            this.dis_diag = values.discharge_diagnosis;
          }
        },
        error => {
          this.loader = true;
          this.toastr.error(Message_data.defaultErr);
        }
      )
  }

  view_prescription() {
    const dialogRef5 = this.dialog.open(InpatMedprescViewComponent, {
      width: '700px',
      height: '300px',
      data: { inpatient_id: this.inpatient_id }
    });

    dialogRef5.afterClosed().subscribe(result => {
      this.personal_information = Doc_Helper.getClient_Info()
    });
  }

  Write_prescription() {
    var inpat_pres: any = []
    inpat_pres = {
      Gender_data: this.personal_information.Gender_data,
      Age_data: this.personal_information.Age_data,
      app_id: this.personal_information.app_id,
      Client_id: this.personal_information.Client_id,
      rel_id: "1",
      sub_id: "",
      Case_Clnt_dob: this.personal_information.Case_Clnt_dob,
      spl_id: this.personal_information.spl_id,
      hptl_clinic_id: this.personal_information.hptl_clinic_id,
      mobile: this.personal_information.mobile,
      spl: this.personal_information.spl,
      symptoms: this.personal_information.symptoms,
      medicare_name: this.personal_information.medicare_name,
      client_name: this.personal_information.client_name,
      addmed: "write",
      //AppFlow: "InpatMedPres_dis",
      AppFlow: "InpatMedPres_dis",
      Appoint_Date: this.personal_information.Appoint_Date,
      address1: this.personal_information.address1,
      address2: this.personal_information.address2,
      location: this.personal_information.location,
      city: this.personal_information.city,
      state: this.personal_information.state,
      country: this.personal_information.country,
      zipcode: this.personal_information.zipcode,
      ref_by: this.personal_information.ref_by,
      hptl_name: this.personal_information.hptl_name,
      pres_id: this.presID,
      inpatient_id: this.inpatient_id,
      discharge_id: this.discharge, doc_reg_id: this.personal_information.doc_reg_id,
      barcode: this.barcode
    };
    Doc_Helper.setClient_Info(inpat_pres);
    this.messageService.sendMessage("medprescription");
  }

  getDischargeSummaryDetails() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "inpat/gdissum",
      JSON.stringify({
        mobile: this.mobile.toString(),
        inpatient_id: this.inpatient_id
      }),
      { headers: headers })
      .subscribe(
        response => {
          var values = response.json();
          this.druglist = [];
          this.consultarray = [];
          this.anaestetist = [];

          console.log("DISCHARGE SUMMARY " + JSON.stringify(values))
          if (values != undefined || values != null) {
            this.loader = true;
            if (values.salutation != undefined) {
              this.salutation = values.salutation
            }
            if (values.middle_name != undefined) {
              this.patient_name = this.salutation + "." + encrypt_decript.Decript(values.first_name) + " " + encrypt_decript.Decript(values.middle_name) + " " + encrypt_decript.Decript(values.last_name);
            } else {
              this.patient_name = this.salutation + "." + encrypt_decript.Decript(values.first_name) + " " + encrypt_decript.Decript(values.last_name);
            }
            this.inpId = values.inp_hosp_id;
            this.patient_identifier = values.patient_id;
            this.clntgender = encrypt_decript.Decript(values.gender);
            if (this.clntgender == "Male") {
              this.gender = "M";
            } else if (this.clntgender == "Female") {
              this.gender = "F";
            }
            this.age = values.age;

            if (values.admission_date != undefined) {
              var dateval = values.admission_date.split('-');
              this.admis_date = dateval[2] + "-" + dateval[1] + "-" + dateval[0];
              this.admDate = values.admission_date;

              var dtval = values.admission_time.split(':')
              if (parseInt(dtval[0]) > 12) {
                this.admis_time = (parseInt(dtval[0]) - 12) + ":" + dtval[1] + " PM";

              } else if (parseInt(dtval[0]) == 12) {
                this.admis_time = dtval[0] + ":" + dtval[1] + " PM";
              } else {
                this.admis_time = dtval[0] + ":" + dtval[1] + " AM";
              }
            }

            if (values.address2 != undefined) {
              this.fulladdress = values.address1 + "," + values.address2 + "," + values.location + "," + values.city + "-" + values.zipcode + "," + values.state + "," + values.country;
            } else {
              this.fulladdress = values.address1 + "," + values.location + "," + values.city + "-" + values.zipcode + "," + values.state + "," + values.country;

            }
            this.presID = values.pres_drug_id;
            // alert("ok"+values.discharge_date)
            if (values.discharge_date != undefined) {
              this.dis_date = Date_Formate(values.discharge_date);
              var disdata = values.discharge_date.split("-");
              this.dis_date1 = disdata[2] + "-" + disdata[1] + "-" + disdata[0];
              this.dis_date1 = values.discharge_date
            }
            if (values.discharge_time != undefined) {
              if (values.discharge_time != "") {
                this.discharge_time = values.discharge_time
                var dtval = values.discharge_time.split(':')
                if (parseInt(dtval[0]) > 12) {
                  this.dis_time = (parseInt(dtval[0]) - 12) + ":" + dtval[1] + " PM";

                } else if (parseInt(dtval[0]) == 12) {
                  this.dis_time = dtval[0] + ":" + dtval[1] + " PM";

                } else {
                  this.dis_time = dtval[0] + ":" + dtval[1] + " AM";
                }
              }
            }

            var docqualif;
            if (values.dr_qualif != undefined) {
              docqualif = values.dr_qualif
            } else {
              docqualif = "";
            }
            if (values.dr_mname != undefined) {
              this.doctor_name = "Dr. " + values.dr_fname + " " + values.dr_mname + " " + values.dr_lname + " " + docqualif;

            } else {
              this.doctor_name = "Dr. " + values.dr_fname + " " + values.dr_lname + " " + docqualif;
            }
            this.consultarray.push({ name: this.doctor_name });
            if (values.consultants != undefined) {
              var cname;
              for (var i = 0; i < values.consultants.length; i++) {
                if (values.consultants[i].middle_name != undefined) {
                  cname = "Dr." + values.consultants[i].first_name + " " + values.consultants[i].middle_name + " " + values.consultants[i].last_name;
                } else {
                  cname = "Dr." + values.consultants[i].first_name + " " + values.consultants[i].last_name;
                }
                if (values.consultants[i].qualification != undefined) {
                  cname = cname + " " + values.consultants[i].qualification;
                }
                this.consultarray.push({ name: cname });
              }
            }

            this.symptoms = values.symptoms;
            if (values.complaints != undefined) {
              this.complaints = values.complaints;
              this.comp_flag = true;
            }
           
          
            if (values.medical_history != undefined && values.medical_history != "") {
              this.medical_hist = values.medical_history;
              this.prev_med_flag = true;
            }

            if (values.physical_exam != undefined && values.physical_exam != "") {
              this.physical_exam = values.physical_exam;
              this.phy_exam_flag = true;
            }

            if (values.discharge_diagnosis != undefined) {
              this.dis_diag = values.discharge_diagnosis;
              this.dis_diag_flag = true;
            }

            if (values.hosp_course != undefined) {
              this.hosp_course = values.hosp_course;
              this.sum_course_flag = true;
            }

            if (values.emergencies != undefined) {
              this.emergency_notes = values.emergencies;
              this.emergency_flag=true
            }

            if (values.treat_proc != undefined) {
              this.Treatmetnt_done = values.treat_proc;
              this.treat_flag = true;
            }

            if (values.hospital_followup != undefined) {
              this.hospital_followup = values.hospital_followup;
              this.dis_adv_flag = true;
            }

            if (values.diet_advice != undefined) {
              this.dietaryadvice = values.diet_advice;
              this.dietaryadviceflag=true;  
            }
            if (values.present_illness != undefined) {
              this.pres_ill = values.present_illness;
              // public dietaryadviceflag:boolean=false;
              this.presentillness=true;
            
            }

            if (values.inp_inv != undefined) {
              this.investigation = values.inp_inv;
              this.inv_flag = true;
            }

            if (values.ot_notes != undefined) {
              this.ot_notes = values.ot_notes;
              this.otnote_flag = true;
            }

            if (this.dis_diag != undefined || this.investigation != undefined) {
              this.updateFlag = true;
              this.confirm_btn = true;
            }

            if (values.endoscopy != undefined) {
              this.endoscopy = values.endoscopy;
              this.endoscopy_flag = true;
            }
            if (values.colonoscopy != undefined) {
              this.colonoscopy = values.colonoscopy;
              this.colonoscopy_flag = true;
            }
            if (values.ct_scan != undefined) {
              this.ct_scan = values.ct_scan;
              this.ct_scan_flag = true;
            }
            if (values.mri_scan != undefined) {
              this.mri_scan = values.mri_scan;
              this.mri_scan_flag = true;
            }
            if (values.doppler != undefined) {
              this.doppler = values.doppler;
              this.doppler_flag = true;
            }

            this.inpatient_id = values.inpatient_id;
            this.hosp_name = values.hptl_name;
            this.emerg_contact = values.emergency_contact;

            if (values.surgery_date != undefined) {
              this.surgery_name = values.surgery_name != undefined ? values.surgery_name : "";;
              var surgdate = values.surgery_date.split("-");
              this.surgery_date = surgdate[2] + "-" + surgdate[1] + "-" + surgdate[0] + " " + values.surgery_time;
              this.surgery_proc = values.surgery_proc;
              if (values.surgeons != undefined) {
                for (var i = 0; i < values.surgeons.length; i++) {
                  var name;
                  if (values.surgeons[i].middle_name != undefined) {
                    name = "Dr. " + values.surgeons[i].first_name + " " + values.surgeons[i].middle_name + " " + values.surgeons[i].last_name + " " + values.surgeons[i].qualification;
                  } else {
                    name = "Dr. " + values.surgeons[i].first_name + " " + values.surgeons[i].last_name + " " + values.surgeons[i].qualification;
                  }
                  this.surgeon_list.push({ name: name });
                }
              }

              if (values.anaest_doc != undefined) {
                for (var i = 0; i < values.anaest_doc.length; i++) {
                  var name;
                  if (values.anaest_doc[i].middle_name != undefined) {
                    if (values.anaest_doc[i].qualification != undefined) {
                      name = "Dr. " + values.anaest_doc[i].first_name + " " + values.anaest_doc[i].middle_name + " " + values.anaest_doc[i].last_name + " " + values.anaest_doc[i].qualification;
                    } else {
                      name = "Dr. " + values.anaest_doc[i].first_name + " " + values.anaest_doc[i].middle_name + " " + values.anaest_doc[i].last_name;

                    }
                  } else {
                    if (values.anaest_doc[i].qualification != undefined) {
                      name = "Dr. " + values.anaest_doc[i].first_name + " " + values.anaest_doc[i].last_name + " " + values.anaest_doc[i].qualification;
                    } else {
                      name = "Dr. " + values.anaest_doc[i].first_name + " " + values.anaest_doc[i].last_name;

                    }
                  }
                  this.anaestetist.push({ name: name });
                }
              }
              console.log("check data --" + JSON.stringify(this.anaestetist))
              this.anaestetype = values.anaesttype;
              this.anaest_proc = values.anaest_proc;
              this.anaest_findings = values.anaest_findings;
            }

            this.Treatmetnt_done = values.treat_proc;
            this.hospital_followup = values.hospital_followup;

            var addval;
            if (values.address2 != undefined) {
              addval = values.address1 + "," + values.address2;
            } else {
              addval = values.address1;
            }

            this.hosp_addr = addval;
            this.hosp_csz = values.city + ", " + values.state + " - " + values.zipcode + "  " + values.country;
            this.hosp_count = values.country;
            if (values.telephone != undefined) {
              this.hosp_tel = values.telephone;

            } else {
              this.hosp_tel = "-";
            }

            if (values.website != undefined) {
              this.hosp_web = values.website;
              this.hosp_web_flag = false;

            } else {
              this.hosp_web_flag = true;
            }
          }

          if (values.next_visit != undefined && values.next_visit != '' && values.next_visit != null) {
            this.next_data = values.next_visit;
            var nextdate = values.next_visit.split('-');
            this.next_data1 = nextdate[2] + "-" + nextdate[1] + "-" + nextdate[0];
          }

          if(values.med_history != undefined){
            if(values.med_history.length != 0){
              for(let m=0;m < values.med_history.length;m++){
                this.med_history.push({
                  product_name	:values.med_history[m].product_name,
                  morning	:values.med_history[m].morning,
                  afternoon	:values.med_history[m].afternoon,
                  evening	:	values.med_history[m].evening,
                  night	:	values.med_history[m].night,
                  intake	:	values.med_history[m].intake,
                  time_duration	:	values.med_history[m].time_duration,

                })
              }
             
            }
          }



          if (Doc_Helper.getMedForDischarge() != undefined) {
            var med = Doc_Helper.getMedForDischarge();
            for (var i = 0; i < med.length; i++) {
              var durval;
              if (med.time_duration != undefined) {
                durval = med.time_duration + " mins";

              } else {
                durval = "";
              }

              var shortName = med[i].short_name != undefined ? med[i].short_name : "";
              this.druglist.push({
                medicine_name: shortName + " " + med[i].drug_name,
                medicine_dosage: med[i].every_six,
                medicine_intakedays: med[i].days + ' days ',
                medicine_food: durval + " " + med[i].drug_intake
              });
            }
          } else {
            var druglist = values.drug_list;
            if (values.drug_list != undefined) {
              for (var i = 0; i < druglist.length; i++) {
                var durval;
                if (druglist.time_duration != undefined) {
                  durval = druglist.time_duration + " mins";

                } else {
                  durval = "";
                }

                var shortName = druglist[i].short_name != undefined ? druglist[i].short_name : "";
                this.druglist.push({
                  medicine_name: shortName + " " + druglist[i].drug_name,
                  medicine_dosage: druglist[i].every_six,
                  medicine_intakedays: druglist[i].days + ' days ',
                  medicine_food: durval + " " + druglist[i].drug_intake
                });
              }
            }
          }
          console.log("med hist "+JSON.stringify(this.med_history))
          this.height = values.height;
          this.height_measure = values.height_measure;
          this.weight = values.weight;
          this.weight_measure = values.weight_measure;
          this.temparature = values.temparature;
          this.blood_pressure = values.blood_pressure;
          this.pulse = values.prtxt;
          this.resp_rate = values.resp_rate;
          this.cvs = values.cvs;
          this.rstxt = values.rstxt;
          this.cns = values.cns;
          this.abdtxt = values.abdtxt;

          this.bmi = values.bmi;
          this.bmr = values.bmr;
          this.prtxt = values.prtxt;
          this.spo2 = values.spo2;
          this.sections = [
            { order: values.diagnosis_order, template: this.diagnosis },
            { order: values.treatment_order, template: this.treatment },
            { order: values.chief_complaints_order, template: this.chief_complaints },
            // { order: values.present_illness_order, template: this.pres_ill },
            { order: values.prev_med_hist_order, template: this.medical_histtemp },
            { order: values.physical_exam_order, template: this.physical_examtemp },
            { order: values.investigation_order, template: this.investigationtemp },
            { order: values.ot_notes_order, template: this.ot_notestemp },
            { order: values.discharge_advice_order, template: this.discharge_adv },
            { order: values.vitals_order, template: this.vitalstemp },
            { order: values.summary_course_order, template: this.follow_sum },
            { order: values.present_illness_order, template: this.present_illne},
            { order:values.diet_adv_order, template: this.diet_advice },
            {order:values.drug_hist_order,template:this.drug_hist_order},
            {order:values.followup_order,template:this.followup_order},
            {order:values.surgery_procedure_order,template:this.surgery_pro_order},
            {order:values.emerygency_order,template:this.emergencyies}
          ]

        //this.presentillness,
        // dietaryadviceflag,
        // comp_flag,
        // prev_med_flag,
        // phy_exam_flag,
        // inv_flag,
        // surgery_date,
        // sum_course_flag,
        // dis_adv_flag,
        // emergency_flag

          this.sections.sort((a, b) => a.order - b.order);
          console.log("after_sort_2", this.sections);
        },
        error => {
          this.loader = true;
          this.toastr.error(Message_data.defaultErr);
        }
      )
  }

  NextDate(e) {
    this.next_data = e;
    // this.next_data=e.value.getDate()+"-"+ ((e.value.getMonth()) + 1) +"-" +e.value.getFullYear();
  }

  saveDischargeSummary() {
    var dateval;
    var newdate;
    if (this.next_data != undefined) {
      newdate = this.next_data;
    }

    var medpres;
    if (Helper_Class.get_med_pres_id() != null) {
      medpres = Helper_Class.get_med_pres_id();
    }
    var diagpres;
    if (Helper_Class.get_diag_pres_id() != null) {
      diagpres = Helper_Class.get_diag_pres_id();
    }
    var time24h = moment(this.dis_time, ["h:mm A"]).format("HH:mm");


    //var disdate =this.dis_date1.split("-");
    var save_data = JSON.stringify({
      inpatient_id: this.inpatient_id,
      discharge_diagnosis: this.dis_diag,
      hopital_course: this.hosp_course,
      follow_summary: this.hospital_followup,
      next_visit: newdate,
      pres_drug_id: medpres,
      symptoms: this.symptoms,
      treat_proc: this.Treatmetnt_done,
      investigation: this.investigation,
      ot_notes: this.ot_notes,
      diet_advice: this.dietaryadvice,
      endoscopy: this.endoscopy,
      colonoscopy: this.colonoscopy,
      ct_scan: this.ct_scan,
      mri_scan: this.mri_scan,
      doppler: this.doppler,
      emergencies: this.emergency_notes,


      discharge_date: this.dis_date1,
      discharge_time: time24h,
    });
    console.log("this.save_data" + this.flagvalue + JSON.stringify(save_data))

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'inpat/sdissum', save_data,
      { headers: headers })
      .subscribe(
        response => {
          var data = JSON.parse(response["_body"]);
          console.log("check data " + JSON.stringify(data))
          if (data.key != "0") {
            this.confirm_btn = true;
            this.toastr.success(Message_data.disSummarySavedSuccess);
            //  this.print_area();
          } else {
            this.confirm_btn = false;
            this.toastr.error(Message_data.unabltToSaveDisSummary);
          }
        })
  }

  goToPatientlist() {
    console.log("this.flagvalue" + this.flagvalue + JSON.stringify(Doc_Helper.getAdmission_id().flag))
    console.log("this.this.personalinfo.AppFlow" + JSON.stringify(this.personalinfo.AppFlow))

    if (this.flagvalue == "inpat") {
      this.messageService.sendMessage("viewIpatient");
    } else if (this.flagvalue == undefined) {
      this.messageService.sendMessage("viewIpatient");
      //this.messageService.sendMessage("inPatientViewInfo");

    } else if (Doc_Helper.getAdmission_id().flag != undefined) {
      if (Doc_Helper.getAdmission_id().flag == "edit") {
        this.messageService.sendMessage("patient_dashboard");
      } else if (this.personalinfo.AppFlow == "InpatMedPres_dis") {
        this.messageService.sendMessage("inPatientViewInfo");
      }
    } else {
      this.messageService.sendMessage("inPatientViewInfo");
    }
  }

  getCurrentDate() {
    var get_date;
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code.toString()
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.current_date != null) {
            get_date = obj.current_date.split('-');
            this.currentDate = obj.current_date;
            // this.next_data = obj.current_date;
            var dis_date1 = obj.current_date;
            console.log("time format" + JSON.stringify(obj.current_time))
            var disdata = dis_date1.split("-");
            this.dis_date1 = disdata[2] + "-" + disdata[1] + "-" + disdata[0];
            var dtval = obj.current_time.split(':')
            if (parseInt(dtval[0]) > 12) {
              this.dis_time = (parseInt(dtval[0]) - 12) + ":" + dtval[1] + " PM";
            } else if (parseInt(dtval[0]) == 12) {
              this.dis_time = dtval[0] + ":" + dtval[1] + " PM";
            } else {
              this.dis_time = dtval[0] + ":" + dtval[1] + " AM";
            }
          }
        },
        error => {

        }
      )
  }

  print_area() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "inpat/gdsv", {
      inpatient_id: Doc_Helper.getAdmission_id().admission_id,
      hptl_clinic_id: this.centerid,
    },
      { headers: headers })
      .subscribe(
        response => {
          console.log("disc headers"+JSON.stringify(response))
          var values = JSON.parse(response["_body"]);
          console.log("disc h"+JSON.stringify(values))

          if (values != undefined) {
            if (values.salutation != undefined) {
              this.salutation = values.salutation
            }
            this.loader = true;
            if (values.middle_name != undefined) {
              this.patient_name = this.salutation + "." + encrypt_decript.Decript(values.first_name) + " " + encrypt_decript.Decript(values.middle_name) + " " + encrypt_decript.Decript(values.last_name);

            } else {
              this.patient_name = this.salutation + "." + encrypt_decript.Decript(values.first_name) + " " + encrypt_decript.Decript(values.last_name);
            }

            if(values.barcode != undefined){
              this.barcode_flag = true;
              this.barcode_url = ipaddress.Ip_with_img_address + values.barcode;
              this.barcode=values.barcode;
             }

            this.patient_identifier = values.patient_id;
            this.clntgender = encrypt_decript.Decript(values.gender);
            if (this.clntgender == "Male") {
              this.gender = "M";
            } else if (this.gender == "Female") {
              this.clntgender = "F";
            }
            var docqualif;
            if (values.dr_qualif != undefined) {
              docqualif = values.dr_qualif
            } else {
              docqualif = "";
            }

            this.age = values.age;
            var dateval = values.admission_date.split('-');
            this.salutation = values.salutation
            this.admis_date = dateval[2] + "-" + dateval[1] + "-" + dateval[0];
            //this.dis_date=localStorage.getItem("current_date");
            if (values.dr_mname != undefined) {
              this.doctor_name = "Dr. "+values.dr_fname + " " + values.dr_mname + " " + values.dr_lname+" "+docqualif;

            } else {
              this.doctor_name = "Dr. "+values.dr_fname + " " + values.dr_lname+" "+docqualif;
            }

            this.symptoms = values.symptoms;
            this.complaints = values.complaints;

            this.medical_hist = values.medical_history;
            this.physical_exam = values.physical_exam;
            this.Treatmetnt_done = values.treat_proc;
            this.dis_diag = values.discharge_diagnosis;
            this.hosp_course = values.hospital_course;
            this.hospital_followup = values.follow_summary;
            this.pres_ill=values.present_illness;
            this.dietaryadvice= values.diet_advice;


            if (values.next_visit != undefined && values.next_visit != '' && values.next_visit != null) {
              var nextdate = values.next_visit.split('-');
              this.next_data = values.next_visit;
              this.next_data1 = nextdate[2] + "-" + nextdate[1] + "-" + nextdate[0];
            }

            this.druglist = [];
            if (values.drug_list != undefined) {
              this.print_drug = true;
              for (var i = 0; i < values.drug_list.length; i++) {
                var master_tablet_data = values.drug_list[i];
                var medicine_dosage_data = "";

                if (master_tablet_data.intake_desc == "Every 6 hours") {
                  medicine_dosage_data = master_tablet_data.morning + master_tablet_data.short_form + '-' + master_tablet_data.morning + master_tablet_data.short_form + ' - ' + master_tablet_data.morning + master_tablet_data.short_form + master_tablet_data.short_form + ' - ' + master_tablet_data.morning + master_tablet_data.short_form;

                } else if (master_tablet_data.intake_desc == "SOS") {
                  medicine_dosage_data = "SOS - if required";

                } else if (master_tablet_data.intake_desc == "Thrice a day") {
                  medicine_dosage_data = master_tablet_data.morning + master_tablet_data.short_form + " - " + master_tablet_data.afternoon + master_tablet_data.short_form + " - " + master_tablet_data.night + master_tablet_data.short_form;

                } else if (master_tablet_data.intake_desc == "Four times a day") {
                  medicine_dosage_data = master_tablet_data.morning + master_tablet_data.short_form + " - " + master_tablet_data.afternoon + master_tablet_data.short_form + " - " + master_tablet_data.evening + master_tablet_data.short_form + " - " + master_tablet_data.night + master_tablet_data.short_form;

                } else if (master_tablet_data.intake_desc == "Once a week") {
                  medicine_dosage_data = master_tablet_data.morning + master_tablet_data.short_form + " - " + master_tablet_data.afternoon + master_tablet_data.short_form + " - " + master_tablet_data.evening + master_tablet_data.short_form + " - " + master_tablet_data.night + master_tablet_data.short_form;

                } else if (master_tablet_data.intake_desc == "Once a day") {
                  medicine_dosage_data = master_tablet_data.morning + master_tablet_data.short_form + " - per day";

                } else {
                  medicine_dosage_data = master_tablet_data.morning + master_tablet_data.short_form + ' - ' + master_tablet_data.afternoon + master_tablet_data.short_form + ' - ' + master_tablet_data.night + master_tablet_data.short_form;
                }

                var master_timeDuration = "";
                if (master_tablet_data.drug_intake == "With food") {
                  master_timeDuration = master_tablet_data.drug_intake;

                } else {
                  if (master_tablet_data.time_duration != null && master_tablet_data.time_duration != "0") {
                    master_timeDuration = master_tablet_data.time_duration + ' mins ' + master_tablet_data.drug_intake;

                  } else {
                    master_timeDuration = master_tablet_data.drug_intake;
                  }
                }

                this.druglist.push({
                  medicine_name: master_tablet_data.short_name + " " + master_tablet_data.drug_name,
                  medicine_dosage: medicine_dosage_data,
                  medicine_intakedays: master_tablet_data.days + ' days ',
                  medicine_food: master_timeDuration
                })
              }
            } else {
              this.print_drug = false;      
            }


            this.hosp_name = values.hptl_name;
            var addval;
            if (values.address2 != undefined) {
              addval = values.address1 + "," + values.address2;

            } else {
              addval = values.address1;
            }
            this.hosp_addr = addval;
            this.hosp_csz = values.city + ", " + values.state + " - " + values.zipcode + "  " + values.country;
            this.hosp_count = values.country;
            if (values.telephone != undefined) {
              this.hosp_tel = values.telephone;

            } else {
              this.hosp_tel = "-";
            }
            this.emergency_notes = values.emergencies;
            if (values.website != undefined) {
              this.hosp_web = values.website;
              this.hosp_web_flag = false;

            } else {
              this.hosp_web_flag = true;
            }

            this.sections=[
              {order:values.diagnosis_order,template:this.diagnosis},
              {order:values.treatment_order,template:this.treatment},
              {order:values.chief_complaints_order,template:this.chief_complaints},
              {order:values.prev_med_hist_order,template:this.medical_histtemp},
              {order:values.physical_exam_order,template:this.physical_examtemp},
              {order:values.investigation_order,template:this.investigationtemp},
              {order:values.ot_notes_order,template:this.ot_notestemp},
              {order:values.discharge_advice_order,template:this.discharge_adv},  
              {order:values.vitals_order,template:this.vitalstemp},
               {order:values.summary_course_order,template:this.follow_sum},
               {order: values.present_illness_order,template:this.present_illne},
               {order:values.diet_adv_order,template:this.diet_advice},
               {order:values.drug_hist_order,template:this.drug_hist_order},
               {order:values.followup_order,template:this.followup_order},
               {order:values.surgery_procedure_order,template:this.surgery_pro_order},
               {order:values.emerygency_order,template:this.emergencyies}
            ]
            this.sections.sort((a, b) => a.order - b.order);
            
            setTimeout(()=> {
              this.print();              
            }, 200)
            
          } else {
            this.toastr.error(Message_data.defaultErr);
          }
          console.log("print order = ",this.sections)
        },
        error => {
          this.loader = true;
          this.toastr.error(Message_data.network);
        }
      )
  }


print() {
  let printContents, popupWin;
  this.print_template = Helper_Class.getInfo().pres_print_template;
  if (this.print_template != undefined && this.print_template == "banner") {
    printContents = this.printbanner.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;

  } else if (this.print_template != undefined && this.print_template == "logowithname") {
    printContents = this.printlogowithname.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;

  } else if (this.print_template != undefined && this.print_template == "llhtemplate") {
    printContents = this.printbanner_llh.nativeElement.innerHTML; //document.getElementById('print-section').innerHTML;

  } else {

    printContents = this.printnoheader.nativeElement.innerHTML;
  }
  popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
  popupWin.document.open();
  popupWin.document.write(`
      <head>
        <title>Discharge summary</title>
        <link rel="stylesheet" media="screen" href="">
        <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">

        <style>
          @page { size: auto;  margin: 0mm 10mm 10mm 10mm; }
          .table_class {
            background-color: rgb(108, 180, 248);
          }
          * {
            font-size: ${this.print_font} !important; 
          }
          .block1_grid{
            margin: 7px auto;
            width: 90%;
          }
          .block1{
            margin:3px 0;
          }
        </style>
      </head>
        <body onload="window.print();window.onfocus=function(){ window.close();}">${printContents}</body>
      </html>`
  );
  popupWin.document.close();
}

onContentChanged = (event) => {
  this.dis_diag = event.html;
  // if (this.dis_diag.length > this.maxLength) {
  //   this.dis_diag = this.dis_diag.substring(0, this.maxLength); // Trim the text
  // }
}
updateText(newValue: string) {
  // Update the text only if the length is within the limit
  if (newValue.length <= this.maxLength) {
    this.dis_diag = newValue;
  }
}

onContentgeneralexa = (event) => {
  this.gen_exam = event.html;
}

onContentChangedTreatment = (event) => {
  this.Treatmetnt_done = event.html;
}

onContentChangedHospitalSummary = (event) => {
  this.hosp_course = event.html;
}

onContentChangedInvestigation = (event) => {
  this.investigation = event.html;
}

onContentChangedOtNotes = (event) => {
  this.ot_notes = event.html;
}

onContentChangedEmergency = (event) => {
  this.emergency_notes = event.html;
}

onContentChangedDischargeAdvice = (event) => {
  this.hospital_followup = event.html;
}

onContentChangeddeit = (event) => {
  this.dietaryadvice = event.html;
}

onContentChangedEndo = (event) => {
  this.endoscopy = event.html;
}
onContentChangedcolon = (event) => {
  this.colonoscopy = event.html;
}
onContentChangedMri = (event) => {
  this.mri_scan = event.html;
}
onContentChangedCtscan = (event) => {
  this.ct_scan = event.html;
}
onContentChangedDropler = (event) => {
  this.doppler = event.html;
}

updateDischarge() {
  var newdate;
  if (this.next_data != undefined) {
    newdate = this.next_data;
    // dateval= this.next_data.split('-');
    // newdate = dateval[2]+"-"+dateval[1]+"-"+dateval[0];
  }
  var medpres;
  if (Helper_Class.get_med_pres_id() != null) {
    medpres = Helper_Class.get_med_pres_id();
  } else {
    medpres = this.presID;
  }

  var diagpres;
  if (Helper_Class.get_diag_pres_id() != null) {
    diagpres = Helper_Class.get_diag_pres_id();
  }
  if (this.discharge_time != undefined) {
    this.discharge_time = this.discharge_time
  } else {
    this.discharge_time = ""
  }

  var ddata = this.dis_date1.split("-");
  var disdate;
  if (ddata[0].length == 4) {
    disdate = this.dis_date1;
  } else {
    disdate = ddata[2] + "-" + ddata[1] + "-" + ddata[0]
    //disdate = Date_Formate(this.dis_date1)
  }

  var save_data = JSON.stringify({
    inpatient_id: this.inpatient_id,
    discharge_diagnosis: this.dis_diag,
    hopital_course: this.hosp_course,
    follow_summary: this.hospital_followup,
    next_visit: newdate,
    pres_drug_id: medpres,
    symptoms: this.symptoms,
    treat_proc: this.Treatmetnt_done,
    investigation: this.investigation,
    ot_notes: this.ot_notes,
    endoscopy: this.endoscopy,
    colonoscopy: this.colonoscopy,
    ct_scan: this.ct_scan,
    mri_scan: this.mri_scan,
    doppler: this.doppler,
    emergencies: this.emergency_notes,
    diet_advice: this.dietaryadvice,
    discharge_date: disdate,
    pres_diag_id: diagpres,
    discharge_time: this.discharge_time
  });
  this.updateFlag = false;
  console.log(("update summary" + JSON.stringify(save_data)))
  var headers = new Headers();
  headers.append('Content-Type', 'application/json');
  this.http.post(ipaddress.getIp.toString() + 'inpat/udissum', save_data,
    { headers: headers })
    .subscribe(
      response => {
        var data = JSON.parse(response["_body"]);
        if (data.key != "0") {
          this.updateFlag = true;
          this.toastr.success(Message_data.disSummarySavedSuccess);
          // this.messageService.sendMessage("patient_dashboard");
          if (Helper_Class.getInfo().user_type == "front-desk") {
            this.messageService.sendMessage("viewIpatient");
          } else {
            this.messageService.sendMessage("patient_dashboard");
          }

        } else {
          this.updateFlag = true;
          this.toastr.error(Message_data.unabltToSaveDisSummary);
        }
      })
}

getfullreportdata() {
  var send_data = {
    hptl_clinic_id: this.centerid,
  };
  var headers = new Headers();
  headers.append('Content-Type', 'application/json');

  this.http.post(ipaddress.getIp.toString() + 'inpat/gidc', send_data, { headers: headers })
    .subscribe(
      response => {
        var obj = response.json();
        console.log(" 22 = " + JSON.stringify(obj))
        this.reportlistarray = {
          medical_history_desc: obj.prev_med_hist_desc,
          follow_summary_desc: "SUMMARY COURSE",
          generalexam_desc: "ON DISCHARGE VITALS",
          ot_notes_desc: obj.ot_notes_desc,
          discharge_heading: obj.discharge_heading,
          diagnosis_req: obj.diagnosis_req,
          diagnosis_desc: obj.diagnosis_desc,
          treatment_req: obj.treatment_req,
          treatment_desc: obj.treatment_desc,
          summary_course_req: obj.summary_course_req,
          summary_course_desc: obj.summary_course_desc,
          investigation_req: obj.investigation_req,
          investigation_desc: obj.investigation_desc,
          emerygency_req: obj.emerygency_req,
          emergency_desc: obj.emergency_desc,
          discharge_advice_req: obj.discharge_advice_req,
          discharge_advice_desc: obj.discharge_advice_desc,
          chief_complaints_desc: obj.chief_complaints_desc,
          chief_complaints_req: obj.chief_complaints_req,
          // chief_complaints_req: obj.chief_complaints_req,
          // chief_complaints_desc: obj.chief_complaints_desc,
          prev_med_hist_req: obj.prev_med_hist_req,
          prev_med_hist_desc: obj.prev_med_hist_desc,

          physical_exam_req: obj.physical_exam_req,
          physical_exam_desc: obj.physical_exam_desc,
          vitals_req: obj.vitals_req,
          height_req: obj.height_req,
          weight_req: obj.weight_req,
          temparature_req: obj.temparature_req,
          bp_req: obj.bp_req,
          bp_desc: obj.bp_desc,
          pulse_req: obj.pulse_req,
          pulse_desc: obj.pulse_desc,
          rr_req: obj.rr_req,
          rr_desc: obj.rr_desc,
          cvs_req: obj.cvs_req,
          cvs_desc: obj.cvs_desc,
          rs_req: obj.rs_req,
          rs_desc: obj.rs_desc,
          cns_req: obj.cns_req,
          cns_desc: obj.cns_desc,
          abd_req: obj.abd_req,
          abd_desc: obj.abd_desc,
          spo2_req: obj.spo2_req,
          spo2_desc: obj.spo2_desc,
          surgery_req: obj.surgery_req,
          surgery_procedure_req: obj.surgery_procedure_req,
          surgery_procedure_desc: obj.surgery_procedure_desc,
          surgery_date_req: obj.surgery_date_req,
          surgery_date_desc: obj.surgery_date_desc,
          anaestetist_req: obj.anaestetist_req,
          anaestetist_desc: obj.anaestetist_desc,
          anaestesia_req: obj.anaestesia_req,
          anaestesia_desc: obj.anaestesia_desc,
          family_hist_desc: obj.family_hist_desc,
          family_hist_req: obj.family_hist_req,
          present_illness_req: obj.present_illness_req,
          present_illness_desc: obj.present_illness_desc,
          surgeon_req: obj.surgeon_req,
          surgeon_desc: obj.surgeon_desc,
          anaestesia_procedure_req: obj.anaestesia_procedure_req,
          anaestesia_procedure_desc: obj.anaestesia_procedure_desc,
          endoscopy_desc: obj.endoscopy_desc,
          colonoscopy_desc: obj.colonoscopy_desc,
          ct_scan_desc: obj.ct_scan_desc,
          mri_scan_desc: obj.mri_scan_desc,
          doppler_desc: obj.doppler_desc,
          endoscopy_req: obj.endoscopy_req,
          colonoscopy_req: obj.colonoscopy_req,
          ct_scan_req: obj.ct_scan_req,
          mri_scan_req: obj.mri_scan_req,
          doppler_req: obj.doppler_req,
          heading_style: obj.heading_style,
          followup_desc: obj.followup_desc,
          vitals_desc: obj.vitals_desc,
          diet_adv_desc: obj.diet_adv_desc,
          diet_adv_req: obj.diet_adv_req,
          medicine_desc: obj.medicine_desc,
          drug_hist_desc:obj.drug_hist_desc,
          drug_hist_req:obj.drug_hist_req
        };
      },

      error => {
        this.toastr.warning("error in fetch role");
      }
    );
}
}
