import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Helper_Class } from '../../helper_class';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { Helper_nephro_casesheet } from '../../../assets/js/Helper_nephro_casesheet';
import { SlickCarouselComponent } from "ngx-slick-carousel";
import { Doc_Helper } from '../Doc_Helper';
import { CasesheetService } from './casesheet.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { MenuViewService } from '../../../app/common-module/menu-view/menu-view.service';
import { Message_data } from '../../../assets/js/Message_data';
declare var $: any;
import { Observable, observable, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { VideoLinkComponent } from '../video-link/video-link.component';
import { ipaddress } from '../../ipaddress'; import { NurseService } from '../../../app/Nurse_module/nurse-view/nurse.service';
import { MasterCSHelperService } from './masterCSData.service';
import { Date_Formate } from 'src/assets/js/common';
import { encrypt_decript } from 'src/assets/js/Encription_Decription';

@Component({
  selector: 'app-casesheet',
  templateUrl: './casesheet.component.html',
  styleUrls: ['./casesheet.component.css']
})
export class CasesheetComponent implements OnInit {
  public medisaveFlag: boolean = false;
  public selectedFile;
  public medicine_Card;
  public diagnosis_Card;
  public inpatflag: boolean = false;
  public mrnno;
  public bedno;
  public visitpurpose;
  public inpatientnuber;
  public outpatientnumber;
  public doctor_name;
  public mrnflag: boolean = false;
  public persondataflag: boolean = false;
  public ageflag: boolean = false;
  public genderflag: boolean = false;
  public personalFlag;
  public medPresFlag;
  public diagPresFlag;
  public clinicalFlag;
  public dietPlanFlag;
  public apptFlag;
  public habitualFlag;
  public familyHistFlag;
  public diagnosisFlag;
  public dischargesummary;
  public medicalprescription;
  public diagnosisprescription;
  public wardno;
  public admission_time;
  public admission_date;
  public surgery_inpat
  //gyne
  public gynPersonalFlag;
  public gynClinicalFlag;
  public obsHistoryFlag;
  public surgHistoryFlag;

  //pediatrics
  public pediaPersonalFlag;
  public pediaClinicalFlag;
  public pediaImmFlag;
  public pediaDevelopFlag;
  public pediapregdelFlag;
  public pediavitalFlag;

  //neph
  public nephphysicalFlag;
  public nephDiagFlag;
  public nephReportsFlag;
  public nephVitalsFlag;

  //dental
  public dentalExaminationFlag;
  public dentalSpecFlag;
  public dentalPlanFlag;
  public dentalreporFlag;

  //diab
  public diabUploadFlag;
  public diabFootFlag;
  public diabCompFlag;

  //general
  public generalReportFlag;
  public generalsurgFlag;

  //others
  public othersPersonalFlag;
  public othersClinicalFlag
  public min = "min";
  public max = "max";
  public min_max_flag: boolean = false;
  public carousel_view_flag: boolean = false;

  public users: any = [];
  public userlist: any;
  public selectedUser: any;
  public patient_name;
  public patient_age;
  public patient_gender;

  //components
  public personal_flag: boolean;
  public clinical_flag: boolean;
  public diagnosis_flag: boolean;
  public personaltab: boolean;
  public clinicaltab: boolean;
  public familytab: boolean;
  public diagnosistab: boolean;
  public dietplantab: boolean;
  public cardio_personal: any;
  public cardio_habitual: any;
  public cardio_family: any;
  public cardio_clinical: any;
  public cardio_diagnosis: any;
  public diet_mrng_arry: any;
  public break_fast_arrydata: any;
  public mid_day_arrydata: any;
  public lunch_arrydata: any;
  public evening_arrydata: any;
  public dinner_arrydata: any;
  public night_arrydata: any;

  public diab_footarr: any;
  public diab_complication_arr: any;

  public blood_preshidden: boolean;
  public header_footer_flag: boolean;
  public header_footertab_flag: boolean;
  public header_footermobile_flag: boolean;
  public main_hidden: boolean;
  public gyna_fields;
  public disease_hidden: boolean;
  public stress_hidden;
  public fooId;
  public gyna_personal_array;
  public gyna_surgical_array;
  public gyna_family_array;
  public gyna_obestric_array;
  public gyna_clinic_array;
  public gyna_diagnosis_array;
  public gyna_treatement_array;
  public gyna_dietplan_array;
  public hideTabs: boolean;
  public clinic_tab: boolean;
  public diag_outtab: boolean;
  public diag_treattab: boolean;
  public tab_value;
  public gyna_persontab: boolean;
  public obestric_tab: boolean;
  public diet_plantab: boolean;
  public lastmenstrual_hidden: boolean;
  public maritial_hidden: boolean;
  public current_occupationhidden: boolean;
  public icdcode_hidden: boolean;
  public prognosis_hidden: boolean;
  public phyex_hidden: boolean;
  public phydure_hidden: boolean;
  public phyfreq_hidden: boolean;
  public speclization_arry;

  //pedia_save
  public active_tab;

  public send_arr: any;
  public pedia_person_arry: any;
  public Pedia_pregnancy_arry: any;
  public pedia_fam_arr: any;
  public pedia_head_array: any;
  public pedia_development_array: any;
  public pedia_treat_arr: any;
  public Pedia_immunization_array: any;
  public personal_tab: boolean;
  public pregnancy_tab: boolean;
  public family_tab: boolean;
  public vital_tab: boolean;
  public head_tab: boolean;
  public development_tab: boolean;
  public immunize_tab: boolean;
  public treate_mentplan: boolean;
  public motherage_hidden: boolean;
  public gestation_agehidden: boolean;
  public delivery_hidden: boolean;
  public noof_preghidden: boolean;
  public savebtn: boolean;
  public static Provider_or_Client: string;
  public static casesheet: string;
  public static relation: string;
  public static case_hist_id;
  public static relation_id;
  public static modified;
  public static date;
  public static gender;
  public static age;
  public static sub_rel_id;
  public static created_time;
  public static dob;

  //others
  public personal: any;
  public family: any;
  public clinical: any;
  public diagnosis: any;
  public surgerytab: boolean;
  public cards: any[] = [];
  public cardview_flag: boolean = false;
  public d_flag: boolean = false;

  //general
  public surgery: any;

  //openMedical
  public openMedical: boolean;
  //detailed
  @ViewChild("slickModal")
  slickModal!: SlickCarouselComponent;
  activeIndex = -1;
  fadeInOut = false;
  isShow = false;

  public Gynecology_flag: boolean = false;
  public cardio_flag: boolean = false;
  public gastro_flag: boolean = false;
  public Pediatrics_flag: boolean = false;
  public clinical_exam_flag: boolean = false;
  public Others_flag: boolean = false;
  public Dental_flag: boolean = false;
  public Diabetes_flag: boolean = false;
  public General_flag: boolean = false;
  public oflag: boolean = false;
  public Nephrology_flag: boolean = false;
  public ENT_flag: boolean = false;

  public patient_image;
  public userinfo;
  public client: boolean = false;

  public reporttab: boolean;
  public treatplantab: boolean;
  public postoperationtab: boolean;
  public loader: boolean;
  public ent_personal: any;
  public ent_family: any;
  public ent_clinical: any;
  public ent_diagnosis: any;
  public module_flag: boolean = false;

  public inpatientFlag: boolean;
  customOptions: OwlOptions = {
    //autoplay : true,
    autoplay: false,
    autoplaySpeed: 1500,
    loop: true,
    pullDrag: false,
    dots: false,
    navSpeed: 750,
    navText: ['<i class="fa fa-chevron-circle-left left" aria-hidden="true" style="font-size: 50px;color: #bfbbbb;position: absolute;left: 16px;top: 46%;z-index: 1;"></i>', '<i class="fa fa-chevron-circle-right right" aria-hidden="true"></i>'],
    responsive: {
      0: {
        items: 4
      },
      400: {
        items: 6
      },
      740: {
        items: 8
      },
      940: {
        items: 10
      }
    },
    nav: true,
  }
  customOptions1: OwlOptions = {
    //autoplay : true,
    autoplay: false,
    autoplaySpeed: 1500,
    loop: false,
    pullDrag: false,
    dots: false,
    navSpeed: 750,
    //  navText: ['<i class="fa fa-chevron-circle-left left" aria-hidden="true" style="font-size: 50px;color: #bfbbbb;position: absolute;left: 16px;top: 46%;z-index: 1;"></i>', '<i class="fa fa-chevron-circle-right right" aria-hidden="true"></i>'],
    navText: ['', ''],
    responsive: {
      0: {
        items: 4
      },
      400: {
        items: 4
      },
      740: {
        items: 4
      },
      940: {
        items: 4
      }
    },
    nav: false,
  }

  subscription: Subscription;
  public casesheet_flag;
  public user_id;
  public save_flag: boolean = true;
  public SplFileds: any[] = [];
  public profileImg;
  public addressdetails;

  constructor(public nurseservice: NurseService, public doctorviewservice: MenuViewService, public messageservice: CasesheetService,
    public http: Http, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService, public dialog: MatDialog,
    public masterCSdata: MasterCSHelperService) {
    this.messageservice.sendCasesheetMessage("min");
    Doc_Helper.setMedDiag(undefined);
    Doc_Helper.setDiagSave(undefined);
    Doc_Helper.setMedSave(undefined);
    this.personal_flag = true;
    this.clinical_flag = true;
    this.diagnosis_flag = true;
    this.personaltab = true;
    this.familytab = true;
    this.clinicaltab = true;
    this.diagnosistab = true;
    this.dietplantab = true;

    //pedia
    this.savebtn = true;
    this.personal_tab = true;
    this.pregnancy_tab = true;
    this.family_tab = true;
    this.vital_tab = true;
    this.head_tab = true;
    this.development_tab = true;
    this.treate_mentplan = true;
    this.immunize_tab = true;
  }

  ngOnInit() {
    this.profileImg = "../../assets/img/default.jpg";

    this.subscription = this.messageservice.getMedicalName().subscribe(message => {
      this.openMedical = Doc_Helper.getMedicalHeading() == "true" ? false : true;
    });
    
    this.userinfo = Helper_Class.getInfo();
    if (Helper_Class.getapp_flow() == "1") {
      this.userlist = Helper_Class.getRet();

    } else if (Doc_Helper.getAppFlow() == "home_case_sheet") {
      this.userlist = Doc_Helper.getClient_Info();

    } else if (Doc_Helper.getAppFlow() == "case_sheet" || Doc_Helper.getAppFlow() == "Inpatient") {
      this.userlist = Doc_Helper.getClient_Info();

    } else {
      if (Doc_Helper.getAppFlow() == "client") {
        this.userlist = Helper_Class.getInfo();
        this.client = true;
      } else {
        this.client = false;
      }
    }

    this.inpatientFlag = Doc_Helper.getAppFlow() == "Inpatient" ? true : false;
    this.d_flag = Doc_Helper.getDischarge_Summary() == "S" ? true : false;
    this.user_id = Helper_Class.getInpatientFlow() == "nurse" ? this.userlist.user_id : this.userinfo.user_id;
    this.inpatientFlag = Doc_Helper.getAppFlow() == "Inpatient" ? true : false;
    this.d_flag = Doc_Helper.getDischarge_Summary() == "S" ? true : false;
    this.user_id = Helper_Class.getInpatientFlow() == "nurse" ? this.userlist.user_id : this.userinfo.user_id;
    if (this.userlist.client_name != "") {
      if(this.userlist.salutation != undefined) {
        this.patient_name = this.userlist.salutation+"."+this.userlist.client_name;

      } else {
        this.patient_name = this.userlist.client_name;
      }
      this.persondataflag = true
    }

    if (this.userlist.spl != "") {
      this.speclization_arry = this.userlist.spl;
    }

    if (this.userlist.Age_data != "") {
      this.ageflag = true;
      this.patient_age = this.userlist.Age_data;
    }

    if (this.userlist.Gender_data != "") {
      this.genderflag = true;
      var gender = this.userlist.Gender_data;
      if (gender == "Male") {
        this.patient_gender = "M"
      } else if (gender == "Female") {
        this.patient_gender = "F"
      } else {
        this.patient_gender = "T"
      }
    }
    
    if (this.userlist.doctor_name != undefined) {
      this.doctor_name ="Dr"+" "+this.userlist.doctor_name+" "+Helper_Class.getQualification();

    } else {
      if(this.userinfo.middle_name != undefined) {
        this.doctor_name ="Dr"+" "+this.userinfo.first_name+" "+this.userinfo.middle_name+" "+this.userinfo.dr_last_name+" "+Helper_Class.getQualification()
      } else {
        this.doctor_name ="Dr"+" "+this.userinfo.first_name+" "+this.userinfo.last_name+" "+Helper_Class.getQualification()
      }
    }
    
    if (this.userlist.clientaddress2 != undefined) {
      if(Doc_Helper.getAppFlow() == "Inpatient"){
        if(this.userlist.clientaddress2 != "null"){
          this.addressdetails = (this.userlist.clientaddress1) + "," + (this.userlist.clientaddress2) + "," + this.userlist.clientlocation + "," + this.userlist.clientcity + "-" + encrypt_decript.Decript(this.userlist.clientzipcode) + "," + this.userlist.clientstate + "-" + this.userlist.clientcountry;

        }else{
          this.addressdetails = (this.userlist.clientaddress1) + "," + this.userlist.clientlocation + "," + this.userlist.clientcity + "-" + encrypt_decript.Decript(this.userlist.clientzipcode) + "," + this.userlist.clientstate + "-" + this.userlist.clientcountry;
        }

      }else{
        this.addressdetails = encrypt_decript.Decript(this.userlist.clientaddress1) + "," + encrypt_decript.Decript(this.userlist.clientaddress2) + "," + this.userlist.clientlocation + "," + this.userlist.clientcity + "-" + encrypt_decript.Decript(this.userlist.clientzipcode) + "," + this.userlist.clientstate + "-" + this.userlist.clientcountry;
      }

    } else {
      if(Doc_Helper.getAppFlow() != "Inpatient"){
        if(this.userlist.clientaddress1!= undefined ) {
          if(this.userlist.clientaddress1!='NA'){
            this.addressdetails = encrypt_decript.Decript(this.userlist.clientaddress1) + "," + this.userlist.clientlocation + "," + this.userlist.clientcity + "-" + encrypt_decript.Decript(this.userlist.clientzipcode) + "," + this.userlist.clientstate + "-" + this.userlist.clientcountry;
          } else {
            if(this.userlist.clientzipcode != undefined) {
              this.addressdetails = this.userlist.clientaddress1 + "," + this.userlist.clientlocation + "," + this.userlist.clientcity + "-" + encrypt_decript.Decript(this.userlist.clientzipcode) + "," + this.userlist.clientstate + "-" + this.userlist.clientcountry;
            } else {
              this.addressdetails = this.userlist.clientaddress1 + "," + this.userlist.clientlocation + "," + this.userlist.clientcity + "," + this.userlist.clientstate + "-" + this.userlist.clientcountry;
            }
          }
        }

      }else{
        if(this.userlist.clientaddress1!= undefined && this.userlist.clientaddress1!='NA') {
          this.addressdetails = encrypt_decript.Decript(this.userlist.clientaddress1)+ "," + this.userlist.clientlocation + "," + this.userlist.clientcity + "-" + this.userlist.clnt_zipcodevalue + "," + this.userlist.clientstate + "-" + this.userlist.clientcountry;
        } else {
          this.addressdetails = this.userlist.clientaddress1+ "," + this.userlist.clientlocation + "," + this.userlist.clientcity + "-" + this.userlist.clnt_zipcodevalue + "," + this.userlist.clientstate + "-" + this.userlist.clientcountry;
        }
      }
    }

    if (Doc_Helper.getAppFlow() != undefined) {
      if (Doc_Helper.getAppFlow() == "Inpatient") {
        if (Helper_Class.getInfo().middle_name != undefined) {
          this.doctor_name = "Dr" + " " + Helper_Class.getInfo().first_name + " " + Helper_Class.getInfo().middle_name + " " + Helper_Class.getInfo().last_name+" "+Helper_Class.getQualification();
        } else {
          this.doctor_name == "Dr" + " " + Helper_Class.getInfo().first_name + " " + Helper_Class.getInfo().last_name+" "+Helper_Class.getQualification();
        }
       
        this.inpatflag = true;
        this.wardno = this.userlist.wardlist;
        this.admission_time = this.userlist.admission_time;
        if (this.userlist.admission_date != undefined) {
          this.admission_date = Date_Formate(this.userlist.admission_date)
        } else {
          this.admission_date = "";
        }

        this.surgery_inpat = this.userlist.surgery_name;
        this.bedno = this.userlist.bed_no
        //  this.wardno 
      }
    }

    if (this.userlist.visitpurpose != "") {
      this.visitpurpose = this.userlist.visitpurpose;
    }

    if (this.userlist.inpatientnuber != undefined) {
      this.inpatientnuber = this.userlist.inpatientnuber;

    } else {
      if (this.userlist.app_id != undefined) {
        this.inpatientnuber = this.userlist.app_id;

      }
    }

    //doctor_name
    if (this.userlist.pat_id != "" && this.userlist.pat_id != undefined) {
      this.mrnno = this.userlist.pat_id;
      this.mrnflag = true;
    }
    if (this.userlist.profile_image != undefined) {
      this.profileImg = this.userlist.profile_image;
    }
  
    this.patient_image = this.userlist.image;

    this.cards = [];
    this.casesheet_flag = Doc_Helper.getCasesheet_flag();
    this.userinfo=Helper_Class.getInfo();
         
    if(Helper_Class.getInfo().both_modules != undefined){
      if (Helper_Class.getInfo().both_modules.length != 0) {
        var Moduleidlist;
        Moduleidlist = Helper_Class.getInfo().both_modules;
        for (var i = 0; i < Moduleidlist.length; i++) {       
          if (Moduleidlist[i].module_id == "6") {
            this.medicine_Card="Medical Prescription"
            //this.medicalprescriptionflag = true;
          }  
          if ( Moduleidlist[i].module_id == "119") {
            this.diagnosis_Card="Diagnosis Prescription"
           // this.reportflag = true;
          }       
        }
      }else{
        this.medicine_Card="Medical Prescription";
        this.diagnosis_Card="Diagnosis Prescription"
      }
    }else{
      this.medicine_Card="Medical Prescription";
      this.diagnosis_Card="Diagnosis Prescription"
    }
    console.log("ONE CARD PRESENT ________"+JSON.stringify(Doc_Helper.getCasesheet_flag()))
    console.log("ONE CARD PRESENT ________"+JSON.stringify(this.medicine_Card)+this.diagnosis_Card)
    if (Doc_Helper.getCasesheet_flag() == "1") {
      if (this.speclization_arry == "Gynecology") {//spell wrong in db 
        this.Gynecology_flag = true;
        this.oflag = true;
        if (this.d_flag == true) {
          this.cards = ["Personal", "Vitals", "Surgical", "Obstetrics", "Family Details", "Clinical Exam", "Reports", "Treatment Plan", "Diet Tracking", "Diet Plan", "Discharge / Case Summary"];

        } else {
          this.cards = ["Personal", "Vitals", "Surgical", "Obstetrics", "Family Details", "Clinical Exam", "Reports", "Treatment Plan", "Diet Tracking", "Diet Plan"];
        }

      } else if (this.speclization_arry == "Pediatrics") {
        this.Pediatrics_flag = true;
        this.oflag = true;
        if (this.d_flag == true) {
          this.cards = ["Personal", "Pregnancy & Delivery", "Family Details", "Vitals", "Head & Cardiovascular", "Development", "Reports", "Treatment Plan", "Immunization", "Discharge / Case Summary"];

        } else {
          this.cards = ["Personal", "Pregnancy & Delivery", "Family Details", "Vitals", "Head & Cardiovascular", "Development", "Reports", "Treatment Plan", "Immunization"];
        }

      } else if (this.speclization_arry == "Cardiology") {         
           
        this.cardio_flag = true;
        this.oflag = true;
        if (this.d_flag == true) {
          this.cards = ["Personal", "Vitals", "Habitual History", "Family Details", "Clinical Exam", "Reports", "Treatment Plan", "Diet Tracking", "Diet Plan", "Discharge / Case Summary"];

        } else {
          this.cards = ["Personal","Vitals", "Habitual History", "Family Details", "Clinical Exam", "Reports", "Treatment Plan", "Diet Tracking", "Diet Plan"];
        }
        if (this.medicine_Card) {
          this.cards.splice(1, 0, this.medicine_Card);
      }
      
      // Check if key2 is present
      if (this.diagnosis_Card) {
        this.cards.splice(2, 0, this.diagnosis_Card);
      }

      } else if (this.speclization_arry == "Gastroenterology") {
        this.gastro_flag = true;
        this.oflag = true;
        if (this.d_flag == true) {
          this.cards = ["Personal", "Vitals", "Habitual History", "Family Details", "Clinical Exam", "Reports", "Treatment Plan", "Diet Tracking", "Diet Plan", "Discharge / Case Summary"];

        } else {
          this.cards = ["Personal", "Vitals", "Habitual History", "Family Details", "Clinical Exam", "Reports", "Treatment Plan", "Diet Tracking", "Diet Plan"];
        }
        if (this.medicine_Card) {
          this.cards.splice(1, 0, this.medicine_Card);
       }
      
      // Check if key2 is present
      if (this.diagnosis_Card) {
        this.cards.splice(2, 0, this.diagnosis_Card);
      }

      } else if (this.speclization_arry == "Nephrology") {
        this.Nephrology_flag = true;
        this.oflag = true;
        if (this.d_flag == true) {
          this.cards = ["Physical", "Habitual History", "Vitals", "Readings", "Family Details", "Reports", "Treatment Plan", "Diet Tracking", "Diet Plan", "Discharge / Case Summary"];

        } else {
          this.cards = ["Physical", "Habitual History", "Vitals", "Readings", "Family Details", "Reports", "Treatment Plan", "Diet Tracking", "Diet Plan"];
        }
        if (this.medicine_Card) {
          this.cards.splice(1, 0, this.medicine_Card);
       }
      
      // Check if key2 is present
      if (this.diagnosis_Card) {
        this.cards.splice(2, 0, this.diagnosis_Card);
      }

      } else if (this.speclization_arry == "Dental") {
        this.Dental_flag = true;
        this.oflag = true;
        if (this.d_flag == true) {
          this.cards = ["Personal", "Vitals", "Habitual History", "Examination", "Speciality", "Treatment Plan", "Reports", "Material Order", "Discharge / Case Summary"];

        } else {
          this.cards = ["Personal", "Vitals", "Habitual History", "Examination", "Speciality", "Treatment Plan", "Reports", "Material Order"];
        }
        if (this.medicine_Card) {
          this.cards.splice(1, 0, this.medicine_Card);
       }
      
      // Check if key2 is present
      if (this.diagnosis_Card) {
        this.cards.splice(2, 0, this.diagnosis_Card);
      }

      } else if (this.speclization_arry == "Diabetes") {
        this.Diabetes_flag = true;
        this.oflag = true;
        if (this.d_flag == true) {
          this.cards = ["Personal", "Vitals", "Habitual History", "Family Details", "Update Readings", "Readings", "Foot Assessment", "Complications", "Reports", "Treatment Plan", "Diet Tracking", "Diet Plan", "Discharge / Case Summary"];

        } else {
          this.cards = ["Personal", "Vitals", "Habitual History", "Family Details", "Update Readings", "Readings", "Foot Assessment", "Complications", "Reports", "Treatment Plan", "Diet Tracking", "Diet Plan"];
        }
        if (this.medicine_Card) {
          this.cards.splice(1, 0, this.medicine_Card);
       }
      
      // Check if key2 is present
      if (this.diagnosis_Card) {
        this.cards.splice(2, 0, this.diagnosis_Card);
      }

      } else if (this.speclization_arry == "General") {
        this.General_flag = true;
        this.oflag = true;
        if (this.d_flag == true) {
          this.cards = ["Personal", "Vitals", "Habitual History", "Family Details", "Treatment Plan", "Reports", "Diet Tracking", "Diet Plan", "Discharge / Case Summary"];

        } else {
          this.cards = ["Personal", "Vitals", "Habitual History", "Family Details", "Treatment Plan", "Reports", "Diet Tracking", "Diet Plan"];
        }
        if (this.medicine_Card) {
          this.cards.splice(1, 0, this.medicine_Card);
       }
      
      // Check if key2 is present
      if (this.diagnosis_Card) {
        this.cards.splice(2, 0, this.diagnosis_Card);
      }

      } else if (this.speclization_arry == "General surgery") {
        this.General_flag = true;
        this.oflag = true;
        if (this.d_flag == true) {
          this.cards = ["Personal", "Vitals", "Habitual History", "Family Details", "Treatment Plan", "Reports", "Diet Tracking", "Diet Plan", "Surgery", "Discharge / Case Summary"];

        } else {
          this.cards = ["Personal", "Vitals", "Habitual History", "Family Details", "Treatment Plan", "Reports", "Diet Tracking", "Diet Plan", "Surgery"];
        }
        if (this.medicine_Card) {
          this.cards.splice(1, 0, this.medicine_Card);
       }
      
      // Check if key2 is present
      if (this.diagnosis_Card) {
        this.cards.splice(2, 0, this.diagnosis_Card);
      }

      } else if (this.speclization_arry == "ENT") {
        this.ENT_flag = true;
        this.oflag = true;
        if (this.d_flag == true) {
          this.cards = ["Personal", "Vitals", "Habitual History", "Family Details", "Clinical Exam", "Reports", "Treatment Plan", "Diet Tracking", "Diet Plan", "Discharge / Case Summary"];

        } else {
          this.cards = ["Personal", "Vitals", "Habitual History", "Family Details", "Clinical Exam", "Reports", "Treatment Plan", "Diet Tracking", "Diet Plan"];
        }
         if (this.medicine_Card) {
          this.cards.splice(1, 0, this.medicine_Card);
       }
      
      // Check if key2 is present
      if (this.diagnosis_Card) {
        this.cards.splice(2, 0, this.diagnosis_Card);
      }

      } else {
        if (this.oflag == false) {
          this.Others_flag = true;
          if (this.speclization_arry == 'Orthopedic') {
            if (this.d_flag == true) {
              this.cards = ["Personal", "Vitals", "Habitual History", "Family Details", "Clinical Exam", "Reports", "Treatment Plan", "Diet Tracking", "Diet Plan", "Surgery", "Discharge / Case Summary"];
            } else {
              this.cards = ["Personal", "Vitals", "Habitual History", "Family Details", "Clinical Exam", "Reports", "Treatment Plan", "Diet Tracking", "Diet Plan", "Surgery"];
            }
            if (this.medicine_Card) {
              this.cards.splice(1, 0, this.medicine_Card);
           }
          
          // Check if key2 is present
          if (this.diagnosis_Card) {
            this.cards.splice(2, 0, this.diagnosis_Card);
          }

          } else {
            if (this.d_flag == true) {
              this.cards = ["Personal", "Vitals", "Habitual History", "Family Details", "Clinical Exam", "Reports", "Treatment Plan", "Diet Tracking", "Diet Plan", "Discharge / Case Summary"];

            } else {
              this.cards = ["Personal", "Vitals", "Habitual History", "Family Details", "Clinical Exam", "Reports", "Treatment Plan", "Diet Tracking", "Diet Plan"];
            }
            if (this.medicine_Card) {
              this.cards.splice(1, 0, this.medicine_Card);
           }
          
          // Check if key2 is present
          if (this.diagnosis_Card) {
            this.cards.splice(2, 0, this.diagnosis_Card);
          }
          }
        }
      }

    } else {
      this.module_flag = true;
      if (this.speclization_arry == "Gynecology") {//spell wrong in db 
        this.Gynecology_flag = true;
        this.oflag = true;
        this.cards = ["Personal", "Vitals"];
        if (this.medicine_Card) {
          this.cards.splice(1, 0, this.medicine_Card);
       }
      
      // Check if key2 is present
      if (this.diagnosis_Card) {
        this.cards.splice(2, 0, this.diagnosis_Card);
      }

      } else if (this.speclization_arry == "Pediatrics") {
        this.Pediatrics_flag = true;
        this.oflag = true;
        this.cards = ["Personal", "Vitals"];
        if (this.medicine_Card) {
          this.cards.splice(1, 0, this.medicine_Card);
       }
      
      // Check if key2 is present
      if (this.diagnosis_Card) {
        this.cards.splice(2, 0, this.diagnosis_Card);
      }

      } else if (this.speclization_arry == "Cardiology") {
        this.cardio_flag = true;
        this.oflag = true;
        this.cards = ["Personal", "Vitals"];
        if (this.medicine_Card) {
          this.cards.splice(1, 0, this.medicine_Card);
       }
      
      // Check if key2 is present
      if (this.diagnosis_Card) {
        this.cards.splice(2, 0, this.diagnosis_Card);
      }

      } else if (this.speclization_arry == "Gastroenterology") {
        this.gastro_flag = true;
        this.oflag = true;
        this.cards = ["Personal", "Vitals"];
        if (this.medicine_Card) {
          this.cards.splice(1, 0, this.medicine_Card);
       }
      
      // Check if key2 is present
      if (this.diagnosis_Card) {
        this.cards.splice(2, 0, this.diagnosis_Card);
      }

      } else if (this.speclization_arry == "Nephrology") {
        this.Nephrology_flag = true;
        this.oflag = true;
        this.cards = ["Physical", "Vitals"];
        if (this.medicine_Card) {
          this.cards.splice(1, 0, this.medicine_Card);
       }
      
      // Check if key2 is present
      if (this.diagnosis_Card) {
        this.cards.splice(2, 0, this.diagnosis_Card);
      }

      } else if (this.speclization_arry == "Dental") {
        this.Dental_flag = true;
        this.oflag = true;
        this.cards = ["Personal", "Vitals"];
        if (this.medicine_Card) {
          this.cards.splice(1, 0, this.medicine_Card);
       }
      
      // Check if key2 is present
      if (this.diagnosis_Card) {
        this.cards.splice(2, 0, this.diagnosis_Card);
      }

      } else if (this.speclization_arry == "Diabetes") {
        this.Diabetes_flag = true;
        this.oflag = true;
        this.cards = ["Personal", "Vitals"];
        if (this.medicine_Card) {
          this.cards.splice(1, 0, this.medicine_Card);
       }
      
      // Check if key2 is present
      if (this.diagnosis_Card) {
        this.cards.splice(2, 0, this.diagnosis_Card);
      }

      } else if (this.speclization_arry == "General") {
        this.General_flag = true;
        this.oflag = true;
        this.cards = ["Personal", "Vitals"];
        if (this.medicine_Card) {
          this.cards.splice(1, 0, this.medicine_Card);
       }
      
      // Check if key2 is present
      if (this.diagnosis_Card) {
        this.cards.splice(2, 0, this.diagnosis_Card);
      }

      } else if (this.speclization_arry == "General surgery") {
        this.General_flag = true;
        this.oflag = true;
        this.cards = ["Personal", "Vitals"];
        if (this.medicine_Card) {
          this.cards.splice(1, 0, this.medicine_Card);
       }
      
      // Check if key2 is present
      if (this.diagnosis_Card) {
        this.cards.splice(2, 0, this.diagnosis_Card);
      }

      } else if (this.speclization_arry == "ENT") {
        this.ENT_flag = true;
        this.oflag = true;
        this.cards = ["Personal", "Vitals"];
        if (this.medicine_Card) {
          this.cards.splice(1, 0, this.medicine_Card);
       }
      
      // Check if key2 is present
      if (this.diagnosis_Card) {
        this.cards.splice(2, 0, this.diagnosis_Card);
      }

      } else {
        if (this.oflag == false) {
          this.Others_flag = true;
          this.cards = ["Personal", "Vitals", "Habitual History"];
          if (this.medicine_Card) {
            this.cards.splice(1, 0, this.medicine_Card);
         }
        
        // Check if key2 is present
        if (this.diagnosis_Card) {
          this.cards.splice(2, 0, this.diagnosis_Card);
        }
        }
      }
    }

    if (Doc_Helper.getAppFlow() == "client") {
      this.cards.forEach((element, index) => {
        if (element == "Appointments") this.cards.splice(index, 1);
      });
    }

    //general 
    this.subscription = this.messageservice.getDiagComponent().subscribe(message => {
      this.messageservice.sendMedicalName("true");
      if (message == "diag") {
        Doc_Helper.setDiagAppflow("diag");
        this.viewRecord("Medical Prescription", "", "change_heading")
      } else {
        Doc_Helper.setDiagAppflow("medical");
        this.viewRecord("Medical Prescription", "", "change_heading")
      }

      if (message == "save_diag") {
        this.viewRecord("Diagnosis Prescription", "", "change_heading");
        this.openMedical = false;
      }
      if (message == "save_med") {
        this.viewRecord("Medical Prescription", "", "change_heading");
        this.openMedical = false;
      }
    });
  }

  slideConfig = {
    infinite: false,
    speed: 300,
    slidesToShow: 6,
    slidesToScroll: 6,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  getRecord(id: any) {
    this.selectedUser = [];
    const res = this.userlist.filter((d: { id: string; }) => d.id === id);
    this.selectedUser = res[0];
  }

  opencard(id, card, index, card_id, flag) {
    Helper_Class.setcasesheetcardname(card)
    console.log("min max "+this.min_max_flag)
    this.min_max_flag = flag;
    this.messageservice.sendMedicalName("false");
    Doc_Helper.setOpenCard(null);
    Doc_Helper.setOpenCard(card);
    if (this.min_max_flag == true) {
      this.messageservice.sendCasesheetMessage("max");
      console.log("ok 1"+this.min_max_flag)
      this.cardview_flag = true;
      if (card == 'Medical Prescription') {
        Doc_Helper.setDiagAppflow("medical");
      }

      if (card == 'Medical Prescription' || card == 'Diagnosis Prescription' || card == 'Update Readings' || card == 'Diagnosis Vitals') {
        this.medisaveFlag = true;

      } else {
        this.medisaveFlag = false;
      }
      Helper_Class.setnewcardname(card)
      // alert(">>> --------"+JSON.stringify(Helper_Class.getnewcardname()))

      for (var i = 0; i < this.cards.length; i++) {
        document.getElementById("cardindex_" + i).className = "col-md-12 m-auto user-view";
        if (card == this.cards[i]) {
          document.getElementById("card_" + i).style.width = "100%";
          document.getElementById("card_" + i).style.height = "auto";
        }
        else {
          document.getElementById("card_" + i).style.display = "none";
        }
      }
    }

    if (this.min_max_flag == false) {
      Doc_Helper.setMedicalHeading("false");
      this.messageservice.sendCasesheetMessage("min");
      console.log("ok"+this.min_max_flag)
      this.cardview_flag = false;
      this.medisaveFlag = false;
      for (var i = 0; i < this.cards.length; i++) {
        document.getElementById("cardindex_" + i).className = "col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4";
        if (card == this.cards[i]) {
          document.getElementById("card_" + i).style.width = "100%";
          document.getElementById("card_" + i).style.height = "290px";

        } else {
          document.getElementById("card_" + i).style.display = "flex";
          document.getElementById("card_" + i).style.width = "100%";
          document.getElementById("card_" + i).style.height = "290px";
        }
      }
    }
  }

  viewRecord(card, index: any, card_id) {
    if (index != "") {
      if (card == 'Medical Prescription') {
        Doc_Helper.setDiagAppflow("medical");
      }
    }
    if (card_id != "change_heading") {
      this.messageservice.sendMedicalName("false");
    } else {
      this.messageservice.sendMedicalName("true");
    }

    this.messageservice.sendCasesheetMessage("max");
    this.cardview_flag = true;
    if (card == 'Medical Prescription' || card == 'Diagnosis Prescription' || card == 'Update Readings' || card == 'Diagnosis Vitals') {
      this.medisaveFlag = true;

    } else {
      this.medisaveFlag = false;
    }
    if (card == 'Surgery') {
      this.messageservice.sendCasesheetMessage("min");
      this.messageservice.sendCasesheetMessage("max");
    }
    for (var i = 0; i < this.cards.length; i++) {
      document.getElementById("cardindex_" + i).className = "col-md-12 m-auto user-view";

      if (card == this.cards[i]) {
        document.getElementById("card_" + i).style.width = "100%";
        document.getElementById("card_" + i).style.height = "auto";
        document.getElementById("card_" + i).style.display = "flex";
      }
      else {
        document.getElementById("card_" + i).style.display = "none";
      }
    }
  }

  viewRecord_user(id: any, card, index: any, card_id) {
    this.fadeInOut = true;
    this.isShow = true;
    // this.router.navigate([`user/${id}`]);
    this.activeIndex = index;
    this.selectedUser = [];
    // const res = this.userlist.filter((d: { id: string; }) => d.id === id);
    this.selectedUser = card;
    setTimeout(() => {
      this.isShow = false;
      this.fadeInOut = false;
    }, 500)
  }

  //save data
  save_cardio() {
    var vitals;
    var vitals_flag: boolean = true;
    this.send_arr = null;
    var url = null; this.personal_flag = true;
    this.clinical_flag = true;
    this.diagnosis_flag = true;
    this.cardio_personal = Helper_Class.getCasesheetPersonal();
    console.log("personal ---"+JSON.stringify(this.cardio_personal))
    this.cardio_habitual = Helper_Class.gethabitual();
    this.cardio_family = {
      family_history: Helper_Class.getDiabFam(),
    };

    this.cardio_clinical = Helper_Class.getclinicalexam();
    this.cardio_diagnosis = Helper_Class.getDiabTreat();
    this.medicalprescription = Helper_Class.get_med_pres_id();
    this.diagnosisprescription = Helper_Class.get_diag_pres_id();
    var get_sga_data = Helper_Class.getSga();
    var get_diet_data = Helper_Class.getDietplan();
    console.log("personal --******-"+JSON.stringify(Helper_Class.getclinicalexam()))
    console.log("personal --******-"+JSON.stringify(Helper_Class.getDiabFam()))
    console.log("personal --******-"+JSON.stringify(Helper_Class.getDietplan()))
    console.log("personal --******-"+JSON.stringify(Helper_Class.getDiabTreat()))

    vitals = Doc_Helper.getVitals();

    var takingmed = undefined;
    var sfactor = undefined;
    var hdisorder = undefined;
    var hospvisit = undefined;
    var murdet = undefined;
    var painfactor = undefined;
    var breathfactor = undefined;
    for (var key in this.cardio_personal) {
      if (key == "medication" && this.cardio_personal[key] != undefined) {
        takingmed = this.cardio_personal[key];
      }
      if (key == "case_hist_id" && this.cardio_personal[key] != undefined) {
        url = "1";
      }
      if (key == "current_occupation" && (this.cardio_personal[key] == undefined || this.cardio_personal[key] == "" || this.cardio_personal[key] == null)) {
        this.personal_flag = false;
      }

      if (takingmed != undefined && takingmed == "Yes") {
        if (key == "medicines" && (this.cardio_personal[key] == undefined || this.cardio_personal[key] == "" || this.cardio_personal[key] == null)) {
          this.personal_flag = false;
        }
      }
    }

    for (var key in vitals) {
      if (key == "height" && (vitals[key] == undefined || vitals[key] == "")) {
        vitals_flag = false;

      }
      if (key == "weight" && (vitals[key] == undefined || vitals[key] == "")) {
        vitals_flag = false;
      }
    }

    if (this.cardio_clinical != undefined) {
      for (var key in this.cardio_clinical) {
        if (key == "stressed") {
          sfactor = this.cardio_clinical[key];
        }

        if (key == "chest_pain") {
          painfactor = this.cardio_clinical[key];
        }

        if (key == "prev_heart_dis") {
          hdisorder = this.cardio_clinical[key];
        }

        if (key == "prev_hosp_visit") {
          hospvisit = this.cardio_clinical[key];
        }

        if (key == "murmurs") {
          murdet = this.cardio_clinical[key];
        }

        if (key == "breathlessness") {
          breathfactor = this.cardio_clinical[key];
        }

        if (key == "stressed" && this.cardio_clinical[key] == undefined) {
          this.clinical_flag = false;

        } else if (sfactor == "Yes") {
          if (key == "stress_factor" && (this.cardio_clinical[key] == undefined || this.cardio_clinical[key] == "")) {
            this.clinical_flag = false;
          } else if (key == "symptoms" && (this.cardio_clinical[key] == undefined || this.cardio_clinical[key] == "")) {
            this.clinical_flag = false;
          }

        } else if (key == "prev_heart_dis" && this.cardio_clinical[key] == undefined) {
          this.clinical_flag = false;

        } else if (hdisorder == "Yes") {
          if (key == "treatment" && this.cardio_clinical[key] == undefined) {
            this.clinical_flag = false;
          }

        } else if (key == "prev_hosp_visit" && this.cardio_clinical[key] == undefined) {
          this.clinical_flag = false;

        } else if (hospvisit == "Yes") {
          if (key == "hosp_visit_det" && this.cardio_clinical[key] == undefined) {
            this.clinical_flag = false;
          }

        } else if (key == "murmurs" && this.cardio_clinical[key] == undefined) {
          this.clinical_flag = false;

        } else if (murdet == "Yes") {
          if (key == "murmur_grade" && this.cardio_clinical[key] == undefined) {
            this.clinical_flag = false;
          }

        } else if (painfactor == "Yes") {
          if (key == "pain_location" && this.cardio_clinical[key] == undefined) {
            this.clinical_flag = false;
          } else if (key == "pain_character" && this.cardio_clinical[key] == undefined) {
            this.clinical_flag = false;
          } else if (key == "pain_radiation" && this.cardio_clinical[key] == undefined) {
            this.clinical_flag = false;
          } else if (key == "pain_duration" && this.cardio_clinical[key] == undefined) {
            this.clinical_flag = false;
          } else if (key == "pain_aggrev_factor" && this.cardio_clinical[key] == undefined) {
            this.clinical_flag = false;
          } else if (key == "pain_relieve_factor" && this.cardio_clinical[key] == undefined) {
            this.clinical_flag = false;
          } else if (key == "pain_asso_symptom" && this.cardio_clinical[key] == undefined) {
            this.clinical_flag = false;
          } else if (key == "pain_intensity" && this.cardio_clinical[key] == undefined) {
            this.clinical_flag = false;
          } else if (key == "pain_cause" && this.cardio_clinical[key] == undefined) {
            this.clinical_flag = false;
          }
        } else if (breathfactor == "Yes") {
          if (key == "breath_triggers" && this.cardio_clinical[key] == undefined) {
            this.clinical_flag = false;
          } else if (key == "breath_onset" && this.cardio_clinical[key] == undefined) {
            this.clinical_flag = false;
          } else if (key == "breath_cold_sweat" && this.cardio_clinical[key] == undefined) {
            this.clinical_flag = false;
          } else if (key == "breath_dizziness" && this.cardio_clinical[key] == undefined) {
            this.clinical_flag = false;
          } else if (key == "breath_vomiting" && this.cardio_clinical[key] == undefined) {
            this.clinical_flag = false;
          } else if (key == "breath_palpitation" && this.cardio_clinical[key] == undefined) {
            this.clinical_flag = false;
          } else if (key == "breath_palp_type" && this.cardio_clinical[key] == undefined) {
            this.clinical_flag = false;
          } else if (key == "breath_cough" && this.cardio_clinical[key] == undefined) {
            this.clinical_flag = false;
          } else if (key == "breath_mucus" && this.cardio_clinical[key] == undefined) {
            this.clinical_flag = false;
          }
        } else if (key == "phy_eye" && this.cardio_clinical[key] == undefined) {
          this.clinical_flag = false;
        } else if (key == "phy_nails" && this.cardio_clinical[key] == undefined) {
          this.clinical_flag = false;
        } else if (key == "phy_lip_tongue" && this.cardio_clinical[key] == undefined) {
          this.clinical_flag = false;
        } else if (key == "phy_neck" && this.cardio_clinical[key] == undefined) {
          this.clinical_flag = false;
        } else if (key == "phy_face" && this.cardio_clinical[key] == undefined) {
          this.clinical_flag = false;
        } else if (key == "phy_precordium" && this.cardio_clinical[key] == undefined) {
          this.clinical_flag = false;
        } else if (key == "phy_hand_arm" && this.cardio_clinical[key] == undefined) {
          this.clinical_flag = false;
        } else if (key == "phy_abdomen" && this.cardio_clinical[key] == undefined) {
          this.clinical_flag = false;
        } else if (key == "phy_lower_limb" && this.cardio_clinical[key] == undefined) {
          this.clinical_flag = false;
        } else if (key == "phy_capillary_refill" && this.cardio_clinical[key] == undefined) {
          this.clinical_flag = false;
        }
      }
    }

    if (this.cardio_diagnosis != undefined) {
      if (this.cardio_diagnosis["disease_type"] == undefined) {
        this.diagnosis_flag = false;
      } else if (this.cardio_diagnosis["disease_type"] == "select") {
        this.diagnosis_flag = false;
      }
      //  else if (this.cardio_diagnosis["recom_exec_id"] == undefined) {
      //   this.diagnosis_flag = false;
      // } else if (this.cardio_diagnosis["recom_exec_dur"] == undefined) {
      //   this.diagnosis_flag = false;
      // } else if (this.cardio_diagnosis["recom_exec_freq"] == undefined) {
      //   this.diagnosis_flag = false;
      // } 
      
      else if (this.cardio_diagnosis["prognosis"] == undefined) {
        this.diagnosis_flag = false;
      }
    }

    if (this.personal_flag == false) {
      this.toastr.error(Message_data.personalCardMandatory);
    }
    else if (this.clinical_flag == false) {
      this.toastr.error(Message_data.clinicalCardMandatory);
    }
    else if (this.diagnosis_flag == false) {
      this.toastr.error(Message_data.treatmentCardMandatory);
    } else if (vitals_flag == false) {
      this.toastr.error(Message_data.vitalMandatory);
    }

    var person_family = $.extend(true, this.cardio_personal, this.cardio_family, this.cardio_habitual, vitals);
    var per_fam_clinical = $.extend(true, person_family, this.cardio_clinical, this.medicalprescription, this.diagnosisprescription);

    var final_array = $.extend(true, per_fam_clinical, this.cardio_diagnosis);
    final_array.country = "IN";

    if (url != null) {
      url = ipaddress.getIp.toString() + 'cardio/ucasehist';
    } else {
      url = ipaddress.getIp.toString() + 'cardio/scasehist';
    }
    var diet_plan_url = "lifestyle/sdplan";

    for (var key in get_diet_data) {
      if (key == "dplan_morning") {
        this.diet_mrng_arry = get_diet_data[key];
      }

      if (key == "dplan_breakfast") {
        this.break_fast_arrydata = get_diet_data[key]
      }

      if (key == "dplan_midday") {
        this.mid_day_arrydata = get_diet_data[key];
      }

      if (key == "dplan_lunch") {
        this.lunch_arrydata = get_diet_data[key];
      }

      if (key == "dplan_evening") {
        this.evening_arrydata = get_diet_data[key];
      }

      if (key == "dplan_dinner") {
        this.dinner_arrydata = get_diet_data[key];
      }

      if (key == "dplan_night") {
        this.night_arrydata = get_diet_data[key];
      }

      if (key == "diet_plan" && get_diet_data[key] == "1") {
        diet_plan_url = "lifestyle/updplan";
        console.log
      }
    }

    if (this.personal_flag == true && this.clinical_flag == true && this.diagnosis_flag == true) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(url, JSON.stringify(final_array),
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();

            if (obj["key"] != null && obj["key"] == "1") {
              Helper_Class.setpatient_information(null);
              Helper_Class.setDiabFam(null);
              Helper_Class.setclinicalexam(null);
              Helper_Class.setDiabTreat(null);
              var dietplan = null;
              if (get_sga_data != undefined) {
                dietplan = $.extend(true, get_sga_data, get_diet_data);

              } else {
                dietplan = get_diet_data;
              }
              var headers = new Headers();
              headers.append('Content-Type', 'application/json');
              this.http.post(ipaddress.getIp.toString() + diet_plan_url, dietplan,
                { headers: headers })
                .subscribe(
                  response => {
                    var diet_obj = response.json();
                    this.save_flag = false;
                    if (this.cardio_personal.case_hist_id != null && this.cardio_personal.case_hist_id != undefined) {
                      this.toastr.success(Message_data.caseHistUpdSuccess);
                    } else {
                      this.toastr.success(Message_data.caseHistSavedSuccess);
                    }
                    if (Helper_Class.getInpatientFlow() == "nurse") {
                      this.nurseservice.sendMessage("viewIpatient");
                    } else if (Doc_Helper.getAppFlow() == "Inpatient") {
                      this.doctorviewservice.sendMessage("inPatientViewInfo");
                    } else {
                      this.doctorviewservice.sendMessage("appointment_list");
                    }
                  },
                  error => {
                    this.toastr.error(Message_data.defaultErr);
                  }
                )
            } else {
              //  this.loader = true;
              this.toastr.error(Message_data.caseHistNotSaved);
            }
          });
    }
  }

  save_pedia() {
    this.send_arr = null;
    var chk_flag = true, url = null
    this.pedia_person_arry = null;
    this.Pedia_pregnancy_arry = null;
    this.pedia_fam_arr = null;
    this.pedia_head_array = null;
    this.pedia_development_array = null;
    this.pedia_treat_arr = null;
    this.savebtn = true;
    if(Helper_Class.getDiabFam() != null){
      this.pedia_fam_arr = {
        family_history: Helper_Class.getDiabFam(),
      }

    }else{
      // this.cardio_family = {
      //   family_history: Helper_Class.getDiabFam(),
      // }
    }
    this.pedia_person_arry = Helper_Class.getPediaHist();
    this.Pedia_pregnancy_arry = Helper_Class.getPediadelivery();
    console.log("Helper_Class.getPediaHist(),lkj"+JSON.stringify(Helper_Class.getPediaHist()))
    console.log("url,lkj"+JSON.stringify(this.Pedia_pregnancy_arry))
    // this.pedia_fam_arr = {
    //   family_history: Helper_Class.getDiabFam(),
    // };

    console.log("url,pedia_fam_arr"+JSON.stringify(this.pedia_fam_arr))
    this.pedia_head_array = Helper_Class.getPediaheadcardio();
    this.pedia_development_array = Helper_Class.getpediadevelop();
    this.pedia_treat_arr = Helper_Class.getDiabTreat();
    this.Pedia_immunization_array = Helper_Class.getpediaimmunization();
    this.medicalprescription = Helper_Class.get_med_pres_id();
    this.diagnosisprescription = Helper_Class.get_diag_pres_id();
    console.log("url,pedia_head_array"+JSON.stringify(this.pedia_head_array))
    console.log("url,pedia_development_array"+JSON.stringify(this.pedia_development_array))
    console.log("url,pedia_treat_arr"+JSON.stringify(this.pedia_treat_arr))
    console.log("url,Pedia_immunization_array"+JSON.stringify(this.Pedia_immunization_array))
    console.log("url,pedia_head_array"+JSON.stringify(this.pedia_head_array))

    //read key
    for (var key in this.pedia_person_arry) {
      if (key == "case_hist_id" && this.pedia_person_arry[key] != undefined) {
        url = "1";
      }
      if (key == "concerns" && this.pedia_person_arry[key] == "") {
        chk_flag = false;
        this.toastr.error(Message_data.concernMandatory);
        this.savebtn = false;
      }

      if (key == "age" && this.pedia_person_arry[key] == "") {
        chk_flag = false;
        this.toastr.error(Message_data.personalCardMandatory);
        this.savebtn = false;
      }
    }
    // const keys = Object.keys(this.pedia_person_arry);
    if (chk_flag == true) {
      if (this.Pedia_pregnancy_arry != undefined) {
        for (var key in this.Pedia_pregnancy_arry) {

          if (key == "mother_age" && this.Pedia_pregnancy_arry[key] == undefined) {
            chk_flag = false;
            this.toastr.error(Message_data.motherAgeMandatory);
            this.savebtn = false;
          } 

          if (key == "gestational_age" && this.Pedia_pregnancy_arry[key] == undefined) {
            chk_flag = false;
            this.toastr.error(Message_data.gestAgeMandatory);
            this.savebtn = false;
          }

          if (key == "delivery_type" && this.Pedia_pregnancy_arry[key] == undefined) {
            chk_flag = false;
            this.toastr.error(Message_data.delivTypeMandatory);
            this.savebtn = false;
          }

          if (key == "no_of_pregnancy" && this.Pedia_pregnancy_arry[key] == undefined) {
            chk_flag = false;
            this.toastr.error(Message_data.noOfPregMandatory);
            this.savebtn = false;
          }

          if (key == "feeding_habit" && this.Pedia_pregnancy_arry[key] == undefined) {
            chk_flag = false;
            this.toastr.error(Message_data.feedHabitMandatory);
            this.savebtn = false;
          }
        }
      }
    }

    if (chk_flag == true) {
      if (this.pedia_treat_arr != undefined) {
        for (var key in this.pedia_treat_arr) {

          if (key == "disease" && this.pedia_treat_arr[key] == undefined) {
            chk_flag = false;
            this.toastr.error(Message_data.diseaseMandatory);
            this.savebtn = false;
          }
        }
      }
    }

    if (chk_flag == true) {
      if (this.pedia_treat_arr["prognosis"] == undefined) {
        chk_flag = false;
        this.toastr.error(Message_data.addProg);
        this.savebtn = false;
      }
    }

    if (chk_flag == true) {
      if (url != null) {
        url = ipaddress.getIp.toString() + 'pedia/ucasehist';

      } else {
        url = ipaddress.getIp.toString() + 'pedia/scasehist';
      }
      console.log("url,pedia_head_array"+JSON.stringify(url))

      console.log("final url --"+JSON.stringify(url))
      var finalObj = $.extend(true, this.pedia_person_arry, this.Pedia_pregnancy_arry);
      if(this.pedia_fam_arr != undefined){
        var finalObj1 = $.extend(true, finalObj, this.pedia_fam_arr, this.medicalprescription, this.diagnosisprescription);
      }else{
       
        var finalObj1 = $.extend(true, finalObj, this.medicalprescription, this.diagnosisprescription);
      }
      var finalObj2 = $.extend(true, finalObj1, this.pedia_head_array);
      var finalObj3 = $.extend(true, finalObj2, this.pedia_development_array);
      var finalObj4 = $.extend(true, finalObj3, this.pedia_treat_arr);

      var output = null;
      output = finalObj4;
      output = this.jsonConcat(finalObj4, this.Pedia_immunization_array);

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(url, JSON.stringify(output),
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();


            if (obj["key"] != null && obj["key"] == "1") {
              this.save_flag = false;
              if (this.pedia_person_arry.case_hist_id != null && this.pedia_person_arry.case_hist_id != undefined) {
                this.toastr.success(Message_data.caseHistUpdSuccess);
              } else {
                this.toastr.success(Message_data.caseHistSavedSuccess);
              }
              if (Helper_Class.getInpatientFlow() == "nurse") {
                this.nurseservice.sendMessage("viewIpatient");
              } else if (Doc_Helper.getAppFlow() == "Inpatient") {
                this.doctorviewservice.sendMessage("inPatientViewInfo");
              } else {
                this.doctorviewservice.sendMessage("appointment_list");
              }
            } else {
              
              this.toastr.error(Message_data.caseHistNotSaved);
              this.savebtn = false;
            }
          },
          error => {
            this.toastr.error(Message_data.defaultErr);
            this.savebtn = false;
          }
        )
    }

  }

  save_nephro() {
    var chk_flag = true, url = null, sga_score = null, diet_mrng_arry = null, break_fast_arrydata = null, mid_day_arrydata = null,
      lunch_arrydata = null, evening_arrydata = null, dinner_arrydata = null, night_arrydata = null,
      diet_flag = true;
     
    var get_sga_data = Helper_Class.getSga();
    var get_diet_data = Helper_Class.getDietplan();

    if (Helper_nephro_casesheet.neph_occ == undefined) {
      this.toastr.error(Message_data.occupMandatory);

    } else if (Helper_nephro_casesheet.neph_age == undefined) {
      this.toastr.error(Message_data.ageMandatory);

    } else if (Helper_nephro_casesheet.neph_ht == undefined || Helper_nephro_casesheet.neph_ht == "" || Helper_nephro_casesheet.neph_wt == undefined || Helper_nephro_casesheet.neph_wt == "" ||
     Helper_nephro_casesheet.heart_beat_sit == undefined || Helper_nephro_casesheet.heart_beat_sit == "" ||
      Helper_nephro_casesheet.res_rate_sit == undefined || Helper_nephro_casesheet.res_rate_sit == "" ||
      Helper_nephro_casesheet.heart_beat_stand == undefined || Helper_nephro_casesheet.heart_beat_stand == "" || Helper_nephro_casesheet.res_rate_stand == undefined || Helper_nephro_casesheet.res_rate_stand == "") {
      this.toastr.error(Message_data.bmiMandatory);

    } else if (Helper_nephro_casesheet.neph_disease == undefined || Helper_nephro_casesheet.neph_treat == undefined) {
      if (Helper_nephro_casesheet.neph_disease != undefined && Helper_nephro_casesheet.neph_disease == "6" && Helper_nephro_casesheet.neph_stage == undefined) {
        this.toastr.error(Message_data.diseaseStageMandatory);
      }
      if ((Helper_nephro_casesheet.neph_treat != undefined && Helper_nephro_casesheet.neph_treat == "2" && Helper_nephro_casesheet.neph_dia_type == undefined) ||
        (Helper_nephro_casesheet.neph_treat != undefined && Helper_nephro_casesheet.neph_treat == "2" && (Helper_nephro_casesheet.neph_dia_freq == undefined || Helper_nephro_casesheet.neph_dia_freq == ""))) {
        this.toastr.error(Message_data.treatDialyMandatory);

      }
      if ((Helper_nephro_casesheet.neph_treat != undefined && Helper_nephro_casesheet.neph_treat == "7" && Helper_nephro_casesheet.neph_dia_type == undefined) ||
        (Helper_nephro_casesheet.neph_treat != undefined && Helper_nephro_casesheet.neph_treat == "7" && (Helper_nephro_casesheet.neph_dia_freq == undefined || Helper_nephro_casesheet.neph_dia_freq == ""))) {
        this.toastr.error(Message_data.treatDialy7Mandatory);

      }
      if ((Helper_nephro_casesheet.neph_treat != undefined && Helper_nephro_casesheet.neph_treat == "8" && (Helper_nephro_casesheet.neph_dia_freq == undefined || Helper_nephro_casesheet.neph_dia_freq == ""))) {
        this.toastr.error(Message_data.treatDialy8Mandatory);

      }
    } else if (Helper_nephro_casesheet.neph_prg_array == undefined) {
      this.toastr.error(Message_data.addProg);


    } else if (get_sga_data != undefined && get_sga_data["sga_score"] != undefined) {
      if (get_sga_data["sga_score"] != "Nutritioned" && (Helper_nephro_casesheet.diet_plan_chk_falg == undefined || Helper_nephro_casesheet.diet_plan_chk_falg == "0")) {
        this.toastr.error(Message_data.addDietPlan);
      }

    } else {

      var diet_avoid_flag = "no", diet_follow_flag = "no", yoga_flag = "no";

      if (Helper_nephro_casesheet.yoga_array != undefined && Helper_nephro_casesheet.yoga_array.length != 0) {
        yoga_flag = "yes"

      } else {
        Helper_nephro_casesheet.yoga_array = undefined;
      }

      if (Helper_nephro_casesheet.diet_avoid_array != undefined && Helper_nephro_casesheet.diet_avoid_array.length != 0) {
        diet_avoid_flag = "yes"

      } else {
        Helper_nephro_casesheet.diet_avoid_array = undefined;
      }

      if (Helper_nephro_casesheet.diet_follow_array != undefined && Helper_nephro_casesheet.diet_follow_array.length != 0) {
        diet_follow_flag = "yes"

      } else {
        Helper_nephro_casesheet.diet_follow_array = undefined;
      }


      var life_style_id_data = undefined, case_hist_id_data = undefined, send_url = "neph/snephro";
      if (Helper_nephro_casesheet.nephro_get_ret != undefined && Helper_nephro_casesheet.nephro_get_ret.life_style_id != undefined) {
        life_style_id_data = Helper_nephro_casesheet.nephro_get_ret.life_style_id;
      }

      if (Helper_nephro_casesheet.nephro_get_ret != undefined && Helper_nephro_casesheet.nephro_get_ret.case_hist_id != undefined) {
        case_hist_id_data = Helper_nephro_casesheet.nephro_get_ret.case_hist_id;
        send_url = "neph/unephro";
      }

      if (Helper_nephro_casesheet.image_path != undefined) {

      }

      if (this.userlist.sub_id.length == 0) {
        var Neph_alldata = ({
          case_hist_id: case_hist_id_data,
          country: ipaddress.country_code,

          app_id: this.userlist.app_id,
          doc_reg_id: this.user_id,
          client_reg_id: this.userlist.Client_id,
          relation_id: this.userlist.rel_id,
          life_style_id: life_style_id_data,

          current_occupation: Helper_nephro_casesheet.neph_occ,
          gender: Helper_nephro_casesheet.neph_gender,
          age: Helper_nephro_casesheet.neph_age,
          physical_activity: Helper_nephro_casesheet.neph_phy_act,
          skin_symptom_dur: Helper_nephro_casesheet.skin_du_array,
          hear_symptom_dur: Helper_nephro_casesheet.hear_du_array,
          retino_symptom_dur: Helper_nephro_casesheet.eye_du_array,
          abdomen_symptom_dur: Helper_nephro_casesheet.abd_du_array,
          cardio_symption_dur: Helper_nephro_casesheet.cardio_du_array,
          resp_symptom_dur: Helper_nephro_casesheet.res_du_array,
          nephro_others: Helper_nephro_casesheet.neph_other,
          stress_factor: Helper_nephro_casesheet.neph_stress,
          complaints: Helper_nephro_casesheet.complaints,
          present_illness: Helper_nephro_casesheet.present_illness,
          past_illness: Helper_nephro_casesheet.past_illness,          
          diet_habits: Helper_nephro_casesheet.diet_habits,
          height: Helper_nephro_casesheet.neph_ht,
          height_measure: Helper_nephro_casesheet.neph_ht_measure,
          weight: Helper_nephro_casesheet.neph_wt,
          weight_measure: Helper_nephro_casesheet.neph_wt_measure,
          disease: Helper_nephro_casesheet.neph_disease,
          stage_type: Helper_nephro_casesheet.neph_stage,
          icd_code: Helper_nephro_casesheet.neph_icdcode,
          icd_desc: Helper_nephro_casesheet.neph_icddes,
          treatment: Helper_nephro_casesheet.neph_treat,
          dialysis_type: Helper_nephro_casesheet.neph_dia_type,
          dialysis_freq: Helper_nephro_casesheet.neph_dia_freq,
          blood_pressure_sit: Helper_nephro_casesheet.blood_pressure_sit,
          blood_pressure_stand: Helper_nephro_casesheet.blood_pressure_stand,
          heart_rate_stand: Helper_nephro_casesheet.heart_beat_stand,
          heart_rate_sit: Helper_nephro_casesheet.heart_beat_sit,
          respiratory_rate_stand: Helper_nephro_casesheet.res_rate_stand,
          respiratory_rate_sit: Helper_nephro_casesheet.res_rate_sit,
          recom_exec_id: Helper_nephro_casesheet.exercise_array,
          recom_exec_dur: Helper_nephro_casesheet.duration_drop,
          recom_exec_freq: Helper_nephro_casesheet.freq_drop,

          gutka: Helper_nephro_casesheet.gutka_data,
          gutka_duration: Helper_nephro_casesheet.gutka_duration_drop,
          gutka_frequency: Helper_nephro_casesheet.gutka_frq_drop,

          smoke: Helper_nephro_casesheet.smoke_data,
          smoke_duration: Helper_nephro_casesheet.smoke_duration_drop,
          smoke_frequency: Helper_nephro_casesheet.smoke_frq_drop,

          mixing_type: Helper_nephro_casesheet.mix_type_array,
          quantity: Helper_nephro_casesheet.qty_array,
          alc_duration: Helper_nephro_casesheet.alc_duration_drop,
          alc_frequency: Helper_nephro_casesheet.alc_frq_drop,
          alcohol: Helper_nephro_casesheet.alc_data,

          exercise: Helper_nephro_casesheet.exe_data,
          exercise_dur: Helper_nephro_casesheet.exe_duration_drop,
          exercise_routine: Helper_nephro_casesheet.exe_route_drop,

          family_history: Helper_Class.getDiabFam(),

          medical_history: Helper_nephro_casesheet.med_hist_array,
          neprho_comp: Helper_nephro_casesheet.complications_array,
          skin_concern: Helper_nephro_casesheet.skin_array,
          hear_concern: Helper_nephro_casesheet.hear_array,
          abdomen_concern: Helper_nephro_casesheet.abd_array,
          retino_concern: Helper_nephro_casesheet.eye_array,
          cardio_concern: Helper_nephro_casesheet.cardio_array,
          respiratory_concern: Helper_nephro_casesheet.res_array,
          disease_cause: Helper_nephro_casesheet.cau_dis_array,
          food_avoid_flag: diet_avoid_flag,
          food_follow_flag: diet_follow_flag,

          medical_hist_flag: Helper_nephro_casesheet.medical_history_flag,
          disease_flag: Helper_nephro_casesheet.disease_cause_flag,
          nephro_comp_flag: Helper_nephro_casesheet.comp_history_flag,
          skin_flag: Helper_nephro_casesheet.skin_flag,
          hear_flag: Helper_nephro_casesheet.hear_flag,
          retino_flag: Helper_nephro_casesheet.eye_flag,
          cardio_flag: Helper_nephro_casesheet.cardio_flag,
          abdomen_flag: Helper_nephro_casesheet.abdomen_flag,
          respiratory_flag: Helper_nephro_casesheet.resp_flag,

          prognosis: Helper_nephro_casesheet.neph_prg_array,
          remarks: Helper_nephro_casesheet.neph_remarks,
          food_follow: Helper_nephro_casesheet.diet_follow_array,
          food_avoid: Helper_nephro_casesheet.diet_avoid_array,
          image: Helper_nephro_casesheet.image_path,
          ref_hospital: Helper_nephro_casesheet.hosp_name,
          ref_speciality: Helper_nephro_casesheet.spl_array,
          refered_to: Helper_nephro_casesheet.neph_ref,
          next_visit: Helper_nephro_casesheet.next_vit,
          yoga_asana_id: Helper_nephro_casesheet.yoga_array,
          yogasanas: yoga_flag,
          pres_drug_id: Helper_nephro_casesheet.prescription,
          pres_diag_id: Helper_nephro_casesheet.diag_prescription
        })
        var nep_array = Neph_alldata;
      } else {
        var sub_rel = this.userlist.sub_id;

        var Neph_alldata1 = ({
          case_hist_id: case_hist_id_data,
          country: ipaddress.country_code,

          app_id: this.userlist.app_id,
          doc_reg_id: this.user_id,
          client_reg_id: this.userlist.Client_id,
          relation_id: this.userlist.rel_id,
          sub_rel_id: sub_rel,
          life_style_id: life_style_id_data,

          current_occupation: Helper_nephro_casesheet.neph_occ,
          gender: Helper_nephro_casesheet.neph_gender,
          age: Helper_nephro_casesheet.neph_age,
          physical_activity: Helper_nephro_casesheet.neph_phy_act,
          skin_symptom_dur: Helper_nephro_casesheet.skin_du_array,
          hear_symptom_dur: Helper_nephro_casesheet.hear_du_array,
          retino_symptom_dur: Helper_nephro_casesheet.eye_du_array,
          abdomen_symptom_dur: Helper_nephro_casesheet.abd_du_array,
          cardio_symption_dur: Helper_nephro_casesheet.cardio_du_array,
          resp_symptom_dur: Helper_nephro_casesheet.res_du_array,
          nephro_others: Helper_nephro_casesheet.neph_other,
          stress_factor: Helper_nephro_casesheet.neph_stress,
          complaints: Helper_nephro_casesheet.complaints,
          present_illness: Helper_nephro_casesheet.present_illness,
          past_illness: Helper_nephro_casesheet.past_illness,  
          diet_habits: Helper_nephro_casesheet.diet_habits,
          height: Helper_nephro_casesheet.neph_ht,
          height_measure: Helper_nephro_casesheet.neph_ht_measure,
          weight: Helper_nephro_casesheet.neph_wt,
          weight_measure: Helper_nephro_casesheet.neph_wt_measure,
          disease: Helper_nephro_casesheet.neph_disease,
          stage_type: Helper_nephro_casesheet.neph_stage,
          icd_code: Helper_nephro_casesheet.neph_icdcode,
          icd_desc: Helper_nephro_casesheet.neph_icddes,
          treatment: Helper_nephro_casesheet.neph_treat,
          dialysis_type: Helper_nephro_casesheet.neph_dia_type,
          dialysis_freq: Helper_nephro_casesheet.neph_dia_freq,
          blood_pressure_sit: Helper_nephro_casesheet.blood_pressure_sit,
          blood_pressure_stand: Helper_nephro_casesheet.blood_pressure_stand,
          heart_rate_stand: Helper_nephro_casesheet.heart_beat_stand,
          heart_rate_sit: Helper_nephro_casesheet.heart_beat_sit,
          respiratory_rate_stand: Helper_nephro_casesheet.res_rate_stand,
          respiratory_rate_sit: Helper_nephro_casesheet.res_rate_sit,
          recom_exec_id: Helper_nephro_casesheet.exercise_array,
          recom_exec_dur: Helper_nephro_casesheet.duration_drop,
          recom_exec_freq: Helper_nephro_casesheet.freq_drop,

          gutka: Helper_nephro_casesheet.gutka_data,
          gutka_duration: Helper_nephro_casesheet.gutka_duration_drop,
          gutka_frequency: Helper_nephro_casesheet.gutka_frq_drop,

          smoke: Helper_nephro_casesheet.smoke_data,
          smoke_duration: Helper_nephro_casesheet.smoke_duration_drop,
          smoke_frequency: Helper_nephro_casesheet.smoke_frq_drop,

          mixing_type: Helper_nephro_casesheet.mix_type_array,
          quantity: Helper_nephro_casesheet.qty_array,
          alc_duration: Helper_nephro_casesheet.alc_duration_drop,
          alc_frequency: Helper_nephro_casesheet.alc_frq_drop,
          alcohol: Helper_nephro_casesheet.alc_data,

          exercise: Helper_nephro_casesheet.exe_data,
          exercise_dur: Helper_nephro_casesheet.exe_duration_drop,
          exercise_routine: Helper_nephro_casesheet.exe_route_drop,

          family_history: Helper_Class.getDiabFam(),

          medical_history: Helper_nephro_casesheet.med_hist_array,
          neprho_comp: Helper_nephro_casesheet.complications_array,
          skin_concern: Helper_nephro_casesheet.skin_array,
          hear_concern: Helper_nephro_casesheet.hear_array,
          abdomen_concern: Helper_nephro_casesheet.abd_array,
          retino_concern: Helper_nephro_casesheet.eye_array,
          cardio_concern: Helper_nephro_casesheet.cardio_array,
          respiratory_concern: Helper_nephro_casesheet.res_array,
          disease_cause: Helper_nephro_casesheet.cau_dis_array,
          food_avoid_flag: diet_avoid_flag,
          food_follow_flag: diet_follow_flag,

          medical_hist_flag: Helper_nephro_casesheet.medical_history_flag,
          disease_flag: Helper_nephro_casesheet.disease_cause_flag,
          nephro_comp_flag: Helper_nephro_casesheet.comp_history_flag,
          skin_flag: Helper_nephro_casesheet.skin_flag,
          hear_flag: Helper_nephro_casesheet.hear_flag,
          retino_flag: Helper_nephro_casesheet.eye_flag,
          cardio_flag: Helper_nephro_casesheet.cardio_flag,
          abdomen_flag: Helper_nephro_casesheet.abdomen_flag,
          respiratory_flag: Helper_nephro_casesheet.resp_flag,

          prognosis: Helper_nephro_casesheet.neph_prg_array,
          remarks: Helper_nephro_casesheet.neph_remarks,
          food_follow: Helper_nephro_casesheet.diet_follow_array,
          food_avoid: Helper_nephro_casesheet.diet_avoid_array,
          image: Helper_nephro_casesheet.image_path,
          ref_hospital: Helper_nephro_casesheet.hosp_name,
          ref_speciality: Helper_nephro_casesheet.spl_array,
          refered_to: Helper_nephro_casesheet.neph_ref,
          next_visit: Helper_nephro_casesheet.next_vit,
          yoga_asana_id: Helper_nephro_casesheet.yoga_array,
          yogasanas: yoga_flag,
          pres_drug_id: Helper_nephro_casesheet.prescription,
          pres_diag_id: Helper_nephro_casesheet.diag_prescription
        })
        nep_array = Neph_alldata1;

      }
      console.log("njhdsg"+JSON.stringify(Helper_nephro_casesheet.image_path))
      console.log("njhdsg"+JSON.stringify(Helper_nephro_casesheet))

    console.log("neph arr"+JSON.stringify(nep_array))
      var diet_plan_url = "lifestyle/sdplan";
      if (get_sga_data != undefined && get_sga_data["sga_score"] != undefined) {
        sga_score = get_sga_data["sga_score"];
      }

      if (get_diet_data != undefined && get_diet_data["dplan_morning"] != undefined) {
        diet_mrng_arry = get_diet_data["dplan_morning"];
      }

      if (get_diet_data != undefined && get_diet_data["dplan_breakfast"] != undefined) {
        break_fast_arrydata = get_diet_data["dplan_breakfast"];
      }

      if (get_diet_data != undefined && get_diet_data["dplan_midday"] != undefined) {
        mid_day_arrydata = get_diet_data["dplan_midday"];
      }

      if (get_diet_data != undefined && get_diet_data["dplan_lunch"] != undefined) {
        lunch_arrydata = get_diet_data["dplan_lunch"];
      }

      if (get_diet_data != undefined && get_diet_data["dplan_evening"] != undefined) {
        evening_arrydata = get_diet_data["dplan_evening"];
      }

      if (get_diet_data != undefined && get_diet_data["dplan_dinner"] != undefined) {
        dinner_arrydata = get_diet_data["dplan_dinner"];
      }

      if (get_diet_data != undefined && get_diet_data["dplan_night"] != undefined) {
        night_arrydata = get_diet_data["dplan_night"];
      }

      if (get_diet_data != undefined && get_diet_data["diet_plan"] == "1") {
        diet_plan_url = "lifestyle/updplan";
      }
      console.log("neph arr"+JSON.stringify(send_url))


      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + send_url, nep_array,
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            if (obj["key"] != null) {
              if (obj["key"] == "1") {
                // save dietplan
                if (Helper_nephro_casesheet.diet_plan_chk_falg != undefined && Helper_nephro_casesheet.diet_plan_chk_falg == "1") {
                  var finalObj = null;

                  if (get_sga_data != undefined) {
                    finalObj = $.extend(true, get_sga_data, get_diet_data);

                  } else {
                    finalObj = get_diet_data;
                  }


                  var headers = new Headers();
                  headers.append('Content-Type', 'application/json');
                  this.http.post(ipaddress.getIp.toString() + diet_plan_url, finalObj,
                    { headers: headers })
                    .subscribe(
                      response => {
                        var diet_obj = response.json();
                        this.save_flag = false;
                        this.toastr.success(Message_data.caseHistSavedSuccess);
                        if (Helper_Class.getInpatientFlow() == "nurse") {
                          this.nurseservice.sendMessage("viewIpatient");
                        } else if (Doc_Helper.getAppFlow() == "Inpatient") {
                          this.doctorviewservice.sendMessage("inPatientViewInfo");
                        } else {
                          this.doctorviewservice.sendMessage("appointment_list");
                        }                       // this.succuess_page();
                      },
                      error => {
                        this.save_flag = true;
                        this.toastr.error(Message_data.defaultErr);
                      }
                    )

                } else {
                  this.savebtn = false;
                  this.save_flag = false;
                  if (case_hist_id_data != undefined && case_hist_id_data != null) {
                    this.toastr.success(Message_data.caseHistUpdSuccess);
                  } else {
                    this.toastr.success(Message_data.caseHistSavedSuccess);
                  }
                  if (Helper_Class.getInpatientFlow() == "nurse") {
                    this.nurseservice.sendMessage("viewIpatient");
                  } else {
                    this.doctorviewservice.sendMessage("appointment_list");
                  }
                }

              } else {
                this.savebtn = false;
                this.toastr.error(Message_data.caseHistNotUpd);
              }
            }
          },
          error => {
            this.savebtn = false;
            //this.toastr.error("Internet is too slow or no connection, try again");
          }
        )
    }

  }

  save_others() {
    this.send_arr = null;
    var url = null; var chk_flag = true;
    this.personal_flag = true;
    this.clinical_flag = true;
    this.diagnosis_flag = true;
    var vitals;
    var vitals_flag: boolean = true;
    var surgical_flag: boolean = true;
    this.personal = Helper_Class.getPersonalHist();
    this.cardio_habitual = Helper_Class.gethabitual();   
    if(Helper_Class.getDiabFam() != null){
      this.family = {
        family_history: Helper_Class.getDiabFam(),
      }
    }
    console.log("personal --******-"+JSON.stringify(this.personal))
    this.clinical = Helper_Class.getClinicalHist();
    this.diagnosis = Helper_Class.getDiabTreat();
    var get_sga_data = Helper_Class.getSga();
    console.log("personal --******-"+JSON.stringify(this.diagnosis))
    console.log("personal --******-"+JSON.stringify(this.clinical))
    console.log("personal --******-"+JSON.stringify(Helper_Class.getDiabTreat()))
    console.log("personal --******-"+JSON.stringify(Helper_Class.getDiabFam()))
    var get_diet_data = Helper_Class.getDietplan();
  this.medicalprescription = Helper_Class.get_med_pres_id();
    this.diagnosisprescription = Helper_Class.get_diag_pres_id();
    vitals = Doc_Helper.getVitals();
    if (this.speclization_arry == 'Orthopedic') {
      var surgery = $.extend(true, Helper_Class.getSurgicalHist(), Doc_Helper.getSugeryDetails());
    }

    var takingmed = undefined;
    var sfactor = undefined;
    var hdisorder = undefined;
    var hospvisit = undefined;

    for (var key in this.personal) {
      if (key == "taking_med" && this.personal[key] != undefined) {
        takingmed = this.personal[key];
      }

      if (key == "case_hist_id" && this.personal[key] != undefined) {
        url = "1";

      } else if (key == "current_occupation" && (this.personal[key] == undefined || this.personal[key] == null)) {
        this.personal_flag = false;
        chk_flag = false;

      } else if (takingmed != undefined && takingmed == "Yes") {
        if (key == "med_det" && this.personal[key] == undefined) {
          this.personal_flag = false;
          chk_flag = false;
        }
      }
    }

    for (var key in vitals) {
      if (key == "height" && (vitals[key] == undefined || vitals[key] == "")) {
        vitals_flag = false;
        chk_flag = false;
      } else if (key == "weight" && (vitals[key] == undefined || vitals[key] == "")) {
        vitals_flag = false;
        chk_flag = false;
      }
    }

    for (var key in surgery) {
      if (key == "pain_factor" && (surgery[key] == undefined || surgery[key] == "")) {
        // surgical_flag = false;
        // chk_flag = false;
      }
    }

    if (this.clinical != undefined) {
      for (var key in this.clinical) {
        if (key == "stressed") {
          sfactor = this.clinical[key];
        }

        if (key == "prev_hosp_visit") {
          hospvisit = this.clinical[key];
        }

        if (key == "stressed" && this.clinical[key] == undefined) {
          this.clinical_flag = false;
          chk_flag = false;
        } else if (sfactor == "Yes") {
          if (key == "stress_factor" && (this.clinical[key] == undefined || this.clinical[key] == "")) {
            this.clinical_flag = false;
            chk_flag = false;
          } else if (key == "symptoms" && (this.clinical[key] == undefined || this.clinical[key] == "")) {
            this.clinical_flag = false;
            chk_flag = false;
          }

        } else if (key == "prev_hosp_visit" && this.clinical[key] == undefined) {
          this.clinical_flag = false;
          chk_flag = false;
        } else if (hospvisit == "Yes") {
          if (key == "hosp_visit_det" && this.clinical[key] == undefined) {
            this.clinical_flag = false;
            chk_flag = false;
          }
          if (key == "treatment" && this.clinical[key] == undefined) {
            this.clinical_flag = false;
            chk_flag = false;
          }
        }
      }
    } else {
      this.clinical_flag = false;
      chk_flag = false;
    }

    if (this.diagnosis != undefined) {
     // alert(this.diagnosis["disease"])
      if (this.diagnosis["disease"] == undefined) {
        this.diagnosis_flag = false;
        chk_flag = false;
      } else {
        this.diagnosis_flag = true;
      }

    }

    if (this.personal_flag == false) {
      this.toastr.error(Message_data.personalCardMandatory);
    } else if (this.clinical_flag == false) {
      this.toastr.error(Message_data.clinicalCardMandatory);
    } else if (this.diagnosis_flag == false) {
      this.toastr.error(Message_data.treatmentCardMandatory);
    } else if (vitals_flag == false) {
      this.toastr.error(Message_data.vitalMandatory);
    }
    // else if (surgical_flag == false) {
    //   this.toastr.error(Message_data.postSurgeryMandatory);
    // }

    if (chk_flag == true) {
      if(this.family != undefined){
        var person_family = $.extend(true, this.personal, this.family, vitals);
      }else{
        var person_family = $.extend(true, this.personal, vitals);

      }
      var habitual = $.extend(true, this.cardio_habitual, person_family, this.diagnosisprescription, this.medicalprescription)
      var per_fam_clinical = $.extend(true, habitual, this.clinical);

      var final_array_nonortho = $.extend(true, per_fam_clinical, this.diagnosis);
      var final_array;
      if (this.speclization_arry == 'Orthopedic') {
        var final_array_ortho = $.extend(true, surgery, final_array_nonortho);
        final_array = final_array_ortho;
      } else {
        final_array = final_array_nonortho;
      }
      if (url != null) {
        url = ipaddress.getIp.toString() + 'other/ucasehist';

      } else {
        url = ipaddress.getIp.toString() + 'other/scasehist';
      }
      console.log("other url"+JSON.stringify(url))
      var diet_plan_url = "lifestyle/sdplan";

      for (var key in get_diet_data) {
        if (key == "dplan_morning") {
          this.diet_mrng_arry = get_diet_data[key];
        }

        if (key == "dplan_breakfast") {
          this.break_fast_arrydata = get_diet_data[key]
        }

        if (key == "dplan_midday") {
          this.mid_day_arrydata = get_diet_data[key];
        }

        if (key == "dplan_lunch") {
          this.lunch_arrydata = get_diet_data[key];
        }

        if (key == "dplan_evening") {
          this.evening_arrydata = get_diet_data[key];
        }

        if (key == "dplan_dinner") {
          this.dinner_arrydata = get_diet_data[key];
        }

        if (key == "dplan_night") {
          this.night_arrydata = get_diet_data[key];
        }

        if (key == "diet_plan" && get_diet_data[key] == "1") {
          diet_plan_url = "lifestyle/updplan";
        }
      }

      if (this.personal_flag == true && this.clinical_flag == true && this.diagnosis_flag == true) {
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(url, JSON.stringify(final_array),
          { headers: headers })
          .subscribe(
            response => {
              var obj = response.json();
              if (obj["key"] != null && obj["key"] == "1") {
                Helper_Class.setPersonalHist(null);
                Helper_Class.setDiabFam(null);
                Helper_Class.setClinicalHist(null);
                Helper_Class.setDiabTreat(null);

                var dietplan = null;

                if (get_sga_data != undefined) {
                  dietplan = $.extend(true, get_sga_data, get_diet_data);

                } else {
                  dietplan = get_diet_data;
                }

                var headers = new Headers();
                headers.append('Content-Type', 'application/json');
                this.http.post(ipaddress.getIp.toString() + diet_plan_url, dietplan,
                  { headers: headers })
                  .subscribe(
                    response => {
                      var diet_obj = response.json();
                      this.save_flag = false;
                      if (this.personal.case_hist_id != undefined && this.personal.case_hist_id != null) {
                        this.toastr.success(Message_data.caseHistUpdSuccess);
                      } else {
                        this.toastr.success(Message_data.caseHistSavedSuccess);
                      }
                      if (Helper_Class.getInpatientFlow() == "nurse") {
                        this.nurseservice.sendMessage("viewIpatient");
                      } else if (Doc_Helper.getAppFlow() == "Inpatient") {
                        this.doctorviewservice.sendMessage("inPatientViewInfo");
                      } else {
                        this.doctorviewservice.sendMessage("appointment_list");
                      }
                    }, error => {
                      this.toastr.error(Message_data.defaultErr);
                    });
              } else {
             
                console.log("final_array = "+JSON.stringify(final_array))
                this.toastr.error(Message_data.caseHistNotSaved);
              }
            });
      }
    }
  }

  save_diab() {
    this.send_arr = null;
    var url = null; this.personal_flag = true, chk_flag = true;
    var send_foot = null, getfoot_ass = null, foot_ass = true, diet_flag = true;
    var send_foot = null, foot_ass = true;
    this.clinical_flag = true;
    this.diagnosis_flag = true;
    var vitals;
    var vitals_flag: boolean = true;

    this.cardio_personal = Helper_Class.getCasesheetPersonal();
    console.log("personal ---"+JSON.stringify(this.cardio_personal))
    this.cardio_habitual = Helper_Class.gethabitual();
    if(Helper_Class.getDiabFam() != null){
      this.cardio_family = {
        family_history: Helper_Class.getDiabFam(),
      }
    }else{
      // this.cardio_family = {
      //   family_history: Helper_Class.getDiabFam(),
      // }
    }
  
    this.cardio_clinical = Helper_Class.getclinicalexam();
    this.cardio_diagnosis = Helper_Class.getDiabTreat();
    this.diab_footarr = Helper_Class.getdiabfoot();
    this.diab_complication_arr = Helper_Class.getcomplication();
    this.medicalprescription = Helper_Class.get_med_pres_id();
    this.diagnosisprescription = Helper_Class.get_diag_pres_id();
    var get_sga_data = Helper_Class.getSga();
    var get_diet_data = Helper_Class.getDietplan();
    console.log("personal ---"+JSON.stringify(Helper_Class.getDiabFam()))
    console.log("personal family ---"+JSON.stringify(this.cardio_family))
    console.log("personal -cardio_diagnosis--"+JSON.stringify(this.cardio_diagnosis))
    console.log("personal diab_footarr---"+JSON.stringify(this.diab_footarr))
    console.log("personal --diab_complication_arr-"+JSON.stringify(this.diab_complication_arr))
    console.log("diet plan-"+JSON.stringify(get_sga_data))

    vitals = Doc_Helper.getVitals();
    var chk_flag = true;
    var diet_plan_url = "lifestyle/sdplan";

    for (var key in get_diet_data) {
      if (key == "dplan_morning") {
        this.diet_mrng_arry = get_diet_data[key];
      }

      if (key == "dplan_breakfast") {
        this.break_fast_arrydata = get_diet_data[key]
      }

      if (key == "dplan_midday") {
        this.mid_day_arrydata = get_diet_data[key];
      }

      if (key == "dplan_lunch") {
        this.lunch_arrydata = get_diet_data[key];
      }

      if (key == "dplan_evening") {
        this.evening_arrydata = get_diet_data[key];
      }

      if (key == "dplan_dinner") {
        this.dinner_arrydata = get_diet_data[key];
      }

      if (key == "dplan_night") {
        this.night_arrydata = get_diet_data[key];
      }

      if (key == "diet_plan" && get_diet_data[key] == "1") {
        diet_plan_url = "lifestyle/updplan";
      }
    }

    //read key
    for (var key in this.cardio_personal) {
      if (key == "case_hist_id" && this.cardio_personal[key] != undefined) {
        url = "1";
      } else if (key == "current_occupation" && this.cardio_personal[key] == undefined) {
        chk_flag = false;
      } else if (key == "cardio_age" && this.cardio_personal[key] == undefined) {
        chk_flag = false;
      }  else if (key == "diet_habits" && this.cardio_personal[key] == undefined) {
        chk_flag = false;
      }
    }
    console.log("personal ---"+JSON.stringify(this.cardio_personal))
    console.log("personal ---"+JSON.stringify(this.cardio_personal[key]))
    for (var key in vitals) {
      if (key == "height" && (vitals[key] == undefined || vitals[key] == "")) {
        vitals_flag = false;
        chk_flag = false;
      } else if (key == "weight" && (vitals[key] == undefined || vitals[key] == "")) {
        vitals_flag = false;
        chk_flag = false;
      }
    }

    if (chk_flag == false) {
      if (vitals_flag == false) {
        this.toastr.error(Message_data.vitalMandatory);
      } else {
        this.toastr.error(Message_data.personalCardMandatory);
      }
    }

    if (url != null) {
      url = ipaddress.getIp.toString() + 'diab/upcasehist';
    } else {
      url = ipaddress.getIp.toString() + 'diab/scasehist';
    }
    console.log("diab url---"+JSON.stringify(url))

    if (chk_flag == true) {
      if (this.cardio_diagnosis != undefined) {
        var chk_inside_flag = true;
      } else {
        this.toastr.error(Message_data.verifyTreaPlan);
        chk_flag = false;
      }
    }

    for (var key in this.cardio_diagnosis) {
      if (key == "disease_type" && this.cardio_diagnosis[key] == undefined) {
        chk_flag = false;

      }  else if (key == "prognosis" && this.cardio_diagnosis[key] == undefined) {
        chk_flag = false;
      }
    }

    if (chk_flag == false) {
      if (this.cardio_diagnosis["prognosis"] != null && this.cardio_diagnosis["prognosis"] != undefined && this.cardio_diagnosis["prognosis"] != "")
        this.toastr.error(Message_data.treatPlanMandatory);
      console.log("this.cardio_diagnosis"+JSON.stringify(this.cardio_diagnosis))
    }
  

    if (chk_flag == true) {
      var finalObj = $.extend(true, this.cardio_personal, this.cardio_diagnosis, this.cardio_habitual, vitals);
      if(this.cardio_family != undefined){
      //  alert("1")
        var finalObj1 = $.extend(true, finalObj, this.cardio_family);
        var finalObj2 = $.extend(true, finalObj1, this.diab_complication_arr, this.medicalprescription, this.diagnosisprescription);

      }else{
        var finalObj2 = $.extend(true, finalObj, this.diab_complication_arr, this.medicalprescription, this.diagnosisprescription);

       // alert("2")
      }
      console.log("this.finalObj1"+JSON.stringify(finalObj1))
      var output = null;
      output = finalObj2;

      getfoot_ass = Helper_Class.getdiabfoot();
      console.log("foot assessment " +JSON.stringify(Helper_Class.getdiabfoot()))

      if (getfoot_ass != null && getfoot_ass != undefined) {
        send_foot = {
          foot_assessment: getfoot_ass,
        }

        for (var i = 0; i < getfoot_ass.length; i++) {
          for (var key in getfoot_ass) {
            if (getfoot_ass.hasOwnProperty(key)) {
              if (getfoot_ass[key].limb_tibia == "None" || getfoot_ass[key].limb_pedis == "None" || getfoot_ass[key].risk_level == "None") {
                this.toastr.error(Message_data.footAssmMandatory);
                foot_ass = false;
              }
            }
          }
        }
        output = this.jsonConcat(finalObj2, send_foot);
      }

      for (var key in get_sga_data) {

      }

      var diet_flag = true;
      output.country = "IN";
      for (var key in get_sga_data) {
        if (get_sga_data[key] == "Malnutritioned") {
          for (var key in get_diet_data) {
            if (this.diet_mrng_arry.length == 0 && this.break_fast_arrydata.length == 0 &&
              this.mid_day_arrydata.length == 0 && this.lunch_arrydata.length == 0 && this.evening_arrydata.length == 0 &&
              this.dinner_arrydata.length == 0 && this.night_arrydata.length == 0) {
              this.toastr.error("Please add diet plan");
              diet_flag = false;
            }
          }
        }
      }

      if (foot_ass == true) {
      //  alert(diet_flag)
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(url, JSON.stringify(output),
          { headers: headers })
          .subscribe(
            response => {
              var obj = response.json();
              if (obj["key"] != null && obj["key"] == "1") {
                Helper_Class.setDiabFam(null);
                Helper_Class.sethabitual(null);
                Helper_Class.setcomplication(null);
                Helper_Class.setdiabfoot(null);
                Helper_Class.setCasesheetPersonal(null);
                Helper_Class.setDiabTreat(null);
                //send dietplan data
                var dietplan = null;
                if(diet_flag == true){
                  if (get_sga_data != undefined) {
                    dietplan = $.extend(true, get_sga_data, get_diet_data);
  
                  } else {
                    if(get_diet_data != undefined){
                      dietplan = get_diet_data;
                    }
                   
                  }
                }
                var diet_flag;
                if(dietplan != null){
                  diet_flag =true
                }else{
                  diet_flag=false
                }
               // alert("dietplan--"+JSON.stringify(dietplan)+diet_flag)

                if (diet_flag == true) {
                 
                  var headers = new Headers();
                  headers.append('Content-Type', 'application/json');
                  this.http.post(ipaddress.getIp.toString() + diet_plan_url, JSON.stringify(dietplan),
                    { headers: headers })
                    .subscribe(
                      response => {
                        var diet_obj = JSON.parse(response["_body"]);
                        // this.loader = true;
                        if (diet_obj.key != "0") {
                          this.save_flag = false;
                          if (this.cardio_personal.case_hist_id != undefined && this.cardio_personal.case_hist_id != null) {
                            this.toastr.success(Message_data.caseHistUpdSuccess);
                          } else {
                            this.toastr.success(Message_data.caseHistSavedSuccess);
                          }
                          if (Helper_Class.getInpatientFlow() == "nurse") {
                            this.nurseservice.sendMessage("viewIpatient");
                          } else if (Doc_Helper.getAppFlow() == "Inpatient") {
                            this.doctorviewservice.sendMessage("inPatientViewInfo");
                          } else {
                            this.doctorviewservice.sendMessage("appointment_list");
                          }
                          if (Doc_Helper.getAppFlow() != null && Doc_Helper.getAppFlow() == "normal") {
                            //  this.navCtrl.setRoot('clientapplistPage');

                          }
                        } else {
                          this.toastr.error(Message_data.defaultErr);
                        }

                      },
                      error => {
                        this.toastr.error(Message_data.defaultErr);
                      }
                    )
                } else {
                  this.save_flag = false;
                  if (this.cardio_personal.case_hist_id != undefined && this.cardio_personal.case_hist_id != null) {
                    this.toastr.success(Message_data.caseHistUpdSuccess);
                  } else {
                    this.toastr.success(Message_data.caseHistSavedSuccess);
                  }
                  if (Helper_Class.getInpatientFlow() == "nurse") {
                    this.nurseservice.sendMessage("viewIpatient");
                  } else if (Doc_Helper.getAppFlow() == "Inpatient") {
                    this.doctorviewservice.sendMessage("inPatientViewInfo");
                  } else {
                    this.doctorviewservice.sendMessage("appointment_list");
                  }
                  if (Doc_Helper.getAppFlow() != null && Doc_Helper.getAppFlow() == "normal") {
                    //  this.navCtrl.setRoot('clientapplistPage');

                  }
                }
              } else {
                if (this.cardio_personal.case_hist_id != undefined && this.cardio_personal.case_hist_id != null) {
                  this.toastr.error(Message_data.getGenralError());
                } else {
                  this.toastr.error(Message_data.getGenralError());
                }
              }
            }, error => {
              this.toastr.error(Message_data.defaultErr);
            });
      }
    }
  }

  save_dental() { //dental
    this.medicalprescription = Helper_Class.get_med_pres_id();
    this.diagnosisprescription = Helper_Class.get_diag_pres_id();
    var assign_data = Doc_Helper.Get_dental_reff_data();
    var get_basic_data = Doc_Helper.Get_send_dental_basic_data();
    var personal_hist = Doc_Helper.Get_den_per_hist();
    var clinical_exam = Doc_Helper.Get_den_cli_exam();
    var den_spl = Doc_Helper.get_den_spl();
    var invset_plan = Doc_Helper.Get_den_treat_plan();
    //  var assign_data = Helper_Class.get_dental_reff_data();
    var material = Doc_Helper.Get_material_data();
    var perio_chart_data = Helper_Class.Get_perio_chart_data();
    var birthcomp = Helper_Class.Get_den_spl_birth();
    var generalcomp = Doc_Helper.Get_den_spl_general();
    var dentalcomp = Doc_Helper.Get_den_spl_dental();
    var gumscomp = Helper_Class.Get_den_spl_gums();
    var natalcomp = Doc_Helper.Get_den_spl_natal();
    var check_flag = true;


    var getret = Helper_Class.getHopitalfieldsRet()
    if (getret != undefined) {
      for (var i = 0; i < getret.length; i++) {
        if (getret[i].page_name == "Speciality") {
          if (getret[i].fields != undefined) {
            for (var j = 0; j < getret[i].fields.length; j++) {
              this.SplFileds.push(getret[i].fields[j].field_name);
            }
          }
        }
      }
    }

    if (personal_hist != undefined && (personal_hist["chief_complaints"] != undefined && personal_hist["chief_complaints"].length == 0)) {
      // this.loader = true;
      this.toastr.error('Select chief complients');
      //this.myTabs.select(0);
      check_flag = false;

    } else if (personal_hist != undefined && personal_hist["den_spl_id"] == undefined) {
      //this.loader = true;
      this.toastr.error('Select speciality');
      // this.myTabs.select(0);
      check_flag = false;

    } else if ((clinical_exam == undefined || clinical_exam["clinical_exam"].length == undefined || clinical_exam["clinical_exam"].length == 0) && personal_hist["ReferFlow"] == undefined) {
      // this.loader = true;
      this.toastr.error('Add clinical examination table data');
      //this.myTabs.select(1);
      check_flag = false;

    } else if (den_spl != undefined) {

      var spl_name = Doc_Helper.get_dental_spl_name()
      if (spl_name == "General") {
        //intra oral
        if (check_flag == true && (den_spl["mouth"] != undefined || den_spl["labial_mucosa"] != undefined ||
          den_spl["palatal"] != undefined || den_spl["buccal_mucosa"] != undefined || den_spl["tongue"] != undefined ||
          den_spl["periodintium"] != undefined || den_spl["alveolar_mucosa"] != undefined || den_spl["floor_mouth"] != undefined)) {

          if ((den_spl["mouth"] != undefined && den_spl["mouth"] == "") || (den_spl["labial_mucosa"] != undefined && den_spl["labial_mucosa"] == "") ||
            (den_spl["palatal"] != undefined && den_spl["palatal"] == "") ||
            (den_spl["buccal_mucosa"] != undefined && den_spl["buccal_mucosa"] == "") ||
            (den_spl["tongue"] != undefined && den_spl["tongue"] == "") ||
            (den_spl["periodintium"] != undefined && den_spl["periodintium"] == "") || (den_spl["alveolar_mucosa"] != undefined && den_spl["alveolar_mucosa"] == "") || (den_spl["floor_mouth"] != undefined && den_spl["floor_mouth"] == "")) {
            // this.loader = true;
            this.toastr.error('Enter intra oral mandatory fields');
            //  this.myTabs.select(2);
            check_flag = false;
          }

        }
        //extra oral
        if (check_flag == true && (den_spl["extra_oral_con_note"] != undefined || den_spl["extra_oral_jaw_note"] != undefined)) {

          if ((den_spl["extra_oral_con_note"] != undefined && den_spl["extra_oral_con_note"] == "") || (den_spl["extra_oral_jaw_note"] != undefined && den_spl["extra_oral_jaw_note"] == "")) {
            //   this.loader = true;
            this.toastr.error('Enter extra oral mandatory fields');
            // this.myTabs.select(2);
            check_flag = false;

          }
        }

      } else if (spl_name == "Periodontics") {
        //intra oral
        if (check_flag == true && (den_spl["mouth"] == undefined || den_spl["mouth"] == "" || den_spl["labial_mucosa"] == undefined || den_spl["labial_mucosa"] == "" ||
          den_spl["palatal"] == undefined || den_spl["palatal"] == "" || den_spl["buccal_mucosa"] == undefined || den_spl["buccal_mucosa"] == "" || den_spl["tongue"] == undefined || den_spl["tongue"] == "" || den_spl["vestibule"] == undefined || den_spl["vestibule"] == "" ||
          den_spl["periodintium"] == undefined || den_spl["periodintium"] == "" || den_spl["floor_mouth"] == undefined || den_spl["floor_mouth"] == "" || den_spl["floor_mouth"] == undefined || den_spl["floor_mouth"] == "")) {
          //this.loader = true;
          this.toastr.error('Enter intra oral mandatory fields');
          //this.myTabs.select(2);
          check_flag = false;
        }
        //extra oral
        if (check_flag == true && (den_spl["extra_oral_con_note"] == undefined || den_spl["extra_oral_con_note"] == "" || den_spl["extra_oral_jaw_note"] == undefined || den_spl["extra_oral_jaw_note"] == "")) {
          //  this.loader = true;
          this.toastr.error('Enter extra oral mandatory fields');
          //  this.myTabs.select(2);
          check_flag = false;
        }

        if (check_flag == true && ((den_spl["pharynx_tonsils"] != undefined && den_spl["pharynx_tonsils"] != "") || den_spl["den_face_sym_id"] != undefined || den_spl["lip"] != undefined ||
          (den_spl["oralym"] != undefined && den_spl["oralym"] != ""))) {
          if (den_spl["pharynx_tonsils"] == undefined || den_spl["den_face_sym_id"] == undefined || den_spl["lip"] == undefined || den_spl["oralym"] == undefined) {
            // this.loader = true;
            this.toastr.error('Enter extra oral mandatory fields');
            // this.myTabs.select(2);
            check_flag = false;
          }
        }
        //gums & teeth
        if (check_flag == true && ((gumscomp["surface_texture"] != undefined && gumscomp["surface_texture"] != "") || (gumscomp["size"] != undefined && gumscomp["size"] != "") || (gumscomp["position"] != undefined && gumscomp["position"] != "") ||
          (gumscomp["ped_pocket"] != undefined && gumscomp["ped_pocket"] != "") || (gumscomp["exudate"] != undefined && gumscomp["exudate"] != "") || (gumscomp["affecting_colour"] != undefined && gumscomp["affecting_colour"] != "") ||
          (gumscomp["consistency"] != undefined && gumscomp["consistency"] != "") || (gumscomp["contour"] != undefined && gumscomp["contour"] != "") ||
          (gumscomp["open_contacts"] != undefined && gumscomp["open_contacts"] != "") || (gumscomp["pathologic_migration"] != undefined && den_spl["pathologic_migration"] != "") ||
          (gumscomp["occlusion"] != undefined && gumscomp["occlusion"] != "") || (gumscomp["supraaerupted"] != undefined && gumscomp["supraaerupted"] != ""))) {

          if (gumscomp["open_contacts"] == undefined || gumscomp["pathologic_migration"] == undefined || gumscomp["supraaerupted"] == undefined) {
            //this.loader = true;
            this.toastr.error('Enter gums & teeth mandatory fields');
            // this.myTabs.select(2);
            check_flag = false;
          }
        }

      } else if (spl_name == "Orthodontics") {
        //physical status
        if (check_flag == true && (den_spl["build"] != undefined || (den_spl["height"] != undefined && den_spl["height"] != "") || (den_spl["weight"] != undefined && den_spl["weight"] != "") || (den_spl["status_gaint"] != undefined && den_spl["status_gaint"] != "")
          || (den_spl["posture"] != undefined && den_spl["posture"] != "") || (den_spl["health"] != undefined) || (den_spl["body_type"] != undefined))) {

          if ((this.SplFileds["Gait"] != null && den_spl["status_gaint"] == undefined) || den_spl["body_type"] == undefined) {

            //this.loader = true;
            this.toastr.error('Enter physical status mandatory fields');
            // this.myTabs.select(2);
            check_flag = false;
          }
        }

        //intra oral
        if (check_flag == true && (den_spl["mouth"] != undefined || den_spl["labial_mucosa"] != undefined ||
          den_spl["palatal"] != undefined || den_spl["buccal_mucosa"] != undefined || den_spl["tongue"] != undefined ||
          den_spl["periodintium"] != undefined || den_spl["alveolar_mucosa"] != undefined || den_spl["floor_mouth"] != undefined)) {

          if ((den_spl["mouth"] != undefined && den_spl["mouth"] == "") || (den_spl["labial_mucosa"] != undefined && den_spl["labial_mucosa"] == "") ||
            (den_spl["palatal"] != undefined && den_spl["palatal"] == "") || (den_spl["buccal_mucosa"] != undefined && den_spl["buccal_mucosa"] == "") || (den_spl["tongue"] != undefined && den_spl["tongue"] == "") ||
            (den_spl["periodintium"] != undefined && den_spl["periodintium"] == "") || (den_spl["alveolar_mucosa"] != undefined && den_spl["alveolar_mucosa"] == "") || (den_spl["floor_mouth"] != undefined && den_spl["floor_mouth"] == "")) {
            // this.loader = true;
            this.toastr.error('Enter intra oral mandatory fields');
            //  this.myTabs.select(2);
            check_flag = false;
          }
        }

        if (check_flag == true && ((den_spl["max_mouth_open"] != undefined && den_spl["max_mouth_open"] != "") || den_spl["oral_hygeine"] != undefined ||
          den_spl["den_ging_colour"] != undefined || den_spl["gingival_texture"] != undefined || den_spl["palatal_contour"] != undefined ||
          den_spl["oral_muscle_tone"] != undefined || (den_spl["tonsils_adenoids"] != undefined && den_spl["tonsils_adenoids"] != "") || (den_spl["oral_mucosa"] != undefined && den_spl["oral_mucosa"] != "") ||
          (den_spl["tongue_size"] != undefined && den_spl["tongue_size"] != "") || (den_spl["tongue_posture"] != undefined && den_spl["tongue_posture"] != "") || (den_spl["tongue_shape"] != undefined && den_spl["tongue_shape"] != "") || (den_spl["tongue_movements"] != undefined && den_spl["tongue_movements"] != undefined))) {

          if (den_spl["tongue_movements"] == undefined || den_spl["tongue_movements"] == "" || den_spl["tongue_shape"] == undefined || den_spl["tongue_shape"] == "" || den_spl["tongue_posture"] == undefined || den_spl["tongue_posture"] == "" || den_spl["tongue_size"] == undefined || den_spl["tongue_size"] == "" || den_spl["oral_mucosa"] == undefined || den_spl["oral_mucosa"] == "" || den_spl["tonsils_adenoids"] == undefined && den_spl["tonsils_adenoids"] == "" || den_spl["oral_muscle_tone"] == undefined && den_spl["oral_muscle_tone"] == "" || den_spl["palatal_contour"] == undefined || den_spl["palatal_contour"] == "" || den_spl["gingival_texture"] == undefined || den_spl["oral_hygeine"] == undefined || den_spl["oral_hygeine"] == "" || den_spl["max_mouth_open"] == undefined || den_spl["max_mouth_open"] == "" || den_spl["den_ging_colour"] == undefined || den_spl["gingival_texture"] == undefined) {
            //this.loader = true;
            this.toastr.error('Enter intra oral mandatory fields');
            // this.myTabs.select(2);
            check_flag = false;
          }
        }

        //extra oral
        if (check_flag == true && (den_spl["extra_oral_con_note"] != undefined || den_spl["extra_oral_jaw_note"] != undefined)) {
          if ((den_spl["extra_oral_con_note"] != undefined && den_spl["extra_oral_con_note"] == "") || (den_spl["extra_oral_jaw_note"] != undefined && den_spl["extra_oral_jaw_note"] == "")) {
            //this.loader = true;
            this.toastr.error('Enter extra oral mandatory fields1');
            // this.myTabs.select(2);
            check_flag = false;
          }
        }

        if (check_flag == true && ((den_spl["cephalic_index"] != undefined && den_spl["cephalic_index"] != "") || (den_spl["facial_index"] != undefined && den_spl["facial_index"] != "") ||
          den_spl["den_facial_divg_id"] != undefined || den_spl["den_lip_post_id"] != undefined || den_spl["lip_pos_incomp"] != undefined || den_spl["clinical_fma"] != undefined || den_spl["upper_lip"] != undefined || den_spl["lower_lip"] != undefined || den_spl["den_chin_posi_id"] != undefined || den_spl["den_naso_angle_id"] != undefined || den_spl["den_ment_sulcus_id"] != undefined || (den_spl["inter_labial_gap"] != undefined && den_spl["inter_labial_gap"] != "") || den_spl["den_facial_profile_id"] != undefined ||
          den_spl["den_head_shape_id"] != undefined || den_spl["den_facial_form_id"] != undefined || (den_spl["skeletal_relation"] != undefined && den_spl["skeletal_relation"] != "") || den_spl["den_face_sym_id"] != undefined)) {

          if (den_spl["cephalic_index"] == undefined || den_spl["cephalic_index"] == "" || den_spl["facial_index"] == undefined || den_spl["facial_index"] == "" || den_spl["den_facial_divg_id"] == undefined || den_spl["den_lip_post_id"] == undefined || den_spl["lip_pos_incomp"] == undefined || den_spl["clinical_fma"] == undefined || den_spl["upper_lip"] == undefined || den_spl["lower_lip"] == undefined ||
            den_spl["den_chin_posi_id"] == undefined || den_spl["den_naso_angle_id"] == undefined || den_spl["den_ment_sulcus_id"] == undefined || den_spl["inter_labial_gap"] == undefined || den_spl["inter_labial_gap"] == "" || den_spl["den_facial_profile_id"] == undefined || den_spl["den_head_shape_id"] == undefined || den_spl["den_face_sym_id"] == undefined || den_spl["skeletal_relation"] == undefined || den_spl["skeletal_relation"] == "" || den_spl["den_facial_form_id"] == undefined) {
            // this.loader = true;
            this.toastr.error('Enter extra oral mandatory fields');
            //  this.myTabs.select(2);
            check_flag = false;

          }
        }

        //functional 1
        if (check_flag == true && (den_spl["respiration"] != undefined || den_spl["path_closure"] != undefined || den_spl["deglutition"] != undefined ||
          den_spl["perioral_mus_act"] != undefined || (den_spl["fun_speech"] != undefined && den_spl["fun_speech"] != "") || den_spl["TMJ_option"] == "yes" || den_spl["pain_condition"] == "yes" || den_spl["sounds_cond"] == "yes" || den_spl["tend_palp_cond"] == "yes" || den_spl["muscle_tend_palp"] == "yes" ||
          (den_spl["mastication"] != undefined && den_spl["mastication"] != "") || (den_spl["inter_clearance"] != undefined && den_spl["inter_clearance"] != "") || (den_spl["expo_speech"] != undefined && den_spl["expo_speech"] != "") || (den_spl["expo_smile"] != undefined && den_spl["expo_smile"] != "") || (den_spl["mandi_mt_open"] != undefined && den_spl["mandi_mt_open"] != "") || (den_spl["mandi_mt_pro"] != undefined && den_spl["mandi_mt_pro"] != "") || (den_spl["mandi_mt_rt_excur"] != undefined && den_spl["mandi_mt_rt_excur"] != "") || (den_spl["mandi_mt_lt_excur"] != undefined && den_spl["mandi_mt_lt_excur"] != ""))) {

          if ((den_spl["muscle_tend_palp"] == "yes" && den_spl["tend_palp_desc"] == undefined) || (den_spl["tend_palp_cond"] == "yes" && (den_spl["tend_palp_rt"] == undefined || den_spl["tend_palp_lt"] == undefined)) || (den_spl["sounds_cond"] == "yes" && den_spl["sounds_dur"] == undefined) || (den_spl["pain_condition"] == "yes" && den_spl["pain_dur"] == undefined) || (den_spl["TMJ_option"] == "yes" && (den_spl["TMJ_desc"] == undefined || den_spl["TMJ_desc"] == "")) || den_spl["respiration"] == undefined || den_spl["path_closure"] == undefined || den_spl["deglutition"] == undefined || den_spl["perioral_mus_act"] == undefined || den_spl["fun_speech"] == undefined || den_spl["fun_speech"] == "" || den_spl["mastication"] == undefined || den_spl["mastication"] == "" || den_spl["inter_clearance"] == undefined || den_spl["inter_clearance"] == "" || den_spl["expo_speech"] == undefined || den_spl["expo_speech"] == "" || den_spl["expo_smile"] == undefined || den_spl["expo_smile"] == "" || den_spl["mandi_mt_open"] == undefined || den_spl["mandi_mt_open"] == "" || den_spl["mandi_mt_pro"] == undefined || den_spl["mandi_mt_pro"] == "" || den_spl["mandi_mt_rt_excur"] == undefined || den_spl["mandi_mt_rt_excur"] == "" || den_spl["mandi_mt_lt_excur"] == undefined || den_spl["mandi_mt_lt_excur"] == "") {
            // this.loader = true;
            this.toastr.error('Enter functional mandatory fields');
            //   this.myTabs.select(2);
            check_flag = false;
          }
        }

        //birth
        if (check_flag == true && ((birthcomp["breast_fed_dur"] != undefined && birthcomp["breast_fed_dur"] != "") || (birthcomp["breast_fed_freq"] != undefined && birthcomp["breast_fed_freq"] != "") || (birthcomp["bottle_fed_dur"] != undefined && birthcomp["bottle_fed_dur"] != "")
          || (birthcomp["bottle_fed_freq"] != undefined && birthcomp["bottle_fed_freq"] != "") || (birthcomp["weaned_out"] != undefined && den_spl["weaned_out"] != "") || den_spl["child_hood"] != undefined || birthcomp["type_nipple"] != undefined)) {
          if (birthcomp["breast_fed_dur"] == undefined || birthcomp["breast_fed_dur"] == "" || birthcomp["breast_fed_freq"] == undefined || birthcomp["breast_fed_freq"] == ""
            || birthcomp["bottle_fed_dur"] == undefined || birthcomp["bottle_fed_dur"] == "" || birthcomp["bottle_fed_freq"] == undefined || birthcomp["bottle_fed_freq"] == "" || birthcomp["type_nipple"] == undefined) {
            //  this.loader = true;
            this.toastr.error('Enter birth history mandatory fields');
            // this.myTabs.select(2);
            check_flag = false;
          }
        }

        // natal
        if (check_flag == true && (natalcomp["narrator"] != undefined || natalcomp["type"] != undefined || natalcomp["infe_moth_preg"] != undefined || natalcomp["gestation_peroid"] != undefined || (natalcomp["health_moth_preg"] != undefined && natalcomp["health_moth_preg"] != undefined) || (natalcomp["drug_moth_preg"] != undefined && natalcomp["drug_moth_preg"] != "") ||
          (natalcomp["birth_weight"] != undefined && natalcomp["birth_weight"] != "") || (natalcomp["rh_comp_dis"] != undefined && natalcomp["rh_comp_dis"] != "") ||
          (natalcomp["sitting"] != undefined && natalcomp["sitting"] != "") || (natalcomp["crawling"] != undefined && natalcomp["crawling"] != "") || (natalcomp["standing"] != undefined && natalcomp["standing"] != "") ||
          (natalcomp["walking"] != undefined && natalcomp["walking"] != "") || (natalcomp["running"] != undefined && natalcomp["running"] != "") ||
          (natalcomp["phonation"] != undefined && natalcomp["phonation"] != "") || (natalcomp["his_tonsill_adenoid"] != undefined && natalcomp["his_tonsill_adenoid"] != "") ||
          (natalcomp["familial_maloc_his"] != undefined && natalcomp["familial_maloc_his"] != "") || natalcomp["parents_maloc_type"] != undefined ||
          (natalcomp["thumb_stick"] != undefined && natalcomp["thumb_stick"] != "") || (natalcomp["nail_biting"] != undefined && natalcomp["nail_biting"] != "") ||
          (natalcomp["dummy_suck"] != undefined && natalcomp["dummy_suck"] != "") ||
          (natalcomp["bruxium"] != undefined && natalcomp["bruxium"] != "") || (natalcomp["tongue_bitting"] != undefined && natalcomp["tongue_bitting"] != "") ||
          (natalcomp["injuries"] != undefined && natalcomp["injuries"] != ""))) {

          if (natalcomp["narrator"] == undefined || natalcomp["type"] == undefined || natalcomp["health_moth_preg"] == undefined || natalcomp["health_moth_preg"] == "" || natalcomp["sitting"] == undefined || natalcomp["sitting"] == "" || natalcomp["crawling"] == undefined || natalcomp["crawling"] == "" || natalcomp["standing"] == undefined || natalcomp["standing"] == "" || natalcomp["walking"] == undefined || natalcomp["walking"] == "" || natalcomp["running"] == undefined || den_spl["running"] == "" || natalcomp["phonation"] == undefined || natalcomp["phonation"] == "") {
            // this.loader = true;
            this.toastr.error('Enter natal history mandatory fields');
            //this.myTabs.select(2);
            check_flag = false;
          }
        }
        //general
        if (generalcomp == true && ((generalcomp["father_name"] != undefined && generalcomp["father_name"] != "") || (generalcomp["mother_name"] != undefined && generalcomp["mother_name"] != "") || generalcomp["marriage"] != undefined || generalcomp["siblings"] != undefined ||
          (generalcomp["sib_dental_cond"] != undefined && generalcomp["sib_dental_cond"] != "") || (generalcomp["familial_disease"] != undefined && generalcomp["familial_disease"] != "") || (generalcomp["par_dental_cond"] != undefined && generalcomp["par_dental_cond"] != "")
          || (generalcomp["male"] != undefined && generalcomp["male"] != "") || (generalcomp["female"] != undefined && generalcomp["female"] != "") || (generalcomp["past_den_hist"] != undefined && generalcomp["past_den_hist"] != ""))) {

          if (generalcomp["father_name"] == undefined || generalcomp["mother_name"] == undefined || generalcomp["marriage"] == undefined) {
            //this.loader = true;
            this.toastr.error('Enter general history mandatory fields');
            // this.myTabs.select(2);
            check_flag = false;
          }

          if (check_flag == true && (generalcomp["siblings"] != undefined && (generalcomp["siblings"] == "Male" && (generalcomp["male"] == undefined || generalcomp["male"] == ""))) || (generalcomp["siblings"] != undefined && (generalcomp["sib_dental_cond"] == undefined || generalcomp["sib_dental_cond"] == ""))) {
            //this.toastr.error('Enter general history mandatory fields');
            // this.myTabs.select(2);
            check_flag = false;
          }
          if (check_flag == true && (generalcomp["siblings"] != undefined && (generalcomp["siblings"] == "Female" && (generalcomp["female"] == undefined || generalcomp["female"] == ""))) || (generalcomp["siblings"] != undefined && (generalcomp["sib_dental_cond"] == undefined || generalcomp["sib_dental_cond"] == ""))) {
            // this.loader = true;
            this.toastr.error('Enter general history mandatory fields');
            //  this.myTabs.select(2);
            check_flag = false;
          }
          if (check_flag == true &&
            (generalcomp["siblings"] != undefined &&
              (generalcomp["siblings"] == "Male/Female" &&
                ((generalcomp["male"] == undefined || generalcomp["male"] == "") || (generalcomp["female"] == undefined || generalcomp["female"] == ""))

              )) ||
            (generalcomp["siblings"] != undefined && (generalcomp["sib_dental_cond"] == undefined || generalcomp["sib_dental_cond"] == ""))) {

            // this.loader = true;
            this.toastr.error('Enter general history mandatory fields');
            //  this.myTabs.select(2);
            check_flag = false;
          }

        }
      }
      else if (spl_name == "Endodontics") {
        //intra oral
        if (check_flag == true && (den_spl["mouth"] != undefined || den_spl["labial_mucosa"] != undefined ||
          den_spl["palatal"] != undefined || den_spl["buccal_mucosa"] != undefined || den_spl["tongue"] != undefined ||
          den_spl["periodintium"] != undefined || den_spl["alveolar_mucosa"] != undefined || den_spl["floor_mouth"] != undefined) || (den_spl["sinus_tracts"] != undefined && den_spl["sinus_tracts"] != "") || (den_spl["ease_access"] != undefined && den_spl["ease_access"] != "") || (den_spl["facial_swelling"] != undefined && den_spl["facial_swelling"] != "")) {

          if ((den_spl["mouth"] != undefined && den_spl["mouth"] == "") || (den_spl["labial_mucosa"] != undefined && den_spl["labial_mucosa"] == "") ||
            (den_spl["palatal"] != undefined && den_spl["palatal"] == "") || (den_spl["buccal_mucosa"] != undefined && den_spl["buccal_mucosa"] == "") || (den_spl["tongue"] != undefined && den_spl["tongue"] == "") ||
            (den_spl["periodintium"] != undefined && den_spl["periodintium"] == "") || (den_spl["alveolar_mucosa"] != undefined && den_spl["alveolar_mucosa"] == "") || (den_spl["floor_mouth"] != undefined && den_spl["floor_mouth"] == "") ||
            (den_spl["sinus_tracts"] == undefined || den_spl["ease_access"] == "" || den_spl["facial_swelling"] == undefined)) {
            // this.loader = true;
            this.toastr.error('Enter intra oral mandatory fields');
            //  this.myTabs.select(2);
            check_flag = false;
          }

        }
        //extra oral
        if (check_flag == true && (den_spl["extra_oral_con_note"] != undefined || den_spl["extra_oral_jaw_note"] != undefined)) {

          if ((den_spl["extra_oral_con_note"] != undefined && den_spl["extra_oral_con_note"] == "") || (den_spl["extra_oral_jaw_note"] != undefined && den_spl["extra_oral_jaw_note"] == "")) {
            // this.loader = true;
            this.toastr.error('Enter extra oral mandatory fields');
            // this.myTabs.select(2);
            check_flag = false;

          }
        }
        // endo
        if (check_flag == true && ((den_spl["pain_worse"] != undefined && den_spl["pain_worse"] != "") || (den_spl["pain_episode"] != undefined && den_spl["pain_episode"] != "")
          || (den_spl["intensity"] != undefined && den_spl["intensity"] != "") || (den_spl["location"] != undefined && den_spl["location"] != "") ||
          (den_spl["quality_pain"] != undefined && den_spl["quality_pain"] != "") || (den_spl["social_history"] != undefined && den_spl["social_history"] != "")
          || (den_spl["relieve_factors"] != undefined && den_spl["relieve_factors"] != "") || (den_spl["den_spl_spec_dis"] != undefined && den_spl["den_spl_spec_dis"] != "")
          || den_spl["ill_operation"] == "yes" || den_spl["bleeding_spl_treat"] == "yes")) {

          if ((den_spl["pain_worse"] == undefined || den_spl["pain_episode"] == undefined || den_spl["intensity"] == undefined || den_spl["location"] == undefined ||
            den_spl["quality_pain"] == undefined || den_spl["social_history"] == undefined || den_spl["relieve_factors"] == undefined || den_spl["den_spl_spec_dis"] == undefined || (den_spl["ill_operation"] == "yes" && den_spl["ill_oper_desc"] == undefined) || (den_spl["bleeding_spl_treat"] == "yes" && (den_spl["spl_treat_desc"] == undefined)))) {
            //  this.loader = true;
            this.toastr.error('Enter endodontic history  mandatory fields');
            //  this.myTabs.select(2);
            check_flag = false;
          }
        }

      }
      else if (spl_name == "Pediatrics") {

        //Pediatric specific
        if (check_flag == true && ((den_spl["beh_desc"] != undefined && den_spl["beh_desc"] != "") || (den_spl["beh_reason"] != undefined && den_spl["beh_reason"] != "")
          || (den_spl["pro_bottle_use"] != undefined && den_spl["pro_bottle_use"] != "") || (den_spl["tooth_decay"] != undefined && den_spl["tooth_decay"] != "")
          || (den_spl["rel_med_history"] != undefined && den_spl["rel_med_history"] != "") || den_spl["rate_type"] != undefined)) {

          if (den_spl["beh_reason"] == undefined || den_spl["pro_bottle_use"] == undefined ||
            den_spl["tooth_decay"] == undefined) {

            //this.loader = true;
            this.toastr.error('Enter pediatric specific  mandatory fields');
            //  this.myTabs.select(2);
            check_flag = false;
          }
        }


        //intra oral
        if (check_flag == true && (den_spl["mouth"] != undefined || den_spl["labial_mucosa"] != undefined ||
          den_spl["palatal"] != undefined || den_spl["buccal_mucosa"] != undefined || den_spl["tongue"] != undefined ||
          den_spl["periodintium"] != undefined || den_spl["alveolar_mucosa"] != undefined || den_spl["floor_mouth"] != undefined) || (den_spl["ped_lips_labial"] != undefined && den_spl["ped_lips_labial"] != "") || (den_spl["ped_tonsils"] != undefined && den_spl["ped_tonsils"] != "") || (den_spl["ped_hard_soft_palate"] != undefined && den_spl["ped_hard_soft_palate"] != "") ||
          (den_spl["pocket"] != undefined && den_spl["pocket"] != "") || (den_spl["ped_frenum"] != undefined && den_spl["ped_frenum"] != "") ||
          (den_spl["intra_bleed"] != undefined && den_spl["intra_bleed"] != "")) {

          if (den_spl["mouth"] == "" || den_spl["labial_mucosa"] == "" ||
            (den_spl["palatal"] != undefined && den_spl["palatal"] == "") || (den_spl["buccal_mucosa"] != undefined && den_spl["buccal_mucosa"] == "") || (den_spl["tongue"] != undefined && den_spl["tongue"] == "") ||
            (den_spl["periodintium"] != undefined && den_spl["periodintium"] == "") || (den_spl["alveolar_mucosa"] != undefined && den_spl["alveolar_mucosa"] == "") || (den_spl["floor_mouth"] != undefined && den_spl["floor_mouth"] == "") ||
            (den_spl["ped_lips_labial"] == undefined || den_spl["ped_hard_soft_palate"] == undefined)) {
            // this.loader = true;
            this.toastr.error('Enter intra oral mandatory fields');
            //  this.myTabs.select(2);
            check_flag = false;
          }
        }
        if ((den_spl["perm_teeth_present"] != undefined && den_spl["perm_teeth_present"] != "") || (den_spl["dec_teeth_present"] != undefined && den_spl["dec_teeth_present"] != "") || den_spl["texture"] != undefined
          || den_spl["unerupted_teeth"] != undefined || den_spl["ineruption_teeth"] != undefined || (den_spl["key_ridge_position"] != undefined && den_spl["key_ridge_position"] != "") || (den_spl["size_shape_form"] != undefined && den_spl["size_shape_form"] != "")) {

          if (den_spl["perm_teeth_present"] == undefined || den_spl["dec_teeth_present"] == undefined ||
            den_spl["texture"] == undefined || den_spl["unerupted_teeth"] == undefined || den_spl["ineruption_teeth"] == undefined || den_spl["key_ridge_position"] == undefined) {
            // this.loader = true; 
            this.toastr.error('Enter intra oral mandatory fields');
            // this.myTabs.select(2);
            check_flag = false;
          }
        }
        //extra oral
        if (check_flag == true && (den_spl["extra_oral_con_note"] != undefined || den_spl["extra_oral_jaw_note"] != undefined)) {

          if ((den_spl["extra_oral_con_note"] != undefined && den_spl["extra_oral_con_note"] == "") || (den_spl["extra_oral_jaw_note"] != undefined && den_spl["extra_oral_jaw_note"] == "")) {
            //  this.loader = true; 
            this.toastr.error('Enter extra oral mandatory fields');
            //this.myTabs.select(2);
            check_flag = false;

          }
        }
        //ortho
        if (check_flag == true && (den_spl["overbite"] != undefined && den_spl["overbite"] != "") || (den_spl["crossbite"] != undefined && den_spl["crossbite"] != "") ||
          (den_spl["pri_mol_rel_rt"] != undefined && den_spl["pri_mol_rel_rt"] != "") || (den_spl["pri_mol_rel_lt"] != undefined && den_spl["pri_mol_rel_lt"] != "") || (den_spl["overbite"] != undefined && den_spl["overbite"] != "")
          || (den_spl["openbite"] != undefined && den_spl["openbite"] != "") || (den_spl["overjet"] != undefined && den_spl["overjet"] != "") ||
          (den_spl["pre_mol_rel_rt"] != undefined && den_spl["pre_mol_rel_rt"] != "") || (den_spl["pre_mol_rel_lt"] != undefined && den_spl["pre_mol_rel_lt"] != "") ||
          (den_spl["sup_num_teeth"] != undefined && den_spl["sup_num_teeth"] != "") || (den_spl["ectopic_eruption"] != undefined && den_spl["ectopic_eruption"] != "") ||
          (den_spl["rotation"] != undefined && den_spl["rotation"] != "") || (den_spl["crowding_spacing"] != undefined && den_spl["crowding_spacing"] != "") ||
          (den_spl["midline"] != undefined && den_spl["midline"] != "") || (den_spl["arc_len_maxi"] != undefined && den_spl["arc_len_maxi"] != "") ||
          (den_spl["arc_len_mandi"] != undefined && den_spl["arc_len_mandi"] != "") || den_spl["analysis_required"] == "yes") {


          if ((den_spl["overbite"] == undefined || den_spl["crossbite"] == undefined || den_spl["openbite"] == undefined ||
            den_spl["overjet"] == undefined || den_spl["pri_mol_rel_rt"] == undefined || den_spl["pri_mol_rel_lt"] == undefined ||
            den_spl["pre_mol_rel_rt"] == undefined || den_spl["pre_mol_rel_lt"] == undefined || den_spl["ectopic_eruption"] == undefined)) {
            // this.loader = true;
            this.toastr.error('Enter orthodontic evaluation mandatory fields');
            //  this.myTabs.select(2);
            check_flag = false;
          }
        }
        //dental
        if (check_flag == true && ((dentalcomp["child_first_visit"] == "yes" || (dentalcomp["last_visit"] != undefined && dentalcomp["last_visit"] != "") || dentalcomp["visit_exp"] != undefined
          || (dentalcomp["prev_treatment"] != undefined && dentalcomp["prev_treatment"] != "") || (dentalcomp["behaviour_visit"] != undefined && dentalcomp["behaviour_visit"] != "")
          || (dentalcomp["hyg_mode_clean"] != undefined && dentalcomp["hyg_mode_clean"] != "") || (dentalcomp["hyg_sup_bru"] != undefined && dentalcomp["hyg_sup_bru"] != "")
          || (dentalcomp["hyg_freq_bru"] != undefined && dentalcomp["hyg_freq_bru"] != "") || (dentalcomp["hyg_other_aids"] != undefined && dentalcomp["hyg_other_aids"] != "")
          || (dentalcomp["ple_sup_desc"] != undefined && dentalcomp["ple_sup_desc"] != "") || (dentalcomp["interpretation"] != undefined && dentalcomp["interpretation"] != "") || (dentalcomp["sweet_score"] != undefined && dentalcomp["sweet_score"] != "")))) {

          if (dentalcomp["last_visit"] == undefined || dentalcomp["visit_exp"] == undefined || dentalcomp["prev_treatment"] == undefined || dentalcomp["behaviour_visit"] == undefined || dentalcomp["ple_sup_desc"] == undefined
            || dentalcomp["diet_analysis"] == undefined) {
            //  this.loader = true;
            this.toastr.error('Enter dental history mandatory fields');
            // this.myTabs.select(2);
            check_flag = false;
          }

        }
        //birth
        if (check_flag == true && ((birthcomp["breast_fed_dur"] != undefined && birthcomp["breast_fed_dur"] != null && birthcomp["breast_fed_dur"] != "") || (birthcomp["breast_fed_freq"] != undefined && birthcomp["breast_fed_freq"] != "") || (birthcomp["bottle_fed_dur"] != undefined && birthcomp["bottle_fed_dur"] != "") || (birthcomp["bottle_fed_freq"] != undefined && birthcomp["bottle_fed_freq"] != "") || (birthcomp["weaned_out"] != undefined && birthcomp["weaned_out"] != "") || birthcomp["child_hood"] != undefined || birthcomp["type_nipple"] != undefined ||
          (birthcomp["gestation_duration"] != undefined && birthcomp["gestation_duration"] != "") || (birthcomp["mot_age_deli"] != undefined && birthcomp["mot_age_deli"] != "") ||
          (birthcomp["parity_times"] != undefined && birthcomp["parity_times"] != "") || (birthcomp["maternal_insults"] != undefined && birthcomp["maternal_insults"] != "") || birthcomp["where_born"] != undefined ||
          (birthcomp["diffi_deliv"] != undefined && birthcomp["diffi_deliv"] != "") || (birthcomp["resuscitation"] != undefined && birthcomp["resuscitation"] != "") ||
          (birthcomp["int_care_req"] != undefined && birthcomp["int_care_req"] != "") || (birthcomp["diseases"] != undefined && birthcomp["diseases"] != "") ||
          (birthcomp["apgar_score"] != undefined && birthcomp["apgar_score"] != "") || (birthcomp["baby_fed"] != undefined && birthcomp["baby_fed"] != "") ||
          (birthcomp["reason"] != undefined && birthcomp["reason"] != ""))) {

          if (birthcomp["breast_fed_dur"] == undefined || birthcomp["breast_fed_dur"] == "" || birthcomp["breast_fed_freq"] == undefined || birthcomp["breast_fed_freq"] == ""
            || birthcomp["bottle_fed_dur"] == undefined || birthcomp["bottle_fed_dur"] == "" || birthcomp["bottle_fed_freq"] == undefined || birthcomp["bottle_fed_freq"] == "" || birthcomp["type_nipple"] == undefined
            || birthcomp["gestation_duration"] == undefined || birthcomp["mot_age_deli"] == undefined || birthcomp["parity_times"] == undefined
            || birthcomp["where_born"] == undefined || birthcomp["apgar_score"] == undefined || birthcomp["reason"] == undefined) {
            // this.loader = true;
            this.toastr.error('Enter birth history mandatory fields');
            // this.myTabs.select(2);
            check_flag = false;
          }
        }
        // natal
        if (check_flag == true && (natalcomp["narrator"] != undefined || natalcomp["type"] != undefined || natalcomp["infe_moth_preg"] != undefined || natalcomp["gestation_peroid"] != undefined || (natalcomp["health_moth_preg"] != undefined && natalcomp["health_moth_preg"] != undefined) || (natalcomp["drug_moth_preg"] != undefined && natalcomp["drug_moth_preg"] != ""))) {

          if (natalcomp["type"] == undefined || natalcomp["health_moth_preg"] == undefined) {

            //   this.loader = true; 
            this.toastr.error('Enter natal history mandatory fields');
            //  this.myTabs.select(2);
            check_flag = false;
          }
        }




      }

      else if (spl_name == "Prosthodontics") {
        //intra oral
        if (check_flag == true && (den_spl["mouth"] != undefined || den_spl["labial_mucosa"] != undefined ||
          den_spl["palatal"] != undefined || den_spl["buccal_mucosa"] != undefined || den_spl["tongue"] != undefined ||
          den_spl["periodintium"] != undefined || den_spl["alveolar_mucosa"] != undefined || den_spl["floor_mouth"] != undefined)) {

          if ((den_spl["mouth"] != undefined && den_spl["mouth"] == "") || (den_spl["labial_mucosa"] != undefined && den_spl["labial_mucosa"] == "") ||
            (den_spl["palatal"] != undefined && den_spl["palatal"] == "") || (den_spl["buccal_mucosa"] != undefined && den_spl["buccal_mucosa"] == "") || (den_spl["tongue"] != undefined && den_spl["tongue"] == "") ||
            (den_spl["periodintium"] != undefined && den_spl["periodintium"] == "") || (den_spl["alveolar_mucosa"] != undefined && den_spl["alveolar_mucosa"] == "") || (den_spl["floor_mouth"] != undefined && den_spl["floor_mouth"] == "")) {

            // this.loader = true;
            this.toastr.error('Enter intra oral mandatory fields');
            // this.myTabs.select(2);
            check_flag = false;
          }

        }
        if (check_flag == true && ((den_spl["max_mouth_open"] != undefined && den_spl["max_mouth_open"] != "") || den_spl["oral_hygeine"] != undefined ||
          den_spl["den_ging_colour"] != undefined || den_spl["gingival_texture"] != undefined || den_spl["palatal_contour"] != undefined ||
          den_spl["oral_muscle_tone"] != undefined || (den_spl["tonsils_adenoids"] != undefined && den_spl["tonsils_adenoids"] != "") || (den_spl["oral_mucosa"] != undefined && den_spl["oral_mucosa"] != ""))) {

          if (den_spl["oral_mucosa"] == undefined || den_spl["tonsils_adenoids"] == undefined || den_spl["oral_muscle_tone"] == undefined || den_spl["palatal_contour"] == undefined || den_spl["gingival_texture"] == undefined || den_spl["oral_hygeine"] == undefined || den_spl["max_mouth_open"] == undefined || den_spl["den_ging_colour"] == undefined) {
            //  this.loader = true; 
            this.toastr.error('Enter intra oral mandatory fields');
            // this.myTabs.select(2);
            check_flag = false;

          }
        }

        //extra oral
        if (check_flag == true && (den_spl["extra_oral_con_note"] != undefined || den_spl["extra_oral_jaw_note"] != undefined)) {

          if ((den_spl["extra_oral_con_note"] != undefined && den_spl["extra_oral_con_note"] == "") || (den_spl["extra_oral_jaw_note"] != undefined && den_spl["extra_oral_jaw_note"] == "")) {
            // this.loader = true;
            this.toastr.error('Enter extra oral mandatory fields');
            //  this.myTabs.select(2);
            check_flag = false;

          }
        }
        if (check_flag == true && ((den_spl["cephalic_index"] != undefined && den_spl["cephalic_index"] != "") || (den_spl["facial_index"] != undefined && den_spl["facial_index"] != "") ||
          (den_spl["skeletal_relation"] != undefined && den_spl["skeletal_relation"] != "") || (den_spl["inter_labial_gap"] != undefined && den_spl["inter_labial_gap"] != "") ||
          den_spl["den_facial_divg_id"] != undefined || den_spl["den_lip_post_id"] != undefined || den_spl["lip_pos_incomp"] != undefined || den_spl["clinical_fma"] != undefined
          || den_spl["upper_lip"] != undefined || den_spl["lower_lip"] != undefined || den_spl["den_chin_posi_id"] != undefined || den_spl["den_naso_angle_id"] != undefined
          || den_spl["den_ment_sulcus_id"] != undefined || den_spl["den_facial_profile_id"] != undefined || den_spl["den_head_shape_id"] != undefined || den_spl["den_facial_form_id"] != undefined ||
          den_spl["den_face_sym_id"] != undefined || den_spl["lip_txt"] != undefined)) {
          if (check_flag == true && (den_spl["cephalic_index"] == undefined || den_spl["facial_index"] == undefined || den_spl["den_facial_divg_id"] == undefined ||
            den_spl["den_lip_post_id"] == undefined || den_spl["lip_pos_incomp"] == undefined || den_spl["clinical_fma"] == undefined ||
            den_spl["upper_lip"] == undefined || den_spl["lower_lip"] == undefined || den_spl["den_chin_posi_id"] == undefined || den_spl["den_naso_angle_id"] == undefined ||
            den_spl["den_ment_sulcus_id"] == undefined || den_spl["inter_labial_gap"] == undefined || den_spl["den_facial_profile_id"] == undefined || den_spl["den_head_shape_id"] == undefined ||
            den_spl["den_facial_form_id"] == undefined || den_spl["skeletal_relation"] == undefined || den_spl["den_face_sym_id"] == undefined || den_spl["lip_txt"] == undefined)) {
            // this.loader = true;
            this.toastr.error('Enter extra oral mandatory fields');
            //  this.myTabs.select(2);
            check_flag = false;
          }
        }

        //functional 2
        if (check_flag == true && (den_spl["respiration"] != undefined || den_spl["path_closure"] != undefined || den_spl["deglutition"] != undefined ||
          den_spl["perioral_mus_act"] != undefined || (den_spl["fun_speech"] != undefined && den_spl["fun_speech"] != "") || den_spl["TMJ_option"] == "yes" || den_spl["pain_condition"] == "yes" || den_spl["sounds_cond"] == "yes" || den_spl["tend_palp_cond"] == "yes" || den_spl["muscle_tend_palp"] == "yes" ||
          (den_spl["mastication"] != undefined && den_spl["mastication"] != "") || (den_spl["inter_clearance"] != undefined && den_spl["inter_clearance"] != "") || (den_spl["expo_speech"] != undefined && den_spl["expo_speech"] != "") || (den_spl["expo_smile"] != undefined && den_spl["expo_smile"] != "") || (den_spl["mandi_mt_open"] != undefined && den_spl["mandi_mt_open"] != "") || (den_spl["mandi_mt_pro"] != undefined && den_spl["mandi_mt_pro"] != "") || (den_spl["mandi_mt_rt_excur"] != undefined && den_spl["mandi_mt_rt_excur"] != "") || (den_spl["mandi_mt_lt_excur"] != undefined && den_spl["mandi_mt_lt_excur"] != ""))) {

          if ((den_spl["muscle_tend_palp"] == "yes" && den_spl["tend_palp_desc"] == undefined) || (den_spl["tend_palp_cond"] == "yes" && (den_spl["tend_palp_rt"] == undefined || den_spl["tend_palp_lt"] == undefined)) || (den_spl["sounds_cond"] == "yes" && den_spl["sounds_dur"] == undefined) || (den_spl["pain_condition"] == "yes" && den_spl["pain_dur"] == undefined) || (den_spl["TMJ_option"] == "yes" && (den_spl["TMJ_desc"] == undefined || den_spl["TMJ_desc"] == "")) || den_spl["respiration"] == undefined || den_spl["path_closure"] == undefined || den_spl["deglutition"] == undefined || den_spl["perioral_mus_act"] == undefined || den_spl["fun_speech"] == undefined || den_spl["fun_speech"] == "" || den_spl["mastication"] == undefined || den_spl["mastication"] == "" || den_spl["inter_clearance"] == undefined || den_spl["inter_clearance"] == "" || den_spl["expo_speech"] == undefined || den_spl["expo_speech"] == "" || den_spl["expo_smile"] == undefined || den_spl["expo_smile"] == "" || den_spl["mandi_mt_open"] == undefined || den_spl["mandi_mt_open"] == "" || den_spl["mandi_mt_pro"] == undefined || den_spl["mandi_mt_pro"] == "" || den_spl["mandi_mt_rt_excur"] == undefined || den_spl["mandi_mt_rt_excur"] == "" || den_spl["mandi_mt_lt_excur"] == undefined || den_spl["mandi_mt_lt_excur"] == "") {
            // this.loader = true;
            this.toastr.error('Enter functional mandatory fields');
            //this.myTabs.select(2);
            check_flag = false;
          }
        }

      }
      else if (spl_name == "Public health") {
        //intra oral
        if (check_flag == true && (den_spl["mouth"] != undefined || den_spl["labial_mucosa"] != undefined ||
          den_spl["palatal"] != undefined || den_spl["buccal_mucosa"] != undefined || den_spl["tongue"] != undefined ||
          den_spl["periodintium"] != undefined || den_spl["alveolar_mucosa"] != undefined || den_spl["floor_mouth"] != undefined || den_spl["floor_mouth"] != undefined || den_spl["ped_tonsils"] != undefined)) {

          if ((den_spl["mouth"] != undefined && den_spl["mouth"] == "") || (den_spl["labial_mucosa"] != undefined && den_spl["labial_mucosa"] == "") ||
            (den_spl["palatal"] != undefined && den_spl["palatal"] == "") || (den_spl["buccal_mucosa"] != undefined && den_spl["buccal_mucosa"] == "") || (den_spl["tongue"] != undefined && den_spl["tongue"] == "") ||
            (den_spl["periodintium"] != undefined && den_spl["periodintium"] == "") || (den_spl["alveolar_mucosa"] != undefined && den_spl["alveolar_mucosa"] == "") || (den_spl["floor_mouth"] != undefined && den_spl["floor_mouth"] == "") || den_spl["ped_tonsils"] == undefined || den_spl["ped_tonsils"] == "") {
            // this.loader = true;
            this.toastr.error('Enter intra oral mandatory fields');
            //  this.myTabs.select(2);
            check_flag = false;
          }
        }
        //extraoral
        if (check_flag == true && (den_spl["extra_oral_con_note"] != "" || den_spl["extra_oral_jaw_note"] != "" || den_spl["TMJ_exam"] == undefined || den_spl["TMJ_exam"] == "")) {

          if ((den_spl["extra_oral_con_note"] != undefined && den_spl["extra_oral_con_note"] == "") || (den_spl["extra_oral_jaw_note"] != undefined && den_spl["extra_oral_jaw_note"] == "") || den_spl["TMJ_exam"] == undefined || den_spl["TMJ_exam"] == "") {
            // this.loader = true;
            this.toastr.error('Enter extra oral mandatory fields');
            // this.myTabs.select(2);
            check_flag = false;

          }
        }
        if (check_flag == true && (den_spl["den_face_sym_id"] != undefined || den_spl["lip_txt"] != undefined || (den_spl["oralym"] != undefined && den_spl["oralym"] != "") || (den_spl["pharynx_tonsils"] != undefined && den_spl["pharynx_tonsils"] != ""))) {
          if (check_flag == true && (den_spl["den_face_sym_id"] == undefined || den_spl["lip_txt"] == undefined || (den_spl["oralym"] != undefined && den_spl["oralym"] == "") || (den_spl["pharynx_tonsils"] != undefined && den_spl["pharynx_tonsils"] == ""))) {
            //  this.loader = true;
            this.toastr.error('Enter extra oral mandatory fields');
            //  this.myTabs.select(2);
            check_flag = false;

          }
        }
        //gums& teeth
        if (check_flag == true && ((gumscomp["position"] != undefined && gumscomp["position"] != "") || (gumscomp["ped_pocket"] != undefined && gumscomp["ped_pocket"] != "")
          || (gumscomp["exudate"] != undefined && gumscomp["exudate"] != "") || (gumscomp["affecting_colour"] != undefined && gumscomp["affecting_colour"] != "")
          || (gumscomp["consistency"] != undefined && gumscomp["consistency"] != "") || (gumscomp["contour"] != undefined && gumscomp["contour"] != "")
          || (gumscomp["ging_other"] != undefined && gumscomp["ging_other"] != "") || (gumscomp["prosthesis"] != undefined && gumscomp["prosthesis"] != "")
          || (gumscomp["fracture_teeth"] != undefined && gumscomp["fracture_teeth"] != "") || (gumscomp["reason_loss"] != undefined && gumscomp["reason_loss"] != "")
          || (gumscomp["occlusion"] != undefined && gumscomp["occlusion"] != ""))) {

          if (gumscomp["prosthesis"] == undefined || gumscomp["prosthesis"] == "") {
            // this.loader = true;
            this.toastr.error('Enter gums & teeth mandatory fields');
            //  this.myTabs.select(2);
            check_flag = false;
          }
        }
        //dental
        if (check_flag == true && ((dentalcomp["sweet_score"] != undefined && dentalcomp["sweet_score"] != "") || (dentalcomp["interpretation"] != undefined && dentalcomp["interpretation"] != ""))) {
          if (dentalcomp["diet_analysis"] == undefined || dentalcomp["diet_analysis"] == "") {

            this.toastr.error('Enter dental history mandatory fields');

            check_flag = false;
          }
        }
      }
    }

    if (get_basic_data == undefined && check_flag == true && invset_plan != undefined && invset_plan["investigation"] == undefined) {

      this.toastr.error('Enter investigation');

      check_flag = false;
    }

    if (material == undefined) {
      material = [];
    }

    if (perio_chart_data == undefined) {
      perio_chart_data = [];
    }

    var finalObj = $.extend(true, personal_hist, clinical_exam, this.medicalprescription, this.diagnosisprescription);
    var object1 = $.extend(true, finalObj, birthcomp);

    var object2 = $.extend(true, object1, generalcomp);

    var object3 = $.extend(true, object2, dentalcomp);

    var object4 = $.extend(true, object3, gumscomp);

    var object5 = $.extend(true, object4, natalcomp);

    var finalObj1 = $.extend(true, object5, den_spl);

    var finalObj2 = $.extend(true, finalObj1, invset_plan);
    var finalObj3 = $.extend(true, finalObj2, finalObj3);
    var finalObj4 = $.extend(true, finalObj3, material);
    var finalObj5 = $.extend(true, finalObj4, perio_chart_data);


    var final_data = null;
    if (assign_data != undefined) {
      final_data = $.extend(true, finalObj5, assign_data);

    } else {
      final_data = finalObj5;
    }


    if (get_basic_data != undefined) {

      // this.loader = true;
      var send_url = "dental/savedendet";

      if (personal_hist["case_hist_id"] != undefined) {
        send_url = "dental/upddendet";
      }

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + send_url, JSON.stringify(get_basic_data),
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();

            if (obj["key"] != null && obj["key"] == "1") {
              this.save_flag = false;
              if (get_basic_data.case_hist_id != undefined && get_basic_data.case_hist_id != null) {
                this.toastr.success("Case history update successfully");
              } else {
                this.toastr.success("Case history saved successfully");
              }
              if (Helper_Class.getInpatientFlow() == "nurse") {
                this.nurseservice.sendMessage("viewIpatient");
              } else {
                this.doctorviewservice.sendMessage("appointment_list");
              }
            } else {
              this.toastr.error("Case history not saved, please try again later");
            }
          },
          error => {
            //this.toastr.error("Internet is too slow or no connection, try again later");
          }
        )
    } else if (check_flag == true) {
      var send_url = "dental/savedendet";
      if (personal_hist["case_hist_id"] != undefined) {
        send_url = "dental/upddendet";
      }


      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + send_url, JSON.stringify(final_data),
        { headers: headers })
        .subscribe(
          response => {

            var obj = response.json();
            if (obj["key"] != null && obj["key"] == "1") {
              this.save_flag = false;
              if (personal_hist.case_hist_id != undefined && personal_hist.case_hist_id != null) {
                this.toastr.success("Case history update successfully");
              } else {
                this.toastr.success("Case history saved successfully");
              }

              if (Helper_Class.getInpatientFlow() == "nurse") {
                this.nurseservice.sendMessage("viewIpatient");
              } else {
                this.doctorviewservice.sendMessage("appointment_list");
              }
            } else {
              this.toastr.error("Case history not saved, please try again later");
            }
          },
          error => {
            //this.toastr.error("Internet is too slow or no connection, try again later");
          }
        )
    }
  }

  //general or general surgery
  save_general() {
    this.send_arr = null;
    var url = null
    this.personal_flag = true;
    this.clinical_flag = true;
    this.diagnosis_flag = true;
    var vitals;
    var vitals_flag: boolean = true;

    this.personal = Helper_Class.getPersonalHist();
    // this.family = {
    //   family_history: Helper_Class.getDiabFam(),
    // };
    if(Helper_Class.getDiabFam() != null){
      this.family = {
        family_history: Helper_Class.getDiabFam(),
      }
    }else{
      // this.cardio_family = {
      //   family_history: Helper_Class.getDiabFam(),
      // }
    }
    this.cardio_habitual = Helper_Class.gethabitual();
    this.medicalprescription = Helper_Class.get_med_pres_id();
    this.diagnosisprescription = Helper_Class.get_diag_pres_id();
    this.surgery = Helper_Class.getSurgicalHist();
    this.diagnosis = Helper_Class.getDiabTreat();
    var get_sga_data = Helper_Class.getSga();
    var get_diet_data = Helper_Class.getDiet();
    var getSugeryHelperclass = Doc_Helper.getSugeryDetails();
    vitals = Doc_Helper.getVitals();

    if (getSugeryHelperclass != undefined) {
      var getSureryDetails = {
        surgery_image: getSugeryHelperclass.surgery_image,
        surgery_inv: getSugeryHelperclass.surgery_inv,
        surgery_proc: getSugeryHelperclass.surgery_proc,
        surgery: getSugeryHelperclass.surgery,
        surgery_type: getSugeryHelperclass.surgery_type,
        updateImage: getSugeryHelperclass.updateImage
      }
    }

    var takingmed = undefined;
    var sfactor = undefined;
    var hdisorder = undefined;
    var rehabval = undefined;

    for (var key in this.personal) {
      if (key == "taking_med" && this.personal[key] != undefined) {
        takingmed = this.personal[key];
      }

      if (key == "case_hist_id" && this.personal[key] != undefined) {
        url = "1";

      } else if (key == "current_occupation" && (this.personal[key] == undefined || this.personal[key] == null)) {
        this.personal_flag = false;

      } else if (key == "age" && (this.personal[key] == undefined || this.personal[key] == "")) {
        this.personal_flag = false;

      } else if (takingmed != undefined && takingmed == "Yes") {
        if (key == "med_det" && this.personal[key] == undefined) {
          this.personal_flag = false;
        }

      }
    }

    for (var key in vitals) {
      if (key == "height" && (vitals[key] == undefined || vitals[key] == "")) {
        vitals_flag = false;

      } else if (key == "weight" && (vitals[key] == undefined || vitals[key] == "")) {
        vitals_flag = false;
      }
    }

    if (this.speclization_arry == "General surgery") {
      if (this.surgery != 'undefined') {
        for (var key in this.surgery) {
          if (key == "surgery") {
            sfactor = this.surgery[key];
          }

          if (key == "rehab") {
            rehabval = this.surgery[key];
          }

          if (key == "surgery" && this.surgery[key] == undefined) {
            this.clinical_flag = false;

          } else if (sfactor == "Yes") {
            if (key == "surg_proc" && (this.surgery[key] == undefined || this.surgery[key] == "")) {
              this.clinical_flag = false;
            }

          } else if (key == "rehab" && this.surgery[key] == undefined) {
            this.clinical_flag = false;

          } else if (rehabval == "Yes") {
            if (key == "rehab_proc" && this.surgery[key] == undefined) {
              this.clinical_flag = false;
            }
          }
        }

      } else {
        this.clinical_flag = false;
      }

      if (this.diagnosis != undefined) {
        if (this.diagnosis["disease"] == undefined) {
          this.diagnosis_flag = false;

        } else if (this.diagnosis["recom_exec_id"] == undefined) {
          this.diagnosis_flag = false;

        } else if (this.diagnosis["recom_exec_dur"] == undefined) {
          this.diagnosis_flag = false;

        } else if (this.diagnosis["recom_exec_freq"] == undefined) {
          this.diagnosis_flag = false;

        } else if (this.diagnosis["prognosis"] == undefined) {
          this.diagnosis_flag = false;

        } else {
          this.diagnosis_flag = true;
        }
      }
    }

    if (this.personal_flag == false) {
      this.toastr.error("Please enter Personal card  mandatory fields");
    } else if (this.clinical_flag == false) {
      this.toastr.error("Please enter Surgery card mandatory fields");
    } else if (this.diagnosis_flag == false) {
      this.toastr.error("Please enter Diagnosis mandatory fields");
    } else if (vitals_flag == false) {
      this.toastr.error("Please enter Vitals mandatory fields");
    }
    if(this.family != undefined){
      var person_family = $.extend(true, this.personal, this.family, this.medicalprescription, this.diagnosisprescription, vitals);
    }else{       
     // alert("2")
      var person_family = $.extend(true, this.personal, this.medicalprescription, this.diagnosisprescription, vitals);
    }
    var habitualdata = $.extend(true, this.cardio_habitual, person_family)
    var per_fam_clinical = $.extend(true, habitualdata, this.surgery, getSureryDetails);
    var final_array = $.extend(true, per_fam_clinical, this.diagnosis);

    if (url != null) {
      url = ipaddress.getIp.toString() + 'gen/ucasehist';

    } else {
      url = ipaddress.getIp.toString() + 'gen/scasehist';
    }

    var diet_plan_url = "lifestyle/sdplan";

    for (var key in get_diet_data) {
      if (key == "dplan_morning") {
        this.diet_mrng_arry = get_diet_data[key];
      }

      if (key == "dplan_breakfast") {
        this.break_fast_arrydata = get_diet_data[key]
      }

      if (key == "dplan_midday") {
        this.mid_day_arrydata = get_diet_data[key];
      }

      if (key == "dplan_lunch") {
        this.lunch_arrydata = get_diet_data[key];
      }

      if (key == "dplan_evening") {
        this.evening_arrydata = get_diet_data[key];
      }

      if (key == "dplan_dinner") {
        this.dinner_arrydata = get_diet_data[key];
      }

      if (key == "dplan_night") {
        this.night_arrydata = get_diet_data[key];
      }

      if (key == "diet_plan" && get_diet_data[key] == "1") {
        diet_plan_url = "lifestyle/updplan";
      }
    }

    if (this.personal_flag == true && this.clinical_flag == true && this.diagnosis_flag == true) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(url, final_array,
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            if (obj.key != null && obj.key == "1") {
              Helper_Class.setPersonalHist(null);
              Helper_Class.setDiabFam(null);
              Helper_Class.setClinicalHist(null);
              Helper_Class.setDiabTreat(null);

              var dietplan = null;
              if (get_diet_data != undefined) {
                if (get_sga_data != undefined) {
                  dietplan = $.extend(true, get_sga_data, get_diet_data);

                } else {
                  dietplan = get_diet_data;
                }

                var headers = new Headers();
                headers.append('Content-Type', 'application/json');
                this.http.post(ipaddress.getIp.toString() + diet_plan_url, dietplan,
                  { headers: headers })
                  .subscribe(
                    response => {
                      var diet_obj = response.json();
                      this.save_flag = false;
                      if (this.personal.case_hist_id != undefined && this.personal.case_hist_id != null) {
                        this.toastr.success("Case history update successfully");
                      } else {
                        this.toastr.success("Case history saved successfully");
                      }

                      if (Helper_Class.getInpatientFlow() == "nurse") {
                        this.nurseservice.sendMessage("viewIpatient");
                      } else {
                        this.doctorviewservice.sendMessage("appointment_list");
                      }
                      if (Doc_Helper.getAppFlow() != null && Doc_Helper.getAppFlow() == "normal") {
                        this.doctorviewservice.sendMessage("appointment_list");
                      } else if (Doc_Helper.getAppFlow() != null && Doc_Helper.getAppFlow() == "home_care") {
                        this.doctorviewservice.sendMessage("appointment_list");
                        localStorage.setItem("home_care", "1");
                      } else if (Doc_Helper.getAppFlow() != null && Doc_Helper.getAppFlow() == "case_sum") {
                        this.doctorviewservice.sendMessage("appointment_list");
                        localStorage.setItem("case_sum", "");
                      } else if (Doc_Helper.getAppFlow() != null && (Doc_Helper.getAppFlow() == "case_sheet" || Doc_Helper.getAppFlow() == "Inpatient")) {
                        this.doctorviewservice.sendMessage("appointment_list");
                        localStorage.setItem("case_sheet", "");
                      }
                    },
                    error => {
                      this.toastr.error("Unable to save case sheet, try again later");
                    }
                  )

              } else {
                this.save_flag = false;

                if (this.personal.case_hist_id != undefined && this.personal.case_hist_id != null) {
                  this.toastr.success("Case history update successfully");
                } else {
                  this.toastr.success("Case history saved successfully");
                }
                if (Helper_Class.getInpatientFlow() == "nurse") {
                  this.nurseservice.sendMessage("viewIpatient");
                } else if (Doc_Helper.getAppFlow() == "Inpatient") {
                  this.doctorviewservice.sendMessage("inPatientViewInfo");
                } else {
                  this.doctorviewservice.sendMessage("appointment_list");
                }
              }

            } else {
              this.toastr.error("Case history not saved, please try again later");
            }
          });
    }
  }

  //gynaecology
  save_gyno() {
    this.send_arr = null;
    var vitals;
    var vitals_flag: boolean = true;

    var chk_flag = true, url = null, sga_score = null, diet_mrng_arry = null, break_fast_arrydata = null, mid_day_arrydata = null,
      lunch_arrydata = null, evening_arrydata = null, dinner_arrydata = null, night_arrydata = null,
      send_foot = null, getfoot_ass = null, foot_ass = true, diet_flag = true

    this.gyna_personal_array = Helper_Class.getPersonalHist();
    console.log("check --"+JSON.stringify(this.gyna_personal_array))
    this.gyna_surgical_array = Helper_Class.getgynasurgical();
    this.gyna_family_array = {
      family_history: Helper_Class.getDiabFam(),
    };
    console.log("gyna_family_array --"+JSON.stringify(this.gyna_surgical_array))

    console.log("gyna_family_array --"+JSON.stringify(this.gyna_family_array))

    this.gyna_obestric_array = Helper_Class.getobestric();
    console.log("getobestric --"+JSON.stringify(this.gyna_obestric_array))
    this.gyna_clinic_array = Helper_Class.getclinicexam();
    console.log("getobestric -getclinicexam-"+JSON.stringify(this.gyna_clinic_array))

    this.gyna_diagnosis_array = Helper_Class.getdiagoutcome();
    console.log("getobestric --"+JSON.stringify(this.gyna_diagnosis_array))

    this.gyna_treatement_array = Helper_Class.getDiabTreat();
   console.log("getobestric --"+JSON.stringify(this.gyna_treatement_array))

    this.medicalprescription = Helper_Class.get_med_pres_id();
    this.diagnosisprescription = Helper_Class.get_diag_pres_id();
    vitals = Doc_Helper.getVitals();

    var get_sga_data = Helper_Class.getSga();
    var get_diet_data = Helper_Class.getDiet();
    console.log("get_diet_dataget_diet_data --"+JSON.stringify(get_diet_data))

    var diet_plan_url = ipaddress.getIp.toString() + "lifestyle/sdplan";
    for (var key in get_diet_data) {
      if (key == "overall_rating") {
        sga_score = get_diet_data[key];
      }
      if (key == "dplan_morning") {
        diet_mrng_arry = get_diet_data[key];
      }
      if (key == "dplan_breakfast") {
        break_fast_arrydata = get_diet_data[key];
      }
      if (key == "dplan_midday") {
        mid_day_arrydata = get_diet_data[key];
      }
      if (key == "dplan_lunch") {
        lunch_arrydata = get_diet_data[key];
      }
      if (key == "dplan_evening") {
        evening_arrydata = get_diet_data[key];
      }
      if (key == "dplan_dinner") {
        dinner_arrydata = get_diet_data[key];
      }
      if (key == "dplan_night") {
        night_arrydata = get_diet_data[key];
      }
      if (key == "diet_plan" && get_diet_data[key] == "1") {
        diet_plan_url = ipaddress.getIp.toString() + "lifestyle/updplan";
      }
    }

    //read key
    if (this.gyna_personal_array["case_hist_id"] != undefined) {
      url = "1";
    }

    if (this.gyna_personal_array["marital_status"] == undefined) {
      chk_flag = false;
      this.toastr.error("Please enter mandatory fields");
    }

    if (chk_flag == true && this.gyna_personal_array["current_occupation"] == undefined) {
      chk_flag = false;
      this.toastr.error("Please enter 'current_occupation' mandatory fields");
    }

    if (chk_flag == true && this.gyna_personal_array["stress_factor"] == undefined) {
      chk_flag = false;
      this.toastr.error("Please enter 'stress_factor' mandatory fields");
    }

    // if (chk_flag == true && this.gyna_personal_array["main_concern"] == undefined) {
    //   chk_flag = false;
    //   this.toastr.error("Please enter 'main_concern' mandatory fields");
    // }

    if (chk_flag == true && this.gyna_fields == "Gynaecology") {
      // if (chk_flag == true && this.gyna_personal_array["present_illness"].length == 0) {
      //   this.toastr.error("Please enter 'present_illness' mandatory fields");
      //   chk_flag = false;
      // }

      if (chk_flag == true) {
        if (this.gyna_personal_array["gync_last_mens_period"] == undefined) {
          this.toastr.error("Please enter 'gync_last_mens_period' mandatory fields");
          chk_flag = false;
        }
      }

      if (chk_flag == true && this.gyna_personal_array["mens_stage"] == "Perimenopause") {
        if (this.gyna_personal_array["stage_date"] == undefined) {
          this.toastr.error("Please enter 'stage_date' mandatory fields");
          chk_flag = false;
        }
      }

      if (chk_flag == true && this.gyna_personal_array["mens_pain"] == "Yes") {
        if (this.gyna_personal_array["pain_timing"] == undefined) {
          this.toastr.error("Please enter 'pain_timing' mandatory fields");
          chk_flag = false;
        }

        if (chk_flag == true && this.gyna_personal_array["pain_character"] == undefined) {
          this.toastr.error("Please enter 'pain_character' mandatory fields");
          chk_flag = false;
        }
      }

      if (chk_flag == true && this.gyna_personal_array["menorragia"] == "Yes") {
        if (chk_flag == true && this.gyna_personal_array["no_of_days"] == undefined) {
          this.toastr.error("Please enter 'no_of_days' mandatory fields");
          chk_flag = false;
        }

        if (chk_flag == true && this.gyna_personal_array["pain_frequency"] == undefined) {
          this.toastr.error("Please enter 'pain_frequency' mandatory fields");
          chk_flag = false;
        }
      }

      if (chk_flag == true && this.gyna_personal_array["status"] != "None" && this.gyna_personal_array["status"] != undefined) {
        if (chk_flag == true && this.gyna_personal_array["contraceptive"] != "None") {
          if (chk_flag == true && this.gyna_personal_array["contraceptive_type"] == undefined) {
            this.toastr.error("Please enter 'contraceptive_type' mandatory fields");
            chk_flag = false;
          }
          if (chk_flag == true && this.gyna_personal_array["discont_reason"] == undefined) {
            this.toastr.error("Please enter 'discont_reason' mandatory fields");
            chk_flag = false;
          }
        }
      }
    } else {
      if (chk_flag == true && this.gyna_personal_array["last_mens_period"] == undefined) {
        chk_flag = false;
        this.toastr.error("Please enter 'last_mens_period' mandatory fields");
      }

      if (chk_flag == true && this.gyna_personal_array["pap_smear_date"] != undefined) {
        if (this.gyna_personal_array["pap_smear_result"] == undefined) {
          chk_flag = false;
          this.toastr.error("Please enter 'pap_smear_result' mandatory fields");
        }
      }
    }

    if (chk_flag == true && this.gyna_personal_array["drug_allergy"] == "Yes") {
      if (chk_flag == true && this.gyna_personal_array["drugs"] == undefined) {
        chk_flag = false;
        this.toastr.error("Please enter 'drugs' mandatory fields");
      }
    }

    if (chk_flag == true) {
      if (chk_flag == true && this.gyna_personal_array["skin"] == "Abnormal") {
        if (this.gyna_personal_array["skin_abnorm"] == undefined) {
          chk_flag = false;
          this.toastr.error("Please enter Skin abnormalities");
        }
      }

      if (chk_flag == true && this.gyna_clinic_array["pulse"] == undefined) {
        chk_flag = false;
        this.toastr.error("Please enter 'pulse' mandatory fields");
      }

      if (chk_flag == true && this.gyna_clinic_array["clinic_bp"] == undefined) {
        if (this.blood_preshidden == false) {
          chk_flag = false;
          this.toastr.error("Please enter 'clinic_bp' mandatory fields");
        }
      }

      if (chk_flag == true) {
        if (this.gyna_fields == "Obstetrics") {
          if (chk_flag == true && this.gyna_clinic_array["fetus_pres"] == undefined) {
            chk_flag = false;
            this.toastr.error("Please enter 'fetus_pres' mandatory fields");
          }

          if (chk_flag == true && this.gyna_clinic_array["emergency"] == "Yes") {
            if (this.gyna_clinic_array["emerg_type"] == undefined) {
              chk_flag = false;
              this.toastr.error("Please enter 'emerg_type' mandatory fields");
            }

            if (chk_flag == true && this.gyna_clinic_array["treat_type"] == undefined) {
              chk_flag = false;
              this.toastr.error("Please enter 'treat_type' mandatory fields");
            }
          }

          if (chk_flag == true && this.gyna_clinic_array["emerg_type"] == "Others") {
            if (this.gyna_clinic_array["emerg_others"] == undefined) {
              chk_flag = false;
              this.toastr.error("Please enter 'emerg_others' mandatory fields");
            }
          }

          if (chk_flag == true && this.gyna_clinic_array["labour_onset"] != undefined && this.gyna_clinic_array["labour_onset"] != "None") {
            if (chk_flag == true && this.gyna_clinic_array["baby_gender"] == undefined) {
              chk_flag = false;
              this.toastr.error("Please enter ' baby_gender' mandatory fields");
            }

            if (chk_flag == true && this.gyna_clinic_array["baby_weight"] == undefined) {
              chk_flag = false;
              this.toastr.error("Please enter 'baby_weight' mandatory fields");
            }

            if (chk_flag == true && this.gyna_clinic_array["contraceptive"] == undefined) {
              chk_flag = false;
              this.toastr.error("Please enter 'contraceptive' mandatory fields");
            }

            if (chk_flag == true && this.gyna_clinic_array["anaemic"] == undefined) {
              chk_flag = false;
              this.toastr.error("Please enter 'anaemic' mandatory fields");
            }

            if (chk_flag == true) {
              if (chk_flag == true && this.gyna_clinic_array["chest"] == "Abnormal") {
                if (chk_flag == true && this.gyna_clinic_array["chest_cond"] == undefined) {
                  chk_flag = false;
                  this.toastr.error("Please enter 'chest_cond' mandatory fields");
                }
              }

              if (chk_flag == true && this.gyna_clinic_array["breast_feeding"] == "Abnormal") {
                if (this.gyna_clinic_array["breast_feeding_cond"] == undefined) {
                  chk_flag = false;
                  this.toastr.error("Please enter 'breast_feeding_cond' mandatory fields");
                }
              }

              if (chk_flag == true && this.gyna_clinic_array["obs_breasts"] == "Abnormal") {
                if (this.gyna_clinic_array["breast_cond"] == undefined) {
                  chk_flag = false;
                  this.toastr.error("Please enter 'breast_cond' mandatory fields");
                }
              }

              if (chk_flag == true && this.gyna_clinic_array["puerperium"] == "No") {
                if (this.gyna_clinic_array["puerperium_cond"] == undefined) {
                  chk_flag = false;
                  this.toastr.error("Please enter 'puerperium_cond' mandatory fields");
                }
              }

              if (chk_flag == true && this.gyna_clinic_array["uterine_evolution"] == "Abnormal") {
                if (this.gyna_clinic_array["uterine_evolve_cond"] == undefined) {
                  chk_flag = false;
                  this.toastr.error("Please enter 'uterine_evolve_cond' mandatory fields");
                }
              }

              if (chk_flag == true && this.gyna_clinic_array["palpable_bladder"] == "Abnormal") {
                if (this.gyna_clinic_array["palpable_bladder_cond"] == undefined) {
                  chk_flag = false;
                  this.toastr.error("Please enter 'palpable_bladder_cond' mandatory fields");
                }
              }
            }
          }
        }
      }
    }

    if (chk_flag == true) {
      if (this.gyna_diagnosis_array["prognosis"] == undefined) {
        chk_flag = false;
        this.toastr.error("Please add prognosis");
      }
    }

    if (chk_flag == true) {
      if (chk_flag == true && this.gyna_diagnosis_array["disease"] == undefined) {
        chk_flag = false;
        this.toastr.error("Please enter 'disease' mandatory fields");
      }
    }

    if (chk_flag == true) {
      // if (chk_flag == true && this.gyna_treatement_array["recom_exec_id"] == undefined) {
      //   chk_flag = false;
      //   this.toastr.error("Please enter 'recom_exec_id' mandatory fields");
      // }
    }

    if (chk_flag == true) {
      if (url != null) {
        url = ipaddress.getIp.toString() + 'goi/ugoi';
      } else {
        url = ipaddress.getIp.toString() + 'goi/sgoi';
      }
      var finalObj = $.extend(true, this.gyna_personal_array, vitals, this.gyna_surgical_array, this.medicalprescription, this.diagnosisprescription);
      var finalObj1 = $.extend(true, finalObj, this.gyna_family_array);
      var finalObj2 = $.extend(true, finalObj1, this.gyna_obestric_array);
      var finalObj3 = $.extend(true, finalObj2, this.gyna_clinic_array);
      var finalObj4 = $.extend(true, finalObj3, this.gyna_treatement_array);

      var output = null;
      output = $.extend(finalObj4, this.gyna_diagnosis_array);

      for (var key in get_sga_data) {
        if (get_sga_data[key] == "Malnutritioned") {
          for (var key in get_diet_data) {
            if (diet_mrng_arry.length == 0 && break_fast_arrydata.length == 0 &&
              mid_day_arrydata.length == 0 && lunch_arrydata.length == 0 && evening_arrydata.length == 0 &&
              dinner_arrydata.length == 0 && night_arrydata.length == 0) {
              this.toastr.error("Please add diet plan");
              diet_flag = false;
            }
          }
        }
      }

      if (diet_flag == true) {
        console.log("getobestric --"+url+JSON.stringify(output))

        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(url, JSON.stringify(output),
          { headers: headers })
          .subscribe(
            response => {
              var obj = response.json();
              console.log("getobestric -obj-"+url+JSON.stringify(obj))

              if (obj["key"] != null && obj["key"] == "1") {
                Helper_Class.setDiabCompl(null);
                Helper_Class.setDiabFam(null);
                Helper_Class.setDiabFoot(null);
                Helper_Class.setPersonalHist(null);
                Helper_Class.setDiabTreat(null);

                // send dietplan data
                var finalObj = $.extend(true, get_sga_data, get_diet_data);

                var headers = new Headers();
                headers.append('Content-Type', 'application/json');
                this.http.post(diet_plan_url, finalObj,
                  { headers: headers })
                  .subscribe(
                    response => {
                      var diet_obj = response.json();
                      if (obj["key"] != null && obj["key"] == "1") {
                        this.save_flag = false;
                        if (this.gyna_personal_array.case_hist_id != undefined && this.gyna_personal_array.case_hist_id != null) {
                          this.toastr.success("Case history update successfully");
                        } else {
                          this.toastr.success("Case history saved successfully");
                        }

                        if (Helper_Class.getInpatientFlow() == "nurse") {
                          this.nurseservice.sendMessage("viewIpatient");
                        } else if (Doc_Helper.getAppFlow() == "Inpatient") {
                          this.doctorviewservice.sendMessage("inPatientViewInfo");
                        } else {
                          this.doctorviewservice.sendMessage("appointment_list");
                        }

                      } else {
                        this.toastr.error("Case history not saved, please try again later");
                      }
                    },
                    error => {
                      this.toastr.error("Something Went Wrong, Please Try Again Later");
                    }
                  )

              } else {
                this.toastr.error("Case history not saved, please try again later");
              }
            },
            error => {
              this.toastr.error("Something Went Wrong, Please Try Again Later");
            }
          )
      }
    }
  }

  save_ent() {
    this.send_arr = null;
    var url = null
    this.personal_flag = true;
    this.clinical_flag = true;
    this.diagnosis_flag = true;
    var vitals;
    var vitals_flag: boolean = true;

    this.ent_personal = Helper_Class.getPersonalHist();
    this.ent_family = {
      family_history: Helper_Class.getDiabFam(),
    };
    console.log("personal --******-"+JSON.stringify(Helper_Class.getPersonalHist()))
    console.log("personal --******-"+JSON.stringify(Helper_Class.getDiabFam()))
    this.cardio_habitual = Helper_Class.gethabitual();
    this.ent_clinical = Helper_Class.getEntClinical();
    this.ent_diagnosis = Helper_Class.getEntDiagnosis();
    var ent_treatment = Helper_Class.getDiabTreat();
    var get_sga_data = Helper_Class.getSga();
    var get_diet_data = Helper_Class.getDiet();
    vitals = Doc_Helper.getVitals();
    var takingmed = undefined;
    var sfactor = undefined;
    var hdisorder = undefined;
    var hospvisit = undefined;
    var murdet = undefined;
    var painfactor = undefined;
    var breathfactor = undefined;

    for (var key in this.ent_personal) {
      if (key == "taking_med" && this.ent_personal[key] != undefined) {
        takingmed = this.ent_personal[key];
      }

      if (key == "case_hist_id" && this.ent_personal[key] != undefined) {
        url = "1";

      } else if (key == "current_occupation" && (this.ent_personal[key] == undefined || this.ent_personal[key] == null)) {
        this.personal_flag = false;

      } else if (key == "age" && (this.ent_personal[key] == undefined || this.ent_personal[key] == "")) {
        this.personal_flag = false;

      } else if (takingmed != undefined && takingmed == "Yes") {
        if (key == "med_det" && this.ent_personal[key] == undefined) {
          this.personal_flag = false;
        }
      }
    }

    for (var key in vitals) {
      if (key == "height" && (vitals[key] == undefined || vitals[key] == "")) {
        vitals_flag = false;
      }
      if (key == "weight" && (vitals[key] == undefined || vitals[key] == "")) {
        vitals_flag = false;
      }
    }

    if (ent_treatment != undefined) {
      if (ent_treatment["disease"] == undefined) {
        this.diagnosis_flag = false;
      }
    }

    if (this.personal_flag == false) {
      this.toastr.error("Please enter Personal Card mandatory fields");
    } else if (this.diagnosis_flag == false) {
      this.toastr.error("Please enter Treatment Card mandatory fields");
      this.loader = true;
    } else if (vitals_flag == false) {
      this.toastr.error("Please enter Vitals mandatory fields");
    }

    var person_family = $.extend(true, this.ent_personal, this.ent_family, this.cardio_habitual, vitals);
    var per_fam_clinical = $.extend(true, person_family, this.ent_clinical);
    var per_fam_clinic_diag = $.extend(true, per_fam_clinical, this.ent_diagnosis);
    var final_array = $.extend(true, per_fam_clinic_diag, ent_treatment);

    if (url != null) {
      url = ipaddress.getIp.toString() + 'ent/ucasehist';

    } else {
      url = ipaddress.getIp.toString() + 'ent/scasehist';
    }

    var diet_plan_url = "lifestyle/sdplan";

    for (var key in get_diet_data) {
      if (key == "dplan_morning") {
        this.diet_mrng_arry = get_diet_data[key];
      }

      if (key == "dplan_breakfast") {
        this.break_fast_arrydata = get_diet_data[key]
      }

      if (key == "dplan_midday") {
        this.mid_day_arrydata = get_diet_data[key];
      }

      if (key == "dplan_lunch") {
        this.lunch_arrydata = get_diet_data[key];
      }

      if (key == "dplan_evening") {
        this.evening_arrydata = get_diet_data[key];
      }

      if (key == "dplan_dinner") {
        this.dinner_arrydata = get_diet_data[key];
      }

      if (key == "dplan_night") {
        this.night_arrydata = get_diet_data[key];
      }

      if (key == "diet_plan" && get_diet_data[key] == "1") {
        diet_plan_url = "lifestyle/updplan";
      }
    }

    if (this.personal_flag == true && this.clinical_flag == true && this.diagnosis_flag == true) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(url, final_array,
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);


            if (obj.key != null && obj.key == "1") {
              Helper_Class.setPersonalHist(null);
              Helper_Class.setDiabFam(null);
              Helper_Class.setEntClinical(null);
              Helper_Class.setDiabTreat(null);

              var dietplan = null;

              if (get_sga_data != undefined) {
                dietplan = $.extend(true, get_sga_data, get_diet_data);

              } else {
                dietplan = get_diet_data;
              }
              if (dietplan != null) {
                var headers = new Headers();
                headers.append('Content-Type', 'application/json');
                this.http.post(ipaddress.getIp.toString() + diet_plan_url, dietplan,
                  { headers: headers })
                  .subscribe(
                    response => {
                      var diet_obj = JSON.parse(JSON.stringify(response));
                      console.log("dierb obj"+JSON.stringify(diet_obj))
                      this.loader = true;
                      this.save_flag = false;
                      this.toastr.success("Case history saved successfully");
                      if (Helper_Class.getInpatientFlow() == "nurse") {
                        this.nurseservice.sendMessage("viewIpatient");
                      } else if (Doc_Helper.getAppFlow() == "Inpatient") {
                        this.doctorviewservice.sendMessage("inPatientViewInfo");
                      } else {
                        this.doctorviewservice.sendMessage("appointment_list");
                      }
                    },
                    error => {
                      this.toastr.error(Message_data.getNetworkMessage());
                    }
                  )

              } else {
                this.toastr.error("Case history saved successfully");
                if (Helper_Class.getInpatientFlow() == "nurse") {
                  this.nurseservice.sendMessage("viewIpatient");
                } else {
                  this.doctorviewservice.sendMessage("appointment_list");
                }
              }

            } else {
              this.loader = true;
              this.toastr.error("Case history not saved, please try again later");
            }
          });
    }
  }

  save_gastro() {
    var vitals;
    var vitals_flag: boolean = true;
    this.send_arr = null;
    var url = null; this.personal_flag = true;
    this.clinical_flag = true;
    this.diagnosis_flag = true;
    this.cardio_personal = Helper_Class.getCasesheetPersonal();
    this.cardio_habitual = Helper_Class.gethabitual();
    if(Helper_Class.getDiabFam() != null){
      this.cardio_family = {
        family_history: Helper_Class.getDiabFam(),
      }
    }else{
      // this.cardio_family = {
      //   family_history: Helper_Class.getDiabFam(),
      // }
    }
    console.log("url,lkj"+JSON.stringify(this.cardio_personal))
    console.log("url,lkj"+JSON.stringify(this.cardio_family))

    this.cardio_clinical = Helper_Class.getclinicalexam();
    this.cardio_diagnosis = Helper_Class.getDiabTreat();
    this.medicalprescription = Helper_Class.get_med_pres_id();
    this.diagnosisprescription = Helper_Class.get_diag_pres_id();
    var get_sga_data = Helper_Class.getSga();
    var get_diet_data = Helper_Class.getDietplan();
    vitals = Doc_Helper.getVitals();

    var takingmed = undefined;
    var sfactor = undefined;
    var painfactor = undefined;
    for (var key in this.cardio_personal) {
      if (key == "case_hist_id" && this.cardio_personal[key] != undefined) {
        url = "1";
      }
      if (key == "current_occupation" && (this.cardio_personal[key] == undefined || this.cardio_personal[key] == "" || this.cardio_personal[key] == null)) {
        this.personal_flag = false;
      }
    }

    for (var key in vitals) {
      if (key == "height" && (vitals[key] == undefined || vitals[key] == "")) {
        vitals_flag = false;

      }
      if (key == "weight" && (vitals[key] == undefined || vitals[key] == "")) {
        vitals_flag = false;
      }
    }

    if (this.cardio_clinical != undefined) {
      for (var key in this.cardio_clinical) {
        if (key == "stressed") {
          sfactor = this.cardio_clinical[key];
        }

        if (key == "chest_pain") {
          painfactor = this.cardio_clinical[key];
        }

        if (key == "stressed" && this.cardio_clinical[key] == undefined) {
          this.clinical_flag = false;

        } else if (sfactor == "Yes") {
          if (key == "stress_factor" && (this.cardio_clinical[key] == undefined || this.cardio_clinical[key] == "")) {
            this.clinical_flag = false;
          } else if (key == "symptoms" && (this.cardio_clinical[key] == undefined || this.cardio_clinical[key] == "")) {
            this.clinical_flag = false;
          }

        } else if (painfactor == "Yes") {
          if (key == "pain_location" && this.cardio_clinical[key] == undefined) {
            this.clinical_flag = false;
          } else if (key == "pain_character" && this.cardio_clinical[key] == undefined) {
            this.clinical_flag = false;
          } else if (key == "pain_radiation" && this.cardio_clinical[key] == undefined) {
            this.clinical_flag = false;
          } else if (key == "pain_duration" && this.cardio_clinical[key] == undefined) {
            this.clinical_flag = false;
          } else if (key == "pain_aggrev_factor" && this.cardio_clinical[key] == undefined) {
            this.clinical_flag = false;
          } else if (key == "pain_relieve_factor" && this.cardio_clinical[key] == undefined) {
            this.clinical_flag = false;
          } else if (key == "pain_asso_symptom" && this.cardio_clinical[key] == undefined) {
            this.clinical_flag = false;
          } else if (key == "pain_intensity" && this.cardio_clinical[key] == undefined) {
            this.clinical_flag = false;
          } else if (key == "pain_cause" && this.cardio_clinical[key] == undefined) {
            this.clinical_flag = false;
          }
        }
      }
    }

    if (this.cardio_diagnosis != undefined) {
      if (this.cardio_diagnosis["disease"] == undefined) {
        this.diagnosis_flag = false;
      } else if (this.cardio_diagnosis["disease"] == "select") {
        this.diagnosis_flag = false;
      }else if (this.cardio_diagnosis["prognosis"] == undefined) {
        this.diagnosis_flag = false;
      }
    }

    if (this.personal_flag == false) {
      this.toastr.error(Message_data.personalCardMandatory);
    }
    else if (this.clinical_flag == false) {
      this.toastr.error(Message_data.clinicalCardMandatory);
    }
    else if (this.diagnosis_flag == false) {
      this.toastr.error(Message_data.treatmentCardMandatory);
    } else if (vitals_flag == false) {
      this.toastr.error(Message_data.vitalMandatory);
    }
    if(this.cardio_family != undefined){
      var person_family = $.extend(true, this.cardio_personal, this.cardio_family, this.cardio_habitual, vitals);

    }else{
      var person_family = $.extend(true, this.cardio_personal, this.cardio_habitual, vitals);

    }
    var per_fam_clinical = $.extend(true, person_family, this.cardio_clinical, this.medicalprescription, this.diagnosisprescription);

    var final_array = $.extend(true, per_fam_clinical, this.cardio_diagnosis);
    final_array.country = "IN";

    if (url != null) {
      url = ipaddress.getIp.toString() + 'gast/ucasehist';
    } else {
      url = ipaddress.getIp.toString() + 'gast/scasehist';
    }
    var diet_plan_url = "lifestyle/sdplan";
    console.log("url,lkj"+url)
    for (var key in get_diet_data) {
      if (key == "dplan_morning") {
        this.diet_mrng_arry = get_diet_data[key];
      }

      if (key == "dplan_breakfast") {
        this.break_fast_arrydata = get_diet_data[key]
      }

      if (key == "dplan_midday") {
        this.mid_day_arrydata = get_diet_data[key];
      }

      if (key == "dplan_lunch") {
        this.lunch_arrydata = get_diet_data[key];
      }

      if (key == "dplan_evening") {
        this.evening_arrydata = get_diet_data[key];
      }

      if (key == "dplan_dinner") {
        this.dinner_arrydata = get_diet_data[key];
      }

      if (key == "dplan_night") {
        this.night_arrydata = get_diet_data[key];
      }

      if (key == "diet_plan" && get_diet_data[key] == "1") {
        diet_plan_url = "lifestyle/updplan";
      }
    }

    if (this.personal_flag == true && this.clinical_flag == true && this.diagnosis_flag == true) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(url, JSON.stringify(final_array),
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();

            if (obj["key"] != null && obj["key"] == "1") {
              Helper_Class.setpatient_information(null);
              Helper_Class.setDiabFam(null);
              Helper_Class.setclinicalexam(null);
              Helper_Class.setDiabTreat(null);
              var dietplan = null;
              if (get_sga_data != undefined) {
                dietplan = $.extend(true, get_sga_data, get_diet_data);

              } else {
                dietplan = get_diet_data;
              }
              var headers = new Headers();
              headers.append('Content-Type', 'application/json');
              this.http.post(ipaddress.getIp.toString() + diet_plan_url, dietplan,
                { headers: headers })
                .subscribe(
                  response => {
                    var diet_obj = response.json();
                    this.save_flag = false;
                    if (this.cardio_personal.case_hist_id != null && this.cardio_personal.case_hist_id != undefined) {
                      this.toastr.success(Message_data.caseHistUpdSuccess);

                    } else {
                      this.toastr.success(Message_data.caseHistSavedSuccess);
                    }

                    if (Helper_Class.getInpatientFlow() == "nurse") {
                      this.nurseservice.sendMessage("viewIpatient");

                    } else if (Doc_Helper.getAppFlow() == "Inpatient") {
                      this.doctorviewservice.sendMessage("inPatientViewInfo");

                    } else {
                      this.doctorviewservice.sendMessage("appointment_list");
                    }

                  },
                  error => {
                    this.toastr.error(Message_data.defaultErr);
                  }
                )
            } else {
              //  this.loader = true;
              this.toastr.error(Message_data.caseHistNotSaved);
            }
          });
    }
  }

  jsonConcat(o1, o2) {
    for (var key in o2) {
      o1[key] = o2[key];
    }
    return o1;
  }

  openRx() {
    $('#noRxModal').modal('show');
  }

  close_appointment(e) {
    if (e == "yes") {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'appointment/clsapp/',
        JSON.stringify({
          app_id: this.userlist.app_id,
          mobile: this.userlist.mobile,
          type: "doctor"
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);

            if (obj.key != null) {
              if (obj.key == "1") {
                this.toastr.success("Successfully closed appointment");
                this.doctorviewservice.sendMessage("appointment_list");
                //this.navCtrl.push("clientapplistPage");

              } else {
                this.toastr.error("Unable to close appointment, try again")
              }
            }
          }
        );
    } else {

    }
    $('#noRxModal').modal('hide');
  }

  video() {
    const dialogRef1 = this.dialog.open(VideoLinkComponent, {
      width: '500px',
      // height: '500px',
      data: {
        login_id: this.userinfo.user_id,
        callee_id: this.userlist.Client_id,
      }
    });
    dialogRef1.afterClosed().subscribe(result => {
    });
  }

  backFunction() {
    if (Helper_Class.getInpatientFlow() == "nurse") {
      this.doctorviewservice.sendMessage("viewIpatient");
    } else {
      if (Doc_Helper.getAppFlow() == "Inpatient") {
        this.doctorviewservice.sendMessage("inPatientViewInfo");
      } else {
        this.doctorviewservice.sendMessage("appointment_list")
      }
    }
  }

  getBedName(bedNo) {
    // Assuming the provided data is stored in a variable named 'data'
    const wardlist = this.userlist.wardlist;

    // Find the bed in the wardlist
    const foundBed = wardlist.find(bed => bed.bed_no === bedNo);

    // If the bed is found, return the bed name; otherwise, return null
    return foundBed ? foundBed.bed : null;
  }

  onFileSelected(event) {
    const file: File = event.target.files[0];
    const fileType: string = file.type;
    if (fileType === 'application/pdf') {
      this.selectedFile = file;
    } else {
      this.toastr.error('Please select a PDF file.');
    }
  }

  getPdfLink() {
    //return this.sanitizer.bypassSecurityTrustResourceUrl(this.filePath);
  }
}
