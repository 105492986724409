import { Component, OnInit } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { CommonDataService } from '../../providers/common-data.service'
import { Router } from '@angular/router';
import { Helper_Class } from '../../helper_class';
import { FilterpageComponent } from '../filterpage/filterpage.component';
import { pharmacy_helper } from '../Pharmcy_Helper';
import { PharmacyService } from '../pharmacy/pharmacy.service';
import { ipaddress } from '../../ipaddress';
import { ToastrService } from 'ngx-toastr';
import { formatDate } from '@angular/common';
import { Date_Formate } from 'src/assets/js/common';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
@Component({
  selector: 'app-pharmacycurrentinformation',
  templateUrl: './pharmacycurrentinformation.component.html',
  styleUrls: ['./pharmacycurrentinformation.component.css']
})
export class PharmacycurrentinformationComponent implements OnInit {
  orderListArray = [];
  noDataCurrent: boolean = false;
  public pharmacyID: string;
  public textAlign;
  public height;
  public width;
  public userinfo;
  public imei;
  dtOptions: DataTables.Settings = {};
  persons: any = [];
  masterSelected: boolean = false;
  checklist: any;
  checkedList: any;
  public AppType;
  public inpatientFlag;
  public ynopt1: boolean = true;
  public createFlag:boolean = false;
  public currentDate;
  public fromDate;
  public toDate;
  public editbutton:boolean=false;
  public newbutton:boolean=false;
  public deletebutton:boolean=false;
  public printbutton:boolean=false;
  public viewbutton:boolean=false;
  public discURl;
  public patienttype;

  constructor(public messageservice: MenuViewService, public gservice: CommonDataService, public http: Http, public router: Router, public dialog: MatDialog,public toastr:ToastrService) {
  }

  ngOnInit(): void {
    this.discURl = ipaddress.getIp.toString() + "pharmacontrol/gdis";
    var currentorderflowstatus = "current";
    this.imei = Helper_Class.getIPAddress();
    this.userinfo = Helper_Class.getInfo();
    pharmacy_helper.setpharmacyflow(currentorderflowstatus);
    this.getCurrentDate();
    this.getDiscTypes();
    this.patienttype="outpatient"
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 10,
      "lengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
      "columnDefs": [
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search by order id, delivery type etc"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    this.AppType = "out";
   

    if(this.userinfo.user_type == "Admin"){this.newbutton=true}else{
    if(Helper_Class.getmodulelist() != undefined){
      for (var i = 0; i < Helper_Class.getmodulelist().length; i++) {
        if (Helper_Class.getmodulelist()[i].module_id == "45" && Helper_Class.getmodulelist()[i].description == "Orders - current"){
          if(Helper_Class.getmodulelist()[i].edit == "1"){
              this.editbutton=true;
            }
            if(Helper_Class.getmodulelist()[i].create == "1"){
              this.newbutton=true;
            }
            if(Helper_Class.getmodulelist()[i].delete == "0"){
              this.deletebutton=true;
            }
            if(Helper_Class.getmodulelist()[i].print == "0"){
              this.printbutton=true;
            }
            if(Helper_Class.getmodulelist()[i].view == "1"){
              this.viewbutton=true;
            }
        }
      }
    }
  }
  }
  getDiscTypes() {
    if (Helper_Class.getIsAdmin() != undefined) {
      this.pharmacyID = Helper_Class.getInfo().pharma_id;
    } else {
      this.pharmacyID = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
    }
    var sendData = {
      center_id: this.pharmacyID
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.discURl, sendData, { headers: headers }).subscribe(
      data => {
        var distypes = data.json();
        Helper_Class.setDiscountTypes(distypes.disc_types);
        
        // this.discountDatasArray = [];
        // var distypes = data.json();
        // console.log("discount type = " + JSON.stringify(distypes))
        // this.discountDatasArray = distypes.disc_types;


        // for(var i=0;i<this.discountDatasArray.length;i++){
        //   if(this.discountDatasArray[i].default_val == "1"){
        //     this.discType = this.discountDatasArray[i].disc_type_id;
        //     break;
        //   }else{
        //     this.discType = this.discountDatasArray[0].disc_type_id;
        //   }
        // }
        //this.discountChange(this.discType)

      }, error => { });
  }

  getCurrentDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',{country: ipaddress.country_code},
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.currentDate = obj.current_date;//display in html for maximum date
          this.fromDate = obj.current_date;
          this.toDate = obj.current_date;
          this.pharmacyBranchList();
        })
  }

  selectDate(data,value) {
    if (data != null) {
      if (value == "1"){
        this.fromDate = data;
      }else{
        this.toDate = data;
      }
    }
  }

  pharmacyBranchList() {
    var send_data = JSON.stringify({
      pharma_reg_id: this.userinfo.user_id,
      imei: this.imei
    });
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/locbyregid/", send_data,
      { headers: headers }).subscribe(
        response => {
          var obj = response.json();
          if (obj.pharma != null) {
            // this.pharmacyID = obj.pharma[0].pharma_id;
            if (Helper_Class.getIsAdmin() != undefined) {
              this.pharmacyID = Helper_Class.getInfo().pharma_id;
            } else {
              this.pharmacyID = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
            }
            this.userTypeSelect("out");
            localStorage.setItem("pharma_id", obj.pharma[0].pharma_id);
          }
        });
  }

  currentOrder(pharmacyBranchId) {
    this.orderListArray = [];
    this.orderListArray = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    var send = {
      pharma_id: pharmacyBranchId,
      flag: "current",
      inpatient_flag: this.inpatientFlag,
      start_date : this.fromDate,
      end_date :  this.toDate,
    }



    this.http.post(ipaddress.getIp + "pharmacontrol/viewordersbyphar", JSON.stringify(send), { headers: headers }).subscribe(
      response => {
        var obj = response.json();
        console.log("feach data"+JSON.stringify(obj))

        if (obj.order_details != null) {
          this.noDataCurrent = false;
          for (var i = 0; i < obj.order_details.length; i++) {
            if (obj.order_details[i].first_name != null) {
              if (obj.order_details[i].description == "Home delivery") {
                var home_deltxt = "Home delivery";
              } else {
                home_deltxt = obj.order_details[i].description;
              }
              if (obj.order_details[i].drug_details != null) {
                var tabletarray = [];
                for (var k = 0; k < obj.order_details[i].drug_details.length; k++) {
                  tabletarray.push({
                    tablet_name: obj.order_details[i].drug_details[k].type_name + " - " + obj.order_details[i].drug_details[k].drug_name + " " + obj.order_details[i].drug_details[k].quantity + " nos"
                  });
                }
              }
              if (obj.order_details[i].middle_name != undefined && obj.order_details[i].middle_name != null) {
                var m_name = encrypt_decript.Decript(obj.order_details[i].middle_name).toString();
              } else {
                m_name = "";
              }
              var status = obj.order_details[i].status == "0" ? "Open" : "Confirmed";
              localStorage.setItem("status", obj.order_details[i].status)
             var doctor_name;
              if( obj.order_details[i].doctor_name == undefined){
                doctor_name="";
              }else{
                doctor_name=obj.order_details[i].doctor_name
              }
              this.orderListArray.push({
                drug_purchase_id: obj.order_details[i].drug_purchase_id,
                tot_name: encrypt_decript.Decript(obj.order_details[i].first_name).toString() + " " + m_name + " " + encrypt_decript.Decript(obj.order_details[i].last_name).toString(),
                home_delivery: home_deltxt,
                order_date: this.DateFormate(obj.order_details[i].order_date),
                tablet_data: tabletarray,
                doctor_name:doctor_name,
                status: status,
                flag: obj.order_details[i].status,
                fin_year: obj.order_details[i].fin_year
              });
              pharmacy_helper.setcurrentinformation_pharrmacy(this.orderListArray)
            }
          }
        } else {
          this.noDataCurrent = true;
        }
      });
  }

  DateFormate(data) {
    var Splitter = data.split("-");
    return Splitter[2] + "-" + Splitter[1] + "-" + Splitter[0];
  }

  orderView(Order_id, type, flag,finyear) {
    var order_View = {
      patienttype:this.patienttype,
      AppFlow: "current_orders",
      orderid: Order_id,
      type: type,
      status: flag,
      value: "current",
      fin_year:finyear
    }
    pharmacy_helper.setorderview(order_View);
    this.messageservice.sendMessage('pharmacymoduleorderdetailview');
  }

  optionsPopover() {
    const dialogRef = this.dialog.open(FilterpageComponent, {
      width: '25%',
      height: '180px',
      data: {}
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data != undefined) {
        this.currentOrder(data)
      }
    });
  }

  back_Nav() {
    this.messageservice.sendMessage("pharmahome");
  }

  createOrder() {
    if (this.inpatientFlag == true) {
      this.messageservice.sendMessage("ipWriteRx");
    } else {
      this.messageservice.sendMessage("ordercreate");
    }
  }

  userTypeSelect(page) {
    this.AppType = page;
    this.inpatientFlag = this.AppType == "in" ? true : false;
    this.currentOrder(this.pharmacyID);
  }

  changeType(id, value) {
    if (value == "yes") {
      this.patienttype="outpatient"
      this.ynopt1 = true;
      this.userTypeSelect('out');
    } else {
      this.patienttype="inpatient"
      this.ynopt1 = false;
      this.userTypeSelect('in');
    }
  }
  
}
