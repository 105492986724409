import { Component, OnInit,ElementRef,ViewChild,HostListener } from '@angular/core';
import { DatePipe } from '@angular/common';
import { CommonDataService } from 'src/app/providers/common-data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ToastrService } from 'ngx-toastr';
import { Message_data } from '../../../assets/js/Message_data';
import { Helper_Class } from '../../helper_class';
import { FormControl } from '@angular/forms';
import { Date_Formate } from '../../../assets/js/common.js';
import { FrontDesk_Helper } from '../FrontDesk_Helper';
import { FrontDeskService } from '../front-desk/front-desk.service';
import { ipaddress } from '../../ipaddress';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
import { Master_Helper } from 'src/app/Doctor_module/Master_Helper';

declare var $: any;
@Component({
  selector: 'app-recept-app-create-page',
  templateUrl: './recept-app-create-page.component.html',
  styleUrls: ['./recept-app-create-page.component.css']
})
export class ReceptAppCreatePageComponent implements OnInit {
  @ViewChild('scrollContainer', { static: false }) scrollContainer: ElementRef;
  public somePlaceholder;
  public covidFlag;
  public updateFlag: boolean;
  public filterType;
  public patientFName;
  public clientList = [];
  public clntFirstName;
  public clntMiddleName;
  public clntLastName;
  public clientId;
  public userTypeFlag: boolean;
  public existUserFlag: boolean;
  public searchFlag: boolean;
  public bookFlag: boolean;
  public availableFlag: boolean;
  public nonAvailableFlag: boolean;
  public appType;
  public filter;
  public referredDoctorListData;
  public sendAppType;
  public readonly: boolean;
  public clntLocationList:any = [];
  public clntCityList:any = [];
  public clntStateList:any = [];
  public clntCountryList:any = [];
  public referredDoctorList:any = [];
  public clntSalList:any = [];
  public splList:any = [];
  public doctorListData:any = [];
  public doctorList:any = [];
  public bookTime:any = [];
  public doctorDetailData:any = [];
  public docUnavailableData:any = [];
  public hospitalListData:any = [];
  public visitingSessionData:any = [];
  public homecareTimeList:any = [];
  public homeSessionTableData:any = [];
  public homecareTableData:any = [];
  public homecareSessionData:any = [];
  public homeApps:any = [];
  public billAddList: any = [];

  public patientList:any = [];
  public ipaddress;
  public sendMiddleName;
  public filteredArr:any = [];
  public patientName;

  public sendDoctorDetailData;
  public timeOfAppt;
  public dataForApptBook;
  public sendUpdateData;
  public clntAddress1: string = null;
  public clntAddress2: string = null;
  public clntLocation: string = null;
  public clntCity: string = null;
  public clntState: string = null;
  public clntCountry: string = null;
  public clntLocationId: string = null;
  public clntCityId: string = null;
  public clntStateId: string = null;
  public clntCountryId: string = null;
  public clntZipcode: string = null;
  public referredDoctor: string = null;
  public clntContNumber: string;
  public clntEmergNumber: number;
  public clntEmergNumber1: number;
  public clntSymptom: string;
  public clntDOB = null;
  public relId: string;
  public clntSal: string;
  public BP01: string;
  public BP02: string;
  public locationUrl: string;
  public locationNameByUrl: string;
  public cityUrl: string;
  public stateUrl: string;
  public countryUrl: string;
  public doctorDetailUrl: string;
  public currentDateUrl: string;
  public apptBookUrl: string;
  public salUrl: string;
  public splUrl: string;
  public doctorUrl: string;

  public hospId: string;
  public clientRegId: string;
  public relationId: string;
  public subRelId: string;
  public clntGender: string;
  public clntAge;
  public clntFatherOrGuardName: string;
  public clientType: string;
  public doctorProfileImg: string;
  public tempFee: string;
  public typeorTime: string;
  public bloodPressureFlag;
  public booktimeMorn:any = [];
  public booktimeNoon:any = [];
  public booktimeEve:any = [];
  public hospitalId: string;
  public hosptlId: string;
  public appTypeTokenFlag: Boolean;
  public appTypeTimeFlag: Boolean;
  public apptType: string;
  public hospSltLocation: string;
  public hospSltFee: string;
  public currentDate: string;
  public currentTime: string;
  public homecare: string;
  public visitSession;
  public visitTime;
  public tokenorTime;
  public timeList;
  public homecareAppDate;
  public appointmentDate;
  public hospitalName;
  public height;
  public hmeasure: string;
  public weight;
  public wmeasure: string;
  public temperature: string;
  public bmi;
  public encheight;
  public encweight;
  public hospName;
  public splName;
  public splId;
  public doctorId;
  public currentDatetime;
  public getDate;
  public currentYear;
  public currentMaxDate;
  public appCurrentDate
  public userInfo;
  public ageRead: boolean = false;
  public ageUrl;
  public timeFlag: boolean;
  public symptomListData:any = [];
  public symptomList:any = [];
  public symptomsMasterList:any = [];
  public selectedSymptoms:any = [];
  public dobDisabledFlag;
  public maxLength: number;
  public retrvDisabledFlag: boolean;
  public newSymptoms: any = [];
  public insurerList: any = [];
  public payTypeList: any = [];
  public ageDisabledFlag: boolean;
  public clientTypeFlag: boolean;
  public apptTypeFlag: boolean;
  public zipcode_url;
  public book_flag: boolean = false;
  public pay_flag: boolean = true;
  public insurFlag: boolean = true;
  public transactionFlag: boolean = true;
  public cardFlag: boolean = true;
  public chequeFlag: boolean = true;
  public payType: string;
  public paydesc: string;
  public transactionId: string;
  public insurerId: string;
  public cardNumber: string;
  public cardHolderName: string;
  public bankName: string;
  private billUrl: string;
  public appclass:boolean=false;
  public appdisable:boolean=false;
  public searchTypeFlag:boolean;
  private nodob:boolean=false;
  public patientid:boolean;
  public searchby:string;
  public searchtext: string;
  public currentLimit = 1;
  public typeflag;
  public spo_txt;
  public pulse_txt;
  public cvs_txt;
  public rstxt;
  public cns_txt;
  public abd_txt;
  dtOptions: DataTables.Settings = {};
  chargeName: any[];
  chargeNameArray: any[];
  is_new: string;
  prev_charges: any;
  concession: any;
  quantity: any;
  transId: any;
  chargeType: any;
  sendConcession: any;
  amount: any;
  amountAftCon: any;
  chargeId: any;
  appointmentId: any;
  finalAmount: any;
  inGST: boolean;
  billingType: string;
  paytypreq: boolean;
  sno: any;
  totalBillAmount: any;
  discount_amount: any;
  gst_amount_txt: any;
  total_collected: any;
  current_amount: any;
  gst_data_list: any[];
  hospitalBills: string;
  Advance_bill: string;
  balance_show: boolean;
  remaing_billbal: number;
  amount_collected: boolean;
  masterData: any;
  gst_retrive_list: any;
  balance_data: any;
  discount_flag: any;
  public bill_qty_flag: boolean = true;
  serviceType: any;
  billaddList: any=[];
  public billamount;

  constructor(public frontdeskservice: MenuViewService, public datepipe: DatePipe, public gservice: CommonDataService,
    public http: HttpClient, public toastr: ToastrService) {
    this.ageUrl = ipaddress.getIp + "adm/ddif";
    localStorage.setItem("searchbyhospital", "search_hospital");
    this.filterType = "0";
    this.searchFlag = false;
    this.appType = "New";
    this.ageRead = false;
    this.userTypeFlag = false;
    this.clientType = "Existing";
    this.existUserFlag = true;
    this.appTypeTokenFlag = true;
    this.hmeasure = "cms";
    this.wmeasure = "kgs";
    this.locationUrl = ipaddress.getIp + "usercontroller/loc";
    this.locationNameByUrl = ipaddress.getIp + "usercontroller/locbyname";
    this.cityUrl = ipaddress.getIp + "gen/city";
    this.stateUrl = ipaddress.getIp + "gen/state";
    this.countryUrl = ipaddress.getIp + "gen/count";
    this.salUrl = ipaddress.getIp + "gen/sal";
    this.splUrl = ipaddress.getIp + "adm/gsplbyhptl";
    this.doctorUrl = ipaddress.getIp + "search/docbyhs";
    this.apptBookUrl = ipaddress.getIp + "appointment/rapp";
    this.billUrl = ipaddress.getIp + "bill/savebill/";
    this.doctorDetailUrl = ipaddress.getIp + "search/doctorbyid/";
    this.currentDateUrl = ipaddress.getIp + "adm/curdate";
    this.zipcode_url = ipaddress.getIp + "usercontroller/locbyzipcode";
    this.clntAddress1 = "NA";

    this.ipaddress = Helper_Class.getIPAddress();
  }

  ngOnInit() {
    //if(Helper_Class.getdatevalue() == undefined){
      this.getCurrentDate();
    // } else {
    //   this.appCurrentDate = Helper_Class.getdatevalue();
    // }
    $(document).ready(function () {
      $("input").attr("autocomplete", "off");
    });
    this.userInfo = Helper_Class.getInfo();
    if (this.userInfo.bill_pay_type_req == '1') {
      this.paytypreq = false;
    } else {
      this.paytypreq = true;
    }

    if (Helper_Class.getInfo().bill_qty_flag == 0) {
      this.bill_qty_flag = true;
    } else {
      this.bill_qty_flag = false;
    }

    this.filter == "name";
    if (this.userInfo.doc_app_bill_create == "1") {
      this.pay_flag = false;
    }
    this.clientType = "Existing";
    this.searchType('name');
    this.searchTypeFlag=true;
    this.clntCountryId = this.userInfo.hospitals[0].country;
    this.clntStateId = this.userInfo.hospitals[0].state;
    this.clntCityId = this.userInfo.hospitals[0].city;
    this.clntLocationId = this.userInfo.hospitals[0].location;
    this.appclass=true;
    this.changeType('client', 1, 'no');
    this.changeType('app', 1, 'yes');
    
    this.getSpecializations();
    this.getSalutations();
    this.getPaymentType();
    if(Helper_Class.getPatientList() == undefined){
      this.getPatientList();
    } else {
      this.patientList = Helper_Class.getPatientList();
      this.filteredArr = Helper_Class.getPatientList();
    }
    
    this.tableFilter(this.patientName);
    this.getBillAmount();
  }

  ngAfterViewInit() {
    if (this.scrollContainer) {
      this.scrollContainer.nativeElement.addEventListener('scroll', this.onScroll.bind(this));
    }
  }

  searchType(data){
    this.searchby=data;
    if(data =="name"){
      this.searchTypeFlag = true
      this.searchtext="search by name"
    } else {
      this.searchTypeFlag = false;
      this.searchtext="search by mobile"
    }

    if(data =="name"){
      this.typeflag = "name";
      this.patientName="";
      this.searchtext="search by name"

    } else if(data =="patientid"){
      this.typeflag = "patientid";
      this.patientName="";
      this.searchtext="search by id"
      this.patientid = true;

    }  else {
      this.typeflag = "mobile";
      this.patientName="";
      this.searchtext="search by mobile"
    }
  }

  changePayType() {
    this.insurFlag = true;
    this.transactionFlag = true;
    this.cardFlag = true;
    this.chequeFlag = true;
    if (this.payType == "2") {
      this.transactionFlag = false;
      this.cardFlag = false;
    }
    else if (this.payType == "3")
      this.insurFlag = false;
    else if (this.payType == "4")
      this.transactionFlag = false;
    else if (this.payType == "5")
      this.transactionFlag = false;
    else if (this.payType == "6")
      this.transactionFlag = false;
    else if (this.payType == "7")
      this.chequeFlag = false;
    else
      this.insurFlag = true;

    if(this.payTypeList.length !=0){
      for (var i = 0; i < this.payTypeList.length; i++) {
        if (this.payTypeList[i].pay_id == this.payType) {
          this.paydesc = this.payTypeList[i].pay_desc;
        }
      }
    }
  }

  getInsurers() {//Get payment type
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/gins',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.insurerList = obj.insurers;
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  getPaymentType() {//Get payment type
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/paytype',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.payTypeList = obj.payments;
          this.payType = this.payTypeList[0].pay_id;
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  back() {
    this.frontdeskservice.sendMessage("manageappointments");
  }

  fnameToUpper() {
    if (this.clntFirstName != null)
      this.clntFirstName = this.clntFirstName.toLocaleUpperCase();
  }

  mnameToUpper() {
    if (this.clntMiddleName != undefined)
      this.clntMiddleName = this.clntMiddleName.toLocaleUpperCase();
  }

  lnameToUpper() {
    if (this.clntLastName != undefined)
      this.clntLastName = this.clntLastName.toLocaleUpperCase();
  }

  filterByPatients() {//based client select placeholder will be change
    if (this.filter == "name") {
      this.maxLength = 50;
      this.somePlaceholder = "Search by first name";
      this.filterType = "0";
      this.patientFName = "";
      this.clientList = [];
      this.clntFirstName = "";
      this.clntMiddleName = "";
      this.clntLastName = "";

    } else if (this.filter == "mobile") {
      this.maxLength = 10;
      this.somePlaceholder = "Search by mobile number";
      this.filterType = "1";
      this.patientFName = "";
      this.clientList = [];
      this.clntFirstName = "";
      this.clntMiddleName = "";
      this.clntLastName = "";

    } else if (this.filter == "patient_id") {
      this.maxLength = 20;
      this.somePlaceholder = "Search by patient id";
      this.filterType = "2";
      this.patientFName = "";
      this.clientList = [];
      this.clntFirstName = "";
      this.clntMiddleName = "";
      this.clntLastName = "";
    }
  }

  changeName() {
    this.clientList = [];
    this.patientFName = this.patientFName.toLocaleUpperCase();
  }

  patientNameToUpper() {
    if (this.patientFName != undefined) {
      if (this.filter == "name")
        this.patientFName = this.patientFName.toLocaleUpperCase();
    }
  }

  changePatientName(name, flag) {//based client search filters patient  names
    this.clntGender = "Male";
    this.clntFirstName = null;
    this.clntMiddleName = null;
    this.clntLastName = null;
    this.clntAge = "";
    this.clntGender = "";
    this.clntFatherOrGuardName = "";
    this.clntAddress1 = "NA";
    this.clntAddress2 = null;
    this.clntContNumber = null;
    this.clntEmergNumber = null;
    this.clntEmergNumber1 = null;
    this.clntSymptom = "";
    this.clntDOB = null;

    this.height = "";
    this.hmeasure = "cms";
    this.weight = "";
    this.wmeasure = "kgs";
    this.bmi = ""
    this.temperature = "";
    this.bmi;
    this.encheight;
    this.encweight;
    this.hospName = "";
    this.referredDoctor = "";
    var nameval;
    if(flag == true){
      this.filter="name";
      nameval = name.first_name;
      this.clntContNumber=name.mobile;
       
    } else {
      nameval = name;
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/cid', {
      first_name: nameval,
      type: this.filter,
      hptl_clinic_id: Helper_Class.getHospitalId(),
    },
      { headers: headers })
      .subscribe(
        response => {
          this.clientList = [];
          var obj = JSON.parse(JSON.stringify(response));

          if (obj.clients != null && obj.clients.length != 0)
            this.clientList = obj.clients;
          else
            this.toastr.error(Message_data.clientNotFound);

          if (obj.clients.length != 0) {
            this.clientId = this.clientList[0].patient;
            this.changeClient(this.clientList[0].patient);
          }
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
    
  }

  getCurrentDate() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.currentDateUrl, { country: "IN" }, { headers: headers }).subscribe(
      data => {
        var dataval = JSON.parse(JSON.stringify(data));
        this.currentDate = Date_Formate(dataval.current_date);
        this.homecareAppDate = this.currentDate;
        this.appointmentDate = dataval.current_date;
        this.currentTime = dataval.current_time;//time basiiss
        this.currentDatetime = dataval.current_date;
        this.getDate = dataval.current_date.split('-');
        this.currentYear = this.getDate[0];
        this.currentMaxDate = dataval.current_date;
        this.appCurrentDate = dataval.current_date;
        Helper_Class.setdatevalue(dataval.current_date);
      },
      error => { });
  }

  changeClient(client) {// get the the client detials based on patient id
    this.clntFirstName = "";
    this.clntMiddleName = undefined;
    this.clntLastName = "";
    for (var i = 0; i < this.clientList.length; i++) {
      if (this.clientList[i].patient == client) {
        this.clntFirstName = (this.clientList[i].first_name);
        this.clntMiddleName = this.clientList[i].middle_name;
        this.clntLastName = this.clientList[i].last_name;
        this.clientRegId = this.clientList[i].client_reg_id;
        this.relationId = this.clientList[i].relation_id;
        this.subRelId = this.clientList[i].sub_rel_id;
      }
    }
    //this.retrieveClient();
  }

  getSpecializations() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.splUrl, { hptl_clinic_id: this.userInfo.hptl_clinic_id },
      { headers: headers }).subscribe(
        data => {
          var dataval = JSON.parse(JSON.stringify(data));
          this.splList = [];
          if (dataval.specializations != null) {
            this.splList = dataval.specializations;
          }
        },
        error => {
          this.toastr.error(Message_data.unableToFetchData);
        });
  }

  getDoctorList() {
    this.doctorList=[];
    this.doctorListData=[];
    for (var i = 0; i < this.splList.length; i++) {
      if (this.splId == this.splList[i].spl_id){
        this.doctorListData = this.splList[i].doctors;
        if (this.doctorListData != undefined) {
          for (var j = 0; j < this.doctorListData.length; j++) {
            var docname = "";
            docname = this.doctorListData[j].middle_name != undefined ? this.doctorListData[j].first_name + " " + this.doctorListData[j].middle_name + " " + this.doctorListData[j].last_name : this.doctorListData[j].first_name + " " + this.doctorListData[j].last_name;

            this.doctorList.push({
              docname: docname,
              prov_id: this.doctorListData[j].prov_id
            });
            this.doctorId = this.doctorList[0].prov_id;
            this.getDoctorDetails();
          }
        }
        break;
      }
    }
  }

  getCountries(stateid, flag) {
    this.clntCountryList = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.countryUrl, { state_id: stateid }, { headers: headers }).subscribe(
      data => {
        this.clntCountryList = [];
        var dataval = JSON.parse(JSON.stringify(data));
        if (dataval.countries != null) {
          this.clntCountryList = dataval.countries;
          for (var c = 0; c < this.clntCountryList.length; c++) {
            if (this.clntCountryList[c].country_id == this.clntCountryId) {
              this.clntCountry = this.clntCountryList[c].country_desc;
              this.clntCountryId = this.clntCountryList[c].country_id;
              //this.getStates(this.clntCountryId, flag);
              break;
            }
          }
        }
      },
      error => {
        this.toastr.error(Message_data.unableToFetchData);
      });
  }

  getStates(city, flag) {
    this.clntStateList = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.stateUrl, { city_id: city },
      { headers: headers }).subscribe(
        data => {
          var dataval = JSON.parse(JSON.stringify(data));
          if (dataval.states != null) {
            this.clntStateList = dataval.states;
            if (this.clntStateList.length == 1) {
              for (var i = 0; i < this.clntStateList.length; i++) {
                if (this.clntStateList[i].state_id == this.clntStateId) {
                  this.clntStateId = this.clntStateList[i].state_id;
                  this.clntState = this.clntStateList[i].state_desc;
                  this.getCountries(this.clntStateId, '0');
                  break;
                } else {
                  this.clntStateId = this.clntStateList[0].state_id;
                  this.clntState = this.clntStateList[0].state_desc;
                  this.getCountries(this.clntStateId, '0');
                }
              }

            } else {
              this.clntStateId = this.clntStateList[0].state_id;
              this.clntState = this.clntStateList[0].state_desc;
              this.getCountries(this.clntStateId, '0');
            }

          }
        });
  }

  changeState(clnt_state, flag) {
    for (var i = 0; i < this.clntStateList.length; i++) {
      if (this.clntStateList[i].stage_desc == clnt_state) {
        this.clntStateId = this.clntStateList[i].state_id;
        this.clntState = this.clntStateList[i].stage_desc;
        this.getCountries(this.clntStateId, '0');
      }
    }
  }

  getCities(state, flag) {
    this.clntCityList = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.cityUrl, { loc_name: this.clntLocation, zipcode: this.clntZipcode },
      { headers: headers }).subscribe(
        data => {
          var dataval = JSON.parse(JSON.stringify(data));
          if (dataval.cities != null) {
            this.clntCityList = dataval.cities;
            if (this.clntCityList.length == 1) {
              for (var i = 0; i < this.clntCityList.length; i++) {
                if (this.clntCityList[i].city_id == this.clntCityId) {
                  this.clntCityId = this.clntCityList[i].city_id;
                  this.clntCity = this.clntCityList[i].city_desc;
                  this.getStates(this.clntCityId, 0)

                  break;
                } else {
                  this.clntCityId = this.clntCityList[0].city_id;
                  this.clntCity = this.clntCityList[0].city_desc;
                  this.getStates(this.clntCityId, 0)
                }
              }

            } else {
              this.clntCityId = this.clntCityList[0].city_id;
              this.clntCity = this.clntCityList[0].city_desc;
              this.getStates(this.clntCityId, 0)
            }
          }
        });
  }

  changeCity(clnt_city, flag) {
    this.clntLocationList = [];
    for (var i = 0; i < this.clntCityList.length; i++) {
      if (this.clntCityList[i].city_desc == clnt_city) {
        this.clntCityId = this.clntCityList[i].district_id;
        this.clntCity = this.clntCityList[i].city_desc;
        this.getStates(this.clntCityId, 0)
      }
    }
  }

  getLocations(city) {
    this.clntLocationList = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.locationUrl, { city_id: city },
      { headers: headers }).subscribe(
        data => {
          var dataval = JSON.parse(JSON.stringify(data));
          if (dataval.locations != null && dataval.locations.length != 0) {
            this.clntLocationList = dataval.locations;
            for (var i = 0; i < this.clntLocationList.length; i++) {
              if (this.clntLocationList[i].location_id == this.clntLocationId) {
                this.clntLocationId = this.clntLocationList[i].location_id;
                this.clntLocation = this.clntLocationList[i].description;
                this.clntZipcode = this.clntLocationList[i].pincode;
                this.clntLocationList = [];
                break;
              }
            }
          }
        });
  }

  zipcode_change() {
    if (this.clntZipcode.length > 4) {
      this.clntLocationList = [];
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.zipcode_url, {
        zipcode: this.clntZipcode,
      }, { headers: headers }).subscribe(
        data => {
          var obj = JSON.parse(JSON.stringify(data))
          if (obj.locations != null && obj.locations.length != 0) {
            if (obj.locations.length == 1) {
              this.clntLocationId = obj.locations[0].location_id;
              this.clntLocation = obj.locations[0].description;
              this.clntZipcode = obj.locations[0].pincode;
              this.getCities(this.clntLocation, 0);
            } else {
              this.clntLocationList = obj.locations;
              console.log("../location" + JSON.stringify(this.clntLocationList))
            }
          }
        },
        error => {
          this.toastr.error("Unable to fetch location details, please try later");
        }
      )
    } else {
      this.clntLocationList = [];
    }
  }

  changeLocation(locat_desc) {
    this.clntLocation = locat_desc;
    this.clntLocationList = [];

    if (this.clntLocation != null && this.clntLocation.length > 2) {
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.locationNameByUrl, {
        name: this.clntLocation,
        //city_id: this.clntCityId,
      }, { headers: headers }).subscribe(
        data => {
          var dataval = JSON.parse(JSON.stringify(data));
          if (dataval.locations != null && dataval.locations.length != 0)
            this.clntLocationList = dataval.locations;
        },
        error => {
          this.toastr.error(Message_data.unableToFetchData);
        });
    } else {
      this.clntLocationList = [];
    }
  }

  selectLocation(location) {
    this.clntLocation = location;
    for (var i = 0; i < this.clntLocationList.length; i++) {
      if (this.clntLocationList[i].location_id == location.location_id) {
        this.clntLocationId = this.clntLocationList[i].location_id;
        this.clntLocation = this.clntLocationList[i].description;
        this.clntZipcode = this.clntLocationList[i].pincode;
      }
    }
    this.clntLocationList = [];
    this.getCities(this.clntLocation, '0');
    if (this.clientType == "Existing") {
      this.updateFlag = true;
    }
  }

  getReferral(e) {// Refer doctor list
    this.referredDoctor = e.target.value.toString();
    this.referredDoctorList = [];
    this.referredDoctorListData = [];
    var dname = this.checkData(this.referredDoctor);

    if (dname != "" && dname.length > 2) {
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'search/docbyhns/',
        {
          hospital_id: this.hospId,
          doc_name: dname
        },
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            this.referredDoctorListData = obj.doctors;

            if (this.referredDoctorListData != undefined) {
              for (var i = 0; i < this.referredDoctorListData.length; i++) {
                var docname = "";
                docname = this.referredDoctorListData[i].middle_name != undefined ? this.referredDoctorListData[i].first_name + " " + this.referredDoctorListData[i].middle_name + " " + this.referredDoctorListData[i].last_name : this.referredDoctorListData[i].first_name + " " + this.referredDoctorListData[i].last_name;
                this.referredDoctorList.push(docname);
              }
            }
            this.referredDoctorList = this.referredDoctorList.filter(function (el) {
              return el.toLowerCase().indexOf(dname.toLowerCase()) > -1;
            }.bind(this));
          },
          error => {
            this.toastr.error(Message_data.getNetworkMessage());
          });
    } else {
      this.referredDoctorList = [];
    }
  }

  selectReferredDoctor(item) {
    this.referredDoctor = item;
    this.referredDoctorList = [];
  }

  selectAppointmentDate(e) {
    this.appointmentDate = e;
    this.getDoctorDetails();
  }

  userTypeSelect(e) {
    this.filterByPatients();
    this.filter = "name";
    if (this.clientType == 'New') {
      this.retrvDisabledFlag = false;
      this.readonly = true;
      this.userTypeFlag = true;
      this.existUserFlag = false;
      this.searchFlag = true;
      this.clientRegId = "0";
      this.clntGender = "Male";
      this.clntFirstName = null;
      this.clntMiddleName = null;
      this.clntLastName = null;
      this.clntAge = "";
      this.clntGender = "";
      this.clntFatherOrGuardName = "";
      this.clntAddress1 = "NA";
      this.clntAddress2 = null;
      this.clntContNumber = null;
      this.clntEmergNumber = null;
      this.clntEmergNumber1 = null;
      this.clntSymptom = "";
      this.clntDOB = null;

      this.height = "";
      this.hmeasure = "cms";
      this.weight = "";
      this.wmeasure = "kgs";
      this.bmi = ""
      this.temperature = "";
      this.bmi;
      this.encheight;
      this.encweight;
      this.hospName = "";
      this.referredDoctor = "";
      this.dobDisabledFlag = false;
      this.ageDisabledFlag = false;
      this.clntSal = "select";

      this.clntZipcode = this.userInfo.hospitals[0].zipcode;
      this.clntLocationId = this.userInfo.hospitals[0].location;
      this.clntLocation = this.userInfo.hospitals[0].location_desc;
      this.selectLocation(this.clntLocation)
      this.clntCityId = this.userInfo.hospitals[0].city;
      this.clntCity = this.userInfo.hospitals[0].city_desc;
      this.clntStateId = this.userInfo.hospitals[0].state;
      this.clntState = this.userInfo.hospitals[0].state_desc;
      this.clntCountryId = this.userInfo.hospitals[0].country;
      this.clntCountry = this.userInfo.hospitals[0].country_desc;
    }
    
    if (this.clientType == "Existing" || this.appType == "Follow-up") {
      this.userTypeFlag = false;
      this.existUserFlag = true;
      this.searchFlag = false;

      this.clntFirstName = null;
      this.clntMiddleName = null;
      this.clntLastName = null;
      this.clntGender = "";
      this.clntFatherOrGuardName = "";
      this.clntAddress1 = "NA";
      this.clntAddress2 = null;
      this.clntContNumber = null;
      this.clntEmergNumber = null;
      this.clntEmergNumber1 = null;
      this.clntSymptom = "";
      this.clntDOB = null;

      this.height = "";
      this.hmeasure = "cms";
      this.weight = "";
      this.wmeasure = "kgs";
      this.bmi = ""
      this.temperature = "";
      this.bmi;
      this.encheight;
      this.encweight;
      this.hospName = "";
      this.referredDoctor = "";
      this.retrvDisabledFlag = false;
      this.dobDisabledFlag = false;
      this.ageDisabledFlag = false;

      this.clntCountryId = this.userInfo.hospitals[0].country;
      this.clntStateId = this.userInfo.hospitals[0].state;
      this.clntCityId = this.userInfo.hospitals[0].city;
      this.clntLocationId = this.userInfo.hospitals[0].location;
      //this.getCountries("0");
      this.clntSal = "select";
    }
  }

  getSalutations() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(this.salUrl, { headers: headers }).subscribe(
      data => {
        var obj = JSON.parse(JSON.stringify(data));
        if (obj != undefined) {
          for (var i = 0; i < obj.salutations.length; i++) {
            this.clntSalList.push({
              sal_id: obj.salutations[i].salutation_id,
              sal_desc: obj.salutations[i].salutation_desc,
            });
          }
          this.clntSal = "select";
          this.changeSalutation(this.clntSal);
        }
      });
  }

  retrieveClient(person) {
    var get_data;
    if(this.searchby == "patientid") {
      get_data = {
        user_id: person.client_reg_id,
        relation_id: person.relation_id,
        sub_rel_id: person.sub_rel_id,
        country: ipaddress.country_code
      };
    } 

    if (person.sub_rel_id != undefined) {
      get_data = {
        user_id: person.client_reg_id,
        relation_id: person.relation_id,
        sub_rel_id: person.sub_rel_id,
        country: ipaddress.country_code
      };
    } else {
      get_data = {
        user_id: person.client_reg_id,
        relation_id: person.relation_id,
        country: ipaddress.country_code
      };
    }

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'usercontroller/grclnt/',
      get_data,
      { headers: headers })
      .subscribe(
        response => {
          FrontDesk_Helper.setClientId(null);
          var obj = JSON.parse(JSON.stringify(response));
          console.log("retrive_person = "+JSON.stringify(obj))
          if (obj.client_id != null) {
            FrontDesk_Helper.setClientId(obj.client_id);
            // Editable Fields
            if (obj.salutation != null) {
              for (var i = 0; i < this.clntSalList.length; i++) {
                if (this.clntSalList[i].sal_desc == obj.salutation) {
                  this.clntSal = this.clntSalList[i].sal_id;
                }
              }
            }
            this.clientRegId=person.client_reg_id;
            this.relationId=person.relation_id;
            this.subRelId=person.sub_rel_id;
            this.clntFirstName = obj.first_name != null && (encrypt_decript.Decript(obj.first_name) != "") ? encrypt_decript.Decript(obj.first_name) : "";
            this.clntMiddleName = obj.middle_name != null && (encrypt_decript.Decript(obj.middle_name) != "") ? encrypt_decript.Decript(obj.middle_name) : "";
            this.clntLastName = obj.last_name != null && (encrypt_decript.Decript(obj.last_name) != "") ? encrypt_decript.Decript(obj.last_name) : "";
            this.clntGender = obj.gender != null && (encrypt_decript.Decript(obj.gender) != "") ? encrypt_decript.Decript(obj.gender) : "";
            this.clntContNumber = obj.mobile != null && obj.mobile != "" && obj.mobile != undefined && (encrypt_decript.Decript(obj.mobile) != "") ? encrypt_decript.Decript(obj.mobile) : "";
            this.clntEmergNumber = obj.emerg_contact1 != null && obj.emerg_contact1 != "" && obj.emerg_contact1 != undefined && (encrypt_decript.Decript(obj.emerg_contact1) != "") ? encrypt_decript.Decript(obj.emerg_contact1) : "";
            this.clntEmergNumber1 = obj.emerg_contact2 != null && obj.emerg_contact2 != "" && obj.emerg_contact2 != undefined && (encrypt_decript.Decript(obj.emerg_contact2) != "") ? encrypt_decript.Decript(obj.emerg_contact2) : "";

            if (obj.dob != 'null') {
              this.clntDOB = obj.dob;
              this.selectDob(this.clntDOB)

            } else {
              this.clntDOB = null;
              this.dobDisabledFlag = true;
              this.ageRead = false;
            }

            if (obj.age != undefined) {
              this.clntAge = obj.age;
              
            } else {
              this.clntAge="";
            }
            
            if (this.appType != "New") {
              this.clntSymptom = obj.symptom;
            }

            this.clntFatherOrGuardName = this.checkData(obj.guardian);
            if (obj.address1 != null && obj.address1 != "") {
              this.clntAddress1 = encrypt_decript.Decript(obj.address1);
            }

            if (obj.address2 != null && obj.address2 != "") {
              this.clntAddress2 = encrypt_decript.Decript(obj.address2);
            }
            this.clntLocation = this.checkData(obj.location_desc);
            this.clntLocationId = this.checkData(obj.location);
            this.clntCountry = this.checkData(obj.country_desc);
            this.clntCountryId = this.checkData(obj.country);
            this.clntState = this.checkData(obj.state_desc);
            this.clntStateId = this.checkData(obj.state);
            this.clntCity = this.checkData(obj.city_desc);
            if (obj.address2 != null && obj.address2 != "") {
              this.clntZipcode = encrypt_decript.Decript(obj.zipcode);
            }

            this.getBillType();
          }
          this.retrvDisabledFlag = true;
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  getDoctorDetails() {
    this.sendDoctorDetailData = {
      doc_reg_id: this.doctorId,
      location: this.userInfo.location,
      country: ipaddress.country_code,
      home_care: "0",
    }

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    this.http.post(this.doctorDetailUrl, this.sendDoctorDetailData, { headers: headers }).subscribe(
      data => {
        this.doctorDetailData = [];
        var dataval = JSON.parse(JSON.stringify(data));
        if (dataval.prov_id != null) {
          this.doctorId = dataval.prov_id;
          if (dataval.non_avail != null)
            this.docUnavailableData = dataval.non_avail;
          this.doctorProfileImg = ipaddress.Ip_with_img_address + dataval.profile_image;
        }

        if (dataval.consultation != null) {// Normal Consultation
          this.doctorDetailData = [];
          this.hospitalListData = [];

          for (var l = 0; l < dataval.consultation.length; l++) {
            this.tempFee = dataval.consultation[l].fees != "0" ? dataval.consultation[l].fees : "NA";

            var temp_address;
            if (this.gservice.get_Array_Contains(this.hospitalListData, "hospital_id", dataval.consultation[l].hospital_id) == false) {
              if (dataval.consultation[l].address2 != null && dataval.consultation[l].address2 != "") {
                temp_address = dataval.consultation[l].address + ", " + dataval.consultation[l].address2;
              } else {
                temp_address = dataval.consultation[l].address;
              }
              this.hospitalListData.push({
                hospital_id: dataval.consultation[l].hospital_id,
                Hospname: dataval.consultation[l].hospital,
                Location: dataval.consultation[l].location,
                Address_1: temp_address,
                City: dataval.consultation[l].city,
                fees: this.tempFee,
              });
            }

            if (this.hospitalListData.length == 1)
              this.hosptlId = this.hospitalListData[0].hospital_id;

            this.doctorDetailData.push({
              hospitalid: dataval.consultation[l].hospital_id,
              hospital: dataval.consultation[l].hospital,
              location: dataval.consultation[l].location,
              day: this.gservice.get_Day(dataval.consultation[l].day),
              day_num: dataval.consultation[l].day,
              session: this.gservice.get_Session(dataval.consultation[l].session),
              available_from: this.gservice.get_Timeformate(dataval.consultation[l].available_from),
              available_to: this.gservice.get_Timeformate(dataval.consultation[l].available_to),
              fees: this.tempFee,
              TokenorTime: dataval.consultation[l].token_flag,
              Time_Duration: dataval.consultation[l].time_duration,
              Avail_from: dataval.consultation[l].available_from,
              Avail_to: dataval.consultation[l].available_to
            });
          }

          if (this.doctorDetailData[0].TokenorTime == "true") {
            this.typeorTime = "type";
            this.appTypeTokenFlag = true;
            this.appTypeTimeFlag = false;
            this.apptType = "Token";
          } else {
            this.typeorTime = "time";
            this.appTypeTokenFlag = false;
            this.appTypeTimeFlag = true;
          }

          if (localStorage.getItem('app_flow') == "search_hospital") {
            for (var o = 0; o < this.hospitalListData.length; o++) {
              if (this.hospitalListData[o].Hospname == localStorage.getItem('doc_search')) {
                this.hospitalId = this.hospitalListData[0].hospital_id;
                this.hospitalName = this.hospitalListData[o].Hospname;
                this.hospSltLocation = this.hospitalListData[o].Location;
                this.hospSltFee = this.hospitalListData[o].fees;
                break;
              }
            }
          } else {
            this.hospitalId = this.hospitalListData[0].hospital_id;
            this.hospitalName = this.hospitalListData[0].Hospname;
            this.hospSltLocation = this.hospitalListData[0].Location;
            this.hospSltFee = this.hospitalListData[0].fees;
          }

          this.addSession(this.hospitalId);
          var parts = this.currentDate.split('-');
          this.availableFunction(parts[2] + "-" + parts[1] + "-" + parts[0], this.currentTime); // Available or Unavailable for today
        }

        if (dataval.home_consult != null) {// HomeCare Consult
          this.homecareTableData = [];
          this.homecareSessionData = [];

          for (var i = 0; i < dataval.home_consult.length; i++) {
            this.homecareTableData.push({
              Session: this.gservice.get_Session(dataval.home_consult[i].session),
              Avail_From: this.gservice.get_Timeformate(dataval.home_consult[i].available_from),
              Avail_To: this.gservice.get_Timeformate(dataval.home_consult[i].available_to),
              Fees: dataval.home_consult[i].fees,
              Time_Inter: dataval.home_consult[i].time_interval,
              avail_from: dataval.home_consult[i].available_from,
              avail_to: dataval.home_consult[i].available_to
            });
            this.homecareSessionData.push(this.gservice.get_Session(dataval.home_consult[i].session));
            this.addSession("")
          }
        }
      },
      error => { });
  }

  appDateChange(appdate) {
    this.timeList = []; // clear app time list

    if (this.currentDate != appdate) {
      var gsDayNames = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
      ];

      var get_date = appdate.split('-');
      var result = new Date(get_date[2] + "-" + get_date[1] + "-" + get_date[0]);
      var dayName = gsDayNames[result.getDay()];
      var get_sel_day_num = this.gservice.get_Day_num(dayName);
      var Available_flag_1 = false;

      for (var i = 0; i < this.doctorDetailData.length; i++) {
        if (this.doctorDetailData[i].hospital_id == this.hospitalId) {
          if (this.doctorDetailData[i].day_num == "8" || (get_sel_day_num != 1 && this.doctorDetailData[i].day_num == "9")) {
            Available_flag_1 = true;
          } else {
            if (((new Date(appdate)).getDay() + 1) == this.doctorDetailData[i].day_num) {
              Available_flag_1 = true;
            }
          }
        }
      }

      if (Available_flag_1 == false) {
        this.availableFlag = false;
        this.nonAvailableFlag = true;
      } else {
        this.availableFlag = true;
        this.nonAvailableFlag = false;
      }
      this.addSession(this.hospitalId);
      this.changeVisitSession(this.visitSession, appdate);
      this.availableFunction((appdate), this.currentTime);
    } else {
      this.addSession(this.hospitalId);
      this.changeVisitSession(this.visitSession, appdate);
      this.availableFunction((appdate), this.currentTime);
    }
  }

  addSession(Hospital) {
    this.visitingSessionData = [];
    var gsDayNames = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ];

    if (Hospital == "") {
      var get_date = Date_Formate(this.homecareAppDate);
      var curr_datetime = new Date(Date_Formate(get_date) + " " + this.currentTime);
      var cuur_mor_sess = "", cuur_afetr_sess = "", cuur_eve_sess = "";

      if (curr_datetime.getHours() >= 0 && curr_datetime.getHours() < 12) {
        cuur_mor_sess = "Morning";
        cuur_afetr_sess = "Afternoon";
        cuur_eve_sess = "Evening";
      }

      if (curr_datetime.getHours() >= 12 && curr_datetime.getHours() < 16) {
        cuur_afetr_sess = "Afternoon";
        cuur_eve_sess = "Evening";
      }

      if (curr_datetime.getHours() >= 16 && curr_datetime.getHours() < 24) {
        cuur_eve_sess = "Evening";
      }

      for (var a = 0; a < this.homecareSessionData.length; a++) {
        if (this.currentDate.trim() == get_date.trim()) { // Today 
          if (this.homecareSessionData[a] == "Morning" && cuur_mor_sess == "Morning") {
            this.visitingSessionData.push({
              description: "Morning",
              id: "1"
            });
          }

          if (this.homecareSessionData[a] == "Afternoon" && cuur_afetr_sess == "Afternoon") {
            this.visitingSessionData.push({
              description: "Afternoon",
              id: "2"
            });
          }

          if (this.homecareSessionData[a] == "Evening" && cuur_eve_sess == "Evening") {
            this.visitingSessionData.push({
              description: "Evening",
              id: "3"
            });
          }
        } else {
          if (this.homecareSessionData[a] == "Morning") {
            this.visitingSessionData.push({
              description: "Morning",
              id: "1"
            });
          }

          if (this.homecareSessionData[a] == "Afternoon") {
            this.visitingSessionData.push({
              description: "Afternoon",
              id: "2"
            });
          }

          if (this.homecareSessionData[a] == "Evening") {
            this.visitingSessionData.push({
              description: "Evening",
              id: "3"
            });
          }
        }
      }
    } else {
      this.appCurrentDate = this.appointmentDate;
      var get_date = Date_Formate(this.appCurrentDate);
      var curr_datetime = new Date(Date_Formate(get_date) + " " + this.currentTime);
      var cuur_mor_sess = "", cuur_afetr_sess = "", cuur_eve_sess = "";

      if (curr_datetime.getHours() >= 0 && curr_datetime.getHours() < 12) {
        cuur_mor_sess = "Morning";
        cuur_afetr_sess = "Afternoon";
        cuur_eve_sess = "Evening";
      }

      if (curr_datetime.getHours() >= 12 && curr_datetime.getHours() < 16) {
        cuur_afetr_sess = "Afternoon";
        cuur_eve_sess = "Evening";
      }

      if (curr_datetime.getHours() >= 16 && curr_datetime.getHours() < 24) {
        cuur_eve_sess = "Evening";
      }

      for (var a = 0; a < this.doctorDetailData.length; a++) {
        var dayName = gsDayNames[curr_datetime.getDay()];
        var sel_day_num = this.gservice.get_Day_num(dayName);
        var day_num = this.doctorDetailData[a].day_num;

        let dayflag = false;
        if (dayName == this.doctorDetailData[a].day)
          dayflag = true;
        else if (this.doctorDetailData[a].day == "All Days")
          dayflag = true;
        else if (sel_day_num != 1 && day_num == 8)
          dayflag = true;

        if (this.doctorDetailData[a].hospitalid == Hospital
          && dayflag == true) {
          if (this.currentDate.trim() == get_date.trim()) { // Today 
            if (this.doctorDetailData[a].session == "Morning" && cuur_mor_sess == "Morning") {
              this.visitingSessionData.push({
                description: "Morning",
                id: "1"
              });
            }

            if (this.doctorDetailData[a].session == "Afternoon" && cuur_afetr_sess == "Afternoon") {
              this.visitingSessionData.push({
                description: "Afternoon",
                id: "2"
              });
            }

            if (this.doctorDetailData[a].session == "Evening" && cuur_eve_sess == "Evening") {
              this.visitingSessionData.push({
                description: "Evening",
                id: "3"
              });
            }
          } else {
            if (this.doctorDetailData[a].session == "Morning") {
              this.visitingSessionData.push({
                description: "Morning",
                id: "1"
              });
            }

            if (this.doctorDetailData[a].session == "Afternoon") {
              this.visitingSessionData.push({
                description: "Afternoon",
                id: "2"
              });
            }

            if (this.doctorDetailData[a].session == "Evening") {
              this.visitingSessionData.push({
                description: "Evening",
                id: "3"
              });
            }
          }
        }
      }
    }
    this.visitSession = this.visitingSessionData[0].id;
    this.changeVisitSession(this.visitingSessionData[0].description, this.appointmentDate)
  }

  checkAvailabilty(get_sel_date, sess, f_time, t_time) {
    var Available_flag = true;
    var check_date = new Date(get_sel_date);

    if (this.docUnavailableData.length != 0) {
      for (var i = 0; i < this.docUnavailableData.length; i++) {
        if (this.docUnavailableData[i].full_day != null) {
          if (check_date >= new Date(this.docUnavailableData[i].from_date)
            && check_date <= new Date(this.docUnavailableData[i].to_date)) {
            Available_flag = false;
            break;
          }
        } else {
          if (sess != null) {
            // get unavialable date
            var From_Split = this.docUnavailableData[i].from_time.split(':');
            var To_Split = this.docUnavailableData[i].to_time.split(':');

            var t1 = new Date(check_date);
            t1.setHours(From_Split[0]);
            t1.setMinutes(From_Split[1]);

            var t2 = new Date(check_date); // t_time
            t2.setHours(To_Split[0]);
            t2.setMinutes(To_Split[1]);

            var get_f_time = this.gservice.get_time_form_session(f_time, this.gservice.get_seesion_ampm(sess));
            var get_t_time = this.gservice.get_time_form_session(t_time, this.gservice.get_seesion_ampm(sess));

            var get_time1 = this.convertTimeformat("24", get_f_time);
            var get_time2 = this.convertTimeformat("24", get_t_time);

            // get available date
            var From_Split1 = get_time1.split(':');
            var To_Split1 = get_time2.split(':');

            var get_f_time1 = get_time1.split(':');
            var get_f_time2 = get_time2.split(':');

            var f_time1 = new Date(check_date);
            f_time1.setHours(parseInt(get_f_time1[0]));
            f_time1.setMinutes(parseInt(get_f_time1[1]));

            var get_chk_date = this.getDateMatePicker1(check_date);
            var get_f_date = this.docUnavailableData[i].from_date;
            var get_t_date = this.docUnavailableData[i].from_date;

            if (get_chk_date.toString() == get_f_date.toString()
              && sess == this.docUnavailableData[i].session
              || get_chk_date.toString() == get_t_date.toString()
              && sess == this.docUnavailableData[i].session) {
              if (f_time1 > t1 && f_time1 < t2)
                Available_flag = false;

              if ((f_time1 == t1 && f_time1 <= t2))
                Available_flag = false;

              if ((f_time1 >= t1 && f_time1 == t2))
                Available_flag = false;

              if ((f_time1 == t1 && f_time1 == t2))
                Available_flag = false;
            }

            if (get_chk_date.toString() == get_f_date.toString()
              && get_chk_date.toString() == get_t_date.toString()
              && sess == this.docUnavailableData[i].session) {

              if (f_time1 > t1 && f_time1 < t2)
                Available_flag = false;

              if ((f_time1 == t1 && f_time1 < t2))
                Available_flag = false;

              if ((f_time1 > t1 && f_time1 == t2))
                Available_flag = false;

              if ((f_time1 == t1 && f_time1 == t2))
                Available_flag = false;
            }
          }
        }
      }
    }
    return Available_flag;
  }

  getDateMatePicker1(e) {
    var month = ((e.getMonth() + 1) < 10 ? '0' : '') + (e.getMonth() + 1);
    var day = (e.getDate() < 10 ? '0' : '') + e.getDate();
    var apt = e.getFullYear() + "-" + month + "-" + day;
    return apt.trim();
  }

  convertTimeformat(format, str) {
    var time = str;
    var hours = Number(time.match(/^(\d+)/)[1]);
    var minutes = Number(time.match(/:(\d+)/)[1]);
    var AMPM = time.match(/\s(.*)$/)[1];
    if (AMPM == "PM" && hours < 12) hours = hours + 12;
    if (AMPM == "AM" && hours == 12) hours = hours - 12;
    var sHours = hours.toString();
    var sMinutes = minutes.toString();
    if (hours < 10) sHours = "0" + sHours;
    if (minutes < 10) sMinutes = "0" + sMinutes;
    return (sHours + ":" + sMinutes);
  }

  book(flow) {// App Booking
    var valid_flag = false;
    var vsession;
    if (flow == "booking") {
      if (this.clntSal == "select") {
        this.toastr.error(Message_data.sltSalutation);
        valid_flag = true;
      }

      if (this.clntAge == "" || this.clntAge == undefined || this.clntAge == null) {
        this.toastr.error(Message_data.ageMandatory);
        valid_flag = true;
      }

      if (this.splName == "Pediatrics") {
        let str = this.clntAge.toString();
        let check = str.includes("d");
        if (check == false && 16 < parseInt(this.clntAge)) {
          this.toastr.error(Message_data.splOnlyForChild);
          valid_flag = true;
        }

      } else if (this.splName == "Gynecology") {
        if (this.clntGender != "Female") {
          this.toastr.error(Message_data.splOnlyForwomen);
          valid_flag = true;
        }
      }

      if (this.visitSession != "" && this.visitSession != undefined) {
        vsession = this.visitSession;
        this.visitSession = encrypt_decript.Encript(this.visitSession).toString()

      } else {
        this.toastr.error(Message_data.sltSession);
        valid_flag = true;
      }

    } else {
      if (this.clntSal == "select") {
        this.toastr.error(Message_data.sltSalutation);
        valid_flag = true;
      }

      if (this.clntAddress1 == "" || this.clntAddress1 == null) {
        this.toastr.error(Message_data.enterPatAddr);
        valid_flag = true;
      }
    }

    if (this.clntSymptom != undefined && this.clntSymptom != "") {
      var new_symptoms:any = [];
      if (this.clntSymptom.indexOf(',') > -1) {
        var dataval = this.clntSymptom.trim().split(",");
        for (var i = 0; i < dataval.length; i++) {
          if (this.newSymptoms.length != 0) {
            for (var j = 0; j < this.newSymptoms.length; j++) {
              if (this.newSymptoms[j] != dataval[i]) {
                new_symptoms.push(dataval[i]);
              }
            }
          }
        }

      } else {
        if (this.newSymptoms.length != 0) {
          for (var j = 0; j < this.newSymptoms.length; j++) {
            if (this.newSymptoms[j] != this.clntSymptom.trim()) {
              new_symptoms.push(this.clntSymptom.trim());
            }
          }
        } else {
          new_symptoms.push(this.clntSymptom.trim());
        }
      }
    }

    if (valid_flag == false) {
      var Provider = "doctor";
      if (localStorage.getItem('app_flow') == "nurse_physio_app") {
        Provider = localStorage.getItem('type');
      }

      if (this.visitTime != undefined) {
        var app_time = this.visitTime.toString().split(" ");
        this.tokenorTime = this.visitTime.toString();
        if (this.tokenorTime != "true") {
          if (this.tokenorTime != undefined) {
            this.timeOfAppt = app_time[0] + ":00";
          }
        } else
          this.timeOfAppt = "1";
      } else
        this.timeOfAppt = "1";

      this.sendAppType = this.appType == "New" ? "1" : "2";

      if (this.clntAge != null)
        var client_app_age = this.clntAge;

      if (this.clntSymptom != null)
        var clnt_symtom_data = this.clntSymptom;

      if (this.clntMiddleName != null)
        var clnt_middle_name = encrypt_decript.Encript(this.clntMiddleName).toString();

      if (this.clntDOB != null) {
        var cdob = this.clntDOB;
        var client_send_dob = cdob;
      }

      if (this.subRelId != null)
        var client_sub_rel_id = this.subRelId;

      if (this.homeSessionTableData.length != 0) {
        this.homeApps = [];
        for (var i = 0; i < this.homeSessionTableData.length; i++) {
          var dateval = this.homeSessionTableData[i].Date.split('-');
          this.homeApps.push({
            session: encrypt_decript.Encript(this.homeSessionTableData[i].Session).toString(),
            date: dateval[2] + "-" + dateval[1] + "-" + dateval[0],
            app_time: this.homeSessionTableData[i].app_time,
          });
        }
      }

      var token_flag;
      token_flag = this.typeorTime == "time" ? false : true;

      var apptype;
      apptype = this.appType == "New" ? "1" : "2";

      var addr1;
      addr1 = this.clntAddress1 != null ? encrypt_decript.Encript(this.clntAddress1).toString() : null;

      var addr2;
      addr2 = this.clntAddress2 != null ? encrypt_decript.Encript(this.clntAddress2).toString() : null;

      var address:any = [];
      if (flow == "booking") {
        address.push({
          address1: addr1,
          address2: addr2,
          location: this.clntLocationId,
          city: this.clntCityId,
          state: this.clntStateId,
          country: this.clntCountryId,
          zipcode: encrypt_decript.Encript(this.clntZipcode).toString(),
          type: "pres"
        });

      } else {   // update client details
        address.push({
          address1: addr1,
          address2: addr2,
          location: this.clntLocationId,
          city: this.clntCityId,
          state: this.clntStateId,
          country: this.clntCountryId,
          zipcode: encrypt_decript.Encript(this.clntZipcode).toString(),
          type: "pres",
          is_permanent: "1"
        });
      }

      var relid;
      relid = this.relationId != undefined ? this.relationId : "1";

      if (client_sub_rel_id != undefined && client_sub_rel_id != null) {
        client_sub_rel_id = client_sub_rel_id;
        this.subRelId = client_sub_rel_id;
      }

      var bpval;
      bpval = this.BP01 != null ? this.BP01 + "/" + this.BP02 : "";

      var clientFName, lastName, gender, contactNo, emgContNo, emgContNo1;
      if (this.clntFirstName != null && this.clntFirstName != undefined)
        clientFName = encrypt_decript.Encript(this.clntFirstName).toString();

      if (this.clntLastName != null && this.clntLastName != undefined)
        lastName = encrypt_decript.Encript(this.clntLastName).toString();

      if (this.clntGender != null && this.clntGender != undefined)
        gender = encrypt_decript.Encript(this.clntGender).toString();

      if (this.clntContNumber != null && this.clntContNumber != undefined)
        contactNo = encrypt_decript.Encript(this.clntContNumber).toString();

      if (this.clntEmergNumber != null && this.clntEmergNumber != undefined)
        emgContNo = encrypt_decript.Encript(this.clntEmergNumber).toString();

      if (this.clntEmergNumber1 != null && this.clntEmergNumber1 != undefined)
        emgContNo1 = encrypt_decript.Encript(this.clntEmergNumber1).toString();

      this.dataForApptBook = {
        prov_id: this.doctorId,
        provider: Provider,
        client_id: this.clientRegId,
        relation_id: relid,
        sub_relation_id: client_sub_rel_id,
        salutation: this.clntSal,
        first_name: clientFName,
        middle_name: clnt_middle_name,
        last_name: lastName,
        gender: gender,
        age: client_app_age,
        dob: client_send_dob,
        mem_mobile: contactNo,
        emerg_contact1: emgContNo,
        emerg_contact2: emgContNo1,
        symptom: clnt_symtom_data,
        app_type: apptype,
        address: address,
        guardian: this.clntFatherOrGuardName,
        medicare_id: "1",
        specialization_id: this.splId,
        session: this.visitSession,
        date: (this.appointmentDate),
        token: token_flag,
        time: this.timeOfAppt,
        hptl_id: this.hosptlId,
        home_care: this.homecare,
        payment_type: this.paydesc,
        ref_by: this.referredDoctor,
        height: this.height,
        height_measure: this.hmeasure,
        weight: this.weight,
        weight_measure: this.wmeasure,
        bmi: this.bmi,
        temparature: this.temperature,
        blood_pressure: bpval,
        spo2: this.spo_txt,
        pulse: this.pulse_txt,
        cvs:this.cvs_txt,
        rstxt:this.rstxt,
        cns:this.cns_txt,
        abd:this.abd_txt,
        newsymptoms: new_symptoms,
        country:ipaddress.country_code,
      };
      
      this.sendUpdateData = {
        client_id: this.clientRegId,
        relation_id: relid,
        sub_rel_id: client_sub_rel_id,
        salutation: this.clntSal,
        // Client
        first_name: clientFName,
        middle_name: clnt_middle_name,
        last_name: lastName,
        gender: gender,
        age: client_app_age,
        dob: client_send_dob,
        mobile: contactNo,
        emerg_contact1: emgContNo,
        emerg_contact2: emgContNo1,
        address1: addr1,
        address2: addr2,
        location: this.clntLocationId,
        city: this.clntCityId,
        state: this.clntStateId,
        country: this.clntCountryId,
        zipcode: encrypt_decript.Encript(this.clntZipcode).toString(),
        guardian: this.clntFatherOrGuardName,
        newsymptoms: new_symptoms,
        rep: true,
      };

      if (client_sub_rel_id != undefined && client_sub_rel_id != null && client_sub_rel_id != "")
        this.sendUpdateData.sub_rel_id = client_sub_rel_id;

      if (flow == "booking") {
        this.bookingConfirm();

      } else { // update client details
        this.updateUserDetails();
      }
    } else {
      this.visitSession = vsession;
    }
  }

  bookingConfirm() {
    this.bookFlag = true;
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.apptBookUrl, this.dataForApptBook, { headers: headers }).subscribe(
      data => {
        var dataval = JSON.parse(JSON.stringify(data));
        if (dataval.status == "1") {
          if(this.clientRegId == "0"){
            this.clientRegId = dataval.client_id;
          }
          this.toastr.success(dataval.result);
          var billData;
          if (this.userInfo.doc_app_bill_create == "1") {
            var apptype = this.appType == "New" ? "1" : "2";
            var paid_amount = 0;
            if(this.clientType == "Existing"){
              for(var t=0;t<this.userInfo.charges.length;t++){
                if(this.userInfo.charges[t].charge_desc.substring(0,3) =="REG"){
                  this.userInfo.charges.splice(t,1)
                }
              }
            }
            var sno=1;
            // for (var b = 0; b < this.userInfo.charges.length; b++) {
            //   paid_amount += parseInt(this.userInfo.charges[b].amount)

            //   this.billaddList.push({
            //     sno:sno,
            //     biill_type: this.userInfo.charges[0].charge_desc,
            //     amount_txt: parseFloat(this.billamount).toFixed(2),
            //     concession: "0.00",
            //     fee: parseFloat(this.billamount).toFixed(2),
            //     charge_type: this.userInfo.charges[0].charge_id,
            //     pay_type: this.paydesc,
            //     paytype: this.payType,
            //     insurer: this.insurerId,
            //     ref_code: this.transactionId,
            //     quantity: "1",
            //   });
            //   sno+=1;
            // }

            this.billaddList.push({
              sno:sno,
              biill_type: this.userInfo.charges[0].charge_desc,
              amount_txt: parseFloat(this.billamount).toFixed(2),
              concession: "0.00",
              fee: parseFloat(this.billamount).toFixed(2),
              charge_type: this.userInfo.charges[0].charge_id,
              pay_type: this.paydesc,
              paytype: this.payType,
              insurer: this.insurerId,
              ref_code: this.transactionId,
              quantity: "1",
            });
            
            billData = {
              hptl_clinic_id: this.userInfo.hptl_clinic_id,
              appointment_id: dataval.app_id,
              app_type: "doctor",
              doc_reg_id: this.doctorId,
              bill_amount: encrypt_decript.Encript(this.billamount.toString()).toString(),
              paid_amount: this.billamount,
              paid_flag: "Fully paid",
              created_by: this.userInfo.user_id,
              bills: this.billaddList,
              country: ipaddress.country_code,
              client_reg_id: this.clientRegId,
              relation_id: this.relationId,
              sub_rel_id: this.subRelId,
              balance: "0.00",
              round_off: "0.00",
              pay_type: this.payType,
              insurer: this.insurerId,
              card_no: this.cardNumber,
              card_holder_name: this.cardHolderName,
              transaction_no: this.transactionId,
              bank_name: this.bankName,
              userid: this.userInfo.user_id,
            };

            this.http.post(this.billUrl, billData, { headers: headers }).subscribe(
              data => {
                var billresult = JSON.parse(JSON.stringify(data));

                if (billresult.key == "1") {
                  var getBillDetails = {
                    bill_view_id: billresult.bill_no,
                    fin_year: billresult.fin_year,
                    provider_type: "doctor",
                    doc_reg_id: this.doctorId,
                  }
                  FrontDesk_Helper.setreceptbilldetailview(getBillDetails);
                  this.frontdeskservice.sendMessage("receptbilldetail");
                  //this.frontseskservice.sendMessage("manageappointments");
                } else {
                  this.bookFlag = true;
                  this.toastr.error(dataval.result);
                }
              });
            //this.generateBill(billData);

          } else {
            this.frontdeskservice.sendMessage("manageappointments");
          }

        } else if(dataval.status=="2"){
          this.visitSession = encrypt_decript.Decript(this.dataForApptBook.session)
          this.bookFlag = false;
          this.toastr.error(dataval.result);
        } else {
          this.bookFlag = false;
          this.toastr.error(dataval.result);
        }
      },
      error => {
        this.toastr.error(error.json().result);
        this.bookFlag = false;
      });
  }

  calculateCalories() {
    if ((this.height != undefined && this.height.length != 0)
      || (this.weight != undefined && this.weight.length != 0)) {

      if (this.height != undefined && this.height.length != 0)
        this.encheight = encrypt_decript.Encript(this.height);

      if (this.weight != undefined && this.weight.length != 0)
        this.encweight = encrypt_decript.Encript(this.weight);
    }

    if (this.height == undefined || this.height == "" || this.weight == undefined || this.weight == "") {
      this.bmi = "";

    } else {
      if (this.height != undefined) {
        if (this.hmeasure == "cms")
          this.height = this.height.replace(/[^0-9]/g, '');
        else {
          this.height = this.height.replace('/[^-0-9\.]/g', '');

          if (this.height.indexOf('.') !== -1) {
            if (this.height.split('.').length > 2)
              this.height = this.height.height.slice(0, -1);
          }
        }

        if (this.hmeasure == "cms") {
          if (parseFloat(this.height) > 300)
            this.height = this.height.slice(0, -1);
        } else {
          if (parseFloat(this.height) > 100)
            this.height = this.height.slice(0, -1);
        }
      }

      if (this.wmeasure == "kgs" && this.hmeasure == "inch") {
        var pound = this.weight * 2.20462;
        var Height_txt = parseFloat(this.height)
        var dat = pound / (Height_txt * Height_txt);
        var to = dat * 703;
        this.bmi = Math.round(to);

      } else if (this.wmeasure == "kgs" && this.hmeasure == "cms") {
        var meter = parseFloat(this.height) / 100.00;
        var to = parseFloat(this.weight) / (meter * meter);
        this.bmi = Math.round(to);

      } else if (this.wmeasure == "lbs" && this.hmeasure == "inch") {
        var Height_txt = parseFloat(this.height);
        var dat = this.weight / (Height_txt * Height_txt);
        var to = dat * 703;
        this.bmi = Math.round(to);

      } else if (this.wmeasure == "lbs" && this.hmeasure == "cms") {
        var Height_txt = parseFloat(this.height);
        var inch = Height_txt * 0.393701;
        var dat = this.weight / (inch * inch);
        var to = dat * 703;
        this.bmi = Math.round(to);
      }
    }
  }

  getDateMatePickerCalendar(e) {
    var month = ((e.getMonth() + 1) < 10 ? '0' : '') + (e.getMonth() + 1);
    var day = (e.getDate() < 10 ? '0' : '') + e.getDate();
    var apt = e.getFullYear() + "-" + month + "-" + day;
    return apt.trim();
  }

  validateMobile(){
    if(this.clntContNumber.length !=10){
      this.toastr.error("Enter valid mobile number")
      this.clntContNumber=undefined;
    }
    if (this.clientType == "Existing") {
      this.updateFlag = true;
    }
  }

  updateUserprofile() {
    if (this.clientType == "Existing") {
      this.updateFlag = true;
    } 
  }

  calculateDOB(){
    this.nodob = true;
    var dateval = this.appCurrentDate.split("-");
    if(this.clntDOB == null){
      this.clntDOB = (parseInt(dateval[0]) - parseInt(this.clntAge))+"-01-01";
    }
  }

  updateUserDetails() {
    var update_url;
    if (this.subRelId != "" && this.subRelId != null && this.subRelId != undefined)
      update_url = ipaddress.getIp + "usercontroller/ureld/";
    else
      update_url = ipaddress.getIp + "usercontroller/updclnt";

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(update_url, this.sendUpdateData, { headers: headers }).subscribe(
      data => {
        var obj = JSON.parse(JSON.stringify(data));
        if (obj.key == "1") {
          this.updateFlag = false;
          this.toastr.success(obj.result);
        } else {
          this.updateFlag = false;
          this.toastr.error(obj.result);
        }
      },
      error => {
        this.updateFlag = false;
        this.toastr.error(error.json().result);
      })
  }

  availableFunction(curtdate, curttime) {
    var Available_flag = false;
    var gsDayNames = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ];
    var get_date = (curtdate).split('-');
    var get_sel_date = new Date(get_date[0] + "-" + get_date[1] + "-" + get_date[2]);
    var get_date = (curtdate).split('-');
    var result = new Date(get_date[2] + "-" + get_date[1] + "-" + get_date[0]);
    var dayName = gsDayNames[result.getDay()];
    var get_sel_day_num = this.gservice.get_Day_num(dayName);

    for (var i = 0; i < this.doctorDetailData.length; i++) {
      var get_day_num = this.gservice.get_Day_num(this.doctorDetailData[i].day);

      if (this.doctorDetailData[i].hospital == this.hospitalName) {
        var Cur_timesplit = [];
        Cur_timesplit = curttime.split(':');
        var Curre_time = new Date(get_sel_date);
        Curre_time.setHours(Cur_timesplit[0]);
        Curre_time.setMinutes(Cur_timesplit[1]);
        var Avtotimesplit = [];
        Avtotimesplit = this.doctorDetailData[i].Avail_to.split(':');
        var Retrivel_time = new Date(get_sel_date);
        Retrivel_time.setHours(Avtotimesplit[0]);
        Retrivel_time.setMinutes(Avtotimesplit[1]);
        if (this.doctorDetailData[i].day_num == "9" || (get_sel_day_num != 1 && get_day_num == 8)) {
          if (this.currentDate == (curtdate)) {
            if (Retrivel_time > Curre_time)
              Available_flag = true;
          } else {
            Available_flag = true;
          }
        } else {
          if ((new Date(curtdate)).getDay() + 1 == this.doctorDetailData[i].day_num) {
            if (this.currentDate == Date_Formate(curtdate)) {
              if (Retrivel_time > Curre_time && this.currentDate == this.gservice.get_Date_Formate(curtdate)) {
                Available_flag = true;
              }
            } else {
              Available_flag = true;
            }
          }
        }
      }
    }

    Available_flag = this.checkAvailabilty(curtdate, null, null, null);
    if (this.visitingSessionData.length == 0) {
      Available_flag = false;
    }
    if (Available_flag == false) {
      this.availableFlag = false;
      this.nonAvailableFlag = true;
    } else {
      this.availableFlag = true;
      this.nonAvailableFlag = false;
    }
  }

  changeVisitSession(session, appdate) {
   
    //this.bookFlag = true;
    this.updateFlag = false;
    this.timeList = [];
    if (this.typeorTime == "time") {
      for (var i = 0; i < this.doctorDetailData.length; i++) {
        if (session == this.doctorDetailData[i].session) {
          var From_Split = this.doctorDetailData[i].Avail_from.split(':');
          var To_Split = this.doctorDetailData[i].Avail_to.split(':');
          var fromday = new Date();
          var parts = (appdate).split('-');
          var datetime = new Date(parts[2] + "-" + parts[1] + "-" + parts[0] + " " + this.currentTime);
          var curr_datetime = new Date(datetime + " " + this.currentTime);
          var curr_get_ftime = new Date(datetime + " " + this.doctorDetailData[i].Avail_from);
          fromday.setHours(From_Split[0]);
          fromday.setMinutes(From_Split[1]);
          var today = new Date();
          today.setHours(To_Split[0]);
          today.setMinutes(To_Split[1]);
          var New_time1 = this.datepipe.transform(fromday, 'hh:mm a');
          var chk_time = this.datepipe.transform(fromday, 'hh:mm');
          var end_time = this.datepipe.transform(today, 'hh:mm');
          var Available_flag = this.checkAvailabilty(appdate, session, chk_time, end_time);
          if (Available_flag == true && this.currentDate == appdate && curr_get_ftime > curr_datetime) {
            this.timeList.push(New_time1);
          } else {
            if (Available_flag == true && this.currentDate != appdate) {
              this.timeList.push(New_time1);
            }
          }
          var substr = this.doctorDetailData[i].Time_Duration;
          while (fromday < today) {
            var Day_Now = new Date();
            if (this.currentDate == appdate) {
              var Now_Split = this.currentTime.split(':');
              Day_Now.setHours(parseInt(Now_Split[0]));
              Day_Now.setMinutes(parseInt(Now_Split[1]));
            } else {
              Day_Now.setHours(From_Split[0]);
              Day_Now.setMinutes(From_Split[1]);
            }
            fromday.setMinutes(fromday.getMinutes() + parseInt(this.doctorDetailData[i].Time_Duration));
            var chk_time1 = this.datepipe.transform(fromday, 'hh:mm');
            var Avail_flag = this.checkAvailabilty(appdate, session, chk_time1, end_time);

            if (Avail_flag == true && Day_Now <= fromday && fromday < today) {
              var New_time = this.datepipe.transform(fromday, 'hh:mm a');

              if (New_time != this.gservice.get_Timeformate(this.doctorDetailData[i].Avail_to)) {
                this.timeList.push(New_time);
              }
            }
          }
        }
      }
    }
  }

  selectDob(e) {
    if (this.clntDOB != undefined){
      this.ageRead = true;
      this.clntDOB = e;
      this.ageDisabledFlag = true;

      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(this.ageUrl, {
        dobdate: Date_Formate(this.clntDOB),
        curdate: Date_Formate(this.currentDatetime),
        spl: this.splId,
      }, { headers: headers }).subscribe(
        data => {
          var obj = JSON.parse(JSON.stringify(data));
          if (obj != undefined){
            this.clntAge = obj.value;
          }
        });
    } else {
      this.ageRead = false;
    }
  }

  getSymptoms() {
    var searchString;
    if (this.clntSymptom.indexOf(',') > -1) {
      var dataval = this.clntSymptom.split(",");
      searchString = dataval[dataval.length - 1].trim();
    } else {
      searchString = this.clntSymptom;
    }

    if (searchString.length > 2) {
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'adm/gsymbn/', {
        symp_name: searchString
      },
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            if (obj.symptoms != null && obj.symptoms != undefined && obj.symptoms != "" && obj.symptoms.length != 0) {
              this.symptomsMasterList = [];
              this.symptomList = [];
              this.symptomListData = [];
              this.symptomsMasterList = obj.symptoms;
              for (var i = 0; i < obj.symptoms.length; i++) {
                this.symptomListData.push(obj.symptoms[i].description);
              }

              this.symptomList = this.symptomListData.filter(function (el) {
                return el.toLowerCase().indexOf(searchString.toLowerCase()) > -1;
              }.bind(this));
            } else {
              this.symptomList = [];
            }
          },
          error => { });
    }
  }

  selectSymptom(data) {
    this.newSymptoms.push(data);
    for (var j = 0; j < this.symptomsMasterList.length; j++) {
      if (this.symptomsMasterList[j].description == data) {
        this.selectedSymptoms.push(data);
        break;
      }
    }

    if (this.clntSymptom.indexOf(',') > -1) {
      var dataval = this.clntSymptom.split(",");
      dataval.pop();
      this.clntSymptom = dataval.join(",");
      this.clntSymptom = this.clntSymptom + "," + data;
    } else {
      this.clntSymptom = data;
    }
    this.symptomList = [];
  }

  changeAge() {
    if (this.clntAge != "") {
      this.dobDisabledFlag = true;
      this.clntDOB = null;
    } else {
      this.dobDisabledFlag = false;
    }
  }

  changeSalutation(e) {
    if (e == "1" || e == "6")
      this.clntGender = "Male";
    else if (e == "2" || e == "3" || e == "4" || e == "7")
      this.clntGender = "Female";
    else if (e == "5")
      this.clntGender = "Transgender";
    else if (e == "8")
      this.clntGender = "Female";
    else if (e == "10")
      this.clntGender = "Male";
    else
      this.clntGender = "";
  }

  changeGender(e) {
    if (this.clntAge != undefined && this.clntAge != null && this.clntAge != "") {
      if (e == "Male") {
        if (this.clntAge <= 15)
          this.clntSal = "6";
        else
          this.clntSal = "1";
      } else if (e == "Female") {
        if (this.clntAge <= 15)
          this.clntSal = "7";
        else
          this.clntSal = "2";
      } else if (e == "Transgender") {
        this.clntSal = "5";
      } else {
        this.clntSal = "";
      }

    } else {
      if (e == "Male") {
        this.clntSal = "1";
      } else if (e == "Female") {
        this.clntSal = "2";
      } else if (e == "Transgender") {
        this.clntSal = "5";
      } else {
        this.clntSal = "";
      }
    }
  }

  changeType(type, id, value) {
    if (type == "app") {
      if (value == "yes") {
        this.apptTypeFlag = true;
        this.appType = "New";
        this.userTypeSelect('New');

      } else {
        this.apptTypeFlag = false;
        this.appType = "Follow-up";
        this.userTypeSelect('Follow-up');
      }

    } else {
      if (value == "yes") {
        this.clientTypeFlag = true;
        this.clientType = "New";
        this.userTypeSelect('New');
        this.relationId="1";
        this.appclass=false;
        this.appdisable= true;
        this.appType = "New";
        this.searchFlag = true;
        this.apptTypeFlag = true;

      } else {
        this.clientTypeFlag = false;
        this.clientType = "Existing";
        this.appclass=true;
        this.userTypeSelect('Existing');
        this.appdisable= false;
      }
    }
  }

  checkData(value) {
    var data = "";
    if (value != undefined && value != null && value != "" && value != "undefined" && value != "null")
      data = value;
    else
      data = "";
    return data;
  }

  getPatientListByName(name) {//get patient list
    if(name.length>2){
      var send_data={
        hptl_clinic_id: this.userInfo.hptl_clinic_id,
        country: ipaddress.country_code,
        type: this.searchby,
        name:encrypt_decript.Encript(this.patientName.toLocaleUpperCase()).toString(),
        prov_id: this.userInfo.user_id,
        location:"front-desk",
      }

      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'gen/gclnt',send_data,
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            console.log("verify this "+JSON.stringify(obj))
            this.patientList = [];
            this.filteredArr = [];
            if (obj.clients != null) {
              for (var i = 0; i < obj.clients.length; i++) {
                var name;
                if (obj.clients[i].middle_name != undefined) {
                  name = encrypt_decript.Decript(obj.clients[i].first_name) + " " + encrypt_decript.Decript(obj.clients[i].middle_name) + " " + encrypt_decript.Decript(obj.clients[i].last_name)
                } else {
                  name = encrypt_decript.Decript(obj.clients[i].first_name) + " " + encrypt_decript.Decript(obj.clients[i].last_name);
                }
                this.patientList.push({
                  client_reg_id: obj.clients[i].client_reg_id,
                  patient_id: obj.clients[i].patient_id,
                  relation_id: obj.clients[i].relation_id,
                  sub_rel_id: obj.clients[i].sub_rel_id,
                  first_name: encrypt_decript.Decript(obj.clients[i].first_name),
                  full_name: name,
                  mobile: encrypt_decript.Decript(obj.clients[i].mobile),
                });
  
                this.filteredArr.push({
                  client_reg_id: obj.clients[i].client_reg_id,
                  patient_id: obj.clients[i].patient_id,
                  relation_id: obj.clients[i].relation_id,
                  sub_rel_id: obj.clients[i].sub_rel_id,
                  first_name: encrypt_decript.Decript(obj.clients[i].first_name),
                  full_name: name,
                  mobile: encrypt_decript.Decript(obj.clients[i].mobile),
                });
              }
              //Helper_Class.setPatientList(this.patientList);
            }
          },
          error => { });
    } else {
      this.patientList = Helper_Class.getPatientList();
      this.filteredArr = Helper_Class.getPatientList();
    }
  }

  getPatientList() {//get patient list
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'gen/gac',
      {
        hptl_clinic_id: this.userInfo.hptl_clinic_id,
        country: ipaddress.country_code,
        imei: this.ipaddress,
        limit: 200,
        filterby: "Patient Id",
        type: "front-desk",
        start_no: this.currentLimit,
        prov_id: this.userInfo.user_id,
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          // this.patientList = [];
          // this.filteredArr = [];
          if (obj.clients != null) {
            
            for (var i = 0; i < obj.clients.length; i++) {
              var name;
              if (obj.clients[i].middle_name != undefined) {
                name = encrypt_decript.Decript(obj.clients[i].first_name) + " " + encrypt_decript.Decript(obj.clients[i].middle_name) + " " + encrypt_decript.Decript(obj.clients[i].last_name)
              } else {
                name = encrypt_decript.Decript(obj.clients[i].first_name) + " " + encrypt_decript.Decript(obj.clients[i].last_name);
              }
              this.patientList.push({
                client_reg_id: obj.clients[i].client_reg_id,
                patient_id: obj.clients[i].patient_id,
                relation_id: obj.clients[i].relation_id,
                first_name: encrypt_decript.Decript(obj.clients[i].first_name),
                full_name: name,
                mobile: encrypt_decript.Decript(obj.clients[i].mobile),
                sub_rel_id:obj.clients[i].sub_rel_id
              });

              this.filteredArr.push({
                client_reg_id: obj.clients[i].client_reg_id,
                patient_id: obj.clients[i].patient_id,
                relation_id: obj.clients[i].relation_id,
                first_name: encrypt_decript.Decript(obj.clients[i].first_name),
                full_name: name,
                mobile: encrypt_decript.Decript(obj.clients[i].mobile),
                sub_rel_id:obj.clients[i].sub_rel_id
              });
            }
            Helper_Class.setPatientList(this.patientList);
            console.log("PATIENT LIST SIZE IS "+this.filteredArr.length +" === "+ this.patientList.length)
          }
        },
        error => { });
  }

  tableFilter(e){
    this.patientName = e;
    if(this.patientName != undefined){
      this.filteredArr = this.patientList.filter(item => item.full_name.toLowerCase().includes(this.patientName.toLowerCase()));
    }
  }

  @HostListener('scroll', ['$event'])
  onScroll(event: Event): void {
    const element = this.scrollContainer.nativeElement;
    var stop = element.scrollTop;
    var oset = element.offsetHeight;
    var cval = stop+oset+2;
    
    if (cval >= element.scrollHeight) {
      this.currentLimit += 200;
      this.getPatientList();
      console.log("reached end of scroll ",this.currentLimit)
    }
  }

  validate(event: any) {
    const inputValue = event.target.value;
    const charCode = event.key.charCodeAt(0);

    // Allow digits, a single dot, backspace, and delete
    if (
      (charCode >= 48 && charCode <= 57) || // Digits
      charCode === 46 || charCode === 67 || charCode === 70 ||  
      event.key === 'Backspace' || // Backspace key
      event.key === 'Delete' // Delete key
    ) {
      // Check if a dot already exists
      const dotIndex = inputValue.indexOf('.');

      if (charCode === 46) {
        if (dotIndex !== -1 || inputValue === '') {
          event.preventDefault(); // Prevent typing an additional dot or starting with a dot
        }
      } else if (dotIndex !== -1) {
        // If there's a dot, allow backspace and delete to remove the digit after the dot
        if (event.key === 'Backspace' || event.key === 'Delete') {
          const decimalPart = inputValue.substr(dotIndex + 2);
          if (decimalPart.length === 1) {
            event.preventDefault();
            event.target.value = inputValue.substr(0, dotIndex); // Remove the digit after the dot
          }
        } else if (inputValue.substr(dotIndex + 2).length >= 1) {
          event.preventDefault(); // Prevent typing more than one digit after the dot
        }
      }
    } else {
      event.preventDefault(); // Prevent typing non-digit and non-dot characters
    }
  }

  getBillType() {
    //Get bill type
    this.billAddList = [];
    this.chargeName = [];
    this.chargeNameArray = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(
        ipaddress.getIp.toString() + 'bill/chgtype',
        {
          hptl_clinic_id: this.userInfo.hptl_clinic_id,
          provider: 'doctor',
        },
        { headers: headers }
      )
      .subscribe(
        (response) => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log(obj);
          if (obj.charges != undefined && obj.charges.length != 0) {
            if (this.is_new == '1') {
              this.chargeName = obj.charges;
            } else if (this.is_new == '0') {
              for (var j = 0; j < obj.charges.length; j++) {
                if (obj.charges[j].one_time == '1') {
                  obj.charges.splice(j, 1);
                  // break;
                } 
              }
              this.chargeName = obj.charges;
            }

            if (this.prev_charges != undefined && this.prev_charges.length != 0) {
              for (var i = 0; i < this.prev_charges.length; i++) {
                for (var j = 0; j < obj.charges.length; j++) {
                  if (this.prev_charges[i].charge_type == obj.charges[j].charge_id) {
                    obj.charges.splice(j, 1);
                  }
                }
                
              }
              this.chargeName = obj.charges;
            }

            console.log(this.chargeName);
            if (this.chargeName != undefined && this.chargeName.length != 0) {
              for (var i = 0; i < this.chargeName.length; i++) {
                this.chargeNameArray.push({
                  sno: i,
                  biill_type: this.chargeName[i].description,
                  amount_txt: parseFloat(this.chargeName[i].amount),
                  concession: this.concession,
                  fee: this.getamountAftCon1(this.chargeName[i].amount,this.quantity),
                  charge_type: this.chargeName[i].charge_id,
                  insurer: this.insurerId,
                  ref_code: this.transId,
                  quantity: this.quantity,
                  selected: false,
                });
              }
            }

            if (this.chargeName != undefined && this.chargeName.length != 0) {
              for (var i = 0; i < this.chargeName.length; i++) {
                if (
                  this.chargeName[i].auto_generate != null &&
                  this.chargeName[i].auto_generate == '1'
                ) {
                  this.chargeType = this.chargeName[i].description;
                  this.amount = parseFloat(this.chargeName[i].amount);
                  this.sendConcession = parseFloat(this.concession);
                  this.amountAftCon = this.getamountAftCon1(this.chargeName[i].amount,this.quantity);
                  this.chargeId = this.chargeName[i].charge_id;
                  this.insurerId = this.insurerId,                   
                  this.transId = this.transId,
                  this.quantity = this.quantity;
                  this.addBill();
                }
              }
            }
          }

          // if (this.billAddList.length == 0) {
          //   this.addNewBillDetails();
          // }
        },
        (error) => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      );
  }

  getamountAftCon1(amount,quantity) {
    var amount_aftcon;
    console.log(amount_aftcon = parseFloat(amount) * quantity - (this.concession / 100) * (parseFloat(amount) * quantity));
    return (amount_aftcon = parseFloat(amount) * quantity - (this.concession / 100) * (parseFloat(amount) * quantity));
  }


  addBill() {
    //Bill add table
    var flag = true;
    if (this.appointmentId == undefined) {
      this.toastr.error(Message_data.sltAppId);
      flag = false;
    } else if (this.chargeType == undefined) {
      flag = false;
      this.toastr.error(Message_data.sltBillType);
    } else if (this.amount == undefined || this.amount == '') {
      this.toastr.error(Message_data.enterAmt);
      flag = false;
    } else if (this.quantity == undefined) {
      this.toastr.error(Message_data.enterQty);
      flag = false;
    }
    
    if (this.finalAmount == 0) {
      this.inGST == false;
    }

    for (var i = 0; i < this.billAddList.length; i++) {
      if (
        this.billAddList[i].biill_type == this.chargeType &&
        this.billAddList[i].paytype == this.payType
      ) {
        flag = false;
        break;
      }
    }

    if (flag == true) {
      if (this.billingType != 'package') {
        if (this.chargeName.length != 0) {
          for (var i = 0; i < this.chargeName.length; i++) {
            if (this.chargeType == this.chargeName[i].description) {
              this.chargeId = this.chargeName[i].charge_id;
            }
          }
        }
      }

      var paydesc;
      if (this.payTypeList.length != 0) {
        for (var i = 0; i < this.payTypeList.length; i++) {
          if (this.payType == this.payTypeList[i].pay_id) {
            paydesc = this.payTypeList[i].pay_desc;
          }
        }
      }

      if (this.concession == '') {
        this.sendConcession = 0;
      } else {
        this.sendConcession = this.concession;
      }

      var total = 0;
      total = (parseFloat(this.quantity) * parseFloat(this.amount));
      var result = this.finekeyInArray_billaddChargeType(
        this.billAddList,
        this.chargeId
      );
      if (result == true) {
        this.toastr.error('Charge type already exists');
      } else if (this.paytypreq == true) {
        this.billAddList.push({
          sno: this.sno,
          biill_type: this.chargeType,
          amount_txt: parseInt(this.amount),
          concession: parseFloat(this.sendConcession),
          fee: parseFloat(this.amountAftCon),
          charge_type: this.chargeId,
          insurer: this.insurerId,
          ref_code: this.transId,
          quantity: this.quantity,
          total: total,
          type: 'hospital',
          edit_flag: true
        });
      } else {
        this.billAddList.push({
          sno: this.sno,
          biill_type: this.chargeType,
          amount_txt: parseInt(this.amount),
          concession: parseFloat(this.sendConcession),
          fee: parseFloat(this.amountAftCon),
          charge_type: this.chargeId,
          pay_type: paydesc,
          paytype: this.payType,
          insurer: this.insurerId,
          ref_code: this.transId,
          quantity: this.quantity,
          total: total,
          type: 'hospital',
          edit_flag: true
        });
      }
      this.sno = 1;
      for (var i = 0; i < this.billAddList.length; i++) {
        this.billAddList[i].sno = this.sno;
        this.sno += 1;
      }
      this.amount = '';
      this.amountAftCon = '';
      this.quantity = 1;
      this.chargeType = '';
    }

    if (this.finalAmount == 0) 
      this.inGST == false;
   
    this.calculateGst('0', '0');
  }

  finekeyInArray_billaddChargeType(array, charge_id) {
    var flag = false;
    for (var i = 0; i < array.length; i++) {
      if (array[i].charge_type == charge_id) {
        flag = true;
        break;
      }
    }
    return flag;
  }

  calculateGst(gst_click, num) {
    this.finalAmount = 0;
    this.totalBillAmount = 0;
    if (num != 1 ) {
      this.discount_amount = 0;
    }  
    this.gst_amount_txt = 0;
    this.total_collected = 0;
    this.current_amount = 0;
    this.gst_data_list = [];

     if (this.billAddList.length != 0) {
      if (this.billAddList.length != 0) {
        for (var i = 0; i < this.billAddList.length; i++) {
          if (this.billAddList[i].amount_txt == '') {
            this.billAddList[i].amount_txt = 0;
          }
          if (this.billAddList[i].quantity == '') {
            this.billAddList[i].quantity = 0;
          }
          if (this.billAddList[i].concession == '') {
            this.billAddList[i].concession = 0;
          }


          if (num != 1) {
            this.discount_amount = (
              parseFloat(this.discount_amount) +
              parseFloat(this.billAddList[i].amount_txt) *
                parseFloat(this.billAddList[i].quantity) *
                (parseInt(this.billAddList[i].concession) / 100)
            );

          }
          
          this.totalBillAmount = (
            parseFloat(this.totalBillAmount) +
            parseFloat(this.billAddList[i].amount_txt) *
              parseFloat(this.billAddList[i].quantity)
          );
        }
        this.current_amount = Math.round(parseFloat(this.current_amount) + parseFloat(this.hospitalBills));
        this.current_amount = parseFloat(this.current_amount);
        if (this.discount_amount != '' && this.discount_amount != 0) {          
          var floatValue = this.discount_amount;
          var floatString = floatValue.toString();
          var parts = floatString.split('.');
          var digitsAfterDecimal = parts[1] ? parts[1] : 0;
          if (digitsAfterDecimal[0] > 5) {
            this.discount_amount = Math.round(this.discount_amount);
            this.discount_amount = parseInt(this.discount_amount); 
          } else {
            this.discount_amount = parseInt(this.discount_amount);
          }                       
        }   
        
        if (this.inGST == false) {
          this.finalAmount = Math.round(parseFloat(this.finalAmount) + parseFloat(this.hospitalBills));
        } else {
          this.finalAmount = (
              parseFloat(this.finalAmount) + parseFloat(this.hospitalBills)
            );
        }
        this.finalAmount = parseFloat(this.finalAmount);
        this.gst_amount_txt = (
          parseFloat(this.gst_amount_txt) + parseFloat(this.hospitalBills)
        );

        if (parseFloat(this.Advance_bill) < parseFloat(this.finalAmount)) {
          this.balance_show = false;
          this.remaing_billbal = (
            parseFloat(this.finalAmount) - parseFloat(this.Advance_bill)
          );
        }

        if (parseFloat(this.Advance_bill) > parseFloat(this.finalAmount)) {
          this.balance_show = false;
          this.remaing_billbal = (
            parseFloat(this.Advance_bill) - parseFloat(this.finalAmount)
          );
          this.total_collected = 0;
        }
        if (this.total_collected == 0) {
          this.amount_collected = false;
        }

        if (this.remaing_billbal == 0) {
          this.balance_show = true;
        }
      }
      
      for (var i = 0; i < this.billAddList.length; i++) {
        if (this.billAddList[i].fee == 0 && this.billAddList[i].biill_type == '') {
          this.billAddList[i].fee = '';
        }
        if (this.billAddList[i].amount_txt == 0 && this.billAddList[i].biill_type == '') {
          this.billAddList[i].amount_txt = '';
        }
        if (this.billAddList[i].quantity == 0 && (this.billAddList[i].biill_type == '' || this.billAddList[i].biill_type != '')) {
          this.billAddList[i].quantity = '';
        }
        if (this.billAddList[i].total == 0 && this.billAddList[i].biill_type == '') {
          this.billAddList[i].total = '';
        }
        if (this.billAddList[i].biill_type == '') {
          this.billAddList[i].concession = '';
        }

        if (this.billAddList[i].concession != '') {          
          var floatValue = this.billAddList[i].concession;
          var floatString = floatValue.toString();
          var parts = floatString.split('.');
          var digitsAfterDecimal = parts[1] ? parts[1].length : 0;
  
          if (digitsAfterDecimal === 2) { 
            // console.log("Value has 2 digits after the decimal point.");
          } else if (digitsAfterDecimal === 3) {
            this.billAddList[i].concession = parseFloat(this.billAddList[i].concession);
            // console.log("Value has 3 digits after the decimal point.");
          } else {
            // console.log("Value doesn't have 2 or 3 digits after the decimal point.");
          }
        }
        
      }
    }

    if (this.inGST == true) {
      for (var i = 0; i < this.gst_retrive_list.length; i++) {
        this.gst_data_list.push({
          Gst_Descrip:
            this.gst_retrive_list[i].tax_desc +
            ' ' +'('+
            parseInt(this.gst_retrive_list[i].tax)+' %'+')',
          Gst_amount: Math.round(
            parseFloat(this.gst_amount_txt) *
            (parseFloat(this.gst_retrive_list[i].tax) / 100)
          ),
        });
        this.finalAmount = (parseFloat(this.finalAmount) +
        Math.round(parseFloat(this.gst_amount_txt) *
          (parseFloat(this.gst_retrive_list[i].tax) / 100)));
          
        
        this.totalBillAmount =
          parseFloat(this.totalBillAmount) +
          Math.round(parseFloat(this.gst_amount_txt) *
            (parseFloat(this.gst_retrive_list[i].tax) / 100));
      }
      this.finalAmount = Math.round(parseFloat(this.finalAmount));
      this.totalBillAmount = parseFloat(this.totalBillAmount);
    }

    if (this.balance_data != undefined) {
      this.finalAmount = (
        parseFloat(this.finalAmount) + parseFloat(this.balance_data)
      );
      this.totalBillAmount = (
        parseFloat(this.totalBillAmount) + parseFloat(this.balance_data)
      );
    }

    //amount to be collected
    this.remaing_billbal = (
      parseFloat(this.finalAmount) - parseFloat(this.Advance_bill)
    );
    
    if (
      this.billAddList.length == 0 
    ) {
      this.inGST = false;
      this.gst_data_list = [];
      this.finalAmount = 0;
      this.discount_amount = 0;
      this.totalBillAmount = 0;
      this.gst_amount_txt = 0;
      this.total_collected = 0;
      this.remaing_billbal = 0;
    }

    if (parseFloat(this.Advance_bill) > parseFloat(this.finalAmount)) {
      this.remaing_billbal = 0;
    }
    
    // this.advance_change();
    setTimeout(()=>{
      // this.paid_change();
    },500)   
  }

  async Get_Gst_Data() {
    var obj = Master_Helper.getMasterGSTData();
    if (Master_Helper.getMasterGSTData() == undefined) {
      await this.masterData.getGstData();
    }
    var obj = Master_Helper.getMasterGSTData();
    if (obj != undefined) this.gst_retrive_list = obj.taxes;
  }

  handleKeyUp(event: KeyboardEvent, biill_type, person) {
    const key = event.key;
    if (key !== 'ArrowUp' && key !== 'ArrowDown') {
      var biill_type;
      this.getServiceType(biill_type);
    }
    if (key === 'Enter') {
      let charge;
      for (let i = 0; i < this.serviceType.length; i++) {
        if (this.serviceType[i].description === biill_type) {
          const { description, amount, charge_id } = this.serviceType[i];
          charge = {
            description,
            amount,
            charge_id,
          };
          break;
        }
      }
      if (biill_type) {
        this.changeChargeType1(charge, person);
      }
    }
  }

  getServiceType(bill_type) {
    this.serviceType = [];
    if (bill_type.length >= 2 && bill_type !== undefined) {
      if (this.chargeName.length !== 0) {
        const filteredItems = this.chargeName.filter((o) =>
          o.description.toLowerCase().includes(bill_type.toLowerCase())
        );
        if (filteredItems.length > 0) {
          filteredItems.forEach((o) => {
            this.serviceType.push({
              description: o.description,
              amount: o.amount,
              charge_id: o.charge_id,
            });
          });
        } else {
          this.serviceType = [];
        }
      }
    }
  }

  changeChargeType1(charge, person) {
    var result = this.finekeyInArray_billaddChargeType(
      this.billAddList,
      charge.charge_id
    );
    if (result == true) {
      this.toastr.error('Charge type already exists');
      person.biill_type = '';
      this.serviceType = [];
    } else {
      person.biill_type = charge.description;
      person.edit_flag = true;
      person.charge_type = charge.charge_id;
      person.amount_txt = parseFloat(charge.amount);
      person.quantity = this.quantity;
      person.concession = this.concession;
      person.fee = this.getamountAftCon1(charge.amount,this.quantity);
      person.total = (parseFloat(this.quantity) * parseFloat(charge.amount));
      this.calculateGst('0', '0');
      this.serviceType = [];
      // this.addNewBillDetails();
    }
    // this.getTableHeading();
  }

  changeamount1(charge,num) {
    for (var i = 0; i < this.billAddList.length; i++) {
      if (charge.biill_type == this.billAddList[i].biill_type) {
        if (num != 2) {
          var concec;
          if (charge.concession == 'NaN') {
            charge.concession = 0;
            concec = 0;
          } else {
            concec = charge.concession;
          }
          charge.fee =
            charge.quantity *
            (charge.amount_txt - charge.amount_txt * (concec / 100));
          charge.fee = parseFloat(charge.fee);

          charge.total = charge.quantity * charge.amount_txt;
          charge.total = parseFloat(charge.total);
        } else {
          var fee;
          if (charge.fee == 'NaN') {
            charge.fee = 0;
            fee = 0;
          } else {
            fee = charge.fee;
          }
           
          if (charge.amount_txt != 0 && charge.quantity != 0 && charge.fee != '') { 
            charge.concession = (100 - ((fee / (charge.amount_txt * charge.quantity)) * 100));
          } else {
            charge.concession = 0;
          }
        }
      }
    }
    this.calculateGst('0', num);
    // this.calculateHospitalGst('0','0');
  }


  deleteHospitalBill(data) {
    //Delete Hospital Bill
    if (data.biill_type.length != 0) {
      if (this.billAddList.length != 0) {
        for (var i = 0; i < this.billAddList.length; i++) {
          if (
            this.billAddList[i].biill_type == data.biill_type &&
            this.billAddList[i].amount_txt == data.amount_txt
          ) {
            this.billAddList.splice(i, 1);
            this.calculateGst('0', '0');
            // this.calculateHospitalGst('0','0');
            break;
          }
        }
      }
      this.sno = 1;
      for (var i = 0; i < this.billAddList.length; i++) {
        this.billAddList[i].sno = this.sno;
        this.sno += 1;
      }
      if (this.finalAmount == 0) this.inGST == false;
      this.calculateGst('0', '0');
      // this.calculateHospitalGst('0','0');
    } else {
      for (var i = 0; i < this.billAddList.length; i++) {
        
        if (data.sno == this.billAddList[i].sno) {
          if (data.biill_type == '') {
            this.billAddList.splice(i, 1);
            this.calculateGst('0','0');
            // this.calculateHospitalGst('0','0');
            break;
          }      
        }
      }
      this.sno = 1;
      for (var i = 0; i < this.billAddList.length; i++) {
        this.billAddList[i].sno = this.sno;
        this.sno += 1;
      }
      this.calculateGst('0','0');
    }
  }

  getBillAmount() {
    this.chargeName = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(
        ipaddress.getIp.toString() + 'bill/chgtype',
        {
          hptl_clinic_id: this.userInfo.hptl_clinic_id,
          provider: 'doctor',
        },
        { headers: headers }
      )
      .subscribe(
        (response) => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log(obj);
          if (obj.charges != undefined && obj.charges.length != 0) {
            this.chargeName = obj.charges;
            if (this.chargeName != undefined && this.chargeName.length != 0) {
              for (var i = 0; i < this.chargeName.length; i++) {
                if (
                  this.chargeName[i].auto_generate != null &&
                  this.chargeName[i].auto_generate == '1' && (this.chargeName[i].description).toLocaleUpperCase().trim() == 'CONSULTATION FEE'
                ) {
                  this.billamount = parseInt(this.chargeName[i].amount);             
                }
              }
            }
          }
        },
        (error) => {
          this.toastr.error(Message_data.getNetworkMessage());
        }
      );
  }

}

