<div class="row" style="margin:0 auto; width:100%">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Consingment list</h5>
                    </div>
                    <div class="headerButtons" >

                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="row" style="margin-top: 10px;">
                    <div class="col-12">
                        <table style="font-size: 13px;" id="product_table" 
                        class="table table-hover table-dynamic">
                        <thead>
                            <tr>
                                <th>Sno</th>
                                <th>HSN no</th>
                                <th>Product name</th>
                                <th>Batch no</th>
                                <th>Supplier</th>
                                <th>Quantity</th>
                                <th>MRP</th>
                                <th>Sales price</th>
                                <th>Cost price</th>
                                <th>CGST</th>
                                <th>SGST</th>
                                <th>GST</th>
                                <th>GST amount</th>
                                <th>Expiry</th>
                                <th>Received by</th>
                                <th>Return</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let prod of consing_data;let i = index">
                                <tr (click)="toggleInputs(i)">
                                    <td>{{prod.sn}}</td>
                                    <td>{{prod.hsn_no}}</td>
                                    <td>{{prod.consignment_name}}</td>
                                    <td>{{prod.batch_no}}</td>
                                    <td>{{prod.supp_name}}</td>
                                    <td>{{prod.quantity}}</td>
                                    <td>{{prod.mrp}}</td>
                                    <td>{{prod.sale_price}}</td>
                                    <td>{{prod.cost_price}}</td>
                                    <td>{{prod.cgst}}</td>
                                    <td>{{prod.sgst}}</td>
                                    <td>{{prod.gst}}</td>
                                    <td>{{prod.gst_amount}}</td>
                                    <td>{{prod.expiry_date}}</td>
                                    <td>{{prod.received_by}}</td>
                                    <td>
                                     <input type="checkbox" [(ngModel)]="prod.is_prod_returned">
                                    </td>
                                  
                                 </tr>
                                 <tr *ngIf="currentOpenedIndex === i" [@slideToggle]>
                                    <td colspan="7">
                                        <div class="row maintance_sch">
                                            
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                                <mat-label class="matlabel"> Name <br>
                                                  <input type="text" class="ipcss order_width getMedcien" required maxlength="50"
                                                    (keyup)="name.length >=3 && handlekeyUp($event)" maxlength="50" [(ngModel)]="name" matInput
                                                    [matAutocomplete]="autoordercrete"/>
                                                </mat-label>
                                                <mat-autocomplete #autoordercrete="matAutocomplete" [panelWidth]="'auto'">
                                    
                                                  <mat-option (click)="retrieveClient(patient)" *ngFor="let patient of patientArray"
                                                    value="{{patient.patient_name}}">
                                                    <div>{{patient.patient_name}} <span>(</span> {{patient.gender}} - {{patient.age}}<span>)</span> </div>
                                                  </mat-option>
                                                </mat-autocomplete>
                                              </div>
                                    
                                            
                                           
                                         
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 input">
                                                <mat-label class="matlabel" style="margin-top: 7px;">Used Qty<br>
                                                    <input type="text" class="ipcss "  maxlength="25" [(ngModel)]="this.usequantity" matInput required/>
                                                </mat-label>
                                            </div>

                                           
                                         
                                            <div class="col-12 col-sm-6 col-md-4 col-lg-1 col-xl-1 input">
                                                <img style="position: relative;top: 20px;" src="../../../assets/ui_icons/buttons/Book_now.svg" 
                                               (click)="booknow(prod)"
                                                class="saveimgbtn_inpatinfo" >
    
                                            </div>
                                        </div>
                                      </td>    
                                </tr>
                                </ng-container>

                        </tbody>
                    </table> 
                    </div>
                    <div  class="col-12">

                    </div>
                </div>


            </mdb-card-body>
        </mdb-card>
    </div>
</div>

