<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Appointment details</h5>
          </div>
          <div class="headerButtons">
          
            <a (click)="back()"><img src="../../../assets/ui_icons/buttons/Back_button.svg"
                class="backimgbtn_inpatinfo" /></a>
                <img src="../../../assets/ui_icons/buttons/cancel_button.svg" [hidden]="cancelFlag"
                class="saveimgbtn_inpatinfo" (click)="cancel()" />
              <img src="../../../assets/ui_icons/buttons/Book_now.svg" [hidden]="bookNowFlag" class="saveimgbtn_inpatinfo"
                (click)="bookNow()" />
          
                <img class="saveimgbtn_inpatinfo" *ngIf="rescheduleFlag" (click)="reschedule()"
              src="../../../assets/ui_icons/buttons/reschedule_button.svg" />
            <img class="saveimgbtn_inpatinfo" *ngIf="doctorAvailableFlag" (click)="bookForReschedule()"
              src="../../../assets/ui_icons/buttons/Book_now.svg" />
            <!-- <a style="margin:0 5px;" (click)="print()"><img src="../../../assets/img/printer.svg"
                class="backimgbtn_inpatinfo" style="width: 25px;" height="25px" /></a> -->
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="cover_div" style="padding-bottom: 13px;">
          <div class="header_lable">
            Personal details
          </div>
          <div class="content_cover">
            <div class="row">
           
              <div class="col-3">
                <p class="textformat"><span style="font-weight: 550;">MR no:</span> {{patientid}}</p>
              </div>
              <div class="col-3">
                <p class="textformat"><span style="font-weight: 550;">Name:</span> {{clntName}}</p>
              </div>
              <div class="col-3">
                <p class="textformat"><span style="font-weight: 550;">Age/Gender:</span> {{age}}/{{gender}}</p>
              </div>
              <div class="col-3">
                <p class="textformat"><span style="font-weight: 550;">Mobile:</span> {{contactNo}}</p>
              </div>
              <div class="col-6" >
                <p class="textformat"><span style="font-weight: 550;">Patient address:</span> {{patientAddress}}</p>
              </div>
              <!-- <div class="col-3">
                <p class="textformat"><span style="font-weight: 550;">Appointment details:</span> {{appInfo}}</p>
              </div> -->


              
            
              <!-- <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">Contact number
                  <input class="ipcss " [readonly]="isReadonly()" [(ngModel)]="contactNo" matInput />
                </mat-label>
              </div> -->
           
            </div>
          </div>
        </div>
        <div class="cover_div" style="padding-bottom: 13px;">
          <div class="header_lable">
            Appointment details
          </div>
          <div class="content_cover">
            <div class="row">
              <div class="col-3">
                <p class="textformat1"><span style="font-weight: 550;margin-bottom: 12px;">Complaints:</span> <span [innerHtml]="symptom" style="margin: 0px;"></span></p>
              </div>
              <div class="col-3">
                <p class="textformat"><span style="font-weight: 550;">Specialization:</span> {{specialization}}</p>
              </div>
              <div class="col-3">
                <p class="textformat"><span style="font-weight: 550;">Doctor name:</span> {{doctorName}}</p>
              </div>
              <div class="col-3" *ngIf="refflag">
                <p class="textformat"><span style="font-weight: 550;">Referred by:</span> {{referredDoctor}}</p>
              </div>
             
              <div class="col-6" >
                <p class="textformat"><span style="font-weight: 550;">Appointment details:</span> {{appInfo}}</p>
              </div>
            
              <!-- <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">Complaints
                  <input class="ipcss " [readonly]="isReadonly()" [(ngModel)]="symptom" matInput />
                </mat-label>
              </div> -->
              <!-- <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">Treatment type
                  <input class="ipcss " [readonly]="isReadonly()" [(ngModel)]="treatmentType" matInput />
                </mat-label>
              </div> -->
              <!-- <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">Specialization
                  <input class="ipcss " [readonly]="isReadonly()" [(ngModel)]="specialization" matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">Doctor name
                  <input class="ipcss " [readonly]="isReadonly()" [(ngModel)]="doctorName" matInput />
                </mat-label>
              </div> -->
              <!-- <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3" *ngIf="refflag">
                <mat-label class="matlabel">Referred by
                  <input class="ipcss " [readonly]="isReadonly()" [(ngModel)]="referredDoctor" matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">Patient address
                  <textarea class="ipcss " style="height:85px !important;" [readonly]="isReadonly()"
                    [(ngModel)]="patientAddress" matInput></textarea>
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">Appointment details
                  <textarea class="ipcss " style="height:85px !important;" [readonly]="isReadonly()"
                    [(ngModel)]="appInfo" matInput></textarea>
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <mat-label class="matlabel">Hospital details
                  <textarea class="ipcss " style="height:85px !important;" [readonly]="isReadonly()"
                    [(ngModel)]="hospInfo" matInput></textarea>
                </mat-label>
              </div> -->
            </div>
          </div>
        </div>
        <div class="cover_div" style="padding-bottom: 13px;" [hidden]="reassignFlag">
          <div class="header_lable">
            Resasign
          </div>
          <div class="content_cover">
            <div [hidden]="reassignFlag">
              <div class="row">
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  <mat-label class="matlabel" [hidden]="reassignFlag">Doctor
                    <select class="ipcss " (change)="changeDoctorForReassign(doctor)" [(ngModel)]="doctor">
                      <option class="ipcss " *ngFor="let doctors of doctorList" value={{doctors.Doc_Name}}>
                        {{doctors.Doc_Name}}</option>
                    </select>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  <mat-label class="matlabel">Date
                    <input type="date" class="ipcss_date widthbillcreate" id="appt_date" required
                      [(ngModel)]="appointmentDate" min="{{currentMinDate}}" #matInput style="background: #fff;">
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  <mat-label class="matlabel">Session<br>
                    <select class="ipcss " [(ngModel)]="session" required (change)="changeSession()">
                      <option *ngFor="let ses of visitingSessionData" value={{ses.description}}>{{ses.description}}
                      </option>
                    </select>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3" [hidden]="appTypeByTokenFlag">
                  <mat-label class="matlabel" [hidden]="appTypeByTokenFlag">Appointment type :</mat-label>
                  <mat-label class="matlabel" [hidden]="appTypeByTokenFlag">Token</mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3" [hidden]="appTypeByTimeFlag">
                  <mat-label class="matlabel" [hidden]="appTypeByTimeFlag">
                    <select class="ipcss " [(ngModel)]="appointmentTime" required (change)="changeSession()">
                      <option *ngFor="let time of timeList" value={{time}}>{{time}}</option>
                    </select>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 my-auto">
                  <mat-label class="matlabel">
                    <img src="../../../assets/ui_icons/buttons/search_button.svg" class="saveimgbtn_inpatinfo"
                      (click)="search()" />
                  </mat-label>
                </div>
                <div class="col-12">
                  <mat-label class="matlabel" [hidden]="unavailableFlag">Sorry already booked</mat-label>
                </div>
              </div>
            </div>
            <div [hidden]="doctorConsultSessionList.length==0">
              <div class="table-responsive dig_table_overflow">
                <table id="patienttable" class="table table-hover table-dynamic reassing_table"
                  [hidden]="doctorConsultSessionList.length==0">
                  <thead>
                    <tr>
                      <th>Work days</th>
                      <th>Session</th>
                      <th>Starts from</th>
                      <th>Ends at </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let work of  doctorConsultSessionList">
                      <td>{{work.Workday}}</td>
                      <td>{{work.Session}}</td>
                      <td>{{work.Avail_from}}</td>
                      <td>{{work.Avail_to}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="cover_div" style="padding-bottom: 13px;" *ngIf="normalRescheduleFlag">
          <div class="header_lable">
            Reschedule
          </div>
          <div class="content_cover">
            <div class="row">
              <div class="col-12">
                <div class="table-responsive dig_table_overflow">
                  <table id="tbl" class="table table-hover table-dynamic">
                    <thead>
                      <tr>
                        <th>Hospital name</th>
                        <th>Day</th>
                        <th>Session</th>
                        <th>Starts from</th>
                        <th>Ends at</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let doctor of normalDoctorList">
                        <td data-th="Hospital Name">{{doctor.hospital}}</td>
                        <td data-th="Day">{{doctor.workdays}}</td>
                        <td data-th="Session">{{doctor.day_session}}</td>
                        <td data-th="Starts from">{{doctor.available_from}}</td>
                        <td data-th="Ends at">{{doctor.available_to}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Date
                  <input type="date" class="ipcss " [(ngModel)]="reschdApptDate"
                    (change)="selectReschdApptDate(reschdApptDate)"  min="{{currentMinDate}}" matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label *ngIf="doctorAvailableFlag" class="matlabel">Session<br>
                  <mat-select [(ngModel)]="visitSession" disableOptionCentering class="ipcss "
                    (ngModelChange)="changeVisitSessionForReschd(visitSession, reschdApptDate)">
                    <mat-option disabled>Select</mat-option>
                    <mat-option *ngFor="let session of reschdVisitingSessionData" value={{session.description}}>
                      {{session.description}}</mat-option>
                  </mat-select>
                </mat-label>
              </div>
              <div *ngIf="timeorTokenFlag" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Appointment {{reschedAppType}}</mat-label>
                <div class="row" *ngIf="appTypeTokenFlag">
                  <div class="col-12">
                    <mat-label class="matlabel">Token<br>
                    </mat-label>
                  </div>
                </div>
                <div class="row" *ngIf="!appTypeTokenFlag">
                  <div class="col-12">
                    <mat-label *ngIf="!appTypeTokenFlag" class="matlabel">
                      <mat-select [(ngModel)]="reschdAppointmentTime" *ngIf="!appTypeTokenFlag" disableOptionCentering
                        class="ipcss ">
                        <mat-option disabled>Select</mat-option>
                        <mat-option *ngFor="let time of timeList" value={{time}}>{{time}}</mat-option>
                      </mat-select>
                    </mat-label>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <mat-label class="matlabel">Reason for reschedule
                  <textarea class="ipcss " [(ngModel)]="rescheduleReason"></textarea>
                </mat-label>
              </div>
              <div class="col-12" *ngIf="doctorAvailableFlag">
                <mat-label class="matlabel" *ngIf="unavailableFlag">Sorry already booked</mat-label>
              </div>
              <div class="col-12" *ngIf="doctorNonAvailableFlag">
                <mat-label class="matlabel" *ngIf="unavailableFlag">Please check doctor's availability</mat-label>
              </div>
            </div>
          </div>
        </div>
        <!-- print -->
        <div [hidden]="true">
          <div #printlogowithname id="printlogowithname">
            <table style="margin-left:50px;width: 100%;">
              <tr>
                <td style="display: flex;justify-content: center;">
                  <div>
                    <img alt="image" src={{hospLogo}} width="100px" height="100px" style="vertical-align: middle;">
                  </div>
                  <div style="position: relative;top: 20px;left: 10px;text-align: left;">
                    <p><b>{{hospName}}</b>,</p>
                    <span *ngIf="hospAddress != undefined">{{hospAddress}}</span><br />
                    <span>{{hospLocation}},{{hospCity}}</span><br />
                    <span>{{hospState}}</span><br />
                  </div>
                </td>
              </tr>
            </table>
            <table style="margin-left: 50px;">
              <tr>
                <td style="width: 450px;">
                  <p><b>MR no:</b> {{patientId}}</p>
                  <p><b>Name:</b>{{salutation}} {{clntName}}</p>
                  <p><b>Age/Gender:</b> {{age}}/{{gender}}&nbsp;&nbsp;&nbsp;&nbsp;</p>
                  <p><b>Mobile:</b>{{contactNo}}</p>
                  <p><b>Address:</b>{{patientAddress}}</p>
                </td>
                <td style="width: 50px;margin-top: 65px;">&nbsp;</td>
                <td style="vertical-align: top;">
                  <p><b>Doctor: </b> {{"Dr."+doctorName}}</p>
                  <p><b>Date: </b>{{appDate}}&nbsp;&nbsp;&nbsp;&nbsp;<b>Session: </b>{{appSession}}</p>
                  <p><b>Appointment Type:</b> {{appType}}</p>
                  <p *ngIf="symptom!= undefined"><b>Complaints:</b> <span [innerHtml]="symptom"></span> </p>
                  <p><b>Specialization:</b>{{specialization}}</p>
                  <p *ngIf="refflag"><b>Referred by: Dr.</b>{{referredDoctor}}</p>
                </td>
              </tr>
            </table>
          </div>
          <div #printbanner id="printbanner">
            <table style="margin-left:10px;margin-top:10px;margin-right: 10px;">
              <tr>
                <td>
                  <img alt="image" src={{hptlPresLogo}} width="750px" height="250px">
                </td>
              </tr>
            </table>
            <table style="margin-left: 50px;">
              <tr>
                <td style="width: 450px;">
                  <p><b>MR no:</b> {{patientId}}</p>
                  <p><b>Name:</b> {{clntName}}</p>
                  <p><b>Age/Gender:</b> {{age}}/{{gender}}&nbsp;&nbsp;&nbsp;&nbsp;</p>
                  <p><b>Mobile:</b>{{contactNo}}</p>
                  <p><b>Address:</b>{{patientAddress}}</p>
                </td>
                <td style="vertical-align: top;">
                  <p><b>Doctor:</b> {{"Dr."+doctorName}}</p>
                  <p><b>Date: </b>{{appointmentDate}}</p>
                  <p><b>Session: </b>{{appSession}}</p>
                  <p><b>Appointment Type:</b> {{appType}}</p>
                  <p *ngIf="symptom!= undefined"><b>Complaints:</b> <span [innerHtml]="symptom"></span> </p>
                  <p><b>Specialization:</b>{{specialization}}</p>
                  <p *ngIf="refflag"><b>Referred by: Dr.</b>{{referredDoctor}}</p>

                  <!-- <p><b>Referred by: Dr.</b>{{referredDoctor}}</p> -->
                </td>
              </tr>
            </table>
          </div>
          <div #printnoheader id="printnoheader">
            <table style="margin-left: 50px; margin-top:220px;">
              <tr>
                <td style="width: 450px;">
                  <p><b>MR no:</b> {{patientId}}</p>
                  <p><b>Name:</b> {{clntName}}</p>
                  <p><b>Age/Gender:</b> {{age}}/{{gender}}&nbsp;&nbsp;&nbsp;&nbsp;</p>
                  <p><b>Mobile:</b>{{contactNo}}</p>
                  <p><b>Address:</b>{{patientAddress}}</p>
                </td>
                <td style="vertical-align: top;">
                  <p><b>Doctor: </b>{{"Dr."+doctorName}}</p>
                  <p><b>Date: </b>{{appointmentDate}}</p>                  
                  <p><b>Session: </b>{{appSession}}</p>
                  <p><b>Appointment Type:</b> {{appType}}</p>
                  <p *ngIf="symptom!= undefined"><b>Complaints:</b><span [innerHtml]="symptom"></span></p>
                  <p><b>Specialization:</b>{{specialization}}</p>
                  <p *ngIf="refflag"><b>Referred by: Dr.</b>{{referredDoctor}}</p>

                  <!-- <p><b>Referred by: Dr.</b>{{referredDoctor}}</p> -->
                </td>
              </tr>
            </table>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card> <br><br><br>
  </div>
</div>