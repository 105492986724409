import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { DiagnosisService } from '../diagnosis/diagnosis.service';
import { Diagnosis_Helper } from '../Diagnosis_Helper';
import { Message_data } from '../../../assets/js/Message_data';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ipaddress } from '../../ipaddress';
import { Date_Formate, Time_Formate } from 'src/assets/js/common';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
import { Helper_Class } from '../../helper_class';

@Component({
  selector: 'app-diagnosis-complete-report',
  templateUrl: './diagnosis-complete-report.component.html',
  styleUrls: ['./diagnosis-complete-report.component.scss']
})
export class DiagnosisCompleteReportComponent implements OnInit {
  public userType;
  public clientNameArray = [];
  public locationArray: any;
  public infoArray: any;
  public location;
  public centreLocation;
  public diagLocArray = [];
  public sendData: any = [];
  public currentData;
  date: any = [];
  public clientArray: any;
  updateListArray: any;
  updateDataArray: any = [];
  public type;
  public reportType;
  public reportFlag;
  public Select = "Select";
  public height;
  public info;
  public reporttypeval: string;
  dtOptions: DataTables.Settings = {};

  // module flags
  public newbutton: boolean = false
  public editbutton: boolean = false;
  public deletebutton: boolean = false;
  public printbutton: boolean = false;
  public viewbutton: boolean = false;
  public moduleList: any = [];
  user_id: any;
  public diag_center_id: any;
  public isSelected: boolean;
  filteredCompletedlistArray: any = [];
  public searchInput: any;
  public from_date: any;
  public to_date: any;
  public filter_type: any = 'date';
  public mr_no_nameFlag: boolean = false;
  public date_Flag: boolean = true;
  public filter_placeholder: string;
  public report: { type: string; reject_reason: string; diag_app_id: any; tests: any[]; test_type: string; reject_type: string; country: string; 
};
  public reConduct_Flag: boolean;
  public reconduct_reason: string;
  public sort_by_type: any = 'date';

  constructor(private cdRef: ChangeDetectorRef, public messageservice: MenuViewService, public service: CommonDataService, public toastr: ToastrService, public http: Http) {
    Diagnosis_Helper.setReportView(undefined);
  }

  ngOnInit(): void {
    this.moduleList = Helper_Class.getmodulelist();
    this.reporttypeval = Diagnosis_Helper.getReportType();
    if (Helper_Class.getInfo().user_type == "Admin") {
      this.diag_center_id = Helper_Class.getInfo().diag_center_id;
    } else {
      this.diag_center_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
    }

    if (this.moduleList != undefined) {
      for (var i = 0; i < this.moduleList.length; i++) {
        if (this.moduleList[i].module_id == "109" && this.reporttypeval == "radiology" || this.moduleList[i].module_id == "105" && this.reporttypeval == "pathology" || this.moduleList[i].module_id == "113" && this.reporttypeval == "microbiology") {
          console.log(JSON.stringify(this.moduleList[i]));
          if (this.moduleList[i].edit == "1") {
            this.editbutton = true;//update button
          }
          if (this.moduleList[i].create == "1") {
            this.newbutton = true;
          }
          if (this.moduleList[i].delete == "1") {
            this.deletebutton = true;
          }
          if (this.moduleList[i].print == "1") {
            this.printbutton = true;
          }
          if (this.moduleList[i].view == "1") {
            this.viewbutton = true;
          }
        }
      }
    }

    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      "order": [[2, 'desc']],
      "columnDefs": [
        { "orderable": false, "targets": [3] },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search patients with name or test type or test name"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };


    if (Helper_Class.getInfo().user_type == 'Admin') {
      this.user_id = Helper_Class.getInfo().user_id;
      this.userType = "diag_user";

      this.editbutton = true;
      this.newbutton = true;
      this.deletebutton = true;
      this.printbutton = true;
      this.viewbutton = true;
    }
    else {
      this.user_id = Helper_Class.getInfo().user_id;
      this.userType = "diagnosis";
      // this.getPendingList();
    }
    this.getDate();
  }

  getLocation() {
    this.clientNameArray = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "diagnosiscontroller/diagloc/", JSON.stringify({
      diag_name: this.user_id,
      flag: "id",
      imei: localStorage.getItem("ipaddress"),
      prov_id: this.user_id,
      type: this.userType,
    }), { headers: headers }).subscribe(
      data => {
        if (data.json().diag_loc != null) {
          this.locationArray = data.json().diag_loc;
          this.infoArray = [];
          for (var i = 0; i < this.locationArray.length; i++) {
            this.infoArray = [{
              name: this.locationArray[0].diag_centre_name,
              id: this.locationArray[0].diag_centre_id,
              address1: this.locationArray[0].address1,
              address2: this.locationArray[0].address2,
              loc: this.locationArray[0].diag_loc,
              state: this.locationArray[0].state,
              city: this.locationArray[0].city,
              country: this.locationArray[0].country,
              zipcode: this.locationArray[0].zipcode,
              telephone: this.locationArray[0].telephone,
            }];
            this.info = this.locationArray[0].diag_centre_name + "-" + this.locationArray[0].diag_loc;
          }
          this.location = this.locationArray[0];
          this.changeLocation(this.locationArray[0]);
        }
      }, error => {
        this.toastr.error(Message_data.getNetworkMessage());
      });
  }

  getDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "adm/curdate", JSON.stringify({
      country: ipaddress.country_code
    }), { headers: headers }).subscribe(
      data => {
        if (data.json().current_date != null) {
          this.currentData = data.json().current_date;
          this.from_date = data.json().current_date;
          this.to_date = data.json().current_date;
          var new_day = this.currentData.split('-');
          var current_date_data = new_day[2] + "-" + new_day[1] + "-" + new_day[0]
          this.date = [{
            day: current_date_data
          }];
          this.getLocation();
        }
      }, error => {
        this.toastr.error(Message_data.getNetworkMessage());
      });
  }

  getClient(i, data) {
    this.centreLocation = i.diag_centre_id;
    if (data != null) {
      this.getLocation();
      this.sendData = {
        diag_reg_id: this.user_id,
        diag_user: "diag_user",
        location: i.diag_loc
      }
    } else {
      i.forEach(element => {
        this.sendData = {
          diag_reg_id: this.user_id,
          location: element.loc
        }
      });
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "diagnosiscontroller/getdiaapp/", JSON.stringify(
      this.sendData
    ), { headers: headers }).subscribe(
      data => {
        if (data.json().appointment_id != null) {
          this.clientArray = data.json().appointment_id;
          var firstname, lastname;
          this.clientNameArray = [];
          this.clientArray.forEach(element => {
            if (element.first_name != undefined) {
              if (element.last_name != undefined) {
                if (encrypt_decript.Decript(element.first_name) != "" && encrypt_decript.Decript(element.last_name) != "") {
                  firstname = encrypt_decript.Decript(element.first_name);
                  lastname = encrypt_decript.Decript(element.last_name);
                }
              } else {
                firstname = encrypt_decript.Decript(element.first_name);
                lastname = "";
              }
            } else {
              firstname = "";
              lastname = "";
            }
            this.clientNameArray.push({
              name: firstname + " " + lastname,
              id: element.app_id,
              rel_id: element.relation_id,
              sub_rel_id: element.sub_rel_id
            });
          });
        }
      }, error => {
        this.toastr.error(Message_data.getNetworkMessage());
      });
  }

  changeLocation(id) {
   
    if (this.diag_center_id != undefined) {
      var send_data = {
        diag_centre_id: this.diag_center_id,
        type: "complete",
        reporttype: this.reporttypeval,
        from_date: this.from_date,
        to_date: this.to_date,
      }

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + "diagnosiscontroller/gteg",
        JSON.stringify(send_data), { headers: headers }).subscribe(
          data => {
            this.updateDataArray = [];
            this.filteredCompletedlistArray = [];
            this.updateListArray = [];
            if (data.json().test_edit != null) {
              console.log("Completed List le == ", JSON.stringify(data.json().test_edit.length));
              console.log("Completed List", JSON.stringify(data.json().test_edit));
              this.updateListArray = data.json().test_edit;
              this.updateListArray.forEach(element => {
                var firstname, lastname;
                if (element.first_name != undefined) {
                  if (element.last_name != undefined) {
                    if (encrypt_decript.Decript(element.first_name) != "" && encrypt_decript.Decript(element.last_name) != "") {
                      firstname = encrypt_decript.Decript(element.first_name);
                      lastname = encrypt_decript.Decript(element.last_name);
                    }
                  } else {
                    firstname = encrypt_decript.Decript(element.first_name);
                    lastname = "";
                  }
                } else {
                  firstname = "";
                  lastname = "";
                }

                var patientName;
                if (element.first_name != undefined && element.middle_name != undefined && element.last_name != undefined) {
                  patientName = encrypt_decript.Decript(element.first_name) + " " + encrypt_decript.Decript(element.middle_name) + " " + encrypt_decript.Decript(element.last_name);
                } else if (element.first_name != undefined && element.last_name != undefined) {
                  patientName = encrypt_decript.Decript(element.first_name) + " " + encrypt_decript.Decript(element.last_name);
                } else {
                  patientName = encrypt_decript.Decript(element.first_name);
                }

                var dr_Name = "";
                if (element.dr_first_name != undefined && element.dr_middle_name != undefined && element.dr_last_name != undefined) {
                  dr_Name = "Dr " + element.dr_first_name + " " + element.dr_middle_name + " " + element.dr_last_name;
                } else if (element.dr_first_name != undefined && element.dr_last_name != undefined) {
                  dr_Name = "Dr " + element.dr_first_name + " " + element.dr_last_name;
                } else if(element.dr_first_name != undefined){
                  dr_Name = "Dr " + element.dr_first_name;
                }

                var ward_details = "";
                if (element.ward_name != undefined && element.bed_no != undefined) {
                  ward_details = element.ward_name + "  " + element.bed_no;
                } else if(element.ward_name != undefined){
                  ward_details = element.ward_name;
                }

                var age_gender;
                if (element.age != undefined && element.gender != undefined) {
                  age_gender = element.age + " / " + encrypt_decript.Decript(element.gender)
                } else if (element.age != undefined) {
                  age_gender = element.age
                } else if (element.gender != undefined) {
                  age_gender = encrypt_decript.Decript(element.gender)
                } else {
                  age_gender = '';
                }

                var test_values = [];
                if (element.test_values.length != 0 && element.test_values != undefined) {
                  for (var i = 0; i < element.test_values.length; i++) {
                   
                    
                    var date = element.test_values[i].approved_date != undefined ? Date_Formate(element.test_values[i].approved_date) : "";
                    var time = element.test_values[i].approved_time != undefined ? Time_Formate(element.test_values[i].approved_time) : "";
                    var sample_collect_date = element.test_values[i].sample_collect_date != undefined ? Date_Formate(element.test_values[i].sample_collect_date) : "";
                    var sample_collect_time = element.test_values[i].sample_collect_time != undefined ? Time_Formate(element.test_values[i].sample_collect_time) : "";
                    var bill_date = element.test_values[i].bill_date != undefined ? Date_Formate(element.test_values[i].bill_date) : "";
                    var bill_time = element.test_values[i].bill_time != undefined ? Time_Formate(element.test_values[i].bill_time) : "";
                    var assert_date = element.test_values[i].assertion_date != undefined ? Date_Formate(element.test_values[i].assertion_date) : ""
                    var assert_time = element.test_values[i].assertion_time != undefined ? Time_Formate(element.test_values[i].assertion_time) : "";
                    var created_date = element.test_values[i].created_date != undefined ? Date_Formate(element.test_values[i].created_date) : "";
                    var created_time = element.test_values[i].created_time != undefined ? Time_Formate(element.test_values[i].created_time) : "";
                    var visited_date = element.test_values[i].visited_date != undefined ? Date_Formate(element.test_values[i].visited_date) : "";
                    var visited_time = element.test_values[i].visited_time != undefined ? Time_Formate(element.test_values[i].visited_time) : "";

                    var status;
                    if (element.test_values[i].status == "0") {
                      status = "Completed";
                    } else {
                      status = "Awaiting For Approval";
                    }

                   


                    test_values.push({
                      test_name: element.test_values[i].test_name,
                      pres_test_id: element.test_values[i].pres_test_id,
                      pres_diag_id: element.test_values[i].pres_diag_id,
                      status: status,
                      test_no: element.test_values[i].test_no,
                      test_id: element.test_values[i].test_id,
                      approvedDate: date + ' ' + time,
                      sample_collect_date_time: sample_collect_date + " " + sample_collect_time,
                      bill_date_time: bill_date + " " + bill_time,
                      assert_date_time: assert_date + " " + assert_time,
                      created_date: created_date,
                      created_date_time: created_date + " " + created_time,
                      visited_date_time: visited_date + ' ' + visited_time,
                      test_type: element.test_values[i].test_type,
                      isSelected: false,
                    })

                  }
                }

                var sample_collect_date_2 = element.test_values[0].sample_collect_date != undefined ? Date_Formate(element.test_values[0].sample_collect_date) : "";
                var sample_collect_time_2 = element.test_values[0].sample_collect_time != undefined ? Time_Formate(element.test_values[0].sample_collect_time) : "";
                var visited_date_2 = element.test_values[0].visited_date != undefined ? Date_Formate(element.test_values[0].visited_date) : "";
                var visited_time_2 = element.test_values[0].visited_time != undefined ? Time_Formate(element.test_values[0].visited_time) : "";

                // console.log(date, sample_collect_time, bill_date_time, assert_date_time, created_date_time)

                const no_of_days = this.getNumberOfDays(element.test_values[0].sample_collect_date, this.to_date)
                 
                var show_reconductFlag: boolean;
                if (no_of_days > 7) {
                  show_reconductFlag = false;
                } else {
                  show_reconductFlag = true;
                }

                this.updateDataArray.push({
                  first_name: firstname,
                  last_name: lastname,
                  patient_name: patientName,
                  age_gender: age_gender,
                  diag_app_id: element.diag_app_id,
                  pres_diag_id: element.pres_diag_id,
                  test_type: element.test_type,
                  patient_diag_id: element.patient_diag_id,
                  client_reg_id: element.client_reg_id,
                  test_values: test_values,
                  sample_collect_date_time: sample_collect_date_2 + " " + sample_collect_time_2,
                  collected_date: element.test_values[0].sample_collect_date,
                  visited_date_time: visited_date_2 + " " + visited_time_2,
                  re_conductFlag: show_reconductFlag,
                  doctor_name: dr_Name,
                  ward_details: ward_details

                })
              });
              // this.updateDataArray.sort((a, b) => b.created_date - a.created_date);
              console.log("this.updateDataArray", this.updateDataArray);
              this.filteredCompletedlistArray = this.updateDataArray;
              this.sort_filter(this.filter_type)
            }
          }, error => {
            this.toastr.error(Message_data.getNetworkMessage());
          });
    }
  }

  sort_filter(filter_type) {
    // this.filteredPendingList = this.pendingLists.sort((a, b) => b.collected_date - a.collected_date);

    // Function to sort by date in descending order
    if(filter_type == 'date') {
      this.updateDataArray.sort((a, b) => {
        // Convert date strings to Date objects
        const dateA:any = new Date(a.collected_date);
        const dateB:any = new Date(b.collected_date);
  
        // Compare dates to determine order
        return dateB - dateA;
      });
    } else if (filter_type == 'name') {
      this.updateDataArray.sort((a, b) => a.patient_name.localeCompare(b.patient_name));
    } else {
      this.updateDataArray.sort((a, b) => a.patient_diag_id.localeCompare(b.patient_diag_id));
    }
    
    this.filteredCompletedlistArray = this.updateDataArray
    console.log(this.updateDataArray);
  }

  getNumberOfDays(startDate: Date, endDate: Date): number {
    const start = new Date(startDate);
    const end = new Date(endDate);

    const timeDiff = Math.abs(end.getTime() - start.getTime());
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return diffDays;
  }

  viewReportDetails(testtype, diag_app_id, pres_test_id, pres_diag_id, testname, sampleDate_Time, approveDate_Time, visited_date_time) {

    if (this.viewbutton == true) {

      this.reportFlag = "Modify";
      var report = {
        AppFlow: "reportview",
        type: testtype,
        diag_app_id: diag_app_id,
        report_diag_id: diag_app_id,
        pres_test_id: pres_test_id,
        pres_diag_id: pres_diag_id,
        Diag_report_flag: this.reportFlag,
        test_name: testname,
        sample_datetime: sampleDate_Time,
        approve_datetime: approveDate_Time,
        test_flag: "single",
        visited_date_time: visited_date_time,
      };
      Diagnosis_Helper.setReportView(report);
      this.messageservice.sendMessage("report_upload_view");
    }
  }

  viewReportDetails2(data) {

    if (this.viewbutton == true) {

      this.reportFlag = "Modify";
      var pres_tests = [];

      if (data.test_values != undefined) {
        if (data.test_values.every(element => element.isSelected == false)) {
       
          for (var j = 0; j < data.test_values.length; j++) {
            pres_tests.push({
              pres_test_id: data.test_values[j].pres_test_id,
              test_type: data.test_values[j].test_type,
              pres_diag_id: data.test_values[j].pres_diag_id,
              test_name: data.test_values[j].test_name,
              sample_datetime: data.test_values[j].sample_collect_date_time,
              approve_datetime: data.test_values[j].approvedDate
            })
          }
        } else {
          for (var j = 0; j < data.test_values.length; j++) {
            if (data.test_values[j].isSelected == true) {
              pres_tests.push({
                pres_test_id: data.test_values[j].pres_test_id,
                test_type: data.test_values[j].test_type,
                pres_diag_id: data.test_values[j].pres_diag_id,
                test_name: data.test_values[j].test_name,
                sample_datetime: data.test_values[j].sample_collect_date_time,
                approve_datetime: data.test_values[j].approvedDate
              })
            }
          }
        }
      }

      // for(var i = 0; i < this.updateDataArray.length; i++){
      //   if(this.updateDataArray[i].test_values != undefined){
      //     for( var j = 0; j < this.updateDataArray[i].test_values.length; j++){
      //       if(this.updateDataArray[i].test_values[j].isSelected == true){
      //         console.log("updateDataArray---selected", this.updateDataArray[i].test_values[j])
      //         pres_tests.push({
      //           pres_test_id: this.updateDataArray[i].test_values[j].pres_test_id,
      //           test_type: this.updateDataArray[i].test_values[j].test_type,
      //           pres_diag_id: this.updateDataArray[i].test_values[j].pres_diag_id,
      //           test_name: this.updateDataArray[i].test_values[j].test_name,
      //           sample_datetime: this.updateDataArray[i].test_values[j].sample_collect_date_time,
      //           approve_datetime: this.updateDataArray[i].test_values[j].approvedDate
      //         })         
      //       }
      //     }
      //   }
      // }

      var report = {
        AppFlow: "reportview",
        diag_app_id: data.diag_app_id,
        report_diag_id: data.diag_app_id,
        Diag_report_flag: this.reportFlag,
        pres_tests: pres_tests,
        test_flag: "group"
      };

      console.log("data", data)

      Diagnosis_Helper.setReportView(report);
      this.messageservice.sendMessage("report_upload_view");
    }
  }

  selectAll(data) {
    console.log(this.isSelected)

    if (this.isSelected == true) {
      for (var i = 0; i < data.length; i++) {
        if (data[i].test_values != undefined) {
          for (var j = 0; j < data[i].test_values.length; j++) {
            data[i].test_values[j].isSelected = true;
          }
        }
      }
    } else {
      for (var i = 0; i < data.length; i++) {
        if (data[i].test_values != undefined) {
          for (var j = 0; j < data.test_values.length; j++) {
            data[i].test_values[j].isSelected = false;
          }
        }
      }
    }

    this.cdRef.detectChanges();
  }

  filterCompletedlistArray(searchInput) {
    this.filteredCompletedlistArray = this.updateDataArray.filter(item =>
      item.patient_diag_id.toLowerCase().includes(searchInput.toLowerCase()) || item.first_name.toLowerCase().includes(searchInput.toLowerCase())
    );
  }

  changeFilter(type) {
    this.searchInput = '';
    if (type == 'date') {
      this.date_Flag = true;
      this.mr_no_nameFlag = false;
    } else if (type == 'mr_no') {
      this.mr_no_nameFlag = true;
      this.date_Flag = false;
      this.filter_placeholder = "Search by MR.No"
      this.changeLocation("");
    } else {
      this.mr_no_nameFlag = true;
      this.date_Flag = false;
      this.changeLocation("");
      this.filter_placeholder = "Search by Name"
    }
  }

  reconductTest(data) {
    var pres_tests = [];

    if (data.test_values != undefined) {
      if (data.test_values.every(element => element.isSelected == false)) {
        this.reConduct_Flag = false;
        this.toastr.error("Select atleast one test to reconduct");
      } else {
        this.reConduct_Flag = true;

        for (var j = 0; j < data.test_values.length; j++) {
          if (data.test_values[j].isSelected == true) {
            pres_tests.push({
              test_id: data.test_values[j].pres_test_id,
              test_type: data.test_values[j].test_type,
              report_type: this.reporttypeval,
            })
          }
        }

      
       
        this.report = {
          diag_app_id: data.diag_app_id,
          tests: pres_tests,
          test_type: this.reporttypeval,
          reject_type: 'reconduct',
         
          country: ipaddress.country_code,
          reject_reason: '',
          type: 'reconduct',
        };

        console.log("pres_tests", pres_tests)

      

      }
    }


  }

  saveReconduct() {
    if (this.reConduct_Flag == true) {
      this.report.reject_reason = this.reconduct_reason;

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + "diagnosiscontroller/rdtbp",
        JSON.stringify(this.report), { headers: headers }).subscribe(
          data => {
            if (data.json().key == "1") {
              this.toastr.success("Successfully rejected report for reconducting test");
            } else {
              this.toastr.error(Message_data.getNetworkMessage());
            }
          }, error => {
            this.toastr.error(Message_data.getNetworkMessage());
          });
    } else {
      this.toastr.error("Select atleast one test to reconduct");
    }
  }

}

