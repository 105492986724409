<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
      <mdb-card>
        <mdb-card-header class="bg-white">
          <div class="headerCover">
            <div class="headerTilte app_list_header">
              <h5 class="m-0" class="mainHeadingStyle">Short expiry Report</h5>
            </div>
            <div class="headerButtons">
             
            </div>
          </div>
          
        </mdb-card-header>
        <div class="d-flex justify-content-end" style="width:95%;" *ngIf="backbutton">
            
          <img src="../../../assets/ui_icons/buttons/back_icon.svg" style="width:30px;margin-right: 5px;" width="85px" (click)="backpage()">
          <a style="margin:0 5px;" (click)="print_area()"><img src="../../../assets/img/printer.svg"
             class="backimgbtn_inpatinfo" style="width: 25px; height: 25px;" *ngIf="backbutton" /></a>
             <img src="../../../assets/ui_icons/buttons/XL_export.svg" class="saveimgbtn_inpatinfo"
             (click)="getExcelData('excel')" />
          </div>
        <mdb-card-body>
          <!-- <div class="cover_div" style="padding-bottom: 13px;" *ngIf="forcoverdiv">
            <div class="header_lable">
              Short Expiry report
            </div>
            <div class="content_cover">
              <div class="row">
                <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 select_bottom">
                   <mat-label class="matlabel">Duration<br>
                     <select [(ngModel)]="filtduration" class="ipcss">
                       <option value="select">Select</option>
                       <option value="0">Present date</option>
                       <option value="7">1 week</option>
                       <option value="30">1 month</option>
                       <option value="91">3 months</option>
                       <option value="183">6 months</option>
                       <option value="365">1 year</option>
                     </select>
                   </mat-label>
                 </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                  <mat-label class="matlabel"><br>
                    <img src="../../../assets/ui_icons/buttons/search_button.svg" class="saveimgbtn_inpatinfo"
                      (click)="getshortexpData()" />
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                  <mat-label class="matlabel"><br>
                  
                  </mat-label>
                </div>
               
              </div>
            </div>
          </div> -->
          <p class="nodata" *ngIf="shortexparray.length ==0">No records found</p>


          <!-- <div class="table-responsive dig_table_overflow" style="margin-top: 10px;"  *ngIf="shortexparray.length != 0 ">
            <table #printinward id="printinward"  class="table table-hover table-dynamic"
              style="font-size: 12px; width: 100%;margin-left: 5px;">
              <thead>
                <tr>
                  <th>s.no</th>
                  <th>Product name</th>
                  <th>Batch</th>
                  <th>Supplier name</th>
                  <th>Qty rec</th>
                  <th>Free qty</th>
                  <th>Cost price</th>
                  <th>sales price</th>
                  <th>MRP</th>
                  <th>Received dt</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let prod of shortexparray">
                  <td>{{prod.sno}}</td>
                  <td>{{prod.product_name}}</td>
                  <td>{{prod.batch_no}}</td>
                  <td>{{prod.supplier_name}}</td>
                  <td>{{prod.qty_received}}</td>
                  <td>{{prod.free_qty}}</td>
                  <td>{{prod.cost_price}}</td>
                  <td>{{prod.sales_price}}</td>
                  <td>{{prod.mrp}}</td>
                  <td>{{prod.qty_received_date}}</td>
                </tr>
 
               
 
              </tbody>
            </table>
          </div> -->

          <div class="table-responsive"  *ngIf="shortexparray.length != 0 ">
            <table style="width:100%">
                 <thead   style="  position: sticky;
                 top: 0;
                 z-index: 10;">
                   <tr style="background-color:#c1e6fb;color: rgba(0,0,0,.54);min-height: 56px;">
                     <!-- <div style="width:100%;display: flex;
                     justify-content: space-evenly"> -->
                    
                        <th class="text-center" style="width:5%">s.no</th>
                        <th class="text-center" style="width:20%">Product name</th>
                        <th class="text-center" style="width:7%">Batch</th>
                        <th class="text-center" style="width:10%">Supplier</th>
                        <th class="text-center" style="width:8%">Qty rec</th>
                        <th class="text-center" style="width:8%">Free qty</th>
                        <th class="text-center" style="width:8%">Cost price</th>
                        <th class="text-center" style="width:8%">sales price</th>
                        <th class="text-center" style="width:5%">MRP</th>
                        <th class="text-center" style="width:11%">Received dt</th>
                        <th class="text-center" style="width:20%">Expiry dt</th>
                     <!-- </div> -->
                     <td><td>
                   </tr>
                 </thead>
                 <tbody>
                  <tr *ngFor="let prod of shortexparray" style="height: 62px !important;">
                        <td style="text-align: center">{{prod.sno}}</td>
                        <td class="text-center">{{prod.product_name}}</td>
                        <td class="text-left">{{prod.batch_no}}</td>
                        <td class="text-left">{{prod.supplier_name}}</td>
                        <td class="text-right">{{prod.qty_received}}</td>
                        <td class="text-right">{{prod.free_qty}}</td>
                        <td class="text-right">{{prod.cost_price}}</td>
                        <td class="text-right">{{prod.sales_price}}</td>
                        <td class="text-right">{{prod.mrp}}</td>
                        <td class="text-center">{{prod.qty_received_date}}</td>
                        <td class="text-center">{{prod.expiry}}</td>
                   </tr>
                 </tbody>
               </table>
 </div>
        </mdb-card-body>
      </mdb-card>
    </div>
  </div>
 