<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white ">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Consolidated Bill Details</h5>
          </div>
          <div class="headerButtons">
            <a (click)="backtoApplist('view')" *ngIf="listview_hidden" style="margin:0 5px;cursor: pointer;">
              <img src="../../../assets/ui_icons/buttons/Back_button.svg" class="backimgbtn_inpatinfo"/>
            </a>
            <a (click)="printBill()" *ngIf="listview_hidden" style="cursor: pointer;">
              <img src="../../../assets/img/printer.svg" width="25px" height="25px"/>
            </a>
            <a (click)="Bill_save()" *ngIf="saveFlag &&listview_hidden" style="cursor: pointer;">
              <img src="../../../assets/ui_icons/buttons/save_button.svg" class="saveimgbtn_inpatinfo" style="margin-left: 7px;"/>
            </a>
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body style="padding-top: 5px;">
        <div *ngIf="listview_hidden">
          <div class="row">
            <div class="col-12 col-sm-6 col-md-12 col-lg-12 col-xl-12">
              <div class="cover_div" style="margin-top: 0px;">
                <div class="header_lable">
                  Patient Details
                </div>
                <div class="content_cover">
                  <div class="row">
                    <div class="col-12">
                      <div class="row">                                                       
                        <div class="col-3">
                          <table style="width: 100%;" class="head-table">
                            <tbody>
                              <tr class="head-tr">
                                <td class="head-td" style="width: 95px; font-weight: 500;font-family: Arial;color: black;">
                                  <b>MR No</b></td>
                                <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{inpatientidnew}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="col-3">
                          <table style="width: 100%;" class="head-table">
                            <tbody>
                              <tr class="head-tr">
                                <td class="head-td" style="width: 95px; font-weight: 500;font-family: Arial;color: black;">
                                  <b>Patient name</b></td>
                                <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{clientnamenew}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="col-3">
                          <table style="width: 100%;" class="head-table">
                            <tbody>
                              <tr class="head-tr">
                                <td class="head-td" style="width: 95px; font-weight: 500;font-family: Arial;color: black;">
                                  <b>Age/Gender</b></td>
                                <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                  {{agegendervalue}} 
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="col-3">
                          <table style="width: 100%;" class="head-table">
                            <tbody>
                              <tr class="head-tr">
                                <td class="head-td" style="width: 95px; font-weight: 500;font-family: Arial;color: black;">
                                  <b>Visit id</b></td>
                                <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{inpid}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>      
                        <div class="col-3">
                          <table style="width: 100%;" class="head-table">
                            <tbody>
                              <tr class="head-tr">
                                <td class="head-td" style="width: 95px; font-weight: 500;font-family: Arial;color: black;">
                                  <b>Mobile</b></td>
                                <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{mobilenumber}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div> 
                        <div class="col-3">
                          <table style="width: 100%;" class="head-table">
                            <tbody>
                              <tr class="head-tr">
                                <td class="head-td" style="width: 95px; font-weight: 500;font-family: Arial;color: black;">
                                  <b>Doctor</b></td>
                                <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                  {{doctorname}}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>        
                        <div class="col-3">
                          <table style="width: 100%;" class="head-table">
                            <tbody>
                              <tr class="head-tr">
                                <td class="head-td" style="width: 95px; font-weight: 500;font-family: Arial;color: black;">
                                  <b>Ward & bed no</b></td>
                                <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{ward_name}} <span *ngIf="bedno != '' && bedno != undefined">/</span> {{bedno}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>  
                        <div class="col-3">
                          <table style="width: 100%;" class="head-table">
                            <tbody>
                              <tr class="head-tr">
                                <td class="head-td" style="width: 95px; font-weight: 500;font-family: Arial;color: black;">
                                  <b>ADT</b></td>
                                <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                  {{admitted_date}} {{admitted_timevalue}} </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>                                                                 
                      </div>      
                    </div>                
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="cover_div">
            <div class="header_lable">
              Inpatient details
            </div>
            <div class="content_cover">
              <div class="row">
                <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 select_bottom">
                  <mat-label class="matlabel">Filter by<br>
                    <select required class="ipcss" [(ngModel)]="Filter_based" required
                      (change)="Filter_changename(Filter_based)" disableOptionCentering>
                      <option value="mrno">MR no</option>
                      <option value="name">Name</option>
                      <option value="mobile">Mobile</option>
                    </select>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2" *ngIf="mrnoflag">
                  <mat-label class="matlabel">MR no<br>
                    <input type="text" class="ipcss" [(ngModel)]="mr_no" name="mrno"
                      (keyup)="getAppointments($event,'mrno','select')" type="text" aria-label="Number" matInput
                      [matAutocomplete]="auto" />
                    <mat-autocomplete #auto="matAutocomplete">
                      <mat-option id="optionfont" *ngFor="let location of appointment_list"
                        (click)="Appointment_change(location.client_reg_id,location.relation_id,location.sub_rel_id,location,location.approved_amount)"
                        [value]="location.docid_name">
                        {{location.docid_name}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2" *ngIf="mobileflag">
                  <mat-label class="matlabel">Mobile<br>
                    <input type="text" class="ipcss" [(ngModel)]="mobile" name="mobile"
                      (keyup)="getAppointments($event,'mobile','select')" type="text" aria-label="Number" matInput
                      [matAutocomplete]="auto" />
                    <mat-autocomplete #auto="matAutocomplete">
                      <mat-option id="optionfont" *ngFor="let location of appointment_list"
                        (click)="Appointment_change(location.client_reg_id,location.relation_id,location.sub_rel_id,location,location.approved_amount)"
                        [value]="location.docid_name">
                        {{location.docid_name}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2" *ngIf="nameflag">
                  <mat-label class="matlabel">Patient name<br>
                    <input type="text" class="ipcss" [(ngModel)]="appoint_txt" name="loc_name"
                      (keyup)="getAppointments($event,'name','select')" type="text" aria-label="Number" matInput
                      [matAutocomplete]="auto" />
                    <mat-autocomplete #auto="matAutocomplete">
                      <mat-option id="optionfont" *ngFor="let location of appointment_list"
                        (click)="Appointment_change(location.client_reg_id,location.relation_id,location.sub_rel_id,location,location.approved_amount)"
                        [value]="location.docid_name">
                        {{location.docid_name}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-label>
                </div>
              </div>
            </div>
          </div> -->
          <div class="billTypeCover">
            <img class="billTypeImg" id="hospBillIMG" (click)="billType('doctor')"
              src="../../../assets/ui_icons/hospital_bill_selected_icon.png" alt="">
            <img class="billTypeImg" id="pharBillIMG" (click)="billType('pharma')"
              src="../../../assets/ui_icons/pharmacy_bill_non_selected_icon.png" alt="">
            <img class="billTypeImg" id="diagBillIMG" (click)="billType('dignosis')"
              src="../../../assets/ui_icons/diagnosis_bill_non_selected_icon.png" alt="">
          </div>
          <div class="billAreaCover">
            <div class="accordion accordion-flush" id="accordionFlushExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingOne">
                  <div class="coverRow">
                    <div class="row" style="text-align: center;">
                      <div class="col-1 my-auto ConHeading" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne"
                        aria-expanded="true" aria-controls="flush-collapseOne">Bill ID</div>
                      <div class="col-2 my-auto ConHeading" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne"
                        aria-expanded="true" aria-controls="flush-collapseOne">Bill Date</div>
                      <div class="col-2 my-auto ConHeading" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne"
                        aria-expanded="true" aria-controls="flush-collapseOne">Bill Amount</div>
                      <div class="col-1 my-auto ConHeading" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne"
                        aria-expanded="true" aria-controls="flush-collapseOne">Discount</div>
                      <div class="col-2 my-auto ConHeading" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne"
                        aria-expanded="true" aria-controls="flush-collapseOne">Paid Amount</div>
                      <div class="col-1 my-auto ConHeading" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne"
                        aria-expanded="true" aria-controls="flush-collapseOne">Advance</div>
                      <div class="col-2 my-auto ConHeading" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne"
                        aria-expanded="true" aria-controls="flush-collapseOne">Balance</div>
                      <div class="col-1 my-auto">
                        <img *ngIf="selectedAllFlage == false" (click)="allSelected(true)"
                          src="../../../assets/ui_icons/yellow_box.svg" style="cursor: pointer;" width="15px" />
                        <img *ngIf="selectedAllFlage == true" (click)="allSelected(false)"
                          src="../../../assets/ui_icons/popup_tick_mark.svg" style="cursor: pointer;" width="15px" />
                      </div>
                    </div>
                  </div>
                </h2>
              </div>
              <div class="accordion-item" *ngFor="let bills of DisplayBills;let i = index">
                <h2 class="accordion-header" id="flush-heading{{i}}">
                  <div class="coverRow">
                    <div class="row" style="text-align: center;">
                      <div class="col-1 my-auto ConContent " data-bs-toggle="collapse"
                        [attr.data-bs-target]="'#flush-collapse'+i" aria-expanded="false"
                        aria-controls="flush-collapse{{i}}">{{bills.billing_id}}</div>
                      <div class="col-2 my-auto ConContent" data-bs-toggle="collapse"
                        [attr.data-bs-target]="'#flush-collapse'+i" aria-expanded="false"
                        aria-controls="flush-collapse{{i}}">{{bills.bill_date}}</div>
                      <div class="col-2 my-auto ConContent" data-bs-toggle="collapse"
                        [attr.data-bs-target]="'#flush-collapse'+i" aria-expanded="false"
                        aria-controls="flush-collapse{{i}}">{{bills.bill_amount}}</div>
                      <div class="col-1 my-auto ConContent" data-bs-toggle="collapse"
                        [attr.data-bs-target]="'#flush-collapse'+i" aria-expanded="false"
                        aria-controls="flush-collapse{{i}}">{{bills.discount}}</div>
                      <div class="col-2 my-auto ConContent" data-bs-toggle="collapse"
                        [attr.data-bs-target]="'#flush-collapse'+i" aria-expanded="false"
                        aria-controls="flush-collapse{{i}}">{{bills.paid_amount}}</div>
                      <div class="col-1 my-auto ConContent" data-bs-toggle="collapse"
                        [attr.data-bs-target]="'#flush-collapse'+i" aria-expanded="false"
                        aria-controls="flush-collapse{{i}}">{{bills.advance}}</div>
                      <div class="col-2 my-auto ConContent" data-bs-toggle="collapse"
                        [attr.data-bs-target]="'#flush-collapse'+i" aria-expanded="false"
                        aria-controls="flush-collapse{{i}}">{{bills.balance}}</div>
                      <div class="col-1 my-auto ConContent">
                        <img *ngIf="bills.checked == false" (click)="selectIndividual(bills)"
                          src="../../../assets/ui_icons/yellow_box.svg" style="cursor: pointer;" width="15px" />
                        <img *ngIf="bills.checked == true" (click)="selectIndividual(bills)"
                          src="../../../assets/ui_icons/popup_tick_mark.svg" style="cursor: pointer;" width="15px" />
                        <!-- <mdb-checkbox [default]="true" [(ngModel)]="bills.checked" name="list_name" (change)="isAllSelected(bills)"
                                                  value="{{bills.checked}}" [disabled]="bills.balance == '0.00' || bills.balance == '0'"></mdb-checkbox> -->
                      </div>
                    </div>
                  </div>
                </h2>
                <div id="flush-collapse{{i}}" class="accordion-collapse collapse" aria-labelledby="flush-heading{{0}}"
                  data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body" style="border-top: 1px solid #84ccf5;font-family: Arial, Helvetica, sans-serif;">
                    <table class="table table-hover table-nowrap table-sm" *ngIf="bills.type == 'doctor'">
                      <thead class="table-light">
                        <tr>
                          <th style="width: 10%;"> S.No </th>
                          <th style="width: 45%;"> Product Name </th>
                          <th style="width: 15%;"> Quantity </th>
                          <th style="width: 15%;"> Amount </th>
                          <!-- <th> Advance </th> -->
                          <th style="width: 15%;"> Total Amount </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let billslist of bills.billsList">
                          <td colspan="8" *ngIf="billslist.category_flag"> 
                            <div>
                              <div>
                                <p class="tervys_heading1 col-12"><b
                                  style="text-transform: capitalize;">{{billslist.category}}</b></p>
                                <table class="table" style="margin-bottom: 0;">
                                  <tbody>
                                    <tr *ngFor='let billslist1 of billslist.charges'>
                                      <td style="width: 10%;text-align: center;"> {{billslist1.sno}} </td>
                                      <td style="width: 45%;text-align: left;"> {{billslist1.name}} </td>
                                      <td style="width: 15%;text-align: right;"> {{billslist1.quantity}} </td> 
                                      <td style="width: 15%;text-align: right;"> {{billslist1.amount}} </td> 
                                      <!-- <td style="text-align: right;padding-right:20px"> {{billslist1.advance}} </td> -->
                                      <td style="width: 15%;text-align: right;"> {{billslist1.fee}} </td> 
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </td>
                        </tr>                       
                      </tbody>
                    </table>
                    <table class="table table-hover table-nowrap table-sm" *ngIf="bills.type == 'diagnosis' ">
                      <thead class="table-light">
                        <tr>
                          <th> S.No </th>
                          <th> Product Name </th>
                          <th> Quantity </th>
                          <th> Amount </th>
                          <!-- <th> Advance </th> -->
                          <th> Total Amount </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let billslist of bills.billsList;let j = index">
                          <td style="text-align: center;"> {{j+1}} </td>
                          <td> {{billslist.name}} </td>
                          <td style="text-align: right;padding-right:20px"> {{billslist.quantity}} </td>
                          <td style="text-align: right;padding-right:20px"> {{billslist.amount}} </td>
                          <!-- <td style="text-align: right;padding-right:20px"> {{billslist.advance}} </td> -->
                          <td style="text-align: right;padding-right:20px"> {{billslist.fee}} </td>
                        </tr>
                      </tbody>
                    </table>
                    <table class="table table-hover table-nowrap table-sm" *ngIf="bills.type == 'pharma'">
                      <thead class="table-light">
                        <tr>
                          <th> S.No </th>
                          <th> Product Name </th>
                          <th> Batch No </th>
                          <th> Quantity </th>
                          <th> Amount </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let billslist of bills.billsList;let j = index">
                          <td style="text-align: center;"> {{j+1}} </td>
                          <td> {{billslist.name}} </td>
                          <td> {{billslist.batch_no}} </td>
                          <td style="text-align: right;padding-right:20px"> {{billslist.quantity}} </td>
                          <td style="text-align: right;padding-right:20px"> {{billslist.amount}} </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" [hidden]="!paytypreq">
            <div class="col-0 col-sm-3 col-md-7 col-lg-7 col-xl-7">
              <div class="cover_div" [hidden]="patient_selectionflag" style="margin-top: 0px !important;padding: 10px 5px 5px 5px;">
                <div class="row">
                  <div class="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-4 select_bottom">
                    <mat-label class="matlabel">Payment mode<br>
                      <select class="ipcss " [(ngModel)]="payType1" disableOptionCentering>
                        <option *ngFor="let pay of paytypelist" value={{pay.pay_id}}>{{pay.pay_desc}}</option>
                      </select>
                    </mat-label>
                  </div>
                  <div class="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-4">
                    <mat-label class="matlabel">Amount<br>
                      <input type=number class="ipcss billing_text_align" [(ngModel)]="amountval" matInput required
                        maxlength="7" style="text-align: right;"/>
                    </mat-label>
                  </div>
                  <div class="col-12 col-sm-2 col-md-3 col-lg-2 col-xl-2">
                    <mat-label class="matlabel ">
                      <img src="../../../assets/ui_icons/buttons/add_button.svg"
                        class="saveimgbtn_inpatinfo_add add_align btn-outline-success rounded" (click)="addPaymentDetails()" />
                    </mat-label>
                  </div>
                </div>
                <div class="row rowchanges">
                  <table>
                    <tr *ngFor="let pay of paymentsarray">
                      <td style="width: 150px !important;" class="payment_array_style">{{pay.pay_desc}}</td>
                      <td style="width: 150px !important;text-align: right;" class="payment_array_style">{{pay.amount}}</td>&nbsp;
                      <td>
                        <img src="../../../assets/img/edit.png" class="billing_edit" (click)="editPayment(pay)">&nbsp;&nbsp;
                        <img src="../../../assets/img/Delete.png" class="billing_edit" (click)="deletePayment(pay)">
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-0 col-sm-2  col-md-2 col-lg-2 col-xl-2"> </div>
            <div class="col-0 col-sm-6  col-md-3 col-lg-3  col-xl-3">
              <div class="row">
                <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                  <mat-label class="matlabel heightmsr">Total </mat-label>
                </div>
                <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                  <input class="ipcss billcreatech heightmsr bill_txt" matInput text-right disabled="true"
                    [(ngModel)]="totalBillAmountvalue" style="text-align: right;" />
                </div>
                <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                  <mat-label class="matlabel heightmsr">Advance</mat-label>
                </div>
                <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                  <input class="ipcss  heightmsr bill_txt billcreatech" matInput text-right (keyup)="advance_change()"
                    [(ngModel)]="total_advance" maxlength="10" disabled="true" style="text-align: right;" />
                </div>
                <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                  <mat-label class="matlabel heightmsr">Discount</mat-label>
                </div>
                <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                  <input class="ipcss  heightmsr bill_txt billcreatech" matInput text-right (blur)="discountvalue()"
                    [(ngModel)]="discountvaluecheck" maxlength="10" (focus)="checkdiscount()"
                    style="text-align: right;" />
                </div>
                <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                  <mat-label class="matlabel heightmsr">Paid</mat-label>
                </div>
                <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6">
                  <input class="ipcss  heightmsr bill_txt billcreatech" matInput text-right (blur)="checkBalance()"
                    [(ngModel)]="paid_bill" maxlength="10" style="text-align: right;" (focus)="checkBalance1()" />
                </div>
                <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6" *ngIf="sponsorDueflag">
                  <mat-label class="matlabel heightmsr">Sponsor Due </mat-label>
                </div>
                <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6" *ngIf="sponsorDueflag">
                  <input class="ipcss billcreatech heightmsr bill_txt" matInput text-right disabled="true"
                    [(ngModel)]="sponsorDue" style="text-align: right;" />
                </div>
                <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6" *ngIf="!adv_refflag">
                  <mat-label class="matlabel heightmsr">Balance</mat-label>
                </div>
                <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6" *ngIf="!adv_refflag">
                  <input class="ipcss billcreatech heightmsr bill_txt" matInput text-right [(ngModel)]="remaining_billbal"
                    maxlength="10" disabled="true" style="text-align: right;" />
                </div>
                <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6" *ngIf="adv_refflag">
                  <mat-label class="matlabel heightmsr">Refund</mat-label>
                </div>
                <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6" *ngIf="adv_refflag">
                  <input class="ipcss billcreatech heightmsr bill_txt" matInput text-right [(ngModel)]="remaining_billbal"
                    maxlength="10" disabled="true" style="text-align: right;" />
                </div>
                <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6" *ngIf="adv_refflag">
                  <mat-label class="matlabel heightmsr">Refund reason</mat-label>
                </div>
                <div class="col-6 col-sm-8 col-md-6 col-lg-6 col-xl-6" *ngIf="adv_refflag">
                  <textarea class="ipcss inpat_width" style="height: 45px;max-height: 45px;" [(ngModel)]="refund_reason" required matInput></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="!listview_hidden">
          <div class="row" style="margin-top: -10px">
            <div class="col-10">
              <div class="row">
                <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-2 select_bottom">
                  <mat-label class="matlabel">Doctor<br>
                    <select class="ipcss widthappt" (ngModelChange)="changeDoctor($event)" [(ngModel)]="doctor">
                      <option *ngFor="let doctors of doctorList" value={{doctors.Doc_ID}}>{{doctors.Doc_Name}}
                      </option>
                    </select>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                  <mat-label class="matlabel">View<br>
                    <select disableOptionCentering class="ipcss widthappt" (change)="viewChange(view_type)"
                      [(ngModel)]="view_type">
                      <option value="graph">Graphical</option>                
                      <option value="tabular">Tabular</option>
                    </select>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                  <mat-label class="matlabel">
                    <div class="flex-container">
                      <div class="label">Floor</div>
                      <select class="ipcss widthappt" (ngModelChange)="changefloorvalue($event)" [(ngModel)]="floorvalue">
                        <option *ngFor="let floorcheck of floorarray" [value]="floorcheck">{{ floorcheck }}</option>
                      </select>
                    </div>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2">
                  <mat-label class="matlabel">Filter by<br>
                    <select disableOptionCentering class="ipcss widthappt" (change)="Filterbydata(selectvalue)"
                      [(ngModel)]="selectvalue" >
                      <option value="mrno">MR no</option>
                      <option value="date">Date</option>
                      <option value="name">Name</option>
                      <option value="location">Location</option>                   
                      <option value="mobile">Mobile</option>
                    </select>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2" *ngIf="mrnoflag">
                  <mat-label class="matlabel">MR no<br>
                    <input type="text" style="height: 25px;" class="ipcss inpat_width" [(ngModel)]="mrno" matInput (keyup)="getdatavaluemrno($event)" />
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2" *ngIf="mobileflag">
                  <mat-label class="matlabel">Mobile<br>
                    <input type="text" style="height: 25px;" class="ipcss inpat_width" [(ngModel)]="mobile" matInput (keyup)="getdatamobilenumber($event)" />
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2" *ngIf="nameflag">
                  <mat-label class="matlabel">Name<br>
                    <input type="text" style="height: 25px;" class="ipcss inpat_width" [(ngModel)]="name" matInput (blur)="mrnobased()"  (keyup)="getdatavalue($event)" />
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2" *ngIf="locationflag">
                  <mat-label class="matlabel">Location<br>
                    <input type="text" style="height: 25px;" class="ipcss inpat_width" [(ngModel)]="location"  matInput  (keyup)="getdatavaluelocation($event)" />
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" style="width: fit-content;margin-top: -23px !important;" *ngIf="fdateflag">
                  <mat-label class="matlabel"><br></mat-label>
                  <div class="full-input">
                    <mat-label class="matlabel">From&nbsp;</mat-label>
                    <input type="date"  (change)="selectFromDate(fromDate)" class="ipcss" id="appt_date" [(ngModel)]="fromDate" #matInput
                      max="{{currentMaxDate}}"  >
                  </div>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" style="width: fit-content;margin-top: -23px !important;" *ngIf="todateflag">
                  <mat-label class="matlabel"><br></mat-label>
                  <div class="full-input">
                    <mat-label class="matlabel">To&nbsp;</mat-label>
                    <input type="date" (change)="selectToDate(toDate)" class="ipcss"  id="appt_date"  [(ngModel)]="toDate" #matInput
                      max="{{currentMaxDate}}" >
                  </div>
                </div>
                <div class="col-1 d-flex justify-content-start" style="margin-top: 23px !important;" *ngIf="nameflag || locationflag || todateflag">
                  <mat-label class="matlabel"><br></mat-label>
                  <a (click)="filtercheck()">
                    <img src="../../../assets/ui_icons/search_icon_new.svg" width='18px' height="auto" style="cursor: pointer;" class="topvalue" />
                  </a>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2" style="text-align: right;margin-top: 23px !important;">
              <p class="textformat"> Count: <strong>{{count}}</strong></p>
            </div>             
          </div>          
          <div *ngIf="tabular_format">
            <p class="nodata" *ngIf="patientList.length == 0">No Patient(s) found</p>
            <table *ngIf="patientList.length" mdbTable datatable [dtOptions]="dtOptions"
              class="table table-nowrap table-sm dataTable">
              <thead>
                <tr>
                  <th>Image</th>
                  <th>MR no</th>
                  <th>Inpatient id</th>                
                  <th style="text-align: center !important;">Patient name</th>
                  <th>Speciality</th>
                  <th>Admitted on</th>
                  <th>Surgery date</th>
                  <th>Ward</th>
                  <th>MLC case</th>
                  <th style="width: 5%;">                  
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let person of patientList; let i = index">
                  <td (click)='viewPatient(person.patient_id,person.inpatient_id,person.mobile,person.client_reg_id,person.first_name,person.middle_name,person.last_name, person.speciality,person)'>
                    <img src="{{person.profile_image}}" class="img-fluid z-depth-1 rounded-circle mr-1"></td>               
                  <td (click)='viewPatient(person.patient_id,person.inpatient_id,person.mobile,person.client_reg_id,person.first_name,person.middle_name,person.last_name, person.speciality,person)'>
                    <p class="ptagvalue">{{person.patient_id}}</p></td>           
                  <td (click)='viewPatient(person.patient_id,person.inpatient_id,person.mobile,person.client_reg_id,person.first_name,person.middle_name,person.last_name, person.speciality,person)' 
                    style="font-size: 12px;">{{ person.hosp_inp_id }}</td>                 
                  <td (click)='viewPatient(person.patient_id,person.inpatient_id,person.mobile,person.client_reg_id,person.first_name,person.middle_name,person.last_name, person.speciality,person)'
                    style="font-size: 12px;text-align: left !important;">{{ person.patient_name }}</td>                  
                  <td (click)='viewPatient(person.patient_id,person.inpatient_id,person.mobile,person.client_reg_id,person.first_name,person.middle_name,person.last_name, person.speciality,person)'
                    style="font-size: 12px;text-align: left !important;">{{ person.speciality }}</td>                 
                  <td (click)='viewPatient(person.patient_id,person.inpatient_id,person.mobile,person.client_reg_id,person.first_name,person.middle_name,person.last_name, person.speciality,person)'
                    style="font-size: 12px;">{{ person.admit_date }}</td>               
                  <td (click)='viewPatient(person.patient_id,person.inpatient_id,person.mobile,person.client_reg_id,person.first_name,person.middle_name,person.last_name, person.speciality,person)'
                    style="font-size: 12px;">{{ person.surgery_date }}</td>
                  <td (click)='viewPatient(person.patient_id,person.inpatient_id,person.mobile,person.client_reg_id,person.first_name,person.middle_name,person.last_name, person.speciality,person)'
                    style="font-size: 12px;text-align: left;padding-left: 50px;">{{ person.ward_text }}</td>
                  <td (click)='viewPatient(person.patient_id,person.inpatient_id,person.mobile,person.client_reg_id,person.first_name,person.middle_name,person.last_name, person.speciality,person)'
                    style="font-size: 12px;text-align: left;padding-left: 50px;">{{ person.mlc }}</td>
                  <td style="font-size: 12px;width: 5%;">
                    <!-- <mdb-checkbox [default]="true" [(ngModel)]="person.checked" name="list_name"
                      value="{{person.inpatient_id}}" (change)="isInpatSelected(person)" [disabled]="person.disflag">
                    </mdb-checkbox> -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div *ngIf="graphicalformat">
            <p class="nodata" *ngIf="patientList.length == 0">No Patient(s) found</p>
            <div class="row">
              <div class="col-2" *ngFor="let person of patientList" style="text-align: center;">
                <div
                  (click)='viewPatient(person.inpatient_id,person.mobile,person.client_reg_id,person,person.first_name,person.middle_name,person.last_name, person.speciality,person)'>
                  <div class="bed_bed_no">{{person.patient_name}}</div>
                  <div class="bed_bed_no1">{{person.bed_no}}</div>
                  <div><img src="{{person.bed_img}}" class="bed_img">
                  </div>
                  <div class="bed_bed_name d-flex justify-content-center">
                    {{person.ward_text}}</div>
                  <div class="bed_bed_name1 d-flex justify-content-center">
                    {{person.hosp_inp_id}}</div>
                  <div class="bed_bed_name2 d-flex justify-content-center">
                    {{person.admit_date}}</div>
                  <div class="bed_bed_name2 d-flex justify-content-center">
                    {{person.doctor_name}}</div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="graph_defaultimg">
            <p class="nodata" *ngIf="patientList.length == 0">No Patient(s) found</p>
            <div class="row">
              <div class="col-2 " *ngFor="let person of patientList" style="text-align: center;padding: 10px;">
                <div class="defaultimg">
                  <div class="defaultimg1" 
                    (click)='viewPatient(person.patient_id,person.inpatient_id,person.mobile,person.client_reg_id,person.first_name,person.middle_name,person.last_name, person.speciality,person)'>
                    <div>
                      <img src="{{person.profile_image}}" class="cycling" />
                    </div>
                    <div>
                      <p class="ptagvalue">{{person.patient_name}}</p>
                      <p class="ptagvalue">{{person.patient_id}}</p>
                      <p class="ptagvalue">{{person.hosp_inp_id}}</p>
                      <p class="ptagvalue">{{person.gender_age}}</p>
                      <p class="ptagvalue">{{person.locationcheck}}/{{person.districtcheck}}</p>
                      <div style="border-top: 1px solid #91badd;">
                      <p class="ptagvalue">{{person.ward_text}}</p>
                    </div>
                      <p class="ptagvalue">ADT: {{person.admitdatetime}}</p>
                      <p class="ptagvalue">{{ person.doctor_name }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>   
        </div>
        <!-- print -->
        <div [hidden]="true">
          <div #printlogowithname id="printlogowithname">
            <div class="bill_border">
              <div *ngFor="let billprint of billing_print_array">
                <div>
                  <table>
                    <thead>
                      <tr>
                        <th style="width:100%;height: 100px;"></th>
                      </tr>
                    </thead>
                    <tfoot>
                      <tr>
                        <td width="100%">
                          <table style="border:'0';width:100%">
                            <tr>
                              <td style="width:100%;height: 100px;">
                                <table style="width: 100%;">
                                  <tr>
                                    <td style="display: flex;justify-content: center;">
                                      <div>
                                        <img alt="image" src={{hospital_logo}} width="100px" height="100px"
                                          style="vertical-align: middle;">
                                      </div>
                                      <div style="position: relative;top: 10px;left: 10px;text-align: left;">
                                        <span><b>{{hospital_name}}</b>,</span><br />
                                        <span>{{hospitalAddress}}</span><br />
                                        <span>{{hosp_location}}</span> <span>{{hosp_district}}</span><br />
                                        <span>{{hosp_state}} {{hosp_zip}}</span><span
                                          *ngIf="hosp_telephone != undefined"><img src="../../../assets/img/phone.png"
                                            width="15px" height="15px" />{{hosp_telephone}}.</span><br>
                                      </div>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </tfoot>
                    <tbody>
                      <tr>
                        <td class="report-content-cell">
                          <div class="main">
                            <div style="width: 98%; margin: 0 auto;">
                              <h3 style="margin:10px 0 ;" class="heading">Patient details</h3>
                              <table style="width: 100%;">
                                <tr>
                                  <td style="width: 58%; vertical-align: top;">
                                    <div>
                                      <span><strong>MR no: </strong>{{inpatientidnew}} </span>
                                      <br />
                                      <span><strong>Patient name: </strong>{{billprint.client_name}} </span>
                                      <br />
                                      <span><strong>Age / gender: </strong>{{age}} / {{gender}}</span>
                                      <br />
                                      <span><strong>Visit id: </strong>{{billprint.inpatientId}}</span>
                                      <br />
                                      <span><strong>Speciality: </strong>{{specialization_name}}</span>
                                      <br />
                                      <span><strong>Address : </strong></span>
                                      <span *ngIf="billprint.address != ''">{{billprint.address}}</span>
                                      <br />
                                      <span>{{billprint.location}}{{billprint.city}}</span>
                                      <br />
                                      <span>{{billprint.state}}</span>
                                      <br />
                                      <span *ngIf="billprint.telephone != undefined">
                                        <img src="../../../assets/img/phone.png" width="15px" height="15px" />
                                        {{billprint.telephone}}
                                      </span>
                                      <span *ngIf="Insuranceflag"><strong>Insurance corp: </strong>{{insurer}}</span>
                                      <br />
                                    </div>
                                  </td>
                                  <td style="width: 42%;vertical-align: top;">
                                    <div>
                                      <span><strong>Doctor :</strong>{{billprint.doctorname}} </span>
                                      <br />
                                      <span><strong>DOA:</strong> {{admissionDate}} {{admitted_timevalue}} </span>
                                      <br />
                                      <span><strong>DOD:</strong> {{dischargeDate}} {{dischargeTime}}</span>
                                      <br />
                                      <span><strong>Rate plan: </strong>{{rate_plan}}</span>
                                      <br />
                                      <span><strong>Ward & bed no:</strong> {{ward_name}} / {{bedno}}</span>
                                      <br />
                                      <span><strong>Surgery name:</strong> {{surgery_name}}</span>
                                      <br />
                                    </div>
                                    <img *ngIf="barcode_flag" style="width: 130px; height: 35px; margin-top: 5px"
                                      src={{barcode_url}} />
                                  </td>
                                </tr>
                              </table>
                            </div>
                            <div style="width: 98%; margin: 0 auto;" *ngIf="insuranceflag1">
                              <h3 style="margin:10px 0 ;" class="heading">Insurer details</h3>
                              <table style="width: 100%;">
                                <tr>
                                  <td style="width: 60%; vertical-align: top;">
                                    <div>
                                      <span><strong>Patient name : </strong>{{insurer}} </span>
                                      <br />
                                      <span><strong> Insurance card no : </strong>{{ins_card_no}}</span>
                                      <br />
                                      <span><strong>CNN number : </strong></span>
                                      <span>{{cnnno}}</span>
                                      <br />
                                    </div>
                                  </td>
                                </tr>
                              </table>
                            </div>
                            <!-- Bill List Particulars -->
                            <div *ngIf="sendDoctor.length != 0" style="width: 98%; margin: 0 auto;">
                              <h3 class="heading">Service Charges</h3>
                              <div class="row">
                                <table
                                  style=" border: 1px solid black;border-collapse: collapse;margin-right: 20px;width:100%;">
                                  <thead>
                                    <tr>
                                      <th style="width: 30%;border: 1px solid black;border-collapse: collapse;">
                                        <b>Service</b>
                                      </th>
                                      <th style="width: 15%;border: 1px solid black;border-collapse: collapse;">
                                        <b>Bill date</b>
                                      </th>
                                      <th style="width: 15%;border: 1px solid black;border-collapse: collapse;">
                                        <b>Quantity</b>
                                      </th>
                                      <th style="width: 15%;border: 1px solid black;border-collapse: collapse;">
                                        <b>Amount</b>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let billprinttable of docBillDetails">
                                      <td
                                        style="width: 200px; text-align: left;border: 1px solid black;border-collapse: collapse;padding:5px 5px;"
                                        data-th="Type">{{billprinttable.name}}</td>
                                      <td
                                        style="width: 100px;border: 1px solid black;border-collapse: collapse;padding:5px 5px;text-align: center;"
                                        data-th="Type">{{billprinttable.bill_date}} </td>
                                      <td
                                        style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                                        data-th="Fee"> {{billprinttable.quantity}} x {{billprinttable.amount}}</td>
                                      <td
                                        style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                                        data-th="Amount" class="fee_txt">{{billprinttable.fee}}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <!-- Madical Bills particulars -->
                            <div *ngIf="medcalDetailsArray.length != 0" style="width: 98%; margin: 0 auto;">
                              <h3 class="heading">Medicine bills </h3>
                              <div class="row" *ngIf="insuranceFlag">
                                <table style=" border: 1px solid black;border-collapse: collapse;margin-right: 20px;">
                                  <thead>
                                    <tr>
                                      <th style="width: 200px;border: 1px solid black;border-collapse: collapse;">
                                        <b>Service</b>
                                      </th>
                                      <th style="width: 200px;border: 1px solid black;border-collapse: collapse;">Bill
                                        date
                                      </th>
                                      <th style="width: 200px;border: 1px solid black;border-collapse: collapse;">
                                        Payment
                                        type</th>
                                      <th style="width: 150px;border: 1px solid black;border-collapse: collapse;">
                                        <b>Fee</b>
                                      </th>
                                      <th style="width: 150px;border: 1px solid black;border-collapse: collapse;">
                                        <b>Concession
                                          (%)</b>
                                      </th>
                                      <th style="width: 150px;border: 1px solid black;border-collapse: collapse;">
                                        <b>Amount</b>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let billprinttable of medcalDetailsArray">
                                      <td
                                        style="width: 200px; text-align: left;border: 1px solid black;border-collapse: collapse;padding:5px 5px;"
                                        data-th="Type">{{billprinttable.bill_type}}</td>
                                      <td
                                        style="width: 15%; border: 1px solid black;border-collapse: collapse;text-align: center;"
                                        data-th="Type">{{billprinttable.bill_date}}</td>
                                      <td
                                        style="width: 200px; text-align: left;border: 1px solid black;border-collapse: collapse;"
                                        data-th="Type">{{billprinttable.pay_type}}</td>
                                      <td
                                        style="width: 200px; text-align: left;border: 1px solid black;border-collapse: collapse;"
                                        data-th="Type">{{billprinttable.quantity}}</td>
                                      <td
                                        style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                                        data-th="Fee">{{billprinttable.amount_txt}}</td>
                                      <td
                                        style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                                        data-th="Concession">{{billprinttable.concession}}</td>
                                      <td
                                        style="width: 150px; text-align: right; border: 1px solid black;border-collapse: collapse;"
                                        data-th="Amount" class="fee_txt">{{billprinttable.fee}}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div class="row" *ngIf="!insuranceFlag">
                                <table
                                  style=" width: 100%; border: 1px solid black;border-collapse: collapse;margin-right: 20px;">
                                  <thead>
                                    <tr>
                                      <th style="width: 60%;border: 1px solid black;border-collapse: collapse;">
                                        <b>Bill No</b>
                                      </th>
                                      <th style="width: 15%;border: 1px solid black;border-collapse: collapse;">
                                        <b>Bill date</b>
                                      </th>
                                      <th style="width: 15%;border: 1px solid black;border-collapse: collapse;">
                                        <b>Amount</b>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let billprinttable of medcalDetailsArray">
                                      <td
                                        style="width: 60%;text-align: left;border: 1px solid black;border-collapse: collapse;padding:5px 5px;">
                                        {{billprinttable.name}}
                                      </td>
                                      <td
                                        style="width: 15%;border: 1px solid black;border-collapse: collapse;padding:5px 5px;">
                                        {{billprinttable.bill_date}}
                                      </td>
                                      <td
                                        style="width: 15%;text-align: right;border: 1px solid black;border-collapse: collapse;">
                                        {{billprinttable.amount}}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <!-- Radiology Bills Particulars -->
                            <div style="width: 98%;" *ngIf="digDetailsArray.length !=0">
                              <h3 class="heading">Pathology & Radiology</h3>
                              <div class="row">
                                <table style="border-collapse: collapse;margin-right: 20px;width:100%;">
                                  <thead>
                                    <tr>
                                      <th [style]="service_style">
                                        <b>Test Name</b>
                                      </th>
                                      <th [style]="date_style">
                                        <b>Bill date</b>
                                      </th>
                                      <th [style]="quant_style" *ngIf="!bill_qty_flag">
                                        <b>Quantity</b>
                                      </th>
                                      <th [style]="Amt_style">
                                        <b>Amount</b>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let billprinttable of digDetailsArray">
                                      <td [style]="service_td_style">
                                        {{billprinttable.name}}
                                      </td>
                                      <td [style]="date_td_style">
                                        {{billprinttable.bill_date}}
                                      </td>
                                      <td [style]="quant_td_style" *ngIf="!bill_qty_flag">
                                        {{billprinttable.fee}} x {{billprinttable.quantity}}
                                      </td>

                                      <td [style]="amt_td_style">
                                        {{billprinttable.amount}}
                                      </td>
                                    </tr>
                                    <tr *ngIf="!bill_qty_flag">
                                      <td colspan="2"></td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;"><b>Subtotal</b></div>
                                      </td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;">{{diagBillSubtotal}}</div>
                                      </td>
                                    </tr>
                                    <tr *ngIf="bill_qty_flag">
                                      <td colspan="1"></td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;"><b>Subtotal</b></div>
                                      </td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;">{{diagBillSubtotal}}</div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <!-- Total Calculation  -->
                            <div style="width: 100%;">
                              <table>
                                <tr>
                                  <td style="width: 71.5%;background-color: #ffffff;"></td>
                                  <th style="text-align: left;">Bill amount (र)</th>
                                  <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Bill amount"
                                    class="fee_txt">{{totalBillAmountvalue}}</td>
                                </tr>
                                <tr *ngIf="sponsorDueflag">
                                  <td style="width: 71.5%;background-color: #ffffff;"></td>
                                  <th style="text-align: left;">Sponsor Due (र)</th>
                                  <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Bill amount"
                                    class="fee_txt">{{sponsorDue}}</td>
                                </tr>
                                <tr>
                                  <td style="width: 71.5%;background-color: #ffffff"></td>
                                  <th style="text-align: left;">Advance (र)</th>
                                  <td style="text-align: right;width: 100px;margin-right: 10px;"
                                    data-th="Advance / Paid र" class="fee_txt">{{total_advance}}</td>
                                </tr>
                                <tr>
                                  <td style="width: 71.5%;background-color: #ffffff"></td>
                                  <th style="text-align: left;"> Paid (र)</th>
                                  <td style="text-align: right;width: 100px;margin-right: 10px;"
                                    data-th="Advance / Paid र" class="fee_txt">
                                    {{paid_bill}}</td>
                                </tr>
                                <tr>
                                  <td style="width: 71.5%;background-color: #ffffff;"></td>
                                  <th style="text-align: left;">Balance (र)</th>
                                  <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Balance"
                                    class="fee_txt">
                                    {{remaining_billbal}}</td>
                                </tr>
                                <tr *ngIf="discountflag">
                                  <td style="width: 71.5%;background-color: #ffffff;"></td>
                                  <th style="text-align: left;">Discount (र)</th>
                                  <td style="text-align: right;width: 100px;margin-right: 10px;" data-th="Balance"
                                    class="fee_txt">
                                    {{discountvaluecheck}}</td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div #printbanner id="printbanner">
            <div class="bill_border">
              <div *ngFor="let billprint of billing_print_array">
                <div>
                  <table style="width: 98%;margin-left: 15px !important;margin-right: 15px !important;">
                    <thead>
                      <th>
                        <div style="height: 275px">
                          <div style="height: 110px;margin-right: 15px;">
                            <img alt="image" src={{hospital_logo}} width="98%" height="110px"
                              style="vertical-align: middle;">
                          </div>
                          <div [style]="header_style">
                            <table style="width: 98%; border: 1px solid black;border-radius: 10px">
                              <tr>
                                <td style="width: 58%; vertical-align: top;padding: 0px;">
                                  <div>
                                    <table style="width: 100%;" class="head-table">
                                      <tbody>
                                        <tr class="head-tr">
                                          <td class="head-td" style="width: 90px; font-weight: 600 !important;padding: 0px;">
                                            MR no</td>
                                          <td class="head-td" style="width: 10px; font-weight: 600 !important;padding: 0px;">:&nbsp;</td>
                                          <td class="head-td" style="font-family: Times New Roman;padding: 0px;">{{inpatientidnew}}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <table style="width: 100%;" class="head-table">
                                      <tbody>
                                        <tr class="head-tr">
                                          <td class="head-td" style="width: 90px; font-weight: 600 !important;padding: 0px;">
                                            Patient name</td>
                                          <td class="head-td" style="width: 10px; font-weight: 600 !important;padding: 0px;">:&nbsp;</td>
                                          <td class="head-td" style="font-family: Times New Roman;padding: 0px;">{{clientnamenew}}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <table style="width: 100%;" class="head-table">
                                      <tbody>
                                        <tr class="head-tr">
                                          <td class="head-td" style="width: 90px; font-weight: 600 !important;padding: 0px;">
                                            Age / gender</td>
                                          <td class="head-td" style="width: 10px; font-weight: 600 !important;padding: 0px;">:&nbsp;</td>
                                          <td class="head-td" style="font-family: Times New Roman;padding: 0px;">{{age}} / {{gender}}</td>
                                        </tr>
                                      </tbody>
                                    </table>                                                                  
                                    <table style="width: 100%;" class="head-table">
                                      <tbody>
                                        <tr class="head-tr">
                                          <td class="head-td" style="width: 90px; font-weight: 600 !important;padding: 0px;">
                                            Visit id</td>
                                          <td class="head-td" style="width: 10px; font-weight: 600 !important;padding: 0px;">:&nbsp;</td>
                                          <td class="head-td" style="font-family: Times New Roman;padding: 0px;">{{billprint.inpatientId}}</td>
                                        </tr>
                                      </tbody>
                                    </table>                                                                  
                                    <table style="width: 100%;" class="head-table">
                                      <tbody>
                                        <tr class="head-tr">
                                          <td class="head-td" style="width: 90px; font-weight: 600 !important;padding: 0px;">
                                            Speciality</td>
                                          <td class="head-td" style="width: 10px; font-weight: 600 !important;padding: 0px;">:&nbsp;</td>
                                          <td class="head-td" style="font-family: Times New Roman;padding: 0px;">{{specialization_name}}</td>
                                        </tr>
                                      </tbody>
                                    </table>                                                                   
                                    <table style="width: 100%;" class="head-table">
                                      <tbody>
                                        <tr class="head-tr">
                                          <td class="head-td" style="width: 90px; font-weight: 600 !important;padding: 0px;vertical-align: top;">
                                            Address</td>
                                          <td class="head-td" style="width: 10px; font-weight: 600 !important;padding: 0px;vertical-align: top;">:&nbsp;</td>
                                          <td class="head-td" style="font-family: Times New Roman;padding: 0px;"><span *ngIf="billprint.address != ''">{{billprint.address}}{{billprint.location}}</span>
                                            <br />
                                            <span>{{billprint.city}}{{billprint.state}}</span>                                         
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>                               
                                  </div>
                                </td>
                                <td style="width: 42%;vertical-align: top;">                             
                                  <div>                               
                                    <table style="width: 100%;" class="head-table">
                                      <tbody>
                                        <tr class="head-tr">
                                          <td class="head-td" style="width: 90px; font-weight: 600 !important;padding: 0px;">
                                            Doctor</td>
                                          <td class="head-td" style="width: 10px; font-weight: 600 !important;padding: 0px;">:&nbsp;</td>
                                          <td class="head-td" style="font-family: Times New Roman;padding: 0px;">{{billprint.doctorname}} {{doc_qualif}}</td>
                                        </tr>
                                      </tbody>
                                    </table>                                                                 
                                    <table style="width: 100%;" class="head-table">
                                      <tbody>
                                        <tr class="head-tr">
                                          <td class="head-td" style="width: 90px; font-weight: 600 !important;padding: 0px;">
                                            DOA</td>
                                          <td class="head-td" style="width: 10px; font-weight: 600 !important;padding: 0px;">:&nbsp;</td>
                                          <td class="head-td" style="font-family: Times New Roman;padding: 0px;">{{admissionDate}} {{admitted_timevalue}}</td>
                                        </tr>
                                      </tbody>
                                    </table>                                                                 
                                    <table style="width: 100%;" class="head-table" *ngIf="dischargeDate != undefined && dischargeDate != ''">
                                      <tbody>
                                        <tr class="head-tr">
                                          <td class="head-td" style="width: 90px; font-weight: 600 !important;padding: 0px;">
                                            DOD</td>
                                          <td class="head-td" style="width: 10px; font-weight: 600 !important;padding: 0px;">:&nbsp;</td>
                                          <td class="head-td" style="font-family: Times New Roman;padding: 0px;">{{dischargeDate}} {{dischargeTime}}</td>
                                        </tr>
                                      </tbody>
                                    </table>                                                                 
                                    <table style="width: 100%;" class="head-table" *ngIf="rate_plan != undefined && rate_plan != ''">
                                      <tbody>
                                        <tr class="head-tr">
                                          <td class="head-td" style="width: 90px; font-weight: 600 !important;padding: 0px;">
                                            Rate plan</td>
                                          <td class="head-td" style="width: 10px; font-weight: 600 !important;padding: 0px;">:&nbsp;</td>
                                          <td class="head-td" style="font-family: Times New Roman;padding: 0px;">{{rate_plan}}</td>
                                        </tr>
                                      </tbody>
                                    </table>                                                                   
                                    <table style="width: 100%;" class="head-table">
                                      <tbody>
                                        <tr class="head-tr">
                                          <td class="head-td" style="width: 90px; font-weight: 600 !important;padding: 0px;">
                                            Ward & bed no</td>
                                          <td class="head-td" style="width: 10px; font-weight: 600 !important;padding: 0px;">:&nbsp;</td>
                                          <td class="head-td" style="font-family: Times New Roman;padding: 0px;">{{ward_name}} / {{bedno}}</td>
                                        </tr>
                                      </tbody>
                                    </table>                                                                  
                                    <table style="width: 100%;" class="head-table" *ngIf="surgery_name != undefined && surgery_name != ''">
                                      <tbody>
                                        <tr class="head-tr">
                                          <td class="head-td" style="width: 90px; font-weight: 600 !important;padding: 0px;">
                                            Surgery name</td>
                                          <td class="head-td" style="width: 10px; font-weight: 600 !important;padding: 0px;">:&nbsp;</td>
                                          <td class="head-td" style="font-family: Times New Roman;padding: 0px;">{{surgery_name}}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <img *ngIf="billprint.barcode_flag" style="width: 130px; height: 35px; margin-top: 5px"
                                    src={{billprint.barcode_url}} />
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </th>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div style="margin-top: 0px;">
                            <h3 class="heading" style="text-align: center;margin: 0px;">Consolidated Bill </h3>
                            <div style="width: 100%;margin-top: -10px;" *ngIf="insuranceFlag">
                              <h3 style="margin:10px 0 ;" class="heading">Insurance details</h3>
                              <table style="width: 98%;border: 1px solid black;border-radius: 10px;">
                                <tr>
                                  <td style="width: 60%; vertical-align: top;">
                                    <div>
                                      <table style="width: 100%;" class="head-table">
                                        <tbody>
                                          <tr class="head-tr">
                                            <td class="head-td" style="width: 100px; font-weight: 600 !important;padding: 0px;">
                                              Insurance corp</td>
                                            <td class="head-td" style="width: 10px; font-weight: 600 !important;padding: 0px;">:&nbsp;</td>
                                            <td class="head-td" style="font-family: Times New Roman;padding: 0px;">{{insurer}}</td>
                                          </tr>
                                        </tbody>
                                      </table>                                                                           
                                      <table style="width: 100%;" class="head-table">
                                        <tbody>
                                          <tr class="head-tr">
                                            <td class="head-td" style="width: 100px; font-weight: 600 !important;padding: 0px;">
                                              Insurance card no</td>
                                            <td class="head-td" style="width: 10px; font-weight: 600 !important;padding: 0px;">:&nbsp;</td>
                                            <td class="head-td" style="font-family: Times New Roman;padding: 0px;">{{ins_card_no}}</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table style="width: 100%;" class="head-table">
                                        <tbody>
                                          <tr class="head-tr">
                                            <td class="head-td" style="width: 100px; font-weight: 600 !important;padding: 0px;">
                                              CNN number</td>
                                            <td class="head-td" style="width: 10px; font-weight: 600 !important;padding: 0px;">:&nbsp;</td>
                                            <td class="head-td" style="font-family: Times New Roman;padding: 0px;">{{cnnno}}</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </td>
                                </tr>
                              </table>
                            </div>
                            <div *ngIf="sendDoctor.length != 0" style="width: 98%; margin-top: 15px !important;">
                              <h3 class="heading">Service Charges</h3>
                              <div class="row">
                                <table style="border: 1px solid black;border-collapse: collapse;margin-right: 20px;width:100%;">
                                  <thead>
                                    <tr>
                                      <th [style]="service_style">
                                        <b>Service</b>
                                      </th>
                                      <th [style]="date_style">
                                        <b>Bill date</b>
                                      </th>
                                      <th [style]="quant_style" *ngIf="!bill_qty_flag">
                                        <b>Quantity</b>
                                      </th>
                                      <th [style]="Amt_style">
                                        <b>Amount</b>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let billslist of docBillDetails">
                                      <td colspan="8" *ngIf="billslist.category_flag"> 
                                        <div>
                                          <div>
                                            <p style="font-weight: 500;font-family: Arial;color: black;font-size: 13px !important;margin: 5px 0 0 0 !important;
                                                      text-align: center !important;"><b
                                              style="text-transform: capitalize;">{{billslist.category}}</b></p>
                                            <table style="border: 1px solid black;border-collapse: collapse;width: 100%;border-style: solid hidden solid hidden;">
                                              <tbody>
                                                <tr *ngFor='let billprinttable of billslist.charges'>
                                                  <td [style]="service_td_style" data-th="Type">{{billprinttable.name}}
                                                  </td>
                                                  <td [style]="date_td_style" data-th="Type">{{billprinttable.bill_date}}
                                                  </td>
                                                  <td [style]="quant_td_style" *ngIf="!bill_qty_flag" data-th="Fee">
                                                    <div *ngIf="!bill_qty_flag">{{billprinttable.amount}} x {{billprinttable.quantity}}
                                                    </div>
                                                  </td>
                                                  <td [style]="amt_td_style" data-th="Amount" class="fee_txt">{{billprinttable.fee}}
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr *ngIf="!bill_qty_flag">
                                      <td colspan="2"></td>
                                      <td [style]="doctor_subtotal_style">
                                        <div style="width: 100%; text-align: end;"><b>Subtotal</b></div>
                                      </td>
                                      <td [style]="doctor_subtotal_style">
                                        <div style="width: 100%; text-align: end;">{{docBillSubtotal}}</div>
                                      </td>
                                    </tr>
                                    <tr *ngIf="bill_qty_flag">
                                      <td colspan="1"></td>
                                      <td [style]="doctor_subtotal_style">
                                        <div style="width: 100%; text-align: end;"><b>Subtotal</b></div>
                                      </td>
                                      <td [style]="doctor_subtotal_style">
                                        <div style="width: 100%; text-align: end;">{{docBillSubtotal}}</div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>

                            <!-- Radiology Bills Particulars -->
                            <div style="width: 98%;" *ngIf="digDetailsArray.length !=0">
                              <h3 class="heading">Pathology & Radiology</h3>
                              <div class="row">
                                <table style="border-collapse: collapse;margin-right: 20px;width:100%;">
                                  <thead>
                                    <tr>
                                      <th [style]="service_style">
                                        <b>Test Name</b>
                                      </th>
                                      <th [style]="date_style">
                                        <b>Bill date</b>
                                      </th>
                                      <th [style]="quant_style" *ngIf="!bill_qty_flag">
                                        <b>Quantity</b>
                                      </th>
                                      <th [style]="Amt_style">
                                        <b>Amount</b>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let billprinttable of digDetailsArray">
                                      <td [style]="service_td_style">
                                        {{billprinttable.name}}
                                      </td>
                                      <td [style]="date_td_style">
                                        {{billprinttable.bill_date}}
                                      </td>
                                      <td [style]="quant_td_style" *ngIf="!bill_qty_flag">
                                        {{billprinttable.fee}} x {{billprinttable.quantity}}
                                      </td>

                                      <td [style]="amt_td_style">
                                        {{billprinttable.amount}}
                                      </td>
                                    </tr>
                                    <tr *ngIf="!bill_qty_flag">
                                      <td colspan="2"></td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;"><b>Subtotal</b></div>
                                      </td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;">{{diagBillSubtotal}}</div>
                                      </td>
                                    </tr>
                                    <tr *ngIf="bill_qty_flag">
                                      <td colspan="1"></td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;"><b>Subtotal</b></div>
                                      </td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;">{{diagBillSubtotal}}</div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>

                            <!-- Madical Bills Particulars -->
                            <div *ngIf="medcalDetailsArray.length != 0" style="width: 98%;">
                              <h3 class="heading">Medicine bills </h3>
                              <div class="row" *ngIf="insuranceFlag">
                                <table style="width: 100%; border-collapse: collapse;margin-right: 20px;">
                                  <thead>
                                    <tr>
                                      <th [style]="service_style">
                                        <b>Medicine</b>
                                      </th>
                                      <th [style]="date_style">Bill
                                        date</th>
                                      <th [style]="quant_style">
                                        Quantity</th>
                                      <th [style]="Amt_style">
                                        <b>Bill Amount</b>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let billprinttable of medcalDetailsArray">
                                      <td [style]="service_td_style" data-th="Type">{{billprinttable.name}}</td>
                                      <td [style]="date_td_style" data-th="Type">{{billprinttable.bill_date}}</td>
                                      <td [style]="quant_td_style" data-th="Type">{{billprinttable.quantity}}</td>
                                      <td [style]="amt_td_style" data-th="Fee">{{billprinttable.amount}}</td>
                                    </tr>
                                    <tr>
                                      <td colspan="2"></td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;"><b>Subtotal</b></div>
                                      </td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;">{{medBillSubtotal}}</div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div class="row" *ngIf="!insuranceFlag">
                                <table style=" width: 100%; border-collapse: collapse;margin-right: 20px;">
                                  <thead>
                                    <tr>
                                      <th [style]="not_ins_serviceStyle"><b>Bill
                                          No</b></th>
                                      <th [style]="not_ins_dateStyle"><b>Bill
                                          date</b></th>
                                      <th [style]="not_ins_amtStyle">
                                        <b>Amount</b>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let billprinttable of medcalDetailsArray">
                                      <td [style]="service_td_style">
                                        {{billprinttable.name}}
                                      </td>
                                      <td [style]="date_td_style">
                                        {{billprinttable.bill_date}}
                                      </td>
                                      <td [style]="not_ins_td_amtStyle">
                                        {{billprinttable.amount}}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;"><b>Subtotal</b></div>
                                      </td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;">{{medBillSubtotal}}</div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>

                            <!-- Total Calculation  -->
                            <div style="width: 98%;">
                              <table style="margin-bottom: -20px;width: 100%;">
                                <tr>
                                  <td style="vertical-align: top; width: 26%;">
                                    <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;" *ngIf="paymentsarray.length !=0">
                                      <tr *ngFor="let pay of paymentsarray">
                                        <td style="width: 90px !important;vertical-align: top;">{{pay.pay_desc}}</td>
                                        <td style="width: 100px !important;vertical-align: top;text-align: right;">{{pay.amount}}</td>
                                      </tr>
                                    </table>                                                       
                                  </td>
                                  <td style="vertical-align: top;width: 31%;">
                                    <table  style="float: right;margin-left: 120px;margin-right: 0px;" *ngIf="paymentsarray.length != 0">
                                      <tr>
                                        <th style="text-align: left;">Bill amount (र)</th>
                                        <td style="text-align: right;width: 100px;" data-th="Bill amount"
                                          class="fee_txt">{{totalBillAmountvalue}}</td>
                                      </tr>
                                      <tr *ngIf="sponsorDueflag">
                                        <th style="text-align: left;">Sponsor Due (र)</th>
                                        <td style="text-align: right;width: 100px;" data-th="Bill amount" class="fee_txt">
                                          {{sponsorDue}}</td>
                                      </tr>
                                      <tr *ngIf="advanceflag">
                                        <th style="text-align: left;">Advance (र)</th>
                                        <td style="text-align: right;width: 100px;"
                                          data-th="Advance / Paid र" class="fee_txt">{{total_advance}}</td>
                                      </tr>
                                      <tr *ngIf="discountflag">
                                        <th style="text-align: left;">Discount (र)</th>
                                        <td style="text-align: right;width: 100px;" data-th="Balance"
                                          class="fee_txt">
                                          {{discountvaluecheck}}</td>
                                      </tr>
                                      <tr *ngIf="paidflag">
                                        <th style="text-align: left;"> Paid (र)</th>
                                        <td style="text-align: right;width: 100px;"
                                          data-th="Advance / Paid र" class="fee_txt">
                                          {{paid_bill}}</td>
                                      </tr>
                                      <tr *ngIf="balanceflag && !adv_refflag">
                                        <th style="text-align: left;">Balance (र)</th>
                                        <td style="text-align: right;width: 100px;" data-th="Balance"
                                          class="fee_txt">
                                          {{remaining_billbal}}</td>
                                      </tr>
                                      <tr *ngIf="adv_refflag">                                    
                                        <th style="text-align: left;">Refund (र)</th>
                                        <td style="text-align: right;width: 100px;" data-th="Balance"
                                          class="fee_txt">
                                          {{remaining_billbal}}</td>
                                      </tr>
                                    </table> 
                                    <table  style="float: right;margin-left: 120px;margin-right: 0px;" *ngIf="paymentsarray.length == 0">
                                      <tr>
                                        <th style="text-align: left;">Bill amount (र)</th>
                                        <td style="text-align: right;width: 100px;" data-th="Bill amount"
                                          class="fee_txt">{{totalBillAmountvalue}}</td>
                                      </tr>
                                      <tr *ngIf="sponsorDueflag">
                                        <th style="text-align: left;">Sponsor Due (र)</th>
                                        <td style="text-align: right;width: 100px;" data-th="Bill amount" class="fee_txt">
                                          {{sponsorDue}}</td>
                                      </tr>
                                      <tr *ngIf="advanceflag">
                                        <th style="text-align: left;">Advance (र)</th>
                                        <td style="text-align: right;width: 100px;"
                                          data-th="Advance / Paid र" class="fee_txt">{{total_advance}}</td>
                                      </tr>
                                      <tr *ngIf="discountflag">
                                        <th style="text-align: left;">Discount (र)</th>
                                        <td style="text-align: right;width: 100px;" data-th="Balance"
                                          class="fee_txt">
                                          {{discountvaluecheck}}</td>
                                      </tr>
                                      <tr *ngIf="paidflag">
                                        <th style="text-align: left;"> Paid (र)</th>
                                        <td style="text-align: right;width: 100px;"
                                          data-th="Advance / Paid र" class="fee_txt">
                                          {{paid_bill}}</td>
                                      </tr>
                                      <tr *ngIf="balanceflag && !adv_refflag">
                                        <th style="text-align: left;">Balance (र)</th>
                                        <td style="text-align: right;width: 100px;" data-th="Balance"
                                          class="fee_txt">
                                          {{remaining_billbal}}</td>
                                      </tr>
                                      <tr *ngIf="adv_refflag">                                    
                                        <th style="text-align: left;">Refund (र)</th>
                                        <td style="text-align: right;width: 100px;" data-th="Balance"
                                          class="fee_txt">
                                          {{remaining_billbal}}</td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td width="100%">
                          <table style="border:'0';width:100%">
                            <tr>
                              <td style="width:100%;height: 50px;">&nbsp;</td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div #printnoheader id="printnoheader">
            <div class="bill_border">
              <div *ngFor="let billprint of billing_print_array">
                <div>
                  <table style="width: 98%;margin-left: 15px !important;margin-right: 15px !important;">
                    <thead>
                      <th>
                        <div style="height: 110px">&nbsp;</div>
                        <div [style]="header_style">
                          <table style="width: 98%; border: 1px solid black;border-radius: 10px">
                            <tr>
                              <td style="width: 58%; vertical-align: top;padding: 0px;">
                                <div>
                                  <table style="width: 100%;" class="head-table">
                                    <tbody>
                                      <tr class="head-tr">
                                        <td class="head-td" style="width: 90px; font-weight: 600 !important;padding: 0px;">
                                          MR no</td>
                                        <td class="head-td" style="width: 10px; font-weight: 600 !important;padding: 0px;">:&nbsp;</td>
                                        <td class="head-td" style="font-family: Times New Roman;padding: 0px;">{{inpatientidnew}}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <table style="width: 100%;" class="head-table">
                                    <tbody>
                                      <tr class="head-tr">
                                        <td class="head-td" style="width: 90px; font-weight: 600 !important;padding: 0px;">
                                          Patient name</td>
                                        <td class="head-td" style="width: 10px; font-weight: 600 !important;padding: 0px;">:&nbsp;</td>
                                        <td class="head-td" style="font-family: Times New Roman;padding: 0px;">{{clientnamenew}}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <table style="width: 100%;" class="head-table">
                                    <tbody>
                                      <tr class="head-tr">
                                        <td class="head-td" style="width: 90px; font-weight: 600 !important;padding: 0px;">
                                          Age / gender</td>
                                        <td class="head-td" style="width: 10px; font-weight: 600 !important;padding: 0px;">:&nbsp;</td>
                                        <td class="head-td" style="font-family: Times New Roman;padding: 0px;">{{age}} / {{gender}}</td>
                                      </tr>
                                    </tbody>
                                  </table>                                                                  
                                  <table style="width: 100%;" class="head-table">
                                    <tbody>
                                      <tr class="head-tr">
                                        <td class="head-td" style="width: 90px; font-weight: 600 !important;padding: 0px;">
                                          Visit id</td>
                                        <td class="head-td" style="width: 10px; font-weight: 600 !important;padding: 0px;">:&nbsp;</td>
                                        <td class="head-td" style="font-family: Times New Roman;padding: 0px;">{{billprint.inpatientId}}</td>
                                      </tr>
                                    </tbody>
                                  </table>                                                                  
                                  <table style="width: 100%;" class="head-table">
                                    <tbody>
                                      <tr class="head-tr">
                                        <td class="head-td" style="width: 90px; font-weight: 600 !important;padding: 0px;">
                                          Speciality</td>
                                        <td class="head-td" style="width: 10px; font-weight: 600 !important;padding: 0px;">:&nbsp;</td>
                                        <td class="head-td" style="font-family: Times New Roman;padding: 0px;">{{specialization_name}}</td>
                                      </tr>
                                    </tbody>
                                  </table>                                                                   
                                  <table style="width: 100%;" class="head-table">
                                    <tbody>
                                      <tr class="head-tr">
                                        <td class="head-td" style="width: 90px; font-weight: 600 !important;padding: 0px;vertical-align: top;">
                                          Address</td>
                                        <td class="head-td" style="width: 10px; font-weight: 600 !important;padding: 0px;vertical-align: top;">:&nbsp;</td>
                                        <td class="head-td" style="font-family: Times New Roman;padding: 0px;"><span *ngIf="billprint.address != ''">{{billprint.address}}{{billprint.location}}</span>
                                          <br />
                                          <span>{{billprint.city}}{{billprint.state}}</span>                                         
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>                               
                                </div>
                              </td>
                              <td style="width: 42%;vertical-align: top;">                             
                                <div>                               
                                  <table style="width: 100%;" class="head-table">
                                    <tbody>
                                      <tr class="head-tr">
                                        <td class="head-td" style="width: 90px; font-weight: 600 !important;padding: 0px;">
                                          Doctor</td>
                                        <td class="head-td" style="width: 10px; font-weight: 600 !important;padding: 0px;">:&nbsp;</td>
                                        <td class="head-td" style="font-family: Times New Roman;padding: 0px;">{{billprint.doctorname}} {{doc_qualif}}</td>
                                      </tr>
                                    </tbody>
                                  </table>                                                                 
                                  <table style="width: 100%;" class="head-table">
                                    <tbody>
                                      <tr class="head-tr">
                                        <td class="head-td" style="width: 90px; font-weight: 600 !important;padding: 0px;">
                                          DOA</td>
                                        <td class="head-td" style="width: 10px; font-weight: 600 !important;padding: 0px;">:&nbsp;</td>
                                        <td class="head-td" style="font-family: Times New Roman;padding: 0px;">{{admissionDate}} {{admitted_timevalue}}</td>
                                      </tr>
                                    </tbody>
                                  </table>                                                                 
                                  <table style="width: 100%;" class="head-table" *ngIf="dischargeDate != undefined && dischargeDate != ''">
                                    <tbody>
                                      <tr class="head-tr">
                                        <td class="head-td" style="width: 90px; font-weight: 600 !important;padding: 0px;">
                                          DOD</td>
                                        <td class="head-td" style="width: 10px; font-weight: 600 !important;padding: 0px;">:&nbsp;</td>
                                        <td class="head-td" style="font-family: Times New Roman;padding: 0px;">{{dischargeDate}} {{dischargeTime}}</td>
                                      </tr>
                                    </tbody>
                                  </table>                                                                 
                                  <table style="width: 100%;" class="head-table" *ngIf="rate_plan != undefined && rate_plan != ''">
                                    <tbody>
                                      <tr class="head-tr">
                                        <td class="head-td" style="width: 90px; font-weight: 600 !important;padding: 0px;">
                                          Rate plan</td>
                                        <td class="head-td" style="width: 10px; font-weight: 600 !important;padding: 0px;">:&nbsp;</td>
                                        <td class="head-td" style="font-family: Times New Roman;padding: 0px;">{{rate_plan}}</td>
                                      </tr>
                                    </tbody>
                                  </table>                                                                   
                                  <table style="width: 100%;" class="head-table">
                                    <tbody>
                                      <tr class="head-tr">
                                        <td class="head-td" style="width: 90px; font-weight: 600 !important;padding: 0px;">
                                          Ward & bed no</td>
                                        <td class="head-td" style="width: 10px; font-weight: 600 !important;padding: 0px;">:&nbsp;</td>
                                        <td class="head-td" style="font-family: Times New Roman;padding: 0px;">{{ward_name}} / {{bedno}}</td>
                                      </tr>
                                    </tbody>
                                  </table>                                                                  
                                  <table style="width: 100%;" class="head-table" *ngIf="surgery_name != undefined && surgery_name != ''">
                                    <tbody>
                                      <tr class="head-tr">
                                        <td class="head-td" style="width: 90px; font-weight: 600 !important;padding: 0px;">
                                          Surgery name</td>
                                        <td class="head-td" style="width: 10px; font-weight: 600 !important;padding: 0px;">:&nbsp;</td>
                                        <td class="head-td" style="font-family: Times New Roman;padding: 0px;">{{surgery_name}}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <img *ngIf="billprint.barcode_flag" style="width: 130px; height: 35px; margin-top: 5px"
                                  src={{billprint.barcode_url}} />
                                </div>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </th>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div style="margin-top: 0px;">
                            <h3 class="heading" style="text-align: center;margin: 0px;">Consolidated Bill </h3>
                            <div style="width: 100%;margin-top: -10px;" *ngIf="insuranceFlag">
                              <h3 style="margin:10px 0 ;" class="heading">Insurance details</h3>
                              <table style="width: 98%;border: 1px solid black;border-radius: 10px;">
                                <tr>
                                  <td style="width: 60%; vertical-align: top;">
                                    <div>
                                      <table style="width: 100%;" class="head-table">
                                        <tbody>
                                          <tr class="head-tr">
                                            <td class="head-td" style="width: 100px; font-weight: 600 !important;padding: 0px;">
                                              Insurance corp</td>
                                            <td class="head-td" style="width: 10px; font-weight: 600 !important;padding: 0px;">:&nbsp;</td>
                                            <td class="head-td" style="font-family: Times New Roman;padding: 0px;">{{insurer}}</td>
                                          </tr>
                                        </tbody>
                                      </table>                                                                           
                                      <table style="width: 100%;" class="head-table">
                                        <tbody>
                                          <tr class="head-tr">
                                            <td class="head-td" style="width: 100px; font-weight: 600 !important;padding: 0px;">
                                              Insurance card no</td>
                                            <td class="head-td" style="width: 10px; font-weight: 600 !important;padding: 0px;">:&nbsp;</td>
                                            <td class="head-td" style="font-family: Times New Roman;padding: 0px;">{{ins_card_no}}</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table style="width: 100%;" class="head-table">
                                        <tbody>
                                          <tr class="head-tr">
                                            <td class="head-td" style="width: 100px; font-weight: 600 !important;padding: 0px;">
                                              CNN number</td>
                                            <td class="head-td" style="width: 10px; font-weight: 600 !important;padding: 0px;">:&nbsp;</td>
                                            <td class="head-td" style="font-family: Times New Roman;padding: 0px;">{{cnnno}}</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </td>
                                </tr>
                              </table>
                            </div>
                            <div *ngIf="sendDoctor.length != 0" style="width: 98%;">
                              <h3 class="heading">Service Charges</h3>
                              <div class="row">
                                <table style="border: 1px solid black;border-collapse: collapse;margin-right: 20px;width:100%;">
                                  <thead>
                                    <tr>
                                      <th [style]="service_style">
                                        <b>Service</b>
                                      </th>
                                      <th [style]="date_style">
                                        <b>Bill date</b>
                                      </th>
                                      <th [style]="quant_style" *ngIf="!bill_qty_flag">
                                        <b>Quantity</b>
                                      </th>
                                      <th [style]="Amt_style">
                                        <b>Amount</b>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let billslist of docBillDetails">
                                      <td colspan="8" *ngIf="billslist.category_flag"> 
                                        <div>
                                          <div>
                                            <p style="font-weight: 500;font-family: Arial;color: black;font-size: 13px !important;margin: 5px 0 0 0 !important;
                                                      text-align: center !important;"><b
                                              style="text-transform: capitalize;">{{billslist.category}}</b></p>
                                            <table style="border: 1px solid black;border-collapse: collapse;width: 100%;border-style: solid hidden solid hidden;">
                                              <tbody>
                                                <tr *ngFor='let billprinttable of billslist.charges'>
                                                  <td [style]="service_td_style" data-th="Type">{{billprinttable.name}}
                                                  </td>
                                                  <td [style]="date_td_style" data-th="Type">{{billprinttable.bill_date}}
                                                  </td>
                                                  <td [style]="quant_td_style" *ngIf="!bill_qty_flag" data-th="Fee">
                                                    <div *ngIf="!bill_qty_flag">{{billprinttable.amount}} x {{billprinttable.quantity}}
                                                    </div>
                                                  </td>
                                                  <td [style]="amt_td_style" data-th="Amount" class="fee_txt">{{billprinttable.fee}}
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr *ngIf="!bill_qty_flag">
                                      <td colspan="2"></td>
                                      <td [style]="doctor_subtotal_style">
                                        <div style="width: 100%; text-align: end;"><b>Subtotal</b></div>
                                      </td>
                                      <td [style]="doctor_subtotal_style">
                                        <div style="width: 100%; text-align: end;">{{docBillSubtotal}}</div>
                                      </td>
                                    </tr>
                                    <tr *ngIf="bill_qty_flag">
                                      <td colspan="1"></td>
                                      <td [style]="doctor_subtotal_style">
                                        <div style="width: 100%; text-align: end;"><b>Subtotal</b></div>
                                      </td>
                                      <td [style]="doctor_subtotal_style">
                                        <div style="width: 100%; text-align: end;">{{docBillSubtotal}}</div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>

                            <!-- Radiology Bills Particulars -->
                            <div style="width: 98%;" *ngIf="digDetailsArray.length !=0">
                              <h3 class="heading">Pathology & Radiology</h3>
                              <div class="row">
                                <table style="border-collapse: collapse;margin-right: 20px;width:100%;">
                                  <thead>
                                    <tr>
                                      <th [style]="service_style">
                                        <b>Test Name</b>
                                      </th>
                                      <th [style]="date_style">
                                        <b>Bill date</b>
                                      </th>
                                      <th [style]="quant_style" *ngIf="!bill_qty_flag">
                                        <b>Quantity</b>
                                      </th>                                  
                                      <th [style]="Amt_style">
                                        <b>Amount</b>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let billprinttable of digDetailsArray">
                                      <td [style]="service_td_style">
                                        {{billprinttable.name}}
                                      </td>
                                      <td [style]="date_td_style">
                                        {{billprinttable.bill_date}}
                                      </td>
                                      <td [style]="quant_td_style" *ngIf="!bill_qty_flag">
                                        {{billprinttable.fee}} x {{billprinttable.quantity}}
                                      </td>

                                      <td [style]="amt_td_style">
                                        {{billprinttable.amount}}
                                      </td>
                                    </tr>
                                    <tr *ngIf="!bill_qty_flag">
                                      <td colspan="2"></td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;"><b>Subtotal</b></div>
                                      </td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;">{{diagBillSubtotal}}</div>
                                      </td>
                                    </tr>
                                    <tr *ngIf="bill_qty_flag">
                                      <td colspan="1"></td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;"><b>Subtotal</b></div>
                                      </td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;">{{diagBillSubtotal}}</div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>

                            <!-- Madical Bills Particulars -->
                            <div *ngIf="medcalDetailsArray.length != 0" style="width: 98%;">
                              <h3 class="heading">Medicine bills </h3>
                              <div class="row" *ngIf="insuranceFlag">
                                <table style="width: 100%; border-collapse: collapse;margin-right: 20px;">
                                  <thead>
                                    <tr>
                                      <th [style]="service_style">
                                        <b>Medicine</b>
                                      </th>
                                      <th [style]="date_style">Bill
                                        date</th>
                                      <th [style]="quant_style" *ngIf="!bill_qty_flag">
                                        Quantity</th>
                                      <th [style]="Amt_style">
                                        <b>Bill Amount</b>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let billprinttable of medcalDetailsArray">
                                      <td [style]="service_td_style" data-th="Type">{{billprinttable.name}}</td>
                                      <td [style]="date_td_style" data-th="Type">{{billprinttable.bill_date}}</td>
                                      <td [style]="quant_td_style" data-th="Type">{{billprinttable.quantity}}</td>
                                      <td [style]="amt_td_style" data-th="Fee">{{billprinttable.amount}}</td>
                                    </tr>
                                    <tr>
                                      <td colspan="2"></td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;"><b>Subtotal</b></div>
                                      </td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;">{{medBillSubtotal}}</div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div class="row" *ngIf="!insuranceFlag">
                                <table style=" width: 100%; border-collapse: collapse;margin-right: 20px;border-radius: 10px">
                                  <thead>
                                    <tr>
                                      <th [style]="not_ins_serviceStyle"><b>Bill No</b></th>
                                      <th [style]="not_ins_dateStyle"><b>Bill date</b></th>
                                      <th [style]="not_ins_amtStyle"><b>Amount</b></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let billprinttable of medcalDetailsArray">
                                      <td [style]="service_td_style">
                                        {{billprinttable.name}}
                                      </td>
                                      <td [style]="date_td_style">
                                        {{billprinttable.bill_date}}
                                      </td>
                                      <td [style]="not_ins_td_amtStyle">
                                        {{billprinttable.amount}}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;"><b>Subtotal</b></div>
                                      </td>
                                      <td [style]="subtotal_style">
                                        <div style="width: 100%; text-align: end;">{{medBillSubtotal}}</div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>

                            <!-- Total Calculation  -->
                            <div style="width: 98%;">
                              <table style="margin-bottom: -20px;width: 100%;">
                                <tr>
                                  <td style="vertical-align: top; width: 26%;">
                                    <table class="col-3 col-md-5 col-lg-3 col-xl-3" style="float: left;" *ngIf="paymentsarray.length !=0">
                                      <tr *ngFor="let pay of paymentsarray">
                                        <td style="width: 90px !important;vertical-align: top;">{{pay.pay_desc}}</td>
                                        <td style="width: 100px !important;vertical-align: top;text-align: right;">{{pay.amount}}</td>
                                      </tr>
                                    </table>                                                       
                                  </td>
                                  <td style="vertical-align: top;width: 31%;">
                                    <table  style="float: right;margin-left: 120px;margin-right: 0px;" *ngIf="paymentsarray.length != 0">
                                      <tr>
                                        <th style="text-align: left;">Bill amount (र)</th>
                                        <td style="text-align: right;width: 100px;" data-th="Bill amount"
                                          class="fee_txt">{{totalBillAmountvalue}}</td>
                                      </tr>
                                      <tr *ngIf="sponsorDueflag">
                                        <th style="text-align: left;">Sponsor Due (र)</th>
                                        <td style="text-align: right;width: 100px;" data-th="Bill amount" class="fee_txt">
                                          {{sponsorDue}}</td>
                                      </tr>
                                      <tr *ngIf="advanceflag">
                                        <th style="text-align: left;">Advance (र)</th>
                                        <td style="text-align: right;width: 100px;"
                                          data-th="Advance / Paid र" class="fee_txt">{{total_advance}}</td>
                                      </tr>
                                      <tr *ngIf="discountflag">
                                        <th style="text-align: left;">Discount (र)</th>
                                        <td style="text-align: right;width: 100px;" data-th="Balance"
                                          class="fee_txt">
                                          {{discountvaluecheck}}</td>
                                      </tr>
                                      <tr *ngIf="paidflag">
                                        <th style="text-align: left;"> Paid (र)</th>
                                        <td style="text-align: right;width: 100px;"
                                          data-th="Advance / Paid र" class="fee_txt">
                                          {{paid_bill}}</td>
                                      </tr>
                                      <tr *ngIf="balanceflag && !adv_refflag">
                                        <th style="text-align: left;">Balance (र)</th>
                                        <td style="text-align: right;width: 100px;" data-th="Balance"
                                          class="fee_txt">
                                          {{remaining_billbal}}</td>
                                      </tr>
                                      <tr *ngIf="adv_refflag">                                    
                                        <th style="text-align: left;">Refund (र)</th>
                                        <td style="text-align: right;width: 100px;" data-th="Balance"
                                          class="fee_txt">
                                          {{remaining_billbal}}</td>
                                      </tr>
                                    </table> 
                                    <table  style="float: right;margin-left: 120px;margin-right: 0px;" *ngIf="paymentsarray.length == 0">
                                      <tr>
                                        <th style="text-align: left;">Bill amount (र)</th>
                                        <td style="text-align: right;width: 100px;" data-th="Bill amount"
                                          class="fee_txt">{{totalBillAmountvalue}}</td>
                                      </tr>
                                      <tr *ngIf="sponsorDueflag">
                                        <th style="text-align: left;">Sponsor Due (र)</th>
                                        <td style="text-align: right;width: 100px;" data-th="Bill amount" class="fee_txt">
                                          {{sponsorDue}}</td>
                                      </tr>
                                      <tr *ngIf="advanceflag">
                                        <th style="text-align: left;">Advance (र)</th>
                                        <td style="text-align: right;width: 100px;"
                                          data-th="Advance / Paid र" class="fee_txt">{{total_advance}}</td>
                                      </tr>
                                      <tr *ngIf="discountflag">
                                        <th style="text-align: left;">Discount (र)</th>
                                        <td style="text-align: right;width: 100px;" data-th="Balance"
                                          class="fee_txt">
                                          {{discountvaluecheck}}</td>
                                      </tr>
                                      <tr *ngIf="paidflag">
                                        <th style="text-align: left;"> Paid (र)</th>
                                        <td style="text-align: right;width: 100px;"
                                          data-th="Advance / Paid र" class="fee_txt">
                                          {{paid_bill}}</td>
                                      </tr>
                                      <tr *ngIf="balanceflag && !adv_refflag">
                                        <th style="text-align: left;">Balance (र)</th>
                                        <td style="text-align: right;width: 100px;" data-th="Balance"
                                          class="fee_txt">
                                          {{remaining_billbal}}</td>
                                      </tr>
                                      <tr *ngIf="adv_refflag">                                    
                                        <th style="text-align: left;">Refund (र)</th>
                                        <td style="text-align: right;width: 100px;" data-th="Balance"
                                          class="fee_txt">
                                          {{remaining_billbal}}</td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td width="100%">
                          <table style="border:'0';width:100%">
                            <tr>
                              <td style="width:100%;height: 50px;">&nbsp;</td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>