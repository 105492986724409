<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Inpatient report</h5>
                    </div>
                    <div style="width: 145px; margin-left: 15px;">
                        <mat-label class="matlabel">Preferrence</mat-label>
                        <mat-select class="ipcss" multiple [(ngModel)]="selected_filter"
                            (selectionChange)="setTable(selected_filter);">
                            <mat-option value="mrNo">MR.No</mat-option>
                            <mat-option value="wardBed">Ward/Bed</mat-option>
                            <mat-option value="admDateTime">Admission date/time </mat-option>
                            <mat-option value="ageGender">Age/Gender</mat-option>
                            <mat-option value="mobileNo">Mobile no</mat-option>
                            <mat-option value="docName">Doctor name</mat-option>
                            <mat-option value="billAmt">Bill amount</mat-option>
                            <mat-option value="billstatus">Bill status</mat-option>                          
                        </mat-select>
                    </div>
                    <div class="headerButtons">
                        <mat-label class="matlabel"><br>
                            <!-- <a style="cursor: pointer;">
                                <img src="../../../assets/ui_icons/buttons/back_icon.svg" style="width:30px;margin-right: 5px;" width="85px" (click)="todisplaycoverdiv()"  *ngIf="backbutton">
                            </a> -->
                            <a style="margin:0 5px;" (click)="print_area()"><img src="../../../assets/img/printer.svg"
                                class="backimgbtn_inpatinfo" style="width: 25px; height: 25px;"
                                *ngIf="printbutton" />
                            </a>
                            <a (click)="getExcelData('excel')">
                                <img src="../../../assets/ui_icons/buttons/XL_export.svg" class="saveimgbtn_inpatinfo" style="margin-right:10px" />
                            </a>
                        </mat-label>
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body style="padding: 0 1rem;"> 
                <div class="row" style="margin-top: 10px;"> <!--*ngIf="forcoverdiv"-->
                    <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 select_bottom">
                        <mat-label class="matlabel">Duration<br>
                            <select  class="ipcss" (change)="updateFromDate()" [(ngModel)]="selectedDuration">
                            <option value="All">All</option>
                            <option value="0">Today</option>
                            <option value="7">1 week</option>
                            <option value="30">1 month</option>
                            <option value="91">3 months</option>
                            <option value="183">6 months</option>
                            <option value="365">1 year</option>
                            </select>
                        </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom" [hidden]="date_flag"> 
                        <mat-label class="matlabel" style="margin-top: 7px;">From
                            <input type="date" class="ipcss_date datecss" max="{{currentDate}}" [(ngModel)]="fromDate" #matInput>
                        </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2" [hidden]="date_flag">
                        <mat-label class="matlabel" style="margin-top: 7px;">To
                            <input type="date" class="ipcss_date " max="{{currentDate}}"  [(ngModel)]="toDate" #matInput>
                        </mat-label>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 d-flex align-items-center" [hidden]="date_flag">
                        <mat-label class="matlabel"><br>
                            <img [hidden]="date_flag" src="../../../assets/ui_icons/buttons/search_button.svg" class="saveimgbtn_inpatinfo" (click)="getInpatientReportData()"/>
                        </mat-label>
                    </div>
                </div>
                    
                <p class="nodata" *ngIf="inpatient_list == undefined || inpatient_list.length == 0">No record(s) found</p>
                <div *ngIf="inpatient_list != undefined && inpatient_list.length != 0" class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-2" style="text-align: left;margin-top: 23px !important;">
                    <p class="textformat"> Active Inpatients: <strong>{{count}}</strong></p>
                </div>
                <div *ngIf="inpatient_list != undefined && inpatient_list.length != 0" class="table-responsive cover_div" style="width: 100%; height: 65vh;">
                    <div class="col-12">
                        <div style="width: 100%; margin-left: 0px;">
                            <mat-accordion displayMode="flat" multi="false" class="mat-table">
                                <section matSort class="mat-elevation-z2 mat-header-row" style="background-color: #c1e6fb; position: sticky; top: 0px; z-index: 2;">
                                    <span class="mat-header-cell" style="width: 50px;text-align: center;">S.No</span>
                                    <span *ngIf="columns.admDateTime" class="mat-header-cell" style="width: 150px;text-align: center;">Adm date/time</span>
                                    <span *ngIf="columns.mrNo" class="mat-header-cell" style="width: 100px;text-align: center;">MR.No</span>
                                    <span class="mat-header-cell" style="width: 130px !important;" style="width: 190px;text-align: center;">Patient name</span>
                                    <span *ngIf="columns.ageGender" class="mat-header-cell" style="width: 75px;text-align: center;">Age/Gender</span>
                                    <span class="mat-header-cell" style="width: 100px;text-align: center;">Inpatient id</span>
                                    <span *ngIf="columns.wardBed" class="mat-header-cell" style="width: 160px;text-align: center;">Ward & Bed</span>
                                    <span *ngIf="columns.mobileNo" class="mat-header-cell" style="width: 100px;text-align: center;">Mobile no</span>
                                    <span *ngIf="columns.docName" class="mat-header-cell" style="width: 150px;text-align: center;">Doctor name</span>
                                    <span *ngIf="columns.billAmt" class="mat-header-cell" style="width: 80px;text-align: center;">Advance</span>
                                    <span *ngIf="columns.billAmt" class="mat-header-cell" style="width: 80px;text-align: center;">Bill amount</span>
                                    <span *ngIf="columns.billAmt" class="mat-header-cell" style="width: 80px;text-align: center;">Balance</span>
                                    <span *ngIf="columns.billstatus" class="mat-header-cell" style="text-align: center;">Bill status</span>
                                </section>
                                <mat-expansion-panel *ngFor="let user of inpatient_list" style="font-size: 12px;">
                                    <mat-expansion-panel-header class="mat-row" (click)="getChargeDetails(user)">
                                        <span class="mat-cell" style="width: 30px;text-align: center;">{{user.sno}}</span>
                                        <span *ngIf="columns.admDateTime" class="mat-cell" style="width: 150px;">{{user.admDatendTime}}</span>
                                        <span *ngIf="columns.mrNo" class="mat-cell" style="width: 100px;">{{user.mr_no}}</span>
                                        <span class="mat-cell" style="width: 190px;">{{user.patient_name}}</span>
                                        <span *ngIf="columns.ageGender" class="mat-cell" style="width: 75px;text-align: center;">{{user.age_gender}}</span>
                                        <span class="mat-cell" style="width: 100px;text-align: center;">{{user.inp_hosp_id}}</span>
                                        <span *ngIf="columns.wardBed" class="mat-cell" style="width: 160px;">{{user.ward_bed}}</span>
                                        <span *ngIf="columns.mobileNo" class="mat-cell" style="width: 100px;">{{user.mobile}}</span>
                                        <span *ngIf="columns.docName" class="mat-cell" style="width: 150px;">{{user.doctor_name}}</span>
                                        <span *ngIf="columns.billAmt" class="mat-cell" style="width: 80px;text-align: right;padding-right: 10px;">{{user.advance}}</span>
                                        <span *ngIf="columns.billAmt" class="mat-cell" style="width: 80px;text-align: right;padding-right: 10px;">{{user.bill_amount}}</span>
                                        <span *ngIf="columns.billAmt" class="mat-cell" [style]="user.balance_col">{{user.balance}}</span>
                                        <span *ngIf="columns.billstatus" class="mat-cell" style="text-align: center;">{{user.status}}</span>
                                    </mat-expansion-panel-header>
                                    <div style="height: 50vh;width: 100%; margin-left: 0px;">
                                        <table style="font-size: 12px;font-family: Arial;" class="headerfixed">
                                            <thead>
                                                <tr>
                                                    <th style="text-align: center;">S.no</th>
                                                    <th style="text-align: center;">Service Charge</th>
                                                    <th style="text-align: center;">Charge base</th>
                                                    <th style="text-align: center;">Fee</th>
                                                    <th style="text-align: center;">Quantity</th>
                                                    <th style="text-align: center;">Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let sales of charges_list">
                                                    <td style="text-align: center;">{{sales.sno}}</td>
                                                    <td>{{sales.charge_desc}}</td>
                                                    <td>{{sales.charge_base}}</td>
                                                    <td style="text-align: right;padding-right: 50px;">{{sales.amount}}</td>
                                                    <td style="text-align: right;padding-right: 50px;">{{sales.quantity}}</td>
                                                    <td style="text-align: right;padding-right: 90px;">{{sales.total_amount}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </div>
                </div>

                <div [hidden]="true" #printbanner id="printbanner">
                    <div [hidden]="inpatient_list == undefined || inpatient_list.length == 0">
                        <table style="border:'0';width:100%">                 
                            <thead>
                                <tr>
                                    <th style="width:100%;">                                 
                                        <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;margin-right: 30px; margin-left: 20px;"> 
                                            <div style="width: 100%; height: 205px !important;"> 
                                                <div style="width: 100%;">
                                                    <img alt="image" src={{hospital_logo}}  [style]="printlogostyle">
                                                </div>                                                             
                                            </div>
                                        </div>               
                                    </th>
                                </tr>
                            </thead>                       
                            <tbody>  
                                <tr>  
                                    <td width="100%">                               
                                        <div class="bill_border">                                                         
                                            <div *ngIf="inpatient_list != undefined && inpatient_list.length != 0" style="margin-left: 20px;margin-right: 10px;">                      
                                                <div class="row">
                                                    <table style="width: 100% !important;border: 1px solid black;border-collapse: collapse;margin-right: 20px;">    
                                                        <thead>
                                                            <tr>
                                                                <th style="border: 1px solid black;border-collapse: collapse;">S.No</th>
                                                                <th style="border: 1px solid black;border-collapse: collapse;" *ngIf="columns.admDateTime">Adm date/time</th>
                                                                <th style="border: 1px solid black;border-collapse: collapse;" *ngIf="columns.mrNo">MR.No</th>
                                                                <th style="border: 1px solid black;border-collapse: collapse;">Patient name</th>
                                                                <th style="border: 1px solid black;border-collapse: collapse;" *ngIf="columns.ageGender">Age/ Gender</th>
                                                                <th style="border: 1px solid black;border-collapse: collapse;">Inpatient id</th>
                                                                <th style="border: 1px solid black;border-collapse: collapse;" *ngIf="columns.wardBed">Ward & Bed</th>
                                                                <th style="border: 1px solid black;border-collapse: collapse;" *ngIf="columns.mobileNo">Mobile no</th>
                                                                <th style="border: 1px solid black;border-collapse: collapse;" *ngIf="columns.docName">Doctor name</th>
                                                                <th style="border: 1px solid black;border-collapse: collapse;"*ngIf="columns.billAmt">Advance</th>
                                                                <th style="border: 1px solid black;border-collapse: collapse;"*ngIf="columns.billAmt">Bill amount</th>
                                                                <th style="border: 1px solid black;border-collapse: collapse;"*ngIf="columns.billAmt">Balance</th>
                                                                <th *ngIf="columns.billstatus" style="border: 1px solid black;border-collapse: collapse;">Bill status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let user of inpatient_list">
                                                                <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{user.sno}}</td>
                                                                <td style="border: 1px solid black;border-collapse: collapse;" *ngIf="columns.admDateTime">{{user.admDatendTime}}</td>
                                                                <td style="text-align: center;border: 1px solid black;border-collapse: collapse;" *ngIf="columns.mrNo">{{user.mr_no}}</td>
                                                                <td style="border: 1px solid black;border-collapse: collapse;">{{user.patient_name}}</td>
                                                                <td style="text-align: center;border: 1px solid black;border-collapse: collapse;" *ngIf="columns.ageGender">{{user.age_gender}}</td>
                                                                <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{user.inp_hosp_id}}</td>
                                                                <td style="border: 1px solid black;border-collapse: collapse;" *ngIf="columns.wardBed">{{user.ward_bed}}</td>
                                                                <td style="border: 1px solid black;border-collapse: collapse;"*ngIf="columns.mobileNo">{{user.mobile}}</td>
                                                                <td style="border: 1px solid black;border-collapse: collapse;" *ngIf="columns.docName">{{user.doctor_name}}</td>
                                                                <td style="text-align: right;border: 1px solid black;border-collapse: collapse;" *ngIf="columns.billAmt">{{user.advance}}</td>
                                                                <td style="text-align: right;border: 1px solid black;border-collapse: collapse;" *ngIf="columns.billAmt">{{user.bill_amount}}</td>
                                                                <td style="text-align: right;border: 1px solid black;border-collapse: collapse;" *ngIf="columns.billAmt">{{user.balance}}</td>
                                                                <td *ngIf="columns.billstatus" style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{user.status}}</td>
                                                            </tr>
                                                        </tbody>                                     
                                                    </table>
                                                </div>
                                            </div>                                                                                                                                                   
                                        </div>                
                                    </td>
                                </tr>
                            </tbody>    
                        </table>
                    </div>
                </div> 
        
                <div [hidden]="true" #printnoheader id="printnoheader">
                    <div [hidden]="inpatient_list == undefined || inpatient_list.length == 0">
                        <table style="border:'0';width:100%">             
                            <thead>
                                <tr>
                                    <th style="width:100%;">
                                        <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;margin-right: 30px; margin-left: 20px;"> 
                                        </div>
                                    </th>
                                </tr>
                            </thead>       
                            <tbody>  
                                <tr>  
                                    <td width="100%">                             
                                        <div class="bill_border">                                              
                                            <div *ngIf="inpatient_list != undefined && inpatient_list.length != 0" style="margin-left: 20px;margin-right: 10px;">                      
                                                <div class="row">
                                                    <table style="width: 100% !important;border: 1px solid black;border-collapse: collapse;margin-right: 20px;">    
                                                        <thead>
                                                            <tr>
                                                                <th style="border: 1px solid black;border-collapse: collapse;">S.No</th>
                                                                <th style="border: 1px solid black;border-collapse: collapse;" *ngIf="columns.admDateTime">Adm date/time</th>
                                                                <th style="border: 1px solid black;border-collapse: collapse;" *ngIf="columns.mrNo">MR.No</th>
                                                                <th style="border: 1px solid black;border-collapse: collapse;">Patient name</th>
                                                                <th style="border: 1px solid black;border-collapse: collapse;" *ngIf="columns.ageGender">Age/Gender</th>
                                                                <th style="border: 1px solid black;border-collapse: collapse;">Inpatient id</th>
                                                                <th style="border: 1px solid black;border-collapse: collapse;" *ngIf="columns.wardBed">Ward & Bed</th>
                                                                <th style="border: 1px solid black;border-collapse: collapse;" *ngIf="columns.mobileNo">Mobile no</th>
                                                                <th style="border: 1px solid black;border-collapse: collapse;" *ngIf="columns.docName">Doctor name</th>
                                                                <th style="border: 1px solid black;border-collapse: collapse;"*ngIf="columns.billAmt">Advance</th>
                                                                <th style="border: 1px solid black;border-collapse: collapse;"*ngIf="columns.billAmt">Bill amount</th>
                                                                <th style="border: 1px solid black;border-collapse: collapse;"*ngIf="columns.billAmt">Balance</th>
                                                                <th *ngIf="columns.billstatus" style="border: 1px solid black;border-collapse: collapse;">Bill status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let user of inpatient_list">
                                                                <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{user.sno}}</td>
                                                                <td style="border: 1px solid black;border-collapse: collapse;" *ngIf="columns.admDateTime">{{user.admDatendTime}}</td>
                                                                <td style="text-align: center;border: 1px solid black;border-collapse: collapse;" *ngIf="columns.mrNo">{{user.mr_no}}</td>
                                                                <td style="border: 1px solid black;border-collapse: collapse;">{{user.patient_name}}</td>
                                                                <td style="text-align: center;border: 1px solid black;border-collapse: collapse;" *ngIf="columns.ageGender">{{user.age_gender}}</td>
                                                                <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{user.inp_hosp_id}}</td>
                                                                <td style="border: 1px solid black;border-collapse: collapse;" *ngIf="columns.wardBed">{{user.ward_bed}}</td>
                                                                <td style="border: 1px solid black;border-collapse: collapse;"*ngIf="columns.mobileNo">{{user.mobile}}</td>
                                                                <td style="border: 1px solid black;border-collapse: collapse;" *ngIf="columns.docName">{{user.doctor_name}}</td>
                                                                <td style="text-align: right;border: 1px solid black;border-collapse: collapse;" *ngIf="columns.billAmt">{{user.advance}}</td>
                                                                <td style="text-align: right;border: 1px solid black;border-collapse: collapse;" *ngIf="columns.billAmt">{{user.bill_amount}}</td>
                                                                <td style="text-align: right;border: 1px solid black;border-collapse: collapse;" *ngIf="columns.billAmt">{{user.balance}}</td>
                                                                <td *ngIf="columns.billstatus" style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{user.status}}</td>
                                                            </tr>
                                                        </tbody>                                     
                                                    </table>
                                                </div>
                                            </div>                                                                                                                                         
                                        </div>                
                                    </td>
                                </tr>
                            </tbody>                       
                        </table>
                    </div>        
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>