import { Component, OnInit, Input, ViewChild,ChangeDetectorRef } from '@angular/core';
import { Helper_Class } from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ipaddress } from '../../ipaddress';
import { Message_data } from '../../../assets/js/Message_data';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import Quill from 'quill';
import { Doc_Helper } from '../../Doctor_module/Doc_Helper';
import { ConvertTimeformat, Date_Formate, tConv24, Time_Formate } from '../../../assets/js/common';
import { CalendarOptions } from '@fullcalendar/angular';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
import { timeStamp } from 'console';

@Component({
  selector: 'app-ot-booking',
  templateUrl: './ot-booking.component.html',
  styleUrls: ['./ot-booking.component.scss']
})
export class OtBookingComponent implements OnInit {
  public eventList: any = [];
  calendarOptions: CalendarOptions;

  handleDateClick(arg) {
    
  }

  editorStyle = { height: '250px' };
  content;
  htmlString: string = '';
  modules = {
    toolbar: [
      ['bold', 'italic', 'underline',], // toggled buttons
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'align': [] }],
    ],

  };
  tools = {
    toolbar: [
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ font: [] }],
      [{ align: [] }],
    ]
  };

  public userInfo;
  public hospitalDetails;
  public rowCount;
  public showCreateDetailFlag: boolean = false;
  public showCalendarViewFlag: boolean = true;
  public otListFlag: boolean = false;
  dtOptions: DataTables.Settings = {};
  public updateFlag: boolean;
  public otList: any = [];
  public otDataList: any = [];
  public spl_array: any = [];
  public surgeon_list: any = [];
  public surgery_list: any = [];
  public anaest_types: any = [];
  public anaest_doctors: any = [];

  public spl_id;
  public spl;
  public otId;
  public surgery_id: string;
  public anaesttype: string;
  public surgeryOut: string;
  public otNotes: string;
  public ansProc: string;
  public surgeryProc: string;
  public patName: string;
  public bookDate;
  public start_time1;
  public start_time2;
  public start_time3;
  public end_time1;
  public end_time2;
  public end_time3;
  public surgeons;
  public doc_reg_id;
  public surgery_proc;
  public surgery_date;
  public consultants;
  public surgery_min = [];
  public surgery_hrs = [];
  public inpatientList: any = [];
  public anaest_doc: string[] = [];
  public surg_doc: string[] = [];
  public consult_doc: string[] = [];
  public isPatUpd: boolean;
  public inPatientId;
  public patFirstName;
  public inPatientId2;
  public consultant;
  public viewValue;
  public surgeryitemlist=[];
  public surgequpart:boolean=true;
  private client_reg_id: string;
  public surgerydet:any=[];
  public surgeryname:any;
  public surgdocname:any;
  public inp_surgery_id:string='0'

  constructor(public http: HttpClient, public toastr: ToastrService,public service: MenuViewService,private cdr: ChangeDetectorRef) {
    for (var i = 1; i <= 12; i++) {
      if (i < 10) {
        this.surgery_hrs.push("0" + i);
      } else {
        this.surgery_hrs.push(i);
      }
    }

    for (var i = 0; i <= 55; i += 5) {
      if (i < 10) {
        this.surgery_min.push("0" + i);
      } else {
        this.surgery_min.push(i);
      }
    }
  }

  ngOnInit(): void {
    this.userInfo = Helper_Class.getInfo();
    this.hospitalDetails = Helper_Class.getHospitalId();
    console.log(" HD = "+this.hospitalDetails)

    this.patFirstName = Doc_Helper.getFirstname();
    this.inPatientId2 = Doc_Helper.getInpatientId();
    this.getOperationTheatres();
    this.getSurgeons();
    this.getAnaestesiaTypes();
    this.getAnaestisiaDoctors();
    this.getSpecializations();
    this.getOtData();

    this.surg_doc = [];
    this.anaest_doc = [];

    // if (Doc_Helper.getFirstname() != null) {
    // this.bookDate = this.inpatientList[0].bookDate;
    this.isPatUpd = false;

    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [0] },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search by name or patient id"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };

    setTimeout(() => {
      this.calendarOptions = {
        themeSystem: 'bootstrap5',
        initialView: 'dayGridMonth',
        contentHeight: 600,
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth, timeGridWeek, timeGridDay' // user can switch between the two
        },
        // dateClick: this.handleDateClick.bind(this), // bind is important!
        events: this.eventList

      };
    }, 2000);

    this.surgequpart=true;
  }

  showsurgery(surgery_id){
    if(surgery_id != undefined || null|| ""){ 
      for(var j = 0; j < this.surgery_list.length; j++) {
        if(this.surgery_list[j].surgery_id == this.surgery_id) {
          this.surgeryname=this.surgery_list[j].surgery_name
          break
        }}
      
      this.surgequpart=false;
      this.getSurgeryEquipment(surgery_id)
    }
  }

  getSurgeryEquipment(surgery_id){
    console.log("data_surgery = "+JSON.stringify(surgery_id))
    var anaest_desc;
    if(this.anaesttype != undefined) {
      for(var j = 0; j < this.anaest_types.length; j++) {
        if(this.anaest_types[j].type_id == this.anaesttype) {
          anaest_desc = this.anaest_types[j].description;
          break
        }
      }
    }

    var senddata={
      hptl_clinic_id:this.hospitalDetails,

      surgery_id: surgery_id,
      type: anaest_desc
    }

    console.log("getsurgery = "+JSON.stringify(senddata))
    this.surgeryitemlist=[];
    var headers=new HttpHeaders();
    headers.append('Content-Type','application/json');
    this.http.post(ipaddress.getIp.toString()+'inpat/gse',senddata,{
      headers:headers
    }).subscribe(
      response=> {
        var obj=JSON.parse(JSON.stringify(response))
        console.log('surgeryEquipment = '+JSON.stringify(obj));
        if (obj.surgery_equipments != null && obj.surgery_equipments != undefined) {
          for(var i=0;i<obj.surgery_equipments.length;i++){
            this.surgeryitemlist.push({
              drug_type_id:obj.surgery_equipments[i].product_type_id,
              drug_id:obj.surgery_equipments[i].product_id,
              item:obj.surgery_equipments[i].equipment,
              qty:obj.surgery_equipments[i].quantity
            })
          }
        }     
    },error =>{
      console.log("error in getting equipment")
    })
  }

  deletesurgeryitem(item){
    this.surgeryitemlist = this.surgeryitemlist.filter(sample => sample.item !== item);
    console.log("surgery item = "+JSON.stringify(this.surgeryitemlist))
  }

  changeOTNotes = (event) => {
    this.otNotes = event.html;
  }

  changeAnaeste = (event) => {
    this.ansProc = event.html;
  }

  goBack() {
    this.showCreateDetailFlag = false;
    this.showCalendarViewFlag = true;
    this.otListFlag = false;
    this.updateFlag = false;
    this.surg_doc = [];
    this.anaest_doc = [];
    this.surgerydet = [];
  }

  getOperationTheatres() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "inpat/got", { hptl_clinic_id: this.hospitalDetails },
      { headers: headers }).subscribe(
        data => {
          this.otList = [];
          this.otList = JSON.parse(JSON.stringify(data)).ot_details;
        });
  }

  getInpatientsInOnit(patname, patid) {
    this.patName = patname;
    console.log("this.patName", this.patName);
    console.log("this.surgeon", this.surgeon_list);

    if (this.patName != ""  && this.patName != undefined && this.patName.length > 2 ) {
      var send_data = {
        first_name: encrypt_decript.Encript(this.patName.toLocaleUpperCase()).toString(),
        hptl_clinic_id: this.hospitalDetails,
      }

      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "inpat/gipn", send_data,
        { headers: headers }).subscribe(
          data => {
            this.inpatientList = [];
            var jobj = JSON.parse(JSON.stringify(data)).inpatients;
            console.log("inpatients_info = "+JSON.stringify(jobj))
            this.inp_surgery_id='1'
            if (jobj != undefined) {
              for (var i = 0; i < jobj.length; i++) {
                var cname;
                if (jobj[i].middle_name != undefined) {
                  cname = encrypt_decript.Decript(jobj[i].first_name) + " " + encrypt_decript.Decript(jobj[i].middle_name) + " " + encrypt_decript.Decript(jobj[i].last_name);
                } else {
                  cname = encrypt_decript.Decript(jobj[i].first_name) + " " + encrypt_decript.Decript(jobj[i].last_name);
                }
                var surgtime, surgtime1, surgtime2;
                if (jobj[i].surgery_time != undefined) {
                  surgtime = jobj[i].surgery_time.split(":")
                  surgtime1 = surgtime[0];
                  surgtime2 = surgtime[1];
                }

                if (jobj[i].inpatient_id == patid) {
                  this.inpatientList.push({
                    inpatient_id: jobj[i].inpatient_id,
                    name: cname,
                    client_reg_id: jobj[i].client_reg_id,
                    doc_reg_id: jobj[i].doc_reg_id,
                    surgery_id: jobj[i].surgery_id,
                    surgery_proc: jobj[i].surgery_proc,
                    surgery_date: jobj[i].surgery_date,
                    start_time1: surgtime1,
                    start_time2: surgtime2,
                    surgeon: jobj[i].surgeon,
                    anaest_doc: jobj[i].anaest_doc,
                    anaesttype: jobj[i].anaesttype,
                    consultants: jobj[i].consultants,
                  })
                }
              }
              console.log("this.patName" + JSON.stringify(this.inpatientList));

              this.start_time1 = this.inpatientList[0].start_time1;
              this.start_time2 = this.inpatientList[0].start_time2;
              this.start_time3 = this.inpatientList[0].start_time3;
              this.surgeons = this.inpatientList[0].surgeon;
              this.doc_reg_id = this.inpatientList[0].doc_reg_id;
              this.surgery_id = this.inpatientList[0].surgery_id;
              this.surgery_proc = this.inpatientList[0].surgery_proc;
              this.surgery_date = this.inpatientList[0].surgery_date;
              this.anaest_doc = this.inpatientList[0].anaest_doc;
              this.anaesttype = this.inpatientList[0].anaesttype;
              this.consultants = this.inpatientList[0].consultants;
              this.inPatientId = this.inpatientList[0].inpatient_id;

              if (this.surgeon_list.length != 0 && this.inpatientList[0].surgeon != undefined) {
                this.surg_doc = this.inpatientList[0].surgeon.split(",");

                console.log("this.surg_doc", this.surg_doc)
              }

              if (this.anaest_doctors.length != 0 && this.inpatientList[0].anaest_doc != undefined) {
                this.anaest_doc = this.inpatientList[0].anaest_doc.split(",");

                console.log("this.anaest_doc", this.anaest_doc)
              }

              if (this.surgeon_list.length != 0 && this.inpatientList[0].consultants != undefined) {
                this.consultant = this.inpatientList[0].consultants.split(",");

                console.log("this.anaest_doc", this.anaest_doc)
              }
            }
          });
    }
  }

  getInpatients(e) {
    this.patName = e.target.value.toString();
    console.log("this.patName", this.patName);
    if (this.patName != "" && this.patName.length > 2) {
      var send_data = {
        first_name: encrypt_decript.Encript(this.patName.toLocaleUpperCase()).toString(),
        hptl_clinic_id: this.hospitalDetails,
      }

      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "inpat/gipn", send_data,
        { headers: headers }).subscribe(
          data => {
            this.inpatientList = [];
            var jobj = JSON.parse(JSON.stringify(data)).inpatients;
            console.log("jobj", jobj);
            if (jobj != undefined) {
              for (var i = 0; i < jobj.length; i++) {
                var cname;
                if (jobj[i].middle_name != undefined) {
                  cname = encrypt_decript.Decript(jobj[i].first_name) + " " + encrypt_decript.Decript(jobj[i].middle_name) + " " + encrypt_decript.Decript(jobj[i].last_name);
                } else {
                  cname = encrypt_decript.Decript(jobj[i].first_name) + " " + encrypt_decript.Decript(jobj[i].last_name);
                }
                var surgtime, surgtime1, surgtime2;
                if (jobj[i].surgery_time != undefined) {
                  surgtime = jobj[i].surgery_time.split(":")
                  surgtime1 = surgtime[0];
                  surgtime2 = surgtime[1];
                }

                this.inpatientList.push({
                  inpatient_id: jobj[i].inpatient_id,
                  name: cname,
                  client_reg_id: jobj[i].client_reg_id,
                  doc_reg_id: jobj[i].doc_reg_id,
                  surgery_id: jobj[i].surgery_id,
                  surgery_proc: jobj[i].surgery_proc,
                  surgery_date: jobj[i].surgery_date,
                  start_time1: surgtime1,
                  start_time2: surgtime2,
                  surgeon: jobj[i].surgeon,
                  anaest_doc: jobj[i].anaest_doc,
                  anaesttype: jobj[i].anaesttype,
                  consultants: jobj[i].consultants,
                })

                console.log("getInpatients(e)", this.inpatientList)
              }
            }
          });
    }

  }

  selectInpatients(inpat) {
    this.patName = inpat.name;
    this.bookDate = inpat.bookDate;
    this.start_time1 = inpat.start_time1;
    this.start_time2 = inpat.start_time2;
    this.start_time3 = inpat.start_time3;
    this.surgeons = inpat.surgeon;
    this.doc_reg_id = inpat.doc_reg_id;
    this.surgery_id = inpat.surgery_id;
    this.surgery_proc = inpat.surgery_proc;
    this.surgery_date = inpat.surgery_date;
    this.anaest_doc = inpat.anaest_doc;
    this.anaesttype = inpat.anaesttype;
    this.consultants = inpat.consultants;
    this.inPatientId = inpat.inpatient_id;
    this.client_reg_id = inpat.client_reg_id;

    if (inpat.surgeon != undefined) {
      this.surg_doc = inpat.surgeon.split(",");
      if (this.surgeon_list.length != 0 && inpat.surgeon != undefined) {
        var surgdoc = inpat.surgeon.split(",");
        for (var i = 0; i < surgdoc.length; i++) {
          this.surg_doc.push(surgdoc[i])
        }
      }
    }

    if (inpat.consultant != undefined) {
      this.consult_doc = inpat.consultant.split(",");
      if (this.surgeon_list.length != 0 && inpat.consultant != undefined) {
        var consultdoc = inpat.consultant.split(",");
        for (var i = 0; i < consultdoc.length; i++) {
          this.consult_doc.push(consultdoc[i])
        }
      }
    }

    if (inpat.anaest_doc != undefined) {
      this.anaest_doc = inpat.anaest_doc.split(",");
      if (this.anaest_doctors.length != 0 && inpat.anaest_doc != undefined) {
        var andoc = inpat.anaest_doc.split(",");
        for (var i = 0; i < andoc.length; i++) {
          this.anaest_doc.push(andoc[i])
        }
      }
    }
  }

  getSpecializations() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "adm/gsplbyhptl", { hptl_clinic_id: this.hospitalDetails },
      { headers: headers }).subscribe(
        data => {
          this.spl_array = [];
          var obj = JSON.parse(JSON.stringify(data));

          if (obj.specializations != null) {
            this.spl_array = obj.specializations;
            this.spl_id = this.spl_array[0].spl_id;
            this.spl = this.spl_array[0].spl_name;
            // this.getDoctorList(this.spl_id)
            this.getSurgeries();
            this.getSurgeons();
          }
        },
        error => {
          this.toastr.error("Unable to fetch country details, try again later");
        })
  }

  getSurgeons() {
    var send_data = {
      hospital_id: this.hospitalDetails
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'search/docbyhns', send_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          // console.log("Surgeons "+JSON.stringify(obj))

          if (obj != null) {
            this.surgeon_list = [];
            for (var i = 0; i < obj.doctors.length; i++) {
              var docname;
              if (obj.doctors[i].middle_name != undefined) {
                docname = obj.doctors[i].first_name + " " + obj.doctors[i].middle_name + " " + obj.doctors[i].last_name;

              } else {
                docname = obj.doctors[i].first_name + " " + obj.doctors[i].last_name;
              }
              this.surgeon_list.push({
                docname: docname,
                prov_id: obj.doctors[i].prov_id,
             })
            }
            this.getInpatientsInOnit(this.patFirstName, this.inPatientId2);
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  Change_Specialization(e) {
    this.spl_id = e;
    for (var i = 0; i < this.spl_array.length; i++) {
      if (this.spl_array[i].spl_id == this.spl_id) {
        this.spl = this.spl_array[i].spl_name;
        this.spl_id = this.spl_array[i].spl_id;
        this.getSurgeries();
      }
    }
  }

  getSurgeries() {
    var send_data = {
      spl_id: this.spl_id,
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'cardio/medproc', send_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          // console.log("getSurgeries "+JSON.stringify(obj))

          if (obj != null) {
            this.surgery_list = [];
            for (var i = 0; i < obj.med_procedures.length; i++) {
              this.surgery_list.push({
                surgery_id: obj.med_procedures[i].procedure_id,
                surgery_name: obj.med_procedures[i].description,
              })
            }
            console.log(this.surgery_list);
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  getAnaestesiaTypes() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp + "inpat/gat",
      { headers: headers }).subscribe(
        data => {
          this.anaest_types = [];
          var obj = JSON.parse(JSON.stringify(data));
          this.anaest_types = obj.anaesthesia_types;
        },
        error => {
          this.toastr.error("Unable to fetch country details, try again later");
        })
  }

  getAnaestisiaDoctors() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "inpat/gad", { hptl_clinic_id: this.hospitalDetails },
      { headers: headers }).subscribe(
        data => {
          this.anaest_doctors = [];
          var obj = JSON.parse(JSON.stringify(data));
          this.anaest_doctors = obj.anaesthesia_doctors;
          console.log("ANAESTESIA EFFECTS " + JSON.stringify(this.anaest_doctors))

        },
        error => {
          this.toastr.error("Unable to fetch country details, try again later");
        })
  }

  saveDetails(type) {
    var start_time;
    if (this.start_time1 != undefined && this.start_time2 != undefined) {
      start_time = ConvertTimeformat("", this.start_time1 + ":" + this.start_time2 + " " + this.start_time3);
    } else {
      start_time = "";
    }

    var end_time;
    if (this.end_time1 != undefined && this.end_time2 != undefined) {
      end_time = ConvertTimeformat("", this.end_time1 + ":" + this.end_time2 + " " + this.end_time3);
    } else {
      end_time = "";
    }

    var docname;
    for(var i=0;i<this.surgeon_list.length;i++) {
      if(this.surg_doc ==this.surgeon_list[i].prov_id) {
        docname = this.surgeon_list[i].docname;
      }
    }
    var send_data;
    send_data = {
      ot_id: this.otId,
      inpatient_id: this.inPatientId,
      booking_date: this.surgery_date,
      from_time: start_time,
      to_time: end_time,
      is_patient_updated: this.isPatUpd,
      country: ipaddress.country_code.toString(),
      surgery_id: this.surgery_id,
      surgery_proc: this.surgeryProc,
      surgery_date: this.surgery_date,
      surgeon: this.surg_doc,
      anaest_doc: this.anaest_doc,
      consultants: this.consultant,
      anaesttype: this.anaesttype,
      medicines:this.surgeryitemlist,
      pharma_id:this.userInfo.pharma_id,
      client_reg_id:this.client_reg_id,
      relation_id:"1",
      doc_name:this.surgdocname,
      surgery_array:this.surgerydet,
      
    }
    console.log("saveDetails", send_data);
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'inpat/sotb', send_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log('save', JSON.stringify(obj));
          if (obj.key == "1") {
            this.toastr.success(obj.result);
            // this.getOtList();
            this.service.sendMessage('OTBooking');
          } else {
            this.toastr.error(obj.result);
          }
        });

  }

  createBooking() {
    this.patName = "";
    this.otId = "";
    this.surgery_date = "";
    this.start_time1 = "";
    this.start_time2 = "";
    this.start_time3 = "";
    this.end_time1 = "";
    this.end_time2 = "";
    this.end_time3 = "";
    // this.spl_id = "";
    this.surgery_id = "";
    this.isPatUpd = false;
    this.surgeryitemlist = [];
    this.anaesttype = "";
    this.showCreateDetailFlag = true;
    this.showCalendarViewFlag = false;
    this.otListFlag = false;
  }

  getOtData() {
    this.otDataList;
    var send_data = {
      hptl_clinic_id: this.hospitalDetails,
    };
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'inpat/gotd', send_data,
      { headers: headers })
      .subscribe(
        response => {
          this.otDataList = [];
          this.eventList = [];
          var obj = JSON.parse(JSON.stringify(response)).otbooking_details;
          if (obj != undefined) {
            console.log("this.otDataList ", obj);
            for (var i = 0; i < obj.length; i++) {

              var patName;
              patName = encrypt_decript.Decript(obj[i].first_name);
              var patient_name;
              if (obj[i].middle_name != undefined) {
                patient_name = encrypt_decript.Decript(obj[i].first_name) + " " + encrypt_decript.Decript(obj[i].middle_name) + " " + encrypt_decript.Decript(obj[i].last_name);
              } else if (obj[i].first_name != undefined && obj[i].last_name != undefined) {
                patient_name = encrypt_decript.Decript(obj[i].first_name) + " " + encrypt_decript.Decript(obj[i].last_name);
              }

              var fromTimeList, fromTime, toTimeList, toTime;
              if (obj[i].from_time != undefined) {
                fromTimeList = obj[i].from_time.split(":")
                fromTime = fromTimeList[2];               
                fromTime = Time_Formate(obj[i].from_time);
              }
              if (obj[i].to_time != undefined) {
                toTimeList = obj[i].to_time.split(":")
                toTime = fromTimeList[2];
                toTime = Time_Formate(obj[i].to_time);
              }

              this.eventList.push({
                title: patient_name,
                start: obj[i].booking_date + 'T' + obj[i].from_time,
                end: obj[i].booking_date + 'T' + obj[i].to_time,
              })

              var bdate;
              if(obj[i].booking_date != undefined){
                var bookdate = obj[i].booking_date.split("-");
                bdate = bookdate[2]+"-"+bookdate[1]+"-"+bookdate[0];
              }
              this.otDataList.push({
                patFirstName: patient_name,
                ot_name: obj[i].ot_name,
                booking_date: bdate,
                booking_id: obj[i].booking_id,
                from_time: fromTime,
                to_time: toTime,
                profile_image: obj[i].profile_image
              })

            }
            console.log("this.eventList", this.eventList);
            console.log("this.otDataList ", this.otDataList);
          }
        });
  }

  getOtList(ot) {
    // this.wardViewdata = [];
    this.otListFlag = false;
    var send_data = {
      hptl_clinic_id: this.hospitalDetails,
      booking_id: ot.booking_id,
    };
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'inpat/gotbd', send_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          if (obj != undefined && obj != null && obj != "") {

            console.log("OT Details", obj);
            this.showCreateDetailFlag = true;
            this.surgeons = obj.surgeon;
            // this.doc_reg_id = this.inpatientList[0].doc_reg_id;

            if (obj.spl_id != undefined) {
              this.spl_id = obj.spl_id;
              console.log(this.spl_id);
              this.getSurgeries();
              this.cdr.detectChanges();
              setTimeout(()=>{
                if (obj.surgery_id != undefined && obj.surgery_id != null) {
                  this.surgery_id = obj.surgery_id;
                  console.log(this.surgery_id);
                  this.showsurgery(this.surgery_id);
                }
              },500);            
            }                                        
            this.surgery_proc = obj.surgery_proc;
            this.surgery_date = obj.surgery_date;
            this.anaest_doc = obj.anaest_doc;
            this.anaesttype = obj.anaesttype;
            this.consultants = obj.consultants;
            this.inPatientId = obj.inpatient_id;
            this.otId = obj.ot_id;

            if (this.surgeon_list.length != 0 && obj.surgeon != undefined) {
              this.surg_doc = obj.surgeon.split(",");

              console.log("this.surg_doc", this.surg_doc)
            }

            if (this.anaest_doctors.length != 0 && obj.anaest_doc != undefined) {
              this.anaest_doc = obj.anaest_doc.split(",");

              console.log("this.anaest_doc", this.anaest_doc)
            }

            if (obj.is_patient_updated == '1') {
              this.isPatUpd = true;
            } else {
              this.isPatUpd = false;
            }

            if (obj.middle_name != undefined) {
              this.patName = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.middle_name) + " " + encrypt_decript.Decript(obj.last_name);

            } else {
              this.patName = encrypt_decript.Decript(obj.first_name) + " " + encrypt_decript.Decript(obj.last_name);
            }

            if (obj.from_time != undefined) {
              var fromTimeList = obj.from_time.split(':');

              this.start_time2 = fromTimeList[1];
              if (fromTimeList[0] > 12) {
                this.start_time3 = 'PM';
                this.start_time1 = ('0' + (fromTimeList[0] - 12));
              } else {
                this.start_time3 = 'AM'
                this.start_time1 = fromTimeList[0];
              }
              // this.start_time3 = fromTimeList[2];
              console.log("fromTimeList", fromTimeList)
            }

            if (obj.to_time != undefined) {
              var toTimeList = obj.to_time.split(':');

              this.end_time2 = toTimeList[1];
              if (toTimeList[0] > 12) {
                this.end_time3 = 'PM';
                this.end_time1 = ('0' + (toTimeList[0] - 12));
                console.log("toTimeList", toTimeList[0])
              } else {
                this.end_time3 = 'AM'
                this.end_time1 = toTimeList[0];
              }

            }

            if(obj.surgery_array != undefined && obj.surgery_array.length != 0){
              for(var s=0;s<obj.surgery_array.length;s++){
                this.surgerydet.push({
                  specialization_id:obj.surgery_array[s].specialization_id,
                  Specialization:obj.surgery_array[s].specialization_name,
                  status:obj.surgery_array[s].status,
                  surgeon:obj.surgery_array[s].surgeon,
                  surgery_id:obj.surgery_array[s].surgery_id,
                  surgery_name:obj.surgery_array[s].surgery_desc

                })
              }
            }
            



            // if (this.surgeon_list.length != 0 && this.inpatientList[0].consultants != undefined) {
            //   this.consultant = this.inpatientList[0].consultants.split(",");

            //   console.log("this.anaest_doc", this.anaest_doc)
            // }
          }
        });
  }

  // viewWardDetail(ward){
  //   this.showCreateDetailFlag = true;
  //   this.bedTableCount = [];
  //   this.wardName = ward.ward_name;
  //   this.floor = ward.floor;
  //   this.nursingCharges = ward.nursing_charges;
  //   this.wardId = ward.ward_id;
  //   this.updateFlag = this.wardId != undefined && this.wardId != null ? true:false;
  //   var bedDetails = ward.bed_details;
  //   for(var i= 0; i < bedDetails.length; i++){
  //     this.bedTableCount.push({
  //       ward_bed_id:bedDetails[i].ward_bed_id,
  //       bed_no:bedDetails[i].bed_no,
  //       bed_type:bedDetails[i].bed_type,
  //       rate:bedDetails[i].rate,
  //     });
  //   }

  // }

  calendarView() {
    this.showCalendarViewFlag = true;
    this.showCreateDetailFlag = false;
    this.otListFlag = false;
  }

  changeView(e) {
    this.viewValue = e.target.value;
    console.log(this.viewValue);

    if (this.viewValue == "Calendar") {
      this.showCalendarViewFlag = true;
      this.showCreateDetailFlag = false;
      this.otListFlag = false;
    } else {
      this.showCalendarViewFlag = false;
      this.showCreateDetailFlag = false;
      this.otListFlag = true;
    }
  }

  redirect(){
    // alert(JSON.stringify(this.service.sendMessage))
    this.service.sendMessage("consingmentlist")
  }

  surgeonsdata(surg_doc){

    // console.log("surgery_list = "+JSON.stringify(this.surgery_list))
    // for(var j = 0; j < this.surgery_list.length; j++) {
    //   alert(this.surg_doc +" "+this.surgery_list[j].prov_id)
    //   if(this.surgery_list[j].prov_id == this.surg_doc) {
    //     this.surgdocname = this.surgery_list[j].docname
    //     alert(this.surgdocname+" "+this.surgery_list[j].docname)
    //     break
    //   }}
    this.surgdocname=surg_doc.docname
  }

  addsurgendetails(){
    // this.surgerydet=[]
    var spl;
    var surgnameflag;
    var surgdocnameflag;
    if(this.spl != undefined){
      this.spl
      spl=true;
    }else{
      this.spl=''
      spl=false
    }

    if(this.surgeryname != undefined){
      this.surgeryname
      surgnameflag=true;
    }else{
      this.surgeryname=''
      surgnameflag=false
    }

    if(this.surgdocname != undefined){
      this.surgdocname
      surgdocnameflag=true
    }else{
      this.surgdocname=''
      surgdocnameflag=false
    }
    
    if( spl ==false && surgnameflag == false && surgdocnameflag == false){

    }
    else{
      this.surgerydet.push({
        Specialization:this.spl,
        surgery_name:this.surgeryname,
        // doctor_name:this.surgdocname
        surgery_id:this.surgery_id,
       surgeon:this.surgdocname,
       specialization_id:this.spl_id,
       inp_surgery_id:this.inp_surgery_id
      })
      this.spl='';
      this.surgeryname='';
      // this.surgdocname='';
      this.spl_id='';
      this.surgery_id='';
      this.surg_doc=[]
    } 
  }

  deletesurg(surg){
    this.surgerydet.splice(surg,1)
  }

}
