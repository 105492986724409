import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Helper_Class } from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { MatDialog } from '@angular/material/dialog';
import { FrontDeskService } from '../front-desk/front-desk.service';
import { ipaddress } from '../../ipaddress' ;import { Message_data } from 'src/assets/js/Message_data';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
import { FrontDesk_Helper } from '../FrontDesk_Helper';
import { error } from 'console';
declare var $:any;

@Component({
  selector: 'app-billing-charges',
  templateUrl: './billing-charges.component.html',
  styleUrls: ['./billing-charges.component.scss']
})

export class BillingChargesComponent implements OnInit {
  public chargeId: string = '';
  public chargeDesc: string = '';
  public chargeAmount: string = '';
  public chargeList:any= [];
  public hospId: string;
  public department: string = '';
  public sub_department: string;
  public departmentList: any[];
  public wardDetailList: any[];
  public ward: string;
  public wardFlag: boolean = false;
  public editbutton : boolean = false;
  public newbutton : boolean = false;
  public deletebutton : boolean = false;
  public printbutton : boolean = false;
  public viewbutton : boolean = false;
  dtOptions: DataTables.Settings = {};
  public autoGen:boolean;
  public Moduleidlist = [];
  public serviceType: any[];
  public ChargeTypeList: any[];
  public single_ac: any;
  public single_ac_amount: any;
  public multi_charge_array: any = [];
  public pagelist;
  public sno;
  public pageSize;
  public currentPage;
  public clientCount;
  public pageNavigate: boolean;
  public nextPageCount: boolean;
  public prevPageCount: boolean;
  public currentLimit = 1;
  public noOfPages: any;
  public charge_desc;
  public single_ac_charge_id: any;
  public super_deluxe_charge_id: any;
  public icu_charge_id: any;
  public semi_icu_charge_id: any;
  public general_female_charge_id: any;
  public general_male_charge_id: any;
  public single_old_block_charge_id: any;
  public single_new_block_charge_id: any;
  public side_room_charge_id: any;
  public charge_bases_array: any = [];
  public charge_base;
  public chargeCategoryList: any[];
  public charge_category;
 
  constructor(
    public gservice: CommonDataService,
    public http: HttpClient,
    public routes: ActivatedRoute,
    public router: Router,
    public toastr: ToastrService,
    public messageservice: MenuViewService,
    public dialog: MatDialog,
    public cdr: ChangeDetectorRef
  ) {
    this.pageSize = 50;
    this.currentPage = 0;
    this.pageNavigate = true;
  }
  ngOnInit(): void {  
    this.sub_department = "op";
    this.wardShow();
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 10,
      "lengthMenu": [[10,25, 50, -1], [10,25, 50, "All"]],     
      "columnDefs": [
          { "orderable": false, "targets": [6] },
        ],    
      "language": {
      "search": "<img src='../../../assets/ui_icons/Bill_list/Filter_Icon.svg' width='18px'/>",
      searchPlaceholder: 'Search Charge types by Name',
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 d-flex justify-content-end text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>',
    };

    this.hospId = Helper_Class.getInfo().hptl_clinic_id;
    this.getDepartWardList();
    this.getChargeCatagories();
    this.getChargeBases();
    this.Moduleidlist = Helper_Class.getmodulelist();
    if (this.Moduleidlist != undefined && this.Moduleidlist.length != 0) {
      for (var i = 0; i < this.Moduleidlist.length; i++) {
        if (this.Moduleidlist[i].module_id == "20") {
          if (this.Moduleidlist[i].edit == "1") {
            this.editbutton = true;
          }                    
          if (this.Moduleidlist[i].create == "1") {
            this.newbutton = true;
          }
          if (this.Moduleidlist[i].delete == "1") {
            this.deletebutton = true;
          }
          if (this.Moduleidlist[i].print == "1") {
            this.printbutton = true;
          }
          if (this.Moduleidlist[i].view == "1") {
            this.viewbutton = true;
          }
        }
      }
    }

    if (Helper_Class.getInfo().user_type == 'Admin') {
      this.editbutton = true;
      this.newbutton = true;
      this.deletebutton = true;
      this.printbutton = true;
      this.viewbutton = true;
    }
    
    this.pagelist="25";
    this.changePageList();
  }

  wardShow(){
    if(this.sub_department == "ip"){
      if (this.wardDetailList != undefined && this.wardDetailList.length != 0) {
        this.wardFlag = false;
      }     
    }
    if(this.sub_department == "op"){
      this.wardFlag = true;
    }
  }

  save() {
    this.multi_charge_array = [];
    var flag = false;
    var autoGen;
    var chargeid,
        errortext = 'Fill',
        mestext = '';
        chargeid = this.chargeId == undefined || this.chargeId == "" ? '0' : this.chargeId;

    if (this.chargeDesc == "" || this.chargeDesc == undefined){
      errortext += " Charge type"
      flag = true;
    }

    if (this.chargeList.length != 0) {
      for (var i = 0; i < this.chargeList.length; i++) {
        if (
          this.chargeList[i].charge_desc ==
          this.chargeDesc &&
          this.chargeList[i].sub_department == this.sub_department && this.chargeList[i].ward_id == this.ward
        ) {
          mestext = 'Charge type already exists';
          flag = true;
        }
      }
    }

    if (this.department == undefined) {
      errortext += ' Department';
      flag = true;
    }

    if (this.charge_category == undefined || this.charge_category == '') {
      errortext += ' Charge category';
      flag = true;
    }

    if (this.sub_department == undefined) {
      errortext += ' IP/OP';
      flag = true;
    }

    if (this.sub_department == "op") {
      if (this.chargeAmount == undefined) {
        errortext +=" amount";
        flag = true;
      }
    }  
    
    if (this.autoGen == true){
      autoGen = "1";
    } else {
      autoGen = "0";
    }

    if (this.sub_department == "ip") {
      var alert_flag = false;
      for (var i = 0; i < this.wardDetailList.length; i++) {  
        if (this.wardDetailList[i].amount != undefined && this.wardDetailList[i].amount != '') {
          alert_flag = true;
        } 
      }
      if (alert_flag == false) {
        errortext +=" atleast one ward charge amount";
        flag = true;
      }
    }

    if (flag == true) {
      if (mestext != '') {
        this.toastr.error(errortext + ' and also ' + mestext);
      } else {
        this.toastr.error(errortext);
      }
    } else if (flag == false) {
      if (
        this.chargeDesc != '' &&
        this.chargeDesc != undefined 
      ) {      
        if (this.sub_department == "ip") {
          var ward_id,amount,charge_id;
          for (var i = 0; i < this.wardDetailList.length; i++) {  
            ward_id = this.wardDetailList[i].ward_id;
            amount = this.wardDetailList[i].amount;
            charge_id = this.wardDetailList[i].charge_id;
            if (this.wardDetailList[i].charge_id == "" || this.wardDetailList[i].charge_id == undefined) {
              charge_id = "0";
            }   

            if (amount == undefined || amount == "") {
              console.log("Amount is Empty");
            } else {
              this.multi_charge_array.push({
                department_id: this.department,
                charge_desc: this.chargeDesc,
                bill_charge_id: charge_id,
                charge_cat_id: this.charge_category,
                ward_id: ward_id,
                amount: amount,
                ip_op: this.sub_department,
                // charge_cat: this.department,
                charge_base: this.charge_base,
                auto_generate : autoGen
              })
            }        
          }   
        } else if(this.sub_department == "op"){
            if (this.chargeAmount != undefined && this.chargeAmount != "") {
              this.multi_charge_array.push({
                department_id: this.department,
                charge_desc: this.chargeDesc,
                bill_charge_id: chargeid, 
                charge_cat_id: this.charge_category,            
                amount: this.chargeAmount,
                ip_op: this.sub_department,
                // charge_cat: this.department,
                charge_base: this.charge_base,
                auto_generate : autoGen
              })
            }
        }
       
        var sendData = {
          hptl_clinic_id: this.hospId,       
          country: 'IN',
          charges: this.multi_charge_array
        };

        console.log("sendData",sendData);
        var headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        this.http
          .post(ipaddress.getIp.toString() + 'bill/scharge/', sendData, {
            headers: headers,
          })
          .subscribe((response) => {
            var obj = JSON.parse(JSON.stringify(response));
            console.log(obj);
            if (obj != undefined && obj.key == '1') {
              this.toastr.success("Service charge saved successfully");
              this.changePageList();
              this.chargeId = undefined;
              this.chargeDesc = '';
              this.chargeAmount = '';
              this.sub_department = 'op';
              this.charge_category = '',
              this.charge_base = this.charge_bases_array[3].charge_base_id;
              this.wardShow();
              this.department = '';
              this.ward = '';
              this.autoGen = false;              
              this.multi_charge_array = [];  
              if (this.wardDetailList != undefined && this.wardDetailList.length != 0) {
                for (var w = 0; w < this.wardDetailList.length; w++) {
                  this.wardDetailList[w].amount = '';
                  this.wardDetailList[w].charge_id = undefined;
                }
              } 
                       
            } else {
              this.toastr.error('Unable to Save Service charge');
            }
          });
      } else {
        this.toastr.error(Message_data.mandatory);
      }
    }
  }

  getChargeCatagories() {
    this.chargeCategoryList = [];
    var headers = new HttpHeaders;
    headers.append('Content-Type','application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/ghcc', { hptl_clinic_id : this.hospId }, { headers: headers })
    .subscribe( (response) =>{
      var obj = JSON.parse(JSON.stringify(response));
      console.log(obj);
      if (obj != undefined && obj != null) {
        for (var i = 0; i < obj.charge_categories.length; i++) {
          this.chargeCategoryList.push({
            category_id: obj.charge_categories[i].category_id,
            category_desc: obj.charge_categories[i].category_desc,
            order_no: obj.charge_categories[i].order_no
          });
        }
        console.log(this.chargeCategoryList);
      }
    },error => {

    })
  }

  getDepartWardList() {
    this.departmentList = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(
        ipaddress.getIp.toString() + 'gen/gdbh',
        {
          hptl_clinic_id: this.hospId,
          type: 'hospital',
        },
        { headers: headers }
      )
      .subscribe(
        (response) => {
          var obj = JSON.parse(JSON.stringify(response));
          if (obj != undefined && obj != null) {
            for (var i = 0; i < obj.departments.length; i++) {
              this.departmentList.push({
                department_id: obj.departments[i].department_id,
                description: obj.departments[i].description,
              });
            }
            console.log(this.departmentList);
          }            
        },
        (error) => {
          this.toastr.error(Message_data.network);
        }
      );
    this.wardDetailList = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(
        ipaddress.getIp.toString() + 'mas/gwd',
        {
          hptl_clinic_id: this.hospId,
        },
        { headers: headers }
      )
      .subscribe(
        (response) => {
          var obj = JSON.parse(JSON.stringify(response));
          for (var i = 0; i < obj.wards.length; i++) {
            this.wardDetailList.push({
              ward_id: obj.wards[i].ward_id,
              ward_name: obj.wards[i].ward_name,
            });
          }
          console.log(this.wardDetailList);
        },
        (error) => {
          this.toastr.error(Message_data.network);
        }
      );
  }

  handleKeyUp(event: KeyboardEvent,charge_type) {
    const key = event.key;
    if (key !== 'ArrowUp' && key !== 'ArrowDown') {
      var  charge_type
      this.getServiceType(charge_type);
      this.chargeId = undefined;
      this.chargeAmount = '';
      this.sub_department = 'op';
      this.charge_base = this.charge_bases_array[3].charge_base_id;
      this.wardShow();
      this.department = '';
      this.ward = '';
      this.autoGen = false;              
      this.multi_charge_array = [];  
      if (this.wardDetailList != undefined && this.wardDetailList.length != 0) {
        for (var w = 0; w < this.wardDetailList.length; w++) {
          this.wardDetailList[w].amount = '';
          this.wardDetailList[w].charge_id = undefined;
        }
      } 
    }
    if (key === 'Enter') {
      if (charge_type) {
        this.changeChargeType1(charge_type);
      }
    }
  } 

  getServiceType(charge_type) {
    this.serviceType = []; 
    if (charge_type.length >= 2 && charge_type !== undefined) {
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http
        .post(
          ipaddress.getIp.toString() + 'bill/gsbn',
          {
            hptl_clinic_id: this.hospId,
            charge_name: charge_type,
          },
          { headers: headers }
        )
        .subscribe(
          (response) => {
            var obj = JSON.parse(JSON.stringify(response));
            console.log("Values",obj);
            for (var i = 0; i < obj.charges.length; i++) {
              this.serviceType.push({               
                charge_desc: obj.charges[i].charge_desc,
              });
            }
          },
          (error) => {
            this.toastr.error(Message_data.network);
          }
        );
      }
  }

  getAllChargeDetails() { 
    this.chargeAmount = "";
    var sendData = {
      hptl_clinic_id: this.hospId,
      charge_name: this.chargeDesc,
      ip_op: this.sub_department,
      department_id: this.department
    }
    console.log(sendData);
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(
        ipaddress.getIp.toString() + 'bill/gsbnd',
        sendData,
        { headers: headers }
      )
      .subscribe(
        (response) => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("Values",obj);
          if (this.sub_department != undefined && this.sub_department != "" && this.sub_department === "ip") {           
            if ( obj.charge_details != undefined &&  obj.charge_details.length != 0) {
              for (var i = 0; i < obj.charge_details.length; i++) {
                for (var j = 0; j < this.wardDetailList.length; j++) {
                  if (obj.charge_details[i].ward_id == this.wardDetailList[j].ward_id) {
                    this.wardDetailList[j].amount = obj.charge_details[i].amount;
                    this.wardDetailList[j].charge_id = obj.charge_details[i].bill_charge_id;
                  }
                }
                if (this.department == obj.charge_details[i].department_id && this.sub_department == obj.charge_details[i].ip_op && obj.charge_details[i].auto_generate === '1') {
                  this.autoGen = true;
                } else {
                  this.autoGen = false;
                }  
                if (this.department == obj.charge_details[i].department_id && this.sub_department == obj.charge_details[i].ip_op && obj.charge_details[i].charge_base != undefined) {
                  this.charge_base =  obj.charge_details[i].charge_base;       
                }
                if (this.department == obj.charge_details[i].department_id && this.sub_department == obj.charge_details[i].ip_op && obj.charge_details[i].charge_cat_id != undefined) {
                  this.charge_category =  obj.charge_details[i].charge_cat_id;       
                }
              }
            }       
          } else {
              if (obj.charge_details != undefined &&  obj.charge_details.length != 0) {
                for (var i = 0; i < obj.charge_details.length; i++) {
                  this.chargeId = obj.charge_details[i].bill_charge_id;
                  this.chargeAmount = obj.charge_details[i].amount;
                  this.charge_base = obj.charge_details[i].charge_base;
                  this.charge_category = obj.charge_details[i].charge_cat_id;
                  if (obj.charge_details[i].auto_generate === '1') {
                    this.autoGen = true;
                  } else {
                    this.autoGen = false;
                  }
                }
              }                 
          }
        },
        (error) => {
          this.toastr.error(Message_data.network);
        }
      );
  }

  finekeyInArray_billaddChargeType(array, charge_id) {
    var flag = false;
    for (var i = 0; i < array.length; i++) {
      if (array[i].charge_id == charge_id) {
        flag = true;
        break;
      }
    }
    return flag;
  }

  changeChargeType1(chargeDesc) {  
    this.chargeDesc = chargeDesc;
    this.serviceType = [];
  }

  pageLimitnext() {
    if (this.currentPage == this.noOfPages) {
      this.nextPageCount = true;
    } else if (this.currentPage == 1 && this.pagelist == "full") {
      this.nextPageCount = true;
    } else {
      this.currentPage = parseInt(this.currentPage);
      this.pageSize = this.pagelist; 
      this.prevPageCount = false;
      this.currentLimit = (parseInt(this.currentPage)*parseInt(this.pagelist))+1;
      this.getChargeTypes();   
      this.currentPage = this.currentPage +1;
    }     
  }

  pageLimitPrevious() {
    if (this.currentPage == "1") {
      this.prevPageCount = true;
    } else {
      this.currentPage = this.currentPage - 1;
      this.pageSize = this.pagelist;   
      if (this.currentPage != 1)
        this.currentLimit = ((parseInt(this.currentPage) - 1)*parseInt(this.pagelist))+1;
      else if (this.currentPage == 1)
        this.currentLimit = 1;
      this.getChargeTypes();
      this.nextPageCount = false;
    }    
  }

  changePageList() {
    this.currentLimit = 1;
    this.pageSize = this.pagelist;  
    this.currentPage = 1;
    this.getChargeTypes();
    if (this.pagelist == "25") {
      if (this.clientCount > 25) {
        this.pageNavigate = false;
        this.nextPageCount = false;
        this.prevPageCount = false;
      } else {
        this.pageNavigate = false;
        this.nextPageCount = false;
        this.prevPageCount = false;
      }
    } else if (this.pagelist == "50") {
      if (this.clientCount > 50) {
        this.pageNavigate = false;
        this.nextPageCount = false;
        this.prevPageCount = false;
      } else {
        this.pageNavigate = true;
        this.nextPageCount = true;
        this.prevPageCount = true;
      }
    } else if (this.pagelist == "100") {
      if (this.clientCount > 100) {
        this.pageNavigate = false;
        this.nextPageCount = false;
        this.prevPageCount = false;
      } else {
        this.pageNavigate = true;
        this.nextPageCount = true;
        this.prevPageCount = true;
      }
    }   
  }

  getChargeTypes() { 
    this.chargeList = [];
    var send_data;
    if (this.charge_desc != undefined && this.charge_desc.length > 2) {
        send_data = {
          hptl_clinic_id: this.hospId,
          charge_name: this.charge_desc
        }
    } else {
        send_data = {
          hptl_clinic_id: this.hospId,
          limit: this.pagelist,
          sno: this.currentLimit,
        }  
    }   
      
    console.log('send_data',send_data);
    this.chargeList = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(
        ipaddress.getIp.toString() + 'bill/gchgtypes/',send_data,
        { headers: headers }
      )
      .subscribe(
        (response) => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log(obj);
          if (obj != undefined) {
            this.pageNavigate = obj.charge_count > 50 ? false : true;
            this.clientCount = obj.charge_count;
            this.chargeList = [];
            if (obj.charges != undefined && obj.charges.length != 0) {
              for (var i = 0; i < obj.charges.length; i++) {
                var autoGen,ip_op,charge_base;
                if (obj.charges[i].auto_generate === "1") 
                  autoGen = "Yes";           
                else 
                  autoGen = "No";            
    
                if (obj.charges[i].ip_op === "ip") 
                  ip_op = "IP";
                else if (obj.charges[i].ip_op === "op") 
                  ip_op = "OP";
                
                if (obj.charges[i].charge_base === "1") 
                  charge_base = "Day";
                else if (obj.charges[i].charge_base === "2")
                  charge_base = "Half day";
                else if (obj.charges[i].charge_base === "3")
                  charge_base = "Hourly";
                else if (obj.charges[i].charge_base === "4")
                  charge_base = "Count";
    
                this.chargeList.push({
                  charge_id: obj.charges[i].charge_id,
                  charge_cat: obj.charges[i].charge_cat,
                  charge_desc: obj.charges[i].charge_desc,
                  charge_rate: obj.charges[i].amount,
                  department: obj.charges[i].department,
                  department_id: obj.charges[i].department_id,
                  ward_id: obj.charges[i].ward_id,
                  sub_department: ip_op,
                  ward_name: obj.charges[i].ward_name,
                  charge_base: charge_base,
                  auto_generate: autoGen
                });
              }
            }
          }         
        },
        (error) => {
          this.toastr.error(Message_data.network);
        }
      );        
  }

  numberOfPages() {   
    if (this.pagelist === "full") {
      return 1;    
    } else {
      this.noOfPages = Math.ceil(this.clientCount / this.pagelist);  
      return Math.ceil(this.clientCount / this.pagelist);
    } 
  }

  getChargeBases() {
    this.charge_bases_array = [];
    var send_data = {
      hptl_clinic_id: this.hospId  
    }
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(
        ipaddress.getIp.toString() + 'mas/ghcb',send_data,
        { headers: headers }
      )
      .subscribe(
        (response) => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log(obj); 
          if (obj.charge_bases != undefined && obj.charge_bases.length != 0) {
            for (var i = 0; i < obj.charge_bases.length; i++) {
              this.charge_bases_array.push({
                charge_base: obj.charge_bases[i].charge_base,
                charge_base_id: obj.charge_bases[i].charge_base_id
              })
            } 
            this.charge_base = this.charge_bases_array[3].charge_base_id;
          }                
        },
        (error) => {
          this.toastr.error(Message_data.network);
        }
      );        
  }
  
  setServiceCharge(charge: number, index: number) {
    var flag = true;
    if (this.wardDetailList != undefined && this.wardDetailList.length != 0) {
      for (var i = 0;i < this.wardDetailList.length; i++) {
        if (this.wardDetailList[i].charge_id != undefined) {
          flag = false;
        }       
      }
      if (flag == true) {
        for (var i = 0;i < this.wardDetailList.length; i++) {
          if (index == 0 && this.wardDetailList[0].amount != undefined) {
            this.wardDetailList[i].amount = charge;
          }  
        }
      }    
    }        
  }

  deleteCharge(id) {
    this.chargeList = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(
        ipaddress.getIp.toString() + 'bill/dchg/',
        {
          provider: Helper_Class.getInfo().user_id,
          hptl_clinic_id: this.hospId,
          charge_id: id,
        },
        { headers: headers }
      )
      .subscribe(
        (response) => {
          var obj = JSON.parse(JSON.stringify(response));
          this.toastr.success('Service charge deleted successfully');
          this.changePageList();
        },
        (error) => {
          this.toastr.error(Message_data.network);
        }
      );
  }
}