
<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Appointments report</h5>
                    </div>
                    <div class="headerButtons" *ngIf="dataList.length != 0">
                        <mat-label class="matlabel"><br>
                            <img src="../../../assets/ui_icons/buttons/back_icon.svg"
                                style="width:30px;margin-right: 5px;" (click)="todisplaycoverdiv()" *ngIf="backbutton" />

                                <a style="margin:0 5px;" (click)="print_area()"><img src="../../../assets/img/printer.svg"
                                  class="backimgbtn_inpatinfo" style="width: 25px; height: 25px;" *ngIf="backbutton" /></a>
                         
                                    <img src="../../../assets/ui_icons/buttons/XL_export.svg" class="saveimgbtn_inpatinfo" style="margin-right:10px" (click)="getExcelData('excel')"  *ngIf="backbutton"/>

                        </mat-label>
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
              
                <div class="cover_div" style="padding-bottom: 13px;" *ngIf="forcoverdiv">
                    <div class="header_lable">
                        Day Book Details
                    </div>
                    <div class="content_cover">
                        <div class="row">
                          
                            

                            <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 select_bottom">
                                <mat-label class="matlabel">Type<br>
                                    <select [(ngModel)]="filtduration" class="ipcss">
                                        <option value="select">Select</option>
                                        <option value="all">All</option>
                                        <option value="follow up">Follow up</option>
                                        <option value="new">New</option>
                                    </select>
                                </mat-label>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2">
                                <mat-label class="matlabel" style="margin-top: 7px;">From
                                  <input type="date" class="ipcss_date " (change)="selectDate(fromDate,'1')" [(ngModel)]="fromDate"
                                    max="{{currentDate}}" #matInput style="width: 140px;">
                                </mat-label>
                              </div>

                              <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2">
                                <mat-label class="matlabel" style="margin-top: 7px;">To
                                  <input type="date" class="ipcss_date " (change)="selectDate(toDate,'2')" [(ngModel)]="toDate"
                                    #matInput style="width: 140px;">
                                </mat-label>
                              </div>
                            
                           
                            
                            <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2">
                                <mat-label class="matlabel"><br>
                                    <img src="../../../assets/ui_icons/buttons/search_button.svg"
                                        class="saveimgbtn_inpatinfo" (click)="getSalesData()" />
                                </mat-label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12">

                  <div [hidden]="true">
                    <table style="margin-left:10px;margin-right: 10px;" #printimage id="printimage">
                      <tr>
                        <td>
                      
                          <img alt="image" src={{hospital_logo}} style="width: 95%; height: 130px;">
                        </td>
                      </tr>
                    </table>
                    <div style="position: relative;top: 20px;left: 10px;text-align: left;" #hospitalwithnamelogo
                      id="hospitalwithnamelogo">
                      <span><b>{{hosp_name}}</b>,</span><br />
                      <span *ngIf="hosp_addr != ''">{{hosp_addr}}</span><br />
                      <span>{{hosp_csz}}</span><br />
                      <span *ngIf="hosp_tel != undefined"><img src="../../../assets/img/phone.png" width="15px" height="15px" />{{hosp_tel}}.</span><br>
                    </div>
                  </div>
                  <p class="nodata" *ngIf="dataList.length == 0">No records found</p>
                <div class="table-responsive cover_div"
                [ngStyle]="{'width': applystyle ? '100%' : '50%','height': applyheight ? '65vh':'65vh'}" *ngIf="dataList.length != 0">
      
                <table #tbl id="tbl" style="font-size: 12px; width: 95%;">
                  <thead>
                    <tr>
                      <th>S.no</th>
                      <th>Name</th>
                      <th>MR NO</th>
                      <th>Age</th>
                      <th>Gender</th>
                      <th>Mobile no</th>
                      <th>Appointment id</th>
                      <th>Type</th>
                      <th>Doctor name</th>
                      <th>Refered by</th>
                      <th>Session</th>
                    <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let sales of dataList">
                      <td>{{sales.sno}}</td>
                      <td>{{sales.name}}</td>
                      <td>{{sales.mr_no}}</td>
                      <td>{{sales.age}}</td>
                      <td>{{sales.gender}}</td>
                      <td>{{sales.mobile}}</td>
                      <td>{{sales.appointment_id}}</td>
                      <td>{{sales.app_type}}</td>
                      <td>{{sales.doc_name}}</td>
                      <td>{{sales.refered_by}}</td>
                      <td>{{sales.session}}</td>
                      <td>{{sales.appointment_date  | date: 'dd-MM-yyyy'}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              </div>

            </mdb-card-body>
        </mdb-card>
    </div>
</div> 
