import { Component, OnInit, ViewChild } from '@angular/core';
import { Http, Headers } from '@angular/http';
// import { ipaddress.getIp, ipaddress.Ip_with_img_address, country_code } from '../../../assets/js/ipaddress';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Helper_Class } from '../../helper_class';
import { Date_Formate, Time_Formate_Session } from '../../../assets/js/common';
import { Location } from '@angular/common';
import { Message_data } from '../../../assets/js/Message_data';
import { ToastrService } from 'ngx-toastr';
import { Nurse_Helper } from '../Nurse_Helper';
import { NurseService } from '../nurse-view/nurse.service';
import { ipaddress } from '../../ipaddress' ;import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
@Component({
  selector: 'app-nurse-doc-list',
  templateUrl: './nurse-doc-list.component.html',
  styleUrls: ['./nurse-doc-list.component.scss']
})
export class NurseDocListComponent implements OnInit {
  public currentURL: string;
  public sendData;
  public doctorArray=[];
  loader: boolean;
  public userInfo;
  public userID;
  public homeCare;
  is_admin: boolean = false;
  hptl_clinic_id: any;
  doctorid: any;
  NursenameList: any[];
  constructor(public http: Http, public toastr: ToastrService, public location: Location, public messageService: MenuViewService) { }
  ngOnInit() {
    this.doctorArray = [];
    this.currentURL = ipaddress.getIp + "appointment/docbynur/";
    this.loader = false;
    this.userInfo = Helper_Class.getInfo();
    this.userID = this.userInfo.user_id;
    this.homeCare = "0";
    this.changeProviderType();
    this.getdoctoravalable();
  }
  changeProviderType() {

    if (Helper_Class.getInfo().user_type == 'Admin') {
      this.hptl_clinic_id = Helper_Class.getInfo().hptl_clinic_id;
      this.is_admin = true;
      this.getNurseList();
    }

  }
  changeNurse() {
    this.userID = this.doctorid;
    this.getdoctoravalable();
    
  }
  getNurseList() {
    this.NursenameList = [];
    var request_data = {
      center_id: this.hptl_clinic_id,
      type: "3"
    };
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http
      .post(ipaddress.getIp.toString() + 'adm/guth', JSON.stringify(request_data), { headers: headers })
      .subscribe(
        (response) => {
          var obj = JSON.parse(response["_body"]);
          console.log('check---' + JSON.stringify(obj));
          if (obj != null) {
            for (var i = 0; i < obj.users.length; i++) {
              var NurseName = '';
              var fname, mname, lname;
              if (
                obj.users[i].middle_name != '' &&
                obj.users[i].middle_name != undefined
              ) {
                NurseName =
                  obj.users[i].first_name +
                  ' ' +
                  obj.users[i].middle_name +
                  ' ' +
                  obj.users[i].last_name;
                fname = obj.users[i].first_name;
                mname = obj.users[i].middle_name;
                lname = obj.users[i].last_name;
              } else {
                NurseName =
                  obj.users[i].first_name + ' ' + obj.users[i].last_name;
                fname = obj.users[i].first_name;
                lname = obj.users[i].last_name;
              }
              this.NursenameList.push({
                NurseName: NurseName,
                Nursedata_id: obj.users[i].user_id,

              });
              
            }
          }
          console.log(JSON.stringify(this.NursenameList))
        },
        (error) => {

        }
      );
      
  }

  getdoctoravalable(){
    this.sendData = {
      nurse_reg_id: this.userID,
      imei: this.userInfo.imei_no
    }
    console.log("check data --"+JSON.stringify(this.sendData))
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.currentURL, JSON.stringify(this.sendData), { headers: headers }).subscribe(
      response => {
        this.doctorArray = [];
        var data = JSON.parse(response["_body"]);
        console.log("data data --"+JSON.stringify(data))
        if (data.doctors.length != 0) {
          this.loader = true;
          this.doctorArray = [];
          for (var i = 0; i < data.doctors.length; i++) {
            var Docname = "";
            if (data.doctors[i].middle_name != undefined && data.doctors[i].middle_name != "") {
              Docname = data.doctors[i].first_name + " " + data.doctors[i].middle_name + " " + data.doctors[i].last_name;
            }
            else {
              Docname = data.doctors[i].first_name + " " + data.doctors[i].last_name;
            }
            this.doctorArray.push({
              Prof_image: ipaddress.Ip_with_img_address + data.doctors[i].profile_image,
              Doctor_Name: Docname,
            });
          }
        }else{
          this.doctorArray = [];
        }
      },
      error => {
        this.loader = true;
      })
  }
}
