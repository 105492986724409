import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Date_Formate, Time_Formate } from '../../../assets/js/common';
import { ipaddress } from '../../ipaddress';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Message_data } from '../../../assets/js/Message_data';
import { Master_Helper } from '../Master_Helper';
import { FrontDesk_Helper } from 'src/app/FrontDesk_module/FrontDesk_Helper';
declare var $: any;

@Component({
  selector: 'app-consolidated-bills',
  templateUrl: './consolidated-bills.component.html',
  styleUrls: ['./consolidated-bills.component.scss']
})
export class ConsolidatedBillsComponent implements OnInit {
  @ViewChild('printlogowithname') printlogowithname: ElementRef;
  @ViewChild('printbanner') printbanner: ElementRef;
  @ViewChild('printnoheader') printnoheader: ElementRef;
  @ViewChild('printheader') printheader: ElementRef;
  dtOptions: any = {};
  public adv_refflag:boolean=false;
  public payment_mode;
  public currentMaxDate;
  public location;
  public name;
  public selectvalue;
  format: '' | 'CODE128' | 'CODE128A' | 'CODE128B' | 'CODE128C' | 'EAN' | 'UPC' | 'EAN8' | 'EAN5' | 'EAN2' | 'CODE39' | 'ITF14' | 'MSI' | 'MSI10' | 'MSI11' | 'MSI1010' | 'MSI1110' | 'pharmacode' | 'codabar';
  public patient_selectionflag: boolean = true;
  public tabular_format: boolean = true;
  public view_type;
  public graphicalformat: boolean = false;
  public graph_defaultimg: boolean = false
  public doctorList=[];
  public doctorName;
  public doctor;
  public floorarray=[];
  public count;
  public discountvaluecheck = 0.00;
  // public paidBill = '0.00';
  public listview_hidden:boolean=false;
  public remainingbalance_advance:any;
  public totalAdvance_bills=0;
  public totalPaidAmountvalue = "";
  public totalBalanceAmountvalue = "";
  public totalBillAmountvalue = "";
  public totalDiscountvalue = "";
  public total_advancevalue = '';
  public discount_rembalance = 0; 
  public paid_bill1;
  elementType: 'svg';
  lineColor = '#000000';
  width = 0.8;
  barcodeheight = 20;
  displayValue = true;
  fontOptions = '';
  font = 'monospace';
  textAlign = 'center';
  textPosition = 'bottom';
  textMargin = 2;
  fontSize = 15;
  background = '#ffffff';
  margin = 10;
  marginTop = 10;
  marginBottom = 10;
  marginLeft = 0;
  marginRight = 10;
  public inpatientflag: boolean = false; public sponsorDueflag: boolean = false;
  public paidflag: boolean = false;
  public discountflag: boolean = false;
  public advanceflag: boolean = false;
  public balanceflag: boolean = false;
  public dateshow: boolean = false;
  public agegenderflag: boolean = false;
  public admitted_timevalue;
  public mr_no;
  public mobile;
  public Filter_based;
  public mrnoflag;
  public nameflag;
  public locationflag;
  public mobileflag;
  public fdateflag;
  public todateflag;
  public fromDate;
  public toDate;
  public agegendervalue;
  public mrno;
  public remaining_billbal;
  public reaminingflafzero: boolean = false
  public inpatientId
  public insuranceflag1: boolean;
  public ins_card_no;
  public ward_name;
  public bedno;
  public clientnamenew; public mobilenumber; public doctorname; inpatientidnew;
  public age;
  public gender;
  public admitted_date;
  public admitted_time;
  public combinedArray = [];
  public approved_amount = "0.00";
  public Insuranceflag: boolean = false;
  public Filter_txt;
  public sponsorDue;
  public cnnno
  public From_show: boolean = true;
  public To_show: boolean = true;
  public f_date;
  public t_date;
  public userinfo;
  public appoint_txt;
  public hptl_clinic_id;
  public appointment_list: any = [];
  public currentDate;
  public CurrentDatetime;
  public Appoint_Date;
  public bill_date;
  public Docidname;
  public hospital_name;
  public hospitalAddress;
  public Hospital_location: string;
  public hosp_location: string;
  public hosp_district: string;
  public hosp_state: string;
  public hosp_zip: string;
  public hosp_telephone: string;
  public bill_type;
  public docBills = [];
  public medBills = [];
  public diagBills = [];
  public DisplayBills = [];
  public final_amount = "0.00";
  public Advance_bill = "0.00";
  public total_advance = "0.00";
  public balance_show: boolean = true;
  public paid_bill = "0.00";
  public temppaid_bill = "0.00";
  public remaing_billbal = "0.00";
  public sendDoctor: any = [];
  public sendPharmacy: any = [];
  public sendDiagnosis: any = [];
  public pharma_id;
  public diag_centre_id;
  public tempPaid = "0.00";
  public totalBillAmount = "0.00";
  public totalPaidAmount = "0.00";
  public TotalBalance = "0.00";
  public selectedAllFlage: boolean = false;
  public patient_name;
  public amount_collected;
  public previous_balshow;
  public balance_data;
  public dog_reg_id;
  public total_collected;
  public billing_print_array: any = [];
  public insuranceFlag: boolean = false;
  public insuranceDec;
  public hospital_logo;
  public mediBillAmount = "0.00";
  public client_id;
  public sub_id;
  public rel_id;
  public tempparray;
  public saveFlag: boolean = true;
  public totalDiscount = "0.00";
  public totalBill = "0.00";
  public totalPaid = "0.00";
  public patientList = []
  public admissionDate = "";
  public admissionTime = "";
  public dischargeDate = "";
  public dischargeTime = "";
  public app_id;
  public inpid;
  public insurer;
  public insCardNo;
  public policyno;
  public CCNno;
  public round_off;
  public print_template;
  public headerstyle;
  public paytypreq;
  public docBillDetails: any = [];
  public medcalDetailsArray: any = [];
  public digDetailsArray: any = [];
  public paymentsarray: any = [];
  public paytypelist: any = [];
  public printlogostyle;
  public bannerimage;
  public payType1;
  public amountval:any;
  public tamt: number = 0;
  public pay_type: string;
  public barcode_url: any;
  public barcode_flag: boolean = false;
  public docBillSubtotal: any;
  public diagBillSubtotal: any;
  public medBillSubtotal: any;
  public specialization_name: any;
  public rate_plan: any;
  public bill_qty_flag:boolean;
  public service_style: string;
  public date_style: string;
  public quant_style: string;
  public service_td_style: string;
  public date_td_style: string;
  public quant_td_style: string;
  public Amt_style: string;
  public amt_td_style: string;
  public subtotal_style: string;
  public not_ins_amtStyle: string;
  public not_ins_td_amtStyle: string;
  public not_ins_serviceStyle: string;
  public not_ins_dateStyle: string;
  public surgery_name:string;
  public printFontSize: any;
  public sno_td_style: string;
  public sno_style: string;
  public print_font:string;
  public bill_no:string;
  public pay_type_desc:string;
  public doc_qualif: any;
  public is_insurance: boolean;
  public floorvalue;
  public filterbasedurl;
  public refund_amount: any =undefined;
  public refund_reason: any;
  public table_style: string;
  public header_style: string;
  public categoryChargeList: any;
  public doctor_subtotal_style: string;

  constructor(public sanitize: DomSanitizer, public doctorservice: MenuViewService,
    public toastr: ToastrService, public http: Http, public router: Router,
    public messageservice: MenuViewService,public cdr: ChangeDetectorRef) { }

  async ngOnInit() {
    this.totalBillAmountvalue = "0.00";
    this.refund_amount = undefined;
    this.refund_reason = '';
    this.adv_refflag = false;
    this.dog_reg_id = "All";
    this.doctor = "All";
    this.selectvalue = "mrno";
    this.floorvalue = "All";
    this.view_type = "graph";
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      iDisplayLength: 2,
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      "order": [[2, 'asc']],
      "columnDefs": [       
        { "type": "date", "orderable": false, "targets": [-1] },      
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search patients"
      },     
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    this.filterbasedurl = ipaddress.getIp + "inpat/gibf ";
    this.remaining_billbal = 0.00;
    this.userinfo = Helper_Class.getInfo();
    console.log(this.userinfo);
    if (this.userinfo.user_type != "doctor" && this.userinfo.user_type != "nurse") {
      if (this.userinfo.bill_qty_flag != undefined) {
        if (this.userinfo.bill_qty_flag == 0) 
          this.bill_qty_flag = true;
        else 
          this.bill_qty_flag = false;
      }
    } else {
      if (this.userinfo.hospitals[0].bill_qty_flag != undefined) {
        if (this.userinfo.bill_qty_flag == 0) 
          this.bill_qty_flag = true;
        else 
          this.bill_qty_flag = false;       
      }
    }
   
    this.pay_type = "Cash";
    this.Filter_based = "mrno";
    if (this.userinfo.user_type == "front-desk") {
      this.print_template = this.userinfo.cons_bill_template;
      this.print_font = this.userinfo.print_font_size;
      this.printlogostyle = Helper_Class.getInfo().bill_print_logo_style;
      this.hospital_logo = ipaddress.Ip_with_img_address + Helper_Class.getInfo().bill_print_logo;
    } else {
      this.print_template = this.userinfo.cons_bill_template;
      this.print_font=this.userinfo.print_font_size;
      this.printlogostyle = Helper_Class.getInfo().hospitals[0].bill_print_logo_style;
      this.hospital_logo = ipaddress.Ip_with_img_address + Helper_Class.getInfo().hospitals[0].bill_print_logo;
      console.log("print_template",this.print_template);
    }

    this.headerstyle = this.sanitize.bypassSecurityTrustHtml(Helper_Class.getInfo().hospitals[0].bill_print_heading);
    if (this.headerstyle == undefined || this.headerstyle == 'undefined') {
      this.headerstyle = "";
    }

    if (this.userinfo.user_type == "front-desk") {
      if (this.userinfo.bill_pay_type_req == "1") 
        this.paytypreq = false;
      else 
        this.paytypreq = true;     
    } else {
      if (this.userinfo.hospitals[0].bill_pay_type_req == "1") 
        this.paytypreq = false;
      else 
        this.paytypreq = true;     
    }

    if (this.userinfo.hospitals[0].user_type == 'Admin') {
      this.hptl_clinic_id = this.userinfo.hptl_clinic_id;
    }
    else {
      var hospitaldet = Helper_Class.getHospital();
      this.hptl_clinic_id = hospitaldet[0].hptl_clinic_id;
    }
    // get the appointment list
    this.bill_type = "doctor";
    this.Filter_txt = "All";
    this.Filter_change();
    this.Filter_changename(this.Filter_based);
    this.Get_current_date();
    this.getPaymentType();
    this.getDoctors();
    this.getPatients();
    this.viewChange(this.view_type);
    this.Filterbydata(this.selectvalue);
    if (this.userinfo.print_table_color != undefined && this.userinfo.print_font_size != undefined)
      this.setPrint_styles(this.userinfo.print_table_color, this.userinfo.print_font_size);
    else 
      this.setPrint_styles('#eeeeee', "11px");   
  }

  Filter_change() {
    if (this.Filter_txt == "All") {
      this.From_show = true;
      this.To_show = true;
    } else if (this.Filter_txt == "Date") {
      this.From_show = false;
      this.To_show = false;
      this.dateshow = true;
    }
  }

  getPaymentType() {
    var obj = Master_Helper.getMasterPaymentType();
    this.paytypelist = obj.payments;
    this.pay_type = this.paytypelist[0].pay_id;
  }

  Get_current_date() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
      JSON.stringify({
        country: ipaddress.country_code.toString()
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          if (obj.current_date != null) {
            this.CurrentDatetime = obj.current_date;
            var get_date = obj.current_date.split('-');
            this.Appoint_Date = get_date[2] + "-" + get_date[1] + "-" + get_date[0];
            this.currentDate = obj.current_date;
            this.bill_date = Date_Formate(obj.current_date);
            this.Appoint_Date = this.CurrentDatetime;
            this.f_date = obj.current_date;
            this.t_date = obj.current_date;
            this.currentMaxDate = this.currentDate;
          }
        },
        error => {
          this.toastr.error("Internet is too slow or no connection, try again");
        }
      )
  }

  OnDateChangedfrom(e) {
    this.f_date = e;
  }

  OnDateChangedto(e) {
    this.t_date = e;
  }

  async filter_click() {
    this.Insuranceflag = true;
    this.Appointment_change(this.client_id, this.rel_id, this.sub_id, this.tempparray, this.tempparray.approved_amount)
  }

  getAppointments(patientList, name,select) {
    this.appointment_list = [];
    this.patientList = [];
    var send_data;
    if (select == "select") {
      if (name == 'name') {
        if (patientList.target.value.toString() != null && patientList.target.value.toString().length > 2) {
          var headers = new Headers();
          var name = patientList.target.value.toUpperCase();
          send_data = JSON.stringify({
            hptl_clinic_id: this.hptl_clinic_id,
            first_name: name
          });
          console.log("PATIENTDETAILS ___" + JSON.stringify(send_data));
          headers.append('Content-Type', 'application/json');
          this.http.post(ipaddress.getIp.toString() + 'bill/inpa', send_data,
            { headers: headers })
            .subscribe(
              response => {
                this.appointment_list = [];
                var obj = response.json();
                console.log("PATIENTDETAILS ___" + JSON.stringify(obj));
                this.insuranceFlag = false;
                if (obj.appointment != null) {
                  for (var i = 0; i < obj.appointment.length; i++) {
                    if (obj.appointment[i].middle_name != undefined) {
                      this.Docidname = obj.appointment[i].doc_app_id + " - " + encrypt_decript.Decript(obj.appointment[i].first_name) + " " + encrypt_decript.Decript(obj.appointment[i].middle_name) + " " + encrypt_decript.Decript(obj.appointment[i].last_name) + " (" + Date_Formate(obj.appointment[i].admitted_date) + ")";
                    } else {
                      this.Docidname = obj.appointment[i].doc_app_id + " - " + encrypt_decript.Decript(obj.appointment[i].first_name) + " " + encrypt_decript.Decript(obj.appointment[i].last_name) + " (" + Date_Formate(obj.appointment[i].admitted_date) + ")";
                    }
                    var doctorname;
                    if (obj.appointment[i].dr_middle_name != undefined) {
                      doctorname = obj.appointment[i].dr_first_name + "" + obj.appointment[i].dr_middle_name + "" + obj.appointment[i].dr_last_name
                    } else {
                      doctorname = obj.appointment[i].dr_first_name + "" + obj.appointment[i].dr_last_name
                    }
                    var sessiondata = null;
                    if (obj.appointment[i].session != undefined && obj.appointment[i].session != undefined && obj.appointment[i].session != null) {
                      sessiondata = encrypt_decript.Decript(obj.appointment[i].session);
                    }
                    var address;
                    if (obj.appointment[i].address2 != undefined && obj.appointment[i].address1 != undefined) {
                      address = encrypt_decript.Decript(obj.appointment[i].address1) + ", " + encrypt_decript.Decript(obj.appointment[i].address2)
                    } else if (obj.appointment[i].address1 != undefined) {
                      address = encrypt_decript.Decript(obj.appointment[i].address1);
                    } else {
                      address = "";
                    }
                    var hospital;
                    if (obj.hosp_address2 != undefined) {
                      hospital = obj.hosp_address1 + "," + obj.hosp_address2;
                    } else {
                      hospital = obj.hosp_address1;
                    }
                    //inpat_id
                    var inpatid;
                    if (obj.appointment[i].inpat_id != undefined) {
                      this.inpatientflag = true;
                      inpatid = obj.appointment[i].inpat_id
                    } else {
                      this.inpatientflag = false;
                      if (obj.appointment[i].appointment_id != undefined) {
                        inpatid = encrypt_decript.Decript(obj.appointment[i].appointment_id);
                      }
                    }
                    var inpatid = obj.appointment[i].inpat_id != undefined ? obj.appointment[i].inpat_id : "-";
                    var inpatientId = obj.appointment[i].inp_hosp_id != undefined ? obj.appointment[i].inp_hosp_id : "-";
                    var zipcode = obj.appointment[i].zipcode != undefined ? encrypt_decript.Decript(obj.appointment[i].zipcode) : "";
                    this.hospital_name = obj.hosp_name != undefined ? obj.hosp_name : "";
                    this.hospitalAddress = hospital;
                    this.hosp_location = obj.hosp_location;
                    this.hosp_district = obj.hosp_district;
                    this.hosp_state = obj.hosp_state;
                    this.hosp_zip = obj.hosp_zip;
                    this.hosp_telephone = obj.hosp_telephone;
                    this.Hospital_location = obj.hosp_location + ", " + obj.hosp_district + " - " + obj.hosp_state + " " + obj.hosp_country + "  " + obj.hosp_zip;
                    if (obj.appointment[i].inp_hosp_id != undefined) {
                      var insurer = obj.appointment[i].insurer;
                      if (obj.appointment[i].insurer != undefined) {
                        this.Insuranceflag = true;
                        this.insuranceFlag = true;
                      }
                      if (obj.appointment[i].ins_card_no != undefined) {
                        this.ins_card_no = obj.appointment[i].ins_card_no;
                      } else {
                        this.ins_card_no = obj.appointment[i].policy_no;
                      }
                      this.cnnno = obj.appointment[i].mr_no;
                      this.approved_amount = obj.appointment[i].approved_amount;
                    }
                    var patientname, dr_first_name, age, gender;
                    if (obj.appointment[i].middle_name != undefined) {
                      patientname = encrypt_decript.Decript(obj.appointment[i].first_name) + " " + encrypt_decript.Decript(obj.appointment[i].middle_name) + " " + encrypt_decript.Decript(obj.appointment[i].last_name)
                    } else {
                      patientname = encrypt_decript.Decript(obj.appointment[i].first_name) + " " + encrypt_decript.Decript(obj.appointment[i].last_name)
                    }
                    if (obj.appointment[i].dr_middle_name != undefined) {
                      dr_first_name = (obj.appointment[i].dr_first_name) + " " + (obj.appointment[i].dr_middle_name) + "" + (obj.appointment[i].dr_last_name);
                    } else {
                      dr_first_name = (obj.appointment[i].dr_first_name) + " " + (obj.appointment[i].dr_last_name);
                    }
                    if (obj.appointment[i].age != undefined) {
                      this.age = obj.appointment[i].age;
                    }
                    if (obj.appointment[i].gender != undefined) {
                      this.gender = encrypt_decript.Decript(obj.appointment[i].gender);
                      this.gender = this.gender[0];
                      if (this.gender != undefined && this.gender[0] == 'T') {
                        this.gender = 'Tgen';
                      }
                    }
                    if (obj.appointment[i].admitted_date != undefined) {
                      this.admitted_date = obj.appointment[i].admitted_date
                    }
                    if (obj.appointment[i].admitted_time != undefined) {
                      this.admitted_time = Time_Formate(obj.appointment[i].admitted_time)
                    }
                    if (obj.specialization_name != undefined) {
                      this.specialization_name = obj.specialization_name
                    }
                    if (obj.rate_card != undefined) {
                      this.rate_plan = obj.rate_card
                    }
                    if (obj.appointment[i].bed_no != undefined) {
                      this.bedno = obj.appointment[i].bed_no
                    }
                    this.appointment_list.push({
                      doc_app_id: obj.appointment[i].doc_app_id,
                      doc_reg_id: obj.appointment[i].doc_reg_id,
                      client_reg_id: obj.appointment[i].client_reg_id,
                      relation_id: obj.appointment[i].relation_id,
                      sub_rel_id: obj.appointment[i].sub_rel_id,
                      address: address,
                      location: obj.appointment[i].location,
                      district: obj.appointment[i].district,
                      state: obj.appointment[i].state,
                      country: obj.appointment[i].country,
                      first_name: obj.appointment[i].first_name,
                      middle_name: obj.appointment[i].middle_name,
                      last_name: obj.appointment[i].last_name,
                      hptl_clinic_id: obj.appointment[i].hptl_clinic_id,
                      session: sessiondata,
                      city: obj.appointment[i].district,
                      zipcode: zipcode,
                      docid_name: this.Docidname,
                      advance: obj.appointment[i].advance,
                      balance: obj.appointment[i].balance,
                      inpatientId: inpatientId,
                      inpatid: inpatid,
                      cgst: "12%",
                      sgst: "12$",
                      admitted_date: this.admitted_date,
                      admitted_time: this.admitted_time,
                      discharge_date: obj.appointment[i].discharge_date,
                      discharge_time: obj.appointment[i].discharge_time,
                      inpid: obj.appointment[i].inp_hosp_id,
                      payment_mode: obj.appointment[i].payment_mode,
                      insurer: obj.appointment[i].insurer,
                      insCardNo: obj.appointment[i].ins_card_no,
                      policyNo: obj.appointment[i].policy_no,
                      ins_card_no: this.ins_card_no,
                      CNNno: this.cnnno,
                      bedno: obj.appointment[i].bed_no,
                      ward_name: obj.appointment[i].ward_name,
                      approved_amount: this.approved_amount,
                      mobilenuo: encrypt_decript.Decript(obj.appointment[i].mobile),
                      patientname: patientname, doctorname: dr_first_name,
                      age: this.age, gender: this.gender, patient_id: obj.appointment[i].patient_id,
                      mr_no: obj.appointment[i].mr_no, bed_no: this.bedno, salutation_desc: obj.appointment[i].salutation_desc,
                      barcode: obj.appointment[i].barcode,
                      specialization_name: this.specialization_name,
                      rate_plan: this.rate_plan,
                      surgery_desc: obj.appointment[i].surgery_desc,
                      doc_qualif: obj.appointment[i].doc_qualif,
                    });
                  }
                }
                console.log("this.appointment_list" + JSON.stringify(this.appointment_list));
              },
              error => {
                this.toastr.error("Network error, try again");
              }
            )
        }
      } else {
        if (name == "mobile") {
          console.log("send_data __*********_" + JSON.stringify(patientList.target.value.toString().length));
          if (patientList.target.value.toString() != null && patientList.target.value.toString().length > 9) {
            var headers = new Headers();
            send_data = {
              hptl_clinic_id: this.hptl_clinic_id,
              mobile: this.mobile
            };
            headers.append('Content-Type', 'application/json');
            this.http.post(ipaddress.getIp.toString() + 'bill/inpa', send_data,
              { headers: headers })
              .subscribe(
                response => {
                  var obj = response.json();
                  console.log("PATIENTDETAILS ___" + JSON.stringify(obj));
                  if (obj.appointment != null) {
                    for (var i = 0; i < obj.appointment.length; i++) {
                      obj.appointment[i].middle_name
                      if (obj.appointment[i].middle_name != undefined) {
                        this.Docidname = obj.appointment[i].doc_app_id + " - " + encrypt_decript.Decript(obj.appointment[i].first_name) + " " + encrypt_decript.Decript(obj.appointment[i].middle_name) + " " + encrypt_decript.Decript(obj.appointment[i].last_name) + " (" + Date_Formate(obj.appointment[i].admitted_date) + ")";
                      } else {
                        this.Docidname = obj.appointment[i].doc_app_id + " - " + encrypt_decript.Decript(obj.appointment[i].first_name) + " " + encrypt_decript.Decript(obj.appointment[i].last_name) + " (" + Date_Formate(obj.appointment[i].admitted_date) + ")";
                      }
                      var doctorname;
                      if (obj.appointment[i].dr_middle_name != undefined) {
                        doctorname = obj.appointment[i].dr_first_name + "" + obj.appointment[i].dr_middle_name + "" + obj.appointment[i].dr_last_name
                      } else {
                        doctorname = obj.appointment[i].dr_first_name + "" + obj.appointment[i].dr_last_name
                      }
                      var sessiondata = null;
                      if (obj.appointment[i].session != undefined && obj.appointment[i].session != undefined && obj.appointment[i].session != null) {
                        sessiondata = encrypt_decript.Decript(obj.appointment[i].session);
                      }
                      var address;
                      if (obj.appointment[i].address2 != undefined && obj.appointment[i].address1 != undefined) {
                        address = encrypt_decript.Decript(obj.appointment[i].address1) + ", " + encrypt_decript.Decript(obj.appointment[i].address2)
                      } else if (obj.appointment[i].address1 != undefined) {
                        address = encrypt_decript.Decript(obj.appointment[i].address1);
                      } else {
                        address = "";
                      }
                      var hospital;
                      if (obj.hosp_address2 != undefined) {
                        hospital = obj.hosp_address1 + "," + obj.hosp_address2;
                      } else {
                        hospital = obj.hosp_address1;
                      }
                      // /inpat_id
                      var inpatid;
                      if (obj.appointment[i].inpat_id != undefined) {
                        this.inpatientflag = true;
                        inpatid = obj.appointment[i].inpat_id
                      } else {
                        this.inpatientflag = false;
                        if (obj.appointment[i].appointment_id != undefined) {
                          inpatid = encrypt_decript.Decript(obj.appointment[i].appointment_id);
                        }
                      }
                      var inpatid = obj.appointment[i].inpat_id != undefined ? obj.appointment[i].inpat_id : "-";
                      var inpatientId = obj.appointment[i].inp_hosp_id != undefined ? obj.appointment[i].inp_hosp_id : "-";
                      var zipcode = obj.appointment[i].zipcode != undefined ? encrypt_decript.Decript(obj.appointment[i].zipcode) : "";
                      this.hospital_name = obj.hosp_name != undefined ? obj.hosp_name : "";
                      this.hospitalAddress = hospital;
                      this.hosp_location = obj.hosp_location;
                      this.hosp_district = obj.hosp_district;
                      this.hosp_state = obj.hosp_state;
                      this.hosp_zip = obj.hosp_zip;
                      this.hosp_telephone = obj.hosp_telephone;
                      this.Hospital_location = obj.hosp_location + ", " + obj.hosp_district + " - " + obj.hosp_state + " " + obj.hosp_country + "  " + obj.hosp_zip;
                      if (obj.appointment[i].inp_hosp_id != undefined) {
                        var insurer = obj.appointment[i].insurer;
                        if (obj.appointment[i].insurer! = undefined) {
                          this.Insuranceflag = true;
                          this.insuranceFlag = true;
                        }
                        if (obj.appointment[i].ins_card_no != undefined) {
                          this.ins_card_no = obj.appointment[i].ins_card_no;
                        } else {
                          this.ins_card_no = obj.appointment[i].policy_no;
                        }
                        this.cnnno = obj.appointment[i].mr_no;
                        this.approved_amount = obj.appointment[i].approved_amount;
                      }
                      var patientname, dr_first_name, age, gender;
                      if (obj.appointment[i].middle_name != undefined) {
                        patientname = encrypt_decript.Decript(obj.appointment[i].first_name) + " " + encrypt_decript.Decript(obj.appointment[i].middle_name) + "" + encrypt_decript.Decript(obj.appointment[i].last_name)
                      } else {
                        patientname = encrypt_decript.Decript(obj.appointment[i].first_name) + " " + encrypt_decript.Decript(obj.appointment[i].last_name)
                      }
                      if (obj.appointment[i].dr_middle_name != undefined) {
                        dr_first_name = (obj.appointment[i].dr_first_name) + " " + (obj.appointment[i].dr_middle_name) + "" + (obj.appointment[i].dr_last_name);
                      } else {
                        dr_first_name = (obj.appointment[i].dr_first_name) + " " + (obj.appointment[i].dr_last_name);
                      }
                      if (obj.appointment[i].age != undefined) {
                        this.age = obj.appointment[i].age;
                      }
                      if (obj.appointment[i].gender != undefined) {
                        this.gender = encrypt_decript.Decript(obj.appointment[i].gender);
                        this.gender = this.gender[0];
                        if (this.gender != undefined && this.gender[0] == 'T') {
                          this.gender = 'Tgen';
                        }
                      }
                      if (this.age != undefined && this.gender != undefined) {
                        this.agegenderflag = true;
                        this.agegendervalue = this.age + "/" + this.gender;
                      }
                      if (obj.appointment[i].admitted_date != undefined) {
                        this.admitted_date = obj.appointment[i].admitted_date
                      }
                      if (obj.appointment[i].admitted_time != undefined) {
                        this.admitted_time = obj.appointment[i].admitted_time
                      }
                      if (obj.appointment[i].bed_no != undefined) {
                        this.bedno = obj.appointment[i].bed_no
                      }
                      if (obj.specialization_name != undefined) {
                        this.specialization_name = obj.specialization_name
                      }
                      if (obj.rate_card != undefined) {
                        this.rate_plan = obj.rate_card
                      }
                      this.appointment_list.push({
                        doc_app_id: obj.appointment[i].doc_app_id,
                        doc_reg_id: obj.appointment[i].doc_reg_id,
                        client_reg_id: obj.appointment[i].client_reg_id,
                        relation_id: obj.appointment[i].relation_id,
                        sub_rel_id: obj.appointment[i].sub_rel_id,
                        address: address,
                        location: obj.appointment[i].location,
                        district: obj.appointment[i].district,
                        state: obj.appointment[i].state,
                        country: obj.appointment[i].country,
                        first_name: obj.appointment[i].first_name,
                        middle_name: obj.appointment[i].middle_name,
                        last_name: obj.appointment[i].last_name,
                        hptl_clinic_id: obj.appointment[i].hptl_clinic_id,
                        session: sessiondata,
                        city: obj.appointment[i].district,
                        zipcode: zipcode,
                        docid_name: this.Docidname,
                        advance: obj.appointment[i].advance,
                        balance: obj.appointment[i].balance,
                        inpatientId: inpatientId,
                        inpatid: inpatid,
                        cgst: "12%",
                        sgst: "12$",
                        admitted_date: this.admitted_date,
                        admitted_time: this.admitted_time,
                        discharge_date: obj.appointment[i].discharge_date,
                        discharge_time: obj.appointment[i].discharge_time,
                        inpid: obj.appointment[i].inp_hosp_id,
                        payment_mode: obj.appointment[i].payment_mode,
                        insurer: obj.appointment[i].insurer,
                        insCardNo: obj.appointment[i].ins_card_no,
                        policyNo: obj.appointment[i].policy_no,
                        ins_card_no: this.ins_card_no,
                        CNNno: this.cnnno,
                        bedno: obj.appointment[i].bed_no,
                        ward_name: obj.appointment[i].ward_name,
                        approved_amount: this.approved_amount,
                        mobilenuo: encrypt_decript.Decript(obj.appointment[i].mobile),
                        patientname: patientname, doctorname: dr_first_name,
                        age: this.age, 
                        gender: this.gender, 
                        patient_id: obj.appointment[i].patient_id,
                        mr_no: obj.appointment[i].mr_no, 
                        bed_no: this.bedno, 
                        salutation_desc: obj.appointment[i].salutation_desc,
                        barcode: obj.appointment[i].barcode,
                        surgery_desc: obj.appointment[i].surgery_desc,
                      });
                    }
                  }
                  console.log("this.appointment_list" + JSON.stringify(this.appointment_list));
                },
                error => {
                  this.toastr.error("Network error, try again");
                }
              )
          }
        }
        if (name == "mrno") {
          console.log("mr_no __*********_" + JSON.stringify(patientList.target.value.toString().length));
          if (patientList.target.value.toString() != null && patientList.target.value.toString().length > 8) {
            var headers = new Headers();
            send_data = {
              hptl_clinic_id: this.hptl_clinic_id,
              mr_no: this.mr_no
            };
            headers.append('Content-Type', 'application/json');
            this.http.post(ipaddress.getIp.toString() + 'bill/inpa', send_data,
              { headers: headers })
              .subscribe(
                response => {
                  var obj = response.json();
                  console.log("PATIENTDETAILS ___" + JSON.stringify(obj));
                  if (obj.appointment != null) {
                    for (var i = 0; i < obj.appointment.length; i++) {
                      obj.appointment[i].middle_name
                      if (obj.appointment[i].middle_name != undefined) {
                        this.Docidname = obj.appointment[i].doc_app_id + " - " + encrypt_decript.Decript(obj.appointment[i].first_name) + " " + encrypt_decript.Decript(obj.appointment[i].middle_name) + " " + encrypt_decript.Decript(obj.appointment[i].last_name) + " (" + Date_Formate(obj.appointment[i].admitted_date) + ")";
                      } else {
                        this.Docidname = obj.appointment[i].doc_app_id + " - " + encrypt_decript.Decript(obj.appointment[i].first_name) + " " + encrypt_decript.Decript(obj.appointment[i].last_name) + " (" + Date_Formate(obj.appointment[i].admitted_date) + ")";
                      }
                      var doctorname;
                      if (obj.appointment[i].dr_middle_name != undefined) {
                        doctorname = obj.appointment[i].dr_first_name + "" + obj.appointment[i].dr_middle_name + "" + obj.appointment[i].dr_last_name
                      } else {
                        doctorname = obj.appointment[i].dr_first_name + "" + obj.appointment[i].dr_last_name
                      }
                      var sessiondata = null;
                      if (obj.appointment[i].session != undefined && obj.appointment[i].session != undefined && obj.appointment[i].session != null) {
                        sessiondata = encrypt_decript.Decript(obj.appointment[i].session);
                      }
                      var address;
                      if (obj.appointment[i].address2 != undefined && obj.appointment[i].address1 != undefined) {
                        address = encrypt_decript.Decript(obj.appointment[i].address1) + ", " + encrypt_decript.Decript(obj.appointment[i].address2)
                      } else if (obj.appointment[i].address1 != undefined) {
                        address = encrypt_decript.Decript(obj.appointment[i].address1);
                      } else {
                        address = "";
                      }
                      var hospital;
                      if (obj.hosp_address2 != undefined) {
                        hospital = obj.hosp_address1 + "," + obj.hosp_address2;
                      } else {
                        hospital = obj.hosp_address1;
                      }
                      //inpat_id
                      var inpatid;
                      if (obj.appointment[i].inpat_id != undefined) {
                        this.inpatientflag = true;
                        inpatid = obj.appointment[i].inpat_id
                      } else {
                        this.inpatientflag = false;
                        if (obj.appointment[i].appointment_id != undefined) {
                          inpatid = encrypt_decript.Decript(obj.appointment[i].appointment_id);
                        }
                      }
                      var inpatid = obj.appointment[i].inpat_id != undefined ? obj.appointment[i].inpat_id : "-";
                      var inpatientId = obj.appointment[i].inp_hosp_id != undefined ? obj.appointment[i].inp_hosp_id : "-";
                      var zipcode = obj.appointment[i].zipcode != undefined ? encrypt_decript.Decript(obj.appointment[i].zipcode) : "";
                      this.hospital_name = obj.hosp_name != undefined ? obj.hosp_name : "";
                      this.hospitalAddress = hospital;
                      this.hosp_location = obj.hosp_location;
                      this.hosp_district = obj.hosp_district;
                      this.hosp_state = obj.hosp_state;
                      this.hosp_zip = obj.hosp_zip;
                      this.hosp_telephone = obj.hosp_telephone;
                      this.Hospital_location = obj.hosp_location + ", " + obj.hosp_district + " - " + obj.hosp_state + " " + obj.hosp_country + "  " + obj.hosp_zip;
                      if (obj.appointment[i].inp_hosp_id != undefined) {
                        var insurer = obj.appointment[i].insurer;
                        if (obj.appointment[i].insurer! = undefined) {
                          this.Insuranceflag = true;
                          this.insuranceFlag = true;
                        }
                        if (obj.appointment[i].ins_card_no != undefined) {
                          this.ins_card_no = obj.appointment[i].ins_card_no;
                        } else {
                          this.ins_card_no = obj.appointment[i].policy_no;
                        }
                        this.cnnno = obj.appointment[i].mr_no;
                        this.approved_amount = obj.appointment[i].approved_amount;
                      }
                      var patientname, dr_first_name, age, gender;
                      if (obj.appointment[i].middle_name != undefined) {
                        patientname = encrypt_decript.Decript(obj.appointment[i].first_name) + " " + encrypt_decript.Decript(obj.appointment[i].middle_name) + "" + encrypt_decript.Decript(obj.appointment[i].last_name)
                      } else {
                        patientname = encrypt_decript.Decript(obj.appointment[i].first_name) + " " + encrypt_decript.Decript(obj.appointment[i].last_name)
                      }
                      if (obj.appointment[i].dr_middle_name != undefined) {
                        dr_first_name = (obj.appointment[i].dr_first_name) + " " + (obj.appointment[i].dr_middle_name) + "" + (obj.appointment[i].dr_last_name);
                      } else {
                        dr_first_name = (obj.appointment[i].dr_first_name) + " " + (obj.appointment[i].dr_last_name);
                      }
                      if (obj.appointment[i].age != undefined) {
                        this.age = obj.appointment[i].age;
                      }
                      if (obj.appointment[i].gender != undefined) {
                        this.gender = encrypt_decript.Decript(obj.appointment[i].gender);
                        this.gender = this.gender[0];
                        if (this.gender != undefined && this.gender[0] == 'T') {
                          this.gender = 'Tgen';
                        }
                      }
                      if (obj.appointment[i].admitted_date != undefined) {
                        this.admitted_date = obj.appointment[i].admitted_date
                      }
                      if (obj.appointment[i].admitted_time != undefined) {
                        this.admitted_time = obj.appointment[i].admitted_time
                      }
                      if (obj.appointment[i].bed_no != undefined) {
                        this.bedno = obj.appointment[i].bed_no
                      }
                      if (obj.specialization_name != undefined) {
                        this.specialization_name = obj.specialization_name
                      }
                      if (obj.rate_card != undefined) {
                        this.rate_plan = obj.rate_card
                      }
                      this.appointment_list.push({
                        doc_app_id: obj.appointment[i].doc_app_id,
                        doc_reg_id: obj.appointment[i].doc_reg_id,
                        client_reg_id: obj.appointment[i].client_reg_id,
                        relation_id: obj.appointment[i].relation_id,
                        sub_rel_id: obj.appointment[i].sub_rel_id,
                        address: address,
                        location: obj.appointment[i].location,
                        district: obj.appointment[i].district,
                        state: obj.appointment[i].state,
                        country: obj.appointment[i].country,
                        first_name: obj.appointment[i].first_name,
                        middle_name: obj.appointment[i].middle_name,
                        last_name: obj.appointment[i].last_name,
                        hptl_clinic_id: obj.appointment[i].hptl_clinic_id,
                        session: sessiondata,
                        city: obj.appointment[i].district,
                        zipcode: zipcode,
                        docid_name: this.Docidname,
                        advance: obj.appointment[i].advance,
                        balance: obj.appointment[i].balance,
                        inpatientId: inpatientId,
                        inpatid: inpatid,
                        cgst: "12%",
                        sgst: "12$",
                        admitted_date: this.admitted_date,
                        admitted_time: this.admitted_time,
                        discharge_date: obj.appointment[i].discharge_date,
                        discharge_time: obj.appointment[i].discharge_time,
                        inpid: obj.appointment[i].inp_hosp_id,
                        payment_mode: obj.appointment[i].payment_mode,
                        insurer: obj.appointment[i].insurer,
                        insCardNo: obj.appointment[i].ins_card_no,
                        policyNo: obj.appointment[i].policy_no,
                        ins_card_no: this.ins_card_no,
                        CNNno: this.cnnno,
                        bedno: obj.appointment[i].bed_no,
                        ward_name: obj.appointment[i].ward_name,
                        approved_amount: this.approved_amount,
                        mobilenuo: encrypt_decript.Decript(obj.appointment[i].mobile),
                        patientname: patientname, doctorname: dr_first_name,
                        age: this.age, 
                        gender: this.gender, 
                        patient_id: obj.appointment[i].patient_id,
                        mr_no: obj.appointment[i].mr_no, 
                        bed_no: this.bedno, 
                        salutation_desc: obj.appointment[i].salutation_desc,
                        barcode: obj.appointment[i].barcode,
                        surgery_desc: obj.appointment[i].surgery_desc,
                      });
                    }
                  }
                  console.log("this.appointment_list" + JSON.stringify(this.appointment_list));
                },
                error => {
                  this.toastr.error("Network error, try again");
                }
              )
          }
        }
      }
    } else {
      this.listview_hidden = true;
      if (name == 'name') {
          var headers = new Headers();
          var name = patientList.target.value.toUpperCase();
          send_data = JSON.stringify({
            hptl_clinic_id: this.hptl_clinic_id,
            first_name: name
          });
          console.log("PATIENTDETAILS ___" + JSON.stringify(send_data));
          headers.append('Content-Type', 'application/json');
          this.http.post(ipaddress.getIp.toString() + 'bill/inpa', send_data,
            { headers: headers })
            .subscribe(
              response => {
                this.appointment_list = [];
                var obj = response.json();
                console.log("PATIENTDETAILS ___" + JSON.stringify(obj));
                this.insuranceFlag = false;
                if (obj.appointment != null) {
                  for (var i = 0; i < obj.appointment.length; i++) {
                    if (obj.appointment[i].middle_name != undefined) {
                      this.Docidname = obj.appointment[i].doc_app_id + " - " + encrypt_decript.Decript(obj.appointment[i].first_name) + " " + encrypt_decript.Decript(obj.appointment[i].middle_name) + " " + encrypt_decript.Decript(obj.appointment[i].last_name) + " (" + Date_Formate(obj.appointment[i].admitted_date) + ")";
                    } else {
                      this.Docidname = obj.appointment[i].doc_app_id + " - " + encrypt_decript.Decript(obj.appointment[i].first_name) + " " + encrypt_decript.Decript(obj.appointment[i].last_name) + " (" + Date_Formate(obj.appointment[i].admitted_date) + ")";
                    }
                    var doctorname;
                    if (obj.appointment[i].dr_middle_name != undefined) {
                      doctorname = obj.appointment[i].dr_first_name + "" + obj.appointment[i].dr_middle_name + "" + obj.appointment[i].dr_last_name
                    } else {
                      doctorname = obj.appointment[i].dr_first_name + "" + obj.appointment[i].dr_last_name
                    }
                    var sessiondata = null;
                    if (obj.appointment[i].session != undefined && obj.appointment[i].session != undefined && obj.appointment[i].session != null) {
                      sessiondata = encrypt_decript.Decript(obj.appointment[i].session);
                    }
                    var address;
                    if (obj.appointment[i].address2 != undefined && obj.appointment[i].address1 != undefined) {
                      address = encrypt_decript.Decript(obj.appointment[i].address1) + ", " + encrypt_decript.Decript(obj.appointment[i].address2)
                    } else if (obj.appointment[i].address1 != undefined) {
                      address = encrypt_decript.Decript(obj.appointment[i].address1);
                    } else {
                      address = "";
                    }
                    var hospital;
                    if (obj.hosp_address2 != undefined) {
                      hospital = obj.hosp_address1 + "," + obj.hosp_address2;
                    } else {
                      hospital = obj.hosp_address1;
                    }
                    //inpat_id
                    var inpatid;
                    if (obj.appointment[i].inpat_id != undefined) {
                      this.inpatientflag = true;
                      inpatid = obj.appointment[i].inpat_id
                    } else {
                      this.inpatientflag = false;
                      if (obj.appointment[i].appointment_id != undefined) {
                        inpatid = encrypt_decript.Decript(obj.appointment[i].appointment_id);
                      }
                    }
                    var inpatid = obj.appointment[i].inpat_id != undefined ? obj.appointment[i].inpat_id : "-";
                    var inpatientId = obj.appointment[i].inp_hosp_id != undefined ? obj.appointment[i].inp_hosp_id : "-";
                    var zipcode = obj.appointment[i].zipcode != undefined ? encrypt_decript.Decript(obj.appointment[i].zipcode) : "";
                    this.hospital_name = obj.hosp_name != undefined ? obj.hosp_name : "";
                    this.hospitalAddress = hospital;
                    this.hosp_location = obj.hosp_location;
                    this.hosp_district = obj.hosp_district;
                    this.hosp_state = obj.hosp_state;
                    this.hosp_zip = obj.hosp_zip;
                    this.hosp_telephone = obj.hosp_telephone;
                    this.Hospital_location = obj.hosp_location + ", " + obj.hosp_district + " - " + obj.hosp_state + " " + obj.hosp_country + "  " + obj.hosp_zip;
                    if (obj.appointment[i].inp_hosp_id != undefined) {
                      var insurer = obj.appointment[i].insurer;
                      if (obj.appointment[i].insurer != undefined) {
                        this.Insuranceflag = true;
                        this.insuranceFlag = true;
                      }
                      if (obj.appointment[i].ins_card_no != undefined) {
                        this.ins_card_no = obj.appointment[i].ins_card_no;
                      } else {
                        this.ins_card_no = obj.appointment[i].policy_no;
                      }
                      this.cnnno = obj.appointment[i].mr_no;
                      this.approved_amount = obj.appointment[i].approved_amount;
                    }
                    var patientname, dr_first_name, age, gender;
                    if (obj.appointment[i].middle_name != undefined) {
                      patientname = encrypt_decript.Decript(obj.appointment[i].first_name) + " " + encrypt_decript.Decript(obj.appointment[i].middle_name) + " " + encrypt_decript.Decript(obj.appointment[i].last_name)
                    } else {
                      patientname = encrypt_decript.Decript(obj.appointment[i].first_name) + " " + encrypt_decript.Decript(obj.appointment[i].last_name)
                    }
                    if (obj.appointment[i].dr_middle_name != undefined) {
                      dr_first_name = (obj.appointment[i].dr_first_name) + " " + (obj.appointment[i].dr_middle_name) + "" + (obj.appointment[i].dr_last_name);
                    } else {
                      dr_first_name = (obj.appointment[i].dr_first_name) + " " + (obj.appointment[i].dr_last_name);
                    }
                    if (obj.appointment[i].age != undefined) {
                      this.age = obj.appointment[i].age;
                    }
                    if (obj.appointment[i].gender != undefined) {
                      this.gender = encrypt_decript.Decript(obj.appointment[i].gender);
                      this.gender = this.gender[0];
                      if (this.gender != undefined && this.gender[0] == 'T') {
                        this.gender = 'Tgen';
                      }
                    }
                    if (obj.appointment[i].admitted_date != undefined) {
                      this.admitted_date = obj.appointment[i].admitted_date
                    }
                    if (obj.appointment[i].admitted_time != undefined) {
                      this.admitted_time = Time_Formate(obj.appointment[i].admitted_time)
                    }
                    if (obj.specialization_name != undefined) {
                      this.specialization_name = obj.specialization_name
                    }
                    if (obj.rate_card != undefined) {
                      this.rate_plan = obj.rate_card
                    }
                    if (obj.appointment[i].bed_no != undefined) {
                      this.bedno = obj.appointment[i].bed_no
                    }
                    this.appointment_list.push({
                      doc_app_id: obj.appointment[i].doc_app_id,
                      doc_reg_id: obj.appointment[i].doc_reg_id,
                      client_reg_id: obj.appointment[i].client_reg_id,
                      relation_id: obj.appointment[i].relation_id,
                      sub_rel_id: obj.appointment[i].sub_rel_id,
                      address: address,
                      location: obj.appointment[i].location,
                      district: obj.appointment[i].district,
                      state: obj.appointment[i].state,
                      country: obj.appointment[i].country,
                      first_name: obj.appointment[i].first_name,
                      middle_name: obj.appointment[i].middle_name,
                      last_name: obj.appointment[i].last_name,
                      hptl_clinic_id: obj.appointment[i].hptl_clinic_id,
                      session: sessiondata,
                      city: obj.appointment[i].district,
                      zipcode: zipcode,
                      docid_name: this.Docidname,
                      advance: obj.appointment[i].advance,
                      balance: obj.appointment[i].balance,
                      inpatientId: inpatientId,
                      inpatid: inpatid,
                      cgst: "12%",
                      sgst: "12$",
                      admitted_date: this.admitted_date,
                      admitted_time: this.admitted_time,
                      discharge_date: obj.appointment[i].discharge_date,
                      discharge_time: obj.appointment[i].discharge_time,
                      inpid: obj.appointment[i].inp_hosp_id,
                      payment_mode: obj.appointment[i].payment_mode,
                      insurer: obj.appointment[i].insurer,
                      insCardNo: obj.appointment[i].ins_card_no,
                      policyNo: obj.appointment[i].policy_no,
                      ins_card_no: this.ins_card_no,
                      CNNno: this.cnnno,
                      bedno: obj.appointment[i].bed_no,
                      ward_name: obj.appointment[i].ward_name,
                      approved_amount: this.approved_amount,
                      mobilenuo: encrypt_decript.Decript(obj.appointment[i].mobile),
                      patientname: patientname, doctorname: dr_first_name,
                      age: this.age,
                      gender: this.gender, 
                      patient_id: obj.appointment[i].patient_id,
                      mr_no: obj.appointment[i].mr_no, 
                      bed_no: this.bedno, 
                      salutation_desc: obj.appointment[i].salutation_desc,
                      barcode: obj.appointment[i].barcode,
                      specialization_name: this.specialization_name,
                      rate_plan: this.rate_plan,
                      surgery_desc: obj.appointment[i].surgery_desc,
                      doc_qualif: obj.appointment[i].doc_qualif,
                    });
                  }
                }
                console.log("this.appointment_list" + JSON.stringify(this.appointment_list));
              },
              error => {
                this.toastr.error("Network error, try again");
              }
            )
        }
      } 
    }

  Appointment_change(client_id, rel_id, Sub_id, patientDetails, approved_amount) {
    console.log("in patientDetails "+JSON.stringify(patientDetails));
    //this.Insuranceflag = true;
    this.Insuranceflag = false;
    this.Insuranceflag = false;
    if (approved_amount != undefined) {
      this.sponsorDueflag = true;
      this.sponsorDue = parseFloat(approved_amount).toFixed(2);
    }
    if (this.sponsorDue == "0.00") {
      this.sponsorDueflag = false;
    }
    this.billing_print_array = [];
    this.client_id = client_id;
    this.sub_id = Sub_id;
    this.rel_id = rel_id;
    this.tempparray = patientDetails;
    this.app_id = this.tempparray.doc_app_id;
    this.inpatientId = this.tempparray.inpatientId;
    this.admitted_date = Date_Formate(this.tempparray.admitted_date);
    var data1 = this.tempparray.admitted_time.split(":")
    this.admitted_time = data1[0] + ":" + data1[1];
    this.admitted_timevalue = this.tempparray.admitted_time;
    this.inpid = patientDetails.inpatientId;
    this.insurer = patientDetails.insurer != undefined ? patientDetails.insurer : "";
    this.insCardNo = patientDetails.insCardNo;
    this.policyno = patientDetails.policyNo;
    this.CCNno = patientDetails.CNNno;
    this.approved_amount = patientDetails.approved_amount;
    this.clientnamenew = patientDetails.salutation_desc + patientDetails.patientname;
    this.mobilenumber = patientDetails.mobilenuo;
    this.doctorname = "Dr."+ patientDetails.doctorname;
    this.inpatientidnew = patientDetails.patient_id;
    this.age = patientDetails.age;
    this.gender = patientDetails.gender != undefined ? patientDetails.gender : "";
    this.bedno = patientDetails.bed_no != undefined ? patientDetails.bed_no : "";
    this.agegendervalue = this.age + " / " + this.gender;
    this.mrno = patientDetails.mr_no;
    this.ward_name = patientDetails.ward_name != undefined ? patientDetails.ward_name : "";
    this.surgery_name = patientDetails.surgery_desc != undefined ? patientDetails.surgery_desc : "";
    this.doc_qualif = patientDetails.doc_qualif != undefined ? patientDetails.doc_qualif : "";
    
    if (this.specialization_name != undefined) {
      this.specialization_name=this.specialization_name;
    } else {
      if (patientDetails.specialization_name != undefined) {
        this.specialization_name=patientDetails.specialization_name;
      } else {
        this.specialization_name=""
      }      
    }
    if (this.dischargeTime != undefined) {
      this.dischargeTime=this.dischargeTime;
    } else {
      if (patientDetails.dischargeTime != undefined) {
        this.dischargeTime=patientDetails.dischargeTime;
      } else {
        this.dischargeTime="";
      }     
    }
    if (this.rate_plan != undefined) {
      this.rate_plan=this.rate_plan;
    } else {
      if (patientDetails.rate_plan != undefined) {
        this.rate_plan=patientDetails.rate_plan;
      } else {
        this.rate_plan="";
      }     
    }
    if (patientDetails.insurer != undefined) {
      this.insuranceFlag = true;
      this.Insuranceflag = true;
    }
    if (patientDetails.barcode != undefined) {
      this.barcode_flag = true;
      this.barcode_url = ipaddress.Ip_with_img_address + patientDetails.barcode;
      console.log("this.barcode_url"+this.barcode_url)
    }
    var headers = new Headers();
    var send_data;
    if (this.Filter_txt == "Date") {
      send_data = JSON.stringify({
        client_reg_id: client_id,
        relation_id: rel_id,
        sub_rel_id: Sub_id,
        app_id: this.tempparray.doc_app_id,
        from_date: this.f_date,
        to_date: this.t_date,
      });
    } else {
      send_data = JSON.stringify({
        client_reg_id: client_id,
        relation_id: rel_id,
        app_id: this.tempparray.doc_app_id,
        sub_rel_id: Sub_id,
      });
    }
    console.log("check ------------"+JSON.stringify(patientDetails));
    if (patientDetails.middle_name != undefined) {
      this.patient_name = patientDetails.salutation_desc + "." + encrypt_decript.Decript(patientDetails.first_name) + " " + encrypt_decript.Decript(patientDetails.middle_name) + " " + encrypt_decript.Decript(patientDetails.last_name);
    } else {
      if (patientDetails.last_name != undefined) {
        this.patient_name = patientDetails.salutation_desc + "." + encrypt_decript.Decript(patientDetails.first_name) + " " + encrypt_decript.Decript(patientDetails.last_name);
      } else {
        this.patient_name = encrypt_decript.Decript(patientDetails.first_name);
      }
    }
    if (patientDetails.balance != undefined) {
      this.amount_collected = true;
      this.balance_show = false;
      this.previous_balshow = false;
      this.balance_data = parseFloat(patientDetails.balance).toFixed();
      this.total_collected = parseFloat(patientDetails.balance).toFixed();
    }  
    if (this.listview_hidden == true) {
      this.dog_reg_id ="All"    
    } else {
      this.dog_reg_id = patientDetails.doc_reg_id;
    }
    var zipcode = patientDetails.czipcode != undefined ? encrypt_decript.Decript(patientDetails.czipcode) : "";
    this.admissionDate = patientDetails.admitted_date != undefined ? Date_Formate(patientDetails.admitted_date) : "";
    if (patientDetails.admitted_date != undefined) {
      var admisTime = patientDetails.admitted_time.split(':');
      var h = admisTime[0], m = admisTime[1];
      this.admissionTime = (h > 12) ? (h - 12 + ':' + m + ' PM') : (h + ':' + m + ' AM');
    }
    this.dischargeDate = patientDetails.discharge_date != undefined ? patientDetails.discharge_date : "";
    this.dischargeTime = patientDetails.discharge_time != undefined ? patientDetails.discharge_time : "";
    this.rate_plan = patientDetails.rate_plan != undefined ? patientDetails.rate_plan : "";
    // if (patientDetails.discharge_time != "") {
    //   if (patientDetails.discharge_time != undefined) {
    //     if (patientDetails.discharge_time != ":00") {
    //       var admisTime = patientDetails.discharge_time.split(':');
    //       var h = admisTime[0], m = admisTime[1];
    //       this.dischargeTime = (h > 12) ? (h - 12 + ':' + m + ' PM') : (h + ':' + m + ' AM');
    //     }
    //   }
    // }

    this.billing_print_array.push({
      dr_name: "Dr." + patientDetails.docid_name,
      licence_code: "",
      hptl_name: "",
      created_date: "",
      address: patientDetails.address+ ",",
      location: patientDetails.location,
      city: patientDetails.district + " " + patientDetails.zipcode,
      state: patientDetails.state + " "+ patientDetails.country,
      telephone: patientDetails.telephone,
      inpatientId: patientDetails.inpatientId,
      appointment_id: patientDetails.doc_app_id,
      client_name: this.patient_name,
      caddress: "",
      clocation: patientDetails.clocation + ", ",
      clientcity: patientDetails.ccity + " - " + zipcode + ",",
      clestare: patientDetails.cstate + " - " + patientDetails.ccountry + ".",
      client_reg_id: patientDetails.client_reg_id,
      advance: patientDetails.advance,
      balance: patientDetails.balance,
      doctorname: "Dr" + "." + patientDetails.doctorname,
      barcode_flag: patientDetails.barcode_flag,
      barcode_url: patientDetails.barcode_url
    });
    this.docBills = [];
    this.medBills = [];
    this.diagBills = [];
    this.DisplayBills = [];
    this.sendDoctor = [];
    this.sendPharmacy = [];
    this.sendDiagnosis = [];
    this.totalBillAmount = "0.00";
    this.totalPaidAmount = "0.00";
    this.TotalBalance = "0.00";
    this.final_amount = "0.00";
    this.totalDiscount = "0.00";
    this.totalPaid = "0.00";
    this.totalBill = "0.00";
    this.refund_amount = undefined;
    this.refund_reason = '';
    this.adv_refflag = false;
    console.log("BILL DETAILS send_datasend_data((())) " + JSON.stringify(send_data));
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/gbfcp', send_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          if (obj != null) {
            console.log("BILL DETAILS ((())) " + JSON.stringify(obj));
            this.Advance_bill = obj.balance != undefined ? parseFloat(obj.balance).toFixed(2) : "0.00";
            this.total_advance = obj.advance != undefined ? parseFloat(obj.advance).toFixed(2) : "0.00";
            //this.insuranceFlag = obj.pay_type_desc != undefined ? true : false;
            this.insuranceDec = obj.pay_type_desc != undefined ? obj.pay_type_desc : " ";
            var doctorBills = obj.doc_bills;
            console.log("doctorBills",doctorBills);
            this.patient_selectionflag = false;
            // for doctor bill
            if (doctorBills != undefined) {
              for (var j = 0; j < doctorBills.length; j++) {
                var billList = doctorBills[j].bill_details;
                var billDetails = [];
                if (billList != undefined) {
                  if (billList.length != 0) {
                    for (var i = 0; i < billList.length; i++) {
                      billDetails.push({
                        name: billList[i].name,
                        quantity: billList[i].quantity,
                        amount: billList[i].fee_before_concession,
                        fee: billList[i].fee,
                        concession: billList[i].concession,
                        category: billList[i].category_desc,
                        charge_cat_id: billList[i].charge_category_id,
                        order_no: billList[i].order_no
                      })
                    }
                    billDetails = this.createGroupping(billDetails);
                  }
                }

                var bill_amount = doctorBills[j].bill_amount != undefined ? encrypt_decript.Decript(doctorBills[j].bill_amount) : "0.00";
                var bill_date = doctorBills[j].bill_date != undefined ? Date_Formate(doctorBills[j].bill_date) : "";
                var advance = doctorBills[j].advance != undefined ? parseFloat(doctorBills[j].advance).toFixed(2) : "0.00";
                var balance = (parseFloat(bill_amount) - (parseFloat(doctorBills[j].paid_amount) + parseFloat(doctorBills[j].discount))-parseFloat(advance)).toFixed(2);
                this.totalBillAmount = (parseFloat(this.totalBillAmount) + parseFloat(bill_amount)).toFixed(2);
                this.totalPaidAmount = (parseFloat(this.totalPaidAmount) + parseFloat(paid)).toFixed(2);
                this.TotalBalance = (parseFloat(this.TotalBalance) + parseFloat(doctorBills[j].balance)).toFixed(2);
                var discount = doctorBills[j].discount != undefined ? doctorBills[j].discount : "0.00";
                var tempDoc = {
                  billing_id: doctorBills[j].billing_id,
                  bill_amount: parseFloat(bill_amount).toFixed(2),
                  advance: parseFloat(advance).toFixed(2),
                  balance: parseFloat(balance).toFixed(2),
                  bill_date: bill_date + " " + Time_Formate(doctorBills[j].bill_time),
                  paid_amount: parseFloat(doctorBills[j].paid_amount).toFixed(2),
                  discount: parseFloat(discount).toFixed(2),
                  fin_year: doctorBills[j].fin_year,
                  checked: false,
                  type: "doctor",
                  billsList: billDetails,
                  paidamountprint: doctorBills[j].paid_amount,
                  balance_amountprint: doctorBills[j].balance,
                  bill_amountprint: doctorBills[j].bill_amount,
                  discountprint: doctorBills[j].discount != undefined ? doctorBills[j].discount : "0.00",
                  
                }
                this.docBills.push(tempDoc);
                console.log(this.docBills);
                if (doctorBills[j].balance == "0.00" || doctorBills[j].balance == "0") {
                  this.selectIndividual(tempDoc);
                }
              }
            }
            // for Pharma bill
            var pharmaBills = obj.pharma_bills;
            console.log("pharmac ***"+JSON.stringify(pharmaBills));
            if (pharmaBills != undefined) {
              for (var j = 0; j < pharmaBills.length; j++) {
                var billList = pharmaBills[j].bill_details;
                var billDetails = [];
                this.pharma_id = pharmaBills[j].pharma_id;
                var tempPharma = {};
                if (billList != undefined) {
                  if (billList.length != 0) {
                    for (var i = 0; i < billList.length; i++) {
                      var concession = billList[i].concession != undefined ? billList[i].concession : "0.00";
                      var discount = billList[i].discount != undefined ? billList[i].discount : "0.00";
                      if (this.is_insurance == true) {
                        if (billList[i].ins_allow != undefined) {
                          if(billList[i].ins_allow == "1"){
                            billDetails.push({
                              name: billList[i].type + "-" + billList[i].name,
                              batch_no: billList[i].batch_no,
                              quantity: billList[i].quantity,
                              discount: discount,
                              amount: billList[i].amount,
                              concession: concession,
                              ins_allow: billList[i].ins_allow,
                            })
                          }
                        } else {
                          billDetails.push({
                            name: billList[i].type + "-" + billList[i].name,
                            batch_no: billList[i].batch_no,
                            quantity: billList[i].quantity,
                            discount: discount,
                            amount: billList[i].amount,
                            concession: concession,
                            ins_allow: billList[i].ins_allow,
                          })
                        }
                      } else {
                        billDetails.push({
                          name: billList[i].type + "-" + billList[i].name,
                          batch_no: billList[i].batch_no,
                          quantity: billList[i].quantity,
                          discount: discount,
                          amount: billList[i].amount,
                          concession: concession,
                          ins_allow: billList[i].ins_allow,
                        })
                      }
                    }
                  }
                }

                var pbill_amount;
                if(this.is_insurance == true){
                  if(pharmaBills[j].sponsor_due != undefined){
                    pbill_amount = pharmaBills[j].sponsor_due;
                  }
                } else {
                  pbill_amount = pharmaBills[j].bill_amount != undefined ? pharmaBills[j].bill_amount : " "
                }
                var blanc;
                if(this.is_insurance == true){
                  if(pharmaBills[j].sponsor_due != undefined){
                    blanc = pharmaBills[j].sponsor_due;
                  }
                } else {
                  blanc = pharmaBills[j].balance;
                }

                var bill_date = pharmaBills[j].bill_date != undefined ? Date_Formate(pharmaBills[j].bill_date) : "";
                var paid = pharmaBills[j].paid_amount != undefined ? pharmaBills[j].paid_amount : "0.00";
                this.totalBillAmount = (parseFloat(this.totalBillAmount) + parseFloat(bill_amount)).toFixed(2);
                this.totalPaidAmount = (parseFloat(this.totalPaidAmount) + parseFloat(paid)).toFixed(2);
                this.TotalBalance = (parseFloat(this.TotalBalance) + parseFloat(pharmaBills[j].balance)).toFixed(2);
                var discount = pharmaBills[j].discount != undefined ? pharmaBills[j].discount : "0.00";

                tempPharma = {
                  billing_id: pharmaBills[j].billing_id,
                  bill_amount: parseFloat(pbill_amount).toFixed(2),
                  advance: parseFloat(pharmaBills[j].advance).toFixed(2),
                  balance: parseFloat(blanc).toFixed(2),
                  bill_date: bill_date + " " + Time_Formate(pharmaBills[j].bill_time),
                  paid_amount: parseFloat(paid).toFixed(2),
                  checked: false,
                  type: "pharma",
                  billsList: billDetails,
                  discount: parseFloat(discount).toFixed(2),
                  fin_year: pharmaBills[j].fin_year,
                  pharma_id: pharmaBills[j].pharma_id,
                  paidamountprint: pharmaBills[j].paid_amount,
                  balance_amountprint: pharmaBills[j].balance,
                  bill_amountprint: pharmaBills[j].bill_amount,
                  discountprint: pharmaBills[j].discount != undefined ? pharmaBills[j].discount : "0.00",
                }
                this.medBills.push(tempPharma);
                console.log("me d ---"+JSON.stringify(this.medBills))
                if (pharmaBills[j].balance == "0.00") {
                  this.selectIndividual(tempPharma);
                }
              }
            }
            // for Diagnosis bill
            var diagBills = obj.diag_bills;
            if (diagBills != undefined) {
              var diaPharma = {};
              var sno = 1;
              for (var j = 0; j < diagBills.length; j++) {
                var billList = diagBills[j].bill_details;
                this.diag_centre_id = diagBills[j].diag_centre_id;
                var billDetails = [];
                if (billList != undefined) {
                  if (billList.length != 0) {
                    for (var i = 0; i < billList.length; i++) {
                      var totalAmount = (parseFloat(billList[i].fee) / billList[i].quantity);
                      billDetails.push({
                        sno: sno,
                        name: billList[i].name,
                        amount: totalAmount,
                        quantity: billList[i].quantity,
                        fee: billList[i].fee,
                        concession: billList[i].concession,
                      })
                      sno += 1;
                    }
                  }
                }
                var bill_amount = diagBills[j].bill_amount != undefined ? encrypt_decript.Decript(diagBills[j].bill_amount) : " ";
                var paid = diagBills[j].paid_amount != undefined ? diagBills[j].paid_amount : "0.00";
                var bill_date = diagBills[j].bill_date != undefined ? Date_Formate(diagBills[j].bill_date) : "";
                this.totalBillAmount = (parseFloat(this.totalBillAmount) + parseFloat(bill_amount)).toFixed(2);
                this.totalPaidAmount = (parseFloat(this.totalPaidAmount) + parseFloat(paid)).toFixed(2);
                this.TotalBalance = (parseFloat(this.TotalBalance) + parseFloat(diagBills[j].balance)).toFixed(2);
                var discount = diagBills[j].discount != undefined ? diagBills[j].discount : "0.00";

                diaPharma = {
                  billing_id: diagBills[j].billing_id,
                  bill_amount: parseFloat(bill_amount).toFixed(2),
                  advance : parseFloat(diagBills[j].advance).toFixed(2),
                  balance: parseFloat(diagBills[j].balance).toFixed(2),
                  bill_date: bill_date + " " + Time_Formate(diagBills[j].bill_time),
                  paid_amount: parseFloat(paid).toFixed(2),
                  checked: false,
                  type: "diagnosis",
                  discount: parseFloat(discount).toFixed(2),
                  fin_year: diagBills[j].fin_year,
                  billsList: billDetails,
                  paidamountprint: diagBills[j].paid_amount,
                  balance_amountprint: diagBills[j].balance,
                  bill_amountprint: diagBills[j].bill_amount,
                  discountprint: diagBills[j].discount != undefined ? diagBills[j].discount : "0.00",
                }
                this.diagBills.push(diaPharma);
                if (diagBills[j].balance == "0.00") {
                  this.selectIndividual(diaPharma);
                }
              }
            }
            this.billType('doctor');
            if (this.surgery_name != undefined && this.surgery_name != "") {
              if (this.print_template == 'banner') 
                this.header_style = "margin-top: 5px; height: 155px;";
              else 
                this.header_style = "margin-top: 5px; height: 162px;";
              console.log("FirstIfCalled....");
              console.log("surgery_name",this.surgery_name);
            } 
            if (this.surgery_name == undefined || this.surgery_name == "") {
              if (this.print_template == 'banner') 
                this.header_style = "margin-top: 5px; height: 140px;";
              else 
                this.header_style = "margin-top: 5px; height: 142px;";
              console.log("SecondIfCalled....");
              console.log("surgery_name",this.surgery_name);
            }
            console.log("header_style",this.header_style);
          }
        },
        error => {
          this.toastr.error("Network error, try again later");
        }
      )
  }

  createGroupping(billDetails) {
    console.log(billDetails);
    this.categoryChargeList = billDetails;
    const groupedByCategory = this.groupBy(this.categoryChargeList, 'category');
    console.log("groupedByCategory",groupedByCategory);
    var hospitalChargeArray2 = [];
    var new_viewchargearray = [];
    for (var key in groupedByCategory) {
      console.log(key + ': ' + JSON.stringify(groupedByCategory[key]));
      if (key !== 'undefined') {
        console.log(key);
        new_viewchargearray = [];
        for (var k = 0; k < groupedByCategory[key].length; k++) {
            var charge_order_list = groupedByCategory[key][k].order_no;
            var category_Name = "";
            if (groupedByCategory.length > 1) {
              category_Name = groupedByCategory[key][k].category;
            }
            new_viewchargearray.push({
              sno: k + 1,
              category: key,
              order_no: charge_order_list,
              name: groupedByCategory[key][k].name,
              quantity: groupedByCategory[key][k].quantity,
              amount: groupedByCategory[key][k].amount,
              fee: groupedByCategory[key][k].fee,
              concession: groupedByCategory[key][k].concession,
              charge_cat_id: groupedByCategory[key][k].charge_cat_id,
            })
        }
        var category_flag;
        if (new_viewchargearray.length == 0) 
          category_flag = false;
        else 
          category_flag = true;
        
        hospitalChargeArray2.push({
            category: key,
            category_flag: category_flag,
            order_no: charge_order_list,
            charges: new_viewchargearray.sort((a, b) => a.order_no - b.order_no),
        })
      }  
    }

    this.categoryChargeList = [];
    this.categoryChargeList = hospitalChargeArray2.sort((a,b)=> a.order_no - b.order_no);
    var rowid = 1;
    for (var i = 0; i < this.categoryChargeList.length; i++) {
      for (var j = 0; j < this.categoryChargeList[i].charges.length; j++) {
        this.categoryChargeList[i].charges[j].sno = rowid;
        rowid += 1;
      }
    }  
    console.log("hospitalChargeArray2", hospitalChargeArray2);
    console.log("this.categoryChargeList",this.categoryChargeList);
    return  this.categoryChargeList;
  }

  groupBy(array, key) {
    return array.reduce((result, obj) => {
       const keyValue = obj[key];
       // If the key doesn't exist in the result, create an empty array for it
       if (!result[keyValue]) {
          result[keyValue] = [];
       }
       // Push the object to the corresponding key's array
       result[keyValue].push(obj);
       return result;
    }, {});
  }

  combineCharges(groupedData) {
    // Use reduce to iterate over each item and combine their charges
    return groupedData.reduce((combinedCharges, item) => {
        return combinedCharges.concat(item.charges);
    }, []);
  }

  calculateTotalAdvance(data: any): number {
    let totalAdvance = 0;
    // Helper function to add advance if it exists, otherwise add 0
    const addAdvance = (bill: any) => {
      const advance = bill.advance ? parseFloat(bill.advance) : 0;
      totalAdvance += advance;
    };
    // Process doc_bills
    if (data.doc_bills && Array.isArray(data.doc_bills)) {
      data.doc_bills.forEach(addAdvance);
    }
    // Process diag_bills
    if (data.diag_bills && Array.isArray(data.diag_bills)) {
      data.diag_bills.forEach(addAdvance);
    }
    // Process pharma_bills
    if (data.pharma_bills && Array.isArray(data.pharma_bills)) {
      data.pharma_bills.forEach(addAdvance);
    }
    return totalAdvance;
  }

  billType(billType) {
    this.DisplayBills = [];
    $("#hospBillIMG").attr("src", "../../../assets/ui_icons/hospital_bill_non_selected_icon.png");
    $("#pharBillIMG").attr("src", "../../../assets/ui_icons/pharmacy_bill_non_selected_icon.png");
    $("#diagBillIMG").attr("src", "../../../assets/ui_icons/diagnosis_bill_non_selected_icon.png");
    this.selectedAllFlage = false;
    if (billType == "doctor") {
      console.log("bjhghhlkjkl --" + JSON.stringify(this.docBills));
      console.log("sendDoctor --" + JSON.stringify(this.sendDoctor));
      this.DisplayBills = this.docBills;
      this.selectedAllFlage = this.docBills.length == this.sendDoctor.length ? true : false;
      this.bill_type = "doctor";
      $("#hospBillIMG").attr("src", "../../../assets/ui_icons/hospital_bill_selected_icon.png");
    } else if (billType == "pharma") {
      this.DisplayBills = this.medBills;
      this.selectedAllFlage = this.medBills.length == this.sendPharmacy.length ? true : false;
      this.bill_type = "pharma";
      $("#pharBillIMG").attr("src", "../../../assets/ui_icons/pharmacy_bill_selected_icon.png");
    } else {
      this.DisplayBills = this.diagBills;
      this.bill_type = "diagnosis";
      this.selectedAllFlage = this.diagBills.length == this.sendDiagnosis.length ? true : false;
      $("#diagBillIMG").attr("src", "../../../assets/ui_icons/diagnosis_bill_selected_icon.png");
    }
    console.log("Checking", this.DisplayBills);
  }

  checkdiscount() {
    this.discountvaluecheck = 0.00;
  }

  discountvalue() {
    this.discountvaluecheck = Number(this.discountvaluecheck); // Convert to number if needed
    console.log(this.totalDiscountvalue);
    console.log(this.discountvaluecheck);
    var totalDiscountValueNumber = parseFloat(this.totalDiscountvalue); // Convert string to number
    this.discountvaluecheck += totalDiscountValueNumber;    
    this.remaining_billbal = this.remaining_billbal - this.discountvaluecheck;
    this.remaining_billbal = parseFloat(this.remaining_billbal).toFixed(2);
    if (this.discountvaluecheck != 0 || this.discountvaluecheck != 0.00) {
      this.discountflag =true;
    } 
    this.remaining_billbal =this.checkAndAssignValue(this.remaining_billbal);
    this.discountvaluecheck=this.checkAndAssignValue(this.discountvaluecheck);
  }

  checkAndAssignValue(value: any): number {
    return isNaN(value) ? 0.00 : value;
  }

  selectIndividual(bill) {
    this.totalPaidAmountvalue = "0";
    this.totalBalanceAmountvalue = "0";
    this.totalBillAmountvalue = "0.00";
    this.totalDiscountvalue = "0";
    this.total_advancevalue = "0";
    this.remaining_billbal=0.00;
    this.discount_rembalance = 0.00
    this.combinedArray = []
    console.log("bill check tyep " + JSON.stringify(bill));
    var paid_flag;
    var paidAmount;
    var balance;
    var print_tempArray = [];
    var isexists = false;
    if (bill.type == "doctor") {  // send for doctor
      if (this.sendDoctor.length != 0) {
        var index: any = 0;
        for (var j = 0; j < this.sendDoctor.length; j++) {
          index = 1;
        }
        if (index == 1) {
          for (var i = 0; i < this.sendDoctor.length; i++) {
            if (this.sendDoctor[i].billing_id == bill.billing_id) {
              this.sendDoctor.splice(i, 1);
              this.final_amount = (parseFloat(this.final_amount) - parseFloat(bill.balance)).toFixed(2);
              this.totalDiscount = (parseFloat(this.totalDiscount) - parseFloat(bill.discount)).toFixed(2);
              this.totalBill = (parseFloat(this.totalBill) - parseFloat(bill.bill_amount)).toFixed(2);
              this.totalPaid = (parseFloat(this.totalPaid) - parseFloat(bill.paid_amount)).toFixed(2);

              isexists = true;
              break;
            }
          }
          for (var k = 0; k < this.docBills.length; k++) {
            if (this.docBills[k].billing_id == bill.billing_id) {
              this.docBills[k].checked = false;
              break;
            }
          }
          if (isexists == false) {
            var flag;
            if (parseFloat(bill.balance) == 0.00) {
              flag = "Fully paid";
            } else if (parseFloat(bill.balance) == parseFloat(bill.bill_amount)) {
              flag = "un paid";
            } else {
              flag = "Partially paid"
            }

            this.bill_no = bill.billing_id;
            this.sendDoctor.push({
              billing_id: bill.billing_id,
              hptl_clinic_id: this.hptl_clinic_id,
              paid_amount: bill.paid_amount,
              balance: bill.balance,
              temp_balance: bill.balance,
              paid_flag: flag,
              paidamountprint: bill.paid_amount,
              balance_amountprint: bill.balance,
              bill_amountprint: bill.bill_amount,
              discountprint: bill.discount,
              billDetails: this.combineCharges(bill.billsList),
              bill_date: bill.bill_date,
              fin_year: bill.fin_year,
              advance: bill.advance != undefined ? bill.advance : "0.00", checkvalue: true
            });
            console.log(this.sendDoctor);
            this.final_amount = (parseFloat(this.final_amount) + parseFloat(bill.balance)).toFixed(2);
            this.totalDiscount = (parseFloat(this.totalDiscount) + parseFloat(bill.balance)).toFixed(2);
            this.totalBill = (parseFloat(this.totalBill) + parseFloat(bill.bill_amount)).toFixed(2);
            this.totalPaid = (parseFloat(this.totalPaid) + parseFloat(bill.paid_amount)).toFixed(2);
            for (var k = 0; k < this.docBills.length; k++) {
              if (this.docBills[k].billing_id == bill.billing_id) {
                this.docBills[k].checked = true;
                this.sendDoctor.checkvalue = true;
                this.bill_no = bill.billing_id;
                break;
              } else {
                this.sendDoctor.checkvalue = false;
              }
            }
          }
        } else {
          var flag;
          if (parseFloat(bill.balance) == 0.00) {
            flag = "Fully paid";
          } else if (parseFloat(bill.balance) == parseFloat(bill.bill_amount)) {
            flag = "un paid";
          } else {
            flag = "Partially paid"
          }
          this.sendDoctor.push({
            billing_id: bill.billing_id,
            hptl_clinic_id: this.hptl_clinic_id,
            paid_amount: bill.paid_amount,
            balance: bill.balance,
            temp_balance: bill.balance,
            paid_flag: flag,
            paidamountprint: bill.paid_amount,
            balance_amountprint: bill.balance,
            bill_amountprint: bill.bill_amount,
            discountprint: bill.discount,
            billDetails: this.combineCharges(bill.billsList),
            fin_year: bill.fin_year,
            advance: bill.advance != undefined ? bill.advance : "0.00",
            checkvalue: true,
            bill_date: bill.bill_date
          });
          console.log(this.sendDoctor);
          this.final_amount = (parseFloat(this.final_amount) + parseFloat(bill.balance)).toFixed(2);
          this.totalDiscount = (parseFloat(this.totalDiscount) + parseFloat(bill.balance)).toFixed(2);
          this.totalBill = (parseFloat(this.totalBill) + parseFloat(bill.bill_amount)).toFixed(2);
          this.totalPaid = (parseFloat(this.totalPaid) + parseFloat(bill.paid_amount)).toFixed(2);
          for (var k = 0; k < this.docBills.length; k++) {
            if (this.docBills[k].billing_id == bill.billing_id) {
              this.docBills[k].checked = true;
              break;
            }         
          }
        }
      } else {
        var flag;
        if (parseFloat(bill.balance) == 0.00) {
          flag = "Fully paid";
        } else if (parseFloat(bill.balance) == parseFloat(bill.bill_amount)) {
          flag = "un paid";
        } else {
          flag = "Partially paid"
        }
        this.sendDoctor.push({
          billing_id: bill.billing_id,
          hptl_clinic_id: this.hptl_clinic_id,
          paid_amount: bill.paid_amount,
          balance: bill.balance,
          paid_flag: flag,
          temp_balance: bill.balance,
          paidamountprint: bill.paid_amount,
          balance_amountprint: bill.balance,
          bill_amountprint: bill.bill_amount,
          discountprint: bill.discount,
          bill_date: bill.bill_date,
          billDetails: this.combineCharges(bill.billsList),
          fin_year: bill.fin_year,
          advance: bill.advance != undefined ? bill.advance : "0.00", checkvalue: true
        });
        console.log(this.sendDoctor);
        this.final_amount = (parseFloat(this.final_amount) + parseFloat(bill.balance)).toFixed(2);
        this.totalDiscount = (parseFloat(this.totalDiscount) + parseFloat(bill.discount)).toFixed(2);
        this.totalBill = (parseFloat(this.totalBill) + parseFloat(bill.bill_amount)).toFixed(2);
        this.totalPaid = (parseFloat(this.totalPaid) + parseFloat(bill.paid_amount)).toFixed(2);
        for (var k = 0; k < this.docBills.length; k++) {
          if (this.docBills[k].billing_id == bill.billing_id) {
            this.docBills[k].checked = true;
            this.sendDoctor.checkvalue = true;
            break;
          } else {
            this.sendDoctor.checkvalue = false;
          }
        }
      }
      console.log("bill c**** " + JSON.stringify(this.sendDoctor));
      if (this.selectedAllFlage == true) {
        if (this.docBills != undefined && this.docBills.length != 0) {
          for (var k = 0; k < this.docBills.length; k++) {
            if (this.docBills[k].checked == false) {
              this.selectedAllFlage = false;
              break;
            } else {
              this.selectedAllFlage = true;
            }
          }
        }     
      } else if (this.selectedAllFlage == false) {
        if (this.docBills != undefined && this.docBills.length != 0) {
          for (var k = 0; k < this.docBills.length; k++) {
            if (this.docBills[k].checked == false) {
              this.selectedAllFlage = false;
              break;
            } else {
              this.selectedAllFlage = true;
            }
          }
        }     
      }
    } else if (bill.type == "pharma") {// send for pharmacy
      if (this.sendPharmacy.length != 0) {
        var index: any = 0;
        for (var j = 0; j < this.sendPharmacy.length; j++) {
          if (this.sendPharmacy[j].order_id == bill.billing_id) {
            index = 1;
          }
        }
        if (index == 1) {
          for (var i = 0; i < this.sendPharmacy.length; i++) {
            if (this.sendPharmacy[i].order_id == bill.billing_id) {
              if (this.sendPharmacy[i].temp_balance != "0.00") {
                this.sendPharmacy.splice(i, 1);
              }
              this.final_amount = (parseFloat(this.final_amount) - parseFloat(bill.balance)).toFixed(2);
              this.mediBillAmount = (parseFloat(this.mediBillAmount) - parseFloat(bill.bill_amount)).toFixed(2);
              this.totalDiscount = (parseFloat(this.totalDiscount) - parseFloat(bill.discount)).toFixed(2);
              this.totalBill = (parseFloat(this.totalBill) - parseFloat(bill.bill_amount)).toFixed(2);
              this.totalPaid = (parseFloat(this.totalPaid) - parseFloat(bill.paid_amount)).toFixed(2);
              break;
            }
          }
          for (var k = 0; k < this.medBills.length; k++) {
            if (this.medBills[k].billing_id == bill.billing_id) {
              if (this.medBills[k].balance == "0.00") {
                this.medBills[k].checked = true;
              } else {
                this.medBills[k].checked = false;
              }
              break;
            }
          }
        } else {
          var flag;
          if (parseFloat(bill.balance) == 0.00) {
            flag = "Fully paid";
          } else if (parseFloat(bill.balance) == parseFloat(bill.bill_amount)) {
            flag = "un paid";
          } else {
            flag = "Partially paid"
          }
          this.sendPharmacy.push({
            order_id: bill.billing_id,
            pharma_id: bill.pharma_id,
            paid_amount: bill.paid_amount,
            balance: bill.balance,
            temp_balance: bill.balance,
            paidamountprint: bill.paid_amount,
            balance_amountprint: bill.balance,
            bill_amountprint: bill.bill_amount,
            discountprint: bill.discount,
            paid_flag: flag,
            bill_date: bill.bill_date,
            billDetails: bill.billsList,
            fin_year: bill.fin_year,
            advance: bill.advance != undefined ? bill.advance : "0.00",
          });
          this.final_amount = (parseFloat(this.final_amount) + parseFloat(bill.balance)).toFixed(2);
          this.mediBillAmount = (parseFloat(this.mediBillAmount) + parseFloat(bill.bill_amount)).toFixed(2);
          this.totalDiscount = (parseFloat(this.totalDiscount) + parseFloat(bill.discount)).toFixed(2);
          this.totalBill = (parseFloat(this.totalBill) + parseFloat(bill.bill_amount)).toFixed(2);
          this.totalPaid = (parseFloat(this.totalPaid) + parseFloat(bill.paid_amount)).toFixed(2);
          for (var k = 0; k < this.medBills.length; k++) {
            if (this.medBills[k].billing_id == bill.billing_id) {
              this.medBills[k].checked = true;
              break;
            }
          }
        }
      } else {
        var flag;
        if (parseFloat(bill.balance) == 0.00) {
          flag = "Fully paid";
        } else if (parseFloat(bill.balance) == parseFloat(bill.bill_amount)) {
          flag = "un paid";
        } else {
          flag = "Partially paid"
        }
        this.sendPharmacy.push({
          order_id: bill.billing_id,
          pharma_id: bill.pharma_id,
          paid_amount: bill.paid_amount,
          balance: bill.balance,
          paid_flag: flag,
          temp_balance: bill.balance,
          paidamountprint: bill.paid_amount,
          balance_amountprint: bill.balance,
          bill_amountprint: bill.bill_amount,
          discountprint: bill.discount,
          billDetails: bill.billsList,
          bill_date: bill.bill_date,
          fin_year: bill.fin_year,
          advance: bill.advance != undefined ? bill.advance : "0.00"
        });
        this.final_amount = (parseFloat(this.final_amount) + parseFloat(bill.balance)).toFixed(2);
        this.mediBillAmount = (parseFloat(this.mediBillAmount) + parseFloat(bill.bill_amount)).toFixed(2);
        this.totalDiscount = (parseFloat(this.totalDiscount) + parseFloat(bill.discount)).toFixed(2);
        this.totalBill = (parseFloat(this.totalBill) + parseFloat(bill.bill_amount)).toFixed(2);
        this.totalPaid = (parseFloat(this.totalPaid) + parseFloat(bill.paid_amount)).toFixed(2);
        for (var k = 0; k < this.medBills.length; k++) {
          if (this.medBills[k].billing_id == bill.billing_id) {
            this.medBills[k].checked = true;
            break;
          }
        }
      }
      console.log("check this.sendPharmacy" + JSON.stringify(this.sendPharmacy));
      if (this.selectedAllFlage == true) {
        if (this.medBills != undefined && this.medBills.length != 0) {
          for (var k = 0; k < this.medBills.length; k++) {
            if (this.medBills[k].checked == false) {
              this.selectedAllFlage = false;
              break;
            } else {
              this.selectedAllFlage = true;
            }
          }
        }     
      } else if (this.selectedAllFlage == false) {
        if (this.medBills != undefined && this.medBills.length != 0) {
          for (var k = 0; k < this.medBills.length; k++) {
            if (this.medBills[k].checked == false) {
              this.selectedAllFlage = false;
              break;
            } else {
              this.selectedAllFlage = true;
            }
          }
        }     
      }
    } else { // send for diagnosis
      if (this.sendDiagnosis.length != 0) {
        var index: any = 0;
        for (var j = 0; j < this.sendDiagnosis.length; j++) {
          if (this.sendDiagnosis[j].billing_id == bill.billing_id) {
            index = 1;
          }
        }
        if (index == 1) {
          for (var i = 0; i < this.sendDiagnosis.length; i++) {
            if (this.sendDiagnosis[i].billing_id == bill.billing_id) {
              if (this.sendDiagnosis[i].temp_balance != "0.00") {
                this.sendDiagnosis.splice(i, 1);
              }
              this.final_amount = (parseFloat(this.final_amount) - parseFloat(bill.balance)).toFixed(2);
              this.totalBill = (parseFloat(this.totalBill) - parseFloat(bill.bill_amount)).toFixed(2);
              this.totalPaid = (parseFloat(this.totalPaid) - parseFloat(bill.paid_amount)).toFixed(2);
              break;
            }
          }
          for (var k = 0; k < this.diagBills.length; k++) {
            if (this.diagBills[k].billing_id == bill.billing_id) {
              if (this.diagBills[k].balance == "0.00") {
                this.diagBills[k].checked = true;
              } else {
                this.diagBills[k].checked = false;
              }
              break;
            }
          }
        } else {
          var flag;
          if (parseFloat(bill.balance) == 0.00) {
            flag = "Fully paid";
          } else if (parseFloat(bill.balance) == parseFloat(bill.bill_amount)) {
            flag = "un paid";
          } else {
            flag = "Partially paid"
          }
          this.sendDiagnosis.push({
            billing_id: bill.billing_id,
            diag_centre_id: this.diag_centre_id,
            paid_amount: bill.paid_amount,
            balance: bill.balance,
            paid_flag: flag,
            temp_balance: bill.balance,
            billDetails: bill.billsList,
            paidamountprint: bill.paid_amount,
            balance_amountprint: bill.balance,
            bill_amountprint: bill.bill_amount,
            discountprint: bill.discount,
            fin_year: bill.fin_year,
            bill_date: bill.bill_date,
          });

          this.final_amount = (parseFloat(this.final_amount) + parseFloat(bill.balance)).toFixed(2);
          this.totalBill = (parseFloat(this.totalBill) + parseFloat(bill.bill_amount)).toFixed(2);
          this.totalPaid = (parseFloat(this.totalPaid) + parseFloat(bill.paid_amount)).toFixed(2);
          for (var k = 0; k < this.diagBills.length; k++) {
            if (this.diagBills[k].billing_id == bill.billing_id) {
              this.diagBills[k].checked = true;
              break;
            }
          }
        }
      } else {
        var flag;
        if (parseFloat(bill.balance) == 0.00) {
          flag = "Fully paid";
        } else if (parseFloat(bill.balance) == parseFloat(bill.bill_amount)) {
          flag = "un paid";
        } else {
          flag = "Partially paid"
        }
        this.sendDiagnosis.push({
          billing_id: bill.billing_id,
          diag_centre_id: this.diag_centre_id,
          paid_amount: bill.paid_amount,
          balance: bill.balance,
          paid_flag: flag,
          temp_balance: bill.balance,
          billDetails: bill.billsList,
          paidamountprint: bill.paid_amount,
          balance_amountprint: bill.balance,
          bill_amountprint: bill.bill_amount,
          discountprint: bill.discount,
          fin_year: bill.fin_year,
          advance: bill.advance != undefined ? bill.advance : "0.00", bill_date: bill.bill_date
        });

        this.final_amount = (parseFloat(this.final_amount) + parseFloat(bill.balance)).toFixed(2);
        this.totalBill = (parseFloat(this.totalBill) + parseFloat(bill.bill_amount)).toFixed(2);
        this.totalPaid = (parseFloat(this.totalPaid) + parseFloat(bill.paid_amount)).toFixed(2);
        for (var k = 0; k < this.diagBills.length; k++) {
          if (this.diagBills[k].billing_id == bill.billing_id) {
            this.diagBills[k].checked = true;
            break;
          }
        }
      }

      if (this.selectedAllFlage == true) {
        if (this.diagBills != undefined && this.diagBills.length != 0) {
          for (var k = 0; k < this.diagBills.length; k++) {
            if (this.diagBills[k].checked == false) {
              this.selectedAllFlage = false;
              break;
            } else {
              this.selectedAllFlage = true;
            }
          }
        }     
      } else if (this.selectedAllFlage == false) {
        if (this.diagBills != undefined && this.diagBills.length != 0) {
          for (var k = 0; k < this.diagBills.length; k++) {
            if (this.diagBills[k].checked == false) {
              this.selectedAllFlage = false;
              break;
            } else {
              this.selectedAllFlage = true;
            }
          }
        }     
      }
    }

    if (this.sendDoctor.length != 0) {
      this.docBillDetails = [];
      var sno=1;
      console.log("sendDoctor",this.sendDoctor);
      for (var j = 0; j < this.sendDoctor.length; j++) {
        for (var k = 0; k < this.sendDoctor[j].billDetails.length; k++) {
          var docfee = parseFloat(this.sendDoctor[j].billDetails[k].fee).toFixed(2);
          this.docBillDetails.push({
            sno: sno,
            name: this.sendDoctor[j].billDetails[k].name,
            quantity: this.sendDoctor[j].billDetails[k].quantity,
            amount: parseFloat(this.sendDoctor[j].billDetails[k].amount).toFixed(2),
            concession: this.sendDoctor[j].billDetails[k].concession,
            fee: docfee,
            bill_date: this.sendDoctor[j].bill_date,
            category: this.sendDoctor[j].billDetails[k].category,
            charge_cat_id: this.sendDoctor[j].billDetails[k].charge_cat_id,
            order_no: this.sendDoctor[j].billDetails[k].order_no 
          })
          sno +=1;
        }
      }
      this.docBillDetails = this.createGroupping1(this.docBillDetails);
      console.log("check data ---" + JSON.stringify(this.sendPharmacy))
    }

    if (this.sendPharmacy.length != 0) {
      this.medcalDetailsArray = [];
      console.log("check sendPharmacy )))))(--" + this.sendPharmacy.length + JSON.stringify(this.sendPharmacy));
      if (this.insuranceFlag != false) {
        var sno=1;
        for (var x = 0; x < this.sendPharmacy.length; x++) {
          for (var y = 0; y < this.sendPharmacy[x].billDetails.length; y++) {
            var pharmadata = parseFloat(this.sendPharmacy[x].billDetails[y].amount).toFixed(2)
            this.medcalDetailsArray.push({
              sno: sno,
              name: this.sendPharmacy[x].billDetails[y].name,
              batch_no: this.sendPharmacy[x].billDetails[y].batch_no,
              quantity: this.sendPharmacy[x].billDetails[y].quantity,
              concession: this.sendPharmacy[x].billDetails[y].oncession,
              amount: pharmadata,
              bill_date: this.sendPharmacy[x].bill_date
            })
            sno +=1;
          }
        }
      } else {
        var sno=1;
        for (var x = 0; x < this.sendPharmacy.length; x++) {
          var pharmadata1 = parseFloat(this.sendPharmacy[x].temp_balance).toFixed(2);
          this.medcalDetailsArray.push({
            sno: sno,
            name: this.sendPharmacy[x].order_id,
            amount: pharmadata1,
            bill_date: this.sendPharmacy[x].bill_date
          })
          sno +=1;          
        }
      }
    }

    if (this.sendDiagnosis.length != 0) {
      this.digDetailsArray = [];
      var sno=1;
      for (var x = 0; x < this.sendDiagnosis.length; x++) {
        console.log("BILL DETAILS " + JSON.stringify(this.sendDiagnosis[x].billDetails))
        for (var y = 0; y < this.sendDiagnosis[x].billDetails.length; y++) {
          var diagdata = parseFloat(this.sendDiagnosis[x].billDetails[y].amount).toFixed(2);
          this.digDetailsArray.push({
            sno: this.sendDiagnosis[x].billDetails[y].sno,
            name: this.sendDiagnosis[x].billDetails[y].name,
            amount: diagdata,
            quantity: this.sendDiagnosis[x].billDetails[y].quantity,
            fee: this.sendDiagnosis[x].billDetails[y].fee,
            concession: this.sendDiagnosis[x].billDetails[y].concession,
            bill_date: this.sendDiagnosis[x].bill_date
          })
          sno +=1;
        }
      }
    }
    this.combinedArray = [...this.sendDoctor, ...this.sendPharmacy, ...this.sendDiagnosis];
    // Loop through each object in the array
    this.calculateCombinedValue();
  }

  createGroupping1(billDetails) {
    console.log(billDetails);
    var categoryChargeList = billDetails;
    const groupedByCategory = this.groupBy(categoryChargeList, 'category');
    console.log("groupedByCategory",groupedByCategory);
    var hospitalChargeArray2 = [];
    var new_viewchargearray = [];
    for (var key in groupedByCategory) {
      console.log(key + ': ' + JSON.stringify(groupedByCategory[key]));
      if (key !== 'undefined') {
        console.log(key);
        new_viewchargearray = [];
        for (var k = 0; k < groupedByCategory[key].length; k++) {
            var charge_order_list = groupedByCategory[key][k].order_no;
            var category_Name = "";
            if (groupedByCategory.length > 1) {
              category_Name = groupedByCategory[key][k].category;
            } 

            new_viewchargearray.push({
              sno: k + 1,
              name: groupedByCategory[key][k].name,
              quantity: groupedByCategory[key][k].quantity,
              amount: groupedByCategory[key][k].amount,
              concession: groupedByCategory[key][k].concession,
              fee: groupedByCategory[key][k].fee,
              bill_date: groupedByCategory[key][k].bill_date,
              category: key,
              charge_cat_id: groupedByCategory[key][k].charge_cat_id,
              order_no: charge_order_list,
            })
        }
        var category_flag;
        if (new_viewchargearray.length == 0) 
          category_flag = false;
        else 
          category_flag = true;
        
        hospitalChargeArray2.push({
            category: key,
            category_flag: category_flag,
            order_no: charge_order_list,
            charges: new_viewchargearray.sort((a, b) => a.order_no - b.order_no),
        })
      }  
    }

    categoryChargeList = [];
    categoryChargeList = hospitalChargeArray2.sort((a,b)=> a.order_no - b.order_no);
    var rowid = 1;
    for (var i = 0; i < categoryChargeList.length; i++) {
      for (var j = 0; j < categoryChargeList[i].charges.length; j++) {
        categoryChargeList[i].charges[j].sno = rowid;
        rowid += 1;
      }
    }  
    console.log("hospitalChargeArray2", hospitalChargeArray2);
    console.log("categoryChargeList",categoryChargeList);
    return  categoryChargeList;   
  }

  allSelected(value) {
    this.totalPaidAmountvalue = "0";
    this.totalBalanceAmountvalue = "0";
    this.totalBillAmountvalue = "0.00";
    this.totalDiscountvalue = "0";
    this.total_advancevalue = "0";
    this.discount_rembalance = 0.00;
    this.remaining_billbal="0.00";
    this.selectedAllFlage = value == true ? true : false;
    console.log("selectedAllFlage",this.selectedAllFlage);
    if (this.bill_type == "doctor") {
      if (this.selectedAllFlage == true) {
        if ((this.docBills.length != this.sendDoctor.length) || this.sendDoctor.length == 0) {
          this.sendDoctor = [];
          for (var k = 0; k < this.docBills.length; k++) {
            var flag;
            if (parseFloat(this.docBills[k].balance) == 0.00) {
              flag = "Fully paid";
            } else if (parseFloat(this.docBills[k].balance) == parseFloat(this.docBills[k].bill_amount)) {
              flag = "un paid";
            } else {
              flag = "Partially paid"
            }
            console.log("doc bills " + JSON.stringify(this.docBills))
            console.log("doc sendDoctor " + JSON.stringify(this.sendDoctor))
            this.bill_no = this.docBills[k].billing_id;
            this.sendDoctor.push({
              billing_id: this.docBills[k].billing_id,
              hptl_clinic_id: this.hptl_clinic_id,
              paid_amount: this.docBills[k].paid_amount,
              balance: this.docBills[k].balance,
              paid_flag: flag,
              advance: this.docBills[k].advance,
              temp_balance: this.docBills[k].balance,
              fin_year: this.docBills[k].fin_year,
              billDetails: this.combineCharges(this.docBills[k].billsList),
              paidamountprint: this.docBills[k].paid_amount,
              balance_amountprint: this.docBills[k].balance,
              bill_amountprint: this.docBills[k].bill_amount,
              discountprint: this.docBills[k].discount != undefined ? this.docBills[k].discount : "0.00",
              bill_date: this.docBills[k].bill_date
            });
            this.docBills[k].checked = this.selectedAllFlage;
            this.final_amount = (parseFloat(this.final_amount) + parseFloat(this.docBills[k].balance)).toFixed(2);
            this.totalDiscount = (parseFloat(this.totalDiscount) + parseFloat(this.docBills[k].discount)).toFixed(2);
            this.totalBill = (parseFloat(this.totalBill) + parseFloat(this.docBills[k].bill_amount)).toFixed(2);
            this.totalPaid = (parseFloat(this.totalPaid) + parseFloat(this.docBills[k].paid_amount)).toFixed(2);
          }
        } 
      } else {
        this.sendDoctor = [];
        for (var k = 0; k < this.docBills.length; k++) {
          this.docBills[k].checked = this.selectedAllFlage;
          this.final_amount = (parseFloat(this.final_amount) - parseFloat(this.docBills[k].balance)).toFixed(2);
          this.totalDiscount = (parseFloat(this.totalDiscount) - parseFloat(this.docBills[k].discount)).toFixed(2);
          this.totalBill = (parseFloat(this.totalBill) - parseFloat(this.docBills[k].bill_amount)).toFixed(2);
          this.totalPaid = (parseFloat(this.totalPaid) - parseFloat(this.docBills[k].paid_amount)).toFixed(2);
        }
      }
    } else if (this.bill_type == "pharma") {
      if (this.selectedAllFlage == true) {
        if ((this.medBills.length != this.sendPharmacy.length) || this.sendPharmacy.length == 0) {
          this.sendPharmacy = [];
          for (var k = 0; k < this.medBills.length; k++) {
            var flag;
            if (parseFloat(this.medBills[k].balance) == 0.00) {
              flag = "Fully paid";
            } else if (parseFloat(this.medBills[k].balance) == parseFloat(this.medBills[k].bill_amount)) {
              flag = "un paid";
            } else {
              flag = "Partially paid"
            }
            this.sendPharmacy.push({
              order_id: this.medBills[k].billing_id,
              pharma_id: this.medBills[k].pharma_id,
              paid_amount: this.medBills[k].paid_amount,
              balance: this.medBills[k].balance,
              paidamountprint: this.medBills[k].paid_amount,
              balance_amountprint: this.medBills[k].balance,
              bill_amountprint: this.medBills[k].bill_amount,
              discountprint: this.medBills[k].discount != undefined ? this.medBills[k].discount : "0.00",
              paid_flag: flag,
              temp_balance: this.medBills[k].balance,
              fin_year: this.medBills[k].fin_year,
              billDetails: this.medBills[k].billsList,
              advance: this.medBills[k].advance != undefined ? this.medBills[k].advance : "0.00",
              bill_date: this.medBills[k].bill_date
            });
            this.medBills[k].checked = this.selectedAllFlage;
            this.final_amount = (parseFloat(this.final_amount) + parseFloat(this.medBills[k].balance)).toFixed(2);
            this.mediBillAmount = (parseFloat(this.mediBillAmount) + parseFloat(this.medBills[k].bill_amount)).toFixed(2);
            this.totalDiscount = (parseFloat(this.totalDiscount) + parseFloat(this.medBills[k].discount)).toFixed(2);
            this.totalBill = (parseFloat(this.totalBill) + parseFloat(this.medBills[k].bill_amount)).toFixed(2);
            this.totalPaid = (parseFloat(this.totalPaid) + parseFloat(this.medBills[k].paid_amount)).toFixed(2);
          }
        }
      } else {
        this.sendPharmacy = [];
        for (var k = 0; k < this.medBills.length; k++) {
          this.medBills[k].checked = this.selectedAllFlage;
          this.final_amount = (parseFloat(this.final_amount) - parseFloat(this.medBills[k].balance)).toFixed(2);
          this.mediBillAmount = (parseFloat(this.mediBillAmount) - parseFloat(this.medBills[k].bill_amount)).toFixed(2);
          this.totalDiscount = (parseFloat(this.totalDiscount) - parseFloat(this.medBills[k].discount)).toFixed(2);
          this.totalBill = (parseFloat(this.totalBill) - parseFloat(this.medBills[k].bill_amount)).toFixed(2);
          this.totalPaid = (parseFloat(this.totalPaid) - parseFloat(this.medBills[k].paid_amount)).toFixed(2);
        }
      }
    } else {
      if (this.selectedAllFlage == true) {
        if ((this.diagBills.length != this.sendDiagnosis.length) || this.sendDiagnosis.length == 0) {
          this.sendDiagnosis = [];
          var sno=1;
          for (var k = 0; k < this.diagBills.length; k++) {
            var flag;
            if (parseFloat(this.diagBills[k].balance) == 0.00) {
              flag = "Fully paid";
            } else if (parseFloat(this.diagBills[k].balance) == parseFloat(this.diagBills[k].bill_amount)) {
              flag = "un paid";
            } else {
              flag = "Partially paid";
            }
            this.sendDiagnosis.push({
              billing_id: this.diagBills[k].billing_id,
              diag_centre_id: this.diag_centre_id,
              paid_amount: this.diagBills[k].paid_amount,
              balance: this.diagBills[k].balance,
              paid_flag: flag,
              temp_balance: this.diagBills[k].balance,
              fin_year: this.diagBills[k].fin_year,
              billDetails: this.diagBills[k].billsList,
              advance: this.diagBills[k].advance != undefined ? this.diagBills[k].advance : "0.00",
              paidamountprint: this.diagBills[k].paid_amount,
              balance_amountprint: this.diagBills[k].balance,
              bill_amountprint: this.diagBills[k].bill_amount,
              bill_date: this.diagBills[k].bill_date,
              discountprint: this.diagBills[k].discount != undefined ? this.diagBills[k].discount : "0.00",
            });
            sno +=1;
            this.diagBills[k].checked = this.selectedAllFlage;
            this.final_amount = (parseFloat(this.final_amount) + parseFloat(this.diagBills[k].balance)).toFixed(2);
            this.totalBill = (parseFloat(this.totalBill) + parseFloat(this.diagBills[k].bill_amount)).toFixed(2);
            this.totalPaid = (parseFloat(this.totalPaid) + parseFloat(this.diagBills[k].paid_amount)).toFixed(2);
          }
        }
      } else {
        this.sendDiagnosis = [];
        for (var k = 0; k < this.diagBills.length; k++) {
          this.diagBills[k].checked = this.selectedAllFlage;
          this.final_amount = (parseFloat(this.final_amount) - parseFloat(this.diagBills[k].balance)).toFixed(2);
          this.totalBill = (parseFloat(this.totalBill) - parseFloat(this.diagBills[k].bill_amount)).toFixed(2);
          this.totalPaid = (parseFloat(this.totalPaid) - parseFloat(this.diagBills[k].paid_amount)).toFixed(2);
        }
      }
    }

    if (this.sendDoctor.length != 0) {
      this.docBillDetails = [];
      var sno=1;
      for (var j = 0; j < this.sendDoctor.length; j++) {
        for (var k = 0; k < this.sendDoctor[j].billDetails.length; k++) {
          var docfee = parseFloat(this.sendDoctor[j].billDetails[k].fee).toFixed(2);
          this.docBillDetails.push({
            sno:sno,
            name: this.sendDoctor[j].billDetails[k].name,
            quantity: this.sendDoctor[j].billDetails[k].quantity,
            amount: parseFloat(this.sendDoctor[j].billDetails[k].amount).toFixed(2),
            concession: this.sendDoctor[j].billDetails[k].concession,
            fee: docfee,
            bill_date: this.sendDoctor[j].bill_date,
            category: this.sendDoctor[j].billDetails[k].category,
            charge_cat_id: this.sendDoctor[j].billDetails[k].charge_cat_id,
            order_no: this.sendDoctor[j].billDetails[k].order_no
          })
          sno +=1;
        }
      }
      this.docBillDetails = this.createGroupping1(this.docBillDetails);
    }

    if (this.sendPharmacy.length != 0) {
      console.log("check sendPharmacy ((--" + this.sendPharmacy.length + JSON.stringify(this.sendPharmacy))
      this.medcalDetailsArray = [];
      var sno=1;
      if (this.insuranceFlag != false) {
        for (var x = 0; x < this.sendPharmacy.length; x++) {
          for (var y = 0; y < this.sendPharmacy[x].billDetails.length; y++) {
            var pharmadata = parseFloat(this.sendPharmacy[x].billDetails[y].amount).toFixed(2)
            this.medcalDetailsArray.push({
              sno: sno,
              name: this.sendPharmacy[x].billDetails[y].name,
              batch_no: this.sendPharmacy[x].billDetails[y].batch_no,
              quantity: this.sendPharmacy[x].billDetails[y].quantity,
              concession: this.sendPharmacy[x].billDetails[y].oncession,
              amount: pharmadata,
              bill_date: this.sendPharmacy[x].bill_date
            })
            sno +=1;
          }
        }
      } else {
        var sno=1;
        for (var x = 0; x < this.sendPharmacy.length; x++) {
          var pharmadata1 = parseFloat(this.sendPharmacy[x].temp_balance).toFixed(2)
          this.medcalDetailsArray.push({
            sno: sno,
            name: this.sendPharmacy[x].order_id,
            amount: pharmadata1,
            bill_date: this.sendPharmacy[x].bill_date
          })
          sno +=1;
        }
      }
    }

    if (this.sendDiagnosis.length != 0) {
      this.digDetailsArray = [];
      var sno=1;
      for (var x = 0; x < this.sendDiagnosis.length; x++) {
        for (var y = 0; y < this.sendDiagnosis[x].billDetails.length; y++) {
          var diagdata = parseFloat(this.sendDiagnosis[x].billDetails[y].amount).toFixed(2)
          this.digDetailsArray.push({
            sno:sno,
            name: this.sendDiagnosis[x].billDetails[y].name,
            amount: diagdata,
            quantity: this.sendDiagnosis[x].billDetails[y].quantity,
            fee: this.sendDiagnosis[x].billDetails[y].fee,
            concession: this.sendDiagnosis[x].billDetails[y].concession,
            bill_date: this.sendDiagnosis[x].bill_date
          })
          sno +=1;
        }
      }
    }
    console.log("calculateCombinedValue  "+this.totalPaidAmountvalue);
    console.log("calculateCombinedValue  "+this.totalBalanceAmountvalue);
    this.combinedArray = [...this.sendDoctor, ...this.sendDiagnosis, ...this.sendPharmacy]; 
    this.checkBalance();
    this.calculateCombinedValue();     
  }

  calculateCombinedValue(){
    this.refund_amount = undefined;
    this.refund_reason = '';
    for (var item of this.combinedArray) {   
      this.totalPaidAmountvalue = (parseFloat(this.totalPaidAmountvalue) + parseFloat(item.paidamountprint)).toFixed(2);
      this.totalBalanceAmountvalue = (parseFloat(this.totalBalanceAmountvalue) + parseFloat(item.balance_amountprint)).toFixed(2);
      this.totalBillAmountvalue = (parseFloat(this.totalBillAmountvalue) + parseFloat(item.bill_amountprint)).toFixed(2);
      this.totalDiscountvalue = (parseFloat(this.totalDiscountvalue) + parseFloat(item.discountprint)).toFixed(2);
      this.total_advancevalue = (parseFloat(this.total_advancevalue) + parseFloat(item.advance)).toFixed(2);
    }
    console.log("calculateCombinedValue  "+this.totalPaidAmountvalue)
    console.log("calculateCombinedValue  "+this.totalBalanceAmountvalue)
    console.log("calculateCombinedValue  "+this.totalBillAmountvalue)
    console.log("calculateCombinedValue  "+this.totalDiscountvalue)
    console.log("calculateCombinedValue  "+this.total_advancevalue)
    // Calculate paid_bill
    this.temppaid_bill = (parseFloat(this.totalPaidAmountvalue) - parseFloat(this.total_advancevalue)).toFixed(2);
    this.paid_bill = parseFloat(this.totalPaidAmountvalue).toFixed(2);
    this.amountval = parseFloat(this.paid_bill).toFixed(2);
    this.discountvaluecheck = parseFloat(this.totalDiscountvalue);      
    var totalAdvance = parseFloat(this.total_advance);
    var totalAdvanceBills = !isNaN(this.totalAdvance_bills) ? this.totalAdvance_bills : 0;
    this.remainingbalance_advance = (totalAdvance - totalAdvanceBills).toFixed(2);
    console.log("this.remainingbalance_advance   **"+totalAdvance+"????"+totalAdvanceBills)
    console.log("this.remainingbalance_advance   "+this.remainingbalance_advance)
    // Calculate remaining balance
    if (this.approved_amount !== undefined) {
      var deductval = parseFloat(this.totalPaidAmountvalue) + totalAdvance + parseFloat(this.approved_amount)+this.discountvaluecheck;
      this.remaining_billbal = (parseFloat(this.totalBillAmountvalue) - deductval).toFixed(2);
    } else {
      deductval =parseFloat(this.totalPaidAmountvalue) + totalAdvance+this.discountvaluecheck;
      this.remaining_billbal = (parseFloat(this.totalBillAmountvalue) - deductval).toFixed(2);
      console.log("calculateCombinedValue  --"+this.totalBillAmountvalue +" ...."+deductval)
      console.log("calculateCombinedValue  --"+this.remaining_billbal +" ...."+deductval)
    }

    if (this.totalBillAmountvalue.trim() === this.paid_bill.trim()) {
      this.paidflag = false;
    } else {
      this.paidflag = true;
    }
    if (this.totalDiscountvalue === "0" || this.totalDiscountvalue === "0.00" || this.totalDiscountvalue === "0.0") {
      this.discountflag = false;
    } else {
      this.discountflag = true;
    }
    if (this.remaining_billbal === "0.00") {
      this.balanceflag = false;
    } else {
      this.balanceflag = true;
    }
    if (this.total_advance === "0.00" || this.total_advance === "0") {
      this.advanceflag = false;
    }
    else {
      this.advanceflag = true;
    }
   
    if (parseInt(this.total_advance) > parseInt(this.totalBillAmountvalue)) {
      console.log(" if this.total_advance ----"+this.total_advance+"????this.remaining_billbal???"+this.totalBillAmountvalue);
      this.adv_refflag = true;
      this.refund_amount = Math.abs(this.remaining_billbal);  
      this.remaining_billbal= parseFloat(this.refund_amount).toFixed(2);
    } else {
      this.adv_refflag = false;
      this.refund_amount = undefined;   
    }
    console.log("check data value" + this.totalPaidAmountvalue + this.totalBalanceAmountvalue + this.totalBillAmountvalue);
    console.log("check discount value" + this.totalDiscountvalue);
    console.log("check ---" + parseFloat(this.totalBillAmountvalue) + "?????" + this.discountvaluecheck + this.total_advance + this.paid_bill);
    this.subtotalCalcFrPrint();
  }

  checkBalance1() {
    this.paid_bill = "0.00";
  }

  checkBalance() { 
    console.log("rem 00 " + this.remainingbalance_advance);
    console.log("cjec 00 " + this.paid_bill + this.totalPaidAmountvalue + "approved_amount" + this.approved_amount);
    if (this.paid_bill != "") {
      this.balance_show = false;
      var advanceBalance = (parseFloat(this.total_advance)).toFixed(2);
      var paidBalance = (parseFloat(this.paid_bill)).toFixed(2);
      console.log("change paid=== this.paid_billthis.paid_bill" + this.paid_bill);
      this.paid_bill1 = (parseFloat(this.paid_bill) + parseFloat(this.totalPaidAmountvalue)).toFixed(2);
      this.paid_bill = this.paid_bill1;
      console.log("change paidBalance === iffffffffff" + paidBalance)
      console.log("change paid=== iffffffffff" + this.totalBillAmountvalue + "???????" + this.paid_bill1 + this.total_advancevalue + this.approved_amount)
      if (this.approved_amount != undefined) {
        var totaldeduct = parseFloat(this.paid_bill1) + parseFloat(this.total_advance)+this.discountvaluecheck+ parseFloat(this.approved_amount);
        this.remaining_billbal = (parseFloat(this.totalBillAmountvalue) - totaldeduct).toFixed(2);
      } else {
        var totaldeduct = parseFloat(this.paid_bill1) + parseFloat(this.total_advance)+this.discountvaluecheck;
        this.remaining_billbal = (parseFloat(this.totalBillAmountvalue) - totaldeduct).toFixed(2);
      }
      console.log("change paid=== this.paid_billthis.paid_bill" + this.discountvaluecheck);
      console.log("this.totalDiscountvalue paid=== this.paid_billthis.paid_bill" + this.totalDiscountvalue );
      if (this.sendDoctor.length != 0) {
        for (var i = 0; i < this.sendDoctor.length; i++) {
          if (parseFloat(advanceBalance) != 0.00 || parseFloat(paidBalance) != 0.00) {
            if (parseFloat(advanceBalance) != 0.00) {
              console.log("change paid=== tuygg"+this.remainingbalance_advance)
              console.log("change paidBalance=== iffffffffff" + this.sendDoctor[i].temp_balance)
              if (parseFloat(this.sendDoctor[i].temp_balance) <= parseFloat(this.remainingbalance_advance)) {
                this.sendDoctor[i].paid_amount = "0.00";
                this.sendDoctor[i].advance =(parseFloat(this.sendDoctor[i].temp_balance)).toFixed(2);
                this.sendDoctor[i].balance = "0.00";
                this.sendDoctor[i].paid_flag = "Fully paid";
                //advanceBalance = (parseFloat(advanceBalance) - parseFloat(this.sendDoctor[i].temp_balance)).toFixed(2);
                this.remainingbalance_advance = (parseFloat(this.remainingbalance_advance) - parseFloat(this.sendDoctor[i].temp_balance)).toFixed(2);

              } else {
                console.log("change else=== iffffffffff" + JSON.stringify(this.sendDoctor))
                var getRemindingAmount = (parseFloat(this.sendDoctor[i].temp_balance) - parseFloat(this.remainingbalance_advance)).toFixed(2);
                console.log("change else=== getRemindingAmount" + JSON.stringify(this.sendDoctor[i].temp_balance)+this.remainingbalance_advance)
                console.log("change paidBalance" + JSON.stringify(paidBalance)+getRemindingAmount)

                if (parseFloat(getRemindingAmount) <= parseFloat(paidBalance)) {
                  console.log("change if=== else" + JSON.stringify(this.sendDoctor))
                //  this.sendDoctor[i].paid_amount =  (parseFloat(this.sendDoctor[i].paidamountprint) + (parseFloat(this.sendDoctor[i].balance_amountprint))).toFixed(2);
                  this.sendDoctor[i].paid_amount = parseFloat(getRemindingAmount).toFixed(2);
                  this.sendDoctor[i].advance = (parseFloat(this.remainingbalance_advance)).toFixed(2);
                  this.sendDoctor[i].balance = "0.00";
                  this.sendDoctor[i].paid_flag = "Fully paid";
                  paidBalance = (parseFloat(paidBalance) - parseFloat(getRemindingAmount)-parseFloat(this.remainingbalance_advance)).toFixed(2);
                  console.log("after paidBalance" + JSON.stringify(paidBalance))
                  if((this.remainingbalance_advance) <= parseFloat(paidBalance)){
                    this.remainingbalance_advance ="0.00"
                  }

                } else {
                  console.log("change else=== else this.remainingbalance" + JSON.stringify(this.sendDoctor)+paidBalance+this.remainingbalance_advance)
                  var remaingBal = (parseFloat(this.sendDoctor[i].temp_balance) - (parseFloat(paidBalance) )-(parseFloat(this.remainingbalance_advance) )).toFixed(2);
                  this.sendDoctor[i].paid_amount = (parseFloat(this.sendDoctor[i].paidamountprint) + (parseFloat(paidBalance))).toFixed(2);
                  console.log("remaingBal else=== else" + JSON.stringify(remaingBal))
                  this.sendDoctor[i].balance = remaingBal;
                  this.sendDoctor[i].advance = (parseFloat(this.remainingbalance_advance)).toFixed(2);
                  this.sendDoctor[i].paid_flag = "Partially paid";
                  paidBalance = "0.00";
                }
              //  advanceBalance = "0.00";
               // break;
              }

            } else {
              //WORKING
              console.log("change paid=== else" + JSON.stringify(this.sendDoctor))
              if (parseFloat(this.sendDoctor[i].temp_balance) <= parseFloat(paidBalance)) {
                console.log("change 2=== if" + paidBalance)
                this.sendDoctor[i].paid_amount = (parseFloat(this.sendDoctor[i].paidamountprint) + parseFloat(this.sendDoctor[i].balance_amountprint)).toFixed(2)
                this.sendDoctor[i].advance = parseFloat(this.sendDoctor[i].advance).toFixed(2);
                this.sendDoctor[i].balance = "0.00";
                this.sendDoctor[i].paid_flag = "Fully paid";
                paidBalance = (parseFloat(paidBalance) - parseFloat(this.sendDoctor[i].temp_balance)).toFixed(2);
                this.totalPaidAmountvalue = (parseFloat(this.sendDoctor[i].paidamountprint) + parseFloat(this.sendDoctor[i].temp_balance)).toFixed(2);
                // this.totalPaidAmountvalue +=  (parseFloat(this.totalPaidAmountvalue)+paidAmount1).toFixed(1);
                this.totalBalanceAmountvalue = "0.00"
              } else {
                console.log("change 2=== else")
                var remaingBal = (parseFloat(this.sendDoctor[i].temp_balance) - parseFloat(paidBalance)).toFixed(2);
                this.sendDoctor[i].paid_amount = parseFloat(paidBalance);
                this.sendDoctor[i].balance = remaingBal;
                this.sendDoctor[i].advance = parseFloat(this.sendDoctor[i].advance).toFixed(2);
                this.sendDoctor[i].paid_flag = "Partially paid";
                paidBalance = "0.00";
                break;
              }
            }
          }
        }
      }
      if (parseFloat(advanceBalance) != 0.00 || parseFloat(paidBalance) != 0.00) {
        if (this.sendPharmacy.length != 0) {
          console.log("check pahrama------------" + JSON.stringify(this.sendPharmacy))

          for (var i = 0; i < this.sendPharmacy.length; i++) {
            if (parseFloat(advanceBalance) != 0.00) {
              console.log("change getRemindingAmount=== iffffffffff" + JSON.stringify(this.sendPharmacy[i].temp_balance)+advanceBalance)

              if (parseFloat(this.sendPharmacy[i].temp_balance) <= parseFloat(this.remainingbalance_advance)) {
                this.sendPharmacy[i].paid_amount = "0.00";                
                this.sendPharmacy[i].advance = (parseFloat(this.sendPharmacy[i].temp_balance) ).toFixed(2);
                this.sendPharmacy[i].balance = "0.00";
                this.sendPharmacy[i].paid_flag = "Fully paid";
                this.remainingbalance_advance = (parseFloat(this.remainingbalance_advance) - parseFloat(this.sendPharmacy[i].temp_balance)).toFixed(2);

              } else {
                console.log("change else=== iffffffffff" + JSON.stringify(this.sendPharmacy))
                var getRemindingAmount = (parseFloat(this.sendPharmacy[i].temp_balance) - parseFloat(this.remainingbalance_advance)).toFixed(2);
                console.log("change else=== iffffffffff" + JSON.stringify(getRemindingAmount)+paidBalance)
                if (parseFloat(getRemindingAmount) <= parseFloat(paidBalance)) {
                  this.sendPharmacy[i].paid_amount = parseFloat(getRemindingAmount).toFixed(2);
                  this.sendPharmacy[i].advance = (parseFloat(this.remainingbalance_advance)).toFixed(2);
                  this.sendPharmacy[i].balance = "0.00";
                  this.sendPharmacy[i].paid_flag = "Fully paid";
                  paidBalance = (parseFloat(paidBalance) - parseFloat(getRemindingAmount)).toFixed(2);
                  if((this.remainingbalance_advance) <= parseFloat(paidBalance)){
                    this.remainingbalance_advance ="0.00"
                  }

                } else {
                  console.log("change diaggetRemindingAmount=== else" + JSON.stringify(getRemindingAmount)+paidBalance+advanceBalance)
                  var tempPaidBal = (parseFloat(getRemindingAmount) - parseFloat(paidBalance)).toFixed(2)
                  var remaingBal = (parseFloat(this.sendPharmacy[i].temp_balance) - (parseFloat(paidBalance) )-(parseFloat(this.remainingbalance_advance) )).toFixed(2);
                  // this.sendPharmacy[i].paid_amount = parseFloat(paidBalance).toFixed(2);
                  this.sendPharmacy[i].paid_amount = (parseFloat(this.sendPharmacy[i].paidamountprint) + (parseFloat(paidBalance))).toFixed(2);

                  this.sendPharmacy[i].balance = remaingBal;
                  this.sendPharmacy[i].advance = (parseFloat(this.remainingbalance_advance)).toFixed(2);
                  this.sendPharmacy[i].paid_flag = "Partially paid";
                  paidBalance = "0.00";
                }
               // advanceBalance = "0.00";
              }

            } else if (parseFloat(paidBalance) != 0.00) {
              //WORKING
              console.log("check pahrama-----paidBalance-------" + JSON.stringify(this.sendPharmacy[i].temp_balance)+parseFloat(paidBalance))
              console.log("check pahrama-----paidBalance-------" + JSON.stringify(this.sendPharmacy)+parseFloat(paidBalance))
              if (parseFloat(this.sendPharmacy[i].temp_balance) <= parseFloat(paidBalance)) {
                console.log("check pahrama--if---paidBalance-------" + JSON.stringify(this.sendPharmacy[i].temp_balance)+parseFloat(paidBalance))

                this.sendPharmacy[i].paid_amount =  (parseFloat(this.sendPharmacy[i].paidamountprint) + parseFloat(this.sendPharmacy[i].balance_amountprint)).toFixed(2);;
                this.sendPharmacy[i].advance = "0.00";
                this.sendPharmacy[i].balance = "0.00";
                this.sendPharmacy[i].paid_flag = "Fully paid";
                paidBalance = (parseFloat(paidBalance) - parseFloat(this.sendPharmacy[i].temp_balance)).toFixed(2);

              } else {
                console.log("check pahrama--else---paidBalance-------" + JSON.stringify(this.sendPharmacy[i].temp_balance)+parseFloat(paidBalance))
                var remaingBal = (parseFloat(this.sendPharmacy[i].temp_balance) - parseFloat(paidBalance)).toFixed(2);
                this.sendPharmacy[i].paid_amount = (parseFloat(this.sendPharmacy[i].paidamountprint) + parseFloat(paidBalance)).toFixed(2);
                this.sendPharmacy[i].balance = remaingBal;
                this.sendPharmacy[i].advance = "0.00";
                this.sendPharmacy[i].paid_flag = "Partially paid";
                paidBalance = "0.00";
              }
              advanceBalance = "0.00";
            } else {
              break;
            }
          }
        }
      }
      if (parseFloat(advanceBalance) != 0.00 || parseFloat(paidBalance) != 0.00) {
        if (this.sendDiagnosis.length != 0) {
          for (var i = 0; i < this.sendDiagnosis.length; i++) {
            console.log("this.sendDiagnosisthis.sendDiagnosisthis.sendDiagnosis--" + JSON.stringify(this.sendDiagnosis)+parseFloat(paidBalance))
            console.log("check sendDiagnosisthis.sendDiagnosisthis.sendDiagnosis--------" + JSON.stringify(this.sendDiagnosis[i].temp_balance)+parseFloat(this.remainingbalance_advance))

            if (parseFloat(advanceBalance) != 0.00) {
              if (parseFloat(this.sendDiagnosis[i].temp_balance) <= parseFloat(this.remainingbalance_advance)) {
                console.log("change === sendDiagnosis" + JSON.stringify(this.sendDiagnosis[i].temp_balance))

                this.sendDiagnosis[i].paid_amount = "0.00";
                this.sendDiagnosis[i].advance = (parseFloat(this.sendDiagnosis[i].temp_balance) ).toFixed(2);
                this.sendDiagnosis[i].balance = "0.00";
                this.sendDiagnosis[i].paid_flag = "Fully paid";
            //    advanceBalance = (parseFloat(this.remainingbalance_advance) - parseFloat(this.sendDiagnosis[i].temp_balance)).toFixed(2);
                this.remainingbalance_advance = (parseFloat(this.remainingbalance_advance) - parseFloat(this.sendDiagnosis[i].temp_balance)).toFixed(2);

              } else {
                var getRemindingAmount = (parseFloat(this.sendDiagnosis[i].temp_balance) - parseFloat(this.remainingbalance_advance)).toFixed(2);
                console.log("change === getRemindingAmount" + JSON.stringify(getRemindingAmount)+paidBalance)
                if (parseFloat(getRemindingAmount) <= parseFloat(paidBalance)) {
                  console.log("change === getRemindingAmount" + JSON.stringify(getRemindingAmount)+paidBalance)
                  this.sendDiagnosis[i].paid_amount = parseFloat(getRemindingAmount).toFixed(2);
                  this.sendDiagnosis[i].advance = (parseFloat(this.remainingbalance_advance)).toFixed(2);
                  this.sendDiagnosis[i].balance = "0.00";
                  this.sendDiagnosis[i].paid_flag = "Fully paid";
                  paidBalance = (parseFloat(paidBalance) - parseFloat(getRemindingAmount)).toFixed(2);
                  if((this.remainingbalance_advance) <= parseFloat(paidBalance)){
                    this.remainingbalance_advance ="0.00"
                  }

                } else {
                  console.log("change ===else getRemindingAmount" + JSON.stringify(getRemindingAmount)+paidBalance)

                  var tempPaidBal = (parseFloat(getRemindingAmount) - parseFloat(paidBalance)).toFixed(2)
                  var remaingBal = (parseFloat(this.sendDiagnosis[i].temp_balance) - (parseFloat(paidBalance) )-(parseFloat(this.remainingbalance_advance) )).toFixed(2);
                  this.sendDiagnosis[i].paid_amount = (parseFloat(this.sendDiagnosis[i].paidamountprint) + (parseFloat(paidBalance))).toFixed(2);

                //  this.sendDiagnosis[i].paid_amount = tempPaidBal;
                  this.sendDiagnosis[i].balance = remaingBal;
                  this.sendDiagnosis[i].advance = (parseFloat(this.remainingbalance_advance)).toFixed(2);
                  this.sendDiagnosis[i].paid_flag = "Partially paid";
                  paidBalance = "0.00";
                }
               // advanceBalance = "0.00";
              }

            } else if (parseFloat(paidBalance) != 0.00) {
              //WORKING
              if (parseFloat(this.sendDiagnosis[i].temp_balance) <= parseFloat(paidBalance)) {
              console.log("check sendDiagnosis-----temp_balance-------" + JSON.stringify(this.sendDiagnosis[i].temp_balance)+parseFloat(paidBalance))
              console.log("check sendDiagnosis-----temp_balance-------" + JSON.stringify(this.sendDiagnosis)+parseFloat(paidBalance))
                this.sendDiagnosis[i].paid_amount = (parseFloat(this.sendDiagnosis[i].paidamountprint) + parseFloat(this.sendDiagnosis[i].balance_amountprint)).toFixed(2);
                this.sendDiagnosis[i].advance =  parseFloat(this.sendDiagnosis[i].advance).toFixed(2);;
                this.sendDiagnosis[i].balance = "0.00";
                this.sendDiagnosis[i].paid_flag = "Fully paid";
                paidBalance = (parseFloat(paidBalance) - parseFloat(this.sendDiagnosis[i].temp_balance)).toFixed(2);
                this.totalPaidAmountvalue = (parseFloat(this.sendDiagnosis[i].paidamountprint) + parseFloat(this.sendDiagnosis[i].temp_balance)).toFixed(2);
                // this.totalPaidAmountvalue +=  (parseFloat(this.totalPaidAmountvalue)+paidAmount1).toFixed(1);
                this.totalBalanceAmountvalue = "0.00"
    
              } else {
                console.log("check else-----temp_balance-------" + JSON.stringify(this.sendDiagnosis)+parseFloat(paidBalance))

                var remaingBal = (parseFloat(this.sendDiagnosis[i].temp_balance) - parseFloat(paidBalance)).toFixed(2);
                this.sendDiagnosis[i].paid_amount = (parseFloat(this.sendDiagnosis[i].paidamountprint) + parseFloat(paidBalance)).toFixed(2);
                this.sendDiagnosis[i].balance = remaingBal;
                this.sendDiagnosis[i].advance = parseFloat(this.sendDiagnosis[i].advance).toFixed(2);;
                this.sendDiagnosis[i].paid_flag = "Partially paid";
                paidBalance = "0.00";
              }
              advanceBalance = "0.00";
            } else {
              break;
            }
          }
        }
      };
      console.log("check sendDoctor--------***----" + JSON.stringify(this.sendDoctor));
      console.log("check sendPharmacy------------" + JSON.stringify(this.sendPharmacy));
      console.log("check sendDiagnosis------------" + JSON.stringify(this.sendDiagnosis));
      if (this.totalBillAmountvalue.trim() === this.paid_bill.trim()) {
        this.paidflag = false;
      } else {
        this.paidflag = true;
      }

      if (this.totalDiscountvalue === "0" || this.totalDiscountvalue === "0.00" || this.totalDiscountvalue === "0.0") {
        this.discountflag = false;
      } else {
        this.discountflag = true;
      }

      if (this.remaining_billbal === "0.00") {
        this.balanceflag = false;
      } else {
        this.balanceflag = true;
      }

      if (this.total_advance == "0.00" || this.total_advance === "0") {
        this.advanceflag = false;
      }
      else {
        this.advanceflag = true;
      }
    } else {
      this.paid_bill = "0.00";
    }
    this.paid_bill=this.checkAndAssignValue_paidbill(this.paid_bill);
    this.changePaidAmount();
  }

  changePaidAmount() { //add the bill details
    if (this.paymentsarray.length == 0) {
      this.payType1 = this.paytypelist[0].pay_id;
    }    
    this.amountval = parseFloat(this.paid_bill).toFixed(2);
  }

  checkAndAssignValue_paidbill(value: any): string {
    // Convert to number first and check if it's NaN
    let numValue = parseFloat(value);
    if (isNaN(numValue)) {
      return '0.00';
    }
    return value.toString();
  }

  printBill() {
    let printContents, popupWin;
    if (this.print_template != undefined && this.print_template == "banner") {
      printContents = this.printbanner.nativeElement.innerHTML; 
    } else if (this.print_template != undefined && this.print_template == "logowithname") {
      printContents = this.printlogowithname.nativeElement.innerHTML; 
    } else {  
      printContents = this.printnoheader.nativeElement.innerHTML;
    }

    popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
    <head>
      <title>Consolidated bill</title>
      <link rel="stylesheet" media="screen" href="">
      <style>
      @page { size: auto;  margin: 0mm; }
      .table_class {
        background-color: rgb(108, 180, 248);
      }
      p{
        margin:0;
      }
      * {
        font-size: ${this.print_font} !important; 
      }
      </style>
    </head>
        <body onload="window.print();window.onfocus=function(){ window.close();}">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }

  subtotalCalcFrPrint() {
    if (this.docBillDetails != undefined) {
      this.docBillSubtotal = 0;
      // for (var i = 0; i < this.docBillDetails.length; i++) {
      //   if (this.docBillDetails[i].fee != undefined) {
      //     this.docBillSubtotal = this.docBillSubtotal + parseInt(this.docBillDetails[i].fee);
      //   }
      // }
      for (var i = 0; i < this.docBillDetails.length; i++) {
        for (var j = 0; j < this.docBillDetails[i].charges.length; j++) {
          if (this.docBillDetails[i].charges[j].fee != undefined) {
            this.docBillSubtotal = this.docBillSubtotal + parseInt(this.docBillDetails[i].charges[j].fee);
          }
        }
      }  
    }
    this.docBillSubtotal = this.docBillSubtotal.toFixed(2);

    if (this.digDetailsArray != undefined) {
      this.diagBillSubtotal = 0;
      for (var j = 0; j < this.digDetailsArray.length; j++) {
        if (this.digDetailsArray[j].fee != undefined) {
          this.diagBillSubtotal = this.diagBillSubtotal + parseInt(this.digDetailsArray[j].fee);
        }
      }
    }
    this.diagBillSubtotal = this.diagBillSubtotal.toFixed(2);

    if (this.medcalDetailsArray != undefined) {
      this.medBillSubtotal = 0;
      for (var k = 0; k < this.medcalDetailsArray.length; k++) {
        if (this.medcalDetailsArray[k].fee != undefined && !isNaN(this.medcalDetailsArray[k].fee)) {
          this.medBillSubtotal = this.medBillSubtotal + parseInt(this.medcalDetailsArray[k].fee);
        } else if (this.medcalDetailsArray[k].amount != undefined && !isNaN(this.medcalDetailsArray[k].amount)) {
          this.medBillSubtotal = this.medBillSubtotal + parseInt(this.medcalDetailsArray[k].amount);
        }
      }
    }
    this.medBillSubtotal = this.medBillSubtotal.toFixed(2);
  }

  Bill_save() {
    var flag = true;
    if (this.sendDoctor.length == 0 && this.sendPharmacy.length == 0 && this.sendDiagnosis.length == 0) {
      this.toastr.error("Select atleast one bill type");
      flag = false;
    } else if (this.Advance_bill == "0.00" && this.paid_bill == "0.00") {
      this.toastr.error("Please fill paid amount");
      flag = false;
    }

    var advance;
    if (parseFloat(this.final_amount) == (parseFloat(this.paid_bill) + parseFloat(this.Advance_bill))) {
      advance = "0.00";
    } else if (parseFloat(this.final_amount) < parseFloat(this.Advance_bill)) {
      advance = ((parseFloat(this.Advance_bill) - parseFloat(this.final_amount))).toFixed(2);
    } else {
      advance = "0.00";
    }

    if (flag == true) {
      console.log("Doctor bills " + JSON.stringify(this.sendDoctor))
      console.log("PHARMA BILLS " + JSON.stringify(this.sendPharmacy))
      console.log("DIAGNOSIS BILLS " + JSON.stringify(this.sendDiagnosis))
      var sendData:any = {
        client_reg_id: this.client_id,
        advance_balance: advance,
        hptl_clinic_id: this.hptl_clinic_id,
        appointment_id: this.app_id,
        pharma: this.sendPharmacy,
        diagnosis: this.sendDiagnosis,
        doctor: this.sendDoctor,
        paytypes: this.paymentsarray,
        // refund: this.remaining_billbal,
      }
      
      if(this.adv_refflag == true){
        sendData.refund=this.remaining_billbal
      }
      if (this.refund_reason != '' && this.adv_refflag == true) {
        sendData.refund_reason = this.refund_reason;
      }
      console.log("send datanbjnb"+JSON.stringify(sendData));
      this.saveFlag = false;
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'bill/ucb/', sendData,
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            if (obj["result"] != null) {
              if (obj["key"] == "1") {
                this.toastr.success("Success");
              } else {
                this.toastr.error("Error");
                this.saveFlag = true;
              }
            }
          },
          error => {
            this.saveFlag = true;
            this.toastr.error(Message_data.getNetworkMessage());
          }
        )
    }
  }

  addPaymentDetails() {
    var bamt: any = 0,
      balance = 0;
    var results = this.finekeyInArray_Payment(
      this.paymentsarray,
      this.payType1
    );
    if (this.payType1 != undefined && this.payType1 != '') {
      for (var i = 0; i < this.paytypelist.length; i++) {
        if (this.payType1 == this.paytypelist[i].pay_id) {
          if (this.paymentsarray.length != 0) {
            for (var j = 0; j < this.paymentsarray.length; j++) {
              bamt += parseInt(this.paymentsarray[j].amount);
            }
            bamt += parseFloat(this.amountval);
          } else {
            bamt += parseFloat(this.amountval);
          }
  
          if (bamt > this.paid_bill) {
            this.toastr.error('Amount cannot be more than bill amount');
          } else if (this.amountval == 0 || this.amountval < 0) {
            this.toastr.error('Amount cannot be zero or minus');
          } else if (results == true) {
            this.toastr.error('Payment mode already exists');
          } else {
            if (
              this.amountval < parseFloat(this.paid_bill) ||
              this.amountval == parseFloat(this.paid_bill)
            ) {
              if (parseFloat(this.paid_bill) > parseFloat(this.totalBillAmountvalue)) {
                if (bamt > parseFloat(this.totalBillAmountvalue)) {
                  this.toastr.error('Amount cannot be more than bill amount');
                } else {
                  this.paymentsarray.push({
                    pay_id: this.paytypelist[i].pay_id,
                    pay_desc: this.paytypelist[i].pay_desc,
                    amount: parseFloat(this.amountval).toFixed(2),
                  });
                  this.amountval = parseFloat(this.totalBillAmountvalue) - bamt;
                  this.amountval = parseFloat(this.amountval).toFixed(2);
                  this.payType1 = '';
                }
              } else {
                if (bamt > parseFloat(this.paid_bill)) {
                  this.toastr.error('Amount cannot be more than bill amount');
                } else {
                  this.paymentsarray.push({
                    pay_id: this.paytypelist[i].pay_id,
                    pay_desc: this.paytypelist[i].pay_desc,
                    amount: parseFloat(this.amountval).toFixed(2),
                  });
                  this.amountval = parseFloat(this.paid_bill) - bamt;
                  this.amountval = parseFloat(this.amountval).toFixed(2);
                  this.payType1 = '';
                }
              }
            } else {
              this.amountval = 0;
            }
          }
        }
      }
    } else {
      this.toastr.error('Please select the payment mode');
    }   
  }

  editPayment(data) {
    this.payType1 = data.pay_id;
    this.amountval = parseInt(data.amount);
    for (var i = 0; i < this.paymentsarray.length; i++) {
      if (data.pay_id == this.paymentsarray[i].pay_id) {
        this.paymentsarray.splice(i, 1);
      }
    }
  }

  deletePayment(data) {
    for (var i = 0; i < this.paymentsarray.length; i++) {
      if (data.pay_id == this.paymentsarray[i].pay_id) {
        this.paymentsarray.splice(i, 1);
      }
    }
  }

  Filter_changename(data) {
    this.appointment_list = [];
    console.log("check data" + JSON.stringify(Helper_Class.getinpatientfilterdata()))
    if (data == "mrno") {
      this.mrnoflag = true;
      this.locationflag = false;
      this.nameflag = false;
      this.fdateflag = false;
      this.todateflag = false;
      this.mobileflag = false;
      this.To_show = true;
      this.mr_no = "";
      this.patient_selectionflag = true;
    }
    if (data == "name") {
      this.mrnoflag = false;
      this.nameflag = true;
      this.locationflag = false;
      this.fdateflag = false;
      this.todateflag = false;
      this.mobileflag = false;
      this.appoint_txt = "";
      this.patient_selectionflag = true;
    }
    if (data == "mobile") {
      this.fdateflag = true;
      this.todateflag = true;
      this.mrnoflag = false;
      this.nameflag = false;
      this.locationflag = false;
      this.mobileflag = true;
      this.To_show = true;
      this.mobile = "";
      this.patient_selectionflag = true;
    }
  }

  Filter_changevalue(value) {
    console.log("chekc value  --" + JSON.stringify(this.patientList));
    if (this.nameflag == true) {
      //  this.getAppointments('','name');
    }
    if (this.mobileflag == true) {
      this.getAppointments('', 'mobile','select');
    }
    if (this.locationflag == true) {
      this.getAppointments('', 'location','select');
    }
    if (this.mrnoflag == true) {
      this.getAppointments('', 'mr_no','select');
    }
    if (this.fdateflag == true && this.todateflag == true) {
      this.dateshow = true
      this.getAppointments('', 'date','select');
    }
  }

  advance_change() { }
  
  finekeyInArray_Payment(array, id) {
    var flag = false;
    for (var i = 0; i < array.length; i++) {
      if (array[i].pay_id === id) {
        flag = true;
        break;
      }
    }
    return flag;
  }

  setPrint_styles(color, font) {
    this.printFontSize = font;
    var commonStyle = "border: 1px solid" + color + ";border-collapse: collapse;";
    this.table_style = "border: 1px solid" + color + ";border-collapse: collapse;border-radius: 10px;margin-right: 20px;width:100%;";
    this.sno_style = "width: 6%;" + commonStyle;
    this.service_style = "width: 38%;" + commonStyle;
    this.date_style = "width: 19%;" + commonStyle;
    this.quant_style = "width: 13%;" + commonStyle;
    this.Amt_style = "width: 15%;" + commonStyle;
    this.sno_td_style = "text-align: left;" + commonStyle;
    this.service_td_style = "text-align: left;padding:2px 2px;" + this.service_style;
    this.date_td_style = "padding:2px 2px;text-align: center;" + this.date_style;
    this.quant_td_style = "text-align: right;" + this.quant_style;
    this.amt_td_style = "text-align: right;" + this.Amt_style;
    this.subtotal_style = commonStyle;
    this.doctor_subtotal_style = "border-style: hidden solid solid solid;" + commonStyle;
    this.not_ins_serviceStyle = "width: 45%;" + commonStyle;
    this.not_ins_dateStyle = "width: 22%;" + commonStyle;
    this.not_ins_amtStyle = commonStyle;
    this.not_ins_td_amtStyle = "text-align: right;" + commonStyle;
  }

  getPatients() {
    this.insuranceFlag = false;
    this.Insuranceflag = false;
    this.patientList = [];
    var data = null;
    var send_data = {
      doc_reg_id: this.dog_reg_id,
      hptl_clinic_id: this.hptl_clinic_id,
      country: ipaddress.country_code.toString(),
      imei: Helper_Class.getIPAddress(),
      type: "doctor",
      floor: this.floorvalue
    }
    console.log("INPATIENT send_data " + JSON.stringify(send_data))
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'inpat/gpat',
      (send_data),
      { headers: headers })
      .subscribe(
        response => {
          this.floorarray = [];
          this.patientList = [];
          var data = response.json();
          console.log("check datta " + JSON.stringify(data));
          if (data.floors != undefined) {
            this.floorarray.push("All")
            for (var k = 0; k < data.floors.length; k++) {
              this.floorarray.push(data.floors[k])
            }
          }
          if (data.patients != undefined && data.patients.length != 0) {
            this.count = data.patients.length;
            for (var i = 0; i < data.patients.length; i++) {
              var values = data.patients[i];
              var pname, fname, lname, mname, fullname;
              if (values.middle_name != undefined) {
                if (values.middle_name != "") {
                  pname = encrypt_decript.Decript(values.first_name) + " " + encrypt_decript.Decript(values.middle_name) + " " + encrypt_decript.Decript(values.last_name);
                  fullname = encrypt_decript.Decript(values.first_name) + encrypt_decript.Decript(values.middle_name) + encrypt_decript.Decript(values.last_name) + values.inpatient_id;
                  mname = encrypt_decript.Decript(values.middle_name);
                  fname = encrypt_decript.Decript(values.first_name);
                  lname = encrypt_decript.Decript(values.last_name);
                } else {
                  pname = encrypt_decript.Decript(values.first_name) + " " + encrypt_decript.Decript(values.last_name);
                  fullname = encrypt_decript.Decript(values.first_name) + encrypt_decript.Decript(values.last_name) + values.inpatient_id;
                  fname = encrypt_decript.Decript(values.first_name);
                  lname = encrypt_decript.Decript(values.last_name);
                  mname = "";
                }
              } else {
                pname = encrypt_decript.Decript(values.first_name) + " " + encrypt_decript.Decript(values.last_name);
                fullname = encrypt_decript.Decript(values.first_name) + encrypt_decript.Decript(values.last_name) + values.inpatient_id;
                fname = encrypt_decript.Decript(values.first_name);
                lname = encrypt_decript.Decript(values.last_name);
                mname = "";
              }

              var wardname = "", salutation_desc;
              if (values.salutation_desc != undefined) {
                salutation_desc = values.salutation_desc + ".";
              }
              else {
                salutation_desc = " "
              }
              var docname
              var ward_text;
              var wardname = "";
              if (values.ward_name != undefined) {
                wardname = values.ward_name;
              }
              else {
                wardname = "Bed Not allocated"
              }
              var bedno = "";
              if (values.bed_no != undefined && values.bed_no != "") {
                bedno = values.bed_no;
                ward_text = wardname + " / " + bedno;
              } else {
                bedno = "";
                ward_text = wardname
              }

              var surdate = "";
              if (values.surgery_date != undefined) {
                var sdate = values.surgery_date.split('-');
                surdate = sdate[2] + "-" + sdate[1] + "-" + sdate[0];
              } else {
                sdate = "";
              }
            
              var cdate;
              if (values.created_date != undefined) {
                var cdval = values.created_date.split('-');
                cdate = cdval[2] + "-" + cdval[1] + "-" + cdval[0]
              } else {
                cdate = "";
              }
              var ctime;
              if (values.created_time != undefined) {
                ctime = Time_Formate(values.created_time);
              } else {
                ctime = "";
              }
              var image
              if (values.profile_image != undefined) {
                if (values.profile_image == "default") {
                  image = "../../../assets/img/default.jpg";
                } else {
                  image = ipaddress.Ip_with_img_address.toString() + values.profile_image;
                }
              }
            
              if(values.payment_mode != undefined){
                this.payment_mode = values.payment_mode
                if(this.payment_mode == "Insurance"){
                  this.sponsorDueflag=true
                }else{
                  this.sponsorDueflag=false;
                }
              }
              if (values.doc_name != undefined) {
                docname =values.doc_name
              } else {
                docname = ""
              }
              var gender, age;
              if (values.age != undefined) {
                age = values.age
              }
              if (values.gender != undefined) {
                gender = encrypt_decript.Decript(values.gender);
                gender = gender[0];
                if (gender != undefined && gender[0] == 'T') {
                  gender = 'Tgen';
                }
              }
              var gender_age,address1,address2,location,district,zipcode,state,country,locationcheck,districtcheck;
              if (age != undefined && gender != undefined) {
                gender_age = age + " / " + gender
              } else {
                if (age == undefined) {
                  gender_age = gender
                } else {
                  gender_age = age
                }
              }

              if (values.address2 != null && values.address2 != undefined && values.address1 != undefined) {
                address1 = encrypt_decript.Decript(values.address1)+ ",";
                if (values.address2 != " " && values.address2 != "null") {
                  if (encrypt_decript.Decript(values.address2) == "") {
                    address2 == "";
                  } else {
                    address2 = encrypt_decript.Decript(values.address2)+",";
                  }
                }
              } else if (values.address1 != undefined) {
                  address1 = encrypt_decript.Decript(values.address1)+",";
              } else if (values.address2 != undefined) {
                  address2 = encrypt_decript.Decript(values.address2)+",";
              }
    
              if(values.location != undefined){
                locationcheck = values.location
              }
              if(values.location != undefined){
                location = values.location + ',';
              }

              if(values.district != undefined){
                districtcheck = values.district
              }
              if(values.district != undefined){
                district = values.district + ' -';
              }
    
              if(values.zipcode != undefined) {
                zipcode = encrypt_decript.Decript(values.zipcode)+",";
              }
    
              if (values.state != undefined) {
                state = values.state + " -";
              }   
              
              if (values.country != undefined) {
                country = values.country+".";
              }
           
              if (values.hosp_inp_id != undefined) {
                var hosp_inp_id1 = values.hosp_inp_id.split("-");
                var hosp_inp_id2 = hosp_inp_id1[0] + hosp_inp_id1[1];
              }

              var admission_date,relation_id;
              if (values.admission_date != undefined) {
                admission_date = values.admission_date;
              }

              if (values.relation_id != undefined) {
                relation_id = values.relation_id;
              }
              var address;
              if (values.address2 != undefined && values.address1 != undefined) {
                address = encrypt_decript.Decript(values.address1) + ", " + encrypt_decript.Decript(values.address2)
              } else if (values.address1 != undefined) {
                address = encrypt_decript.Decript(values.address1);
              } else {
                address = "";
              }

              if (values.gender != undefined) {
                gender = encrypt_decript.Decript(values.gender);
                gender = gender[0];
                if (gender != undefined && gender[0] == 'T') {
                  gender = 'Tgen';
                }
              }
              var discharge_date;
              if (values.discharge_date != undefined) {
                var dval = values.discharge_date.split('-');
                discharge_date = dval[2] + "-" + dval[1] + "-" + dval[0]
              } else {
                discharge_date = "";
              }
              var discharge_time; 
              if (values.discharge_time != undefined) {
                discharge_time = Time_Formate(values.discharge_time);
              } else {
                discharge_time = "";
              }
              var barcode_flag = false,barcode_url = "";
              if (values.barcode != undefined) {
                barcode_flag = true;
                barcode_url = ipaddress.Ip_with_img_address + values.barcode;
                console.log("this.barcode_url"+barcode_url);
              }
              this.patientList.push({
                salutation_desc: salutation_desc,
                client_reg_id: values.client_reg_id,
                bed_img: '../../../assets/ui_icons/bed_icon.svg',
                patient_name: salutation_desc + pname,
                patientname: pname,
                surgery_desc: values.surgery_desc,
                patient_namevalue: pname,
                fullname: fullname,
                inpatientId: values.inpatient_id,
                admit_date: cdate,
                admit_time: ctime,
                admitdatetime: cdate + " " + ctime,
                admission_date: admission_date,
                admitted_date: admission_date,
                admitted_time: ctime,
                relation_id: relation_id,
                ward_name: wardname,
                bed_no: bedno,
                mobile: values.mobile,               
                profile_image: image,
                first_name: values.first_name,
                last_name: values.last_name,
                middle_name: values.middle_name,
                ward_text: ward_text,
                inpatient_id: values.inpatient_id,
                hosp_inp_id: values.hosp_inp_id,
                speciality: values.speciality,
                specialization_name: values.speciality,
                surgery_date: surdate,
                disflag: false,
                ward_id: values.ward_id,
                doctor_name: docname,
                doctorname: docname,
                doc_reg_id: values.doc_reg_id,
                doc_app_id: values.doc_app_id,
                gender_age: gender_age,
                address: address,
                address1: values.address1,
                address2: values.address2,                 
                locationcheck: locationcheck, 
                location: location,
                districtcheck: districtcheck,
                district: district,
                zipcode: zipcode,
                state: state,
                country: country,
                hosp_inp_idvalue: hosp_inp_id2, 
                mobilenumber: encrypt_decript.Decript(values.mobile),
                mobilenuo: encrypt_decript.Decript(values.mobile),
                patient_id: values.patient_id,
                mlc: values.mlc_type,
                age: values.age,
                gender: gender,
                doc_qualif: values.doc_qualif,
                insurer: values.insurer,
                insCardNo: values.ins_card_no,
                policyno: values.policy_no,
                CCNno: values.ins_ccn_no,
                tpa: values.ins_tpa,
                approved_amount: values.approved_amount,
                discharge_date: discharge_date,
                discharge_time: discharge_time,
                rate_plan: values.rate_card_desc,
                payment_mode: values.payment_mode,
                barcode_flag: barcode_flag,
                barcode_url: barcode_url
              });
              Helper_Class.setinpatientfilterdata(this.patientList);
            }
            console.log("this.patientListthis.patientList" + JSON.stringify(this.patientList));
          } else {  
            this.count = "0";
          }
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  viewPatient(patient_id,inpatient_id, mobile, regid, fname, mname, lname, speciality, list) {
    console.log("list view  0--"+JSON.stringify(list))
    this.totalBillAmountvalue = "0.00";
    this.sponsorDue = '0.00';
    this.total_advance = "0.00";
    this.paid_bill = '0.00';
    this.remaining_billbal = '0.00';
    this.discountvaluecheck = 0;
    this.amountval = '0';
    this.listview_hidden = true;
    if (list.payment_mode == "Insurance") {
      this.sponsorDueflag =true;
    } else {
      this.sponsorDueflag =false; 
    }
    this.Appointment_change(list.client_reg_id,list.relation_id,list.sub_rel_id,list,list.approved_amount)
  }

  getDoctors() {
    console.log("get doc =="+JSON.stringify( {
      country: "IN",
      reg_id: Helper_Class.getInfo().user_id,
      imei: Helper_Class.getIPAddress(),
      type: "front-desk",
      hosp_id:this.hptl_clinic_id,
    }))
    this.doctorList=[];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'adm/docbyrep',
      {
        country: "IN",
        reg_id: Helper_Class.getInfo().user_id,
        imei: Helper_Class.getIPAddress(),
        type: "front-desk",
        hosp_id:this.hptl_clinic_id,
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          if (obj != null) {
            this.doctorList.push({
              Doc_Name: "All",
              Doc_ID: "All",
            });

            for (var i = 0; i < obj.doctors.length; i++) {
              this.doctorName = "";
              var fname, mname, lname;
              if (obj.doctors[i].middle_name != "" && obj.doctors[i].middle_name != undefined) {
                this.doctorName = obj.doctors[i].first_name + " " + obj.doctors[i].middle_name + " " + obj.doctors[i].last_name;
                fname = obj.doctors[i].first_name; mname = obj.doctors[i].middle_name; lname = obj.doctors[i].last_name;
              } else {
                this.doctorName = obj.doctors[i].first_name + " " + obj.doctors[i].last_name;
                fname = obj.doctors[i].first_name; lname = obj.doctors[i].last_name;
              }
              this.doctorList.push({
                Doc_Name: this.doctorName,
                Doc_ID: obj.doctors[i].prov_id,
              });
            }
            this.doctor = this.doctorList[0].Doc_ID;
            this.changeDoctor(this.doctor);
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  changeDoctor(e) {
    FrontDesk_Helper.setdoctor(undefined);
    FrontDesk_Helper.setdoctor(e);
    this.doctor = e;
    this.dog_reg_id=e;
    this.getPatients();
  }

  getdatavalue(value) {
    if (!this.name || this.name.trim() === '') {
      console.log('Input value is empty');
      this.patientList = Helper_Class.getinpatientfilterdata();
      // Handle the empty value case
    } else {
      console.log('Input value:', this.name);
      this.filtermethod("mr_no", this.mrno, '');
      // Your logic for non-empty value
    }
  }

  getdatavaluemrno(value) {
    if (!this.mrno || this.mrno.trim() === '') {
      console.log('Input value is empty');
      this.patientList = Helper_Class.getinpatientfilterdata();
      // Handle the empty value case
    } else {
      console.log('Input value:', this.mrno);
      this.filtermethod("mr_no", this.mrno, '');
      // Your logic for non-empty value
    }
  }

  getdatavaluelocation(value) {
    if (!this.location || this.location.trim() === '') {
      console.log('Input value is empty');
      this.patientList = Helper_Class.getinpatientfilterdata();
      // Handle the empty value case
    } else {
      console.log('Input value:', this.location);
      if (this.location.length >= 3) {
        this.filtermethod("location", this.location, '');
      }   
    }
  }

  getdatamobilenumber(vlaue) {
    if (!this.mobile || this.mobile.trim() === '') {
      console.log('Input value is empty');
      this.patientList = Helper_Class.getinpatientfilterdata();
      // Handle the empty value case
    } else {
      console.log('Input value:', this.mobile);
      this.filtermethod("mobile", this.mobile, '');
      // Your logic for non-empty value
    }
  }

  filtermethod(type, name, name1) {
    this.patientList = [];
    var senddata;
    if (type == "date") {
      senddata = {
        filter: type,
        fdate: (this.fromDate),
        tdate: (this.toDate),hptl_clinic_id: this.hptl_clinic_id
      }
    }   
    if (type == "name") {
      senddata = {
        filter: type,
        fname: encrypt_decript.Encript(this.name).toString(),hptl_clinic_id: this.hptl_clinic_id
      }
    }
    if (type == "mobile") {
      senddata = {
        filter: type,
        mobile:  encrypt_decript.Encript(this.mobile).toString(),
        hptl_clinic_id: this.hptl_clinic_id
      }
    }
    if (type == "mr_no") {
      senddata = {
        filter: type,
        mr_no: this.mrno, hptl_clinic_id: this.hptl_clinic_id
      }
    }
    if (type == "location") {
      senddata = {
        filter: type,
        location: this.location, hptl_clinic_id: this.hptl_clinic_id
      }
    }
    console.log("filter basedheck ---" + JSON.stringify(senddata))
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.filterbasedurl, senddata,
      { headers: headers }).subscribe(
        response => {
          var data = JSON.parse(JSON.stringify(response));
          console.log("check ---" + JSON.stringify(data))
          this.patientList = [];
          if (data.patients != undefined && data.patients.length != 0) {
            this.count = data.patients.length;
            for (var i = 0; i < data.patients.length; i++) {
              var values = data.patients[i];
              var pname, fname, lname, mname, fullname;
              if (values.middle_name != undefined) {
                if (values.middle_name != "") {
                  pname = encrypt_decript.Decript(values.first_name) + " " + encrypt_decript.Decript(values.middle_name) + " " + encrypt_decript.Decript(values.last_name);
                  fullname = encrypt_decript.Decript(values.first_name) + encrypt_decript.Decript(values.middle_name) + encrypt_decript.Decript(values.last_name) + values.inpatient_id;
                  mname = encrypt_decript.Decript(values.middle_name);
                  fname = encrypt_decript.Decript(values.first_name);
                  lname = encrypt_decript.Decript(values.last_name);
                } else {
                  pname = encrypt_decript.Decript(values.first_name) + " " + encrypt_decript.Decript(values.last_name);
                  fullname = encrypt_decript.Decript(values.first_name) + encrypt_decript.Decript(values.last_name) + values.inpatient_id;
                  fname = encrypt_decript.Decript(values.first_name);
                  lname = encrypt_decript.Decript(values.last_name);
                  mname = "";
                }
              } else {
                pname = encrypt_decript.Decript(values.first_name) + " " + encrypt_decript.Decript(values.last_name);
                fullname = encrypt_decript.Decript(values.first_name) + encrypt_decript.Decript(values.last_name) + values.inpatient_id;
                fname = encrypt_decript.Decript(values.first_name);
                lname = encrypt_decript.Decript(values.last_name);
                mname = "";
              }
              var wardname = "", salutation_desc;
              if (values.salutation_desc != undefined) {
                salutation_desc = values.salutation_desc;
              }
              else {
                salutation_desc = " "
              }
              var docname
              var ward_text;
              var wardname = "";
              if (values.ward_name != undefined) {
                wardname = values.ward_name;
              }
              else {
                wardname = "Bed Not allocated"
              }
              var bedno = "";
              if (values.bed_no != undefined && values.bed_no != "") {
                bedno = values.bed_no;

                ward_text = wardname + " - " + bedno;
              } else {
                bedno = "";
                ward_text = wardname
              }

              var surdate = "";
              if (values.surgery_date != undefined) {
                var sdate = values.surgery_date.split('-');
                surdate = sdate[2] + "-" + sdate[1] + "-" + sdate[0];
              } else {
                sdate = "";
              }
  
              var cdate;
              if (values.created_date != undefined) {
                var cdval = values.created_date.split('-');
                cdate = cdval[2] + "-" + cdval[1] + "-" + cdval[0]
              } else {
                cdate = "";
              }
              var ctime;
              if (values.created_time != undefined) {
                ctime = Time_Formate(values.created_time);
              } else {
                ctime = "";
              }
              var image
              if (values.profile_image != undefined) {
                if (values.profile_image == "default") {
                  image = "../../../assets/img/default.jpg";

                } else {
                  image = ipaddress.Ip_with_img_address.toString() + values.profile_image;


                }
              }
              if (values.doc_name != undefined) {
                docname = "Dr." + values.doc_name
              } else {
                docname = ""
              }
              var gender, age, location, district;
              if (values.age != undefined) {
                age = values.age
              }
              if (values.gender != undefined) {
                gender = encrypt_decript.Decript(values.gender);
                gender = gender[0];
                if (gender != undefined && gender[0] == 'T') {
                  gender = 'Tgen';
                }
              }
              var gender_age, locationcheck, districtcheck;
              if (age != undefined && gender != undefined) {
                gender_age = age + "/" + gender
              } else {
                if (age == undefined) {
                  gender_age = gender
                } else {
                  gender_age = age
                }
              }
              if (values.location != undefined) {
                locationcheck = values.location
              }
              if (values.district != undefined) {
                districtcheck = values.district
              }
              if (values.hosp_inp_id != undefined) {
                var hosp_inp_id1 = values.hosp_inp_id.split("-");
                var hosp_inp_id2 = hosp_inp_id1[0] + hosp_inp_id1[1];
              }
              var discharge_date;
              if (values.discharge_date != undefined) {
                var dval = values.discharge_date.split('-');
                discharge_date = dval[2] + "-" + dval[1] + "-" + dval[0];
              } else {
                discharge_date = "";
              }
              var discharge_time; 
              if (values.discharge_time != undefined) {
                discharge_time = Time_Formate(values.discharge_time);
              } else {
                discharge_time = "";
              }
              var barcode_flag = false,barcode_url = "";
              if (values.barcode != undefined) {
                barcode_flag = true;
                barcode_url = ipaddress.Ip_with_img_address + values.barcode;
                console.log("this.barcode_url"+barcode_url);
              }

              this.patientList.push({
                client_reg_id: values.client_reg_id,
                bed_img: '../../../assets/ui_icons/bed_icon.svg',
                patient_name: salutation_desc + " " + pname,
                patient_namevalue: pname,
                fullname: fullname,
                admit_date: cdate,
                admit_time: ctime,
                admitdatetime: cdate + " " + ctime,
                ward_name: wardname,
                bed_no: bedno,
                mobile: values.mobile,
                patient_id: values.patient_id,
                profile_image: image,
                first_name: fname,
                last_name: lname,
                middle_name: mname,
                ward_text: ward_text,
                inpatient_id: values.inpatient_id,
                hosp_inp_id: values.inp_hosp_id,
                speciality: values.speciality,
                surgery_desc: values.surgery_desc,
                surgery_date: surdate,
                disflag: false,
                ward_id: values.ward_id,
                doctor_name: docname,
                gender_age: gender_age,
                districtcheck: districtcheck, 
                locationcheck: locationcheck, 
                hosp_inp_idvalue: hosp_inp_id2, 
                mobilenumber: encrypt_decript.Decript(values.mobile),
                mobilenuo:encrypt_decript.Decript(values.mobile),
                mlc:values.mlc_type,
                discharge_date: discharge_date,
                discharge_time: discharge_time,
                barcode_flag: barcode_flag,
                barcode_url: barcode_url
              });
              console.log("this.patientListthis.patientList" + JSON.stringify(this.patientList))
            }
          } else {
           this.count = "0";
          }
        },
        error => {
          this.toastr.error(Message_data.unableToFetchData);
        });
  }

  filtercheck() {
    console.log("chekc value  --" + JSON.stringify(this.patientList))
    if (this.nameflag == true) {
      let filteredArray = this.patientList.filter(obj => obj.patient_namevalue.toLowerCase().includes(this.name.toLowerCase()));
      console.log("filteredArray ***  --" + JSON.stringify(filteredArray))
      this.filtermethod("name", this.name, '')
    }
    if (this.mobileflag == true) {
      let filteredArray = this.patientList.filter(obj => obj.mobilenumber == this.mobile);
      console.log("filteredArray ***  --" + JSON.stringify(filteredArray));
      this.filtermethod("mobile", this.mobile, '');
    }

    if (this.locationflag == true) {
      let filteredArray1 = this.patientList.filter(obj => obj.locationcheck.trim().toLowerCase() === this.location.trim().toLowerCase());
      console.log("filteredArray loctaion  --" + JSON.stringify(filteredArray1));
      this.filtermethod("location", this.location, '')
    }

    if (this.mrnoflag == true) {
      let filteredArray = this.patientList.filter(obj => obj.hosp_inp_idvalue.toLowerCase() === this.mrno.toLowerCase());
      console.log("filteredArray mrno  --" + JSON.stringify(filteredArray))
      this.patientList = [];
      this.patientList = filteredArray;
      this.filtermethod("mr_no", this.mrno, '');
    }
    if (this.fdateflag == true && this.todateflag == true) {
      let fromDateObj = new Date(this.fromDate.split("-").reverse().join("-"));
      let toDateObj = new Date(this.toDate.split("-").reverse().join("-"));
      let filteredArraycheck = this.patientList.filter(obj => {
        // Convert admit_date to a Date object
        let admitDateObj = new Date(obj.admit_date.split("-").reverse().join("-"));
        // Check if admit_date is between fromDate and toDate
        return (admitDateObj >= fromDateObj && admitDateObj <= toDateObj);
      });
      console.log("filteredArray date   --" + JSON.stringify(filteredArraycheck));     
      this.filtermethod("date", '', '');
    }
  }

  selectFromDate(e) {
    this.fromDate = e;
  }

  selectToDate(e) {
    this.toDate = e;
  }

  fnameToUpper() {
    if (this.name != null)
      this.name = this.name.toLocaleUpperCase();
  }

  fnameToUpperlocation() {
    if (this.location != null)
      this.location = this.location.toLocaleUpperCase();
  }

  mrnobased() {
    if (this.name != null)
      this.name = this.name.toLocaleUpperCase();
  }

  Filterbydata(data) {
    this.mrno="";
    this.name="";
    this.location="";
    this.mobile= "";
    console.log("check data" + JSON.stringify(Helper_Class.getinpatientfilterdata()))
    if (data == "mrno") {
      this.mrnoflag = true;
      this.locationflag = false;
      this.nameflag = false;
      this.fdateflag = false;
      this.todateflag = false;
      this.mobileflag = false;
    }
    if (data == "name") {
      this.mrnoflag = false;
      this.nameflag = true;
      this.locationflag = false;
      this.fdateflag = false;
      this.todateflag = false;
      this.mobileflag = false;
    }
    if (data == "location") {
      this.mrnoflag = false;
      this.nameflag = false;
      this.locationflag = true;
      this.fdateflag = false;
      this.todateflag = false;
      this.mobileflag = false;
    }
    if (data == "date") {
      this.fdateflag = true;
      this.todateflag = true;
      this.mrnoflag = false;
      this.nameflag = false;
      this.locationflag = false;
      this.mobileflag = false;
      this.filtercheck();
    }
    if (data == "mobile") {
      this.fdateflag = false;
      this.todateflag = false;
      this.mrnoflag = false;
      this.nameflag = false;
      this.locationflag = false;
      this.mobileflag = true;
    }
     this.patientList = Helper_Class.getinpatientfilterdata();
  }

  viewChange(value) {
    if (value == "graph1") {
      this.graphicalformat = true;
      this.graph_defaultimg = false;
      this.tabular_format = false;
    } else if (value == "tabular") {
      this.graphicalformat = false;
      this.graph_defaultimg = false;
      this.tabular_format = true;     
    } else {
      this.tabular_format = false;
      this.graphicalformat = false;
      this.graph_defaultimg = true;
    }
  }

  changefloorvalue(value) {
    this.floorvalue = value;
    this.getPatients();
  }

  backtoApplist(data) {
    if(data == "view"){
      this.listview_hidden =false;
      this.selectvalue = "mrno";
      this.floorvalue = "All";
      this.view_type = "graph";  
      this.getPatients()
    } 
  }
}
