<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white ">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Partial Payments</h5>
          </div>
          <div class="headerButtons">
            <a> <img (click)="print_area()" src="../../../assets/img/printer.svg" width="25px" height="25px" /> </a> &nbsp;
            <a> <img src="../../../assets/ui_icons/buttons/save_button.svg" (click)="save()" class="addimgbtn_icd" /> </a>
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="Details">
          <div class="row">
            <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
              <mat-label class="matlabel">Type<br>
                <select class="ipcss" *ngIf="provtypeArray !=undefined" [(ngModel)]="provtype">
                  <option *ngFor="let prov of provtypeArray" value={{prov}}> {{prov}}
                  </option>
                </select>
              </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 select_bottom">
              <mat-label class="matlabel">Filter by<br>
                <select required [(ngModel)]="filterType" class="ipcss" (change)="filterTypeChange()">                 
                  <option selected>Name</option>
                  <option>MR.No</option>
                  <option>Mobile</option>
                  <option>Date</option>
                  <option>Bill number</option>
                </select>
              </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 select_bottom" [hidden]="clientFlag">
              <mat-label class="matlabel">First name<br>
                <input matInput class="ipcss widthbilllist" type="text" required [(ngModel)]="clntName" style="margin-bottom: 10px;"
                (keyup)="clientFilter()" [matAutocomplete]="auto1" /> 
                <mat-autocomplete #auto1="matAutocomplete" [panelWidth]="'auto'">
                    <mat-option  *ngFor="let item of clientList" (click)="selectClient(item)"> 
                        {{item.name}}
                    </mat-option>
                </mat-autocomplete>
              </mat-label>   
            </div>

            <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 select_bottom" [hidden]="clientFlag">
              <mat-label class="matlabel">Last name<br>
                <input matInput class="ipcss widthbilllist" type="text" disabled required [(ngModel)]="lastName"
                  style="margin-bottom: 10px;" />
              </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 select_bottom" [hidden]="dateFlag">
              <mat-label class="matlabel">From date<br>
                <input type="date" class="ipcss_date widthbilllist datecss" required [(ngModel)]="fromDate"
                  max="{{currentDate}}" matInput>
              </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 select_bottom" [hidden]="dateFlag">
              <mat-label class="matlabel">To date<br>
                <input type="date" class="ipcss_date widthbilllist datecss" required [(ngModel)]="toDate"
                  max="{{currentDate}}" matInput>
              </mat-label>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 select_bottom" [hidden]="billFlag">
              <mat-label class="matlabel">{{filterType}}<br>
                <input type="text" class="ipcss" required [(ngModel)]="billingId" matInput>
              </mat-label>
            </div>
            <div class="col-1  p-0">
              <mat-label class="matlabel">
                <a (click)="searchBills()"><img src="../../../assets/ui_icons/search_icon.svg"
                    class="searchButton" /></a>
              </mat-label>
            </div>
          </div>
          <div class="row" [hidden]="nodata">
            <div class="col-12">
              <p class="nodata">No data found</p>
            </div>
          </div>
          <div class="row" *ngIf="createpage_flag">
            <div class="col-12">
              <div class="cover_div" style="font-size: 13px !important;">
                <div class="header_lable">
                   Patient details
                </div>
                <div class="content_cover">                              
                  <div class="row">
                    <div class="col-2" style="text-align: center;">
                      <img src="{{profile_image}}" onerror="this.src='../../../assets/img/default.jpg';"
                        class="profileimage">
                    </div>
                    <div class="col-10">
                      <div class="row">                                                                              
                        <div class="col-3">
                          <table style="width: 100%;" class="head-table">
                            <tbody>
                              <tr class="head-tr">
                                <td class="head-td" style="width: 90px; font-weight: 500;font-family: Arial;color: black;">
                                  <b>MR No</b></td>
                                <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{uhid}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="col-3">
                          <table style="width: 100%;" class="head-table">
                            <tbody>
                              <tr class="head-tr">
                                <td class="head-td" style="width: 90px; font-weight: 500;font-family: Arial;color: black;">
                                  <b>Patient name</b></td>
                                <td class="head-td" style="width: 10px; font-weight: 500;">:&nbsp;</td>
                                <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{client_name}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="col-3">
                          <table style="width: 100%;" class="head-table">
                            <tbody>
                              <tr class="head-tr">
                                <td class="head-td" style="width: 90px; font-weight: 500;font-family: Arial;color: black;">
                                  <b>Age/Gender</b></td>
                                <td class="head-td" style="width: 10px; font-weight: 500;">:&nbsp;</td>
                                <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                  {{ageval}} <span *ngIf="genderval != '' && genderval != undefined">/</span> {{genderval}}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="col-3" *ngIf="mobile != undefined && mobile != ''">
                          <table style="width: 100%;" class="head-table">
                            <tbody>
                              <tr class="head-tr">
                                <td class="head-td" style="width: 90px; font-weight: 500;font-family: Arial;color: black;">
                                  <b>Mobile</b></td>
                                <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{mobile}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>      
                        <div class="col-3" *ngIf="dr_name != '' && dr_name != undefined">
                          <table style="width: 100%;" class="head-table">
                            <tbody>
                              <tr class="head-tr">
                                <td class="head-td" style="width: 90px; font-weight: 500;font-family: Arial;color: black;">
                                  <b>Doctor</b></td>
                                <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{dr_name}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>                    
                        <div class="col-3" *ngIf="surgery != undefined && surgery != ''">
                          <table style="width: 100%;" class="head-table">
                            <tbody>
                              <tr class="head-tr">
                                <td class="head-td" style="width: 90px; font-weight: 500;font-family: Arial;color: black;">
                                  <b>Surgery name</b></td>
                                <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                <td class="head-td" style="font-family: Arial, Helvetica, sans-serif"> {{surgery}} </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>                       
                        <div class="col-3" *ngIf="created_date != undefined && created_date != ''">
                          <table style="width: 100%;" class="head-table">
                            <tbody>
                              <tr class="head-tr">
                                <td class="head-td" style="width: 90px; font-weight: 500;font-family: Arial;color: black;">
                                  <b>Bill Date/Time</b></td>
                                <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{created_date}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="col-3" *ngIf="billId != undefined && billId != ''">
                          <table style="width: 100%;" class="head-table">
                            <tbody>
                              <tr class="head-tr">
                                <td class="head-td" style="width: 90px; font-weight: 500;font-family: Arial;color: black;">
                                  <b>Bill No</b></td>
                                <td class="head-td" style="width: 10px;font-weight: 500 !important">:&nbsp;</td>
                                <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                  {{billId}} 
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>                                 
                        <div class="col-6">
                          <table style="width: 100%;" class="head-table">
                              <tbody>
                                <tr class="head-tr">
                                    <td class="head-td" style="width: 90px; font-weight: 500;font-family: Arial;color: black;">
                                      <b>Address</b></td>
                                    <td class="head-td" style="width: 10px; font-weight: 500;">:&nbsp;</td>
                                    <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                      {{clientAddress1}} {{clientAddress2}} {{clocation}} {{ccity}} {{czipcode}} {{cstate}} {{ccountry}}
                                  </td>
                                </tr>                 
                              </tbody>
                          </table>
                        </div>                                              
                      </div>                 
                    </div>                    
                  </div>
                </div>
              </div>          
            </div>
          </div>

          <div class="row" style="margin-top:15px;padding: 0 10px 0 10px;">
            <div [hidden]="billList.length == 0" class="dig_table_overflow">
              <div class="table-responsive" style="max-height: 360px;overflow-y: auto;overflow-x: hidden;">
                <table id="tbl" class="table table-hover table-dynamic" style="font-size: 12px;">
                  <thead style="top: 0;position: sticky;z-index: 10;">
                    <tr>
                      <th [hidden]="!print_header_flag">MR.No</th>
                      <th style="width: 115px;">Patient name</th>    
                      <!-- <th [hidden]="!print_header_flag">Age/Gender</th> -->
                      <th>Bill no</th>
                      <th style="width: 165px;">Date</th>
                      <th style="width: 140px;" *ngIf="doctor_name_flag">Doctor</th>
                      <th>Bill amount</th>
                      <th>Advance</th>
                      <th>Paid</th>
                      <th>Balance</th>
                      <th>Payable </th>
                      <th style="width: 100px !important">Pay type</th>
                      <th>Remarks </th>
                      <th style="width: 100px;">Status </th>
                      <th>&nbsp; </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let data of billList">
                      <td [hidden]="!print_header_flag">{{data.mr_no}}</td>
                      <td>{{data.name}}</td>
                      <!-- <td [hidden]="!print_header_flag">{{data.age_gender}}</td> -->
                      <td>{{data.billno}}</td>
                      <td>{{data.date}}</td>
                      <td *ngIf="doctor_name_flag">{{data.dr_name}}</td>
                      <td style="text-align: right;">{{data.billamount}}</td>
                      <td style="text-align: right;">{{data.advance}}</td>
                      <td style="text-align: right;">{{data.paid_amt}}</td>
                      <td style="text-align: right;">{{data.balance}}</td>
                      <td><input type="text" class="ipcss paywidth" [readonly]="data.paidflag" style="text-align: right;"
                          [(ngModel)]="data.payamount" maxlength="10"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57" matInput />
                      </td>
                      <td>
                        <select class="ipcss" *ngIf="paymentArray !=undefined" [(ngModel)]="data.pay_id"

                          [disabled]="data.paidflag">
                          <option *ngFor="let pay of paymentArray" value={{pay.pay_id}}> {{pay.pay_desc}}
                          </option>
                        </select>
                      </td>
                      <td><input type="text" class="ipcss paywidth1" [readonly]="data.paidflag"
                          [(ngModel)]="data.remarks" maxlength="100" matInput />
                      </td>
                      <td>{{data.payflag}}</td>
                      <td>
                        <img src="../../../assets/img/Second_opinion_tabs_icons/Observation.png" width="20px"
                          height="20px" (click)="showBillHistory(mymodal,data.billno)" *ngIf="data.showimage" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <ng-template #mymodal let-modal>
            <div [hidden]="billList.length == 0" class="dig_table_overflow">
              <div class="table-responsive">
                <table id="tbl" class="table table-hover table-dynamic" style="font-size: 11px;">
                  <thead>
                    <tr>
                      <th>Amount</th>
                      <th>Paid on</th>
                      <th>remarks</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let pay of parpayarray">
                      <td style="text-align: right;">{{pay.amount}}</td>
                      <td style="text-align: center;">{{pay.created_date}}</td>
                      <td style="text-align: left;">{{pay.remarks}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </ng-template>
        </div>

        <div [hidden]="true" #printbanner id="printbanner">
          <table style="border:'0';width:100%">              
           <thead [hidden]="print_header_flag">
            <tr>
              <th style="width:100%;">                                 
                <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;margin-right: 30px; margin-left: 20px;"> 
                  <div style="width: 100%; height: 205px !important;"> 
                    <div style="width: 100%;">
                      <img alt="image" src={{hospital_logo}}   [style]="printlogostyle"> 
                    </div>                                                             
                    <table style="width: 100%;font-size: 12px;border: 1px solid black;margin-top: 15px;margin-bottom: 5px;border-radius: 10px;height: 95px;"> 
                      <tbody>
                        <tr style="height: 14px;">
                          <td style="padding-left: 5px;"><strong>Patient Name</strong></td>
                          <td>: {{client_name}} </td>
                          <td style="padding-left: 5px;"><strong>Address</strong></td>
                          <td>: {{clientAddress1}} {{clientAddress2}}</td>
                        </tr>
                        <tr style="height: 14px;">
                          <td style="padding-left: 5px;"><strong>Age/Gender</strong></td>
                          <td>: {{ageval}} / {{genderval}}</td>
                          <td style="padding-left: 5px;"><strong></strong></td>
                          <td><span class="address_visibility">:</span> {{clocation}} {{ccity}} {{czipcode}}</td>
                        </tr>
                        <tr style="height: 14px;">
                          <td *ngIf="dr_name != ''" style="padding-left: 5px;"><strong>Doctor</strong></td>
                          <td *ngIf="dr_name != ''">: {{dr_name}}</td>
                          <td *ngIf="dr_name == ''" style="padding-left: 5px;"><strong> </strong></td>
                          <td *ngIf="dr_name == ''"> </td>
                          <td style="padding-left: 5px;"><strong></strong></td>
                          <td><span class="address_visibility">:</span> {{cstate}} {{ccountry}}</td>
                        </tr>     
                        <tr style="height: 14px;" *ngIf="billId != ''">
                          <td style="padding-left: 5px;"><strong>Bill No</strong></td>
                          <td>: {{billId}}</td>
                        </tr>   
                        <tr style="height: 14px;" *ngIf="created_date != ''">
                          <td style="padding-left: 5px;"><strong>Bill Date/Time</strong></td>
                          <td>: {{created_date}}</td>
                        </tr>      
                        <tr style="height: 14px;" *ngIf="surgery != undefined && surgery != '' ">
                          <td style="padding-left: 5px;"><strong>Surgery name</strong></td>                             
                          <td>: {{surgery}}</td>                                                   
                        </tr>                                                
                        <tr>
                          <td style="padding-left: 5px;"><strong></strong></td>
                          <td> </td> 
                          <td style="padding-left: 5px;"><strong></strong></td>
                          <td *ngIf="barcode_flag">
                            <img [style]="barcode_style"
                              src={{barcode_url}} /></td> 
                        </tr>                         
                      </tbody>
                    </table>
                  </div>
                </div>               
              </th>
            </tr>
           </thead>
           <tbody style="background-color:whitesmoke;">  
             <tr>  
               <td width="100%">  
                <p style="text-align: center;font-size: 16px;margin: 0px;margin-right: 10px !important;margin-bottom: 3px !important;"><b>Bill/Receipt</b></p>
                <div class="bill_border">
                  <div>
                    <div style="margin-left: 20px;margin-right: 30px;">
                      <div class="row">
                        <table style="width: 100%;border: 1px solid black;border-collapse: collapse;">
                          <thead>
                            <tr>
                              <th style="width: 50px;padding: 5px 0 5px 0;border: 1px solid black;border-collapse: collapse;">Bill no</th>
                              <th style="width: 130px;border: 1px solid black;border-collapse: collapse;">Date</th>
                              <th [hidden]="!print_header_flag" style="width: 80px;border: 1px solid black;border-collapse: collapse;">MR.No</th>
                              <th [hidden]="!print_header_flag" style="width: 80px;border: 1px solid black;border-collapse: collapse;">Client name</th>
                              <th [hidden]="!print_header_flag" style="width: 80px;border: 1px solid black;border-collapse: collapse;">Age/Gender</th>
                              <th *ngIf="doctor_name_flag" style="width: 80px;border: 1px solid black;border-collapse: collapse;">Doctor name</th>
                              <th style="width: 50px;border: 1px solid black;border-collapse: collapse;">Bill amount</th>
                              <th style="width: 50px;border: 1px solid black;border-collapse: collapse;">Advance</th>
                              <th style="width: 50px;border: 1px solid black;border-collapse: collapse;">Paid</th>
                              <th style="width: 50px;border: 1px solid black;border-collapse: collapse;">Balance</th>
                              <th style="width: 50px;border: 1px solid black;border-collapse: collapse;">Payable </th>
                              <th style="width: 80px;border: 1px solid black;border-collapse: collapse;">Pay type </th>
                              <th style="width: 80px;border: 1px solid black;border-collapse: collapse;">Status </th>                             
                            </tr>
                          </thead>   
                          <tbody>
                            <tr *ngFor="let data of billList">
                              <td style="text-align: center;padding: 3px 0 3px 0;border: 1px solid black;border-collapse: collapse;">{{data.billno}}</td>
                              <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{data.date}}</td>
                              <td [hidden]="!print_header_flag" style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{data.mr_no}}</td>
                              <td [hidden]="!print_header_flag" style="text-align: left;border: 1px solid black;border-collapse: collapse;">{{data.name}}</td>
                              <td [hidden]="!print_header_flag" style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{data.age_gender}}</td>
                              <td *ngIf="doctor_name_flag" style="text-align: left;border: 1px solid black;border-collapse: collapse;">{{data.dr_name}}</td>
                              <td style="text-align: right;border: 1px solid black;border-collapse: collapse;">{{data.billamount}}</td>
                              <td style="text-align: right;border: 1px solid black;border-collapse: collapse;">{{data.advance}}</td>
                              <td style="text-align: right;border: 1px solid black;border-collapse: collapse;">{{data.paid_amt}}</td>
                              <td style="text-align: right;border: 1px solid black;border-collapse: collapse;"> {{data.balance}}</td>
                              <td style="text-align: right;border: 1px solid black;border-collapse: collapse;">{{data.payamount}}</td>
                              <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{data.pay_desc}}</td>
                              <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{data.payflag}}</td>                             
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>                                                
                  </div>
                </div>                           
               </td>
             </tr>
           </tbody>
           <tfoot style="margin-bottom: 10px;"> 
            <tr>  
              <td width="100%">  
                <table style="width: 100%;">  
                  <tr>  
                    <td style="vertical-align: top;width:50%;">
                      <table style="float: left !important;margin-left: 15px;">
                        <tr>
                          <td>&nbsp;</td>
                        </tr>
                      </table> 
                    </td>  
                    <td style="vertical-align: top;width:50%;">
                      <table style="float: right !important;margin-left: 20px;margin-right: 32px;">
                        <tr>
                          <div style="float: right !important;margin-left: 20px;margin-right: 32px;">
                            <p style="margin-bottom: 3px !important;text-align: center;">{{logUserName}}</p>
                            <p style="text-align: center;margin-top: 6px;margin-bottom: 20px;"><strong>Created by</strong></p>
                          </div>                       
                        </tr>
                      </table>                      
                    </td>
                  </tr>  
                </table> 
              </td>
            </tr> 
           </tfoot>    
          </table>
        </div>

        <div [hidden]="true" #printnoheader id="printnoheader">
          <table style="border:'0';width:100%;">  
            <thead [hidden]="print_header_flag">
              <tr>
                <th style="width:100%;">
                  <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;margin-right: 30px; margin-left: 20px;"> 
                    <div [style]="nobanner_style">
                      <div style="height: 5px !important;"></div>
                      <table style="width: 100%;font-size: 12px;border: 1px solid black;border-radius: 10px;height: 85px;"> 
                        <tbody>
                          <tr style="height: 14px;">
                            <td style="padding-left: 5px;"><strong>Patient Name</strong></td>
                            <td>: {{client_name}} </td>
                            <td style="padding-left: 5px;"><strong>Address</strong></td>
                            <td>: {{clientAddress1}} {{clientAddress2}}</td>
                          </tr>
                          <tr style="height: 14px;">
                            <td style="padding-left: 5px;"><strong>Age/Gender</strong></td>
                            <td>: {{ageval}} / {{genderval}}</td>
                            <td style="padding-left: 5px;"><strong></strong></td>
                            <td><span class="address_visibility">:</span> {{clocation}} {{ccity}} {{czipcode}}</td>
                          </tr>
                          <tr style="height: 14px;">
                            <td *ngIf="dr_name != ''" style="padding-left: 5px;"><strong>Doctor</strong></td>
                            <td *ngIf="dr_name != ''">: {{dr_name}}</td>
                            <td *ngIf="dr_name == ''" style="padding-left: 5px;"><strong> </strong></td>
                            <td *ngIf="dr_name == ''"> </td>
                            <td style="padding-left: 5px;"><strong></strong></td>
                            <td><span class="address_visibility">:</span> {{cstate}} {{ccountry}}</td>
                          </tr>     
                          <tr style="height: 14px;" *ngIf="billId != ''">
                            <td style="padding-left: 5px;"><strong>Bill No</strong></td>
                            <td>: {{billId}}</td>
                          </tr>   
                          <tr style="height: 14px;" *ngIf="created_date != ''">
                            <td style="padding-left: 5px;"><strong>Bill Date/Time</strong></td>
                            <td>: {{created_date}}</td>
                          </tr>      
                          <tr style="height: 14px;" *ngIf="surgery != undefined && surgery != ''">
                            <td style="padding-left: 5px;"><strong>Surgery name</strong></td>                             
                            <td>: {{surgery}}</td>                                                   
                          </tr>                                                
                          <tr>
                            <td style="padding-left: 5px;"><strong></strong></td>
                            <td> </td> 
                            <td style="padding-left: 5px;"><strong></strong></td>
                            <td *ngIf="barcode_flag">
                              <img [style]="barcode_style"
                                src={{barcode_url}} /></td> 
                          </tr> 
                        </tbody>
                      </table>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody style="width: 100%;" >  
              <tr>  
                <td width="100%">  
                  <p style="text-align: center;font-size: 16px;margin: 0px;margin-right: 10px !important;margin-bottom: 3px !important;"><b>Bill/Receipt</b></p>
                  <div class="bill_border">
                    <div>
                      <div style="margin-left: 20px;margin-right: 30px;">
                        <div class="row">
                          <table style="width: 100%;border: 1px solid black;border-collapse: collapse;margin-right: 20px !important;">
                            <thead>
                              <tr>
                                <th style="width: 50px;border: 1px solid black;border-collapse: collapse;padding: 5px 0 5px 0;">Bill no</th>
                                <th style="width: 130px;border: 1px solid black;border-collapse: collapse;">Date</th>
                                <th [hidden]="!print_header_flag" style="width: 80px;border: 1px solid black;border-collapse: collapse;">MR.No</th>
                                <th [hidden]="!print_header_flag" style="width: 80px;border: 1px solid black;border-collapse: collapse;">Client name</th>
                                <th [hidden]="!print_header_flag" style="width: 80px;border: 1px solid black;border-collapse: collapse;">Age/Gender</th>
                                <th *ngIf="doctor_name_flag" style="width: 80px;border: 1px solid black;border-collapse: collapse;">Doctor</th>
                                <th style="width: 50px;border: 1px solid black;border-collapse: collapse;">Bill amount</th>
                                <th style="width: 50px;border: 1px solid black;border-collapse: collapse;">Advance</th>
                                <th style="width: 50px;border: 1px solid black;border-collapse: collapse;">Paid</th>
                                <th style="width: 50px;border: 1px solid black;border-collapse: collapse;">Balance</th>
                                <th style="width: 50px;border: 1px solid black;border-collapse: collapse;">Payable </th>
                                <th style="width: 80px;border: 1px solid black;border-collapse: collapse;">Pay type </th>
                                <th style="width: 80px;border: 1px solid black;border-collapse: collapse;">Status </th>
                              </tr>
                            </thead>   
                            <tbody>
                              <tr *ngFor="let data of billList">
                                <td style="text-align: center;padding: 3px 0 3px 0;border: 1px solid black;border-collapse: collapse;">{{data.billno}}</td>
                                <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{data.date}}</td>
                                <td [hidden]="!print_header_flag" style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{data.mr_no}}</td>
                                <td [hidden]="!print_header_flag" style="text-align: left;border: 1px solid black;border-collapse: collapse;">{{data.name}}</td>
                                <td [hidden]="!print_header_flag" style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{data.age_gender}}</td>
                                <td *ngIf="doctor_name_flag" style="text-align: left;border: 1px solid black;border-collapse: collapse;">{{data.dr_name}}</td>
                                <td style="text-align: right;border: 1px solid black;border-collapse: collapse;">{{data.billamount}}</td>
                                <td style="text-align: right;border: 1px solid black;border-collapse: collapse;">{{data.advance}}</td>
                                <td style="text-align: right;border: 1px solid black;border-collapse: collapse;">{{data.paid_amt}}</td>
                                <td style="text-align: right;border: 1px solid black;border-collapse: collapse;">{{data.balance}}</td>
                                <td style="text-align: right;border: 1px solid black;border-collapse: collapse;">{{data.payamount}}</td>
                                <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{data.pay_desc}}</td>
                                <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{data.payflag}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>                                                                                                        
                    </div>
                  </div>                           
                </td>
              </tr>
            </tbody>
            <tfoot style="margin-bottom: 10px;"> 
              <tr>  
                <td width="100%">  
                  <table style="width: 100%;">  
                    <tr>  
                      <td style="vertical-align: top;width:50%;">
                        <table style="float: left !important;margin-left: 15px;">
                          <tr>
                            <td>&nbsp;</td>
                          </tr>
                        </table> 
                      </td>  
                      <td style="vertical-align: top;width:50%;">
                        <table style="float: right !important;margin-left: 20px;margin-right: 32px;">
                          <tr>
                            <div style="float: right !important;margin-left: 20px;margin-right: 32px;">
                              <p style="margin-bottom: 3px !important;text-align: center;">{{logUserName}}</p>
                              <p style="text-align: center;margin-top: 6px;margin-bottom: 20px;"><strong>Created by</strong></p>
                            </div>                       
                          </tr>
                        </table>                      
                      </td>
                    </tr>  
                  </table> 
                </td>
              </tr> 
            </tfoot>  
          </table>
        </div> 
      </mdb-card-body>
    </mdb-card>
  </div>
</div>