<div class="row">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Admin Dashboard</h5>
                    </div>
                    <!-- <div class="headerButtons">
                        <img src="../../../assets/ui_icons/buttons/update_button.svg" class="saveimgbtn_inpatinfo" />
                    </div> -->
                </div>
            </mdb-card-header>
            <mdb-card-body>

                <div class="row" style="margin: 0 auto;">
                    <div class="Details">
                        <mat-tab-group [disableRipple]=true mat-align-tabs="start" [selectedIndex]="0"
                            (selectedTabChange)="selectTab($event)">

                            <mat-tab label="outpatient">
                                <ng-template mat-tab-label style="margin-left:10px !important;">
                                    <img src="../../../assets/ui_icons/second_opinion/Pending_icon.svg" width="18px"
                                        height="auto" />
                                    &nbsp; Outpatient
                                </ng-template>
                                <mdb-card style="width: 99% !important;margin-left: 3px;">
                                    <mdb-card-body>
                                        <div class="row mb-3">
                                            <div class="col-12 col-sm-6 col-md-6  col-lg-2 col-xl-2"
                                                style="margin-top: 5px;width: auto;">
                                                <mat-label class="matlabel"
                                                    style="font-size: 15px; font-weight: 600;">Duration</mat-label>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                                                <select disableOptionCentering class="ipcss widthappt"
                                                    (change)="Appointment_type_change()" [(ngModel)]="appointment_type"
                                                    style="border: 1px solid black !important;">
                                                    <option value="Today">Today</option>
                                                    <option value="Weekly">Weekly</option>
                                                    <option value="Fort Night">Fort Night</option>
                                                    <option value="Monthly">Monthly</option>
                                                    <option value="3 Months">3 Months</option>
                                                    <option value="6 Months">6 Months</option>
                                                    <option value="Yearly">Yearly</option>
                                                </select>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-6  col-lg-2 col-xl-2"
                                                style="margin-top: 5px;width: auto;">
                                                <mat-label class="matlabel"
                                                    style="font-size: 15px; font-weight: 600;">From :</mat-label>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                                                <input type="date" [(ngModel)]="from_date" class="ipcss widthappt"
                                                    [disabled]="true">
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-6  col-lg-2 col-xl-2"
                                                style="margin-top: 5px;width: auto;">
                                                <mat-label class="matlabel"
                                                    style="font-size: 15px; font-weight: 600;">To :</mat-label>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                                                <input type="date" [(ngModel)]="to_date" class="ipcss widthappt"
                                                    [disabled]="true">
                                            </div>
                                        </div>
                                        <div class="container-fluid">
                                            <div class="row">
                                                <div class="col-7">
                                                    <div class="card-container mb-3">
                                                        <h2 class="card-heading">Appointments</h2>
                                                        <div class="row">
                                                            <div class="col-3">
                                                                <h2 class="card-heading-2">Total</h2>
                                                                <div
                                                                    class="indent-card-container mb-1 d-flex flex-row justify-content-center align-items-center">
                                                                    <h2 class="indent-card-container-head">{{total_docApps}}</h2>
                                                                </div>
                                                            </div>
                                                            <div class="col-2">
                                                                <h2 class="card-heading-2">Completed</h2>
                                                                <div
                                                                    class="card-container-green mb-1 d-flex flex-row justify-content-center align-items-center">
                                                                    <h2 class="card-container-head">{{doc_completedApp}}</h2>
                                                                </div>
                                                            </div>
                                                            <div class="col-2">
                                                                <h2 class="card-heading-2">Open</h2>
                                                                <div
                                                                    class="card-container-red mb-1 d-flex flex-row justify-content-center align-items-center">
                                                                    <h2 class="card-container-head">{{doc_openApp}}</h2>
                                                                </div>
                                                            </div>
                                                            <div class="col-2">
                                                                <h2 class="card-heading-2">Confirmed</h2>
                                                                <div
                                                                    class="indent-card-container mb-1 d-flex flex-row justify-content-center align-items-center">
                                                                    <h2 class="indent-card-container-head">{{doc_confirmedApp}}</h2>
                                                                </div>
                                                            </div>
                                                            <div class="col-3">
                                                                <h2 class="card-heading-2">Inpatients</h2>
                                                                <div
                                                                    class="indent-card-container mb-1 d-flex flex-row justify-content-center align-items-center">
                                                                    <h2 class="indent-card-container-head">{{doc_inpatApp}}</h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-5">
                                                    <div class="card-container mb-3">
                                                        <h2 class="card-heading">Diagnostic Order</h2>
                                                        <div class="row">
                                                            <div class="col-4">
                                                                <h2 class="card-heading-2">Total</h2>
                                                                <div
                                                                    class="indent-card-container mb-1 d-flex flex-row justify-content-center align-items-center">
                                                                    <h2 class="indent-card-container-head">{{total_diagApps}}</h2>
                                                                </div>
                                                            </div>
                                                            <div class="col-4">
                                                                <h2 class="card-heading-2">Completed</h2>
                                                                <div
                                                                    class="card-container-green mb-1 d-flex flex-row justify-content-center align-items-center">
                                                                    <h2 class="card-container-head">{{diag_completedApp}}</h2>
                                                                </div>
                                                            </div>
                                                            <div class="col-4">
                                                                <h2 class="card-heading-2">Open</h2>
                                                                <div
                                                                    class="card-container-red mb-1 d-flex flex-row justify-content-center align-items-center">
                                                                    <h2 class="card-container-head">{{diag_openApp}}</h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <div class="col-6">
                                                    <div class="card-container" style="height: 340px;">
                                                        <h2 class="card-heading">Mode of payments</h2>
                                                        <div class="graph-container mb-3">
                                                            <div>
                                                                <canvas id="modeOfPayCanvasIpAdm" width="361"
                                                                    height="175" style="display: block; "></canvas>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> -->
                                                <div class="col-6">
                                                    <div class="card-container" style="height: 340px;">
                                                        <h2 class="card-heading">Hospital bills</h2>
                                                        <div class="graph-container mb-3">
                                                            <div>
                                                                <canvas id="hospBillsOpAdm" width="361" height="175"
                                                                    style="display: block; "></canvas>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <div class="card-container" style="height: 340px;">
                                                        <h2 class="card-heading">Hospital bill amount</h2>
                                                        <div class="graph-container mb-3">
                                                            <div>
                                                                <canvas id="hospbillAmtOpAdm" width="361" height="175"
                                                                    style="display: block; "></canvas>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-2 bill-amt-card">
                                                    <div class="card-container mb-1 d-flex flex-row justify-content-center align-items-center"
                                                        style="background-color: #ffffff; color: #0079bf; height: 340px;">
                                                        <div style="text-align: center;">
                                                            <p class="mb-2 card-container-para"><b>Hospital Total Bill Amount</b></p>
                                                            <h3 class="card-container-head"
                                                                style="margin-bottom: 0px !important; color: #000;">
                                                                {{hosp_totalOpBillAmt}}</h3>
                                                        </div>
                                                    </div>
                                                </div> 
                                                <div class="col-6">
                                                    <div class="card-container" style="height: 340px;">
                                                        <h2 class="card-heading">Pharmacy bills</h2>
                                                        <div class="graph-container mb-3">
                                                            <div>
                                                                <canvas id="pharmBillsOpAdm" width="361" height="175"
                                                                    style="display: block; "></canvas>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <div class="card-container" style="height: 340px;">
                                                        <h2 class="card-heading">Bill amount</h2>
                                                        <div class="graph-container mb-3">
                                                            <div>
                                                                <canvas id="pharmabillAmtOpAdm" width="361" height="175"
                                                                    style="display: block; "></canvas>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-2">
                                                    <div class="card-container">
                                                        <h2 class="card-heading">Indents</h2>
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <h2 class="card-heading-2">Total</h2>
                                                                <div class="indent-card-container mb-1 d-flex flex-row justify-content-center align-items-center"
                                                                    style="padding: 8px;">
                                                                    <h2 class="indent-card-container-head">{{total_indents}}</h2>
                                                                </div>
                                                            </div>
                                                            <div class="col-12">
                                                                <h2 class="card-heading-2">Completed</h2>
                                                                <div class="card-container-green mb-1 d-flex flex-row justify-content-center align-items-center"
                                                                    style="padding: 8px;">
                                                                    <h2 class="card-container-head">{{complete_pos_indents}}</h2>
                                                                </div>
                                                            </div>
                                                            <div class="col-12">
                                                                <h2 class="card-heading-2">Pending</h2>
                                                                <div class="card-container-red mb-1 d-flex flex-row justify-content-center align-items-center"
                                                                    style="padding: 8px;">
                                                                    <h2 class="card-container-head">{{pending_indents}}</h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div class="card-container" style="height: 340px;">
                                                        <h2 class="card-heading">Diagnosis bills</h2>
                                                        <div class="graph-container mb-3">
                                                            <div>
                                                                <canvas id="diagBillsOpAdm" width="361" height="175"
                                                                    style="display: block; "></canvas>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <div class="card-container" style="height: 340px;">
                                                        <h2 class="card-heading">Diagnosis bill amount</h2>
                                                        <div class="graph-container mb-3">
                                                            <div>
                                                                <canvas id="diagbillAmtOpAdm" width="361" height="175"
                                                                    style="display: block; "></canvas>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-2 bill-amt-card">
                                                    <div class="card-container mb-1 d-flex flex-row justify-content-center align-items-center"
                                                        style="background-color: #ffffff; color: #0079bf; height: 340px;">
                                                        <div style="text-align: center;">
                                                            <p class="mb-2 card-container-para"><b>Diagnosis Total Bill Amount</b></p>
                                                            <h3 class="card-container-head"
                                                                style="margin-bottom: 0px !important; color: #000;">
                                                                {{diag_totalOpBillAmt}}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-6" *ngIf="!isBar_pharma">
                                                    <div class="card-container" style="height: 340px;">
                                                        <h2 class="card-heading">Pharmacy bills paytypes</h2>
                                                        <div class="graph-container mb-3">
                                                            <div>
                                                                <canvas id="pharmaBillsPaytypeOpAdm" width="361" height="175"
                                                                    style="display: block; "></canvas>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-6" *ngIf="isBar_pharma">
                                                    <div class="card-container" style="height: 340px;">
                                                        <h2 class="card-heading">Pharmacy bills paytypes</h2>
                                                        <div class="graph-container mb-3">
                                                            <div>
                                                                <canvas id="pharmaBillsPaytypeOpAdm_Bar" width="361" height="175"
                                                                    style="display: block; "></canvas>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-6" *ngIf="!isBar_hosp">
                                                    <div class="card-container" style="height: 340px;">
                                                        <h2 class="card-heading">Hospital bills paytypes</h2>
                                                        <div class="graph-container mb-3">
                                                            <div>
                                                                <canvas id="hospBillsPaytypeOpAdm" width="361" height="175"
                                                                    style="display: block; "></canvas>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-6" *ngIf="isBar_hosp">
                                                    <div class="card-container" style="height: 340px;">
                                                        <h2 class="card-heading">Hospital bills paytypes</h2>
                                                        <div class="graph-container mb-3">
                                                            <div>
                                                                <canvas id="hospBillsPaytypeOpAdm_Bar" width="361" height="175"
                                                                    style="display: block; "></canvas>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>  
                                                <div class="col-6" *ngIf="!isBar_diag">
                                                    <div class="card-container" style="height: 340px;">
                                                        <h2 class="card-heading">Diagnosis bills paytypes</h2>
                                                        <div class="graph-container mb-3">
                                                            <div>
                                                                <canvas id="diagBillsPaytypeOpAdm" width="361" height="175"
                                                                    style="display: block; "></canvas>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-6" *ngIf="isBar_diag">
                                                    <div class="card-container" style="height: 340px;">
                                                        <h2 class="card-heading">Diagnosis bills paytypes</h2>
                                                        <div class="graph-container mb-3">
                                                            <div>
                                                                <canvas id="diagBillsPaytypeOpAdm_Bar" width="361" height="175"
                                                                    style="display: block; "></canvas>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>                                          
                                            </div>
                                        </div>
                                        
                                    </mdb-card-body>
                                </mdb-card>
                            </mat-tab>

                            <mat-tab label="inpatient">
                                <ng-template mat-tab-label>
                                    <img src="../../../assets/ui_icons/second_opinion/Completed_icon.svg" width="20px"
                                        height="auto" />
                                    &nbsp; Inpatient
                                </ng-template>
                                <mdb-card style="width: 99% !important;margin-left: 3px;">
                                    <mdb-card-body>
                                        <div class="row mb-3">
                                            <div class="col-12 col-sm-6 col-md-6  col-lg-2 col-xl-2"
                                                style="margin-top: 5px;width: auto;">
                                                <mat-label class="matlabel"
                                                    style="font-size: 15px; font-weight: 600;">Duration</mat-label>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                                                <select disableOptionCentering class="ipcss widthappt"
                                                    (change)="Appointment_type_change()" [(ngModel)]="appointment_type"
                                                    style="border: 1px solid black !important;">
                                                    <option value="Today">Today</option>
                                                    <option value="Weekly">Weekly</option>
                                                    <option value="Fort Night">Fort Night</option>
                                                    <option value="Monthly">Monthly</option>
                                                    <option value="3 Months">3 Months</option>
                                                    <option value="6 Months">6 Months</option>
                                                    <option value="Yearly">Yearly</option>
                                                </select>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-6  col-lg-2 col-xl-2"
                                                style="margin-top: 5px;width: auto;">
                                                <mat-label class="matlabel"
                                                    style="font-size: 15px; font-weight: 600;">From :</mat-label>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                                                <input type="date" [(ngModel)]="from_date" class="ipcss widthappt"
                                                    [disabled]="true">
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-6  col-lg-2 col-xl-2"
                                                style="margin-top: 5px;width: auto;">
                                                <mat-label class="matlabel"
                                                    style="font-size: 15px; font-weight: 600;">To :</mat-label>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                                                <input type="date" [(ngModel)]="to_date" class="ipcss widthappt"
                                                    [disabled]="true">
                                            </div>
                                        </div>
                                        <div class="container-fluid">
                                            <div class="row">
                                                <div class="col-7">
                                                    <div class="card-container mb-3">
                                                        <h2 class="card-heading">Appointments</h2>
                                                        <div class="row">
                                                            <div class="col-3">
                                                                <h2 class="card-heading-2">Completed</h2>
                                                                <div
                                                                    class="card-container-green mb-1 d-flex flex-row justify-content-center align-items-center">
                                                                    <h2 class="card-container-head">{{doc_completedApp}}</h2>
                                                                </div>
                                                            </div>
                                                            <div class="col-3">
                                                                <h2 class="card-heading-2">Open</h2>
                                                                <div
                                                                    class="card-container-red mb-1 d-flex flex-row justify-content-center align-items-center">
                                                                    <h2 class="card-container-head">{{doc_openApp}}</h2>
                                                                </div>
                                                            </div>
                                                            <div class="col-3">
                                                                <h2 class="card-heading-2">Confirmed</h2>
                                                                <div
                                                                    class="indent-card-container mb-1 d-flex flex-row justify-content-center align-items-center">
                                                                    <h2 class="indent-card-container-head">{{doc_confirmedApp}}</h2>
                                                                </div>
                                                            </div>
                                                            <div class="col-3">
                                                                <h2 class="card-heading-2">Inpatients</h2>
                                                                <div
                                                                    class="indent-card-container mb-1 d-flex flex-row justify-content-center align-items-center">
                                                                    <h2 class="indent-card-container-head">{{doc_inpatApp}}</h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-5">
                                                    <div class="card-container mb-3">
                                                        <h2 class="card-heading">Diagnostic Order</h2>
                                                        <div class="row">
                                                            <div class="col-4">
                                                                <h2 class="card-heading-2">Total</h2>
                                                                <div
                                                                    class="indent-card-container mb-1 d-flex flex-row justify-content-center align-items-center">
                                                                    <h2 class="indent-card-container-head">15</h2>
                                                                </div>
                                                            </div>
                                                            <div class="col-4">
                                                                <h2 class="card-heading-2">Completed</h2>
                                                                <div
                                                                    class="card-container-green mb-1 d-flex flex-row justify-content-center align-items-center">
                                                                    <h2 class="card-container-head">12</h2>
                                                                </div>
                                                            </div>
                                                            <div class="col-4">
                                                                <h2 class="card-heading-2">Pending</h2>
                                                                <div
                                                                    class="card-container-red mb-1 d-flex flex-row justify-content-center align-items-center">
                                                                    <h2 class="card-container-head">3</h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <div class="col-6">
                                                    <div class="card-container" style="height: 340px;">
                                                        <h2 class="card-heading">Mode of payments</h2>
                                                        <div class="graph-container mb-3">
                                                            <div>
                                                                <canvas id="modeOfPayCanvasIpAdm" width="361"
                                                                    height="175" style="display: block; "></canvas>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> -->
                                                <div class="col-6">
                                                    <div class="card-container" style="height: 340px;">
                                                        <h2 class="card-heading">Hospital bills</h2>
                                                        <div class="graph-container mb-3">
                                                            <div>
                                                                <canvas id="hospBillsIpAdm" width="361" height="175"
                                                                    style="display: block; "></canvas>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <div class="card-container" style="height: 340px;">
                                                        <h2 class="card-heading">Hospital bill amount</h2>
                                                        <div class="graph-container mb-3">
                                                            <div>
                                                                <canvas id="hospbillAmtIpAdm" width="361" height="175"
                                                                    style="display: block; "></canvas>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> 
                                                <div class="col-2 bill-amt-card">
                                                    <div class="card-container mb-1 d-flex flex-row justify-content-center align-items-center"
                                                        style="background-color: #ffffff; color: #0079bf; height: 340px;">
                                                        <div style="text-align: center;">
                                                            <p class="mb-2 card-container-para"><b>Hospital Total Bill Amount</b></p>
                                                            <h3 class="card-container-head"
                                                                style="margin-bottom: 0px !important; color: #000;">
                                                                {{hosp_totalIpBillAmt}}</h3>
                                                        </div>
                                                    </div>
                                                </div>   
                                                <div class="col-6">
                                                    <div class="card-container" style="height: 340px;">
                                                        <h2 class="card-heading">Pharmacy bills</h2>
                                                        <div class="graph-container mb-3">
                                                            <div>
                                                                <canvas id="pharmBillsIpAdm" width="361" height="175"
                                                                    style="display: block; "></canvas>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <div class="card-container" style="height: 340px;">
                                                        <h2 class="card-heading">Bill amount</h2>
                                                        <div class="graph-container mb-3">
                                                            <div>
                                                                <canvas id="pharmabillAmtIpAdm" width="361" height="175"
                                                                    style="display: block; "></canvas>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-2">
                                                    <div class="card-container">
                                                        <h2 class="card-heading">Indents</h2>
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <h2 class="card-heading-2">Total</h2>
                                                                <div class="indent-card-container mb-1 d-flex flex-row justify-content-center align-items-center"
                                                                    style="padding: 8px;">
                                                                    <h2 class="indent-card-container-head">{{total_indents}}</h2>
                                                                </div>
                                                            </div>
                                                            <div class="col-12">
                                                                <h2 class="card-heading-2">Completed</h2>
                                                                <div class="card-container-green mb-1 d-flex flex-row justify-content-center align-items-center"
                                                                    style="padding: 8px;">
                                                                    <h2 class="card-container-head">{{complete_pos_indents}}</h2>
                                                                </div>
                                                            </div>
                                                            <div class="col-12">
                                                                <h2 class="card-heading-2">Pending</h2>
                                                                <div class="card-container-red mb-1 d-flex flex-row justify-content-center align-items-center"
                                                                    style="padding: 8px;">
                                                                    <h2 class="card-container-head">{{pending_indents}}</h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div class="card-container" style="height: 340px;">
                                                        <h2 class="card-heading">Diagnosis bills</h2>
                                                        <div class="graph-container mb-3">
                                                            <div>
                                                                <canvas id="diagBillsIpAdm" width="361" height="175"
                                                                    style="display: block; "></canvas>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <div class="card-container" style="height: 340px;">
                                                        <h2 class="card-heading">Diagnosis bill amount</h2>
                                                        <div class="graph-container mb-3">
                                                            <div>
                                                                <canvas id="diagbillAmtIpAdm" width="361" height="175"
                                                                    style="display: block; "></canvas>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-2 bill-amt-card">
                                                    <div class="card-container mb-1 d-flex flex-row justify-content-center align-items-center"
                                                        style="background-color: #ffffff; color: #0079bf; height: 340px;">
                                                        <div style="text-align: center;">
                                                            <p class="mb-2 card-container-para"><b>Diagnosis Total Bill Amount</b></p>
                                                            <h3 class="card-container-head"
                                                                style="margin-bottom: 0px !important; color: #000;">
                                                                {{diag_totalIpBillAmt}}</h3>
                                                        </div>
                                                    </div>
                                                </div>   
                                                <div class="col-6" *ngIf="!isBar_pharma_Ip">
                                                    <div class="card-container" style="height: 340px;">
                                                        <h2 class="card-heading">Pharmacy bills paytypes</h2>
                                                        <div class="graph-container mb-3">
                                                            <div>
                                                                <canvas id="pharmaBillsPaytypeIpAdm" width="361" height="175"
                                                                    style="display: block; "></canvas>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-6" *ngIf="isBar_pharma_Ip">
                                                    <div class="card-container" style="height: 340px;">
                                                        <h2 class="card-heading">Pharmacy bills paytypes</h2>
                                                        <div class="graph-container mb-3">
                                                            <div>
                                                                <canvas id="pharmaBillsPaytypeIpAdm_Bar" width="361" height="175"
                                                                    style="display: block; "></canvas>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-6" *ngIf="!isBar_hosp_Ip">
                                                    <div class="card-container" style="height: 340px;">
                                                        <h2 class="card-heading">Hospital bills paytypes</h2>
                                                        <div class="graph-container mb-3">
                                                            <div>
                                                                <canvas id="hospBillsPaytypeIpAdm" width="361" height="175"
                                                                    style="display: block; "></canvas>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-6" *ngIf="isBar_hosp_Ip">
                                                    <div class="card-container" style="height: 340px;">
                                                        <h2 class="card-heading">Hospital bills paytypes</h2>
                                                        <div class="graph-container mb-3">
                                                            <div>
                                                                <canvas id="hospBillsPaytypeIpAdm_Bar" width="361" height="175"
                                                                    style="display: block; "></canvas>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>  
                                                <div class="col-6" *ngIf="!isBar_diag">
                                                    <div class="card-container" style="height: 340px;">
                                                        <h2 class="card-heading">Diagnosis bills paytypes</h2>
                                                        <div class="graph-container mb-3">
                                                            <div>
                                                                <canvas id="diagBillsPaytypeIpAdm" width="361" height="175"
                                                                    style="display: block; "></canvas>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-6" *ngIf="isBar_diag">
                                                    <div class="card-container" style="height: 340px;">
                                                        <h2 class="card-heading">Diagnosis bills paytypes</h2>
                                                        <div class="graph-container mb-3">
                                                            <div>
                                                                <canvas id="diagBillsPaytypeIpAdm_Bar" width="361" height="175"
                                                                    style="display: block; "></canvas>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>          
                                                                                                 
                                            </div>
                                        </div>                                        
                                    </mdb-card-body>
                                </mdb-card>
                            </mat-tab>
                        </mat-tab-group>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>