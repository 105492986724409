import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { CommonDataService } from '../../providers/common-data.service';
import { ToastrService } from 'ngx-toastr';
import { Diagnosis_Helper } from '../Diagnosis_Helper';
import { ipaddress } from '../../ipaddress';
import { Message_data } from '../../../assets/js/Message_data';
import { MenuViewService } from '../../../app/common-module/menu-view/menu-view.service';
import { Helper_Class } from '../../../app/helper_class';

@Component({
  selector: 'app-diag-test-add',
  templateUrl: './diag-test-add.component.html',
  styleUrls: ['./diag-test-add.component.css']
})
export class DiagTestAddComponent implements OnInit {
  public diag_test_cat_id;
  public diagnosisapp_id;
  public test_category_data = [];
  public dis_category_data = [];
  public Subtype_data = [];
  public diag_subtest_id;
  public Diag_Subtest_name;
  public diagSubUOM;
  public male_min;
  public male_max;
  public female_min;
  public female_max;
  public child_min;
  public child_max;
  public Diag_child_data;
  public born_min;
  public born_max;
  public Diag_newborn_data;
  public Diag_subtest_list_data = [];
  public Send_sublist_data = [];
  public Diag_test_id;
  public Diag_TestName;
  public diag_test_categ;
  public diag_dis_categ;
  public dis_test_categ;
  public Diag_test_subtype;
  public Diag_Uof;
  public Diag_test_cost;
  public test_price;
  public diag_add_test_id;
  public diag_cat_id;
  public diag_dis_id;
  public diag_subcat_id;
  public save_btn: boolean;
  public update_btn: boolean;
  public header_footer_flag: boolean;
  public loader: boolean;
  public unithidden: boolean;
  public diagsubhidden: boolean = true;
  public gettestdata;
  public Select = "Select";
  public addTest_flag: boolean = true;
  public subtest_flag: boolean = true;
  public subtest_uomflag: boolean = true;
  public test_method;
  public test_method_flag: boolean;
  public addSubtest_checked: boolean = false;
  public main_test_uom_flag: boolean = true;
  public ref_flag: boolean = false;
  public reference_flag: boolean = false;
  public male_ref;
  public female_ref;
  public child_ref;
  public newborn_ref;

  public referenceArray: any = [];
  public age_ref;
  public gender_ref;
  public ref_value;
  // ----------------------------------------New Test Create--
  public referenceArray_2: any = [];
  public min_ref_value;
  public max_ref_value;

  public diag_dept;
  public department_data: any = [];
  public reagents_data: any = [];
  public reagent_id;
  public Diag_testtube_id;
  public testtube_data: any = [];
  public Sample_type;
  public sampletype_data: any[];
  public age_input;
  public age_input_max
  public frequency_Value = '0';
  public frequency_Measure = "Day(s)";
  public outsource_cost;
  public insource_cost;
  public conduction_format = '2';
  public show_test_ng: boolean = true;
  public conduct_doctor_ng;
  public conduct_doctor = '0';
  public con_doc_check: boolean = false;
  public min_nor_value;
  public max_nor_value;
  public min_anor_value;
  public max_anor_value;
  public min_crit_value;
  public max_crit_value;
  public UOM_list: any = [];
  public unit_of_measure: any;
  public inpat_outpt;
  public age_grp_check: boolean = true;
  public show_test = '0';

  public newbutton: boolean = false
  public editbutton: boolean = false;
  public deletebutton: boolean = false;
  public printbutton: boolean = false;
  public viewbutton: boolean = false;
  public moduleList: any = [];
  public is_active: boolean = true;
  public is_specimen_req: boolean = false;
  public specimenTypeFlag: boolean = false;
  public conductflag: boolean = false;
  public microdrugFlag: boolean = true;

  //mdbTable
  dtOptions: DataTables.Settings = {};
  public range;
  modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'script': 'sub' }, { 'script': 'super' }], // superscript/subscript
      [{ 'indent': '-1' }, { 'indent': '+1' }], // outdent/indent
      [{ 'direction': 'rtl' }], // text direction
      [{ 'size': ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      [{ 'color': [] }, { 'background': [] }], // dropdown with defaults from theme
      [{ 'font': [] }],
      [{ 'align': [] }],
      ['clean'], // remove formatting button
    ]
  };
  editorStyle = {
    height: '300px;',
  };
  public templates: any = [];
  public report_template: any;
  public Diag_TAT: any;
  conductionFormatarr: any = [];
  public templateFlag: boolean = true;
  public dropFlag: boolean = true;
  public dropFlag1: boolean = true;
  public cbt_Template: any;
  template_flag: boolean = true;
  diagsub: boolean;
  public showAgeInp: boolean = true;
  public collect_TAT: any;
  public assert_TAT: any;
  public upload_TAT: any;
  public approve_TAT: any;
  public total_TAT: any;
  public order_no: any = '';
  public conduction_format_disable: boolean = false;
  public previous_order_no: any = '';
  public drop_default;
  public drop_default1;
  public total_TAT_Measure;
  public upload_TAT_Measure;
  public specimen_required;
  editIndex: number;
  public test_order: string;
  public drug_type;
  sampletype_micro_data: any = [];

  constructor(public diagnosisservice: MenuViewService, public toastr: ToastrService,
    public gservice: CommonDataService, public http: HttpClient, public dialog: MatDialog) {
    this.unithidden = false;
  }

  ngOnInit(): void {
    if (this.moduleList != undefined) {
      for (var i = 0; i < this.moduleList.length; i++) {
        if (this.moduleList[i].module_id == "102") {
          console.log(JSON.stringify(this.moduleList[i]));
          if (this.moduleList[i].edit == "1") {
            this.editbutton = true;//update button
          }
          if (this.moduleList[i].create == "1") {
            this.newbutton = true;
          }
          if (this.moduleList[i].delete == "1") {
            this.deletebutton = true;
          }
          if (this.moduleList[i].print == "1") {
            this.printbutton = true;
          }
          if (this.moduleList[i].view == "1") {
            this.viewbutton = true;
          }
        }
      }
    }

    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      "lengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
      "order": [[2, 'asc']],
      "columnDefs": [
        { "orderable": false, "targets": [0, 1, 3, 4, 5, 6, 7] },
      ],
      "language": {
        "search": "Filter"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"l><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };

    if (Helper_Class.getInfo().hospitals[0].test_range == "1") {
      this.ref_flag = true;

    } else {
      this.ref_flag = false;
    }

    this.getDiagDepartment();
    this.getConductionFormats();
    this.upload_TAT_Measure = "min";
    this.total_TAT_Measure = "min";
    this.gettestdata = Diagnosis_Helper.getdiagnosistest();
    this.Diag_test_id = this.gettestdata.Diag_test_id;
    this.diagnosisapp_id = this.gettestdata.diagnosis_id
    this.order_no = this.gettestdata.test_order;

    if (this.Diag_test_id != undefined) {
      this.save_btn = true; // Save & Update btn
      if (this.editbutton == true) {
        this.update_btn = true;
      } else {
        this.update_btn = false;
      }
      this.getTestDetails(this.Diag_test_id)

    } else {
      this.save_btn = false; // Save & Update btn
      this.update_btn = true;

      this.getUnitOfMeasure();
      this.getDiseaseCategory('0');
      //this.getTestCategory('1', '0');
      // this.getSubtypes('0');
    }

    this.showOrhide_test(true);

    this.moduleList = Helper_Class.getmodulelist();
  }

  allAgeChk(e) {
    this.age_grp_check = e
    if (e == true) {
      this.showAgeInp = true;
    } else {
      this.showAgeInp = false;
    }
  }

  getReportTemplate() {
    var send_data = {
      diag_centre_id: this.diagnosisapp_id,
      dept_id: this.diag_dept,
    }

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/gdtrt', send_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.templates = obj.diag_templates;
          console.log(this.templates)
        }, error => { });
  }

  getConductionFormats() {
    this.reagents_data = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'diagnosiscontroller/gdcf',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("conduction farmats", obj)
          this.conductionFormatarr = obj.conduction_formats;

          this.dropFlag1 = true;
          this.dropFlag = true;
        }, error => { });
  }

  getUnitOfMeasure() {
    this.UOM_list = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'diagnosiscontroller/gubt',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.UOM_list = obj.uom_list;

          console.log("getUnitOfMeasure", this.UOM_list)
        }, error => { });
  }

  getDiagDepartment() {
    this.department_data = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'mas/gdd', { diag_centre_id: this.diagnosisapp_id },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          var dept_data = obj.diag_dept_list;

          if (obj.diag_dept_list.length != 0) {
            for (var i = 0; i < obj.diag_dept_list.length; i++) {
              this.department_data.push({
                diag_department_id: dept_data[i].diag_department_id,
                diag_dept_desc: dept_data[i].diag_dept_desc,
                status: dept_data[i].status,
              })
            }
            this.diag_dept = this.department_data[0].diag_department_id;
            if (this.diag_test_cat_id != undefined) {
              this.getTestCategory(this.diag_dept, this.diag_test_cat_id);
            } else {
              this.getTestCategory(this.diag_dept, '0');
            }
            this.getReportTemplate();
            console.log("getDiagDepartment", this.department_data)
          }
        }, error => { });
  }

  conddocReq(e) {
    if (e == true) {
      this.conduct_doctor = "1"

    } else {
      this.conduct_doctor = "0";
    }

  }

  showOrhide_test(e) {
    if (e == true) {
      this.show_test = "1"

    } else {
      this.show_test = "0";
    }
  }

  showTemplate(tempId) {
    if (this.templates != undefined) {
      console.log(this.cbt_Template)
      for (var i = 0; i < this.templates.length; i++) {
        if (this.templates[i].template_id == tempId) {
          this.cbt_Template = this.templates[i].template_desc;

        }
      }
    }
  }

  checkOrder(data) {
    if (parseInt(data) < parseInt(this.previous_order_no)) {
      this.toastr.error("Test order already exist")
    }
  }

  getSampleTypes() {
    if (this.diag_dept == 1) {
      if (this.diag_test_categ == 'Microbiology') {
        this.specimenTypeFlag = false;
        this.addTest_flag = true;
        this.diagsubhidden = true;
        this.reference_flag = true;
        this.conduction_format = '3';
        // this.conduction_format_disable = true;
        // this.formatChange('3')
       
        this.formatChange(this.conduction_format)

      } else {
        
        // if (this.conduction_format != '2') {
        //   this.conduction_format = '2';
        // }
        this.formatChange(this.conduction_format)
        this.addTest_flag = false;
        if (this.conduction_format == '2') {
          this.diagsubhidden = false;
        }

        this.reference_flag = false;
      }

    } else {
      this.addTest_flag = true;
    }

    this.sampletype_data = [];
    this.sampletype_micro_data = [];

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'mas/gdst ',
      {
        diag_centre_id: this.diagnosisapp_id,
        // sub_dept: this.diag_test_categ,
      },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          var sampleTypesData = obj.specimen_type_list;
          var sensitive_list = obj.sensitive_list;
          console.log("getSampleTypes-------", obj);

          if (sampleTypesData.length != 0) {
            for (var i = 0; i < sampleTypesData.length; i++) {
              this.sampletype_data.push({
                sample_type_id: sampleTypesData[i].specimen_type_id,
                sample_desc: sampleTypesData[i].specimen_desc,
                status: sampleTypesData[i].status,
              })
            }
          }

          if (sensitive_list.length != 0) {
            for (var j = 0; j < sensitive_list.length; j++) {
              this.sampletype_micro_data.push({
                sample_type_id: sensitive_list[j].sensitive_type_id,
                sample_desc: sensitive_list[j].sensitive_desc,
              })
            }
          }
        }, error => { });
  }

  getDiseaseCategory(categ_id) {//get disease cateegory
    this.dis_category_data = [];

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'diagnosiscontroller/ddcat',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.dis_category_data = obj.test_categories;

          if (categ_id != "0" && categ_id != undefined) {
            for (var i = 0; i < this.dis_category_data.length; i++) {
              if (this.dis_category_data[i].diag_dis_cat_id == categ_id) {
                this.diag_dis_categ = this.dis_category_data[i].description;
                break;
              }
            }
          }
        }, error => { });
  }

  getTestCategory(deptId, categ_id) {//get disease cateegory
    this.test_category_data = [];
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/dtcatn',
      { diag_centre_id: this.diagnosisapp_id, diag_dept_id: deptId },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log("getTestCategory - obj" + JSON.stringify(obj))
          this.test_category_data = obj.test_categories;
          this.previous_order_no = obj.test_order;
          if (this.order_no == undefined) {
            this.order_no = obj.test_order;
          }

          // if (categ_id != "0" && categ_id != undefined) {
          //   for (var i = 0; i < this.test_category_data.length; i++) {
          //     if (this.test_category_data[i].diag_test_cat_id == categ_id) {
          //       this.diag_test_categ = this.test_category_data[i].description;
          //       break;
          //     }
          //   }
          // }
          if (categ_id != 0) {
            for (var j = 0; j < this.test_category_data.length; j++) {
              if (this.test_category_data[j].diag_test_cat_id == categ_id) {
                this.diag_test_categ = this.test_category_data[j].description;
              }
            }

          } else {
            this.diag_test_categ = this.test_category_data[0].description;
          }

          if (this.diag_dept == 1) {
            if (this.conduction_format == undefined) {
              this.conduction_format = '2';
            }
            this.diagsubhidden = false;
            this.reference_flag = false;
            this.addTest_flag = false;

          } else {
            this.addTest_flag = true;
          }
          this.getSampleTypes();
          console.log('this.test_category_data', obj)
        }, error => { });

    if (deptId == "1") {
      if (this.conduction_format == undefined) {
        this.diagsubhidden = false;
        this.conduction_format = "2";
      }

      this.templateFlag = true;
      
      this.formatChange(this.conduction_format)
      this.conduction_format_disable = false;
      this.addTest_flag = false;
      this.unithidden = false;
      this.specimenTypeFlag = false;
      this.ref_flag = true;

    } else {
      this.formatChange("1")
      this.conduction_format = "1";
      this.conduction_format_disable = true;
      this.specimenTypeFlag = true;
      this.addTest_flag = true;
      this.unithidden = true;
      this.diagsubhidden = true;
      this.ref_flag = false;
    }
  }

  formatChange(Id) {
    if (Id == 1) {
      this.templateFlag = false;
      this.diagsubhidden = true;
      this.dropFlag = true;
      this.dropFlag1 = true;
      this.template_flag = false;
      this.unithidden = true;
      this.specimenTypeFlag = false;
      this.reference_flag = true;
      this.addTest_flag = true;
      this.microdrugFlag = true;

    } else if (Id == 2) {
      if (this.diag_dept == "1") {
        this.addTest_flag = false;
        this.diagsubhidden = false;
        this.ref_flag = true;
        this.template_flag = true;
        this.templateFlag = true;
        this.dropFlag = true;
        this.dropFlag1 = true;
        this.unithidden = false;
        this.specimenTypeFlag = false;
        this.reference_flag = false;
        this.microdrugFlag = true;
      }

    } else if (Id == 4) {
      this.templateFlag = true;
      if (this.conductflag == true) {
        this.dropFlag = true;
        this.dropFlag1 = false;
      } else {
        this.dropFlag = false;
        this.dropFlag1 = true;
      }

      this.unithidden = false;
      this.addTest_flag = false;
      this.reference_flag = false;
      this.diagsubhidden = false;
      this.template_flag = true;
      this.ref_flag = true;
      this.specimenTypeFlag = false;
      this.microdrugFlag = true;

    } else {
      this.templateFlag = true;
      this.dropFlag = true;
      this.dropFlag1 = true;
      this.diagsubhidden = true;
      this.template_flag = true;
      this.ref_flag = false;
      this.unithidden = true;
      this.specimenTypeFlag = false;
      this.reference_flag = false;
      this.addTest_flag = true;
      this.microdrugFlag = false;
    }
  }

  onTemplateChanged = (event) => {
    this.cbt_Template = event.html
  }

  getSubtypes(subtest_id) {//Get subtype
    this.Subtype_data = [];

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'diagnosiscontroller/dttypes',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.Subtype_data = obj.diag_test_types;

          if (subtest_id != null) {
            for (var i = 0; i < this.Subtype_data.length; i++) {
              if (this.Subtype_data[i].diag_test_type_id == subtest_id) {
                this.Diag_test_subtype = this.Subtype_data[i].test_type_name;
                // this.subTypeChange();
                break;
              }
            }
          }
        }, error => { });
  }

  Subtest_Select_Click(subtest_data) {
    for (var i = 0; i < this.Diag_subtest_list_data.length; i++) {
      if (this.Diag_subtest_list_data[i].sub_test_name == subtest_data) {
        // if(this.Diag_subtest_list_data[i].conduct_format != undefined){
        //   this.conductflag = true;
        // }

        if (this.Diag_subtest_list_data[i].conduct_format == "4") {
          this.dropFlag1 = false;
          this.dropFlag = true;
        } else {
          this.dropFlag1 = true;
          this.dropFlag = true;
        }
        // this.subtest_uomflag = false;
        this.unithidden = true;
        this.diag_subtest_id = this.Diag_subtest_list_data[i].sub_test_id;
        this.Diag_Subtest_name = this.Diag_subtest_list_data[i].sub_test_name;
        this.diagSubUOM = this.Diag_subtest_list_data[i].sub_uom;
        this.range = this.Diag_subtest_list_data[i].range;

        this.test_method = this.Diag_subtest_list_data[i].test_method;
        // this.conduction_format = this.Diag_subtest_list_data[i].conduct_format;
        this.drop_default1 = this.Diag_subtest_list_data[i].drop_default;
        if (this.Diag_subtest_list_data[i].ref_range != undefined) {
          this.referenceArray = this.Diag_subtest_list_data[i].ref_range;
        }
        this.Diag_subtest_list_data.splice(i, 1);

        this.editIndex = i;

        // for(var i=0;i<this.conductionFormatarr.length;i++) {
        //   if(this.conductionFormatarr[i].format_id =="1" 
        //     ||this.conductionFormatarr[i].format_id =="3"){
        //     this.conductionFormatarr.splice(i,1)
        //   }
        // }
        break;
      }
    }
  }

  Subtest_Delete_Click(subtest_data) {//subtest delete
    for (var i = 0; i < this.Diag_subtest_list_data.length; i++) {
      if (this.Diag_subtest_list_data[i].sub_test_name == subtest_data) {
        this.Diag_subtest_list_data[i].status = 0;
        break;
      }
    }
  }

  test_add() {//add tests 
    var Valid_flag = false;

    if (this.Diag_Subtest_name == undefined || this.Diag_Subtest_name == "") {
      if (this.Diag_TestName == undefined || this.Diag_TestName == "")
        Valid_flag = true;
      else
        this.Diag_Subtest_name = this.Diag_TestName;
    }

    if (this.diagSubUOM == undefined || this.diagSubUOM == "") {
      if (this.unit_of_measure == undefined || this.unit_of_measure == "") {
        Valid_flag = false;
      } else {
        this.diagSubUOM = this.unit_of_measure;
      }
    }

    if (this.subtest_flag == false && this.Diag_Subtest_name != undefined && this.Diag_TestName != undefined) {
      var diagTestname = this.Diag_TestName.split(" ").join("").toLowerCase();
      var diagSubtestname = this.Diag_Subtest_name.split(" ").join("").toLowerCase();
      if (diagTestname == diagSubtestname) {
        Valid_flag = true;
        this.toastr.error("Test and subtest name cannot be identical", null, { timeOut: 3000 });
      }
    }

    if (this.drop_default == undefined) {
      this.drop_default = this.drop_default1;
    }
    if (Valid_flag == false) {
      var Exist_flag = false;
      if (this.Diag_subtest_list_data.length != 0) {
        Exist_flag = false;
      } else {
        for (var i = 0; i < this.Diag_subtest_list_data.length; i++) {
          if (this.Diag_subtest_list_data[i].sub_test_name == this.Diag_Subtest_name) {
            Exist_flag = true;
            break;
          }
        }
      }

      var sub_id = this.diag_subtest_id != undefined ? this.diag_subtest_id : "0";
      var conduct_form;
      for (var c = 0; c < this.conductionFormatarr.length; c++) {
        if (this.conductionFormatarr[c].format_id == this.conduction_format) {
          conduct_form = this.conductionFormatarr[c].format_desc;
        }
      }

      var id = this.Diag_subtest_list_data.length + 1;
      // if(this.Diag_subtest_list_data.length == 0){
      //   id = "1";
      // } else {
      //   id = this.Diag_subtest_list_data.length.toString();
      // }

      if (Exist_flag == false) {
        if (this.editIndex != undefined) {
          this.Diag_subtest_list_data.splice(this.editIndex, 0, {
            sub_test_order: this.editIndex + 1,
            sub_test_id: sub_id,
            sub_test_name: this.Diag_Subtest_name,
            sub_uom: this.diagSubUOM,
            ref_range: this.referenceArray,
            test_method: this.test_method,
            conduct_format: this.conduction_format,
            drop_default: this.drop_default1,
            conduct_form: conduct_form,
            status: 1,
          })
        } else {
          this.Diag_subtest_list_data.push({
            sub_test_order: id,
            sub_test_id: sub_id,
            sub_test_name: this.Diag_Subtest_name,
            sub_uom: this.diagSubUOM,
            ref_range: this.referenceArray,
            test_method: this.test_method,
            conduct_format: this.conduction_format,
            drop_default: this.drop_default1,
            conduct_form: conduct_form,
            status: 1,
          });
        }


        this.editIndex = null;
        console.log("this.Diag_subtest_list_data", this.Diag_subtest_list_data)

        this.Diag_Subtest_name = undefined;
        this.diagSubUOM = undefined;
        this.Diag_Subtest_name = "";
        this.diagSubUOM = "";
        this.test_method = "";
        this.drop_default1 = "";

      } else {
        this.toastr.error(Message_data.dataAlreadyExist);
      }
      this.diag_subtest_id = undefined;
      this.referenceArray = [];
    }
    // } else {
    //   this.toastr.error(Message_data.mandatory);
    // }
  }

  getTestDetails(diag_test_id) {//retrieve tests
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/tbyid/', { test_id: diag_test_id },
      { headers: headers })
      .subscribe(
        response => {
          this.loader = true;
          var obj = JSON.parse(JSON.stringify(response));
          console.log("TEST DETAILS ((())) " + JSON.stringify(obj));

          if (obj.diag_tests_id != null) {
            this.Diag_TestName = obj.test_name;
            if (obj.uom != undefined) {
              this.Diag_Uof = obj.uom;
              this.unithidden = false;
            } else {
              this.unithidden = true;
            }
            this.Diag_test_cost = obj.price;
            this.frequency_Value = obj.frequency_val;
            this.frequency_Measure = obj.frequency_measure;
            this.insource_cost = obj.insource_cost;
            this.outsource_cost = obj.outsource_cost;
            this.report_template = obj.diag_template;
            this.conduction_format = obj.conduct_format;
            this.Diag_TAT = obj.TAT;
            this.collect_TAT = obj.collectTAT;
            this.assert_TAT = obj.assertTAT;
            this.upload_TAT = obj.uploadTAT;
            this.approve_TAT = obj.approveTAT;
            this.total_TAT = obj.totalTAT;
            this.order_no = obj.test_order;
            this.cbt_Template = obj.template_desc;
            this.conduct_doctor = obj.conduct_doctor;
            this.test_category_data = obj.test_categories;
            this.diag_test_categ = obj.sub_dept;
            this.diag_test_cat_id = obj.diag_test_cat_id;

            // if(obj.diag_dept_id == '1' && obj.sub_dept == 'Microbiology'){
            //   this.microdrugFlag = false;
            // } else {
            //   this.microdrugFlag = true;
            // }

            // if(obj.sample_req == "1"){
            //   this.is_specimen_req = true;
            //   this.specimen_required=1;

            // } else {
            //   this.is_specimen_req =false;
            //   this.specimen_required=0;
            // }

            if (obj.show_booking == "1") {
              this.show_test_ng = true;
            } else {
              this.show_test_ng = false;
            }

            this.diag_dept = obj.diag_dept_id;
            if (obj.subtests != undefined) {
              this.drop_default = obj.subtests[0].drop_default;
              this.unit_of_measure = obj.subtests[0].sub_uom;
            }

            if (obj.upTATmeasure != undefined) {
              this.upload_TAT_Measure = obj.upTATmeasure;
            }
            if (obj.tTATmeasure != undefined) {
              this.total_TAT_Measure = obj.tTATmeasure;
            }

            if (obj.status == '1') {
              this.is_active = true;
            } else {
              this.is_active = false;
            }
            this.inpat_outpt = obj.ip_op;
            if (this.conduct_doctor == "1") {
              this.conduct_doctor_ng = true;
            } else {
              this.conduct_doctor_ng = false;
            }
            if (obj.conduct_format == 1) {
              this.template_flag = false;
              this.diagsub = true;
            } else {
              this.template_flag = true;
              this.diagsub = false;
            }

            if (obj.subtests != undefined) {
              if (obj.subtests.length == 1) {
                if (this.Diag_TestName == obj.subtests[0].sub_test_name) {
                  this.diagsubhidden = true;
                  this.addTest_flag = true;
                  this.subtest_flag = true;
                  this.subtest_uomflag = true;
                  this.addSubtest_checked = false;
                  this.main_test_uom_flag = true;
                  this.Diag_subtest_list_data = obj.subtests;
                  this.range = obj.subtests[0].range;
                  this.male_ref = obj.subtests[0].male;
                  this.female_ref = obj.subtests[0].female;
                  this.child_ref = obj.subtests[0].child;
                  this.newborn_ref = obj.subtests[0].newborn;
                  this.conductflag = false;
                  this.conduction_format = obj.subtests[0].conduct_format;
                  if (obj.subtests[0].conduct_format == "4") {
                    this.dropFlag = false;
                    this.dropFlag1 = true;
                  }
                  this.Subtest_Select_Click(obj.subtests[0].sub_test_name);

                } else {
                  this.diagsubhidden = false;
                  this.addTest_flag = false;
                  this.main_test_uom_flag = false;
                  this.subtest_flag = false;
                  this.subtest_uomflag = true;
                  this.unithidden = true;
                  this.conductflag = false;
                  if (this.conduction_format == "4") {
                    this.dropFlag = true;
                    this.dropFlag1 = false;
                  }
                  this.addSubtest_checked = true;
                  if (obj.subtests.length != 0 && obj.subtests[0].sub_test_name != null) {
                    this.Diag_subtest_list_data = obj.subtests;
                  }
                }

              } else {
                this.diagsubhidden = false;
                this.addTest_flag = false;
                this.main_test_uom_flag = false;
                this.subtest_flag = false;
                this.subtest_uomflag = false;
                this.unithidden = true;
                this.addSubtest_checked = true;
                this.addSubtest(this.addSubtest_checked);
                if (obj.subtests.length != 0 && obj.subtests[0].sub_test_name != null) {
                  this.Diag_subtest_list_data = obj.subtests;
                }
              }

            } else {
              this.diagsubhidden = true;
              this.addTest_flag = true;
              this.subtest_flag = true;
              this.subtest_uomflag = false;
              this.unithidden = false;
              this.addSubtest_checked = false;
            }

            if (obj.test_method != undefined && obj.test_method != null && obj.test_method != "") {
              this.test_method = obj.test_method;
            }
            if (this.Diag_test_id != undefined) {

              this.getReportTemplate();

              this.getUnitOfMeasure();
            }
            this.getDiseaseCategory(obj.diag_dis_cat_id);

            this.getTestCategory(obj.diag_dept_id, obj.diag_test_cat_id);
            // this.getSampleTypes()

            this.formatChange(obj.conduct_format);

            this.Diag_testtube_id = obj.tube_type_id;
            if (obj.sample_type != undefined) {
              this.Sample_type = obj.sample_type.split(',');
            }

            if (obj.sensitive_drug_type != undefined) {
              this.drug_type = obj.sensitive_drug_type.split(',')
            }
          }
        }, error => { });
  }

  setSampleReq(e) {
    this.specimen_required;
    if (e == true) {
      this.specimen_required = 1;
    } else {
      this.specimen_required = 0;
    }
  }

  saveTest(flag) {//save tests
    if (this.diag_dept == "1") {
      if (this.main_test_uom_flag == true) {
        this.test_add();
      }
    }

    var Valid_final_flag = false;
    if (this.Diag_TestName == undefined || this.Diag_TestName == "") {
      Valid_final_flag = true;

    } else if (this.diag_test_categ == undefined || this.diag_test_categ == "" || this.diag_test_categ == null) {
      Valid_final_flag = true;
    }
    // } else if (this.Diag_test_subtype == undefined || this.Diag_test_subtype == "" || this.Diag_test_subtype == null) {
    //   Valid_final_flag = true;
    // }

    if (this.diag_dept == "1") {
      if (this.main_test_uom_flag == false) {
        if (this.diag_dept == "1") {
          if (this.Diag_subtest_list_data.length == 0) {
            this.toastr.error(Message_data.addSubTest);
            Valid_final_flag = true;
          }
        }
      }
    }

    var sample_ids
    if (this.Sample_type != undefined) {
      if (this.Sample_type.length > 1) {
        var sample_values = ""
        for (var i = 0; i < this.Sample_type.length; i++) {
          sample_values += this.Sample_type[i] + ",";
        }
        sample_ids = sample_values.substring(0, sample_values.length - 1);
      } else if (this.Sample_type.length == 1) {
        sample_ids = this.Sample_type[0]
      } else {
        sample_ids = ""
      }
    }

    var drug_ids
    if (this.drug_type != undefined) {
      if (this.drug_type.length > 1) {
        var drug_values = ""
        for (var i = 0; i < this.drug_type.length; i++) {
          drug_values += this.drug_type[i] + ",";
        }
        drug_ids = drug_values.substring(0, drug_values.length - 1);
      } else if (this.drug_type.length == 1) {
        drug_ids = this.drug_type[0]
      } else {
        drug_ids = ""
      }
    }

    console.log("drugs" + drug_values + ' ' + drug_ids)

    var status;
    if (this.diag_add_test_id == 0) {
      status = 1;
    } else {
      if (this.is_active == true) {
        status = 1;
      } else {
        status = 0;
      }
    }

    var frequency;
    if (this.frequency_Value == '') {
      frequency = undefined;
    } else {
      frequency = this.frequency_Value;
    }

    if (Valid_final_flag == false) {
      if (this.Diag_test_cost != undefined || this.Diag_test_cost != "") {
        this.test_price = this.Diag_test_cost;
      }

      this.diag_add_test_id = flag == "Save" ? "0" : this.Diag_test_id;

      if (this.Diag_subtest_list_data.length != 0) {
        this.Send_sublist_data = [];
        if (this.Diag_subtest_list_data.length == 1) {
          for (var i = 0; i < this.Diag_subtest_list_data.length; i++) {
            this.Send_sublist_data.push(this.Diag_subtest_list_data[i]);
            this.Send_sublist_data[0].drop_default = this.drop_default;
            if (this.Send_sublist_data[0].sub_uom == undefined) {
              this.Send_sublist_data[0].sub_uom = this.unit_of_measure;
            }
          }

        } else {
          for (var i = 0; i < this.Diag_subtest_list_data.length; i++) {
            this.Send_sublist_data.push(this.Diag_subtest_list_data[i]);
          }
        }

      } else {
        if (this.diag_dept == 1) {
          this.Send_sublist_data = undefined;
        } else {
          this.Send_sublist_data = [{
            id: 1,
            sub_test_id: 0,
            sub_test_name: this.Diag_TestName,
            conduct_format: this.conduction_format,
            status: 1,
          }]
        }
      }

      for (var i = 0; i < this.test_category_data.length; i++) {
        if (this.test_category_data[i].description == this.diag_test_categ) {
          this.diag_cat_id = this.test_category_data[i].diag_test_cat_id;
        }
      }

      for (var i = 0; i < this.dis_category_data.length; i++) {
        if (this.dis_category_data[i].description == this.diag_dis_categ) {
          this.diag_dis_id = this.dis_category_data[i].diag_dis_cat_id;
        }
      }

      for (var i = 0; i < this.Subtype_data.length; i++) {
        if (this.Subtype_data[i].test_type_name == this.Diag_test_subtype) {
          this.diag_subcat_id = this.Subtype_data[i].diag_test_type_id;
        }
      }

      var save_diagnosistestdata = {
        diag_tests_id: this.diag_add_test_id,
        diag_centre_id: this.diagnosisapp_id,
        test_name: this.Diag_TestName,
        test_cat_id: this.diag_cat_id,
        dis_cat_id: this.diag_dis_id,
        test_type_id: this.diag_subcat_id,
        uom: this.Diag_Uof,
        price: this.Diag_test_cost,
        subtests: this.Send_sublist_data,
        test_method: this.test_method,

        diag_dept_id: this.diag_dept,
        // diag_reagent_id: reagent_values1,
        tube_type_id: this.Diag_testtube_id,
        sample_type: sample_ids,
        frequency_val: frequency,
        frequency_measure: this.frequency_Measure,
        outsource_cost: this.outsource_cost,
        insource_cost: this.insource_cost,
        diag_template: this.report_template,
        conduct_format: this.conduction_format,
        // TAT: this.Diag_TAT,
        collectTAT: this.collect_TAT,
        assertTAT: this.assert_TAT,
        uploadTAT: this.upload_TAT,
        approveTAT: this.approve_TAT,
        totalTAT: this.total_TAT,
        test_order: this.order_no,
        template_desc: this.cbt_Template,
        conduct_doctor: this.conduct_doctor,
        show_booking: this.show_test,
        ip_op: this.inpat_outpt,
        sample_req: this.specimen_required,
        status: status,
        upTATmeasure: this.upload_TAT_Measure,
        tTATmeasure: this.total_TAT_Measure,
        sensitive_drug_type: drug_ids,
      }

      console.log("DATA TO SAVE " + JSON.stringify(save_diagnosistestdata))
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/sdtbdc', save_diagnosistestdata,
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            if (obj.key != null && obj.key == "1") {
              if (flag == 'Save') {
                this.toastr.success(obj.result);
              } else {
                this.toastr.success("Test details updated successfully");
              }
              this.diagnosisservice.sendMessage("testsandpackadv");
            } else {
              this.toastr.error(Message_data.testDetNotSaved);
            }
          }, error => { });
    } else {
      if (this.main_test_uom_flag == true) {
        this.Diag_subtest_list_data = [];
      }
      this.toastr.error(Message_data.mandatory)
    }
  }

  addSubtest(e) {
    if (e == true) {
      this.subtest_flag = false;
      this.main_test_uom_flag = false;
      if (this.conduction_format == "4") {
        this.dropFlag = true;
        this.dropFlag1 = false;
      }
      this.subtest_uomflag = false;
      this.unithidden = true;
      this.conductflag = true;
      for (var i = 0; i < this.conductionFormatarr.length; i++) {
        if (this.conductionFormatarr[i].format_id == "1"
          || this.conductionFormatarr[i].format_id == "3") {
          this.conductionFormatarr.splice(i, 1)
        }
      }

    } else {
      this.subtest_flag = true;
      this.main_test_uom_flag = true;
      this.subtest_uomflag = true;
      this.unithidden = false;
      this.conductflag = false;
      if (this.conduction_format == "4") {
        this.dropFlag = false;
        this.dropFlag1 = true;
      }
      this.getConductionFormats();
      this.formatChange(this.conduction_format)
    }
  }

  backClicked() {  //back
    this.diagnosisservice.sendMessage('testsandpackadv')
  }

  onSelectionChanged = (event) => { }
  onContentChanged = (event) => {
    this.range = event.html;
  }

  editorCreated(quill: any) { }
  onSelectionChanged1 = (event) => { }
  onContentChanged1 = (event) => {
    this.test_method = event.html;
  }

  editorCreated1(quill: any) { }

  onDragStart(event: DragEvent, row: any) {
    event.dataTransfer.setData('text/plain', row.sub_test_order.toString());
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  onDrop(event: DragEvent, index: number) {
    const draggedRowId = +event.dataTransfer.getData('text/plain');
    const draggedRowIndex = this.Diag_subtest_list_data.findIndex(row => row.sub_test_order == draggedRowId);

    if (draggedRowIndex > -1) {
      const [draggedRow] = this.Diag_subtest_list_data.splice(draggedRowIndex, 1);
      this.Diag_subtest_list_data.splice(index, 0, draggedRow);
    }

    for (var i = 0; i < this.Diag_subtest_list_data.length; i++) {
      this.Diag_subtest_list_data[i].sub_test_order = i + 1;
    }
    console.log("this.Diag_subtest_list_data", this.Diag_subtest_list_data)
  }

  referenceAddBtn() {
    var ageCat;

    if ((this.gender_ref != undefined && this.gender_ref != "") && (this.min_nor_value != undefined && this.min_nor_value != "") && (this.max_nor_value != undefined && this.max_nor_value != "")) {
      var ageValue;
      var ageMinMax;

      if (this.age_grp_check == true) {
        ageValue = "All age group";
        this.age_input = "0";
        this.age_input_max = "120";
        this.age_ref = "Year(s)"
        ageMinMax = this.age_input + "-" + this.age_input_max;
      } else {
        ageValue = this.age_input + "-" + this.age_input_max + " " + this.age_ref;
        ageMinMax = this.age_input + "-" + this.age_input_max;
      }

      var id;
      if (this.referenceArray == undefined) {
        id = 1;
      } else {
        id = this.referenceArray.length + 1;
      }

      this.referenceArray.push({
        id: id,
        age_display: ageValue,
        age_val: ageMinMax,
        age_max: this.age_input_max,
        age_min: this.age_input,
        age_measure: this.age_ref,
        gender: this.gender_ref,
        min_nor_value: this.min_nor_value,
        max_nor_value: this.max_nor_value,
        min_abnor_value: this.min_anor_value,
        max_abnor_value: this.max_anor_value,
        min_crit_value: this.min_crit_value,
        max_crit_value: this.max_crit_value,
      })
    } else {
      this.toastr.error("Fill the required fields")
    }

    this.age_ref = "";
    this.age_input = "";
    this.age_input_max = "";
    ageValue = "";
    ageMinMax = "";
    this.gender_ref = "";
    this.ref_value = "";
    this.min_nor_value = "";
    this.max_ref_value = "";
    this.max_nor_value = "";
    this.min_anor_value = "";
    this.max_anor_value = "";
    this.min_crit_value = "";
    this.max_crit_value = "";

  }

  referenceEdit(reference) {
    for (var i = 0; i < this.referenceArray.length; i++) {
      if (this.referenceArray[i].min_nor_value == reference.min_nor_value) {
        this.age_input = reference.age_min;
        this.age_input_max = reference.age_max;
        this.age_ref = reference.age_measure;
        this.gender_ref = reference.gender;
        this.min_nor_value = reference.min_nor_value;
        this.max_nor_value = reference.max_nor_value;
        this.min_anor_value = reference.min_abnor_value;
        this.max_anor_value = reference.max_abnor_value;
        this.min_crit_value = reference.min_crit_value;
        this.max_crit_value = reference.max_crit_value;
        this.referenceArray.splice(i, 1);
        break;
      }
    }
  }

  referenceDelete(reference) {
    for (var i = 0; i < this.referenceArray.length; i++) {
      if (this.referenceArray[i].min_nor_value == reference.min_nor_value) {

        this.referenceArray.splice(i, 1);
        break;
      }
    }
  }

  getUomInput(data){
    this.unit_of_measure = data;
  }
}
