import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Helper_Class } from '../../helper_class';
import * as $ from 'jquery/dist/jquery.min.js';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from 'src/app/providers/common-data.service';
import { Time_Formate, ConvertTimeformat, Nurse_Day_Return } from '../../../assets/js/common';
import { ipaddress } from '../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Chart } from 'chart.js';
import { Date_Formate } from '../../../assets/js/common';
import { Master_Helper } from '../../Doctor_module/Master_Helper';
import * as HighCharts from 'highcharts';

@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.scss']
})
export class AdminHomeComponent implements OnInit {

  tabtype: string;
  // public ModeOfPayCanvas: any;
  public modeOfPayCanvas: any;
  public modeOfPayCanvasIp: any;
  public modeOfPayCanvasIpAdm: any;
  public billEstCompletedIpAdm: any;
  billCountIpAdm: any;
  IndentsIpAdm: any;
  billAmtIpAdm: any;
  currentDate: any;
  from_date: any;
  to_date: any;
  f_date: string;
  t_date: string;
  appointment_type: string;
  interval: string;
  range: string;
  is_admin: boolean;
  user_id: string;
  hosp_id: any;
  admin: any;

  public op_estimate: any;
  public ip_estimate: any;
  pending_indents: any;
  complete_pos_indents: any;
  total_indents: number;
  op_comp_estimate: any;
  op_pending_estimate: any;
  total_Op_estimate: number;
  ip_comp_estimate: any;
  ip_pending_estimate: any;
  total_Ip_estimate: number;
  op_bill_count: number;
  op_bill_amount: any;
  ip_bill_count: any;
  ip_bill_amount: any;
  userInfo: any;
  hospital_details: any;
  hospBillsOpAdm: any;
  pharmBillsOpAdm: any;
  diagBillsOpAdm: any;
  pharmabillAmtIpAdm: any;
  pharmabillAmtOpAdm: any;
  diagbillAmtOpAdm: any;
  doc_completedApp: any;
  doc_openApp: any;
  doc_confirmedApp: any;
  doc_inpatApp: any;
  pharmBillsIpAdm: any;
  hospBillsIpAdm: any;
  diagBillsIpAdm: any;
  diagbillAmtIpAdm: any;
  hospbillAmtOpAdm: any;
  hospbillAmtIpAdm: any;
  hosp_totalOpBillAmt: number;
  diag_totalOpBillAmt: number;
  diag_totalIpBillAmt: number;
  hosp_totalIpBillAmt: number;
  total_docApps: number;
  diag_completedApp: any;
  diag_openApp: any;
  total_diagApps: number;
  isBar_pharma: boolean;
  isBar_hosp: boolean;
  pharmaBillsPaytypeOpAdm: any;
  hospBillsPaytypeOpAdm: any;
  hospBillsPaytypeOpAdm_Bar: any;
  isBar_hosp_Ip: boolean;
  pharmaBillsPaytypeIpAdm: any;
  hospBillsPaytypeIpAdm: any;
  hospBillsPaytypeIpAdm_Bar: any;
  pharmaBillsPaytypeIpAdm_Bar: any;
  isBar_pharma_Ip: boolean;
  isBar_diag: boolean;
  diagBillsPaytypeOpAdm: any;
  diagBillsPaytypeOpAdm_Bar: any;
  diagBillsPaytypeIpAdm_Bar: any;
  diagBillsPaytypeIpAdm: any;

  constructor(public http: Http,) {
    this.appointment_type = 'Weekly';
    this.tabtype = 'outpatient';
    this.interval = '7';
    this.range = 'weekly';
  }

  ngOnInit(): void {
    this.userInfo = Helper_Class.getInfo();
    this.user_id = this.userInfo.user_id;

    // setTimeout(() => {
    //   // this.getModeOfPayments();
    //   // this.getModeOfPayments_Ip();
    //   this.getModeOfPayments_IpAdm();
    //   this.getbillEstCompleted_IpAdm();
    //   this.getbillCount_IpAdm();
    //   this.getIndents_IpAdm();
    //   this.getbillAmt_IpAdm();
    // }, 500);

    if (this.userInfo.admin == '1' || this.userInfo.user_type == 'Admin') {
      this.is_admin = true;
      this.user_id = 'All';
    } else {
      this.user_id = this.userInfo.user_id;
      this.is_admin = false;
    }

    this.hospital_details = Helper_Class.getHospitalInfo();
    this.hosp_id = this.hospital_details.hptl_clinic_id;
    this.admin = this.userInfo.admin;
    this.get_curr_date();

    // this.hospital_name = this.hospital_details.hosp_name;   
    // this.hospital_logo = ipaddress.Ip_with_img_address + this.hospital_details.hptl_logo; 
    // this.hosp_id = this.userInfo.hosp_id;
  }

  // ----------------------------Pharma Dashboard
  selectTab(event: MatTabChangeEvent) {
    const tab = event.tab.textLabel;
    if (tab == 'inpatient') {
      this.tabtype = 'inpatient';
      // this.getModeOfPayments_Ip();
      // this.getModeOfPayments_IpAdm();
      // this.getbillEstCompleted_IpAdm();
      // this.getbillCount_IpAdm();
      // this.getIndents_IpAdm();
      // this.getbillAmt_IpAdm();

      // this.user_base();
      this.getFrontdeskDashdata();
      this.Appointment_type_change();
    } else {
      this.tabtype = 'outpatient';
      // this.getModeOfPayments();

      // this.user_base();
      this.getFrontdeskDashdata();
      this.Appointment_type_change();
    }
  }

  get_curr_date() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "adm/curdate", JSON.stringify({
      country: ipaddress.country_code,
    }),
      { headers: headers }).subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);

          if (this.tabtype == 'outpatient') {
            if (obj != undefined) {
              this.currentDate = obj.current_date;
              // this.curr_date = obj.current_date;
              this.from_date = obj.current_date;
              this.to_date = obj.current_date;
              this.f_date = Date_Formate(this.currentDate);
              this.t_date = Date_Formate(this.currentDate);
              this.Appointment_type_change();
              this.getFrontdeskDashdata();
            }
          } else {
            if (obj != undefined) {
              // this.curr_date = obj.current_date;
              this.currentDate = obj.current_date;
              this.f_date = Date_Formate(this.currentDate);
              this.t_date = Date_Formate(this.currentDate);
              this.Appointment_type_change();
              this.getFrontdeskDashdata();
            }
          }


        },
      )

  }

  getDateFormate(d) {
    var month = (d.getMonth() + 1 < 10 ? '0' : '') + (d.getMonth() + 1);
    var day = (d.getDate() < 10 ? '0' : '') + d.getDate();
    var temp = d.getFullYear() + '-' + month + '-' + day;
    return temp;
  }

  Appointment_type_change() {
    var e;

    if (this.appointment_type == 'Today') {
      e = this.currentDate;
      this.from_date = e;
      this.interval = '0';
      this.range = 'today';
    } else if (this.appointment_type == 'Weekly') {
      var weekly,
        d = new Date();
      d.setDate(d.getDate() - 7);
      e = this.getDateFormate(d);
      this.from_date = e;
      this.interval = '7';
      this.range = 'weekly';
    } else if (this.appointment_type == 'Fort Night') {
      var weekly,
        d = new Date();
      d.setDate(d.getDate() - 14);
      e = this.getDateFormate(d);
      this.from_date = e;
      this.interval = '14';
      this.range = 'fort night';
    } else if (this.appointment_type == 'Monthly') {
      var weekly,
        d = new Date();
      d.setDate(d.getDate() - 30);
      e = this.getDateFormate(d);
      this.from_date = e;
      this.interval = '30';
      this.range = 'monthly';
    } else if (this.appointment_type == '3 Months') {
      var d = new Date();
      d.setDate(d.getDate() - 92);
      e = this.getDateFormate(d);
      this.from_date = e;
      this.interval = '92';
      this.range = '3 months';
    } else if (this.appointment_type == '6 Months') {
      var weekly,
        d = new Date();
      d.setDate(d.getDate() - 183);
      e = this.getDateFormate(d);
      this.from_date = e;
      this.interval = '183';
      this.range = '6 months';
    } else if (this.appointment_type == 'Yearly') {
      var weekly,
        d = new Date();
      d.setDate(d.getDate() - 365);
      e = this.getDateFormate(d);
      this.from_date = e;
      this.interval = '365';
      this.range = '12 months';
    } else {
    }

    if (this.tabtype == 'outpatient') {
      this.f_date = Date_Formate(e);
      this.user_base();
      this.getFrontdeskDashdata();

    } else if (this.tabtype == 'inpatient') {
      this.f_date = Date_Formate(e);
      this.user_base();
      this.getFrontdeskDashdata();

    } else {
      console.log('allrt');
    }
  }

  getHospBills_OpAdm(hosp_bills, hosp_data, hosp_dates) {
    // var data = ["1500", "2000", "2500", "5500", "5000"];
    // var labels = ["Total", "Completed", "Pending"]
    var backgroundColors = ['rgb(255, 99, 132, 1)',
      'rgb(75, 192, 192, 1)',
      'rgb(255, 205, 86, 1)',
      'rgb(201, 203, 207, 1)',
      'rgb(54, 162, 235, 1)']

    console.log("getHospBills_OpAdm------", hosp_bills, hosp_data, hosp_dates)

    var dataset_array = [];
    if (hosp_data != undefined) {
      for (var i = 0; i < hosp_data.length; i++) {
        dataset_array.push({
          data: hosp_data[i].op_bill_amount,
          fill: false,
          backgroundColor: backgroundColors[i],
          borderColor: backgroundColors[i],
          spanGaps: true,
          label: hosp_data[i].created_by
        })
      }

      var hospital_dates = [];
      for (var l = 0; l < hosp_dates.length; l++) {
        hospital_dates.push(
          Date_Formate(hosp_dates[l])
        )
      }
    }

    console.log("Datasetarray------", dataset_array)


    // console.log(hospital_dates);

    if (this.hospBillsOpAdm) {
      this.hospBillsOpAdm.destroy();
    }

    var ctx = <HTMLCanvasElement>document.getElementById("hospBillsOpAdm");
    ctx.height = 245;
    ctx.width = 515;
    this.hospBillsOpAdm = new Chart(ctx, {
      type: "line",
      labels: ["Stepcount"],
      data: {
        labels: hospital_dates,
        datasets: dataset_array,
      },
      options: {
        responsive: false,
        scales: {
          yAxes: [{
            display: true,
            ticks: {
              beginAtZero: true,
              steps: 100,
              stepValue: 5,
            },
            scaleLabel: {
              display: true,
              labelString: 'Bill Amount'
            }
          }],
          xAxes: [{
            barPercentage: 1,
            barThickness: 35,
            ticks: {
              maxRotation: 90,
              minRotation: 0,
            },
            scaleLabel: {
              display: false,
              labelString: 'Payment Methods'
            }
          }]
        },
        legend: {
          display: true
        },
      }
    });

    // Hospital Total Amount Graph data 

    var data_array = [];
    var labels = [];
    this.hosp_totalOpBillAmt = 0;

    if (hosp_data != undefined) {
      for (var j = 0; j < hosp_data.length; j++) {
        var total_op_amt = 0;
        for (var k = 0; k < hosp_data[j].op_bill_amount.length; k++) {
          total_op_amt += parseFloat(hosp_data[j].op_bill_amount[k])
        }
        data_array.push(total_op_amt)
        labels.push(hosp_data[j].created_by)
        this.hosp_totalOpBillAmt += total_op_amt
      }
    }

    if (this.hospbillAmtOpAdm) {
      this.hospbillAmtOpAdm.destroy();
    }

    var ctx = <HTMLCanvasElement>document.getElementById("hospbillAmtOpAdm");
    ctx.height = 260;
    // ctx.width = 515;
    this.hospbillAmtOpAdm = new Chart(ctx, {
      type: "polarArea",
      labels: ["Stepcount"],
      data: {
        labels: labels,
        datasets: [
          {
            data: data_array,
            fill: false,
            backgroundColor: ['rgb(255, 99, 132, 0.4)',
              'rgb(75, 192, 192, 0.4)',
              'rgb(255, 205, 86, 0.4)',
              'rgb(201, 203, 207, 0.4)',
              'rgb(54, 162, 235), 0.4'],
          }
        ]
      },
      options: {
      }
    });
  }

  getPharmBills_OpAdm(pharm_bills, pharm_data, pharm_dates) {
    // var data = ["1500", "2000", "2500", "5500", "5000"];
    // var labels = ["Total", "Completed", "Pending"]
    var backgroundColors = ['rgb(255, 99, 132)',
      'rgb(75, 192, 192)',
      'rgb(255, 205, 86)',
      'rgb(201, 203, 207)',
      'rgb(54, 162, 235)']

    console.log("getPharmBills_OpAdm------", pharm_bills, pharm_data, pharm_dates)

    var dataset_array = [];
    if (pharm_data != undefined) {
      for (var i = 0; i < pharm_data.length; i++) {
        dataset_array.push({
          data: pharm_data[i].op_bill_amount,
          fill: false,
          backgroundColor: backgroundColors[i],
          // borderColor: ["rgba(255, 99, 132, 1)"],
          spanGaps: true,
          label: pharm_data[i].created_by
        })
      }

      var pharmacy_dates = [];
      for (var l = 0; l < pharm_dates.length; l++) {
        pharmacy_dates.push(
          Date_Formate(pharm_dates[l])
        )
      }
    }

    console.log("Datasetarray------", dataset_array)

    if (this.pharmBillsOpAdm) {
      this.pharmBillsOpAdm.destroy();
    }

    var ctx = <HTMLCanvasElement>document.getElementById("pharmBillsOpAdm");
    ctx.height = 245;
    ctx.width = 515;
    this.pharmBillsOpAdm = new Chart(ctx, {
      type: "line",
      labels: ["Stepcount"],
      data: {
        labels: pharmacy_dates,
        datasets: dataset_array,
      },
      options: {
        responsive: false,
        scales: {
          yAxes: [{
            display: true,
            ticks: {
              beginAtZero: true,
              steps: 100,
              stepValue: 5,
            },
            scaleLabel: {
              display: true,
              labelString: 'Bill Amount'
            }
          }],
          xAxes: [{
            barPercentage: 1,
            barThickness: 35,
            ticks: {
              maxRotation: 90,
              minRotation: 0,
            },
            scaleLabel: {
              display: false,
              labelString: 'Payment Methods'
            }
          }]
        },
        legend: {
          display: true
        },
      }
    });

    // Pharmacy Total Amount Graph data 

    var data_array = [];
    var labels = [];

    if (pharm_data != undefined) {
      for (var j = 0; j < pharm_data.length; j++) {
        var total_op_amt = 0;
        for (var k = 0; k < pharm_data[j].op_bill_amount.length; k++) {
          total_op_amt += parseFloat(pharm_data[j].op_bill_amount[k])
        }
        data_array.push(total_op_amt)
        labels.push(pharm_data[j].created_by)
      }
    }

    if (this.pharmabillAmtOpAdm) {
      this.pharmabillAmtOpAdm.destroy();
    }

    var ctx = <HTMLCanvasElement>document.getElementById("pharmabillAmtOpAdm");
    ctx.height = 260;
    // ctx.width = 515;
    this.pharmabillAmtOpAdm = new Chart(ctx, {
      type: "polarArea",
      labels: ["Stepcount"],
      data: {
        labels: labels,
        datasets: [
          {
            data: data_array,
            fill: false,
            backgroundColor: ['rgb(255, 99, 132, 0.4)',
              'rgb(75, 192, 192, 0.4)',
              'rgb(255, 205, 86, 0.4)',
              'rgb(201, 203, 207, 0.4)',
              'rgb(54, 162, 235), 0.4'],
          }
        ]
      },
      options: {
      }
    });
  }

  getDiagBills_OpAdm(diag_bills, diag_data, diag_dates) {
    // var data = ["1500", "2000", "2500", "5500", "5000"];
    // var labels = ["Total", "Completed", "Pending"]
    var backgroundColors = ['rgb(255, 99, 132)',
      'rgb(75, 192, 192)',
      'rgb(255, 205, 86)',
      'rgb(201, 203, 207)',
      'rgb(54, 162, 235)']

    // console.log("getPharmBills_OpAdm------", pharm_bills, pharm_data, pharm_dates)

    var dataset_array = [];
    if (diag_data != undefined) {
      for (var i = 0; i < diag_data.length; i++) {
        var created_by = '';
        if (diag_data[i].created_by == "null null") {
          created_by = "Sudharshan"
        } else {
          created_by = diag_data[i].created_by;
        }

        dataset_array.push({
          data: diag_data[i].op_bill_amount,
          fill: false,
          backgroundColor: backgroundColors[i],
          // borderColor: ["rgba(255, 99, 132, 1)"],
          spanGaps: true,
          label: created_by
        })
      }

      var diagnosis_dates = [];
      for (var l = 0; l < diag_dates.length; l++) {
        diagnosis_dates.push(
          Date_Formate(diag_dates[l])
        )
      }
    }

    console.log("Datasetarray--getDiagBills_OpAdm------", dataset_array)

    if (this.diagBillsOpAdm) {
      this.diagBillsOpAdm.destroy();
    }

    var ctx = <HTMLCanvasElement>document.getElementById("diagBillsOpAdm");
    ctx.height = 245;
    ctx.width = 515;
    this.diagBillsOpAdm = new Chart(ctx, {
      type: "line",
      labels: ["Stepcount"],
      data: {
        labels: diagnosis_dates,
        datasets: dataset_array,
      },
      options: {
        responsive: false,
        scales: {
          yAxes: [{
            display: true,
            ticks: {
              beginAtZero: true,
              steps: 100,
              stepValue: 5,
            },
            scaleLabel: {
              display: true,
              labelString: 'Bill Amount'
            }
          }],
          xAxes: [{
            barPercentage: 1,
            barThickness: 35,
            ticks: {
              maxRotation: 90,
              minRotation: 0,
            },
            scaleLabel: {
              display: false,
              labelString: 'Payment Methods'
            }
          }]
        },
        legend: {
          display: true
        },
      }
    });

    // Diagnosis Total Amount Graph data 

    var data_array = [];
    var labels = [];
    this.diag_totalOpBillAmt = 0;

    if (diag_data != undefined) {
      for (var j = 0; j < diag_data.length; j++) {
        var total_op_amt = 0;
        for (var k = 0; k < diag_data[j].op_bill_amount.length; k++) {
          total_op_amt += parseFloat(diag_data[j].op_bill_amount[k])
        }

        var created_by = '';
        if (diag_data[j].created_by == "null null") {
          created_by = "Sudharshan"
        } else {
          created_by = diag_data[j].created_by;
        }

        data_array.push(total_op_amt)
        labels.push(created_by)
        this.diag_totalOpBillAmt += total_op_amt
      }
    }

    if (this.diagbillAmtOpAdm) {
      this.diagbillAmtOpAdm.destroy();
    }

    var ctx = <HTMLCanvasElement>document.getElementById("diagbillAmtOpAdm");
    ctx.height = 260;
    // ctx.width = 515;
    this.diagbillAmtOpAdm = new Chart(ctx, {
      type: "polarArea",
      labels: ["Stepcount"],
      data: {
        labels: labels,
        datasets: [
          {
            data: data_array,
            fill: false,
            backgroundColor: ['rgb(255, 99, 132, 0.4)',
              'rgb(75, 192, 192, 0.4)',
              'rgb(255, 205, 86, 0.4)',
              'rgb(201, 203, 207, 0.4)',
              'rgb(54, 162, 235), 0.4'],
          }
        ]
      },
      options: {
      }
    });
  }

  getHospBills_IpAdm(hosp_bills, hosp_data, hosp_dates) {
    // var data = ["1500", "2000", "2500", "5500", "5000"];
    // var labels = ["Total", "Completed", "Pending"]
    var backgroundColors = ['rgb(255, 99, 132, 1)',
      'rgb(75, 192, 192, 1)',
      'rgb(255, 205, 86, 1)',
      'rgb(201, 203, 207, 1)',
      'rgb(54, 162, 235, 1)']

    console.log("getHospBills_IpAdm------", hosp_bills, hosp_data, hosp_dates)

    var dataset_array = [];
    if (hosp_data != undefined) {
      for (var i = 0; i < hosp_data.length; i++) {
        dataset_array.push({
          data: hosp_data[i].ip_bill_amount,
          fill: false,
          backgroundColor: backgroundColors[i],
          borderColor: backgroundColors[i],
          spanGaps: true,
          label: hosp_data[i].created_by
        })
      }
      var hospital_dates = [];
      for (var l = 0; l < hosp_dates.length; l++) {
        hospital_dates.push(
          Date_Formate(hosp_dates[l])
        )
      }
    }

    console.log("Datasetarray------", dataset_array)

    if (this.hospBillsIpAdm) {
      this.hospBillsIpAdm.destroy();
    }

    var ctx = <HTMLCanvasElement>document.getElementById("hospBillsIpAdm");
    ctx.height = 245;
    ctx.width = 515;
    this.hospBillsIpAdm = new Chart(ctx, {
      type: "line",
      labels: ["Stepcount"],
      data: {
        labels: hospital_dates,
        datasets: dataset_array,
      },
      options: {
        responsive: false,
        scales: {
          yAxes: [{
            display: true,
            ticks: {
              beginAtZero: true,
              steps: 100,
              stepValue: 5,
            },
            scaleLabel: {
              display: true,
              labelString: 'Bill Amount'
            }
          }],
          xAxes: [{
            barPercentage: 1,
            barThickness: 35,
            ticks: {
              maxRotation: 90,
              minRotation: 0,
            },
            scaleLabel: {
              display: false,
              labelString: 'Payment Methods'
            }
          }]
        },
        legend: {
          display: true
        },
      }
    });

    // Hospital Total Amount Graph data 

    var data_array = [];
    var labels = [];
    this.hosp_totalIpBillAmt = 0;

    if (hosp_data != undefined) {
      for (var j = 0; j < hosp_data.length; j++) {
        var total_op_amt = 0;
        for (var k = 0; k < hosp_data[j].ip_bill_amount.length; k++) {
          total_op_amt += parseFloat(hosp_data[j].ip_bill_amount[k])
        }
        data_array.push(total_op_amt)
        labels.push(hosp_data[j].created_by)
        this.hosp_totalIpBillAmt += total_op_amt
      }
    }

    if (this.hospbillAmtIpAdm) {
      this.hospbillAmtIpAdm.destroy();
    }

    var ctx = <HTMLCanvasElement>document.getElementById("hospbillAmtIpAdm");
    ctx.height = 260;
    // ctx.width = 515;
    this.hospbillAmtIpAdm = new Chart(ctx, {
      type: "polarArea",
      labels: ["Stepcount"],
      data: {
        labels: labels,
        datasets: [
          {
            data: data_array,
            fill: false,
            backgroundColor: ['rgb(255, 99, 132, 0.4)',
              'rgb(75, 192, 192, 0.4)',
              'rgb(255, 205, 86, 0.4)',
              'rgb(201, 203, 207, 0.4)',
              'rgb(54, 162, 235), 0.4'],
          }
        ]
      },
      options: {
      }
    });
  }

  getPharmBills_IpAdm(pharm_bills, pharm_data, pharm_dates) {
    // var data = ["1500", "2000", "2500", "5500", "5000"];
    // var labels = ["Total", "Completed", "Pending"]
    var backgroundColors = ['rgb(255, 99, 132)',
      'rgb(75, 192, 192)',
      'rgb(255, 205, 86)',
      'rgb(201, 203, 207)',
      'rgb(54, 162, 235)']

    console.log("getPharmBills_OpAdm------", pharm_bills, pharm_data, pharm_dates)

    var dataset_array = [];
    if (pharm_data != undefined) {
      for (var i = 0; i < pharm_data.length; i++) {
        dataset_array.push({
          data: pharm_data[i].ip_bill_amount,
          fill: false,
          backgroundColor: backgroundColors[i],
          // borderColor: ["rgba(255, 99, 132, 1)"],
          spanGaps: true,
          label: pharm_data[i].created_by
        })
      }

      var pharmacy_dates = [];
      for (var l = 0; l < pharm_dates.length; l++) {
        pharmacy_dates.push(
          Date_Formate(pharm_dates[l])
        )
      }
    }

    console.log("Datasetarray------", dataset_array)



    if (this.pharmBillsIpAdm) {
      this.pharmBillsIpAdm.destroy();
    }

    var ctx = <HTMLCanvasElement>document.getElementById("pharmBillsIpAdm");
    ctx.height = 245;
    ctx.width = 515;
    this.pharmBillsIpAdm = new Chart(ctx, {
      type: "line",
      labels: ["Stepcount"],
      data: {
        labels: pharm_dates,
        datasets: dataset_array,
      },
      options: {
        responsive: false,
        scales: {
          yAxes: [{
            display: true,
            ticks: {
              beginAtZero: true,
              steps: 100,
              stepValue: 5,
            },
            scaleLabel: {
              display: true,
              labelString: 'Bill Amount'
            }
          }],
          xAxes: [{
            barPercentage: 1,
            barThickness: 35,
            ticks: {
              maxRotation: 90,
              minRotation: 0,
            },
            scaleLabel: {
              display: false,
              labelString: 'Payment Methods'
            }
          }]
        },
        legend: {
          display: true
        },
      }
    });

    // Pharmacy Total Amount Graph data 

    var data_array = [];
    var labels = [];

    if (pharm_data != undefined) {
      for (var j = 0; j < pharm_data.length; j++) {
        var total_op_amt = 0;
        for (var k = 0; k < pharm_data[j].ip_bill_amount.length; k++) {
          total_op_amt += parseFloat(pharm_data[j].ip_bill_amount[k])
        }
        data_array.push(total_op_amt)
        labels.push(pharm_data[j].created_by)
      }
    }

    if (this.pharmabillAmtIpAdm) {
      this.pharmabillAmtIpAdm.destroy();
    }

    var ctx = <HTMLCanvasElement>document.getElementById("pharmabillAmtIpAdm");
    ctx.height = 260;
    // ctx.width = 515;
    this.pharmabillAmtIpAdm = new Chart(ctx, {
      type: "polarArea",
      labels: ["Stepcount"],
      data: {
        labels: labels,
        datasets: [
          {
            data: data_array,
            fill: false,
            backgroundColor: ['rgb(255, 99, 132, 0.4)',
              'rgb(75, 192, 192, 0.4)',
              'rgb(255, 205, 86, 0.4)',
              'rgb(201, 203, 207, 0.4)',
              'rgb(54, 162, 235), 0.4'],
          }
        ]
      },
      options: {
      }
    });
  }

  getDiagBills_IpAdm(diag_bills, diag_data, diag_dates) {
    // var data = ["1500", "2000", "2500", "5500", "5000"];
    // var labels = ["Total", "Completed", "Pending"]
    var backgroundColors = ['rgb(255, 99, 132)',
      'rgb(75, 192, 192)',
      'rgb(255, 205, 86)',
      'rgb(201, 203, 207)',
      'rgb(54, 162, 235)']

    // console.log("getPharmBills_OpAdm------", pharm_bills, pharm_data, pharm_dates)

    var dataset_array = [];
    if (diag_data != undefined) {
      for (var i = 0; i < diag_data.length; i++) {
        var created_by = '';
        if (diag_data[i].created_by == "null null") {
          created_by = "Sudharshan"
        } else {
          created_by = diag_data[i].created_by;
        }

        dataset_array.push({
          data: diag_data[i].ip_bill_amount,
          fill: false,
          backgroundColor: backgroundColors[i],
          // borderColor: ["rgba(255, 99, 132, 1)"],
          spanGaps: true,
          label: created_by
        })
      }

      var diagnosis_dates = [];
      for (var l = 0; l < diag_dates.length; l++) {
        diagnosis_dates.push(
          Date_Formate(diag_dates[l])
        )
      }
    }

    console.log("Datasetarray--getDiagBills_OpAdm------", dataset_array)

    if (this.diagBillsIpAdm) {
      this.diagBillsIpAdm.destroy();
    }

    var ctx = <HTMLCanvasElement>document.getElementById("diagBillsIpAdm");
    ctx.height = 245;
    ctx.width = 515;
    this.diagBillsIpAdm = new Chart(ctx, {
      type: "line",
      labels: ["Stepcount"],
      data: {
        labels: diagnosis_dates,
        datasets: dataset_array,
      },
      options: {
        responsive: false,
        scales: {
          yAxes: [{
            display: true,
            ticks: {
              beginAtZero: true,
              steps: 100,
              stepValue: 5,
            },
            scaleLabel: {
              display: true,
              labelString: 'Bill Amount'
            }
          }],
          xAxes: [{
            barPercentage: 1,
            barThickness: 35,
            ticks: {
              maxRotation: 90,
              minRotation: 0,
            },
            scaleLabel: {
              display: false,
              labelString: 'Payment Methods'
            }
          }]
        },
        legend: {
          display: true
        },
      }
    });

    // Diagnosis Total Amount Graph data 

    var data_array = [];
    var labels = [];
    this.diag_totalIpBillAmt = 0;

    if (diag_data != undefined) {
      for (var j = 0; j < diag_data.length; j++) {
        var total_op_amt = 0;
        for (var k = 0; k < diag_data[j].ip_bill_amount.length; k++) {
          total_op_amt += parseFloat(diag_data[j].ip_bill_amount[k])
        }

        var created_by = '';
        if (diag_data[j].created_by == "null null") {
          created_by = "Sudharshan"
        } else {
          created_by = diag_data[j].created_by;
        }

        data_array.push(total_op_amt)
        labels.push(created_by)
        this.diag_totalIpBillAmt += total_op_amt
      }
    }

    if (this.diagbillAmtIpAdm) {
      this.diagbillAmtIpAdm.destroy();
    }

    var ctx = <HTMLCanvasElement>document.getElementById("diagbillAmtIpAdm");
    ctx.height = 260;
    // ctx.width = 515;
    this.diagbillAmtIpAdm = new Chart(ctx, {
      type: "polarArea",
      labels: ["Stepcount"],
      data: {
        labels: labels,
        datasets: [
          {
            data: data_array,
            fill: false,
            backgroundColor: ['rgb(255, 99, 132, 0.4)',
              'rgb(75, 192, 192, 0.4)',
              'rgb(255, 205, 86, 0.4)',
              'rgb(201, 203, 207, 0.4)',
              'rgb(54, 162, 235), 0.4'],
          }
        ]
      },
      options: {
      }
    });
  }

  getPharmPaytype_OpAdm(pharma_paytypes) {
    var backgroundColors = ['rgb(255, 99, 132, 1)',
      'rgb(75, 192, 192, 1)',
      'rgb(255, 205, 86, 1)',
      'rgb(201, 203, 207, 1)',
      'rgb(54, 162, 235, 1)']

    if (pharma_paytypes != undefined) {
      if (pharma_paytypes.length > 1) {
        var dates = [];
        var cash = [];
        var card = [];
        var upi = [];
        var cheque = [];
        var emi = [];
        var insurance = [];

        for (var i = 0; i < pharma_paytypes.length; i++) {
          dates.push(pharma_paytypes[i].created_date)
          cash.push(pharma_paytypes[i].op_cash)
          card.push(pharma_paytypes[i].op_card)
          upi.push(pharma_paytypes[i].op_upi)
          cheque.push(pharma_paytypes[i].op_cheque)
          emi.push(pharma_paytypes[i].op_emi)
          insurance.push(pharma_paytypes[i].op_insurance)
        }

        if (this.pharmaBillsPaytypeOpAdm) {
          this.pharmaBillsPaytypeOpAdm.destroy();
        }

        var ctx = <HTMLCanvasElement>document.getElementById("pharmaBillsPaytypeOpAdm");
        ctx.height = 245;
        ctx.width = 515;
        this.pharmaBillsPaytypeOpAdm = new Chart(ctx, {
          type: "line",
          labels: ["Stepcount"],
          data: {
            labels: dates,
            datasets: [{
              data: cash,
              fill: false,
              backgroundColor: backgroundColors[0],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "Cash"
            }, {
              data: card,
              fill: false,
              backgroundColor: backgroundColors[1],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "Card"
            }, {
              data: upi,
              fill: false,
              backgroundColor: backgroundColors[2],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "UPI"
            }, {
              data: cheque,
              fill: false,
              backgroundColor: backgroundColors[3],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "Cheque"
            }, {
              data: emi,
              fill: false,
              backgroundColor: backgroundColors[1],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "EMI"
            }, {
              data: insurance,
              fill: false,
              backgroundColor: backgroundColors[2],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "Insurance"
            }],
          },
          options: {
            responsive: false,
            scales: {
              yAxes: [{
                display: true,
                ticks: {
                  beginAtZero: true,
                  steps: 100,
                  stepValue: 5,
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Bill Amount'
                }
              }],
              xAxes: [{
                barPercentage: 1,
                barThickness: 35,
                ticks: {
                  maxRotation: 90,
                  minRotation: 0,
                },
                scaleLabel: {
                  display: false,
                  labelString: 'Payment Methods'
                }
              }]
            },
            legend: {
              display: true
            },
          }
        });
      } else {

      }
    }
  }

  getHospPaytype_OpAdm(hosp_paytypes) {
    var backgroundColors = ['rgb(255, 99, 132, 1)',
      'rgb(75, 192, 192, 1)',
      'rgb(255, 205, 86, 1)',
      'rgb(201, 203, 207, 1)',
      'rgb(54, 162, 235, 1)']

    if (hosp_paytypes != undefined) {
      if (hosp_paytypes.length > 1) {
        var dates = [];
        var cash = [];
        var card = [];
        var upi = [];
        var cheque = [];
        var emi = [];
        var insurance = [];

        for (var i = 0; i < hosp_paytypes.length; i++) {
          dates.push(hosp_paytypes[i].created_date)
          cash.push(hosp_paytypes[i].op_cash)
          card.push(hosp_paytypes[i].op_card)
          upi.push(hosp_paytypes[i].op_upi)
          cheque.push(hosp_paytypes[i].op_cheque)
          emi.push(hosp_paytypes[i].op_emi)
          insurance.push(hosp_paytypes[i].op_insurance)
        }

        if (this.hospBillsPaytypeOpAdm) {
          this.hospBillsPaytypeOpAdm.destroy();
        }

        var ctx = <HTMLCanvasElement>document.getElementById("hospBillsPaytypeOpAdm");
        ctx.height = 245;
        ctx.width = 515;
        this.hospBillsPaytypeOpAdm = new Chart(ctx, {
          type: "line",
          labels: ["Stepcount"],
          data: {
            labels: dates,
            datasets: [{
              data: cash,
              fill: false,
              backgroundColor: backgroundColors[0],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "Cash"
            }, {
              data: card,
              fill: false,
              backgroundColor: backgroundColors[1],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "Card"
            }, {
              data: upi,
              fill: false,
              backgroundColor: backgroundColors[2],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "UPI"
            }, {
              data: cheque,
              fill: false,
              backgroundColor: backgroundColors[3],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "Cheque"
            }, {
              data: emi,
              fill: false,
              backgroundColor: backgroundColors[1],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "EMI"
            }, {
              data: insurance,
              fill: false,
              backgroundColor: backgroundColors[2],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "Insurance"
            }],
          },
          options: {
            responsive: false,
            scales: {
              yAxes: [{
                display: true,
                ticks: {
                  beginAtZero: true,
                  steps: 100,
                  stepValue: 5,
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Bill Amount'
                }
              }],
              xAxes: [{
                barPercentage: 1,
                barThickness: 35,
                ticks: {
                  maxRotation: 90,
                  minRotation: 0,
                },
                scaleLabel: {
                  display: false,
                  labelString: 'Payment Methods'
                }
              }]
            },
            legend: {
              display: true
            },
          }
        });
      } else {

      }
    }
  }

  getHospPaytype_OpAdm_bar(hosp_paytypes) {
    // this.isBar_hosp = true;
    var data = [hosp_paytypes[0].op_cash, hosp_paytypes[0].op_card, hosp_paytypes[0].op_upi, hosp_paytypes[0].op_cheque, hosp_paytypes[0].op_emi, hosp_paytypes[0].op_insurance];
    var labels = ["Cash", "Card", "UPI", "Cheque", "EMI", "Insurance"]

    if (this.hospBillsPaytypeOpAdm_Bar) {
      this.hospBillsPaytypeOpAdm_Bar.destroy();
    }

    var ctx = <HTMLCanvasElement>document.getElementById("hospBillsPaytypeOpAdm_Bar");
    ctx.height = 245;
    ctx.width = 515;
    this.hospBillsPaytypeOpAdm_Bar = new Chart(ctx, {
      type: "bar",
      labels: ["Stepcount"],
      data: {
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(255, 159, 64, 0.2)"
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)"
            ],
            fill: false,
            borderWidth: 2,
            spanGaps: true,
            label: "Payment Modes"
          },
        ]
      },
      options: {
        responsive: false,
        scales: {
          yAxes: [{
            display: true,
            ticks: {
              beginAtZero: true,
              steps: 100,
              stepValue: 5,
            },
            scaleLabel: {
              display: true,
              labelString: 'Amount'
            }
          }],
          xAxes: [{
            barPercentage: 1,
            barThickness: 35,
            ticks: {
              maxRotation: 90,
              minRotation: 0,
            },
            scaleLabel: {
              display: true,
              labelString: 'Payment Methods'
            }
          }]
        },
        legend: {
          display: false
        },
      }
    });
  }

  getPharmPaytype_IpAdm(pharma_paytypes) {
    var backgroundColors = ['rgb(255, 99, 132, 1)',
      'rgb(75, 192, 192, 1)',
      'rgb(255, 205, 86, 1)',
      'rgb(201, 203, 207, 1)',
      'rgb(54, 162, 235, 1)']

    if (pharma_paytypes != undefined) {
      if (pharma_paytypes.length > 1) {
        var dates = [];
        var cash = [];
        var card = [];
        var upi = [];
        var cheque = [];
        var emi = [];
        var insurance = [];

        for (var i = 0; i < pharma_paytypes.length; i++) {
          dates.push(pharma_paytypes[i].created_date)
          cash.push(pharma_paytypes[i].ip_cash)
          card.push(pharma_paytypes[i].ip_card)
          upi.push(pharma_paytypes[i].ip_upi)
          cheque.push(pharma_paytypes[i].ip_cheque)
          emi.push(pharma_paytypes[i].ip_emi)
          insurance.push(pharma_paytypes[i].ip_insurance)
        }

        if (this.pharmaBillsPaytypeIpAdm) {
          this.pharmaBillsPaytypeIpAdm.destroy();
        }

        var ctx = <HTMLCanvasElement>document.getElementById("pharmaBillsPaytypeIpAdm");
        ctx.height = 245;
        ctx.width = 515;
        this.pharmaBillsPaytypeIpAdm = new Chart(ctx, {
          type: "line",
          labels: ["Stepcount"],
          data: {
            labels: dates,
            datasets: [{
              data: cash,
              fill: false,
              backgroundColor: backgroundColors[0],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "Cash"
            }, {
              data: card,
              fill: false,
              backgroundColor: backgroundColors[1],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "Card"
            }, {
              data: upi,
              fill: false,
              backgroundColor: backgroundColors[2],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "UPI"
            }, {
              data: cheque,
              fill: false,
              backgroundColor: backgroundColors[3],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "Cheque"
            }, {
              data: emi,
              fill: false,
              backgroundColor: backgroundColors[1],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "EMI"
            }, {
              data: insurance,
              fill: false,
              backgroundColor: backgroundColors[2],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "Insurance"
            }],
          },
          options: {
            responsive: false,
            scales: {
              yAxes: [{
                display: true,
                ticks: {
                  beginAtZero: true,
                  steps: 100,
                  stepValue: 5,
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Bill Amount'
                }
              }],
              xAxes: [{
                barPercentage: 1,
                barThickness: 35,
                ticks: {
                  maxRotation: 90,
                  minRotation: 0,
                },
                scaleLabel: {
                  display: false,
                  labelString: 'Payment Methods'
                }
              }]
            },
            legend: {
              display: true
            },
          }
        });
      } else {

      }
    }
  }

  getPharmPaytype_IpAdm_bar(pharma_paytypes) {
    // this.isBar_hosp = true;
    var data = [pharma_paytypes[0].ip_cash, pharma_paytypes[0].ip_card, pharma_paytypes[0].ip_upi, pharma_paytypes[0].ip_cheque, pharma_paytypes[0].ip_emi, pharma_paytypes[0].ip_insurance];
    var labels = ["Cash", "Card", "UPI", "Cheque", "EMI", "Insurance"]

    if (this.pharmaBillsPaytypeIpAdm_Bar) {
      this.pharmaBillsPaytypeIpAdm_Bar.destroy();
    }

    var ctx = <HTMLCanvasElement>document.getElementById("pharmaBillsPaytypeIpAdm_Bar");
    ctx.height = 245;
    ctx.width = 515;
    this.pharmaBillsPaytypeIpAdm_Bar = new Chart(ctx, {
      type: "bar",
      labels: ["Stepcount"],
      data: {
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(255, 159, 64, 0.2)"
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)"
            ],
            fill: false,
            borderWidth: 2,
            spanGaps: true,
            label: "Payment Modes"
          },
        ]
      },
      options: {
        responsive: false,
        scales: {
          yAxes: [{
            display: true,
            ticks: {
              beginAtZero: true,
              steps: 100,
              stepValue: 5,
            },
            scaleLabel: {
              display: true,
              labelString: 'Amount'
            }
          }],
          xAxes: [{
            barPercentage: 1,
            barThickness: 35,
            ticks: {
              maxRotation: 90,
              minRotation: 0,
            },
            scaleLabel: {
              display: true,
              labelString: 'Payment Methods'
            }
          }]
        },
        legend: {
          display: false
        },
      }
    });
  }

  getHospPaytype_IpAdm(hosp_paytypes) {
    var backgroundColors = ['rgb(255, 99, 132, 1)',
      'rgb(75, 192, 192, 1)',
      'rgb(255, 205, 86, 1)',
      'rgb(201, 203, 207, 1)',
      'rgb(54, 162, 235, 1)']

    if (hosp_paytypes != undefined) {
      if (hosp_paytypes.length > 1) {
        var dates = [];
        var cash = [];
        var card = [];
        var upi = [];
        var cheque = [];
        var emi = [];
        var insurance = [];

        for (var i = 0; i < hosp_paytypes.length; i++) {
          dates.push(hosp_paytypes[i].created_date)
          cash.push(hosp_paytypes[i].ip_cash)
          card.push(hosp_paytypes[i].ip_card)
          upi.push(hosp_paytypes[i].ip_upi)
          cheque.push(hosp_paytypes[i].ip_cheque)
          emi.push(hosp_paytypes[i].ip_emi)
          insurance.push(hosp_paytypes[i].ip_insurance)
        }

        if (this.hospBillsPaytypeIpAdm) {
          this.hospBillsPaytypeIpAdm.destroy();
        }

        var ctx = <HTMLCanvasElement>document.getElementById("hospBillsPaytypeIpAdm");
        ctx.height = 245;
        ctx.width = 515;
        this.hospBillsPaytypeIpAdm = new Chart(ctx, {
          type: "line",
          labels: ["Stepcount"],
          data: {
            labels: dates,
            datasets: [{
              data: cash,
              fill: false,
              backgroundColor: backgroundColors[0],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "Cash"
            }, {
              data: card,
              fill: false,
              backgroundColor: backgroundColors[1],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "Card"
            }, {
              data: upi,
              fill: false,
              backgroundColor: backgroundColors[2],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "UPI"
            }, {
              data: cheque,
              fill: false,
              backgroundColor: backgroundColors[3],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "Cheque"
            }, {
              data: emi,
              fill: false,
              backgroundColor: backgroundColors[1],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "EMI"
            }, {
              data: insurance,
              fill: false,
              backgroundColor: backgroundColors[2],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "Insurance"
            }],
          },
          options: {
            responsive: false,
            scales: {
              yAxes: [{
                display: true,
                ticks: {
                  beginAtZero: true,
                  steps: 100,
                  stepValue: 5,
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Bill Amount'
                }
              }],
              xAxes: [{
                barPercentage: 1,
                barThickness: 35,
                ticks: {
                  maxRotation: 90,
                  minRotation: 0,
                },
                scaleLabel: {
                  display: false,
                  labelString: 'Payment Methods'
                }
              }]
            },
            legend: {
              display: true
            },
          }
        });
      } else {

      }
    }
  }

  getHospPaytype_IpAdm_bar(hosp_paytypes) {
    // this.isBar_hosp = true;
    var data = [hosp_paytypes[0].ip_cash, hosp_paytypes[0].ip_card, hosp_paytypes[0].ip_upi, hosp_paytypes[0].ip_cheque, hosp_paytypes[0].ip_emi, hosp_paytypes[0].ip_insurance];
    var labels = ["Cash", "Card", "UPI", "Cheque", "EMI", "Insurance"]

    if (this.hospBillsPaytypeIpAdm_Bar) {
      this.hospBillsPaytypeIpAdm_Bar.destroy();
    }

    var ctx = <HTMLCanvasElement>document.getElementById("hospBillsPaytypeIpAdm_Bar");
    ctx.height = 245;
    ctx.width = 515;
    this.hospBillsPaytypeIpAdm_Bar = new Chart(ctx, {
      type: "bar",
      labels: ["Stepcount"],
      data: {
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(255, 159, 64, 0.2)"
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)"
            ],
            fill: false,
            borderWidth: 2,
            spanGaps: true,
            label: "Payment Modes"
          },
        ]
      },
      options: {
        responsive: false,
        scales: {
          yAxes: [{
            display: true,
            ticks: {
              beginAtZero: true,
              steps: 100,
              stepValue: 5,
            },
            scaleLabel: {
              display: true,
              labelString: 'Amount'
            }
          }],
          xAxes: [{
            barPercentage: 1,
            barThickness: 35,
            ticks: {
              maxRotation: 90,
              minRotation: 0,
            },
            scaleLabel: {
              display: true,
              labelString: 'Payment Methods'
            }
          }]
        },
        legend: {
          display: false
        },
      }
    });
  }

  getDiagPaytype_OpAdm(diag_paytypes) {
    var backgroundColors = ['rgb(255, 99, 132, 1)',
      'rgb(75, 192, 192, 1)',
      'rgb(255, 205, 86, 1)',
      'rgb(201, 203, 207, 1)',
      'rgb(54, 162, 235, 1)']

    if (diag_paytypes != undefined) {
      if (diag_paytypes.length > 1) {
        var dates = [];
        var cash = [];
        var card = [];
        var upi = [];
        var cheque = [];
        var emi = [];
        var insurance = [];

        for (var i = 0; i < diag_paytypes.length; i++) {
          dates.push(diag_paytypes[i].created_date)
          cash.push(diag_paytypes[i].op_cash)
          card.push(diag_paytypes[i].op_card)
          upi.push(diag_paytypes[i].op_upi)
          cheque.push(diag_paytypes[i].op_cheque)
          emi.push(diag_paytypes[i].op_emi)
          insurance.push(diag_paytypes[i].op_insurance)
        }

        if (this.diagBillsPaytypeOpAdm) {
          this.diagBillsPaytypeOpAdm.destroy();
        }

        var ctx = <HTMLCanvasElement>document.getElementById("diagBillsPaytypeOpAdm");
        ctx.height = 245;
        ctx.width = 515;
        this.diagBillsPaytypeOpAdm = new Chart(ctx, {
          type: "line",
          labels: ["Stepcount"],
          data: {
            labels: dates,
            datasets: [{
              data: cash,
              fill: false,
              backgroundColor: backgroundColors[0],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "Cash"
            }, {
              data: card,
              fill: false,
              backgroundColor: backgroundColors[1],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "Card"
            }, {
              data: upi,
              fill: false,
              backgroundColor: backgroundColors[2],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "UPI"
            }, {
              data: cheque,
              fill: false,
              backgroundColor: backgroundColors[3],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "Cheque"
            }, {
              data: emi,
              fill: false,
              backgroundColor: backgroundColors[1],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "EMI"
            }, {
              data: insurance,
              fill: false,
              backgroundColor: backgroundColors[2],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "Insurance"
            }],
          },
          options: {
            responsive: false,
            scales: {
              yAxes: [{
                display: true,
                ticks: {
                  beginAtZero: true,
                  steps: 100,
                  stepValue: 5,
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Bill Amount'
                }
              }],
              xAxes: [{
                barPercentage: 1,
                barThickness: 35,
                ticks: {
                  maxRotation: 90,
                  minRotation: 0,
                },
                scaleLabel: {
                  display: false,
                  labelString: 'Payment Methods'
                }
              }]
            },
            legend: {
              display: true
            },
          }
        });
      } else {

      }
    }
  }

  getDiagPaytype_OpAdm_bar(diag_paytypes) {
    // this.isBar_hosp = true;
    var data = [diag_paytypes[0].op_cash, diag_paytypes[0].op_card, diag_paytypes[0].op_upi, diag_paytypes[0].op_cheque, diag_paytypes[0].op_emi, diag_paytypes[0].op_insurance];
    var labels = ["Cash", "Card", "UPI", "Cheque", "EMI", "Insurance"]

    if (this.diagBillsPaytypeOpAdm_Bar) {
      this.diagBillsPaytypeOpAdm_Bar.destroy();
    }

    var ctx = <HTMLCanvasElement>document.getElementById("diagBillsPaytypeOpAdm_Bar");
    ctx.height = 245;
    ctx.width = 515;
    this.diagBillsPaytypeOpAdm_Bar = new Chart(ctx, {
      type: "bar",
      labels: ["Stepcount"],
      data: {
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(255, 159, 64, 0.2)"
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)"
            ],
            fill: false,
            borderWidth: 2,
            spanGaps: true,
            label: "Payment Modes"
          },
        ]
      },
      options: {
        responsive: false,
        scales: {
          yAxes: [{
            display: true,
            ticks: {
              beginAtZero: true,
              steps: 100,
              stepValue: 5,
            },
            scaleLabel: {
              display: true,
              labelString: 'Amount'
            }
          }],
          xAxes: [{
            barPercentage: 1,
            barThickness: 35,
            ticks: {
              maxRotation: 90,
              minRotation: 0,
            },
            scaleLabel: {
              display: true,
              labelString: 'Payment Methods'
            }
          }]
        },
        legend: {
          display: false
        },
      }
    });
  }

  getDiagPaytype_IpAdm(diag_paytypes) {
    var backgroundColors = ['rgb(255, 99, 132, 1)',
      'rgb(75, 192, 192, 1)',
      'rgb(255, 205, 86, 1)',
      'rgb(201, 203, 207, 1)',
      'rgb(54, 162, 235, 1)']

    if (diag_paytypes != undefined) {
      if (diag_paytypes.length > 1) {
        var dates = [];
        var cash = [];
        var card = [];
        var upi = [];
        var cheque = [];
        var emi = [];
        var insurance = [];

        for (var i = 0; i < diag_paytypes.length; i++) {
          dates.push(diag_paytypes[i].created_date)
          cash.push(diag_paytypes[i].ip_cash)
          card.push(diag_paytypes[i].ip_card)
          upi.push(diag_paytypes[i].ip_upi)
          cheque.push(diag_paytypes[i].ip_cheque)
          emi.push(diag_paytypes[i].ip_emi)
          insurance.push(diag_paytypes[i].ip_insurance)
        }

        if (this.diagBillsPaytypeIpAdm) {
          this.diagBillsPaytypeIpAdm.destroy();
        }

        var ctx = <HTMLCanvasElement>document.getElementById("diagBillsPaytypeIpAdm");
        ctx.height = 245;
        ctx.width = 515;
        this.diagBillsPaytypeIpAdm = new Chart(ctx, {
          type: "line",
          labels: ["Stepcount"],
          data: {
            labels: dates,
            datasets: [{
              data: cash,
              fill: false,
              backgroundColor: backgroundColors[0],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "Cash"
            }, {
              data: card,
              fill: false,
              backgroundColor: backgroundColors[1],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "Card"
            }, {
              data: upi,
              fill: false,
              backgroundColor: backgroundColors[2],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "UPI"
            }, {
              data: cheque,
              fill: false,
              backgroundColor: backgroundColors[3],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "Cheque"
            }, {
              data: emi,
              fill: false,
              backgroundColor: backgroundColors[1],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "EMI"
            }, {
              data: insurance,
              fill: false,
              backgroundColor: backgroundColors[2],
              // borderColor: ["rgba(255, 99, 132, 1)"],
              spanGaps: true,
              label: "Insurance"
            }],
          },
          options: {
            responsive: false,
            scales: {
              yAxes: [{
                display: true,
                ticks: {
                  beginAtZero: true,
                  steps: 100,
                  stepValue: 5,
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Bill Amount'
                }
              }],
              xAxes: [{
                barPercentage: 1,
                barThickness: 35,
                ticks: {
                  maxRotation: 90,
                  minRotation: 0,
                },
                scaleLabel: {
                  display: false,
                  labelString: 'Payment Methods'
                }
              }]
            },
            legend: {
              display: true
            },
          }
        });
      } else {

      }
    }
  }

  getDiagPaytype_IpAdm_bar(diag_paytypes) {
    // this.isBar_hosp = true;
    var data = [diag_paytypes[0].ip_cash, diag_paytypes[0].ip_card, diag_paytypes[0].ip_upi, diag_paytypes[0].ip_cheque, diag_paytypes[0].ip_emi, diag_paytypes[0].ip_insurance];
    var labels = ["Cash", "Card", "UPI", "Cheque", "EMI", "Insurance"]

    if (this.diagBillsPaytypeIpAdm_Bar) {
      this.diagBillsPaytypeIpAdm_Bar.destroy();
    }

    var ctx = <HTMLCanvasElement>document.getElementById("diagBillsPaytypeIpAdm_Bar");
    ctx.height = 245;
    ctx.width = 515;
    this.diagBillsPaytypeIpAdm_Bar = new Chart(ctx, {
      type: "bar",
      labels: ["Stepcount"],
      data: {
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(255, 159, 64, 0.2)"
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)"
            ],
            fill: false,
            borderWidth: 2,
            spanGaps: true,
            label: "Payment Modes"
          },
        ]
      },
      options: {
        responsive: false,
        scales: {
          yAxes: [{
            display: true,
            ticks: {
              beginAtZero: true,
              steps: 100,
              stepValue: 5,
            },
            scaleLabel: {
              display: true,
              labelString: 'Amount'
            }
          }],
          xAxes: [{
            barPercentage: 1,
            barThickness: 35,
            ticks: {
              maxRotation: 90,
              minRotation: 0,
            },
            scaleLabel: {
              display: true,
              labelString: 'Payment Methods'
            }
          }]
        },
        legend: {
          display: false
        },
      }
    });
  }

  user_base() {
    var send_data: any;
    send_data = {
      reg_id: this.user_id,
      fdate: Date_Formate(this.f_date),
      tdate: Date_Formate(this.t_date),
      hosp_id: this.hosp_id,
      admin: this.admin,
      pat_type: 'outpat',
      interval: this.interval,
      range: this.range,
    };

    console.log('send_data' + JSON.stringify(send_data));

    if (this.userInfo.user_type == 'dietician') {
      send_data.type = 'dietician';
    } else if (this.userInfo.user_type == 'Admin') {
      send_data.reg_id = 'All';
      send_data.admin = '1';
    } else if (this.userInfo.user_type == 'doctor') {
      send_data.reg_id = this.user_id;
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "dash/grbhd", JSON.stringify({
      reg_id: this.user_id,
      fdate: Date_Formate(this.f_date),
      tdate: Date_Formate(this.t_date),
      hosp_id: this.hosp_id,
      createdby: this.user_id,
      // admin: this.admin,
      // pat_type: 'outpat',
      // interval: this.interval,
      range: this.range,
    }), { headers: headers }).subscribe(
      response => {
        var obj = JSON.parse(response["_body"]);
        console.log('gender based  GRAPH ' + JSON.stringify(obj));
        if (obj != undefined) {
          if (this.is_admin == true) {
            if (this.tabtype == "outpatient") {
              this.getHospBills_OpAdm(obj.hospital_bills, obj.hosp_data, obj.hosp_dates);
              this.getPharmBills_OpAdm(obj.pharma_bills, obj.pharma_data, obj.pharma_dates);
              this.getDiagBills_OpAdm(obj.diagnosis_bills, obj.diag_data, obj.diag_dates);

              this.getPharmPaytype_OpAdm(obj.pharma_paytypes);
              if (obj.hosp_paytypes != undefined) {
                if (obj.hosp_paytypes.length == 1) {
                  this.isBar_hosp = true;
                  setTimeout(() => {
                    this.getHospPaytype_OpAdm_bar(obj.hosp_paytypes);
                  }, 500);
                } else {
                  this.isBar_hosp = false;
                  setTimeout(() => {
                    this.getHospPaytype_OpAdm(obj.hosp_paytypes);
                  }, 500);                  
                }
              } 

              if (obj.diag_paytypes != undefined) {
                if (obj.diag_paytypes.length == 1) {
                  this.isBar_diag = true;
                  setTimeout(() => {
                    this.getDiagPaytype_OpAdm_bar(obj.diag_paytypes);
                  }, 500);
                } else {
                  this.isBar_diag = false;
                  setTimeout(() => {
                    this.getDiagPaytype_OpAdm(obj.diag_paytypes);
                  }, 500);                  
                }
              }
            } else {
              this.getHospBills_IpAdm(obj.hospital_bills, obj.hosp_data, obj.hosp_dates);
              this.getPharmBills_IpAdm(obj.pharma_bills, obj.pharma_data, obj.pharma_dates);
              this.getDiagBills_IpAdm(obj.diagnosis_bills, obj.diag_data, obj.diag_dates);

              if (obj.hosp_paytypes != undefined) {
                if (obj.hosp_paytypes.length == 1) {
                  this.isBar_hosp_Ip = true;
                  setTimeout(() => {
                    this.getHospPaytype_IpAdm_bar(obj.hosp_paytypes);
                  }, 500);
                } else {
                  this.isBar_hosp_Ip = false;
                  setTimeout(() => {
                    this.getHospPaytype_IpAdm(obj.hosp_paytypes);
                  }, 500);                  
                }
              }

              if (obj.pharma_paytypes != undefined) {
                if (obj.pharma_paytypes.length == 1) {
                  this.isBar_pharma_Ip = true;
                  setTimeout(() => {
                    this.getPharmPaytype_IpAdm_bar(obj.pharma_paytypes);
                  }, 500);
                } else {
                  this.isBar_pharma_Ip = false;
                  setTimeout(() => {
                    this.getPharmPaytype_IpAdm(obj.pharma_paytypes);
                  }, 500);                  
                }
              }

              if (obj.diag_paytypes != undefined) {
                if (obj.diag_paytypes.length == 1) {
                  this.isBar_pharma_Ip = true;
                  setTimeout(() => {
                    this.getDiagPaytype_IpAdm_bar(obj.diag_paytypes);
                  }, 500);
                } else {
                  this.isBar_pharma_Ip = false;
                  setTimeout(() => {
                    this.getDiagPaytype_IpAdm(obj.diag_paytypes);
                  }, 500);                  
                }
              }
            }

            this.pending_indents = obj.pending_pos != undefined ? obj.pending_pos : "0";
            this.complete_pos_indents = obj.complete_pos != undefined ? obj.complete_pos : "0";
            this.total_indents = parseInt(this.pending_indents) + parseInt(this.complete_pos_indents);
            // this.bills_chart(obj);
          } else {

            // Estimates
            this.op_comp_estimate = obj.op_comp_estimate != undefined ? obj.op_comp_estimate : "0";
            this.op_pending_estimate = obj.op_pending_estimate != undefined ? obj.op_pending_estimate : "0"
            this.total_Op_estimate = parseInt(this.op_comp_estimate) + parseInt(this.op_pending_estimate);

            this.ip_comp_estimate = obj.ip_comp_estimate != undefined ? obj.ip_comp_estimate : "0";
            this.ip_pending_estimate = obj.ip_pending_estimate != undefined ? obj.ip_pending_estimate : "0";
            this.total_Ip_estimate = parseInt(this.ip_comp_estimate) + parseInt(this.ip_pending_estimate);

            // Indents
            this.pending_indents = obj.pending_pos != undefined ? obj.pending_pos : "0";
            this.complete_pos_indents = obj.complete_pos != undefined ? obj.complete_pos : "0";
            this.total_indents = parseInt(this.pending_indents) + parseInt(this.complete_pos_indents);

            // Bill count and Bill amount
            this.op_bill_count = 0;
            this.op_bill_amount = 0;
            this.ip_bill_count = 0;
            this.ip_bill_amount = 0;
            if (obj.pharma_bills != undefined) {
              for (var i = 0; i < obj.pharma_bills.length; i++) {
                this.op_bill_count = obj.pharma_bills[i].op_bill_count != undefined ? this.op_bill_count + parseInt(obj.pharma_bills[i].op_bill_count) : this.op_bill_count;

                this.op_bill_amount = (obj.pharma_bills[i].op_bill_amount != undefined ? this.op_bill_amount + parseFloat(obj.pharma_bills[i].op_bill_amount) : this.op_bill_amount).toFixed(2);

                this.ip_bill_count = obj.pharma_bills[i].ip_bill_count != undefined ? this.ip_bill_count + parseInt(obj.pharma_bills[i].ip_bill_count) : this.ip_bill_count;

                this.ip_bill_amount = (obj.pharma_bills[i].ip_bill_amount != undefined ? this.ip_bill_amount + parseFloat(obj.pharma_bills[i].ip_bill_amount) : this.ip_bill_amount).toFixed(2);
              }
            }
          }
        }
      },
      (error) => { }
    );
  }

  getFrontdeskDashdata() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "dash/gabfd", JSON.stringify({
      reg_id: this.user_id,
      fdate: Date_Formate(this.f_date),
      tdate: Date_Formate(this.t_date),
      hosp_id: this.hosp_id,
      createdby: this.user_id,
      // admin: this.admin,
      // pat_type: 'outpat',
      // interval: this.interval,
      range: this.range,
    }), { headers: headers }).subscribe(
      response => {
        var obj = JSON.parse(response["_body"]);
        console.log('frontdesk dash data' + JSON.stringify(obj));

        if (obj != undefined) {
          this.doc_completedApp = obj.completed != undefined ? obj.completed : 0;
          this.doc_openApp = obj.open_app != undefined ? obj.open_app : 0;
          this.doc_confirmedApp = obj.confirmed != undefined ? obj.confirmed : 0;
          this.doc_inpatApp = obj.inpat != undefined ? obj.inpat : 0;
          this.total_docApps = parseInt(this.doc_completedApp) + parseInt(this.doc_openApp) + parseInt(this.doc_confirmedApp);

          this.diag_completedApp = obj.diag_completed != undefined ? obj.diag_completed : 0;
          this.diag_openApp = obj.diag_open_app != undefined ? obj.diag_open_app : 0;
          this.total_diagApps = parseInt(this.diag_completedApp) + parseInt(this.diag_openApp);
        }
      },
      (error) => { });

  }

}
