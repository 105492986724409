<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Past order</h5>
          </div>
          <div class="headerButtons">
          </div>
        </div>

      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="col-12" style="margin-bottom: 15px;">
            <div class="row">
              <div class="col-12">
                <div class="switch-field" *ngIf="ynopt1 != undefined">
                  <input type="radio" id="radio-three" (click)="change_type(1,'yes')" />
                  <label for="radio-three" [ngClass]="ynopt1 ? 'radio_active':'radio_inactive'">
                    <div class="tab_cover">
                      <div class="tab_icon_cover"><img src="../../../assets/ui_icons/Outpatient.svg" class="iocinStyle"
                          alt=""></div>
                      <div class="lableStyle">Out-patient</div>
                    </div>
                  </label>
                  <input type="radio" id="radio-four" (click)="change_type(1,'no')" />
                  <label for="radio-four" [ngClass]="!ynopt1 ? 'radio_active':'radio_inactive'">
                    <div class="tab_cover">
                      <div class="tab_icon_cover"><img src="../../../assets/ui_icons/Inpatient.svg" class="iocinStyle"
                          alt=""></div>
                      <div class="lableStyle">In-patient</div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-6 col-md-4 col-xl-2 col-lg-2">
            <mat-label class="matlabel">Order type<br>
              <select disableOptionCentering class="ipcss " required [(ngModel)]="ordertype">
                <option value="otc">OTC</option>
                <option value="notc">Non-OTC</option>
              </select>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-xl-2 col-lg-2">
            <mat-label class="matlabel">Filter by<br>
              <select disableOptionCentering class="ipcss " required [(ngModel)]="filtertype"
                (change)="changeFilterType()">
                <option value="date">Date</option>
                <option value="client">Patient</option>
              </select>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-xl-3 col-lg-3" *ngIf="!filter_flag">
            <mat-label class="matlabel">Patient name<br>  
              <input type="text" class="ipcss order_width getMedcien" required maxlength="50"
              (keyup)="getPatientDetails()" maxlength="50" [(ngModel)]="patName" matInput
              [matAutocomplete]="auto1" />
              <mat-autocomplete #auto1="matAutocomplete" [panelWidth]="'auto'">
                <mat-option (click)="selectPatient(name)" *ngFor="let name of nameList"
                  value="{{name.name}}">{{name.name}}</mat-option>
              </mat-autocomplete>
            </mat-label>
          </div>

          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" *ngIf="filter_flag">
            <mat-label class="matlabel" style="margin-top: 7px;">From
              <input type="date" class="ipcss_date " (change)="selectDate(fromDate,'1')" [(ngModel)]="fromDate"
              max="{{currentDate}}" #matInput style="width: 140px;">
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" *ngIf="filter_flag">
            <mat-label class="matlabel" style="margin-top: 7px;">To
              <input type="date" class="ipcss_date " (change)="selectDate(toDate,'2')" [(ngModel)]="toDate"
                max="{{currentDate}}" #matInput style="width: 140px;">
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" *ngIf="filter_flag">
            <mat-label class="matlabel"><br>
              <img src="../../../assets/ui_icons/buttons/search_button.svg" class="saveimgbtn_inpatinfo"
                (click)="currentOrder(pharmacy_id,'',fname)" />
            </mat-label>
          </div>
        </div>

        <div class="row" style="margin-top: 10px !important;">
          <div class="col-12 col-lg-12 col-md-12 col-xl-12">
            <p class="nodata" *ngIf="order_listarray.length == 0">No Past Order(s) found</p>
            
            <table *ngIf="order_listarray.length" mdbTable datatable [dtOptions]="dtOptions"
              class="table table-nowrap table-sm dataTable">
              <thead class="tableheading">
                <tr>
                  <th>Bill.no</th>
                  <th>Token no</th>
                  
                  <th>Name</th>
                  <th>Delivery type </th>
                  <th>Order date </th>
                  <th *ngIf="editbutton"> </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let list of order_listarray;let i = index" class="tdvalues">
                  <td style="font-size: 12px;" (click)='order_View(list.drug_purchase_id,list.fin_year)'>{{list.drug_purchase_id}}</td>
                  <td style="font-size: 12px;">{{list.tokenno}}&nbsp;&nbsp;
                    <img style="width:45px;" src="../../../assets/img/Button_Icons/mic_on.svg" (click)="tokennospeech(list.tokenno)">  
                    </td>
                    <td style="font-size: 12px;text-align: left !important;" (click)='order_View(list.drug_purchase_id,list.fin_year)'>{{list.tot_name}}</td>
                  
                  <td style="font-size: 12px;text-align: left !important;" (click)='order_View(list.drug_purchase_id,list.fin_year)'> {{list.home_delivery}}</td>
                  <td style="font-size: 12px;" (click)='order_View(list.drug_purchase_id,list.fin_year)'>{{list.order_date||'-'}}/{{list.ordertime}}</td>
                  <td *ngIf="editbutton" style="font-size: 12px;" (click)='order_View(list.drug_purchase_id,list.fin_year)'> <a (click)="editMedicine(list.drug_purchase_id,list.fin_year)">
                      <img src="../../../assets/ui_icons/buttons/pencil_icon.svg" width="20" height="20" /></a></td>
                </tr>
              </tbody>
            </table>
          
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>