<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white ">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Appointment details</h5>
          </div>
          <!-- *ngIf="bookFlag" -->
          <div class="headerButtons">
            <a *ngIf="!updateFlag"><img src="../../../assets/ui_icons/buttons/Book_now.svg" class="saveimgbtn_inpatinfo"
                (click)="book('booking')" [hidden]="bookFlag" /></a>
            <img src="../../../assets/ui_icons/buttons/update_button.svg" class="saveimgbtn_inpatinfo"
              (click)="book('appointment')" *ngIf="updateFlag" [hidden]="bookFlag" />
            <a style="margin:0 5px;" (click)="back()"><img src="../../../assets/ui_icons/buttons/Back_button.svg"
                class="backimgbtn_inpatinfo" /></a>
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="" style="margin-top: -10px;">
            <div class="row">
              <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5" style="margin: 15px 0 15px 0;">
                <div class="row">
                  <div class="col-3">
                    <mat-label class="matlabel" style="font-weight: 600;">Client Type : </mat-label>
                  </div>
                  <div class="col-8">
                    <div class="switch-field">
                      <input type="radio" id="radio-three" (click)="changeType('client',1,'yes')" />
                      <label for="radio-three" [ngClass]="clientTypeFlag ? 'radio_active':'radio_inactive'">New</label>
                      <input type="radio" id="radio-four" (click)="changeType('client',1,'no')" />
                      <label for="radio-four"
                        [ngClass]="!clientTypeFlag ? 'radio_active':'radio_inactive'">Existing</label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5" style="margin: 15px 0 15px 0;" >
                <div class="row">
                  <div class="col-4">
                    <mat-label class="matlabel" style="font-weight: 600;">Appointment Type : </mat-label>
                  </div>
                  <div class="col-8">
                    <div class="switch-field">
                      <input type="radio" id="radio-one" (click)="changeType('app',1,'yes')"  [disabled]="appdisable"/>
                      <label for="radio-one" [ngClass]="apptTypeFlag ? 'radio_active':'radio_inactive'">New</label>
                      <input type="radio" id="radio-two" (click)="changeType('app',1,'no')"  [disabled]="appdisable"/>
                      <label for="radio-two"
                        [ngClass]="!apptTypeFlag ? 'radio_active':'radio_inactive'">Follow-up</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="container-fluid" style="margin-left: -20px; vertical-align: top !important;">
              <div class="row align-items-top"  style="margin-right: -50px;">
                <div class="col-3" [hidden]="searchFlag">
                  <div class="cover_div" style="padding-bottom: 13px;">
                    <div class="header_lable">Patient</div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" >
                      <div class="row">
                        <div class="col-12">
                          <div class="switch-field" >
                            <input type="radio" id="radio-five" (click)="searchType('name')" />
                            <label for="radio-five" [ngClass]="typeflag == 'name' ? 'radio_active':'radio_inactive'">Name</label>
                            <input type="radio" id="radio-six" (click)="searchType('mobile')" />
                            <label for="radio-six" [ngClass]="typeflag == 'mobile' ? 'radio_active':'radio_inactive'">Mobile</label>
                            <input type="radio" id="radio-seven" (click)="searchType('patientid');" />
                            <label for="radio-seven" [ngClass]="typeflag == 'patientid' ? 'radio_active':'radio_inactive'">patient id</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- style="height:82vh" -->
                    <div class="content_cover">
                      <div>
                        <input class="inputStyle2" type="text" id="filterInput" [(ngModel)]="patientName" placeholder={{searchtext}} 
                        (keyup)="getPatientListByName(patientName)">
                        <!-- (keyup)="tableFilter(patientName)" -->
                        <!-- style="height:80vh" -->
                        <div class="scrollbar" #scrollContainer>
                          <div class="scrollbar-inner">
                            <table *ngIf="patientList.length">
                              <tbody>
                                <tr *ngFor="let person of filteredArr; let i = index"
                                  (click)='retrieveClient(person)'>
                                  <td style="font-size: 12px;text-align: left;">{{ person.full_name }} <br /> {{
                                    person.mobile }} </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <!-- <div class="col-12" *ngIf="searchFlag"> -->
                <div [ngClass]="appclass ? 'col-9':'col-12'">
                  <div class="cover_div" style="padding-bottom: 13px;">
                    <div class="header_lable">
                      Appointment
                    </div>
                    <div class="content_cover">
                      <div class="row">
                        <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                          <mat-label class="matlabel">Specialization<br>
                            <select id="mandatory_specfication" class="ipcss widthappt" [(ngModel)]="splId"
                              (change)="getDoctorList()">
                              <option *ngFor="let spl of splList" value="{{spl.spl_id}}">{{spl.spl_name}}</option>
                            </select>
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                          <mat-label class="matlabel">Doctor<br>
                            <select class="ipcss widthappt" [(ngModel)]="doctorId" required
                              (change)="getDoctorDetails()">
                              <option *ngFor="let doctor of doctorList" value="{{doctor.prov_id}}">{{doctor.docname}}
                              </option>
                            </select>
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-5 col-lg-3 col-xl-2">
                          <mat-label class="matlabel">Date
                            <input type="date" class="ipcss_date widthbillcreate" id="appt_date"
                              (change)="selectAppointmentDate(appointmentDate)" required [(ngModel)]="appointmentDate"
                              #matInput style="background: #fff;" pattern="\d{1,2}/\d{1,2}/\d{4}">
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3" *ngIf="availableFlag">
                          <mat-label class="matlabel">Visiting session<br>
                            <select class="ipcss widthappt" [(ngModel)]="visitSession"
                              (change)="changeVisitSession(visitSession, appointmentDate)" required>
                              <option *ngFor="let session of visitingSessionData" value="{{session.description}}">
                                {{session.description}}</option>
                            </select>
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3" *ngIf="!appTypeTokenFlag">
                          <mat-label class="matlabel">Appointment Time
                            <select required class="ipcss widthappt" [(ngModel)]="visitTime" required
                              *ngIf="!appTypeTokenFlag">
                              <option *ngFor="let time of timeList" value="{{time}}">{{time}}</option>
                            </select>
                          </mat-label>
                        </div>
                        <div class="col-12" *ngIf="appTypeTokenFlag">
                          <mat-label class="matlabel">
                            <p *ngIf="appTypeTokenFlag">Appointment type : Token</p>
                          </mat-label>
                        </div>
                        <div class="col-12" *ngIf="nonAvailableFlag">
                          <mat-label *ngIf="nonAvailableFlag" class="matlabel">Doctor not available at this time, choose
                            another date to proceed</mat-label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="cover_div" style="padding-bottom: 13px;">
                    <div class="header_lable">
                      Profile
                    </div>
                    <div class="content_cover">
                      <div class="row">
                        <!-- <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3" [hidden]="searchFlag">
                          <mat-label class="matlabel">Filter
                            <select disableOptionCentering class="ipcss " (change)="filterByPatients()"
                              [(ngModel)]="filter">
                              <option value="name">First name</option>
                              <option value="mobile">Mobile</option>
                              <option value="patient_id">Patient Id</option>
                            </select>
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 " [hidden]="searchFlag">
                          <div class="row">
                            <div class="col-10">
                              <mat-label class="matlabel "><br>
                                <input class="ipcss " [(ngModel)]="patientFName" (selecionChange)="changeName()"
                                  (blur)="patientNameToUpper()" placeholder={{somePlaceholder}} [maxLength]="maxLength">
                              </mat-label>
                            </div>
                            <div class="col-2 p-0" [hidden]="searchFlag">
                              <mat-label class="matlabel ">
                                <a (click)="changePatientName(patientFName,false)"><img
                                    src="../../../assets/ui_icons/search_icon.svg" class="seacrhicon" width="30"
                                    height="auto" /></a>
                              </mat-label>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3" [hidden]="searchFlag">
                          <mat-label class="matlabel">Patient id
                 
                            <mat-select [(ngModel)]="clientId" class="ipcss widthappt" required>
                              <mat-option *ngFor="let client of clientList" value={{client.patient}}
                                (click)="changeClient(client.patient)">
                                {{client.patient}}</mat-option>
                            </mat-select>
                          </mat-label>
                        </div> -->
                        <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                          <mat-label class="matlabel">Salutation
                            <br>
                            <select class="ipcss widthappt" [(ngModel)]="clntSal"
                              (ngModelChange)="changeSalutation($event)" required>
                              <!-- <option value="select" Selected>Select</option> -->
                              <option *ngFor="let sal of clntSalList" value="{{sal.sal_id}}">{{sal.sal_desc}}</option>
                            </select>
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                          <mat-label class="matlabel">First name
                            <input class="ipcss widthappt" [(ngModel)]="clntFirstName" (blur)="fnameToUpper()"
                              (keyup)="updateUserprofile()" required matInput />
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                          <mat-label class="matlabel">Middle name
                            <input class="ipcss widthappt" [(ngModel)]="clntMiddleName" (blur)="mnameToUpper()"
                              (keyup)="updateUserprofile()" matInput />
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                          <mat-label class="matlabel">Last name
                            <input class="ipcss widthappt" [(ngModel)]="clntLastName" (blur)="lnameToUpper()"
                              (keyup)="updateUserprofile()" required matInput />
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                          <mat-label class="matlabel">DOB
                            <input type="date" class="ipcss_date noappt_width heightdob" (change)="selectDob(clntDOB)"
                              [(ngModel)]="clntDOB" max="{{currentMaxDate}}" required #matInput>
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                          <mat-label class="matlabel">Age
                            <input [(ngModel)]="clntAge" [readonly]="ageRead" [required]="!ageRead"
                              (keyup)="updateUserprofile()" (blur)="calculateDOB()"  class="ipcss widthappt" required matInput>
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                          <mat-label class="matlabel">Mobile
                            <input [(ngModel)]="clntContNumber" maxlength="10" required (blur)="validateMobile()"
                              class="ipcss widthappt" onkeypress="return event.charCode >= 48 && event.charCode <= 57" matInput>
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                          <mat-label class="matlabel">Emergency contact1
                            <input [(ngModel)]="clntEmergNumber" maxlength="15" (keyup)="updateUserprofile()"
                              class="ipcss widthappt" onkeypress="return event.charCode >= 48 && event.charCode <= 57" matInput>
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                          <mat-label class="matlabel">Emergency contact2
                            <input [(ngModel)]="clntEmergNumber1" maxlength="15" class="ipcss widthappt"
                              (keyup)="updateUserprofile()" onkeypress="return event.charCode >= 48 && event.charCode <= 57" matInput>
                          </mat-label>
                        </div>
                        <!-- <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3" >
                          <mat-label class="matlabel">Gender
                            <input class="ipcss widthappt" [(ngModel)]="clntGender" matInput/>
                          </mat-label>
                        </div> -->
                        <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                          <mat-label class="matlabel">Gender<br>
                            <select [(ngModel)]="clntGender" class="ipcss widthappt" [disabled]="retrvDisabledFlag"
                              (ngModelChange)="changeGender($event)">
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                              <option value="Transgender">Transgender</option>
                            </select>
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3" [hidden]="covidFlag">
                          <mat-label class="matlabel">Symptoms
                            <input type="text" class="ipcss noappt_width" [(ngModel)]="clntSymptom"
                              (keyup)="getSymptoms()" matInput />
                            <div class="auto_complete_text" *ngIf="symptomList.length != 0">
                              <ul *ngFor="let symptom of symptomList">
                                <li>
                                  <a (click)="selectSymptom(symptom)">{{symptom}}</a>
                                </li>
                              </ul>
                            </div>
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                          <mat-label class="matlabel">Father / Guardian name
                            <input class="ipcss widthappt" [(ngModel)]="clntFatherOrGuardName" matInput
                              (keyup)="updateUserprofile()" />
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                          <mat-label class="matlabel">Address1
                            <!--  [disabled]="userTypeFlag"-->
                            <input type="text" class="ipcss widthappt" [(ngModel)]="clntAddress1" required
                              (keyup)="updateUserprofile()" matInput />
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                          <mat-label class="matlabel">Address2
                            <!--  [disabled]="userTypeFlag"-->
                            <input type="text" class="ipcss widthappt" [(ngModel)]="clntAddress2" matInput
                              (keyup)="updateUserprofile()" />
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                          <mat-label class="matlabel">Zipcode
                            <input type="text" class="ipcss widthappt" [(ngModel)]="clntZipcode"
                              (keyup)=zipcode_change() matInput />
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                          <mat-label class="matlabel">Location<br>
                            <input type="text" class="ipcss widthappt" required maxlength="50"
                              [(ngModel)]="clntLocation" (keyup)="changeLocation(clntLocation)" matInput />
                            <div class="auto_complete_text" *ngIf="clntLocationList.length != 0">
                              <ul *ngFor="let location of clntLocationList">
                                <li>
                                  <a (click)="selectLocation(location)">{{location.description}}</a>
                                </li>
                              </ul>
                            </div>
                          </mat-label>
                        </div>

                        <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                          <mat-label class="matlabel">District / City<br>
                            <input matInput class="ipcss widthappt" [(ngModel)]="clntCity" [disabled]="userTypeFlag"
                              [hidden]="!existUserFlag">
                            <mat-select required class="ipcss widthappt" [(ngModel)]="clntCity" [hidden]="existUserFlag"
                              disableOptionCentering (ngModelChange)="changeCity(clntCity, '0')">
                              <mat-option *ngFor="let city of clntCityList"
                                value="{{city.city_desc}}">{{city.city_desc}}
                              </mat-option>
                            </mat-select>
                          </mat-label>
                        </div>

                        <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                          <mat-label class="matlabel">State<br>
                            <input type="text" matInput class="ipcss widthappt" [(ngModel)]="clntState"
                              [disabled]="userTypeFlag" [hidden]="!existUserFlag">
                            <mat-select required class="ipcss widthappt" [(ngModel)]="clntState" disableOptionCentering
                              [hidden]="existUserFlag" (ngModelChange)="changeState(clntState, '0')">
                              <mat-option *ngFor="let state of clntStateList"
                                value={{state.state_desc}}>{{state.state_desc}}
                              </mat-option>
                            </mat-select>
                          </mat-label>
                        </div>

                        <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                          <mat-label class="matlabel">Country<br>
                            <input type="text" class="ipcss widthappt" [(ngModel)]="clntCountry"
                              [disabled]="userTypeFlag" [hidden]="!existUserFlag">
                            <mat-select required [(ngModel)]="clntCountry" class="ipcss widthappt"
                              [hidden]="existUserFlag" disableOptionCentering>
                              <mat-option *ngFor="let country of clntCountryList" value={{country.country_desc}}>
                                {{country.country_desc}}</mat-option>
                            </mat-select>
                          </mat-label>
                        </div>

                        <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                          <mat-label class="matlabel"> Referred by<br>
                            <input type="text" class="ipcss widthappt" maxlength="50" (keyup)="getReferral($event)"
                              [(ngModel)]="referredDoctor" matInput [matAutocomplete]="auto" />
                            <mat-autocomplete #auto="matAutocomplete">
                              <mat-option (click)="selectReferredDoctor(item)" *ngFor="let item of referredDoctorList"
                                value={{item}}>
                                {{item}}
                              </mat-option>
                            </mat-autocomplete>
                          </mat-label>
                        </div>

                        
                      </div>
                    </div>
                  </div>
                  <div class="cover_div" style="padding-bottom: 13px;" [hidden]="pay_flag">
                    <div class="header_lable">
                      Payment
                    </div>
                    <div class="content_cover">
                      <div class="row">
                        <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="pay_flag">
                          <mat-label class="matlabel">Amount <br>
                            <input class="ipcss  widthbillcreate" matInput [(ngModel)]="billamount" />
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="pay_flag">
                          <mat-label class="matlabel">Payment type<br>
                            <select class="ipcss " [(ngModel)]="payType" (change)="changePayType()"
                              disableOptionCentering>
                              <option *ngFor="let pay of payTypeList" value={{pay.pay_id}}>{{pay.pay_desc}}</option>
                            </select>
                          </mat-label>
                        </div>
                        <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="insurFlag">
                          <mat-label class="matlabel" [hidden]="insurFlag">Insurer<br>
                            <select required class="ipcss " [(ngModel)]="insurerId" disableOptionCentering>
                              <option *ngFor="let ins of insurerList" value={{ins.insurer_id}}>{{ins.insurer_desc}}
                              </option>
                            </select>
                          </mat-label>
                        </div>
                        <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="cardFlag">
                          <mat-label class="matlabel">Card No <br>
                            <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" />
                          </mat-label>
                        </div>
                        <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="cardFlag">
                          <mat-label class="matlabel">Holder name<br>
                            <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardHolderName" />
                          </mat-label>
                        </div>
                        <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="transactionFlag">
                          <mat-label class="matlabel">Transaction ID<br>
                            <input class="ipcss  widthbillcreate" matInput [(ngModel)]="transactionId" />
                          </mat-label>
                        </div>
                        <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="chequeFlag">
                          <mat-label class="matlabel">Cheque No <br>
                            <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" />
                          </mat-label>
                        </div>
                        <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom" [hidden]="chequeFlag">
                          <mat-label class="matlabel">Bank Name <br>
                            <input class="ipcss  widthbillcreate" matInput [(ngModel)]="bankName" />
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3"></div>
                        <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3"></div>
                      </div>
                    </div>
                  </div>
                  <div class="cover_div" style="padding-bottom: 13px;">
                    <div class="header_lable">
                      Observations
                    </div>
                    <div class="content_cover">
                      <div class="row">
                        <div class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2">
                          <mat-label class="matlabel">Height
                            <input class="ipcss " (change)="calculateCalories()" (keyup)="updateUserprofile()"
                              [(ngModel)]="height" (keydown)=(validate($event)) matInput />
                          </mat-label>
                        </div>
                        <div class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2">
                          <mat-label class="matlabel">Measure<br>
                            <select class="ipcss widthch1" [(ngModel)]="hmeasure" (change)="calculateCalories()">
                              <option value="cms">cms</option>
                              <option value="inch">inch</option>
                            </select>
                          </mat-label>
                        </div>
                        <div class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2">
                          <mat-label class="matlabel">Weight<br>
                            <input class="ipcss widthch1" (change)="calculateCalories()" (keyup)="updateUserprofile()" 
                              [(ngModel)]="weight" (keydown)=(validate($event)) matInput />
                          </mat-label>
                        </div>
                        <div class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2">
                          <mat-label class="matlabel">Measure<br>
                            <select class="ipcss widthch1" [(ngModel)]="wmeasure" (change)="calculateCalories()">
                              <option value="kgs">kgs</option>
                              <option value="lbs">lbs</option>
                            </select>
                          </mat-label>
                        </div>
                        <div class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2">
                          <mat-label class="matlabel">BMI
                            <input class="ipcss " [(ngModel)]="bmi" disabled matInput />
                          </mat-label>
                        </div>
                        
                        <div class="col-2">
                          <mat-label class="matlabel">Temperature
                            <input matInput class="ipcss noappt_width" [(ngModel)]="temperature"
                              (keydown)=(validate($event))>
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-4 col-md-3 col-lg-4 col-xl-4" [hidden]="bloodPressureFlag">
                          <mat-label class="matlabel">Blood pressure</mat-label>
                          <div class="row" [hidden]="bloodPressureFlag">
                            <div class=" col-6" [hidden]="bloodPressureFlag">
                              <input type="text" [hidden]="bloodPressureFlag" class="ipcss widthch1" placeholder="mm Hg"
                                maxlength="3" [(ngModel)]="BP01" (keyup)="updateUserprofile()"
                                onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                            </div>
                            <div class="col-6" [hidden]="bloodPressureFlag">
                              <input type="text" [hidden]="bloodPressureFlag" class="ipcss widthch1" placeholder="mm Hg"
                                maxlength="3" [(ngModel)]="BP02" (keyup)="updateUserprofile()"
                                onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                            </div>
                          </div>
                        </div>
                      <div class="col-2">
                        <mat-label class="matlabel">SPO2</mat-label>
                        <input matInput class="ipcss inpat_width" [(ngModel)]="spo_txt">
                      </div>
                      <div class="col-2">
                        <mat-label class="matlabel">Pulse</mat-label>
                        <input matInput class="ipcss inpat_width" [(ngModel)]="pulse_txt">
                      </div>
                      <div class="col-2">
                        <mat-label class="matlabel">CVS<br>
                          <input matInput class="ipcss " placeholder="cvs" [(ngModel)]="cvs_txt">
                        </mat-label>
                      </div>
                      <div class="col-2">
                        <mat-label class="matlabel">RS<br>
                          <input matInput class="ipcss " placeholder="rs" [(ngModel)]="rstxt">
                        </mat-label>
                      </div>
                      <div class="col-2">
                        <mat-label class="matlabel">CNS<br>
                          <input matInput class="ipcss " placeholder="cns" [(ngModel)]="cns_txt">
                        </mat-label>
                      </div>
                      <div class="col-2">
                        <mat-label class="matlabel">ABD<br>
                          <input matInput class="ipcss " placeh older="ABD" [(ngModel)]="abd_txt">
                        </mat-label>
                      </div>
                      </div>
                    </div>
                  </div>
                  <!--  -->
                  
                  <div class="cover_div" style="padding-bottom: 13px;">
                    <div class="header_lable">
                      Charges
                    </div>
                    <div class="content_cover">
                      <div class="row">
                        <div class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2">
                          <mat-label class="matlabel">Height
                            <input class="ipcss " (change)="calculateCalories()" (keyup)="updateUserprofile()"
                              [(ngModel)]="height" (keydown)=(validate($event)) matInput />
                          </mat-label>
                        </div>
                        <div class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2">
                          <mat-label class="matlabel">Measure<br>
                            <select class="ipcss widthch1" [(ngModel)]="hmeasure" (change)="calculateCalories()">
                              <option value="cms">cms</option>
                              <option value="inch">inch</option>
                            </select>
                          </mat-label>
                        </div>
                        <div class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2">
                          <mat-label class="matlabel">Weight<br>
                            <input class="ipcss widthch1" (change)="calculateCalories()" (keyup)="updateUserprofile()" 
                              [(ngModel)]="weight" (keydown)=(validate($event)) matInput />
                          </mat-label>
                        </div>
                        <div class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2">
                          <mat-label class="matlabel">Measure<br>
                            <select class="ipcss widthch1" [(ngModel)]="wmeasure" (change)="calculateCalories()">
                              <option value="kgs">kgs</option>
                              <option value="lbs">lbs</option>
                            </select>
                          </mat-label>
                        </div>
                        <div class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-2">
                          <mat-label class="matlabel">BMI
                            <input class="ipcss " [(ngModel)]="bmi" disabled matInput />
                          </mat-label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--  -->
                  <!-- <div class="cover_div" style="padding-bottom: 13px;">
                    <div class="header_lable">
                      Bill create
                    </div>
                    <div class="content_cover">
                      <div class="row">
                        <div class="col-12 col-lg-12 col-md-12 col-xl-12">
                          <div *ngIf="billAddList.length">
                            <div class="dig_table_overflow">
                              <div class="table-responsive">
                                <table class="table table-hover table-dynamic table-sm bill_edit_table" style="margin-bottom: 0px;">
                                  <thead>
                                    <tr style="height: 25px !important;">
                                      <th style="width: 10%;">Sno</th>
                                      <th style="width: 20%">Service Charges<th> 
                                      <th [hidden]="paytypreq" style="width: 5%">Payment type</th>
                                      <th style="width: 5%">Rate</th>
                                      <th [hidden]="discount_flag" style="width: 5%">Discount(%)</th>
                                      <th [hidden]="bill_qty_flag" style="width: 2%">Quantity</th>
                                      <th [hidden]="bill_qty_flag && discount_flag" style="width: 6%">Amount</th>                     
                                      <th style="width: 10%">Delete</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let person of billAddList" class="tdvalues">
                                      <td style="text-align: center;width: 10%;">{{person.sno}}</td> 
                                      <td>
                                        <input type="text" class="ipcss order_width getChargeDesc" style="font-size: 12px !important;" required maxlength="50"
                                          (keyup)="person.biill_type && person.biill_type.length >=2 && handleKeyUp($event,person.biill_type,person)" maxlength="50" [(ngModel)]="person.biill_type"
                                          matInput [matAutocomplete]="auto2" [disabled]="person.edit_flag" />
                                          <mat-autocomplete #auto2="matAutocomplete" [panelWidth]="'auto'">
                                            <mat-option (click)="changeChargeType1(charge,person)" *ngFor="let charge of serviceType"
                                              value="{{charge.description}}"> {{charge.description}}                                          
                                            </mat-option>
                                          </mat-autocomplete>
                                      </td>
                                      
                                      <td [hidden]="paytypreq" style="width: 5%;">{{person.pay_type}}</td>
                                      <td [hidden]="!discount_flag" style="text-align: right;width: 5%;">{{person.amount_txt}}</td> 
                                      <td [hidden]="discount_flag" style="text-align: center;" >
                                        <input type="text" class="bill_edit_td" style="width: 75%;" 
                                        [(ngModel)]="person.amount_txt"
                                        #matInput (keyup)="changeamount1(person,0)" /> 
                                      </td>
                                      <td [hidden]="discount_flag">                       
                                        <input type="text" class="bill_edit_td" style="width: 75%;" 
                                        [(ngModel)]="person.concession"
                                        #matInput (keyup)="changeamount1(person,0)" /> 
                                      </td> 
                                      <td [hidden]="bill_qty_flag">                       
                                        <input type="text" class="bill_edit_td" style="width: 75%;" 
                                        [(ngModel)]="person.quantity"
                                        #matInput (keyup)="changeamount1(person,0)" /> 
                                      </td> 
                                      <td [hidden]="bill_qty_flag || !discount_flag" style="text-align: right !important;width: 6%;">{{person.total}}</td> 
                                      <td [hidden]="discount_flag" style="text-align: right !important;width: 6%;">
                                        <input type="text" class="bill_edit_td" style="width: 75%;" [(ngModel)]="person.fee" (keyup)="changeamount1(person,2)"
                                        #matInput />
                                      </td>                                        
                                      <td class="delete" style="width: 10%;">                         
                                        <a (click)="deleteHospitalBill(person)"> <img
                                            src="../../../assets/ui_icons/buttons/trash_icon.svg" class="billing_edit"></a>
                                      </td>  
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>