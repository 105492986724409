import { Component, OnInit } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { MatDialog } from '@angular/material/dialog';
import { CommonDataService } from '../../providers/common-data.service';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { Diagnosis_Helper } from '../Diagnosis_Helper';
import { ipaddress } from '../../ipaddress';
import { Message_data } from '../../../assets/js/Message_data';
import { MenuViewService } from '../../../app/common-module/menu-view/menu-view.service';

@Component({
  selector: 'app-diag-testandpackages-adv',
  templateUrl: './diag-testandpackages-adv.component.html',
  styleUrls: ['./diag-testandpackages-adv.component.scss']
})
export class DiagTestandpackagesAdvComponent implements OnInit {
  public User_type;
  public Diag_Location_Data: any = [];
  public Diag_Test_list_Data = [];
  public Location_txt;
  public createcentre_id;
  public header_footer_flag: boolean;
  public loader: boolean;
  public user_diag;
  public user_name;
  public userinfo;
  public ipaddress;
  public location;
  public ynopt1: boolean = true;
  public test_flag: boolean = false;
  public center_id: string;
  public packageData: any = [];
  public Foradmin: boolean = false;
  //mdbTable
  dtOptions: DataTables.Settings = {};
  dtOptions1: DataTables.Settings = {};

  // modules 
  public newbutton: boolean = false
  public editbutton: boolean = false;
  public deletebutton: boolean = false;
  public printbutton: boolean = false;
  public viewbutton: boolean = false;
  public moduleList: any = [];

  public diag_dept: any;
  public diag_test_categ: any;
  public department_data: any = [];
  test_category_data: any = [];
  previous_order_no: any;
  order_no: any;
  public diag_centre_id: string;
  public existsFlag: boolean;
  all_flag: boolean = true;

  constructor(public diagnosisservice: MenuViewService, public toastr: ToastrService, public gservice: CommonDataService, public http: Http, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.moduleList = Helper_Class.getmodulelist();

    if (this.moduleList != undefined) {
      for (var i = 0; i < this.moduleList.length; i++) {
        if (this.moduleList[i].module_id == "102") {
          console.log(JSON.stringify(this.moduleList[i]));
          if (this.moduleList[i].edit == "1") {
            this.editbutton = true;//update button
          }
          if (this.moduleList[i].create == "1") {
            this.newbutton = true;
          }
          if (this.moduleList[i].delete == "1") {
            this.deletebutton = true;
          }
          if (this.moduleList[i].print == "1") {
            this.printbutton = true;
          }
          if (this.moduleList[i].view == "1") {
            this.viewbutton = true;
          }
        }
      }
    }

    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      "lengthMenu": [[-1, 25, 50], ["All", 25, 50]],
      "order": [[2, 'asc']],
      "columnDefs": [
        { "orderable": false, "targets": [2] },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search by test name or category or type"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    }

    this.dtOptions1 = {
      pagingType: 'simple_numbers_no_ellipses',
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      "order": [[0, 'asc']],
      "columnDefs": [
        { "orderable": false, "targets": [3] },
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search by package name or price or description"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };

    this.ipaddress = Helper_Class.getIPAddress();
    this.userinfo = Helper_Class.getInfo();
    if (this.userinfo.user_type == "Admin") {
      this.diag_centre_id = this.userinfo.diag_center_id;
      this.center_id = this.userinfo.diag_center_id;
    } else if (this.userinfo.user_type == "diagnosis") {
      this.diag_centre_id = this.userinfo.hospitals[0].hptl_clinic_id;
      this.center_id = this.userinfo.hospitals[0].hptl_clinic_id;
    }
    //this.getLocation();
    this.getDiagDepartment();

  }

  // getLocation() {
  //   this.Diag_Location_Data = [];
  //   if (Helper_Class.getInfo().user_type == 'Admin') {
  //     this.center_id = this.diag_centre_id;
  //     this.User_type = "diag_user";
  //     this.user_name = this.userinfo.user_id;
  //     this.Location_txt = this.center_id;
  //     // this.getTestList();
  //   } else {
  //     if (Diagnosis_Helper.getUserID_Type().user_type != null) {
  //       this.User_type = "diag_user";
  //       this.user_name = this.userinfo.user_id;
  //       this.user_diag = undefined;

  //     } else {
  //       this.User_type = "diagnosis";
  //       this.user_name = this.userinfo.user_id;
  //     }
  //   }
  //   var headers = new Headers();
  //   headers.append('Content-Type', 'application/json');
  //   this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/diagloc/',
  //     JSON.stringify({
  //       diag_name: this.user_name,
  //       flag: "id",
  //       imei: this.ipaddress,
  //       prov_id: this.userinfo.user_id,
  //       type: this.User_type,
  //     }),
  //     { headers: headers })
  //     .subscribe(
  //       response => {
  //         var obj = response.json();
  //         for (var i = 0; i < obj.diag_loc.length; i++) {
  //           this.Diag_Location_Data.push({
  //             centre_id: obj.diag_loc[i].diag_centre_id,
  //             centre_name: obj.diag_loc[i].diag_centre_name,
  //             centre_location: obj.diag_loc[i].diag_loc,
  //             view_data: obj.diag_loc[i].diag_centre_name + " - " + obj.diag_loc[i].diag_loc
  //           });
  //         }

  //         this.center_id = this.Diag_Location_Data[0].centre_id;
  //         // this.getTestList();

  //         this.Location_txt = this.Diag_Location_Data[0].view_data;
  //       }, error => {
  //         this.toastr.error(Message_data.error);
  //       });
  // }

  changeDigCenter(data) { //based on location det appointmnets
    // if (this.Diag_Location_Data != undefined) {
    //   for (var i = 0; i < this.Diag_Location_Data.length; i++) {
    //     if (this.Diag_Location_Data[i].view_data == data) {
    //       this.center_id = this.Diag_Location_Data[i].centre_id;
    //       this.getTestList();

    //     }
    //   }
    // }
  }

  getTestList() {//Get tests
    if(this.diag_test_categ == 'All'){
      this.all_flag = true;
    } else {
      this.all_flag = false;
    }
    this.loader = false
    this.Diag_Test_list_Data = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/ltbyid/',
      JSON.stringify({
        diag_id: this.diag_centre_id,
        dept_id: this.diag_dept,
        subdept_id: this.diag_test_categ,
        flag:'1',
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          console.log("test List obj", JSON.stringify(obj))
          if (obj.diag_tests != null) {
            this.existsFlag = false;
            this.loader = true

            var id;
            for (var i = 0; i < obj.diag_tests.length; i++) {
              if (obj.diag_tests[i].test_order != undefined) {
                id = obj.diag_tests[i].test_order
              } else {
                id = i + 1
              }

              var status;
              if (obj.diag_tests[i].status != undefined) {
                if (obj.diag_tests[i].status == '1') {
                  status = true
                } else {
                  status = false
                }
              }

              this.Diag_Test_list_Data.push({
                id: i + 1,
                diag_tests_id: obj.diag_tests[i].diag_tests_id,
                test_name: obj.diag_tests[i].test_name,
                test_category: obj.diag_tests[i].test_category,
                diag_department_id: obj.diag_tests[i].diag_department_id,
                diag_dept_desc: obj.diag_tests[i].diag_dept_desc,
                sample_req: obj.diag_tests[i].sample_req,
                status: status,
                conduction_desc: obj.diag_tests[i].conduction_desc, collectTAT: obj.diag_tests[i].collectTAT,
                assertTAT: obj.diag_tests[i].assertTAT,
                uploadTAT: obj.diag_tests[i].uploadTAT,
                approveTAT: obj.diag_tests[i].approveTAT,
                price: obj.diag_tests[i].price,
                test_order: obj.diag_tests[i].test_order,
                totalTAT: obj.diag_tests[i].totalTAT
              })
            }
            // this.Diag_Test_list_Data = obj.diag_tests;
          } else {
            this.existsFlag = true;
          }
          console.log("this.Diag_Test_list_Data", JSON.stringify(this.Diag_Test_list_Data))
        }, error => { });
  }

  testDelete(test_id) {//delete tests
    if (test_id != null) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/cbyid/',
        JSON.stringify({
          diag_id: test_id
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            if (obj != null && obj.key == "1") {
              this.toastr.success(Message_data.testCancelledSuccess);
              if (this.Diag_Location_Data != undefined) {
                for (var i = 0; i < this.Diag_Location_Data.length; i++) {
                  if (this.Diag_Location_Data[i].view_data == this.Location_txt) {
                    this.center_id = this.Diag_Location_Data[i].centre_id;
                    this.getTestList();
                  }
                }
              }
              this.diagnosisservice.sendMessage('testsandpackadv');
            }
            else {
              if (obj.result != null)
                this.toastr.success(obj.result);
              else
                this.toastr.error(Message_data.testNotDeleted);
            }
          }, error => {
            this.toastr.error(Message_data.error);
          });
    }
  }

  testCreate() { //create test
    if (this.test_flag == false) {
      var diag_test = {
        diagnosis_id: this.center_id,
      }
      Diagnosis_Helper.setdiagnosistest(diag_test);
      this.diagnosisservice.sendMessage('diagtestadd');

    } else {
      var send_data = {
        diagnosis_id: this.center_id,
        is_new: "1",
      };

      Diagnosis_Helper.setdiagnosistest(send_data);
      this.diagnosisservice.sendMessage('diagPackageCreate');

    }

  }

  testView(test_id, status, testorder) {//diag test view
    // if (this.Diag_Location_Data != undefined) {
    //   for (var i = 0; i < this.Diag_Location_Data.length; i++) {
    //     if (this.Diag_Location_Data[i].view_data == this.Location_txt) {
    //       this.createcentre_id = this.Diag_Location_Data[i].centre_id;
    //     }
    //   }
    // }

    // if (this.Location_txt != undefined) {

    var status;
    if (status == true) {
      var diag_test = {
        diagnosis_id: this.diag_centre_id,
        Diag_test_id: test_id,
        test_order: testorder,
      }
      Diagnosis_Helper.setdiagnosistest(diag_test);
      this.diagnosisservice.sendMessage('diagtestadd');
      console.log(diag_test)
    } else {
      this.toastr.error("Activate the test to edit", null, { timeOut: 3000 });
    }
    // } else {
    //   this.toastr.error(Message_data.sltDiagCenter);
    // }
  }

  change_type(id, value) {
    if (value == "test") {
      this.ynopt1 = true;
      this.test_flag = false;
      this.getTestList();
    } else {
      this.ynopt1 = false;
      this.test_flag = true;
      this.getPackageList();
      //this.filterClick();
    }
  }

  getPackageList() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "diagnosiscontroller/gpd",
      {
        diag_centre_id: this.center_id
      }, { headers: headers }).subscribe(response => {

        var obj = JSON.parse(response["_body"]);
        if (obj.profiles != undefined && obj.profiles.length != 0) {
          this.packageData = obj.profiles;

        }
        console.log(obj);

      }, error => { });
  }

  packageView(data) {

  }

  packageEdit(data) {
    var send_data = {
      diagnosis_id: this.center_id,
      is_new: "0",
      diag_package_id: data.diag_profile_id,
      pkg_name: data.profile_name,
      pkg_desc: data.package_desc,
      pkg_price: data.price,
      pack_data: data,
    };
    Diagnosis_Helper.setdiagnosistest(send_data);
    this.diagnosisservice.sendMessage('diagPackageCreate');
  }

  packageDelete(data) {
    this.loader = false
    this.Diag_Test_list_Data = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/dpkgd/',
      JSON.stringify({
        diag_packages_id: data
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          if (obj.key != null && obj.key == "1") {
            this.toastr.success(obj.result);
            this.getPackageList();
          } else {
            this.toastr.error("Something went wrong, try again later")
          }
        }, error => { });
  }

  getDiagDepartment() {
    this.department_data = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'mas/gdd', JSON.stringify({ diag_centre_id: this.diag_centre_id }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          console.log("getDiagDepartment", obj)
          var dept_data = obj.diag_dept_list;

          if (obj.diag_dept_list.length != 0) {
            for (var i = 0; i < obj.diag_dept_list.length; i++) {
              this.department_data.push({
                diag_department_id: dept_data[i].diag_department_id,
                diag_dept_desc: dept_data[i].diag_dept_desc,
                status: dept_data[i].status,
              })
            }
            this.diag_dept = this.department_data[0].diag_department_id;
            this.getTestCategory(this.diag_dept, '0');
            console.log("getDiagDepartment", this.department_data)
          }
        }, error => { });
  }

  getTestCategory(deptId, categ_id) {//get disease cateegory
    this.test_category_data = [];

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/dtcatn',
      JSON.stringify({
        diag_centre_id: this.diag_centre_id,
        diag_dept_id: deptId
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          console.log("getTestCategory - obj", obj)
          this.test_category_data = obj.test_categories;
          this.previous_order_no = obj.test_order;
          this.order_no = obj.test_order;
          this.diag_test_categ = "All";
          
          // if (deptId == "All")
          //   this.diag_test_categ = "All";
          // else
          //   this.diag_test_categ = this.test_category_data[0].diag_test_cat_id;

          this.getTestList();

          console.log('this.test_category_data', obj)
        }, error => { });
  }


  onDragStart(event: DragEvent, row: any) {
    if(this.all_flag == false){
      event.dataTransfer.setData('text/plain', row.id.toString());
    } else {
      this.toastr.error("Select specific sub-department to change order");
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  onDrop(event: DragEvent, index: number) {
    if(this.all_flag == false){
      const draggedRowId = +event.dataTransfer.getData('text/plain');
    const draggedRowIndex = this.Diag_Test_list_Data.findIndex(row => row.id === draggedRowId);

    if (draggedRowIndex > -1) {
      const [draggedRow] = this.Diag_Test_list_Data.splice(draggedRowIndex, 1);
      this.Diag_Test_list_Data.splice(index, 0, draggedRow);
    }
    this.saveTestOrder();
    }
  }

  saveTestOrder() {
    var test_order_arr = [];

    for (var i = 0; i < this.Diag_Test_list_Data.length; i++) {
      test_order_arr.push({
        test_order: i + 1,
        test_id: this.Diag_Test_list_Data[i].diag_tests_id
      })
    }

    console.log("test_order_arr", test_order_arr)

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/sdto',
      JSON.stringify({ diag_centre_id: Helper_Class.getInfo().hospitals[0].hptl_clinic_id, test_list: test_order_arr }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          console.log("getTestCategory - obj", obj)

        }, error => { });
  }

  testStatus(data, test_id) {
    console.log("toggle", data)

    var type
    if (this.test_flag == true) {
      type = "profile"
    } else {
      type = "test"
    }

    var status;
    if (data == true) {
      status = '1'
    } else {
      status = '0'
    }

    if (test_id != null) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/udtp/',
        JSON.stringify({
          type: type,
          status: status,
          test_id: test_id,
          diag_centre_id: Helper_Class.getInfo().hospitals[0].hptl_clinic_id,
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            console.log(obj)

          }, error => {
            this.toastr.error(Message_data.error);
          });
    }
  }
}
