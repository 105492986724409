<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">
                            Weightage
                        </h5>
                    </div>
                    <div class="headerButtons">
                        <!-- <img src="../../../assets/ui_icons/buttons/back_icon.svg" style="width:30px;margin-right: 5px;"
                            (click)="todisplayassignedproduct()" *ngIf="backbutton"  /> -->
                        <img src="../../../assets/ui_icons/buttons/save_button.svg" class="saveimgbtn_inpatinfo"
                            (click)="saveSupplerrating()" />
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="column select" >

                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_box">
                        <mat-label class="matlabel">Price<br>
                            <select disableOptionCentering class="ipcss " [(ngModel)]="price"
                                (change)="validateTotal('price',price)">
                                <option disabled>select</option>
                                <option *ngFor="let price of rateingarray" [value]="price">
                                    {{price}}
                                </option>
                            </select>
                        </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_box">
                        <mat-label class="matlabel">Delivery <br>
                            <select disableOptionCentering class="ipcss " [(ngModel)]="delivery"
                                (change)="validateTotal('delivery',delivery)">
                                <option disabled>select</option>
                                <option *ngFor="let delivery of rateingarray" [value]="delivery">
                                    {{delivery}}
                                </option>
                            </select>
                        </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_box">
                        <mat-label class="matlabel">Short expiry <br>
                            <select disableOptionCentering class="ipcss " [(ngModel)]="short_exp"
                                (change)="validateTotal('short_exp',short_exp)">
                                <option disabled>select</option>
                                <option *ngFor="let short_exp of rateingarray" [value]="short_exp">
                                    {{short_exp}}</option>
                            </select>
                        </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_box">
                        <mat-label class="matlabel">Damage <br>
                            <select disableOptionCentering class="ipcss " [(ngModel)]="Damage"
                                (change)="validateTotal('damage',Damage)">
                                <option disabled>select</option>
                                <option *ngFor="let damage of rateingarray" [value]="damage">
                                    {{damage}}</option>
                            </select>
                        </mat-label>
                    </div>

                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>