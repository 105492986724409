<app-header></app-header>
<div class="banner-section-bg d-flex flex-column justify-content-center">
   <div class="px-5 ">
      <h1 class="banner-head"> Diagnostic Management system</h1>
   </div>
</div>
<div class="pt-5 pb-5">
   <div class="container">
      <div class="row">
         <div class="col-12 col-md-4">
            <img src="../../../assets/images/solutions/diagnosis-icon.jpg" class="hosp-img">
         </div>
         <div class="col-12 col-md-8">
            <p class="para">Tervys AI Diagnostic Management System is a software solution that leverages artificial
               intelligence (AI) technologies to assist healthcare providers in diagnosing and managing medical
               conditions. The system uses machine learning algorithms to analyze patient data, identify potential
               diagnoses, and recommend treatment plans.
               <br>
               <br>
               The Tervys AI Diagnostic Management System provides healthcare providers with real-time decision support,
               streamlines clinical workflows, and improves patient outcomes. By harnessing the power of machine
               learning and other AI technologies, this system can revolutionize how healthcare providers diagnose and
               manage medical conditions, leading to better patient outcomes and more efficient and effective care
               delivery.
            </p>
         </div>
      </div>
   </div>
</div>
<div class="wcu-section pt-5 pb-5" id="wcuSection">
   <div class="container">
      <div class="row justify-content-center">
         <div class="text-center pt-3 pb-3">
            <h1 class="wcu-heading" style="font-size: 30px;">Key features and benefits of Gritsys AI diagnostic
               management system</h1>
         </div>
         <div class="col-12 col-md-4">
            <div class="wcu-card text-center">
               <img src="../../../assets/images/solutions/accurate-diagnostic.png" class="wcu-card-icon" />
               <h2 class="wcu-heading pt-3">Faster and accurate diagnoses</h2>
               <p class="wcu-para">AI diagnostic systems rapidly analyze large patient data, enabling quicker, more
                  accurate diagnoses, and identifying rare or complex conditions that may be missed by human providers.
               </p>
            </div>
         </div>
         <div class="col-12 col-md-4">
            <div class="wcu-card text-center">
               <img src="../../../assets/images/solutions/planning.png" class="wcu-card-icon" />
               <h2 class="wcu-heading pt-3">Personalized treatment plans</h2>
               <p class="wcu-para">AI systems create personalized treatment plans tailored to a patient's health profile
                  and history, improving outcomes and reducing the risk of adverse events.</p>
            </div>
         </div>
         <div class="col-12 col-md-4">
            <div class="wcu-card text-center">
               <img src="../../../assets/images/solutions/decision-making.png" class="wcu-card-icon" />
               <h2 class="wcu-heading pt-3">Enhanced decision-making</h2>
               <p class="wcu-para">AI systems offer real-time insights and decision support, helping providers make
                  informed choices based on the latest medical research and guidelines for optimal patient care.</p>
            </div>
         </div>
         <div class="col-12 col-md-4">
            <div class="wcu-card text-center">
               <img src="../../../assets/images/solutions/efficiency.png" class="wcu-card-icon" />
               <h2 class="wcu-heading pt-3">Improved efficiency and productivity</h2>
               <p class="wcu-para">AI diagnostic systems streamline workflows, reduce administrative tasks, and automate
                  documentation, enabling healthcare providers to save time and focus on delivering high-quality patient
                  care.</p>
            </div>
         </div>
         <div class="col-12 col-md-4">
            <div class="wcu-card text-center">
               <img src="../../../assets/images/solutions/patientengage.png" class="wcu-card-icon" />
               <h2 class="wcu-heading pt-3">Patient engagement and satisfaction</h2>
               <p class="wcu-para">AI diagnostic systems enhance patient engagement and satisfaction through
                  personalized treatment plans and remote access to health information and communication with providers.
               </p>
            </div>
         </div>
      </div>
   </div>
</div>

<app-footer></app-footer>