import { Component, OnInit, HostListener, Input } from '@angular/core';
import {Helper_Class} from '../../helper_class';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { Helper_nephro_casesheet } from '../../../assets/js/Helper_nephro_casesheet';
import { Observable, observable, Subscription } from 'rxjs';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { NephroHistoryComponent } from '../nephro-history/nephro-history.component';
import { CasesheetService } from '../casesheet/casesheet.service';
import { Doc_Helper } from '../Doc_Helper';
import { BmiandbmrService } from '../service/bmiandbmr.service';
import { ipaddress } from '../../ipaddress' ;import { Message_data } from 'src/assets/js/Message_data';

@Component({
  selector: 'app-nephrology-bmi-vitals',
  templateUrl: './nephrology-bmi-vitals.component.html',
  styleUrls: ['./nephrology-bmi-vitals.component.css']
})
export class NephrologyBmiVitalsComponent implements OnInit {
  public nephVitalsFlag ="min";
  spo2colorstyle: boolean;
  public temp_read;
  pulsecolorstyle:boolean;
  rrcolorstyle:boolean;
  public cbg_txt;
  public pulse_txt: string;
  public rr_txt;
  cbgcolorstyle:boolean;
  subscription:Subscription;
  public minflag:boolean=true;
  siscolorstyle: boolean;
  diocolorstyle: boolean;
  public colorstyle:boolean;
  public temperature : string;
  tempcolorstyle: boolean;
  public Temperature;
  public height_measure;
  public weight_measure;
  public weight_txt;
  public height_txt;
  public txt_bmi;
  public txt_bmr;
  public BP_01;
  public BP_02;
  public Heart_Beat;
  public Respiratory_rate;
  public BP_01_stand;
  public BP_02_stand;
  public Heart_Beat_stand;
  public Respiratory_rate_stand;
  public bmi_hide: boolean;
  public sitting_hide: boolean;
  public header_footer_flag:boolean;
  public heart_hidden: boolean;
  public respi_hidden: boolean;
  public weight_hidden: boolean;
  public height_hidden: boolean;
  public blood_hidden: boolean;
  public temp_hidden: boolean;
  public body_nature: string;

  public gender;
  public age;
  public client:boolean = false;
  public personaldata;
  public cvs_txt:string;
  public rstxt:string;
  public cns_txt:string;
  public abd_txt:string;
  public pr_txt:string;
  public spo2_txt:string;
  public cvs_hidden:boolean;
  public rs_hidden:boolean;
  public cns_hidden:boolean;
  public abd_hidden:boolean;
  public pr_hidden:boolean;
  constructor(public bmiservice:BmiandbmrService,public dialog: MatDialog,public messageservice:CasesheetService,private http:Http,private routes:ActivatedRoute,private router:Router,public toastr:ToastrService) { 
    this.bmi_hide = true;
    this.sitting_hide = true;
    this.heart_hidden = true;
    this.respi_hidden = true;
    this.weight_hidden = true;
    this.height_hidden = true;
    this.blood_hidden = true;    

    this.height_txt="";
    this.weight_txt ="";
    this.height_measure = "cms";
    this.weight_measure = "kgs";
    this.personaldata = [];
  }

  ngOnInit(): void {
    this.temp_read="F"
    console.log("check ---"+JSON.stringify(Helper_Class.getRet()))
    this.subscription = this.messageservice.getCasesheetMessage().subscribe(message => {
      if (message) {
          this.nephVitalsFlag = message;
      }
    });

    if(Doc_Helper.getAppFlow() == "client" ){
        this.client = true;
    }else{
      this.client = false;
      this.personaldata = Doc_Helper.getClient_Info();
    }
    console.log("checkn-------------"+JSON.stringify(this.personaldata))
    if(this.personaldata.Age_data != null && this.personaldata.Age_data != undefined && this.personaldata.Age_data != ""){
      this.age = this.personaldata.Age_data;
    }

    if(this.personaldata.Gender_data != null && this.personaldata.Gender_data != undefined && this.personaldata.Gender_data != ""){
      this.gender = this.personaldata.Gender_data;
    }

    if(this.personaldata.height != null && this.personaldata.height != undefined && this.personaldata.height != ""){
      this.height_txt = this.personaldata.height;
      this.height_measure = this.personaldata.height_measure;
      this.weight_txt = this.personaldata.weight;
      this.weight_measure = this.personaldata.weight_measure;
      if(this.personaldata.bp != undefined){
        var bp = this.personaldata.bp.split("/");
        this.BP_01 = bp[0];
        this.BP_02 = bp[1];
      }
      this.Heart_Beat =  this.personaldata.pulse;
      this.cvs_txt=this.personaldata.cvs;
      this.rstxt=this.personaldata.resp_rate;
      this.cns_txt=this.personaldata.cns;
      this.abd_txt=this.personaldata.abd;
      this.pr_txt=this.personaldata.pr;
     // this.Temperature=this.personaldata.temparature;  
      if(this.personaldata.temparature != undefined){
        var temp=this.personaldata.temparature.split(" ");
        this.Temperature = temp[0];
        this.temp_read = temp[1];
      }
      this.spo2_txt=this.personaldata.spo2;  
      this.cns_txt = this.personaldata.cns;
      this.abd_txt = this.personaldata.abd;
            //Bmi and mnr
      if (this.personaldata.bmi != null) {
        this.txt_bmi = this.personaldata.bmi;
        this.txt_bmr = this.personaldata.bmr;
      
        var datavalue=null;
        datavalue={
          txt_bmr:this.txt_bmr,
          txt_bmi:this.txt_bmi,
          height:this.height_txt,
          height_measure:this.height_measure,
          weight: this.weight_txt,
          weight_measure: this.weight_measure,
          temparature:this.Temperature+" "+this.temp_read,
          bp:this.personaldata.bp,
          pulse:this.Heart_Beat, 
          bodynatute:this.body_nature,  
          flag:"vitals"       
        }
        
        this.bmiservice.sendMessage(datavalue);
      }else{
        this.bmi_calculation();
      }
    }

    if (Helper_nephro_casesheet.nephro_get_ret != undefined) {
      if(Helper_nephro_casesheet.nephro_get_ret.age != undefined){
        this.age = Helper_nephro_casesheet.nephro_get_ret.age;
      }

      if(Helper_nephro_casesheet.nephro_get_ret.gender != undefined){
        this.gender = Helper_nephro_casesheet.nephro_get_ret.gender;
      }

      if (Helper_nephro_casesheet.nephro_get_ret.height != undefined) {
        this.height_txt = Helper_nephro_casesheet.nephro_get_ret.height;
      }
      this.bmi_history(Helper_nephro_casesheet.nephro_get_ret.case_hist_id);
      this.vital_history(Helper_nephro_casesheet.nephro_get_ret.life_style_id);

      if (Helper_nephro_casesheet.nephro_get_ret.height_measure != undefined) {
        this.height_measure = Helper_nephro_casesheet.nephro_get_ret.height_measure;
      }
      if (Helper_nephro_casesheet.nephro_get_ret.weight != undefined) {
        this.weight_txt = Helper_nephro_casesheet.nephro_get_ret.weight;
      }
      if (Helper_nephro_casesheet.nephro_get_ret.weight_measure != undefined) {
        this.weight_measure = Helper_nephro_casesheet.nephro_get_ret.weight_measure;
      }
      if (Helper_nephro_casesheet.nephro_get_ret.blood_pressure_sit != undefined) {
        var get_blood_sit = Helper_nephro_casesheet.nephro_get_ret.blood_pressure_sit.split('/');
        this.BP_01 = get_blood_sit[0];
        this.BP_02 = get_blood_sit[1];
      }
      if (Helper_nephro_casesheet.nephro_get_ret.heart_rate_sit != undefined) {
        this.Heart_Beat = Helper_nephro_casesheet.nephro_get_ret.heart_rate_sit;
      }
      if (Helper_nephro_casesheet.nephro_get_ret.respiratory_rate_sit != undefined) {
        this.Respiratory_rate = Helper_nephro_casesheet.nephro_get_ret.respiratory_rate_sit;
      }
      if (Helper_nephro_casesheet.nephro_get_ret.blood_pressure_stand != undefined) {
        var get_blood_stand = Helper_nephro_casesheet.nephro_get_ret.blood_pressure_stand.split('/');
        this.BP_01_stand = get_blood_stand[0];
        this.BP_02_stand = get_blood_stand[1];
      }
      if (Helper_nephro_casesheet.nephro_get_ret.heart_rate_stand != undefined) {
        this.Heart_Beat_stand = Helper_nephro_casesheet.nephro_get_ret.heart_rate_stand;
      }
      if (Helper_nephro_casesheet.nephro_get_ret.respiratory_rate_stand != undefined) {
        this.Respiratory_rate_stand = Helper_nephro_casesheet.nephro_get_ret.respiratory_rate_stand;
      }
      this.bmi_calculation();
    }else {
      this.BP_01_stand = "";
      this.BP_02_stand = "";
      this.Heart_Beat_stand =""
      this.Respiratory_rate_stand = "";
    }

    var getHopitalfieldsRet = Helper_Class.getHopitalfieldsRet();
    if (getHopitalfieldsRet != undefined) {
      for (var i = 0; i < getHopitalfieldsRet.length; i++) {
        if (getHopitalfieldsRet[i].page_name == "Vitals") {
          if (getHopitalfieldsRet[i].fields != undefined) {
            if (getHopitalfieldsRet[i].fields != undefined) {
              for (var j = 0; j < getHopitalfieldsRet[i].fields.length; j++) {
                if (getHopitalfieldsRet[i].fields[j].field_name == "Height") {
                  this.height_hidden = false;
                }
                if (getHopitalfieldsRet[i].fields[j].field_name == "Weight") {
                  this.weight_hidden = false;
                }
                if (getHopitalfieldsRet[i].fields[j].field_name == "Heart beat") {
                  this.heart_hidden = false;
                }
                if (getHopitalfieldsRet[i].fields[j].field_name == "Respiratory rate") {
                  this.respi_hidden = false;
                }
                if (getHopitalfieldsRet[i].fields[j].field_name == "Blood pressure") {
                  this.blood_hidden = false;
                }
              }
            }
          }
        }
      }
    }
  }

  bmi_calculation() {// BMI calculation
    if (this.height_txt == undefined || this.height_txt == "" || this.weight_txt == undefined || this.weight_txt == "") {
      this.txt_bmr = "";
      this.txt_bmi = "";
    } else {
      if (this.height_txt != undefined) {
        if (this.height_measure == "cms") {
          this.height_txt = this.height_txt.toString().replace(/[^0-9]/g, '');
        } else {
          this.height_txt = this.height_txt.toString().replace('/[^-0-9\.]/g', '');
          if (this.height_txt.indexOf('.') !== -1) {
            if (this.height_txt.split('.').length > 2) {
              this.height_txt = this.height_txt.height.slice(0, -1);
            }
          }
        }

        if (this.height_measure == "cms") {
          if (parseFloat(this.height_txt) > 300) {
            this.height_txt = this.height_txt.slice(0, -1);
          }
        } else {
          if (parseFloat(this.height_txt) > 100) {
            this.height_txt = this.height_txt.slice(0, -1);
          }
        }
      }

      if (this.weight_measure == "kgs" && this.height_measure == "inch") {
        var pound = this.weight_txt * 2.20462;
        var Height_txt = parseFloat(this.height_txt)
        var dat = pound / (Height_txt * Height_txt);
        var to = dat * 703;
        this.txt_bmi = Math.round(to);

        if (this.gender == "Male") {
          var get_bmr = (10 * this.weight_txt) + (6.25 * (this.height_txt * 2.54)) - (5 * this.age) + 5;
          this.txt_bmr = Math.round(get_bmr);
        } else if (this.gender == "Female") {
          var get_bmr = (10 * this.weight_txt) + (6.25 * (this.height_txt * 2.54)) - (5 * this.age) - 161;
          this.txt_bmr = Math.round(get_bmr);
        }
      } else if (this.weight_measure == "kgs" && this.height_measure == "cms") {
        var meter = parseFloat(this.height_txt) / 100.00;
        var to = parseFloat(this.weight_txt) / (meter * meter);
        this.txt_bmi = Math.round(to);

        if (this.gender == "Male") {
          var get_bmr = (10 * this.weight_txt) + (6.25 * this.height_txt) - (5 * this.age) + 5;
          this.txt_bmr = Math.round(get_bmr);
        } else if (this.gender == "Female") {
          var get_bmr = (10 * this.weight_txt) + (6.25 * this.height_txt) - (5 * this.age) - 161;
          this.txt_bmr = Math.round(get_bmr);
        }
      } else if (this.weight_measure == "lbs" && this.height_measure == "inch") {
        var Height_txt = parseFloat(this.height_txt);
        var dat = this.weight_txt / (Height_txt * Height_txt);
        var to = dat * 703;
        this.txt_bmi = Math.round(to);

        if (this.gender == "Male") {
          var get_bmr = (10 * (this.weight_txt * 0.453592)) + (6.25 * (this.height_txt * 2.54)) - (5 * this.age) + 5;
          this.txt_bmr = Math.round(get_bmr);
        } else if (this.gender == "Female") {
          var get_bmr = (10 * (this.weight_txt * 0.453592)) + (6.25 * (this.height_txt * 2.54)) - (5 * this.age) - 161;
          this.txt_bmr = Math.round(get_bmr);
        }

      } else if (this.weight_measure == "lbs" && this.height_measure == "cms") {
        var Height_txt = parseFloat(this.height_txt);
        var inch = Height_txt * 0.393701;
        var dat = this.weight_txt / (inch * inch);
        var to = dat * 703;
        this.txt_bmi = Math.round(to);

        if (this.gender == "Male") {
          var get_bmr = (10 * (this.weight_txt * 0.453592)) + (6.25 * this.height_txt) - (5 * this.age) + 5;
          this.txt_bmr = Math.round(get_bmr);

        } else if (this.gender == "Female") {
          var get_bmr = (10 * (this.weight_txt * 0.453592)) + (6.25 * this.height_txt) - (5 * this.age) - 161;
          this.txt_bmr = Math.round(get_bmr);
        }
      }
    }
    Helper_nephro_casesheet.bmr = this.txt_bmr;
  }
  
  bmi_click() {// BMI History popup
    const dialogRef1 = this.dialog.open(NephroHistoryComponent, {
      width: '700px',
      height: '500px',
      data:{bmi_type: "bmi"}
    });
  
    dialogRef1.afterClosed().subscribe(result => {});
  }
  
  bmi_history(neph_case_hist_id) { // BMI history data from server
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'neph/bmihist/', JSON.stringify({
      case_hist_id: neph_case_hist_id,
      country: ipaddress.country_code
    })
      ,
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json();
          
          if (obj.bmi_history != null && obj.bmi_history.length != 0) {
            this.bmi_hide = false;
            Helper_nephro_casesheet.bmi_hist_array = obj.bmi_history;
          }
        },
        error => {});
  }
  
  vitals_click() {//vitals popup
    const dialogRef1 = this.dialog.open(NephroHistoryComponent, {
      width: '700px',
      height: '500px',
      data:{vitals_type: "vitals"}
    });
  
    dialogRef1.afterClosed().subscribe(result => {});
  }

  vital_history(get_id) {// vital history data from server
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'neph/vithist/', JSON.stringify({
      life_style_id: get_id,
      country: ipaddress.country_code
    }),{ headers: headers }).subscribe(
        response => {
          var obj = response.json();
          
          if (obj.vital_history != null && obj.vital_history.length != 0) {
            this.sitting_hide = false;
            Helper_nephro_casesheet.vitals_sit_array = obj.vital_history;
          }
        },
        error => {});
  }

  @HostListener('window:blur', ['$event'])
  onBlur(event: any): void {
    this.send_data();
  }

  @HostListener('keyup', ['$event']) keyup(event: any): void {
    this.send_data();
  }

  // Data capture and attach to casesheet save array
  send_data() {
    if (this.height_hidden == false) {
      Helper_nephro_casesheet.neph_ht = this.height_txt;
      Helper_nephro_casesheet.neph_ht_measure = this.height_measure;
    } else {
      Helper_nephro_casesheet.neph_ht = "0";
      Helper_nephro_casesheet.neph_ht_measure = null;
    }

    if (this.weight_hidden == false) {
      Helper_nephro_casesheet.neph_wt = this.weight_txt;
      Helper_nephro_casesheet.neph_wt_measure = this.weight_measure;
    } else {
      Helper_nephro_casesheet.neph_wt = "0";
      Helper_nephro_casesheet.neph_wt_measure = null;
    }

    var vitalhist ={
      height: this.height_txt,
      height_measure: this.height_measure,
      weight: this.weight_txt,
      weight_measure: this.weight_measure,
      bmi: this.txt_bmi,
      bmr: this.txt_bmr,
    }

    if (this.blood_hidden == false) {
      Helper_nephro_casesheet.blood_pressure_sit = this.BP_01 + "/" + this.BP_02;
      Helper_nephro_casesheet.blood_pressure_stand = this.BP_01_stand + "/" + this.BP_02_stand;
    } else {
      Helper_nephro_casesheet.blood_pressure_sit = "0/0";
      Helper_nephro_casesheet.blood_pressure_stand = "0/0";
    }
    
    if (this.heart_hidden == false) {
      Helper_nephro_casesheet.heart_beat_sit = this.Heart_Beat;
      Helper_nephro_casesheet.heart_beat_stand = this.Heart_Beat_stand;
    } else{
      Helper_nephro_casesheet.heart_beat_sit = "0";
      Helper_nephro_casesheet.heart_beat_stand = "0";
    }

    if(this.respi_hidden==false) {
      Helper_nephro_casesheet.res_rate_sit = this.Respiratory_rate;
      Helper_nephro_casesheet.res_rate_stand = this.Respiratory_rate_stand;
    } else{
      Helper_nephro_casesheet.res_rate_sit = "0";
      Helper_nephro_casesheet.res_rate_stand =  "0";
    }
    Helper_Class.setDiabHist(vitalhist);
  }

  save_click() {
    var chk_read_flag = true;
    var Send_data = null;
    var pedia_case_hist_id = undefined;
    var bpvar: any;
    var lif_stl_id = undefined;

    if (this.height_txt == undefined) {
      chk_read_flag = false;
      this.toastr.error(Message_data.mandatory );
    }

    if (this.height_measure == undefined) {
      chk_read_flag = false;
      this.toastr.error(Message_data.mandatory );
    }

    if (this.weight_txt == undefined) {
      chk_read_flag = false;
      this.toastr.error(Message_data.mandatory );
    }

    if (this.weight_measure == undefined) {
      chk_read_flag = false;
      this.toastr.error(Message_data.mandatory );
    }

    if (Helper_Class.getRet() != undefined) {
      if (Helper_Class.getRet().life_style_id != null) {
        lif_stl_id = Helper_Class.getRet().life_style_id;
      }
      if (Helper_Class.getRet().case_hist_id != null) {
        pedia_case_hist_id = Helper_Class.getRet().case_hist_id;
      }
    }

    if (this.BP_01 != undefined && this.BP_02 != undefined) {
      bpvar = this.BP_01 + "/" + this.BP_02;
    } else {
      bpvar = null;
    }

    if (this.personaldata.sub_id != "" && this.personaldata.sub_id.length != 0) {
      Send_data = {
        client_reg_id: this.personaldata.Client_id,
        doc_app_id: this.personaldata.app_id,
        relation_id: this.personaldata.rel_id,
        sub_rel_id: this.personaldata.sub_id,
        life_style_id: lif_stl_id,
        country: ipaddress.country_code,
        height: this.height_txt,
        height_measure: this.height_measure,
        weight: this.weight_txt,
        weight_measure: this.weight_measure,
        blood_pressure: bpvar,
        heart_rate: this.Heart_Beat,
        pulse: this.Heart_Beat,
        temparature:this.Temperature+" "+this.temp_read,
        case_hist_id: pedia_case_hist_id,
        cvs:this.cvs_txt,
        rstxt:this.rstxt,
        cns_txt:this.cns_txt,
        abd_txt:this.abd_txt,
        pr_txt:this.pr_txt,  
        spo2:this.spo2_txt,  
      };
    }
    else {
      Send_data = {
        client_reg_id: this.personaldata.Client_id,
        relation_id: this.personaldata.rel_id,
        doc_app_id: this.personaldata.app_id,
        life_style_id: lif_stl_id,
        country: ipaddress.country_code,
        height: this.height_txt,
        height_measure: this.height_measure,
        weight: this.weight_txt,
        weight_measure: this.weight_measure,
        blood_pressure: bpvar,
        heart_rate: this.Heart_Beat,
        respiratory_rate: this.Respiratory_rate,
        temparature:this.Temperature+" "+this.temp_read,
        case_hist_id: pedia_case_hist_id,
        cvs:this.cvs_txt,
        rstxt:this.rstxt,
        cns_txt:this.cns_txt,
        abd_txt:this.abd_txt,
        pr_txt:this.pr_txt,  
        spo2:this.spo2_txt,  
      };
    }

    if (chk_read_flag == true) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'appointment/updappn', JSON.stringify(Send_data),
        { headers: headers })
        .subscribe(
          response => {
            var obj = response.json();
            if (obj.key != null) {
              if (obj.key == "1") {
                var datavalue=null;
                datavalue={
                  txt_bmr:this.txt_bmr,
                  txt_bmi:this.txt_bmi,
                  height:this.height_txt,
                  height_measure:this.height_measure,
                  weight: this.weight_txt,
                  weight_measure: this.weight_measure,
                  temparature:this.Temperature+" "+this.temp_read,
                  bp:bpvar,
                  pulse:this.Heart_Beat,
                  bodynatute:this.body_nature,
                  flag:"vitals"       
                }
                this.bmiservice.sendMessage(datavalue);
                this.toastr.success(Message_data.vitalSavedSuccess);
              } else {
                this.toastr.error(Message_data.vitalNotSavedSuccess);
              }
            }
          },
          error => {
            this.toastr.error(Message_data.defaultErr);
          }
        )

    } else {
      this.toastr.error(Message_data.mandatory);
    }
  }
  // diostol(value){
  //   if( value > 100 || value < 60 ){
  //     this.diocolorstyle=true;
  //   } else {
  //     this.diocolorstyle=false;
  //   }
  // }
  // spo2(value){
  //   if(  value < 75 ){
  //     this.spo2colorstyle=true;
  //   } else {
  //     this.spo2colorstyle=false;
  //   }
  // }
  // tempcolor(value){
  //   if(value >100){
  //     this.colorstyle=true;

  //   } else {
  //     this.colorstyle=false;
  //   }
  // }
  // settempcolor(temprature){
  //   if(temprature >100){
  //     this.tempcolorstyle=true;
  //   }else{
  //     this.tempcolorstyle=false;
  //   }
  // }
  diostol(value) {
    if (value > 90 || value < 70) {
      this.diocolorstyle = true;
    } else {
      this.diocolorstyle = false;
    }
  }

  sistol(value) {
    if (value > 160 || value < 110) {
      this.siscolorstyle = true;
    } else {
      this.siscolorstyle = false;
    }
  }
  spo2(value){
    if(  value < 95 ){
      this.spo2colorstyle=true;
    } else {
      this.spo2colorstyle=false;
    }
  }
  tempcolor(value){
    if (this.temp_read === '°C') {
      // Temperature is in Celsius
      // if (value > 32 || value < 21) { // Convert 90°F to 32°C and 70°F to 21°C
      //   this.colorstyle = true;
      // } else {
      //   this.colorstyle = false;
      // }
    } else if (this.temp_read === 'F') {
      // Temperature is in Fahrenheit
      if (value > 100 || value < 96) {
        this.colorstyle = true;
      } else {
        this.colorstyle = false;
      }
    }
  }
  settempcolor(temprature){
    if(temprature >100){
      this.tempcolorstyle=true;
    }else{
      this.tempcolorstyle=false;
    }
  }
  spo2value(value) {
    if (value < 95) {
      this.spo2colorstyle = true;
    } else {
      this.spo2colorstyle = false;
    }
  }
  pulsevalue(value){ 
    if (value < 60 ||  value >100) {
      this.pulsecolorstyle = true;
    } else {
      this.pulsecolorstyle = false;
    }
  }
  rrvalue(value){ 
    if (value < 14 ||  value > 20) {
      this.rrcolorstyle = true;
    } else {
      this.rrcolorstyle = false;
    }
  }
  cbgvalue(value){ 
    if (value < 90 ||  value > 250) {
      this.cbgcolorstyle = true;
    } else {
      this.cbgcolorstyle = false;
    }
  }
  chnagetemp(e){
    this.temp_read=e;
  }
}
