<div class="row" style="margin:0 auto; width:100%">
   <div class="col-12">
      <mdb-card>
         <mdb-card-header class="bg-white " style="padding:7px 20px;">
            <div class="headerCover">
               <div class="headerTilte app_list_header">
                  <h5 class="m-0" class="mainHeadingStyle">Specimen Report</h5>
               </div>

               <!-- <div class="headerButtons" style="width: 50px; text-align: left;">
                        <mat-label class="matlabel">Date:&nbsp; </mat-label>
                    </div>
                    <div class="headerButtons" style="width: 50px;">
                        <input type="date" class="ipcss" (change)="getSampleList(sampledate)" [(ngModel)]="sampledate"
                            #matInput>
                    </div>
                    <div class="headerButtons" style="width: 50px;">
                        <mat-label class="matlabel"> or&nbsp;&nbsp;&nbsp; </mat-label>
                    </div>
                    <div class="headerButtons" style="width: 50px;">
                        <mat-label class="matlabel"> Duration:&nbsp; </mat-label>
                    </div>
                    <div class="headerButtons" style="width: 50px;">
                        <select [(ngModel)]="filtduration" class="ipcss" (change)="getSampleList(sampledate)">
                            <option value="select">Select</option>
                            <option value="7">1 week</option>
                            <option value="30">1 month</option>
                            <option value="91">3 months</option>
                            <option value="183">6 months</option>
                            <option value="365">1 year</option>
                        </select>
                    </div> -->

               <div class="headerButtons">
                  <img src="../../../assets/ui_icons/buttons/Back_button.svg" (click)="back()"
                     class="saveimgbtn_inpatinfo" />
                  <img src="../../../assets/ui_icons/buttons/save_button.svg" [hidden]="save_btn"
                     (click)="saveUploadData()" class="saveimgbtn_inpatinfo" />
               </div>

            </div>
         </mdb-card-header>
         <mdb-card-body>
            <!-- <div class="container-fluid" [hidden]="true">
               <div class="row">
                  <div class="col-6">
                     <div class="formbold-main-wrapper">
                        <div class="formbold-form-wrapper">
                           <div class="formbold-form-title">
                              <h2>
                                 {{test_Name}}
                              </h2>
                           </div>
                           <div class="formbold-input-flex">
                              <div>
                                 <mat-label class="formbold-form-label  ">Gram Stain<br>
                                    <select disableOptionCentering class="formbold-form-input" [(ngModel)]="gram_stain"
                                       style="margin-top: 8px;" (change)="getMedicine(gram_stain)">
                                       <option *ngFor="let gramSt of gramStainList" value="{{gramSt.gram_stain_id}}">
                                          {{gramSt.gram_stain_desc}}</option>
                                    </select>
                                 </mat-label>
                              </div>
                              <div>
                                 <label for="Specimen" class="formbold-form-label">
                                    Colony count
                                 </label>
                                 <input *ngIf="showColonyData" type="text" name="Specimen" id="Specimen"
                                    class="formbold-form-input" data-bs-toggle="modal" data-bs-target="#exampleModal_2"
                                    [(ngModel)]="colony_input"  [disabled]="isDisabled"/>
                                 <input *ngIf="!showColonyData" type="text" name="Specimen" id="Specimen"
                                    class="formbold-form-input" (click)="getMedicine(undefined)" [disabled]="isDisabled"/>
                              </div>
                           </div>
                           <div class="formbold-mb-3">
                              <label for="culture" class="formbold-form-label">
                                 Culture Report
                              </label>
                              <input type="text" name="culture" id="culture" class="formbold-form-input"
                                 [(ngModel)]="culture_code" data-bs-toggle="modal" data-bs-target="#exampleModal_1" [disabled]="isDisabled"/>
                           </div>

                           <div class="formbold-input-flex">
                              <div>
                                 <label for="organismiso1" class="formbold-form-label"> Organism Isolated 1 </label>
                                 <input type="text" class="formbold-form-input" data-bs-toggle="modal"
                                    data-bs-target="#exampleModal_3" [(ngModel)]="organism_code_Iso1" [disabled]="isDisabled"/>
                              </div>
                              <div>
                                 <label for="Measurement" class="formbold-form-label" style="margin-top: 28px;">
                                 </label>
                                 <input type="text" class="formbold-form-input" [(ngModel)]="organism_desc_Iso1" [disabled]="isDisabled"/>
                              </div>
                           </div>
                           <div class="formbold-input-flex">
                              <div>
                                 <label for="organismiso1" class="formbold-form-label"> Organism Isolated 2 </label>
                                 <input type="text" [(ngModel)]="organism_code_Iso2" class="formbold-form-input"
                                    data-bs-toggle="modal" data-bs-target="#exampleModal_4" [disabled]="isDisabled"/>
                              </div>
                              <div>
                                 <label for="Measurement" class="formbold-form-label" style="margin-top: 28px;">
                                 </label>
                                 <input type="text" [(ngModel)]="organism_desc_Iso2" class="formbold-form-input" [disabled]="isDisabled"/>
                              </div>
                           </div>
                           <div class="formbold-input-flex">
                              <div>
                                 <label for="organismiso1" class="formbold-form-label"> Organism Isolated 3 </label>
                                 <input type="text" [(ngModel)]="organism_code_Iso3" class="formbold-form-input"
                                    data-bs-toggle="modal" data-bs-target="#exampleModal_5" [disabled]="isDisabled"/>
                              </div>
                              <div>
                                 <label for="Measurement" class="formbold-form-label" style="margin-top: 28px;">
                                 </label>
                                 <input type="text" [(ngModel)]="organism_desc_Iso3" class="formbold-form-input" [disabled]="isDisabled"/>
                              </div>
                           </div>

                           <div class="formbold-mb-3" *ngIf="showSterile">
                              <mat-label class="matlabel">
                                 <mat-checkbox [(ngModel)]="otSterile_checked" color="primary" labelPosition="before"
                                    data-bs-toggle="modal" data-bs-target="#exampleModal_6"></mat-checkbox>
                                 OT Sterile
                              </mat-label>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-6">
                     <div class="formbold-main-wrapper" style="margin-top: 65px;">
                        <div class="formbold-form-wrapper">
                           <form>
                              <div>
                                 <label for="message" class="formbold-form-label"> Culture report </label>
                                 <textarea rows="5" name="message" id="message" class="formbold-form-input"
                                    [(ngModel)]="culture_report_notes" [disabled]="isDisabled"></textarea>
                              </div>
                              <div>
                                 <label for="message1" class="formbold-form-label"> Technical Note </label>
                                 <textarea rows="5" name="message1" id="message1" class="formbold-form-input"
                                    [(ngModel)]="technical_notes" [disabled]="isDisabled"></textarea>
                              </div>
                              <div>
                                 <label for="message2" class="formbold-form-label"> Comments </label>
                                 <textarea rows="6" name="message2" id="message2" class="formbold-form-input"
                                    [(ngModel)]="comment_data"></textarea>
                              </div>
                           </form>
                        </div>
                     </div>
                  </div>
                  <div class="col-12 mt-3">
                     <table *ngIf="sterileChecklist.length != 0 && otSterile_checked">
                        <thead>
                           <tr>
                              <th style="text-align: left;">Area</th>
                              <th style="text-align: left;">Result</th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr *ngFor="let sterile of sterileChecklist">
                              <td style="text-align: left; width: 30%;">{{sterile.area}}</td>
                              <td style="text-align: left;">{{sterile.result}}</td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
               </div>
            </div> -->

            <div class="container-fluid">
               <div class="row">
                  <div class="col-12">
                     <div class="row" style="padding: 10px;">
                        <div class="cover_div">
                           <div class="header_lable">Personal Details</div>
                           <div class="content_cover">
                              <!-- <div class="row">
                                 <div class="col-3">
                                    MR No: {{mrno}}
                                 </div>
                                 <div class="col-3">
                                    Name: {{patientName}}
                                 </div>
                                 <div class="col-3">
                                    Age / Gender: {{patAge}} / {{patGender}}
                                 </div>
                                 <div class="col-3">
                                    Mobile: {{mobile}}
                                 </div>
                                 <div class="col-3">
                                    Patient type: {{patient_type}}
                                 </div>
                                 <div class="col-3">
                                    Doctor name: {{doctorname}}
                                 </div>
                                 <div class="col-3">
                                    Address1: {{address1}}
                                 </div>
                                 <div class="col-3">
                                    Location: {{location}}
                                 </div>
                                 <div class="col-3">
                                    City: {{district}}
                                 </div>
                                 <div class="col-3">
                                    State: {{state}}
                                 </div>
                                 <div class="col-3">
                                    Country: {{clntcountry}}
                                 </div>
                                 <div class="col-3">
                                    Zipcode: {{zipcode}}
                                 </div>
                              </div> -->
                              <div class="row">
                                 <div class="col-3">
                                    <table style="width: 100%;" class="head-table">
                                       <tbody>
                                          <tr class="head-tr">
                                             <td class="head-td" style="vertical-align: top;width: 100px"><strong>MR No</strong></td>
                                             <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                             <td class="head-td">{{mrno}}</td>
                                          </tr>
                                          <tr class="head-tr">
                                             <td class="head-td" style="vertical-align: top;width: 100px"><strong>Name</strong></td>
                                             <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                             <td class="head-td">{{patientName}}</td>
                                          </tr>
                                          <tr class="head-tr">
                                             <td class="head-td" style="vertical-align: top;width: 100px"><strong>Age / Gender</strong></td>
                                             <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                             <td class="head-td">{{patAge}} / {{patGender}}</td>
                                          </tr>
                                          <!-- <tr *ngIf="diag_report_type != 'radiology'">
                                             <td><strong>Specimen</strong></td>
                                             <td>: {{sample_type}}</td>
                                          </tr> -->
                                       </tbody>
                                    </table>
                                 </div>
                                 <div class="col-3">
                                    <table style="width: 100%;" class="head-table">
                                       <tbody>
                                          <tr class="head-tr">
                                             <td class="head-td" style="vertical-align: top;width: 100px"><strong>Mobile</strong></td>
                                             <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                             <td class="head-td">{{mobile}}</td>
                                          </tr>
                                          <tr class="head-tr">
                                             <td class="head-td" style="vertical-align: top;width: 100px"><strong>Patient type</strong></td>
                                             <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                             <td class="head-td">{{patient_type}}</td>
                                          </tr>
                                          <tr class="head-tr">
                                             <td class="head-td" style="vertical-align: top;width: 100px"><strong>Doctor</strong></td>
                                             <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                             <td class="head-td">{{doctorname}}</td>
                                          </tr>
                                       </tbody>
                                    </table>
                                 </div>
                                 <div class="col-3">
                                    <table style="width: 100%;" class="head-table">
                                       <tbody>
                                          <tr class="head-tr">
                                             <td class="head-td" style="vertical-align: top;width: 100px"><strong>Address1</strong></td>
                                             <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                             <td class="head-td">{{address1}}</td>
                                          </tr>
                                          <tr class="head-tr">
                                             <td class="head-td" style="vertical-align: top;width: 100px"><strong>Location</strong></td>
                                             <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                             <td class="head-td">{{location}}</td>
                                          </tr>
                                          <tr class="head-tr">
                                             <td class="head-td" style="vertical-align: top;width: 100px"><strong>City</strong></td>
                                             <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                             <td class="head-td">{{district}}</td>
                                          </tr>
                                       </tbody>
                                    </table>
                                 </div>
                                 <div class="col-3">
                                    <table style="width: 100%;" class="head-table">
                                       <tbody>
                                          <tr class="head-tr">
                                             <td class="head-td" style="vertical-align: top; width: 100px;"><strong>State</strong></td>
                                             <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                             <td class="head-td">{{state}}</td>
                                          </tr>
                                          <tr class="head-tr">
                                             <td class="head-td" style="vertical-align: top; width: 100px"><strong>Country</strong></td>
                                             <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                             <td class="head-td">{{clntcountry}}</td>
                                          </tr>
                                          <tr class="head-tr">
                                             <td class="head-td" style="vertical-align: top; width: 100px"><strong>Zipcode</strong></td>
                                             <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                                             <td class="head-td">{{zipcode}}</td>
                                          </tr>
                                       </tbody>
                                    </table>
                                 </div>                              
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            
            <div class="container-fluid" [hidden]="micro_template_flag">
               <div class="row">
                  <div class="col-12">
                     <div class="formbold-form-title">
                        <h2 style="margin-bottom: 0px !important;">{{test_Name}}</h2>
                     </div>
                  </div>
                  <div class="col-12 mb-3">
                     <div class="row">
                        <div class="col-3">
                           <mat-label class="formbold-form-label">
                              <select disableOptionCentering class="formbold-form-input" [(ngModel)]="growth_noGrowth"
                                 style="margin-top: 32px;" (change)="growthNogrowth(growth_noGrowth)">
                                 <option value="growth">Growth</option>
                                 <option value="nogrowth">No-growth</option>
                              </select>
                           </mat-label>
                        </div>
                        <div class="col-4" *ngIf="no_growthFlag">
                           <div class="formbold-mb-3">
                              <label for="culture" class="formbold-form-label">
                                 Culture Report
                              </label>
                              <input type="text" name="culture" id="culture" class="formbold-form-input"
                                 [(ngModel)]="culture_code_1" data-bs-toggle="modal" data-bs-target="#exampleModal_1"
                               />
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-12 mb-3 p-3" style="box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;" *ngIf="!no_growthFlag">
                     <div class="row">
                        <div class="col-3">
                           <div class="formbold-input-flex">
                              <div>
                                 <label for="organismiso1" class="formbold-form-label"> Organism Isolated 1 </label>
                                 <input type="text" class="formbold-form-input" data-bs-toggle="modal"
                                    data-bs-target="#exampleModal_3" [(ngModel)]="organism_code_Iso1"
                                    [disabled]="isDisabled" />
                              </div>
                              <div>
                                 <label for="Measurement" class="formbold-form-label" style="margin-top: 28px;">
                                 </label>
                                 <input type="text" class="formbold-form-input" [(ngModel)]="organism_desc_Iso1"
                                    [disabled]="isDisabled" />
                              </div>
                           </div>
                        </div>
                        <div class="col-3" *ngIf="urineTest_flag">
                           <div>
                              <mat-label class="formbold-form-label  ">Colony count<br>
                                 <select disableOptionCentering class="formbold-form-input" [(ngModel)]="colony_count_1"
                                    style="margin-top: 8px;" [disabled]="isDisabled">
                                    <option *ngFor="let col of colonyCountList" value="{{col.colony_count_id}}">
                                       {{col.colonycount_desc}}</option>
                                 </select>
                              </mat-label>
                           </div>
                        </div>
                        <div class="col-2">
                           <div>
                              <mat-label class="formbold-form-label  ">Growth type<br>
                                 <select disableOptionCentering class="formbold-form-input" [(ngModel)]="gram_stain_1"
                                    style="margin-top: 8px;" (change)="getMedicine(gram_stain_1, '1')"
                                    [disabled]="isDisabled">
                                    <option *ngFor="let gramSt of gramStainList" value="{{gramSt.gram_stain_id}}">
                                       {{gramSt.gram_stain_desc}}</option>
                                 </select>
                              </mat-label>
                           </div>
                        </div>
                        <!-- <div class="col-4" *ngIf="no_growthFlag">
                           <div class="formbold-mb-3">
                              <label for="culture" class="formbold-form-label">
                                 Culture Report
                              </label>
                              <input type="text" name="culture" id="culture" class="formbold-form-input"
                                 [(ngModel)]="culture_code_1" data-bs-toggle="modal" data-bs-target="#exampleModal_1"
                               />
                           </div>
                        </div> -->
                        <div class="col-8">
                           <div>
                              <label for="message" class="formbold-form-label"> Culture report selected</label>
                              <textarea rows="1" name="message" id="message" class="formbold-form-input"
                                 [(ngModel)]="culture_report_notes_1" [disabled]="isDisabled"></textarea>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div class="col-12 mb-3" *ngIf="!no_growthFlag">
                     <mat-accordion displayMode="flat" multi="false" class="mat-table mb-2">
                        <mat-expansion-panel>
                           <mat-expansion-panel-header class="mat-row align-items-center">
                              <span class="mat-cell">Organism Isolated 2</span>
                           </mat-expansion-panel-header>
                           <div>
                              <div class="col-12 mb-3 p-3" style="box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;">
                                 <div class="row">
                                    <div class="col-3">
                                       <label for="organismiso1" class="formbold-form-label"> Organism Isolated 2
                                       </label>
                                       <div class="formbold-input-flex">
                                          <div>
                                             <input type="text" [(ngModel)]="organism_code_Iso2"
                                                class="formbold-form-input" data-bs-toggle="modal"
                                                data-bs-target="#exampleModal_4" [disabled]="isDisabled" />
                                          </div>
                                          <div>
                                             <!-- <label for="Measurement" class="formbold-form-label" style="margin-top: 28px;">
                                             </label> -->
                                             <input type="text" [(ngModel)]="organism_desc_Iso2"
                                                class="formbold-form-input" [disabled]="isDisabled" />
                                          </div>
                                       </div>
                                    </div>
                                    <div class="col-3" *ngIf="urineTest_flag">
                                       <div>
                                          <mat-label class="formbold-form-label  ">Colony count<br>
                                             <select disableOptionCentering class="formbold-form-input"
                                                [(ngModel)]="colony_count_2" style="margin-top: 8px;"
                                                [disabled]="isDisabled">
                                                <option *ngFor="let col of colonyCountList"
                                                   value="{{col.colony_count_id}}">
                                                   {{col.colonycount_desc}}</option>
                                             </select>
                                          </mat-label>
                                       </div>
                                    </div>
                                    <div class="col-2">
                                       <div>
                                          <mat-label class="formbold-form-label  ">Growth type<br>
                                             <select disableOptionCentering class="formbold-form-input"
                                                [(ngModel)]="gram_stain_2" style="margin-top: 8px;"
                                                (change)="getMedicine(gram_stain_2, '2')" [disabled]="isDisabled">
                                                <option *ngFor="let gramSt of gramStainList"
                                                   value="{{gramSt.gram_stain_id}}">
                                                   {{gramSt.gram_stain_desc}}</option>
                                             </select>
                                          </mat-label>
                                       </div>
                                    </div>
                                    <!-- <div class="col-4" *ngIf="no_growthFlag">
                                       <div class="formbold-mb-3">
                                          <label for="culture" class="formbold-form-label">
                                             Culture Report
                                          </label>
                                          <input type="text" name="culture" id="culture" class="formbold-form-input"
                                             [(ngModel)]="culture_code_2" data-bs-toggle="modal"
                                             data-bs-target="#exampleModal_7"  />
                                       </div>
                                    </div> -->
                                    <div class="col-8">
                                       <div>
                                          <label for="message" class="formbold-form-label"> Culture report
                                             selected</label>
                                          <textarea rows="1" name="message" id="message" class="formbold-form-input"
                                             [(ngModel)]="culture_report_notes_2" [disabled]="isDisabled"></textarea>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </mat-expansion-panel>
                     </mat-accordion>
                  </div>

                  <mat-accordion displayMode="flat" multi="false" class="mat-table mb-2" *ngIf="!no_growthFlag">
                     <mat-expansion-panel>
                        <mat-expansion-panel-header class="mat-row align-items-center">
                           <span class="mat-cell">Organism Isolated 3</span>
                        </mat-expansion-panel-header>
                        <div>
                           <div class="col-12 mb-3 p-3" style="box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;">
                              <div class="row">
                                 <div class="col-3">
                                    <label for="organismiso1" class="formbold-form-label"> Organism Isolated 3 </label>
                                    <div class="formbold-input-flex">
                                       <div>
                                          <input type="text" [(ngModel)]="organism_code_Iso3"
                                             class="formbold-form-input" data-bs-toggle="modal"
                                             data-bs-target="#exampleModal_5" [disabled]="isDisabled" />
                                       </div>
                                       <div>
                                          <!-- <label for="Measurement" class="formbold-form-label" style="margin-top: 28px;">
                                          </label> -->
                                          <input type="text" [(ngModel)]="organism_desc_Iso3"
                                             class="formbold-form-input" [disabled]="isDisabled" />
                                       </div>
                                    </div>
                                 </div>
                                 <div class="col-3" *ngIf="urineTest_flag">
                                    <div>
                                       <mat-label class="formbold-form-label  ">Colony count<br>
                                          <select disableOptionCentering class="formbold-form-input"
                                             [(ngModel)]="colony_count_3" style="margin-top: 8px;"
                                             [disabled]="isDisabled">
                                             <option *ngFor="let col of colonyCountList"
                                                value="{{col.colony_count_id}}">
                                                {{col.colonycount_desc}}</option>
                                          </select>
                                       </mat-label>
                                    </div>
                                 </div>
                                 <div class="col-2">
                                    <div>
                                       <mat-label class="formbold-form-label  ">Growth type<br>
                                          <select disableOptionCentering class="formbold-form-input"
                                             [(ngModel)]="gram_stain_3" style="margin-top: 8px;"
                                             (change)="getMedicine(gram_stain_3, '3')" [disabled]="isDisabled">
                                             <option *ngFor="let gramSt of gramStainList"
                                                value="{{gramSt.gram_stain_id}}">
                                                {{gramSt.gram_stain_desc}}</option>
                                          </select>
                                       </mat-label>
                                    </div>
                                 </div>
                                 <!-- <div class="col-4" *ngIf="no_growthFlag">
                                    <div class="formbold-mb-3">
                                       <label for="culture" class="formbold-form-label">
                                          Culture Report
                                       </label>
                                       <input type="text" name="culture" id="culture" class="formbold-form-input"
                                          [(ngModel)]="culture_code_3" data-bs-toggle="modal"
                                          data-bs-target="#exampleModal_8" />
                                    </div>
                                 </div> -->
                                 <div class="col-8">
                                    <div>
                                       <label for="message" class="formbold-form-label"> Culture report selected</label>
                                       <textarea rows="1" name="message" id="message" class="formbold-form-input"
                                          [(ngModel)]="culture_report_notes_3" [disabled]="isDisabled"></textarea>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </mat-expansion-panel>
                  </mat-accordion>

                  <div class="col-12">
                     <div class="row">
                        <div class="col-6">
                           <div>
                              <label for="message1" class="formbold-form-label"> Technical Note </label>
                              <textarea rows="5" name="message1" id="message1" class="formbold-form-input"
                                 [(ngModel)]="technical_notes"></textarea>
                           </div>
                        </div>
                        <div class="col-6">
                           <div>
                              <label for="message2" class="formbold-form-label"> Comments </label>
                              <textarea rows="5" name="message2" id="message2" class="formbold-form-input"
                                 [(ngModel)]="comment_data"></textarea>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-12">
                     <div class="row">
                        <div class="col-6">
                           <div class="formbold-mb-3" *ngIf="showSterile">
                              <mat-label class="matlabel">
                                 <mat-checkbox [(ngModel)]="otSterile_checked" color="primary" labelPosition="before"
                                    data-bs-toggle="modal" data-bs-target="#exampleModal_6"></mat-checkbox>
                                 OT Sterile
                              </mat-label>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <div class="container-fluid" [hidden]="template_flag">
               <div class="row p-3" >
                  <div class="d-flex align-items-center justify-content-center">
                     <div class="col-12">
                        <quill-editor id="editor1" [(ngModel)]="cbt_Template" [style]="editorStyle" [modules]="modules"
                           placeholder="Enter Text" (onContentChanged)="onTemplateChanged($event)"></quill-editor>
                        <div>
                           <!-- Preview -->
                           <quill-view-html hidden id="viewhtml1" [content]="cbt_Template"></quill-view-html>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <div class="modal fade" id="exampleModal_1" tabindex="-1" aria-labelledby="exampleModalLabel"
               aria-hidden="true">
               <div class="modal-dialog">
                  <div class="modal-content">
                     <div class="modal-body">
                        <table>
                           <thead>
                              <tr>
                                 <!-- <th style="text-align: left;">Code</th> -->
                                 <th style="text-align: left;">Description</th>
                              </tr>
                           </thead>
                           <tbody>
                              <tr *ngFor="let culture of cultureReports" data-bs-dismiss="modal"
                                 (click)="getCultureReportInput(culture, '1')">
                                 <!-- <td style="text-align: left;">{{culture.culture_report_code}}</td> -->
                                 <td style="text-align: left;">{{culture.culture_report_desc}}</td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                  </div>
               </div>
            </div>
            <div class="modal fade" id="exampleModal_2" tabindex="-1" aria-labelledby="exampleModalLabel"
               aria-hidden="true">
               <div class="modal-dialog modal-lg">
                  <div class="modal-content">
                     <div class="modal-body">
                        <div style="height: 300px; overflow-y: scroll; margin-top: 20px;">
                           <table>
                              <thead>
                                 <tr>
                                    <th style="text-align: left;">Antibiotic</th>
                                    <th style="text-align: left; width: 25%;">Sensitivity</th>
                                    <th style="text-align: left;">MM</th>
                                    <th style="text-align: left;">MIC</th>
                                 </tr>
                              </thead>
                              <tbody>
                                 <tr *ngFor="let medicine of medicineList">
                                    <td style="text-align: left;">{{medicine.sensitive_drug_desc}}</td>
                                    <td style="text-align: left;">
                                       <mat-label class="formbold-form-label">
                                          <select disableOptionCentering class="formbold-form-input"
                                             [ngStyle]="{'color': medicine.isSensitive ? 'red' : '#536387'}"
                                             style="margin-top: 8px;" [(ngModel)]="medicine.culture_sensitive_id"
                                             (change)="onSensitivyChange(medicine.culture_sensitive_id, medicine.sensitive_drug_desc, medicine.sensitive_drug_id)">
                                             <option *ngFor="let sensitivity of sensitivityTypeList"
                                                value="{{sensitivity.culture_sensitive_id}}">
                                                {{sensitivity.culture_sensitive_desc}}</option>
                                          </select>
                                       </mat-label>
                                    </td>
                                    <td style="text-align: left; font-size: 15px;"><input [(ngModel)]="medicine.mm"
                                          type="text" class="formbold-form-input" /></td>
                                    <td style="text-align: left; font-size: 15px;"><input [(ngModel)]="medicine.mic"
                                          type="text" class="formbold-form-input" /></td>
                                 </tr>
                              </tbody>
                           </table>
                        </div>
                        <div class="container-fluid mt-3">
                           <!-- <label class="formbold-form-label"> Sensitive Medicines </label>
                              <textarea rows="6" class="formbold-form-input"
                                 [(ngModel)]="sensitive_med"></textarea> -->
                           <div class="row ">
                              <div *ngFor="let Sense of sensitivityCardList" class="col-2">
                                 <div class="card-container mb-2">
                                    <p class="ref-values">{{Sense.med_Name}}</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="modal-footer">
                        <!-- <button (click)="saveColonyCountData()" type="button" class="btn btn-secondary"
                           data-bs-dismiss="modal">Done</button> -->
                        <img src="../../../assets/ui_icons/buttons/Done_button.svg" data-bs-dismiss="modal"
                           (click)="saveColonyCountData()" class="saveimgbtn_inpatinfo" />
                        <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
                     </div>
                  </div>
               </div>
            </div>
            <div class="modal fade" id="exampleModal_3" tabindex="-1" aria-labelledby="exampleModalLabel"
               aria-hidden="true">
               <div class="modal-dialog">
                  <div class="modal-content">
                     <div class="modal-body">
                        <table>
                           <thead>
                              <tr>
                                 <th style="text-align: left;">Code</th>
                                 <th style="text-align: left;">Description</th>
                              </tr>
                           </thead>
                           <tbody>
                              <tr *ngFor="let culture of organismIsoList" data-bs-dismiss="modal"
                                 (click)="getOrgnaismIsoInput(culture, 1)">
                                 <td style="text-align: left;">{{culture.culture_pathogen_code}}</td>
                                 <td style="text-align: left;">{{culture.culture_pathogen_desc}}</td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                  </div>
               </div>
            </div>
            <div class="modal fade" id="exampleModal_4" tabindex="-1" aria-labelledby="exampleModalLabel"
               aria-hidden="true">
               <div class="modal-dialog">
                  <div class="modal-content">
                     <div class="modal-body">
                        <table>
                           <thead>
                              <tr>
                                 <th style="text-align: left;">Code</th>
                                 <th style="text-align: left;">Description</th>
                              </tr>
                           </thead>
                           <tbody>
                              <tr *ngFor="let culture of organismIsoList" data-bs-dismiss="modal"
                                 (click)="getOrgnaismIsoInput(culture, 2)">
                                 <td style="text-align: left;">{{culture.culture_pathogen_code}}</td>
                                 <td style="text-align: left;">{{culture.culture_pathogen_desc}}</td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                  </div>
               </div>
            </div>
            <div class="modal fade" id="exampleModal_5" tabindex="-1" aria-labelledby="exampleModalLabel"
               aria-hidden="true">
               <div class="modal-dialog">
                  <div class="modal-content">
                     <div class="modal-body">
                        <table>
                           <thead>
                              <tr>
                                 <th style="text-align: left;">Code</th>
                                 <th style="text-align: left;">Description</th>
                              </tr>
                           </thead>
                           <tbody>
                              <tr *ngFor="let culture of organismIsoList" data-bs-dismiss="modal"
                                 (click)="getOrgnaismIsoInput(culture, 3)">
                                 <td style="text-align: left;">{{culture.culture_pathogen_code}}</td>
                                 <td style="text-align: left;">{{culture.culture_pathogen_desc}}</td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                  </div>
               </div>
            </div>
            <div class="modal fade" id="exampleModal_6" tabindex="-1" aria-labelledby="exampleModalLabel"
               aria-hidden="true">
               <div class="modal-dialog modal-lg">
                  <div class="modal-content">
                     <div class="modal-body">
                        <div class="container-fluid">
                           <div class="row ">
                              <div class="col-12">
                                 <h3 style="font-weight: 500;">OT Sterility Check For Aerobic OT Sterility</h3>
                              </div>
                              <div class="col-4">
                                 <label for="organismiso1" class="formbold-form-label"> Area </label>
                                 <input type="text" [(ngModel)]="sterile_area" class="formbold-form-input" />
                              </div>
                              <div class="col-6">
                                 <label for="organismiso1" class="formbold-form-label"> Result </label>
                                 <input type="text" [(ngModel)]="sterile_result" class="formbold-form-input" />
                              </div>
                              <div class="col-2" style="text-align: center;
                              margin-top: 25px; ">
                                 <!-- <button type="button" class="btn btn-secondary" style="width: 100px;"
                                    (click)="addSterileCheckList()">Add</button> -->
                                 <img src="../../../assets/ui_icons/buttons/add_button.svg" class="saveimgbtn_inpatinfo"
                                    (click)="addSterileCheckList()" />
                              </div>
                              <div class="col-12 mt-3">
                                 <table *ngIf="sterileChecklist.length != 0">
                                    <thead>
                                       <tr>
                                          <th style="text-align: left;">Area</th>
                                          <th style="text-align: left;">Result</th>
                                          <th></th>
                                          <th></th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       <tr *ngFor="let sterile of sterileChecklist">
                                          <td style="text-align: left; width: 20%;">{{sterile.area}}</td>
                                          <td style="text-align: left;">{{sterile.result}}</td>
                                          <td style="width: 5%;">
                                             <!-- <button type="button" class="btn btn-secondary"
                                                style="width: 60px; height: 35px;"
                                                (click)="editSterileCheckList(sterile)">Edit</button> -->
                                             <img src="../../../assets/ui_icons/buttons/pencil_icon.svg"
                                                class="del_img_class" (click)="editSterileCheckList(sterile)" />
                                          </td>
                                          <td style="width: 5%;">
                                             <!-- <button type="button" class="btn btn-secondary"
                                                style="width: 80px; height: 35px;"
                                                (click)="deleteSterileCheckList(sterile)">Delete</button> -->
                                             <img src="../../../assets/ui_icons/buttons/trash_icon.svg"
                                                class="del_img_class" (click)="deleteSterileCheckList(sterile)" />
                                          </td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="modal-footer">
                        <img src="../../../assets/ui_icons/buttons/Done_button.svg" class="saveimgbtn_inpatinfo"
                           data-bs-dismiss="modal" />
                        <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
                     </div>
                  </div>
               </div>
            </div>
            <div class="modal fade" id="exampleModal_7" tabindex="-1" aria-labelledby="exampleModalLabel"
               aria-hidden="true">
               <div class="modal-dialog">
                  <div class="modal-content">
                     <div class="modal-body">
                        <table>
                           <thead>
                              <tr>
                                 <!-- <th style="text-align: left;">Code</th> -->
                                 <th style="text-align: left;">Description</th>
                              </tr>
                           </thead>
                           <tbody>
                              <tr *ngFor="let culture of cultureReports" data-bs-dismiss="modal"
                                 (click)="getCultureReportInput(culture, '2')">
                                 <!-- <td style="text-align: left;">{{culture.culture_report_code}}</td> -->
                                 <td style="text-align: left;">{{culture.culture_report_desc}}</td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                  </div>
               </div>
            </div>
            <div class="modal fade" id="exampleModal_8" tabindex="-1" aria-labelledby="exampleModalLabel"
               aria-hidden="true">
               <div class="modal-dialog">
                  <div class="modal-content">
                     <div class="modal-body">
                        <table>
                           <thead>
                              <tr>
                                 <!-- <th style="text-align: left;">Code</th> -->
                                 <th style="text-align: left;">Description</th>
                              </tr>
                           </thead>
                           <tbody>
                              <tr *ngFor="let culture of cultureReports" data-bs-dismiss="modal"
                                 (click)="getCultureReportInput(culture, '3')">
                                 <!-- <td style="text-align: left;">{{culture.culture_report_code}}</td> -->
                                 <td style="text-align: left;">{{culture.culture_report_desc}}</td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                  </div>
               </div>
            </div>
         </mdb-card-body>
      </mdb-card>
   </div>
</div>