<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Patient list</h5>
          </div>
          <div class="headerButtons">
        
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="col-12" style="margin-bottom:15px;">
            <div class="row">
              <div class="col-4 switch-field">
                <input type="radio" id="radio-five" (click)="searchType('name')" />
                <label for="radio-five" [ngClass]=" typeflag == 'name' ? 'radio_active':'radio_inactive'">
                  <div class="tab_cover">
                    <div class="tab_icon_cover"><img src="../../../assets/ui_icons/Doctor.svg" class="iocinStyle"
                        alt=""></div>
                    <div class="lableStyle">Name</div>
                  </div>
                </label>
                <input type="radio" id="radio-six" (click)="searchType('mobile')" />
                <label for="radio-six" [ngClass]=" typeflag == 'mobile' ? 'radio_active':'radio_inactive'">
                  <div class="tab_cover">
                    <div class="tab_icon_cover"><img src="../../../assets/ui_icons/diagnosis.svg" class="iocinStyle"
                        alt=""></div>
                    <div class="lableStyle">Mobile</div>
                  </div>
                </label>
                <input type="radio" id="radio-seven" (click)="searchType('patientid');focusInput()" />
                <label for="radio-seven" [ngClass]=" typeflag == 'patientid' ? 'radio_active':'radio_inactive'">
                  <div class="tab_cover">
                    <div class="tab_icon_cover"><img src="../../../assets/ui_icons/Dietician_icon_svg.svg"
                        class="iocinStyle" alt=""></div>
                    <div class="lableStyle">MR no</div>
                  </div>
                </label>
              </div>
              <div class="col-6">
                <input class="inputStyle2" type="text" id="filterInput" [(ngModel)]="patientName"
                  placeholder={{searchtext}} (keyup)="getPatientListByName(patientName)">
              </div>
              <div class="col-1" style="margin: -23px !important;">
                <mat-label class="matlabel">Per page<br>
                  <select [(ngModel)]="pagelist" class="ipcss" (change)="changePageList()">
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                  </select>
                </mat-label>
              </div>
            </div>
          </div>
        </div>
        <div style="width: 100%;display:flex; justify-content: center;">
          <div style="width: 91vw;">
            <div class="row" style="overflow: scroll; overflow-x: hidden; height: 70vh;">
              <div class="col-12" *ngIf="nodata">
                <p class="nodata" *ngIf="nodata">No patient list(s) found</p>
              </div>
              <!-- <p class="nodata col-12" *ngIf="patientList.length == 0">No patient list(s) found</p>  -->
              <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-11 "  *ngIf="patientList.length != 0">
                <table *ngIf="patientList.length" class="table-fixed outline_setup rounded-3">
                  <thead>
                    <tr class="background-color_b" style="height: 35px;">
                      <th
                        style="border-bottom: 1px solid #ddd;font-weight: 400;text-align: center;background: #51b0e7 !important;">
                        Image</th>
                      <th
                        style="border-bottom: 1px solid #ddd;font-weight: 400;text-align: center;background: #51b0e7 !important;">
                        Patient id</th>
                      <th
                        style="border-bottom: 1px solid #ddd;font-weight: 400;text-align: center;background: #51b0e7 !important;">
                        Name</th>
                      <th
                        style="border-bottom: 1px solid #ddd;font-weight: 400;text-align: center;background: #51b0e7 !important;">
                        Mobile</th>
                      <th
                        style="border-bottom: 1px solid #ddd;font-weight: 400;text-align: center;background: #51b0e7 !important;">
                        Barcode</th>
                      <th
                        style="border-bottom: 1px solid #ddd;font-weight: 400;text-align: center;background: #51b0e7 !important;">
                        Id card</th>
                      <th
                        style="border-bottom: 1px solid #ddd;font-weight: 400;text-align: center;background: #51b0e7 !important;">
                        Edit</th>

                    </tr>
                  </thead>

                  <tbody>
                    <tr *ngFor="let person of patientList; let i = index" style="height: 30px;">
                      <td style="text-align: center;font-size: 12px;                      
                      border-bottom: 1px solid #ddd;
                      border-left: 1px solid #ddd;
                      padding: 5px">
                        <img src="{{person.prof}}" onerror="this.src='../../../assets/img/default.jpg';"
                          class="profileimage">
                      </td>
                      <td
                        style="font-size: 12px; text-align: left;border-bottom: 1px solid #ddd;border-left: 1px solid #ddd;padding: 5px;">
                        {{ person.patient_id }}</td>
                      <td style="font-size: 12px;text-align: left;border-bottom: 1px solid #ddd;padding: 5px;">{{
                        person.full_name }}</td>
                      <td style="font-size: 12px;text-align: center;border-bottom: 1px solid #ddd;">
                        {{ person.mobile }}</td>
                      <td style="text-align: center;border-bottom: 1px solid #ddd;border-right: 1px solid #ddd;">

                        <img id="device" src="../../../assets/img/printer.svg" class="billing_edit" style="z-index: 9;"
                          alt="" (click)="patientid_card(person,i,'id')">
                      </td>
                      <td style="text-align: center;border-bottom: 1px solid #ddd;border-right: 1px solid #ddd;">
                        <img src="../../../assets/img/printer.svg" class="billing_edit" style="z-index: 9;" alt=""
                          (click)="patientid_card(person,i,'value')">
                      </td>
                      <td style="text-align: center;border-bottom: 1px solid #ddd;border-right: 1px solid #ddd;">
                        <img src="../../../assets/img/edit.png" class="billing_edit" style="z-index: 9;" alt=""
                          (click)="editClient(person)">
                      </td>


                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="row" style="margin-top: 15px;">
            <div class="col-3 col-sm-3 col-md-4 col-lg-4 col-xl-4 select_bottom"> </div>
            <div class="col-3 col-sm-3 col-md-1 col-lg-1 col-xl-1 select_bottom">
              <input id="Text1" type="image" [disabled]="prevPageCount" class="pag_images" (click)="pageLimitPrevious()"
                src="../../../assets/ui_icons/arrow_blue.svg" />
            </div>
            <div class="col-3 col-sm-3 col-md-1 col-lg-1 col-xl-1 select_bottom">
              <span class="current_font">{{currentPage}}/{{numberOfPages()}}</span>
            </div>

            <div class="col-3 col-sm-3 col-md-1 col-lg-1 col-xl-1 select_bottom">
              <input id="Image1" type="image" [disabled]="nextPageCount" class="pag_images" (click)="pageLimitnext()"
                src="../../../assets/ui_icons/arrow_blue r.svg" />
            </div>

          </div>
        </div>


        <div [hidden]="true">
          <div #idprint id="idprint">
            <div class="container-fluid m-5">
              <div class="row" style="margin: 0;
              position: absolute;
              top: 50%;
              left: 50%;
              -ms-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);">
                <div class="col-6" style="width: 250px; 
                  border-style: solid; 
                  border-width: thin; 
                  border-radius: 10px; 
                  border-color: rgb(139, 139, 251); 
                  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;padding:0% ">

                  <div class="justify-content-center" style="text-align: center;">
                    <img style="width: 125px; height: 125px; border-radius: 72px; margin: 10px;" src={{idcardprof}}
                      onerror="this.src='../../../assets/img/default.jpg';">
                    <h1 style="font-size: 10px; letter-spacing: 4px;  color: rgb(139, 139, 251);">
                      {{clientname}}</h1>
                  </div>
                  <div class="d-flex align-items-center justify-content-center " style="letter-spacing: 1px">
                    <table style="width:100%">


                      <tr>
                        <td
                          style="font-size: 10px; font-family:Verdana, Geneva, Tahoma, sans-serif;padding:3px;width:50%">
                          DOB</td>
                        <td style="font-size: 10px; font-family:Verdana, Geneva, Tahoma, sans-serif;padding:3px">
                          {{dateformate}}</td>
                      </tr>

                      <tr>
                        <td
                          style="font-size: 10px; font-family:Verdana, Geneva, Tahoma, sans-serif;padding:3px;width:50%">
                          Age/Gender</td>
                        <td style="font-size: 10px; font-family:Verdana, Geneva, Tahoma, sans-serif;padding:3px">
                          {{clntAge}}/{{clntGendervalue}}</td>
                      </tr>

                      <!-- <tr>
                    <td style="font-size: 10px; font-family:Verdana, Geneva, Tahoma, sans-serif;padding:3px;width:50%"></td>
                    <td style="font-size: 10px; font-family:Verdana, Geneva, Tahoma, sans-serif;padding:3px"></td>
                  </tr>   -->

                      <tr>
                        <td
                          style="font-size: 10px; display:block;font-family:Verdana, Geneva, Tahoma, sans-serif;padding:3px;width:50%">
                          Address</td>
                        <td style="font-size: 10px; font-family:Verdana, Geneva, Tahoma, sans-serif;padding:3px">
                          {{address}}
                        </td>
                      </tr>

                      <tr>
                        <td
                          style="font-size: 10px; font-family:Verdana, Geneva, Tahoma, sans-serif;padding:3px;width:50%">
                          Mobile No</td>
                        <td style="font-size: 10px; font-family:Verdana, Geneva, Tahoma, sans-serif;padding:3px">
                          {{clntContNumber}}</td>
                      </tr>
                    </table>

                  </div>
                  <div class="d-flex align-items-center justify-content-center">
                    <ngx-barcode class="barcode" [bc-value]="mrNumber" [bc-display-value]="displayValue"
                      [bc-line-color]="lineColor" [element-type]="elementType" [format]="format" [bc-width]="width"
                      [bc-height]="barcodeheight" [bc-font-options]="fontOptions" [bc-font]="font"
                      [bc-text-align]="textAlign" [bc-text-position]="textPosition" [bc-text-margin]="textMargin"
                      [bc-font-size]="fontSize" [bc-background]="background" [bc-margin]="margin"
                      [bc-margin-top]="marginTop" [bc-margin-bottom]="marginBottom" [bc-margin-left]="marginLeft"
                      [bc-margin-right]="marginRight"></ngx-barcode>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div #idprintnew id="idprintnew">
            <div class="container-fluid m-1">
              <div class="row">
                <div class="col-6">
                  <div style="width: 185px; height: 84px; border-style: none; border-width: thin; border-radius: 10px; border-color: rgb(139, 139, 251); margin-top: 5px !important; margin-bottom: 0px !important; margin-left: 90px;">
                    <div style="padding: 6px;">
                      <div class="row d-flex flex-column justify-content-center">
                        <div class="col-12">
                          <p
                            style="font-size: 11px !important; margin-bottom: 0px !important; font-weight: bolder; letter-spacing: 1px; text-align: center !important;">
                            {{ hospital_name }}</p>
                        </div>
                        <div class="col-12">
                          <div class="d-flex align-items-center justify-content-between">
                            <div style="width: 35%;">
                              <label
                                style="font-size: 11px !important; margin-bottom: 0px !important; font-weight: bolder; letter-spacing: 1px;">{{clntSaldesc}}.{{clientname }}</label>
                            </div>
                            <div style="width: 20%;">
                              <label
                                style="font-size: 11px !important; margin-bottom: 0px !important; font-weight: bolder; letter-spacing: 1px;">{{clntAge}}/{{clntGendervalue}}</label>
                            </div>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="d-flex align-items-center justify-content-center">
                            <ngx-barcode class="barcode1" [bc-value]="mrNumber" [bc-display-value]="displayValue1"
                              [bc-line-color]="lineColor1" [element-type]="elementType1" [format]="format"
                              [bc-width]="width1" [bc-height]="barcodeheight1" [bc-font-options]="fontOptions"
                              [bc-font]="font1" [bc-text-align]="textAlign1" [bc-text-position]="textPosition1"
                              [bc-text-margin]="textMargin1" [bc-font-size]="fontSize1" [bc-background]="background1"
                              [bc-margin]="margin1" [bc-margin-top]="marginTop1" [bc-margin-bottom]="marginBottom1"
                              [bc-margin-left]="marginLeft1" [bc-margin-right]="marginRight1"></ngx-barcode>
                          </div>
                        </div>

                      </div>
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>