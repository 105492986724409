<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white ">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Patient details</h5>
          </div>
          <!-- *ngIf="bookFlag" -->
          <div class="headerButtons">
            <a style="margin:0 5px;" (click)="back()">
              <img src="../../../assets/ui_icons/buttons/Back_button.svg" class="backimgbtn_inpatinfo" /></a>
            <a><img src="../../../assets/ui_icons/buttons/update_button.svg" class="saveimgbtn_inpatinfo"
                (click)="updatePatientDetails()" /></a>
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="" style="margin-top: -10px;">
            <div class="container-fluid" style="margin-left: -20px;">
              <div class="row" style="margin-right: -50px;">
                <div class="col-12">
                  <div class="cover_div" style="padding-bottom: 13px;">
                    <div class="header_lable">
                      Profile details
                    </div>
                    <div class="content_cover">
                      <div class="row">
                        <div class="col-2">
                          <p class="matlabel" style="margin-left: 25px;">Profile picture</p>
                          <div class="col-12 col-sm-6 col-md-3 col-lg-2 col-xl-2 mx-3">
                            <div class="small-12 medium-2 large-2 columns" *ngIf="!webcamImage">
                              <div class="circle">
                                <img *ngIf="thisshow" class="profile-pic" id="profile_img"  [src]="imgUrl ? imgUrl : 'https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg'">
                                  <img *ngIf="thisnot" class="profile-pic" id="profile_img"  [src]="imgUrll ? imgUrll : 'https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg'">
                                  <img *ngIf="!thisshow" class="profile-pic" [src]="imgUrll ? imgUrll : '../../assets/img/default.jpg'">
                                  <img *ngIf="!thisnot" class="profile-pic" [src]="imgUrll ? imgUrll : '../../assets/img/default.jpg'">
                                </div>
                              <div [ngClass]="styleOne ? 'p-image' : 'p-image-new'">
                                <button type="button" style="border: none; background: none;" data-bs-toggle="modal"
                                  data-bs-target="#profilePicModal">
                                
                                  <i style="font-size: 15px;" class="fa fa-camera upload-button"></i>
                                </button>
                              </div>
                            </div>
                            <!-- <input type="file" (change)="changeFile($event)" name="profile_pic" id="profile_pic"> -->
                            <div class="snapshot" *ngIf="webcamImage">
                              <div class="circle">
                                <img *ngIf="thisshow" class="profile-pic2" [src]="webcamImage.imageAsDataUrl" />
                                <img *ngIf="thisnot" class="profile-pic2" [src]="imgUrll" />
                                <img *ngIf="!thisshow" class="profile-pic" [src]="imgUrll ? imgUrll : '../../assets/img/default.jpg'">
                                <img *ngIf="!thisnot" class="profile-pic" [src]="imgUrll ? imgUrll : '../../assets/img/default.jpg'">
                                <!-- <img [src]="webcamImage.imageAsDataUrl" style="width: 160px;" /> -->
                              </div>
                              <div [ngClass]="styleOne ? 'p-image' : 'p-image-new'">
                                <button type="button" style="border: none; background: none;" data-bs-toggle="modal"
                                  data-bs-target="#profilePicModal">
                                  <i class="fa fa-camera upload-button"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div class="modal fade" id="profilePicModal" tabindex="-1" data-bs-backdrop="static" role="dialog" data-bs-keyboard="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog">
                              <div class="modal-content">
                                <div class="modal-header">

                                  <h5 class="modal-title" id="exampleModalLabel">ID Card</h5>
                                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            
                                  </button>
                                </div>
                                <div class="modal-body ">
                                  <div class="col-12 text-center">
                                    <webcam [height]="200" [width]="200" [trigger]="triggerObservable "
                                      (imageCapture)="handleImage($any($event))">
                                    </webcam>
            
                                    <div>
                                      <button class="button-34 my-2"  data-bs-dismiss="modal" (click)="triggerSnapshot();">
                                        Click Here and take the Shot</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                          <input style="width: 90px;" class="form-control form-control-sm" id="formFileSm" name="imageUrl" type="file" accept="image/*" (change)="handleInputChange($event)" />
                          <!-- <img src="../../../assets/ui_icons/buttons/Back_button1.svg" class="backimgbtn_inpatinfo" (click)="removeFile('image')" /> -->

                        </div>
                        </div>

                        
                        <!-- <div class="col-12 col-sm-6 col-md-2 col-lg-2 col-xl-2" style="text-align: center;">
                          <img src="{{this.profileImg}}" onerror="this.src='../../../assets/img/default.jpg';"
                            class="profileimage">
                        </div> -->
                        <div class="col-12 col-sm-6 col-md-10 col-lg-10 col-xl-10">
                          <div class="row">
                            <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                              <mat-label class="matlabel">Salutation
                                <br>
                                <select class="ipcss widthappt" [(ngModel)]="clntSal"
                                  (ngModelChange)="changeSalutation($event)">
                                  <option value="select" Selected>Select</option>
                                  <option *ngFor="let sal of clntSalList" value="{{sal.sal_id}}">{{sal.sal_desc}}
                                  </option>
                                </select>
                              </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                              <mat-label class="matlabel">First name
                                <input class="ipcss widthappt" [(ngModel)]="clntFirstName" (blur)="fnameToUpper()"
                                  matInput />
                              </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                              <mat-label class="matlabel">Middle name
                                <input class="ipcss widthappt" [(ngModel)]="clntMiddleName" (blur)="mnameToUpper()"
                                  matInput />
                              </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                              <mat-label class="matlabel">Last name
                                <input class="ipcss widthappt" [(ngModel)]="clntLastName" (blur)="lnameToUpper()"
                                  matInput />
                              </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                              <mat-label class="matlabel">DOB
                                <input type="date" class="ipcss_date noappt_width heightdob"
                                  (change)="selectDob(clntDOB)" [(ngModel)]="clntDOB" #matInput>
                              </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                              <mat-label class="matlabel">Age
                                <input [(ngModel)]="clntAge" [readonly]="ageRead" [required]="!ageRead"
                                  (keyup)="ChangeAge(clntAge)" class="ipcss widthappt" required matInput>
                              </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                              <mat-label class="matlabel">Mobile
                                <input [(ngModel)]="clntContNumber" maxlength="10" required class="ipcss widthappt"
                                  matInput>
                              </mat-label>
                            </div>

                            <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                              <mat-label class="matlabel">Gender<br>
                                <select [(ngModel)]="clntGender" class="ipcss widthappt"
                                  (ngModelChange)="changeGender($event)">
                                  <option value="Male">Male</option>
                                  <option value="Female">Female</option>
                                  <option value="Transgender">Transgender</option>
                                </select>
                              </mat-label>
                            </div>
                          </div>
                   
                        </div>
                      </div>
                      <div class="row">

                        <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                          <mat-label class="matlabel">Address1
                            <input type="text" class="ipcss widthappt" [(ngModel)]="clntAddress1" required
                              matInput />
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                          <mat-label class="matlabel">Address2
                            <input type="text" class="ipcss widthappt" [(ngModel)]="clntAddress2" matInput />
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                          <mat-label class="matlabel">Zipcode
                            <input type="text" class="ipcss widthappt" [(ngModel)]="clntZipcode"
                              (keyup)=zipcode_change() matInput />
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                          <mat-label class="matlabel">Location<br>
                            <input type="text" class="ipcss widthappt" required maxlength="50"
                              [(ngModel)]="clntLocation" (keyup)="changeLocation(clntLocation)" matInput />
                            <div class="auto_complete_text" *ngIf="clntLocationList.length != 0">
                              <ul *ngFor="let location of clntLocationList">
                                <li>
                                  <a (click)="selectLocation(location)">{{location.description}}</a>
                                </li>
                              </ul>
                            </div>
                          </mat-label>
                        </div>

                        <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                          <mat-label class="matlabel">District / City<br>
                            <mat-select required class="ipcss widthappt" [(ngModel)]="clntCity"
                              disableOptionCentering (ngModelChange)="changeCity(clntCity, '0')">
                              <mat-option *ngFor="let city of clntCityList" value="{{city.city_desc}}">
                                {{city.city_desc}}
                              </mat-option>
                            </mat-select>
                          </mat-label>
                        </div>

                        <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                          <mat-label class="matlabel">State<br>
                            <mat-select required class="ipcss widthappt" [(ngModel)]="clntState"
                              disableOptionCentering (ngModelChange)="changeState(clntState, '0')">
                              <mat-option *ngFor="let state of clntStateList" value={{state.state_desc}}>
                                {{state.state_desc}}
                              </mat-option>
                            </mat-select>
                          </mat-label>
                        </div>

                        <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                          <mat-label class="matlabel">Country<br>
                            <mat-select required [(ngModel)]="clntCountry" class="ipcss widthappt"
                              disableOptionCentering>
                              <mat-option *ngFor="let country of clntCountryList" value={{country.country_desc}}>
                                {{country.country_desc}}</mat-option>
                            </mat-select>
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                          <mat-label class="matlabel"> Referred by<br>
                            <input type="text" class="ipcss widthappt" maxlength="50" (keyup)="getReferral($event)"
                              [(ngModel)]="referredDoctor" matInput [matAutocomplete]="auto" />
                            <mat-autocomplete #auto="matAutocomplete">
                              <mat-option (click)="selectReferredDoctor(item)"
                                *ngFor="let item of referredDoctorList" value={{item}}>
                                {{item}}
                              </mat-option>
                            </mat-autocomplete>
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                          <mat-label class="matlabel">Channel<br>                         
                            <select class="ipcss widthappt" [(ngModel)]="ref_source" 
                            (change)="changeref(ref_source)">
                              <option *ngFor="let doctor of ref_sourcearray" value="{{doctor.lead_type_id}}">
                                {{doctor.lead_desc}}
                              </option>
                            </select>
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                          <mat-label class="matlabel">Emergency contact1
                            <input [(ngModel)]="clntEmergNumber" maxlength="15" class="ipcss widthappt"
                              onkeypress="return event.charCode >= 48 && event.charCode <= 57" matInput>
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                          <mat-label class="matlabel">Emergency contact2
                            <input [(ngModel)]="clntEmergNumber1" maxlength="15" class="ipcss widthappt"
                              onkeypress="return event.charCode >= 48 && event.charCode <= 57" matInput>
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                          <mat-label class="matlabel">Blood Group<br>
                            <mat-select disableOptionCentering class="ipcss" [(ngModel)]="bloodGroup">
                              <mat-option disabled>Select</mat-option>
                              <mat-option *ngFor="let blood of bloodGroupTestArray" [value]="blood.description">
                                {{blood.description}}</mat-option>
                            </mat-select>
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                          <mat-label class="matlabel">Primary Language<br>
                            <mat-select class="ipcss" [(ngModel)]="primlanguage">
                              <mat-option disabled>Select</mat-option>
                              <mat-option *ngFor="let language of languageDataArray" [value]="language.language_id">
                                {{language.description}}</mat-option>
                            </mat-select>
                          </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                          <mat-label class="matlabel">Aadhaar Number
                            <input [(ngModel)]="aadharNumber" maxlength="12" (blur)="validateAadhar()"
                            class="ipcss widthappt"
                              onkeypress="return event.charCode >= 48 && event.charCode <= 57" matInput>
                          </mat-label>
                        </div>

                        <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                          <mat-label class="matlabel">Whatsapp Number
                            <input [(ngModel)]="whatsappNumber" maxlength="10" (blur)="validatewhatsapp()"
                              class="ipcss widthappt"
                              onkeypress="return event.charCode >= 48 && event.charCode <= 57" matInput>
                          </mat-label>
                        </div>
                        <div>

                       
                        <!-- <div class="12" *ngIf="imageflag">
                          <img src="{{this.uploadidproof}}" onerror="this.src='../../../assets/img/default.jpg';"
                            class="idproof">
                        </div>
                        <div class="12" *ngIf="!imageflag">
                          <iframe src="{{this.uploadidproof}}" width="100%" height="600px"></iframe>

                        </div> -->
                      </div>
                      <div class="col-8" style="margin-top: 10px;">
                        <mat-label class="matlabel">Id proof
                          <br>
                        <div class="rectangle">
                          <img *ngIf="thisnota" class="profile-pic2 fileuppic"  [src]="webcamImage2.imageAsDataUrl"/>
                       
                          <img *ngIf="thisshowa" class="profile-pic2 fileuppic" [src]="imgUrl2" /> 
                          <img *ngIf="!thisshowa" class="profile-pic2 fileuppic" [src]="imgUrl2 ? imgUrl2 : '../../assets/img/default.jpg'">
                          <img *ngIf="!thisnota" class="profile-pic2 fileuppic" [src]="imgUrl2 ? imgUrl2 : '../../assets/img/default.jpg'">
                        </div>    
                        <div class="chooseFile-container">
                          <div>
                          <input style="width: 90px;" class="form-control form-control-sm" id="formFileSma"
                            name="imageUrl2" type="file" accept="image/*" (change)="handleInputChange1($event)" />
                            <!-- <img src="../../../assets/ui_icons/buttons/Back_button1.svg" class="backimgbtn_inpatinfo" (click)="removeFile('id')" /> -->
                          </div>
                          <img src="../../../assets/img/camera.svg" data-bs-toggle="modal" data-bs-target="#idproofPicModal"
                            class="camerasimg" (click)="changeidproofimage()">
                        </div>        
                        </mat-label>
                      </div>
                      <div class="modal fade" id="idproofPicModal" tabindex="-1" role="dialog"
                      aria-labelledby="exampleModalLabel" aria-hidden="true">
                      <div class="modal-dialog">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Profile Picture</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                            </button>
                          </div>
                          <div class="modal-body ">
                            <div class="col-12 text-center">
                              <webcam [height]="200" [width]="200" [trigger]="triggerObservable2"
                                (imageCapture)="handleImage2($any($event))">
                              </webcam>
      
                              <div>
                                <button class="button-34 my-2" data-bs-dismiss="modal" (click)="triggerSnapshot2();">
                                  Click Here and take the Shot</button>
                              </div>
      
                              <div class="snapshot" *ngIf="webcamImage2">
                                <!-- <h2>Here is your image!</h2> -->
                                <!-- <img [src]="webcamImage2.imageAsDataUrl" style="width: 160px;" /> -->
                              </div>
                            </div>
                          </div>
                          <!-- <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Save</button>
                          </div> -->
                        </div>
                      </div>
                    </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card> <br><br><br>
  </div>
</div>
