import { Component, OnInit ,ElementRef,Input,Inject,ViewEncapsulation,HostListener} from '@angular/core';
import { Http, Headers } from '@angular/http';
// import { ipaddress.getIp, Ip_with_img_address, ipaddress.country_code } from '../../../assets/js/ipaddress';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ipaddress } from '../../ipaddress' ;
@Component({
  selector: 'app-inpat-medpresc-view',
  templateUrl: './inpat-medpresc-view.component.html',
  styleUrls: ['./inpat-medpresc-view.component.scss']
})
export class InpatMedprescViewComponent implements OnInit {

  public viewpreslist_array=[];
  public patid: string;
  public radioarraynew:any=[];
  public labarraynew:any=[];
  public print_labarray:any=[];
  public print_labarraynew:any=[];
  public print_radioarray:any=[];
  public print_radioarraynew:any=[];
  public url_prescription;
  public med_view_list;
  public prescription_pres_id;
  public labarray:any=[];
  public radioarray:any=[];
  public viewpreslist_arraynew;
  public days
  constructor(public http:Http, public el: ElementRef,public dialogRef: MatDialogRef<InpatMedprescViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {    
      dialogRef.disableClose = true;
      this.patid = data.inpatient_id;
   }

  ngOnInit(): void {
    console.log("check sned ---"+JSON.stringify({
      inpatient_id: this.patid,
      country:ipaddress.country_code,
      spl_name: "General",
    }))

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'prescription/mpbyinpid/',
      JSON.stringify({
        inpatient_id: this.patid,
        country:ipaddress.country_code,
        spl_name: "General",

      }),
      { headers: headers })
      .subscribe(
        response => {
         
          var obj = JSON.parse(response["_body"]);
          console.log("check daata---"+JSON.stringify(obj))
          if (obj.prescriptions != null) {
            var spl_view_med_preslist = obj.prescriptions;
            // this.key1="Prescriptions"
            if (spl_view_med_preslist.length != 0) {
              for (var i = 0; i < spl_view_med_preslist.length; i++) {
                var med_format = null;
                var dateview = spl_view_med_preslist[i].date.split('-');
                var medicines =[];

                for (var j = 0; j < spl_view_med_preslist[i].drug_list.length; j++) {
                  var drugintakevalue;
                  var shortform = "";
                  shortform = spl_view_med_preslist[i].drug_list[j].short_form;
                  if(spl_view_med_preslist[i].drug_list[j].show_short_form =="1"){
                    
                    if(spl_view_med_preslist[i].drug_list[j].intake =="1"){

                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+shortform+' - '+spl_view_med_preslist[i].drug_list[j].afternoon+shortform+' - '+spl_view_med_preslist[i].drug_list[j].evening+shortform+' - '+spl_view_med_preslist[i].drug_list[j].night+shortform;

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="2"){

                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+shortform+' - '+spl_view_med_preslist[i].drug_list[j].afternoon+shortform+' - '+spl_view_med_preslist[i].drug_list[j].evening+shortform+' - '+spl_view_med_preslist[i].drug_list[j].night+shortform;

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="3"){

                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+shortform+' - '+spl_view_med_preslist[i].drug_list[j].afternoon+shortform+' - '+spl_view_med_preslist[i].drug_list[j].evening+shortform+' - '+spl_view_med_preslist[i].drug_list[j].night+shortform;

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="4"){

                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+shortform+' - '+spl_view_med_preslist[i].drug_list[j].afternoon+shortform+' - '+spl_view_med_preslist[i].drug_list[j].evening+shortform+' - '+spl_view_med_preslist[i].drug_list[j].night+shortform;

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="5"){

                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+shortform+' - '+spl_view_med_preslist[i].drug_list[j].afternoon+shortform+' - '+spl_view_med_preslist[i].drug_list[j].evening+shortform+' - '+spl_view_med_preslist[i].drug_list[j].night+shortform;

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="6"){

                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+shortform+' - '+spl_view_med_preslist[i].drug_list[j].afternoon+shortform+' - '+spl_view_med_preslist[i].drug_list[j].evening+shortform+' - '+spl_view_med_preslist[i].drug_list[j].night+shortform;
                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="7"){

                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+shortform+' - '+spl_view_med_preslist[i].drug_list[j].afternoon+shortform+' - '+spl_view_med_preslist[i].drug_list[j].evening+shortform+' - '+spl_view_med_preslist[i].drug_list[j].night+shortform;
                    }else if(spl_view_med_preslist[i].drug_list[j].intake =="8"){

                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+shortform+' - '+spl_view_med_preslist[i].drug_list[j].afternoon+shortform+' - '+spl_view_med_preslist[i].drug_list[j].evening+shortform+' - '+spl_view_med_preslist[i].drug_list[j].night+shortform;

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="9"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+" - Every 6 hours";

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="10"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+" - Every 8 hours";

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="11"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+" - Every 12 hours";

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="12"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+" - Once a day";

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="13"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+" SOS - if required";

                    }else if(spl_view_med_preslist[i].drug_list[j].intake =="14"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+spl_view_med_preslist[i].drug_list[j].short_form+" - "+spl_view_med_preslist[i].drug_list[j].afternoon+spl_view_med_preslist[i].drug_list[j].short_form+" - "+spl_view_med_preslist[i].drug_list[j].evening+spl_view_med_preslist[i].drug_list[j].short_form+" - "+spl_view_med_preslist[i].drug_list[j].night+spl_view_med_preslist[i].drug_list[j].short_form +" Alternative days";

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="15"){

                        drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+shortform+' - '+spl_view_med_preslist[i].drug_list[j].afternoon+shortform+' - '+spl_view_med_preslist[i].drug_list[j].evening+shortform+' - '+spl_view_med_preslist[i].drug_list[j].night+shortform;

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="16"){

                        drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+shortform+' - '+spl_view_med_preslist[i].drug_list[j].afternoon+shortform+' - '+spl_view_med_preslist[i].drug_list[j].evening+shortform+' - '+spl_view_med_preslist[i].drug_list[j].night+shortform;

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="17"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+ shortform +" STAT";
                    } 
                    else if(spl_view_med_preslist[i].drug_list[j].intake =="18"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+ " Once a month";
                    } 
                    else if(spl_view_med_preslist[i].drug_list[j].intake =="19"){
                        drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+shortform+' - '+spl_view_med_preslist[i].drug_list[j].afternoon+shortform+' - '+spl_view_med_preslist[i].drug_list[j].evening+shortform+' - '+spl_view_med_preslist[i].drug_list[j].night+shortform;
                    } 
                    else if(spl_view_med_preslist[i].drug_list[j].intake =="20"){

                        drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+shortform+' - '+spl_view_med_preslist[i].drug_list[j].afternoon+shortform+' - '+spl_view_med_preslist[i].drug_list[j].evening+shortform+' - '+spl_view_med_preslist[i].drug_list[j].night+shortform;
                    } 
                    else if(spl_view_med_preslist[i].drug_list[j].intake =="21"){
                        drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+shortform+' - '+spl_view_med_preslist[i].drug_list[j].afternoon+shortform+' - '+spl_view_med_preslist[i].drug_list[j].evening+shortform+' - '+spl_view_med_preslist[i].drug_list[j].night+shortform;
                    } 
                    else if(spl_view_med_preslist[i].drug_list[j].intake =="22"){
                        drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+shortform+' - '+spl_view_med_preslist[i].drug_list[j].afternoon+shortform+' - '+spl_view_med_preslist[i].drug_list[j].evening+shortform+' - '+spl_view_med_preslist[i].drug_list[j].night+shortform;
                    } 
                    else if(spl_view_med_preslist[i].drug_list[j].intake =="23"){
                        drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+shortform+' - '+spl_view_med_preslist[i].drug_list[j].afternoon+shortform+' - '+spl_view_med_preslist[i].drug_list[j].evening+shortform+' - '+spl_view_med_preslist[i].drug_list[j].night+shortform;
                    } 

                    if (spl_view_med_preslist[i].drug_list[j].time_duration != null && spl_view_med_preslist[i].drug_list[j].time_duration != undefined) {
                      med_format = drugintakevalue + "  -  " + spl_view_med_preslist[i].drug_list[j].time_duration + " mins " + spl_view_med_preslist[i].drug_list[j].drug_intake;

                    } else {
                      med_format = drugintakevalue + "  -  " + spl_view_med_preslist[i].drug_list[j].drug_intake;
                    }

                  } else {
                    if(spl_view_med_preslist[i].drug_list[j].intake =="1"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+" - "+spl_view_med_preslist[i].drug_list[j].afternoon+" - "+spl_view_med_preslist[i].drug_list[j].evening+" - "+spl_view_med_preslist[i].drug_list[j].night;

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="2"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+" - "+spl_view_med_preslist[i].drug_list[j].afternoon+" - "+spl_view_med_preslist[i].drug_list[j].evening+" - "+spl_view_med_preslist[i].drug_list[j].night;

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="3"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+" - "+spl_view_med_preslist[i].drug_list[j].afternoon+" - "+spl_view_med_preslist[i].drug_list[j].evening+" - "+spl_view_med_preslist[i].drug_list[j].night;

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="4"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+" - "+spl_view_med_preslist[i].drug_list[j].afternoon+" - "+spl_view_med_preslist[i].drug_list[j].evening+" - "+spl_view_med_preslist[i].drug_list[j].night;

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="5"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+" - "+spl_view_med_preslist[i].drug_list[j].afternoon+" - "+spl_view_med_preslist[i].drug_list[j].evening+" - "+spl_view_med_preslist[i].drug_list[j].night;

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="6"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+" - "+spl_view_med_preslist[i].drug_list[j].afternoon+" - "+spl_view_med_preslist[i].drug_list[j].evening+" - "+spl_view_med_preslist[i].drug_list[j].night;

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="7"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+" - "+spl_view_med_preslist[i].drug_list[j].afternoon+" - "+spl_view_med_preslist[i].drug_list[j].evening+" - "+spl_view_med_preslist[i].drug_list[j].night;
                    }else if(spl_view_med_preslist[i].drug_list[j].intake =="8"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+" - "+spl_view_med_preslist[i].drug_list[j].afternoon+" - "+spl_view_med_preslist[i].drug_list[j].evening+" - "+spl_view_med_preslist[i].drug_list[j].night;

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="9"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+" - Every 6 hours";

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="10"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+" - Every 8 hours";

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="11"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+" - Every 12 hours";

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="12"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+" - Once a day";

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="13"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+" SOS - if required";

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="14"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+" - "+spl_view_med_preslist[i].drug_list[j].afternoon+" - "+spl_view_med_preslist[i].drug_list[j].evening+" - "+spl_view_med_preslist[i].drug_list[j].night;

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="15"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+" - "+spl_view_med_preslist[i].drug_list[j].afternoon+" - "+spl_view_med_preslist[i].drug_list[j].evening+" - "+spl_view_med_preslist[i].drug_list[j].night;

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="16"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+" - "+spl_view_med_preslist[i].drug_list[j].afternoon+" - "+spl_view_med_preslist[i].drug_list[j].evening+" - "+spl_view_med_preslist[i].drug_list[j].night;

                    } else if(spl_view_med_preslist[i].drug_list[j].intake =="17"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+" STAT";
                    } 
                    else if(spl_view_med_preslist[i].drug_list[j].intake =="18"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+ " Once a month";
                    } 
                    else if(spl_view_med_preslist[i].drug_list[j].intake =="19"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+" - "+spl_view_med_preslist[i].drug_list[j].afternoon+" - "+spl_view_med_preslist[i].drug_list[j].evening+" - "+spl_view_med_preslist[i].drug_list[j].night;
                    } 
                    else if(spl_view_med_preslist[i].drug_list[j].intake =="20"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+" - "+spl_view_med_preslist[i].drug_list[j].afternoon+" - "+spl_view_med_preslist[i].drug_list[j].evening+" - "+spl_view_med_preslist[i].drug_list[j].night;
                    } 
                    else if(spl_view_med_preslist[i].drug_list[j].intake =="21"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+" - "+spl_view_med_preslist[i].drug_list[j].afternoon+" - "+spl_view_med_preslist[i].drug_list[j].evening+" - "+spl_view_med_preslist[i].drug_list[j].night;
                    } 
                    else if(spl_view_med_preslist[i].drug_list[j].intake =="22"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+" - "+spl_view_med_preslist[i].drug_list[j].afternoon+" - "+spl_view_med_preslist[i].drug_list[j].evening+" - "+spl_view_med_preslist[i].drug_list[j].night;
                    }  
                    else if(spl_view_med_preslist[i].drug_list[j].intake =="23"){
                      drugintakevalue=spl_view_med_preslist[i].drug_list[j].morning+" - "+spl_view_med_preslist[i].drug_list[j].afternoon+" - "+spl_view_med_preslist[i].drug_list[j].evening+" - "+spl_view_med_preslist[i].drug_list[j].night;
                    } 
                    console.log("time_duration medicines"+JSON.stringify(spl_view_med_preslist[i].drug_list[j].time_duration))
                    if (spl_view_med_preslist[i].drug_list[j].time_duration != "" && spl_view_med_preslist[i].drug_list[j].time_duration != undefined) {
                      if(spl_view_med_preslist[i].drug_list[j].drug_intake !="" && spl_view_med_preslist[i].drug_list[j].drug_intake != undefined){
                        med_format = drugintakevalue + "  -  " + spl_view_med_preslist[i].drug_list[j].time_duration + " mins " + spl_view_med_preslist[i].drug_list[j].drug_intake;

                      }else{
                        med_format = drugintakevalue + "  -  " + spl_view_med_preslist[i].drug_list[j].time_duration + " mins ";

                      }

                    } else {
                      med_format = drugintakevalue + " " + spl_view_med_preslist[i].drug_list[j].drug_intake;
                    }
                  }
                  console.log("pres medicines"+JSON.stringify(drugintakevalue)+spl_view_med_preslist[i].drug_list[j].time_duration+"?????"+spl_view_med_preslist[i].drug_list[j].drug_intake)
                  if(spl_view_med_preslist[i].drug_list[j].days != 0 && spl_view_med_preslist[i].drug_list[j].days != ""){
                    if(spl_view_med_preslist[i].drug_list[j].period != undefined && spl_view_med_preslist[i].drug_list[j].period != ""){
                      this.days =spl_view_med_preslist[i].drug_list[j].short_name + "  " + spl_view_med_preslist[i].drug_list[j].drug_name + " " + spl_view_med_preslist[i].drug_list[j].days + " "+spl_view_med_preslist[i].drug_list[j].period

                    }else{
                      this.days =spl_view_med_preslist[i].drug_list[j].short_name + "  " + spl_view_med_preslist[i].drug_list[j].drug_name + " " + spl_view_med_preslist[i].drug_list[j].days 

                    }
                  }else{
                    if(spl_view_med_preslist[i].drug_list[j].period != undefined && spl_view_med_preslist[i].drug_list[j].period != ""){
                      this.days =spl_view_med_preslist[i].drug_list[j].short_name + "  " + spl_view_med_preslist[i].drug_list[j].drug_name + " "+spl_view_med_preslist[i].drug_list[j].period

                    }else{
                      this.days =spl_view_med_preslist[i].drug_list[j].short_name + "  " + spl_view_med_preslist[i].drug_list[j].drug_name 

                    }
                  }
                      medicines.push({
                        drug: this.days,
                        catagory: med_format
                      })
                      console.log("pres medicines"+JSON.stringify(medicines))
                 }

                var docname;
                if (spl_view_med_preslist[i].middle_name != undefined && spl_view_med_preslist[i].middle_name != null) {
                  docname = "Dr. " + spl_view_med_preslist[i].first_name + " " + spl_view_med_preslist[i].middle_name + " " + spl_view_med_preslist[i].last_name

                } else {
                  docname = "Dr. " + spl_view_med_preslist[i].first_name + " " + spl_view_med_preslist[i].last_name
                }
                if(obj.Laboratory != undefined){
                  if(obj.Laboratory.length != 0){

                  }
                }

                this.viewpreslist_array.push({
                  tot_doct_name: docname,
                  pres_drug_id: spl_view_med_preslist[i].pres_drug_id,
                  view_date: "Date :" + dateview[2] + '-' + dateview[1] + '-' + dateview[0],
                  medicines: medicines,
                });
                console.log("pres 0000"+JSON.stringify(this.viewpreslist_array))
              }
              if (this.viewpreslist_array.length != 0) {
                //view_rxbtn = true;
              }
            }
          }
          if (obj.Laboratory != undefined) {
            for (var i = 0; i < obj.Laboratory.length; i++) {
            this.labarray.push({
              key:"Laboratory",
              testname:obj.Laboratory[i].test_name
            })
          }
          if (obj.Radiology != undefined) {
            for (var i = 0; i < obj.Radiology.length; i++) {
            this.radioarray.push({
              key:"Radiology",
              testname:obj.Laboratory[i].test_name
            })
          }
            // this.key2
          }
          if(obj.next_lab
            != undefined){
            for(var i=0;i<obj.next_lab.length;i++){
              this.print_labarraynew.push({
              test_name:obj.next_lab[i].test_name
            })
            this.labarraynew.push({
              test_name:obj.next_lab[i].test_name
            })
          }
          }
          if(obj.next_radio != undefined){
            for(var k=0;k<obj.next_radio.length;k++){
              this.print_radioarraynew.push({
                test_name:obj.next_radio[k].test_name
              })
              this.radioarraynew.push({
                test_name:obj.next_radio[k].test_name
              })
            }
          }
        }
          
        },
        error => {

        }
      )  

  }

  ClosePopup(){
   // "close")
    this.dialogRef.close();
  }

}
