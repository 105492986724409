import { Component, OnInit } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { CommonDataService } from '../../providers/common-data.service';
import { ToastrService } from 'ngx-toastr';
import { Diagnosis_Helper } from '../Diagnosis_Helper';
import { ipaddress } from '../../ipaddress';
import { Message_data } from '../../../assets/js/Message_data';
import { MenuViewService } from '../../../app/common-module/menu-view/menu-view.service';
import { Helper_Class } from '../../../app/helper_class';

@Component({
  selector: 'app-diag-test-create-adv',
  templateUrl: './diag-test-create-adv.component.html',
  styleUrls: ['./diag-test-create-adv.component.scss']
})
export class DiagTestCreateAdvComponent implements OnInit {

  public diagnosisapp_id;
  public test_category_data = [];
  public dis_category_data = [];
  public Subtype_data = [];
  public diag_subtest_id;
  public Diag_Subtest_name;
  public diagSubUOM;
  public male_min;
  public male_max;
  public female_min;
  public female_max;
  public child_min;
  public child_max;
  public Diag_child_data;
  public born_min;
  public born_max;
  public Diag_newborn_data;
  public Diag_subtest_list_data = [];
  public Send_sublist_data = [];
  public Diag_test_id;
  public Diag_TestName;
  public diag_test_categ;
  public diag_dis_categ;
  public dis_test_categ;
  public Diag_test_subtype;
  public Diag_Uof;
  public Diag_test_cost;
  public test_price;
  public diag_add_test_id;
  public diag_cat_id;
  public diag_dis_id;
  public diag_subcat_id;
  public save_btn: boolean;
  public update_btn: boolean;
  public header_footer_flag: boolean;
  public loader: boolean;
  public unithidden: boolean;
  public diagsubhidden: boolean = true;
  public gettestdata;
  public Select = "Select";
  public addTest_flag: boolean = true;
  public subtest_flag: boolean = true;
  public test_method;
  public test_method_flag: boolean;
  public addSubtest_checked: boolean = false;
  public main_test_uom_flag: boolean = true;
  public ref_flag: boolean = false;
  public male_ref;
  public female_ref;
  public child_ref;
  public newborn_ref;

  public referenceArray: any = [];
  public referenceArray_2: any = [];
  public age_ref;
  public gender_ref = "All";
  public ref_value;
  public min_ref_value;
  public max_ref_value;

  public diag_dept;
  public department_data: any = [];
  public reagents_data: any = [];
  public reagent_id;
  public Diag_testtube_id;
  public testtube_data: any = [];
  public Sample_type;
  public sampletype_data: any[];
  public age_input;
  public age_input_max
  public frequency_Value;
  public frequency_Measure;
  public outsource_cost;
  public insource_cost;
  public conduction_format = '2';
  public show_test_ng: boolean = true;
  public conduct_doctor_ng;
  public conduct_doctor = '0';
  public con_doc_check: boolean = false;
  public min_nor_value;
  public max_nor_value;
  public min_anor_value;
  public max_anor_value;
  public min_crit_value;
  public max_crit_value;
  public UOM_list: any = [];
  public unit_of_measure: any;
  public inpat_outpt;
  public age_grp_check: boolean = true;
  public show_test = '0';

  public newbutton: boolean = false
  public editbutton: boolean = false;
  public deletebutton: boolean = false;
  public printbutton: boolean = false;
  public viewbutton: boolean = false;
  public moduleList: any = [];
  public is_active: boolean = true;
  public is_specimen_req: boolean = true;
  public specimenTypeFlag: boolean = false;

  //mdbTable
  dtOptions: DataTables.Settings = {};
  public range;
  modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      // [{ 'header': 1 }, { 'header': 2 }], // custom button values
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'script': 'sub' }, { 'script': 'super' }], // superscript/subscript
      [{ 'indent': '-1' }, { 'indent': '+1' }], // outdent/indent
      [{ 'direction': 'rtl' }], // text direction
      [{ 'size': ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      [{ 'color': [] }, { 'background': [] }], // dropdown with defaults from theme
      [{ 'font': [] }],
      [{ 'align': [] }],
      ['clean'], // remove formatting button
      // ['link', 'image'], // link and image, video
      // ['table']
    ]
  };
  editorStyle = {
    height: '300px;',
  };
  public templates: any = [];
  public report_template: any;
  public Diag_TAT: any;
  public conductionFormatarr: any = [];
  public templateFlag: boolean = true;
  public cbt_Template: any;
  template_flag: boolean = true;
  diagsub: boolean;
  public showAgeInp: boolean = true;
  public collect_TAT: any;
  public assert_TAT: any;
  public upload_TAT: any;
  public approve_TAT: any;
  public total_TAT: any;
  public order_no: any = '';
  public conduction_format_disable: boolean = false;
  public previous_order_no: any = '';


  constructor(public diagnosisservice: MenuViewService, public toastr: ToastrService, public gservice: CommonDataService, public http: Http, public dialog: MatDialog) {
    this.unithidden = false;
  }

  async ngOnInit(): Promise<void> {
    this.moduleList = Helper_Class.getmodulelist();

    if (this.moduleList != undefined) {
      for (var i = 0; i < this.moduleList.length; i++) {
        if (this.moduleList[i].module_id == "102") {
          console.log(JSON.stringify(this.moduleList[i]));
          if (this.moduleList[i].edit == "1") {
            this.editbutton = true;//update button
          }
          if (this.moduleList[i].create == "1") {
            this.newbutton = true;
          }
          if (this.moduleList[i].delete == "1") {
            this.deletebutton = true;
          }
          if (this.moduleList[i].print == "1") {
            this.printbutton = true;
          }
          if (this.moduleList[i].view == "1") {
            this.viewbutton = true;
          }
        }
      }
    }

    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      "lengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
      "order": [[2, 'asc']],
      "columnDefs": [
        { "orderable": false, "targets": [0, 1, 3, 4, 5, 6, 7] },
      ],
      "language": {
        "search": "Filter"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"l><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };

    if (Helper_Class.getInfo().hospitals[0].test_range == "1") {
      this.ref_flag = true;

    } else {
      this.ref_flag = false;
    }

    this.gettestdata = Diagnosis_Helper.getdiagnosistest();
    this.Diag_test_id = this.gettestdata.Diag_test_id;
    this.diagnosisapp_id = this.gettestdata.diagnosis_id;
    this.show_test_ng=true;
    this.show_test = "1"

    if (this.Diag_test_id != undefined) {
      this.save_btn = true; // Save & Update btn
      this.update_btn = true;
      this.getTestDetails(this.Diag_test_id)
    } else {
      this.save_btn = false; // Save & Update btn
      this.update_btn = false;
      this.getDiseaseCategory('0');
      this.getTestCategory('1', '0');
      this.getSubtypes('0');
    }

    this.getDiagDepartment();
    this.getReagents();
    this.getDiagTesttubes();
    this.getReportTemplate();
    await this.getConductionFormats();
    this.showOrhide_test(true);
    // this.formatChange(this.conduction_format)
  }

  

  subTypeChange() {
    if (this.Diag_test_subtype == "Blood Test"
      || this.Diag_test_subtype == "Urine Test"
      || this.Diag_test_subtype == "Faeces Test") {
      // this.addTest_flag = false;
      this.unithidden = false;
      this.diagsubhidden = false;
      this.specimenTypeFlag = false;
      // this.ref_flag = true;
      this.getUnitOfMeasure(this.Diag_test_subtype);
    } else {
      // this.addTest_flag = true;
      this.unithidden = true;
      this.diagsubhidden = true;
      this.specimenTypeFlag = true;
      // this.ref_flag = false;
    }
  }

  getDiseaseCategory(categ_id) {//get disease cateegory
    this.dis_category_data = [];

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'diagnosiscontroller/ddcat',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.dis_category_data = obj.test_categories;

          if (categ_id != "0" && categ_id != undefined) {
            for (var i = 0; i < this.dis_category_data.length; i++) {
              if (this.dis_category_data[i].diag_dis_cat_id == categ_id) {
                this.diag_dis_categ = this.dis_category_data[i].description;
                break;
              }
            }
          }
        }, error => { });
  }

  getTestCategory(deptId, categ_id) {//get disease cateegory
    if (deptId == "1"){
      this.formatChange(this.conduction_format)
      // this.conduction_format = "2";
      this.conduction_format_disable = false;
    } else {
      this.formatChange("1")
      this.conduction_format = "1";
      this.conduction_format_disable = true;
    }

    this.test_category_data = [];

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/dtcatn', JSON.stringify({ diag_centre_id: this.diagnosisapp_id, diag_dept_id: deptId }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.test_category_data = obj.test_categories;
          this.previous_order_no = obj.test_order;
          this.order_no = obj.test_order;

          if (categ_id != "0" && categ_id != undefined) {
            for (var i = 0; i < this.test_category_data.length; i++) {
              if (this.test_category_data[i].diag_test_cat_id == categ_id) {
                this.diag_test_categ = this.test_category_data[i].description;
                break;
              }
            }
          }
          console.log('this.test_category_data', obj)
        }, error => { });
  }

  checkOrder(data){
    if(parseInt(data) < parseInt(this.previous_order_no)){
      this.toastr.error("Test order already exist")
    }
  }

  getSubtypes(subtest_id) {//Get subtype
    this.Subtype_data = [];

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'diagnosiscontroller/dttypes',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.Subtype_data = obj.diag_test_types;

          if (subtest_id != null) {
            for (var i = 0; i < this.Subtype_data.length; i++) {
              if (this.Subtype_data[i].diag_test_type_id == subtest_id) {
                this.Diag_test_subtype = this.Subtype_data[i].test_type_name;
                this.subTypeChange();
                break;
              }
            }
          }
        }, error => { });
  }

  Subtest_Select_Click(subtest_data) {
    for (var i = 0; i < this.Diag_subtest_list_data.length; i++) {
      if (this.Diag_subtest_list_data[i].sub_test_name == subtest_data) {
        this.diag_subtest_id = this.Diag_subtest_list_data[i].sub_test_id;
        this.Diag_Subtest_name = this.Diag_subtest_list_data[i].sub_test_name;
        // this.frequency_Value = this.Diag_subtest_list_data[i].frequency_val;
        // this.frequency_Measure = this.Diag_subtest_list_data[i].frequency_measure;
        this.diagSubUOM = this.Diag_subtest_list_data[i].sub_uom;
        this.range = this.Diag_subtest_list_data[i].range;
        this.male_ref = this.Diag_subtest_list_data[i].maleref;
        this.female_ref = this.Diag_subtest_list_data[i].femaleref;
        this.child_ref = this.Diag_subtest_list_data[i].childref;
        this.newborn_ref = this.Diag_subtest_list_data[i].newbornref;
        this.test_method = this.Diag_subtest_list_data[i].test_method;
        if(this.Diag_subtest_list_data[i].ref_range == undefined){
          this.referenceArray = [];  
        } else {
          this.referenceArray = this.Diag_subtest_list_data[i].ref_range;
        }
        
        this.Diag_subtest_list_data.splice(i, 1);
        break;
      }
    }
  }

  Subtest_Delete_Click(subtest_data) {//subtest delete
    for (var i = 0; i < this.Diag_subtest_list_data.length; i++) {
      if (this.Diag_subtest_list_data[i].sub_test_name == subtest_data) {
        this.Diag_subtest_list_data[i].status = 0;
        break;
      }
    }
  }

  test_add() {//add tests 
    var Valid_flag = false;

    if (this.Diag_Subtest_name == undefined || this.Diag_Subtest_name == "") {
      if (this.Diag_TestName == undefined || this.Diag_TestName == "")
        Valid_flag = true;
      else
        this.Diag_Subtest_name = this.Diag_TestName;
    }

    if (this.diagSubUOM == undefined || this.diagSubUOM == "") {
      if (this.unit_of_measure == undefined || this.unit_of_measure == "") {
        Valid_flag = true;
      } else {
        this.diagSubUOM = this.unit_of_measure;
      }
    }

    if (this.male_ref == undefined && this.male_ref == "") {
      Valid_flag = true;
    }

    if (this.female_ref == undefined && this.female_ref == "") {
      Valid_flag = true;
    }

    if (Valid_flag == false) {
      var Exist_flag = false;
      if (this.Diag_subtest_list_data.length != 0) {
        Exist_flag = false;
      } else {
        for (var i = 0; i < this.Diag_subtest_list_data.length; i++) {
          if (this.Diag_subtest_list_data[i].sub_test_name == this.Diag_Subtest_name) {
            Exist_flag = true;
            break;
          }
        }
      }

      var sub_id = this.diag_subtest_id != undefined ? this.diag_subtest_id : "0";
      if (Exist_flag == false) {
        if (this.addSubtest_checked == true) {
          this.Diag_subtest_list_data.push({
            sub_test_id: sub_id,
            sub_test_name: this.Diag_Subtest_name,
            sub_uom: this.diagSubUOM,
            range: this.range,
            ref_range: this.referenceArray,
            test_method: this.test_method,
            status: 1,
          });

          this.Diag_Subtest_name = undefined;
          this.diagSubUOM = undefined;
          this.male_min = undefined;
          this.male_max = undefined;
          this.female_min = undefined;
          this.female_max = undefined;
          this.child_min = undefined;
          this.child_max = undefined;
          this.born_min = undefined;
          this.born_max = undefined;
          this.range = undefined;
          this.Diag_Subtest_name = "";
          this.diagSubUOM = "";
          this.range = '';
          this.male_min = "";
          this.male_max = "";
          this.female_min = "";
          this.female_max = "";
          this.child_min = "";
          this.child_max = "";
          this.born_min = "";
          this.born_max = "";
          this.male_ref = "";
          this.female_ref = "";
          this.child_ref = "";
          this.newborn_ref = "";
          this.test_method = "";
        } else {
          this.Diag_subtest_list_data.push({
            sub_test_id: sub_id,
            sub_test_name: this.Diag_TestName,
            sub_uom: this.unit_of_measure,
            range: this.range,
            ref_range: this.referenceArray,
            test_method: this.test_method,
            status: 1,
          });

          this.Diag_Subtest_name = undefined;
          this.diagSubUOM = undefined;
          this.male_min = undefined;
          this.male_max = undefined;
          this.female_min = undefined;
          this.female_max = undefined;
          this.child_min = undefined;
          this.child_max = undefined;
          this.born_min = undefined;
          this.born_max = undefined;
          this.range = undefined;
          this.Diag_Subtest_name = "";
          this.diagSubUOM = "";
          this.range = '';
          this.male_min = "";
          this.male_max = "";
          this.female_min = "";
          this.female_max = "";
          this.child_min = "";
          this.child_max = "";
          this.born_min = "";
          this.born_max = "";
          this.male_ref = "";
          this.female_ref = "";
          this.child_ref = "";
          this.newborn_ref = "";
          this.test_method = "";
        }
      } else {
        this.toastr.error(Message_data.dataAlreadyExist);
      }
      this.diag_subtest_id = undefined;
      this.referenceArray = [];
      // this.referenceArray_2 = [];
    } else {
      this.toastr.error(Message_data.mandatory);
    }
  }

  onTemplateChanged = (event) => {
    this.cbt_Template = event.html
  }

  getTestDetails(diag_test_id) {//retrieve tests
    
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/tbyidn/',
      JSON.stringify({ test_id: diag_test_id }),
      { headers: headers })
      .subscribe(
        response => {
          this.loader = true;
          var obj = JSON.parse(response["_body"]);
          console.log("TEST DETAILS ((())) ", JSON.stringify(obj));

          if (obj.diag_tests_id != null) {
            this.Diag_TestName = obj.test_name;
            if (obj.uom != undefined) {
              this.unit_of_measure = obj.uom;
              this.unithidden = false;
            } else {
              this.unithidden = true;
            }
            this.Diag_test_cost = obj.price;
            this.frequency_Value = obj.frequency_val;
            this.frequency_Measure = obj.frequency_measure;
            this.insource_cost = obj.insource_cost;
            this.outsource_cost = obj.outsource_cost;
            this.report_template = obj.diag_template;
            this.conduction_format = obj.conduct_format;
            this.Diag_TAT = obj.TAT;
            this.collect_TAT = obj.collectTAT;
            this.assert_TAT = obj.assertTAT;
            this.upload_TAT = obj.uploadTAT;
            this.approve_TAT = obj.approveTAT;
            this.order_no = obj.test_order;
            this.cbt_Template = obj.template_desc;
            this.conduct_doctor = obj.conduct_doctor;
            this.is_specimen_req = obj.sample_req;
            this.diag_dept = obj.diag_dept_id;
            if(obj.status == '1'){
              this.is_active = true;
            } else {
              this.is_active = false;
            }
            this.inpat_outpt = obj.ip_op;
            if (this.conduct_doctor == "1") {
              this.con_doc_check = true;
            } else {
              this.con_doc_check = false;
            }
            if (obj.conduct_format == 1) {
              this.template_flag = false;
              this.diagsub = true;
            } else {
              this.template_flag = true;
              this.diagsub = false;
            }
            // if (obj.ref_range != undefined) {
            //   this.referenceArray = obj.ref_range
            // }

            if (obj.subtests != undefined) {
              if (obj.subtests.length == 1) {
                if (this.Diag_TestName == obj.subtests[0].sub_test_name) {
                  this.ref_flag = true;
                  this.diagsubhidden = true;
                  this.addTest_flag = false;
                  this.subtest_flag = true;
                  this.addSubtest_checked = false;
                  this.main_test_uom_flag = true;
                  this.Diag_subtest_list_data = obj.subtests;
                  this.range = obj.subtests[0].range;
                  this.male_ref = obj.subtests[0].male;
                  this.female_ref = obj.subtests[0].female;
                  this.child_ref = obj.subtests[0].child;
                  this.newborn_ref = obj.subtests[0].newborn;
                  this.Subtest_Select_Click(obj.subtests[0].sub_test_name);

                } else {
                  this.diagsubhidden = false;
                  this.addTest_flag = false;
                  this.main_test_uom_flag = false;
                  this.subtest_flag = false;
                  this.addSubtest_checked = true;
                  if (obj.subtests.length != 0 && obj.subtests[0].sub_test_name != null) {
                    this.Diag_subtest_list_data = obj.subtests;
                  }
                }

              } else {
                this.diagsubhidden = false;
                this.addTest_flag = false;
                this.main_test_uom_flag = false;
                this.subtest_flag = false;
                this.addSubtest_checked = true;
                if (obj.subtests.length != 0 && obj.subtests[0].sub_test_name != null) {
                  this.Diag_subtest_list_data = obj.subtests;
                }
              }

            } else {
              this.diagsubhidden = true;
              this.addTest_flag = true;
              this.subtest_flag = true;
              this.addSubtest_checked = false;
            }

            if (obj.test_method != undefined && obj.test_method != null && obj.test_method != "") {
              this.test_method = obj.test_method;
            }

            this.getDiseaseCategory(obj.diag_dis_cat_id);
            this.getTestCategory(obj.diag_dept_id, obj.diag_test_cat_id);
            this.getSubtypes(obj.diag_test_type_id);

            // this.reagent_id = obj.diag_reagent_id.split(',');
            this.Diag_testtube_id = obj.tube_type_id;
            this.Sample_type = obj.sample_type.split(',');
           
          }
        }, error => { });
  }

  saveTest(flag) {//save tests
    if (this.Diag_test_subtype == "Blood Test" || this.Diag_test_subtype == "Urine Test" || this.Diag_test_subtype == "Faeces Test") {
      if (this.main_test_uom_flag == true) {
        this.test_add();
      }
    }

    var Valid_final_flag = false;
    if (this.Diag_TestName == undefined || this.Diag_TestName == "") {
      Valid_final_flag = true;
    } else if (this.diag_test_categ == undefined || this.diag_test_categ == "" || this.diag_test_categ == null) {
      Valid_final_flag = true;
    } else if (this.Diag_test_subtype == undefined || this.Diag_test_subtype == "" || this.Diag_test_subtype == null) {
      Valid_final_flag = true;
    }
    
    if (this.Diag_test_subtype == "Blood Test" || this.Diag_test_subtype == "Urine Test" || this.Diag_test_subtype == "Faeces Test") {
      if (this.main_test_uom_flag == false) {
        if (this.Diag_test_subtype == "Blood Test" || this.Diag_test_subtype == "Urine Test" || this.Diag_test_subtype == "Faeces Test") {
          if (this.Diag_subtest_list_data.length == 0) {
            this.toastr.error(Message_data.addSubTest);
            Valid_final_flag = true;
          }
        }
      }
    }

    if (Valid_final_flag == false) {
      if (this.Diag_test_cost != undefined || this.Diag_test_cost != "") {
        this.test_price = this.Diag_test_cost;
      }

      this.diag_add_test_id = flag == "Save" ? "0" : this.Diag_test_id;

      if (this.Diag_subtest_list_data.length != 0) {
        this.Send_sublist_data = [];
        for (var i = 0; i < this.Diag_subtest_list_data.length; i++) {
          this.Send_sublist_data.push(this.Diag_subtest_list_data[i]);
        }
      } else {
        this.Send_sublist_data = undefined;
      }

      for (var i = 0; i < this.test_category_data.length; i++) {
        if (this.test_category_data[i].description == this.diag_test_categ) {
          this.diag_cat_id = this.test_category_data[i].diag_test_cat_id;
        }
      }

      for (var i = 0; i < this.dis_category_data.length; i++) {
        if (this.dis_category_data[i].description == this.diag_dis_categ) {
          this.diag_dis_id = this.dis_category_data[i].diag_dis_cat_id;
        }
      }

      for (var i = 0; i < this.Subtype_data.length; i++) {
        if (this.Subtype_data[i].test_type_name == this.Diag_test_subtype) {
          this.diag_subcat_id = this.Subtype_data[i].diag_test_type_id;
        }
      }

      // if (this.reagent_id.length > 1 ) {
      //   var reagent_values = ""
      //   for (var i = 0; i < this.reagent_id.length; i++) {
      //     reagent_values += this.reagent_id[i] + ",";
      //   }
      //   var reagent_values1 = reagent_values.substring(0, reagent_values.length - 1);
      // }

      var sample_ids
      if (this.Sample_type != undefined) {
        if (this.Sample_type.length > 1) {
          var sample_values = ""
          for (var i = 0; i < this.Sample_type.length; i++) {
            sample_values += this.Sample_type[i] + ",";
          }
          sample_ids = sample_values.substring(0, sample_values.length - 1);
        } else if (this.Sample_type.length == 1) {
          sample_ids = this.Sample_type[0]
        } else {
          sample_ids = ""
        }
      }

      var specimen_required;
      if(this.is_specimen_req == true) {
        specimen_required = 1;
      } else {
        specimen_required = 0;
      }

      var status;
      if(this.diag_add_test_id == 0){
        status = 1;
      } else {
        if(this.is_active == true){
          status = 1;
        } else {
          status = 0;
        }        
      }

      var frequency;
      if(this.frequency_Value == ''){
        frequency = undefined;
      }

      var save_diagnosistestdata = JSON.stringify({
        diag_tests_id: this.diag_add_test_id,
        diag_centre_id: this.diagnosisapp_id,
        test_name: this.Diag_TestName,
        test_cat_id: this.diag_cat_id,
        dis_cat_id: this.diag_dis_id,
        test_type_id: this.diag_subcat_id,
        uom: this.unit_of_measure,
        price: this.Diag_test_cost,
        subtests: this.Send_sublist_data,
        test_method: this.test_method,
        diag_dept_id: this.diag_dept,
        // diag_reagent_id: reagent_values1,
        tube_type_id: this.Diag_testtube_id,
        sample_type: sample_ids,
        frequency_val: frequency,
        frequency_measure: this.frequency_Measure,
        outsource_cost: this.outsource_cost,
        insource_cost: this.insource_cost,
        diag_template: this.report_template,
        conduct_format: this.conduction_format,
        // TAT: this.Diag_TAT,
        collectTAT: this.collect_TAT,
        assertTAT: this.assert_TAT,
        uploadTAT: this.upload_TAT,
        approveTAT: this.approve_TAT,
        test_order: this.order_no,
        template_desc: this.cbt_Template,
        conduct_doctor: this.conduct_doctor,
        show_booking: this.show_test,
        ip_op: this.inpat_outpt,
        sample_req: specimen_required,
        status: status,
      })

      console.log("save_diagnosistestdata", save_diagnosistestdata)

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'diagnosiscontroller/sdtestsn', save_diagnosistestdata,
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            console.log('saveTest--response', obj)
            if (obj.key != null && obj.key == "1") {
              this.toastr.success(obj.result);
              this.diagnosisservice.sendMessage("testsandpackadv");
            } else {
              this.toastr.error(Message_data.testDetNotSaved);
            }
          }, error => { });
    } else {
      if (this.main_test_uom_flag == true) {
        this.Diag_subtest_list_data = [];
      }
      this.toastr.error(Message_data.mandatory)
    }
  }

  conddocReq(e) {
    if (e == true) {
      this.conduct_doctor = "1"

    } else {
      this.conduct_doctor = "0";
    }

  }

  showOrhide_test(e) {
    if (e == true) {
      this.show_test = "1"

    } else {
      this.show_test = "0";
    }
  }

  allAgeChk(e) {
    this.age_grp_check = e
    if (e == true) {
      this.showAgeInp = true;
    } else {
      this.showAgeInp = false;
    }
  }

  addSubtest(e) {
    if (e == true) {
      this.subtest_flag = false;
      this.main_test_uom_flag = false;
    } else {
      this.subtest_flag = true;
      this.main_test_uom_flag = true;
    }
  }

  backClicked() {  //back
    this.diagnosisservice.sendMessage('testsandpackadv')
  }

  onSelectionChanged = (event) => { }
  onContentChanged = (event) => {
    this.range = event.html;
  }

  editorCreated(quill: any) { }

  onSelectionChanged1 = (event) => { }
  onContentChanged1 = (event) => {
    this.test_method = event.html;
  }

  editorCreated1(quill: any) { }

  referenceAddBtn() {
    var ageCat;

    if ((this.gender_ref != undefined && this.gender_ref != "") && (this.min_nor_value != undefined && this.min_nor_value != "") && (this.max_nor_value != undefined && this.max_nor_value != "")) {
      var ageValue;
      var ageMinMax;

      if(this.age_grp_check == true){
        ageValue = "All age group";
        this.age_input = "0";
        this.age_input_max = "120";
        this.age_ref = "Year(s)"
        ageMinMax = this.age_input + "-" + this.age_input_max;
      } else {
        ageValue = this.age_input + "-" + this.age_input_max + " " + this.age_ref;
        ageMinMax = this.age_input + "-" + this.age_input_max;
      }

      console.log({
        age_display: ageValue,
        age_val: ageMinMax,
        age_max: this.age_input_max,
        age_min: this.age_input,
        age_measure: this.age_ref,
        gender: this.gender_ref,
        min_nor_value: this.min_nor_value,
        max_nor_value: this.max_nor_value,
        min_abnor_value: this.min_anor_value,
        max_abnor_value: this.max_anor_value,
        min_crit_value: this.min_crit_value,
        max_crit_value: this.max_crit_value,
      })

      this.referenceArray.push({
        age_display: ageValue,
        age_val: ageMinMax,
        age_max: this.age_input_max,
        age_min: this.age_input,
        age_measure: this.age_ref,
        gender: this.gender_ref,
        min_nor_value: this.min_nor_value,
        max_nor_value: this.max_nor_value,
        min_abnor_value: this.min_anor_value,
        max_abnor_value: this.max_anor_value,
        min_crit_value: this.min_crit_value,
        max_crit_value: this.max_crit_value,
      })
    } else {
      this.toastr.error("Please Fill The Required Fields")
    }

    this.age_ref = "";
    this.age_input = "";
    this.age_input_max = "";
    ageValue = "";
    ageMinMax = "";
    this.gender_ref = "";
    this.ref_value = "";
    this.min_nor_value = "";
    this.max_ref_value = "";
    this.max_nor_value = "";
    this.min_anor_value = "";
    this.max_anor_value = "";
    this.min_crit_value = "";
    this.max_crit_value = "";

  }

  referenceEdit(reference) {
    for (var i = 0; i < this.referenceArray.length; i++) {
      if (this.referenceArray[i].min_nor_value == reference.min_nor_value) {
        this.age_input = reference.age_min;
        this.age_input_max = reference.age_max;
        this.age_ref = reference.age_measure;
        this.gender_ref = reference.gender;
        this.min_nor_value = reference.min_nor_value;
        this.max_nor_value = reference.max_nor_value;
        this.min_anor_value = reference.min_abnor_value;
        this.max_anor_value = reference.max_abnor_value;
        this.min_crit_value = reference.min_crit_value;
        this.max_crit_value = reference.max_crit_value;
        this.referenceArray.splice(i, 1);
        break;
      }
    }
  }

  referenceDelete(reference) {
    for (var i = 0; i < this.referenceArray.length; i++) {
      if (this.referenceArray[i].min_nor_value == reference.min_nor_value) {
        this.referenceArray.splice(i, 1);
        break;
      }
    }
  }

  getDiagDepartment() {
    
    this.department_data = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'mas/gdd', JSON.stringify({ diag_centre_id: this.diagnosisapp_id }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          var dept_data = obj.diag_dept_list;

          if (obj.diag_dept_list.length != 0) {
            for (var i = 0; i < obj.diag_dept_list.length; i++) {
              this.department_data.push({
                diag_department_id: dept_data[i].diag_department_id,
                diag_dept_desc: dept_data[i].diag_dept_desc,
                status: dept_data[i].status,
              })
            }
            console.log("getDiagDepartment", this.department_data)
          }
        }, error => { });
  }

  getReagents() {
    this.reagents_data = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'mas/gdat',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          var reagent_types = obj.reagent_types;
          console.log("getReagents", obj)

          if (obj.reagent_types != undefined) {
            for (var i = 0; i < reagent_types.length; i++) {
              this.reagents_data.push({
                reagent_type_desc: reagent_types[i].reagent_type_desc,
                reagent_type_id: reagent_types[i].reagent_type_id,
                status: reagent_types[i].status,
              })
            }
          }
        }, error => { });
  }

  getDiagTesttubes() {
    this.testtube_data = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'mas/gdtt', JSON.stringify({ diag_centre_id: this.diagnosisapp_id }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          var diagtube_data = obj.tube_type_list;

          if (diagtube_data.length != 0) {
            for (var i = 0; i < diagtube_data.length; i++) {
              this.testtube_data.push({
                tube_type_id: diagtube_data[i].tube_type_id,
                tube_desc: diagtube_data[i].tube_desc,
                status: diagtube_data[i].status,
              }
              )
            }
          }
          console.log("getDiagTesttubes", obj)
        }, error => { });
  }

  getSampleTypes() {
    if (this.diag_test_categ == 'Microbiology'){
      this.specimenTypeFlag = false;
      this.conduction_format = '3'
      this.formatChange('3')
    } 
    
    this.sampletype_data = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'mas/gdst ', 
    JSON.stringify({ 
      diag_centre_id: this.diagnosisapp_id,
      sub_dept:this.diag_test_categ,
    }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          var sampleTypesData = obj.specimen_type_list;
          console.log("getSampleTypes-------", obj);

          if (sampleTypesData.length != 0) {
            for (var i = 0; i < sampleTypesData.length; i++) {
              this.sampletype_data.push(
                {
                  sample_type_id: sampleTypesData[i].specimen_type_id,
                  sample_desc: sampleTypesData[i].specimen_desc,
                  status: sampleTypesData[i].status,
                }
              )
            }
          }
        }, error => { });
  }

  getReportTemplate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'diagnosiscontroller/gdtrt',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.templates = obj.diag_templates;
        }, error => { });
  }

  // getConductionFormats() {
  //   this.reagents_data = [];
  //   var headers = new Headers();
  //   headers.append('Content-Type', 'application/json');
  //   this.http.get(ipaddress.getIp.toString() + 'diagnosiscontroller/gdcf',
  //     { headers: headers })
  //     .subscribe(
  //       response => {
  //         var obj = JSON.parse(response["_body"]);
  //         this.conductionFormatarr = obj.conduction_formats;
  //       }, error => { });
  // }

  async getConductionFormats() {
    this.reagents_data = [];
    const headers: any = new HttpHeaders().set('Content-Type', 'application/json');

    try {
      const response: any = await this.http.get(ipaddress.getIp.toString() + 'diagnosiscontroller/gdcf', { headers }).toPromise();
      this.conductionFormatarr = response.conduction_formats;
    } catch (error) {
      console.error('Error fetching conduction formats:', error);
    }
  }


  formatChange(Id) {
    if (Id == 1) {
      this.templateFlag = false;
      this.diagsubhidden = true;
      this.template_flag = false;
      this.unithidden = true;
      this.specimenTypeFlag = true;
    } else if (Id == 2) {
      if (this.diag_dept == "1") {
        this.addTest_flag = false;
        this.diagsubhidden = false;
        this.ref_flag = true;
        this.template_flag = true;
        this.templateFlag = true;
        this.unithidden = false;
        this.specimenTypeFlag = false;
      }
    } else {
      this.templateFlag = true;
      this.diagsubhidden = false;
      this.template_flag = true;
      this.ref_flag = false;
      this.unithidden = true;
      this.specimenTypeFlag = false;
    }
  }

  showTemplate(tempId) {
    if (this.templates != undefined) {
      for (var i = 0; i < this.templates.length; i++) {
        if (this.templates[i].template_id == tempId) {
          this.cbt_Template = this.templates[i].template_desc;
        }
      }
    }
  }

  getUnitOfMeasure(testType) {
    this.UOM_list = [];

    console.log("testType", testType)

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
      this.http.get(ipaddress.getIp.toString() + 'diagnosiscontroller/gubt',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.UOM_list = obj.uom_list;

          console.log("getUnitOfMeasure", this.UOM_list)
        }, error => { });
  }

}
