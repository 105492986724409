import { Component, OnInit } from '@angular/core';
import { pharmacy_helper } from '../../../pharma/Pharmcy_Helper';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { ipaddress } from '../../../ipaddress';
import { Helper_Class } from '../../../../app/helper_class';
import { ToastrService } from 'ngx-toastr';
import { Message_data } from '../../../../assets/js/Message_data';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
import { CommonDataService } from '../../../../app/providers/common-data.service';
// import { Date_Formate } from 'src/assets/js/common';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { encrypt_decript } from '../../../../assets/js/Encription_Decription';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Date_Formate, Time_Formate, convertNumberToWords } from '../../../../assets/js/common.js';


@Component({
  selector: 'app-consingmentlist',
  templateUrl: './consingmentlist.component.html',
  styleUrls: ['./consingmentlist.component.scss'],
  animations: [
    trigger('slideToggle', [
      state('void', style({ height: '0px', opacity: 0})),
      state('*', style({ height: '*', opacity: 1})),
      transition('void <=> *', [
        animate('400ms ease-in-out')
      ])
    ])
  ]
})
export class ConsingmentlistComponent implements OnInit {
  public hsnNO: string = "";
  public newFlag: boolean;
  public productName: string = "";
  public filteredList = [];
  public userInfo;
  public pharmacyID;
  public PharmauserID;
  public data_type:string;
  public hospitalid;
  public department_id;
  public store_id;
  public productFilterList:any = [];
  public mdeicareTypeArray: any = [];
  public shortName: string = "";
  productID: string = "";
  public productType: any = [];
  public medicareType: any = [];
  public categoryType: any = [];
  public productTypeArray: any = [];
  public categoryTypeArray: any = [];
  uomListArray: any;
  uomTypeArray: any = [];
  public productTypeID;
  public categoryCtgID;
  public uomCode;
  public supp_name: string;
  public supplierList: any = [];
  public suppID;
  public scheduleType;
  public scheduleList;
  public prodCenteralGST;
  public prodStateGST;
  public avail_qty;
  public MRP;
  public c_price;
  public s_price;
  public expirydura;
  public hide_show_btn;
  isCoverVisible: boolean = true;
  public username;
  public batch_no;
  public consing_data=[];
  public returnedprod;
  public returnedaccepted:boolean;
  public consignment_id;
  public reversedate;
  public editflag:boolean;
  public clearflag:boolean;
  public cgst:any;
  public sgst:any;
  public gst:any;
  currentOpenedIndex: number | null = null;
  public name: string;
  public usequantity:any;
  public patientArray: any = [];
  public retrivemr:boolean;
  public MedicineNameArray:any=[];
  public patient_detailsflag: boolean;
  public relation_id: any = "";
  public client_reg_id: any = "";
  public patient_id: any = '';
  public print_template: string;
  public documentarray = [];
  public totalAMT = 0;
  public totalGST = 0;
  public totalcgst: number;
  public totalsgst: number;
  public totalcgstpercent: number;
  public totalsgstpercent: number;
  public roundoff: number;
  public hospitalName;
  public getInfo;
  public user_type;
  public address1;
  public address2
  public city_desc;
  public zipcode;
  public vendorlocation;
  public presentdate;
  public currentDate;
  public fromDate;
  public toDate;
  public current_time1;
  public currenttime;
  public totalamountinword;
  public purchase_order_id;
  public purchaseOrderArray:any=[];
  public venderstate;
  public vendorcon;
  public amountinwords;
  public vendorcity;
  public vendorname;
  public vendoraddress;
  public pdfObj;
  public vendor_Mobile1;
  public vendor_Mobile2;
  public vendor_gst;
  public resultarray = [];
  public pdfdata = [];
  public poBaseTXT;
 

  constructor(public gservice: CommonDataService,public httpclt: HttpClient, public httpp: HttpClient,public http: Http, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService, public pharmaservice: MenuViewService) { 
    this.newFlag = false;
  }

  ngOnInit(): void {
    this.retrivemr = false;
    this.userInfo = Helper_Class.getInfo();
    console.log("user info = "+JSON.stringify(this.userInfo))
    this.department_id=this.userInfo.hospitals[0].department_id;
    this.store_id=this.userInfo.hospitals[0].store_id	;
    if (Helper_Class.getInfo().user_type == 'Admin') {
      this.pharmacyID = Helper_Class.getInfo().pharma_id;
      this.PharmauserID = this.userInfo.po_approver;
      this.data_type='hospital'
    }else if(Helper_Class.getInfo().user_type == 'pharmacy'){
      this.pharmacyID = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.PharmauserID = this.userInfo.user_id;
      this.data_type='pharmacy'
    }else if(Helper_Class.getInfo().user_type == 'OT admin'){
      this.pharmacyID = Helper_Class.getInfo().hospitals[0].pharma_id;
      this.hospitalid = Helper_Class.getInfo().hospitals[0].hptl_clinic_id	;
      this.PharmauserID = this.userInfo.user_id;
      this.data_type='hospital'
    }
    else{
      this.pharmacyID = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.hospitalid = Helper_Class.getInfo().hospitals[0].hosp_id	;
      this.PharmauserID = this.userInfo.user_id;
      this.data_type='hospital'
    }

    if(this.userInfo.middle_name != undefined ){
      this.username= this.userInfo.first_name+" "+this.userInfo.middle_name+" "+this.userInfo.last_name
    }else if(this.userInfo.first_name !=undefined && this.userInfo.last_name != undefined){
      this.username= this.userInfo.first_name+" "+this.userInfo.last_name
    }else if(this.userInfo.first_name !=undefined){
      this.username= this.userInfo.first_name
    }else{
      this.username=''
    }

    if (pharmacy_helper.getManufacturer() == true || pharmacy_helper.getSupplier() == true) {
      if (pharmacy_helper.getProductMst() != undefined) {
        var productMst = pharmacy_helper.getProductMst();
        this.hsnNO = productMst.hsn_no;
        this.productName = productMst.name;
        // this.shortName = productMst.short_name;
        // this.genericName = productMst.generic_name;
        // this.productTypeID = productMst.product_type_id;
        // this.medicareMedID = productMst.medicare_id;
        // this.categoryCtgID = productMst.category_type_id;
        // this.uomCode = productMst.uom_code;
        // this.suppID = productMst.supp_id;
        // this.manufacturerMfgID = productMst.mfg_id;
        // this.quantity = productMst.per_unit;
        // this.costPrice = productMst.cost_price;
        // this.sellingPrice = productMst.sales_price;
        // this.MRP = productMst.MRP;
        // this.prodCenteralGST = productMst.prod_cgst;
        // this.prodStateGST = productMst.prod_sgst;
        pharmacy_helper.setProductMst(undefined);
        if (pharmacy_helper.getManufacturer() == true) {
          pharmacy_helper.setManufacturer(undefined);
        } else {
          pharmacy_helper.setSupplier(undefined);
        }
      }
    }
    this.getInfo = Helper_Class.getInfo();
    this.user_type = Helper_Class.getInfo().user_type;
    this.hospitalName = this.getInfo.hospitals[0].pat_ident_prefix;
    this.address1 = this.getInfo.hospitals[0].address1;
    this.address2 = this.getInfo.hospitals[0].address2;
    this.vendorlocation = this.getInfo.hospitals[0].location_desc;
    this.city_desc = this.getInfo.hospitals[0].city_desc;
    this.zipcode = this.getInfo.hospitals[0].zipcode;
    this.poBaseTXT == "Purchase Request"




    this.GetProductdata();
    this.getCategory();
    //this.getManufacturer();
    this.getUOM();
    this.getSuppliers();
    this.getCurrentDate()
    this.hide_show_btn="../../../assets/ui_icons/buttons/up.svg";
    this.getData();
    this.editflag=false;
    this.clearflag=false;

  }


  public getCurrentDate() {
    var headers = new Headers();
    var date
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate', { country: ipaddress.country_code },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          console.log("current time", JSON.stringify(obj))
          this.currentDate = obj.current_date;//display in html for maximum date
          this.fromDate = obj.current_date;
          this.toDate = obj.current_date;
          var currenttime1 = obj.current_time.split(":");
          var currentDate1 = obj.current_date.split('-');
          this.current_time1 = Time_Formate(obj.current_time);
          this.currenttime = currenttime1[0]
          this.presentdate = currentDate1[2] + "-" + currentDate1[1] + "-" + currentDate1[0];
          console.log('presentdate1', this.presentdate)
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }




  productNameFilter(e) {
    this.filteredList = [];
    this.productName = e.target.value.toString();
    if (this.productName !== "" && this.productName.length > 2) {
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/gpbyn',
        JSON.stringify({
          product_name: this.productName,
         
        }),
        { headers: headers })
        .subscribe(
          response => {
            console.log( "product filter send_data = "+JSON.stringify({
              product_name: this.productName,
              dept_id:this.department_id,
              store_id:this.store_id,
            }))
            var hospital_txt:any = [];
            var obj = JSON.parse(response["_body"]);
            if (obj.products != null) {
              this.productFilterList = obj.products;
              for (var i = 0; i < this.productFilterList.length; i++) {
                
                hospital_txt.push(this.productFilterList[i].name);
                console.log("name "+JSON.stringify(hospital_txt))
              }
              this.filteredList = hospital_txt.filter(function (this: any, el) {
                return el.toLowerCase().indexOf(this.product_name.toLowerCase()) > -1;
              }.bind(this));
            }
          },
          error => { }
        )
    } else {
      this.filteredList = [];
    }
  }

  selectItem(data) {
    this.productName = data;
    this.filteredList = [];
    for (var i = 0; i < this.productFilterList.length; i++) {
      if (data == this.productFilterList[i].name) {
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp.toString() + 'pharmacontrol/gpd',
          JSON.stringify({
            product_id: this.productFilterList[i].product_id
          }),
          { headers: headers })
          .subscribe(
            response => {
              var obj = JSON.parse(response["_body"]);
              if (obj.hsn_no != null) {
                this.hsnNO = obj.hsn_no;
                this.shortName = obj.short_name;
                // this.genericName = obj.generic_name;
                // this.quantity = obj.per_unit;;
                this.productID = obj.product_id;
                for (var j = 0; j < this.mdeicareTypeArray.length; j++) {
                  if (this.mdeicareTypeArray[j].medicare_id == obj.medicare_id) {
                    this.medicareType = this.mdeicareTypeArray[j];
                    break;
                  }
                }
                for (var j = 0; j < this.productTypeArray.length; j++) {
                  if (this.productTypeArray[j].med_code == obj.product_type_id) {
                    this.productType = this.productTypeArray[j];
                    break;
                  }
                }
                for (var j = 0; j < this.categoryTypeArray.length; j++) {
                  if (this.categoryTypeArray[j].category_id == obj.category_type_id) {
                    this.categoryType = this.categoryTypeArray[j];
                    break;
                  }
                }
                for (var j = 0; j < this.uomListArray.length; j++) {
                  if (this.uomListArray[j].uom_code == obj.uom_code) {
                    this.uomTypeArray = this.uomListArray[j];
                    break;
                  }
                }
                // for (var j = 0; j < this.manufacList.length; j++) {
                //   if (this.manufacList[j].mfg_id == obj.mfg_id) {
                //     this.manufacturer = this.manufacList[j];
                //     break;
                //   }
                // }
              }
            },
            error => {
              this.toastr.error(JSON.stringify(error.json()));
            }
          )
      }
    }
  }

  GetProductdata() {
    this.http.get(ipaddress.getIp + "pharmacontrol/mtypes/").subscribe(
      data => {
        if (data.json().med_details != null) {
          this.productTypeArray = data.json().med_details;
        }
      },
      error => { });
  }

  getCategory() {
    this.http.get(ipaddress.getIp + "pharmacontrol/gpcat/").subscribe(
      data => {
        if (data.json().product_categories != null) {
          this.categoryTypeArray = data.json().product_categories;
          this.categoryTypeArray.forEach(element => {
          });
        }
      },
      error => { });
  }

  getUOM() {
    var send_data;
    if(this.userInfo.user_type == "pharmacy"){
      send_data={
        pharmacy_id: this.pharmacyID, 
         type:this.data_type
      }
    }else[
      send_data={
        pharmacy_id: this.hospitalid, 
           type:this.data_type
      }
    ]
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/guom/", 
      // JSON.stringify({ 
      //   pharmacy_id: this.pharmacyID, 
      //   type:this.data_type
      // })
      send_data
      , { headers: headers }).subscribe(
      data => {
        if (data.json().product_uoms != null) {
          this.uomListArray = data.json().product_uoms;
        }
      },
      error => { });
  }

  getSuppliers() {
   
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "inv/gsup/",
        JSON.stringify({
          pharmacy_id: this.pharmacyID,
          supp_name: this.supp_name,
          dept_id:this.department_id,
          store_id:this.store_id
        }), { headers: headers }).subscribe(
          data => {
             var obj=data.json().supplier_details;
             console.log("supplier name  = "+JSON.stringify(obj))
           for(var i=0;i<obj.length;i++){
             var exists = this.supplierList.some(function(supplier) {
              return supplier.supp_id === obj[i].supp_id;
          });
            if (!exists) {
            this.supplierList.push({
              name:obj[i].name,
              supp_id:obj[i].supp_id,
              supp_code:obj[i].supp_code,
              gst_no:obj[i].gst_no
            })
          }
          }
          },
          error => {
            this.toastr.error(Message_data.unabletofetsupl);
          }
        )
  }

  supplierdatafunction(data){
    this.suppID=data.supp_id
  }

  getSchedule() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp + "pharmacontrol/gsch", { headers: headers }).subscribe(
      data => {
        this.scheduleList = [];
        var obj = data.json();
        if (obj != undefined && obj.schedules.length != 0) {
          this.scheduleList = obj.schedules;
        }
      },
      error => {
        this.toastr.error(Message_data.unableToFetchSech);
      }
    )
  }
 
  toggleCover() {
    if(this.editflag == true){
      this.isCoverVisible=true 
    }else if(this.clearflag == true){
      this.isCoverVisible=true
    }else{
      this.isCoverVisible = !this.isCoverVisible 
    }
    if(this.isCoverVisible == false){
      this.hide_show_btn="../../../assets/ui_icons/buttons/down.svg";
    }else{
      this.hide_show_btn="../../../assets/ui_icons/buttons/up.svg";
    }
    this.editflag =false;
    this.clearflag =false;
  }

  getData(){
    var send_data={
      hptl_clinic_id:this.pharmacyID,
    };
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "inv/gcbh", send_data, { headers: headers }).subscribe(
      data => {
        var obj=JSON.parse(data["_body"]);

        if(obj != undefined ){
          var consin_data=obj.consignments;
          if(consin_data.length != 0){
            console.log("get consi_data"+JSON.stringify(obj))
            this.consing_data=[];
            var expdate;
            var gst_amount;
            var cgst;
            var sgst;
            for(var i=0;i< consin_data.length;i++){
              
              if(consin_data[i].expiry_date != undefined){
                expdate = Date_Formate(consin_data[i].expiry_date)
                this.reversedate=consin_data[i].expiry_date
              }else{
                expdate=''
              }
              if(consin_data[i].consignment_id != undefined){
                consin_data[i].consignment_id=consin_data[i].consignment_id
              }else{
                consin_data[i].consignment_id=''
              }
    
              if(consin_data[i].is_prod_returned == "1" ){
                consin_data[i].is_prod_returne=true
              }else{
                consin_data[i].is_prod_returne=false
              }
              
              if(consin_data[i].cgst != undefined  ){
                consin_data[i].cgst=  consin_data[i].cgst
              }else{
                consin_data[i].cgst=0
              }

              if(consin_data[i].sgst != undefined ){
                consin_data[i].sgst=consin_data[i].sgst
              }else{
                consin_data[i].sgst=0
              }
              if(consin_data[i].mrp != undefined){
                consin_data[i].mrp =consin_data[i].mrp 
              }else{
                consin_data[i].mrp=0
              }
              if(consin_data[i].cost_price != undefined){
                consin_data[i].cost_price =consin_data[i].cost_price 
              }else{
                consin_data[i].cost_price=0
              }
              if(consin_data[i].quantity != undefined){
                consin_data[i].quantity =consin_data[i].quantity 
              }else{
                consin_data[i].quantity=0
              }
              var quantity=consin_data[i].cost_price*consin_data[i].quantity
              if(consin_data[i].gst != undefined){
                
                gst_amount=parseFloat(( quantity * (consin_data[i].gst / 100)).toFixed(2))
              }else{
                gst_amount=0
              }

              this.consing_data.push({
                sn:i+1,
              consignment_name	:	consin_data[i].consignment_name,
              quantity	:	consin_data[i].quantity,
              batch_no	:	consin_data[i].batch_no,
              expiry_date	:	expdate,
              supplier_id	:	consin_data[i].supplier_id,
              cost_price	:	consin_data[i].cost_price,
              sale_price	:	consin_data[i].sale_price,
              mrp	:	consin_data[i].mrp,
              received_by	:	consin_data[i].received_by,
              is_prod_returned	:	consin_data[i].is_prod_returned,
              status	:	consin_data[i].status,
              hsn_no	:consin_data[i].hsn_no,
              supp_name	:	consin_data[i].supp_name,
              consignment_id: consin_data[i].consignment_id,
              cgst:consin_data[i].cgst,
              sgst:consin_data[i].sgst,
              gst:consin_data[i].gst,
               gst_amount:gst_amount
            })
            }
            console.log("get consin data = "+JSON.stringify(this.consing_data))
          }
        }
      },error =>{

      });
  }
  



 
  toggleInputs(index: number) {
    if (this.currentOpenedIndex === index) {
      this.currentOpenedIndex = null; // Close the currently opened row
    } else {
      this.currentOpenedIndex = index; // Open the new row and close the previous one
    }
    
  }



  handlekeyUp(event: KeyboardEvent) {
    const key = event.key;
    if (key !== 'ArrowUp' && key !== 'ArrowDown') {
      this.getPatientListByName(this.name)
    }
    if (key === 'Enter') {
      let patient;
      for (let i = 0; i < this.patientArray.length; i++) {
        if (this.patientArray[i].patient_name === this.name) {

          const { patient_id, patient_name, relation_id, doc_name, client_reg_id, sub_rel_id, gender, age, mobile } = this.patientArray[i];
          patient = {
            patient_id,
            doc_name,
            descProductsInput: this.name,
            relation_id,
            client_reg_id,
            sub_rel_id,
            gender,
            age,
            mobile
          };
          break;
        }
      }
      if (patient) {
        this.retrieveClient(patient)
        console.log("value = " + JSON.stringify(patient))
      }
    }
  }



  getPatientListByName(name) {
    var send_data;
    var gender;
    var mobilenum;
    
    console.log("verify send_data " + JSON.stringify({
      hptl_clinic_id: this.pharmacyID,
      country: ipaddress.country_code,
      type:"name",
      center_type: "pharma",
      name: this.name,
      prov_id:  this.userInfo.user_id,
      location:  this.userInfo.user_type,
    }))

    if (this.retrivemr == true && name.toString().length > 8) {
      send_data = {
        hptl_clinic_id: this.pharmacyID,
        country: ipaddress.country_code,
        type: "name",
        center_type: "pharma",
        name: this.name,
        prov_id: this.userInfo.user_id,
        location: this.userInfo.user_type,
        patient_type:"inpatient"
      }
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.httpp.post(ipaddress.getIp.toString() + 'gen/gclnt', send_data,
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            console.log("verify this " + JSON.stringify(obj))
            if (obj.clients != null) {
              this.patientArray = [];
              for (var i = 0; i < obj.clients.length; i++) {
                var patName;
                if (obj.clients[i].middle_name != undefined) {
                  patName = encrypt_decript.Decript(obj.clients[i].first_name) + " " + encrypt_decript.Decript(obj.clients[i].middle_name) + " " + encrypt_decript.Decript(obj.clients[i].last_name)
                } else {
                  patName = encrypt_decript.Decript(obj.clients[i].first_name) + " " + encrypt_decript.Decript(obj.clients[i].last_name);
                }
                if (obj.clients[i].gender != undefined) {
                  gender = encrypt_decript.Decript(obj.clients[i].gender)
                  console.log(" g =" + JSON.stringify(gender))
                }
                if (obj.clients[i].mobile != undefined) {
                  mobilenum = encrypt_decript.Decript(obj.clients[i].mobile)
                  console.log(" m =" + JSON.stringify(mobilenum))
                }
                this.patientArray.push({
                  patient_name: patName,
                  relation_id: obj.clients[i].relation_id,
                  client_reg_id: obj.clients[i].client_reg_id,
                  patient_id: obj.clients[i].patient_id,
                  sub_rel_id: obj.clients[i].sub_rel_id,
                  gender: gender,
                  age: obj.clients[i].age,
                  mobile: mobilenum,
                })
              }
            }
          },
          error => { });

    } else if (name.toString().length > 2 && this.retrivemr == false) {
      send_data = {
        hptl_clinic_id: this.pharmacyID,
        country: ipaddress.country_code,
        type: 'name',
        name: encrypt_decript.Encript(this.name.toLocaleUpperCase()).toString(),
        center_type: "pharma",
        prov_id: this.userInfo.user_id,
        location: this.userInfo.user_type,
        patient_type:"inpatient"
      }
      console.log("send data ====++" + JSON.stringify(send_data))
      var headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      this.httpp.post(ipaddress.getIp.toString() + 'gen/gclnt', send_data,
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(JSON.stringify(response));
            console.log("verify this " + JSON.stringify(obj))
            if (obj.clients != null) {
              this.patientArray = [];

              for (var i = 0; i < obj.clients.length; i++) {
                var patName;
                if (obj.clients[i].middle_name != undefined) {
                  patName = encrypt_decript.Decript(obj.clients[i].first_name) + " " + encrypt_decript.Decript(obj.clients[i].middle_name) + " " + encrypt_decript.Decript(obj.clients[i].last_name)
                } else {
                  patName = encrypt_decript.Decript(obj.clients[i].first_name) + " " + encrypt_decript.Decript(obj.clients[i].last_name);
                }
                if (obj.clients[i].gender != undefined) {
                  gender = encrypt_decript.Decript(obj.clients[i].gender)
                  console.log(" g =" + JSON.stringify(gender))
                }
                if (obj.clients[i].mobile != undefined) {
                  mobilenum = encrypt_decript.Decript(obj.clients[i].mobile)
                  console.log(" m =" + JSON.stringify(mobilenum))
                }
                this.patientArray.push({
                  patient_name: patName,
                  relation_id: obj.clients[i].relation_id,
                  client_reg_id: obj.clients[i].client_reg_id,
                  patient_id: obj.clients[i].patient_id,
                  sub_rel_id: obj.clients[i].sub_rel_id,
                  gender: gender,
                  age: obj.clients[i].age,
                  mobile: mobilenum,
                })
              }
            }
          },
          error => { });

    } else {
      this.MedicineNameArray = [];
    }
   
  }


  retrieveClient(person) {

    if (this.retrivemr == true) {
      var get_data = {
        user_id: person.client_reg_id,
        relation_id: person.relation_id,
        sub_rel_id: person.sub_rel_id,
        country: ipaddress.country_code
      };
    } else {
      var get_data = {
        user_id: person.client_reg_id,
        relation_id: person.relation_id,
        sub_rel_id: person.sub_rel_id,
        country: ipaddress.country_code
      };
    }

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.httpp.post(ipaddress.getIp.toString() + 'usercontroller/grclnt/',
      get_data,
      { headers: headers })
      .subscribe(
        response => {

          var obj = JSON.parse(JSON.stringify(response));
          console.log("check patientid" + JSON.stringify(obj))


          if (obj.client_id != null) {
            if(obj.relation_id != undefined){
              this.relation_id=obj.relation_id_id;
            }else{
              this.relation_id='1';
            } 
          
            this.client_reg_id=obj.client_id;
            this.patient_id=obj.patient_id;
            
           
          }
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }




  po_generator() {

    if (this.print_template != undefined && this.print_template == "banner") {
      // this.bannerTemplateforpodata();
      this.printnoheaderdf();

    } else if (this.print_template != undefined && this.print_template == "logowithname") {
      // this.printlogowithnamepdfforpodata();
      this.printnoheaderdf();

    } else {

      this.printnoheaderdf();
    }
  }


  printnoheaderdf() {
 console.log(" PURCHASE ORDER ARRAY = " + JSON.stringify(this.purchaseOrderArray))
    this.documentarray = [];
    this.totalAMT = 0;
    this.totalGST = 0;
    this.totalcgstpercent = 0;
    this.totalsgstpercent = 0;
    this.totalcgst = 0;
    this.totalsgst = 0;
    this.roundoff = 0;
    for (var i = 0; i < this.purchaseOrderArray.length; i++) {
      var cgst;
      var sgst;
      if (this.purchaseOrderArray[i].cgst != undefined) {
        cgst = this.purchaseOrderArray[i].cgst
      } else {
        cgst = 0
      }


      if (this.purchaseOrderArray[i].cgst != undefined) {
        sgst = this.purchaseOrderArray[i].sgst
      } else {
        sgst = 0
      }

      if (this.purchaseOrderArray[i].cgst == undefined && this.purchaseOrderArray[i].sgst == undefined) {
        this.purchaseOrderArray[i].gst = 1
        this.totalcgstpercent += 0
        this.totalsgstpercent += 0
        this.totalcgst += parseFloat(this.purchaseOrderArray[i].quantity) * parseInt(this.purchaseOrderArray[i].unit_price) * (0 / 100)
        this.totalsgst += parseFloat(this.purchaseOrderArray[i].quantity) * parseInt(this.purchaseOrderArray[i].unit_price) * (0 / 100)
      } else if (this.purchaseOrderArray[i].cgst == undefined && this.purchaseOrderArray[i].sgst != undefined) {
        this.purchaseOrderArray[i].gst = 0 + parseInt(this.purchaseOrderArray[i].sgst)
        this.totalcgstpercent += 0
        this.totalsgstpercent += parseInt(this.purchaseOrderArray[i].sgst)
        this.totalcgst += parseFloat(this.purchaseOrderArray[i].quantity) * parseInt(this.purchaseOrderArray[i].unit_price) * (0 / 100)
        this.totalsgst += parseFloat(this.purchaseOrderArray[i].quantity) * parseInt(this.purchaseOrderArray[i].unit_price) * (parseFloat(this.purchaseOrderArray[i].sgst) / 100)
      } else if (this.purchaseOrderArray[i].cgst != undefined && this.purchaseOrderArray[i].sgst == undefined) {
        this.purchaseOrderArray[i].gst = parseInt(this.purchaseOrderArray[i].cgst) + 0
        this.totalcgstpercent += parseInt(this.purchaseOrderArray[i].cgst)
        this.totalsgstpercent += 0
        this.totalcgst += parseFloat(this.purchaseOrderArray[i].quantity) * parseInt(this.purchaseOrderArray[i].unit_price) * (parseFloat(this.purchaseOrderArray[i].cgst) / 100)
        this.totalsgst += parseFloat(this.purchaseOrderArray[i].quantity) * parseInt(this.purchaseOrderArray[i].unit_price) * (0 / 100)
      } else {
        this.purchaseOrderArray[i].gst = parseInt(this.purchaseOrderArray[i].cgst) + parseInt(this.purchaseOrderArray[i].sgst)
        this.totalcgstpercent += parseInt(this.purchaseOrderArray[i].cgst)
        this.totalsgstpercent += parseInt(this.purchaseOrderArray[i].sgst)
        this.totalcgst += parseFloat(this.purchaseOrderArray[i].quantity) * parseInt(this.purchaseOrderArray[i].unit_price) * (parseFloat(this.purchaseOrderArray[i].cgst) / 100)
        this.totalsgst += parseFloat(this.purchaseOrderArray[i].quantity) * parseInt(this.purchaseOrderArray[i].unit_price) * (parseFloat(this.purchaseOrderArray[i].sgst) / 100)
      }

      var totalamount = parseFloat(this.purchaseOrderArray[i].usequantity) * parseInt(this.purchaseOrderArray[i].unit_price) * (parseFloat(this.purchaseOrderArray[i].gst) / 100)
      var amount = parseFloat(this.purchaseOrderArray[i].usequantity) * parseFloat(this.purchaseOrderArray[i].unit_price) + totalamount
      var amountfixed = amount.toFixed(2)
      var totalfixed = totalamount.toFixed(2)
      var amountbfround = amount
      var amountaftround = Math.round(amount)
      this.roundoff += amount - Math.round(amount)
      this.roundoff = this.spliceDecimal(this.roundoff)
      this.totalAMT += Math.round(amount)
      this.totalGST += totalamount
      var totalAMT = this.totalAMT

      var totalGST = this.totalGST.toFixed(2)
      this.totalamountinword = convertNumberToWords(totalAMT, ipaddress.country_code);
      this.documentarray.push({
        sn: [i + 1],
        Index: this.purchaseOrderArray[i].Index,
        product_id: this.purchaseOrderArray[i].product_id,
        hsn_no: this.purchaseOrderArray[i].product_code,
        product_name: this.purchaseOrderArray[i].consignment_name,
        quantity: this.purchaseOrderArray[i].usequantity,
        unit_price: this.purchaseOrderArray[i].unit_price,
        total_amount: this.purchaseOrderArray[i].total_amount,
        cgst: this.purchaseOrderArray[i].cgst,
        sgst: this.purchaseOrderArray[i].sgst,
        gst: this.purchaseOrderArray[i].gst,
        total: totalfixed,
        amount: amountfixed
      })
    }
    console.log(" PURCHASE ORDER ARRAY 2 = " + JSON.stringify(this.documentarray))

    var tempArr = [];
    var pdffile;
    let popupWin;
    let printContents;

    let tableData = [];
    let tablebody = [];
    let tablefooter = [];
    let tableheader1Data = [];
    let tableheader2Data = [];


    // Push header rows into the table data array
    tableheader1Data = [
      'SI NO', 'Item Name', 'HSN CODE', 'Qty', 'Rate', 'Disc', 'GST%', 'GST Amount (Rs)', 'Amount'];
    tableheader2Data = [
      { text: ' ' },
      { text: ' ' },
      { text: ' ' },
      { text: ' ' },
      { text: ' ' },
      { text: ' ' },
      { text: ' ' },
      { text: 'CGST' },
      { text: 'SGST' },
      { text: 'TOTAL' },
      { text: ' ' },
      { text: ' ' },
    ];

    this.documentarray.forEach(data => {
      tablebody.push([
        { text: data.sn, alignment: 'center', fontSize: 8 },  // SN
        { text: data.product_name, fontSize: 8 },// Product Name
        { text: data.hsn_no, fontSize: 8 },  // Product Supplier ID
        { text: data.quantity, fontSize: 8 },// Quantity
        { text: data.unit_price, fontSize: 8 },
        { text: data.disc, fontSize: 8 },
        { text: data.gst, alignment: 'right', fontSize: 8 },
        {
          table: {
            widths: [20, 20, 25],
            body: [
              [{ text: data.cgst, alignment: 'right', fontSize: 8 }, { text: data.sgst, alignment: 'right', fontSize: 8 }, { text: data.total, alignment: 'right', fontSize: 8 }]
            ]
          }, layout: {
            defaultBorder: false,
          }

        },
        { text: data.amount, alignment: 'right', fontSize: 8 }
      

      ]);
    });
    let po_data = {
      content: [
        {
          fontSize: 10,
          text: this.hospitalName + "-" + this.user_type,
          style: '',
          alignment: 'center'
        }
        ,
        {
          text: ' ',
          style: ''
        }
        ,
        {
          text: ' ',
          style: ''
        }

        , {
          fontSize: 10,
          text: this.address1 + ',' + this.address2 + ',' + this.vendorlocation + ',' + this.city_desc + '-' + this.zipcode,
          style: '',
          alignment: 'center'
        },
        {
          text: '______________________________________________________________________________________________'
        }
        , {
          fontSize: 9,
          text: 'PURCHASE ORDER',
          style: '',
          alignment: 'center'
        },
        {
          columns: [
            {
              fontSize: 9,
              text: 'Hospital GSTIN No:',

            },
            {

              text: '',

            },

          ]

        },
        {
          columns: [
            {
              fontSize: 9,
              width: '*',
              text: 'Purchase Order No :' + this.purchase_order_id,

            },
            // {
            //   width: '*',
            //   text: '',

            // }, {
            //   width: 50,
            //   text: '',

            // },
            {
              fontSize: 9,
              width: '*',
              text: 'Date/Time: ' + this.presentdate + "/" + this.current_time1,

            },
          ]
        },
        {
          text: ' ',
          style: ''
        },
        {
          fontSize: 9,
          text: 'To',
          style: ''
        }, {
          fontSize: 9,
          text: this.vendorname,
          style: ''
        },
        , {
          fontSize: 9,
          text: this.vendoraddress + "  " + this.vendorcity,
          style: ''
        },
        {
          text: ' ',
          style: ''
        },
        {
          fontSize: 9,
          text: this.venderstate,
          style: ''
        },
        {
          fontSize: 9,
          text: this.vendorcon,
          style: ''
        },
        {
          fontSize: 9,
          text: 'Phone: ' + this.vendor_Mobile1 + "  " + this.vendor_Mobile2,
          style: ''
        }
        ,
        {
          text: ' ',
          style: ''
        },
        {
          table: {
            body: [
              { text: 'Supplier GSTIN No: ' + this.vendor_gst, fontSize: 9 }
            ]
          }, layout: {
            defaultBorder: false,
          }
        },
        {
          text: ' ',
          style: ''
        }, {
          fontSize: 9,
          text: 'Dear Sir/Mam,',
          style: ''
        },
        {
          fontSize: 9,
          text: 'Sub: Supply of items required for our Hospital',
          style: ''
        }, {
          fontSize: 9,
          text: 'We are pleased to place purchse order for supply of the following items',
          style: ''
        },
        //  {
        this.po_productdetailstable(tablebody,
          [
            { text: 'S NO', fontSize: 9 },
            { text: 'Item Name', fontSize: 9 },
            { text: 'HSN CODE', fontSize: 9 },
            { text: 'Qty', fontSize: 9 },
            { text: 'Rate', fontSize: 9 },
            { text: 'Disc', fontSize: 9 },
            { text: 'GST%', fontSize: 9 },

            // { text: 'GST Amt (Rs)\n ', colspan: 3, fontSize: 9 },
            [
              { text: 'GST Amt', fontSize: 9 },
              {
                table: {
                  widths: [20, 20, 20],
                  body: [
                    [{ text: 'CGST', fontSize: 6 }, { text: 'SGST', fontSize: 6 }, { text: 'TOTAL', fontSize: 6 }],
                  ]
                }, layout: {
                  defaultBorder: false,
                }
              },
            ],
            { text: 'Amt', fontSize: 9 }
          ]
          ,
       
        ),
       
        {
          table: {
          
            widths: [40, 40, 40, 40, 45, 40, 40, 55, 90],

            body: [
              [{ text: 'CGST%', fontSize: 9 },
              { text: 'CGST', fontSize: 9 },
              { text: 'SGST%', fontSize: 9 },
              { text: 'SGST', fontSize: 9 },
              { text: 'IGST%', fontSize: 9 },
              { text: 'IGST', fontSize: 9 },
              { text: 'Total GST', alignment: "LEFT", fontSize: 9 },
              { text: 'Total AMT', alignment: "right", fontSize: 9 },
              { text: ": " + totalAMT, alignment: "right", fontSize: 8 }
              ],
              [{ text: this.totalcgstpercent, alignment: "right", fontSize: 8 },
              { text: this.totalcgst, alignment: "right", fontSize: 8 },
              { text: this.totalsgstpercent, alignment: "right", fontSize: 8 },
              { text: this.totalsgst, alignment: "right", fontSize: 8 },
              { text: ' ' },
              { text: ' ' },
              { text: totalGST, alignment: "LEFT", fontSize: 8 },
              { text: 'Discount', alignment: "right", fontSize: 9 },
              { text: ' ' }
              ]
            ]
          }
        },

        {
          table: {
            widths: [339, 60, 85],

            body: [

              [
                { text: 'Total Amount in Words: ', fontSize: 9 }, {
                  text: 'Round off', alignment: "right", fontSize: 9
                }, { text: this.roundoff, alignment: "right", fontSize: 8 }
              ],
              [
                { text: this.totalamountinword, aligment: "left", fontSize: 9 }, {
                  text: 'Grand total', alignment: "right", fontSize: 9
                }, { text: this.totalAMT, alignment: "right", fontSize: 8 }
              ]
            ]
          }
        },
        {
          text: ' ',
          style: ''
        },
        , {
          text: 'Please expedite supplies accordingly, and arrange to send the Bill of cost in duplicate for arranging', fontSize: 9,
        }, {
          text: 'Payment of the same on receipt and inspectio of materials at our end.', fontSize: 9,
        }, {
          text: ' ',
          style: ''
        },
        {
          columns: [
            {
              width: '*',
              text: 'Remarks: ' + "", fontSize: 9
            }, {
              width: '*',
              text: 'Prepared By: ' + this.username, fontSize: 9,
            }
          ]
        },
      ]
    };

    this.pdfObj = pdfMake.createPdf(po_data);
    console.log(JSON.stringify(po_data))
    this.pdfObj.getBuffer((buffer) => {
      var blob = new Blob([buffer], {
        type: 'application/pdf'
      });

      var reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function (this: any,) {
        pdffile = reader.result;
        this.pdfdata = this.resultarray.map(item => ({
          supp_id: item.supplier_id,

          purchase_order_id: item.pur_order_id,
          pdfdoc: pdffile,
          ordered_items: this.purchaseOrderArray,
          country: ipaddress.country_code
        }));

      this.save_pdf()
      }.bind(this);
      var a = document.createElement('a');
          document.body.appendChild(a);
          // Set the download attribute and file name
           a.href = window.URL.createObjectURL(blob);
           a.download = 'purchase_order.pdf';
           // Trigger the click event
           a.click();
           // Clean up
          window.URL.revokeObjectURL(a.href);
           document.body.removeChild(a);
    });
    printContents = this.pdfObj
  }

  po_productdetailstable(data, header1) {
    return {
      table: {
        // widths: [20, 100, 70, 40, 30, 30, 40, 100, 40],
        widths: [20, 90, 65, 35, 25, 25, 35, 100, 35],
        headerRows: 2,

        body: this.buildTableBody(data, header1)
      }
    }
  }

  buildTableBody(data, header1) {

    var body = [];

    // Push header1
    body.push(header1);

    // Push header2
    // body.push(header2);

    // Push data rows
    data.forEach(function (row) {
      var dataRow = [];
      row.forEach(function (cell) {
        dataRow.push(cell);
      });
      body.push(dataRow);
    });


    return body;
  }


  spliceDecimal(number) {
    // Convert the number to a string
    let numberStr = number.toString();
    // Split the number into integer and decimal parts
    let parts = numberStr.split('.');
    // Check if there's a decimal part

    if (parts.length > 1) {
      // Take the first two digits of the decimal part
      let newDecimal = parts[1].slice(0, 2);
      // Combine the integer part with the truncated decimal part
      var newNumberStr = parts[0] + '.' + newDecimal;
      // Convert back to a number
      return parseFloat(newNumberStr);

    } else {
      // If there's no decimal part, return the original number
      return number;
    }
    // 
  }


  save_pdf() {
    var send_data = {
      po_base: this.poBaseTXT,
      purchase_orders: this.pdfdata
    }
    console.log("pdf_save_send_data = " + JSON.stringify(send_data))
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.httpclt.post(ipaddress.getIp + "inv/upo/", send_data, { headers: headers }).subscribe(
      data => {
        console.log("data savepdf = " + JSON.stringify(data))
        // this.toastr.success("PDF Saved sucessfully")
      },
      error => {

        // this.toastr.success("Unable to save pdf data")
        console.log("error to retrive")
      }

    );
  }

  booknow(data){
    // this.purchaseOrderArray.push(data)
    // inv/scfc
    var senddata;
    var gst_amount;
    var costlineamount;
    var amount= parseFloat(data.cost_price) * parseFloat(this.usequantity)
    if(this.usequantity != undefined){
      this.usequantity=this.usequantity
    }else{
      this.usequantity =0
    }
    var quantity=data.cost_price*this.usequantity
    if(data.gst != undefined){
      if(data.mrp != undefined){
        data.mrp =data.mrp 
      }else{
        data.mrp=0
      }
      gst_amount= quantity + parseFloat((quantity * (data.gst / 100)).toFixed(2))
    }else{
      gst_amount=0
    }
    data.usequantity = this.usequantity;
    data.gst_amount = gst_amount;
    data.amount=amount
    // Push updated data into purchaseOrderArray
    this.purchaseOrderArray.push(data);
    senddata={
      pharmacy_id: this.pharmacyID ,
      hptl_clinic_id:this.hospitalid,
      consignment_name: data.consignment_name,
      ordered_items: this.purchaseOrderArray,
      country: ipaddress.country_code,
      supp_id:data.supplier_id,
      amount:amount,
      po_type:'consignment',
      consignment_id:data.consignment_id,
      quantity:this.usequantity,
      mr_no:this.patient_id,
      client_reg_id:this.client_reg_id,
      relation_id:this.relation_id,
      gst_amount:gst_amount,
      created_by:this.username,
      department_id:"15",
      store_id:this.userInfo.hospitals[0].pharma_id,
      po_base: this.poBaseTXT,
      
    }
// {"pharmacy_id":"27","hptl_clinic_id":"66","country":"IN","supp_id":"13","amount":"1600","po_type":"consignment","consignment_id":"1","quantity":"1","mr_no":"SGH090923","client_reg_id":"17506117518000178","relation_id":"1","gst_amount":"1650.00","created_by":"Christopher","department_id":"15","store_id":"27"}
console.log("send_data"+JSON.stringify(senddata))
var headers = new Headers();
headers.append('Content-Type', 'application/json');
this.http.post(ipaddress.getIp + "inv/scfc", senddata, 
  
  { headers: headers }).subscribe(
  data => {
    var obj=JSON.parse(data["_body"]);
   console.log("save consi_data"+JSON.stringify(obj))
    if(obj.key != '0' && obj.key != undefined){
      this.usequantity=''
      this.name=''
      this.purchase_order_id = data.json().po_no
      this.resultarray.push({
        supplier_id: data.json().supplier_id,
        po_no: data.json().po_no,
        pur_order_id: data.json().purchase_order_id
      })
    //  this.po_generator()
      this.toastr.success('Consingment booked successfully')
    }else{
      this.toastr.error("Error in save")
    }
      // this.getData()
  },error =>{

  });


  


  }


}
