<div class="container">
  <div class="row">
    <div class="col-12">
      <mdb-card>
        <mdb-card-header class="bg-white">
          <div class="headerCover">
            <div class="headerTilte app_list_header">
              <h5 class="m-0" class="mainHeadingStyle">Casesheet view</h5>
            </div>
            <div class="headerButtons">
              <img src="../../../assets/ui_icons/buttons/Back_button.svg" class="saveimgbtn_inpatinfo"
                (click)="backClicked()" />
              <img class="" src="../../../assets/img/printer.svg" style="width:25px; height:25px; margin: 0 0 0 7px;"
                (click)="print_area()">
            </div>
          </div>

        </mdb-card-header>
        <mdb-card-body>
          <div class="container">
            <div class="row">
              <!-- <div class="cover_div">
                <div class="header_lable"></div>
                <div class="content_cover">
                  <div class="row">                  
                    <table id="table_header" class="d-flex justify-content-center">
                      <tr>
                        <td class="firsttd_style"></td>
                        <td style="text-align: left;">
                          <p class="hosp_details"><strong>{{hosp_name}}</strong></p>
                          <p class="hosp_details_addr">{{hosp_addr}} {{hosp_csz}}</p>
                          <p class="hosp_details_addr">Tel: {{hosp_tel}}</p>
                        </td>
                        <td class="threetd_style">
                          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                        </td>
                      </tr>
                    </table>
                  </div>

                </div>
              </div> -->
            </div>
            <div class="row margin">
              <div class="cover_div">
                <div class="header_lable">Personal details</div>
                <div class="content_cover">

                  <div class="row">
                    <div class="col-3">
                      <p class="textformat"><span style="font-weight: 550;">MR No:</span> {{patient_id}}</p>

                    </div>
                    <div class="col-3">
                      <p class="textformat"><span style="font-weight: 550;">Name:</span> {{patient_name}}</p>

                    </div>
                    <div class="col-3">
                      <p class="textformat"><span style="font-weight: 550;">Age/Gender:</span> {{patient_age}}/
                        {{patient_gender}}</p>
                    </div>
                    <div class="col-3">
                      <p class="textformat"><span style="font-weight: 550;">Current occupation:</span>
                        {{current_occup}}</p>
                    </div>
                    <!-- <div class="col-3" *ngIf="ageflag && genderflag">
                        <p class="textformat"><span style="font-weight: 550;">Mobile:</span> {{patient_age}}/ {{patient_gender}}</p>
                      </div> -->

                    <div class="col-3">
                      <p class="textformat"><span style="font-weight: 550;">Doctor:</span> {{doctor_name}}</p>
                    </div>
                    <div class="col-3">
                      <p class="textformat"><span style="font-weight: 550;">Disease:</span> {{disease}}</p>
                    </div>
                    <div class="col-3">
                      <p class="textformat"><span style="font-weight: 550;">Symptoms:</span> {{symptoms}}</p>
                    </div>
                    <div class="col-3">
                      <p class="textformat"><span style="font-weight: 550;">Stress factors:</span> {{stress_factor}}
                      </p>
                    </div>
                    <!-- <div class="col-3">
                                    Op number: {{outpatientnumber}}
                                </div> -->


                  </div>


                </div>
              </div>
            </div>
            <!-- cardio -->
            <div class="row " *ngIf="spl_name == 'Cardiology'">
              <div class="col-12">
                <div class="row margin">
                  <div class="cover_div">
                    <div class="header_lable">Vitals</div>
                    <div class="content_cover">
                      <div class="row">
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Height:</span> {{height}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Weight:</span> {{weight}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">BMI:</span> {{bmi}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Body type:</span> {{body_type}}</p>
                        </div>
                        <div class="col-6">
                          <p class="textformat"><span style="font-weight: 550;">Body nature:</span> {{body_nature}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Heart sound:</span> {{heart_sound}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Diatory habits:</span> {{diet_habits}}
                          </p>
                        </div>


                      </div>
                      <div class="row" [hidden]="bp_flag">
                        <div class="col-6">
                          <p class="textformat"><span style="font-weight: 550;">Blood pressure:</span> Right arm:
                            {{bp_right}}, Left arm: {{bp_left}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- chest pain -->
                <div class="row margin">
                  <div class="cover_div">
                    <div class="header_lable">Chest pain</div>
                    <div class="content_cover">
                      <div class="row">
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Pain location:</span> {{pain_location}}
                          </p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Pain character:</span> {{pain_location}}
                          </p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Pain character:</span> {{weight}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Radiation:</span> {{bmi}}</p>
                        </div>
                        <div class="col-6">
                          <p class="textformat"><span style="font-weight: 550;">Duration:</span> {{body_type}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Aggravating factor:</span>
                            {{body_nature}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Relieving factor:</span> {{heart_sound}}
                          </p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Pain intensity:</span> {{body_nature}}
                          </p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Pain cause:</span> {{heart_sound}}</p>
                        </div>


                      </div>

                    </div>
                  </div>
                </div>
                <!--Breathing  -->
                <div class="row margin">
                  <div class="cover_div">
                    <div class="header_lable">Breathing dificulties</div>
                    <div class="content_cover">
                      <div class="row">
                        <div class="col-3">
                          <p class="textformat"><span style="">Trigers:</span> {{breath_trigers}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="">Onset:</span> {{breath_onset}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="">Cold sweats:</span> {{breath_cold_sweat}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="">Diziness:</span> {{breath_diziness}}</p>
                        </div>
                        <div class="col-6">
                          <p class="textformat"><span style="">Vomiting:</span> {{breath_vomiting}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="">Palpitation:</span> {{breath_palpitation}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="">Palpitation type:</span> {{breath_palp_type}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="">Cough:</span> {{breath_cough}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="">Mucus:</span> {{breath_mucus}}</p>
                        </div>


                      </div>

                    </div>
                  </div>
                </div>
                <div class="row margin" [hidden]="mainconc_flag">
                  <div class="cover_div">
                    <div class="header_lable">Past medical history</div>
                    <div class="content_cover">
                      <div [innerHTML]="main_concerns"></div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- diab -->
            <div class="row" *ngIf="spl_name == 'Diabetes'">
              <div class="col-12">
                <div class="row margin">
                  <div class="cover_div">
                    <div class="header_lable">Vitals</div>
                    <div class="content_cover">
                      <div class="row">
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Height:</span> {{height}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Weight:</span> {{weight}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">BMI:</span> {{bmi}}</p>
                        </div>


                      </div>

                    </div>
                  </div>
                </div>
                <div class="row margin">
                  <div class="cover_div">
                    <div class="header_lable">concerns</div>
                    <div class="content_cover">
                      <div class="row">
                        <div class="col-3" [hidden]="mainconc_flag">
                          <div style="display: flex;">
                            <p class="textformat"><span style="">Main concerns: </span></p>
                            <div [innerHTML]="main_concerns"></div>
                          </div>
                        </div>
                        <div class="col-3" [hidden]="medhist_flag">
                          <div style="display: flex;">
                            <p class="textformat"><span style="">Medical history: </span></p>
                            <div [innerHTML]="med_hist"></div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <div class="row margin">
                  <div class="cover_div">
                    <div class="header_lable">Recommended excercises</div>
                    <div class="content_cover">
                      <div class="row">
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Excercise:</span> {{rec_excercise}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Duration:</span> {{rec_dur}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Frequency:</span> {{rec_freq}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!--Comorbidities  -->
                <div class="row margin" [hidden]="comorb_flag">
                  <div class="cover_div">
                    <div class="header_lable">Comorbidities</div>
                    <div class="content_cover">
                      <div class="row">
                        <div class="col-3">
                          <p class="textformat"><span style="">Cardiological:</span> {{cardiodis}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="">Mobility:</span> {{mobility}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="">Nephrological:</span> {{neprhodis}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="">Neurological:</span> {{neurodis}}</p>
                        </div>
                        <div class="col-6">
                          <p class="textformat"><span style="">Retino issues:</span> {{retinodis}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="">Dermatological:</span> {{skindis}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="">Hearing issues:</span> {{hearingdis}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="">Foot issues:</span> {{footdis}}</p>
                        </div>


                      </div>

                    </div>
                  </div>
                </div>

              </div>
            </div>

            <!-- ENT -->

            <div class="row " *ngIf="spl_name == 'ENT'">
              <div class="col-12">
                <div class="row margin">
                  <div class="cover_div">
                    <div class="header_lable">Vitals</div>
                    <div class="content_cover">
                      <div class="row">
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Height:</span> {{height}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Weight:</span> {{weight}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">BMI:</span> {{bmi}}</p>
                        </div>


                      </div>

                    </div>
                  </div>
                </div>
                <div class="row margin">
                  <div class="cover_div">
                    <div class="header_lable">concerns</div>
                    <div class="content_cover">
                      <div class="row">
                        <div class="col-3" [hidden]="mainconc_flag">
                          <div style="display: flex;">
                            <p class="textformat"><span style="">Main concerns: </span></p>
                            <div [innerHTML]="main_concerns"></div>
                          </div>
                        </div>
                        <div class="col-3" [hidden]="medhist_flag">
                          <div style="display: flex;">
                            <p class="textformat"><span style="">Medical history: </span></p>
                            <div [innerHTML]="med_hist"></div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>




              </div>
            </div>
            <!-- *ngIf="spl_name == 'General' || spl_name == 'General surgery'" -->
            <div class="row" *ngIf="spl_name == 'General' || spl_name == 'General surgery'">
              <div class="col-12">
                <div class="row margin">
                  <div class="cover_div">
                    <div class="header_lable">Vitals</div>
                    <div class="content_cover">
                      <div class="row">
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Height:</span> {{height}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Weight:</span> {{weight}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">BMI:</span> {{bmi}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Body type:</span> {{body_type}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Body nature:</span> {{body_nature}}</p>
                        </div>


                      </div>
                      <div class="row" [hidden]="bp_flag">
                        <div class="col-6">
                          <p class="textformat"><span style="font-weight: 550;">Blood pressure:</span> Right arm:
                            {{bp_right}}, Left arm: {{bp_left}}</p>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div class="row margin" [hidden]="mainconc_flag || medhist_flag" >
                  <div class="cover_div">
                    <div class="header_lable">concerns</div>
                    <div class="content_cover">
                      <div class="row">
                        <div class="col-3" [hidden]="mainconc_flag">
                          <div style="display: flex;">
                            <p class="textformat"><span style="">Main concerns: </span></p>
                            <div [innerHTML]="main_concerns"></div>
                          </div>
                        </div>
                        <div class="col-3" [hidden]="medhist_flag">
                          <div style="display: flex;">
                            <p class="textformat"><span style="">Past medical history: </span></p>
                            <div [innerHTML]="med_hist"></div>
                          </div>
                        </div>
                        <div class="col-3" [hidden]="medhist_flag">
                          <div style="display: flex;">
                            <div class="row">
                              <div class="col-12">
                                <p><b>Surgery:</b> {{surgery}}</p>
                                <p><b>Procedure:</b> {{surgery_proc}}</p>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>




              </div>
            </div>

            <!-- spl_name == 'Gynecology -->
            <div class="row" *ngIf="spl_name == 'Gynecology'">
              <div class="col-12">
                <div class="row margin">
                  <div class="cover_div">
                    <div class="header_lable">Vitals</div>
                    <div class="content_cover">
                      <div class="row">
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Height:</span> {{height}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Weight:</span> {{weight}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">BMI:</span> {{bmi}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Body type:</span> {{body_type}}</p>
                        </div>
                        <div class="col-6">
                          <p class="textformat"><span style="font-weight: 550;">Body nature:</span> {{body_nature}}</p>
                        </div>
                        <!-- <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Heart sound:</span> {{heart_sound}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Diatory habits:</span> {{diet_habits}}
                          </p>
                        </div> -->


                      </div>
                      <div class="row" [hidden]="bp_flag">
                        <div class="col-6">
                          <p class="textformat"><span style="font-weight: 550;">Blood pressure:</span> Right arm:
                            {{bp_right}}, Left arm: {{bp_left}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Menstrual -->
                <div class="row margin">
                  <div class="cover_div">
                    <div class="header_lable">Menstrual</div>
                    <div class="content_cover">
                      <div class="row">
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Stage:</span> {{mens_stage}}
                          </p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Periods:</span> {{mens_periods}}
                          </p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Last menstrual period:</span> {{last_mens_period}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Cycle length:</span> {{mens_cycle_length}}</p>
                        </div>
                        <div class="col-6">
                          <p class="textformat"><span style="font-weight: 550;">Menstrual pain:</span> {{mens_pain}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Pain frequency:</span>
                            {{mens_pain_freq}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Pain timing:</span> {{mens_pain_time}}
                          </p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Pain character:</span> {{mens_pain_char}}
                          </p>
                        </div>
                        <!-- <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Pain cause:</span> {{heart_sound}}</p>
                        </div> -->


                      </div>

                    </div>
                  </div>
                </div>
                <!--concerns  -->
           
                <div class="row margin" [hidden]="mainconc_flag || medhist_flag" >
                  <div class="cover_div">
                    <div class="header_lable">concerns</div>
                    <div class="content_cover">
                      <div class="row">
                        <div class="col-3" [hidden]="mainconc_flag">
                          <div style="display: flex;">
                            <p class="textformat"><span style="">Main concerns: </span></p>
                            <div [innerHTML]="main_concerns"></div>
                          </div>
                        </div>
                        <div class="col-3" [hidden]="medhist_flag">
                          <div style="display: flex;">
                            <p class="textformat"><span style="">Past medical history: </span></p>
                            <div [innerHTML]="med_hist"></div>
                          </div>
                        </div>
                        <div class="col-3" [hidden]="medhist_flag">
                          <div style="display: flex;">
                            <div class="row">
                              <div class="col-12">
                                <p><b>Surgery:</b> {{surgery}}</p>
                                <p><b>Procedure:</b> {{surgery_proc}}</p>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- spl_name == 'others -->
            <div class="row" *ngIf="others">
              <div class="col-12">
                <div class="row margin">
                  <div class="cover_div">
                    <div class="header_lable">Vitals</div>
                    <div class="content_cover">
                      <div class="row">
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Height:</span> {{height}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Weight:</span> {{weight}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">BMI:</span> {{bmi}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Body type:</span> {{body_type}}</p>
                        </div>
                        <div class="col-3">
                          <p class="textformat"><span style="font-weight: 550;">Body nature:</span> {{body_nature}}</p>
                        </div>
                        <div class="col-3"  [hidden]="bp_flag">
                          <p class="textformat"><span style="font-weight: 550;">Blood pressure:</span> {{bp_right}}</p>
                        </div>


                      </div>
                  

                    </div>
                  </div>
                </div>
                <div class="row margin" [hidden]="mainconc_flag || medhist_flag" >
                  <div class="cover_div">
                    <div class="header_lable">concerns</div>
                    <div class="content_cover">
                      <div class="row">
                        <div class="col-3" [hidden]="mainconc_flag">
                          <div style="display: flex;">
                            <p class="textformat"><span style="">Main concerns: </span></p>
                            <div [innerHTML]="main_concerns"></div>
                          </div>
                        </div>
                        <div class="col-3" [hidden]="medhist_flag">
                          <div style="display: flex;">
                            <p class="textformat"><span style="">Past medical history: </span></p>
                            <div [innerHTML]="med_hist"></div>
                          </div>
                        </div>
                        <div class="col-3" [hidden]="medhist_flag">
                          <div style="display: flex;">
                            <div class="row">
                              <div class="col-12">
                                <p><b>Surgery:</b> {{surgery}}</p>
                                <p><b>Procedure:</b> {{surgery_proc}}</p>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>




              </div>
            </div>
              <!-- spl_name == pediatrics -->
              <div class="row" *ngIf="spl_name == 'Pediatrics'">
                <div class="col-12">
                  <div class="row margin">
                    <div class="cover_div">
                      <div class="header_lable">Clinical</div>
                      <div class="content_cover">
                        <div class="row">
                          <div class="col-3">
                            <p class="textformat"><span style="font-weight: 550;">Complications:</span> {{ped_complic}}</p>
                          </div>
                          <div class="col-3">
                            <p class="textformat"><span style="font-weight: 550;">Mother's age at birth:</span> {{mother_age}}</p>
                          </div>
                          <div class="col-3">
                            <p class="textformat"><span style="font-weight: 550;">Delivery type:</span> {{delivery_type}}</p>
                          </div>
                          <div class="col-3">
                            <p class="textformat"><span style="font-weight: 550;">Gestational age:</span> {{gestational_age}}</p>
                          </div>
                          <div class="col-3">
                            <p class="textformat"><span style="font-weight: 550;">Allergies:</span> {{allergies}}</p>
                          </div>
                          <div class="col-3"  [hidden]="bp_flag">
                            <p class="textformat"><span style="font-weight: 550;">Feeding habit:</span> {{feeding_habit}}</p>
                          </div>
  
  
                        </div>
                    
  
                      </div>
                    </div>
                  </div>
                  <div class="row margin" [hidden]="mainconc_flag || medhist_flag" >
                    <div class="cover_div">
                      <div class="header_lable">concerns</div>
                      <div class="content_cover">
                        <div class="row">
                          <div class="col-3" [hidden]="mainconc_flag">
                            <div style="display: flex;">
                              <p class="textformat"><span style="">Main concerns: </span></p>
                              <div [innerHTML]="main_concerns"></div>
                            </div>
                          </div>
                          <div class="col-3" [hidden]="medhist_flag">
                            <div style="display: flex;">
                              <p class="textformat"><span style="">Past medical history: </span></p>
                              <div [innerHTML]="med_hist"></div>
                            </div>
                          </div>
                       
  
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row margin" [hidden]="materill_flag" >
                    <div class="cover_div">
                      <div class="header_lable">Maternal illness</div>
                      <div class="content_cover">
                        <div class="row">
                      
                          <div class="col-3" [hidden]="medhist_flag">
                            <div style="display: flex;">
                              <div [innerHTML]="med_hist"></div>
                            </div>
                          </div>
                       
  
                        </div>
                      </div>
                    </div>
                  </div>
  
  
  
  
                </div>
              </div>
                <!-- fdrugs -->
                <div class="row margin">
                  <div class="col-12">
                    <div class="cover_div" style="padding-bottom: 13px;" *ngIf="druglist.length !=0">
                      <div class="header_lable">Medicines</div>
                      <div class="content_cover">
                        <div class="table-responsive dig_table_overflow">
                          <table *ngIf="druglist.length" class="table table-nowrap table-sm dataTable billlisttable">
                            <thead>
                              <tr>
                                <th>Medicine</th>
                                <th>Dosage</th>
                                <th>Days</th>                         
                              </tr>
                            </thead>
                            <tbody>
                              <tr style="height: 30px;" *ngFor="let medicine of druglist">
                                <td style="font-size: 12px;" class="txtalign">{{medicine.medicine_name}}</td>
                                <td style="font-size: 12px;" class="txtalignright">{{medicine.medicine_dosage}}</td>
                                <td style="font-size: 12px;" class="txtalignright">{{medicine.medicine_intakedays+" -
                                  "+medicine.medicine_food}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
               
            <!-- olf -->
           
          </div>

        </mdb-card-body>
      </mdb-card>
    </div>
  </div>
</div>

<!-- print -->
<div [hidden]="true">
  {{spl_name}}
  <div *ngIf="spl_name == 'Cardiology'" class="col-12 col-md-12 col-lg-12 col-xl-10">
    <div #casesheetsummary id="casesheetsummary">
      <table style="border:'0';margin-left: 20px !important;margin-right: 20px !important; width:95%">
        <thead>
          <tr>
            <th style="width:100%;">
              <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                <div style="width: 90%; height: 278px;">
                  <!-- <img alt="image" src={{hospitalLogo}} width="100%" height="170px"> -->
                  <div style="height: 170px;"></div>
                  <table
                    style="width: 100%; font-size: 11px; border: 1px solid black; margin-top: 5px; border-radius: 10px;   margin-bottom: 5px;">
                    <tbody>
                      <tr>
                        <td style="padding-left: 5px;"><strong>MR No</strong></td>
                        <td>: {{patient_id}}</td>
                        <td style="padding-left: 5px;"><strong>Date:</strong></td>
                        <td> : {{curr_date}}</td>
                      </tr>
                      <tr>
                        <td style="padding-left: 5px;"><strong>Patient Name</strong></td>
                        <td>: {{patient_name}}</td>
                        <td style="padding-left: 5px;"><strong>Doctor</strong></td>
                        <td> : {{patient_name}}</td>
                      </tr>
                      <tr>
                        <td style="padding-left: 5px;"><strong>Age/Gender</strong></td>
                        <td>: {{patient_age}}/ {{patient_gender}}</td>
                        <td style="padding-left: 5px;"><strong>Occupation</strong></td>
                        <td>: {{current_occup}}</td>
  
  
                      </tr>
                  
                    </tbody>
                  </table>
                </div>
              </div>
            </th>
          </tr>
        </thead>
  
        <tbody>
          <tr>
            <td width="100%">
              <div style="width: 100%;height: auto;display: contents; display: flex; justify-content: center;">
                <table class=paging style="width:100%;">
                  <tbody>
                    <tr>
                      <td>
                        <div class="main"
                          style="display: flex; flex-direction: column; justify-content: center; align-items: center; margin-top: 10px; ">
                          <div style="margin-left: 25px !important;margin-right:25px !important; width:90%;">
                            <div>
                              <p><b>General examination :</b></p>
                            </div>
                            <div class="content_cover">
                              <div class="row" >
                                <div class="col-3">
                                  <p class="textformat"><span style="font-weight: 550;">Height:</span> {{height}}</p>
                                </div>
                              </div>
                              <div class="row" >
                                <div class="col-3">
                                  <p class="textformat"><span style="font-weight: 550;">Weight:</span> {{weight}}</p>
                                </div>
                                </div>
                                <div class="row" >
                                <div class="col-3">
                                  <p class="textformat"><span style="font-weight: 550;">BMI:</span> {{bmi}}</p>
                                </div>
                              </div>
                              <div class="row" >
                                <div class="col-3">
                                  <p class="textformat"><span style="font-weight: 550;">Body
                                    type:</span> {{body_type}}</p>
                                </div>
                              </div>
                              <div class="row" >
                                <div class="col-3">
                                  <p class="textformat"><span style="font-weight: 550;">Body nature:</span> {{body_nature}}</p>
                                </div>                         
                              
              
                                
                              </div>
                            </div>
                          </div>
                          <div style="margin-left: 25px !important;margin-right:25px !important; width:90%;">
                            <div class="row" >
                              <div class="col-3"  [hidden]="mainconc_flag">
                                <p class="textformat"><span style="font-weight: 550;">Main concerns:</span> <span [hidden]="mainconc_flag" [innerHtml]="main_concerns"></span></p>
                              </div>
                              <div class="col-3" [hidden]="medhist_flag">
                                <p class="textformat"><span style="font-weight: 550;">Past medical history:</span> <span [hidden]="medhist_flag" [innerHtml]="med_hist"></span></p>
                              </div>
                              <div class="col-3" [hidden]="medhist_flag">
                                <p class="textformat"><span style="font-weight: 550;">Complaints:</span> <span [hidden]="medhist_flag" [innerHtml]="complaints"></span></p>
                              </div>
                              <div class="col-3" [hidden]="bp_flag">
                                <p class="textformat"><span style="font-weight: 550;">Blood pressure:</span> <span [hidden]="bp_flag">Right arm: {{bp_right}}, Left arm: {{bp_left}}</span></p>
                              </div>
                              <div class="col-3" [hidden]="bp_flag">
                                <p class="textformat"><span style="font-weight: 550;">Medicines:</span> <span [hidden]="bp_flag">Surgery: {{surgery}} <br/> Procedure: {{surgery_proc}}</span></p>
                              </div>
                              </div>
                            </div>
                       
  
                          <div style="width:90%;margin-left: 25px !important;margin-right:25px !important;" [hidden]="med_flag">
                          <div class="col-12">
                              <table *ngIf="druglist.length != 0"
                                  style=" border: 1px solid black;border-collapse: collapse;">
  
                                  <tr style="text-align: center;">
                                      <th>Medicine</th>
                                      <th>Dosage</th>
                                      <th>Days</th>
                                  </tr>
                                  <tr *ngFor="let medicine of druglist">
                                      <td
                                          style="border: 1px solid black;border-collapse: collapse;width: 310px;height: 30px;">
                                          {{medicine.medicine_name}}
                                      </td>
                                      <td
                                          style="text-align: left;border: 1px solid black;border-collapse: collapse;width: 100px;padding: 15px !important;height: 30px;">
                                          {{medicine.medicine_dosage}}
                                      </td>
                                      <td
                                          style="text-align: center;border-collapse: collapse;width: 100px;border: 1px solid black;height: 30px;width: 310px;">
                                          {{medicine.medicine_intakedays+" -
                                          "+medicine.medicine_food}}
                                      </td>
  
                                  </tr>
                              </table>
                          </div>
                      </div>
                          <br />
                        
                        </div>
                      </td>
                    
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td width="100%">
                        <table style="border:'0';width:100%">
                          <tr>
                            <td style="width:100%;height: 70px;">&nbsp;
                            </td>
                          </tr>
                        </table>
                  </tfoot>
                </table>
              </div>
            </td>
          </tr>
        </tbody>
  
      </table>
  
      <footer style="width:100%;">
        <table style="border:'0';width:100%; margin-bottom: 0;">
          <tr>
            <td style="width:100%;height: 100px;">&nbsp;
              <!-- <img src={{hospitalFooter}} width="100%" height="200px"> -->
            </td>
          </tr>
        </table>
      </footer>
    </div>
  </div>
  <div *ngIf="spl_name == 'Diabetes'" class="col-12 col-md-12 col-lg-12 col-xl-10">
    <div #casesheetsummary id="casesheetsummary">
      <table style="border:'0';margin-left: 20px !important;margin-right: 20px !important; width:95%">
        <thead>
          <tr>
            <th style="width:100%;">
              <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                <div style="width: 90%; height: 278px;">
                  <!-- <img alt="image" src={{hospitalLogo}} width="100%" height="170px"> -->
                  <div style="height: 170px;"></div>
                  <table
                    style="width: 100%; font-size: 11px; border: 1px solid black; margin-top: 5px; border-radius: 10px;   margin-bottom: 5px;">
                    <tbody>
                      <tr>
                        <td style="padding-left: 5px;"><strong>MR No</strong></td>
                        <td>: {{patient_id}}</td>
                        <td style="padding-left: 5px;"><strong>Date:</strong></td>
                        <td> : {{curr_date}}</td>
                      </tr>
                      <tr>
                        <td style="padding-left: 5px;"><strong>Patient Name</strong></td>
                        <td>: {{patient_name}}</td>
                        <td style="padding-left: 5px;"><strong>Doctor</strong></td>
                        <td> : {{patient_name}}</td>
                      </tr>
                      <tr>
                        <td style="padding-left: 5px;"><strong>Age/Gender</strong></td>
                        <td>: {{patient_age}}/ {{patient_gender}}</td>
                        <td style="padding-left: 5px;"><strong>Occupation</strong></td>
                        <td>: {{current_occup}}</td>
  
  
                      </tr>
                  
                    </tbody>
                  </table>
                </div>
              </div>
            </th>
          </tr>
        </thead>
  
        <tbody>
          <tr>
            <td width="100%">
              <div style="width: 100%;height: auto;display: contents; display: flex; justify-content: center;">
                <table class=paging style="width:100%;">
                  <tbody>
                    <tr>
                      <td>
                        <div class="main"
                          style="display: flex; flex-direction: column; justify-content: center; align-items: center; margin-top: 10px; ">
                          <div style="margin-left: 25px !important;margin-right:25px !important; width:90%;">
                            <div>
                              <p><b>General examination :</b></p>
                            </div>
                            <div class="content_cover">
                              <div class="row" >
                                <div class="col-3">
                                  <p class="textformat"><span style="font-weight: 550;">Height:</span> {{height}}</p>
                                </div>
                              </div>
                              <div class="row" >
                                <div class="col-3">
                                  <p class="textformat"><span style="font-weight: 550;">Weight:</span> {{weight}}</p>
                                </div>
                                </div>
                                <div class="row" >
                                <div class="col-3">
                                  <p class="textformat"><span style="font-weight: 550;">BMI:</span> {{bmi}}</p>
                                </div>
                              </div>
                              <div class="row" >
                                <div class="col-3">
                                  <p class="textformat"><span style="font-weight: 550;">Body
                                    type:</span> {{body_type}}</p>
                                </div>
                              </div>
                              <div class="row" >
                                <div class="col-3">
                                  <p class="textformat"><span style="font-weight: 550;">Body nature:</span> {{body_nature}}</p>
                                </div>                         
                              
              
                                
                              </div>
                            </div>
                          </div>
                          <div style="margin-left: 25px !important;margin-right:25px !important; width:90%;">
                            <div class="row" >
                              <div class="col-3"  [hidden]="mainconc_flag">
                                <p class="textformat"><span style="font-weight: 550;">Complaints:</span> <span [hidden]="mainconc_flag" [innerHtml]="main_concerns"></span></p>
                              </div>
                              <div class="col-3" [hidden]="medhist_flag">
                                <p class="textformat"><span style="font-weight: 550;">Present illness:</span> <span [hidden]="medhist_flag" [innerHtml]="med_hist"></span></p>
                              </div>
                              <!-- <div class="col-3" [hidden]="medhist_flag">
                                <p class="textformat"><span style="font-weight: 550;">Complaints:</span> <span [hidden]="medhist_flag" [innerHtml]="complaints"></span></p>
                              </div> -->
                              <div class="col-3" [hidden]="bp_flag">
                                <p class="textformat"><span style="font-weight: 550;">Blood pressure:</span> <span [hidden]="bp_flag">Right arm: {{bp_right}}, Left arm: {{bp_left}}</span></p>
                              </div>
                              <div class="col-3" [hidden]="bp_flag">
                                <p class="textformat"><span style="font-weight: 550;">Medicines:</span> <span [hidden]="bp_flag">Surgery: {{surgery}} <br/> Procedure: {{surgery_proc}}</span></p>
                              </div>
                              </div>
                            </div>
                       
  
                          <div style="width:90%;margin-left: 25px !important;margin-right:25px !important;" [hidden]="med_flag">
                          <div class="col-12">
                              <table *ngIf="druglist.length != 0"
                                  style=" border: 1px solid black;border-collapse: collapse;">
  
                                  <tr style="text-align: center;">
                                      <th>Medicine</th>
                                      <th>Dosage</th>
                                      <th>Days</th>
                                  </tr>
                                  <tr *ngFor="let medicine of druglist">
                                      <td
                                          style="border: 1px solid black;border-collapse: collapse;width: 310px;height: 30px;">
                                          {{medicine.medicine_name}}
                                      </td>
                                      <td
                                          style="text-align: left;border: 1px solid black;border-collapse: collapse;width: 100px;padding: 15px !important;height: 30px;">
                                          {{medicine.medicine_dosage}}
                                      </td>
                                      <td
                                          style="text-align: center;border-collapse: collapse;width: 100px;border: 1px solid black;height: 30px;width: 310px;">
                                          {{medicine.medicine_intakedays+" -
                                          "+medicine.medicine_food}}
                                      </td>
  
                                  </tr>
                              </table>
                          </div>
                      </div>
                          <br />
                        
                        </div>
                      </td>
                    
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td width="100%">
                        <table style="border:'0';width:100%">
                          <tr>
                            <td style="width:100%;height: 70px;">&nbsp;
                            </td>
                          </tr>
                        </table>
                  </tfoot>
                </table>
              </div>
            </td>
          </tr>
        </tbody>
  
      </table>
  
      <footer style="width:100%;">
        <table style="border:'0';width:100%; margin-bottom: 0;">
          <tr>
            <td style="width:100%;height: 100px;">&nbsp;
              <!-- <img src={{hospitalFooter}} width="100%" height="200px"> -->
            </td>
          </tr>
        </table>
      </footer>
    </div>
  </div>
  <div *ngIf="spl_name == 'ENT'" class="col-12 col-md-12 col-lg-12 col-xl-10">
    <div #casesheetsummary id="casesheetsummary">
      <table style="border:'0';margin-left: 20px !important;margin-right: 20px !important; width:95%">
        <thead>
          <tr>
            <th style="width:100%;">
              <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                <div style="width: 90%; height: 278px;">
                  <!-- <img alt="image" src={{hospitalLogo}} width="100%" height="170px"> -->
                  <div style="height: 170px;"></div>
                  <table
                    style="width: 100%; font-size: 11px; border: 1px solid black; margin-top: 5px; border-radius: 10px;   margin-bottom: 5px;">
                    <tbody>
                      <tr>
                        <td style="padding-left: 5px;"><strong>MR No</strong></td>
                        <td>: {{patient_id}}</td>
                        <td style="padding-left: 5px;"><strong>Date:</strong></td>
                        <td> : {{curr_date}}</td>
                      </tr>
                      <tr>
                        <td style="padding-left: 5px;"><strong>Patient Name</strong></td>
                        <td>: {{patient_name}}</td>
                        <td style="padding-left: 5px;"><strong>Doctor</strong></td>
                        <td> : {{patient_name}}</td>
                      </tr>
                      <tr>
                        <td style="padding-left: 5px;"><strong>Age/Gender</strong></td>
                        <td>: {{patient_age}}/ {{patient_gender}}</td>
                        <td style="padding-left: 5px;"><strong>Occupation</strong></td>
                        <td>: {{current_occup}}</td>
  
  
                      </tr>
                  
                    </tbody>
                  </table>
                </div>
              </div>
            </th>
          </tr>
        </thead>
  
        <tbody>
          <tr>
            <td width="100%">
              <div style="width: 100%;height: auto;display: contents; display: flex; justify-content: center;">
                <table class=paging style="width:100%;">
                  <tbody>
                    <tr>
                      <td>
                        <div class="main"
                          style="display: flex; flex-direction: column; justify-content: center; align-items: center; margin-top: 10px; ">
                          <div style="margin-left: 25px !important;margin-right:25px !important; width:90%;">
                            <div>
                              <p><b>General examination :</b></p>
                            </div>
                            <div class="content_cover">
                              <div class="row" >
                                <div class="col-3">
                                  <p class="textformat"><span style="font-weight: 550;">Height:</span> {{height}}</p>
                                </div>
                              </div>
                              <div class="row" >
                                <div class="col-3">
                                  <p class="textformat"><span style="font-weight: 550;">Weight:</span> {{weight}}</p>
                                </div>
                                </div>
                                <div class="row" >
                                <div class="col-3">
                                  <p class="textformat"><span style="font-weight: 550;">BMI:</span> {{bmi}}</p>
                                </div>
                              </div>
                              <div class="row" >
                                <div class="col-3">
                                  <p class="textformat"><span style="font-weight: 550;">Body
                                    type:</span> {{body_type}}</p>
                                </div>
                              </div>
                              <div class="row" >
                                <div class="col-3">
                                  <p class="textformat"><span style="font-weight: 550;">Body nature:</span> {{body_nature}}</p>
                                </div>                         
                              
              
                                
                              </div>
                            </div>
                          </div>
                          <div style="margin-left: 25px !important;margin-right:25px !important; width:90%;">
                            <div class="row" >
                              <div class="col-3"  [hidden]="mainconc_flag">
                                <p class="textformat"><span style="font-weight: 550;">Complaints:</span> <span [hidden]="mainconc_flag" [innerHtml]="main_concerns"></span></p>
                              </div>
                              <div class="col-3" [hidden]="medhist_flag">
                                <p class="textformat"><span style="font-weight: 550;">Pats medical history:</span> <span [hidden]="medhist_flag" [innerHtml]="med_hist"></span></p>
                              </div>
                              <!-- <div class="col-3" [hidden]="medhist_flag">
                                <p class="textformat"><span style="font-weight: 550;">Complaints:</span> <span [hidden]="medhist_flag" [innerHtml]="complaints"></span></p>
                              </div> -->
                              <div class="col-3" [hidden]="bp_flag">
                                <p class="textformat"><span style="font-weight: 550;">Blood pressure:</span> <span [hidden]="bp_flag">Right arm: {{bp_right}}, Left arm: {{bp_left}}</span></p>
                              </div>
                              <div class="col-3" [hidden]="bp_flag">
                                <p class="textformat"><span style="font-weight: 550;">Medicines:</span> <span [hidden]="bp_flag">Surgery: {{surgery}} <br/> Procedure: {{surgery_proc}}</span></p>
                              </div>
                              </div>
                            </div>
                       
  
                          <div style="width:90%;margin-left: 25px !important;margin-right:25px !important;" [hidden]="med_flag">
                          <div class="col-12">
                              <table *ngIf="druglist.length != 0"
                                  style=" border: 1px solid black;border-collapse: collapse;">
  
                                  <tr style="text-align: center;">
                                      <th>Medicine</th>
                                      <th>Dosage</th>
                                      <th>Days</th>
                                  </tr>
                                  <tr *ngFor="let medicine of druglist">
                                      <td
                                          style="border: 1px solid black;border-collapse: collapse;width: 310px;height: 30px;">
                                          {{medicine.medicine_name}}
                                      </td>
                                      <td
                                          style="text-align: left;border: 1px solid black;border-collapse: collapse;width: 100px;padding: 15px !important;height: 30px;">
                                          {{medicine.medicine_dosage}}
                                      </td>
                                      <td
                                          style="text-align: center;border-collapse: collapse;width: 100px;border: 1px solid black;height: 30px;width: 310px;">
                                          {{medicine.medicine_intakedays+" -
                                          "+medicine.medicine_food}}
                                      </td>
  
                                  </tr>
                              </table>
                          </div>
                      </div>
                          <br />
                        
                        </div>
                      </td>
                    
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td width="100%">
                        <table style="border:'0';width:100%">
                          <tr>
                            <td style="width:100%;height: 70px;">&nbsp;
                            </td>
                          </tr>
                        </table>
                  </tfoot>
                </table>
              </div>
            </td>
          </tr>
        </tbody>
  
      </table>
  
      <footer style="width:100%;">
        <table style="border:'0';width:100%; margin-bottom: 0;">
          <tr>
            <td style="width:100%;height: 100px;">&nbsp;
              <!-- <img src={{hospitalFooter}} width="100%" height="200px"> -->
            </td>
          </tr>
        </table>
      </footer>
    </div>
  </div>
  <div *ngIf="spl_name == 'General' || spl_name == 'General surgery'" class="col-12 col-md-12 col-lg-12 col-xl-10">
    <div #casesheetsummary id="casesheetsummary">
      <table style="border:'0';margin-left: 20px !important;margin-right: 20px !important; width:95%">
        <thead>
          <tr>
            <th style="width:100%;">
              <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                <div style="width: 90%; height: 278px;">
                  <!-- <img alt="image" src={{hospitalLogo}} width="100%" height="170px"> -->
                  <div style="height: 170px;"></div>
                  <table
                    style="width: 100%; font-size: 11px; border: 1px solid black; margin-top: 5px; border-radius: 10px;   margin-bottom: 5px;">
                    <tbody>
                      <tr>
                        <td style="padding-left: 5px;"><strong>MR No</strong></td>
                        <td>: {{patient_id}}</td>
                        <td style="padding-left: 5px;"><strong>Date:</strong></td>
                        <td> : {{curr_date}}</td>
                      </tr>
                      <tr>
                        <td style="padding-left: 5px;"><strong>Patient Name</strong></td>
                        <td>: {{patient_name}}</td>
                        <td style="padding-left: 5px;"><strong>Doctor</strong></td>
                        <td> : {{patient_name}}</td>
                      </tr>
                      <tr>
                        <td style="padding-left: 5px;"><strong>Age/Gender</strong></td>
                        <td>: {{patient_age}}/ {{patient_gender}}</td>
                        <td style="padding-left: 5px;"><strong>Occupation</strong></td>
                        <td>: {{current_occup}}</td>
  
  
                      </tr>
                  
                    </tbody>
                  </table>
                </div>
              </div>
            </th>
          </tr>
        </thead>
  
        <tbody>
          <tr>
            <td width="100%">
              <div style="width: 100%;height: auto;display: contents; display: flex; justify-content: center;">
                <table class=paging style="width:100%;">
                  <tbody>
                    <tr>
                      <td>
                        <div class="main"
                          style="display: flex; flex-direction: column; justify-content: center; align-items: center; margin-top: 10px; ">
                          <div style="margin-left: 25px !important;margin-right:25px !important; width:90%;">
                            <div>
                              <p><b>General examination :</b></p>
                            </div>
                            <div class="content_cover">
                              <div class="row" >
                                <div class="col-3">
                                  <p class="textformat"><span style="font-weight: 550;">Height:</span> {{height}}</p>
                                </div>
                              </div>
                              <div class="row" >
                                <div class="col-3">
                                  <p class="textformat"><span style="font-weight: 550;">Weight:</span> {{weight}}</p>
                                </div>
                                </div>
                                <div class="row" >
                                <div class="col-3">
                                  <p class="textformat"><span style="font-weight: 550;">BMI:</span> {{bmi}}</p>
                                </div>
                              </div>
                              <div class="row" >
                                <div class="col-3">
                                  <p class="textformat"><span style="font-weight: 550;">Body
                                    type:</span> {{body_type}}</p>
                                </div>
                              </div>
                              <div class="row" >
                                <div class="col-3">
                                  <p class="textformat"><span style="font-weight: 550;">Body nature:</span> {{body_nature}}</p>
                                </div>                         
                              
              
                                
                              </div>
                            </div>
                          </div>
                          <div style="margin-left: 25px !important;margin-right:25px !important; width:90%;">
                            <div class="row" >
                              <div class="col-3"  [hidden]="mainconc_flag">
                                <p class="textformat"><span style="font-weight: 550;">Complaints:</span> <span [hidden]="mainconc_flag" [innerHtml]="main_concerns"></span></p>
                              </div>
                              <div class="col-3" [hidden]="medhist_flag">
                                <p class="textformat"><span style="font-weight: 550;">Pats medical history:</span> <span [hidden]="medhist_flag" [innerHtml]="med_hist"></span></p>
                              </div>
                              <!-- <div class="col-3" [hidden]="medhist_flag">
                                <p class="textformat"><span style="font-weight: 550;">Complaints:</span> <span [hidden]="medhist_flag" [innerHtml]="complaints"></span></p>
                              </div> -->
                              <div class="col-3" [hidden]="bp_flag">
                                <p class="textformat"><span style="font-weight: 550;">Blood pressure:</span> <span [hidden]="bp_flag">Right arm: {{bp_right}}, Left arm: {{bp_left}}</span></p>
                              </div>
                              <div class="col-3" [hidden]="bp_flag">
                                <p class="textformat"><span style="font-weight: 550;">Medicines:</span> <span [hidden]="bp_flag">Surgery: {{surgery}} <br/> Procedure: {{surgery_proc}}</span></p>
                              </div>
                              </div>
                            </div>
                       
  
                          <div style="width:90%;margin-left: 25px !important;margin-right:25px !important;" [hidden]="med_flag">
                          <div class="col-12">
                              <table *ngIf="druglist.length != 0"
                                  style=" border: 1px solid black;border-collapse: collapse;">
  
                                  <tr style="text-align: center;">
                                      <th>Medicine</th>
                                      <th>Dosage</th>
                                      <th>Days</th>
                                  </tr>
                                  <tr *ngFor="let medicine of druglist">
                                      <td
                                          style="border: 1px solid black;border-collapse: collapse;width: 310px;height: 30px;">
                                          {{medicine.medicine_name}}
                                      </td>
                                      <td
                                          style="text-align: left;border: 1px solid black;border-collapse: collapse;width: 100px;padding: 15px !important;height: 30px;">
                                          {{medicine.medicine_dosage}}
                                      </td>
                                      <td
                                          style="text-align: center;border-collapse: collapse;width: 100px;border: 1px solid black;height: 30px;width: 310px;">
                                          {{medicine.medicine_intakedays+" -
                                          "+medicine.medicine_food}}
                                      </td>
  
                                  </tr>
                              </table>
                          </div>
                      </div>
                          <br />
                        
                        </div>
                      </td>
                    
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td width="100%">
                        <table style="border:'0';width:100%">
                          <tr>
                            <td style="width:100%;height: 70px;">&nbsp;
                            </td>
                          </tr>
                        </table>
                  </tfoot>
                </table>
              </div>
            </td>
          </tr>
        </tbody>
  
      </table>
  
      <footer style="width:100%;">
        <table style="border:'0';width:100%; margin-bottom: 0;">
          <tr>
            <td style="width:100%;height: 100px;">&nbsp;
              <!-- <img src={{hospitalFooter}} width="100%" height="200px"> -->
            </td>
          </tr>
        </table>
      </footer>
    </div>
  </div>

  <div *ngIf="others" class="col-12 col-md-12 col-lg-12 col-xl-10">
    <div #casesheetsummary id="casesheetsummary">
      <table style="border:'0';margin-left: 20px !important;margin-right: 20px !important; width:95%">
        <thead>
          <tr>
            <th style="width:100%;">
              <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                <div style="width: 90%; height: 278px;">
                  <!-- <img alt="image" src={{hospitalLogo}} width="100%" height="170px"> -->
                  <div style="height: 170px;"></div>
                  <table
                    style="width: 100%; font-size: 11px; border: 1px solid black; margin-top: 5px; border-radius: 10px;   margin-bottom: 5px;">
                    <tbody>
                      <tr>
                        <td style="padding-left: 5px;"><strong>MR No</strong></td>
                        <td>: {{patient_id}}</td>
                        <td style="padding-left: 5px;"><strong>Date:</strong></td>
                        <td> : {{curr_date}}</td>
                      </tr>
                      <tr>
                        <td style="padding-left: 5px;"><strong>Patient Name</strong></td>
                        <td>: {{patient_name}}</td>
                        <td style="padding-left: 5px;"><strong>Doctor</strong></td>
                        <td> : {{patient_name}}</td>
                      </tr>
                      <tr>
                        <td style="padding-left: 5px;"><strong>Age/Gender</strong></td>
                        <td>: {{patient_age}}/ {{patient_gender}}</td>
                        <td style="padding-left: 5px;"><strong>Occupation</strong></td>
                        <td>: {{current_occup}}</td>
  
  
                      </tr>
                  
                    </tbody>
                  </table>
                </div>
              </div>
            </th>
          </tr>
        </thead>
  
        <tbody>
          <tr>
            <td width="100%">
              <div style="width: 100%;height: auto;display: contents; display: flex; justify-content: center;">
                <table class=paging style="width:100%;">
                  <tbody>
                    <tr>
                      <td>
                        <div class="main"
                          style="display: flex; flex-direction: column; justify-content: center; align-items: center; margin-top: 10px; ">
                          <div style="margin-left: 25px !important;margin-right:25px !important; width:90%;">
                            <div>
                              <p><b>General examination :</b></p>
                            </div>
                            <div class="content_cover">
                              <div class="row" >
                                <div class="col-3">
                                  <p class="textformat"><span style="font-weight: 550;">Height:</span> {{height}}</p>
                                </div>
                              </div>
                              <div class="row" >
                                <div class="col-3">
                                  <p class="textformat"><span style="font-weight: 550;">Weight:</span> {{weight}}</p>
                                </div>
                                </div>
                                <div class="row" >
                                <div class="col-3">
                                  <p class="textformat"><span style="font-weight: 550;">BMI:</span> {{bmi}}</p>
                                </div>
                              </div>
                              <div class="row" >
                                <div class="col-3">
                                  <p class="textformat"><span style="font-weight: 550;">Body
                                    type:</span> {{body_type}}</p>
                                </div>
                              </div>
                              <div class="row" >
                                <div class="col-3">
                                  <p class="textformat"><span style="font-weight: 550;">Body nature:</span> {{body_nature}}</p>
                                </div>                         
                              
              
                                
                              </div>
                            </div>
                          </div>
                          <div style="margin-left: 25px !important;margin-right:25px !important; width:90%;">
                            <div class="row" >
                              <div class="col-3"  [hidden]="mainconc_flag">
                                <p class="textformat"><span style="font-weight: 550;">Complaints:</span> <span [hidden]="mainconc_flag" [innerHtml]="main_concerns"></span></p>
                              </div>
                              <div class="col-3" [hidden]="medhist_flag">
                                <p class="textformat"><span style="font-weight: 550;">Pats medical history:</span> <span [hidden]="medhist_flag" [innerHtml]="med_hist"></span></p>
                              </div>
                              <!-- <div class="col-3" [hidden]="medhist_flag">
                                <p class="textformat"><span style="font-weight: 550;">Complaints:</span> <span [hidden]="medhist_flag" [innerHtml]="complaints"></span></p>
                              </div> -->
                              <div class="col-3" [hidden]="bp_flag">
                                <p class="textformat"><span style="font-weight: 550;">Blood pressure:</span> <span [hidden]="bp_flag">Right arm: {{bp_right}}, Left arm: {{bp_left}}</span></p>
                              </div>
                              <div class="col-3" [hidden]="bp_flag">
                                <p class="textformat"><span style="font-weight: 550;">Medicines:</span> <span [hidden]="bp_flag">Surgery: {{surgery}} <br/> Procedure: {{surgery_proc}}</span></p>
                              </div>
                              </div>
                            </div>
                       
  
                          <div style="width:90%;margin-left: 25px !important;margin-right:25px !important;" [hidden]="med_flag">
                          <div class="col-12">
                              <table *ngIf="druglist.length != 0"
                                  style=" border: 1px solid black;border-collapse: collapse;">
  
                                  <tr style="text-align: center;">
                                      <th>Medicine</th>
                                      <th>Dosage</th>
                                      <th>Days</th>
                                  </tr>
                                  <tr *ngFor="let medicine of druglist">
                                      <td
                                          style="border: 1px solid black;border-collapse: collapse;width: 310px;height: 30px;">
                                          {{medicine.medicine_name}}
                                      </td>
                                      <td
                                          style="text-align: left;border: 1px solid black;border-collapse: collapse;width: 100px;padding: 15px !important;height: 30px;">
                                          {{medicine.medicine_dosage}}
                                      </td>
                                      <td
                                          style="text-align: center;border-collapse: collapse;width: 100px;border: 1px solid black;height: 30px;width: 310px;">
                                          {{medicine.medicine_intakedays+" -
                                          "+medicine.medicine_food}}
                                      </td>
  
                                  </tr>
                              </table>
                          </div>
                      </div>
                          <br />
                        
                        </div>
                      </td>
                    
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td width="100%">
                        <table style="border:'0';width:100%">
                          <tr>
                            <td style="width:100%;height: 70px;">&nbsp;
                            </td>
                          </tr>
                        </table>
                  </tfoot>
                </table>
              </div>
            </td>
          </tr>
        </tbody>
  
      </table>
  
      <footer style="width:100%;">
        <table style="border:'0';width:100%; margin-bottom: 0;">
          <tr>
            <td style="width:100%;height: 100px;">&nbsp;
              <!-- <img src={{hospitalFooter}} width="100%" height="200px"> -->
            </td>
          </tr>
        </table>
      </footer>
    </div>
  </div>
  <div *ngIf="spl_name == 'Gynecology'" class="col-12 col-md-12 col-lg-12 col-xl-10">
    <div #casesheetsummary id="casesheetsummary">
      <table style="border:'0';margin-left: 20px !important;margin-right: 20px !important; width:95%">
        <thead>
          <tr>
            <th style="width:100%;">
              <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                <div style="width: 90%; height: 278px;">
                  <!-- <img alt="image" src={{hospitalLogo}} width="100%" height="170px"> -->
                  <div style="height: 170px;"></div>
                  <table
                    style="width: 100%; font-size: 11px; border: 1px solid black; margin-top: 5px; border-radius: 10px;   margin-bottom: 5px;">
                    <tbody>
                      <tr>
                        <td style="padding-left: 5px;"><strong>Patient Idetifier</strong></td>
                        <td>: {{patient_id}}</td>
                        <td style="padding-left: 5px;"><strong>Date:</strong></td>
                        <td> : {{curr_date}}</td>
                      </tr>
                      <tr>
                        <td style="padding-left: 5px;"><strong>Patient Name</strong></td>
                        <td>: {{patient_name}}</td>
                        <td style="padding-left: 5px;"><strong>Doctor</strong></td>
                        <td> : {{patient_name}}</td>
                      </tr>
                      <tr>
                        <td style="padding-left: 5px;"><strong>Age/Gender</strong></td>
                        <td>: {{patient_age}}/ {{patient_gender}}</td>
                        <td style="padding-left: 5px;"><strong>Occupation</strong></td>
                        <td>: {{current_occup}}</td>
  
  
                      </tr>
                  
                    </tbody>
                  </table>
                </div>
              </div>
            </th>
          </tr>
        </thead>
  
        <tbody>
          <tr>
            <td width="100%">
              <div style="width: 100%;height: auto;display: contents; display: flex; justify-content: center;">
                <table class=paging style="width:100%;">
                  <tbody>
                    <tr>
                      <td>
                        <div class="main"
                          style="display: flex; flex-direction: column; justify-content: center; align-items: center; margin-top: 10px; ">
                          <div style="margin-left: 25px !important;margin-right:25px !important; width:90%;">
                            <div>
                              <p><b>General examination :</b></p>
                            </div>
                            <div class="content_cover">
                              <div class="row" >
                                <div class="col-3">
                                  <p class="textformat"><span style="font-weight: 550;">Height:</span> {{height}}</p>
                                </div>
                                <div class="col-3">
                                  <p class="textformat"><span style="font-weight: 550;">Weight:</span> {{weight}}</p>
                                </div>
                                <div class="col-3">
                                  <p class="textformat"><span style="font-weight: 550;">BMI:</span> {{bmi}}</p>
                                </div>
                                <div class="col-3">
                                  <p class="textformat"><span style="font-weight: 550;">Body
                                    type:</span> {{body_type}}</p>
                                </div>
                                <div class="col-3">
                                  <p class="textformat"><span style="font-weight: 550;">Body nature:</span> {{body_nature}}</p>
                                </div>                         
                              
              
                                
                              </div>
                            </div>
                          </div>
                          <div style="margin-left: 25px !important;margin-right:25px !important; width:90%;">
                            <div class="row" >
                              <div class="col-3" [hidden]="mainconc_flag">
                                <p class="textformat"><span style="font-weight: 550;">Main concerns:</span> <span [hidden]="mainconc_flag" [innerHtml]="main_concerns"></span></p>
                              </div>
                              </div>
                              <div class="row" [hidden]="medhist_flag">
                              <div class="col-3">
                                <p class="textformat"><span style="font-weight: 550;">Past medical history:</span> <span [hidden]="medhist_flag" [innerHtml]="med_hist"></span></p>
                              </div>
                              </div>
                              <div class="row" [hidden]="bp_flag">
                              <div class="col-3">
                                <p class="textformat"><span style="font-weight: 550;">Blood pressure:</span> <span [hidden]="bp_flag">Right arm: {{bp_right}}, Left arm: {{bp_left}}</span></p>
                              </div>
                              </div>
                              <div class="row" [hidden]="bp_flag">
                              <div class="col-3">
                                <p class="textformat"><span style="font-weight: 550;">Medicines:</span> <span [hidden]="bp_flag">Surgery: {{surgery}} <br/> Procedure: {{surgery_proc}}</span></p>
                              </div>
                              </div>
                            </div>
                       
  
                          <div style="width:90%;margin-left: 25px !important;margin-right:25px !important;" [hidden]="med_flag">
                          <div class="col-12">
                              <table *ngIf="druglist.length != 0"
                                  style=" border: 1px solid black;border-collapse: collapse;">
  
                                  <tr style="text-align: center;">
                                      <th>Medicine</th>
                                      <th>Dosage</th>
                                      <th>Days</th>
                                  </tr>
                                  <tr *ngFor="let medicine of druglist">
                                      <td
                                          style="border: 1px solid black;border-collapse: collapse;width: 310px;height: 30px;">
                                          {{medicine.medicine_name}}
                                      </td>
                                      <td
                                          style="text-align: left;border: 1px solid black;border-collapse: collapse;width: 100px;padding: 15px !important;height: 30px;">
                                          {{medicine.medicine_dosage}}
                                      </td>
                                      <td
                                          style="text-align: center;border-collapse: collapse;width: 100px;border: 1px solid black;height: 30px;width: 310px;">
                                          {{medicine.medicine_intakedays+" -
                                          "+medicine.medicine_food}}
                                      </td>
  
                                  </tr>
                              </table>
                          </div>
                      </div>
                          <br />
                        
                        </div>
                      </td>
                    
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td width="100%">
                        <table style="border:'0';width:100%">
                          <tr>
                            <td style="width:100%;height: 70px;">&nbsp;
                            </td>
                          </tr>
                        </table>
                  </tfoot>
                </table>
              </div>
            </td>
          </tr>
        </tbody>
  
      </table>
  
      <footer style="width:100%;">
        <table style="border:'0';width:100%; margin-bottom: 0;">
          <tr>
            <td style="width:100%;height: 100px;">&nbsp;
              <!-- <img src={{hospitalFooter}} width="100%" height="200px"> -->
            </td>
          </tr>
        </table>
      </footer>
    </div>
  </div>



</div>