<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
      <mdb-card>
        <mdb-card-header class="bg-white">
          <div class="headerCover">
            <div class="headerTilte app_list_header">
              <h5 class="m-0" class="mainHeadingStyle">Log Report</h5>
            </div>
            <div lass="col-12 col-sm-6 col-md-4 col-xl-3 col-lg-3">
              <mat-label class="matlabel">Preferrence</mat-label>
              <mat-select class="ipcss" multiple [(ngModel)]="selected_filter"
                (selectionChange)="setTable(selected_filter);">
                <mat-option  value="sno">Sno</mat-option>
                <mat-option  value="Bill_no">Bill no</mat-option>
                <mat-option value="Bill_date">Bill date</mat-option>
                <mat-option  value='Name'>Name</mat-option>
                <mat-option  value='bill_amount'>Bill amount</mat-option>
                <mat-option value="Modified_amount">Modified amount</mat-option>
                <mat-option value="Created_by">Created by</mat-option>
                <mat-option value="Created_date">Created date</mat-option>
                <mat-option value="Modified_by">Modified by</mat-option> 
                <mat-option value="Modified_date">Modified date</mat-option>
              </mat-select>
            </div>
            <div class="headerButtons">
             
            </div>
          </div>
          
        </mdb-card-header>
        <div class="d-flex justify-content-end" style="width:95%;" >
            
          <img src="../../../assets/ui_icons/buttons/back_icon.svg" style="width:30px;margin-right: 5px;" width="85px" (click)="todisplaycoverdiv()" *ngIf="backbutton">
          <a style="margin:0 5px;"><img src="../../../assets/img/printer.svg"
             class="backimgbtn_inpatinfo" style="width: 25px; height: 25px;"  (click)="print_area()" *ngIf="backbutton"/></a>
             <img src="../../../assets/ui_icons/buttons/XL_export.svg" class="saveimgbtn_inpatinfo" (click)="getExcelData('excel')" *ngIf="backbutton"/>
          </div>
        <mdb-card-body>
          <div class="cover_div" style="padding-bottom: 13px;" *ngIf="forcoverdiv">
            <div class="header_lable">
              Log report
            </div>
            <div class="content_cover">
              <div class="row">
                
                <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 select_bottom">
                   <mat-label class="matlabel">Duration<br>
                     <select  class="ipcss" (change)="updateFromDate()" [(ngModel)]="selectedDuration">
                       <option value="select">Select</option>
                       <option value="0">Today</option>
                       <option value="7">1 week</option>
                       <option value="30">1 month</option>
                       <option value="91">3 months</option>
                       <option value="183">6 months</option>
                       <option value="365">1 year</option>
                     </select>
                   </mat-label>
                 </div>
 
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                  <mat-label class="matlabel" style="margin-top: 7px;">From
                    <input type="date" class="ipcss_date " max="{{currentDate}}" [(ngModel)]="fromDate" #matInput style="width: 140px;">
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                  <mat-label class="matlabel" style="margin-top: 7px;">To
                    <input type="date" class="ipcss_date " max="{{currentDate}}"  [(ngModel)]="toDate" #matInput style="width: 140px;">
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                  <mat-label class="matlabel"><br>
                    <img src="../../../assets/ui_icons/buttons/search_button.svg" class="saveimgbtn_inpatinfo" (click)="get_log_data()"/>
                  </mat-label>
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                  <mat-label class="matlabel"><br>
                  
                  </mat-label>
                </div>
               
              </div>
            </div>
          </div>
          <p class="nodata" *ngIf="logdata.length == 0">No records found</p>
          
          <div [hidden]="true">
            <table style="margin-left:10px;margin-right: 10px;" #printimage id="printimage">
              <tr>
                <td>
              
                  <img alt="image" src={{hospital_logo}} style="width: 95%; height: 130px;">
                </td>
              </tr>
            </table>
            <div style="position: relative;top: 20px;left: 10px;text-align: left;" #hospitalwithnamelogo
              id="hospitalwithnamelogo">
              <span><b>{{hosp_name}}</b>,</span><br />
              <span *ngIf="hosp_addr != ''">{{hosp_addr}}</span><br />
              <span>{{hosp_csz}}</span><br />
              <span *ngIf="hosp_tel != undefined"><img src="../../../assets/img/phone.png" width="15px"
                  height="15px" />{{hosp_tel}}.</span><br>
            </div>
          </div>
          
          <div  class="table-responsive cover_div" *ngIf="logdata.length != 0"  [ngStyle]="{'height': ishide ? '75vh' : '60vh'}"
          
          style="margin-top: 10px;width: 100% !important; padding: 0px !important;" [hidden]="hasData">
          <table class="headerfixed" #printlogrep id="printlogrep" style="font-size: 12px; width: 100%;">
            <thead>
              <tr>
                <th *ngIf="columns.sno"style="height: auto;width:5%">S no</th>
                <th *ngIf="columns.Bill_no"style="height: auto;width:7%">Bill no</th>

                <th *ngIf="columns.Name"style="height: auto;width:10%">Name</th>
                <th *ngIf="columns.bill_amount"style="height: auto;width:9%">Bill amt</th>
                <th *ngIf="columns.Modified_amount"style="height: auto;width:9%">Modified amt</th>
                <th *ngIf="columns.Created_by"style="height: auto;width:12%;text-align: center;">Created by</th>
                <th *ngIf="columns.Bill_date"style="height: auto;width:10%">Created time</th>
                <th *ngIf="columns.Modified_by"style="height: auto;width:12%;text-align: center;">Modified by</th>
                <th *ngIf="columns.Modified_date"style="height: auto;width:10%">Modified date</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let sales of logdata">
                <td *ngIf="columns.sno"style="height: auto;">{{sales.sno}}</td>
                <td *ngIf="columns.Bill_no"style="height: auto;" class="align_left">{{sales.billing_id}}</td>
                <td *ngIf="columns.Name"style="height: auto;" >{{sales.patient_name}}</td>
                <td  *ngIf="columns.bill_amount"style="height: auto;font-size: 11px;text-align: right;" >{{sales.prev_bill_amount}}</td>
                <td  *ngIf="columns.Modified_amount"style="height: auto;text-align: right;">{{sales.upd_bill_amount}}</td>
                <td  *ngIf="columns.Created_by"style="height: auto;text-align: center;">{{sales.created_by}}</td>
                <td  *ngIf="columns.Bill_date"style="height: auto;font-size:10px">{{sales.created_date}} {{sales.created_time}}</td>
                <td  *ngIf="columns.Modified_by"style="height: auto;text-align: center;">{{sales.updated_by}}</td>
                <td  *ngIf="columns.Modified_date"style="height: auto;font-size:10px" >{{sales.updated_date}} {{sales.updated_time}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        </mdb-card-body>
      </mdb-card>
    </div>
  </div>
 
