import { Component, OnInit,ChangeDetectorRef,ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ipaddress } from 'src/app/ipaddress';
import { Http, Headers } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Date_Formate, Time_Formate, Time_Formate_Session } from '../../../assets/js/common';
import { Helper_Class } from '../../helper_class';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { error } from 'console';
import { MenuViewService } from '../../../../src/app/common-module/menu-view/menu-view.service';
import { Message_data } from 'src/assets/js/Message_data';
import { CommonDataService } from '../../providers/common-data.service';
import { ToastrService } from 'ngx-toastr';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

@Component({
  selector: 'app-pastinwards',
  templateUrl: './pastinwards.component.html',
  styleUrls: ['./pastinwards.component.scss']
})
export class PastinwardsComponent implements OnInit {
  @ViewChild('printinward') printinward: ElementRef;

  public pharmacistID: string;
  public reportType: string;
  public fromDate;
  public toDate;
  public currDate = null;
  public salesArray = [];
  public noData: boolean;
  public hasData: boolean;
  tmr = new Date();
  public userInfo;
  public currentDate;
  public payType: string;
  public totalAmount = "0.00";
  public title = 'Sales report';
  public header = [];
  public payTypeListArrray: any = [];
  public pagetrue;
  public backbutton;
  public pharmacistformadmin;
 
  //inwards report
  public pastinwardsarray:any=[];
  public filtduration;
  public pharmacyID;
  public forcoverdiv:boolean;

  constructor(public gservice: CommonDataService, public http: Http, public routes: ActivatedRoute, private activatedRoute: ActivatedRoute, public router: Router, public toastr: ToastrService) {
    this.noData = false;
    this.hasData = true;
  }

  ngOnInit(): void {
    
    this.userInfo = Helper_Class.getInfo();
    this.pagetrue = Helper_Class.getbillpagetrue();
    if (this.pagetrue == 'pharmacybill') {
      this.backbutton = true;
      this.pharmacistformadmin = Helper_Class.gethspidfrmadm();
      this.pharmacistID = this.pharmacistformadmin.pharma_id

    }
    this.forcoverdiv=true
    this.backbutton=false
    this.filtduration = "0";
    if(Helper_Class.getInfo().user_type == 'Admin'){
      this.pharmacyID = Helper_Class.getInfo().pharma_id;
    }else{
      this.pharmacyID = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
    }
 
    console.log("------------------------>", this.pharmacistID)
    this.getDate();
    this.getPaymentType();
    this.reportType = "OTC";
    this.header = ['Bill no', 'Name', 'Refund date', 'Refund time', 'Payment type', 'Amount'];
  }

  backpage() {
   this.forcoverdiv=true;
   this.pastinwardsarray=[];
   this.backbutton=false;
  }

  getDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate', { country: ipaddress.country_code },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.tmr = new Date(obj.current_date);
          this.tmr.setDate(this.tmr.getDate());
          this.currentDate = this.getDateMatePicker1(this.tmr);
          this.fromDate = obj.current_date;
          this.toDate = obj.current_date;
          this.getinwardData('');
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  getDateMatePicker1(e) {
    var month = ((e.getMonth() + 1) < 10 ? '0' : '') + (e.getMonth() + 1);
    var day = (e.getDate() < 10 ? '0' : '') + e.getDate();
    var apt = e.getFullYear() + "-" + month + "-" + day;
    return apt.trim();
  }

  dateSelect(data, value) {
    if (data != null) {
      if (value == "1")
        this.fromDate = data;
      else
        this.toDate = data;
    }
  }

  getinwardData(data) {
    var reptype;
    var send_data;
    reptype = data == "otc" ? this.reportType : "excel";
    //if (Helper_Class.getIsAdmin() != undefined) {
      send_data = {
        pharma_id: this.pharmacyID,
        duration: this.filtduration,
        // from_date: this.fromDate,
        // to_date: this.toDate,
      };
    // } else {
    //   send_data = {
    //     pharmacist_id: this.pharmacistID,
    //     type: reptype,
    //     from_date: this.fromDate,
    //     to_date: this.toDate,
    //     paytype: this.payType,
    //   };
    // }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "misr/gprr", send_data,
      { headers: headers }).subscribe(
        data => {
          var dataval = data.json();
          console.log("inwardsdata"+JSON.stringify(dataval))
          if (dataval != null) {
            // this.noData = true;
            // this.hasData = false;
            this.pastinwardsarray = [];
            if (dataval.refunds != undefined) {
              this.forcoverdiv=false;
              this.backbutton=true;
              for (var i = 0; i < dataval.refunds.length; i++) {
                var name;
                if (dataval.refunds[i].middle_name != undefined) {
                  name = encrypt_decript.Decript(dataval.refunds[i].first_name) + " " + encrypt_decript.Decript(dataval.refunds[i].middle_name) + " " + encrypt_decript.Decript(dataval.refunds[i].last_name);
                } else {
                  name = encrypt_decript.Decript(dataval.refunds[i].first_name) + " " + encrypt_decript.Decript(dataval.refunds[i].last_name);
                }
                var orddate = dataval.refunds[i].refund_date.split("-");

                // var amount = dataval.orders[i].amount != undefined ? (parseFloat(dataval.orders[i].amount)).toFixed(2) : "0.00";
                // var balance = dataval.orders[i].balance != undefined ? (parseFloat(dataval.orders[i].balance)).toFixed(2) : "0.00";
                // if (dataval.orders[i].paid_flag != undefined && dataval.orders[i].paid_flag != null && dataval.orders[i].paid_flag != "") {
                //   var payFlag = dataval.orders[i].paid_flag.replace("_", " ");
                //   payFlag = payFlag.charAt(0).toUpperCase() + payFlag.substr(1).toLowerCase();
                // }
                this.pastinwardsarray.push({
                  order_id: dataval.refunds[i].order_id,
                  name: name,
                  order_date: orddate[2] + "-" + orddate[1] + "-" + orddate[0],
                  refund_time:Time_Formate(dataval.refunds[i].refund_time), 
                  payment_desc:dataval.refunds[i].payment_desc,
                  amount: dataval.refunds[i].amount,
                 
                })
              }
             
            } else {
              this.pastinwardsarray = [];
              this.noData = false;
              this.hasData = true;
            }
          }
        }, error => { });
  }

  getExcelData(type) {
    if (this.pastinwardsarray.length != 0) {
      let workbook = new Workbook();
      let worksheet = workbook.addWorksheet('Inward report');
      worksheet.getColumn(1).width = 10;
      worksheet.getColumn(2).width = 30;
      worksheet.getColumn(3).width = 20;
      worksheet.getColumn(4).width = 15;
      worksheet.getColumn(5).width = 20;
      worksheet.getColumn(5).width = 20;
      let headerRow = worksheet.addRow(this.header);
      headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '4167B8' },
          bgColor: { argb: '' }
        }
        cell.font = {
          bold: true,
          color: { argb: 'FFFFFF' },
          size: 12
        }
      })
      for (let x1 of this.pastinwardsarray) {
        let x2 = Object.keys(x1);
        let temp = []
        for (let y of x2) {
          temp.push(x1[y])
        }
        let chilrow = worksheet.addRow(temp)
        let salesamt = chilrow.getCell(5);
        salesamt.alignment = {
          vertical: 'middle',
          horizontal: 'right'
        }
      }
      // var finaldata = ['', '', '', '', 'Total', this.totalAmount];
      
      let finalrow = worksheet.addRow("");
      let totalval = finalrow.getCell(4);
      totalval.font = {
        bold: true,
        color: { argb: '000000' },
        size: 12
      }
      let totalamt = finalrow.getCell(5);
      totalamt.alignment = {
        vertical: 'middle',
        horizontal: 'right'
      }
      totalamt.font = {
        bold: true,
        color: { argb: '000000' },
        size: 12
      }
      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, "Inward_report" + '-' + new Date().valueOf() + '.xlsx');
      });
    } else {
      this.toastr.error(Message_data.exportExcelFailed);
    }
  }

  getPaymentType() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/paytype',
      { headers: headers })
      .subscribe(
        response => {
          this.payTypeListArrray.push({
            pay_id: "All",
            pay_desc: "All",
          })
          var obj = response.json();
          for (var i = 0; i < obj.payments.length; i++) {
            this.payTypeListArrray.push({
              pay_id: obj.payments[i].pay_id,
              pay_desc: obj.payments[i].pay_desc,
            })
          }
          //this.payTypeListArrray = obj.payments;
          this.payType = this.payTypeListArrray[0].pay_id;
        },
        error => {
          this.toastr.error(Message_data.network);
        });
  }

  print_area(){
    let printContents, popupWin;
    printContents = this.printinward.nativeElement.innerHTML;
    popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
<head>
  <title>Reports</title>
  
<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
<style>
@page { size: landscape || potrait; } 
@media print and (orientation: landscape) {
    .table {
      width: 100%;
    }
  }

  /* Portrait Styles */
  @media print and (orientation: portrait) {
    .table {
      width: auto;
    }
  }
th{
    padding:0%;
  }
  .table{
    padding:5% !important;
    border: 1px solid #c7c7e9bf !important;
    border-radius: 10px !important;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px !important;
    font-family:Segoe UI;
    font-size:12px; 
  }
  body{
    padding-top:10%;
  }
  </style>
</head>
    <body onload="window.print();window.onfocus=function(){ window.close();}">

 
<div style="margin-top:20px">
<table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' >
${printContents}
</table>
</div>
  </body>
  </html>`
    );
    popupWin.document.close();

  }

}


// <div style="background-color:gray;color:black;width:100%;border: 1px solid #c7c7e9bf !important;    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px !important;">
// <h5>
// Pharma Sales Report
// </h5>
// </div>