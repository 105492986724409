import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { ipaddress } from '../../../ipaddress';
import { Helper_Class } from '../../../helper_class';
import { Message_data } from 'src/assets/js/Message_data';
import * as fs from 'file-saver';
import { Workbook } from 'exceljs';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-pharma-supplier-payment',
  templateUrl: './pharma-supplier-payment.component.html',
  styleUrls: ['./pharma-supplier-payment.component.scss']
})
export class PharmaSupplierPaymentComponent implements OnInit {
  public locationArray = [];
  public loadLocationList = [];
  public pharmacyFacID: any;
  public location: any;
  public locationIP = null;
  public invoicedetails: any = [];
  public pharmacistID;
  public userInfo;
  public pharmacyID;
  public suppId;
  public fromDate;
  public toDate;
  public currentDate;
  public currDate;
  public totalAmount;
  public pendingAmount;
  private tmr = new Date();
  public supplierList: any = [];
  dtOptions: DataTables.Settings = {};
  isDisabled: boolean = false;
  public cardFlag: boolean;
  public chequeFlag: boolean;
  public transactionFlag: boolean;
  public insurFlag: boolean;
  public showpayflag: boolean = true;
  public showimage: boolean = false;
  public showarray: boolean = true;
  public paid_amount: number = 0;
  public payTypeList: any = [];
  public insurerList: any = [];
  public payHistList: any = [];
  public payType;
  public insurerId;
  public cardHolderName;
  public transactionId;
  public cardNumber
  public bankName;
  private closeResult;
  public editbutton: boolean = false;
  public newbutton: boolean = false;
  public deletebutton: boolean = false;
  public printbutton: boolean = false;
  public viewbutton: boolean = false;
  public paytotal = 0;
  public paymentcheckbox;
  public accepted: Boolean;
  public checkboxflag: Boolean;
  private selectedInvoice: any = [];

  constructor(public http: HttpClient, public routes: ActivatedRoute,
    public router: Router, public toastr: ToastrService, private modalService: NgbModal) {

  }

  ngOnInit(): void {
    this.checkboxflag = false
    this.userInfo = Helper_Class.getInfo();
    if (Helper_Class.getInfo().user_type == 'pharmacy') {
      this.pharmacyID = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.pharmacistID = this.userInfo.user_id;
     
    } else {
      this.pharmacyID = Helper_Class.getInfo().pharma_id;
      this.pharmacistID = Helper_Class.getInfo().po_approver;
    }
    //this.pharmaLocation();
    this.getSuppliers();

    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      info: false,
      "lengthMenu": [[25, 50, 100, -1], [25, 50, 100, "All"]],
      order: [[0, 'desc']],
      "columnDefs": [
        { "orderable": false, "targets": [0, 1, 2, 3] },
      ],

      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search invoice, date, or due date"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };

    if(this.userInfo.user_type =="Admin") {
      this.editbutton = true;
    } else {
      if (Helper_Class.getmodulelist() != undefined) {
        for (var i = 0; i < Helper_Class.getmodulelist().length; i++) {
          if (Helper_Class.getmodulelist()[i].module_id == "60" && Helper_Class.getmodulelist()[i].description == "Supplier payments") {
  
            if (Helper_Class.getmodulelist()[i].edit == "1") {
              this.editbutton = true;
            }
            if (Helper_Class.getmodulelist()[i].create == "1") {
              this.newbutton = true;
            }
            if (Helper_Class.getmodulelist()[i].delete == "1") {
              this.deletebutton = true;
            }
            if (Helper_Class.getmodulelist()[i].print == "1") {
              this.printbutton = true;
            }
            if (Helper_Class.getmodulelist()[i].view == "1") {
              this.viewbutton = true;
            }
          }
        }
      }
    }
  }

  // pharmaLocation() {
  //   var send_data;
  //   if (Helper_Class.getIsAdmin() != undefined) {
  //     send_data = {
  //       hptl_clinic_id: this.pharmacyID,
  //     };

  //   } else {
  //     send_data = {
  //       pharmacy_id: this.pharmacyID,
  //       pharmacist_id: this.pharmacistID
  //     };
  //   }
  //   var headers = new HttpHeaders();
  //   headers.append('Content-Type', 'application/json');
  //   this.http.post(ipaddress.getIp + "pharmacontrol/pharlocbyid/", send_data, { headers: headers }).subscribe(
  //     data => {
  //       var obj = JSON.parse(JSON.stringify(data));
  //       if (obj.pharma_locations != null) {
  //         this.locationArray = obj.pharma_locations;
  //         for (var i = 0; i < this.locationArray.length; i++) {
  //           this.loadLocationList.push({
  //             location_id: this.locationArray[i].pharma_facility_id + " - " + this.locationArray[i].location,
  //             pharma_facility_id: this.locationArray[i].pharma_facility_id,
  //           });
  //           if (i == 0) {
  //             //this.getInvoiceDetails(this.locationArray[i].pharma_facility_id);
  //           }
  //         }
  //         if (this.loadLocationList.length != 0) {
  //           this.location = this.loadLocationList[0].location_id;
  //         }
  //         this.getSuppliers();
  //       }
  //     },
  //     error => { });
  // }

  getSuppliers() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "inv/gsup/", {
      pharmacy_id: this.pharmacyID,
    }, { headers: headers }).subscribe(
      data => {
        this.supplierList = [];
        var suppliers = JSON.parse(JSON.stringify(data))
        this.supplierList.push({
          supp_id: 'All',
          name: 'All'
        })
        if (suppliers.supplier_details != null && suppliers.supplier_details.length != 0) {
          for (var i = 0; i < suppliers.supplier_details.length; i++) {
            this.supplierList.push({
              supp_id: suppliers.supplier_details[i].supp_id,
              name: suppliers.supplier_details[i].name,
            })
          }
          // this.supplierList = suppliers.supplier_details;
          this.suppId = this.supplierList[0].supp_id;
        }
        this.getDate();
      },
      error => {
        this.toastr.error(Message_data.unableToFetchsupp);
      }
    )
  }

  getDate() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate', { country: ipaddress.country_code },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.tmr = new Date(obj.current_date);
          this.tmr.setDate(this.tmr.getDate());
          this.currentDate = this.getDateMatePicker1(this.tmr);
          this.currDate = obj.current_date;

          this.fromDate = obj.current_date;
          this.toDate = obj.current_date;
          this.getInvoiceDetails();
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  getDateMatePicker1(e) {
    var month = ((e.getMonth() + 1) < 10 ? '0' : '') + (e.getMonth() + 1);
    var day = (e.getDate() < 10 ? '0' : '') + e.getDate();
    var apt = e.getFullYear() + "-" + month + "-" + day;
    return apt.trim();
  }

  dateSelect(data, value) {
    if (data != null) {
      if (value == "1")
        this.fromDate = data;
      else
        this.toDate = data;
    }
  }

  getInvoiceDetails() {
    var send_data = {
      pharma_id: this.pharmacyID,
      supp_id: this.suppId,
      from_date: this.fromDate,
      to_date: this.toDate,
    }
    console.log("send data" + JSON.stringify(send_data))
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'inv/gidps', {
      pharma_id: this.pharmacyID,
      supp_id: this.suppId,
      from_date: this.fromDate,
      to_date: this.toDate,
    },
      { headers: headers })
      .subscribe(
        response => {
          this.invoicedetails = [];
          
          var obj = JSON.parse(JSON.stringify(response));
          console.log("object = " + JSON.stringify(obj))
          if (obj != undefined && obj.invoice_details.length != 0) {
            this.showpayflag = true;
            this.totalAmount = (obj.inv_amount).toFixed(2);
            this.pendingAmount = (obj.balance).toFixed(2);
            for (var i = 0; i < obj.invoice_details.length; i++) {
              var balance;
              var paidflag;
              if (obj.invoice_details[i].payment != undefined) {
                balance = parseInt(obj.invoice_details[i].invoice_amount) - parseInt(obj.invoice_details[i].payment);
                paidflag = obj.invoice_details[i].paid_flag;
              } else {
                balance = parseInt(obj.invoice_details[i].invoice_amount);
                paidflag = "Unpaid";
              }

              var invdate = obj.invoice_details[i].invoice_date.split("-");
              var duedate = obj.invoice_details[i].inv_due_date.split("-");
              var dueflag;
              if ((obj.invoice_details[i].inv_due_date <= this.currDate) && balance != 0) {
                dueflag = 1;

              } else {
                dueflag = 0;
              }

              if (obj.invoice_details[i].invoice_date != undefined && this.currDate != undefined) {
                var currentDate = new Date(this.currDate);
                var invoiceDate = new Date(obj.invoice_details[i].invoice_date);
                // Calculate the difference in milliseconds
                var timeDifference = currentDate.getTime() - invoiceDate.getTime();
                // Convert the difference to days
                var daysDifference = timeDifference / (1000 * 60 * 60 * 24);
              }

              if (obj.invoice_details[i].inv_due_date !== undefined && this.currDate !== undefined) {
                var invDueDate_inv_due_date = new Date(obj.invoice_details[i].inv_due_date);
                var currentDate_inv_due_date = new Date(this.currDate);
                var timeDifference_inv_due_date = invDueDate_inv_due_date.getTime() - currentDate_inv_due_date.getTime();
                var daysDifference_inv_due_date = timeDifference_inv_due_date / (1000 * 60 * 60 * 24);
                obj.invoice_details[i].diff = daysDifference_inv_due_date;
              }

              var disableflag;
              if (balance != 0) {
                disableflag = false;

              } else {
                disableflag = true;
              }

              if (obj.pay_history != undefined) {
                this.payHistList = obj.pay_history;
                this.showimage = true;
              }

              obj.invoice_details[i].paymentcheckbox = false;
              this.invoicedetails.push({
                receivables_id: obj.invoice_details[i].receivables_id,
                supp_id: obj.invoice_details[i].supp_id,
                name: obj.invoice_details[i].name,
                invoice_no: obj.invoice_details[i].invoice_no,
                invoice_date: invdate[2] + "-" + invdate[1] + "-" + invdate[0],
                inv_due_date: duedate[2] + "-" + duedate[1] + "-" + duedate[0],
                invoice_amount: obj.invoice_details[i].invoice_amount,
                payment_date: obj.invoice_details[i].payment_date,
                payment: obj.invoice_details[i].payment,
                balance: balance,
                paid_flag: paidflag,
                dueflag: dueflag,
                paid_amount: "0",
                disableflag: disableflag,
                lag: daysDifference,
                invdiffdays: obj.invoice_details[i].diff,
                paymentcheckbox: obj.invoice_details[i].paymentcheckbox
              })
            }
          }
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  calculateDaysDifference(inv_due_date: Date): number {
    var currentDate = new Date(this.currDate);
    var timeDifference = inv_due_date.getTime() - currentDate.getTime();
    return Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  }

  showPaumentHistory(content: any) {
    this.showarray = !this.showarray;

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {

      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';

    } else {
      return `with: 140%`;
    }
  }

  calculatePaidAmount(invoice) {
    this.getPaymentType();
    this.getInsurers();

    for (var i = 0; i < this.invoicedetails.length; i++) {
      this.showpayflag = false;
      this.insurFlag = true;
      this.cardFlag = true;
      this.cardFlag = true;
      this.transactionFlag = true;
      this.chequeFlag = true;
      this.chequeFlag = true;

      if (this.invoicedetails[i].receivables_id == invoice.receivables_id) {
        if (this.checkboxflag == false) {
          if (invoice.paid_amount != 0) {
            invoice.paymentcheckbox = true;

          } else if (invoice.paid_amount == 0) {
            invoice.paymentcheckbox = false;
          }
        }

        if (invoice.paid_amount == "") {
          this.invoicedetails[i].paid_amount = "0";
        }

        this.paytotal += parseFloat(invoice.paid_amount);
        this.paytotal.toFixed(2);
        var bal;

        if (invoice.payment != undefined) {
          bal = parseInt(this.invoicedetails[i].invoice_amount) - (parseInt(invoice.payment) + parseInt(invoice.paid_amount));

        } else {
          bal = parseInt(this.invoicedetails[i].invoice_amount) - (parseInt(invoice.paid_amount));
        }

        if (invoice.paymentcheckbox == true) {
          this.paid_amount += parseInt(invoice.paid_amount);
          this.invoicedetails[i].balance = bal;

        } else if (invoice.paymentcheckbox == false && invoice.paid_amount != 0) {
          this.paid_amount -= parseInt(invoice.paid_amount);
          this.invoicedetails[i].balance = invoice.paid_amount;
          invoice.paid_amount = 0

        } else {
          this.paid_amount -= parseInt(invoice.paid_amount);
          this.invoicedetails[i].balance = invoice.paid_amount;
          invoice.paid_amount = 0
        }

        if (bal == 0) {
          this.invoicedetails[i].paid_flag = "Fully paid";
        } else if (bal == this.invoicedetails[i].invoice_amount) {
          this.invoicedetails[i].paid_flag = "Unpaid";
        } else {
          this.invoicedetails[i].paid_flag = "Partially paid";
        }
      }
    }
    this.checkboxflag = true;
  }

  getExcelData(data) {
    var excel_data = [];
    var header = ['Supplier', 'Invoice', 'Invoice date', 'Invoice amount', 'Paid amount', 'Balance', 'Status']
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Stock status report');
    worksheet.getColumn(1).width = 40;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 15;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 15;
    worksheet.getColumn(7).width = 15;
    let headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4167B8' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12
      }
    })
    for (let invoice of this.invoicedetails) {
      excel_data.push({
        name: invoice.name,
        invoice_no: invoice.invoice_no,
        invoice_date: invoice.invoice_date,
        invoice_amount: invoice.invoice_amount,
        paid_amount: invoice.paid_amount,
        balance: invoice.balance,
        paid_flag: invoice.paid_flag
      })
    }
    for (let x1 of excel_data) {
      let x2 = Object.keys(x1);
      let temp = []
      for (let y of x2) {
        temp.push(x1[y])
      }
      let chilrow = worksheet.addRow(temp)
      let salesamt = chilrow.getCell(7);
      salesamt.alignment = {
        vertical: 'middle',
        horizontal: 'right'
      }
    }
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, "supplier_payments" + '-' + new Date().valueOf() + '.xlsx');
    });
  }

  save_data() {
    var send_data = {
      supplier_id: this.suppId,
      pharma_id: this.pharmacyID,
      amount: this.paid_amount,
      pay_type: this.payType,
      insurer: this.insurerId,
      card_no: this.cardNumber,
      card_holder_name: this.cardHolderName,
      transaction_no: this.transactionId,
      bank_name: this.bankName,
      payments: this.selectedInvoice,
      country: "IN",
    }

    console.log("updated_data " + JSON.stringify(send_data))
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'inv/sspp', send_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          if (obj.key == "1") {
            this.paid_amount = 0.00;
            this.selectedInvoice=[]
            this.cardNumber = "";
            this.cardHolderName = "";
            this.transactionId = "";
            this.bankName = "";
            this.toastr.success("Payment details update successfully");
            this.accepted = false
          }
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  pay_change() {
    this.insurFlag = true;
    this.transactionFlag = true;
    this.cardFlag = true;
    this.chequeFlag = true;
    if (this.payType == "3")
      this.insurFlag = false;
    else if (this.payType == "4" || this.payType == "5" || this.payType == "6" || this.payType == "9")
      this.transactionFlag = false;
    else if (this.payType == "2") {
      this.transactionFlag = false;
      this.cardFlag = false;
    } else if (this.payType == "7")
      this.chequeFlag = false;
    else
      this.insurFlag = true;
  }

  getInsurers() {//Get payment type
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/gins',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.insurerList = obj.insurers;
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  getPaymentType() {//Get payment type
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/paytype',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.payTypeList = obj.payments;
          this.payType = this.payTypeList[0].pay_id;
          this.pay_change();
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  check_amt_to_payment(invoice) {
    var flag = true;
    
    if (invoice.paymentcheckbox == true) {
      if (this.selectedInvoice.length != 0) {
        if (invoice.supp_id != this.selectedInvoice[0].supp_id) {
          this.toastr.error("Multi-supplier selection not encouraged");
          invoice.paymentcheckbox =false;

        } else {
          flag = false;
        }

      } else {
        this.selectedInvoice.push(invoice);
        flag = false;
      }
      
      if (flag == false) {
        this.selectedInvoice.push(invoice);
        this.suppId=invoice.supp_id
        if (invoice.payment != undefined) {
          invoice.paid_amount = (invoice.invoice_amount - invoice.payment);
        } else {
          invoice.paid_amount = invoice.invoice_amount;
        }
        this.calculatePaidAmount(invoice)
      }

    } else if (invoice.paymentcheckbox == false) {
      for (var i = 0; i < this.selectedInvoice.length; i++) {
        if (invoice.invoice_no == this.selectedInvoice[i].invoice_no) {
          this.selectedInvoice.splice(i, 1);
        }
      }
      this.calculatePaidAmount(invoice)
    }
    this.checkboxflag = true
  }
}
