import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import { Http, Headers, JSONPBackend } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { Message_data } from '../../../assets/js/Message_data';
import { Helper_Class } from '../../helper_class';
import { Doc_Helper } from '../../Doctor_module/Doc_Helper'
import { ipaddress } from '../../ipaddress' ;
@Component({
  selector: 'app-doctor-notification',
  templateUrl: './doctor-notification.component.html',
  styleUrls: ['./doctor-notification.component.css']
})
export class DoctorNotificationComponent implements OnInit {
  public user_info;
  public user_id;
  public notification_list:any = [];
  public nodapp: boolean;
  public list_img:string;

  public tablet_tracker :any= [];
  public total_name: string;
  public dr_total_name: string;
  public date_prescription: string;
  public disease_name: string;
  public disease_describtion: string;
  public consul_text;
  public days_txt: string;
  public notifacte_remark: string;

  public days_hidd: boolean;
  public pres_id: string;
  public not_id: string;
  public app_flag: boolean;
  public pres_flag:boolean;
  public type;
  public tickflag:boolean;

  constructor(public gservice:CommonDataService,public http:Http,public routes:ActivatedRoute,public router:Router,public toastr:ToastrService) { 
    this.nodapp = true;
    this.days_hidd = true;
    this.pres_flag=true;
    this.app_flag=true;
  }

  ngOnInit(): void {
    this.notification_list=[]
    this.user_info = Helper_Class.getInfo();
    console.log("this,use"+JSON.stringify(Helper_Class.getInfo()))
    this.user_id = this.user_info.user_id;
    if(this.user_info.user_type == "front-desk"){
      this.type = this.user_info.user_type;
    }else{
      this.type = this.user_info.provider;
    }
   // == "dietician"
    this.list_img = "../../../assets/img/plus.jpg";
    if(Helper_Class.getmodulelist() != undefined){
      for (var i = 0; i < Helper_Class.getmodulelist().length; i++) {
        if (Helper_Class.getmodulelist()[i].module_id == "12"){
            if(Helper_Class.getmodulelist()[i].edit == "1"){
             // this.editbutton=true;
            }
            if(Helper_Class.getmodulelist()[i].create == "1"){
              //this.newbutton=true;
            }
            if(Helper_Class.getmodulelist()[i].delete == "1"){
            // this.deletebutton=true;
            }
            if(Helper_Class.getmodulelist()[i].print == "1"){
             // this.printbutton=true;
            }
            if(Helper_Class.getmodulelist()[i].view == "1"){
             // this.viewbutton=true;
            }
            
            
        }
       
      }
    }
    this.get_notification_list();
  }

  get_notification_list() {
    this.notification_list=[]
    console.log("check no list"+this.user_id+this.type)
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'gen/gc',
    {
      type: this.type,
      user_id: this.user_id,
      imei: Helper_Class.getIPAddress()
    },
      { headers: headers })
      .subscribe(
        response => {
          var obj =response.json()
          console.log("check obj list"+JSON.stringify(obj))
             if (obj.notifications != null) {
              for (var i = 0; i < obj.notifications.length; i++) {
                if(obj.notifications[i].status == '1'){
                  obj.notifications[i].flag=true
                }else{
                  obj.notifications[i].flag=false
                }
                this.notification_list.push({
                  notification_id: obj.notifications[i].notification_id,
                  pres_drug_id: obj.notifications[i].pres_drug_id,
                  content:obj.notifications[i].content,
                  tickurl:obj.notifications[i].flag,
                  status:obj.notifications[i].status
                });
              }
              
              if (obj.notifications.length != 0) {
                this.nodapp = true;
                Doc_Helper.setNotificationCount(obj.notifications.length);
              }else{
                this.nodapp = false;
                Doc_Helper.setNotificationCount("0");
              }
            }else{
              this.toastr.error(Message_data.getNetworkMessage());
            }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  expandNotification(data){
    this.list_img="../../assets/imgs/minus.png";

    for(var i=0;i<this.notification_list.length;i++){
      if(data == this.notification_list[i].notification_id){
        if(this.notification_list[i].pres_drug_id != undefined){
          this.pres_flag = false;
        } else {
          this.app_flag =false;
        }
      }
    }
  }

  get_notify_view(pres_id, not_id) {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'gen/gp',
      JSON.stringify({
        pres_drug_id: pres_id,
        notification_id: not_id,
        type: "doctor"
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.first_name != null) {
            this.total_name = obj.middle_name != null && obj.middle_name != "" ? obj.first_name + ' ' + obj.middle_name + ' ' + obj.last_name : obj.first_name + ' ' + obj.last_name;

            if (obj.middle_name != null && obj.middle_name != "") {
              this.dr_total_name = encrypt_decript.Decript(obj.cl_first_name) + ' ' + encrypt_decript.Decript(obj.cl_last_name) + ' ' + encrypt_decript.Decript(obj.cl_middle_name);
            } else {
              this.dr_total_name = encrypt_decript.Decript(obj.cl_first_name) + ' ' + encrypt_decript.Decript(obj.cl_last_name);
            }

            this.date_prescription = obj.pres_date;
            this.disease_name = obj.disease;
            this.disease_describtion = obj.dis_desc;

            for (var i = 0; i < obj.drug_list.length; i++) {
              var master_tablet_data = obj.drug_list[i];
              var medicene_dosage_data = "";

              if (master_tablet_data.intake_desc == "Every 6 hours") {
                medicene_dosage_data = master_tablet_data.morning + '-' + master_tablet_data.morning + '-' + master_tablet_data.morning + '-' + master_tablet_data.morning;
              } else {
                medicene_dosage_data = master_tablet_data.morning + '-' + master_tablet_data.afternoon + '-' + master_tablet_data.evening;;
              }

              var master_timeDuration = "";
              if (master_tablet_data.drug_intake == "With food") {
                master_timeDuration = master_tablet_data.drug_intake;
              } else {
                if (master_tablet_data.time_duration != null && master_tablet_data.time_duration != "0") {
                  master_timeDuration = master_tablet_data.time_duration + ' mins ' + master_tablet_data.drug_intake;
                } else {
                  master_timeDuration = master_tablet_data.drug_intake;
                }
              }

              this.tablet_tracker.push({
                medicene_name: master_tablet_data.drug_type_name + ' - ' + master_tablet_data.drug_name,
                medicene_dosage: medicene_dosage_data,
                medicene_intakedays: master_tablet_data.days + ' days ',
                medicene_food: master_timeDuration
              })
            }
          } else {
            this.toastr.error(Message_data.getNetworkMessage());
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  prescription_notification_save(data) {
    var pres_id_send,days_extend;
    if (this.consul_text == "Extend") {
      if (this.days_txt != undefined && this.days_txt != '') {
        pres_id_send = this.pres_id;
        days_extend = this.days_txt;
      }
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'gen/uc',
      JSON.stringify({
        remarks: this.notifacte_remark,
        notification_id: this.not_id,
        pres_drug_id: pres_id_send,
        days: days_extend
      }),
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          if (obj.key != null && obj.key == "1") {
            this.toastr.success(Message_data.saveSuccess);
          } else {
            this.toastr.error(Message_data.notSavedSuccess);
          }
        },
        error => {
          this.toastr.error(Message_data.getNetworkMessage());
        });
  }

  consult_change() {
    if (this.consul_text == "Extend") {
      this.days_hidd = false;
      this.notifacte_remark = "";
      this.days_txt = "";
    } else if (this.consul_text == "Consult me") {
      this.days_hidd = true;
      this.notifacte_remark = "";
    } else {
      this.days_hidd = false;
      this.notifacte_remark = "";
      this.days_txt = "";
    }
  }

  view_click(pres_id, not_id) {
    if(pres_id !=undefined){
    } 
  }

  notificationclick(id){
  //gen/cc 
  // params
  // - notification_id
var send_data={
  notification_id:id.notification_id,
  status:id.status	
}
console.log("notificationclick_send = "+JSON.stringify(send_data))
var headers = new Headers();
headers.append('Content-Type', 'application/json');
this.http.post(ipaddress.getIp.toString() + 'gen/cc',send_data,
{ headers: headers })
  .subscribe(
    response => {
      var obj = JSON.parse(response["_body"]);
      console.log("notificationclick_url = "+JSON.stringify(obj))
      if (obj.key != null && obj.key == "1") {
        // this.toastr.success(Message_data.saveSuccess);
        id.tickurl=false
      } else {
        this.toastr.error("Not readed");
      }
    },
    error => {
      // this.toastr.error(Message_data.getNetworkMessage());
    });

  }

  
}
  
