<div class="row" style="margin:0 auto; width:100%">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white " style="padding:7px 20px;">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Tests</h5>
          </div>
          <div class="headerButtons">
            <img src="../../../assets/ui_icons/buttons/Back_button.svg" (click)="backClicked()"
              class="saveimgbtn_inpatinfo " />
            <img src="../../../assets/ui_icons/buttons/save_button.svg" [hidden]="save_btn" (click)="saveTest('Save')"
              class="saveimgbtn_inpatinfo" />
            <img src="../../../assets/ui_icons/buttons/update_button.svg" [hidden]="update_btn"
              (click)="saveTest('update')" class="saveimgbtn_inpatinfo" />
            <!-- <img src="../../../assets/ui_icons/buttons/update_button.svg" [hidden]="!(update_btn && editbutton)"
              (click)="saveTest('update')" class="saveimgbtn_inpatinfo" /> -->
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>

        <div class="row" style="margin-bottom: 20px;">
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 select_bottom mt-2">
            <mat-label class="matlabel">Department<br>
              <select disableOptionCentering class="ipcss " [(ngModel)]="diag_dept"
                (change)="getTestCategory(diag_dept, '0')">
                <option *ngFor="let dept of department_data" required value={{dept.diag_department_id}}>
                  {{dept.diag_dept_desc}}</option>
              </select>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 select_bottom mt-2">
            <mat-label class="matlabel">Sub department<br>
              <select disableOptionCentering class="ipcss " [(ngModel)]="diag_test_categ" (change)="getSampleTypes()">
                <option *ngFor="let testcat of test_category_data" required value={{testcat.description}}>
                  {{testcat.description}}</option>
              </select>
            </mat-label>
          </div>

          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 select_bottom mt-2">
            <mat-label class="matlabel">Test name
              <input class="ipcss widthappt" required [(ngModel)]="Diag_TestName" required #matInput />
            </mat-label>
          </div>

          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 select_bottom mt-2">
            <mat-label class="matlabel">Disease category<br>
              <select disableOptionCentering class="ipcss " [(ngModel)]="diag_dis_categ" required>
                <option [ngValue]="undefined" selected>Select</option>
                <option *ngFor="let cateogry of dis_category_data" value={{cateogry.description}}>
                  {{cateogry.description}}</option>
              </select>
            </mat-label>
          </div>

          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-1 select_bottom mt-2">
            <mat-label class="matlabel">IP/OP<br>
              <select disableOptionCentering class="ipcss " [(ngModel)]="inpat_outpt" required>
                <option value="both">Both</option>
                <option value="ip">IP</option>
                <option value="op">OP</option>
              </select>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 select_bottom mt-2" [hidden]="specimenTypeFlag">
            <mat-label class="matlabel">Specimen type</mat-label>
            <mat-select class="ipcss" multiple [(ngModel)]="Sample_type" required>
              <mat-option *ngFor="let specimen of sampletype_data" value="{{specimen.sample_type_id}}">
                {{specimen.sample_desc}}</mat-option>
            </mat-select>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 select_bottom mt-2">
            <mat-label class="matlabel">Frequency
              <div class="row">
                <div class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5 select_bottom">
                  <input class="ipcss " [(ngModel)]="frequency_Value" #matInput />
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-7 col-xl-7">
                  <select disableOptionCentering class="ipcss " [(ngModel)]="frequency_Measure">
                    <option value="Day(s)">Day(s)</option>
                    <option value="Month(s)">Month(s)</option>
                    <option value="Year(s)">Year(s)</option>
                  </select>
                </div>
              </div>
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 select_bottom mt-2">
            <div class="row">
              <div class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5 select_bottom">
                <mat-label class="matlabel">Cost
                  <input class="ipcss " [(ngModel)]="Diag_test_cost" #matInput />
                </mat-label>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-7 col-xl-7">
                <mat-label class="matlabel"> Status
                  <select disableOptionCentering class="ipcss " [(ngModel)]="is_active" required>
                    <option value="true">Active</option>
                    <option value="false">Inactive</option>
                  </select>
                </mat-label>
              </div>
            </div>
          </div>

          <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3" style="margin-top: 31px;">
            <div class="row">
              <div class="col-12 col-sm-12 col-md-6 col-lg-7 col-xl-7">
                <mat-label class="matlabel">
                  <mat-checkbox color="primary" (change)="conddocReq($event.checked)" [ngModel]="conduct_doctor_ng"
                    labelPosition="before"></mat-checkbox> Conducting doctor
                </mat-label>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5" style="padding: 0px;">
                <mat-label class="matlabel">
                  <mat-checkbox color="primary" (change)="showOrhide_test($event.checked)" [ngModel]="show_test_ng"
                    labelPosition="before"></mat-checkbox> Allow booking
                </mat-label>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-2 mt-2" [hidden]="unithidden">
            <mat-label class="matlabel">Unit of measure<br>
              <select disableOptionCentering class="ipcss " [(ngModel)]="unit_of_measure">
                <option *ngFor="let uom of UOM_list" value={{uom.uom_desc}} [innerHtml]="uom.uom_desc"></option>
              </select>
            </mat-label>
          </div>
          <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 mt-2" [hidden]="conductflag">
            <div class="row">
              <div class="col-12 col-sm-12 col-md-6 col-lg-7 col-xl-6" [hidden]="conductflag">
                <mat-label class="matlabel"> Conduction format
                  <select disableOptionCentering class="ipcss " [disabled]="conduction_format_disable"
                    [(ngModel)]="conduction_format" (change)="formatChange(conduction_format)" required>
                    <option *ngFor="let format of conductionFormatarr " value="{{format.format_id}}">
                      {{format.format_desc}}</option>
                  </select>
                </mat-label>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-6" [hidden]="microdrugFlag">
                <mat-label class="matlabel">Sensitive drug </mat-label>
                <mat-select class="ipcss" multiple [(ngModel)]="drug_type" required>
                  <mat-option *ngFor="let specimen of sampletype_micro_data" value="{{specimen.sample_type_id}}">
                    {{specimen.sample_desc}}</mat-option>
                </mat-select>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-6" [hidden]="templateFlag">
                <mat-label class="matlabel"> Report Templates
                  <select disableOptionCentering class="ipcss " [(ngModel)]="report_template"
                    (change)="showTemplate(report_template)" required>
                    <option *ngFor="let eachTemplate of templates " value="{{eachTemplate.template_id}}">
                      {{eachTemplate.template_name}}</option>
                  </select>
                </mat-label>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 select_bottom mt-2" [hidden]="dropFlag">
            <mat-label class="matlabel">Dropdown values
              <input class="ipcss widthappt" placeholder="Enter comma seperated values without space" maxlength="450"
                [(ngModel)]="drop_default" required #matInput />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-1 col-xl-1 select_bottom mt-2">
            <mat-label class="matlabel">Collect TAT
              <input class="ipcss widthappt" [(ngModel)]="collect_TAT" #matInput />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-1 col-xl-1 select_bottom mt-2">
            <mat-label class="matlabel">Assert TAT
              <input class="ipcss widthappt" [(ngModel)]="assert_TAT" #matInput />
            </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 select_bottom mt-2">
            <mat-label class="matlabel">Upload TAT
              <div class="row">
                <div class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5 select_bottom">
                  <input class="ipcss widthappt" [(ngModel)]="upload_TAT" #matInput />
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-7 col-xl-7">
                  <select disableOptionCentering class="ipcss " [(ngModel)]="upload_TAT_Measure">
                    <option value="Min(s)">Min(s)</option>
                    <option value="Hour(s)">Hour(s)</option>
                    <option value="Day(s)">Day(s)</option>
                  </select>
                </div>
              </div>
            </mat-label>
          </div>

          <div class="col-12 col-sm-6 col-md-6 col-lg-1 col-xl-1 select_bottom mt-2">
            <mat-label class="matlabel">Approve TAT
              <input class="ipcss widthappt" [(ngModel)]="approve_TAT" #matInput />
            </mat-label>
          </div>

          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 select_bottom mt-2">
            <mat-label class="matlabel">Total TAT
              <div class="row">
                <div class="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5 select_bottom">
                  <input class="ipcss widthappt" [(ngModel)]="total_TAT" #matInput />
                </div>
                <div class="col-12 col-sm-6 col-md-6 col-lg-7 col-xl-7">
                  <select disableOptionCentering class="ipcss " [(ngModel)]="total_TAT_Measure">
                    <option value="Min(s)">Min(s)</option>
                    <option value="Hour(s)">Hour(s)</option>
                    <option value="Day(s)">Day(s)</option>
                  </select>
                </div>
              </div>
            </mat-label>
          </div>

          <!-- <div class="col-12 col-sm-6 col-md-6 col-lg-1 col-xl-1 select_bottom mt-2">
            <mat-label class="matlabel">Total TAT
              <input class="ipcss widthappt" [(ngModel)]="total_TAT" #matInput />
            </mat-label>
          </div> -->
          <div class="col-12 col-sm-6 col-md-6 col-lg-1 col-xl-1 select_bottom mt-2">
            <mat-label class="matlabel">Order No
              <input class="ipcss widthappt" [(ngModel)]="order_no" (keyup)="checkOrder(order_no)" #matInput />
            </mat-label>
          </div>

          <div [hidden]="addTest_flag" class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 select_bottom my-auto">
            <mat-label class="matlabel">
              <mat-checkbox color="primary" style="margin-top: 30px !important;" (change)="addSubtest($event.checked)"
                [ngModel]="addSubtest_checked" labelPosition="before"></mat-checkbox>
              Would you like to add subtest?
            </mat-label>
          </div>
        </div>
        <div class="row" [hidden]="diagsubhidden">
          <div class="col-12 col-sm-2 col-md-3 col-lg-3 col-xl-1 select_bottom"></div>
          <div class="col-12 col-sm-8 col-md-6 col-lg-6 col-xl-10 add_test_cover">
            <div class="row" [hidden]="diagsubhidden">
              <div [hidden]="subtest_flag" class="col-12 col-sm-12 col-md-7 col-lg-4 col-xl-4"
                style="margin-bottom: 20px;">
                <mat-label class="matlabel">Subtest name
                  <input class="ipcss " required [(ngModel)]="Diag_Subtest_name" #matInput />
                </mat-label>
              </div>
              <div [hidden]="subtest_uomflag" class="col-12 col-sm-12 col-md-5 col-lg-2 col-xl-2">
                <mat-label class="matlabel">Unit of measure<br>
                  <select disableOptionCentering class="ipcss " [(ngModel)]="diagSubUOM">
                    <option *ngFor="let uom of UOM_list" value={{uom.uom_desc}}>
                      {{uom.uom_desc}}
                    </option>
                  </select>
                </mat-label>
              </div>
              <div class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-2" [hidden]="!conductflag">
                <mat-label class="matlabel"> Conduction format
                  <select disableOptionCentering class="ipcss " [disabled]="conduction_format_disable"
                    [(ngModel)]="conduction_format" (change)="formatChange(conduction_format)" required>
                    <option *ngFor="let format of conductionFormatarr " value="{{format.format_id}}">
                      {{format.format_desc}}</option>
                  </select>
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4" [hidden]="dropFlag1">
                <mat-label class="matlabel">Dropdown values
                  <input placeholder="Enter comma seperated values without space" class="ipcss widthappt" required
                    maxlength="450" [(ngModel)]="drop_default1" #matInput />
                </mat-label>
              </div>
            </div>

            <div class="row mt-3 mb-3" [hidden]="reference_flag">
              <div [hidden]="addTest_flag" class="col-12 col-sm-12 col-md-7 col-lg-4 col-xl-4">
                <div class="row mb-2">
                  <div [hidden]="addTest_flag" class="col-12 " style="margin-left: -6px;
                    margin-bottom: 10px;">
                    <mat-label class="matlabel">
                      <mat-checkbox color="primary" (change)="allAgeChk($event.checked)" [ngModel]="age_grp_check"
                        labelPosition="before"></mat-checkbox> All
                      age group
                    </mat-label>
                  </div>
                  <div [hidden]="showAgeInp" class="col-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
                    <mat-label class="matlabel">Age
                      <input placeholder="Min" class="ipcss " [(ngModel)]="age_input" #matInput
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                    </mat-label>
                  </div>
                  <div [hidden]="showAgeInp" class="col-12 col-sm-12 col-md-6 col-lg-2 col-xl-2"
                    style="margin-top: 19px;     margin-left: -15px;">
                    <mat-label class="matlabel">
                      <input placeholder="Max" class="ipcss " [(ngModel)]="age_input_max" #matInput
                        onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                    </mat-label>
                  </div>
                  <div [hidden]="showAgeInp" class="col-12 col-sm-4 col-md-3 col-lg-4 col-xl-4"
                    style="margin-top: 19px;">
                    <mat-label class="matlabel">
                      <select disableOptionCentering class="ipcss " [(ngModel)]="age_ref">
                        <option value="Day(s)">Day(s)</option>
                        <option value="Month(s)">Month(s)</option>
                        <option value="Year(s)">Year(s)</option>
                      </select>
                    </mat-label>
                  </div>
                  <div [hidden]="addTest_flag" class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <mat-label class="matlabel">Gender<br>
                      <select disableOptionCentering class="ipcss" [(ngModel)]="gender_ref" required>
                        <option selected value="All">All</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Transgender">Transgender</option>
                      </select>
                    </mat-label>
                  </div>
                </div>
                <div class="row mb-2">
                  <div [hidden]="addTest_flag" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <mat-label class="matlabel">Normal range</mat-label>
                    <div class="row">
                      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <input placeholder="Min" class="ipcss " [(ngModel)]="min_nor_value" #matInput />
                      </div>
                      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <input placeholder="Max" class="ipcss " [(ngModel)]="max_nor_value" #matInput />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div [hidden]="addTest_flag" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <mat-label class="matlabel">Abnormal range</mat-label>
                    <div class="row">
                      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <input placeholder="Min" class="ipcss " [(ngModel)]="min_anor_value" #matInput />
                      </div>
                      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <input placeholder="Max" class="ipcss " [(ngModel)]="max_anor_value" #matInput />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div [hidden]="addTest_flag" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <mat-label class="matlabel">Critical range</mat-label>
                    <div class="row">
                      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <input placeholder="Min" class="ipcss " [(ngModel)]="min_crit_value" #matInput />
                      </div>
                      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <input placeholder="Max" class="ipcss " [(ngModel)]="max_crit_value" #matInput />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div [hidden]="addTest_flag" class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-1 mt-3"
                style="display: flex; align-items: center;">
                <img src="../../../assets/ui_icons/arrow_green.svg" class="arrow_imageprescript"
                  (click)="referenceAddBtn()" />
              </div>
              <div [hidden]="addTest_flag" class="col-12 col-sm-12 col-md-6 col-lg-7 col-xl-7 mt-3 ref-readings-card">
                <div *ngIf="referenceArray!= undefined && referenceArray.length != 0">
                  <table>
                    <thead>
                      <tr>
                        <th>Age</th>
                        <th>Gender</th>
                        <th>Normal range</th>
                        <th>Abnormal range</th>
                        <th>Critical range</th>
                        <th style="width: 5%;"></th>
                        <th style="width: 5%;"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let reference of referenceArray; let i = index">
                        <td>{{reference.age_display}}</td>
                        <td>{{reference.gender}}</td>
                        <td>{{reference.min_nor_value}}
                          - {{reference.max_nor_value}}</td>
                        <td>{{reference.min_abnor_value}} -
                          {{reference.max_abnor_value}}</td>
                        <td>{{reference.min_crit_value}} -
                          {{reference.max_crit_value}}</td>
                        <td><img src="../../../assets/ui_icons/buttons/pencil_icon.svg" class="del_img_class"
                            (click)="referenceEdit(reference)" /></td>
                        <td><img src="../../../assets/ui_icons/buttons/trash_icon.svg" class="del_img_class"
                            (click)="referenceDelete(reference)" /></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div class="row" style="margin-top: 10px;" *ngIf="!ref_flag">
              <div class="col-12">
                <mat-label class="mat-label">Referrence value <span
                    style="color: red;font-size: 20px;">*</span></mat-label>
                <quill-editor [(ngModel)]="range" [style]="editorStyle" [modules]="modules"
                  (onEditorCreated)="editorCreated($event)" placeholder="Enter Text"
                  (onSelectionChanged)="onSelectionChanged($event)" (onContentChanged)="onContentChanged($event)">
                </quill-editor>
              </div>
            </div>
            <div [hidden]="addTest_flag" class="row" style="margin-top: 10px;">
              <div class="col-6">
                <div>
                  <mat-label class="matlabel">Test method
                    <input class="ipcss " id="testMethod" [(ngModel)]="test_method" #matInput />
                  </mat-label>
                </div>
              </div>
            </div>
            <div class="row" style="margin-top: 10px;" [hidden]="diagsubhidden">
              <div class="col-12">
                <img *ngIf="!main_test_uom_flag" src="../../../assets/ui_icons/buttons/add_button.svg"
                  (click)="test_add()" class="saveimgbtn_inpatinfo" style="float: right;" />
              </div>
            </div>

            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin-top: 10px;"
              *ngIf='Diag_subtest_list_data != undefined && Diag_subtest_list_data.length != 0 && !main_test_uom_flag'>
              <div class="table-responsive">
                <table *ngIf="Diag_subtest_list_data.length" class="table table-hover table-dynamic" id="card_tbl">
                  <thead>
                    <tr>
                      <th>Sub test name</th>
                      <th>UOM</th>
                      <th>Conduction</th>
                      <th>Test method</th>
                      <th class="extra_action">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let Subtest of Diag_subtest_list_data; let i = index" draggable="true"
                      (dragstart)="onDragStart($event, Subtest)" (dragover)="onDragOver($event)"
                      (drop)="onDrop($event, i)">
                      <td *ngIf="Subtest.status == 1" style="text-align: left;">{{Subtest.sub_test_name}}</td>
                      <td *ngIf="Subtest.status == 1" style="text-align: left;">{{Subtest.sub_uom}}</td>
                      <td *ngIf="Subtest.status == 1" style="text-align: left;">{{Subtest.conduct_form}}</td>
                      <td *ngIf="Subtest.status == 1">
                        <div class="test_method" [innerHtml]="Subtest.test_method"></div>
                      </td>
                      <td *ngIf="Subtest.status == 1">
                        <img src="../../../assets/ui_icons/buttons/pencil_icon.svg" class="del_img_class"
                          (click)="Subtest_Select_Click(Subtest.sub_test_name)" />
                        <img src="../../../assets/ui_icons/buttons/trash_icon.svg" class="del_img_class"
                          (click)="Subtest_Delete_Click(Subtest.sub_test_name)" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <br><br><br>
            </div>
          </div>
          <div class="col-12 col-sm-2 col-md-3 col-lg-3 col-xl-1 select_bottom"></div>
        </div>
        <div class="row p-3" [hidden]="template_flag">
          <div class="d-flex align-items-center justify-content-center">
            <div class="col-12">
              <quill-editor id="editor1" [(ngModel)]="cbt_Template" [style]="editorStyle" [modules]="modules"
                placeholder="Enter Text" (onContentChanged)="onTemplateChanged($event)"></quill-editor>
              <div>
                <!-- Preview -->
                <quill-view-html hidden id="viewhtml1" [content]="cbt_Template"></quill-view-html>
              </div>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>