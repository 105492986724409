<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Doctor payments</h5>
          </div>
          <div class="headerButtons">
            <img src="../../../assets/ui_icons/buttons/update_button.svg" (click)="save_data()" *ngIf="selected_payments.length != 0"
              class="saveimgbtn_inpatinfo" />
            <img printSectionId="print" (click)="print_area()" *ngIf="selected_payments.length != 0"
              src="../../../assets/img/printer.svg" class="saveimgbtn_inpatinfo"
              style="width: 25px !important;height: 25px !important;" ngxPrint>
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <mat-label class="matlabel">Filter by<br>
              <select disableOptionCentering class="ipcss " [(ngModel)]="payment_type" (change)="getDoctors()">
                <option value="surgery">Surgery</option>
                <option value="rounds">Rounds</option>
                <option value="consultation">Consultation</option>
                <option value="anaestesia">Anaestesia</option>
                <option value="conduction">Conduction</option>
              </select>
            </mat-label>
          </div>
          <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
              <mat-label class="matlabel" style="margin-top: 7px;">From
                <input type="date" class="ipcss_date " (change)="dateSelect(fromDate,'1')" [(ngModel)]="fromDate"
                   #matInput style="width: 140px;">
              </mat-label>
            </div>

            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
              <mat-label class="matlabel" style="margin-top: 7px;">To
                <input type="date" class="ipcss_date " (change)="dateSelect(toDate,'2')" [(ngModel)]="toDate"
                  #matInput style="width: 140px;">
              </mat-label>
            </div> -->
          <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-1">
            <mat-label class="matlabel"><br>
              <img src="../../../assets/ui_icons/buttons/search_button.svg" class="saveimgbtn_inpatinfo"
                (click)="getDoctors()" />
            </mat-label>
          </div> -->
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-1" *ngIf="selected_payments.length != 0">
            <mat-label class="matlabel"><br>
              <img src="../../../assets/ui_icons/buttons/XL_export.svg" class="saveimgbtn_inpatinfo"
                (click)="getExcelData('excel')" />
            </mat-label>
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 my-auto"
            style="margin-top: 15px !important;margin-bottom: 10px !important;">
            <mat-label class="matlabel" style="margin-top: 7px;"><span style="font-weight: 600;">Total amount :
              </span><b>{{totalAmount}}</b>
            </mat-label>
          </div>
          <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 my-auto" style="margin-top: 15px !important;margin-bottom: 10px !important;">
              <mat-label class="matlabel" style="margin-top: 7px;"><span style="font-weight: 600;">Pending amount : </span><b>{{pendingAmount}}</b> 
              </mat-label>
            </div>             -->
          <div class="col-12" *ngIf="doctor_payments.length != 0" style="margin-top: 10px !important;">
            <table *ngIf="doctor_payments.length" mdbTable datatable [dtOptions]="dtOptions"
              class="table table-nowrap table-sm dataTable">
              <thead>
                <tr>
                  <th style="width: 12%;">Appointment id</th>
                  <th style="width: 10%;">Doctor</th>
                  <th style="width: 10%;">Description</th>
                  <th style="width: 10%;">Amount</th>
                  <th style="width:10%">Payment</th>
                  <th style="width: 10%;">Payment mode</th>
                  <th style="width: 10%;">Balance</th>
                  <th style="width: 10%;">Status</th>
                  <th style="width: 5%;"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let payment of doctor_payments">
                  <td style="text-align: center !important;">{{payment.diag_appointment_id}}</td>
                  <td style="text-align: center !important;">{{payment.doctor_name}}</td>
                  <td style="text-align: center !important;">{{payment.test_name}}</td>
                  <td style="text-align: right !important;">{{payment.cost}}</td>
                  <td style="text-align: center !important;" *ngIf="!payment.disableflag">
                    <input class="ipcss" matInput text-right maxlength="10" style="margin:0;text-align:right;"
                      [(ngModel)]="payment.paid_amount" (blur)="calculatePaidAmount(payment)" />
                  </td>


                  <td>
                    <mat-label class="matlabel">
                      <mat-select disableOptionCentering class="ipcss" [(ngModel)]="payment.selected_paytype">
                        <mat-option *ngFor="let payType of payTypeList" required value={{payType.pay_id}}>
                          {{payType.pay_desc}}</mat-option>
                      </mat-select>
                    </mat-label>
                  </td>
                  <td style="text-align: right !important;">{{payment.balance}}</td>
                  <td style="text-align: center !important;">{{payment.paid_flag}}</td>
                  <td>
                    <input type="checkbox" name="" id="" [(ngModel)]="payment.paymentcheckbox"
                      (change)="check_amt_to_payment(payment)">
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div [hidden]="true" #printbanner id="printbanner">
            <div>
              <table style="border:'0';width:100%">
                <thead>
                  <tr>
                    <th style="width:100%;">
                      <div
                        style="display: flex; flex-direction: column; justify-content: center; align-items: center;margin-right: 30px; margin-left: 20px;">
                        <div style="width: 100%; height: 205px !important;">
                          <div style="width: 100%;">
                            <img alt="image" src={{hospital_logo}} [style]="printlogostyle">
                          </div>
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td width="100%">
                      <div class="bill_border">
                        <div>
                          <div *ngIf="selected_payments != undefined && selected_payments .length != 0"
                            style="margin-left: 20px;margin-right: 10px;">
                            <div class="row">
                              <table style="border: 1px solid black;border-collapse: collapse;margin-right: 20px; width: 100%;">
                                <thead>
                                  <tr>
                                    <th style="border: 1px solid black;border-collapse: collapse;">Appointment id</th>
                                    <th style="border: 1px solid black;border-collapse: collapse;">Doctor</th>
                                    <th style="border: 1px solid black;border-collapse: collapse;">Description</th>
                                    <th style="border: 1px solid black;border-collapse: collapse;">Amount</th>
                                    <th style="border: 1px solid black;border-collapse: collapse;">Payment</th>
                                    <th style="border: 1px solid black;border-collapse: collapse;">Balance</th>
                                    <th style="border: 1px solid black;border-collapse: collapse;">Status</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let payment of selected_payments">
                                    <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                                      {{payment.diag_appointment_id}}</td>
                                    <td style="border: 1px solid black;border-collapse: collapse;">
                                      {{payment.doctor_name}}</td>
                                    <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                                      {{payment.test_name}}</td>
                                    <td style="text-align: right; border: 1px solid black;border-collapse: collapse;">{{payment.cost}}
                                    </td>
                                    <td style="text-align: right;border: 1px solid black;border-collapse: collapse;">
                                      {{payment.paid_amount}}</td>
                                    <td style="text-align: right; border: 1px solid black;border-collapse: collapse;">{{payment.balance}}
                                    </td>
                                    <td style="text-align: right; border: 1px solid black;border-collapse: collapse;">{{payment.paid_flag}}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div [hidden]="true" #printnoheader id="printnoheader">
            <div [hidden]="selected_payments == undefined || selected_payments.length == 0">
              <table style="border:'0';width:100%">
                <thead>
                  <tr>
                    <th style="width:100%;">
                      <div
                        style="display: flex; flex-direction: column; justify-content: center; align-items: center;margin-right: 30px; margin-left: 20px;">
                        <!-- <div style="width: 100%; height: 95px;">
                                <div style="height: 5px !important;">                                   
                                </div>               
                            </div> -->
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td width="100%">
                      <div class="bill_border">
                        <div>
                          <div *ngIf="selected_payments != undefined && selected_payments.length != 0"
                            style="margin-left: 20px;margin-right: 10px;">
                            <div class="row">
                              <table style="border: 1px solid black;border-collapse: collapse;margin-right: 20px; width: 100%;">
                                <thead>
                                  <tr>
                                    <th style="border: 1px solid black;border-collapse: collapse;">Appointment id</th>
                                    <th style="border: 1px solid black;border-collapse: collapse;">Doctor</th>
                                    <th style="border: 1px solid black;border-collapse: collapse;">Description</th>
                                    <th style="border: 1px solid black;border-collapse: collapse;">Amount</th>
                                    <th style="border: 1px solid black;border-collapse: collapse;">Payment</th>
                                    <th style="border: 1px solid black;border-collapse: collapse;">Balance</th>
                                    <th style="border: 1px solid black;border-collapse: collapse;">Status</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let payment of selected_payments">
                                    <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                                      {{payment.diag_appointment_id}}</td>
                                    <td style="border: 1px solid black;border-collapse: collapse;">
                                      {{payment.doctor_name}}</td>
                                    <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                                      {{payment.test_name}}</td>
                                    <td style="text-align: right; border: 1px solid black;border-collapse: collapse;">{{payment.cost}}
                                    </td>
                                    <td style="text-align: right;border: 1px solid black;border-collapse: collapse;">
                                      {{payment.paid_amount}}</td>
                                    <td style="text-align: right; border: 1px solid black;border-collapse: collapse;">{{payment.balance}}
                                    </td>
                                    <td style="text-align: right; border: 1px solid black;border-collapse: collapse;">{{payment.paid_flag}}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>