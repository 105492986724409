import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Http, Headers } from '@angular/http';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { FrontDesk_Helper } from '../FrontDesk_Helper';
import { ipaddress } from '../../ipaddress';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
import { ScanDetected } from 'ngx-scanner-detection';
import { Date_Formate } from 'src/assets/js/common';
import { WebcamImage } from 'ngx-webcam';
//import {requestDevice_frontdesk,testPrintfrontdesk} from "../../../assets/js/common"


@Component({
  selector: 'app-recept-patient-list',
  templateUrl: './recept-patient-list.component.html',
  styleUrls: ['./recept-patient-list.component.css']
})
export class ReceptPatientListComponent implements OnInit {
  @ViewChild('device') device: ElementRef;

  @ViewChild('scrollContainer', { static: false }) scrollContainer: ElementRef;
  @ViewChild('input', { static: false }) input: ElementRef;
  @ViewChild('idprint') idprint: ElementRef;
  @ViewChild('idprintnew') idprintnew: ElementRef;
  public webcamImage: WebcamImage = null;
  public nodata:boolean =false;
  public clientname;public address;
  public usertype;
  public hospitalinfoarray;
  public hospital_clinic_id
  activeRowIndex: number = -1;
  public clntSaldesc
  dtOptions: DataTables.Settings = {};
  masterSelected: boolean = false;
  public showimg:boolean=false;
  checklist: any;
  checkedList: any
  public checklistid: any = [];
  public pagelist;
  public patientList = [];
  public sendMiddleName;
  public pageSize;
  public pageNavigate: boolean;
  public currentPage;
  public clientCount;
  public filterBy;
  public prevPageCount: boolean;
  public nextPageCount: boolean;
  public noOfPages: any;
  public userInfo;
  public ipaddress;
  public currentLimit = 1;
  searchby: any;
  public typeflag: string;
  public patientName: string;
  public searchtext: string;
  patientid: boolean;
  public filteredArr: any[];
  public paturl:string;
  public clntSal;
  public clientId;
  public clntFirstName;
  public clntMiddleName;
  public clntLastName;
  public clntDOB;
  public clntAge;
  public clntContNumber;
  public clntEmergNumber;
  public clntEmergNumber1;
  public clntGender;
  public clntGendervalue;

  public clntFatherOrGuardName;
  public clntAddress1;
  public clntAddress2;
  public clntZipcode;
  public clntLocation;
  public clntLocationId;
  private locationNameByUrl: string;
  private cityUrl: string;
  private stateUrl: string;
  private countryUrl: string;
  private currentDateUrl: string;
  private zipcode_url: string;
  private ageUrl:string;
  private salUrl: string;
  // private paturl:string;
  private updurl:string;
  public clntLocationList:any=[];
  public clntCityList:any=[];
  public clntStateList:any=[];
  public clntSalList:any=[];
  public clntCityId;
  public clntCity;
  public clntStateId;
  public clntState;
  public clntCountryList;
  public clntCountry;
  public clntCountryId;
  public currentDatetime;
  public currentTime;
  public ageRead: boolean = false;
  public ageDisabledFlag: boolean;
  private clientid:string;
  private relid:string;
  private subrelid:string;
  private upd_data;
  public dateformate;
  public idcardprof:string = "https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg";
  public idprnt:boolean;
  format: '' | 'CODE128' | 'CODE128A' | 'CODE128B' | 'CODE128C' | 'EAN' | 'UPC' | 'EAN8' | 'EAN5' | 'EAN2' | 'CODE39' | 'ITF14' | 'MSI' | 'MSI10' | 'MSI11' | 'MSI1010' | 'MSI1110' | 'pharmacode' | 'codabar';

  elementType: 'svg';
  lineColor = '#000000';
  width = 1;
  barcodeheight = 23;
  displayValue = true;
  fontOptions = '';
  font = 'monospace';
  textAlign = 'center';
  textPosition = 'bottom';
  textMargin = 2;
  fontSize = 15;
  background = '#ffffff';
  margin = 10;
  marginTop = 10;
  marginBottom = 10;
  marginLeft = 10;
  marginRight = 10;
  elementType1: 'svg';
  lineColor1 = '#000000';
  
  width1 = 1;
  barcodeheight1 = 20;
  displayValue1 = true;
  fontOptions1 = '';
  font1 = 'monospace';
  textAlign1 = 'center';
  textPosition1 = 'bottom';
  textMargin1 = 2;
  fontSize1 = 12;
  background1 = '#ffffff';
  margin1 = 2;
  marginTop1 = 2;
  marginBottom1 = 2;
  marginLeft1 = 2;
  marginRight1 = 2;
  public mrNumber: string;
  public profileImg;
  public userinfo;
  public hospital_details;
  public hospital_name;

  constructor(public datepipe: DatePipe, public http: Http,public httpclnt:HttpClient, public toastr: ToastrService, public https: HttpClient,
    public frontdeskservice: MenuViewService) {
    this.userInfo = Helper_Class.getInfo();
    this.ipaddress = Helper_Class.getIPAddress();
    this.pageNavigate = true;
    this.pagelist = "50";
    this.currentPage = 1;
    this.pageSize = 50;
    this.filterBy = "Patient Id";
    this.paturl = ipaddress.getIp + "usercontroller/clientdet";
    FrontDesk_Helper.setClientCount(null);
  }

  ngOnInit() {
    FrontDesk_Helper.setClientCount(null);
    this.patientList = [];
    this.filteredArr = [];
    this.profileImg = "../../assets/img/default.jpg";
    this.salUrl = ipaddress.getIp + "gen/sal";
    this.idprnt=false;

    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 50,
      "lengthMenu": [[50, 100, 200, -1], [50, 100, 200, "All"]],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search by name or mobile no or patient id"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"l><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    this.typeflag = "name";
    this.searchtext = "search by name";

    this.getSalutations();
    this.userinfo = Helper_Class.getInfo();

    this.hospital_details = this.userinfo.hospitals[0];
    this.hospital_name = this.hospital_details.hptl_name;
   this.getNoPatients();
  }

  getSalutations() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.get(this.salUrl, { headers: headers }).subscribe(
      data => {
        var obj = JSON.parse(data["_body"]);
        if (obj != undefined) {
          for (var i = 0; i < obj.salutations.length; i++) {
            this.clntSalList.push({
              sal_id: obj.salutations[i].salutation_id,
              sal_desc: obj.salutations[i].salutation_desc,
            });
          }
        //  this.clntSal = "select";
         // this.changeSalutation(this.clntSal);
        }
      });
  }

  changePageList() {
    this.patientList=[]
    this.pageSize = this.pagelist;
    this.currentPage = 1;
    this.currentLimit=1;
    if (this.pagelist == "50") {
      if (this.clientCount > 50) {
        this.pageNavigate = false;
        this.nextPageCount = false;
        this.prevPageCount = false;
      } else {
        this.pageNavigate = false;
        this.nextPageCount = false;
        this.prevPageCount = false;
      }
    } else if (this.pagelist == "100") {
      if (this.clientCount > 100) {
        this.pageNavigate = false;
        this.nextPageCount = false;
        this.prevPageCount = false;
      } else {
        this.pageNavigate = true;
        this.nextPageCount = true;
        this.prevPageCount = true;
      }
    } else if (this.pagelist == "200") {
      if (this.clientCount > 200) {
        this.pageNavigate = false;
        this.nextPageCount = false;
        this.prevPageCount = false;
      } else {
        this.pageNavigate = true;
        this.nextPageCount = true;
        this.prevPageCount = true;
      }
    }
       this.getNoPatients();
    
  }

  pageLimitnext() {
    this.currentPage = this.currentPage + 1;
    console.log("check --"+this.currentPage)   
    this.pageSize = this.pagelist;
    this.filterBy = this.filterBy;
    var check1 = parseInt(this.currentPage) -1
    this.prevPageCount = false;
    this.currentLimit = ((check1)*parseInt(this.pagelist))+1;
    this.getNoPatients();
    if (this.currentPage == this.noOfPages)
      this.nextPageCount = true;   
    }

  pageLimitPrevious() {//previous limit
    console.log("check --previous"+this.currentPage)
    this.pageSize = this.pagelist;
    this.filterBy = this.filterBy;
  
      if (this.currentPage > 1) {
        this.currentLimit = (this.currentPage - 2) * this.pagelist + 1;
        this.currentPage = this.currentPage - 1;
        this.getNoPatients();     
  
      } 
  //  this.getNoPatients();
    this.nextPageCount = false;
    if (this.currentPage == "1")
      this.prevPageCount = true;
  }

  getNoPatients() {//get no of patients
    console.log("check info --"+JSON.stringify(FrontDesk_Helper.getClientCount()))
    console.log("Helper_Class.getHospital() info --"+JSON.stringify(Helper_Class.getHospital()))
    this.patientList = [];
    this.filteredArr = [];
    if (FrontDesk_Helper.getClientCount() == null) {
      this.hospitalinfoarray = Helper_Class.getHospital();
      if(this.userInfo.user_type == "doctor"){
        this.usertype="doctor";
        this.hospital_clinic_id = this.hospitalinfoarray[0].hptl_clinic_id;

      } else if (this.userInfo.user_type == "pharmacy") {
        this.usertype="pharmacy";
        if(this.userInfo.hosp_id != undefined){
          this.hospital_clinic_id = this.userInfo.hosp_id;
        } else {
          this.hospital_clinic_id = this.hospitalinfoarray[0].hptl_clinic_id;
        }

      } else if (this.userInfo.user_type == "diagnosis") {
        this.usertype="diagnosis";
        if(this.hospitalinfoarray[0].hosp_id != undefined){
          this.hospital_clinic_id = this.hospitalinfoarray[0].hosp_id;
        } else {
          this.hospital_clinic_id = this.hospitalinfoarray[0].hptl_clinic_id;
        }

      } 
      else if (this.userInfo.user_type == "Billing") {
        this.usertype="Billing";
        if(this.userInfo.hptl_clinic_id != undefined){
          this.hospital_clinic_id = this.userInfo.hptl_clinic_id;
        } else {
          this.hospital_clinic_id = this.hospitalinfoarray[0].hptl_clinic_id;
        }

      }
      else{
        this.usertype="nurse";
        this.hospital_clinic_id = this.hospitalinfoarray[0].hptl_clinic_id;
      }

      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + 'gen/gcount',
        JSON.stringify({
          hptl_clinic_id: this.hospital_clinic_id,
        }),
        { headers: headers })
        .subscribe(
          response => {
            var obj = JSON.parse(response["_body"]);
            console.log("pateint list = "+JSON.stringify(obj))
            console.log("Helper_Class.getPatientList() list = "+JSON.stringify(Helper_Class.getPatientList()))
            this.pageNavigate = obj.client_count > 50 ? false : true;
            this.clientCount = obj.client_count;
            FrontDesk_Helper.setClientCount(this.clientCount);
            this.patientList=[];
            if (Helper_Class.getPatientList() == undefined) {
              this.getPatientList();
            } else {
              this.patientList=[];
            //  this.patientList = Helper_Class.getPatientList();
            this.getPatientList();
            }
          },
          error => { });
    } 
    else {
      console.log("check  --"+JSON.stringify(FrontDesk_Helper.getClientCount()))

      this.clientCount = FrontDesk_Helper.getClientCount();
      if (Helper_Class.getPatientList() == undefined) {
        this.getPatientList();
      } else {
       // this.patientList = Helper_Class.getPatientList();
        this.getPatientList();
      }
    }
  }

  isEmptyObject(obj) {
    return obj && Object.keys(obj).length === 0;
  }
  ;
  getPatientList() {//get patient list
    this.patientList=[];
    var senddata;
    if(this.userInfo.user_id == "front-desk"){
      senddata=      
      JSON.stringify({
        hptl_clinic_id: this.hospital_clinic_id,
        country: ipaddress.country_code,
        imei: this.ipaddress,
        limit: this.pagelist,
        filterby: this.filterBy,
        type: "front-desk",
        start_no: this.currentLimit,
        prov_id: this.userInfo.user_id,
      })

    }else{
      this.hospitalinfoarray = Helper_Class.getHospital();
      if(this.userInfo.user_type == "doctor"){
        this.usertype="doctor";
        this.hospital_clinic_id = this.hospitalinfoarray[0].hptl_clinic_id;

      } else if (this.userInfo.user_type == "pharmacy") {
        this.usertype="pharma";
        if(this.userInfo.hosp_id != undefined){
          this.hospital_clinic_id = this.userInfo.hosp_id;
        } else {
          this.hospital_clinic_id = this.hospitalinfoarray[0].hptl_clinic_id;
        }
      } else if (this.userInfo.user_type == "diagnosis") {
        this.usertype="diagnosis";
        if(this.hospitalinfoarray[0].hosp_id != undefined){
          this.hospital_clinic_id = this.hospitalinfoarray[0].hosp_id;
        } else {
          this.hospital_clinic_id = this.hospitalinfoarray[0].hptl_clinic_id;
        }

      }  else if (this.userInfo.user_type == "Billing") {
        this.usertype="Billing";
        if(this.userInfo.hptl_clinic_id != undefined){
          this.hospital_clinic_id = this.userInfo.hptl_clinic_id;
        } else {
          this.hospital_clinic_id = this.hospitalinfoarray[0].hptl_clinic_id;
        }
      }
       else{
        this.usertype="nurse";
        this.hospital_clinic_id = this.hospitalinfoarray[0].hptl_clinic_id;
      }
      
      senddata = JSON.stringify({
        hptl_clinic_id: this.hospital_clinic_id,
        country: ipaddress.country_code,
        imei: this.ipaddress,
        limit: this.pagelist,
        filterby: this.filterBy,
        type: "nurse",
        start_no: this.currentLimit,
        prov_id: this.userInfo.user_id,
      })
      console.log("get patient list = "+ JSON.stringify({
        hptl_clinic_id: this.hospital_clinic_id,
        country: ipaddress.country_code,
        imei: this.ipaddress,
        limit: this.pagelist,
        filterby: this.filterBy,
        type: "doctor",
        start_no: this.currentLimit,
        prov_id: this.userInfo.user_id,
      }))
  
    }
    console.log("get senddata list = "+JSON.stringify(senddata)) 
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'gen/gac',
    
        senddata
      ,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
         console.log("get patient list = "+JSON.stringify(obj))
         var flag = this.isEmptyObject(obj);
         console.log("get obj.clients.length list = "+JSON.stringify(obj))

         if(flag  == false){
          if(obj.clients.length == 0){
            this.nodata =true
          }else{
            if(obj.clients.length != 0){
              if (obj.clients != null) {
                this.patientList=[];
                this.nodata =false;
                for (var i = 0; i < obj.clients.length; i++) {
                  if (obj.clients[i].middle_name != undefined && obj.clients[i].middle_name != "") {
                    this.sendMiddleName = encrypt_decript.Decript(obj.clients[i].middle_name);
                  } else {
                    this.sendMiddleName = "";
                  }
                  var lname;
                  if (obj.clients[i].last_name != undefined && obj.clients[i].last_name != "") {
                    lname = encrypt_decript.Decript(obj.clients[i].last_name);
                  } else {
                    lname = "";
                  }
                  if(obj.clients[i].profile_image != ""){
                    var image=ipaddress.Ip_with_img_address+obj.clients[i].profile_image
                    this.profileImg = ipaddress.Ip_with_img_address + obj.clients[i].profile_image;
  
                  }
                
                this.patientList.push({
                    client_reg_id: obj.clients[i].client_reg_id,
                    relation_id: obj.clients[i].relation_id,
                    sub_rel_id: obj.clients[i].sub_rel_id,
                    patient_id: obj.clients[i].patient_id,
                    full_name: encrypt_decript.Decript(obj.clients[i].first_name) + " " + this.sendMiddleName + " " + lname,
                    mobile: encrypt_decript.Decript(obj.clients[i].mobile),
                    relationship_name: obj.clients[i].relationship_name,
                    prof:image
                  });
                }
                Helper_Class.setPatientList(null);
                Helper_Class.setPatientList(this.patientList);
                console.log("length -- " + JSON.stringify(this.patientList))
              }
            }
            else{
              this.patientList=[];
             }
          }
       
         }
     
         
        },
        error => { });
  }

  numberOfPages() {
    console.log("client count "+this.clientCount+"...."+this.pagelist)
    this.noOfPages = Math.floor(this.clientCount / this.pagelist);
    console.log("client count "+this.noOfPages)

    return Math.floor(this.clientCount / this.pagelist);
  }

  editClient(client) {
    var send_data = {
      client_id: client.client_reg_id,
      relation_id: client.relation_id,
      sub_rel_id: client.sub_rel_id,
      relationship_name: client.relationship_name,
    }
    Helper_Class.setClientData(send_data);
    this.frontdeskservice.sendMessage("manageclient");
  }

  
  patientid_card(client,i,id){   
    this.activeRowIndex = i;
    this.idprnt=true;
   
    var send_data = {
      client_id: client.client_reg_id,
      relation_id: client.relation_id,
      relationship: client.relationship_name,
      sub_rel_id: client.sub_rel_id,
      country:"IN",
    }
    this.mrNumber=client.patient_id;
    this.idcardprof=ipaddress.Ip_with_img_address+client.prof;
    console.log("CLIENT DATA send_data"+JSON.stringify(send_data))

   var headers = new HttpHeaders();
  headers.append('Content-Type', 'application/json');
  this.httpclnt.post(this.paturl,send_data, { headers: headers }).subscribe(
    data => {
      var obj = JSON.parse(JSON.stringify(data));  

      if (obj != undefined) {
        console.log("CLIENT DATA "+JSON.stringify(obj))
        if(obj.salutation != undefined){
          this.clntSal= obj.salutation;
          this.clntSaldesc= obj.salutation_desc;
          // if(this.clntSalList.length != 0){
          //   for(var k= 0;k<this.clntSal.length;k++){

          //   }
          // }
        }
        
        this.clntFirstName = encrypt_decript.Decript(obj.first_name);
        if(obj.middle_name != undefined){
          this.clntMiddleName = encrypt_decript.Decript(obj.middle_name);
        }
        if(obj.last_name != undefined){
          this.clntLastName = encrypt_decript.Decript(obj.last_name);
        }
        if(obj.middle_name != undefined){
          this.clientname =encrypt_decript.Decript(obj.first_name);
        }else{
          this.clientname =encrypt_decript.Decript(obj.first_name);

        }
        if(obj.gender != undefined){
          this.clntGender = encrypt_decript.Decript(obj.gender);
          if(this.clntGender == "Male"){
            this.clntGendervalue="M"
          }else  if(this.clntGender == "Female"){
            this.clntGendervalue="F"
          }else{
            this.clntGendervalue="T"
          }
        
          // this.changeGender(this.clntGender);
        }

        if(obj.dob != null){
          this.clntDOB = obj.dob;
        }

        if(obj.age != undefined){
          this.clntAge = obj.age;
        }

        if(obj.mobile != undefined){
          this.clntContNumber = encrypt_decript.Decript(obj.mobile);
        }
        if(obj.emerg_contact1 != undefined){
          this.clntEmergNumber = obj.emerg_contact1;
        }

        if(obj.dob != undefined){
          this.dateformate = Date_Formate(obj.dob);
        }

        if(obj.emerg_contact2 != undefined){
          this.clntEmergNumber1 = obj.emerg_contact2;
        }
        if(obj.zipcode != undefined){
          this.clntZipcode= encrypt_decript.Decript(obj.zipcode);
        }
        if(obj.address1 != undefined){
          this.clntAddress1 = encrypt_decript.Decript(obj.address1);
        }
        if(obj.location != undefined){
          this.clntLocation = obj.location_desc;
          this.clntLocationId = obj.location;
          // this.getCities(this.clntLocationId,0);
          this.clntCity = obj.city_desc;
          this.clntCityId = obj.city;
          
          this.clntState = obj.state_desc;
          this.clntStateId = obj.state;

          this.clntCountry = obj.country_desc;
          this.clntCountryId = obj.country;
        }   
        if(obj.address2 != undefined){
          if(encrypt_decript.Decript(obj.address2) && encrypt_decript.Decript(obj.address2) !== "null"){
            this.address = this.clntAddress1+","+encrypt_decript.Decript(obj.address2)+","+this.clntLocation+","+this.clntCity+"-"+this.clntZipcode+","+this.clntState+this.clntCountry;
          }else{
            this.address = this.clntAddress1+","+this.clntLocation+","+this.clntCity+"-"+this.clntZipcode+","+this.clntState+this.clntCountry;
         }
        
        }else{
          this.address = this.clntAddress1+","+this.clntLocation+","+this.clntCity+"-"+this.clntZipcode+","+this.clntState+this.clntCountry;

        }
      
       
        if(obj.profile_image != ""){
         this.idcardprof=ipaddress.Ip_with_img_address+obj.profile_image
        }
      
         
      }
      setTimeout(() => {
        this.printIdCard(id)
      
      }, 1000);
    });
   

}



  changeGender(e) {
    if (this.clntAge != undefined && this.clntAge != null && this.clntAge != "") {
      if (e == "Male") {
        if (this.clntAge <= 15)
          this.clntSal = "6";
        else
          this.clntSal = "1";
      } else if (e == "Female") {
        if (this.clntAge <= 15)
          this.clntSal = "7";
        else
          this.clntSal = "2";
      } else if (e == "Transgender") {
        this.clntSal = "5";
      } else {
        this.clntSal = "";
      }

    } else {
      if (e == "Male") {
        this.clntSal = "1";
      } else if (e == "Female") {
        this.clntSal = "2";
      } else if (e == "Transgender") {
        this.clntSal = "5";
      } else {
        this.clntSal = "";
      }
    }
  }

  onRowClick(patient: any): void {
 
    patient.isSelected = !patient.isSelected;
  }


  searchType(data) {
    this.searchby = data;
    if (data == "name") {
      this.typeflag = "name";
      this.patientName = "";
      this.searchtext = "search by name";
      this.getPatientListByName(this.patientName);

    } else if (data == "patientid") {
      this.typeflag = "patientid";
      this.patientName = "";
      this.searchtext = "search by patient id";
      this.patientid = true;
      this.getPatientListByName(this.patientName);

    } else {
      this.typeflag = "mobile";
      this.patientName = "";
      this.searchtext = "search by mobile";
      this.getPatientListByName(this.patientName);
    }
  }

  focusInput() {
    this.patientName = "";
    document.getElementById("filterInput").focus();
    this.input.nativeElement.focus();
  }

  getPatientListByName(name) {
    console.log("name length --"+name.length)
    this.patientName=  name.toLocaleUpperCase()
    if(this.userInfo.user_type == "doctor"){
      this.usertype="doctor";
      this.hospital_clinic_id = this.hospitalinfoarray[0].hptl_clinic_id;

    } else if (this.userInfo.user_type == "pharmacy") {
      this.usertype="pharmacy";
      if(this.userInfo.hosp_id != undefined){
        this.hospital_clinic_id = this.userInfo.hosp_id;
      } else {
        this.hospital_clinic_id = this.hospitalinfoarray[0].hptl_clinic_id;
      }

    } else if (this.userInfo.user_type == "diagnosis") {
      this.usertype="diagnosis";
      if(this.hospitalinfoarray[0].hosp_id != undefined){
        this.hospital_clinic_id = this.hospitalinfoarray[0].hosp_id;
      } else {
        this.hospital_clinic_id = this.hospitalinfoarray[0].hptl_clinic_id;
      }


    } 
    else if (this.userInfo.user_type == "Billing") {
      this.usertype="Billing";
      if(this.userInfo.hptl_clinic_id != undefined){
        this.hospital_clinic_id = this.userInfo.hptl_clinic_id;
      } else {
        this.hospital_clinic_id = this.hospitalinfoarray[0].hptl_clinic_id;
      }

    }
    else{
      this.usertype="nurse";
      this.hospital_clinic_id = this.hospitalinfoarray[0].hptl_clinic_id;
    }
    var send_data;
    if (this.typeflag == "patientid" && name.length > 8) {
      send_data = {
        hptl_clinic_id: this.hospital_clinic_id,
        type: "hospital",
        name: this.patientName,
        filter: this.typeflag,
      }
      this.getPatients(send_data);

    } else if (name.length > 2 && this.searchby != "patientid") {
      send_data = {
        hptl_clinic_id: this.hospital_clinic_id,
        type: "hospital",
        name: this.patientName,
        filter: this.typeflag,
      }
      this.getPatients(send_data);
    } else {
      this.patientList = Helper_Class.getPatientList();
      this.filteredArr = Helper_Class.getPatientList();
    }
  }

  getPatients(senddata){
    console.log("verify this " + JSON.stringify(senddata))

    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.https.post(ipaddress.getIp.toString() + 'gen/gpbht', senddata,
      { headers: headers })
      .subscribe(
        response => {
          this.patientList = [];
          this.filteredArr = [];
          var obj = JSON.parse(JSON.stringify(response));
          console.log("verify this " + JSON.stringify(obj))
          var flag = this.isEmptyObject(obj);
         if(obj.clients.length == 0){
          this.nodata =true
         }else{
          if (obj.clients != null) {   
            this.nodata =false;       
            for (var i = 0; i < obj.clients.length; i++) {
              var name;
              if (obj.clients[i].middle_name != undefined) {
                name = encrypt_decript.Decript(obj.clients[i].first_name) + " " + encrypt_decript.Decript(obj.clients[i].middle_name) + " " + encrypt_decript.Decript(obj.clients[i].last_name)
              } else {
                name = encrypt_decript.Decript(obj.clients[i].first_name) + " " + encrypt_decript.Decript(obj.clients[i].last_name);
              }

              this.patientList.push({
                client_reg_id: obj.clients[i].client_reg_id,
                patient_id: obj.clients[i].patient_id,
                relation_id: obj.clients[i].relation_id,
                sub_rel_id: obj.clients[i].sub_rel_id,
                first_name: encrypt_decript.Decript(obj.clients[i].first_name),
                full_name: name,
                mobile: encrypt_decript.Decript(obj.clients[i].mobile),
               // idcardprof:ipaddress.Ip_with_img_address+profile_image
              });

              this.filteredArr.push({
                client_reg_id: obj.clients[i].client_reg_id,
                patient_id: obj.clients[i].patient_id,
                relation_id: obj.clients[i].relation_id,
                sub_rel_id: obj.clients[i].sub_rel_id,
                first_name: encrypt_decript.Decript(obj.clients[i].first_name),
                full_name: name,
                mobile: encrypt_decript.Decript(obj.clients[i].mobile),
              });
            }
          }
         }
        
          console.log("length -- " + JSON.stringify(this.patientList.length))

        },
        error => { });
  }


  printIdCard(value) {
    if(value != "id"){
      let printContents, popupWin;
      // printContents = document.getElementById('idprint').innerHTML;
      printContents = this.idprint.nativeElement.innerHTML;
  
      popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
      popupWin.document.open();
      popupWin.document.write(`
          <head>
            <title>Bill</title>
            <link rel="stylesheet" media="screen" href="">
            <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
          <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
            <style>
            @page { size: auto;  margin: 0mm; }
            .table_class {
              background-color: rgb(108, 180, 248);
            }
            
            </style>
          </head>
              <body onload="window.print();window.onfocus=function(){ window.close();}">${printContents}</body>
            </html>`
      );
      popupWin.document.close();
      this.idprnt=false;
    }else{
      // var printData={
      //   hospital_name:this.hospital_name,
      //   name:this.clntSaldesc+"."+this.clientname,
      //   clntAge:this.clntAge+"/"+this.clntGendervalue,       
      //   mrNumber:this.mrNumber
      // }
      // testPrintfrontdesk(this.device,printData)
     
      let printContents, popupWin;
      printContents = this.idprintnew.nativeElement.innerHTML;

      popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
      popupWin.document.open();
      popupWin.document.write(`
          <head>
            <title>Bill</title>
            <link rel="stylesheet" media="screen" href="">
            <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
          <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
            <style>
            @page { size: auto;  margin: 0mm; }
            .table_class {
              background-color: rgb(108, 180, 248);
            }
            
          
            </style>
          </head>
              <body onload="window.print();window.onfocus=function(){ window.close();}">${printContents}</body>
            </html>`
      );
      popupWin.document.close();
      this.idprnt=false;
    }
   
 
  
  }

  @HostListener('scroll', ['$event'])
  onScroll(event: Event): void {
    console.log("scroll called")
    const element = this.scrollContainer.nativeElement;
    var stop = element.scrollTop;
    var oset = element.offsetHeight;
    var cval = stop + oset + 2;

    if (cval >= element.scrollHeight) {
      this.currentLimit += 200;
      this.getPatientList();
      console.log("reached end of scroll ", this.currentLimit)
    }
  }
//profile_image,is_prof_base64,id_proof,is_id_base64
//   patientid_card1() {
//   requestDevice_frontdesk('')
// }

}

