import { Component, OnInit, Input, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Helper_Class } from '../../helper_class';
import { Message_data } from '../../../assets/js/Message_data';
import { encrypt_decript } from 'src/assets/js/Encription_Decription';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ipaddress } from '../../ipaddress'; 
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
import { Time_Formate, Time_Formate_Session } from 'src/assets/js/common';

@Component({
  selector: 'app-next-visit-report',
  templateUrl: './next-visit-report.component.html',
  styleUrls: ['./next-visit-report.component.scss']
})

export class NextVisitReportComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  public currentDate: any;
  public fromDate: any;
  public toDate: any;
  public nextVisitList: any = [];
  public userInfo;
  public currentTime: any;
  public current_time: any;
  timeSplit: any;

  constructor(public toastr: ToastrService,public http: HttpClient, public frontdeskservice: MenuViewService,public cdr: ChangeDetectorRef) {     
  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      "lengthMenu": [[25, 50, -1], [25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [1] },
      ],
      "language": {
        "search": "<img src='../../../assets/ui_icons/Bill_list/Filter_Icon.svg' width='18px'/>",
        searchPlaceholder: "Filter by patient id  or patient name"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"l><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    this.userInfo = Helper_Class.getInfo();
    this.getCurrentDate();
    this.getNextvisitDetails();
  }

  getCurrentDate() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate',
      { country: ipaddress.country_code },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log(obj);
          this.currentDate = obj.current_date;
          this.current_time = obj.current_time;
          this.fromDate = obj.current_date;
          this.toDate = obj.current_date;
          console.log(this.fromDate,"----",this.toDate);
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  getNextvisitDetails() {
    this.nextVisitList = [];
    var headers = new HttpHeaders;
    headers.append('Content-Type','application/json');
    this.http.post(ipaddress.getIp.toString() + 'misr/gafc',
      { hptl_clinic_id: this.userInfo.hptl_clinic_id },
      { headers : headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log(obj);
          if (obj.followups != null && obj.followups.length != 0) {
            for (var i = 0; i < obj.followups.length; i++) {
              var clnt_nmae;
              if (obj.followups[i].middle_name != undefined && obj.followups[i].middle_name != null) {
                clnt_nmae = encrypt_decript.Decript(obj.followups[i].first_name) + " " + encrypt_decript.Decript(obj.followups[i].middle_name) + " " + encrypt_decript.Decript(obj.followups[i].last_name);
              } else {
                if (obj.followups[i].last_name != undefined && obj.followups[i].last_name != null) {
                  clnt_nmae = encrypt_decript.Decript(obj.followups[i].first_name) + " " + encrypt_decript.Decript(obj.followups[i].last_name);
                } else {
                  clnt_nmae = encrypt_decript.Decript(obj.followups[i].first_name);
                }
              }
              
              var last_visit_date = obj.followups[i].last_visit_date.split('-');
              var last_visit_date_value;
              if ( last_visit_date[2] != undefined && last_visit_date[1] != undefined && last_visit_date[0] != undefined) {
                last_visit_date_value = last_visit_date[2] + "-" + last_visit_date[1] + "-" + last_visit_date[0];
              } else {
                last_visit_date_value = "No visited";
              }
              var next_visit_date = obj.followups[i].next_visit_date.split('-');
              var next_visit_date_value = next_visit_date[2] + "-" + next_visit_date[1] + "-" + next_visit_date[0];
       
              var mr_no = '';
              mr_no = obj.followups[i].mr_no != undefined ? obj.followups[i].mr_no : '';
      
              var ageval,genderval;
              if(obj.followups[i].age != undefined){
                ageval = obj.followups[i].age;
              } else {
                ageval = "";
              }
              if(obj.followups[i].gender != undefined){
                genderval = obj.followups[i].gender;
              } else {
                genderval = "";
              } 
             
              if (genderval != null && genderval != undefined && genderval != '') {
                genderval = encrypt_decript.Decript(genderval);
                genderval = genderval[0];
                if (genderval != undefined && genderval[0] == 'T') {
                  genderval = 'Tgen';
                }
              }  

              var mobile_no = obj.followups[i].mobile != undefined ? encrypt_decript.Decript(obj.followups[i].mobile) : "";

              var session = obj.followups[i].session != undefined ? encrypt_decript.Decript(obj.followups[i].session) : '';           

              if (this.current_time != null) {
                this.currentTime = Time_Formate_Session(this.current_time, encrypt_decript.Decript(obj.followups[i].session));
              }
              var Status_txt = "";
              console.log("Current_Date => ",this.currentDate," Next_Visit_Date => ",obj.followups[i].next_visit_date);
              if (this.currentDate == obj.followups[i].next_visit_date) {
                this.timeSplit = this.currentTime.split(':');
                console.log(encrypt_decript.Decript(obj.followups[i].session).toString().toLocaleUpperCase().trim());
                if (obj.followups[i].status == "1") {
                  if (encrypt_decript.Decript(obj.followups[i].session).toString().toLocaleUpperCase().trim() == "MORNING") {
                    if (parseInt(this.timeSplit[0]) < 16) {
                      Status_txt = "Open";
                    } else {
                      Status_txt = "Not visited";
                    }
                  } else if (encrypt_decript.Decript(obj.followups[i].session).toString().toLocaleUpperCase().trim() == "AFTERNOON") {
                    Status_txt = "Open";
                  } else if (encrypt_decript.Decript(obj.followups[i].session).toString().toLocaleUpperCase().trim() == "EVENING") {
                    Status_txt = "Open";
                  } else {
                    Status_txt = "Open";
                  }
                } else {
                    if (obj.followups[i].status == "0") {
                      Status_txt = "Completed";
                    } 
                    if (obj.followups[i].status == "2") {
                      Status_txt = "Confirmed";
                    } 
                }
              } else {
                if (new Date(this.currentDate) < new Date(obj.followups[i].next_visit_date) && obj.followups[i].status == "1") {
                  Status_txt = "Open";
                }
                if (new Date(this.currentDate) > new Date(obj.followups[i].next_visit_date) && obj.followups[i].status == "1") {
                  Status_txt = "Not visited";
                }
                if (new Date(this.currentDate) < new Date(obj.followups[i].next_visit_date) && obj.followups[i].status == "2") {
                  Status_txt = "Confirmed";
                }
                if (new Date(this.currentDate) > new Date(obj.followups[i].next_visit_date) && obj.followups[i].status == "2" ) {
                  Status_txt = "Not visited";
                }
              }
             
              if (encrypt_decript.Decript(obj.followups[i].session).toString() == "") {
                Status_txt = "Not visited";
              }
                     
              this.nextVisitList.push({
                mr_no: mr_no,
                client_name: clnt_nmae,
                age_gender: ageval + " / " + genderval,
                mobile: mobile_no,
                last_visit_date: last_visit_date_value,
                next_visit_date: next_visit_date_value,        
                session: session,
                statustxt: Status_txt
              });
            }
          }
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        }) 
  }

  selectDate() {
  }

}
