import { Component, OnInit,ChangeDetectorRef,ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { ipaddress } from 'src/app/ipaddress';
import { Http, Headers } from '@angular/http';
import { CommonDataService } from '../../providers/common-data.service';
import { Message_data } from 'src/assets/js/Message_data';
import { Date_Formate, Time_Formate, Time_Formate_Session } from '../../../assets/js/common';
import { Helper_Class } from '../../helper_class';
import { encrypt_decript } from 'src/assets/js/Encription_Decription';
import { Doc_Helper } from '../../Doctor_module/Doc_Helper';

@Component({
  selector: 'app-logreport',
  templateUrl: './logreport.component.html',
  styleUrls: ['./logreport.component.scss']
})
export class LogreportComponent implements OnInit {
  @ViewChild('printlogrep') printlogrep: ElementRef;
  @ViewChild('printimage') printimage: ElementRef;
  @ViewChild('hospitalwithnamelogo') hospitalwithnamelogo: ElementRef;
  public noData: boolean;
  public hasData: boolean;
  tmr = new Date();
  public fromDate;
  public toDate;
  public currentDate;
  public header = [];
  public ishide: boolean = false;
  public logdata:any=[];
  public columns = {
    sno:true,
    Bill_no: true,
    Bill_date: true,
    Name: true,
    bill_amount: true,
    Modified_amount: true,
    Created_by: true,
    Created_date: true,
    Modified_by: true,
    Modified_date: true,
  };
  public pharmacyid;
  public userInfo;
  selectedDuration: string = 'select';
  public forcoverdiv: boolean = true;
  public backbutton: boolean = false;
  public selected_filter: any = [];
  public print_template;
  public hospital_logo;
  public hosp_name;
  public hosp_addr;
  public hosp_csz;
  public hosp_tel;
  public loader;

  constructor(public gservice: CommonDataService,public http: Http, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService) {
    this.selected_filter=[
      'sno',
      'Bill_no',
      'Bill_date',
      'Name',
      'bill_amount',
      'Modified_amount',
      'Created_by',
      'Created_date',
      'Modified_by',
      'Modified_date',
    ]
   }

  ngOnInit(): void {


    this.hospital_logo = ipaddress.Ip_with_img_address + Helper_Class.getInfo().bill_print_logo;
    this.print_template = Helper_Class.getInfo().bill_print_template;
    this.header = ['S no', 'Bill no','Name','bill amount','Modified amount','Created by', 'Created date','Created time' ,'Modified by','Modified date','Modified time'];
    this.userInfo = Helper_Class.getInfo();
    this.pharmacyid=this.userInfo.hospitals[0].hptl_clinic_id;
    this.getDate();
    this.getRetrievalData();
  }

  getDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate', { country: ipaddress.country_code },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          console.log(" get_obj = "+JSON.stringify(obj))
          this.tmr = new Date(obj.current_date);
          this.tmr.setDate(this.tmr.getDate());
          this.currentDate = this.getDateMatePicker1(this.tmr);
          this.fromDate = obj.current_date;
          this.toDate = obj.current_date;
          this.get_log_data()
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  getDateMatePicker1(e) {
    var month = ((e.getMonth() + 1) < 10 ? '0' : '') + (e.getMonth() + 1);
    var day = (e.getDate() < 10 ? '0' : '') + e.getDate();
    var apt = e.getFullYear() + "-" + month + "-" + day;
    return apt.trim();
  }

  get_log_data(){
    var send_data,patientname,crt_date,crt_time,update,update_by,createdby,uptime,updbill,prevbill;
    send_data={
      hptl_clinic_id:this.pharmacyid,
      from_date:this.fromDate,
      to_date:this.toDate

    }
    console.log(send_data)
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "misr/gbal", send_data,
      { headers: headers }).subscribe(
        data => {
          var dataval = data.json();
          var obj=dataval.bill_log;
          this.logdata=[];
          console.log("dataval = ", JSON.stringify(dataval))
          if(dataval.length != 0 && dataval != undefined ){
            if(dataval.length !=0){
              this.hasData = false;
              }
            if(obj.length != 0  && obj != undefined){
              console.log("log_obj = ", JSON.stringify(obj))
              for(var i=0;i<obj.length;i++){
                if(obj[i].first_name != undefined){
                  if (obj[i].middle_name != undefined) {
                    patientname = encrypt_decript.Decript(obj[i].first_name) + " " + encrypt_decript.Decript(obj[i].middle_name) + " " + encrypt_decript.Decript(obj[i].last_name);
                  } else {
                    patientname = encrypt_decript.Decript(obj[i].first_name) + " " + encrypt_decript.Decript(obj[i].last_name);
                  }
                }else{
                  patientname=''
                }
    
                if(obj[i].cfname != undefined){
                  if (obj[i].cmname != undefined) {
                    createdby = obj[i].cfname + " " + obj[i].cmname + " " + obj[i].clname;
                  } else {
                    createdby = obj[i].cfname + " " + obj[i].clname;
                  }
                }else{
                  createdby =''
                }
    
                if(obj[i].ufname != undefined){
                  if (obj[i].umname != undefined) {
                    update_by = obj[i].ufname + " " + obj[i].umname + " " + obj[i].ulname;
                  } else {
                    update_by = obj[i].ufname + " " + obj[i].ulname;
                  }
                }else{
                  update_by=''
                }
    
                if(obj[i].created_date != undefined){
                  crt_date=Date_Formate(obj[i].created_date)
                }else{
                  crt_date=''
                }
                if(obj[i].created_time != undefined){
                  crt_time=Time_Formate(obj[i].created_time)
                }else{
                  crt_time=''
                }
                if(obj[i].updated_date != undefined){
                  update=Date_Formate(obj[i].updated_date)
                }else{
                  update=''
                }
                if(obj[i].updated_time != undefined){
                  uptime=Time_Formate(obj[i].updated_time)
                }else{
                  uptime=''
                }
                
                if(obj[i].bill_amount != undefined){
                  prevbill=obj[i].bill_amount
                  
                }else{
                  prevbill=''
                }

                if(obj[i].prev_bill_amount != undefined){
                  updbill=obj[i].prev_bill_amount
                
                }else{
                  updbill=''
                }
    
                // 'S no', 'Bill no', 'Name','bill amount','Modified amount','Created by','Created time','Modified by','Modified date'
  
                this.logdata.push({
                  sno:i+1,
                  billing_id:obj[i].billing_id,
                  patient_name:patientname,
                  prev_bill_amount:prevbill,
                  upd_bill_amount:updbill,
                  created_by:createdby,
                created_date:crt_date,
                created_time:crt_time,
                updated_by:update_by,
                updated_date:update,
                updated_time:	uptime,
                })
                this.forcoverdiv = false;
                this.backbutton = true;
                this.ishide = true;
              }
            }else{
              this.logdata = [];
              this.noData = false;
              this.hasData = true;
            }
          }else{
            this.logdata = [];
              this.noData = false;
              this.hasData = true;
          }


    
                 }, error => { });
  }

  dateSelect(data, value) {
    if (data != null) {
      if (value == "1")
        this.fromDate = data;
      else
        this.toDate = data;
    }
  }


  updateFromDate() {
    const today = new Date();
    const selectedDuration = parseInt(this.selectedDuration);

    if (!isNaN(selectedDuration)) {
      const fromDate = new Date(today);
      fromDate.setDate(today.getDate() - selectedDuration);
      this.fromDate = fromDate.toISOString().split('T')[0];
      this.toDate = today.toISOString().split('T')[0];
    }
    this.get_log_data()
  }



  print_area() {
    let printContents, popupWin, totalamt, cashamt, cardamt, insureamt, upiamt,logo,hospitaldetails;

     printContents = this.printlogrep.nativeElement.innerHTML; 
     
    logo = document.getElementById('printimage').innerHTML;
    hospitaldetails = this.hospitalwithnamelogo.nativeElement.innerHTML;
    // totalamt = this.totalamount.nativeElement.innerHTML;
    // cashamt = this.cashamount.nativeElement.innerHTML
    // cardamt = this.cardamount.nativeElement.innerHTML;
    // insureamt = this.insureamount.nativeElement.innerHTML;
    // upiamt = this.upiamount.nativeElement.innerHTML;

    if(this.print_template != undefined && this.print_template == "banner"){

    }
    else if(this.print_template != undefined && this.print_template == "logowithname"){
      popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
      popupWin.document.open();


      popupWin.document.write(`
  
      <head>
    <title>Reports</title>
    
  <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
  <style>
  @page { size: landscape || potrait; } 
  @media print and (orientation: landscape) {
      .table {
        width: 100%;
      }
    }
  
    /* Portrait Styles */
    @media print and (orientation: portrait) {
      .table {
        width: auto;
      }
    }
  th{
      padding:0%;
    }
    .table{
      padding:5% !important;
      border: 1px solid #c7c7e9bf !important;
      border-radius: 10px !important;
      box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px !important;
      font-family:Segoe UI;
      font-size:12px; 
    }
   .img{
    vertical-align: middle;
    width:750;
  }
    </style>
  </head>
      <body onload="window.print();window.onfocus=function(){ window.close();}">
      <table style="border:'0';width:100%">  
      <thead>
      <tr>
      ${logo}
      </tr>
      <div>
      ${hospitaldetails}
      </div>
      </thead>             
         <tfoot> 
          <tr>  

           <td width="100%">  
            <table style="border:'0';width:100%">  
              <tr>  
                <td style="width:100%;">&nbsp;</td>  
             </tr>  
           </table>  
         </tfoot> 

         <tbody>  
           <tr>  
             <td width="100%"> 
           <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' id="table">
       
       
           ${printContents}
       
                   </table>
         </td>  
          </tr> 
        </tbody>  
      </table>
   </body>
    </html>
   

    `);

      popupWin.document.close();
    }
    else
    {
      popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
      popupWin.document.open();
      popupWin.document.write(`
      <head>
        <title>Reports</title>
        
  <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
  <style>
        @page { size: auto;  margin: 0mm 10mm 10mm 10mm; }
       
        th{
          padding:0%;
        }
        .tbl{
          padding:5% !important;
          border: 1px solid #c7c7e9bf !important;
          border-radius: 10px !important;
          box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px !important;
        }
        body{
          padding-top:10%;
        }
        </style>
      </head>
          <body onload="window.print();window.onfocus=function(){ window.close();}">
          <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' >
          ${printContents}
         </table>
        
          </body>
        </html>`
      );
      popupWin.document.close();
    }
  }




  setTable(data) {
    console.log(data)

    this.columns = {
      sno:false,
      Bill_no: false,
      Bill_date: false,
      Name: false,
      bill_amount: false,
      Modified_amount: false,
      Created_by: false,
      Created_date: false,
      Modified_by: false,
      Modified_date: false,
     
    };

    // if (data.includes("doctor")) {
    //   this.colspann = 4;
    // } else {
    //   this.colspann = 3;
    // }

    for (var i = 0; i < data.length; i++) {
      this.columns[data[i]] = !this.columns[data[i]];
    }
  }

  todisplaycoverdiv() {
    this.forcoverdiv = true;
    
    this.backbutton = false;
    this.ishide = false;
    this.logdata = [];
    this.hasData = true;
  }



  getExcelData(type) {
    if (this.logdata.length != 0) {
      let workbook = new Workbook();
      let worksheet = workbook.addWorksheet('Log report');
      worksheet.getColumn(1).width = 10;
      worksheet.getColumn(2).width = 30;
      worksheet.getColumn(3).width = 20;
      worksheet.getColumn(4).width = 15;
      worksheet.getColumn(5).width = 20;
      let headerRow = worksheet.addRow(this.header);
      headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '4167B8' },
          bgColor: { argb: '' }
        }
        cell.font = {
          bold: true,
          color: { argb: 'FFFFFF' },
          size: 12
        }
      })
      for (let x1 of this.logdata) {
        let x2 = Object.keys(x1);
        let temp = []
        for (let y of x2) {
          temp.push(x1[y])
        }
        let chilrow = worksheet.addRow(temp)
        let salesamt = chilrow.getCell(5);
        salesamt.alignment = {
          vertical: 'middle',
          horizontal: 'right'
        }
      }

      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, "Log report" + '-' + new Date().valueOf() + '.xlsx');
      });
    } else {
      this.toastr.error(Message_data.exportExcelFailed);
    }
  }


  getRetrievalData() {
    var send = {
      inpatient_id: Doc_Helper.getAdmission_id().admission_id,
    }
    var headers = new Headers();
    console.log("get retrieval data" + send)
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "inpat/gdsv", send,
      { headers: headers })
      .subscribe(
        response => {
          // var values = response.json();
          var values = JSON.parse(JSON.stringify(response));
          console.log("retrive obj" + JSON.stringify(values));
          if (values != undefined || values != null) {

            this.hosp_name = values.hptl_name;

            var addval;
            if (values.address2 != undefined) {
              addval = values.address1 + "," + values.address2;

            } else {
              addval = values.address1;
            }
            this.hosp_addr = addval;
            this.hosp_csz = values.city + ", " + values.state + " - " + values.zipcode + "  " + values.country;

            if (values.telephone != undefined) {
              this.hosp_tel = values.telephone;

            } else {
              this.hosp_tel = "-";
            }


          }
        },
        error => {
          this.loader = true;
          this.toastr.error(Message_data.defaultErr);
        }
      )
  }

}
