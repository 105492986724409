import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Http, Headers } from '@angular/http';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { FrontDesk_Helper } from '../FrontDesk_Helper';
import { MatDialog } from '@angular/material/dialog';
import { BillHistViewComponent } from '../bill-hist-view/bill-hist-view.component';
import { ipaddress } from '../../ipaddress';
import { Helper_Class } from '../../..//app/helper_class';
import { MenuViewService } from '../../../app/common-module/menu-view/menu-view.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Date_Formate, Time_Formate,convertNumberToWords } from '../../../assets/js/common';
declare var $:any;

@Component({
  selector: 'app-inpatient-bill-detailview',
  templateUrl: './inpatient-bill-detailview.component.html',
  styleUrls: ['./inpatient-bill-detailview.component.scss']
})
export class InpatientBillDetailviewComponent implements OnInit {
  @ViewChild('billprint') printsection: ElementRef;
  @ViewChild('noheader') noheader: ElementRef;
  @ViewChild('banner') banner: ElementRef;
  @ViewChild('logowithname') logowithname: ElementRef;
  @ViewChild('logowithnamenopayconsqty') logowithnamenopayconsqty: ElementRef;
  @ViewChild('printbanner') printbanner: ElementRef;
  @ViewChild('printnoheader') printnoheader: ElementRef;
 
  public inpatientflag:boolean=false;
  public advancevalue;
  public billId: string;
  public billingPrintView:any = [];
  public payType: string;
  public payFlag: boolean;
  public docRegId: string;
  public billDetailedView;
  public cgstFlag;
  public sgstFlag;
  public billingDetailedViewData;
  public billingPrintViewFlag: boolean;
  public totalFlag: boolean;
  public advanceFlag: boolean;
  public paidFlag: boolean;
  public prevBalance: any;
  public prevBalanceFlag: boolean;
  public mainBalanceFlag: boolean;
  public estimatePrintData;
  public treatementPlanFlag: boolean;
  public charge = [];
  public hospAddress: boolean;
  public sendAddress: string;
  public clientAddress: string;
  public billAmount: any;
  public totalBill: string;
  public cgst: any;
  public sgst: any;
  public billingDetailList: any = [];
  public paymentsarray: any=[];
  public clientName: string;
  public docName: string;
  public paid;
  public discount;
  dtOptions: DataTables.Settings = {};
  public hospitalLogo;
  public printtemplate: string;
  public printlogostyle: string;
  public bill_qty_flag: boolean=false;
  public bill_head: string;
  public bill_cons_flag: boolean=false;
  public bill_pay_flag: boolean=false;
  public paytypreq: boolean=false;
  public finyear: string;
  public headerstyle;
  public ageval: string;
  public genderval: string;
  public credit_type: string;
  public credit_person:string;
  public numToWords: any;
  public print_size:any = null;
  public Moduleidlist = [];
  public editbutton: boolean = false;
  public newbutton: boolean = false;
  public deletebutton: boolean = false;
  public printbutton: boolean = false;
  public viewbutton: boolean = false;
  public totalBillAmount: any;
  public discount_amount:any;
  public concession_txt: any; 
  public profile_image: string;
  public clientAddress1: string;
  public clientAddress2: string;
  public billingFlow: any;
  public userInfo;
  public logUserName;
  public client_name: any;
  public age: string;
  public gender: any;
  public dr_name: any;
  public created_date: string;
  public caddress1: string;
  public caddress2: string;
  public clocation: string;
  public clientcity: string;
  public clestare: string;
  public uhid: any;
  public discount_flag:boolean = false;
  public inpatient_flag:boolean = false;
  public barcode_flag:boolean = false;
  public barcode_url;
  public surgery;
  public barcode_style: string;
  public nobanner_style;
  public multiply_flag: boolean = true;
  public amount_td_style;
  public p_tag_style: any;
  public grouping_flag: boolean = false;
  displayValue = true;
  lineColor = '#000000';
  elementType: 'svg';
  format:
    | ''
    | 'CODE128'
    | 'CODE128A'
    | 'CODE128B'
    | 'CODE128C'
    | 'EAN'
    | 'UPC'
    | 'EAN8'
    | 'EAN5'
    | 'EAN2'
    | 'CODE39'
    | 'ITF14'
    | 'MSI'
    | 'MSI10'
    | 'MSI11'
    | 'MSI1010'
    | 'MSI1110'
    | 'pharmacode'
    | 'codabar';
  width = 1.0;
  barcodeheight = 23;
  fontOptions = '';
  font = 'monospace';
  textAlign = 'center';
  textPosition = 'bottom';
  textMargin = 2;
  fontSize = 15;
  background = '#ffffff'; 
  categoryChargeList: any;
  inpatient: any;
  sponsor_due: any;
  public balance;
  public bill_type_pharma;
  public refund;

  constructor(public sanitize:DomSanitizer,public toastr: ToastrService, public http: Http,public service: MenuViewService,public dialog:MatDialog,public adminservice:MenuViewService) {
    this.payFlag = true;
    this.billDetailedView = FrontDesk_Helper.getreceptbilldetailview();
    console.log("BillDetailedView",this.billDetailedView);
    this.billId = this.billDetailedView.bill_view_id;
    this.docRegId = this.billDetailedView.doc_reg_id;
    this.finyear = this.billDetailedView.fin_year;
    this.inpatient = this.billDetailedView.inpatient;
    this.advanceFlag = true;
    this.discount_flag = false;
  }

  ngOnInit(): void {
    this.profile_image = "../../assets/img/default.jpg";
    this.Moduleidlist = Helper_Class.getmodulelist();
    $(document).ready(function () {
      $("input").attr("autocomplete", "off");
    });
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 10,
      "lengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [0, 1, 2] },
      ],
      "language": {
        "search": "Filter"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"l><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
   
    if (this.Moduleidlist != undefined && this.Moduleidlist.length != 0) {
      for (var i = 0; i < this.Moduleidlist.length; i++) {
        if (this.Moduleidlist[i].module_id == "7") {
          if (this.Moduleidlist[i].edit == "1") {
            this.editbutton = true;
          }                    
          if (this.Moduleidlist[i].create == "1") {
            this.newbutton = true;
          }
          if (this.Moduleidlist[i].delete == "1") {
            this.deletebutton = true;
          }
          if (this.Moduleidlist[i].print == "1") {
            this.printbutton = true;
          }
          if (this.Moduleidlist[i].view == "1") {
            this.viewbutton = true;
          }
        }
      }
    }

    if (Helper_Class.getInfo().user_type == 'Admin') {
      this.editbutton = true;
      this.newbutton = true;
      this.deletebutton = true;
      this.printbutton = true;
      this.viewbutton = true;
    }

    this.discount_amount = 0;
    this.sponsor_due = 0;
    this.balance = 0;
    this.refund = 0;
    this.bill_type_pharma = '';
    this.hospAddress = true;
    this.billingPrintViewFlag = true;
    this.treatementPlanFlag = true;
    this.cgstFlag = true;
    this.sgstFlag = true;
    this.prevBalanceFlag = true;
   
    if( Helper_Class.getLoginType() == "doctor" ){   
      this.hospitalLogo = ipaddress.Ip_with_img_address + Helper_Class.getInfo().hospitals[0].bill_print_logo;
      this.printtemplate = Helper_Class.getInfo().hospitals[0].bill_print_template;   
      this.printlogostyle = Helper_Class.getInfo().hospitals[0].bill_print_logo_style;
      this.print_size = Helper_Class.getInfo().hospitals[0].bill_print_paper;
      
    } else{
      this.hospitalLogo = ipaddress.Ip_with_img_address + Helper_Class.getInfo().bill_print_logo;
      this.printtemplate = Helper_Class.getInfo().bill_print_template;   
      this.printlogostyle = Helper_Class.getInfo().bill_print_logo_style;
      this.print_size = Helper_Class.getInfo().bill_print_paper;
    } 

    this.userInfo = Helper_Class.getInfo();
    console.log(this.userInfo);
    if (this.userInfo.first_name != undefined) {
      this.logUserName = this.userInfo.first_name;
      if (this.userInfo.last_name != undefined) {
        this.logUserName = this.userInfo.first_name+" "+this.userInfo.last_name;
      }
    }

    if (this.userInfo.hptl_name == 'SGH') {
      this.p_tag_style = "text-align: center;font-size: 16px;margin: 0px;margin-right: 10px !important;margin-bottom: 3px !important;margin-top: 10px;";
    } else {
      this.p_tag_style = "text-align: center;font-size: 16px;margin: 0px;margin-right: 10px !important;margin-bottom: 3px !important;";
    }
   
    this.headerstyle = this.sanitize.bypassSecurityTrustHtml(Helper_Class.getInfo().bill_print_heading);   
    if(this.headerstyle == undefined || this.headerstyle == 'undefined'){
      this.headerstyle="";
    }

    if(Helper_Class.getInfo().bill_qty_flag == 0){
      this.bill_qty_flag = true;
    } else {
      this.bill_qty_flag = false;
    }    
    
    if(Helper_Class.getInfo().bill_cons_flag ==0){
      this.bill_cons_flag = true;
    } else {
      this.bill_cons_flag = false;
    }

    if(Helper_Class.getInfo().bill_pay_flag ==0){
      this.bill_pay_flag = true;
    } else {
      this.bill_pay_flag = false;
    }
    
    if(Helper_Class.getInfo().bill_pay_type_req =="1"){
      this.paytypreq=false;
    } else {
      this.paytypreq=true;
    }

    this.bill_head = Helper_Class.getInfo().bill_heading;
    this.grouping_flag = false;
    this.getBillDetailedView();
  }

  getBillDetailedView() {
    this.billingFlow = Helper_Class.getBillingFlow();
    console.log(this.billingFlow);
    if (this.billingFlow != undefined) {
      var type = this.billingFlow.flow == 'patient'  ? "doctor" : this.billingFlow.flow == 'All'  ? "all" : this.billingFlow.flow == 'package' ? 'package' : "dietician";
    } else {
      type = 'doctor';
    } 
   
    type = FrontDesk_Helper.getreceptbilldetailview().billtype;

    if (type == "hospital") {
      type ="All"
    }

    var sendData,sendUrl;
    // alert(this.inpatient);
    if (type == 'All' && this.inpatient == 'yes') {
      sendUrl = 'bill/gdobi';
      sendData = JSON.stringify({
        billing_id: this.billId,
        fin_year: this.finyear,
        center_id: this.userInfo.hptl_clinic_id
      })
    } else {
      sendUrl = 'bill/getbill/';
      if (type == 'package') {
        sendData = JSON.stringify({
          billing_id: this.billId,
          spl_name: "dental",
          app_type: type,
          fin_year:  this.finyear, 
        });
  
      } else {
        var docid,center_id;
        if(type =="diagnosis"){
          docid = this.userInfo.diag_center_id;
          center_id = this.userInfo.diag_center_id;
        } else {
          docid = this.docRegId
          center_id = this.userInfo.hptl_clinic_id;
        }
        sendData = JSON.stringify({
          center_id: center_id,
          doc_reg_id: docid,
          billing_id: this.billId,
          spl_name: "dental",
          app_type: type,
          fin_year:  this.finyear,
        });
      }
    }
    console.log(sendData + " SendUrl " + sendUrl);
    var headers = new Headers();   
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + sendUrl ,sendData,  
      { headers: headers })
      .subscribe(
        response => {
          var obj = response.json()
          console.log("Bill details "+ JSON.stringify(obj));
          if (obj.bills != null) {
            if (obj.cgst != null) {
              this.cgstFlag = false;
              this.sgstFlag = false;
            }

            if (obj.rate_card_desc == 'Service wise') {                  
              this.discount_flag = false;                 
            } else {
              this.discount_flag = true;
            }
           
            if (obj.inp_hosp_id != undefined && obj.inp_hosp_id != null && obj.inp_hosp_id != "") {
              this.inpatient_flag = true;
            } else {
              this.inpatient_flag = false;
            }
            
            if (this.bill_qty_flag == true && !this.discount_flag == false && this.inpatient_flag == false) {
              this.multiply_flag = true;
            } else if (this.bill_qty_flag == true && !this.discount_flag == true && this.inpatient_flag == false) {
              this.multiply_flag = true;
            } else if (this.bill_qty_flag == true && !this.discount_flag == false && this.inpatient_flag == true) {
              this.multiply_flag = false;
            } else if (this.bill_qty_flag == true && !this.discount_flag == true && this.inpatient_flag == true) {
              this.multiply_flag = true;
            } else if (this.bill_qty_flag == false && !this.discount_flag == false && this.inpatient_flag == false) {
              this.multiply_flag = false;
            } else if (this.bill_qty_flag == false && !this.discount_flag == true && this.inpatient_flag == false) {
              this.multiply_flag = true;
            } else if (this.bill_qty_flag == false && !this.discount_flag == false && this.inpatient_flag == true) {
              this.multiply_flag = false;
            } else if (this.bill_qty_flag == false && !this.discount_flag == true && this.inpatient_flag == true) {
              this.multiply_flag = true;
            }

            if (this.bill_qty_flag == true && this.discount_flag == true && this.inpatient_flag == false) {
              this.amount_td_style = "text-align: right; border: 1px solid black;border-collapse: collapse;width: 300px";
            } else {
              this.amount_td_style = "text-align: right; border: 1px solid black;border-collapse: collapse;width: 150px;";
            }
            if (obj.inp_hosp_id != undefined) {
              this.inpatientflag = true;
            } else {
              this.inpatientflag = false;          
            }
            this.billingDetailedViewData = obj.bills;
            this.billingPrintViewFlag = this.billingDetailedViewData != null ?  false : true;
            //this.billingPrintViewFlag = this.billingDetailedViewData != null && this.billingDetailedViewData[0].charge_type != null ?  false : true;
            var hospital_charges = [];
            var sno=1;
            for (var i = 0; i < this.billingDetailedViewData.length; i++) {
              if (this.billingDetailedViewData[i].type == 'hospital') {
                var multiply_value;
                multiply_value = (parseFloat(this.billingDetailedViewData[i].amount_txt) * parseFloat(this.billingDetailedViewData[i].quantity));
                var created_date_time =  this.billingDetailedViewData[i].created_date != undefined ? Date_Formate(this.billingDetailedViewData[i].created_date) + " " + Time_Formate(this.billingDetailedViewData[i].created_time) : '';

                if (this.billingDetailedViewData[i].fee == '') {
                  this.billingDetailedViewData[i].fee = 0;
                }
                
                hospital_charges.push({
                  sno:sno,
                  bill_type: this.billingDetailedViewData[i].charge_desc,
                  created_date_time: created_date_time,
                  amount_txt: parseFloat(this.billingDetailedViewData[i].amount_txt),
                  concession: this.billingDetailedViewData[i].concession,
                  fee: parseFloat(this.billingDetailedViewData[i].fee),
                  charge_type: this.billingDetailedViewData[i].charge_id,
                  quantity: parseFloat(this.billingDetailedViewData[i].quantity),
                  multiply_value: parseFloat(multiply_value),
                  category: this.billingDetailedViewData[i].category_desc,
                  order_no: this.billingDetailedViewData[i].order_no,
                  charge_cat_id: this.billingDetailedViewData[i].charge_category_id,
                  billing_type: this.billingDetailedViewData[i].type,
                })
                sno+=1;
              }
            }

            console.log(this.inpatientflag);

            if (hospital_charges.length != 0) {
              this.createGroupping(hospital_charges);
            }

            var diag_charges = [];
            var sno=1;
            for (var i = 0; i < this.billingDetailedViewData.length; i++) {
              if (this.billingDetailedViewData[i].type == 'diagnosis') {
                  var multiply_value;
                  multiply_value = (parseFloat(this.billingDetailedViewData[i].amount_txt) * parseFloat(this.billingDetailedViewData[i].quantity));
                  var created_date_time =  this.billingDetailedViewData[i].created_date != undefined ? Date_Formate(this.billingDetailedViewData[i].created_date) + " " + Time_Formate(this.billingDetailedViewData[i].created_time) : '';

                  if (this.billingDetailedViewData[i].fee == '') {
                    this.billingDetailedViewData[i].fee = 0;
                  }

                  diag_charges.push({
                    sno: sno,
                    bill_type: this.billingDetailedViewData[i].charge_desc,
                    created_date_time: created_date_time,
                    amount_txt: parseFloat(this.billingDetailedViewData[i].amount_txt),
                    concession: this.billingDetailedViewData[i].concession,
                    fee: parseFloat(this.billingDetailedViewData[i].fee),
                    charge_type: this.billingDetailedViewData[i].charge_type,
                    quantity: parseFloat(this.billingDetailedViewData[i].quantity),
                    multiply_value: parseFloat(multiply_value),
                    billing_type: this.billingDetailedViewData[i].type,
                  })
                  sno+=1;
              }
            }

            if (diag_charges.length != 0) {
              if (diag_charges.length != 0) {
                var diag_bill_array = [];
                diag_charges.forEach(diag => {
                  diag_bill_array.push(diag)
                }) 
        
                this.categoryChargeList.push({
                  bill_type: "Pathology & Radiology",
                  charges: diag_bill_array});
              }

              if (this.categoryChargeList.length != 0) {
                var rowid = 1;
                for (var i = 0; i < this.categoryChargeList.length; i++) {
                  for (var j = 0; j < this.categoryChargeList[i].charges.length; j++) {
                    this.categoryChargeList[i].charges[j].sno = rowid;
                    rowid += 1;
                  }
                }
              }
            }
              
            var pharma_charges = [];
            var sno=1;
            for (var i = 0; i < this.billingDetailedViewData.length; i++) {
              if (this.billingDetailedViewData[i].type == 'pharma') {
                var multiply_value;
                multiply_value = (parseFloat(this.billingDetailedViewData[i].amount_txt) * parseFloat(this.billingDetailedViewData[i].quantity));
                var created_date_time =  this.billingDetailedViewData[i].created_date != undefined ? Date_Formate(this.billingDetailedViewData[i].created_date) + " " + Time_Formate(this.billingDetailedViewData[i].created_time) : '';

                if (this.billingDetailedViewData[i].fee == '') {
                  this.billingDetailedViewData[i].fee = 0;
                }

                pharma_charges.push({
                  sno:sno,
                  bill_type: this.billingDetailedViewData[i].charge_desc,
                  created_date_time: created_date_time,
                  amount_txt: Math.round(this.billingDetailedViewData[i].amount_txt),
                  concession: this.billingDetailedViewData[i].concession,
                  fee: Math.round(this.billingDetailedViewData[i].fee),
                  charge_type: this.billingDetailedViewData[i].charge_type,
                  quantity: parseFloat(this.billingDetailedViewData[i].quantity),
                  multiply_value: Math.round(multiply_value),
                  billing_type: this.billingDetailedViewData[i].type,
                })
                sno+=1;
              }
            }

            if (pharma_charges.length != 0) {
              if (pharma_charges.length != 0) {
                var pharma_bill_array = [];
                pharma_charges.forEach(pharma => {
                  pharma_bill_array.push(pharma)
                }) 
        
                this.categoryChargeList.push({
                  bill_type: "Medicines",
                  charges: pharma_bill_array});
          
              }
        
              if (this.categoryChargeList.length != 0) {
                var rowid = 1;
                for (var i = 0; i < this.categoryChargeList.length; i++) {
                  for (var j = 0; j < this.categoryChargeList[i].charges.length; j++) {
                    this.categoryChargeList[i].charges[j].sno = rowid;
                    rowid += 1;
                  }
                }
              }
            }     

            if(obj.profile_image != ""){
              this.profile_image = ipaddress.Ip_with_img_address + obj.profile_image;
            }

            if (obj.bill_amount == undefined && obj.bill_amount == "") {
              this.totalFlag = true;            
            }             

            if (obj.advance == undefined || obj.advance == "" || obj.advance == "0.00" || obj.advance == 0) {
              this.advanceFlag = true;
            } else {
              this.advanceFlag = false;
              this.advancevalue= parseFloat(obj.advance);
            }
             

            if (obj.prev_balance != null && obj.prev_balance != 0) {
              this.prevBalanceFlag = false;
              this.prevBalance = parseFloat(obj.prev_balance);           
            } else {
              this.prevBalanceFlag = true;
            }           

            if (obj.balance == undefined || obj.balance == "")
              this.mainBalanceFlag = true;

            this.estimatePrintData = obj.estimates;
            this.treatementPlanFlag = this.estimatePrintData != "" && this.estimatePrintData != undefined ? false : true;

            this.charge = [];
            this.hospAddress = obj.hptl_name != null ?  false : true;

            if(obj.credit_type != undefined){
              this.credit_type = obj.credit_type;
              this.credit_person = obj.credit_person;
            } else {
              this.credit_type = '';
              this.credit_person = '';
            }
            
            if (obj.created_date != undefined) {
              var get_date = obj.created_date.toString().split('-');
              var datetxt = get_date[2] + "-" + get_date[1] + "-" + get_date[0]+" "+Time_Formate(obj.created_time);
            }
            if(obj.age != undefined){
              this.ageval = obj.age;
            } else {
              this.ageval="";
            }
            if(obj.gender != undefined){
              this.genderval = obj.gender;
            } else {
              this.genderval="";
            } 

            if (obj.first_name != undefined) {
              this.sendAddress = obj.address2 != null && obj.address2 != "" && obj.address2 != undefined ?   obj.address1 + ", " + obj.address2 + "," :  obj.address1 + ",";
              
            if (obj.caddress2 != null && obj.caddress2 != undefined && obj.caddress1 != undefined) {
              this.clientAddress1 = encrypt_decript.Decript(obj.caddress1)+ ",";
              if(obj.caddress2 != "" && obj.caddress2 != "null") {
                if (encrypt_decript.Decript(obj.caddress2) == "") {
                  this.clientAddress2 == "";
                } else {
                  this.clientAddress2 = encrypt_decript.Decript(obj.caddress2)+",";
                }
              }
            } else if (obj.caddress1 != undefined) {
              this.clientAddress1 = encrypt_decript.Decript(obj.caddress1)+",";
            } else if (obj.caddress2 != undefined) {
              this.clientAddress2 = encrypt_decript.Decript(obj.caddress2)+",";
            }          

            var clocation = '',czipcode = '',ccity = '',cstate = '',ccountry = '';
            if (obj.clocation != undefined) {
              clocation = obj.clocation+",";
            }
  
            if (obj.ccity != undefined) {
              ccity = obj.ccity+"-";
            }

            if(obj.czipcode != undefined) {
              czipcode = encrypt_decript.Decript(obj.czipcode)+",";
            }
  
            if (obj.cstate != undefined) {
              cstate = obj.cstate+"-";
            }   
            
            if(obj.ccountry != undefined) {
              ccountry = obj.ccountry+".";
            }

            var tot_amt1:any = 0;
            if (obj.bills != null && obj.bills.length != 0) {
              for (var i = 0; i < obj.bills.length; i++) {
                if (obj.bills[i].amount_txt != null) {
                  tot_amt1 = (parseFloat(tot_amt1) + (parseFloat(obj.bills[i].amount_txt) * parseFloat(obj.bills[i].quantity)));
                  this.discount_amount = (parseFloat(this.discount_amount) + parseFloat(obj.bills[i].amount_txt) * parseFloat(obj.bills[i].quantity) * (parseFloat(obj.bills[i].concession)/ 100));
                }
                if (obj.bills[i].pay_type != null) {
                  this.payType = obj.bills[i].pay_type;
                  this.payFlag = false;
                }
              }
              if (this.discount_amount != '') {          
                var floatValue = this.discount_amount;
                var floatString = floatValue.toString();
                var parts = floatString.split('.');
                var digitsAfterDecimal = parts[1] ? parts[1] : 0;
                if (digitsAfterDecimal[0] > 5) {
                  this.discount_amount = Math.round(this.discount_amount);
                  this.discount_amount = parseInt(this.discount_amount); 
                } else {
                  this.discount_amount = parseInt(this.discount_amount);
                }                       
              }            
            }
            this.totalBillAmount = Math.round(tot_amt1);
            console.log("totalBillAmount",this.totalBillAmount);
            //my changes 
            var tot_amt = "0";
            if (obj.bills != null && obj.bills.length != 0) {
              for (var i = 0; i < obj.bills.length; i++) {
                if (obj.bills[i].fee != null) {
                  tot_amt = (parseFloat(tot_amt) + parseFloat(obj.bills[i].fee)).toFixed(2);
                }
                if (obj.bills[i].pay_type != null) {
                  this.payType = obj.bills[i].pay_type;
                  this.payFlag = false;
                }
              }
            }

            if (obj.estimates != null) {
              if (obj.estimates.length != 0) {
                for (var i = 0; i < obj.estimates.length; i++) {
                  if (obj.estimates[i].estimate != null) {
                    tot_amt = (parseFloat(tot_amt) + parseFloat(obj.estimates[i].estimate)).toFixed(2);
                  }
                }
              }
            }
            
            this.billAmount = tot_amt;
            if (obj.cgst == undefined || obj.cgst == null) {
              this.billAmount = Math.round(parseFloat(this.billAmount));
            }
            
            this.totalBill = tot_amt;             
            if (obj.prev_balance != null && obj.prev_balance != '0' && obj.prev_balance != '0.00') {
              this.totalBill = (parseFloat(tot_amt) + parseFloat(this.prevBalance)).toFixed(2);
              this.billAmount = (parseFloat(tot_amt) + parseFloat(this.prevBalance));
              this.totalBillAmount = (parseFloat(tot_amt1) + parseFloat(this.prevBalance));
            }
            if (obj.cgst != null) {
              this.cgst = Math.round(parseFloat(this.billAmount) * (parseFloat(obj.cgst) / 100));
              this.sgst = Math.round(parseFloat(this.billAmount) * (parseFloat(obj.sgst) / 100));            
              this.billAmount = parseFloat(tot_amt) + parseFloat(this.cgst) + parseFloat(this.sgst);
              this.billAmount = Math.round(parseFloat(this.billAmount));
              this.totalBillAmount = parseFloat(tot_amt1) + parseFloat(this.cgst) + parseFloat(this.sgst);
              this.totalBillAmount = parseFloat(this.totalBillAmount);
            }

            if (obj.bill_amount != undefined && obj.bill_amount != "") {
              if (parseInt(this.concession_txt) != 100) {
                this.billAmount = encrypt_decript.Decript(obj.bill_amount);
                this.billAmount = parseInt(this.billAmount);
              }             
            }   
            
            this.paid =  obj.paid_amount != undefined ? parseFloat(obj.paid_amount) : 0;
            if (this.paid != '' && this.paid != 0) {          
              var floatValue = this.paid;
              var floatString = floatValue.toString();
              var parts = floatString.split('.');
              var digitsAfterDecimal = parts[1] ? parts[1] : 0;
              if (digitsAfterDecimal[0] > 5) {
                this.paid = Math.round(this.paid);
                this.paid = parseInt(this.paid); 
              } else {
                this.paid = parseInt(this.paid);
              }                       
            }
            this.discount = parseFloat(obj.round_off).toFixed(2);
            if (obj.middle_name != null && obj.middle_name != undefined) {
              this.clientName = encrypt_decript.Decript(obj.first_name).toString() + " " + encrypt_decript.Decript(obj.middle_name).toString() + " " + encrypt_decript.Decript(obj.last_name).toString();
            } else {
              this.clientName = encrypt_decript.Decript(obj.first_name).toString() + " " + encrypt_decript.Decript(obj.last_name).toString();
            }

            this.docName = obj.dr_middle_name == undefined ?  "Dr." + obj.dr_first_name + " " + obj.dr_last_name : "Dr." + obj.dr_first_name + " " + obj.dr_middle_name + " " + obj.dr_last_name;
            // var advanceval,paidval,balval;
            // var advval;
            // if(obj.advance !=undefined){
            //   advval = obj.advance;
            // } else {
            //   advval=0.00;
            // }
            // if(advval != "0.00" && obj.adv_balance != undefined && this.paid == 0.00){
            //   balval= (parseFloat(this.billAmount)-advval).toFixed(2);
            //   paidval = 0.00;
            //   console.log("1",balval);
              
            // } else if(advval != "0.00" && obj.adv_balance == undefined && this.paid == 0.00){
            //   advanceval =parseFloat(advval).toFixed(2); 
            //   balval= (parseFloat(this.billAmount)-advanceval).toFixed(2);
            //   paidval = 0.00;
            //   console.log("2",balval);
              
            // } else if(advval != "0.00" && obj.adv_balance == undefined && this.paid != 0){
            //   advanceval =parseFloat(advval).toFixed(2) 
            //   paidval =parseFloat(this.paid).toFixed(2);
            //   balval= (parseFloat(this.billAmount)-(parseFloat(paidval)+parseFloat(advanceval))).toFixed(2);
            //   console.log("3",balval);              
            // } else if(advval == "0.00" && obj.adv_balance == undefined && this.paid != 0 && obj.prev_balance != "0.00" && obj.prev_balance != undefined){
            //   paidval =parseFloat(this.paid).toFixed(2);
            //   balval= (parseFloat(this.billAmount) - parseFloat(paidval)).toFixed(2);
            //   advanceval=0.00;
            //   console.log("4",balval);               
            // } else if(advval == "0.00" && obj.adv_balance == undefined && this.paid == 0 && obj.prev_balance != "0.00" && obj.prev_balance != undefined){
            //   balval= parseFloat(this.totalBill).toFixed(2);
            //   balval= parseFloat(this.totalBillAmount);
            //   advanceval=0.00;
            //   paidval=0.00
            //   console.log("5",balval);
            // } else if(this.paid != "0.00" && obj.advance == "0.00"){
            //   balval = (parseFloat(this.billAmount)- parseFloat(this.paid)).toFixed(2);
            // } else if(this.paid != "0.00" && obj.advance != "0.00") {
            //   balval = (parseFloat(this.billAmount)- (parseFloat(this.paid)+parseFloat(obj.advance))).toFixed(2);
            // }
            // else {
            //   balval = parseFloat(this.billAmount).toFixed(2);
            //   advanceval=0.00;
            //   paidval=0.00
            //   console.log("6",balval);
            // }    
            
            // if (balval == 'NaN' || balval == undefined || balval == "") {
            //   if (obj.balance != undefined && obj.balance != '') {
            //     balval = obj.balance;
            //   }          
            // }


            this.totalBillAmount = obj.bill_amount != undefined ? encrypt_decript.Decript(obj.bill_amount) : 0;
            this.totalBillAmount = parseFloat(this.totalBillAmount);
            this.discount_amount = obj.discount != undefined ? parseFloat(obj.discount) : 0;
            this.advancevalue = obj.advance != undefined ? parseFloat(obj.advance) : 0;
            this.sponsor_due = obj.sponsor_due != undefined ? parseFloat(obj.sponsor_due) : 0;
            this.paid = obj.paid_amount != undefined ? parseFloat(obj.paid_amount) : 0;
            this.balance = obj.balance != undefined ? parseFloat(obj.balance) : 0;
            this.refund = obj.refund != undefined ? parseFloat(obj.refund) : 0;
            this.bill_type_pharma = obj.bill_incl_pharma != undefined ? obj.bill_incl_pharma : '';
            
            var cname;
            if(obj.salutation != undefined) {
              cname = obj.salutation+"."+this.clientName;
            } else {
              cname = this.clientName;
            }

            var inpatientid,bed_no,admission_time,ward_name,admission_date;
            if(obj.inp_hosp_id != undefined) {
              inpatientid = obj.inp_hosp_id;
              this.inpatientflag=true;

            } else {
              this.inpatientflag=false;
              if(obj.appointment_id != undefined){
                inpatientid = obj.appointment_id;
              }              
            }
            if(obj.bed_no != undefined) {
              bed_no = obj.bed_no;

            } 
            if(obj.ward_name != undefined) {
              ward_name = obj.ward_name;

            } 
            if(obj.admission_date != undefined) {
              admission_date = obj.admission_date;

            } 
            if(obj.admission_time != undefined) {
              admission_time = Time_Formate(obj.admission_time);
            } 

            var genshort;         
            if (
              this.genderval != null &&
              this.genderval != undefined
            ) {
                this.genderval = encrypt_decript.Decript(this.genderval);
                genshort = this.genderval[0];
              if (this.genderval != undefined && this.genderval[0] == 'T') {
                genshort = 'Tgen';
              }
            }  
            
            var docName;
            if (obj.dr_middle_name != undefined && obj.dr_middle_name != null) {
              docName = "Dr." + obj.dr_first_name + " " + obj.dr_middle_name + " " + obj.dr_last_name;
            } else if (obj.dr_first_name != undefined && obj.dr_last_name != undefined) {
              docName = "Dr." + obj.dr_first_name + " " + obj.dr_last_name;
            }    
                                 
            if(obj.payments != undefined){
              this.paymentsarray = obj.payments;    
            }

            this.surgery = obj.surgery != undefined ? obj.surgery : '';

            if (this.surgery == '' && this.credit_type == '') {
              this.barcode_style = "width: 157px; height: 37px !important; margin-left: 0px; margin-top: -35px;";
              this.nobanner_style = "width: 100%; height: 95px;";
            } else if (this.surgery != '' && this.credit_type != '') {
              this.barcode_style = "width: 157px; height: 37px !important; margin-left: 0px; margin-top: -65px;";
              this.nobanner_style = "width: 100%; height: 115px;";
            } else if (this.surgery != '' || this.credit_type != '') {
              this.barcode_style = "width: 157px; height: 37px !important; margin-left: 0px; margin-top: -45px;";
              this.nobanner_style = "width: 100%; height: 110px;";
            }           

            if(parseFloat(this.paid) > this.billAmount){              
              this.numToWords = convertNumberToWords(this.billAmount,ipaddress.country_code);
            } else {
              this.numToWords = convertNumberToWords(this.paid,ipaddress.country_code);
            }

            this.client_name = cname;
            this.age = this.ageval;
            this.gender = genshort;
            this.dr_name = docName;
            this.created_date = datetxt;
            this.caddress1 = this.clientAddress1;
            this.caddress2 = this.clientAddress2;
            this.clocation = clocation;
            this.clientcity = ccity + czipcode;
            this.clestare = cstate + ccountry;
            this.uhid = obj.client_hosp_id;
            if (obj.barcode != undefined && obj.barcode != null) {
              this.barcode_flag = true;
              this.barcode_url = ipaddress.Ip_with_img_address + obj.barcode;
            }
            
            this.billingDetailList.push({
              profile_image: this.profile_image,
              dr_name: docName,
              licence_code: " Reg. No: " + obj.licence_code,
              hptl_name: obj.hptl_name,
              address: this.sendAddress,
              location: obj.location + ", ",
              city: obj.city + " - " + obj.zipcode + ",",
              state: obj.state + " - " + obj.country + ",",
              telephone: obj.telephone,
              created_date: datetxt,
              appointment_id: obj.appointment_id,
              client_name: cname,
              pay_type: obj.pay_type,
              // advance: parseFloat(advanceval).toFixed(2),
              // balance: parseFloat(balval),
              caddress1: this.clientAddress1,
              caddress2: this.clientAddress2,
              clocation: clocation,
              clientcity: ccity + czipcode,
              clestare: cstate + ccountry,
              uhid: obj.client_hosp_id,        
              tot_amt : this.billAmount,
              mobile:encrypt_decript.Decript(obj.mobile),
              inpatid:obj.inp_hosp_id,
              age:this.ageval,
              gender:genshort,
              numToWords: this.numToWords,
              inpatientid:inpatientid,
              bedno:bed_no,
              wardname:ward_name,
              admitteddate: admission_date,
              admittedtime: admission_time,
              advancevalue: parseFloat(obj.advance),
              cgst: parseInt(obj.cgst),
              sgst: parseInt(obj.sgst)
            });
          }
        } 
      },
      error => {});
  }

  getamountAftCon1(amount,quantity) {
    var amount_aftcon;
    console.log(amount_aftcon = parseFloat(amount) * quantity - (this.concession_txt / 100) * (parseFloat(amount) * quantity));
    return (amount_aftcon = parseFloat(amount) * quantity - (this.concession_txt / 100) * (parseFloat(amount) * quantity));
  }

  createGroupping(hospital_charges) {
    console.log(hospital_charges);
    this.categoryChargeList = hospital_charges;
    const groupedByCategory = this.groupBy(this.categoryChargeList, 'category');
    console.log("groupedByCategory",groupedByCategory);
    var hospitalChargeArray2 = [];
    var new_viewchargearray = [];
    for (var key in groupedByCategory) {
      console.log(key + ': ' + JSON.stringify(groupedByCategory[key]));
      if (key !== 'undefined') {
        console.log(key);
        new_viewchargearray = [];
        for (var k = 0; k < groupedByCategory[key].length; k++) {
            var charge_order_list = groupedByCategory[key][k].order_no;
            var category_Name = "";
            if (groupedByCategory.length > 1) {
              category_Name = groupedByCategory[key][k].category;
            }

            new_viewchargearray.push({
              sno: k + 1,
              bill_type: groupedByCategory[key][k].bill_type,
              created_date_time: groupedByCategory[key][k].created_date_time,
              amount_txt: groupedByCategory[key][k].amount_txt,
              concession: groupedByCategory[key][k].concession,
              fee: groupedByCategory[key][k].fee,
              charge_type: groupedByCategory[key][k].charge_type,
              pay_type: groupedByCategory[key][k].pay_type,
              quantity: groupedByCategory[key][k].quantity,
              multiply_value: groupedByCategory[key][k].multiply_value,
              advancevalue: groupedByCategory[key][k].advancevalue,
              advance: groupedByCategory[key][k].advance,
              category: key,
              order_no: charge_order_list,
              charge_cat_id: groupedByCategory[key][k].charge_cat_id,
            })
        }
        var category_flag;
        if (new_viewchargearray.length == 0) 
          category_flag = false;
        else 
          category_flag = true;
        
        hospitalChargeArray2.push({
            category: key,
            category_flag: category_flag,
            order_no: charge_order_list,
            charges: new_viewchargearray.sort((a, b) => a.order_no - b.order_no),
        })
      }  
    }

    this.categoryChargeList = [];
    this.categoryChargeList = hospitalChargeArray2.sort((a,b)=> a.order_no - b.order_no);
    var rowid = 1;
    for (var i = 0; i < this.categoryChargeList.length; i++) {
      for (var j = 0; j < this.categoryChargeList[i].charges.length; j++) {
        this.categoryChargeList[i].charges[j].sno = rowid;
        rowid += 1;
      }
    }
    console.log("hospitalChargeArray2", hospitalChargeArray2);
    console.log("this.categoryChargeList",this.categoryChargeList);
  }

  groupBy(array, key) {
    return array.reduce((result, obj) => {
       const keyValue = obj[key];
       // If the key doesn't exist in the result, create an empty array for it
       if (!result[keyValue]) {
          result[keyValue] = [];
       }
       // Push the object to the corresponding key's array
       result[keyValue].push(obj);
       return result;
    }, {});
  }
    
  print() {
    var margin_top;
    let printContents, popupWin;
    if (this.printtemplate != undefined && this.printtemplate == "banner") {
      printContents = this.printbanner.nativeElement.innerHTML; 
      margin_top = '0px';    
    } else {
      printContents = this.printnoheader.nativeElement.innerHTML;
      margin_top = '122px';
    }
    popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
    <html>
      <head>
        <title>Bill</title>
        <link rel="stylesheet" media="screen" href="">
        <style>                        
          @page {
            size: auto;
            margin-top: ${margin_top};
            margin-right: 0px;
            margin-left: 0px;
            margin-bottom: 0px;
            overflow: visible;
          } 
          * {
              font-size: 12px !important;
              line-height: 1 !important;
              page-break-inside: always;
              overflow: visible;
            }                                     
          @media print {   
            body {
              height: auto; /* Allow body height to adjust dynamically */
            }          
            .page-break {
              page-break-before: always; /* or page-break-after: always; */
              page-break-inside: always;
            }
          }           
          .address_visibility {
            visibility: hidden;
          }        
          table.report-container {
            page-break-after: always;
          }
          thead.report-header {
            display: table-header-group;
          }
          tfoot.report-footer {
            display: table-footer-group;
          }
          .print:last-child {
            page-break-after: auto;
          }
          .alignRight {
            text-align: right;
          }
          .col-print-1 {width:8%;  float:left;}
          .col-print-2 {width:16%; float:left;}
          .col-print-3 {width:25%; float:left;}
          .col-print-4 {width:33%; float:left;}
          .col-print-5 {width:42%; float:left;}
          .col-print-6 {width:50%; float:left;}
          .col-print-7 {width:58%; float:left;}
          .col-print-8 {width:66%; float:left;}
          .col-print-9 {width:75%; float:left;}
          .col-print-10 {width:83%; float:left;}
          .col-print-11 {width:92%; float:left;}
          .col-print-12 {width:100%; float:left;}
        </style>
      </head>
      <body onload="window.print(); window.onfocus=function(){ window.close();}">${printContents}</body>
    </html>`);
    popupWin.document.close();
  } 

  back() {
    if(Helper_Class.getIsAdmin() != undefined){
      this.adminservice.sendMessage("frontdeskadminbilling");
    }else if (Helper_Class.getLoginType() == 'doctor'){
      this.service.sendMessage('billing');
    } else {
      this.service.sendMessage('frontdeskbilling');
    }
  }

  history(){
    const dialogRef = this.dialog.open(BillHistViewComponent, {
      width: '700px',
      height: '500px'
    });
    dialogRef.afterClosed().subscribe(result => {});
  }
}
