<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white ">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Other Expenses</h5>
          </div>
          <div class="headerButtons">    
            <a *ngIf="printbutton" (click)="printExpenses()">
              <img src="../../../assets/img/printer.svg"
                style="width: 25px; height: 25px;cursor: pointer;"/>
            </a>&nbsp;    
            <a *ngIf="approved_flag" (click)="cancelExpenses()">
              <img src="../../../assets/ui_icons/buttons/cancel_button.svg"  
                class="addimgbtn_icd"/> 
            </a>&nbsp;
            <a *ngIf="approved_flag" (click)="approvedSave()">
              <img src="../../../assets/ui_icons/buttons/save_button.svg"  
                class="addimgbtn_icd"/> 
            </a>
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>
        <div class="row">           
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom">  
            <mat-label class="matlabel">Date<br>
              <input type="date" class="ipcss" style="height: 25px;"
                [(ngModel)]="exp_date" #matInput max="{{currentDate}}" required>                                                               
            </mat-label> 
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom">
            <mat-label class="matlabel">Time<br></mat-label>
            <div class="row">
              <div class="col3" style="padding-right:0;">
                <select type="date" class="ipcss inpat_width" [(ngModel)]="expense_time1" required style="padding: 3px 1px !important">
                  <option *ngFor="let hrs of expense_hrs" value="{{hrs}}">{{hrs}}
                  </option>
                </select>
              </div>
              <div class="col-1" style="padding:0;position: relative;top: 5px;left: 8px;">
                : </div>
              <div class="col3" style="padding-left:0;">
                <select type="date" class="ipcss inpat_width" [(ngModel)]="expense_time2" required style="padding: 3px 1px !important">
                  <option *ngFor="let min of expense_min" value="{{min}}">{{min}}
                  </option>
                </select>
              </div>
              <div class="col5">
                <select type="date" class="ipcss inpat_width" [(ngModel)]="expense_time3" required>
                  <option value="AM">AM</option>
                  <option value="PM">PM</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom">
            <mat-label class="matlabel">Type<br>
              <input  matInput class="ipcss" style="height: 25px;" type="text"  required
                [(ngModel)]="exp_name" />
            </mat-label>
          </div> 
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom">
            <mat-label class="matlabel">Amount<br>
              <input class="ipcss" style="height: 25px;" type="text" required
                [(ngModel)]="exp_amount" />
            </mat-label>
          </div>  
          <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom">
            <mat-label class="matlabel">Description<br>
              <textarea class="ipcss inpat_width" style="height: 60px;max-height: 60px;min-height: 60px;" [(ngModel)]="exp_desc" matInput required></textarea>                                              
            </mat-label>
          </div> 
          <div class="col-1  p-0">
            <mat-label class="matlabel"><br>
              <a (click)="addExpense()">
                <img src="../../../assets/ui_icons/buttons/add_button.svg" class="saveimgbtn_inpatinfo add_align btn-outline-success rounded"
                  style="float: right;cursor: pointer;"/>
              </a>                
            </mat-label>
          </div>
        </div> 
        <div class="row" style="margin-top: 15px;">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <p class="nodata" *ngIf="noExpFlag">No expense(s) found</p>
            <table *ngIf="expList != undefined && expList.length != 0" mdbTable datatable [dtOptions]="dtOptions" class="table table-hover table-nowrap table-sm billlisttable table-responsive">
              <thead>
                <tr>
                  <th>Sno</th>
                  <th>Created Date/Time</th>
                  <th>Expense Date/Time</th>
                  <th>Type</th>
                  <th>Amount</th>  
                  <th>Description</th>  
                  <th>Approved <br> <span *ngIf="approved_flag"> <mat-checkbox color="primary" [(ngModel)]="select_all" (change)="selectAll()"></mat-checkbox> </span></th>
                  <th *ngIf="accounts_flag">Paid</th>
                  <th *ngIf="accounts_flag">Balance</th>
                  <th *ngIf="accounts_flag">Payable</th>
                  <th *ngIf="accounts_flag">Pay type</th>
                  <th *ngIf="accounts_flag">Status</th>
                  <th *ngIf="editbutton">Edit</th> 
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let exp of expList; let i = index">
                  <td style="font-size: 12px;text-align: center;">{{exp.sno}}</td>
                  <td style="font-size: 12px;text-align: center;">{{exp.created_date}} {{exp.created_time}}</td>
                  <td style="font-size: 12px;text-align: center;">{{exp.exp_date}} {{exp.exp_time}}</td>
                  <td style="font-size: 12px;text-align: left;">{{exp.exp_name}}</td>
                  <td style="font-size: 12px;text-align: right !important;">{{exp.exp_amount}}</td>
                  <td style="font-size: 12px;text-align: left !important;">{{exp.exp_desc}}</td>
                  <td style="font-size: 12px;text-align: center;" *ngIf="approved_flag">
                    <mat-checkbox color="primary" [(ngModel)]="exp.exp_approved"></mat-checkbox> 
                  </td>
                  <td style="font-size: 12px;text-align: center;" *ngIf="!approved_flag">
                    {{exp.exp_approved_status}}
                  </td>
                  <td style="text-align: right;" *ngIf="accounts_flag">{{exp.paid_amt}}</td>
                  <td style="text-align: right;" *ngIf="accounts_flag">{{exp.balance}}</td>
                  <td *ngIf="accounts_flag">
                    <input type="text" class="ipcss paywidth" [readonly]="exp.paidflag" style="text-align: right;"
                          [(ngModel)]="exp.payamount" maxlength="10"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57" matInput />
                  </td>
                  <td *ngIf="accounts_flag" style="width: 100px !important;">
                    <select class="ipcss" *ngIf="paymentArray !=undefined" [(ngModel)]="exp.pay_id"
                      [disabled]="exp.paidflag">
                      <option *ngFor="let pay of paymentArray" value={{pay.pay_id}}> {{pay.pay_desc}}
                      </option>
                    </select>
                  </td>
                  <td *ngIf="accounts_flag">{{exp.payflag}}</td>
                  <td  style="font-size: 12px;text-align: center;" *ngIf="editbutton">
                    <img src="../../../assets/img/edit.png" class="billing_edit" style="z-index: 9;" alt="" (click)="editExpense(exp)">
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div [hidden]="true" #printbanner id="printbanner">
          <div [hidden]="expList == undefined || expList.length == 0">
            <table style="border:'0';width:100%">                 
              <thead>
                <tr>
                  <th style="width:100%;">                                 
                    <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;margin-right: 30px; margin-left: 20px;"> 
                      <div style="width: 100%; height: 205px !important;"> 
                        <div style="width: 100%;">
                          <img alt="image" src={{hospital_logo}}  [style]="printlogostyle">
                        </div>                                                             
                      </div>
                    </div>               
                  </th>
                </tr>
              </thead>                       
              <tbody>  
                <tr>  
                  <td width="100%">                               
                    <div class="bill_border">  
                      <div>
                        <div *ngIf="expList != undefined && expList.length != 0" style="margin-left: 20px;margin-right: 10px;">                      
                          <div class="row">
                            <table style="width: 100% !important;border: 1px solid black;border-collapse: collapse;margin-right: 20px;">    
                              <thead>
                                <tr>
                                  <th style="border: 1px solid black;border-collapse: collapse;">Sno</th>
                                  <th style="width: 70px;border: 1px solid black;border-collapse: collapse;">Created Date/Time</th>
                                  <th style="width: 70px;border: 1px solid black;border-collapse: collapse;">Expenses Date/Time</th>
                                  <th style="width: 150px;border: 1px solid black;border-collapse: collapse;">Name</th>
                                  <th style="border: 1px solid black;border-collapse: collapse;">Amount</th>
                                  <th style="width: 150px;border: 1px solid black;border-collapse: collapse;">Description</th>
                                  <th style="border: 1px solid black;border-collapse: collapse;">Approved</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let exp of expList">
                                  <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{exp.sno}}</td>
                                  <td style="text-align: left;border: 1px solid black;border-collapse: collapse;">{{exp.created_date}} {{exp.created_time}}</td>
                                  <td style="text-align: left;border: 1px solid black;border-collapse: collapse;">{{exp.exp_date}} {{exp.exp_time}}</td>
                                  <td style="text-align: left;border: 1px solid black;border-collapse: collapse;">{{exp.exp_name}}</td>
                                  <td style="text-align: right;border: 1px solid black;border-collapse: collapse;">{{exp.exp_amount}}</td>
                                  <td style="text-align: left;border: 1px solid black;border-collapse: collapse;">{{exp.exp_desc}}</td>
                                  <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{exp.exp_approved_status}}</td>
                                </tr>
                              </tbody>                                     
                            </table>
                          </div>
                        </div>
                      </div>                                                                                                                                                                                   
                    </div>                
                  </td>
                </tr>
              </tbody>    
            </table>
          </div>
        </div> 
        <div [hidden]="true" #printnoheader id="printnoheader">
          <div [hidden]="expList == undefined || expList.length == 0">
            <table style="border:'0';width:100%">             
              <thead>
                <tr>
                  <th style="width:100%;">
                    <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;margin-right: 30px; margin-left: 20px;"> 
                    </div>
                  </th>
                </tr>
              </thead>       
              <tbody>  
                <tr>  
                  <td width="100%">                             
                    <div class="bill_border">
                      <div>
                        <div *ngIf="expList != undefined && expList.length != 0" style="margin-left: 20px;margin-right: 10px;">                      
                          <div class="row">
                            <table style="width: 100% !important;border: 1px solid black;border-collapse: collapse;margin-right: 20px;">    
                              <thead>
                                <tr>
                                  <th style="border: 1px solid black;border-collapse: collapse;">Sno</th>
                                  <th style="border: 1px solid black;border-collapse: collapse;">Created Date/Time</th>
                                  <th style="border: 1px solid black;border-collapse: collapse;">Expenses Date/Time</th>
                                  <th style="border: 1px solid black;border-collapse: collapse;">Name</th>
                                  <th style="border: 1px solid black;border-collapse: collapse;">Amount</th>
                                  <th style="border: 1px solid black;border-collapse: collapse;">Description</th>
                                  <th style="border: 1px solid black;border-collapse: collapse;">Approved</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let exp of expList">
                                  <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{exp.sno}}</td>
                                  <td style="text-align: left;border: 1px solid black;border-collapse: collapse;">{{exp.created_date}} {{exp.created_time}}</td>
                                  <td style="text-align: left;border: 1px solid black;border-collapse: collapse;">{{exp.exp_date}} {{exp.exp_time}}</td>
                                  <td style="text-align: left;border: 1px solid black;border-collapse: collapse;">{{exp.exp_name}}</td>
                                  <td style="text-align: right;border: 1px solid black;border-collapse: collapse;">{{exp.exp_amount}}</td>
                                  <td style="text-align: left;border: 1px solid black;border-collapse: collapse;">{{exp.exp_desc}}</td>
                                  <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{exp.exp_approved_status}}</td>
                                </tr>
                              </tbody>                                          
                            </table>
                          </div>
                        </div>  
                      </div>                                                                              
                    </div>                
                  </td>
                </tr>
              </tbody>                       
            </table>
          </div>        
        </div>
      </mdb-card-body>
    </mdb-card> 
  </div>      
</div>

