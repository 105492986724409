<div class="row" style="margin:0 auto; width:100%">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Products</h5>
                    </div>
                    <div class="headerButtons" *ngIf="!tableviewflag">
                        <img style="float: right;" src="../../../assets/ui_icons/buttons/save_button.svg"
                            class="saveimgbtn_inpatinfo" (click)="saveData()">
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>

                <div class="row">
                    <div class="col-12" style="display: flex;
                    flex-direction: row-reverse;
                    padding: 3px;">
                        <!-- <img src="../../../assets/ui_icons/buttons/next_button.svg" *ngIf="nextflag" style="width:27px;margin-right: 5px;"
                        (click)="nexttableview()"/>

                        <img src="../../../assets/ui_icons/buttons/back_icon.svg" *ngIf="backflag" style="width:27px;margin-right: 5px;"
                        (click)="backtableview()"/> -->
                          
                    </div>

                <!-- <button class="saveimgbtn_inpatinfo" (click)="producttableview()" style="width:5%;height:20px"></button> -->
                </div>
                
                <div class="cover_div" style="margin-top: 0;" [ngClass]="{'cover-hide': !isCoverVisible, 'cover-show': isCoverVisible}">
                    <!-- *ngIf="!tableviewflag" -->
                    <div class="header_lable">
                        Product details
                    </div>
                    <div class="content_cover">
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel" style="margin-top: 7px;">HSN No
                                    <input type="text" class="ipcss " required [(ngModel)]="hsnNO" [disabled]="newFlag"
                                        maxlength="10" matInput
                                        onkeypress="return (event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122) || (event.charCode >= 48 && event.charCode <= 57)" />
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel" style="margin-top: 7px;">Product name

                                    <input type="text" class="ipcss " [(ngModel)]="productName"
                                        (keyup)="productNameFilter($event)" matInput [matAutocomplete]="auto10" />
                                    <mat-autocomplete #auto10="matAutocomplete">
                                        <mat-option (click)="selectItem(item)" *ngFor="let item of filteredList"
                                            value={{item}}> {{item}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-label>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel" style="margin-top: 7px;">Short name
                                    <input type="text" class="ipcss " [(ngModel)]="shortName" matInput />
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2">
                                <mat-label class="matlabel" style="margin-top: 7px;">Generic name
                                    <input type="text" class="ipcss " [(ngModel)]="genericName" matInput
                                        (keyup)="genericNameFilter($event)" [matAutocomplete]="gennameauto" />
                                    <mat-autocomplete #gennameauto="matAutocomplete">
                                        <mat-option (click)="selectGEN(gen)" *ngFor="let gen of genericlist"
                                            value={{gen}}> {{gen}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-label>
                            </div>

                            <div class="col-1" style="padding: 0;">
                                <img class="manuf_add" src="../../../assets/ui_icons/Add_icon.svg"
                                    data-bs-toggle="modal" data-bs-target="#profilePicModal" />

                                    <img class="manuf_add" style="width: 25px;position: relative;top: 24px;left: 25px;" src="../../../assets/img/captcha_refresh.png" (click)="cleararray(this.rowid)" class="refresh_btn" alt="clear data">
                            </div>
                           

                            <!-- <div class="col-1" style="padding: 0; ">
                               
                            </div> -->

                            <div class="modal fade" id="profilePicModal" tabindex="-1" role="dialog"
                                aria-labelledby="exampleModalLabel" aria-hidden="true">

                                <div class="modal-dialog modal-md">
                                    <div class="modal-content" style="width:100%">
                                        <div class="modal-header">
                                            <h4 class="modal-title" id="exampleModalLabel">Generic Name</h4>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                aria-label="Close">
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            
                                            <div class="col-12">
                                                <div class="snapshot">
                                                    <div class="scrollbar">
                                                        <input type="text" class="ipcss " [(ngModel)]="genericName"
                                                            matInput (keyup)="genericNameFilter($event)"
                                                            [matAutocomplete]="gennameauto" />
                                                        <img src="../../../assets/ui_icons/buttons/save_button.svg"
                                                            data-bs-toggle="modal" data-bs-target="#profilePicModal"
                                                            class="saveimgbtn_inpatinfo" (click)="savegen()" style="margin-top:5px">
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">Medicare type<br>
                                    <select disableOptionCentering class="ipcss " [(ngModel)]="medicareMedID" required>
                                        <option disabled>Select</option>
                                        <option *ngFor="let medicare of mdeicareTypeArray"
                                            value={{medicare.medicare_id}}>
                                            {{medicare.medicare_name}}</option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">Product type<br>
                                    <select disableOptionCentering class="ipcss " [(ngModel)]="productTypeID" required>
                                        <option disabled>Select</option>
                                        <option *ngFor="let pro_type of productTypeArray" value={{pro_type.med_code}}>
                                            {{pro_type.med_name}}</option>
                                    </select>
                                </mat-label>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">Category type<br>
                                    <select disableOptionCentering class="ipcss " [(ngModel)]="categoryCtgID">
                                        <option disabled>Select</option>
                                        <option *ngFor="let category of categoryTypeArray"
                                            value={{category.category_id}}>
                                            {{category.category_name}}</option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">UOM<br>
                                    <select disableOptionCentering class="ipcss" [(ngModel)]="uomCode">
                                        <option disabled>Select</option>
                                        <option *ngFor="let uom of uomListArray" value={{uom.uom_code}}>
                                            {{uom.uom_desc}}
                                        </option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <div class="row">
                                    <div class="col-11">
                                        <mat-label class="matlabel">Manufacturer<br>
                                            <input type="text" class="ipcss widthappt" maxlength="50"
                                                (keyup)="getManufacturer()" maxlength="50" [(ngModel)]="mfg_name"
                                                matInput [matAutocomplete]="auto" />
                                            <mat-autocomplete #auto="matAutocomplete">
                                                <mat-option (click)="manufacturer_change(manufacturer)"
                                                    *ngFor="let manufacturer of manufacList"
                                                    value={{manufacturer.name}}>
                                                    {{manufacturer.name}}
                                                </mat-option>
                                            </mat-autocomplete>
                                        </mat-label>
                                    </div>
                                    <div class="col-1" style="padding: 0;">
                                        <img (click)="createNew('Manufacturer')" class="manuf_add"
                                            src="../../../assets/ui_icons/Add_icon.svg" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <div class="row">
                                    <div class="col-11">
                                        <mat-label class="matlabel">Supplier / distributor
                                            name<br>
                                            <mat-select class="ipcss " [(ngModel)]="supp_name">
                                                <mat-option *ngFor="let supplier of supplierList" (click)="supplierdatafunction(supplier)" value={{supplier.name}}>
                                                    {{supplier.name}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-label>
                                    </div>
                                    <div class="col-1" style="padding: 0;">
                                        <img (click)="createNew('Supplier')" class="manuf_add"
                                            src="../../../assets/ui_icons/Add_icon.svg" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <mat-label class="matlabel">Schedule type<br>
                                    <select disableOptionCentering class="ipcss " [(ngModel)]="scheduleType">
                                        <option disabled>Select</option>
                                        <option *ngFor="let schedule of scheduleList"
                                            value={{schedule.schedule_type_id}}>{{schedule.category}}</option>
                                    </select>
                                </mat-label>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <div class="row">
                                    <div class="col-6">
                                        <mat-label class="matlabel">Rack no<br>
                                            <select disableOptionCentering class="ipcss " [(ngModel)]="rackidname" (change)="getBins()">
                                                <option disabled>Select</option>
                                                <option *ngFor="let rack of rackList"
                                                    value={{rack.rackid}}>{{rack.rackname}}</option>
                                            </select>
                                        </mat-label>
                                    </div>
                                    <div class="col-6">
                                        <mat-label class="matlabel">Bin no<br>
                                            <select disableOptionCentering class="ipcss " [(ngModel)]="binnameng">
                                                <option disabled>Select</option>
                                                <option *ngFor="let bins of binList"
                                                    value={{bins.binid}}>{{bins.binname}}</option>
                                            </select>
                                        </mat-label>
                                    </div>
                                    
                                </div>
                            </div>


                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <div class="row">
                                     <div class="col-6">
                                        <mat-label class="matlabel">Per unit<br>

                                            <input type="text" class="ipcss widthappt" required [(ngModel)]="quantity"
                                            matInput list="perunit">
                                         <datalist [(ngModel)]="quantity"  id="perunit">
                                            <!-- (onclick)="supplier_change($event, supplier)" -->
                                            <option  *ngFor="let unit of perunit" value={{unit.perunit_desc}} >
                                                {{unit.perunit_desc}}
                                            </option>
                                         </datalist>

                                        <!-- <mat-label class="matlabel">Quantity per unit
                                            <input type="text" class="ipcss" [(ngModel)]="quantity" 
                                                matInput required />
                                        </mat-label> -->
                                        </mat-label>
                                    </div> 



                                    <div class="col-6">
                                        <mat-label class="matlabel">MRP
                                            <input type="text" class="ipcss" [(ngModel)]="MRP" required matInput />
                                        </mat-label>
                                    </div>
                                    
                                </div>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <div class="row">
                                    <div class="col-6">
                                        <mat-label class="matlabel">CGST %
                                            <input type="text" class="ipcss" [(ngModel)]="prodCenteralGST" maxlength="4"
                                                matInput />
                                        </mat-label>
                                    </div>
                                    <div class="col-6">
                                        <mat-label class="matlabel">SGST %
                                            <input type="text" class="ipcss" [(ngModel)]="prodStateGST" maxlength="4"
                                                matInput />
                                        </mat-label>
                                    </div>

                                </div>
                            </div>

                             <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <div class="row">
                                    <div class="col-6">
                                        <mat-label class="matlabel" style="margin-top: 7px;">Min level
                                            <input type="text" class="ipcss " [(ngModel)]="min_qty" matInput
                                                onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                                        </mat-label>
                                    </div>
                                    <div class="col-6">
                                        <mat-label class="matlabel" style="margin-top: 7px;">Max level
                                            <input type="text" class="ipcss " [(ngModel)]="max_lvl" matInput
                                                onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                                        </mat-label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <div class="row">
                                    <div class="col-6">
                                        <mat-label class="matlabel" style="margin-top: 7px;">Re-order level
                                            <input type="text" class="ipcss " [(ngModel)]="ReOrder_lvl" matInput
                                                onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                                        </mat-label>
                                    </div>

                                    <div class="col-6">
                                        <mat-label class="matlabel" style="margin-top: 7px;">Danger level
                                            <input type="text" class="ipcss " [(ngModel)]="danger_lvl" matInput
                                                onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                                        </mat-label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <div class="row">

                                    <div class="col-6">
                                        <mat-label class="matlabel" style="margin-top: 7px;">Expiry Duration
                                            <input type="text" class="ipcss " [(ngModel)]="expirydura" matInput
                                                onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                                        </mat-label>
                                    </div>

                                    <div class="col-6">
                                        <mat-label class="matlabel"><br>
                                            <select disableOptionCentering class="ipcss " [(ngModel)]="dur">
                                                <option disabled>Select</option>
                                                <option value="day">Day(s)</option>
                                                <option value="month">Month(s)</option>
                                            </select>
                                        </mat-label>

                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-1 col-xl-1" style="margin-bottom: auto;margin-top: auto;">
                                 
                                <div style="position: relative;top: 10px;">
                                    <!-- <input *ngIf="item.status == 'Open'" type="checkbox" [checked]="accepted" (change)="checkeddata(item)"> -->
                                    <input type="checkbox" [checked]="discountaccepted" (change)="allowdiscountfun()" [(ngModel)]="allowdiscount">  Allow discount
                                </div>

                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2" style="margin-bottom: auto;margin-top: auto;">
                                <div style="position: relative;top: 10px;">
                                    <input type="checkbox" style="position:relative;top:1px" [checked]="look_alike"  [(ngModel)]="look_alike" (change)="look_alikevalue(look_alike)"> Look a like
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-1 col-lg-2 col-xl-2" style="margin-bottom: auto;margin-top: auto;">
                                <div style="position: relative;top: 10px;">
                                    <input type="checkbox" style="position:relative;top:1px" [checked]="sound_alike"  [(ngModel)]="sound_alike" (change)="sound_alikevalue(sound_alike)"> Sound a like
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2" style="margin-bottom: auto;margin-top: auto;">
                                <div style="position: relative;top: 10px;">
                                    <input type="checkbox" style="position:relative;top:1px" [checked]="hight_risk"  [(ngModel)]="hight_risk" (change)="high_riskvalue(hight_risk)"> High risk
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2" style="margin-bottom: auto;margin-top: auto;">
                                <div style="position: relative;top: 10px;">
                                    <input type="checkbox" style="position:relative;top:1px" [checked]="ward_only"  [(ngModel)]="ward_only" (change)="ward_onlyvalue(ward_only)"> ICU & Ward
                                </div>
                            </div>

                            <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <div class="row">

                                    <div class="col-6">




                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="row">    
                    <div class="col-12" style="display: flex;
                    flex-direction: row-reverse;
                    padding: 3px;">
                    <img src={{hide_show_btn}}  style="width:27px;margin-right: 5px;" (click)="toggleCover()"/>
                        </div>

                <!-- <button class="saveimgbtn_inpatinfo" (click)="producttableview()" style="width:5%;height:20px"></button> -->
                </div>

                <div class="row" style="margin-top: 10px;" [ngClass]="{'table-expand': !isCoverVisible, 'table-collapse': isCoverVisible}">
                    <!-- *ngIf="tableviewflag" -->
                    <div class="col-12">
                        <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <mat-label class="matlabel">Filter products
                                        <input type="text" class="ipcss" [(ngModel)]="productNameTxt" maxlength="50"
                                            (keyup)="productFilter()" matInput />
                                    </mat-label>
                                </div>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" style="float: right;">
                                    <mat-label class="matlabel">Per page<br>
                                        <select disableOptionCentering class="ipcss perpage" [(ngModel)]="pageList"
                                            (change)="pagelistChange()">
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="200">200</option>
                                        </select>
                                    </mat-label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <table style="font-size: 13px;" id="product_table" [hidden]="productMasterAddArray.length==0"
                            class="table table-hover table-dynamic">
                            <thead>
                                <tr>
                                    <th>Index</th>
                                    <th>HSN no</th>
                                    <th>Product name</th>
                                    <th>Product type</th>
                                    <th>Supplier id</th>
                                    <th>Supplier</th>
                                    <th>schedule type</th>
                                    <th>Qty/Unit</th>
                                    <th>MRP</th>
                                    <th>Min qty</th>
                                    <th>Max qty</th>
                                    <th>Re order</th>
                                    <th>Danger lvl</th>

                                    <th *ngIf="editbutton">Edit</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let productmaster of productMasterAddArray">
                                    <td class="align_left">{{productmaster.Index}}</td>
                                    <td class="align_left">{{productmaster.hsn_no}}</td>
                                    <td class="align_left">{{productmaster.name}}</td>
                                    <td class="align_left">{{productmaster.product_type_desc}}</td>
                                    <td class="align_left">{{productmaster.supp_id}}</td>
                                    <td class="align_left">{{productmaster.supp_name}}  <strong><span style="display:inline-block; font-weight: bold;" [hidden]="productmaster.ranking == ''">({{productmaster.ranking}})</span></strong></td>
                                    <td class="align_left">
                                        <span
                                            *ngIf="productmaster.schedule_type_desc != undefined ? productmaster.schedule_type_desc : ''">{{productmaster.schedule_type_desc}}</span>
                                    </td>
                                    <td>{{productmaster.per_unit}}</td>
                                    <td>{{productmaster.MRP}}</td>
                                    <td>{{productmaster.min_qty}}</td>
                                    <td>{{productmaster.max_level}}</td>
                                    <td>{{productmaster.reorder_level}}</td>
                                    <td>{{productmaster.danger_level}}</td>
                                    <td *ngIf="editbutton" style="font-size: 13px;">
                                    <a (click)="editProductMaster(productmaster.Index,productmaster)" id="view" style="color: #2c7fe1;"><i class="fas fa-edit"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div [hidden]="page_navigate || productMasterAddArray.length==0" class="col-12">
                        <div class="row">
                            <div class="col-3 col-lg-4 col-md-3 col-xl-4"></div>
                            <div class="col-2 col-md-2 col-lg-1 col-xl-1">
                                <input id="Text1" type="image" [disabled]="prevPageCount" class="pag_images"
                                    (click)="pageLimitPrevious()" src="../../../assets/img/pagnation_previous.png" />
                            </div>
                            <div class="col-2 col-md-2 col-lg-1 col-xl-1">
                                <span class="current_font">{{currentPage}}/{{numberOfPages()}}</span>
                            </div>
                            <div class="col-2 col-md-2 col-lg-1 col-xl-1">
                                <input id="Image1" type="image" [disabled]="nextPageCount" class="pag_images"
                                    (click)="pageLimitnext()" src="../../../assets/img/pagination_next.png" />
                            </div>
                        </div>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>