<div class="Details container" id="Begin_content_others" style="padding: 0 15px 0 15px; max-width: 100%;">
   <div class="row">
      <div class="col-12 p-0">
         <mdb-card>
            <mdb-card-header class="bg-white" style="border-bottom: 0 !important;">
               <div class="headerCover">
                  <div class="headerTilte app_list_header">
                     <h5 class="m-0" class="mainHeadingStyle">Prescription</h5>
                  </div>
                  <div class="headerButtons">
                     <a style="margin:0 5px;" (click)="backtoPreslist()"><img
                           src="../../../assets/ui_icons/buttons/Back_button.svg" class="backimgbtn_inpatinfo" />
                     </a>

                     <a style="margin:0 5px; position: relative;" *ngIf="saveflag" (click)="saveData()"><img
                           src="../../../assets/ui_icons/buttons/save_button.svg" class="backimgbtn_inpatinfo" />
                     </a>

                     <a style="margin:0 5px; position: relative;" *ngIf="!print_view_flag && printFlag && printbutton"
                        (click)="print_Viewprescription()"><img style="width: 30px;"
                           src="../../../assets/img/printer.svg" />
                     </a>
                  </div>
               </div>
            </mdb-card-header>
            <mdb-card-body>
               <div style="width: 60%;">
                  <app-loader></app-loader>
               </div>
               <div class="container-fluid">
                  <div class="row">
                     <div *ngIf="medpres">
                        <div class="row" *ngIf="bp_flag || weight_flag || height_flag || temparature_flag">
                           <div class="col-12">
                              <div class="cover_div">
                                 <div class="header_lable">
                                    Vitals
                                 </div>
                                 <div class="content_cover">
                                    <div class="row">
                                       <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                       style="margin-bottom: 10px !important;">
                                       <mat-label class="matlabel  col-4" *ngIf="height_flag">
                                         {{'Height'}}: {{height}}
                                       </mat-label>
                                       <mat-label class="matlabel  col-4" *ngIf="weight_flag">
                                         {{'Weight'}}: {{weight}}
                                       </mat-label>
                                       <mat-label class="matlabel  col-4" *ngIf="bp_flag">
                                         {{'BP'}}: {{clnt_bp}}
                                       </mat-label>
                                       <mat-label class="matlabel  col-4" *ngIf="pulseflag">
                                         {{'Pulse'}}: {{clnt_pulse}}
                                       </mat-label>
                                       <!-- <mat-label class="matlabel col-4" *ngIf="bp_flag">{{'BP'}}: {{clnt_bp}}
                                         &nbsp;&nbsp;&nbsp;{{'Pulse'}}: {{clnt_pulse}}
                                       </mat-label> -->
                                       <mat-label class="matlabel  col-4" *ngIf="temparature_flag">
                                         {{'Temperature'}}: {{temparature}}
                                       </mat-label>
                                       <mat-label class="matlabel  col-4" *ngIf="rrflag">
                                         {{'RR'}}: {{clnt_rr}}
                                       </mat-label>
                                       <mat-label class="matlabel  col-4" *ngIf="spoflag">
                                         {{'Spo2'}}: {{clnt_spo2}}
                                       </mat-label>
                                       <mat-label class="matlabel  col-4" *ngIf="cbgflag">
                                         {{'CBG'}}: {{clnt_cbg}}
                                       </mat-label>
                                       <mat-label class="matlabel  col-4" *ngIf="cvsflag">
                                         {{'CVS'}}: {{clnt_cvs}}
                                       </mat-label>
                                       <mat-label class="matlabel  col-4" *ngIf="rsflag">
                                         {{'RS'}}: {{clnt_rs}}
                                       </mat-label>
                                       <mat-label class="matlabel  col-4" *ngIf="abdflag">
                                         {{'Abdomen'}}: {{clnt_abd}}
                                       </mat-label>
                                       <mat-label class="matlabel  col-4" *ngIf="cnsflag">
                                         {{'CNS'}}: {{clnt_cns}}
                                       </mat-label>
                                       <!-- <mat-label class="matlabel col-4" *ngIf="rrspoflag">{{'RR'}}: {{clnt_bp}}
                                         &nbsp;&nbsp;&nbsp;{{'Spo2'}}: {{clnt_pulse}}
                                       </mat-label> -->
                                       <!-- <mat-label class="matlabel col-4" *ngIf="cvsrsflag">{{'CVS'}}: {{clnt_bp}}
                                         &nbsp;&nbsp;&nbsp;{{'RS'}}: {{clnt_pulse}}
                                       </mat-label>
                                       <mat-label class="matlabel col-4" *ngIf="abdcnsflag">{{'Abdomen'}}: {{clnt_bp}}
                                         &nbsp;&nbsp;&nbsp;{{'CNS'}}: {{clnt_pulse}}
                                       </mat-label> -->
     
     
                                     </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="row">
                           <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-12">
                              <div class="cover_div">
                                 <div class="header_lable">
                                    Investigation details
                                 </div>
                                 <div class="content_cover">
                                    <div class="row">
                                       <div class="col-12">
                                          <mat-label class="matlabel">{{'Language'}}<br>
                                             <mat-select disableOptionCentering class="ipcss" [(ngModel)]="language"
                                                (selectionChange)="languageChange()">
                                                <mat-option value="English">English</mat-option>
                                                <mat-option value="Tamil">Tamil</mat-option>
                                                <mat-option value="Telugu">Telugu</mat-option>
                                                <mat-option value="Kannada">Kannada</mat-option>
                                                <mat-option value="Malayalam">Malayalam</mat-option>
                                                <mat-option value="Oriya">Oriya</mat-option>
                                                <mat-option value="Bengali">Bengali</mat-option>
                                                <mat-option value="Hindi">Hindi</mat-option>
                                             </mat-select>
                                          </mat-label>
                                       </div>
                                       <div class="col-12">
                                          <mat-label class="matlabel" style="margin-top: 10px !important;">
                                             <mat-radio-group class="radiobtngrp" color="primary"
                                                labelPosition="before">
                                                <mat-radio-button value="autoRx" (change)="fillType($event.value)">
                                                   {{'Auto Rx'}}
                                                </mat-radio-button>&nbsp;
                                                <mat-radio-button value="refill" (change)="fillType($event.value)">
                                                   {{'Refill'}}
                                                </mat-radio-button>&nbsp;
                                                <mat-radio-button value="previous" (change)="fillType($event.value)">
                                                   {{'Previous Rx'}}
                                                </mat-radio-button>
                                             </mat-radio-group>
                                          </mat-label>
                                       </div>
                                       <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                          <div class="cover_div">
                                             <div class="header_lable">Complaints</div>
                                             <div class="content_cover">
                                                <div class="row">
                                                   <div class="col-md-6 col-lg-6 col-sm-12 col-xl-12">
                                                      <quill-editor id="editor1" [(ngModel)]="complaints"
                                                         [styles]="{height: 'calc(35vh - 100px)'}" [modules]="modules"
                                                         placeholder="Enter Text"
                                                         (onContentChanged)="changecomplaints($event)">
                                                      </quill-editor>
                                                      <div>
                                                         <!-- Preview -->
                                                         <quill-view-html hidden id="linemgmt"
                                                            [content]="complaints"></quill-view-html>

                                                      </div>
                                                   </div>

                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                          <div class="cover_div">
                                             <div class="header_lable">Diagnosis<span class="required-marker">*</span>
                                             </div>
                                             <div class="content_cover">
                                                <div class="row">
                                                   <div class="col-md-6 col-lg-6 col-sm-12 col-xl-12">
                                                      <quill-editor id="pastill" [(ngModel)]="dis_txt"
                                                         [styles]="{height: 'calc(35vh - 100px)'}" [modules]="modules"
                                                         placeholder="Enter Text"
                                                         (onContentChanged)="changediagnosis($event)">
                                                      </quill-editor>
                                                      <div>
                                                         <!-- Preview -->
                                                         <quill-view-html hidden id="pastill"
                                                            [content]="dis_txt"></quill-view-html>
                                                      </div>
                                                   </div>

                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-12">
                              <div class="cover_div">
                                 <div class="header_lable">
                                    Medicine details
                                 </div>
                                 <div class="content_cover" style="position: relative;">
                                    <a (click)="getmedicine()" class="seacrhicon"><img
                                          src="../../../assets/ui_icons/tablet_icon.svg" height="30px"
                                          width="30px" /></a>

                                    <div class="row" *ngIf="medicineFlag">
                                       <div class="col-xl-2"> </div>
                                       <div class="col-md-12 col-lg-12 col-xl-8" style="margin-top: 15px;">
                                          <div>
                                             <table style="width: 75%; margin: 0 auto;" *ngIf="mediceList.length != 0"
                                                mdbTable datatable [dtOptions]="dtOptions"
                                                class="table table-nowrap table-sm dataTable billlisttable">
                                                <thead>
                                                   <tr>
                                                      <th style="width: 45% !important;">Medicine name</th>
                                                      <th>Quantity</th>
                                                   </tr>
                                                </thead>
                                                <tbody>
                                                   <tr *ngFor="let person of mediceList; let i = index"
                                                      (click)="setMedicine(person.med_id)">
                                                      <td style="font-size: 12px;text-align: left;">{{person.type_name}}
                                                         {{person.med_name}} </td>
                                                      <td style="font-size: 12px;text-align: right;">{{person.quantity}}
                                                      </td>
                                                   </tr>
                                                </tbody>
                                             </table>
                                          </div>
                                       </div>
                                       <div class="col-xl-2"> </div>
                                    </div>

                                    <div class="row" *ngIf="!medicineFlag">
                                       <div class="col-12 col-sm-12 col-md-5 col-lg-6 col-xl-3">
                                          <mat-checkbox color="primary" [(ngModel)]="new_med"
                                             (change)="showMedType(new_med)">
                                          </mat-checkbox>
                                          <mat-label class="matlabel">
                                             {{'New medicine'}}
                                          </mat-label>
                                       </div>

                                       <div class="col-12 col-sm-12 col-md-7 col-lg-6 col-xl-9">
                                          <mat-label class="matlabel" [hidden]="newmed_flag">{{'Medicine type' }}
                                             <input type="text" class="ipcss " required maxlength="50"
                                                [(ngModel)]="medtypetxt" (keyup)="Get_medicine_type($event)"
                                                (focus)="clearmedtype()" aria-label="Number" matInput autocomplete="on"
                                                [matAutocomplete]="auto2" style="max-width: 175px;" />
                                             <mat-autocomplete #auto2="matAutocomplete">
                                                <mat-option id="optionfont" (click)="select_medicine_type(medtype)"
                                                   *ngFor="let medtype of Medical_prescription_array"
                                                   value={{medtype.med_name}}>
                                                   {{medtype.med_name}}
                                                </mat-option>
                                             </mat-autocomplete>
                                          </mat-label>
                                       </div>
                                       <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4"
                                          *ngIf="genericDisplayFlag">
                                          <mat-label class="matlabel">Generic name
                                             <input type="text" class="ipcss " required maxlength="50"
                                                [(ngModel)]="ganericName"
                                                (keyup)="Medicine_name_change($event,'generic')" aria-label="Number"
                                                matInput [matAutocomplete]="auto6" />
                                             <mat-autocomplete #auto6="matAutocomplete" [panelWidth]="'auto'">
                                                <mat-option id="optionfont" (click)="select_medicine_item(medicine)"
                                                   *ngFor="let medicine of Medicne_data" [value]="medicine.name">
                                                   {{medicine.name}}
                                                </mat-option>
                                             </mat-autocomplete>
                                          </mat-label>
                                       </div>
                                       <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4">
                                          <mat-label class="matlabel">{{'Medicine name'}}
                                             <input type="text" class="ipcss " required maxlength="50"
                                                [(ngModel)]="medicine_nametxt"
                                                (keyup)="Medicine_name_change($event,'medicine')" aria-label="Number"
                                                matInput [matAutocomplete]="auto6" />
                                             <mat-autocomplete #auto6="matAutocomplete" [panelWidth]="'auto'">
                                                <mat-option id="optionfont" (click)="select_medicine_item(medicine)"
                                                   *ngFor="let medicine of Medicne_data" [value]="medicine.name">
                                                   {{medicine.name}}
                                                   <span style="font-weight: lighter;"> &nbsp; -
                                                      &nbsp;{{medicine.quantity}}</span>
                                                </mat-option>
                                             </mat-autocomplete>
                                          </mat-label>
                                       </div>

                                       <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4" [hidden]="daydur_show">
                                          <mat-label class="matlabel">{{'Duration'}}</mat-label>
                                          <div class="row">
                                             <div class="col-6 col-xl-6 col-sm-6 col-md-6 col-lg-6">
                                                <input type="text" class="ipcss" matInput required maxlength="5"
                                                   onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                   [(ngModel)]="day_txt" />
                                             </div>

                                             <div class="col-6 col-xl-6 col-sm-6 col-md-6 col-lg-6"
                                                [hidden]="daydur_show">
                                                <mat-select disableOptionCentering class="ipcss "
                                                   (selectionChange)="Duration()" [hidden]="daydur_show"
                                                   [(ngModel)]="daydur_txt">
                                                   <mat-option disabled>Select</mat-option>
                                                   <mat-option value="day(s)">day(s)</mat-option>
                                                   <mat-option value="week(s)">week(s)</mat-option>
                                                   <mat-option value="month(s)">month(s)</mat-option>
                                                </mat-select>
                                             </div>
                                          </div>
                                       </div>

                                       <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-3" [hidden]="intake_show">
                                          <mat-label class="matlabel">{{'Intake'}}<br>
                                             <mat-select disableOptionCentering class="ipcss " required
                                                [(ngModel)]="afterfood_txt">
                                                <mat-option value="Select">Select</mat-option>
                                                <mat-option *ngFor="let intake of intakeOption"
                                                   value={{intake}}>{{intake}}</mat-option>
                                             </mat-select>
                                          </mat-label>
                                       </div>
                                       <div class="col-6 col-sm-6 col-md-3 col-lg-6 col-xl-1" [hidden]="dure_show">
                                          <mat-label [hidden]="dure_show" class="matlabel">{{'Min'}}
                                             <input type="text" class="ipcss " matInput [required]="dure_require"
                                                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                maxlength="3" [(ngModel)]="dure_txt" />
                                          </mat-label>
                                       </div>
                                       <div class="col-6 col-sm-6 col-md-3 col-lg-6 col-xl-1" [hidden]="!dure_show">

                                       </div>
                                       <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-3" [hidden]="mix_show">
                                          <mat-label class="matlabel">{{'Mixed with'}}<br>
                                             <mat-select disableOptionCentering class="ipcss " [(ngModel)]="mixing_txt">
                                                <mat-option value="Select">Select</mat-option>
                                                <mat-option *ngFor="let mixing of mixtype"
                                                   value={{mixing}}>{{mixing}}</mat-option>
                                             </mat-select>
                                          </mat-label>
                                       </div>

                                       <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-2"
                                          [hidden]="intakesession">
                                          <mat-label class="matlabel">{{'Intake mode'}}<br>
                                             <mat-select disableOptionCentering class="ipcss " required
                                                [(ngModel)]="intake_mode">
                                                <mat-option disabled>Select</mat-option>
                                                <mat-option *ngFor="let intake of intakemodes"
                                                   value={{intake.intake_mode_desc}}>
                                                   {{intake.intake_mode_desc}}</mat-option>
                                             </mat-select>
                                          </mat-label>
                                       </div>

                                       <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-2"
                                          [hidden]="intakesession">
                                          <mat-label class="matlabel">{{'Intake session'}}<br>
                                             <mat-select disableOptionCentering class="ipcss " required
                                                [(ngModel)]="intake_txt">
                                                <mat-option disabled>Select</mat-option>
                                                <mat-option *ngFor="let intake of Get_Intake_array"
                                                   (click)="sesssion_basechange()" value={{intake.description}}>
                                                   {{intake.description}}</mat-option>
                                             </mat-select>
                                          </mat-label>
                                       </div>

                                       <div [hidden]="morn_eventxt" class="col-3 col-sm-3 col-md-3 col-lg-2 col-xl-2">
                                          <div [hidden]="mor_lable">
                                             <mat-label class="matlabel input">{{'Mor'}}
                                                <input type="text" class="ipcss " matInput [required]="morning_req"
                                                   [(ngModel)]="mor" [disabled]="morning_dis" maxlength="5"
                                                   onkeypress="return event.charCode == 32 || event.charCode == 46 || event.charCode == 47 || (event.charCode >= 48 && event.charCode <= 57)" />
                                             </mat-label>
                                          </div>

                                          <div [hidden]="quan_lable">
                                             <mat-label class="matlabel">{{'Qty'}}
                                                <input type="text" class="ipcss " matInput [required]="morning_req"
                                                   maxlength="5"
                                                   onkeypress="return event.charCode == 32 || event.charCode == 46 || event.charCode == 47 || (event.charCode >= 48 && event.charCode <= 57)"
                                                   [disabled]="morning_dis" [(ngModel)]="mor" />
                                             </mat-label>
                                          </div>
                                       </div>

                                       <div class="col-3 col-sm-3 col-md-3 col-lg-2 col-xl-2 " [hidden]="after_eventxt">
                                          <span class="iocn_position">-</span>
                                          <mat-label class="matlabel">{{'Aft'}}
                                             <input type="text" class="ipcss " matInput [disabled]="afternoon_dis"
                                                maxlength="5"
                                                onkeypress="return event.charCode == 32 || event.charCode == 46 || event.charCode == 47 || (event.charCode >= 48 && event.charCode <= 57)"
                                                [required]="afternoon_req" [(ngModel)]="aft" />
                                          </mat-label>
                                       </div>

                                       <div class="col-3 col-sm-3 col-md-3 col-lg-2 col-xl-2" [hidden]="eve_eventxt">
                                          <span class="iocn_position ">-</span>
                                          <mat-label class="matlabel">{{'Eve'}}
                                             <input type="text" class="ipcss " matInput [disabled]="even_dis"
                                                [required]="evening_req" maxlength="5"
                                                onkeypress="return event.charCode == 32 || event.charCode == 46 || event.charCode == 47 || (event.charCode >= 48 && event.charCode <= 57)"
                                                [(ngModel)]="eve" />
                                          </mat-label>
                                       </div>

                                       <div class="col-3 col-sm-3 col-md-3 col-lg-2 col-xl-2" [hidden]="night_eventxt">
                                          <span class="iocn_position">-</span>
                                          <mat-label class="matlabel">{{'Ngt'}}
                                             <input type="text" class="ipcss " matInput [disabled]="ngt_dis"
                                                [required]="night_req" maxlength="5"
                                                onkeypress="return event.charCode == 32 || event.charCode == 46 || event.charCode == 47 || (event.charCode >= 48 && event.charCode <= 57)"
                                                [(ngModel)]="ngt" />
                                          </mat-label>
                                       </div>

                                       <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                          <mat-label class="matlabel">{{'Note'}}<br>
                                             <textarea class="ipcss widthappt" id="myInput" maxlength="250"
                                                [(ngModel)]="remark_txt"></textarea>
                                          </mat-label>
                                       </div>
                                       <div *ngIf="Med_addbtn"
                                          class="col-12 col-sm-12 col-md-3 col-lg-2 col-xl-2 my-auto"
                                          style="text-align: right;">
                                          <a (click)="Medicalpres_add()"><img
                                                src="../../../assets/ui_icons/buttons/add_button.svg"
                                                class="saveimgbtn_inpatinfo" /></a>
                                          <a (click)="concernformprint('concern')"  *ngIf="highRiskPresent" ><img src="../../../assets/ui_icons/buttons/confirm_button_icons.svg"
                                                   class="saveimgbtn_inpatinfo" /></a>
                                       </div>
                                       <!-- <div *ngIf="highRiskPresent" class="col-12 col-sm-12 col-md-3 col-lg-2 col-xl-2 my-auto"
                                       style="margin: 26px !important;">
                                       <a style="margin:0 5px; position: relative;"  *ngIf="highRiskPresent" (click)="concernformprint('concern')"><img style="width: 30px;"
                                         src="path/to/your/image.jpg" />
                                     </a>
                                     </div> -->
                                    </div>
                                 </div>
                              </div>
                           </div>

                           <div class="col-12" style="margin: 20px 0;">
                              <div class="col-12 dig_table_overflow" [hidden]="Medicine_table">
                                 <div class="table-responsive">
                                    <table id="tbl" *ngIf="listProducts.length !=0 ">
                                       <thead>
                                          <tr>
                                             <th style="width: 11%;">{{'Medicine type'}}</th>
                                             <th style="width: 5%;"></th>
                                             <th style="width: 16%;">{{'Medicine name'}}</th>
                                             <th style="width: 7%;">{{'Duration'}}</th>
                                             <th style="width: 12%;">{{'Intake session'}}</th>
                                             <th style="width: 15%;">{{'Mode'}}</th>
                                             <th style="width: 10%;">{{'Frequency'}}</th>
                                             <th style="width: 15%;">{{'Intake'}}</th>
                                             <th style="width: 22%;">{{'Note'}}</th>
                                             <th>{{'Action'}}</th>
                                          </tr>
                                       </thead>
                                       <tbody>
                                          <tr *ngFor="let product of listProducts">
                                             <td style="text-align: left;">{{product.short_name}}</td>
                                             <td style="width:40px;text-align:left">
                                                <span class="color-square" data-toggle="tooltip" data-placement="top"
                                                  title="Look alike" [hidden]="!product.look_alikecolor"
                                                  [ngStyle]="{'background-color': product.look_alikecolor, 'position': 'relative','left': '5px'}">
                                                </span>
                                                <span class="color-square" data-toggle="tooltip" data-placement="top"
                                                  title="Sound alike" [hidden]="!product.sound_alikecolor"
                                                  [ngStyle]="{'background-color': product.sound_alikecolor, 'position': 'relative','left': '10px'}">
                                                </span>
                                                <span class="color-square" data-toggle="tooltip" data-placement="top"
                                                  title="High risk" [hidden]="!product.high_riskcolor"
                                                  [ngStyle]="{'background-color': product.high_riskcolor , 'position': 'relative','left': '15px'}">
                                                </span>
                                                <span class="color-square" data-toggle="tooltip" data-placement="top"
                                                  title="OT / ICU" [hidden]="!product.ward_onlycolor"
                                                  [ngStyle]="{'background-color': product.ward_onlycolor , 'position': 'relative','left':  '20px'}">
                                                </span>
                                              </td>
                                             <td style="text-align: left;">
                                                {{product.drug_name}}<br><br><span>{{product.genericname}}</span></td>
                                             <td style="text-align: left;">{{product.days}} {{product.period }}</td>
                                             <td>{{product.every_six}}</td>
                                             <td style="text-align: left;">{{product.intake_mode }}</td>
                                             <td style="text-align: left;">{{product.frequency }}</td>
                                             <td style="text-align: left;">{{product.dure_txt_table}}
                                                {{product.drug_intake
                                                }} {{product.mixval}}</td>
                                             <td style="text-align: left;">{{product.remarks}}</td>
                                             <td>
                                                <a
                                                   (click)="editMedicalPres(product.drug_id,product.rowid,product.drug_name,product.genericname)">
                                                   <img src="../../../assets/ui_icons/buttons/pencil_icon.svg"
                                                      class="editDelicon1" /></a>&nbsp;&nbsp;
                                                <a (click)="Delete_medicalpres(product.med_typetxt,product.drug_name)"><img
                                                      src="../../../assets/ui_icons/buttons/trash_icon.svg"
                                                      class="editDelicon1" /></a>
                                             </td>
                                          </tr>
                                       </tbody>
                                    </table>
                                 </div>
                              </div>
                           </div>
                           <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4">
                              <div class="cover_div">
                                 <div class="header_lable">
                                    Pharmacy details
                                 </div>
                                 <div class="content_cover">
                                    <div class="row">
                                       <div class="col-12">
                                          <mat-label class="matlabel">{{'Preferred pharmacy' }}
                                             <input type="text" class="ipcss" maxlength="50"
                                                [(ngModel)]="prfer_pharmatxt" (keyup)="Prefer_pharmacy_change($event)"
                                                aria-label="Number" matInput [matAutocomplete]="auto1" />
                                             <mat-autocomplete #auto1="matAutocomplete">
                                                <mat-option id="optionfont"
                                                   (click)="Pharma_medicine_name(prfer_pharmatxt)"
                                                   *ngFor="let pharma of Pharmanamelist" [value]="pharma">
                                                   {{pharma}}
                                                </mat-option>
                                             </mat-autocomplete>
                                          </mat-label>
                                       </div>
                                       <div class="col-12">
                                          <mat-label class="matlabel">{{'Preferred pharmacy address' }}<br>
                                             <textarea class=" ipcss  txtarea" disabled="true" id="myInput" rows="1"
                                                [(ngModel)]="prefer_add"></textarea>
                                          </mat-label>
                                       </div>
                                       <div class="col-12">
                                          <div>
                                             <mat-label class="matlabel">
                                                <mat-checkbox labelPosition="before" [(ngModel)]="order_now"> {{'Would u like to place order now' }} </mat-checkbox>
                                             </mat-label><br>
                                             <mat-label class="matlabel"><strong>{{"Dr."+doctorname}} <span
                                                      style="font-size: 10px;">{{qualification}}</span></strong></mat-label>
                                             <br>
                                             <mat-label class="matlabel"> ({{licence_code}})</mat-label><br>
                                          </div>
                                          <div>
                                             <mat-label class="matlabel">{{'Find your prescription online at'}}
                                                www.tervys.com
                                             </mat-label><br>
                                             <mat-label class="matlabel"><b><i>This is digitally generated
                                                      Prescription</i></b>
                                             </mat-label>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>

                           </div>
                           <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-8">
                              <div class="cover_div" style="padding-bottom: 17px;">
                                 <div class="header_lable">
                                    Instructions
                                 </div>
                                 <div class="content_cover">
                                    <div class="row">
                                       <div class="col-12">
                                          <quill-editor id="editor" [(ngModel)]="instructions" [style]="editorStyle"
                                             [modules]="modules" placeholder="Enter Text"
                                             (onSelectionChanged)="onSelectionChanged($event)"
                                             (onContentChanged)="onContentChanged($event)"></quill-editor>
                                       </div>
                                       <div class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3" >
                                          <mat-label class="matlabel">{{'Next visit' }}<br>
                                            <input type="date" class="ipcss widthappt" id="appt_date"
                                              (change)="OnDateMatePicker(next_datetxt)" [(ngModel)]="next_datetxt"
                                              min={{currentDate}} #matInput style="width: 140px;">
                                          </mat-label>
                                        </div>
                                        <div class="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3" >
                                          <mat-label class="matlabel">{{'Days' }}<br>
                                            <input type="number" class="ipcss_date widthappt" (keyup)="Noofdays(no_of_days)"
                                              [(ngModel)]="no_of_days" #matInput style="width: 140px;">
                                          </mat-label>
                                        </div>                                   

                                    </div>
                                    <div class="row">
                                       <mat-label class="matlabel">
                                          <mat-checkbox labelPosition="before" [(ngModel)]="book_app_now"
                                             (change)="checkNextVisit()"> {{'Would u like to book appointment for next visit? ' |translate}} </mat-checkbox>
                                       </mat-label><br>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <br><br><br>
            </mdb-card-body>
         </mdb-card>
      </div>
   </div>

   <div [hidden]="true">
      <div *ngIf="print_same_page">
      <div #printlogowithname id="printlogowithname"
         style="background-image: url({{bg_image}}); background-position: center;">
         <div *ngIf="!print_same_age">
            <div *ngIf="listProducts.length != 0 && print_view_flag">
               <table style="margin-left:50px;margin-top:70px;">
                  <tr>
                     <td style="width: 450px; vertical-align: top;">
                        <img alt="image" src={{hosp_img}} [style]="preslogo_style">
                        <p style="margin-top: -100px;margin-left: 110px;">{{dct_hospital}}</p>
                        <p style="margin-left: 110px;">{{doct_address}},{{doct_location}}</p>
                        <p style="margin-left: 110px;">{{doct_city}}</p>
                        <p style="margin-left: 110px;">{{doct_state}}</p>
                     </td>
                     <td style="width: 50px;margin-top: 165px;">&nbsp;</td>
                     <td style="width: 300px;vertical-align: top;">
                        <p><b>{{'Doctor'|translate}} : {{"Dr."+doct_name}} {{qualification}}</b></p>
                        <p><b>{{'Date'|translate}} : </b>{{Appoint_Date}}</p>
                        <p *ngIf="ref_flag"><b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p>
                     </td>
                  </tr>
               </table>
               <br /><br />
               <table style="margin-left: 50px; margin-top:20px;">
                  <tr>
                     <td style="width: 500px;">
                        <p><b>{{'Name'|translate}} :</b> {{doct_clientname}}</p>
                        <p><b>{{'Mobile'}} :</b> {{mobile}}</p>
                        <p><b>{{'Age'|translate}} :</b> {{client_age}}
                           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{{'Gender'|translate}}:
                           </b>{{client_gender}} </p>
                        <p *ngIf="oberse_med!=undefined"><b>{{'Investigation'|translate}} :</b> {{oberse_med}}</p>
                        <p *ngIf="disease_flag"><b>Disease : </b>{{disease_name}}</p>

                     </td>
                     <td style="width: 300px;vertical-align: top;">
                        <p><b>{{'Date'|translate}} : </b>{{Appoint_Date}}</p>
                        <p *ngIf="ref_flag"><b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p>
                        <p *ngIf="bp_flag"><b>{{'BP'|translate}}: </b>{{clnt_bp}}
                           &nbsp;&nbsp;&nbsp;<b>{{'Pulse'|translate}}:
                           </b>{{clnt_pulse}}</p>
                        <p *ngIf="height_flag"><b>{{'Height'|translate}}: </b>{{height}} &nbsp;&nbsp;&nbsp;<span
                              *ngIf="weight_flag"><b>{{'Weight'|translate}}: </b>
                              {{weight}}</span></p>
                        <p *ngIf="temparature_flag"><b> {{'Temperature'|translate}}: </b>{{temparature}} </p>
                        <p *ngIf="ortho_flag"><b>{{'CVS'|translate}}: </b>{{clnt_cvs}}
                           &nbsp;&nbsp;&nbsp;<b>{{'RS'|translate}}: </b>
                           {{clnt_rs}}</p>
                        <p *ngIf="ortho_flag"><b> {{'Kidney'|translate}}: </b>{{clnt_kidney}}</p>
                     </td>
                  </tr>
               </table>
               <div style="margin-left: 50px;" *ngIf="print_view_flag && listProducts.length != 0">
                  <p><b>{{'Diagnosis' |translate}}: </b>{{dis_txt}}</p>
               </div>
               <br />
               <table *ngIf="listProducts.length != 0"
                  style="margin-left: 50px; border: 1px solid black;border-collapse: collapse;">
                  <tr>
                     <th [style]="med_style">
                        <b>{{'Medicine'|translate}}</b>
                     </th>
                     <th [style]="dosage_style">
                        <b>{{'Dosage'|translate}}</b>
                     </th>
                     <th [style]="dur_style">
                        <b>{{'Duration'|translate}}</b>
                     </th>
                     <th [style]="dur_style">
                        <b>{{'Intake mode'|translate}}</b>
                     </th>
                     <th [style]="freq_style">
                        <b>{{'Frequency'|translate}}</b>
                     </th>
                     <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">&nbsp;</th>
                     <th style="width: 50px;border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">
                        <b>{{'Note'|translate}}</b>
                     </th>
                  </tr>
                  <tr *ngFor="let product of listProducts">
                     <td style="border: 1px solid black;border-collapse: collapse;">
                        {{product.short_name}} {{product.drug_name}}
                     </td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                        {{product.every_six}}
                     </td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{product.days}}
                        {{product.period|translate}}</td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{product.days}}
                        {{product.intake_mode|translate}}</td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                        {{product.frequency
                        |
                        translate}}</td>
                     <td style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">
                        {{product.dure_txt_table}}
                        {{product.drug_intake|translate}}</td>
                     <td style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">
                        {{product.remarks}}</td>
                  </tr>
               </table>

               <div style="margin-left: 50px;">
                  <p *ngIf="instructions!=undefined"><b>{{'Instructions' |translate}}: </b>
                     <span>
                        <div class="matlabel" [innerHtml]="print_instruction"></div>
                     </span>
                  </p>
               </div>

               <div style="margin-left: 50px;" *ngIf="treatflag">
                  <p><b>{{'Treatment' |translate}}: </b>
                     <span>
                        <div class="matlabel" [innerHtml]="treatment"></div>
                     </span>
                  </p>
               </div>

               <div style="margin-left: 50px;">
                  <p *ngIf="next_txt!=undefined"><b>{{'Next visit' |translate}}: </b>{{next_txt | date : "dd-MM-yyyy"}}</p>
               </div>
               <br />
               <div>
                  <p style="margin-left: 50px;font-size: 13px;"> <strong>{{"Dr."+doct_name}} {{qualification}}</strong>
                  </p>
                  <p style="margin-left: 50px;font-size: 13px">({{licence_code}})</p>
                  <p style="margin-left: 50px;font-size: 13px">Find your prescription online at <i>www.tervys.com</i>
                  </p>
                  <p style="margin-left: 50px;font-size: 13px"><b><i>This is digitally generated prescription</i></b>
                  </p>
                  <br />
               </div>
            </div>
            <div *ngIf="print_diag">
               <div class="page-break" *ngIf="listProducts.length != 0"></div>
               <table style="margin-left:50px;margin-top:70px;">
                  <tr>
                     <td style="width: 450px; vertical-align: top;">
                        <img alt="image" src={{hosp_img}} [style]="preslogo_style">
                        <p style="margin-top: -100px;margin-left: 110px;">{{dct_hospital}}</p>
                        <p style="margin-left: 110px;">{{doct_address}},{{doct_location}}</p>
                        <p style="margin-left: 110px;">{{doct_city}}</p>
                        <p style="margin-left: 110px;">{{doct_state}}</p>
                     </td>
                     <td style="width: 50px;margin-top: 165px;">&nbsp;</td>
                     <td style="width: 300px;vertical-align: top;">
                        <p><b>{{'Doctor'|translate}} : {{"Dr."+doct_name}} {{qualification}}</b></p>
                        <p><b>{{'Date'|translate}} : </b>{{Appoint_Date}}</p>
                        <p *ngIf="ref_flag"><b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p>
                     </td>
                  </tr>
               </table>
               <br /><br />
               <table style="margin-left: 50px; margin-top:20px;">
                  <tr>
                     <td style="width: 500px;">
                        <p><b>{{'Name'|translate}} :</b> {{doct_clientname}}</p>
                        <p><b>{{'Mobile'}} :</b> {{mobile}}</p>
                        <p><b>{{'Age'|translate}} :</b> {{client_age}}</p>
                        <p><b>{{'Gender'|translate}}:</b> {{client_gender}} </p>
                     </td>
                     <td style="width: 300px;vertical-align: top;">
                        <p><b>{{'Date'|translate}} : </b>{{Appoint_Date}}</p>
                        <p *ngIf="ref_flag"><b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p>
                     </td>
                  </tr>
               </table>
               <div style="margin-left: 50px;" *ngIf="print_diag">
                  <p><b>Diagnostic Test</b></p>
                  <div *ngIf="pritn_blood_flag">
                     <p id="print_blood_head"><b>{{blood_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{blood_test_content}}
                     </p>
                  </div>
                  <div *ngIf="pritn_scan_flag">
                     <p id="print_blood_head"><b>{{scan_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{scan_test_content}}
                     </p>
                  </div>
                  <div *ngIf="pritn_us_flag">
                     <p id="print_blood_head"><b>{{us_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{us_test_content}}
                     </p>
                  </div>
                  <div *ngIf="pritn_xray_flag">
                     <p id="print_blood_head"><b>{{xray_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{xray_test_content}}
                     </p>
                  </div>
                  <div *ngIf="pritn_bio_flag">
                     <p id="print_blood_head"><b>{{bio_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{bio_test_content}}
                     </p>
                  </div>
                  <div *ngIf="pritn_ut_flag">
                     <p id="print_blood_head"><b>{{ut_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{ut_test_content}}
                     </p>
                  </div>
                  <div *ngIf="pritn_ft_flag">
                     <p id="print_blood_head"><b>{{ft_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{ft_test_content}}
                     </p>
                  </div>
                  <div *ngIf="pritn_culture_flag">
                     <p id="print_blood_head"><b>{{culture_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{culture_test_content}}
                     </p>
                  </div>
                  <!-- <div style="margin-left: 50px;">
                       <p *ngIf="next_txt!=undefined"><b>{{'Next visit' |translate}}: </b>{{next_txt}}</p>
                    </div> -->
                  <br />
                  <div style="margin-left: 50px;">
                     <p style="font-size: 13px;"> <strong>{{"Dr."+doct_name}}
                           {{qualification}}</strong>
                     </p>
                     <p style="font-size: 13px">({{licence_code}})</p>
                     <p style="font-size: 13px">Find your prescription online at <i>www.tervys.com</i>
                     </p>
                     <p style="font-size: 13px"><b><i>This is digitally generated prescription</i></b>
                     </p>
                     <br />
                  </div>
               </div>
            </div>
         </div>
         <div *ngIf="print_same_age">
            <div>
               <table style="margin-left:50px;margin-top:70px;">
                  <tr>
                     <td style="width: 450px; vertical-align: top;">
                        <img alt="image" src={{hosp_img}} [style]="preslogo_style">
                        <p style="margin-top: -100px;margin-left: 110px;">{{dct_hospital}}</p>
                        <p style="margin-left: 110px;">{{doct_address}},{{doct_location}}</p>
                        <p style="margin-left: 110px;">{{doct_city}}</p>
                        <p style="margin-left: 110px;">{{doct_state}}</p>
                     </td>
                     <td style="width: 50px;margin-top: 165px;">&nbsp;</td>
                     <td style="width: 300px;vertical-align: top;">
                        <p><b>{{'Doctor'|translate}} : {{"Dr."+doct_name}} {{qualification}}</b></p>
                        <p><b>{{'Date'|translate}} : </b>{{Appoint_Date}}</p>
                        <p *ngIf="ref_flag"><b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p>
                     </td>
                  </tr>
               </table>
               <br /><br />
               <table style="margin-left: 50px; margin-top:20px;">
                  <tr>
                     <td style="width: 500px;">
                        <p><b>{{'Name'|translate}} :</b> {{doct_clientname}}</p>
                        <p><b>{{'Mobile'}} :</b> {{mobile}}</p>
                        <p><b>{{'Age'|translate}} :</b> {{client_age}}
                           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{{'Gender'|translate}}:
                           </b>{{client_gender}} </p>
                        <p *ngIf="oberse_med!=undefined"><b>{{'Investigation'|translate}} :</b> {{oberse_med}}</p>
                        <p *ngIf="disease_flag"><b>Disease : </b>{{disease_name}}</p>

                     </td>
                     <td style="width: 300px;vertical-align: top;">
                        <p><b>{{'Date'|translate}} : </b>{{Appoint_Date}}</p>
                        <p *ngIf="ref_flag"><b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p>
                        <p *ngIf="bp_flag"><b>{{'BP'|translate}}: </b>{{clnt_bp}}
                           &nbsp;&nbsp;&nbsp;<b>{{'Pulse'|translate}}:
                           </b>{{clnt_pulse}}</p>
                        <p *ngIf="height_flag"><b>{{'Height'|translate}}: </b>{{height}} &nbsp;&nbsp;&nbsp;<span
                              *ngIf="weight_flag"><b>{{'Weight'|translate}}: </b>
                              {{weight}}</span></p>
                        <p *ngIf="temparature_flag"><b> {{'Temperature'|translate}}: </b>{{temparature}} </p>
                        <p *ngIf="ortho_flag"><b>{{'CVS'|translate}}: </b>{{clnt_cvs}}
                           &nbsp;&nbsp;&nbsp;<b>{{'RS'|translate}}: </b>
                           {{clnt_rs}}</p>
                        <p *ngIf="ortho_flag"><b> {{'Kidney'|translate}}: </b>{{clnt_kidney}}</p>
                     </td>
                  </tr>
               </table>
               <div style="margin-left: 50px;" *ngIf="print_view_flag && listProducts.length != 0">
                  <p><b>{{'Diagnosis' |translate}}: </b>{{dis_txt}}</p>
               </div>
               <br />
               <table *ngIf="listProducts.length != 0"
                  style="margin-left: 50px; border: 1px solid black;border-collapse: collapse;">
                  <tr>
                     <th [style]="med_style">
                        <b>{{'Medicine'|translate}}</b>
                     </th>
                     <th [style]="dosage_style">
                        <b>{{'Dosage'|translate}}</b>
                     </th>
                     <th [style]="dur_style">
                        <b>{{'Duration'|translate}}</b>
                     </th>
                     <th [style]="dur_style">
                        <b>{{'Intake mode'|translate}}</b>
                     </th>
                     <th [style]="freq_style">
                        <b>{{'Frequency'|translate}}</b>
                     </th>
                     <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">&nbsp;</th>
                     <th style="width: 50px;border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">
                        <b>{{'Note'|translate}}</b>
                     </th>
                  </tr>
                  <tr *ngFor="let product of listProducts">
                     <td style="border: 1px solid black;border-collapse: collapse;">
                        {{product.short_name}} {{product.drug_name}}
                     </td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                        {{product.every_six}}
                     </td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{product.days}}
                        {{product.period|translate}}</td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{product.days}}
                        {{product.intake_mode|translate}}</td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                        {{product.frequency
                        |
                        translate}}</td>
                     <td style="border: 1px solid black;border-collapse: collapse;">{{product.dure_txt_table}}
                        {{product.drug_intake|translate}}</td>
                     <td style="border: 1px solid black;border-collapse: collapse;">{{product.remarks}}</td>
                  </tr>
               </table>
            </div>
            <div style="margin-left: 50px;" *ngIf="print_diag">
               <p><b>Diagnostic Test</b></p>
               <div *ngIf="pritn_blood_flag">
                  <p id="print_blood_head"><b>{{blood_test_head}}</b></p>
                  <p style="margin-left: 20px;margin-top:0;">
                     {{blood_test_content}}
                  </p>
               </div>
               <div *ngIf="pritn_scan_flag">
                  <p id="print_blood_head"><b>{{scan_test_head}}</b></p>
                  <p style="margin-left: 20px;margin-top:0;">
                     {{scan_test_content}}
                  </p>
               </div>
               <div *ngIf="pritn_us_flag">
                  <p id="print_blood_head"><b>{{us_test_head}}</b></p>
                  <p style="margin-left: 20px;margin-top:0;">
                     {{us_test_content}}
                  </p>
               </div>
               <div *ngIf="pritn_xray_flag">
                  <p id="print_blood_head"><b>{{xray_test_head}}</b></p>
                  <p style="margin-left: 20px;margin-top:0;">
                     {{xray_test_content}}
                  </p>
               </div>
               <div *ngIf="pritn_bio_flag">
                  <p id="print_blood_head"><b>{{bio_test_head}}</b></p>
                  <p style="margin-left: 20px;margin-top:0;">
                     {{bio_test_content}}
                  </p>
               </div>
               <div *ngIf="pritn_ut_flag">
                  <p id="print_blood_head"><b>{{ut_test_head}}</b></p>
                  <p style="margin-left: 20px;margin-top:0;">
                     {{ut_test_content}}
                  </p>
               </div>
               <div *ngIf="pritn_ft_flag">
                  <p id="print_blood_head"><b>{{ft_test_head}}</b></p>
                  <p style="margin-left: 20px;margin-top:0;">
                     {{ft_test_content}}
                  </p>
               </div>
               <div *ngIf="pritn_culture_flag">
                  <p id="print_blood_head"><b>{{culture_test_head}}</b></p>
                  <p style="margin-left: 20px;margin-top:0;">
                     {{culture_test_content}}
                  </p>
               </div>
            </div>
            <div style="margin-left: 50px;">
               <p *ngIf="instructions!=undefined"><b>{{'Instructions' |translate}}: </b>
                  <span>
                     <div class="matlabel" [innerHtml]="print_instruction"></div>
                  </span>
               </p>
            </div>

            <div style="margin-left: 50px;" *ngIf="treatflag">
               <p><b>{{'Treatment' |translate}}: </b>
                  <span>
                     <div class="matlabel" [innerHtml]="treatment"></div>
                  </span>
               </p>
            </div>

            <div style="margin-left: 50px;">
               <p *ngIf="next_txt!=undefined"><b>{{'Next visit' |translate}}: </b>{{next_txt | date : "dd-MM-yyyy"}}</p>
            </div>
            <br />
            <div>
               <p style="margin-left: 50px;font-size: 13px;"> <strong>{{"Dr."+doct_name}} {{qualification}}</strong>
               </p>
               <p style="margin-left: 50px;font-size: 13px">({{licence_code}})</p>
               <p style="margin-left: 50px;font-size: 13px">Find your prescription online at <i>www.tervys.com</i></p>
               <p style="margin-left: 50px;font-size: 13px"><b><i>This is digitally generated prescription</i></b></p>
               <br />
            </div>
         </div>
         <div *ngIf="!print_view_flag">
            <div *ngIf="listProducts.length != 0">
               <table style="margin-left:50px;margin-top:70px;">
                  <tr>
                     <td style="width: 450px; vertical-align: top;">
                        <img alt="image" src={{hosp_img}} [style]="preslogo_style">
                        <p style="margin-top: -100px;margin-left: 110px;">{{dct_hospital}}</p>
                        <p style="margin-left: 110px;">{{doct_address}},{{doct_location}}</p>
                        <p style="margin-left: 110px;">{{doct_city}}</p>
                        <p style="margin-left: 110px;">{{doct_state}}</p>
                     </td>
                     <td style="width: 50px;margin-top: 165px;">&nbsp;</td>
                     <td style="width: 300px;vertical-align: top;">
                        <p><b>{{'Doctor'|translate}} : {{"Dr."+doct_name}} {{qualification}}</b></p>
                        <p><b>{{'Date'|translate}} : </b>{{Appoint_Date}}</p>
                        <p *ngIf="ref_flag"><b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p>
                     </td>
                  </tr>
               </table>
               <br /><br />
               <table style="margin-left: 50px; margin-top:20px;">
                  <tr>
                     <td style="width: 500px;">
                        <p><b>{{'Name'|translate}} :</b> {{doct_clientname}}</p>
                        <p><b>{{'Age'|translate}} :</b> {{client_age}}
                           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{{'Gender'|translate}}:
                           </b>{{client_gender}} </p>
                        <p *ngIf="oberse_med!=undefined"><b>{{'Investigation'|translate}} :</b> {{oberse_med}}</p>
                        <p *ngIf="disease_flag"><b>Disease : </b>{{disease_name}}</p>

                     </td>
                     <td style="width: 300px;vertical-align: top;">
                        <p><b>{{'Date'|translate}} : </b>{{Appoint_Date}}</p>
                        <p *ngIf="ref_flag"><b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p>
                        <p *ngIf="bp_flag"><b>{{'BP'|translate}}: </b>{{clnt_bp}}
                           &nbsp;&nbsp;&nbsp;<b>{{'Pulse'|translate}}:
                           </b>{{clnt_pulse}}</p>
                        <p *ngIf="height_flag"><b>{{'Height'|translate}}: </b>{{height}} &nbsp;&nbsp;&nbsp;<span
                              *ngIf="weight_flag"><b>{{'Weight'|translate}}: </b>
                              {{weight}}</span></p>
                        <p *ngIf="temparature_flag"><b> {{'Temperature'|translate}}: </b>{{temparature}} </p>
                        <p *ngIf="ortho_flag"><b>{{'CVS'|translate}}: </b>{{clnt_cvs}}
                           &nbsp;&nbsp;&nbsp;<b>{{'RS'|translate}}: </b>
                           {{clnt_rs}}</p>
                        <p *ngIf="ortho_flag"><b> {{'Kidney'|translate}}: </b>{{clnt_kidney}}</p>
                     </td>
                  </tr>
               </table>
               <div style="margin-left: 50px;" *ngIf="print_view_flag && listProducts.length != 0">
                  <p><b>{{'Diagnosis' |translate}}: </b>{{dis_txt}}</p>
               </div>
               <br />
               <table *ngIf="listProducts.length != 0"
                  style="margin-left: 50px; border: 1px solid black;border-collapse: collapse;">
                  <tr>
                     <th [style]="med_style">
                        <b>{{'Medicine'|translate}}</b>
                     </th>
                     <th [style]="dosage_style">
                        <b>{{'Dosage'|translate}}</b>
                     </th>
                     <th [style]="dur_style">
                        <b>{{'Duration'|translate}}</b>
                     </th>
                     <th [style]="dur_style">
                        <b>{{'Intake mode'|translate}}</b>
                     </th>
                     <th [style]="freq_style">
                        <b>{{'Frequency'|translate}}</b>
                     </th>
                     <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">&nbsp;</th>
                     <th style="width: 50px;border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">
                        <b>{{'Note'|translate}}</b>
                     </th>
                  </tr>
                  <tr *ngFor="let product of listProducts">
                     <td style="border: 1px solid black;border-collapse: collapse;">
                        {{product.short_name}} {{product.drug_name}}
                     </td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                        {{product.every_six}}
                     </td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{product.days}}
                        {{product.period|translate}}</td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{product.days}}
                        {{product.intake_mode|translate}}</td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                        {{product.frequency
                        |
                        translate}}</td>
                     <td style="border: 1px solid black;border-collapse: collapse;">{{product.dure_txt_table}}
                        {{product.drug_intake|translate}}</td>
                     <td style="border: 1px solid black;border-collapse: collapse;">{{product.remarks}}</td>
                  </tr>
               </table>

               <div style="margin-left: 50px;">
                  <p *ngIf="instructions!=undefined"><b>{{'Instructions' |translate}}: </b>
                     <span>
                        <div class="matlabel" [innerHtml]="print_instruction"></div>
                     </span>
                  </p>
               </div>

               <div style="margin-left: 50px;" *ngIf="treatflag">
                  <p><b>{{'Treatment' |translate}}: </b>
                     <span>
                        <div class="matlabel" [innerHtml]="treatment"></div>
                     </span>
                  </p>
               </div>

               <div style="margin-left: 50px;">
                  <p *ngIf="next_txt!=undefined"><b>{{'Next visit' |translate}}: </b>{{next_txt | date : "dd-MM-yyyy"}}</p>
               </div>
               <br />
               <div>
                  <p style="margin-left: 50px;font-size: 13px;"> <strong>{{"Dr."+doct_name}} {{qualification}}</strong>
                  </p>
                  <p style="margin-left: 50px;font-size: 13px">({{licence_code}})</p>
                  <p style="margin-left: 50px;font-size: 13px">Find your prescription online at <i>www.tervys.com</i>
                  </p>
                  <p style="margin-left: 50px;font-size: 13px"><b><i>This is digitally generated prescription</i></b>
                  </p>
                  <br />
               </div>
            </div>
         </div>
      </div>

      <div #printbanner id="printbanner" style="background-image: url({{bg_image}}); background-position: center;">
         <div *ngIf="!print_same_age">
            <div *ngIf="listProducts.length != 0 && print_view_flag">
               <table style="margin-left:10px;margin-top:10px;margin-right: 10px;">
                  <tr>
                     <td>
                        <img alt="image" src={{hptl_pres_logo}} [style]="preslogo_style">
                     </td>
                  </tr>
                  <tr>
                     <td colspan="2">
                        <div [innerHTML]="headerstyle"></div>
                     </td>
                  </tr>
               </table>
               <br /><br />
               <table style="margin-left: 50px; margin-top:20px;">
                  <tr>
                     <td style="width: 500px;">
                        <p><b>{{'Name'|translate}} :</b> {{doct_clientname}}</p>
                        <p><b>{{'Mobile'}} :</b> {{mobile}}</p>
                        <p><b>{{'Age'|translate}} :</b> {{client_age}}
                           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{{'Gender'|translate}}:
                           </b>{{client_gender}} </p>
                        <p *ngIf="oberse_med!=undefined"><b>{{'Investigation'|translate}} :</b> {{oberse_med}}</p>
                        <p *ngIf="disease_flag"><b>Disease : </b>{{disease_name}}</p>

                     </td>
                     <td style="width: 300px;vertical-align: top;">
                        <p><b>{{'Date'|translate}} : </b>{{Appoint_Date}}</p>
                        <p *ngIf="ref_flag"><b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p>
                        <p *ngIf="bp_flag"><b>{{'BP'|translate}}: </b>{{clnt_bp}}
                           &nbsp;&nbsp;&nbsp;<b>{{'Pulse'|translate}}:
                           </b>{{clnt_pulse}}</p>
                        <p *ngIf="height_flag"><b>{{'Height'|translate}}: </b>{{height}} &nbsp;&nbsp;&nbsp;<span
                              *ngIf="weight_flag"><b>{{'Weight'|translate}}: </b>
                              {{weight}}</span></p>
                        <p *ngIf="temparature_flag"><b> {{'Temperature'|translate}}: </b>{{temparature}} </p>
                        <p *ngIf="ortho_flag"><b>{{'CVS'|translate}}: </b>{{clnt_cvs}}
                           &nbsp;&nbsp;&nbsp;<b>{{'RS'|translate}}: </b>
                           {{clnt_rs}}</p>
                        <p *ngIf="ortho_flag"><b> {{'Kidney'|translate}}: </b>{{clnt_kidney}}</p>
                     </td>
                  </tr>
               </table>
               <div style="margin-left: 50px;" *ngIf="print_view_flag && listProducts.length != 0">
                  <p><b>{{'Diagnosis' |translate}}: </b>{{dis_txt}}</p>
               </div>
               <br />
               <table *ngIf="listProducts.length != 0"
                  style="margin-left: 50px; border: 1px solid black;border-collapse: collapse;">
                  <tr>
                     <th [style]="med_style">
                        <b>{{'Medicine'|translate}}</b>
                     </th>
                     <th [style]="dosage_style">
                        <b>{{'Dosage'|translate}}</b>
                     </th>
                     <th [style]="dur_style">
                        <b>{{'Duration'|translate}}</b>
                     </th>
                     <th [style]="dur_style">
                        <b>{{'Intake mode'|translate}}</b>
                     </th>
                     <th [style]="freq_style">
                        <b>{{'Frequency'|translate}}</b>
                     </th>
                     <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">&nbsp;</th>
                     <th style="width: 50px;border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">
                        <b>{{'Note'|translate}}</b>
                     </th>
                  </tr>
                  <tr *ngFor="let product of listProducts">
                     <td style="border: 1px solid black;border-collapse: collapse;">
                        {{product.short_name}} {{product.drug_name}}
                     </td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                        {{product.every_six}}
                     </td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{product.days}}
                        {{product.period|translate}}</td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{product.days}}
                        {{product.intake_mode|translate}}</td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                        {{product.frequency|translate}}</td>
                     <td style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">
                        {{product.dure_txt_table}}
                        {{product.drug_intake|translate}}</td>
                     <td style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">
                        {{product.remarks}}</td>
                  </tr>
               </table>

               <div style="margin-left: 50px;">
                  <p *ngIf="instructions!=undefined"><b>{{'Instructions' |translate}}: </b>
                     <span>
                        <div class="matlabel" [innerHtml]="print_instruction"></div>
                     </span>
                  </p>
               </div>

               <div style="margin-left: 50px;" *ngIf="treatflag">
                  <p><b>{{'Treatment' |translate}}: </b>
                     <span>
                        <div class="matlabel" [innerHtml]="treatment"></div>
                     </span>
                  </p>
               </div>

               <div style="margin-left: 50px;">
                  <p *ngIf="next_txt!=undefined"><b>{{'Next visit' |translate}}: </b>{{next_txt | date : "dd-MM-yyyy"}}</p>
               </div>
               <br />
               <div>
                  <p style="margin-left: 50px;font-size: 13px;"> <strong>{{"Dr."+doct_name}} {{qualification}}</strong>
                  </p>
                  <p style="margin-left: 50px;font-size: 13px">({{licence_code}})</p>
                  <p style="margin-left: 50px;font-size: 13px">Find your prescription online at <i>www.tervys.com</i>
                  </p>
                  <p style="margin-left: 50px;font-size: 13px"><b><i>This is digitally generated prescription</i></b>
                  </p>
                  <br />
               </div>
            </div>
            <div *ngIf="print_diag">
               <div class="page-break" *ngIf="listProducts.length != 0"></div>
               <table style="margin-left:10px;margin-top:10px;margin-right: 10px;">
                  <tr>
                     <td>
                        <img alt="image" src={{hptl_pres_logo}} width="750px" height="250px">
                     </td>
                  </tr>
               </table>
               <br /><br />
               <table style="margin-left: 50px; margin-top:20px;">
                  <tr>
                     <td style="width: 500px;">
                        <p><b>{{'Name'|translate}} :</b> {{doct_clientname}}</p>
                        <p><b>{{'Mobile'}} :</b> {{mobile}}</p>
                        <p><b>{{'Age'|translate}} :</b> {{client_age}}</p>
                        <p><b>{{'Gender'|translate}}:</b> {{client_gender}} </p>
                     </td>
                     <td style="width: 300px;vertical-align: top;">
                        <p><b>{{'Date'|translate}} : </b>{{Appoint_Date}}</p>
                        <p *ngIf="ref_flag"><b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p>
                     </td>
                  </tr>
               </table>
               <div style="margin-left: 50px;" *ngIf="print_diag">
                  <p><b>Diagnostic Test</b></p>
                  <div *ngIf="pritn_blood_flag">
                     <p id="print_blood_head"><b>{{blood_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{blood_test_content}}
                     </p>
                  </div>
                  <div *ngIf="pritn_scan_flag">
                     <p id="print_blood_head"><b>{{scan_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{scan_test_content}}
                     </p>
                  </div>
                  <div *ngIf="pritn_us_flag">
                     <p id="print_blood_head"><b>{{us_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{us_test_content}}
                     </p>
                  </div>
                  <div *ngIf="pritn_xray_flag">
                     <p id="print_blood_head"><b>{{xray_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{xray_test_content}}
                     </p>
                  </div>
                  <div *ngIf="pritn_bio_flag">
                     <p id="print_blood_head"><b>{{bio_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{bio_test_content}}
                     </p>
                  </div>
                  <div *ngIf="pritn_ut_flag">
                     <p id="print_blood_head"><b>{{ut_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{ut_test_content}}
                     </p>
                  </div>
                  <div *ngIf="pritn_ft_flag">
                     <p id="print_blood_head"><b>{{ft_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{ft_test_content}}
                     </p>
                  </div>
                  <div *ngIf="pritn_culture_flag">
                     <p id="print_blood_head"><b>{{culture_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{culture_test_content}}
                     </p>
                  </div>
                  <!-- <div style="margin-left: 50px;">
                       <p *ngIf="next_txt!=undefined"><b>{{'Next visit' |translate}}: </b>{{next_txt}}</p>
                    </div> -->
                  <br />
               </div>
               <div style="margin-left: 50px;">
                  <p style="font-size: 13px;"> <strong>{{"Dr."+doct_name}}
                        {{qualification}}</strong>
                  </p>
                  <p style="font-size: 13px">({{licence_code}})</p>
                  <p style="font-size: 13px">Find your prescription online at <i>www.tervys.com</i>
                  </p>
                  <p style="font-size: 13px"><b><i>This is digitally generated prescription</i></b>
                  </p>
                  <br />
               </div>
            </div>
         </div>
         <div *ngIf="print_same_page">
            <table>
               <thead>
                  <tr>
                     <td>
                        <div class=“empty-header“ [style]="preslogo_style"> </div>
                     </td>
                  </tr>
               </thead>
               <tbody>
                  <tr>
                     <td>
                        <div class=“content“>

                           <div>
                              <div
                                 style="margin-left: 25px !important;margin-right:25px !important;display: flex;padding: 10px; margin-top: 10px; border: 1px solid black; border-radius: 10px;">
                                 <div>
                                    <div style="display: flex;vertical-align: top;">
                                       <table style="width: 450px;vertical-align: top;">
                                          <!-- <tr>
                                 <td><strong>{{'MR No'}}</strong></td>
                                 <td>: {{mrNumber}}</td>
                               </tr> -->
                                          <tr style="vertical-align: top;">
                                             <td><strong>{{'Name'}}</strong></td>
                                             <td>: {{doct_clientname}}</td>
                                          </tr>
                                          <tr style="vertical-align: top;">
                                             <td><strong>{{'Age'}}/{{'Gender'}}</strong></td>
                                             <td>: {{client_age}}/{{client_gender}}</td>
                                          </tr>
                                          <tr style="vertical-align: top;">
                                             <td><strong>{{'Mobile'}}</strong></td>
                                             <td>: {{mobile}}</td>
                                          </tr>


                                       </table>
                                       <table style="width: 350px;vertical-align: top;">
                                          <tr style="vertical-align: top;">
                                             <td><strong>{{'Date'}}</strong></td>
                                             <td>: {{Appoint_Date}} {{printtime}}</td>
                                          </tr>
                                          <tr style="vertical-align: top;">
                                             <td *ngIf="barcode_flag"><img
                                                   style="width: 130px; height: 35px; margin-left: -12px;"
                                                   src={{barcode_url}} />
                                             </td>
                                          </tr>
                                       </table>
                                    </div>
                                 </div>
                              </div>
                              <div style="margin-left: 25px !important;margin-right:25px !important;margin-top: 3px;">
                                 <p style="margin:0px"><strong>{{'Complaints'}}: </strong>
                                    <span>
                                       <div class="matlabel" [innerHtml]="complaints"></div>
                                    </span>
                                 </p>
                              </div>
                              <div style="margin-left: 25px !important;margin-right:25px !important;margin-top: 3px;">
                                 <p style="margin:0px"><strong>{{'Diagnosis'}}: </strong>
                                    <span>
                                       <div class="matlabel" [innerHtml]="dis_txt"></div>
                                    </span>
                                 </p>
                              </div>
                              <div
                                 style="display: flex;margin-left: 25px !important;margin-right:25px !important;margin-top: 3px;width: 89%;"
                                 class="rowformat">
                                 <p *ngIf="ref_flag" class="refformat" style="margin: 2px !important;">
                                    <b>{{'Referred by'}}: </b>{{refer_txt}}
                                 </p>
                                 <p *ngIf="bp_flag" class="refformat" style="margin: 2px !important;">
                                    <b>{{'BP'}}:</b>{{clnt_bp}}
                                 </p>
                                 <p *ngIf="pulseflag" class="refformat" style="margin: 2px !important;">
                                    <b>{{'Pulse'}}:</b>{{clnt_pulse}}
                                 </p>
                                 <p *ngIf="height_flag" class="refformat" style="margin: 2px !important;">
                                    <b>{{'Height'}}:</b>{{height}}
                                 </p>

                                 <p *ngIf="weight_flag" class="refformat" style="margin: 2px !important;">
                                    <b>{{'Weight'}}:</b>{{weight}}
                                 </p>
                                 <p *ngIf="temparature_flag" class="refformat" style="margin: 2px !important;">
                                    <b>{{'Temperature'}}: </b>{{temparature}}
                                 </p>
                                 <p *ngIf="cvsflag" class="refformat" style="margin: 2px !important;">
                                    <b>{{'CVS'}}:</b>{{clnt_cvs}}
                                 </p>
                                 <p *ngIf="rsflag" class="refformat" style="margin: 2px !important;">
                                    <b>{{'RS'}}:</b>{{clnt_rs}}
                                 </p>
                                 <p *ngIf="heartrateflag" class="refformat" style="margin: 2px !important;">
                                    <b>{{'Heart rate'}}:</b>{{clnt_heartrate}}
                                 </p>
                                 <p *ngIf="kidney_flag" class="refformat" style="margin: 2px !important;">
                                    <b>{{'Kidney'}}:</b>{{clnt_kidney}}
                                 </p>
                              </div>

                              <div style="margin-left: 25px !important;margin-right:25px !important;"
                                 *ngIf="listProducts.length != 0">
                                 <p><strong>Rx</strong></p>
                              </div>
                              <table *ngIf="listProducts.length != 0"
                                 style="margin-left: 25px !important;margin-right:25px !important;; border: 1px solid black;border-collapse: collapse;">

                                 <tr style="text-align: center;">
                                    <th [style]="med_style">
                                       <p>Medicine</p>
                                       <p *ngIf="printLanguageflag"> {{'Medicine'|translate}}</p>
                                    </th>
                                    <th [style]="dosage_style">
                                       <p>Mor</p>
                                       <p *ngIf="printLanguageflag"> {{'Mor'|translate}}</p>
                                    </th>
                                    <th [style]="dosage_style">
                                       <p>Aft</p>
                                       <p *ngIf="printLanguageflag">{{'Aft'|translate}}</p>
                                    </th>
                                    <th [style]="dosage_style">
                                       <p>Eve</p>
                                       <p *ngIf="printLanguageflag">{{'Eve'|translate}}</p>
                                    </th>
                                    <th [style]="dosage_style">
                                       <p>Ngt</p>
                                       <p *ngIf="printLanguageflag"> {{'Ngt'|translate}}</p>

                                    </th>
                                    <th [style]="dur_style">
                                       <p>Duration</p>
                                       <p *ngIf="printLanguageflag">{{'Duration'|translate}}</p>
                                    </th>
                                    <th [style]="dur_style">
                                       <p>Intake mode</p>
                                       <p *ngIf="printLanguageflag">{{'Intake mode'|translate}}</p>
                                    </th>
                                    <th [style]="freq_style">
                                       {{'Frequency'|translate}} & {{'Notes'|translate}}
                                    </th>
                                    <!-- <th style="border: 1px solid black;border-collapse: collapse;min-width: 100px !important;"
                           [hidden]="dur_flag"> <strong>{{'Intake'|translate}}</strong></th>
                         <th style="min-width: 250px !important;border: 1px solid black;border-collapse: collapse;"
                           [hidden]="notes_flag">
                           <strong>{{'Note'|translate}}</strong>
                         </th> -->
                                 </tr>
                                 <tr *ngFor="let product of listProducts">
                                    <td style="border: 1px solid black;border-collapse: collapse;width: 310px;">
                                       {{product.med_typetxt}} {{product.drug_name}} <div style="font-size:12px">
                                          {{product.genericname}}</div>
                                    </td>
                                    <td style="text-align: left;border: 1px solid black;border-collapse: collapse;width: 100px;padding: 15px !important;"
                                       colspan="4" *ngIf="product.param_four ==true">
                                       {{product.every_six}}
                                    </td>
                                    <td style="text-align: center;border-collapse: collapse;width: 100px;border: 1px solid black;"
                                       *ngIf="product.param_four ==false">
                                       {{product.morning1}}
                                    </td>
                                    <td style="text-align: center;border-collapse: collapse;width: 100px;border: 1px solid black;"
                                       *ngIf="product.param_four ==false">
                                       {{product.afternoon1}}
                                    </td>
                                    <td style="text-align: center;border-collapse: collapse;width: 100px;border: 1px solid black;"
                                       *ngIf="product.param_four ==false">
                                       {{product.evening1}}
                                    </td>
                                    <td style="text-align: center;border-collapse: collapse;width: 100px;border: 1px solid black;"
                                       *ngIf="product.param_four ==false">
                                       {{product.night1}}
                                    </td>
                                    <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                                       {{product.days}}
                                       {{product.period|translate}}</td>
                                    <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                                       {{product.intake_mode}}</td>
                                    <td
                                       style="text-align: center;border: 1px solid black;border-collapse: collapse;min-width: 250px;">
                                       {{product.frequency
                                       |
                                       translate}} {{product.dure_txt_table}}
                                       {{product.drug_intake|translate}}<br> {{product.remarks}}</td>
                                    <!-- <td style="border: 1px solid black;border-collapse: collapse;min-width: 100px !important;">
                            </td>
                          <td style="border: 1px solid black;border-collapse: collapse;min-width: 250px !important;">
                            </td> -->
                                 </tr>
                              </table>
                              <div style="margin-left: 25px !important;margin-right:25px !important;margin-top: 3px;">
                                 <p style="margin:0px"><strong>{{'Instructions'}}: </strong>
                                    <span>
                                       <div class="matlabel" [innerHtml]="instructions"></div>
                                    </span>
                                 </p>
                              </div>

                              <div style="margin-left: 25px !important;margin-right:25px !important;margin-top: 3px;">
                                 <p><strong>{{'Next visit'}}: </strong>{{next_txt | date : "dd-MM-yyyy"}}</p>
                              </div>
                              <div style="margin-left: 25px !important;margin-right:25px !important;margin-top: 3px;"
                                 *ngIf="treatflag">
                                 <p><strong>{{'Treatment'}}: </strong>
                                    <span>
                                       <div class="matlabel" [innerHtml]="treatment"></div>
                                    </span>
                                 </p>
                              </div>

                           </div>



                           <div>
                              <p
                                 style="margin-left: 25px !important;margin-right:25px !important;font-size: 13px;margin-top: 3px;">
                                 <strong>{{"Dr."+doct_name}}
                                    {{qualification}}</strong>
                              </p>
                              <p style="margin-left: 25px !important;margin-right:25px !important;font-size: 13px">
                                 ({{licence_code}})</p>

                              <br />
                           </div>
                        </div>
                     </td>
                  </tr>
               </tbody>
               <tfoot>
                  <div class=”empty-footer”>&nbsp;</div>
               </tfoot>
            </table>
            <div class=”header”>&nbsp;</div>
            <div class=”footer”>&nbsp;</div>
         </div>
         <div *ngIf="!print_view_flag">
            <div *ngIf="listProducts.length != 0">
               <table style="margin-left:10px;margin-top:10px;margin-right: 10px;">
                  <tr>
                     <td>
                        <img alt="image" src={{hptl_pres_logo}} [style]="preslogo_style">
                     </td>
                  </tr>
               </table>
               <br /><br />
               <table style="margin-left: 50px; margin-top:20px;">
                  <tr>
                     <td style="width: 500px;">
                        <p><b>{{'Name'|translate}} :</b> {{doct_clientname}}</p>
                        <p><b>{{'Mobile'}} :</b> {{mobile}}</p>
                        <p><b>{{'Age'|translate}} :</b> {{client_age}}
                           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{{'Gender'|translate}}:
                           </b>{{client_gender}} </p>
                        <p *ngIf="oberse_med!=undefined"><b>{{'Investigation'|translate}} :</b> {{oberse_med}}</p>
                        <p *ngIf="disease_flag"><b>Disease : </b>{{disease_name}}</p>

                     </td>
                     <td style="width: 300px;vertical-align: top;">
                        <p><b>{{'Date'|translate}} : </b>{{Appoint_Date}}</p>
                        <p *ngIf="ref_flag"><b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p>
                        <p *ngIf="bp_flag"><b>{{'BP'|translate}}: </b>{{clnt_bp}}
                           &nbsp;&nbsp;&nbsp;<b>{{'Pulse'|translate}}:
                           </b>{{clnt_pulse}}</p>
                        <p *ngIf="height_flag"><b>{{'Height'|translate}}: </b>{{height}} &nbsp;&nbsp;&nbsp;<span
                              *ngIf="weight_flag"><b>{{'Weight'|translate}}: </b>
                              {{weight}}</span></p>
                        <p *ngIf="temparature_flag"><b> {{'Temperature'|translate}}: </b>{{temparature}} </p>
                        <p *ngIf="ortho_flag"><b>{{'CVS'|translate}}: </b>{{clnt_cvs}}
                           &nbsp;&nbsp;&nbsp;<b>{{'RS'|translate}}: </b>
                           {{clnt_rs}}</p>
                        <p *ngIf="ortho_flag"><b> {{'Kidney'|translate}}: </b>{{clnt_kidney}}</p>
                     </td>
                  </tr>
               </table>
               <div style="margin-left: 50px;" *ngIf="print_view_flag && listProducts.length != 0">
                  <p><b>{{'Diagnosis' |translate}}: </b>{{dis_txt}}</p>
               </div>
               <br />
               <table *ngIf="listProducts.length != 0"
                  style="margin-left: 50px; border: 1px solid black;border-collapse: collapse;">
                  <tr>
                     <th [style]="med_style">
                        <b>{{'Medicine'|translate}}</b>
                     </th>
                     <th [style]="dosage_style">
                        <b>{{'Dosage'|translate}}</b>
                     </th>
                     <th [style]="dur_style">
                        <b>{{'Duration'|translate}}</b>
                     </th>
                     <th [style]="freq_style">
                        <b>{{'Frequency'|translate}}</b>
                     </th>
                     <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">&nbsp;</th>
                     <th style="width: 50px;border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">
                        <b>{{'Note'|translate}}</b>
                     </th>
                  </tr>
                  <tr *ngFor="let product of listProducts">
                     <td style="border: 1px solid black;border-collapse: collapse;">
                        {{product.short_name}} {{product.drug_name}}
                     </td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                        {{product.every_six}}
                     </td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{product.days}}
                        {{product.period|translate}}</td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                        {{product.frequency
                        |
                        translate}}</td>
                     <td style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">
                        {{product.dure_txt_table}}
                        {{product.drug_intake|translate}}</td>
                     <td style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">
                        {{product.remarks}}</td>
                  </tr>
               </table>

               <div style="margin-left: 50px;">
                  <p *ngIf="instructions!=undefined"><b>{{'Instructions' |translate}}: </b>
                     <span>
                        <div class="matlabel" [innerHtml]="print_instruction"></div>
                     </span>
                  </p>
               </div>

               <div style="margin-left: 50px;" *ngIf="treatflag">
                  <p><b>{{'Treatment' |translate}}: </b>
                     <span>
                        <div class="matlabel" [innerHtml]="treatment"></div>
                     </span>
                  </p>
               </div>

               <div style="margin-left: 50px;">
                  <p *ngIf="next_txt!=undefined"><b>{{'Next visit' |translate}}: </b>{{next_txt | date : "dd-MM-yyyy"}}</p>
               </div>
               <br />
               <div>
                  <p style="margin-left: 50px;font-size: 13px;"> <strong>{{"Dr."+doct_name}} {{qualification}}</strong>
                  </p>
                  <p style="margin-left: 50px;font-size: 13px">({{licence_code}})</p>
                  <p style="margin-left: 50px;font-size: 13px">Find your prescription online at <i>www.tervys.com</i>
                  </p>
                  <p style="margin-left: 50px;font-size: 13px"><b><i>This is digitally generated prescription</i></b>
                  </p>
                  <br />
               </div>
            </div>
         </div>
      </div>

      <div #printnoheader id="printnoheader" style="background-image: url({{bg_image}}); background-position: center;">
         <div *ngIf="print_same_page">
            <table>
               <thead>
                  <tr>
                     <td>
                        <div class=“empty-header“ [style]="preslogo_style"> </div>
                     </td>
                  </tr>
               </thead>
               <tbody>
                  <tr>
                     <td>
                        <div class=“content“>

                           <div>
                              <div
                                 style="margin-left: 25px !important;margin-right:25px !important;display: flex;padding: 10px; margin-top: 10px; border: 1px solid black; border-radius: 10px;">
                                 <div>
                                    <div style="display: flex;vertical-align: top;">
                                       <table style="width: 450px;vertical-align: top;">
                                          <!-- <tr>
                                 <td><strong>{{'MR No'}}</strong></td>
                                 <td>: {{mrNumber}}</td>
                               </tr> -->
                                          <tr style="vertical-align: top;">
                                             <td><strong>{{'Name'}}</strong></td>
                                             <td>: {{doct_clientname}}</td>
                                          </tr>
                                          <tr style="vertical-align: top;">
                                             <td><strong>{{'Age'}}/{{'Gender'}}</strong></td>
                                             <td>: {{client_age}}/{{client_gender}}</td>
                                          </tr>
                                          <tr style="vertical-align: top;">
                                             <td><strong>{{'Mobile'}}</strong></td>
                                             <td>: {{mobile}}</td>
                                          </tr>


                                       </table>
                                       <table style="width: 350px;vertical-align: top;">
                                          <tr style="vertical-align: top;">
                                             <td><strong>{{'Date'}}</strong></td>
                                             <td>: {{Appoint_Date}} {{printtime}}</td>
                                          </tr>
                                          <tr style="vertical-align: top;">
                                             <td *ngIf="barcode_flag"><img
                                                   style="width: 130px; height: 35px; margin-left: -12px;"
                                                   src={{barcode_url}} />
                                             </td>
                                          </tr>
                                       </table>
                                    </div>
                                 </div>
                              </div>
                              <div style="margin-left: 25px !important;margin-right:25px !important;margin-top: 3px;">
                                 <p style="margin:0px"><strong>{{'Complaints'}}: </strong>
                                    <span>
                                       <div class="matlabel" [innerHtml]="complaints"></div>
                                    </span>
                                 </p>
                              </div>
                              <div style="margin-left: 25px !important;margin-right:25px !important;margin-top: 3px;">
                                 <p style="margin:0px"><strong>{{'Diagnosis'}}: </strong>
                                    <span>
                                       <div class="matlabel" [innerHtml]="dis_txt"></div>
                                    </span>
                                 </p>
                              </div>
                              <div
                                 style="display: flex;margin-left: 25px !important;margin-right:25px !important;margin-top: 3px;width: 89%;"
                                 class="rowformat">
                                 <p *ngIf="ref_flag" class="refformat" style="margin: 2px !important;">
                                    <b>{{'Referred by'}}: </b>{{refer_txt}}
                                 </p>
                                 <p *ngIf="height_flag" class="refformat" style="margin: 2px 10px 2px 10px !important;">
                                    <b>{{'Height'}}:</b>{{height}}
                                  </p>
        
                                  <p *ngIf="weight_flag" class="refformat" style="margin: 2px !important;">
                                    <b>{{'Weight'}}:</b>{{weight}}
                                  </p>
                                 
                                  <p *ngIf="bp_flag" class="refformat" style="margin: 2px 10px 2px 10px !important;">
                                    <b>{{'BP'}}:</b>{{clnt_bp}}
                                  </p>
                                  <p *ngIf="pulseflag" class="refformat" style="margin: 2px 10px 2px 10px !important;">
                                    <b>{{'Pulse'}}:</b>{{clnt_pulse}}
                                  </p>
                                  <p *ngIf="temparature_flag" class="refformat" style="margin: 2px !important;">
                                    <b>{{'Temperature'}}: </b>{{temparature}}
                                  </p>
                                  <p *ngIf="rrflag" class="refformat" style="margin: 2px !important;">
                                    <b>{{'RR'}}:</b>{{clnt_rr}}
                                  </p>
                                  <p *ngIf="spoflag" class="refformat" style="margin: 2px !important;">
                                    <b>{{'Spo2'}}:</b>{{clnt_spo2}}
                                  </p>
                                  <p *ngIf="cbgflag" class="refformat" style="margin: 2px !important;">
                                    <b>{{'CBG'}}:</b>{{clnt_cbg}}
                                  </p>                       
                                
                                  <p *ngIf="cvsflag" class="refformat" style="margin: 2px !important;">
                                    <b>{{'CVS'}}:</b>{{clnt_cvs}}
                                  </p>
                                  <p *ngIf="rsflag" class="refformat" style="margin: 2px !important;">
                                    <b>{{'RS'}}:</b>{{clnt_rs}}
                                  </p>
                                
                                  <p *ngIf="abdflag" class="refformat" style="margin: 2px !important;">
                                    <b>{{'Abdomen'}}:</b>{{clnt_abd}}
                                  </p>
                                  <p *ngIf="cnsflag" class="refformat" style="margin: 2px !important;">
                                    <b>{{'CNS'}}:</b>{{clnt_cns}}
                                  </p>
        
                                  <p *ngIf="kidney_flag" class="refformat" style="margin: 2px 10px 2px 10px !important;">
                                    <b>{{'Kidney'}}:</b>{{clnt_kidney}}
                                  </p>
                                 <!-- <p *ngIf="kidney_flag" class="refformat" style="margin: 2px !important;">
                                    <b>{{'Kidney'}}:</b>{{clnt_kidney}}
                                 </p> -->
                              </div>

                              <div style="margin-left: 25px !important;margin-right:25px !important;"
                                 *ngIf="listProducts.length != 0">
                                 <p><strong>Rx</strong></p>
                              </div>
                              <table *ngIf="listProducts.length != 0"
                                 style="margin-left: 25px !important;margin-right:25px !important;; border: 1px solid black;border-collapse: collapse;">

                                 <tr style="text-align: center;">
                                    <th [style]="med_style">
                                       <p>Medicine</p>
                                       <p *ngIf="printLanguageflag"> {{'Medicine'|translate}}</p>
                                    </th>
                                    <th [style]="dosage_style">
                                       <p>Mor</p>
                                       <p *ngIf="printLanguageflag"> {{'Mor'|translate}}</p>
                                    </th>
                                    <th [style]="dosage_style">
                                       <p>Aft</p>
                                       <p *ngIf="printLanguageflag">{{'Aft'|translate}}</p>
                                    </th>
                                    <th [style]="dosage_style">
                                       <p>Eve</p>
                                       <p *ngIf="printLanguageflag">{{'Eve'|translate}}</p>
                                    </th>
                                    <th [style]="dosage_style">
                                       <p>Ngt</p>
                                       <p *ngIf="printLanguageflag"> {{'Ngt'|translate}}</p>

                                    </th>
                                    <th [style]="dur_style">
                                       <p>Duration</p>
                                       <p *ngIf="printLanguageflag">{{'Duration'|translate}}</p>
                                    </th>
                                    <th [style]="dur_style">
                                       <p>Intake mode</p>
                                       <p *ngIf="printLanguageflag">{{'Intake mode'|translate}}</p>
                                    </th>
                                    <th [style]="freq_style">
                                       {{'Frequency'|translate}} & {{'Notes'|translate}}
                                    </th>
                                    <!-- <th style="border: 1px solid black;border-collapse: collapse;min-width: 100px !important;"
                           [hidden]="dur_flag"> <strong>{{'Intake'|translate}}</strong></th>
                         <th style="min-width: 250px !important;border: 1px solid black;border-collapse: collapse;"
                           [hidden]="notes_flag">
                           <strong>{{'Note'|translate}}</strong>
                         </th> -->
                                 </tr>
                                 <tr *ngFor="let product of listProducts">
                                    <td style="border: 1px solid black;border-collapse: collapse;width: 310px;">
                                       {{product.med_typetxt}} {{product.drug_name}} <div style="font-size:12px">
                                          {{product.genericname}}</div>
                                    </td>
                                    <td style="text-align: left;border: 1px solid black;border-collapse: collapse;width: 100px;padding: 15px !important;"
                                       colspan="4" *ngIf="product.param_four ==true">
                                       {{product.every_six}}
                                    </td>
                                    <td style="text-align: center;border-collapse: collapse;width: 100px;border: 1px solid black;"
                                       *ngIf="product.param_four ==false">
                                       {{product.morning1}}
                                    </td>
                                    <td style="text-align: center;border-collapse: collapse;width: 100px;border: 1px solid black;"
                                       *ngIf="product.param_four ==false">
                                       {{product.afternoon1}}
                                    </td>
                                    <td style="text-align: center;border-collapse: collapse;width: 100px;border: 1px solid black;"
                                       *ngIf="product.param_four ==false">
                                       {{product.evening1}}
                                    </td>
                                    <td style="text-align: center;border-collapse: collapse;width: 100px;border: 1px solid black;"
                                       *ngIf="product.param_four ==false">
                                       {{product.night1}}
                                    </td>
                                    <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                                       {{product.days}}
                                       {{product.period|translate}}</td>
                                    <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                                       {{product.intake_mode}}</td>
                                    <td
                                       style="text-align: center;border: 1px solid black;border-collapse: collapse;min-width: 250px;">
                                       {{product.frequency
                                       |
                                       translate}} {{product.dure_txt_table}}
                                       {{product.drug_intake|translate}}<br> {{product.remarks}}</td>
                                    <!-- <td style="border: 1px solid black;border-collapse: collapse;min-width: 100px !important;">
                            </td>
                          <td style="border: 1px solid black;border-collapse: collapse;min-width: 250px !important;">
                            </td> -->
                                 </tr>
                              </table>
                              <div style="margin-left: 25px !important;margin-right:25px !important;margin-top: 3px;">
                                 <p style="margin:0px"><strong>{{'Instructions'}}: </strong>
                                    <span>
                                       <div class="matlabel" [innerHtml]="instructions"></div>
                                    </span>
                                 </p>
                              </div>

                              <div style="margin-left: 25px !important;margin-right:25px !important;margin-top: 3px;">
                                 <p><strong>{{'Next visit'}}: </strong>{{next_txt | date : "dd-MM-yyyy"}}</p>
                              </div>
                              <div style="margin-left: 25px !important;margin-right:25px !important;margin-top: 3px;"
                                 *ngIf="treatflag">
                                 <p><strong>{{'Treatment'}}: </strong>
                                    <span>
                                       <div class="matlabel" [innerHtml]="treatment"></div>
                                    </span>
                                 </p>
                              </div>
                           </div>


                           <div>
                              <p
                                 style="margin-left: 25px !important;margin-right:25px !important;font-size: 13px;margin-top: 3px;">
                                 <strong>{{"Dr."+doct_name}}
                                    {{qualification}}</strong>
                              </p>
                              <p style="margin-left: 25px !important;margin-right:25px !important;font-size: 13px">
                                 ({{licence_code}})</p>

                              <br />
                           </div>
                        </div>
                     </td>
                  </tr>
               </tbody>
               <tfoot>
                  <div class=”empty-footer”>&nbsp;</div>
               </tfoot>
            </table>
            <div class=”header”>&nbsp;</div>
            <div class=”footer”>&nbsp;</div>
         </div>
         <!-- <div *ngIf="!print_view_flag">
              <div *ngIf="listProducts.length != 0">
                 <table style="margin-left: 50px; margin-top:220px;">
                    <tr>
                       <td style="width: 500px;">
                          <p><b>{{'Name'|translate}} :</b> {{doct_clientname}}</p>
                          <p><b>{{'Mobile'}} :</b> {{mobile}}</p>
                          <p><b>{{'Age'|translate}} :</b> {{client_age}}
                             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{{'Gender'|translate}}:
                             </b>{{client_gender}} </p>
                          <p *ngIf="oberse_med!=undefined"><b>{{'Investigation'|translate}} :</b> {{oberse_med}}</p>
                          <p *ngIf="disease_flag"><b>Disease : </b>{{disease_name}}</p>
  
                       </td>
                       <td style="width: 300px;vertical-align: top;">
                          <p><b>{{'Date'|translate}} : </b>{{Appoint_Date}}</p>
                          <p *ngIf="ref_flag"><b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p>
                          <p *ngIf="bp_flag"><b>{{'BP'|translate}}: </b>{{clnt_bp}}
                             &nbsp;&nbsp;&nbsp;<b>{{'Pulse'|translate}}:
                             </b>{{clnt_pulse}}</p>
                          <p *ngIf="height_flag"><b>{{'Height'|translate}}: </b>{{height}} &nbsp;&nbsp;&nbsp;<span
                                *ngIf="weight_flag"><b>{{'Weight'|translate}}: </b>
                                {{weight}}</span></p>
                          <p *ngIf="temparature_flag"><b> {{'Temperature'|translate}}: </b>{{temparature}} </p>
                          <p *ngIf="ortho_flag"><b>{{'CVS'|translate}}: </b>{{clnt_cvs}}
                             &nbsp;&nbsp;&nbsp;<b>{{'RS'|translate}}: </b>
                             {{clnt_rs}}</p>
                          <p *ngIf="ortho_flag"><b> {{'Kidney'|translate}}: </b>{{clnt_kidney}}</p>
                       </td>
                    </tr>
                 </table>
                 <div style="margin-left: 50px;" *ngIf="print_view_flag && listProducts.length != 0">
                    <p><b>{{'Diagnosis' |translate}}: </b>{{dis_txt}}</p>
                 </div>
                 <br />
                 <table *ngIf="listProducts.length != 0"
                    style="margin-left: 50px; border: 1px solid black;border-collapse: collapse;">
                    <tr>
                       <th [style]="med_style">
                          <b>{{'Medicine'|translate}}</b>
                       </th>
                       <th [style]="dosage_style">
                          <b>{{'Dosage'|translate}}</b>
                       </th>
                       <th [style]="dur_style">
                          <b>{{'Duration'|translate}}</b>
                       </th>
                       <th [style]="freq_style">
                          <b>{{'Frequency'|translate}}</b>
                       </th>
                       <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">&nbsp;</th>
                       <th style="width: 50px;border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">
                          <b>{{'Note'|translate}}</b>
                       </th>
                    </tr>
                    <tr *ngFor="let product of listProducts">
                       <td style="border: 1px solid black;border-collapse: collapse;">
                          {{product.short_name}} {{product.drug_name}}
                       </td>
                       <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                          {{product.every_six}}
                       </td>
                       <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{product.days}}
                          {{product.period|translate}}</td>
                       <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                          {{product.frequency
                          |
                          translate}}</td>
                          <td style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">{{product.dure_txt_table}}
                             {{product.drug_intake|translate}}</td>
                          <td style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">{{product.remarks}}</td>
                    </tr>
                 </table>
  
                 <div style="margin-left: 50px;">
                    <p *ngIf="instructions!=undefined"><b>{{'Instructions' |translate}}: </b>
                       <span>
                          <div class="matlabel" [innerHtml]="print_instruction"></div>
                       </span>
                    </p>
                 </div>
  
                 <div style="margin-left: 50px;" *ngIf="treatflag">
                    <p><b>{{'Treatment' |translate}}: </b>
                       <span>
                          <div class="matlabel" [innerHtml]="treatment"></div>
                       </span>
                    </p>
                 </div>
  
                 <div style="margin-left: 50px;">
                    <p *ngIf="next_txt!=undefined"><b>{{'Next visit' |translate}}: </b>{{next_txt}}</p>
                 </div>
                 <br />
                 <div style="margin-left: 50px;">
                    <p style="font-size: 13px;"> <strong>{{"Dr."+doct_name}} {{qualification}}</strong>
                    </p>
                    <p style="font-size: 13px">({{licence_code}})</p>
                    <p style="font-size: 13px">Find your prescription online at <i>www.tervys.com</i></p>
                    <p style="font-size: 13px"><b><i>This is digitally generated prescription</i></b></p>
                    <br />
                 </div>
              </div>
           </div> -->
      </div>
      <div #printnoheadsidebar id="printnoheadsidebar"
         style="background-image: url({{bg_image}}); background-position: center;">
         <div *ngIf="!print_same_age">
            <div *ngIf="listProducts.length != 0 && print_view_flag">
               <table style="margin-left: 220px; margin-top:350px;">
                  <tr>
                     <td style="width: 500px;">
                        <p><b>{{'Name'|translate}} :</b> {{doct_clientname}}</p>
                        <p><b>{{'Mobile'}} :</b> {{mobile}}</p>
                        <p><b>{{'Age'|translate}} :</b> {{client_age}}
                           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{{'Gender'|translate}}:
                           </b>{{client_gender}} </p>
                        <p *ngIf="oberse_med!=undefined"><b>{{'Investigation'|translate}} :</b> {{oberse_med}}</p>
                        <p *ngIf="disease_flag"><b>Disease : </b>{{disease_name}}</p>

                     </td>
                     <td style="width: 300px;vertical-align: top;">
                        <p><b>{{'Date'|translate}} : </b>{{Appoint_Date}}</p>
                        <p *ngIf="ref_flag"><b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p>
                        <p *ngIf="bp_flag"><b>{{'BP'|translate}}: </b>{{clnt_bp}}
                           &nbsp;&nbsp;&nbsp;<b>{{'Pulse'|translate}}:
                           </b>{{clnt_pulse}}</p>
                        <p *ngIf="height_flag"><b>{{'Height'|translate}}: </b>{{height}} &nbsp;&nbsp;&nbsp;<span
                              *ngIf="weight_flag"><b>{{'Weight'|translate}}: </b>
                              {{weight}}</span></p>
                        <p *ngIf="temparature_flag"><b> {{'Temperature'|translate}}: </b>{{temparature}} </p>
                        <p *ngIf="ortho_flag"><b>{{'CVS'|translate}}: </b>{{clnt_cvs}}
                           &nbsp;&nbsp;&nbsp;<b>{{'RS'|translate}}: </b>
                           {{clnt_rs}}</p>
                        <p *ngIf="ortho_flag"><b> {{'Kidney'|translate}}: </b>{{clnt_kidney}}</p>
                     </td>
                  </tr>
               </table>
               <div style="margin-left: 220px;" *ngIf="print_view_flag && listProducts.length != 0">
                  <p><b>{{'Diagnosis' |translate}}: </b>{{dis_txt}}</p>
               </div>
               <br />
               <table *ngIf="listProducts.length != 0"
                  style="margin-left: 220px; border: 1px solid black;border-collapse: collapse;">
                  <tr>
                     <th [style]="med_style">
                        <b>{{'Medicine'|translate}}</b>
                     </th>
                     <th [style]="dosage_style">
                        <b>{{'Dosage'|translate}}</b>
                     </th>
                     <th [style]="dur_style">
                        <b>{{'Duration'|translate}}</b>
                     </th>
                     <th [style]="freq_style">
                        <b>{{'Frequency'|translate}}</b>
                     </th>
                     <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">&nbsp;</th>
                     <th style="width: 50px;border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">
                        <b>{{'Note'|translate}}</b>
                     </th>
                  </tr>
                  <tr *ngFor="let product of listProducts">
                     <td style="border: 1px solid black;border-collapse: collapse;">
                        {{product.short_name}} {{product.drug_name}}
                     </td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                        {{product.every_six}}
                     </td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{product.days}}
                        {{product.period|translate}}</td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                        {{product.frequency
                        |
                        translate}}</td>
                     <td style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">
                        {{product.dure_txt_table}}
                        {{product.drug_intake|translate}}</td>
                     <td style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">
                        {{product.remarks}}</td>
                  </tr>
               </table>

               <div style="margin-left: 220px;">
                  <p *ngIf="instructions!=undefined"><b>{{'Instructions' |translate}}: </b>
                     <span>
                        <div class="matlabel" [innerHtml]="print_instruction"></div>
                     </span>
                  </p>
               </div>

               <div style="margin-left: 220px;" *ngIf="treatflag">
                  <p><b>{{'Treatment' |translate}}: </b>
                     <span>
                        <div class="matlabel" [innerHtml]="treatment"></div>
                     </span>
                  </p>
               </div>

               <div style="margin-left: 220px;">
                  <p *ngIf="next_txt!=undefined"><b>{{'Next visit' |translate}}: </b>{{next_txt | date : "dd-MM-yyyy"}}</p>
               </div>
               <br />
               <div>
                  <p style="margin-left: 220px;font-size: 13px;"> <strong>{{"Dr."+doct_name}} {{qualification}}</strong>
                  </p>
                  <p style="margin-left: 220px;font-size: 13px">({{licence_code}})</p>
                  <p style="margin-left: 220px;font-size: 13px">Find your prescription online at <i>www.tervys.com</i>
                  </p>
                  <p style="margin-left: 220px;font-size: 13px"><b><i>This is digitally generated prescription</i></b>
                  </p>
                  <br />
               </div>
            </div>
            <div *ngIf="print_diag">
               <div class="page-break" *ngIf="listProducts.length != 0"></div>
               <table style="margin-left: 220px; margin-top:220px;">
                  <tr>
                     <td style="width: 500px;">
                        <p><b>{{'Name'|translate}} :</b> {{doct_clientname}}</p>
                        <p><b>{{'Mobile'}} :</b> {{mobile}}</p>
                        <p><b>{{'Age'|translate}} :</b> {{client_age}}</p>
                        <p><b>{{'Gender'|translate}}:</b> {{client_gender}} </p>
                     </td>
                     <td style="width: 300px;vertical-align: top;">
                        <p><b>{{'Date'|translate}} : </b>{{Appoint_Date}}</p>
                        <p *ngIf="ref_flag"><b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p>
                     </td>
                  </tr>
               </table>
               <div style="margin-left: 220px;" *ngIf="print_diag">
                  <p><b>Diagnostic Test</b></p>
                  <div *ngIf="pritn_blood_flag">
                     <p id="print_blood_head"><b>{{blood_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{blood_test_content}}
                     </p>
                  </div>
                  <div *ngIf="pritn_scan_flag">
                     <p id="print_blood_head"><b>{{scan_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{scan_test_content}}
                     </p>
                  </div>
                  <div *ngIf="pritn_us_flag">
                     <p id="print_blood_head"><b>{{us_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{us_test_content}}
                     </p>
                  </div>
                  <div *ngIf="pritn_xray_flag">
                     <p id="print_blood_head"><b>{{xray_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{xray_test_content}}
                     </p>
                  </div>
                  <div *ngIf="pritn_bio_flag">
                     <p id="print_blood_head"><b>{{bio_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{bio_test_content}}
                     </p>
                  </div>
                  <div *ngIf="pritn_ut_flag">
                     <p id="print_blood_head"><b>{{ut_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{ut_test_content}}
                     </p>
                  </div>
                  <div *ngIf="pritn_ft_flag">
                     <p id="print_blood_head"><b>{{ft_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{ft_test_content}}
                     </p>
                  </div>
                  <div *ngIf="pritn_culture_flag">
                     <p id="print_blood_head"><b>{{culture_test_head}}</b></p>
                     <p style="margin-left: 20px;margin-top:0;">
                        {{culture_test_content}}
                     </p>
                  </div>
                  <!-- <div style="margin-left: 220px;">
                       <p *ngIf="next_txt!=undefined"><b>{{'Next visit' |translate}}: </b>{{next_txt}}</p>
                    </div> -->
                  <br />

               </div>
               <div style="margin-left: 220px;">
                  <p style="font-size: 13px;"> <strong>{{"Dr."+doct_name}} {{qualification}}</strong>
                  </p>
                  <p style="font-size: 13px">({{licence_code}})</p>
                  <p style="font-size: 13px">Find your prescription online at <i>www.tervys.com</i>
                  </p>
                  <p style="font-size: 13px"><b><i>This is digitally generated prescription</i></b>
                  </p>
                  <br />
               </div>

            </div>
         </div>
         <div *ngIf="print_same_age">
            <div>
               <table style="margin-left: 220px; margin-top:350px;">
                  <tr>
                     <td style="width: 500px;">
                        <p><b>{{'Name'|translate}} :</b> {{doct_clientname}}</p>
                        <p><b>{{'Mobile'}} :</b> {{mobile}}</p>
                        <p><b>{{'Age'|translate}} :</b> {{client_age}}
                           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{{'Gender'|translate}}:
                           </b>{{client_gender}} </p>
                        <p *ngIf="oberse_med!=undefined"><b>{{'Investigation'|translate}} :</b> {{oberse_med}}</p>
                        <p *ngIf="disease_flag"><b>Disease : </b>{{disease_name}}</p>

                     </td>
                     <td style="width: 300px;vertical-align: top;">
                        <p><b>{{'Date'|translate}} : </b>{{Appoint_Date}}</p>
                        <p *ngIf="ref_flag"><b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p>
                        <p *ngIf="bp_flag"><b>{{'BP'|translate}}: </b>{{clnt_bp}}
                           &nbsp;&nbsp;&nbsp;<b>{{'Pulse'|translate}}:
                           </b>{{clnt_pulse}}</p>
                        <p *ngIf="height_flag"><b>{{'Height'|translate}}: </b>{{height}} &nbsp;&nbsp;&nbsp;<span
                              *ngIf="weight_flag"><b>{{'Weight'|translate}}: </b>
                              {{weight}}</span></p>
                        <p *ngIf="temparature_flag"><b> {{'Temperature'|translate}}: </b>{{temparature}} </p>
                        <p *ngIf="ortho_flag"><b>{{'CVS'|translate}}: </b>{{clnt_cvs}}
                           &nbsp;&nbsp;&nbsp;<b>{{'RS'|translate}}: </b>
                           {{clnt_rs}}</p>
                        <p *ngIf="ortho_flag"><b> {{'Kidney'|translate}}: </b>{{clnt_kidney}}</p>
                     </td>
                  </tr>
               </table>
               <div style="margin-left: 220px;" *ngIf="print_view_flag && listProducts.length != 0">
                  <p><b>{{'Diagnosis' |translate}}: </b>{{dis_txt}}</p>
               </div>
               <br />
               <table *ngIf="listProducts.length != 0"
                  style="margin-left: 220px; border: 1px solid black;border-collapse: collapse;">
                  <tr>
                     <th [style]="med_style">
                        <b>{{'Medicine'|translate}}</b>
                     </th>
                     <th [style]="dosage_style">
                        <b>{{'Dosage'|translate}}</b>
                     </th>
                     <th [style]="dur_style">
                        <b>{{'Duration'|translate}}</b>
                     </th>
                     <th [style]="freq_style">
                        <b>{{'Frequency'|translate}}</b>
                     </th>
                     <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">&nbsp;</th>
                     <th style="width: 50px;border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">
                        <b>{{'Note'|translate}}</b>
                     </th>
                  </tr>
                  <tr *ngFor="let product of listProducts">
                     <td style="border: 1px solid black;border-collapse: collapse;">
                        {{product.short_name}} {{product.drug_name}}
                     </td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                        {{product.every_six}}
                     </td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{product.days}}
                        {{product.period|translate}}</td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                        {{product.frequency
                        |
                        translate}}</td>
                     <td style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">
                        {{product.dure_txt_table}}
                        {{product.drug_intake|translate}}</td>
                     <td style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">
                        {{product.remarks}}</td>
                  </tr>
               </table>
            </div>
            <div style="margin-left: 220px;" *ngIf="print_diag">
               <p><b>Diagnostic Test</b></p>
               <div *ngIf="pritn_blood_flag">
                  <p id="print_blood_head"><b>{{blood_test_head}}</b></p>
                  <p style="margin-left: 20px;margin-top:0;">
                     {{blood_test_content}}
                  </p>
               </div>
               <div *ngIf="pritn_scan_flag">
                  <p id="print_blood_head"><b>{{scan_test_head}}</b></p>
                  <p style="margin-left: 20px;margin-top:0;">
                     {{scan_test_content}}
                  </p>
               </div>
               <div *ngIf="pritn_us_flag">
                  <p id="print_blood_head"><b>{{us_test_head}}</b></p>
                  <p style="margin-left: 20px;margin-top:0;">
                     {{us_test_content}}
                  </p>
               </div>
               <div *ngIf="pritn_xray_flag">
                  <p id="print_blood_head"><b>{{xray_test_head}}</b></p>
                  <p style="margin-left: 20px;margin-top:0;">
                     {{xray_test_content}}
                  </p>
               </div>
               <div *ngIf="pritn_bio_flag">
                  <p id="print_blood_head"><b>{{bio_test_head}}</b></p>
                  <p style="margin-left: 20px;margin-top:0;">
                     {{bio_test_content}}
                  </p>
               </div>
               <div *ngIf="pritn_ut_flag">
                  <p id="print_blood_head"><b>{{ut_test_head}}</b></p>
                  <p style="margin-left: 20px;margin-top:0;">
                     {{ut_test_content}}
                  </p>
               </div>
               <div *ngIf="pritn_ft_flag">
                  <p id="print_blood_head"><b>{{ft_test_head}}</b></p>
                  <p style="margin-left: 20px;margin-top:0;">
                     {{ft_test_content}}
                  </p>
               </div>
               <div *ngIf="pritn_culture_flag">
                  <p id="print_blood_head"><b>{{culture_test_head}}</b></p>
                  <p style="margin-left: 20px;margin-top:0;">
                     {{culture_test_content}}
                  </p>
               </div>
            </div>
            <div style="margin-left: 220px;">
               <p *ngIf="instructions!=undefined"><b>{{'Instructions' |translate}}: </b>
                  <span>
                     <div class="matlabel" [innerHtml]="print_instruction"></div>
                  </span>
               </p>
            </div>

            <div style="margin-left: 220px;" *ngIf="treatflag">
               <p><b>{{'Treatment' |translate}}: </b>
                  <span>
                     <div class="matlabel" [innerHtml]="treatment"></div>
                  </span>
               </p>
            </div>

            <div style="margin-left: 220px;">
               <p *ngIf="next_txt!=undefined"><b>{{'Next visit' |translate}}: </b>{{next_txt | date : "dd-MM-yyyy"}}</p>
            </div>
            <br />
            <div>
               <p style="margin-left: 50px;font-size: 13px;"> <strong>{{"Dr."+doct_name}} {{qualification}}</strong>
               </p>
               <p style="margin-left: 50px;font-size: 13px">({{licence_code}})</p>
               <p style="margin-left: 50px;font-size: 13px">Find your prescription online at <i>www.tervys.com</i></p>
               <p style="margin-left: 50px;font-size: 13px"><b><i>This is digitally generated prescription</i></b></p>
               <br />
            </div>
         </div>
         <div *ngIf="print_view_flag">
            <div *ngIf="listProducts.length != 0">
               <table style="margin-left: 220px; margin-top:350px;">
                  <tr>
                     <td style="width: 500px;">
                        <p><b>{{'Name'|translate}} :</b> {{doct_clientname}}</p>
                        <p><b>{{'Mobile'}} :</b> {{mobile}}</p>
                        <p><b>{{'Age'|translate}} :</b> {{client_age}}
                           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{{'Gender'|translate}}:
                           </b>{{client_gender}} </p>
                        <p *ngIf="oberse_med!=undefined"><b>{{'Investigation'|translate}} :</b> {{oberse_med}}</p>
                        <p *ngIf="disease_flag"><b>Disease : </b>{{disease_name}}</p>

                     </td>
                     <td style="width: 300px;vertical-align: top;">
                        <p><b>{{'Date'|translate}} : </b>{{Appoint_Date}}</p>
                        <p *ngIf="ref_flag"><b>{{'Referred by'|translate}}: </b>{{refer_txt}}</p>
                        <p *ngIf="bp_flag"><b>{{'BP'|translate}}: </b>{{clnt_bp}}
                           &nbsp;&nbsp;&nbsp;<b>{{'Pulse'|translate}}:
                           </b>{{clnt_pulse}}</p>
                        <p *ngIf="height_flag"><b>{{'Height'|translate}}: </b>{{height}} &nbsp;&nbsp;&nbsp;<span
                              *ngIf="weight_flag"><b>{{'Weight'|translate}}: </b>
                              {{weight}}</span></p>
                        <p *ngIf="temparature_flag"><b> {{'Temperature'|translate}}: </b>{{temparature}} </p>
                        <p *ngIf="ortho_flag"><b>{{'CVS'|translate}}: </b>{{clnt_cvs}}
                           &nbsp;&nbsp;&nbsp;<b>{{'RS'|translate}}: </b>
                           {{clnt_rs}}</p>
                        <p *ngIf="ortho_flag"><b> {{'Kidney'|translate}}: </b>{{clnt_kidney}}</p>
                     </td>
                  </tr>
               </table>
               <div style="margin-left: 220px;" *ngIf="print_view_flag && listProducts.length != 0">
                  <p><b>{{'Diagnosis' |translate}}: </b>{{dis_txt}}</p>
               </div>
               <br />
               <table *ngIf="listProducts.length != 0"
                  style="margin-left: 220px; border: 1px solid black;border-collapse: collapse;">
                  <tr>
                     <th [style]="med_style">
                        <b>{{'Medicine'|translate}}</b>
                     </th>
                     <th [style]="dosage_style">
                        <b>{{'Dosage'|translate}}</b>
                     </th>
                     <th [style]="dur_style">
                        <b>{{'Duration'|translate}}</b>
                     </th>
                     <th [style]="freq_style">
                        <b>{{'Frequency'|translate}}</b>
                     </th>
                     <th style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">&nbsp;</th>
                     <th style="width: 50px;border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">
                        <b>{{'Note'|translate}}</b>
                     </th>
                  </tr>
                  <tr *ngFor="let product of listProducts">
                     <td style="border: 1px solid black;border-collapse: collapse;">
                        {{product.short_name}} {{product.drug_name}}
                     </td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                        {{product.every_six}}
                     </td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">{{product.days}}
                        {{product.period|translate}}</td>
                     <td style="text-align: center;border: 1px solid black;border-collapse: collapse;">
                        {{product.frequency
                        |
                        translate}}</td>
                     <td style="border: 1px solid black;border-collapse: collapse;" [hidden]="dur_flag">
                        {{product.dure_txt_table}}
                        {{product.drug_intake|translate}}</td>
                     <td style="border: 1px solid black;border-collapse: collapse;" [hidden]="notes_flag">
                        {{product.remarks}}</td>
                  </tr>
               </table>

               <div style="margin-left: 220px;">
                  <p *ngIf="instructions!=undefined"><b>{{'Instructions' |translate}}: </b>
                     <span>
                        <div class="matlabel" [innerHtml]="print_instruction"></div>
                     </span>
                  </p>
               </div>

               <div style="margin-left: 220px;" *ngIf="treatflag">
                  <p><b>{{'Treatment' |translate}}: </b>
                     <span>
                        <div class="matlabel" [innerHtml]="treatment"></div>
                     </span>
                  </p>
               </div>

               <div style="margin-left: 220px;">
                  <p *ngIf="next_txt!=undefined"><b>{{'Next visit' |translate}}: </b>{{next_txt | date : "dd-MM-yyyy"}}</p>
               </div>
               <br />
               <div>
                  <p style="margin-left: 220px;font-size: 13px;"> <strong>{{"Dr."+doct_name}} {{qualification}}</strong>
                  </p>
                  <p style="margin-left: 220px;font-size: 13px">({{licence_code}})</p>
                  <p style="margin-left: 220px;font-size: 13px">Find your prescription online at <i>www.tervys.com</i>
                  </p>
                  <p style="margin-left: 220px;font-size: 13px"><b><i>This is digitally generated prescription</i></b>
                  </p>
                  <br />
               </div>
            </div>
         </div>
         <!-- <footer [hidden]="printf_flag">
                <img src={{footer_image}}>
              </footer> -->
      </div>
   </div>
   <div *ngIf="highRiskPresent">
      <div #printconcernform id="printconcernform">
        <div  style="background-image: url({{bgImage}}); background-position: center;">
  
          <table style="border:'0';width:100%">
            <thead>
              <tr>
                <th style="width:100%;">
                  <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                    <div style="width: 90%; height: 278px;">
                      <!-- <img alt="image" src={{hospitalLogo}} width="100%" height="170px"> -->
                      <div style="height: 160px;"></div>
  
                      <table
                        style="width: 100%; font-size: 11px; border: 1px solid black; margin-top: 5px; border-radius: 10px; margin-bottom: 5px; height: 95px;">
                        <tbody>
                          <tr>
                            <td style="padding-left: 5px;"><strong>Patient Name</strong></td>
                            <td>: {{doct_clientname}}</td>
                            <td style="padding-left: 5px;"><strong>Age / Gender</strong></td>
                            <td>: {{client_age}}/{{client_gender}}</td>
                          </tr>
  
                          <tr>
                            <td style="padding-left: 5px;"><strong>Address</strong></td>
                            <td>: {{addressdetails}}</td>
                          </tr>
                          <tr>
                            <td style="padding-left: 5px;"><strong>Mobile</strong></td>
                            <td> : {{mobile}}</td>
                          </tr>
                          <tr>
                            <td style="padding-left: 5px;"><strong>Doctor name</strong></td>
                            <td>: {{doct_name}}</td>
  
                          </tr>
                          <!-- <tr *ngIf="diag_report_type != 'radiology'">
                              
                           </tr> -->
                          <tr>
                            <td style="padding-left: 5px;"><strong>Date & Time</strong></td>
                            <td> : {{Appoint_Date}} {{printtime}}</td>
                            <td *ngIf="barcode_flag"><img
                                style="width: 130px; height: 35px; margin-left: -12px;         margin-top: -24px;"
                                src={{barcode_url}} /></td>
                          </tr>
                        </tbody>
                      </table>
                      <!-- <div style="margin: 0px !important;" *ngIf="diag_report_type == 'pathology'">
                           <div style="display: flex;">
                              <div style="margin-bottom: 0px !important; width: 270px;">Test name</div>
                              <div style="margin-bottom: 0px !important; width: 153px;">Reading</div>
                              <div style="margin-bottom: 0px !important; width: 118px;">Unit</div>
                              <div>Reference value</div>
                            
                           </div>
                        </div> -->
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
  
            <tbody>
              <tr>
                <td width="100%">
                  <div style="width: 100%;height: auto;display: contents; display: flex; justify-content: center;">
                    <table class=paging style="width:90%;">
                      <!-- <thead>
                           <tr>
                              <td style="width: 100%;">
                                 <img alt="image" src={{hospitalLogo}} width="100%" height="170px">
                              </td>
                           </tr>
                        </thead> -->
                      <tbody>
                        <tr>
                          <td>
                            <div class="main"
                              style="display: flex; flex-direction: column; margin-top: 10px; ">
  
                              <p style="font-size: 12px;">I do hereby give my concent for any
                                investigation,operation,anesthesia and treatment consider necessary in the patient
                              </p>
  
                              <div style="display: flex;vertical-align: top;">
                                <table style="vertical-align: top;">
                                  <tr style="vertical-align: top;">
                                    <td><span style="font-weight: 550;">Consultant</span></td>
                                    <td>: {{consultantanme}}</td>
                                  </tr>
                                  <tr style="vertical-align: top;" *ngIf="Inpatientflag">
                                    <td><span style="font-weight: 550;">Room No</span></td>
                                    <td>: {{ward_bed}}</td>
                                  </tr>
  
  
  
                                  <tr style="vertical-align: top;">
                                    <td><span style="font-weight: 550;">Allergic to</span></td>
                                    <td>: {{allergy}}</td>
                                  </tr>
                                  <tr style="vertical-align: top;">
                                    <td><span style="font-weight: 550;">Drug allergy</span ></td>
                                    <td>: {{drug_details
                                      }}</td>
                                  </tr>
                                  <tr style="vertical-align: top;">
                                    <td><span style="font-weight: 550;">Env allergy</span ></td>
                                    <td>: {{env_details
                                      }}</td>
                                  </tr>
                                  <tr style="vertical-align: top;">
                                    <td><span style="font-weight: 550;">Food allergy</span ></td>
                                    <td>: {{food_details
                                      }}</td>
                                  </tr>
                                  <tr style="vertical-align: top;">
                                    <td><span style="font-weight: 550;">Blood Group</span></td>
                                    <td>: {{blood_group}}</td>
                                  </tr>
  
                                </table>
                              </div>
                              <table class="table" style="margin-bottom: 30px;">
                                <tr>
                                  <td class="align_left">
                                    <br>
                                    <div>
                                      <p style="font-size: 11px;">1.{{ hospitalname }} சட்ட திட்டங்களுக்கும்,
                                        விதிகளுக்கும் ஒப்புக்கொண்டு டாக்டர் ஆலோசனைப்படி நடக்கவும் சம்மதிக்கிறேன்.</p>
                                      <p style="font-size: 11px;">2.மேற்படி ஆஸ்பத்திரியில் பரிசோதனை செய்வதற்கும்.
                                        சிகிச்சை செய்வதற்கும், மயக்க மருந்து கொடுப்பதற்கும், ஆபரேஷன்' செய்வதற்கும்
                                        நோயாளியின் நலனுக்காக என்பதை அறிந்து நான் முழுமனதுடன் ஒப்புதல் அளிக்கிறேன்.</p>
                                      <p style="font-size: 11px;">3.எங்களுடைய உடைமைகள் மற்றும் பொருட்களை நாங்கள் எங்கள்
                                        பொறுப்பில் பார்த்துக் கொள்ள சம்மதிக்கிறேன்.</p>
                                      <p style="font-size: 11px;">4.எங்களுடைய உடைமைகள் மற்றும் பொருட்களுக்கு
                                        நிர்வாகஸ்தாரோ, உரிமையாளரோ பொறுப்பல்ல என்பதை இதன் மூலம் அறிகிறேன்.</p>
                                      <p style="font-size: 11px;">5.எங்களுக்கு ஏற்படும் நஷ்டத்திற்கு நாங்களே பொறுப்பு
                                        என்பதை அறிந்து இங்கு அட்மிஷன் செய்ய சம்மதிக்கிறேன்.</p>
                                      <p style="font-size: 11px;">6.போலீஸ் /கோர்ட் சம்பந்தப்பட்ட (M.L.C.) கேஸ் அல்ல
                                        என்று உறுதியளித்து அட்மிஷன் செய்கிறேன்.சகல நஷ்டத்திற்கு நானே பொறுப்பு என்பதை
                                        இதன் மூலம் உறுதியளிக்கிறேன்.</p>
                                    </div>
                                  </td>
                                </tr>
                              </table>
                              <div class="empty-footer" style="text-align: center; margin-top: 10px;">
                                <!-- <h4>------ End of Report ------</h4> -->
                              </div>
  
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td width="100%">
                            <table style="border:'0';width:100%">
                              <tr>
                                <td style="width:100%;height: 90px;">
                                  <div class="container-fluid">
                                    <div class="row d-flex flex-row justify-content-center">
                                      <table style="width: 100%;">
                                        <tr>
                                          <td style="width: 90%;text-align: center;">
                                            <div class="row">
                                              <div class="d-flex flex-row justify-content-center align-items-end">
                                                <div  class="col-4">
                                                  <div style="text-align: center;">
                                                    <!-- <img *ngIf="lab_tech_sign" style="width: 55px;"
                                                      src={{lab_tech_sign}} />
                                                    <p style="margin-bottom: 3px !important;">
                                                      {{createdBy}}</p> -->
                                                    <p><strong>Medical Officer Signature and date</strong>
                                                    </p>
                                                  </div>
                                                  <!-- <mat-label class="matlabel"><strong>Lab
                                                                  technician
                                                                  :</strong> {{createdBy}}
                                                                    </mat-label> -->
                                                </div>
                                                <div class="col-4">
                                                  <!-- <div style="text-align: center;"> -->
                                                    <!-- <img *ngIf="approver_sign" style="width: 55px;"
                                                      src={{approver_sign}} />
                                                    <p *ngIf="approver_name == undefined"
                                                      style="margin-bottom: 3px !important;">
                                                      &nbsp;</p> -->
                                                    <!-- <p 
                                                      style="margin-bottom: 3px !important;">
                                                      {{approver_name}}</p> -->
                                                    <!-- <p><strong>Medical Officer Signature and date</strong>
                                                    </p>
                                                  </div> -->
                                                  <!-- <mat-label class="matlabel"><strong>Approver
                                                                  name:
                                                                  </strong> {{approver_name}}
                                                                  </mat-label> -->
                                                </div>
                                                <div class="col-4" >
                                                  <div style="text-align: center;">
                                                    <!-- <img *ngIf="quality_head_sign" style="width: 55px;"
                                                      src={{quality_head_sign}} /> -->
                                                    <!-- <p style="margin-bottom: 3px !important;">
                                                      {{qualityHead_name}}</p> -->
                                                    <p><strong>Parent or Guardian Signature and date</strong></p>
                                                  </div>
                                                  <!-- <mat-label class="matlabel"><strong>Quality Head
                                                                  :</strong> {{qualityHead_name}}
                                                                  </mat-label> -->
                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      </table>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </table>
                      </tfoot>
                    </table>
                  </div>
                </td>
              </tr>
            </tbody>
  
          </table>
  
          <footer style="width:100%;">
            <table style="border:'0';width:100%; margin-bottom: 0;">
              <tr>
                <td style="width:100%;height: 100px;">&nbsp;
                  <!-- <img src={{hospitalFooter}} width="100%" height="200px"> -->
                </td>
              </tr>
            </table>
          </footer>
        </div>
      </div>
      
      <div #printconcernformbanner id="printbanner"
      style="background-image: url({{bgImage}}); background-position: center;">
      <table style="border:'0';width:100%;">
         <thead>
            <tr>
               <th style="width:100%;">
                  <div class="main"
                     style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                     <div style="width: 90%; height: 278px;">
                        <img alt="image" src={{hospitalLogo}} width="100%" height="160px">
  
                        <table
                        style="width: 100%; font-size: 11px; border: 1px solid black; margin-top: 5px; border-radius: 10px; margin-bottom: 5px; height: 95px;">
                        <tbody>
                          <tr>
                            <td style="padding-left: 5px;"><strong>Patient Name</strong></td>
                            <td>: {{doct_clientname}}</td>
                            <td style="padding-left: 5px;"><strong>Age / Gender</strong></td>
                            <td>: {{client_age}}/{{client_gender}}</td>
                          </tr>
  
                          <tr>
                            <td style="padding-left: 5px;"><strong>Address</strong></td>
                            <td>: {{addressdetails}}</td>
                          </tr>
                          <tr>
                            <td style="padding-left: 5px;"><strong>Mobile</strong></td>
                            <td> : {{mobile}}</td>
                          </tr>
                          <tr>
                            <td style="padding-left: 5px;"><strong>Doctor name</strong></td>
                            <td>: {{doct_name}}</td>
  
                          </tr>
                          <!-- <tr *ngIf="diag_report_type != 'radiology'">
                              
                           </tr> -->
                          <tr>
                            <td style="padding-left: 5px;"><strong>Date & Time</strong></td>
                            <td> : {{Appoint_Date}} {{printtime}}</td>
                            <td *ngIf="barcode_flag"><img
                                style="width: 130px; height: 35px; margin-left: -12px;         margin-top: -24px;"
                                src={{barcode_url}} /></td>
                          </tr>
                        </tbody>
                      </table>
                     </div>
  
                  </div>
               </th>
            </tr>
         </thead>
  
         <tbody>
            <tr>
               <td width="100%">
                  <div
                     style="width: 100%;height: auto;display: contents; display: flex; justify-content: center;">
                     <table class=paging style="width:90%;">
                        <!-- <thead>
                           <tr>
                              <td style="width: 100%;">
                                 <img alt="image" src={{hospitalLogo}} width="100%" height="170px">
                              </td>
                           </tr>
                        </thead> -->
                        <tbody>
                           <tr>
                              <td>
                                <div class="main"
                                style="display: flex; flex-direction: column; margin-top: 10px; ">
    
                                <p style="font-size: 12px;">I do hereby give my concent for any
                                  investigation,operation,anesthesia and treatment consider necessary in the patient
                                </p>
    
                                <div style="display: flex;vertical-align: top;">
                                  <table style="vertical-align: top;">
                                    <tr style="vertical-align: top;">
                                      <td><span style="font-weight: 550;">Consultant</span></td>
                                      <td>: {{consultantanme}}</td>
                                    </tr>
                                    <tr style="vertical-align: top;" *ngIf="Inpatientflag">
                                      <td><span style="font-weight: 550;">Room No</span></td>
                                      <td>: {{ward_bed}}</td>
                                    </tr>
    
    
    
                                    <tr style="vertical-align: top;">
                                      <td><span style="font-weight: 550;">Allergic to</span></td>
                                      <td>: {{allergy}}</td>
                                    </tr>
                                    <tr style="vertical-align: top;">
                                      <td><span style="font-weight: 550;">Drug allergy</span ></td>
                                      <td>: {{drug_details
                                        }}</td>
                                    </tr>
                                    <tr style="vertical-align: top;">
                                      <td><span style="font-weight: 550;">Env allergy</span ></td>
                                      <td>: {{env_details
                                        }}</td>
                                    </tr>
                                    <tr style="vertical-align: top;">
                                      <td><span style="font-weight: 550;">Food allergy</span ></td>
                                      <td>: {{food_details
                                        }}</td>
                                    </tr>
                                    <tr style="vertical-align: top;">
                                      <td><span style="font-weight: 550;">Blood Group</span></td>
                                      <td>: {{blood_group}}</td>
                                    </tr>
    
                                  </table>
                                </div>
                                <table class="table" style="margin-bottom: 30px;">
                                  <tr>
                                    <td class="align_left">
                                      <br>
                                      <div>
                                        <p style="font-size: 11px;">1.{{ hospitalname }} சட்ட திட்டங்களுக்கும்,
                                          விதிகளுக்கும் ஒப்புக்கொண்டு டாக்டர் ஆலோசனைப்படி நடக்கவும் சம்மதிக்கிறேன்.</p>
                                        <p style="font-size: 11px;">2.மேற்படி ஆஸ்பத்திரியில் பரிசோதனை செய்வதற்கும்.
                                          சிகிச்சை செய்வதற்கும், மயக்க மருந்து கொடுப்பதற்கும், ஆபரேஷன்' செய்வதற்கும்
                                          நோயாளியின் நலனுக்காக என்பதை அறிந்து நான் முழுமனதுடன் ஒப்புதல் அளிக்கிறேன்.</p>
                                        <p style="font-size: 11px;">3.எங்களுடைய உடைமைகள் மற்றும் பொருட்களை நாங்கள் எங்கள்
                                          பொறுப்பில் பார்த்துக் கொள்ள சம்மதிக்கிறேன்.</p>
                                        <p style="font-size: 11px;">4.எங்களுடைய உடைமைகள் மற்றும் பொருட்களுக்கு
                                          நிர்வாகஸ்தாரோ, உரிமையாளரோ பொறுப்பல்ல என்பதை இதன் மூலம் அறிகிறேன்.</p>
                                        <p style="font-size: 11px;">5.எங்களுக்கு ஏற்படும் நஷ்டத்திற்கு நாங்களே பொறுப்பு
                                          என்பதை அறிந்து இங்கு அட்மிஷன் செய்ய சம்மதிக்கிறேன்.</p>
                                        <p style="font-size: 11px;">6.போலீஸ் /கோர்ட் சம்பந்தப்பட்ட (M.L.C.) கேஸ் அல்ல
                                          என்று உறுதியளித்து அட்மிஷன் செய்கிறேன்.சகல நஷ்டத்திற்கு நானே பொறுப்பு என்பதை
                                          இதன் மூலம் உறுதியளிக்கிறேன்.</p>
                                      </div>
                                    </td>
                                  </tr>
                                </table>
                                <div class="empty-footer" style="text-align: center; margin-top: 10px;">
                                  <!-- <h4>------ End of Report ------</h4> -->
                                </div>
    
                              </div>
                              </td>
                           </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td width="100%">
                              <table style="border:'0';width:100%">
                                <tr>
                                  <td style="width:100%;height: 90px;">
                                    <div class="container-fluid">
                                      <div class="row d-flex flex-row justify-content-center">
                                        <table style="width: 100%;">
                                          <tr>
                                            <td style="width: 90%;text-align: center;">
                                              <div class="row">
                                                <div class="d-flex flex-row justify-content-center align-items-end">
                                                  <div  class="col-4">
                                                    <div style="text-align: center;">
                                                      <!-- <img *ngIf="lab_tech_sign" style="width: 55px;"
                                                        src={{lab_tech_sign}} />
                                                      <p style="margin-bottom: 3px !important;">
                                                        {{createdBy}}</p> -->
                                                      <p><strong>Medical Officer Signature and date</strong>
                                                      </p>
                                                    </div>
                                                    <!-- <mat-label class="matlabel"><strong>Lab
                                                                    technician
                                                                    :</strong> {{createdBy}}
                                                                      </mat-label> -->
                                                  </div>
                                                  <div class="col-4">
                                                    <!-- <div style="text-align: center;"> -->
                                                      <!-- <img *ngIf="approver_sign" style="width: 55px;"
                                                        src={{approver_sign}} />
                                                      <p *ngIf="approver_name == undefined"
                                                        style="margin-bottom: 3px !important;">
                                                        &nbsp;</p> -->
                                                      <!-- <p 
                                                        style="margin-bottom: 3px !important;">
                                                        {{approver_name}}</p> -->
                                                      <!-- <p><strong>Medical Officer Signature and date</strong>
                                                      </p>
                                                    </div> -->
                                                    <!-- <mat-label class="matlabel"><strong>Approver
                                                                    name:
                                                                    </strong> {{approver_name}}
                                                                    </mat-label> -->
                                                  </div>
                                                  <div class="col-4" >
                                                    <div style="text-align: center;">
                                                      <!-- <img *ngIf="quality_head_sign" style="width: 55px;"
                                                        src={{quality_head_sign}} /> -->
                                                      <!-- <p style="margin-bottom: 3px !important;">
                                                        {{qualityHead_name}}</p> -->
                                                      <p><strong>Parent or Guardian Signature and date</strong></p>
                                                    </div>
                                                    <!-- <mat-label class="matlabel"><strong>Quality Head
                                                                    :</strong> {{qualityHead_name}}
                                                                    </mat-label> -->
                                                  </div>
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        </table>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </table>
                        </tfoot>
                     </table>
                  </div>
               </td>
            </tr>
         </tbody>
  
      </table>
  
      <footer style="width:100%;">
         <table style="border:'0';width:100%; margin-bottom: 0;">
            <tr>
               <td style="width:100%;height: 100px;">&nbsp;
                  <!-- <img src={{hospitalFooter}} width="100%" height="200px"> -->
               </td>
            </tr>
         </table>
      </footer>
   </div>
      </div>
   </div>
</div>