import { Component, OnInit, ElementRef, Input, ViewEncapsulation, OnDestroy, Inject } from '@angular/core';
import { Http, Headers } from '@angular/http';
// import { getIp, Ip_with_img_address, country_code } from '../../../assets/js/ipaddress';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
// import { Master_Helper } from '../../Doctor_module/Master_Helper';
// import { MasterHelperService } from '../../Doctor_module/MasterHelper.service';
// import { Helper_Class } from '../../helper_class';
// import { Router, ActivatedRoute, ParamMap } from '@angular/router';
// import { encrypt_decript } from '../../../assets/js/Encription_Decription';
// import { ConvertTimeformat, Date_Formate, tConv24, Time_Formate } from '../../../assets/js/common';
import { ipaddress } from '../../ipaddress'; import { Doc_Helper } from '../../Doctor_module/Doc_Helper';
// import { MenuViewService } from '../../common-module/menu-view/menu-view.service';
import { Message_data } from '../../../assets/js/Message_data';
import { Nurse_Helper } from '../../Nurse_module/Nurse_Helper';
import moment from 'moment';
import { Helper_Class } from 'src/app/helper_class';
import { Date_Formate } from 'src/assets/js/common';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-warstracker-info',
  templateUrl: './warstracker-info.component.html',
  styleUrls: ['./warstracker-info.component.scss']
})
export class WarstrackerInfoComponent implements OnInit {
  public current_date;
  public showfoodarray=[];
  public trackerArrayvalue;
  public dietsave:boolean=true;
  public medsave: boolean = true;
  public history_diet: boolean = false;
  public showarray_diet: any = []
  public Drug_Hist_List = [];
  public history_med: boolean = false;
  public days;
  public Date;
  public today;
  public med_url_txt;
  public sub_rel_id;
  public relation_id;
  public client_reg_id;
  public trackerArray = [];
  public send_data_med;
  public session;
  public current_time;
  public Medicine_Hist_List;
  public Medicine_Hist_List_HISTORY;
  public data1;
  public thd_value;
  public title;
  public foodarray;
  public foodarray_hist = [];;
  public flagselection: boolean;
  @Input() id: string; parm: string;
  public value: any;

  public life_style_id;
  public currentDay;
  public element: any;
  public savebtnhidden: boolean = true;
  public showarray: any = [];
  constructor(public http: Http, public dialogRef: MatDialogRef<WarstrackerInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public el: ElementRef, public toastr: ToastrService) {
    dialogRef.disableClose = true;
    console.log("data close ==" + JSON.stringify(data))
    console.log("data close ==" + JSON.stringify(Helper_Class.getapp_flowinpa()))
    if (Helper_Class.getapp_flowinpa() == undefined) {
      this.savebtnhidden = false;
    } else {
      if (Helper_Class.getapp_flowinpa() != undefined) {
        if (Helper_Class.getapp_flowinpa() == "Inpatient") {
          this.savebtnhidden = true;
        } else {
          this.savebtnhidden = false;
        }
      }
    }

    //this.savebtnhidden=true;
    if (data.Title == "Medicine") {
      this.title = data.Title;
      this.data = data.warddata;
      this.Medicine_Hist_List = [];
      this.Medicine_Hist_List_HISTORY = []
      this.flagselection = true;
      this.foodarray = [];
      this.appGetDate(this.title,)

    } else {
      this.title = "Diet"
      this.data1 = data.warddata;
      this.Medicine_Hist_List = [];
      this.Medicine_Hist_List_HISTORY = [];
      this.foodarray = [];
      this.flagselection = false;
      this.appGetDate(this.title)

    }


  }

  ngOnInit() {
    this.trackerArrayvalue=[]
    this.Drug_Hist_List = [];
    this.days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    this.today = this.days[new Date().getDay()];
  }
  appGetDate(e) {
    var headers = new Headers();
    var date
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate', { country: ipaddress.country_code },
      { headers: headers })
      .subscribe(
        response => {
          if (e == "Diet") {
            var obj = JSON.parse(response["_body"]);
            console.log("current time", JSON.stringify(obj))
            this.current_date = obj.current_date;
            this.current_time = obj.current_time;
            this.currentDay = moment().format('dddd');

            var currenttime = obj.current_time.split(":");
            if (currenttime[0] >= 6 && currenttime[0] < 8) {
              this.session = "Morning";
            } else if (currenttime[0] >= 8 && currenttime[0] < 10) {
              this.session = "Breakfast";
            } else if (currenttime[0] >= 10 && currenttime[0] < 12) {
              this.session = "Midday";
            } else if (currenttime[0] >= 12 && currenttime[0] < 14) {
              this.session = "Lunch";
            } else if (currenttime[0] >= 14 && currenttime[0] < 18) {
              this.session = "Evening";
            } else if (currenttime[0] >= 18 && currenttime[0] < 21) {
              this.session = "Dinner";
            }
            else if (currenttime[0] >= 21 && currenttime[0] < 22) {
              this.session = "Night";
            }
            let days = [];
            let daysRequired = 6;
            for (let i = 0; i <= daysRequired; i++) {
              var day = {
                days: moment().subtract(i, 'days').format('dddd'),
                date: moment().subtract(i, 'days').format('DD-MM-YYYY'),
              }
              days.push(day)
            }
            var sendArray = {
              created_date: (this.current_date),
              day: this.currentDay,
              dplan_morning: [],
              dplan_breakfast: [],
              dplan_midday: [],
              dplan_lunch: [],
              dplan_evening: [],
              dplan_dinner: [],
              dplan_night: [],
              cal_required: "0.00",
              cal_intake: "0.00"
            }
            this.trackerArray.push(sendArray)
            console.log("dayss--" + JSON.stringify(this.trackerArray))

            this.retrieveDataHist(this.data1)
           
            console.log('presentdate1', this.session + currenttime)
          } else {
            var obj = JSON.parse(response["_body"]);
            console.log("current time", JSON.stringify(obj))
            this.current_date = obj.current_date;
            this.current_time = obj.current_time;
            var currenttime = obj.current_time.split(":");
            if (currenttime[0] >= 3 && currenttime[0] < 12) {
              this.session = "Morning";
            } else if (currenttime[0] >= 12 && currenttime[0] < 16) {
              this.session = "Afternoon";
            } else if (currenttime[0] >= 16 && currenttime[0] < 19) {
              this.session = "Evening";
            } else if (currenttime[0] >= 19 && currenttime[0] < 24) {
              this.session = "Evening";
            }
            this.life_style_id = this.data.life_style_id;
            this.medicineTrackerHistory(this.data);
          }

        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });

  }
  findMatchingObjects(jsonArray1: any[], jsonArray2: any[]): any[] {
    let matchingObjects: any[] = [];
    for (var obj1 of jsonArray1) {
      for (var i = 0; i < jsonArray2.length; i++) {
        var obj2 = jsonArray2[i];
        if (obj1.drug_id === obj2.drug_id && obj1.session === this.session) {
          // Match found, remove obj2 from jsonArray2
          jsonArray2.splice(i, 1);
          matchingObjects.push(obj1);
          break; // Assuming you want to break after finding one match
        }
      }
    }

    return matchingObjects;
  }
  findMatchingObjectsvalue(jsonArray1: any[], jsonArray2: any[]): any[] {
    let matchingObjects: any[] = [];
    for (var obj1 of jsonArray1) {
      for (var i = 0; i < jsonArray2.length; i++) {
        var obj2 = jsonArray2[i];
        if (obj1.drug_id === obj2.drug_id && obj1.session === this.session) {
          // Match found, remove obj2 from jsonArray2
          jsonArray2.splice(i, 1);
         // matchingObjects.push(obj1);
          break; // Assuming you want to break after finding one match
        }
      }
    }

    return jsonArray2;
  }
  findMatchingObjectsdiet(jsonArray1: any[], jsonArray2: any[]): any[] {
    let matchingObjects =[];
    for (var obj1 of jsonArray1) {
      for (var i = 0; i < jsonArray2.length; i++) {
        var obj2 = jsonArray2[i];
        if (obj1.food_item_id === obj2.food_item_id && this.session === obj2.session) {
          // Match found, remove obj2 from jsonArray2
       
          matchingObjects.push(obj1);
          break; // Assuming you want to break after finding one match
        }
      }
    }

    return matchingObjects;
  }
  findMatchingObjectsdiet1(jsonArray1: any[], jsonArray2: any[]): any[] {
    let matchingObjects =[];
    for (var obj1 of jsonArray1) {
      for (var i = 0; i < jsonArray2.length; i++) {
        var obj2 = jsonArray2[i];
        if (obj1.food_item_id === obj2.food_item_id && this.session === obj2.session) {
          // Match found, remove obj2 from jsonArray2
       
          jsonArray2.splice(i, 1);
          break; // Assuming you want to break after finding one match
        }
      }
    }

    return jsonArray2;
  }
  medicinetracker(warddata) {

    this.foodarray = [];
    this.med_url_txt = ipaddress.getIp + "lifestyle/pmeds";
    console.log("ward data ****" + JSON.stringify(warddata))
    if (warddata.relation_id != undefined) {
      this.relation_id = warddata.relation_id;
    }
    if (warddata.sub_rel_id != undefined) {
      this.sub_rel_id = warddata.sub_rel_id;
    }
    if (warddata.client_reg_id != undefined) {
      this.client_reg_id = warddata.client_reg_id;
    }
    if (warddata.life_style_id != undefined) {
      this.life_style_id = warddata.life_style_id;
    }

    this.sub_rel_id = this.sub_rel_id
    if (this.sub_rel_id != undefined && this.sub_rel_id != null && this.sub_rel_id != "") {
      this.send_data_med = {
        client_reg_id: this.client_reg_id,
        relation_id: this.relation_id,
        sub_rel_id: this.sub_rel_id,
        time_string: this.session
      }
    }
    else {
      this.send_data_med = {
        client_reg_id: this.client_reg_id,
        relation_id: this.relation_id,
        time_string: this.session
      }
    }
    console.log("ward data ****" + JSON.stringify(this.send_data_med))
    var image;
    var headers = new Headers();

    headers.append('Content-Type', 'application/json');
    this.http.post(this.med_url_txt, (this.send_data_med), { headers: headers }).subscribe(
      data => {
        var shortform;
        this.Medicine_Hist_List = [];

        var obj = JSON.parse(data["_body"]);
        console.log("check data" + JSON.stringify(obj))
        if (obj.medicines != null && obj.medicines.length != 0) {
          for (var i = 0; i < obj.medicines.length; i++) {
            if (obj.medicines[i].short_form != null && obj.medicines[i].short_form != undefined && obj.medicines[i].short_form != "") {
              shortform = obj.medicines[i].short_form;
            }
            else {
              shortform = "no(s)";
            }
            if (obj.medicines[i].short_name != null) {
              if (obj.medicines[i].short_name == "TAB") {
                image = "../../../assets/imgs/homepagemobileicons/Aspirin_tab_icon.png"
              }
              else if (obj.medicines[i].short_name == "CAP") {
                image = "../../../assets/imgs/homepagemobileicons/Synthroid_icon.png"
              }
              else if (obj.medicines[i].short_name == "Drops") {
                image = "../../../assets/imgs/homepagemobileicons/eyedrops_icon.png"
              }
            }

            this.Medicine_Hist_List.push({
              drug_id: obj.medicines[i].drug_id,
              drug_name: obj.medicines[i].drug_name,
              quantity: obj.medicines[i].quantity,
              short_form: shortform,
              time_duration: obj.medicines[i].time_duration,
              intake: obj.medicines[i].drug_intake,
              tabimg: image,
              name: "Medicine tracker",
              checked: false
            });
            Nurse_Helper.setwardinfomedicinetracker(null);
            console.log("check Medicine_Hist_List*****" + JSON.stringify(this.Medicine_Hist_List))
            console.log("check HIST" + JSON.stringify(this.Medicine_Hist_List_HISTORY))

            if (this.Medicine_Hist_List_HISTORY.length != 0 && this.Medicine_Hist_List.length != 0) {
              let resultArray = this.findMatchingObjects(this.Medicine_Hist_List_HISTORY, this.Medicine_Hist_List);
              console.log("resultArray" + JSON.stringify(resultArray));
          
              if(resultArray.length != 0){
              //  this.showarray = resultArray
                let resultArray = this.findMatchingObjectsvalue(this.Medicine_Hist_List_HISTORY, this.Medicine_Hist_List);
                this.showarray = resultArray;
                this.history_med=false;
              }else{
                this.showarray = this.Medicine_Hist_List
                this.history_med=false;
              }
            } 
            else if(this.Medicine_Hist_List_HISTORY.length != 0 && this.Medicine_Hist_List.length == 0){
              this.showarray = this.Medicine_Hist_List_HISTORY;
              this.history_med=true
            }
            else if(this.Medicine_Hist_List_HISTORY.length == 0 && this.Medicine_Hist_List.length != 0){
              this.showarray = this.Medicine_Hist_List;
              this.history_med=false
            }
            
            
            else {
              this.showarray = this.Medicine_Hist_List;
              this.history_med = false
            }
            console.log("resultArray" + JSON.stringify(this.showarray));

          }

        }

      },
      error => {
      })



  }
  retrieveDataHist(warddata) {
    this.Medicine_Hist_List = [];
    this.Medicine_Hist_List_HISTORY = []; this.showarray = []
    if (warddata.relation_id != undefined) {
      this.relation_id = warddata.relation_id;
    }
    if (warddata.sub_rel_id != undefined) {
      this.sub_rel_id = warddata.sub_rel_id;
    }
    if (warddata.client_reg_id != undefined) {
      this.client_reg_id = warddata.client_reg_id;
    }
    if (warddata.life_style_id != undefined) {
      this.life_style_id = warddata.life_style_id;
    }


    this.sub_rel_id = this.sub_rel_id
    if (this.sub_rel_id != undefined && this.sub_rel_id != null && this.sub_rel_id != "") {
      this.send_data_med = {
        client_reg_id: this.client_reg_id,
        relation_id: this.relation_id,
        life_style_id: this.life_style_id,
        sub_rel_id: this.sub_rel_id,
        country: ipaddress.country_code,
        is_doctor: "1",
        plan_date: this.current_date,
        end_date: this.current_date,
        type: "plan"
      }
    }
    else {
      this.send_data_med = {
        client_reg_id: this.client_reg_id,
        relation_id: this.relation_id,
        country: ipaddress.country_code,
        life_style_id: this.life_style_id,
        is_doctor: "1",
        plan_date: this.current_date,
        end_date: this.current_date,
        type: "plan"
      }
    }
    console.log("check now--" + JSON.stringify(this.send_data_med))
    console.log("session now--" + JSON.stringify(this.session))
    var image;
    var headers = new Headers();

    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'lifestyle/dpthn', (this.send_data_med), { headers: headers }).subscribe(
      data => {
        var shortform; var session;
        console.log("check HIST DATA" + JSON.stringify(obj) + this.session)
        var obj = JSON.parse(data["_body"]);
        this.foodarray_hist = [];
        var flag = this.isEmptyObject(obj);
        console.log("check HIST DATA" + JSON.stringify(obj) + flag)
        if(flag == false){
          if (obj != undefined) {
            if (this.session == "Dinner") {
              session = "dinner"
            }
            else if (this.session == "Breakfast") {
              session = "breakfast"
            }
            else if (this.session == "Lunch") {
              session = "lunch"
            }
            else if (this.session == "Night") {
              session = "night"
            }
            else if (this.session == "Midday") {
              session = "midday"
            }
            else if (this.session == "Morning") {
              session = "morning"
            }
            else if (this.session == "Evening") {
              session = "evening"
            }
            if (this.session == "Dinner" || session == "dinner" && obj.dinner != undefined && obj.dinner.length != 0) {
              for (var i = 0; i < obj.dinner.length; i++) {
                var tempWeight = obj.dinner[i].weight.split(" ");
                var calsWight = tempWeight[0];
                var wightMesure = tempWeight[1];
                this.foodarray_hist.push({
                  food_item_id: obj.dinner[i].food_item_id,
                  description: obj.dinner[i].food_desc,
                  food_type_id: obj.dinner[i].food_type_id,
                  food_sub_type: obj.dinner[i].food_sub_type,
                  quantity: obj.dinner[i].quantity != undefined ? obj.dinner[i].quantity : "0.00",
                  measure: obj.dinner[i].measure != undefined ? obj.dinner[i].measure : "0.00",
                  weight: obj.dinner[i].weight != undefined ? obj.dinner[i].weight : "0.00",
                  cals_weight: calsWight,
                  wightMesure: wightMesure,
                  // energy: obj.dinner[i].energy != undefined ? obj.dinner[i].energy : "0.00",
                  // proteins: obj.dinner[i].proteins != undefined ? obj.dinner[i].proteins : "0.00",
                  // carbohydrates: obj.dinner[i].carbohydrates != undefined ? obj.dinner[i].carbohydrates : "0.00",
                  // fat: obj.dinner[i].fat != undefined ? obj.dinner[i].fat : "0.00",
                  // calcium: obj.dinner[i].calcium != undefined ? obj.dinner[i].calcium : "0.00",
                  // iron: obj.dinner[i].iron != undefined ? obj.dinner[i].iron : "0.00",
                  // vitaminC: obj.dinner[i].vitaminC != undefined ? obj.dinner[i].vitaminC : "0.00",
                  // vitaminA: obj.dinner[i].vitaminA != undefined ? obj.dinner[i].vitaminA : "0.00",
                  // vitaminB12: obj.dinner[i].vitaminB12 != undefined ? obj.dinner[i].vitaminB12 : "0.00",
                  // fiber: obj.dinner[i].fiber != undefined ? obj.dinner[i].fiber : "0.00",
                  // folic: obj.dinner[i].folic != undefined ? obj.dinner[i].folic : "0.00",
                  // h2o: obj.dinner[i].h2o != undefined ? obj.dinner[i].h2o : "0.00",
                  session: "Dinner",
                  type: "Doctor",
                  image: ipaddress.Ip_with_img_address + "/" + obj.dinner[i].image,
                  day: obj.dinner[i].day,
                  date: obj.dinner[i].created_date,
                  checked: false, is_doctor: "1",
                })
              }
              console.log("ary length--"+JSON.stringify(this.foodarray_hist))
            }
            else if (this.session == "Breakfast" || session == "breakfast" && obj.breakfast != undefined && obj.breakfast.length != 0) {
              var tempWeight = obj.breakfast[i].weight.split(" ");
              var calsWight = tempWeight[0];
              var wightMesure = tempWeight[1];
              for (var i = 0; i < obj.breakfast.length; i++) {
                this.foodarray_hist.push({
                  food_item_id: obj.breakfast[i].food_item_id,
                  description: obj.breakfast[i].food_desc,
                  food_type_id: obj.breakfast[i].food_type_id,
                  food_sub_type: obj.breakfast[i].food_sub_type,
                  quantity: obj.breakfast[i].quantity != undefined ? obj.breakfast[i].quantity : "0.00",
                  measure: obj.breakfast[i].measure != undefined ? obj.breakfast[i].measure : "0.00",
                  weight: obj.breakfast[i].weight != undefined ? obj.breakfast[i].weight : "0.00",
                  cals_weight: calsWight,
                  wightMesure: wightMesure,
                  // energy: obj.dinner[i].energy != undefined ? obj.dinner[i].energy : "0.00",
                  // proteins: obj.dinner[i].proteins != undefined ? obj.dinner[i].proteins : "0.00",
                  // carbohydrates: obj.dinner[i].carbohydrates != undefined ? obj.dinner[i].carbohydrates : "0.00",
                  // fat: obj.dinner[i].fat != undefined ? obj.dinner[i].fat : "0.00",
                  // calcium: obj.dinner[i].calcium != undefined ? obj.dinner[i].calcium : "0.00",
                  // iron: obj.dinner[i].iron != undefined ? obj.dinner[i].iron : "0.00",
                  // vitaminC: obj.dinner[i].vitaminC != undefined ? obj.dinner[i].vitaminC : "0.00",
                  // vitaminA: obj.dinner[i].vitaminA != undefined ? obj.dinner[i].vitaminA : "0.00",
                  // vitaminB12: obj.dinner[i].vitaminB12 != undefined ? obj.dinner[i].vitaminB12 : "0.00",
                  // fiber: obj.dinner[i].fiber != undefined ? obj.dinner[i].fiber : "0.00",
                  // folic: obj.dinner[i].folic != undefined ? obj.dinner[i].folic : "0.00",
                  // h2o: obj.dinner[i].h2o != undefined ? obj.dinner[i].h2o : "0.00",
                  session: obj.breakfast[i].session,
                  type: "Doctor",
                  image: ipaddress.Ip_with_img_address + "/" + obj.breakfast[i].image,
                  day: obj.breakfast[i].day,
                  date: obj.breakfast[i].created_date,
                  checked: false, is_doctor: "1",
                })
              }
  
            }
            else if (this.session == "Morning" || session == "morning" && obj.morning != undefined && obj.morning.length != 0) {
              var tempWeight = obj.morning[i].weight.split(" ");
              var calsWight = tempWeight[0];
              var wightMesure = tempWeight[1];
              for (var i = 0; i < obj.morning.length; i++) {
                this.foodarray_hist.push({
                  food_item_id: obj.morning[i].food_item_id,
                  description: obj.morning[i].food_desc,
                  food_type_id: obj.morning[i].food_type_id,
                  food_sub_type: obj.morning[i].food_sub_type,
                  quantity: obj.morning[i].quantity != undefined ? obj.morning[i].quantity : "0.00",
                  measure: obj.morning[i].measure != undefined ? obj.morning[i].measure : "0.00",
                  weight: obj.morning[i].weight != undefined ? obj.morning[i].weight : "0.00",
                  cals_weight: calsWight,
                  wightMesure: wightMesure,
                  // energy: obj.dinner[i].energy != undefined ? obj.dinner[i].energy : "0.00",
                  // proteins: obj.dinner[i].proteins != undefined ? obj.dinner[i].proteins : "0.00",
                  // carbohydrates: obj.dinner[i].carbohydrates != undefined ? obj.dinner[i].carbohydrates : "0.00",
                  // fat: obj.dinner[i].fat != undefined ? obj.dinner[i].fat : "0.00",
                  // calcium: obj.dinner[i].calcium != undefined ? obj.dinner[i].calcium : "0.00",
                  // iron: obj.dinner[i].iron != undefined ? obj.dinner[i].iron : "0.00",
                  // vitaminC: obj.dinner[i].vitaminC != undefined ? obj.dinner[i].vitaminC : "0.00",
                  // vitaminA: obj.dinner[i].vitaminA != undefined ? obj.dinner[i].vitaminA : "0.00",
                  // vitaminB12: obj.dinner[i].vitaminB12 != undefined ? obj.dinner[i].vitaminB12 : "0.00",
                  // fiber: obj.dinner[i].fiber != undefined ? obj.dinner[i].fiber : "0.00",
                  // folic: obj.dinner[i].folic != undefined ? obj.dinner[i].folic : "0.00",
                  // h2o: obj.dinner[i].h2o != undefined ? obj.dinner[i].h2o : "0.00",
                  session: obj.morning[i].session,
                  type: "Doctor",
                  image: ipaddress.Ip_with_img_address + "/" + obj.morning[i].image,
                  day: obj.morning[i].day,
                  date: obj.morning[i].created_date,
                  checked: false, is_doctor: "1",
                })
              }
  
            }
            else if (this.session == "midday" || session == "midday" && obj.midday != undefined && obj.midday.length != 0) {
              for (var i = 0; i < obj.midday.length; i++) {
                var tempWeight = obj.midday[i].weight.split(" ");
                var calsWight = tempWeight[0];
                var wightMesure = tempWeight[1];
                this.foodarray_hist.push({
                  food_item_id: obj.midday[i].food_item_id,
                  description: obj.midday[i].food_desc,
                  food_type_id: obj.midday[i].food_type_id,
                  food_sub_type: obj.midday[i].food_sub_type,
                  quantity: obj.midday[i].quantity != undefined ? obj.midday[i].quantity : "0.00",
                  measure: obj.midday[i].measure != undefined ? obj.midday[i].measure : "0.00",
                  weight: obj.midday[i].weight != undefined ? obj.midday[i].weight : "0.00",
                  cals_weight: calsWight,
                  wightMesure: wightMesure,
                  // energy: obj.dinner[i].energy != undefined ? obj.dinner[i].energy : "0.00",
                  // proteins: obj.dinner[i].proteins != undefined ? obj.dinner[i].proteins : "0.00",
                  // carbohydrates: obj.dinner[i].carbohydrates != undefined ? obj.dinner[i].carbohydrates : "0.00",
                  // fat: obj.dinner[i].fat != undefined ? obj.dinner[i].fat : "0.00",
                  // calcium: obj.dinner[i].calcium != undefined ? obj.dinner[i].calcium : "0.00",
                  // iron: obj.dinner[i].iron != undefined ? obj.dinner[i].iron : "0.00",
                  // vitaminC: obj.dinner[i].vitaminC != undefined ? obj.dinner[i].vitaminC : "0.00",
                  // vitaminA: obj.dinner[i].vitaminA != undefined ? obj.dinner[i].vitaminA : "0.00",
                  // vitaminB12: obj.dinner[i].vitaminB12 != undefined ? obj.dinner[i].vitaminB12 : "0.00",
                  // fiber: obj.dinner[i].fiber != undefined ? obj.dinner[i].fiber : "0.00",
                  // folic: obj.dinner[i].folic != undefined ? obj.dinner[i].folic : "0.00",
                  // h2o: obj.dinner[i].h2o != undefined ? obj.dinner[i].h2o : "0.00",
                  session: obj.midday[i].session,
                  type: "Doctor",
                  // image: ipaddress.Ip_with_img_address + "/" + obj.dinner[i].image,
                  day: obj.midday[i].day,
                  date: obj.midday[i].created_date,
                  checked: false, is_doctor: "1",
                })
              }
  
            }
            else if (this.session == "Evening" || session == "evening" && obj.evening != undefined && obj.evening.length != 0) {
              for (var i = 0; i < obj.evening.length; i++) {
                var tempWeight = obj.evening[i].weight.split(" ");
                var calsWight = tempWeight[0];
                var wightMesure = tempWeight[1];
                this.foodarray_hist.push({
                  food_item_id: obj.evening[i].food_item_id,
                  description: obj.evening[i].food_desc,
                  food_type_id: obj.evening[i].food_type_id,
                  food_sub_type: obj.evening[i].food_sub_type,
                  quantity: obj.evening[i].quantity != undefined ? obj.evening[i].quantity : "0.00",
                  measure: obj.evening[i].measure != undefined ? obj.evening[i].measure : "0.00",
                  weight: obj.evening[i].weight != undefined ? obj.evening[i].weight : "0.00",
                  cals_weight: calsWight,
                  wightMesure: wightMesure,
                  // energy: obj.dinner[i].energy != undefined ? obj.dinner[i].energy : "0.00",
                  // proteins: obj.dinner[i].proteins != undefined ? obj.dinner[i].proteins : "0.00",
                  // carbohydrates: obj.dinner[i].carbohydrates != undefined ? obj.dinner[i].carbohydrates : "0.00",
                  // fat: obj.dinner[i].fat != undefined ? obj.dinner[i].fat : "0.00",
                  // calcium: obj.dinner[i].calcium != undefined ? obj.dinner[i].calcium : "0.00",
                  // iron: obj.dinner[i].iron != undefined ? obj.dinner[i].iron : "0.00",
                  // vitaminC: obj.dinner[i].vitaminC != undefined ? obj.dinner[i].vitaminC : "0.00",
                  // vitaminA: obj.dinner[i].vitaminA != undefined ? obj.dinner[i].vitaminA : "0.00",
                  // vitaminB12: obj.dinner[i].vitaminB12 != undefined ? obj.dinner[i].vitaminB12 : "0.00",
                  // fiber: obj.dinner[i].fiber != undefined ? obj.dinner[i].fiber : "0.00",
                  // folic: obj.dinner[i].folic != undefined ? obj.dinner[i].folic : "0.00",
                  // h2o: obj.dinner[i].h2o != undefined ? obj.dinner[i].h2o : "0.00",
                  session: obj.evening[i].session,
                  type: "Doctor",
                  image: ipaddress.Ip_with_img_address + "/" + obj.evening[i].image,
                  day: obj.evening[i].day,
                  date: obj.evening[i].created_date,
                  checked: false, is_doctor: "1",
                })
              }
  
            }
            else if (this.session == "Lunch" || session == "lunch" && obj.midday != undefined && obj.lunch.length != 0) {
              for (var i = 0; i < obj.lunch.length; i++) {
                var tempWeight = obj.lunch[i].weight.split(" ");
                var calsWight = tempWeight[0];
                var wightMesure = tempWeight[1];
                this.foodarray_hist.push({
                  food_item_id: obj.lunch[i].food_item_id,
                  description: obj.lunch[i].food_desc,
                  food_type_id: obj.lunch[i].food_type_id,
                  food_sub_type: obj.lunch[i].food_sub_type,
                  quantity: obj.lunch[i].quantity != undefined ? obj.lunch[i].quantity : "0.00",
                  measure: obj.lunch[i].measure != undefined ? obj.lunch[i].measure : "0.00",
                  weight: obj.lunch[i].weight != undefined ? obj.lunch[i].weight : "0.00",
                  cals_weight: calsWight,
                  wightMesure: wightMesure,
                  // energy: obj.dinner[i].energy != undefined ? obj.dinner[i].energy : "0.00",
                  // proteins: obj.dinner[i].proteins != undefined ? obj.dinner[i].proteins : "0.00",
                  // carbohydrates: obj.dinner[i].carbohydrates != undefined ? obj.dinner[i].carbohydrates : "0.00",
                  // fat: obj.dinner[i].fat != undefined ? obj.dinner[i].fat : "0.00",
                  // calcium: obj.dinner[i].calcium != undefined ? obj.dinner[i].calcium : "0.00",
                  // iron: obj.dinner[i].iron != undefined ? obj.dinner[i].iron : "0.00",
                  // vitaminC: obj.dinner[i].vitaminC != undefined ? obj.dinner[i].vitaminC : "0.00",
                  // vitaminA: obj.dinner[i].vitaminA != undefined ? obj.dinner[i].vitaminA : "0.00",
                  // vitaminB12: obj.dinner[i].vitaminB12 != undefined ? obj.dinner[i].vitaminB12 : "0.00",
                  // fiber: obj.dinner[i].fiber != undefined ? obj.dinner[i].fiber : "0.00",
                  // folic: obj.dinner[i].folic != undefined ? obj.dinner[i].folic : "0.00",
                  // h2o: obj.dinner[i].h2o != undefined ? obj.dinner[i].h2o : "0.00",
                  session: obj.lunch[i].session,
                  type: "Doctor",
                  image: ipaddress.Ip_with_img_address + "/" + obj.dinner[i].image,
                  day: obj.lunch[i].day,
                  date: obj.lunch[i].created_date,
                  checked: false, is_doctor: "1",
                })
              }
  
            }
            else if (this.session == "Night" || session == "night" && obj.night != undefined && obj.night.length != 0) {
              var tempWeight = obj.night[i].weight.split(" ");
              var calsWight = tempWeight[0];
              var wightMesure = tempWeight[1];
              for (var i = 0; i < obj.night.length; i++) {
                this.foodarray_hist.push({
                  food_item_id: obj.night[i].food_item_id,
                  description: obj.night[i].food_desc,
                  food_type_id: obj.night[i].food_type_id,
                  food_sub_type: obj.night[i].food_sub_type,
                  quantity: obj.night[i].quantity != undefined ? obj.night[i].quantity : "0.00",
                  measure: obj.night[i].measure != undefined ? obj.night[i].measure : "0.00",
                  weight: obj.night[i].weight != undefined ? obj.night[i].weight : "0.00",
                  cals_weight: calsWight,
                  wightMesure: wightMesure,
                  // energy: obj.dinner[i].energy != undefined ? obj.dinner[i].energy : "0.00",
                  // proteins: obj.dinner[i].proteins != undefined ? obj.dinner[i].proteins : "0.00",
                  // carbohydrates: obj.dinner[i].carbohydrates != undefined ? obj.dinner[i].carbohydrates : "0.00",
                  // fat: obj.dinner[i].fat != undefined ? obj.dinner[i].fat : "0.00",
                  // calcium: obj.dinner[i].calcium != undefined ? obj.dinner[i].calcium : "0.00",
                  // iron: obj.dinner[i].iron != undefined ? obj.dinner[i].iron : "0.00",
                  // vitaminC: obj.dinner[i].vitaminC != undefined ? obj.dinner[i].vitaminC : "0.00",
                  // vitaminA: obj.dinner[i].vitaminA != undefined ? obj.dinner[i].vitaminA : "0.00",
                  // vitaminB12: obj.dinner[i].vitaminB12 != undefined ? obj.dinner[i].vitaminB12 : "0.00",
                  // fiber: obj.dinner[i].fiber != undefined ? obj.dinner[i].fiber : "0.00",
                  // folic: obj.dinner[i].folic != undefined ? obj.dinner[i].folic : "0.00",
                  // h2o: obj.dinner[i].h2o != undefined ? obj.dinner[i].h2o : "0.00",
                  session: obj.night[i].session,
                  type: "Doctor",
                  image: ipaddress.Ip_with_img_address + "/" + obj.dinner[i].image,
                  day: obj.night[i].day,
                  date: obj.night[i].created_date,
                  checked: false, is_doctor: "1",
                })
              }
  
            }
        
            else {
              console.log("ary length--"+JSON.stringify(this.foodarray_hist))
              this.retrieveData(warddata)
            }
          }
        }
        this.retrieveData(warddata)
       
      },
      error => {
      })


  }
  retrieveData(warddata) {
    this.Medicine_Hist_List = [];
    this.Medicine_Hist_List_HISTORY = []; this.showarray = []
    if (warddata.relation_id != undefined) {
      this.relation_id = warddata.relation_id;
    }
    if (warddata.sub_rel_id != undefined) {
      this.sub_rel_id = warddata.sub_rel_id;
    }
    if (warddata.client_reg_id != undefined) {
      this.client_reg_id = warddata.client_reg_id;
    }
    if (warddata.life_style_id != undefined) {
      this.life_style_id = warddata.life_style_id;
    }


    this.sub_rel_id = this.sub_rel_id
    if (this.sub_rel_id != undefined && this.sub_rel_id != null && this.sub_rel_id != "") {
      this.send_data_med = {
        client_reg_id: this.client_reg_id,
        relation_id: this.relation_id,
        life_style_id: this.life_style_id,
        sub_rel_id: this.sub_rel_id,
        country: ipaddress.country_code,
        is_doctor: "1",
        plan_date: this.current_date,
        end_date: this.current_date,
        type: "plan"
      }
    }
    else {
      this.send_data_med = {
        client_reg_id: this.client_reg_id,
        relation_id: this.relation_id,
        country: ipaddress.country_code,
        life_style_id: this.life_style_id,
        is_doctor: "1",
        plan_date: this.current_date,
        end_date: this.current_date,
        type: "plan"
      }
    }
    console.log("check now--" + JSON.stringify(this.send_data_med))
    console.log("session now--" + JSON.stringify(this.session))
    var image;
    var headers = new Headers();

    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'lifestyle/gdpnt', (this.send_data_med), { headers: headers }).subscribe(
      data => {
        var shortform;
        var obj = JSON.parse(data["_body"]);
        console.log("check data ***" + JSON.stringify(obj) + this.session)
        if (obj != undefined) {


          if (this.session == "Morning" && obj.doctor_morning != null && obj.doctor_morning.length != 0) {
            if (obj.doctor_morning != null) {
              var tempFoodItems = {};
              this.foodarray = []
              for (var i = 0; i < obj.doctor_morning.length; i++) {
                var dayMorning = obj.doctor_morning[i].day != undefined ? obj.doctor_morning[i].day : " ";
                var tempWeight = obj.doctor_morning[i].weight.split(" ");
                var calsWight = tempWeight[0];
                var wightMesure = tempWeight[1];
                tempFoodItems = {
                  food_item_id: obj.doctor_morning[i].food_item_id,
                  description: obj.doctor_morning[i].description,
                  food_type_id: obj.doctor_morning[i].food_type_id,
                  food_sub_type: obj.doctor_morning[i].food_sub_type,
                  quantity: obj.doctor_morning[i].quantity != undefined ? obj.doctor_morning[i].quantity : "0.00",
                  measure: obj.doctor_morning[i].measure != undefined ? obj.doctor_morning[i].measure : "0.00",
                  weight: obj.doctor_morning[i].weight != undefined ? obj.doctor_morning[i].weight : "0.00",
                  cals_weight: calsWight,
                  wightMesure: wightMesure,
                  energy: obj.doctor_morning[i].energy != undefined ? obj.doctor_morning[i].energy : "0.00",
                  proteins: obj.doctor_morning[i].proteins != undefined ? obj.doctor_morning[i].proteins : "0.00",
                  carbohydrates: obj.doctor_morning[i].carbohydrates != undefined ? obj.doctor_morning[i].carbohydrates : "0.00",
                  fat: obj.doctor_morning[i].fat != undefined ? obj.doctor_morning[i].fat : "0.00",
                  calcium: obj.doctor_morning[i].calcium != undefined ? obj.doctor_morning[i].calcium : "0.00",
                  iron: obj.doctor_morning[i].iron != undefined ? obj.doctor_morning[i].iron : "0.00",
                  vitaminC: obj.doctor_morning[i].vitaminC != undefined ? obj.doctor_morning[i].vitaminC : "0.00",
                  vitaminA: obj.doctor_morning[i].vitaminA != undefined ? obj.doctor_morning[i].vitaminA : "0.00",
                  vitaminB12: obj.doctor_morning[i].vitaminB12 != undefined ? obj.doctor_morning[i].vitaminB12 : "0.00",
                  fiber: obj.doctor_morning[i].fiber != undefined ? obj.doctor_morning[i].fiber : "0.00",
                  folic: obj.doctor_morning[i].folic != undefined ? obj.doctor_morning[i].folic : "0.00",
                  h2o: obj.doctor_morning[i].h2o != undefined ? obj.doctor_morning[i].h2o : "0.00",
                  session: "Morning",
                  type: "Doctor",
                  image: obj.doctor_morning[i].image,
                  day: dayMorning,
                  date: obj.doctor_morning[i].date, checked: false
                };
                this.foodarray.push({
                  food_item_id: obj.doctor_morning[i].food_item_id,
                  description: obj.doctor_morning[i].description,
                  food_type_id: obj.doctor_morning[i].food_type_id,
                  food_sub_type: obj.doctor_morning[i].food_sub_type,
                  quantity: obj.doctor_morning[i].quantity != undefined ? obj.doctor_morning[i].quantity : "0.00",
                  measure: obj.doctor_morning[i].measure != undefined ? obj.doctor_morning[i].measure : "0.00",
                  weight: obj.doctor_morning[i].weight != undefined ? obj.doctor_morning[i].weight : "0.00",
                  cals_weight: calsWight,
                  wightMesure: wightMesure,
                  energy: obj.doctor_morning[i].energy != undefined ? obj.doctor_morning[i].energy : "0.00",
                  proteins: obj.doctor_morning[i].proteins != undefined ? obj.doctor_morning[i].proteins : "0.00",
                  carbohydrates: obj.doctor_morning[i].carbohydrates != undefined ? obj.doctor_morning[i].carbohydrates : "0.00",
                  fat: obj.doctor_morning[i].fat != undefined ? obj.doctor_morning[i].fat : "0.00",
                  calcium: obj.doctor_morning[i].calcium != undefined ? obj.doctor_morning[i].calcium : "0.00",
                  iron: obj.doctor_morning[i].iron != undefined ? obj.doctor_morning[i].iron : "0.00",
                  vitaminC: obj.doctor_morning[i].vitaminC != undefined ? obj.doctor_morning[i].vitaminC : "0.00",
                  vitaminA: obj.doctor_morning[i].vitaminA != undefined ? obj.doctor_morning[i].vitaminA : "0.00",
                  vitaminB12: obj.doctor_morning[i].vitaminB12 != undefined ? obj.doctor_morning[i].vitaminB12 : "0.00",
                  fiber: obj.doctor_morning[i].fiber != undefined ? obj.doctor_morning[i].fiber : "0.00",
                  folic: obj.doctor_morning[i].folic != undefined ? obj.doctor_morning[i].folic : "0.00",
                  h2o: obj.doctor_morning[i].h2o != undefined ? obj.doctor_morning[i].h2o : "0.00",
                  session: "Morning",
                  type: "Doctor",
                  image: ipaddress.Ip_with_img_address + "/" + obj.doctor_morning[i].image,
                  day: dayMorning,
                  date: obj.doctor_morning[i].date, checked: false, is_doctor: "1",
                })

                // this.mainDishFlag = obj.doctor_morning[i].main_course != "1" ? false : true;
                //  this.selctedFood(obj.doctor_morning[i].day, obj.doctor_morning[i].date, tempFoodItems, 0);
              }
            }
          }

          if (this.session == "Breakfast" && obj.doctor_breakfast != null && obj.doctor_breakfast.length != 0) {
            var tempFoodItems = {};
            for (var i = 0; i < obj.doctor_breakfast.length; i++) {
              var dayBreak = obj.doctor_breakfast[i].day != undefined ? obj.doctor_breakfast[i].day : " ";
              var tempWeight = obj.doctor_breakfast[i].weight.split(" ");
              var calsWight = tempWeight[0];
              var wightMesure = tempWeight[1];
              this.foodarray.push({
                food_item_id: obj.doctor_breakfast[i].food_item_id,
                description: obj.doctor_breakfast[i].description,
                food_type_id: obj.doctor_breakfast[i].food_type_id,
                food_sub_type: obj.doctor_breakfast[i].food_sub_type,
                quantity: obj.doctor_breakfast[i].quantity != undefined ? obj.doctor_breakfast[i].quantity : "0.00",
                measure: obj.doctor_breakfast[i].measure != undefined ? obj.doctor_breakfast[i].measure : "0.00",
                weight: obj.doctor_breakfast[i].weight != undefined ? obj.doctor_breakfast[i].weight : "0.00",
                cals_weight: calsWight,
                wightMesure: wightMesure,
                energy: obj.doctor_breakfast[i].energy != undefined ? obj.doctor_breakfast[i].energy : "0.00",
                proteins: obj.doctor_breakfast[i].proteins != undefined ? obj.doctor_breakfast[i].proteins : "0.00",
                carbohydrates: obj.doctor_breakfast[i].carbohydrates != undefined ? obj.doctor_breakfast[i].carbohydrates : "0.00",
                fat: obj.doctor_breakfast[i].fat != undefined ? obj.doctor_breakfast[i].fat : "0.00",
                calcium: obj.doctor_breakfast[i].calcium != undefined ? obj.doctor_breakfast[i].calcium : "0.00",
                iron: obj.doctor_breakfast[i].iron != undefined ? obj.doctor_breakfast[i].iron : "0.00",
                vitaminC: obj.doctor_breakfast[i].vitaminC != undefined ? obj.doctor_breakfast[i].vitaminC : "0.00",
                vitaminA: obj.doctor_breakfast[i].vitaminA != undefined ? obj.doctor_breakfast[i].vitaminA : "0.00",
                vitaminB12: obj.doctor_breakfast[i].vitaminB12 != undefined ? obj.doctor_breakfast[i].vitaminB12 : "0.00",
                fiber: obj.doctor_breakfast[i].fiber != undefined ? obj.doctor_breakfast[i].fiber : "0.00",
                folic: obj.doctor_breakfast[i].folic != undefined ? obj.doctor_breakfast[i].folic : "0.00",
                h2o: obj.doctor_breakfast[i].h2o != undefined ? obj.doctor_breakfast[i].h2o : "0.00",
                session: "Breakfast",
                type: "Doctor",
                image: ipaddress.Ip_with_img_address + "/" + obj.doctor_breakfast[i].image,
                day: dayMorning,
                date: obj.doctor_breakfast[i].date,
                checked: false, is_doctor: "1",
              })
            }
          }
          if (this.session == "Midday" && obj.doctor_midday != null && obj.doctor_midday.length != 0) {
            if (obj.doctor_midday != null) {
              var tempFoodItems = {};
              this.foodarray = []
              for (var i = 0; i < obj.doctor_midday.length; i++) {
                var dayMorning = obj.doctor_midday[i].day != undefined ? obj.doctor_midday[i].day : " ";
                var tempWeight = obj.doctor_midday[i].weight.split(" ");
                var calsWight = tempWeight[0];
                var wightMesure = tempWeight[1];
                tempFoodItems = {
                  food_item_id: obj.doctor_midday[i].food_item_id,
                  description: obj.doctor_midday[i].description,
                  food_type_id: obj.doctor_midday[i].food_type_id,
                  food_sub_type: obj.doctor_midday[i].food_sub_type,
                  quantity: obj.doctor_midday[i].quantity != undefined ? obj.doctor_midday[i].quantity : "0.00",
                  measure: obj.doctor_midday[i].measure != undefined ? obj.doctor_midday[i].measure : "0.00",
                  weight: obj.doctor_midday[i].weight != undefined ? obj.doctor_midday[i].weight : "0.00",
                  cals_weight: calsWight,
                  wightMesure: wightMesure,
                  energy: obj.doctor_midday[i].energy != undefined ? obj.doctor_midday[i].energy : "0.00",
                  proteins: obj.doctor_midday[i].proteins != undefined ? obj.doctor_midday[i].proteins : "0.00",
                  carbohydrates: obj.doctor_midday[i].carbohydrates != undefined ? obj.doctor_midday[i].carbohydrates : "0.00",
                  fat: obj.doctor_midday[i].fat != undefined ? obj.doctor_midday[i].fat : "0.00",
                  calcium: obj.doctor_midday[i].calcium != undefined ? obj.doctor_midday[i].calcium : "0.00",
                  iron: obj.doctor_midday[i].iron != undefined ? obj.doctor_midday[i].iron : "0.00",
                  vitaminC: obj.doctor_midday[i].vitaminC != undefined ? obj.doctor_midday[i].vitaminC : "0.00",
                  vitaminA: obj.doctor_midday[i].vitaminA != undefined ? obj.doctor_midday[i].vitaminA : "0.00",
                  vitaminB12: obj.doctor_midday[i].vitaminB12 != undefined ? obj.doctor_midday[i].vitaminB12 : "0.00",
                  fiber: obj.doctor_midday[i].fiber != undefined ? obj.doctor_midday[i].fiber : "0.00",
                  folic: obj.doctor_midday[i].folic != undefined ? obj.doctor_midday[i].folic : "0.00",
                  h2o: obj.doctor_midday[i].h2o != undefined ? obj.doctor_midday[i].h2o : "0.00",
                  session: "Midday",
                  type: "Doctor",
                  image: obj.doctor_midday[i].image,
                  day: dayMorning,
                  date: obj.doctor_midday[i].date, checked: false
                };
                this.foodarray.push({
                  food_item_id: obj.doctor_midday[i].food_item_id,
                  description: obj.doctor_midday[i].description,
                  food_type_id: obj.doctor_midday[i].food_type_id,
                  food_sub_type: obj.doctor_midday[i].food_sub_type,
                  quantity: obj.doctor_midday[i].quantity != undefined ? obj.doctor_midday[i].quantity : "0.00",
                  measure: obj.doctor_midday[i].measure != undefined ? obj.doctor_midday[i].measure : "0.00",
                  weight: obj.doctor_midday[i].weight != undefined ? obj.doctor_midday[i].weight : "0.00",
                  cals_weight: calsWight,
                  wightMesure: wightMesure,
                  energy: obj.doctor_midday[i].energy != undefined ? obj.doctor_midday[i].energy : "0.00",
                  proteins: obj.doctor_midday[i].proteins != undefined ? obj.doctor_midday[i].proteins : "0.00",
                  carbohydrates: obj.doctor_midday[i].carbohydrates != undefined ? obj.doctor_midday[i].carbohydrates : "0.00",
                  fat: obj.doctor_midday[i].fat != undefined ? obj.doctor_midday[i].fat : "0.00",
                  calcium: obj.doctor_midday[i].calcium != undefined ? obj.doctor_midday[i].calcium : "0.00",
                  iron: obj.doctor_midday[i].iron != undefined ? obj.doctor_midday[i].iron : "0.00",
                  vitaminC: obj.doctor_midday[i].vitaminC != undefined ? obj.doctor_midday[i].vitaminC : "0.00",
                  vitaminA: obj.doctor_midday[i].vitaminA != undefined ? obj.doctor_midday[i].vitaminA : "0.00",
                  vitaminB12: obj.doctor_midday[i].vitaminB12 != undefined ? obj.doctor_midday[i].vitaminB12 : "0.00",
                  fiber: obj.doctor_midday[i].fiber != undefined ? obj.doctor_midday[i].fiber : "0.00",
                  folic: obj.doctor_midday[i].folic != undefined ? obj.doctor_midday[i].folic : "0.00",
                  h2o: obj.doctor_midday[i].h2o != undefined ? obj.doctor_midday[i].h2o : "0.00",
                  session: "Morning",
                  type: "Doctor",
                  image: ipaddress.Ip_with_img_address + "/" + obj.doctor_midday[i].image,
                  day: dayMorning,
                  date: obj.doctor_midday[i].date, checked: false, is_doctor: "1",
                })
                // this.mainDishFlag = obj.doctor_morning[i].main_course != "1" ? false : true;
                //  this.selctedFood(obj.doctor_morning[i].day, obj.doctor_morning[i].date, tempFoodItems, 0);
              }
            }
          }
          if (this.session == "Lunch" && obj.doctor_lunch != null && obj.doctor_lunch.length != 0) {
            if (obj.doctor_lunch != null) {
              var tempFoodItems = {};
              this.foodarray = []
              for (var i = 0; i < obj.doctor_lunch.length; i++) {
                var dayMorning = obj.doctor_lunch[i].day != undefined ? obj.doctor_lunch[i].day : " ";
                var tempWeight = obj.doctor_lunch[i].weight.split(" ");
                var calsWight = tempWeight[0];
                var wightMesure = tempWeight[1];

                this.foodarray.push({
                  food_item_id: obj.doctor_lunch[i].food_item_id,
                  description: obj.doctor_lunch[i].description,
                  food_type_id: obj.doctor_lunch[i].food_type_id,
                  food_sub_type: obj.doctor_lunch[i].food_sub_type,
                  quantity: obj.doctor_lunch[i].quantity != undefined ? obj.doctor_lunch[i].quantity : "0.00",
                  measure: obj.doctor_lunch[i].measure != undefined ? obj.doctor_lunch[i].measure : "0.00",
                  weight: obj.doctor_lunch[i].weight != undefined ? obj.doctor_lunch[i].weight : "0.00",
                  cals_weight: calsWight,
                  wightMesure: wightMesure,
                  energy: obj.doctor_lunch[i].energy != undefined ? obj.doctor_lunch[i].energy : "0.00",
                  proteins: obj.doctor_lunch[i].proteins != undefined ? obj.doctor_lunch[i].proteins : "0.00",
                  carbohydrates: obj.doctor_lunch[i].carbohydrates != undefined ? obj.doctor_lunch[i].carbohydrates : "0.00",
                  fat: obj.doctor_lunch[i].fat != undefined ? obj.doctor_lunch[i].fat : "0.00",
                  calcium: obj.doctor_lunch[i].calcium != undefined ? obj.doctor_lunch[i].calcium : "0.00",
                  iron: obj.doctor_lunch[i].iron != undefined ? obj.doctor_lunch[i].iron : "0.00",
                  vitaminC: obj.doctor_lunch[i].vitaminC != undefined ? obj.doctor_lunch[i].vitaminC : "0.00",
                  vitaminA: obj.doctor_lunch[i].vitaminA != undefined ? obj.doctor_lunch[i].vitaminA : "0.00",
                  vitaminB12: obj.doctor_lunch[i].vitaminB12 != undefined ? obj.doctor_lunch[i].vitaminB12 : "0.00",
                  fiber: obj.doctor_lunch[i].fiber != undefined ? obj.doctor_lunch[i].fiber : "0.00",
                  folic: obj.doctor_lunch[i].folic != undefined ? obj.doctor_lunch[i].folic : "0.00",
                  h2o: obj.doctor_lunch[i].h2o != undefined ? obj.doctor_lunch[i].h2o : "0.00",
                  session: "Lunch",
                  type: "Doctor",
                  image: ipaddress.Ip_with_img_address + "/" + obj.doctor_lunch[i].image,
                  day: dayMorning,
                  date: obj.doctor_lunch[i].date,
                  checked: false, is_doctor: "1",
                })
                // this.mainDishFlag = obj.doctor_morning[i].main_course != "1" ? false : true;
                //  this.selctedFood(obj.doctor_morning[i].day, obj.doctor_morning[i].date, tempFoodItems, 0);
              }
            }
          }
          if (this.session == "Evening" && obj.doctor_evening != null && obj.doctor_evening.length != 0 && obj.doctor_evening != undefined) {
            if (obj.doctor_evening != null) {
              var tempFoodItems = {};
              this.foodarray = []
              for (var i = 0; i < obj.doctor_evening.length; i++) {
                var dayMorning = obj.doctor_evening[i].day != undefined ? obj.doctor_evening[i].day : " ";
                var tempWeight = obj.doctor_evening[i].weight.split(" ");
                var calsWight = tempWeight[0];
                var wightMesure = tempWeight[1];

                this.foodarray.push({
                  food_item_id: obj.doctor_evening[i].food_item_id,
                  description: obj.doctor_evening[i].description,
                  food_type_id: obj.doctor_evening[i].food_type_id,
                  food_sub_type: obj.doctor_evening[i].food_sub_type,
                  quantity: obj.doctor_evening[i].quantity != undefined ? obj.doctor_evening[i].quantity : "0.00",
                  measure: obj.doctor_evening[i].measure != undefined ? obj.doctor_evening[i].measure : "0.00",
                  weight: obj.doctor_evening[i].weight != undefined ? obj.doctor_evening[i].weight : "0.00",
                  cals_weight: calsWight,
                  wightMesure: wightMesure,
                  energy: obj.doctor_evening[i].energy != undefined ? obj.doctor_evening[i].energy : "0.00",
                  proteins: obj.doctor_evening[i].proteins != undefined ? obj.doctor_evening[i].proteins : "0.00",
                  carbohydrates: obj.doctor_evening[i].carbohydrates != undefined ? obj.doctor_evening[i].carbohydrates : "0.00",
                  fat: obj.doctor_evening[i].fat != undefined ? obj.doctor_evening[i].fat : "0.00",
                  calcium: obj.doctor_evening[i].calcium != undefined ? obj.doctor_evening[i].calcium : "0.00",
                  iron: obj.doctor_evening[i].iron != undefined ? obj.doctor_evening[i].iron : "0.00",
                  vitaminC: obj.doctor_evening[i].vitaminC != undefined ? obj.doctor_evening[i].vitaminC : "0.00",
                  vitaminA: obj.doctor_evening[i].vitaminA != undefined ? obj.doctor_evening[i].vitaminA : "0.00",
                  vitaminB12: obj.doctor_evening[i].vitaminB12 != undefined ? obj.doctor_evening[i].vitaminB12 : "0.00",
                  fiber: obj.doctor_evening[i].fiber != undefined ? obj.doctor_evening[i].fiber : "0.00",
                  folic: obj.doctor_evening[i].folic != undefined ? obj.doctor_evening[i].folic : "0.00",
                  h2o: obj.doctor_evening[i].h2o != undefined ? obj.doctor_evening[i].h2o : "0.00",
                  session: "Evening",
                  type: "Doctor",
                  image: ipaddress.Ip_with_img_address + "/" + obj.doctor_evening[i].image,
                  day: dayMorning,
                  date: obj.doctor_evening[i].date,
                  checked: false, is_doctor: "1",
                })
                // this.mainDishFlag = obj.doctor_morning[i].main_course != "1" ? false : true;
                //  this.selctedFood(obj.doctor_morning[i].day, obj.doctor_morning[i].date, tempFoodItems, 0);
              }
            }
          }

          if (this.session == "Dinner" && obj.doctor_dinner != null && obj.doctor_dinner.length != 0 && obj.doctor_dinner != undefined) {
            if (obj.doctor_dinner != null) {
              var tempFoodItems = {};
              this.foodarray = []
              for (var i = 0; i < obj.doctor_dinner.length; i++) {
                var dayMorning = obj.doctor_dinner[i].day != undefined ? obj.doctor_dinner[i].day : " ";
                var tempWeight = obj.doctor_dinner[i].weight.split(" ");
                var calsWight = tempWeight[0];
                var wightMesure = tempWeight[1];
                tempFoodItems = {
                  food_item_id: obj.doctor_dinner[i].food_item_id,
                  description: obj.doctor_dinner[i].description,
                  food_type_id: obj.doctor_dinner[i].food_type_id,
                  food_sub_type: obj.doctor_dinner[i].food_sub_type,
                  quantity: obj.doctor_dinner[i].quantity != undefined ? obj.doctor_dinner[i].quantity : "0.00",
                  measure: obj.doctor_dinner[i].measure != undefined ? obj.doctor_dinner[i].measure : "0.00",
                  weight: obj.doctor_dinner[i].weight != undefined ? obj.doctor_dinner[i].weight : "0.00",
                  cals_weight: calsWight,
                  wightMesure: wightMesure,
                  energy: obj.doctor_dinner[i].energy != undefined ? obj.doctor_dinner[i].energy : "0.00",
                  proteins: obj.doctor_dinner[i].proteins != undefined ? obj.doctor_dinner[i].proteins : "0.00",
                  carbohydrates: obj.doctor_dinner[i].carbohydrates != undefined ? obj.doctor_dinner[i].carbohydrates : "0.00",
                  fat: obj.doctor_dinner[i].fat != undefined ? obj.doctor_dinner[i].fat : "0.00",
                  calcium: obj.doctor_dinner[i].calcium != undefined ? obj.doctor_dinner[i].calcium : "0.00",
                  iron: obj.doctor_dinner[i].iron != undefined ? obj.doctor_dinner[i].iron : "0.00",
                  vitaminC: obj.doctor_dinner[i].vitaminC != undefined ? obj.doctor_dinner[i].vitaminC : "0.00",
                  vitaminA: obj.doctor_dinner[i].vitaminA != undefined ? obj.doctor_dinner[i].vitaminA : "0.00",
                  vitaminB12: obj.doctor_dinner[i].vitaminB12 != undefined ? obj.doctor_dinner[i].vitaminB12 : "0.00",
                  fiber: obj.doctor_dinner[i].fiber != undefined ? obj.doctor_dinner[i].fiber : "0.00",
                  folic: obj.doctor_dinner[i].folic != undefined ? obj.doctor_dinner[i].folic : "0.00",
                  h2o: obj.doctor_dinner[i].h2o != undefined ? obj.doctor_dinner[i].h2o : "0.00",
                  session: "Dinner",
                  type: "Doctor",
                  image: obj.doctor_dinner[i].image,
                  day: dayMorning,
                  date: obj.doctor_dinner[i].date, checked: false
                };
                this.foodarray.push({
                  food_item_id: obj.doctor_dinner[i].food_item_id,
                  description: obj.doctor_dinner[i].description,
                  food_type_id: obj.doctor_dinner[i].food_type_id,
                  food_sub_type: obj.doctor_dinner[i].food_sub_type,
                  quantity: obj.doctor_dinner[i].quantity != undefined ? obj.doctor_dinner[i].quantity : "0.00",
                  measure: obj.doctor_dinner[i].measure != undefined ? obj.doctor_dinner[i].measure : "0.00",
                  weight: obj.doctor_dinner[i].weight != undefined ? obj.doctor_dinner[i].weight : "0.00",
                  cals_weight: calsWight,
                  wightMesure: wightMesure,
                  energy: obj.doctor_dinner[i].energy != undefined ? obj.doctor_dinner[i].energy : "0.00",
                  proteins: obj.doctor_dinner[i].proteins != undefined ? obj.doctor_dinner[i].proteins : "0.00",
                  carbohydrates: obj.doctor_dinner[i].carbohydrates != undefined ? obj.doctor_dinner[i].carbohydrates : "0.00",
                  fat: obj.doctor_dinner[i].fat != undefined ? obj.doctor_dinner[i].fat : "0.00",
                  calcium: obj.doctor_dinner[i].calcium != undefined ? obj.doctor_dinner[i].calcium : "0.00",
                  iron: obj.doctor_dinner[i].iron != undefined ? obj.doctor_dinner[i].iron : "0.00",
                  vitaminC: obj.doctor_dinner[i].vitaminC != undefined ? obj.doctor_dinner[i].vitaminC : "0.00",
                  vitaminA: obj.doctor_dinner[i].vitaminA != undefined ? obj.doctor_dinner[i].vitaminA : "0.00",
                  vitaminB12: obj.doctor_dinner[i].vitaminB12 != undefined ? obj.doctor_dinner[i].vitaminB12 : "0.00",
                  fiber: obj.doctor_dinner[i].fiber != undefined ? obj.doctor_dinner[i].fiber : "0.00",
                  folic: obj.doctor_dinner[i].folic != undefined ? obj.doctor_dinner[i].folic : "0.00",
                  h2o: obj.doctor_dinner[i].h2o != undefined ? obj.doctor_dinner[i].h2o : "0.00",
                  session: "Dinner",
                  type: "Doctor",
                  image: ipaddress.Ip_with_img_address + "/" + obj.doctor_dinner[i].image,
                  day: dayMorning,
                  date: obj.doctor_dinner[i].date, checked: false, is_doctor: "1",
                })
                // this.mainDishFlag = obj.doctor_morning[i].main_course != "1" ? false : true;
                //  this.selctedFood(obj.doctor_morning[i].day, obj.doctor_morning[i].date, tempFoodItems, 0);
              }
            }
          }else{
            this.foodarray = []
          }
          if (this.session == "Night" && obj.doctor_night != null && obj.doctor_night.length != 0 && obj.doctor_night != undefined) {
            if (obj.doctor_night != null) {
              var tempFoodItems = {};
              this.foodarray = []
              for (var i = 0; i < obj.doctor_night.length; i++) {
                var dayMorning = obj.doctor_night[i].day != undefined ? obj.doctor_night[i].day : " ";
                var tempWeight = obj.doctor_night[i].weight.split(" ");
                var calsWight = tempWeight[0];
                var wightMesure = tempWeight[1];

                this.foodarray.push({
                  food_item_id: obj.doctor_night[i].food_item_id,
                  description: obj.doctor_night[i].description,
                  food_type_id: obj.doctor_night[i].food_type_id,
                  food_sub_type: obj.doctor_night[i].food_sub_type,
                  quantity: obj.doctor_night[i].quantity != undefined ? obj.doctor_night[i].quantity : "0.00",
                  measure: obj.doctor_night[i].measure != undefined ? obj.doctor_night[i].measure : "0.00",
                  weight: obj.doctor_night[i].weight != undefined ? obj.doctor_night[i].weight : "0.00",
                  cals_weight: calsWight,
                  wightMesure: wightMesure,
                  energy: obj.doctor_night[i].energy != undefined ? obj.doctor_night[i].energy : "0.00",
                  proteins: obj.doctor_night[i].proteins != undefined ? obj.doctor_night[i].proteins : "0.00",
                  carbohydrates: obj.doctor_night[i].carbohydrates != undefined ? obj.doctor_night[i].carbohydrates : "0.00",
                  fat: obj.doctor_night[i].fat != undefined ? obj.doctor_night[i].fat : "0.00",
                  calcium: obj.doctor_night[i].calcium != undefined ? obj.doctor_night[i].calcium : "0.00",
                  iron: obj.doctor_night[i].iron != undefined ? obj.doctor_night[i].iron : "0.00",
                  vitaminC: obj.doctor_night[i].vitaminC != undefined ? obj.doctor_night[i].vitaminC : "0.00",
                  vitaminA: obj.doctor_night[i].vitaminA != undefined ? obj.doctor_night[i].vitaminA : "0.00",
                  vitaminB12: obj.doctor_night[i].vitaminB12 != undefined ? obj.doctor_night[i].vitaminB12 : "0.00",
                  fiber: obj.doctor_night[i].fiber != undefined ? obj.doctor_night[i].fiber : "0.00",
                  folic: obj.doctor_night[i].folic != undefined ? obj.doctor_night[i].folic : "0.00",
                  h2o: obj.doctor_night[i].h2o != undefined ? obj.doctor_night[i].h2o : "0.00",
                  session: "Night",
                  type: "Doctor",
                  image: ipaddress.Ip_with_img_address + "/" + obj.doctor_night[i].image,
                  day: dayMorning,
                  date: obj.doctor_night[i].date,
                  checked: false, is_doctor: "1",
                })
                // this.mainDishFlag = obj.doctor_morning[i].main_course != "1" ? false : true;
                //  this.selctedFood(obj.doctor_morning[i].day, obj.doctor_morning[i].date, tempFoodItems, 0);
              }
            }
          }

        }
        console.log("this.foodarraythis.foodarray" + JSON.stringify(this.foodarray));
        console.log("this.foodarraythis.foodarray_hist" + JSON.stringify(this.foodarray_hist));
        if (this.foodarray_hist.length != 0 && this.foodarray.length != 0) {
          let resultArray  = this.findMatchingObjectsdiet(this.foodarray_hist, this.foodarray);
          console.log("resultArray" + JSON.stringify(resultArray));
        //  if
          // let resultArray =matchingObjects
          if(resultArray.length != 0){
            this.showfoodarray = []         
            this.foodarray =  this.findMatchingObjectsdiet1(this.foodarray_hist, this.foodarray);
            this.showfoodarray = this.foodarray;
            this.history_diet = false;
          }else{
            this.showfoodarray = this.foodarray
            this.history_diet = false
          }
         
        } else if(this.foodarray_hist.length != 0 && this.foodarray.length == 0) {
          this.showfoodarray = []
          this.showfoodarray = this.foodarray_hist
          this.history_diet = true
        } else if(this.foodarray_hist.length == 0 && this.foodarray.length != 0) {
          this.showfoodarray = this.foodarray
          this.history_diet = false
        }
        console.log("resultArray" + JSON.stringify(this.foodarray));
      },
      error => {
      })


  }
  dismiss() {

  }

  ClosePopup() {
    this.dialogRef.close(this.value);
  }

  isSelected(list, key, checked) {
    console.log("key vakuye" + JSON.stringify(list))
    console.log("this.trackerArraye" + JSON.stringify(this.trackerArray))
    console.log("this.foodarray0000" + JSON.stringify(this.foodarray))
    // for(var k=0;k<this.foodarray.length;k++){

    // }
    this.foodarray.forEach(item => {
      // Compare food_item_id
      if (item.food_item_id === list.food_item_id) {
        // Update checked field
        item.checked = true;
      }
    });


    if (key == "add") {
      console.log("this.trackerArray.length" + JSON.stringify(this.trackerArray.length))
      if (this.trackerArray.length != 0) {
        for (let i = 0; i < this.trackerArray.length; i++) {
          if (this.session == "Morning") {
            this.trackerArray[i].dplan_morning.push(list);
            this.trackerArrayvalue.push(list)            
          }
          if (this.session == "Breakfast") {
            this.trackerArray[i].dplan_breakfast.push(list);
            this.trackerArrayvalue.push(list)   
          }
          if (this.session == "Midday") {
            this.trackerArray[i].dplan_midday.push(list);
            this.trackerArrayvalue.push(list)   
          }
          if (this.session == "Lunch") {
            this.trackerArray[i].dplan_lunch.push(list);
            this.trackerArrayvalue.push(list)   
          }
          if (this.session == "Evening") {
            this.trackerArray[i].dplan_evening.push(list);
            this.trackerArrayvalue.push(list)   
          }
          if (this.session == "Dinner") {
            this.trackerArray[i].dplan_dinner.push(list);
            this.trackerArrayvalue.push(list)          
          }
          if (this.session == "Night") {
            this.trackerArray[i].dplan_night.push(list);
            this.trackerArrayvalue.push(list)          
          }
        }
      } else {
        console.log("this.trackerArray" + JSON.stringify(this.trackerArray))
        console.log("this.foodarray" + JSON.stringify(this.foodarray))
        if (this.trackerArray.length == 0) {
          var trackdata = {
            created_date: this.today,
            day: list.day,
            dplan_morning: [],
            dplan_breakfast: [],
            dplan_midday: [],
            dplan_lunch: [],
            dplan_evening: [],
            dplan_dinner: [],
            dplan_night: [],
            cal_required: "0.00",
            cal_intake: "0.00"
          }
          this.trackerArray.push(trackdata)
        }

        if (this.session == "Morning") {
          this.trackerArray[0].dplan_morning = list;
          this.trackerArrayvalue.push(list)
        }
        if (this.session == "Breakfast") {
          this.trackerArray[0].dplan_breakfast = list;
          this.trackerArrayvalue.push(list)
        }
        if (this.session == "Midday") {
          this.trackerArray[0].dplan_midday = list;
          this.trackerArrayvalue.push(list)
        }
        if (this.session == "Lunch") {
          this.trackerArray[0].dplan_lunch = list;
          this.trackerArrayvalue.push(list)
        }
        if (this.session == "Evening") {
          this.trackerArray[0].dplan_evening = list;
          this.trackerArrayvalue.push(list)
        }
        if (this.session == "Dinner") {
          this.trackerArray[0].dplan_dinner = list;
          this.trackerArrayvalue.push(list)
        }
        if (this.session == "Night") {
          this.trackerArray[0].dplan_night = list;
          this.trackerArrayvalue.push(list)
        }
      }
      console.log("this.trackerArrayvalue" + JSON.stringify(this.trackerArrayvalue))
    } else {
      console.log("this.trackerArray" + JSON.stringify(this.trackerArray))
      console.log("this.foodarray" + JSON.stringify(this.foodarray))
      for (var i = 0; i < this.trackerArray.length; i++) {
        if (this.trackerArray[i].food_item_id == list.food_item_id && this.trackerArray[i].day == list.day) {
          list.checked = false;
          this.trackerArray.splice(i, 1);

        }
      }
      if (this.trackerArray.length == 0) {
        this.trackerArray.push({
          flag: "No"
        });
      }
    }
    console.log("this.foodarray***" + JSON.stringify(this.trackerArray));

  }
  click_value() {
    var send_data;
    send_data = {
      client_reg_id: this.client_reg_id,
      relation_id: this.relation_id,
      life_style_id: this.life_style_id,
      session:this.session,
      user_id:this.client_reg_id,
      // cal_required: this.cal_txt,
      // cal_intake: this.tot_cal,
      country: ipaddress.country_code.toString(),
      tracker: this.trackerArrayvalue,
      type:"tracker"

    }
    if (this.sub_rel_id != null && this.sub_rel_id != undefined && this.sub_rel_id != "") {
      send_data.sub_rel_id = this.sub_rel_id;
    }
    console.log("this.trackerArray" + JSON.stringify(send_data));
    var headers = new Headers();
    var send_data1
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "lifestyle/dplantrack", JSON.stringify((send_data)), { headers: headers }).subscribe(
      data => {
        var obj = JSON.parse(data["_body"]);
        console.log("fainal data send" + JSON.stringify(send_data))
        console.log("fainal Response" + JSON.stringify(obj))

        if (obj.key != undefined && obj.key == "1") {
          // Helper_class.setDietProgress(undefined);
          // if (obj.key != undefined && obj.key == "1") {
          //   if (Helper_class.getrelationsubrelationslide().relation_id == "1") {
          //     send_data.dietflow = "dietTracker";
          //     Helper_class.setDietProgress(send_data);
          //   }
          // }
          this.toastr.success('Diet details saved successfully');
          this.dialogRef.close();
        } else {
          this.toastr.error('Diet details not saved, please try again later');
        }
      });
    this.dialogRef.close(this.value)
  }
  medselection(list, checked) {
    if (checked == true) {
      this.Drug_Hist_List.push(list)
    } else {
      for (let i = this.Drug_Hist_List.length - 1; i >= 0; i--) {
        const item = this.Drug_Hist_List[i];
        const foundItem = this.Medicine_Hist_List.find(overallItem => overallItem.drug_id === item.drug_id);
        if (foundItem && !foundItem.checked) {
          this.Drug_Hist_List.splice(i, 1);
        }
      }
    }
    console.log("this.foodarray***" + JSON.stringify(this.Drug_Hist_List));

  }
  savemed() {
    var flag = true;
    if (this.Drug_Hist_List.length == 0) {
      this.toastr.error("Select Medicine")
      flag = false;
    }
    if (flag == true) {
      var send_data = {
        client_reg_id: this.client_reg_id,
        relation_id: this.relation_id,
        life_style_id: this.life_style_id,
        country: ipaddress.country_code.toString(),
        session: this.session,
        medicines: this.Drug_Hist_List,

      }
      console.log("this.trackerArray" + JSON.stringify(send_data));
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp.toString() + "lifestyle/medtrack", JSON.stringify((send_data)), { headers: headers }).subscribe(
        data => {
          var obj = JSON.parse(data["_body"]);
          console.log("fainal data send" + JSON.stringify(send_data))
          console.log("fainal Response" + JSON.stringify(obj))
          if (obj.key != undefined && obj.key == "1") {
            this.toastr.success(obj.result);
            this.dialogRef.close();
          } else {
            this.toastr.error('Diet details not saved, please try again later');
          }
        });
      // this.dialogRef.close(this.value)
    }

  }
  medicineTrackerHistory(data) {
    this.flagselection = true;
    if (this.sub_rel_id != undefined && this.sub_rel_id != null && this.sub_rel_id != "") {
      this.send_data_med = {
        client_reg_id: data.client_reg_id,
        relation_id: data.relation_id,
        sub_rel_id: data.sub_rel_id,
        time_string: this.session
      }
    }
    else {
      this.send_data_med = {
        client_reg_id: data.client_reg_id,
        relation_id: data.relation_id,
        time_string: data.session
      }
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + 'lifestyle/mthist',
      this.send_data_med,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          console.log("fainal Response" + JSON.stringify(obj))
          this.Medicine_Hist_List_HISTORY = [];
          if (obj.medicine_intakes != null) {
            if (obj.medicine_intakes.length != 0) {

              for (var i = 0; i < obj.medicine_intakes.length; i++) {
                this.Medicine_Hist_List_HISTORY.push({
                  drug_id: obj.medicine_intakes[i].drug_id,
                  drug_name: obj.medicine_intakes[i].drug_name,
                  quantity: obj.medicine_intakes[i].quantity,
                  intake: obj.medicine_intakes[i].intake,
                  created_date: Date_Formate(obj.medicine_intakes[i].created_date),
                  created_time: (obj.medicine_intakes[i].created_time),
                  session: obj.medicine_intakes[i].session,
                  shortform: obj.medicine_intakes[i].shortform,
                  time_duration:obj.medicine_intakes[i].time_duration,
                  drug_inake:obj.medicine_intakes[i].drug_inake
                });

              }
              console.log("this.Medicine_Hist_List_HISTOR" + JSON.stringify(this.Medicine_Hist_List_HISTORY))
              this.medicinetracker(this.data)
            } else {
              var sentdata = {
                client_reg_id: this.client_reg_id,
                relation_id: this.relation_id,
                life_style_id: this.life_style_id,
              }
              this.medicinetracker(this.send_data_med);
            }

          } else {

            this.medicinetracker(this.send_data_med);

          }
        }, error => { })
  }
  diethistory(data) {

  }
  history(value) {
    console.log("check Medicine_Hist_List*****" + JSON.stringify(this.Medicine_Hist_List))
    console.log("check HIST" + JSON.stringify(this.Medicine_Hist_List_HISTORY))

    if (value == 'hist') {
      this.showarray=[]
      this.showarray = this.Medicine_Hist_List_HISTORY
      this.history_med = true;
      this.medsave = false;
    } else {
      this.medicineTrackerHistory(this.data1)
    }

  }
  historydiet(value) {
    this.showfoodarray=[];
    console.log("check Medicine_Hist_List*****" + JSON.stringify(this.foodarray_hist))
    console.log("check HIST" + JSON.stringify(this.foodarray))
    if (value == 'diet') {
      this.showfoodarray = this.foodarray_hist
      this.history_diet=true;
      this.dietsave=false;
      // if (this.foodarray_hist != undefined && this.foodarray != undefined) {
      //   if (this.foodarray_hist.length != 0 && this.foodarray.length != 0) {
      //     console.log("resultArray"+JSON.stringify(this.foodarray_hist));
      //     this.foodarray = this.foodarray_hist
      //     this.history_diet=true;
      //     this.dietsave=false;
      //   } else {
      //     this.retrieveDataHist(this.data1)
      //   }
      // }
      // else {
      //   this.retrieveDataHist(this.data1)
      // }
    }

  }
  isEmptyObject(obj) {
    return (obj && (Object.keys(obj).length === 0));
  }
}
