import { ReturnStatement } from "@angular/compiler";

export class ipaddress{
    // static getIp:any  = "http://localhost:8080/GritServiceLatest/";
    // static Ip_with_img_address:any = "http://localhost:8080";

    // static getIp:any  = "https://www.ipronesys.com:7443/GritStaging/";
    // static Ip_with_img_address:any = "https://www.ipronesys.com:7443";

    // static getIp:any  = "http://192.168.1.7:8080/GritRestfulService/";
    // static Ip_with_img_address:any = "http://192.168.1.7:8080"
    //static python_ip = "http://192.168.1.10:8080";

    static getIp:any  = "https://www.tervys.com:8443/GritRestfulService/";
    static Ip_with_img_address:any = "https://www.tervys.com:8443";

    // static getIp:any  = "https://www.tervys.com:8443/GritProdService/";
    // static Ip_with_img_address:any = "https://www.tervys.com:8443";

    static country_code ="IN";
    static video_ip = "tervys.com";
    static video_port = "3000";
}