import * as CanvasJS from './canvasjs';
import * as $ from '../../../node_modules/jquery/dist/jquery.min.js';
declare var $:any;
var eGFR_array_last = new Array();
var Serum_Creatinine_Array_last = new Array();
var Albuminuria_Array_last = new Array();
var Proteinuria_Array_last = new Array();
export class Neprology_graph {
    static Neprograph(Datearray, eGFRarray, Serumarray, Albuminarray, Proteinarray,timelist) {
        console.log("Datearray -----"+JSON.stringify(Datearray))
        console.log("timelist -----"+JSON.stringify(timelist))
        for (var i = 0; i < Datearray.length; i++) {
            for (var j = 0; j < timelist.length; j++) {
                // Split the date into parts
                var res = Datearray[i].split("-"); // Date in ["YYYY", "MM", "DD"]
        
                // Get the time for this index
                var time = timelist[j]; // Time in "HH:mm" format
                
                // Construct the date-time string in ISO 8601 format
                var dateTimeString = `${res[0]}-${res[1]}-${res[2]}T${time}:00Z`; // Added seconds and timezone
        
                // Create a Date object using the combined date and time string
                var dateTime = new Date(dateTimeString);
        
                // Log the result for debugging
                console.log("Combined date-time string: " + dateTimeString);
                console.log("Date object: " + dateTime);
        
                if (eGFRarray[i] != "") {
                    eGFR_array_last.push({ x: dateTime, y: parseFloat(eGFRarray[i]) });
                }
        
                if (Serumarray[i] != "") {
                    Serum_Creatinine_Array_last.push({ x: dateTime, y: parseFloat(Serumarray[i]) });
                }
        
                if (Albuminarray[i] != "") {
                    Albuminuria_Array_last.push({ x: dateTime, y: parseFloat(Albuminarray[i]) });
                }
        
                if (Proteinarray[i] != "") {
                    Proteinuria_Array_last.push({ x: dateTime, y: parseFloat(Proteinarray[i]) });
                }
            }
        }
        
        // Log arrays for debugging
        console.log("eGFR_array_last:", JSON.stringify(eGFR_array_last));
        console.log("Serum_Creatinine_Array_last:", JSON.stringify(Serum_Creatinine_Array_last));
        console.log("Albuminuria_Array_last:", JSON.stringify(Albuminuria_Array_last));
        console.log("Proteinuria_Array_last:", JSON.stringify(Proteinuria_Array_last));
    
        if (eGFR_array_last.length != 0) {
            Neprology_graph.eGFR_Graph(eGFR_array_last);
            $("#eGFR_Chart").show();
            $("#eGFR_Chart_Unavail").hide();
        }
        else {
            $("#eGFR_Chart_Unavail").show();
            $("#eGFR_Chart").hide();
        }
    
        if (Serum_Creatinine_Array_last.length != 0) {
            Neprology_graph.Serum_Creatinine_Graph(Serum_Creatinine_Array_last);
            $("#Serum_Chart").show();
            $("#Serum_Chart_Unavail").hide();
        }
        else {
            $("#Serum_Chart_Unavail").show();
            $("#Serum_Chart").hide();
        }
    
        if (Albuminuria_Array_last.length != 0) {
            Neprology_graph.Albuminuria_Graph(Albuminuria_Array_last);
            $("#Albuminuria_Chart").show();
            $("#Albuminuria_Unavail").hide();
        }
        else {
            $("#Albuminuria_Unavail").show();
            $("#Albuminuria_Chart").hide();
        }
    
        if (Proteinuria_Array_last.length != 0) {
            Neprology_graph.Proteinuria_Graph(Proteinuria_Array_last);
            $("#Proteinuria_Chart").show();
            $("#Proteinuria_Unavail").hide();
        }
        else {
            $("#Proteinuria_Unavail").show();
            $("#Proteinuria_Chart").hide();
        }
    }
    
    //eGFR Graph
    
    static eGFR_Graph(finalarray) {
        // Convert date strings to Date objects and filter out invalid dates
        finalarray = finalarray.map(dataPoint => ({
            x: new Date(dataPoint.x),
            y: dataPoint.y
        })).filter(dataPoint => 
            !isNaN(dataPoint.x.getTime()) && typeof dataPoint.y === 'number'
        );
    
        $(document).ready(function() {
            var eGFR_Chart_00 = new CanvasJS.Chart("eGFR_Chart", {
                title: {
                    text: ""
                },
                toolTip: {
                    fontWeight: "normal",
                    fontSize: "15",
                    fontFamily: "Consolas, Tohama, verdana",
                    fontStyle: "normal",
                    borderThickness: 2,
                    contentFormatter: function(e) {
                        var content = "";
                        for (var i = 0; i < e.entries.length; i++) {
                            content = e.entries[i].dataPoint.y + " on " + 
                                      CanvasJS.formatDate(e.entries[i].dataPoint.x, "DD-MMM-YYYY HH:mm");
                        }
                        return content;
                    }
                },
                colorSet: "SetColourforChart",
                axisX: {
                    labelAngle: 135,
                    labelFontSize: "12",
                    valueFormatString: "DD-MMM-YYYY HH:mm",
                },
                axisY: {
                    titleFontSize: 15,
                    gridThickness: 1,
                    tickThickness: 0,
                },
                data: [
                    {
                        name: 'eGFR',
                        type: "spline", // Change to spline for smooth lines
                        showInLegend: true,
                        dataPoints: finalarray,
                        lineThickness: 2, // Adjust line thickness if needed
                        color: "#4F81BC" // Set color for the line
                    }
                ],
                legend: {
                    cursor: "pointer",
                    itemclick: function(e1) {
                        if (typeof(e1.dataSeries.visible) === "undefined" || e1.dataSeries.visible) {
                            e1.dataSeries.visible = false;
                        } else {
                            e1.dataSeries.visible = true;
                        }
                        eGFR_Chart_00.render();
                    }
                }
            });
    
            eGFR_Chart_00.render();
    
            // Show tooltip for the first data point by default
            var firstDataPoint = eGFR_Chart_00.data[0].dataPoints[0];
            if (firstDataPoint) {
                eGFR_Chart_00.tooltip.showAt({ x: firstDataPoint.x, y: firstDataPoint.y });
            }
        });
    }
    //Serum_Creatinine Graph
    
    static Serum_Creatinine_Graph(finalarray) {
        // Convert date strings to Date objects and filter out invalid dates
        finalarray = finalarray.map(dataPoint => ({
            x: new Date(dataPoint.x),
            y: dataPoint.y
        })).filter(dataPoint => 
            !isNaN(dataPoint.x.getTime()) && typeof dataPoint.y === 'number'
        );
    
        $(document).ready(function() {
            var Serum_Creatinine = new CanvasJS.Chart("Serum_Chart", {
                title: {
                    text: ""
                },
                toolTip: {
                    fontWeight: "normal",
                    fontSize: "15",
                    fontFamily: "Consolas, Tahoma, Verdana",
                    fontStyle: "normal",
                    borderThickness: 2,
                    contentFormatter: function(e) {
                        var content = "";
                        for (var i = 0; i < e.entries.length; i++) {
                            content = e.entries[i].dataPoint.y + " on " + 
                                      CanvasJS.formatDate(e.entries[i].dataPoint.x, "DD-MMM-YYYY HH:mm");
                        }
                        return content;
                    }
                },
                colorSet: "SetColourforChart",
                axisX: {
                    labelAngle: 135,
                    labelFontSize: "12",
                    valueFormatString: "DD-MMM-YYYY HH:mm",
                },
                axisY: {
                    titleFontSize: 15,
                    gridThickness: 1,
                    tickThickness: 0,
                },
                data: [
                    {
                        name: 'Serum Creatinine',
                        type: "spline", // Change to spline for smooth lines
                        showInLegend: true,
                        dataPoints: finalarray,
                        lineThickness: 2, // Adjust line thickness if needed
                        // type: "line",
                        // showInLegend: true,
                        // splineType: "natural", // Make the line smooth
                        // dataPoints: finalarray
                    }
                ],
                legend: {
                    cursor: "pointer",
                    itemclick: function(e1) {
                        if (typeof(e1.dataSeries.visible) === "undefined" || e1.dataSeries.visible) {
                            e1.dataSeries.visible = false;
                        } else {
                            e1.dataSeries.visible = true;
                        }
                        Serum_Creatinine.render();
                    }
                }
            });
    
            Serum_Creatinine.render();
    
            // Show tooltip for the first data point by default
            var firstDataPoint = Serum_Creatinine.data[0].dataPoints[0];
            if (firstDataPoint) {
                Serum_Creatinine.tooltip.showAt({ x: firstDataPoint.x, y: firstDataPoint.y });
            }
        });
    }
    
    
    
    
    
    
    //Albumin-uria Graph
    
    static Albuminuria_Graph(finalarray) {
        finalarray = finalarray.map(dataPoint => ({
            x: new Date(dataPoint.x),
            y: dataPoint.y
        })).filter(dataPoint => 
            !isNaN(dataPoint.x.getTime()) && typeof dataPoint.y === 'number'
        );
    
        $(document).ready(function() {
            var Albuminuria_Chart_00 = new CanvasJS.Chart("Albuminuria_Chart", {
                title: {
                    text: ""
                },
                toolTip: {
                    fontWeight: "normal",
                    fontSize: "15",
                    fontFamily: "Consolas, Tahoma, Verdana",
                    fontStyle: "normal",
                    borderThickness: 2,
                    contentFormatter: function(e) {
                        var content = "";
                        for (var i = 0; i < e.entries.length; i++) {
                            content = e.entries[i].dataPoint.y + " on " + 
                                      CanvasJS.formatDate(e.entries[i].dataPoint.x, "DD-MMM-YYYY HH:mm");
                        }
                        return content;
                    }
                },
                colorSet: "SetColourforChart",
                axisX: {
                    labelAngle: 135,
                    labelFontSize: "12",
                    valueFormatString: "DD-MMM-YYYY HH:mm",
                },
                axisY: {
                    titleFontSize: 15,
                    gridThickness: 1,
                    tickThickness: 0,
                },
                data: [
                    {
                        name: 'Albuminuria',
                        type: "spline", // Change to spline for smooth lines
                        showInLegend: true,
                        dataPoints: finalarray,
                        lineThickness: 2, // Adjust line thickness if needed
                        // dataPoints: finalarray
                    }
                ],
                legend: {
                    cursor: "pointer",
                    itemclick: function(e1) {
                        if (typeof(e1.dataSeries.visible) === "undefined" || e1.dataSeries.visible) {
                            e1.dataSeries.visible = false;
                        } else {
                            e1.dataSeries.visible = true;
                        }
                        Albuminuria_Chart_00.render();
                    }
                }
            });
    
            Albuminuria_Chart_00.render();
    
            // Show tooltip for the first data point by default
            var firstDataPoint = Albuminuria_Chart_00.data[0].dataPoints[0];
            if (firstDataPoint) {
                Albuminuria_Chart_00.tooltip.showAt({ x: firstDataPoint.x, y: firstDataPoint.y });
            }
        });
    }
    
    
    
    //Protein-uria Graph
    
    static Proteinuria_Graph(finalarray) {
        finalarray = finalarray.map(dataPoint => ({
            x: new Date(dataPoint.x),
            y: dataPoint.y
        })).filter(dataPoint => 
            !isNaN(dataPoint.x.getTime()) && typeof dataPoint.y === 'number'
        );
    
        $(document).ready(function() {
            var Proteinuria_Chart_00 = new CanvasJS.Chart("Proteinuria_Chart", {
                title: {
                    text: ""
                },
                toolTip: {
                    fontWeight: "normal",
                    fontSize: "15",
                    fontFamily: "Consolas, Tahoma, Verdana",
                    fontStyle: "normal",
                    borderThickness: 2,
                    contentFormatter: function(e) {
                        var content = "";
                        for (var i = 0; i < e.entries.length; i++) {
                            content = e.entries[i].dataPoint.y + " on " + 
                                      CanvasJS.formatDate(e.entries[i].dataPoint.x, "DD-MMM-YYYY HH:mm");
                        }
                        return content;
                    }
                },
                colorSet: "SetColourforChart",
                axisX: {
                    labelAngle: 135,
                    labelFontSize: "12",
                    valueFormatString: "DD-MMM-YYYY HH:mm",
                },
                axisY: {
                    titleFontSize: 15,
                    gridThickness: 1,
                    tickThickness: 0,
                },
                data: [
                    {
                        name: 'Proteinuria',
                        type: "spline", // Change to spline for smooth lines
                        showInLegend: true,
                        dataPoints: finalarray,
                        lineThickness: 2, // Adjust line thickness if needed
                        // dataPoints: finalarray
                    }
                ],
                legend: {
                    cursor: "pointer",
                    itemclick: function(e1) {
                        if (typeof(e1.dataSeries.visible) === "undefined" || e1.dataSeries.visible) {
                            e1.dataSeries.visible = false;
                        } else {
                            e1.dataSeries.visible = true;
                        }
                        Proteinuria_Chart_00.render();
                    }
                }
            });
    
            Proteinuria_Chart_00.render();
    
            // Show tooltip for the first data point by default
            var firstDataPoint = Proteinuria_Chart_00.data[0].dataPoints[0];
            if (firstDataPoint) {
                Proteinuria_Chart_00.tooltip.showAt({ x: firstDataPoint.x, y: firstDataPoint.y });
            }
        });
    }
    
    
    
    
    
    // poup grpah
    static Neprozoomgraph(Datearray, eGFRarray, Serumarray, Albuminarray, Proteinarray, graph_id) {
    
        var eGFR_array_last = new Array();
        var Serum_Creatinine_Array_last = new Array();
        var Albuminuria_Array_last = new Array();
        var Proteinuria_Array_last = new Array();
       
        for (var i = 0; i < Datearray.length; i++) {
    
            var res = Datearray[i].split("-");
    
            if (eGFRarray[i] != "") {
    
                eGFR_array_last.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(eGFRarray[i]) });
            }
    
            if (Serumarray[i] != "") {
    
                Serum_Creatinine_Array_last.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(Serumarray[i]) });
            }
    
            if (Albuminarray[i] != "") {
    
                Albuminuria_Array_last.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(Albuminarray[i]) });
            }
    
            if (Proteinarray[i] != "") {
    
                Proteinuria_Array_last.push({ x: new Date(+res[0] + ',' + res[1] + ',' + res[2]), y: parseFloat(Proteinarray[i]) });
            }
        }
    
        if (eGFR_array_last.length != 0 && graph_id == "1") {
    
            Neprology_graph.eGFR_Zoom_Graph(eGFR_array_last);
        }
        else if (Serum_Creatinine_Array_last.length != 0 && graph_id == "2") {
    
            Neprology_graph.Serum_Creatinine_Zoom_Graph(Serum_Creatinine_Array_last);
    
        }
        else if (Albuminuria_Array_last.length != 0 && graph_id == "3") {
    
            Neprology_graph.Albuminuria_Zoom_Graph(Albuminuria_Array_last);
    
        }
        else if (Proteinuria_Array_last.length != 0 && graph_id == "4") {
    
            Neprology_graph.Proteinuria_Zoom_Graph(Proteinuria_Array_last);
    
        }
    
    }
    
    //eGFR Graph
    
    static eGFR_Zoom_Graph(finalarray) {
        $(document).ready(function(){  
        var eGFR_Chart_00 = new CanvasJS.Chart("nephro_popup_grph",
            {
                title: {
                    text: ""
                },
                toolTip: {
                    fontWeight: "normal",
                    fontSize: "15",
                    fontFamily: "Consolas,Tohama,verdana",
                    fontStyle: "normal",
                    borderThickness: 2,
    
                    contentFormatter: function (e) {
                        var content = "";
                        for (var i = 0; i < e.entries.length; i++) {
                            content = e.entries[i].dataPoint.y + " on " + CanvasJS.formatDate(e.entries[i].dataPoint.x, "DD-MMM-YYYY");
                        }
                        return content;
                    }
                },
                colorSet: "SetColourforChart",
                axisX: {
                    labelAngle: 135,
                    labelFontSize: "12",
                    valueFormatString: "DD-MMM-YYYY",
                },
                axisY: {
                    //includeZero: true,
                    //title: " Height in cms ",
                    titleFontSize: 15,
                    gridThickness: 1,
                    //lineThickness: 0,
                    tickThickness: 0,
                    //labelFontSize: 16,
                },
                data: [
                    {
                        name: 'eGFR',
                        type: "line",
                        showInLegend: true,
                        dataPoints: finalarray
                    }
                ],
                legend: {
                    cursor: "pointer",
                    itemclick: function (e1) {
                        if (typeof (e1.dataSeries.visible) === "undefined" || e1.dataSeries.visible) {
                            e1.dataSeries.visible = false;
                        }
                        else {
                            e1.dataSeries.visible = true;
                        }
                        eGFR_Chart_00.render();
                    }
                }
            });
    
        eGFR_Chart_00.render();
        });
    }
    
    //Serum_Creatinine Graph
    
    static Serum_Creatinine_Zoom_Graph(finalarray) {
        $(document).ready(function(){     
        var Serum_Creatinine = new CanvasJS.Chart("nephro_popup_grph",
            {
                title: {
                    text: ""
                },
                toolTip: {
                    fontWeight: "normal",
                    fontSize: "15",
                    fontFamily: "Consolas,Tohama,verdana",
                    fontStyle: "normal",
                    borderThickness: 2,
    
                    //contentFormatter: function (e) {
                    //    var content = "";
                    //    for (var i = 0; i < e.entries.length; i++) {
                    //        content = e.entries[i].dataPoint.y + " mg/dl" + " on " + CanvasJS.formatDate(e.entries[i].dataPoint.x, "DD-MMM-YYYY");
                    //    }
                    //    return content;
                    //}
    
                    contentFormatter: function (e) {
    
                        var content = "";
    
                        for (var i = 0; i < e.entries.length; i++) {
    
                            content = e.entries[i].dataPoint.y + " mg on " + CanvasJS.formatDate(e.entries[i].dataPoint.x, "DD-MMM-YYYY");
    
                        }
                        return content;
                    }
                },
                colorSet: "SetColourforChart",
                axisX: {
                    labelAngle: 135,
                    labelFontSize: "12",
                    valueFormatString: "DD-MMM-YYYY",
                },
                axisY: {
                    //includeZero: true,
                    //title: " Height in cms ",
                    titleFontSize: 15,
                    gridThickness: 1,
                    //lineThickness: 0,
                    tickThickness: 0,
                    //labelFontSize: 16,
                },
                data: [
                    {
                        name: 'Serum Creatinine',
                        type: "line",
                        showInLegend: true,
                        dataPoints: finalarray
                    }
                ],
                legend: {
                    cursor: "pointer",
                    itemclick: function (e1) {
                        if (typeof (e1.dataSeries.visible) === "undefined" || e1.dataSeries.visible) {
                            e1.dataSeries.visible = false;
                        }
                        else {
                            e1.dataSeries.visible = true;
                        }
                        Serum_Creatinine.render();
                    }
                }
            });
    
        Serum_Creatinine.render();
        });
    }
    
    //Albumin-uria Graph
    
    static Albuminuria_Zoom_Graph(finalarray) {
        $(document).ready(function(){     
        var Albuminuria_Chart_00 = new CanvasJS.Chart("nephro_popup_grph",
            {
                title: {
                    text: ""
                },
                toolTip: {
                    fontWeight: "normal",
                    fontSize: "15",
                    fontFamily: "Consolas,Tohama,verdana",
                    fontStyle: "normal",
                    borderThickness: 2,
    
                    //contentFormatter: function (e) {
                    //    var content = "";
                    //    for (var i = 0; i < e.entries.length; i++) {
                    //        content = e.entries[i].dataPoint.y + " mg/dl" + " on " + CanvasJS.formatDate(e.entries[i].dataPoint.x, "DD-MMM-YYYY");
                    //    }
                    //    return content;
                    //}
    
                    contentFormatter: function (e) {
                        var content = "";
    
                        for (var i = 0; i < e.entries.length; i++) {
                            content = e.entries[i].dataPoint.y + " g/dl on " + CanvasJS.formatDate(e.entries[i].dataPoint.x, "DD-MMM-YYYY");
                        }
                        return content;
                    }
                },
                colorSet: "SetColourforChart",
                axisX: {
                    labelAngle: 135,
                    labelFontSize: "12",
                    valueFormatString: "DD-MMM-YYYY",
                },
                axisY: {
                    //includeZero: true,
                    //title: " Height in cms ",
                    titleFontSize: 15,
                    gridThickness: 1,
                    //lineThickness: 0,
                    tickThickness: 0,
                    //labelFontSize: 16,
                },
                data: [
                    {
                        name: 'Albuminuria',
                        type: "line",
                        showInLegend: true,
                        dataPoints: finalarray
                    }
                ],
                legend: {
                    cursor: "pointer",
                    itemclick: function (e1) {
                        if (typeof (e1.dataSeries.visible) === "undefined" || e1.dataSeries.visible) {
                            e1.dataSeries.visible = false;
                        }
                        else {
                            e1.dataSeries.visible = true;
                        }
                        Albuminuria_Chart_00.render();
                    }
                }
            });
    
        Albuminuria_Chart_00.render();
        });
    }
    
    //Protein-uria Graph
    
    static Proteinuria_Zoom_Graph(finalarray) {
        $(document).ready(function(){     
        var Proteinuria_Chart_00 = new CanvasJS.Chart("nephro_popup_grph",
            {
                title: {
                    text: ""
                },
                toolTip: {
                    fontWeight: "normal",
                    fontSize: "15",
                    fontFamily: "Consolas,Tohama,verdana",
                    fontStyle: "normal",
                    borderThickness: 2,
    
                    contentFormatter: function (e) {
                        var content = "";
                        for (var i = 0; i < e.entries.length; i++) {
                            content = e.entries[i].dataPoint.y + " grams on " + CanvasJS.formatDate(e.entries[i].dataPoint.x, "DD-MMM-YYYY");
                        }
                        return content;
                    }
                },
                colorSet: "SetColourforChart",
                axisX: {
                    labelAngle: 135,
                    labelFontSize: "12",
                    valueFormatString: "DD-MMM-YYYY",
                },
                axisY: {
                    //includeZero: true,
                    //title: " Height in cms ",
                    titleFontSize: 15,
                    gridThickness: 1,
                    //lineThickness: 0,
                    tickThickness: 0,
                    //labelFontSize: 16,
                },
                data: [
                    {
                        name: 'Proteinuria',
                        type: "line",
                        showInLegend: true,
                        dataPoints: finalarray
                    }
                ],
                legend: {
                    cursor: "pointer",
                    itemclick: function (e1) {
                        if (typeof (e1.dataSeries.visible) === "undefined" || e1.dataSeries.visible) {
                            e1.dataSeries.visible = false;
                        }
                        else {
                            e1.dataSeries.visible = true;
                        }
                        Proteinuria_Chart_00.render();
                    }
                }
            });
    
        Proteinuria_Chart_00.render();
        });
    }
}