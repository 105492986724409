<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="this.masterType == 'Conductiondoctor'">
    <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="cover_div">
                <div class="header_lable">
                    Conduction Doctor
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label>Test name</mat-label>
                            <mat-select class="ipcss widthappt" [(ngModel)]="changetestid" required>
                                <mat-option *ngFor="let Diag_Test of Diag_Test_list_Data"
                                    value={{Diag_Test.diag_tests_id}}>
                                    {{Diag_Test.test_name}}</mat-option>
                            </mat-select>

                            <!-- <select class="ipcss widthappt" required [(ngModel)]="changetestid">
                                <option *ngFor="let Diag_Test of Diag_Test_list_Data"
                                    value={{Diag_Test.diag_tests_id}}>
                                    {{Diag_Test.test_name}}
                                </option>
                            </select> -->
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label>Doctor</mat-label>
                            <mat-select class="ipcss widthappt" [(ngModel)]="doctorid" multiple>
                                <mat-option *ngFor="let doctors of doctorList" value={{doctors.Doc_ID}}>
                                    {{doctors.Doc_Name}}</mat-option>
                            </mat-select>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                            <mat-label class="matlabel">Price<br>
                                <input placeholder="fees " [(ngModel)]="costprice" type="text" />
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-1 col-lg-1 col-xl-1">
                            <mat-label class="matlabel">&nbsp;<br>
                                <img src="../../../assets/ui_icons/buttons/add_button.svg" class="saveimgbtn_inpatinfo"
                                    (click)="saveConductiondoctor('','','','','save')" />
                            </mat-label>
                        </div>
                        <!-- <div class="col-4">
                            <mat-label >Price</mat-label>
                            <input  placeholder="price and fees " [(ngModel)]="costprice" type="text"/>
                            <img *ngIf="newbutton" src="../../../assets/ui_icons/buttons/add_button.svg" class="saveimgbtn_inpatinfo"
                                (click)="saveConductiondoctor('','','','','save')" />
                        </div> -->
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 masterdatabox">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 "
                                *ngFor="let getdoctortest of getdoctortestfeearray">
                                <table id="salesTable" class="table table-sm discount_table">
                                    <tbody style="border-radius: 10px;">
                                        <tr>
                                            <td style="text-align: left; width: 35%">{{ getdoctortest.test_name }}</td>
                                            <!-- <td>{{doctorList.Doc_Name}}</td> -->
                                            <td style="text-align: left; width: 35%">{{ getdoctortest.first_name }} {{
                                                getdoctortest.last_name }}</td>
                                            <td style="text-align: right; width: 20%">{{ getdoctortest.cost }}</td>
                                            <td class="butnstyle" style="text-align: right; width: 10%">
                                                <!-- <a *ngIf="editbutton" (click)="editConductiondoctor(getdoctortest.diag_tests_id, getdoctortest.cost, getdoctortest.conduct_doc_pay_id, getdoctortest.doc_reg_id)">
                                          <img src="../../assets/img/edit.png" width="20" height="20" class="btun1" />
                                        </a> -->
                                                <a *ngIf="deletebutton"
                                                    (click)="deleteConductiondoctor(getdoctortest.diag_tests_id, getdoctortest.doc_reg_id, getdoctortest.conduct_doc_pay_id, getdoctortest.cost, 'Erase')">
                                                    <img src="../../assets/dist/images/delete.png" width="20"
                                                        height="20" class="btun" />
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="this.masterType == 'Conductiondoctorsurgery'">
    <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding: 20px;">
            <div class="cover_div">
                <div class="header_lable">
                    Conduction Doctor Surgery
                </div>
                <div class="content_cover">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label>Surgery name</mat-label>
                            <mat-select class="ipcss widthappt" [(ngModel)]="sugeryid" required>
                                <mat-option *ngFor="let surgery of surgery_data" value={{surgery.procedure_id}}>
                                    {{surgery.description}}</mat-option>
                            </mat-select>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <mat-label>Doctor</mat-label>
                            <mat-select class="ipcss widthappt" [(ngModel)]="doctorid" multiple>
                                <mat-option *ngFor="let doctors of doctorList" value={{doctors.Doc_ID}}>
                                    {{doctors.Doc_Name}}</mat-option>
                            </mat-select>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                            <mat-label class="matlabel">Price<br>
                                <input placeholder="fees " [(ngModel)]="costprice" type="text" />
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-1 col-lg-1 col-xl-1">
                            <mat-label class="matlabel">&nbsp;<br>
                                <img src="../../../assets/ui_icons/buttons/add_button.svg" class="saveimgbtn_inpatinfo"
                                    (click)="saveConductiondoctor('','','','','save')" />
                            </mat-label>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 masterdatabox">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 "
                                *ngFor="let doc of doctorPayList">
                                <table id="salesTable" class="table table-sm discount_table">
                                    <tbody style="border-radius: 10px;">
                                        <tr>
                                            <td style="text-align: left; width: 35%">{{ doc.surgery_desc }}</td>
                                            <td style="text-align: left; width: 35%">{{ doc.doctor_name }}</td>
                                            <td style="text-align: right; width: 20%">{{ doc.cost }}</td>
                                            <td class="butnstyle" style="text-align: right; width: 10%">
                                                <a *ngIf="deletebutton"
                                                    (click)="deleteConductiondoctorSurgery(doc.surgery_id, doc.doc_reg_id, doc.cost, doc.doc_payments_id)">
                                                    <img src="../../assets/dist/images/delete.png" width="20"
                                                        height="20" class="btun" />
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>