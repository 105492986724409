<div class="row" style="width:100%;margin:0 auto;">
   <div class="col-12">
     <mdb-card>
       <mdb-card-header class="bg-white">
         <div class="headerCover">
           <div class="headerTilte app_list_header">
             <h5 class="m-0" class="mainHeadingStyle">Inward Report</h5>
           </div>
           <div class="headerButtons">
            
           </div>
         </div>
         
       </mdb-card-header>
       <div class="d-flex justify-content-end" style="width:95%;" *ngIf="backbutton">
           
         <img src="../../../assets/ui_icons/buttons/back_icon.svg" style="width:30px;margin-right: 5px;" width="85px" (click)="backpage()">
         <a style="margin:0 5px;" (click)="print_area()"><img src="../../../assets/img/printer.svg"
            class="backimgbtn_inpatinfo" style="width: 25px; height: 25px;" *ngIf="backbutton" /></a>
            <img src="../../../assets/ui_icons/buttons/XL_export.svg" class="saveimgbtn_inpatinfo"
            (click)="getExcelData('excel')" />
         </div>
       <mdb-card-body>
         <div class="cover_div" style="padding-bottom: 13px;" *ngIf="forcoverdiv">
           <div class="header_lable">
             Inward report
           </div>
           <div class="content_cover">
             <div class="row">
               
               <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 select_bottom">
                  <mat-label class="matlabel">Duration<br>
                    <select [(ngModel)]="filtduration" class="ipcss">
                      <option value="select">Select</option>
                      <option value="0">Present date</option>
                      <option value="7">1 week</option>
                      <option value="30">1 month</option>
                      <option value="91">3 months</option>
                      <option value="183">6 months</option>
                      <option value="365">1 year</option>
                    </select>
                  </mat-label>
                </div>

               <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                 <mat-label class="matlabel" style="margin-top: 7px;">From
                   <input type="date" class="ipcss_date " (change)="dateSelect(fromDate,'1')" [(ngModel)]="fromDate"
                     max="{{currentDate}}" #matInput style="width: 140px;">
                 </mat-label>
               </div>
               <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                 <mat-label class="matlabel" style="margin-top: 7px;">To
                   <input type="date" class="ipcss_date " (change)="dateSelect(toDate,'2')" [(ngModel)]="toDate"
                     max="{{currentDate}}" #matInput style="width: 140px;">
                 </mat-label>
               </div> -->
               <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                 <mat-label class="matlabel"><br>
                   <img src="../../../assets/ui_icons/buttons/search_button.svg" class="saveimgbtn_inpatinfo"
                     (click)="getinwardData('otc')" />
                 </mat-label>
               </div>
               <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                 <mat-label class="matlabel"><br>
                 
                 </mat-label>
               </div>
              
             </div>
           </div>
         </div>
         <p class="nodata" *ngIf="pastinwardsarray.length ==0">No records found</p>
         <div class="table-responsive dig_table_overflow" style="margin-top: 10px;"  *ngIf="pastinwardsarray.length != 0 ">
           <table #printinward id="printinward"  class="table table-hover table-dynamic"
             style="font-size: 12px; width: 100%;margin-left: 5px;">
             <thead>
               <tr>
                 <th>Bill no</th>
                 <th>Name</th>
                 <th>Refund Date</th>
                 <th>Refund time</th>
                 <th>Pay type</th>
                 <th>Amount</th>
               </tr>
             </thead>
             <tbody>
               <tr *ngFor="let sales of pastinwardsarray">
                 <td>{{sales.order_id}}</td>
                 <td class="align_left">{{sales.name}}</td>
                 <td>{{sales.order_date}}</td>
                 <td class="align_left">{{sales.refund_time}}</td>
                 <td style="text-align: right;">{{sales.payment_desc}}</td>
                 <td style="text-align: right;">{{sales.amount}}</td>
               </tr>

               <!-- order_id: dataval.refunds[i].order_id,
               name: name,
               refund_time:Time_Formate(dataval.refunds[i].refund_time), 
               order_date: orddate[2] + "-" + orddate[1] + "-" + orddate[0],
               payment_desc:dataval.refunds[i].payment_desc,
               amount: dataval.refunds[i].amount, -->

             </tbody>
           </table>
         </div>
       </mdb-card-body>
     </mdb-card>
   </div>
 </div>
