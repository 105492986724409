<div *ngIf="vitalFlag == 'min'">
  <div class="row">
    <div [hidden]="height_hidden" class="col-12">
      <mat-label class="matlabel">Height<br>
        <input [readonly]="client" matInput class="ipcss " onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46"
         (change)="calories_data()" required [(ngModel)]="height_txt">
      </mat-label>
    </div>
    <div [hidden]="height_measurehidden" class="col-12">
      <mat-label class="matlabel" [hidden]="height_measurehidden">Measure<br>
        <select [disabled]="client" class="ipcss " (change)="calories_data()" [(ngModel)]="height_measure"
          disableOptionCentering>
          <option value="cms">cms</option>
          <option value="inch">inch</option>
        </select>
      </mat-label>
    </div>

    <div [hidden]="weight_hidden" class="col-12">
      <mat-label class="matlabel">Weight<br>
        <input [readonly]="client" matInput class="ipcss " (change)="calories_data()" required [(ngModel)]="weight_txt">
      </mat-label>
    </div>
    <div [hidden]="weight_measurehidden" class="col-12">
      <mat-label class="matlabel" [hidden]="weight_measurehidden">Measure<br>
        <select [disabled]="client" class="ipcss " [(ngModel)]="weight_measure" disableOptionCentering>
          <option value="kgs">kgs</option>
          <option value="lbs">lbs</option>
        </select>
      </mat-label>
    </div>
  </div>
</div>
<div *ngIf="vitalFlag == 'max'">
  <div class="row">
    <div class="col-12">
      <div class="cover_div" style="margin-top: 15px;">
        <div class="header_lable">
          Vitals & Systemic Examination
        </div>
        <div class="content_cover">
          <div class="container  p-0" style="margin-top: 2px;margin-bottom: 2px;margin-left: 0px;margin-right: 0px;">
            <div class="row">
              <p style=""><span style="font-weight: 550;">Vitals :</span></p>
            </div>

            <div class="row">
              <div class="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-2">
                <div class="row">
                  <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6" [hidden]="height_hidden">
                    <mat-label class="matlabel">Height
                      <input class="ipcss " 
                      onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46"
                        (change)="calories_data()" [(ngModel)]="height_txt" matInput (keydown)=(validate($event))
                        required />
                        <!-- onkeypress="return event.charCode >= 48 && event.charCode <= 57" -->
                    </mat-label>
                  </div>


                  <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6" [hidden]="height_measurehidden">
                    <mat-label class="matlabel">Measure<br>
                      <select class="ipcss widthch1" [disabled]="client" class="ipcss " (change)="calories_data()"
                        [(ngModel)]="height_measure" disableOptionCentering>
                        <option value="cms">cms</option>
                        <option value="inch">inch</option>
                      </select>
                    </mat-label>
                  </div>
                </div>
              </div>
              <div class="col-6 col-sm-4 col-md-3 col-lg-3 col-xl-2" [hidden]="weight_hidden">
                <div class="row">
                  <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                    <mat-label class="matlabel">Weight<br>
                      <input [readonly]="client" matInput class="ipcss " (change)="calories_data()" required
                        [(ngModel)]="weight_txt" (keydown)=(validate($event)) />
                    </mat-label>
                  </div>
                  <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6" [hidden]="weight_measurehidden">
                    <mat-label class="matlabel">Measure<br>
                      <select class="ipcss widthch1" [(ngModel)]="weight_measure" [disabled]="client" class="ipcss "
                        (change)="calories_data()">
                        <option value="kgs">kgs</option>
                        <option value="lbs">lbs</option>
                      </select>
                    </mat-label>
                  </div>
                </div>
              </div>

              <!-- onkeypress="return event.charCode >= 48 && event.charCode <= 57" -->

              <div class="col-6 col-sm-4 col-md-1 col-lg-1 col-xl-1">
                <mat-label class="matlabel">BMI
                  <input class="ipcss " [(ngModel)]="txt_bmi" matInput [readonly]="client" [(ngModel)]="txt_bmi"
                    [readonly]="client" />
                </mat-label>
              </div>


              <div class="col-6 col-sm-4 col-md-2 col-lg-2 col-xl-1">
                <mat-label class="matlabel">Bmr<br>
                  <input class="ipcss " matInput [readonly]="client" [(ngModel)]="txt_bmr" [readonly]="client" />
                </mat-label>
              </div>

              <div class="col-12 col-sm-4 col-md-2 col-lg-2 col-xl-2" [hidden]="blood_hidden">
                <mat-label class="matlabel">Blood pressure</mat-label>
                <div class="row">
                  <div class=" col-6">
                    <input type="text" class="ipcss widthch1 noappt_width" placeholder="mm Hg" maxlength="3"
                      [(ngModel)]="BP_01" [ngStyle]="{'color': siscolorstyle ? 'red' : 'black'}"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57" (input)="sistol(BP_01)">
                  </div>
                  <div class="col-6">
                    <input type="text" class="ipcss widthch1 noappt_width" placeholder="mm Hg" maxlength="3"
                      [(ngModel)]="BP_02" [ngStyle]="{'color': diocolorstyle ? 'red' : 'black'}"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57" (input)="diostol(BP_02)">
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-1 col-lg-1 col-xl-1">
                <div class="row">
                  <div >
                    <mat-label class="matlabel">PR (min)<br>
                      <input matInput class="ipcss " maxlength="25" placeholder="PR" [(ngModel)]="pr_txt" (input)="pulsevalue(pr_txt)" [ngStyle]="{'color': pulsecolorstyle ? 'red' : 'black'}">
                    </mat-label>
                  </div>
                  <!-- <div class="col-4" style="margin-top:26px;padding: 0px;">
                    <span>/ min</span>
                  </div> -->
                </div>
              </div>
              <div class="col-6 col-sm-4 col-md-1 col-lg-1 col-xl-2" [hidden]="temp_hidden">
                <mat-label class="matlabel">Temperature (°F)</mat-label>
                <div class="row">
                  <div class="col-12" [hidden]="temp_hidden">                   
                      <input type="number" matInput class="ipcss noappt_width"
                        [ngStyle]="{'color': colorstyle ? 'red' : 'black'}" [(ngModel)]="Temperature"
                        (input)="tempcolor(Temperature)">                   
                  </div>
                  <!-- <div class="col-4">
                      <select [(ngModel)]="temp_read" class="ipcss widthappt" (ngModelChange)="chnagetemp($event)">
                        <option value="°C">°C</option>
                        <option value="F">F</option>
                      </select>
                  </div> -->
                </div>
              </div>
            
              <div class="col-12 col-sm-6 col-md-1 col-lg-1 col-xl-1">
                <div class="row">
                  <div>
                    <mat-label class="matlabel">RR (min)<br>
                      <input matInput class="ipcss noappt_width" maxlength="25" placeholder="RR" [(ngModel)]="rr_txt"
                        (input)="rrvalue(rr_txt)" [ngStyle]="{'color': rrcolorstyle ? 'red' : 'black'}">
                    </mat-label>
                  </div>
                  <!-- <div class="col-4" style="margin-top:26px;padding: 0px;">
                    <span>/ min</span>
                  </div> -->
                </div>
              </div>
     
              <div class="col-12 col-sm-6 col-md-1 col-lg-1 col-xl-1">
                <div class="row">
                  <div>
                    <mat-label class="matlabel">Spo2 (%)<br>
                      <input matInput class="ipcss " maxlength="25" placeholder="saturation" [(ngModel)]="spo2_txt"
                        (input)="spo2(spo2_txt)" [ngStyle]="{'color': spo2colorstyle ? 'red' : 'black'}">
                    </mat-label>
                  </div>
                  <!-- <div class="col-4" style="margin-top:26px;padding: 0px;">
                    <span>%</span>
                  </div> -->
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-1 col-lg-1 col-xl-2">
                <div class="row">
                  <div class="col-8">
                    <mat-label class="matlabel">CBG (mg/dl)<br>
                      <input matInput class="ipcss " maxlength="25" placeholder="CBG" [(ngModel)]="cbg_txt"
                        (input)="cbgvalue(cbg_txt)" [ngStyle]="{'color': cbgcolorstyle ? 'red' : 'black'}">
                    </mat-label>
                  </div>
                  <!-- <div class="col-4" style="margin-top:26px;padding: 0px;">
                    <span>mg/dl</span>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
          <div class="container  p-0" style="margin-top: 11px;margin-bottom: 2px;margin-left: 0px;margin-right: 0px;">
            <div class="row">
              <p style=""><span style="font-weight: 550;">Systemic Examination :</span></p>
            </div>

            <div class="row">
              <div class="col-12 col-sm-6 col-md-1 col-lg-1 col-xl-1">
                <mat-label class="matlabel">CVS<br>
                  <input matInput class="ipcss " placeholder="CVS" [(ngModel)]="cvs_txt">
                </mat-label>
              </div>
              <!-- newly added rs key -->

              <div class="col-12 col-sm-6 col-md-1 col-lg-1 col-xl-1">
                <mat-label class="matlabel">RS<br>
                  <input matInput class="ipcss " maxlength="25" placeholder="RS" [(ngModel)]="rstxt">
                </mat-label>
              </div>
              <!-- <div class="col-6 col-sm-4 col-md-1 col-lg-1 col-xl-1">
                <div class="row">
                  <div class="col-8">
                    <mat-label class="matlabel">Pulse<br>
                      <input matInput class="ipcss noappt_width" placeholder="pulse" [(ngModel)]="pulse_txt"
                        (input)="pulsevalue(pulse_txt)" [ngStyle]="{'color': pulsecolorstyle ? 'red' : 'black'}">
                    </mat-label>
                  </div>

                  <div class="col-4" style="margin-top:26px;padding: 0px;">
                    <span> bpm</span>
                  </div>
                </div>
              </div> -->
              <div class="col-12 col-sm-6 col-md-1 col-lg-1 col-xl-1">
                <mat-label class="matlabel">Abdomen<br>
                  <input matInput class="ipcss " placeholder="ABD" maxlength="25" [(ngModel)]="abd_txt">
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-1 col-lg-1 col-xl-1">
                <mat-label class="matlabel">CNS<br>
                  <input matInput class="ipcss " maxlength="25" placeholder="CNS" [(ngModel)]="cns_txt">
                </mat-label>
              </div>
           
              <!-- new keys -->
              <div class="col-12">
                <a style="float: right;" [hidden]="client" (click)="save_click()"><img
                    src="../../../assets/ui_icons/buttons/save_button.svg" class="saveimgbtn_inpatinfo" /></a>
              </div>

            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</div>
