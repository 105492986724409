<!-- minimize -->
<div *ngIf="diagPresFlag == 'min' ">
    <p *ngIf="diag_pres_list.length == 0">No prescription details found</p>
    <div class="dig_table_overflow" style="height: 200px;" *ngIf="diag_pres_list.length != 0">
        <div class="table-responsive">
            <table id="tbl" class="table table-hover table-dynamic">
                <thead>
                    <tr>
                        <th style="padding-left: 8px !important;">Date</th>
                        <th>Prescription</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let prescription of diag_pres_list | slice: 0 : 2">
                        <td style="font-size: 11px !important;">{{prescription.date}}</td>
                        <td style="font-size: 11px !important;"> Prescribed by {{"Dr."+ prescription.doctorname}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<!-- maximize -->
<div *ngIf="diagPresFlag == 'max' ">
    <!-- List -->
    <div style="float: right;margin-bottom: 10px;">
        <img *ngIf="diagPresFlag=='max' && !adddiag && !add_MP"
            style="width: 25px !important;position: absolute;top: 4px;right: 32px;"
            src="../../../assets/ui_icons/minussybl.svg" (click)="addDiagPrescription()" />

        <a *ngIf="list_button_flag" (click)="spiltarea('list_view')"><img
                src="../../../assets/ui_icons/pres_list_view.svg" class="backimgbtn_inpatinfo"
                style="position: absolute; top: 6px; right: 61px; width: 22px;" /></a>
        <a *ngIf="!print_view_flag" (click)="print_Viewprescription()"><span class="cardsave" style="right: 84px;"><img
                    src="../../../assets/img/printer.svg" style="width:25px" /></span> </a>

    </div>
    <div class="nodata" *ngIf="diag_pres_list.length == 0 && !adddiag">No prescription details found</div>

    <div *ngIf="!adddiag" class="splitter">
        <as-split direction="vertical" *ngIf="diag_pres_list.length != 0">
            <as-split-area [size]="areas[0].size" style="margin-top: -42px;">
                <div class="block1 floatright">
                </div><br>
                <div class="personaldetails" *ngIf="diag_pres_list.length != 0">
                    <div class="block1">
                        <p class="nodata" *ngIf="diag_pres_list.length == 0">No prescription details found</p>
                        <div class="dig_table_overflow">
                            <table id="card_tbl" class="table table-hover table-dynamic"
                                *ngIf="diag_pres_list.length != 0">
                                <tr>
                                    <th style="padding-left: 9px !important;border-right: 1px solid #fff;">Date</th>
                                    <th style="border-right: 1px solid #fff;">Prescription</th>
                                    <th></th>
                                </tr>
                                <tr *ngFor="let prescription of diag_pres_list">
                                    <td>{{prescription.date}} {{prescription.app_time}}</td>
                                    <td style="text-align: left;">{{" Prescribed by "+"Dr."+ prescription.doctorname}}
                                    </td>
                                    <td *ngIf="diagPresFlag == 'max'"><a
                                            (click)="Select_list(prescription.pres_diag_id)"><img
                                                src="../../../assets/ui_icons/search_icon.svg"
                                                class="editDelicon" /></a></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </as-split-area>
            <as-split-area [size]="areas[1].size" *ngIf="diagPresFlag == 'max'">
                <p *ngIf="diag_view_list" class="tervys_heading"><strong>Diagnosis details</strong></p>
                <div class="personaldetails">
                    <div *ngIf="diag_view_list">
                        <div *ngFor="let address of doct_address1">
                            <div class="row">
                                <div class="col-6">
                                    <div class="row" *ngIf="Hospdetails">
                                        <div class="col-4 headernew"><span style="font-weight: 550;">Referred
                                                doctor</span></div>
                                        <div class="col-1"><span>: </span></div>
                                        <div class="col-7 optionnew"><b>Dr. {{address.name}}</b></div>
                                        <div class="col-4 headernew"></div>
                                        <div class="col-1"></div>
                                        <div class="col-7 optionnew"><b>{{address.hosp_name}}</b></div>
                                        <div class="col-4 headernew"></div>
                                        <div class="col-1"></div>
                                        <div class="col-7 optionnew">{{address.hos_address}}</div>
                                        <div class="col-4 headernew"></div>
                                        <div class="col-1"></div>
                                        <div class="col-7 optionnew">{{address.hos_location + ", " +
                                            address.hos_city}}</div>
                                        <div class="col-4 headernew"></div>
                                        <div class="col-1"></div>
                                        <div class="col-7 optionnew">{{address.hos_state}}</div>
                                        <div class="col-4 headernew"></div>
                                        <div class="col-1"></div>
                                        <div class="col-7 optionnew">{{address.hos_telephone}}</div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="row" *ngIf="Hospdetails">
                                        <div class="col-4 headernew"><span style="font-weight: 550;">Suggested
                                                center</span></div>
                                        <div class="col-1"><span>: </span></div>
                                        <div class="col-7 optionnew">{{address.hos_center}}</div>
                                        <div class="col-4 headernew"></div>
                                        <div class="col-1"></div>
                                        <div class="col-7 optionnew"><b>{{address.address1}}</b></div>
                                        <div class="col-4 headernew"></div>
                                        <div class="col-1"></div>
                                        <div class="col-7 optionnew">{{address.state}}</div>
                                        <div class="col-4 headernew"></div>
                                        <div class="col-1"></div>
                                        <div class="col-7 optionnew">{{address.hos_location + ", " +
                                            address.hos_city}}</div>
                                        <div class="col-4 headernew"></div>
                                        <div class="col-1"></div>
                                        <div class="col-7 optionnew">{{address.hos_state}}</div>
                                        <div class="col-4 headernew"></div>
                                        <div class="col-1"></div>
                                        <div class="col-7 optionnew">{{address.telephone}}</div>
                                    </div>
                                </div>
                            </div>



                            <div class="topBottom">
                                <mat-label class="matlabel"><b>Name :</b> {{doct_clientname}}</mat-label><br>
                                <mat-label class="matlabel"><b>Date & time:</b> {{address.date}}</mat-label>
                            </div>
                        </div>
                        <div *ngIf=test_check>
                            <div class="table-responsive dig_table_overflow">
                                <table id="tbl">
                                    <thead>
                                        <tr>
                                            <th style="border-right:1px solid #fff;">Test type</th>
                                            <th>Test name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let tests of test_type">
                                            <td data-th="Test type">{{tests.test}}</td>
                                            <td data-th="Test name" style="text-align: left; padding-left: 20px;">
                                                {{tests.subtest}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div *ngIf="next_vist_flag" class="topBottom">
                            <mat-label class="matlabel"><b>Next visit date:</b> {{next_vist_date}}</mat-label>
                        </div>
                        <div *ngIf="next_visit_list.length != 0">
                            <div class="table-responsive dig_table_overflow">
                                <table id="tbl">
                                    <thead>
                                        <tr>
                                            <th style="border-right:1px solid #fff; width: 19%;">Test type</th>
                                            <th>Test name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let tests of next_visit_list">
                                            <td data-th="Test type">{{tests.test}}</td>
                                            <td data-th="Test name" style="text-align: left; padding-left: 20px;">
                                                {{tests.subtest}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </as-split-area>
        </as-split>
    </div>

    <!-- create -->
    <!-- <div class="personaldetails" *ngIf="adddiag">
      <a (click)="Diagnosis_prescription_save()"><span class="cardsave"><img src="../../../assets/img/save.svg"
                  style="width:25px" /></span> </a>
      <div class="row">
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
              <mat-label class="matlabel">Search location<br>
                  <input type="text" class="ipcss length" [(ngModel)]="loc_name" name="loc_name"
                      (keyup)="Locationfilter($event)" (ngModelChange)="select_location_item(loc_name)" type="text"
                      aria-label="Number" matInput [matAutocomplete]="auto" />
                  <mat-autocomplete #auto="matAutocomplete">
                      <mat-option id="optionfont" (click)="select_location_item(location)"
                          *ngFor="let location of consultationlocation" [value]="location">
                          {{location}}
                      </mat-option>
                  </mat-autocomplete>
              </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
              <mat-label class="matlabel">Search city<br>
                  <input type="text" class="ipcss length" [(ngModel)]="filt_city_desc" name="filt_city_desc"
                      (ngModelChange)="City_change($event)" aria-label="Number" matInput [matAutocomplete]="auto2" />
                  <mat-autocomplete #auto2="matAutocomplete">
                      <mat-option id="optionfont" *ngFor="let city of consultationcity" [value]="city.city_desc">
                          {{city.city_desc}}
                      </mat-option>
                  </mat-autocomplete>
              </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
              <mat-label class="matlabel">Search state<br>
                  <input type="text" class="ipcss length" [(ngModel)]="filt_state_desc" name="filt_state_desc"
                      (ngModelChange)="State_change($event)" aria-label="Number" matInput [matAutocomplete]="auto3" />
                  <mat-autocomplete #auto3="matAutocomplete">
                      <mat-option id="optionfont" *ngFor="let state of consultationstate" [value]="state.state_desc">
                          {{state.state_desc}}
                      </mat-option>
                  </mat-autocomplete>
              </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
              <mat-label class="matlabel">Search country<br>
                  <input type="text" class="ipcss length"
                      (ngModelChange)="SerCountry_Change(filt_country,filt_state_desc,filt_city_desc,loc_name)"
                      [(ngModel)]="filt_country" name="filt_country" aria-label="Number" matInput
                      [matAutocomplete]="auto4">
                  <mat-autocomplete #auto4="matAutocomplete">
                      <mat-option id="optionfont" *ngFor="let country of consultationcountry"
                          [value]="country.country_desc">
                          {{country.country_desc}}
                      </mat-option>
                  </mat-autocomplete>
              </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
              <mat-label class="matlabel">Diagnosis center<br>
                  <mat-select disableOptionCentering class="ipcss" [(ngModel)]="sercah_center">
                      <mat-option *ngFor="let diag of diag_detail_List" (click)="serch_centerchange(diag)"
                          [value]="diag.diag_centre_name"> {{diag.diag_centre_name}}</mat-option>
                  </mat-select>
              </mat-label>
          </div>
          <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
              <mat-label class="matlabel">Diagnosis address<br>
                  <textarea class="text_area" [(ngModel)]="obs_add_diag" name="obs_add_diag" matInput></textarea>
              </mat-label>
          </div>
      </div>

      <div class="nodata" *ngIf="test_flag">No data found</div>
      <div class="row">
          <div class="col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12">
              <div class="row">
                  <div class="col-12 col-sm-12 col-xl-9 col-lg-9 col-md-6">
                      <div class="row">
                          <div *ngFor="let testtype of Blood_test" class="col-12 col-sm-6 col-xl-4 col-lg-4 col-md-4">
                              <div class="diagnosis_testgrid testtype">
                                  <p class="titletest"><strong>{{testtype.test__type_name}}</strong></p>
                                  <div class="diag_test_content_cover">
                                      <div *ngFor="let subtest of testtype.sub_tests" id="testlbel">
                                          <span *ngIf="subtest.checked == true" class="check_img"><img
                                                  (click)="ChangeTestType(false,subtest.test_id,subtest.test_name,testtype.test__type_name)"
                                                  src="../../../assets/ui_icons/popup_tick_mark.svg"
                                                  style="cursor: pointer;" width="12px" /></span>

                                          <span *ngIf="subtest.checked == false" class="check_img"><img
                                                  (click)="ChangeTestType(true,subtest.test_id,subtest.test_name,testtype.test__type_name)"
                                                  src="../../../assets/ui_icons/yellow_box.svg"
                                                  style="cursor: pointer;" width="12px" /></span>
                                          {{subtest.test_name}}
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-12 col-sm-12 col-xl-3 col-lg-3 col-md-6">
                      <div *ngFor="let testtype of Blood_test">
                          <div *ngIf="!bloodtest && testtype.test__type_name=='Blood Test'">
                              <div class="grouped-tags">
                                  <p class="title_subtest"><strong>{{testtype.test__type_name}}</strong></p>
                                  <div class="mat1">
                                      <div class="matmat" *ngFor="let s of subtestname">
                                          <div class="testtypep">{{s.test_name}}
                                              <span>
                                                  <img (click)="closeSelectedTest(testtype.test__type_name,s.test_id,s.test_name)"
                                                      src="../../../assets/ui_icons/cancel_icon_list.svg"
                                                      class="dig_close" alt="">
                                              </span>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div *ngIf="!scantest && testtype.test__type_name=='Scan'">
                              <div class="grouped-tags">
                                  <p class="title_subtest"><strong>{{testtype.test__type_name}}</strong></p>
                                  <div class="mat1">
                                      <div class="matmat" *ngFor="let s of subtestsname">
                                          <div class="testtypep">{{s.test_name}}
                                              <span>
                                                  <img (click)="closeSelectedTest(testtype.test__type_name,s.test_id,s.test_name)"
                                                      src="../../../assets/ui_icons/cancel_icon_list.svg"
                                                      class="dig_close" alt="">
                                              </span>

                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>

                          <div *ngIf="!ultratest && testtype.test__type_name=='Ultra Sound'">
                              <div class="grouped-tags">
                                  <p class="title_subtest"><strong>{{testtype.test__type_name}}</strong></p>
                                  <div class="mat1">
                                      <div class="matmat" *ngFor="let s of subtestuname">
                                          <div class="testtypep">{{s.test_name}}
                                              <span>
                                                  <img (click)="closeSelectedTest(testtype.test__type_name,s.test_id,s.test_name)"
                                                      src="../../../assets/ui_icons/cancel_icon_list.svg"
                                                      class="dig_close" alt="">
                                              </span>

                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div *ngIf="!xraytest && testtype.test__type_name=='X-ray'">
                              <div class="grouped-tags">
                                  <p class="title_subtest"><strong>{{testtype.test__type_name}}</strong></p>
                                  <div class="mat1">
                                      <div class="row">
                                          <div class="matmat" *ngFor="let s of subtestxname">
                                              <div class="testtypep">{{s.test_name}}
                                                  <span>
                                                      <img (click)="closeSelectedTest(testtype.test__type_name,s.test_id,s.test_name)"
                                                          src="../../../assets/ui_icons/cancel_icon_list.svg"
                                                          class="dig_close" alt="">
                                                  </span>

                                              </div>
                                          </div>

                                      </div>
                                  </div>

                              </div>
                          </div>
                          <div *ngIf="!biopsytest && testtype.test__type_name=='Biopsy'">
                              <div class="grouped-tags">
                                  <p class="title_subtest"><strong>{{testtype.test__type_name}}</strong></p>
                                  <div class="mat1">
                                      <div class="matmat" *ngFor="let s of subtestbname">
                                          <div class="testtypep">{{s.test_name}}
                                              <span>
                                                  <img (click)="closeSelectedTest(testtype.test__type_name,s.test_id,s.test_name)"
                                                      src="../../../assets/ui_icons/cancel_icon_list.svg"
                                                      class="dig_close" alt="">
                                              </span>

                                          </div>
                                      </div>
                                  </div>

                              </div>
                          </div>
                          <div *ngIf="!urinetest && testtype.test__type_name=='Urine Test'">
                              <div class="grouped-tags">
                                  <p class="title_subtest"><strong>{{testtype.test__type_name}}</strong></p>
                                  <div class="mat1">
                                      <div class="matmat" *ngFor="let s of subtesturname">
                                          <div class="testtypep">{{s.test_name}}
                                              <span>
                                                  <img (click)="closeSelectedTest(testtype.test__type_name,s.test_id,s.test_name)"
                                                      src="../../../assets/ui_icons/cancel_icon_list.svg"
                                                      class="dig_close" alt="">
                                              </span>

                                          </div>
                                      </div>
                                  </div>

                              </div>
                          </div>
                          <div *ngIf="!faecestest && testtype.test__type_name=='Faeces Test'">
                              <div class="grouped-tags">
                                  <p class="title_subtest"><strong>{{testtype.test__type_name}}</strong></p>
                                  <div class="mat1">
                                      <div class="matmat" *ngFor="let s of subtestfname">
                                          <div class="testtypep">{{s.test_name}}
                                              <span>
                                                  <img (click)="closeSelectedTest(testtype.test__type_name,s.test_id,s.test_name)"
                                                      src="../../../assets/ui_icons/cancel_icon_list.svg"
                                                      class="dig_close" alt="">
                                              </span>

                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>

                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="row col-sm-12 col-xl-12 ">
          <mat-label class="matlabel wt">
              <mat-checkbox color="primary" labelPosition="before" [(ngModel)]="test_now">Would u like to test now
              </mat-checkbox>
          </mat-label>
      </div>
  </div> -->

</div>

<div hidden>
    <div #printnoheadsidebar id="printnoheadsidebar"
        style="background-image: url({{bg_image}}); background-position: center;">
        <div class="page-break"></div>
        <table style="border-bottom: 1px solid;width: 100%;margin-top: 100px;">
            <tr *ngFor="let address of doct_address1" style="float: right;">
                <td style="position:relative; right: 50px;">
                    <p>{{address.hos_center }}</p>
                    <p>{{address.address1}}</p>
                    <p>{{address.location + ", " + address.city}}</p>
                    <p>{{address.state}}</p>
                    <p>{{address.telephone}}</p>
                </td>
            </tr>
        </table>

        <table style="margin-left: 50px; margin-top:20px;">
            <tr>
                <td style="width: 500px;">
                    <p><b>{{'Name'|translate}} : </b> {{doct_clientname}}</p>
                    <p><b>{{'Age'|translate}} : </b> {{client_age}}</p>
                    <p><b>{{'Gender'|translate}}: </b>{{client_gender}} </p>
                    <!-- <p *ngIf="oberse_med!=undefined"><b>{{'Investigation'|translate}} :</b> {{oberse_med}}</p> -->
                </td>
                <td style="width: 300px;vertical-align: top;">
                    <p><b>{{'Date'|translate}} : </b>{{Appoint_Date}}</p>
                    <!-- <p *ngIf="ref_flag">{{'Referred by'|translate}}: {{refer_txt}}</p> -->
                </td>
            </tr>
        </table>
        <!-- <div style="margin-left: 220px;">
         <p><b>{{'Diagnosis' |translate}}: </b>{{dis_txt}}</p>
      </div> -->
        <br />
        <p style="margin-left: 50px;"><b>Diagnostic Test</b></p>
        <div *ngIf=test_check>
            <div class="table-responsive dig_table_overflow">
                <table style="margin-left: 100px; border: 1px solid black;border-collapse: collapse;width: 70%;">
                    <thead>
                        <tr>
                            <th style="width: 100px;border: 1px solid black;border-collapse: collapse;">Test type</th>
                            <th style="width: 100px;border: 1px solid black;border-collapse: collapse;">Test name</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let tests of test_type">
                            <td data-th="Test type"
                                style="width: 100px;border: 1px solid black;border-collapse: collapse;">{{tests.test}}
                            </td>
                            <td data-th="Test name"
                                style="width: 100px;border: 1px solid black;border-collapse: collapse;">
                                {{tests.subtest}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <br />
        <table style="width: 100%;margin-left: 50px;">
            <tr style="float: left;">
                <td>
                    <p><b>{{'Doctor'|translate}} : {{"Dr."+doct_name}} {{doc_qualif}}</b></p>
                    <p style="font-size: 13px">Registration no: {{licence_code}}</p>
                    <!-- <p style="font-size: 13px">Find your prescription online at
                      <i>www.tervys.com</i>
                  </p>
                  <p style="font-size: 13px"><b><i>This is digitally generated
                              prescription</i></b>
                  </p> -->
                    <br />
                </td>
            </tr>
        </table>
    </div>
</div>

<div [hidden]="true">
    <div #printbanner id="printbanner" style="background-image: url({{bg_image}}); background-position: center;">
        <div>
            <table>
                <thead>
                    <tr>
                        <td>
                            <div class=“empty-header“> </div>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <div class=“content“>

                                <div>
                                    <div
                                        style="margin-left: 25px !important;margin-right:25px !important;display: flex;padding: 10px; margin-top: 10px; border: 1px solid black; border-radius: 10px;">
                                        <div>
                                            <div style="display: flex;vertical-align: top;">
                                                <table style="width: 450px;vertical-align: top;">
                                                    <tr style="vertical-align: top;">
                                                        <td><strong>{{'Name'}}</strong></td>
                                                        <td>: {{doct_clientname}}</td>
                                                    </tr>
                                                    <tr style="vertical-align: top;">
                                                        <td><strong>{{'Age'}}/{{'Gender'}}</strong></td>
                                                        <td>: {{client_age}}/{{client_gender}}</td>
                                                    </tr>
                                                    <tr style="vertical-align: top;">
                                                        <td><strong>{{'Mobile'}}</strong></td>
                                                        <td>: {{mobile}}</td>
                                                    </tr>


                                                </table>
                                                <table style="width: 350px;vertical-align: top;">
                                                    <tr style="vertical-align: top;">
                                                        <td><strong>{{'Date & Time'}}</strong></td>
                                                        <td>: {{Appoint_Date}}</td>
                                                    </tr>
                                                    <tr style="vertical-align: top;">
                                                        <td *ngIf="barcode_flag"><img
                                                                style="width: 130px; height: 35px; margin-left: -12px;"
                                                                src={{barcode_url}} />
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>


                                    <div style="margin-left: 25px !important;margin-right:25px !important;margin-top: 10px;"
                                        *ngIf="test_type.length!=0">
                                        <p><strong>DX</strong></p>

                                        <div style="border: 1px solid #000;border-radius: 10px;padding: 10px;">
                                            <div *ngFor="let test of test_type" style="width: 89%;margin-bottom: 10px;">
                                                <div class="heading"><strong>{{test.test}}Tests:</strong></div>
                                                <div class="test-names-container">
                                                    <ng-container>{{ test.subtest}}</ng-container>
                                                </div>
                                            </div>
                                        </div>                                        
                                    </div>

                                </div>
                                <div style="margin-left: 25px !important;margin-right:25px !important;margin-top: 10px;"
                                    *ngIf="next_visit_list.length!=0">
                                    <p><strong>Test for next visit</strong></p>

                                    <div style="border: 1px solid #000;border-radius: 10px;padding: 10px;">
                                        <div *ngFor="let test of next_visit_list" style="width: 89%;margin-bottom: 10px;">
                                            <div class="heading"><strong>{{test.test}}Tests:</strong></div>
                                            <div class="test-names-container">
                                                <ng-container>{{ test.subtest}}</ng-container>
                                            </div>
                                        </div>                                                                           
                                        
                                    </div>
                                </div>

                                <div>
                                    <p style="margin-left: 25px !important;margin-right:25px !important;font-size: 13px;margin-top: 3px;">
                                        <strong>Doctor:</strong> {{doc_name}}
                                    </p>
                                    <!-- <p
                                        style="margin-left: 25px !important;margin-right:25px !important;font-size: 13px">
                                        ({{licence_code}})</p> -->

                                    <br />
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <div class=”empty-footer”>&nbsp;</div>
                </tfoot>
            </table>
            <div class=”header”>&nbsp;</div>
            <div class=”footer”>&nbsp;</div>
        </div>
    </div>
</div>