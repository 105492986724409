<div *ngIf="clinicalFlag == 'min'">
    <div class="block1_grid">
        <mat-label class="matlabel column">{{feelstress_label}}<br>
            <select required [(ngModel)]="feelstress" [disabled]="feelstress_flag" class="ipcss  thappt"
                disableOptionCentering>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
            </select>
        </mat-label>
        <mat-label class="matlabel column">{{stress_label}}<br>
            <textarea class="ipcss  thappt " #stress1 id="stress1" maxlength="250" rows="1" required
                [(ngModel)]="stress" [disabled]="stress_flag"></textarea>
        </mat-label>
    </div>
</div>

<div *ngIf="clinicalFlag == 'max'">
    <mat-tab-group [disableRipple]=true mat-align-tabs="start" [selectedIndex]="0">
        <mat-tab label="Clinical Exam">
            <div class="container ">
                <div class="row">
                    <div class="col-6">
                        <div class="cover_div">
                            <div class="header_lable">
                                Stress
                            </div>
                            <div class="content_cover">
                                <div class="row">
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <mat-label class="matlabel">{{feelstress_label}}<br>
                                            <select required [(ngModel)]="feelstress" [disabled]="client"
                                                (ngModelChange)="stress_change($event)" [disabled]="feelstress_flag"
                                                class="ipcss  " disableOptionCentering>
                                                <option value="Yes">Yes</option>
                                                <option value="No">No</option>
                                            </select>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <mat-label class="matlabel">{{stress_label}}<br>
                                            <textarea class="ipcss   " #stress1 id="stress1" maxlength="250" rows="1"
                                                required [(ngModel)]="stress"
                                                [disabled]="stress_flag || client"></textarea>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <mat-label class="matlabel">{{symptom_label}}<br>
                                            <textarea class="ipcss   " #symptom1 id="symptom1" maxlength="250" rows="1"
                                                required [(ngModel)]="symptom"
                                                [disabled]="stress_flag || client"></textarea>
                                        </mat-label>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="cover_div">
                            <div class="header_lable">
                                Concerns
                            </div>
                            <div class="content_cover">
                                <div class="row">
                                    <div class=" col-12">
                                        <mat-label class="matlabel">{{medhist_label}}<br>
                                            <textarea #matInput class="ipcss obsttxtareaheight " [(ngModel)]="medhist"
                                                (click)="medical_popup()" [disabled]="client"
                                                style="height: 132px !important;"></textarea>
                                        </mat-label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="cover_div">
                            <div class="header_lable">
                                Disorder
                            </div>
                            <div class="content_cover">
                                <div class="row">
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <mat-label class="matlabel">{{hdisorder_label}}<br>
                                            <select class="ipcss" required [(ngModel)]="hdisorder" [disabled]="client"
                                                (change)="hdisorder_change()" [disabled]="hdisorder_flag"
                                                disableOptionCentering>
                                                <option value="Yes">Yes</option>
                                                <option value="No">No</option>
                                            </select>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <mat-label class="matlabel">{{treat_label}}<br>
                                            <textarea class="ipcss   " #prevheartdis id="prevheartdis" maxlength="250"
                                                rows="1" required [(ngModel)]="treat"
                                                [disabled]="hdis_flag || client"></textarea>
                                        </mat-label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="cover_div">
                            <div class="header_lable">
                                Previous hospital
                            </div>
                            <div class="content_cover">
                                <div class="row">
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <mat-label class="matlabel">{{hosp_label}}<br>
                                            <select class="ipcss  " required [(ngModel)]="hosp"
                                                [disabled]="hosp_flag && client" (change)="prevhosp_change()">
                                                <option value="Yes">Yes</option>
                                                <option value="No">No</option>
                                            </select>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <mat-label class="matlabel">{{hospdet_label}}<br>
                                            <textarea class="ipcss" #prevhosp id="prevhosp1" maxlength="250" rows="1"
                                                required [(ngModel)]="hospdet"
                                                [disabled]="prevhosp_flag || client"></textarea>
                                        </mat-label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="cover_div">
                            <div class="header_lable">
                                Blood pressure
                            </div>
                            <div class="content_cover">
                                <div class="row">
                                    <div class=" col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                        <mat-label class="matlabel">{{rightarm_label}}</mat-label>
                                        <div class="row">
                                            <div class=" col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                                <input matInput class="ipcss " maxlength="3" placeholder="mm Hg"
                                                    [disabled]="client" [(ngModel)]="rightarm"
                                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                                            </div>
                                            <div class=" col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                                <input matInput class="ipcss " maxlength="3" placeholder="mm Hg"
                                                    [(ngModel)]="rightarm1"
                                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class=" col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                        <mat-label class="matlabel">{{leftarm_label}}<br></mat-label>
                                        <div class="row">
                                            <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">
                                                <input matInput class="ipcss " maxlength="3" placeholder="mm Hg"
                                                    [(ngModel)]="leftarm"
                                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                    [disabled]="client" />
                                                <!-- </mat-label> -->
                                            </div>
                                            <div class="col-6 col-sm-4 col-md-6 col-lg-6 col-xl-6">

                                                <input matInput class="ipcss " maxlength="3" placeholder="mm Hg"
                                                    [(ngModel)]="leftarm1"
                                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                    [disabled]="client" />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="cover_div">
                            <div class="header_lable">
                                Blood glucose
                            </div>
                            <div class="content_cover">
                                <div class="row">
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <mat-label class="matlabel">{{fasting_label}}<br>
                                            <input matInput class="ipcss" style="width: 100%;" maxlength="3"
                                                placeholder="mg/dl" [(ngModel)]="fasting"
                                                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                [disabled]="client" />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <mat-label class="matlabel">{{prandial_label}}<br>
                                            <input matInput class="ipcss" style="width: 100%;" maxlength="3"
                                                placeholder="mg/dl" [(ngModel)]="prandial"
                                                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                [disabled]="client" />
                                        </mat-label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="cover_div">
                            <div class="content_cover">
                                <div class="row">
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <mat-label class="matlabel">{{hsound_label}}<br>
                                            <select class="ipcss  " required [(ngModel)]="hsound" [disabled]="client"
                                                disableOptionCentering>
                                                <option value="Normal">Normal</option>
                                                <option value="Diminishing">Diminishing</option>
                                                <option value="Loud">Loud</option>
                                            </select>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" [hidden]="murmurs_hidden">
                                        <mat-label class="matlabel">{{murmurs_label}}<br>
                                            <select class="ipcss  " required [(ngModel)]="murmurs" [disabled]="client"
                                                (ngModelChange)="murmur_change($event)" disableOptionCentering>
                                                <option value="No">No</option>
                                                <option value="Loud">Loud</option>
                                                <option value="Harsh">Harsh</option>
                                                <option value="Mid-systolic ejection">Mid-systolic ejection</option>
                                                <option value="Blowing">Blowing</option>
                                                <option value="Low">Low</option>
                                                <option value="Numbling">Numbling</option>
                                                <option value="High-pitched early diastolic">High-pitched early
                                                    diastolic
                                                </option>
                                                <option value="Mid-diastolic">Mid-diastolic</option>
                                                <option>Continuous machinery murmur</option>
                                            </select>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <mat-label class="matlabel">{{murmursgrade_label}}<br>
                                            <select class="ipcss  " required [(ngModel)]="murmursgrade"
                                                disableOptionCentering [disabled]="murmur_flag || client">
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                            </select>
                                        </mat-label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class=" col-12 " *ngIf="!client">
                        <a style="float: right;"><img src="../../../assets/ui_icons/buttons/history.svg"
                                class="saveimgbtn_inpatinfo" (click)="history1()" /></a>
                    </div>
                    <div class="col-12" style="margin-bottom: 5px;margin-top: 5px;">
                        <mat-accordion class="mataccordion">
                            <mat-expansion-panel class="expandpanel">
                                <mat-expansion-panel-header class="exppanel th">
                                    <mat-panel-title class="title ">
                                        Pulse examination
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div class="top">
                                    <div class="container ">
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="cover_div">
                                                    <div class="header_lable">
                                                        Radial
                                                    </div>
                                                    <div class="content_cover">
                                                        <div class="row">
                                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <mat-label class="matlabel">{{rate_label}}<br>
                                                                    <select class="ipcss" [required]="radrate_required"
                                                                        [disabled]="client" [(ngModel)]="radrate"
                                                                        disableOptionCentering>
                                                                        <option value="Normal">Normal</option>
                                                                        <option value="&#60; 60 bpm (bradycardia)">&#60;
                                                                            60 bpm
                                                                            (bradycardia)</option>
                                                                        <option value="&#62; 100 bpm (tachycardia)">
                                                                            &#62; 100 bpm
                                                                            (tachycardia)</option>
                                                                    </select>
                                                                </mat-label>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <mat-label class="matlabel">{{rhythmn_label}}<br>
                                                                    <select class="ipcss" disableOptionCentering
                                                                        [disabled]="client"
                                                                        [required]="radrhythymn_required"
                                                                        [(ngModel)]="radrhythmn">
                                                                        <option value="Regular">Regular</option>
                                                                        <option value="Regularly irregulat">Regularly
                                                                            irregulat</option>
                                                                        <option value="Irregularly irregular">
                                                                            Irregularly irregular
                                                                        </option>
                                                                        <option value="Slow rising">Slow rising</option>
                                                                        <option value="Waterhammer">Waterhammer</option>
                                                                        <option value="Collapsing">Collapsing</option>
                                                                        <option value="Bisferiens">Bisferiens</option>
                                                                        <option value="Pulsus paradoxus">Pulsus
                                                                            paradoxus</option>
                                                                        <option value="Pulsus alternans">Pulsus
                                                                            alternans</option>
                                                                        <option value="Bounding">Bounding</option>
                                                                    </select>
                                                                </mat-label>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <mat-label class="matlabel">{{volume_label}}<br>
                                                                    <select class="ipcss" disableOptionCentering
                                                                        [disabled]="client" [required]="radvol_required"
                                                                        [(ngModel)]="radvolume">
                                                                        <option value="Hyperkinetic">Hyperkinetic
                                                                        </option>
                                                                        <option value="Hypokinetic">Hypokinetic</option>
                                                                        <option value="Pulsus paradoxus">Pulsus
                                                                            paradoxus</option>
                                                                        <option value="reverse pulsus paradoxus">reverse
                                                                            pulsus paradoxus
                                                                        </option>
                                                                        <option value="Pulsus alternans">Pulsus
                                                                            alternans</option>
                                                                        <option value="dicrotic pulse">dicrotic pulse
                                                                        </option>
                                                                    </select>
                                                                </mat-label>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <mat-label class="matlabel">{{wavetense_label}}<br>
                                                                    <select class="ipcss " disableOptionCentering
                                                                        [disabled]="client"
                                                                        [required]="radwtense_required"
                                                                        [(ngModel)]="radwtense">
                                                                        <option value="High-tension">High-tension
                                                                        </option>
                                                                        <option value="Low-tension">Low-tension</option>
                                                                    </select>
                                                                </mat-label>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <mat-label class="matlabel">{{wavecont_label}}<br>
                                                                    <select class="ipcss" [required]="radwcont_required"
                                                                        [disabled]="client">
                                                                        <option value="Fast rising">Fast rising</option>
                                                                        <option value="Slow rising">Slow rising</option>
                                                                    </select>
                                                                </mat-label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="cover_div">
                                                    <div class="header_lable">
                                                        Brachial
                                                    </div>
                                                    <div class="content_cover">
                                                        <div class="row">
                                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <mat-label class="matlabel">{{rate_label}}<br>
                                                                    <select class="ipcss  "
                                                                        [required]="bracrate_required"
                                                                        disableOptionCentering [(ngModel)]="bracrate"
                                                                        [disabled]="client">
                                                                        <option value="Normal">Normal</option>
                                                                        <option value="&#60; 60 bpm (bradycardia)">&#60;
                                                                            60 bpm
                                                                            (bradycardia)</option>
                                                                        <option value="&#62; 100 bpm (tachycardia)">
                                                                            &#62; 100 bpm
                                                                            (tachycardia)</option>
                                                                    </select>
                                                                </mat-label>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <mat-label class="matlabel">{{rhythmn_label}}<br>
                                                                    <select class="ipcss  "
                                                                        [required]="bracrhythymn_required"
                                                                        [disabled]="client" disableOptionCentering
                                                                        [(ngModel)]="bracrhythmn">
                                                                        <option value="Regular">Regular</option>
                                                                        <option value="Regularly irregulat">Regularly
                                                                            irregulat</option>
                                                                        <option value="Irregularly irregular">
                                                                            Irregularly irregular
                                                                        </option>
                                                                        <option value="Slow rising">Slow rising</option>
                                                                        <option value="Waterhammer">Waterhammer</option>
                                                                        <option value="Collapsing">Collapsing</option>
                                                                        <option value="Bisferiens">Bisferiens</option>
                                                                        <option value="Pulsus paradoxus">Pulsus
                                                                            paradoxus</option>
                                                                        <option value="Pulsus alternans">Pulsus
                                                                            alternans</option>
                                                                        <option value="Bounding">Bounding</option>
                                                                    </select>
                                                                </mat-label>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <mat-label class="matlabel">{{volume_label}}<br>
                                                                    <select class="ipcss  "
                                                                        [required]="bracvol_required"
                                                                        [disabled]="client" [(ngModel)]="bracvolume"
                                                                        disableOptionCentering>
                                                                        <option value="Hyperkinetic">Hyperkinetic
                                                                        </option>
                                                                        <option value="Hypokinetic">Hypokinetic</option>
                                                                        <option value="Pulsus paradoxus">Pulsus
                                                                            paradoxus</option>
                                                                        <option value="reverse pulsus paradoxus">reverse
                                                                            pulsus paradoxus
                                                                        </option>
                                                                        <option value="Pulsus alternans">Pulsus
                                                                            alternans</option>
                                                                        <option value="dicrotic pulse">dicrotic pulse
                                                                        </option>
                                                                    </select>
                                                                </mat-label>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <mat-label class="matlabel">{{wavetense_label}}<br>
                                                                    <select class="ipcss  "
                                                                        [required]="bracwtense_required"
                                                                        [disabled]="client" [(ngModel)]="bracwtense"
                                                                        disableOptionCentering>
                                                                        <option value="High-tension">High-tension
                                                                        </option>
                                                                        <option value="Low-tension">Low-tension</option>
                                                                    </select>
                                                                </mat-label>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <mat-label class="matlabel">{{wavecont_label}}<br>
                                                                    <select class="ipcss  "
                                                                        [required]="bracwcont_required"
                                                                        [disabled]="client" disableOptionCentering
                                                                        [(ngModel)]="bracwcont">
                                                                        <option value="Fast rising">Fast rising</option>
                                                                        <option value="Slow rising">Slow rising</option>
                                                                    </select>
                                                                </mat-label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="cover_div">
                                                    <div class="header_lable">
                                                        Carotid
                                                    </div>
                                                    <div class="content_cover">
                                                        <div class="row">
                                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <mat-label class="matlabel">{{rate_label}}<br>
                                                                    <select class="ipcss  "
                                                                        [required]="carotrate_required"
                                                                        [disabled]="client" disableOptionCentering
                                                                        [(ngModel)]="carotrate">
                                                                        <option value="Normal">Normal</option>
                                                                        <option value="&#60; 60 bpm (bradycardia)">&#60;
                                                                            60 bpm
                                                                            (bradycardia)</option>
                                                                        <option value="&#62; 100 bpm (tachycardia)">
                                                                            &#62; 100 bpm
                                                                            (tachycardia)</option>
                                                                    </select>
                                                                </mat-label>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <mat-label class="matlabel">{{rhythmn_label}}<br>
                                                                    <select class="ipcss  "
                                                                        [required]="carotrhythymn_required"
                                                                        [disabled]="client" disableOptionCentering
                                                                        [(ngModel)]="carotrhythmn">
                                                                        <option value="Regular">Regular</option>
                                                                        <option value="Regularly irregulat">Regularly
                                                                            irregulat
                                                                        </option>
                                                                        <option value="Irregularly irregular">
                                                                            Irregularly irregular
                                                                        </option>
                                                                        <option value="Slow rising">Slow rising</option>
                                                                        <option value="Waterhammer">Waterhammer</option>
                                                                        <option value="Collapsing">Collapsing</option>
                                                                        <option value="Bisferiens">Bisferiens</option>
                                                                        <option value="Pulsus paradoxus">Pulsus
                                                                            paradoxus</option>
                                                                        <option value="Pulsus alternans">Pulsus
                                                                            alternans</option>
                                                                        <option value="Bounding">Bounding</option>
                                                                    </select>
                                                                </mat-label>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <mat-label class="matlabel">{{volume_label}}<br>
                                                                    <select class="ipcss  "
                                                                        [required]="carotvol_required"
                                                                        [disabled]="client" disableOptionCentering
                                                                        [(ngModel)]="carotvolume">
                                                                        <option value="Hyperkinetic">Hyperkinetic
                                                                        </option>
                                                                        <option value="Hypokinetic">Hypokinetic</option>
                                                                        <option value="Pulsus paradoxus">Pulsus
                                                                            paradoxus</option>
                                                                        <option value="reverse pulsus paradoxus">reverse
                                                                            pulsus
                                                                            paradoxus</option>
                                                                        <option value="Pulsus alternans">Pulsus
                                                                            alternans</option>
                                                                        <option value="dicrotic pulse">dicrotic pulse
                                                                        </option>
                                                                    </select>
                                                                </mat-label>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <mat-label class="matlabel">{{wavetense_label}}<br>
                                                                    <select class="ipcss  "
                                                                        [required]="carotwtense_required"
                                                                        [disabled]="client" disableOptionCentering
                                                                        [(ngModel)]="carotwtense">
                                                                        <option value="High-tension">High-tension
                                                                        </option>
                                                                        <option value="Low-tension">Low-tension</option>
                                                                    </select>
                                                                </mat-label>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <mat-label class="matlabel">{{wavecont_label}}<br>
                                                                    <select class="ipcss  "
                                                                        [required]="carotwcont_required"
                                                                        [disabled]="client" disableOptionCentering
                                                                        [(ngModel)]="carotwcont">
                                                                        <option value="Fast rising">Fast rising</option>
                                                                        <option value="Slow rising">Slow rising</option>
                                                                    </select>
                                                                </mat-label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="cover_div">
                                                    <div class="header_lable">
                                                        Femoral
                                                    </div>
                                                    <div class="content_cover">
                                                        <div class="row">
                                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <mat-label class="matlabel">{{rate_label}}<br>
                                                                    <select class="ipcss  "
                                                                        [required]="femoralrate_required"
                                                                        [disabled]="client" disableOptionCentering
                                                                        [(ngModel)]="femoralrate">
                                                                        <option value="Normal">Normal</option>
                                                                        <option value="&#60; 60 bpm (bradycardia)">&#60;
                                                                            60 bpm
                                                                            (bradycardia)</option>
                                                                        <option value="&#62; 100 bpm (tachycardia)">
                                                                            &#62; 100 bpm
                                                                            (tachycardia)</option>
                                                                    </select>
                                                                </mat-label>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <mat-label class="matlabel">{{rhythmn_label}}<br>
                                                                    <select class="ipcss  "
                                                                        [required]="femoralrhythymn_required"
                                                                        [disabled]="client" disableOptionCentering
                                                                        [(ngModel)]="femoralrhythmn">
                                                                        <option value="Regular">Regular</option>
                                                                        <option value="Regularly irregulat">Regularly
                                                                            irregulat
                                                                        </option>
                                                                        <option value="Irregularly irregular">
                                                                            Irregularly irregular
                                                                        </option>
                                                                        <option value="Slow rising">Slow rising</option>
                                                                        <option value="Waterhammer">Waterhammer</option>
                                                                        <option value="Collapsing">Collapsing</option>
                                                                        <option value="Bisferiens">Bisferiens</option>
                                                                        <option value="Pulsus paradoxus">Pulsus
                                                                            paradoxus</option>
                                                                        <option value="Pulsus alternans">Pulsus
                                                                            alternans</option>
                                                                        <option value="Bounding">Bounding</option>
                                                                    </select>
                                                                </mat-label>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <mat-label class="matlabel">{{volume_label}}<br>
                                                                    <select class="ipcss  "
                                                                        [required]="femoralvol_required"
                                                                        [disabled]="client" disableOptionCentering
                                                                        [(ngModel)]="femoralvolume">
                                                                        <option value="Hyperkinetic">Hyperkinetic
                                                                        </option>
                                                                        <option value="Hypokinetic">Hypokinetic</option>
                                                                        <option value="Pulsus paradoxus">Pulsus
                                                                            paradoxus</option>
                                                                        <option value="reverse pulsus paradoxus">reverse
                                                                            pulsus
                                                                            paradoxus</option>
                                                                        <option value="Pulsus alternans">Pulsus
                                                                            alternans</option>
                                                                        <option value="dicrotic pulse">dicrotic pulse
                                                                        </option>
                                                                    </select>
                                                                </mat-label>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <mat-label class="matlabel">{{wavetense_label}}<br>
                                                                    <select class="ipcss  "
                                                                        [required]="femoralwtense_required"
                                                                        [disabled]="client" disableOptionCentering
                                                                        [(ngModel)]="femoralwtense">
                                                                        <option value="High-tension">High-tension
                                                                        </option>
                                                                        <option value="Low-tension">Low-tension</option>
                                                                    </select>
                                                                </mat-label>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <mat-label class="matlabel">{{wavecont_label}}<br>
                                                                    <select class="ipcss  "
                                                                        [required]="femoralwcont_required"
                                                                        [disabled]="client"
                                                                        [disabled]="murmur_flag && client"
                                                                        disableOptionCentering
                                                                        [(ngModel)]="femoralwcont">
                                                                        <option value="High-tension">High-tension
                                                                        </option>
                                                                        <option value="Low-tension">Low-tension</option>
                                                                    </select>
                                                                </mat-label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="cover_div">
                                                    <div class="header_lable">
                                                        Popliteal
                                                    </div>
                                                    <div class="content_cover">
                                                        <div class="row">
                                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <mat-label class="matlabel">{{rate_label}}<br>
                                                                    <select class="ipcss  "
                                                                        [required]="poplitrate_required"
                                                                        [disabled]="client" disableOptionCentering
                                                                        [(ngModel)]="poplitrate">
                                                                        <option value="Normal">Normal</option>
                                                                        <option value="&#60; 60 bpm (bradycardia)">&#60;
                                                                            60 bpm
                                                                            (bradycardia)</option>
                                                                        <option value="&#62; 100 bpm (tachycardia)">
                                                                            &#62; 100 bpm
                                                                            (tachycardia)</option>
                                                                    </select>
                                                                </mat-label>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <mat-label class="matlabel">{{rhythmn_label}}<br>
                                                                    <select class="ipcss  "
                                                                        [required]="poplitrhythymn_required"
                                                                        [disabled]="client" disableOptionCentering
                                                                        [(ngModel)]="poplitrhythmn">
                                                                        <option value="Regular">Regular</option>
                                                                        <option value="Regularly irregulat">Regularly
                                                                            irregulat
                                                                        </option>
                                                                        <option value="Irregularly irregular">
                                                                            Irregularly irregular
                                                                        </option>
                                                                        <option value="Slow rising">Slow rising</option>
                                                                        <option value="Waterhammer">Waterhammer</option>
                                                                        <option value="Collapsing">Collapsing</option>
                                                                        <option value="Bisferiens">Bisferiens</option>
                                                                        <option value="Pulsus paradoxus">Pulsus
                                                                            paradoxus</option>
                                                                        <option value="Pulsus alternans">Pulsus
                                                                            alternans</option>
                                                                        <option value="Bounding">Bounding</option>
                                                                    </select>
                                                                </mat-label>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <mat-label class="matlabel">{{volume_label}}<br>
                                                                    <select class="ipcss  "
                                                                        [required]="poplitvol_required"
                                                                        [disabled]="client" disableOptionCentering
                                                                        [(ngModel)]="poplitvolume">
                                                                        <option value="Hyperkinetic">Hyperkinetic
                                                                        </option>
                                                                        <option value="Hypokinetic">Hypokinetic</option>
                                                                        <option value="Pulsus paradoxus">Pulsus
                                                                            paradoxus</option>
                                                                        <option value="reverse pulsus paradoxus">reverse
                                                                            pulsus
                                                                            paradoxus</option>
                                                                        <option value="Pulsus alternans">Pulsus
                                                                            alternans</option>
                                                                        <option value="dicrotic pulse">dicrotic pulse
                                                                        </option>
                                                                    </select>
                                                                </mat-label>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <mat-label class="matlabel">{{wavetense_label}}<br>
                                                                    <select class="ipcss  "
                                                                        [required]="poplitwtense_required"
                                                                        [disabled]="client" disableOptionCentering
                                                                        [(ngModel)]="poplitwtense">
                                                                        <option value="High-tension">High-tension
                                                                        </option>
                                                                        <option value="Low-tension">Low-tension</option>
                                                                    </select>
                                                                </mat-label>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <mat-label class="matlabel">{{wavecont_label}}<br>
                                                                    <select class="ipcss  "
                                                                        [required]="poplitwcont_required"
                                                                        [disabled]="client" disableOptionCentering
                                                                        [(ngModel)]="poplitwcont">
                                                                        <option value="High-tension">High-tension
                                                                        </option>
                                                                        <option value="Low-tension">Low-tension</option>
                                                                    </select>
                                                                </mat-label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="cover_div">
                                                    <div class="header_lable">
                                                        Posterior tibial
                                                    </div>
                                                    <div class="content_cover">
                                                        <div class="row">
                                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <mat-label class="matlabel">{{rate_label}}<br>
                                                                    <select class="ipcss  "
                                                                        [required]="postrate_required"
                                                                        [disabled]="client" disableOptionCentering
                                                                        [(ngModel)]="postrate">
                                                                        <option value="Normal">Normal</option>
                                                                        <option value="&#60; 60 bpm (bradycardia)">&#60;
                                                                            60 bpm
                                                                            (bradycardia)</option>
                                                                        <option value="&#62; 100 bpm (tachycardia)">
                                                                            &#62; 100 bpm
                                                                            (tachycardia)</option>
                                                                    </select>
                                                                </mat-label>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <mat-label class="matlabel">{{rhythmn_label}}<br>
                                                                    <select class="ipcss  "
                                                                        [required]="postrhythymn_required"
                                                                        [disabled]="client" disableOptionCentering
                                                                        [(ngModel)]="postrhythmn">
                                                                        <option value="Regular">Regular</option>
                                                                        <option value="Regularly irregulat">Regularly
                                                                            irregulat
                                                                        </option>
                                                                        <option value="Irregularly irregular">
                                                                            Irregularly irregular
                                                                        </option>
                                                                        <option value="Slow rising">Slow rising</option>
                                                                        <option value="Waterhammer">Waterhammer</option>
                                                                        <option value="Collapsing">Collapsing</option>
                                                                        <option value="Bisferiens">Bisferiens</option>
                                                                        <option value="Pulsus paradoxus">Pulsus
                                                                            paradoxus</option>
                                                                        <option value="Pulsus alternans">Pulsus
                                                                            alternans</option>
                                                                        <option value="Bounding">Bounding</option>
                                                                    </select>
                                                                </mat-label>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <mat-label class="matlabel">{{volume_label}}<br>
                                                                    <select class="ipcss  "
                                                                        [required]="postvol_required"
                                                                        [disabled]="client" disableOptionCentering
                                                                        [(ngModel)]="postvolume">
                                                                        <option value="Hyperkinetic">Hyperkinetic
                                                                        </option>
                                                                        <option value="Hypokinetic">Hypokinetic</option>
                                                                        <option value="Pulsus paradoxus">Pulsus
                                                                            paradoxus</option>
                                                                        <option value="reverse pulsus paradoxus">reverse
                                                                            pulsus
                                                                            paradoxus</option>
                                                                        <option value="Pulsus alternans">Pulsus
                                                                            alternans</option>
                                                                        <option value="dicrotic pulse">dicrotic pulse
                                                                        </option>
                                                                    </select>
                                                                </mat-label>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <mat-label class="matlabel">{{wavetense_label}}<br>
                                                                    <select class="ipcss  "
                                                                        [required]="postwtense_required"
                                                                        [disabled]="client" disableOptionCentering
                                                                        [(ngModel)]="postwtense">
                                                                        <option value="High-tension">High-tension
                                                                        </option>
                                                                        <option value="Low-tension">Low-tension</option>
                                                                    </select>
                                                                </mat-label>

                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <mat-label class="matlabel">{{wavecont_label}}<br>
                                                                    <select class="ipcss  "
                                                                        [required]="postwcont_required"
                                                                        [disabled]="client" disableOptionCentering
                                                                        [(ngModel)]="postwcont">
                                                                        <option value="High-tension">High-tension
                                                                        </option>
                                                                        <option value="Low-tension">Low-tension</option>
                                                                    </select>
                                                                </mat-label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="cover_div">
                                                    <div class="header_lable">
                                                        Dorsails pedis
                                                    </div>
                                                    <div class="content_cover">
                                                        <div class="row">
                                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <mat-label class="matlabel">{{rate_label}}<br>
                                                                    <select class="ipcss  "
                                                                        [required]="dorsalrate_required"
                                                                        [disabled]="client" disableOptionCentering
                                                                        [(ngModel)]="dorsalrate">
                                                                        <option value="Normal">Normal</option>
                                                                        <option value="&#60; 60 bpm (bradycardia)">&#60;
                                                                            60 bpm
                                                                            (bradycardia)</option>
                                                                        <option value="&#62; 100 bpm (tachycardia)">
                                                                            &#62; 100 bpm
                                                                            (tachycardia)</option>
                                                                    </select>
                                                                </mat-label>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <mat-label class="matlabel">{{rhythmn_label}}<br>
                                                                    <select class="ipcss  "
                                                                        [required]="dorsalrhythymn_required"
                                                                        [disabled]="client" disableOptionCentering
                                                                        [(ngModel)]="dorsalrhythmn">
                                                                        <option value="Regular">Regular</option>
                                                                        <option value="Regularly irregulat">Regularly
                                                                            irregulat
                                                                        </option>
                                                                        <option value="Irregularly irregular">
                                                                            Irregularly irregular
                                                                        </option>
                                                                        <option value="Slow rising">Slow rising</option>
                                                                        <option value="Waterhammer">Waterhammer</option>
                                                                        <option value="Collapsing">Collapsing</option>
                                                                        <option value="Bisferiens">Bisferiens</option>
                                                                        <option value="Pulsus paradoxus">Pulsus
                                                                            paradoxus</option>
                                                                        <option value="Pulsus alternans">Pulsus
                                                                            alternans</option>
                                                                        <option value="Bounding">Bounding</option>
                                                                    </select>
                                                                </mat-label>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <mat-label class="matlabel">{{volume_label}}<br>
                                                                    <select class="ipcss  "
                                                                        [required]="dorsalvol_required"
                                                                        [disabled]="client" disableOptionCentering
                                                                        [(ngModel)]="dorsalvolume">
                                                                        <option value="Hyperkinetic">Hyperkinetic
                                                                        </option>
                                                                        <option value="Hypokinetic">Hypokinetic</option>
                                                                        <option value="Pulsus paradoxus">Pulsus
                                                                            paradoxus</option>
                                                                        <option value="reverse pulsus paradoxus">reverse
                                                                            pulsus
                                                                            paradoxus</option>
                                                                        <option value="Pulsus alternans">Pulsus
                                                                            alternans</option>
                                                                        <option value="dicrotic pulse">dicrotic pulse
                                                                        </option>
                                                                    </select>
                                                                </mat-label>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <mat-label class="matlabel">{{wavetense_label}}<br>
                                                                    <select class="ipcss  "
                                                                        [required]="dorsalwtense_required"
                                                                        [disabled]="client" disableOptionCentering
                                                                        [(ngModel)]="dorsalwtense">
                                                                        <option value="High-tension">High-tension
                                                                        </option>
                                                                        <option value="Low-tension">Low-tension</option>
                                                                    </select>
                                                                </mat-label>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <mat-label class="matlabel">{{wavecont_label}}<br>
                                                                    <select class="ipcss  "
                                                                        [required]="dorsalwcont_required"
                                                                        [disabled]="client" disableOptionCentering
                                                                        [(ngModel)]="dorsalwcont">
                                                                        <option value="Fast rising">Fast rising</option>
                                                                        <option value="Slow rising">Slow rising</option>
                                                                    </select>
                                                                </mat-label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="cover_div">
                                                    <div class="header_lable">
                                                        Abdominal aorta
                                                    </div>
                                                    <div class="content_cover">
                                                        <div class="row">
                                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <mat-label class="matlabel">{{rate_label}}<br>
                                                                    <select class="ipcss  "
                                                                        [required]="abdomrate_required"
                                                                        [disabled]="client" disableOptionCentering
                                                                        [(ngModel)]="abdomrate">
                                                                        <option value="Normal">Normal</option>
                                                                        <option value="&#60; 60 bpm (bradycardia)">&#60;
                                                                            60 bpm
                                                                            (bradycardia)</option>
                                                                        <option value="&#62; 100 bpm (tachycardia)">
                                                                            &#62; 100 bpm
                                                                            (tachycardia)</option>
                                                                    </select>
                                                                </mat-label>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <mat-label class="matlabel">{{rhythmn_label}}<br>
                                                                    <select class="ipcss  "
                                                                        [required]="abdomrhythymn_required"
                                                                        [disabled]="client" disableOptionCentering
                                                                        [(ngModel)]="abdomrhythmn">
                                                                        <option value="Regular">Regular</option>
                                                                        <option value="Regularly irregulat">Regularly
                                                                            irregulat
                                                                        </option>
                                                                        <option value="Irregularly irregular">
                                                                            Irregularly irregular
                                                                        </option>
                                                                        <option value="Slow rising">Slow rising</option>
                                                                        <option value="Waterhammer">Waterhammer</option>
                                                                        <option value="Collapsing">Collapsing</option>
                                                                        <option value="Bisferiens">Bisferiens</option>
                                                                        <option value="Pulsus paradoxus">Pulsus
                                                                            paradoxus</option>
                                                                        <option value="Pulsus alternans">Pulsus
                                                                            alternans</option>
                                                                        <option value="Bounding">Bounding</option>
                                                                    </select>
                                                                </mat-label>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <mat-label class="matlabel">{{volume_label}}<br>
                                                                    <select class="ipcss  "
                                                                        [required]="abdomvol_required"
                                                                        [disabled]="client" disableOptionCentering
                                                                        [(ngModel)]="abdomvolume">
                                                                        <option value="Hyperkinetic">Hyperkinetic
                                                                        </option>
                                                                        <option value="Hypokinetic">Hypokinetic</option>
                                                                        <option value="Pulsus paradoxus">Pulsus
                                                                            paradoxus</option>
                                                                        <option value="reverse pulsus paradoxus">reverse
                                                                            pulsus
                                                                            paradoxus</option>
                                                                        <option value="Pulsus alternans">Pulsus
                                                                            alternans</option>
                                                                        <option value="dicrotic pulse">dicrotic pulse
                                                                        </option>
                                                                    </select>
                                                                </mat-label>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <mat-label class="matlabel">{{wavetense_label}}<br>
                                                                    <select class="ipcss  "
                                                                        [required]="abdomwtense_required"
                                                                        [disabled]="client" disableOptionCentering
                                                                        [(ngModel)]="abdomwtense">
                                                                        <option value="High-tension">High-tension
                                                                        </option>
                                                                        <option value="Low-tension">Low-tension</option>
                                                                    </select>
                                                                </mat-label>
                                                            </div>
                                                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                                <mat-label class="matlabel">{{wavecont_label}}<br>
                                                                    <select class="ipcss  "
                                                                        [required]="abdomwcont_required"
                                                                        [disabled]="client" disableOptionCentering
                                                                        [(ngModel)]="abdomwcont">
                                                                        <option value="Fast rising">Fast rising</option>
                                                                        <option value="Slow rising">Slow rising</option>
                                                                    </select>
                                                                </mat-label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <div class="col-12" style="margin-bottom: 5px;">
                        <mat-accordion class="mataccordion">
                            <mat-expansion-panel class="expandpanel">
                                <mat-expansion-panel-header class="exppanel th">
                                    <mat-panel-title class="title ">
                                        Chest pain
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div class="container">
                                    <div class="row top">
                                        <div class="col-12">
                                            <div class="cover_div">
                                                <div class="content_cover">
                                                    <div class="row">
                                                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                                            <mat-label class="matlabel">{{pain_label}}<br>
                                                                <select class="ipcss  " required [(ngModel)]="pain"
                                                                    [disabled]="client" (change)="pain_change()"
                                                                    disableOptionCentering>
                                                                    <option value="Yes">Yes</option>
                                                                    <option value="No">No</option>
                                                                </select>
                                                            </mat-label>
                                                        </div>
                                                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                                            <mat-label class="matlabel">{{location_label}}<br>
                                                                <select class="ipcss  " [required]="loc_required"
                                                                    disableOptionCentering [(ngModel)]="location"
                                                                    [disabled]="pain_flag || client">
                                                                    <option Selected
                                                                        *ngFor="let painloc of cpainloc_array"
                                                                        value="{{painloc.location_desc}}">
                                                                        {{painloc.location_desc}}</option>
                                                                </select>
                                                            </mat-label>
                                                        </div>
                                                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                                            <mat-label class="matlabel">{{character_label}}<br>
                                                                <select class="ipcss  " [required]="char_required"
                                                                    disableOptionCentering [(ngModel)]="character"
                                                                    [disabled]="pain_flag || client">
                                                                    <option *ngFor="let paincar of cpaincar_array"
                                                                        value="{{paincar.character_desc}}">
                                                                        {{paincar.character_desc}}
                                                                    </option>
                                                                </select>
                                                            </mat-label>
                                                        </div>
                                                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                                            <mat-label class="matlabel">{{radiation_label}}<br>
                                                                <select class="ipcss  " [required]="rad_required"
                                                                    disableOptionCentering [(ngModel)]="radiation"
                                                                    [disabled]="pain_flag || client">
                                                                    <option *ngFor="let painrad of cpainrad_array"
                                                                        value="{{painrad.radiation_desc}}">
                                                                        {{painrad.radiation_desc}}
                                                                    </option>
                                                                </select>
                                                            </mat-label>
                                                        </div>
                                                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                                            <mat-label class="matlabel">{{duration_label}}<br>
                                                                <select class="ipcss  " [required]="dur_required"
                                                                    disableOptionCentering [(ngModel)]="duration"
                                                                    [disabled]="pain_flag || client">
                                                                    <option value="&#60;30 mins">&#60;30 mins</option>
                                                                    <option value="&#60;60 mins">&#60;60 mins</option>
                                                                    <option vvalue="&#62;60 mins">&#62;60 mins</option>
                                                                </select>
                                                            </mat-label>
                                                        </div>
                                                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                                            <mat-label class="matlabel">{{aggravat_label}}<br>
                                                                <select class="ipcss  " [required]="aggravat_required"
                                                                    disableOptionCentering [(ngModel)]="aggravat"
                                                                    [disabled]="pain_flag || client">
                                                                    <option *ngFor="let painagrrel of cpainagrel_array"
                                                                        value="{{painagrrel.agrrelfact_desc}}">
                                                                        {{painagrrel.agrrelfact_desc}}
                                                                    </option>
                                                                </select>
                                                            </mat-label>
                                                        </div>
                                                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                                            <mat-label class="matlabel">{{relieve_label}}<br>
                                                                <select class="ipcss  " [required]="relieve_required"
                                                                    disableOptionCentering [(ngModel)]="relieve"
                                                                    [disabled]="pain_flag || client">
                                                                    <option *ngFor="let painagrrel of cpainagrel_array"
                                                                        value="{{painagrrel.agrrelfact_desc}}">
                                                                        {{painagrrel.agrrelfact_desc}}
                                                                    </option>
                                                                </select>
                                                            </mat-label>
                                                        </div>
                                                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                                            <mat-label class="matlabel">{{assosymp_label}}<br>
                                                                <select class="ipcss  " [required]="assosymp_required"
                                                                    disableOptionCentering [(ngModel)]="assosymp"
                                                                    [disabled]="pain_flag || client">
                                                                    <option *ngFor="let painasympt of cpainasympt_array"
                                                                        value="{{painasympt.asssympt_desc}}">
                                                                        {{painasympt.asssympt_desc}}
                                                                    </option>
                                                                </select>
                                                            </mat-label>
                                                        </div>
                                                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                                            <mat-label class="matlabel">{{intensity_label}}<br>
                                                                <select class="ipcss  " [required]="intensity_required"
                                                                    disableOptionCentering [(ngModel)]="intensity"
                                                                    [disabled]="pain_flag || client">
                                                                    <option value="1">1</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                    <option value="4">4</option>
                                                                    <option value="5">5</option>
                                                                    <option value="6">6</option>
                                                                    <option value="7">7</option>
                                                                    <option value="8">8</option>
                                                                    <option value="9">9</option>
                                                                    <option value="10">10</option>
                                                                </select>
                                                            </mat-label>
                                                        </div>
                                                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                                            <mat-label class="matlabel">{{paincause_label}}<br>
                                                                <select class="ipcss  " [required]="paincause_required"
                                                                    disableOptionCentering [(ngModel)]="paincause"
                                                                    [disabled]="pain_flag || client">
                                                                    <option *ngFor="let painc of cpaincause_array"
                                                                        value="{{painc.cause_desc}}">
                                                                        {{painc.cause_desc}}</option>
                                                                </select>
                                                            </mat-label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class=" col-12 " *ngIf="!client">
                                            <a style="float: right;"><img
                                                    src="../../../assets/ui_icons/buttons/history.svg"
                                                    class="saveimgbtn_inpatinfo" (click)="pain_history()" /></a>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <div class="col-12" style="margin-bottom: 5px;">
                        <mat-accordion class="mataccordion">
                            <mat-expansion-panel class="expandpanel">
                                <mat-expansion-panel-header class="exppanel th">
                                    <mat-panel-title class="title ">
                                        Breathing difficulties
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div class="container">
                                    <div class="row top">
                                        <div class="col-12">
                                            <div class="cover_div">
                                                <div class="content_cover">
                                                    <div class="row">
                                                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                                            <mat-label class="matlabel">{{breathlessness_label}}<br>
                                                                <mat-select class="ipcss  " required [(ngModel)]="breathlessness"
                                                                    [disabled]="client" disableOptionCentering
                                                                    (selectionChange)="breath_change()">
                                                                    <mat-option value="Yes">Yes</mat-option>
                                                                    <mat-option value="No">No</mat-option>
                                                                </mat-select>
                                                            </mat-label>
                                                        </div>
                                                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                                            <mat-label class="matlabel">{{triggers_label}}<br>
                                                                <select class="ipcss  " [required]="trig_required"
                                                                    disableOptionCentering [(ngModel)]="triggers"
                                                                    [disabled]="trig_flag || client">
                                                                    <option *ngFor="let btrig of btrig_array"
                                                                        value="{{btrig.triggers_desc}}">{{btrig.triggers_desc}}</option>
                                                                </select>
                                                            </mat-label>
                                                        </div>
                                                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                                            <mat-label class="matlabel">{{onset_label}}<br>
                                                                <select class="ipcss " [required]="onset_required"
                                                                    disableOptionCentering [(ngModel)]="onset"
                                                                    [disabled]="trig_flag || client">
                                                                    <option *ngFor="let bonset of bonset_array"
                                                                        value="{{bonset.onset_desc}}">{{bonset.onset_desc}}</option>
                                                                </select>
                                                            </mat-label>
                                                        </div>
                                                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                                            <mat-label class="matlabel">{{coldsweats_label}}<br>
                                                                <select class="ipcss  " [required]="coldsweat_required"
                                                                    disableOptionCentering [(ngModel)]="coldsweats"
                                                                    [disabled]="trig_flag || client">
                                                                    <option value="Yes">Yes</option>
                                                                    <option value="No">No</option>
                                                                </select>
                                                            </mat-label>
                                                        </div>
                                                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                                            <mat-label class="matlabel">{{dizziness_label}}<br>
                                                                <select class="ipcss  " [required]="dizziness_required"
                                                                    disableOptionCentering [(ngModel)]="dizziness"
                                                                    [disabled]="trig_flag || client">
                                                                    <option value="Dizziness">Dizziness</option>
                                                                    <option value="Weekness">Weekness</option>
                                                                </select>
                                                            </mat-label>
                                                        </div>
                                                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                                            <mat-label class="matlabel">{{vomiting_label}}<br>
                                                                <select class="ipcss  " [required]="vomiting_required"
                                                                    disableOptionCentering [(ngModel)]="vomiting"
                                                                    [disabled]="trig_flag || client">
                                                                    <option value="Yes">Yes</option>
                                                                    <option value="No">No</option>
                                                                </select>
                                                            </mat-label>
                                                        </div>
                                                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                                            <mat-label class="matlabel">{{palpitation_label}}<br>
                                                                <select class="ipcss  " [required]="palpitation_required"
                                                                    disableOptionCentering [(ngModel)]="palpitation"
                                                                    [disabled]="trig_flag || client">
                                                                    <option value="Yes">Yes</option>
                                                                    <option value="No">No</option>
                                                                </select>
                                                            </mat-label>
                                                        </div>
                                                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                                            <mat-label class="matlabel">{{palptype_label}}<br>
                                                                <select class="ipcss  " [required]="palptype_required"
                                                                    disableOptionCentering [(ngModel)]="palptype"
                                                                    [disabled]="trig_flag || client">
                                                                    <option value="{{palptypes.palp_type_desc}}"
                                                                        *ngFor="let palptypes of palp_array">
                                                                        {{palptypes.palp_type_desc}}
                                                                    </option>
                                                                </select>
                                                            </mat-label>
                                                        </div>
                                                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                                            <mat-label class="matlabel">{{cough_label}}<br>
                                                                <select class="ipcss  " [required]="cough_required"
                                                                    disableOptionCentering [(ngModel)]="cough"
                                                                    [disabled]="trig_flag || client">
                                                                    <option value="Yes">Yes</option>
                                                                    <option value="No">No</option>
                                                                </select>
                                                            </mat-label>
                                                        </div>
                                                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                                            <mat-label class="matlabel">{{mucus_label}}<br>
                                                                <select class="ipcss  " [required]="mucus_required"
                                                                    disableOptionCentering [disabled]="client" [(ngModel)]="mucus"
                                                                    [disabled]="trig_flag || client">
                                                                    <option *ngFor="let mucuse of mucus_array"
                                                                        value="{{mucuse.mucus_desc}}">
                                                                        {{mucuse.mucus_desc}}</option>
                                                                </select>
                                                            </mat-label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class=" col-12" *ngIf="!client">
                                            <a style="float: right;"><img
                                                    src="../../../assets/ui_icons/buttons/history.svg"
                                                    class="saveimgbtn_inpatinfo" [disabled]="client"
                                                    (click)="breath_history()" /></a>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <div class="col-12" style="margin-bottom: 5px;">
                        <mat-accordion class="mataccordion">
                            <mat-expansion-panel class="expandpanel">
                                <mat-expansion-panel-header class="exppanel th">
                                    <mat-panel-title class="title ">
                                        Physical
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <hr class="hr">
                                <div class="container">
                                    <div class="row top">
                                        <div class="col-12">
                                            <div class="cover_div">
                                                <div class="content_cover">
                                                    <div class="row">
                                                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                                            <mat-label class="matlabel">{{eyes_label}}<br>
                                                                <select class="ipcss  " required [(ngModel)]="eyes" [disabled]="client"
                                                                    disableOptionCentering required>
                                                                    <option value="Normal">Normal</option>
                                                                    <option value="Bulge">Bulge</option>
                                                                    <option value="Deep">Deep</option>
                                                                </select>
                                                            </mat-label>
                                                        </div>
                                                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                                            <mat-label class="matlabel">{{nails_label}}<br>
                                                                <select class="ipcss  " required [(ngModel)]="nails" [disabled]="client"
                                                                    disableOptionCentering required>
                                                                    <option value="Normal">Normal</option>
                                                                    <option value="Peripheral cyanosis">Peripheral cyanosis</option>
                                                                    <option value="clubbing">Clubbing</option>
                                                                    <option value="Splinter haemorrhages">Splinter haemorrhages</option>
                                                                    <option value="Koilonychia">Koilonychia</option>
                                                                </select>
                                                            </mat-label>
                                                        </div>
                                                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                                            <mat-label class="matlabel">{{liptongue_label}}<br>
                                                                <select class="ipcss  " required [(ngModel)]="liptongue"
                                                                    [disabled]="client" disableOptionCentering>
                                                                    <option value="Normal">Normal</option>
                                                                    <option value="Abnormal">Abnormal</option>
                                                                </select>
                                                            </mat-label>
                                                        </div>
                                                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                                            <mat-label class="matlabel">{{neck_label}}<br>
                                                                <select class="ipcss  " required [(ngModel)]="neck" [disabled]="client"
                                                                    disableOptionCentering required>
                                                                    <option value="Normal">Normal</option>
                                                                    <option value="Abnormal">Abnormal</option>
                                                                </select>
                                                            </mat-label>
                                                        </div>
                                                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                                            <mat-label class="matlabel">{{face_label}}<br>
                                                                <select class="ipcss  " required [(ngModel)]="face" [disabled]="client"
                                                                    disableOptionCentering required>
                                                                    <option value="Normal">Normal</option>
                                                                    <option value="Malar flush">Malar flush</option>
                                                                    <option value="swollen">swollen</option>
                                                                </select>
                                                            </mat-label>
                                                        </div>
                                                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                                            <mat-label class="matlabel">{{precordium_label}}<br>
                                                                <select class="ipcss  " required [(ngModel)]="precordium"
                                                                    [disabled]="client" disableOptionCentering>
                                                                    <option value="Normal">Normal</option>
                                                                    <option value="Abnormal">Abnormal</option>
                                                                </select>
                                                            </mat-label>
                                                        </div>
                                                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                                            <mat-label class="matlabel">{{hand_arm_label}}<br>
                                                                <select class="ipcss  " disableOptionCentering [(ngModel)]="hand_arm"
                                                                    [disabled]="client" required>
                                                                    <option value="Normal">Normal</option>
                                                                    <option value="Venous obstruction">Venous obstruction</option>
                                                                    <option value="Osler nodes">Osler nodes</option>
                                                                    <option value="Janewaylesions">Janewaylesions</option>
                                                                </select>
                                                            </mat-label>
                                                        </div>
                                                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                                            <mat-label class="matlabel">{{abdomen_label}}<br>
                                                                <select class="ipcss  " required [(ngModel)]="abdomen"
                                                                    [disabled]="client" disableOptionCentering>
                                                                    <option value="Normal">Normal</option>
                                                                    <option value="Abnormal">Abnormal</option>
                                                                </select>
                                                            </mat-label>
                                                        </div>
                                                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                                            <mat-label class="matlabel">{{lowerlimb_label}}<br>
                                                                <select class="ipcss  " required [(ngModel)]="lowerlimb"
                                                                    [disabled]="client" disableOptionCentering>
                                                                    <option value="Normal">Normal</option>
                                                                    <option value="Peripheral edema">Peripheral edema</option>
                                                                    <option value="vericose veins">vericose veins</option>
                                                                </select>
                                                            </mat-label>
                                                        </div>
                                                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                                            <mat-label class="matlabel">{{caprefill_label}}<br>
                                                                <select class="ipcss  " required [(ngModel)]="caprefill"
                                                                    [disabled]="client" disableOptionCentering>
                                                                    <option value="1 sec"> 1 sec</option>
                                                                    <option value="2 sec"> 2 sec</option>
                                                                    <option value="3 sec"> 3 sec</option>
                                                                    <option value="&#62; 3 sec">&#62; 3 sec</option>
                                                                </select>
                                                            </mat-label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class=" col-12 " *ngIf="!client">
                                            <a style="float: right;"><img
                                                    src="../../../assets/ui_icons/buttons/history.svg"
                                                    class="saveimgbtn_inpatinfo" (click)="phy_history()" /></a>

                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="History" *ngIf="client">
            <table class="table table-hover table-dynamic">
                <tr>
                    <th>Date</th>
                    <th>Stress Factor</th>
                    <th>Heart Sound</th>
                    <th>murmurs </th>
                    <th>bp_right </th>
                    <th>bp_left </th>
                    <th></th>
                </tr>
                <tr *ngFor="let list of clinical_list">
                    <td>{{list.created_date}}</td>
                    <td style="text-align: left;">{{list.stress_factor}}</td>
                    <td>{{list.heart_sound}}</td>
                    <td>{{list.murmurs}}</td>
                    <td>{{list.bp_right}}</td>
                    <td>{{list.bp_left}}</td>
                    <td><a (click)="SelectClinicallist()">view rx</a></td>
                </tr>
            </table>
        </mat-tab>
    </mat-tab-group>
</div>
