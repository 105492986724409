import { Component, OnInit } from '@angular/core';
import { ipaddress } from '../../../ipaddress';
import { Http, Headers } from '@angular/http';
import { Helper_Class } from '../../../../app/helper_class';
import { pharmacy_helper } from '../../../pharma/Pharmcy_Helper';
import { Date_Formate } from '../../../../assets/js/common';
// import { PharmacyService } from '../../../pharmacy/pharmacy.service';
import { PharmacyService } from 'src/app/pharma/pharmacy/pharmacy.service';
import { ToastrService } from 'ngx-toastr';
import { Message_data } from 'src/assets/js/Message_data';
// import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
import { MenuViewService } from '../../../common-module/menu-view/menu-view.service';

@Component({
  selector: 'app-purchaserequestlist',
  templateUrl: './purchaserequestlist.component.html',
  styleUrls: ['./purchaserequestlist.component.scss']
})
export class PurchaserequestlistComponent implements OnInit {
  public ipaddress:any;
  public getinfo;
  public pharmacistID:any;
  public pharmacyID:any;
  public requestList:any=[];
  public requestArray:any=[];
  public po:any;
  public poDate:any;
  public poapproved;
  public checkboxandbutton:boolean;
  public prarray:any=[];
  public accepted:boolean;
  public po_approver;
  public editbutton;
  public newbutton;
  public deletebutton;
  public printbutton;
  public viewbutton;

  constructor(public http: Http,public messageservice:MenuViewService ,public toastr: ToastrService) { }

  ngOnInit(): void {
    this.ipaddress = Helper_Class.getIPAddress();
    this.getinfo = Helper_Class.getInfo()
    if (Helper_Class.getInfo().user_type == 'Admin') {
      this.pharmacyID = Helper_Class.getInfo().pharma_id;
      this.poapproved = Helper_Class.getInfo().indent_approval_req;
      this.po_approver= Helper_Class.getInfo().po_approver;
    }
    else{
      this.pharmacyID = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
      this.poapproved = Helper_Class.getInfo().hospitals[0].indent_approval_req;
      this.po_approver= Helper_Class.getInfo().hospitals[0].po_approver;
    }
    console.log("po approved ="+this.poapproved)
    if(this.poapproved == "1"){
      this.checkboxandbutton=true
    }else
    {
      this.checkboxandbutton=false
    }
    this.pharmacistID = this.getinfo.user_id;

    // for (var i = 0; i < this.getinfo.hospitals.length; i++) {
    //   this.pharmacyID = this.getinfo.hospitals[0].hptl_clinic_id;
    // }
    this.purchaserequestlist();
    if(this.getinfo.user_type == "Admin"){
      this.editbutton=true;
      this.newbutton=true;
      this.deletebutton=true;
      this.printbutton=true;
      this.viewbutton=true;
    }else if(this.getinfo.user_type == "diagnosis"){
      this.editbutton=true;
      this.newbutton=true;
      this.deletebutton=true;
      this.printbutton=true;
      this.viewbutton=true;
    }
    else{
    if(Helper_Class.getmodulelist() != undefined){
      for (var i = 0; i < Helper_Class.getmodulelist().length; i++) {
        if (Helper_Class.getmodulelist()[i].module_id == "55"){
            if(Helper_Class.getmodulelist()[i].edit == "1"){
              this.editbutton=true;
            }
            if(Helper_Class.getmodulelist()[i].create == "1"){ 
              this.newbutton=true;
            }
            if(Helper_Class.getmodulelist()[i].delete == "1"){
              this.deletebutton=true;
            }
            if(Helper_Class.getmodulelist()[i].print == "1"){
              this.printbutton=true;
            }
            if(Helper_Class.getmodulelist()[i].view == "1"){
              this.viewbutton=true;
            }
          }

  }
}
    }
}
// Note work in Admin: its static , temp "pharmacist_id: '17'" becuse data saved depends on "user id"
  
  purchaserequestlist(){
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "inv/gprs", JSON.stringify({ 
      // pharmacist_id: this.pharmacistID
      pharma_id:this.pharmacyID
      , imei: this.ipaddress 
    }), { headers: headers }).subscribe(
      data => {
        console.log("order list one "+JSON.stringify({ pharmacist_id: this.pharmacistID, imei: this.ipaddress }))
        console.log("order list = "+JSON.stringify(data.json()))
        if(data.json().purchase_requests != null){
          var requestlist=data.json().purchase_requests
          console.log("data = "+JSON.stringify(requestlist))
          for(let i=0;i < requestlist.length;i++){
            var status = requestlist[i].status == "0" ? "Po issued" : "Open";
            this.requestArray.push({
              plr_id:requestlist[i].purchase_request_id,
              plr_date: Date_Formate(requestlist[i].created_date),
              department:requestlist[i].department_desc,
              storename:requestlist[i].store_desc,
              product_list:requestlist[i].product_list || [],
              status:status,
              amount:requestlist[i].amount
            })
          }
        }
      },
      error => { });
  }

  requestlistview_details(id){ 
    console.log("request list")
  }

  purchaserequest_view(){
    
    // this.vendor = "";
    // this.purchaseAddressArray = "";
    // this.poBase = "";
    // this.details = true;
    // this.orderListFlag = false;
    // this.allow = false;
    // this.taxAllow = false;
    // this.a = false;
    // this.notAllow = false;
    // var data = null;
    pharmacy_helper.setpoid("");
    pharmacy_helper.setpono("");
    pharmacy_helper.setfromreqlist("frompurchasereq");
    this.messageservice.sendMessage("pharmacyrequest");

    console.log("purchase list")
  
  }
 
  checkeddata(item){
   
    console.log("item 1 ="+JSON.stringify(item))

    var id=item.plr_id
this.prarray.push(
  item.plr_id
)
console.log("item 2 ="+JSON.stringify(this.prarray))
  }
 
 
  approved(){
  var sendarraydata={
    pharmacist_id: this.pharmacyID,
    country: ipaddress.country_code,
    purchase_requests:this.prarray,
    approver:this.po_approver
  }
  console.log("seneddata array to backend"+JSON.stringify(sendarraydata))
    var headers=new Headers;
    headers.append('content-Type','application/json');
    this.http.post(ipaddress.getIp.toString() + "pharmacontrol/upr", 
    JSON.stringify({ pharmacy_id: this.pharmacyID,country: ipaddress.country_code,purchase_requests:this.prarray,approver:this.po_approver}),
    {headers:headers}
    ).subscribe(
      response =>{
        // var obj = JSON.parse(JSON.stringify(response));
        // this.accepted=false;
        console.log(" Purchaserequest list = "+JSON.stringify(obj))
        var obj = JSON.parse(response["_body"]);
        // var obj2=response._body
        // this.reorderdata.forEach(order => (order.checked = false));
        console.log("RESULT FOR APPROVED = "+JSON.stringify(obj))
        if (obj != undefined && obj.key == "1") {
          // this.saveFlag = false;
          setTimeout(() => {
            this.toastr.success(Message_data.purchaseordersucess);
          }, 3000);
          this.prarray=[];
          this.accepted=false;
          // Display toastr for 3 seconds
          this.requestArray=[];
          this.purchaserequestlist();
          // this.messageservice.sendMessage("pharmacycurentinformation");
        } 
        else 
        {
          // this.saveFlag = true;
          if(this.prarray.length == 0){
            this.toastr.error("Select atleast one order");
            setTimeout(() => {
              this.toastr.clear();
            }, 3000); // Display toastr for 3 seconds
          }else{
            this.toastr.error(Message_data.purchaseordererror);
            setTimeout(() => {
              this.toastr.clear();
            }, 3000); // Display toastr for 3 seconds
          }
        }
      },
      error=>{
        console.log("error in retriving the stock reorder")
      }
    )
  
  
  }
  
}
