<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Create package</h5>
                    </div>
                    <div class="headerButtons">
                        <a (click)="back()"><img src="../../../assets/ui_icons/buttons/Back_button.svg"
                                style="width: 85px;" /></a>
                        <a *ngIf="saveFlag" (click)="save()"><img src="../../../assets/ui_icons/buttons/save_button.svg"
                                style="width: 85px;" /></a>
                        <a *ngIf="updateFlag" (click)="update()"><img
                                src="../../../assets/ui_icons/buttons/update_button.svg" style="width: 85px;" /></a>
                        <a *ngIf="diagApp" (click)="saveDiagApp()"><img src="../../../assets/ui_icons/buttons/Book_now.svg" style="width: 85px;" /></a>
                        <!-- <a *ngIf="bookAppointment" (click)="saveBookAppointment()"><img src="../../../assets/ui_icons/buttons/Book_now.svg" style="width: 85px;" /></a> -->
                        <!-- <div *ngIf="createFlag"><a (click)="digBillCreate()" ><img src="../../../assets/ui_icons/Add_icon.svg" class="pluseIcon"></a></div> -->
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="cover_div" style="padding-bottom: 13px;height: 175px;" *ngIf="userAppintment">
                    <div class="header_lable">Appointment details</div>
                    <div class="content_cover">
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
                                <div class="row">
                                    <div class="col-12" class="clientCover">
                                        <div class="clientLable"> Client name :</div>
                                        <div>{{clientName}}</div>
                                    </div>
                                    <div class="col-12" class="clientCover">
                                        <div class="clientLable"> Mobile no :</div>
                                        <div>{{getData.content.mobile}}</div>
                                    </div>
                                    <div class="col-12" class="clientCover">
                                        <div class="clientLable"> Address : </div>
                                        <div> {{clientAddress}} 
                                            <span *ngIf="getData.content.location != undefined">,{{getData.content.location}},</span><br>
                                            <span *ngIf="getData.content.city != undefined"> {{getData.content.city}}</span> 
                                            <span *ngIf="getData.content.state != undefined"> ,{{getData.content.state}}</span>
                                            <span *ngIf="getData.content.country != undefined"> ,{{getData.content.country}}</span> 
                                            <span *ngIf="getData.content.zipcode != undefined"> - {{getData.content.zipcode}}.</span> </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-8 col-xxl-8" *ngIf="diagApp">
                                <div class="row">
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">Booking date
                                            <input type="date" class="ipcss" [(ngModel)]="appDate"
                                                min="{{currentDate}}">
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">Booking Session
                                            <select class="ipcss" [(ngModel)]="visitSession" (change)="selectSession()">
                                                <option *ngFor="let session of getVisitingSessionArray">
                                                    {{session.description}}
                                                </option>
                                            </select>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Time</mat-label>
                                        <div class="row">
                                            <div class="col-6" style="padding-right: 0px;">
                                                <select class="ipcss  " [(ngModel)]="startTime">
                                                    <option *ngFor="let name of sessionTimeStart" value="{{name}}">
                                                        {{name}}</option>
                                                </select>
                                            </div>
                                            <div class="col-6" style="padding-right: 0px;">
                                                <select class="ipcss " [(ngModel)]="endTime">
                                                    <option *ngFor="let name of sessionTimeEnd" value="{{name}}">
                                                        {{name}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-8 col-xxl-8" *ngIf="!diagApp">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="switch-field" *ngIf="ynopt1 != undefined">
                                            <input type="radio" id="radio-three" (click)="changeType(1,'doctor')" />
                                            <label for="radio-three" [ngClass]="ynopt1 == 'doctor' ? 'radio_active':'radio_inactive'">
                                                <div class="tab_cover1">
                                                    <div class="tab_icon_cover"><img
                                                            src="../../../assets/ui_icons/Doctor.svg" class="iocinStyle"
                                                            alt=""></div>
                                                    <div class="lableStyle">Doctor</div>
                                                </div>
                                            </label>
                                            <input type="radio" id="radio-four" (click)="changeType(1,'diagnosis')" />
                                            <label for="radio-four" [ngClass]="ynopt1 == 'diagnosis' ? 'radio_active':'radio_inactive'">
                                                <div class="tab_cover1">
                                                    <div class="tab_icon_cover"><img src="../../../assets/ui_icons/diagnosis.svg" class="iocinStyle" alt=""></div>
                                                    <div class="lableStyle">Diagnosis</div>
                                                </div>
                                            </label>
                                            <input type="radio" id="radio-five" (click)="changeType(1,'dietician')" />
                                            <label for="radio-five" [ngClass]="ynopt1 == 'dietician' ? 'radio_active':'radio_inactive'">
                                                <div class="tab_cover1">
                                                    <div class="tab_icon_cover">
                                                        <img src="../../../assets/ui_icons/Dietician_icon_svg.svg"   class="iocinStyle" alt=""></div>
                                                    <div class="lableStyle">Dietician</div>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="ynopt1 == 'diagnosis'">
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">Booking date
                                            <input type="date" class="ipcss" [(ngModel)]="appDate"
                                                min="{{currentDate}}">
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">Booking Session
                                            <select class="ipcss" [(ngModel)]="visitSession" (change)="selectSession()">
                                                <option *ngFor="let session of getVisitingSessionArray">
                                                    {{session.description}}
                                                </option>
                                            </select>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Time</mat-label>
                                        <div class="row">
                                            <div class="col-6" style="padding-right: 0px;">
                                                <select class="ipcss  " [(ngModel)]="startTime">
                                                    <option *ngFor="let name of sessionTimeStart" value="{{name}}">
                                                        {{name}}</option>
                                                </select>
                                            </div>
                                            <div class="col-6" style="padding-right: 0px;">
                                                <select class="ipcss " [(ngModel)]="endTime">
                                                    <option *ngFor="let name of sessionTimeEnd" value="{{name}}">
                                                        {{name}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <a (click)="saveDiagApp()"><img src="../../../assets/ui_icons/buttons/Book_now.svg" style="width: 85px;" /></a>
                                </div>
                                <div class="row" *ngIf="ynopt1 == 'doctor'">
                                    <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                                        <mat-label class="matlabel">Specialization<br>
                                            <select id="mandatory_specfication" class="ipcss widthappt"
                                                [(ngModel)]="splName" (change)="getDoctorList()">
                                                <option *ngFor="let spl of splList" value="{{spl.spl_name}}"> {{spl.spl_name}}</option>
                                            </select>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-3">
                                        <mat-label class="matlabel">Doctor<br>
                                            <select class="ipcss widthappt" [(ngModel)]="doctorId" required
                                                (change)="getDoctorDetails()">
                                                <option *ngFor="let doctor of doctorList" value="{{doctor.prov_id}}">
                                                    {{doctor.docname}}
                                                </option>
                                            </select>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">Booking date
                                            <input type="date" class="ipcss" [(ngModel)]="appDate"
                                                min="{{currentDate}}">
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">Booking Session
                                            <select class="ipcss" [(ngModel)]="visitSession" (change)="selectSession()">
                                                <option *ngFor="let session of getVisitingSessionArray">
                                                    {{session.description}}
                                                </option>
                                            </select>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Time</mat-label>
                                        <div class="row">
                                            <div class="col-6" style="padding-right: 0px;">
                                                <select class="ipcss  " [(ngModel)]="startTime">
                                                    <option *ngFor="let name of sessionTimeStart" value="{{name}}">
                                                        {{name}}</option>
                                                </select>
                                            </div>
                                            <div class="col-6" style="padding-right: 0px;">
                                                <select class="ipcss " [(ngModel)]="endTime">
                                                    <option *ngFor="let name of sessionTimeEnd" value="{{name}}">
                                                        {{name}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <a (click)="bookingConfirm()"><img src="../../../assets/ui_icons/buttons/Book_now.svg" style="width: 85px;position: absolute;  bottom: 24px;
                                        right: 18px;" /></a>
                                </div>
                                <div class="row" *ngIf="ynopt1 == 'dietician'">
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 select_bottom">
                                        <mat-label class="matlabel">Doctor<br>
                                            <select class="ipcss widthappt" (ngModelChange)="changeDoctor($event)"
                                                [(ngModel)]="doctor">
                                                <option *ngFor="let doctors of doctorList"
                                                    value={{doctors.Doc_Name}}>{{doctors.Doc_Name}}
                                                </option>
                                            </select>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
                                        <mat-label class="matlabel">Booking date
                                            <input type="date" class="ipcss" [(ngModel)]="appDate"
                                                min="{{currentDate}}">
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Time</mat-label>
                                        <div class="row">
                                            <div class="col-6" style="padding-right: 0px;">
                                                <select class="ipcss  " [(ngModel)]="startTime">
                                                    <option *ngFor="let name of sessionTimeStart" value="{{name}}">
                                                        {{name}}</option>
                                                </select>
                                            </div>
                                            <div class="col-6" style="padding-right: 0px;">
                                                <select class="ipcss " [(ngModel)]="endTime">
                                                    <option *ngFor="let name of sessionTimeEnd" value="{{name}}">
                                                        {{name}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                        <mat-label class="matlabel">Purpose of visit
                                            <input type="text" class="ipcss noappt_width" required [(ngModel)]="dietTypeDesc"
                                                (keyup)="getTypes()" matInput />
                                            <div class="auto_complete_text" *ngIf="dietTypeList.length != 0">
                                                <ul *ngFor="let diet of dietTypeList">
                                                    <li>
                                                        <a (click)="selectPurposeOfVisit(diet)">{{diet.description}}</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </mat-label>
                                    </div>
                                    <a (click)="bookingConfirm()"><img src="../../../assets/ui_icons/buttons/Book_now.svg" style="width: 85px;position: absolute;  bottom: 24px;
                                        right: 18px;" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cover_div" style="padding-bottom: 13px;height: 270px; margin-top: -15px !important;">
                    <div class="header_lable">Package details</div>
                    <div class="content_cover">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <div class="row">
                                    <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-4  select_bottom">
                                        <!-- (change)="changePayType()" -->
                                        <mat-label class="matlabel">Package type<br>
                                            <select class="ipcss " [(ngModel)]="packageType" required
                                                disableOptionCentering>
                                                <option *ngFor="let package of packageArray"
                                                    value={{package.package_type_id}}>
                                                    {{package.description}}</option>
                                            </select>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-4 select_bottom">
                                        <mat-label class="matlabel">Package name<br>
                                            <input class="ipcss  widthbillcreate" required matInput
                                                [(ngModel)]="packageName" />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-4 select_bottom">
                                        <mat-label class="matlabel">Price<br>
                                            <input class="ipcss  widthbillcreate" required matInput
                                                [(ngModel)]="packagePrice" />
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                                        <mat-label class="matlabel">Short description<br>
                                            <textarea maxlength="250" class="ipcss txtarea " required
                                                ng-reflect-maxlength="250" style="height: 101px;"
                                                [(ngModel)]="packageShortDesc"></textarea>
                                        </mat-label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-4"
                                        style="position: relative;">
                                        <input type="file" class="ipcss" (change)="fileChange1($event)" name="filUpload"
                                            id="file1" />
                                        <label class="closeoption" (change)="fileChange1($event)" for="file1"
                                            style="position: absolute;top: 39px;left: 16%;width: 100%;"><span
                                                class="excr_confirm matlabel"><i class="fa fa-upload"
                                                    aria-hidden="true"></i> Upload Icon</span> </label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                                        <img *ngIf="packageImage != undefined && packageImage != null"
                                            [src]="packageImage" id="packageImage"
                                            style="width: 40px;height: 40px;top: 27px;position: relative;text-align: center;">
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 select_bottom">
                                <mat-label class="matlabel">Long description<br>
                                    <quill-editor id="editor" [(ngModel)]="packageLongDesc" [style]="editorStyle"
                                        [modules]="modules" placeholder="Enter Text"
                                        (onContentChanged)="onContentChanged($event)"></quill-editor>
                                </mat-label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" style="margin: 15px 0 0 0;">
                    <div class="col-4">
                        <ul class="nav nav-tabs nav-fill" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class="nav-link " id="diet-tab" data-bs-toggle="tab" data-bs-target="#diet"
                                    type="button" role="tab" aria-controls="diet" aria-selected="true"
                                    (click)="changetab('diet')">
                                    <div class="tab_cover">
                                        <div class="tab_icon_cover"><img src="../../../assets/ui_icons/Add_icon.svg"
                                                class="iocinStyle" alt=""></div>
                                        <div class="lableStyle"> Features </div>
                                    </div>
                                </button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link " id="prognosis-tab" data-bs-toggle="tab"
                                    data-bs-target="#prognosis" type="button" role="tab" aria-controls="prognosis"
                                    aria-selected="true" (click)="changetab('prognosis')">
                                    <div class="tab_cover">
                                        <div class="tab_icon_cover"><img src="../../../assets/ui_icons/Add_icon.svg"
                                                class="iocinStyle" alt=""></div>
                                        <div class="lableStyle">Test</div>
                                    </div>
                                </button>
                            </li>

                        </ul>
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane" id="diet" role="tabpanel" aria-labelledby="home-tab"
                                *ngIf="diet_table">
                                <div class="row ">
                                    <div class="col-11">
                                        <div class="row therapyCover">
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                                style="position: relative;">
                                                <input type="file" class="ipcss" (change)="fileChange($event)"
                                                    name="filUpload" id="file" />
                                                <label class="closeoption" (change)="fileChange($event)" for="file"
                                                    style="position: absolute;top: 48%;left: 31%;width: 100%;"><span
                                                        class="excr_confirm matlabel"><i class="fa fa-upload"
                                                            aria-hidden="true"></i> Upload Icon</span> </label>
                                            </div>
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                                style="position: relative;">
                                                <mat-label class="matlabel">Feature<br>
                                                    <input class="ipcss  widthbillcreate" required matInput [(ngModel)]="offerTitle" 
                                                    (keyup)="getCharges(offerTitle)" [matAutocomplete]="auto1"/>
                                                    <mat-autocomplete #auto1="matAutocomplete">
                                                        <mat-option (click)="selectedCharge(charge)"
                                                            *ngFor="let charge of chargeList"
                                                            value="{{charge.charge_desc}}">
                                                            {{charge.charge_desc}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </mat-label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-1 my-auto" style="text-align: center;">
                                        <a *ngIf="!disableFlag" (click)="add('offers')"><img
                                                src="../../../assets/img/pagination_next.png"
                                                class="saveimgbtn_inpatinfo" /></a>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane" id="prognosis" role="tabpanel" aria-labelledby="home-tab"
                                *ngIf="prognosis_table">
                                <div class="row ">
                                    <div class="col-11">
                                        <div class="row therapyCover">
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                                                style="position: relative;">
                                                <mat-label class="matlabel">Test Type
                                                    <select class="ipcss" required [(ngModel)]="diagTest" (change)="diagTestChange()">
                                                        <option *ngFor="let ttype of testListArray" value={{ttype.diag_department_id}}>{{ttype.diag_dept_desc}}</option>
                                                    </select>
                                                </mat-label>
                                            </div>
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 "
                                                *ngIf="testNameArray.length != 0">
                                                <mat-label class="matlabel">Test</mat-label>
                                                <input type="text" class="ipcss inpat_width" [(ngModel)]="test_name"
                                                    (keyup)="getTest(test_name)" required matInput />
                                                <div class="scroll_checkbox test_box" id="Div8">
                                                    <table>
                                                        <tr *ngFor="let tests of testNameArray">
                                                            <td *ngIf="!tests.show">
                                                                <mat-checkbox color="primary"
                                                                    (change)="clickTest(tests.checked,tests.test_id,tests)"
                                                                    [(ngModel)]="tests.checked">
                                                                </mat-checkbox>
                                                            </td>
                                                            <td *ngIf="!tests.show" style="text-align:left;"><span
                                                                    class="test">{{tests.test_name}}</span></td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-1 my-auto" style="text-align: center;">
                                        <a *ngIf="!disableFlag" (click)="add('test')"><img
                                                src="../../../assets/img/pagination_next.png"
                                                class="saveimgbtn_inpatinfo" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-8 ">
                        <div class="table_cover " *ngIf="diet_table" style="margin-top: 39px;">
                            <div class="col-12" style="margin-top: 5px; height: 160px;">
                                <div *ngIf="offerArray.length == 0" class="nodata">No offers found</div>
                                <div *ngIf="offerArray.length != 0">
                                    <div class="dig_table_overflow">
                                        <table id="card_tbl" class="table table-hover table-dynamic">
                                            <thead>
                                                <tr>
                                                    <th>Si.no</th>
                                                    <th>Feature</th>
                                                    <th>Icon</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let offer of offerArray; let i = index">
                                                    <td> {{i + 1}}</td>
                                                    <td> {{offer.description}} </td>
                                                    <td> <img [src]="offer.displayImage" height="25px" width="25px"
                                                            alt=""> </td>
                                                    <td>
                                                        <a (click)="deleteOffer(offer.index)" *ngIf="updateFlag">
                                                            <img src="../../../assets/ui_icons/buttons/trash_icon.svg"
                                                                class="editDelicon" /></a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="table_cover" *ngIf="prognosis_table" style="margin-top: 39px;">
                            <div class="col-12" style="margin-top: 5px; height: 160px;">
                                <div *ngIf="testArray.length == 0" class="nodata">No tests found</div>
                                <div *ngIf="testArray.length != 0" class="dig_table_overflow">
                                    <div>
                                        <table id="card_tbl" class="table table-hover table-dynamic">
                                            <thead>
                                                <tr>
                                                    <th>Si.no</th>
                                                    <!-- <th>Icon</th> -->
                                                    <th>Test type</th>
                                                    <th>Test name</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let offer of testArray; let i = index">
                                                    <td> {{i + 1}}</td>
                                                    <!-- <td> <img [src]="offer.displayImage" height="25px" width="25px" alt=""> </td> -->
                                                    <td> {{offer.test_type_desc}} </td>
                                                    <td> {{offer.test_name}} </td>
                                                    <td>
                                                        <a (click)="deleteTest(offer.test_id)" *ngIf="updateFlag">
                                                            <img src="../../../assets/ui_icons/buttons/trash_icon.svg"
                                                                class="editDelicon" />
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>