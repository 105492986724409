import { Component, Input, OnInit } from '@angular/core';
import { Neprology_graph } from '../../../assets/js/Neprology_Graph';
import { Helper_nephro_casesheet } from '../../../assets/js/Helper_nephro_casesheet';
import { Observable, observable, Subscription } from 'rxjs';
import { CasesheetService } from '../casesheet/casesheet.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NephroGraphPopupComponent } from '../nephro-graph-popup/nephro-graph-popup.component';

@Component({
  selector: 'app-nephrology-diag-graph',
  templateUrl: './nephrology-diag-graph.component.html',
  styleUrls: ['./nephrology-diag-graph.component.css']
})
export class NephrologyDiagGraphComponent implements OnInit {
  public nephReportsFlag ="min";
  subscription:Subscription;
  public minflag:boolean=true;
  public header_footer_flag: boolean;
  public Serum_Chart_zoom: boolean;

  constructor(public dialog: MatDialog,public messageservice:CasesheetService) { 
    this.Serum_Chart_zoom = true;
  }

  ngOnInit(): void {
    this.subscription = this.messageservice.getCasesheetMessage().subscribe(message => {
      if (message) {
          this.nephReportsFlag = message;
          if (Helper_nephro_casesheet.nephro_get_ret != undefined) {
            if (Helper_nephro_casesheet.nephro_get_ret.diag_reading != null) {
              this.Serum_Chart_zoom = false;
              for (let value of Helper_nephro_casesheet.nephro_get_ret.diag_reading) {
                // $scope.Nepro_Graph = true;
      
                var Datelist = new Array();
                var eGFR_list = new Array();
                var Serum_Creatinine_list = new Array();
                var Albuminuria_list = new Array();
                var Proteinuria_list = new Array();
                var Serum_Measure_list = new Array();
                var Albumin_Measure_List = new Array();
                var timelist = new Array();
      
                Datelist.push(value.created_date+","+value.created_time);
                eGFR_list.push(value.egfr);
                Serum_Creatinine_list.push(value.serum_creatinine);
                Albuminuria_list.push(value.albumin);
                Proteinuria_list.push(value.protein);
                var timelist = new Array();
                timelist.push(value.created_time)
                Neprology_graph.Neprograph(Datelist, eGFR_list, Serum_Creatinine_list, Albuminuria_list, Proteinuria_list,timelist);
              }
            }
          }  
      }
    });

    if(this.nephReportsFlag == "min"){
      if (Helper_nephro_casesheet.nephro_get_ret != undefined) {
        if (Helper_nephro_casesheet.nephro_get_ret.diag_reading != null) {
          this.Serum_Chart_zoom = false;
          for (let value of Helper_nephro_casesheet.nephro_get_ret.diag_reading) {
            // $scope.Nepro_Graph = true;
  
            var Datelist = new Array();
            var eGFR_list = new Array();
            var Serum_Creatinine_list = new Array();
            var Albuminuria_list = new Array();
            var Proteinuria_list = new Array();
            var Serum_Measure_list = new Array();
            var Albumin_Measure_List = new Array();
            var timelist = new Array();
            timelist.push(value.created_time)
            Datelist.push(value.created_date);
            eGFR_list.push(value.egfr);
            Serum_Creatinine_list.push(value.serum_creatinine);
            Albuminuria_list.push(value.albumin);
            Proteinuria_list.push(value.protein);
  
            Neprology_graph.Neprograph(Datelist, eGFR_list, Serum_Creatinine_list, Albuminuria_list, Proteinuria_list,timelist);
          }timelist
        }
      }  
    }
    console.log("Helper_nephro_casesheet.nephro_get_ret"+JSON.stringify(Helper_nephro_casesheet.nephro_get_ret))
  }

  // Navigate NephroGraphPopupPage 
  reading_poup(id) {
    const dialogRef = this.dialog.open(NephroGraphPopupComponent, {
      width: '900px',
      data: {title:"Graph",graph_id: id}
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
