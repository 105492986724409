<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white ">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">Purchase request </h5>
          </div>
          <div class="headerButtons">
            <img *ngIf="newbutton" class="saveimgbtn_inpatinfo" src="../../../assets/ui_icons/buttons/new_button.svg"
              (click)="purchaserequest_view()" />

            <img class="saveimgbtn_inpatinfo" *ngIf="checkboxandbutton && requestArray.length != 0" src="../../../assets/ui_icons/buttons/approve_button.svg" (click)="approved()">

            <!-- <button class="saveimgbtn_inpatinfo" *ngIf="checkboxandbutton" (click)="approved()">Approved</button> -->
          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>

       
        <p class="nodata" *ngIf="requestArray.length == 0">No Purchase request list(s) found</p> 
        <div class="table-responsive" *ngIf="requestArray.length != 0">
        <div class="container-fluid">
          <div class="row">
             <div class="col-12">
   
            <div>

            <mat-accordion displayMode="flat" multi="false" class="mat-table">
              <section matSort class="mat-elevation-z2 mat-header-row" style="background-color: #c1e6fb;">
                <span class="mat-header-cell">Indent ID</span>
                <span class="mat-header-cell">Date</span>
                <span class="mat-header-cell">Department</span>
                <span class="mat-header-cell">Store name</span>
                <span class="mat-header-cell">Amount</span>
                <span class="mat-header-cell">Status</span>
                <span class="mat-header-cell"></span>
               
                
              </section>
            
              <mat-expansion-panel *ngFor="let item of requestArray">
                <mat-expansion-panel-header class="mat-row">
                  <span class="mat-cell">{{ item.plr_id }}</span>
                  <span class="mat-cell">{{ item.plr_date }}</span>
                  <span class="mat-cell">{{item.department || '-'}}</span>
                  <span class="mat-cell">{{item.storename ||'-'}}</span>
                    <span class="mat-cell">{{item.amount ||'-'}}</span>
                    <span class="mat-cell">

                      <div style="font-size: 10px;">
                       
                        <img *ngIf="item.status == 'Open'" width="30px;" height="22px"
                           src="../../../assets/ui_icons/opened_icon.svg" />
    
                           <img *ngIf="item.status == 'Po issued'" width="30px;" height="22px"
                           src="../../../assets/ui_icons/awaiting_for_rating.svg" />
    
                        
                        
                        
                           <p>{{item.status}}</p>
                     </div>
    
    
                    </span>
                    
                    <span class="mat-cell" style="width:20%;text-align:center;display:inline-block"> 
                      <input *ngIf="item.status == 'Open'" type="checkbox" [checked]="accepted" (change)="checkeddata(item)">
                      <input *ngIf="item.status == 'Po issued'" style=" cursor:no-drop;" type="checkbox" [checked]="item.status === 'Po issued'" [disabled]="item.status !== 'Po issued'">
                    </span>
              
                </mat-expansion-panel-header>
                
                <div style="display:flex">
                
                  <table>
                    <thead>
                      <tr>
                     
                        <th>Supplier</th>
                        <th>Product Name</th>

                        <th>Price</th>
                        <th>Quantity</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngIf="item.product_list.length === 0; else productData">
                        <tr>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>
                            
                          </td>
                        </tr>
                      </ng-container>
                      <ng-template #productData>
                        <tr *ngFor="let product of item.product_list">
                          <td>{{ product.supplier || '-' }}</td>
                          <td>{{ product.product_name || '-' }}</td>
                          <td>{{ product.cost_price || '-' }}</td>
                          <td>{{ product.quantity || '-' }}</td>
                          <td>
                           
                          </td>
                        </tr>
                      </ng-template>
                    </tbody>
                  </table>
                  
                  
                </div>
                
               

              </mat-expansion-panel>
              
              
            
            </mat-accordion>
            
            </div>

















            </div>
          </div>
        </div>
        </div>

      </mdb-card-body>
    </mdb-card>
  </div>
</div>