import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { Helper_Class } from '../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from '../../providers/common-data.service';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { Date_Formate } from '../../../assets/js/common';
import { ipaddress } from '../../ipaddress';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { Message_data } from 'src/assets/js/Message_data';
import { send } from 'process';
import { Doc_Helper } from '../../Doctor_module/Doc_Helper';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-appoinment-report',
  templateUrl: './appoinment-report.component.html',
  styleUrls: ['./appoinment-report.component.scss']
})
export class AppoinmentReportComponent implements OnInit {
  @ViewChild('tbl') tbl: ElementRef;
  @ViewChild('hospitalwithnamelogo') hospitalwithnamelogo: ElementRef;

  public filtduration:string="all";
  public forcoverdiv:boolean=true;
  public backbutton: boolean = false;
  public dataList=[];
  public url:string;
  public currentDate;
  public fromDate;
  public toDate;
  public currenttime;
  public presentdate;
  public hosp_id:string;
  public userdata;
  public applystyle: boolean;
  public applyheight: boolean;
  public print_template;
  public printlogostyle;
  public hospital_logo;
  public hosp_name;
  public hosp_addr;
  public hosp_csz;
  public hosp_tel;
  public loader;
  public dataListheader=[];


  constructor(public gservice: CommonDataService, public http: Http, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService) { }

  ngOnInit(): void {
    this.url="misr/ghpr";
    this.getCurrentDate();
    // this.getRetrievalData()
    this.userdata =Helper_Class.getInfo();
    console.log(" this.userdata = "+JSON.stringify(this.userdata))
    this.hosp_id=this.userdata.hospitals[0].hptl_clinic_id;
    this.hosp_name=this.userdata.hptl_name
    var addval;
    if (this.userdata.location_desc != undefined) {
      addval = this.userdata.location_desc

    } else {
      addval = "";
    }
    this.hosp_addr = addval;
    this.hosp_csz = this.userdata.city_desc + ", " + this.userdata.state_desc + " - " + this.userdata.zipcode + "  " + this.userdata.country_desc;
    if (this.userdata.telephone != undefined) {
      this.hosp_tel = this.userdata.telephone;
    } else {
      this.hosp_tel = "-";
    }
    this.applystyle = true;
    this.applyheight = true;
    this.print_template = Helper_Class.getInfo().bill_print_template;
    this.printlogostyle = Helper_Class.getInfo().bill_print_logo_style;
    this.hospital_logo = ipaddress.Ip_with_img_address + Helper_Class.getInfo().bill_print_logo;
    this.dataListheader=['S.no','Name','MR NO','Age','Gender',
                      'Mobile no','Appointment id','Session','Type','Refered by',
                      'Doctor name','Date']
  }

  public getCurrentDate() {
    var headers = new Headers();
    var date
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate', { country: ipaddress.country_code },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          console.log("current time", JSON.stringify(obj))
          this.currentDate = obj.current_date;//display in html for maximum date
          this.fromDate = obj.current_date;
          this.toDate = obj.current_date;
          var currenttime1 = obj.current_time.split(":");
          this.currenttime = currenttime1[0]
          this.presentdate = obj.current_date;

          // this.updateCurrentSession(this.presentdate,this.currenttime)
          //this.getSalesData();
          this.getSalesData()
          console.log('presentdate1', this.presentdate)
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });

  }

  todisplaycoverdiv(){
    this.forcoverdiv = true;
    this.backbutton = false;
    // this.amountcard = false;
    // this.ishide = false;
    this.dataList = [];
  }


  selectDate(data, value) {
    if (data != null) {
      if (value == "1")
        this.fromDate = data;
      else
        this.toDate = data;
    }
  }

  getSalesData(){
    var filtdur;
     var send_data={
      hptl_clinic_id: this.hosp_id,
      app_type:this.filtduration,
      from_date:this.fromDate,
      to_date:this.toDate,
     }
     console.log("send_data = "+JSON.stringify(send_data))
      this.dataList=[];
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + this.url, send_data,
        { headers: headers }).subscribe(
          data => {
            var dataval = data.json();
            // var data = dataval.result
            console.log("pharma dataval" + JSON.stringify(dataval))
            if(dataval.appointments  != undefined && dataval.appointments.length != 0){
              this.forcoverdiv = false;
              this.backbutton = true;
              for(var d=0;d < dataval.appointments.length;d++){

               var doc_name,mobile,name,gender,session;

               if(dataval.appointments[d].middle_name != undefined){
                name= encrypt_decript.Decript(dataval.appointments[d].first_name)+" "+encrypt_decript.Decript(dataval.appointments[d].middle_name)+" "+encrypt_decript.Decript(dataval.appointments[d].last_name)
               }else if(dataval.appointments[d].first_name != undefined && dataval.appointments[d].last_name != undefined){
                name= encrypt_decript.Decript(dataval.appointments[d].first_name)+" "+encrypt_decript.Decript(dataval.appointments[d].last_name)
               }else{
                name= encrypt_decript.Decript(dataval.appointments[d].first_name)
               }

               if(dataval.appointments[d].dr_middle_name != undefined){
                doc_name= dataval.appointments[d].dr_first_name+" "+dataval.appointments[d].dr_middle_name+" "+dataval.appointments[d].dr_last_name
               }else if(dataval.appointments[d].dr_first_name != undefined && dataval.appointments[d].dr_last_name != undefined){
                doc_name= dataval.appointments[d].dr_first_name+" "+dataval.appointments[d].dr_last_name
               }else{
                doc_name= dataval.appointments[d].dr_first_name
               }

               if(dataval.appointments[d].gender != undefined){
                gender=encrypt_decript.Decript(dataval.appointments[d].gender)
               }else{
                gender=''
               }

               if(dataval.appointments[d].mobile != undefined){
                mobile=encrypt_decript.Decript(dataval.appointments[d].mobile)
               }else{
                mobile=''
               }

               if(dataval.appointments[d].session){
                session=encrypt_decript.Decript(dataval.appointments[d].session)
               }else{
                session=''
               }

                this.dataList.push({
                  sno:d+1,
                   mr_no:dataval.appointments[d].mr_no,
                   name:name,
                   age:dataval.appointments[d].age,
                   gender:gender,
                  mobile:mobile,
                   appointment_id:dataval.appointments[d].appointment_id,
                   status:dataval.appointments[d].status,
                  session:session,
                   app_type:dataval.appointments[d].app_type,
                  doc_name:doc_name,
                  refered_by:dataval.appointments[d].referred_by,
                  appointment_date:dataval.appointments[d].appointment_date ||''
                })
              }
            }
            console.log("dataList = " + JSON.stringify(this.dataList))
          }, error => { });

  }


  getRetrievalData() {
    var send = {
      inpatient_id: Doc_Helper.getAdmission_id().admission_id,
    }
    var headers = new Headers();
    console.log("get retrieval data" + send)
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + "inpat/gdsv", send,
      { headers: headers })
      .subscribe(
        response => {
          // var values = response.json();
          var values = JSON.parse(JSON.stringify(response));
          console.log("retrive obj" + JSON.stringify(values));
          if (values != undefined || values != null) {

            this.hosp_name = values.hptl_name;

            var addval;
            if (values.address2 != undefined) {
              addval = values.address1 + "," + values.address2;

            } else {
              addval = values.address1;
            }
            this.hosp_addr = addval;
            this.hosp_csz = values.city + ", " + values.state + " - " + values.zipcode + "  " + values.country;

            if (values.telephone != undefined) {
              this.hosp_tel = values.telephone;

            } else {
              this.hosp_tel = "-";
            }


          }
        },
        error => {
          this.loader = true;
          this.toastr.error(Message_data.defaultErr);
        }
      )
  }


  print_area(){
    let tbl,printContents,popupWin,logo,hospitaldetails;
    printContents = this.tbl.nativeElement.innerHTML;
    logo = document.getElementById('printimage').innerHTML;
    hospitaldetails = this.hospitalwithnamelogo.nativeElement.innerHTML;
  
    if(this.print_template != undefined && this.print_template == "banner"){
      popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
      popupWin.document.open();
      popupWin.document.write(`
  
  <head>
    <title>Reports</title>
    
  <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
  <style>
  @page { size: landscape || potrait; } 
  @media print and (orientation: landscape) {
      .table {
        width: 100%;
      }
    }
  
    /* Portrait Styles */
    @media print and (orientation: portrait) {
      .table {
        width: auto;
      }
    }
  th{
      padding:0%;
    }
    .table{
      padding:5% !important;
      border: 1px solid #c7c7e9bf !important;
      border-radius: 10px !important;
      box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px !important;
      font-family:Segoe UI;
      font-size:12px; 
    }
   .img{
    vertical-align: middle;
    width:750;
  }
    </style>
  </head>
      <body onload="window.print();window.onfocus=function(){ window.close();}">
     
      <table style="border:'0';width:100%; margin: 20px;">  
      <thead>
      <tr>
      ${logo}
      </tr>
      </thead>             
         <tfoot> 
          <tr>  
  
           <td width="100%">  
            <table style="border:'0';width:100%">  
              <tr>  
                <td style="width:100%;">&nbsp;</td>  
             </tr>  
           </table>  
         </tfoot> 
  
         <tbody>  
           <tr>  
             <td width="100%"> 
            
           
           <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' id="table">
       
       
           ${printContents}
       
                   </table>
         </td>  
          </tr> 
        </tbody>  
      </table>
   </body>
    </html>`
      );
      popupWin.document.close();
    }
    else if(this.print_template != undefined && this.print_template == "logowithname"){
      popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
      popupWin.document.open();
  
  
      popupWin.document.write(`
  
      <head>
    <title>Reports</title>
    
  <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
  <style>
  @page { size: landscape || potrait; } 
  @media print and (orientation: landscape) {
      .table {
        width: 100%;
      }
    }
  
    /* Portrait Styles */
    @media print and (orientation: portrait) {
      .table {
        width: auto;
      }
    }
  th{
      padding:0%;
    }
    .table{
      padding:5% !important;
      border: 1px solid #c7c7e9bf !important;
      border-radius: 10px !important;
      box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px !important;
      font-family:Segoe UI;
      font-size:12px; 
    }
   .img{
    vertical-align: middle;
    width:750;
  }
    </style>
  </head>
      <body onload="window.print();window.onfocus=function(){ window.close();}">
      <table style="border:'0';width:100%">  
      <thead>
      <tr>
      ${logo}
      </tr>
      <div>
      ${hospitaldetails}
      </div>
      </thead>             
         <tfoot> 
          <tr>  
  
           <td width="100%">  
            <table style="border:'0';width:100%">  
              <tr>  
                <td style="width:100%;">&nbsp;</td>  
             </tr>  
           </table>  
         </tfoot> 
  
         <tbody>  
           <tr>  
             <td width="100%"> 
            
           
           <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' id="table">
       
       
           ${printContents}
       
                   </table>
         </td>  
          </tr> 
        </tbody>  
      </table>
   </body>
    </html>
   
  
    `);
  
      popupWin.document.close();
    }else{
      popupWin = window.open('', '_blank', 'top=20,left=10,height=100%,width=auto');
      popupWin.document.open();
  
  
      popupWin.document.write(`
  
  <head>
    <title>Reports</title>
    
  <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css" integrity="sha384-rHyoN1iRsVXV4nD0JutlnGaslCJuC7uwjduW9SVrLvRYooPp2bWYgmgJQIXwl/Sp" crossorigin="anonymous">
  <style>
  @page { size: landscape || potrait; } 
  @media print and (orientation: landscape) {
      .table {
        width: 100%;
      }
    }
  
    /* Portrait Styles */
    @media print and (orientation: portrait) {
      .table {
        width: auto;
      }
    }
  th{
      padding:0%;
    }
    .table{
      padding:5% !important;
      border: 1px solid #c7c7e9bf !important;
      border-radius: 10px !important;
      box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px !important;
      font-family:Segoe UI;
      font-size:12px; 
    }
   
   .img{
    vertical-align: middle;
    width:750;
  }
    </style>
  </head>
      <body onload="window.print();window.onfocus=function(){ window.close();}">
     
      <table style="border:'0';width:100%; margin: 15px;">  
      <thead>
      <tr>
      <td><div style="height: 130px;"></div></td>    
      </tr>
      </thead>             
         <tfoot> 
          <tr>  
  
           <td width="100%">  
            <table style="border:'0';width:100%">  
              <tr>  
                <td style="width:100%;">&nbsp;</td>  
             </tr>  
           </table>  
         </tfoot> 
  
         <tbody>  
           <tr>  
             <td width="100%"> 
            
           
           <table class='table table-striped bordered align-middle mb-1 bg-white tbl px-0' id="table">
       
       
           ${printContents}
       
                   </table>
         </td>  
          </tr> 
        </tbody>  
      </table>
      
   </body>
    </html>`
      );
  
      popupWin.document.close();
  
    }
  }
  

  getExcelData(type){
    var receivables=[];
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Receivables report');
    worksheet.getColumn(1).width = 10;
    worksheet.getColumn(2).width = 10;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 30;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 15;
    worksheet.getColumn(7).width = 20;
    worksheet.getColumn(8).width = 20;
    worksheet.getColumn(9).width = 20;
    worksheet.getColumn(10).width = 20;
    worksheet.getColumn(11).width = 20;
    worksheet.getColumn(12).width = 20;
    var headerRow = worksheet.addRow(this.dataListheader);
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4167B8' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12
      }
    });
  
    for (let receivable_data of this.dataList) {
      // var datetime= receivable_data.poDate+"/"+receivable_data.potime
      receivables.push({
                   sno:receivable_data.sno,
                   name:receivable_data.name,
                   mr_no:receivable_data.mr_no,
                   age:receivable_data.age,
                   gender:receivable_data.gender,
                  mobile:receivable_data.mobile,
                   appointment_id:receivable_data.appointment_id,
                  //  status:receivable_data.status,
                  session:receivable_data.session,
                   app_type:receivable_data.app_type,
                  doc_name:receivable_data.doc_name,
                  refered_by:receivable_data.refered_by,
                  appointment_date:receivable_data.appointment_date
      })
    }
  
    for (let x1 of receivables) {
      let x2 = Object.keys(x1);
      let temp = []
      for (let y of x2) {
        temp.push(x1[y])
      }
      let chilrow = worksheet.addRow(temp)
      let salesamt = chilrow.getCell(5);
      salesamt.alignment = {
        vertical: 'middle',
        horizontal: 'right'
      }
      let costprice = chilrow.getCell(13);
      costprice.alignment = {
        vertical: 'middle',
        horizontal: 'right'
      }
      let salesprice = chilrow.getCell(14);
      salesprice.alignment = {
        vertical: 'middle',
        horizontal: 'right'
      }
      let mrp = chilrow.getCell(15);
      mrp.alignment = {
        vertical: 'middle',
        horizontal: 'right'
      }
    }
  
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, "Appointment report" + '-' + new Date().valueOf() + '.xlsx');
    });
  
  
  }




}


