import { Component, OnInit,ElementRef,Inject } from '@angular/core';
import { Http, Headers } from '@angular/http';
// import { encrypt_decript } from '../assets/js/Encription_Decription';
import { encrypt_decript } from '../../../../assets/js/Encription_Decription';
import { ToastrService } from 'ngx-toastr'
import { CommonDataService } from '../../../../app/providers/common-data.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Data, Route, Router } from '@angular/router';
import { ipaddress } from '../../../ipaddress';

@Component({
  selector: 'app-purchase-orderpopup',
  templateUrl: './purchase-orderpopup.component.html',
  styleUrls: ['./purchase-orderpopup.component.scss']
})
export class PurchaseOrderpopupComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  public pharmacyid;
  public productname;
  public productdetails:any[]=[];
  constructor(public comm_Data: CommonDataService, private el: ElementRef, toastr: ToastrService, public router: Router, public http: Http, public dialog: MatDialog, public dialogRef: MatDialogRef<PurchaseOrderpopupComponent>,
  @Inject(MAT_DIALOG_DATA) public data: any) {
    dialogRef.disableClose = true;
    this.pharmacyid=data.pharmacy_id;
    this.productname=data.pro_name
    // this.pharmacy_id = data.pharmacy_id;
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 10,
      "lengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
      "columnDefs": [
        { "orderable": false, "targets": [0, 1, 2] },
      ],
      "language": {
        "search": "Filter"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12">><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"l><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
  }
  ngOnInit(): void {
    
    var headers = new Headers();
    headers.append('Content-Type','application/json');
    this.http.post(ipaddress.getIp+ 'inv/gpsbl',
    JSON.stringify({
      pharmacy_id:this.pharmacyid,
      prod_name:this.productname
    }),{headers: headers}
  ).subscribe(response =>{
    var obj = JSON.parse(response['_body']);
    console.log("product details  = "+JSON.stringify(obj))
    if(obj.length !=0 && obj != undefined){
      for(var i=0;i<obj.stock_onhand.length; i++){
        if (obj.stock_onhand[i].exp_date != undefined) {
          var expdate = obj.stock_onhand[i].exp_date.split('-');
          obj.stock_onhand[i].exp_date = expdate[2] + "-" + expdate[1] + "-" + expdate[0]
        }
        this.productdetails.push({
          sn: i+1,
          short_name: obj.stock_onhand[i].short_name,
          name: obj.stock_onhand[i].name,
          supplier: obj.stock_onhand[i].supplier,
          batch_no: obj.stock_onhand[i].batch_no,
          rack: obj.stock_onhand[i].rack_id,
          bin: obj.stock_onhand[i].bin_id,
          exp_date: obj.stock_onhand[i].exp_date,
          min_level: obj.stock_onhand[i].min_level,
          max_level: obj.stock_onhand[i].max_level,
          danger_level: obj.stock_onhand[i].danger_level,
          qty_onhand: obj.stock_onhand[i].qty_onhand,
        })

      }
      console.log("product_popup = " + JSON.stringify(this.productdetails))
    }
    

  });
  }

  closePopup() {
    this.dialogRef.close();
  }
  onNoClick() {
    this.dialogRef.close();
  }
  
}
