import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Http, Headers } from '@angular/http';
import { Helper_Class } from '../../../helper_class';
import { ToastrService } from 'ngx-toastr';
import { CommonDataService } from 'src/app/providers/common-data.service';
import * as Excel from 'exceljs/dist/exceljs.min.js';
// import { PharmacyService } from '../../../../../pharmacy/pharmacy.service';
import { PharmacyService } from 'src/app/pharma/pharmacy/pharmacy.service';
import { ipaddress } from '../../../ipaddress';
import { Message_data } from 'src/assets/js/Message_data';
import { MenuViewService } from 'src/app/common-module/menu-view/menu-view.service';
import { convertNumberToWords } from '../../../../assets/js/common';
@Component({
  selector: 'app-pharma-inventory-upload',
  templateUrl: './pharma-inventory-upload.component.html',
  styleUrls: ['./pharma-inventory-upload.component.css']
})
export class PharmaInventoryUploadComponent implements OnInit {
  @ViewChild('excelreader') excelreader: ElementRef;
  public inventory = [];
  public locationArray: any;
  public inventoryColumnArray = [];
  public realignColumnsArray = [];
  public productArray: any = [];
  public columnArray = []
  public pharmacyID: string;
  public pharmaFacID: string;
  public pharColumn: string;
  public manufacturer: string;
  public mfgID: string;
  public invoiceNO: string;
  public pharmacyLocation: any;
  public poNO: string;
  public data_flag: boolean;
  public target;
  public userInfo;
  public userID;
  public pharmacistID;
  public poapproved;
  public invoice_colm: string = '';
  public Discount_value;
  public discountamount;
  public totalCostForCheckedItems: number = 0;
  public disamt: number = 0;
  public aftdiscamt: number = 0;
  public GSTamount: number = 0;
  public Cgst: number = 0;
  public Sgst: number = 0;
  public Cgstper: number = 0;
  public Sgstper: number = 0;
  public invoiceAmount: number = 0;
  public tcprice: string;
  public constpriceinwords;
  public discount_option;
  public discountpercentageflag: boolean;
  public discountpriceflag: boolean = false;
  public product_wise_discountflag: boolean = true;
  public returnAmount: number = 0;
  public receivedAmount: number = 0;
  public balance: number = 0;
  public invoiceAmountbe: number = 0;
  public po_required;
  public Gstflag: boolean = true;
  public supplierList: any = [];
  public productdata_detal: any = [];
  public gst_no: string;
  public product_returns_id;
  public receivedamoutsupp: number = 0;
  public cgst: number = 0;
  public sgst: number = 0;
  public inventrystorename: string = "All";
  public inventrydepartname: string = "All";
  public inventdepartmentarray = [];
  public inventrystorenamearray = [];
  public user_type;
  public multicheck: boolean;
  public filtercheckbox;
  public podataid;
  public supplierflag: boolean;
  public inputsupplierflag: boolean;
  public purchasePoID;
  public pobase;
  public countryID: any;
  public mgfID: any;
  public checkboxarry = [];
  public poorder: any = [];
  public realignColumnsArray_actual = [];
  public invDate:string;
  public currentDate;

  constructor(public messageservice: MenuViewService, public gservice: CommonDataService, public http: Http, public routes: ActivatedRoute, public router: Router, public toastr: ToastrService) {
    this.pharmaFacID = Helper_Class.get_inventory_upload().pharma_fac_id;
    this.data_flag = true;
  }
  ngOnInit(): void {
    this.supplierflag = false;
    this.inputsupplierflag = true;
    this.userInfo = Helper_Class.getInfo();
    this.userID = this.userInfo.user_id;
    this.pharmacistID = this.userID;
    this.poapproved = Helper_Class.getInfo().hospitals[0].po_approval_req;
    this.po_required = Helper_Class.getInfo().hospitals[0].po_required;

    this.user_type = Helper_Class.getInfo().user_type;
    for (var i = 0; i < this.userInfo.hospitals.length; i++) {
      this.pharmacyID = this.userInfo.hospitals[0].hptl_clinic_id;
    }
    this.getPharmacy();
    this.productOrder();
    this.getSuppliers();
    this.getDate();
    // this.inventoryColumnArray.push(
    //   "Product_name",
    //   "Batch_no",
    //   "Package",
    //   "UOM",
    //   "Quantity",
    //   "Free_qty",
    //   "Rate",
    //   "Cost_price",
    //   "Sales_price",
    //   "MRP",
    //   "Tax",
    //   "Discount",
    //   "Mfg_date",
    //   "Received_date",
    //   "Expiry_date",
    //   "Amount",
    //   "cgst",
    //   "sgst"
    // )
  }

  getDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate', { country: ipaddress.country_code },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.currentDate = obj.current_date;
          this.invDate = obj.current_date;
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }
  
  onInputFocusOut() {
    // Check if the entered string contains spaces
    if (this.invoice_colm.includes('\t')) {
      // Split the string into an array of separate strings
      const separatedStrings = this.invoice_colm.split('\t');
      // Call another function with the separated strings
      this.columnOrder(separatedStrings,'');
    }
  }

  columnOrder(data,data_actual) {
    // this.realignColumnsArray=[];
    var dataval = data.toString().split(",")
    for (var i = 0; i < dataval.length; i++) {
      if (!this.realignColumnsArray.includes(dataval[i])) {
        this.realignColumnsArray.push(dataval[i])
        this.data_flag = false;
      }
    }

    var originaldataval = data_actual.toString().split(",")
    for (var j = 0; j < originaldataval.length; j++) {
      if (!this.realignColumnsArray_actual.includes(originaldataval[j])) {
        if(originaldataval[j] !='')
        this.realignColumnsArray_actual.push(originaldataval[j])
        this.data_flag = false;
      }
    }
  }

  getPharmacy() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/pharlocbyid/",
      {
        pharmacist_id: this.pharmacistID,
        pharmacy_id: this.pharmacyID
      },
      { headers: headers }).subscribe(
        data => {
          var respdata = data.json();
          if (respdata.pharma_locations != null) {
            this.locationArray = respdata.pharma_locations;
          }
        },
        error => { });
  }

  productOrder() {
    console.log("is and send data" + this.pharmacyID, this.poapproved === "1" ? "1" : undefined)
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/gpos/",
      {
        pharmacist_id: this.pharmacistID,
        pharmacy_id: this.pharmacyID,
        is_approve: this.poapproved === "1" ? "1" : undefined
      },
      { headers: headers }).subscribe(
        data => {
          var obj = data.json()
          console.log(" po order no =" + JSON.stringify(obj))
          if (data.json().purchase_orders != null) {
            this.productArray = data.json().purchase_orders;
            console.log("product arrray" + JSON.stringify(this.productArray))
          }
        },
        error => { });
  }
  public suppID;

  getManufacturer() {
    this.realignColumnsArray = [];
    this.columnArray = [];
    console.log("data = " + JSON.stringify(this.productArray))
    for (var i = 0; i < this.productArray.length; i++) {
      if (this.productArray[i].purchase_order_id == this.poNO) {
        this.manufacturer = this.productArray[i].supp_name;
        this.mfgID = this.productArray[i].mfg_id;

        this.suppID = this.productArray[i].supp_id;
        if (this.productArray[i].invoice_col_order != undefined) {
          var colarray = this.productArray[i].invoice_col_order.split(",");
          for (var x = 0; x < colarray.length; x++) {
            this.realignColumnsArray.push(colarray[x]);
          }
        }
      }
    }
  }

  // readExcel(event): void {
  //   const workbook = new Excel.Workbook();
  //   const target: DataTransfer = <DataTransfer>(event.target);
  //   if (target.files.length !== 1) {
  //     this.toastr.error(Message_data.selectOneFile);
  //   }
  //   var array = {};
  //   var array1 = []
  //   var colarray = [];
  //   var colarray1 = [];
  //   var header_array_xl = [];
  //   var rowcountvalue = this.realignColumnsArray;
  //   console.log("rowcountvalue", rowcountvalue)
  //   const arryBuffer = new Response(target.files[0]).arrayBuffer();
  //   arryBuffer.then(function (data) {
  //     workbook.xlsx.load(data)
  //       .then(function () {
  //         const worksheet = workbook.getWorksheet('Sheet1');
  //         worksheet.eachRow(function (row, rowNumber) {
  //           if (rowNumber == 1) { 
  //             for (var i = 0; i < rowcountvalue.length; i++) {
  //               // console.log("rowcountvalue", rowcountvalue[i])
  //               console.log("row No " + rowNumber ,row.getCell(i + 1).value);

  //               if (row.getCell(i + 1).value != null) {
  //                 header_array_xl.push(row.getCell(i + 1).value);
  //               }                
  //             }
  //             console.log(header_array_xl)
  //           }
            
  //           if (rowNumber != 1) {
  //             colarray = [];
  //             for (var i = 0; i < rowcountvalue.length; i++) {
  //               var name;
  //               var nameval = rowcountvalue[i];
  //               if (nameval.toUpperCase() == "PRODUCT_NAME" || nameval.toUpperCase() == "PRODUCT NAME" || nameval.toUpperCase() == "PRODUCT") {
  //                 name = "Product_name";
  //               } else if (nameval.toUpperCase() == "BATCH_NO" || nameval.toUpperCase() == "BATCH NO" || nameval.toUpperCase() == "BATCH" || nameval.toUpperCase() == "BATCHNO") {
  //                 name = "Batch_no";
  //               } else if (nameval.toUpperCase() == "PACKAGE" || nameval.toUpperCase() == "PACKAGE NO" || nameval.toUpperCase() == "PACKAGE NUMBER") {
  //                 name = "Package";
  //               } else if (nameval.toUpperCase() == "UOM") {
  //                 name = "UOM";
  //               } else if (nameval.toUpperCase() == "QUANTITY") {
  //                 name = "Quantity";
  //               } else if (nameval.toUpperCase() == "FREE_QTY" || nameval.toUpperCase() == "FREE QTY" || nameval.toUpperCase() == "FREE QUANTITY") {
  //                 name = "Free_qty";
  //               } else if (nameval.toUpperCase() == "RATE" || nameval.toUpperCase() == "Cost Price" || nameval.toUpperCase() == "Cost_Price") {
  //                 name = "Rate";
  //               } else if (nameval.toUpperCase() == "MRP") {
  //                 name = "MRP";
  //               } else if (nameval.toUpperCase() == "DISCOUNT") {
  //                 name = "Discount";
  //               } else if (nameval.toUpperCase() == "TAX") {
  //                 name = "Package";
  //               } else if (nameval.toUpperCase() == "MFG_DATE" || nameval.toUpperCase() == "MFG DATE" || nameval.toUpperCase() == "MANUFACTURING DATE") {
  //                 name = "Mfg_date";
  //               } else if (nameval.toUpperCase() == "RECEIVED_DATE" || nameval.toUpperCase() == "RECEIVED DATE") {
  //                 name = "Received_date";
  //               } else if (nameval.toUpperCase() == "EXPIRY_DATE" || nameval.toUpperCase() == "EXPIRY DATE") {
  //                 name = "Expiry_date";
  //               } else if (nameval.toUpperCase() == "AMOUNT") {
  //                 name = "Amount";
  //               }
  //               else if (nameval.toUpperCase() == "COST PRICE" || nameval.toUpperCase() == "COST_PRICE" || nameval.toUpperCase() == "CST_PRICE" || nameval.toUpperCase() == "Ct_PRICE" || nameval.toUpperCase() == "CP" || nameval.toUpperCase() == "cp" || nameval.toUpperCase() == "Cp") {
  //                 name = "Cost_price";
  //               }
  //               else if (nameval.toUpperCase() == "SALES PRICE" || nameval.toUpperCase() == "SALES_PRICE" || nameval.toUpperCase() == "SL_PRICE" || nameval.toUpperCase() == "Sl_PRICE" || nameval.toUpperCase() == "SP" || nameval.toUpperCase() == "sp" || nameval.toUpperCase() == "Sp") {
  //                 name = "Sales_price";
  //               }
  //               else if (nameval.toUpperCase() == "CGST" || nameval.toUpperCase() == "Cgst" || nameval.toUpperCase() == "CGST%" || nameval.toUpperCase() == "Cgst%") {
  //                 name = "cgst";
  //               }
  //               else if (nameval.toUpperCase() == "SGST" || nameval.toUpperCase() == "Sgst" || nameval.toUpperCase() == "SGST%" || nameval.toUpperCase() == "Sgst%") {
  //                 name = "sgst";
  //               }

  //               for(var j = 0; j < header_array_xl.length; j++) {
  //                 if(rowcountvalue[i] != "") {
  //                   if(name == header_array_xl[j]) {
  //                     if (name == "Received_date") {
  //                       var date = new Date(row.getCell(j + 1).value)
  //                       var dateval = date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
  //                       array[name] = dateval;
  //                       colarray.push(dateval)
  //                     } else {
  //                       // alert(header_array_xl[j])
  //                       if (row.getCell(j + 1).value != null) {
  //                         array[name] = row.getCell(j + 1).value;
  //                         colarray.push(row.getCell(j + 1).value)
  //                         // alert(row.getCell(j + 1).value)
  //                       }
  //                     }
  //                   }
  //                 }                  
  //               }

  //               // if (name == "Received_date") {
  //               //   var date = new Date(row.getCell(i + 1).value)
  //               //   var dateval = date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
  //               //   array[name] = dateval;
  //               //   colarray.push(dateval)
  //               // } else {
  //               //   if (row.getCell(i + 1).value != null) {
  //               //     array[name] = row.getCell(i + 1).value;
  //               //     colarray.push(row.getCell(i + 1).value)
  //               //   }
  //               // }
  //             }
  //             console.log("THE ARRAY VALUE " + JSON.stringify(colarray))
  //             colarray1.push(colarray);
  //             array1.push(array);
  //             array = {};

              
  //           }
  //         });
  //       });
  //   });
  //   this.columnArray = colarray1;
  //   this.inventory = array1;

  //   setTimeout(() => {
  //     this.updateTotalCost()
  //   }, 700);

  //   console.log("column array = " + JSON.stringify(this.inventory))
  // }

  readExcel(event): void {
    const workbook = new Excel.Workbook();
    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length !== 1) {
      this.toastr.error(Message_data.selectOneFile);
    }
    var array = {};
    var array1 = []
    var colarray = [];
    var colarray1 = [];
    var header_array_xl = [];
    //var header_array_actual=[];
    // var rowcountvalue_actual=[];
    var rowcountvalue = this.realignColumnsArray;
    var rowcountvalue_actual =this.realignColumnsArray_actual;
    const arryBuffer = new Response(target.files[0]).arrayBuffer();
    arryBuffer.then(function (data) {
      workbook.xlsx.load(data)
        .then(function () {
          const worksheet = workbook.getWorksheet('Sheet1');
          worksheet.eachRow(function (row, rowNumber) {
            if (rowNumber == 1) { 
              for (var i = 0; i < rowcountvalue.length; i++) {
                if (row.getCell(i + 1).value != null || row.getCell(i + 1).value !="") {
                  header_array_xl.push(row.getCell(i + 1).value);
                }  
              }
            }
            
            if (rowNumber != 1) {
              colarray = [];
              for (var i = 0; i < rowcountvalue.length; i++) {
                var name=rowcountvalue[i];
                var oriname=rowcountvalue_actual[i];
                for(var j = 0; j < header_array_xl.length; j++) {
                  if(rowcountvalue[i] != "") {
                    if(name == header_array_xl[j]) {
                      if (name == "Received date") {
                        var date = new Date(row.getCell(j + 1).value);
                        var dayval = date.getDate().toString();
                        if(dayval.length == 1){
                          dayval="0"+dayval;
                        }
                        var monval = (date.getMonth() + 1).toString();
                        if(monval.length == 1){
                          monval="0"+monval;
                        }
                        //var dateval = date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
                        var dateval = dayval + "-" + monval + "-" + date.getFullYear();
                        array[oriname] = dateval;
                        colarray.push(dateval);

                      } else {
                        if (row.getCell(j + 1).value != null) {
                          array[oriname] = row.getCell(j + 1).value;
                          colarray.push(row.getCell(j + 1).value)
                        }
                      }
                    }
                  }                  
                }
              }
              colarray1.push(colarray);
              array1.push(array);
              array = {};
            }
          });
        });
    });
    this.columnArray = colarray1;
    this.inventory = array1;

    setTimeout(() => {
      this.updateTotalCost()
    }, 700);

    console.log("column array = " + JSON.stringify(this.inventory))
  }

  saveData() {
    var send_data;
    var podataid = "";
    if (this.inventory.length != 0) {
      for (var i = 0; i < this.inventory.length; i++) {
        var expdate = this.inventory[i].Expiry_date.split("-");
        this.inventory[i].Expiry_date = expdate[1] + "-" + expdate[0] + "-" + "28";
      }
      console.log("EXPIRY DATE VALUE ===== "+JSON.stringify(this.inventory))
      if (this.po_required == "1") {
        for (var i = 0; i < this.poorder.length; i++) {
          // Add comma if not the last element
          podataid += this.poorder[i];
          if (i < this.poorder.length - 1) {
            podataid += ",";
          }
        }
        
        var receivedamount = this.receivedAmount + this.receivedamoutsupp
        if (this.returnAmount != 0) {
          send_data = {
            invoice_no: this.invoiceNO,
            invoice_date: this.invDate,
            purchase_order_id: podataid,
            country: "IN",
            mfg_id: this.mfgID,
            supp_id: this.suppID,
            facility_id: this.pharmacyLocation,
            product_details: this.inventory,
            pharma_id: this.pharmacyID,
            amount: this.invoiceAmount,
            disc_amount: this.disamt,
            gst_amount: this.GSTamount,
            product_returns_id: this.product_returns_id,
            refund_amount: receivedamount,
            po_required: this.po_required,
            balance: Math.abs(this.balance),
          }

        } else {
          send_data = {
            invoice_no: this.invoiceNO,
            invoice_date: this.invDate,
            purchase_order_id: podataid,
            country: "IN",
            mfg_id: this.mfgID,
            supp_id: this.suppID,
            disc_amount: this.disamt,
            facility_id: this.pharmacyLocation,
            product_details: this.inventory,
            pharma_id: this.pharmacyID,
            amount: this.invoiceAmount,
            gst_amount: this.GSTamount,
            // product_returns_id:this.product_returns_id,
            // refund_amount:receivedamount,
            po_required: this.po_required
          }
        }

      } else {
        var receivedamount = this.receivedAmount + this.receivedamoutsupp
        if (this.returnAmount != 0) {
          send_data = {
            invoice_no: this.invoiceNO,
            invoice_date: this.invDate,
            country: "IN",
            mfg_id: this.mfgID,
            supp_id: this.suppID,
            disc_amount: this.disamt,
            facility_id: this.pharmacyLocation,
            product_details: this.inventory,
            pharma_id: this.pharmacyID,
            amount: this.invoiceAmount,
            gst_amount: this.GSTamount,
            product_returns_id: this.product_returns_id,
            refund_amount: receivedamount,
            po_required: this.po_required,
            balance: Math.abs(this.balance),
          }
        } else {
          send_data = {
            invoice_no: this.invoiceNO,
            invoice_date: this.invDate,
            country: "IN",
            mfg_id: this.mfgID,
            supp_id: this.suppID,
            disc_amount: this.disamt,
            facility_id: this.pharmacyLocation,
            product_details: this.inventory,
            pharma_id: this.pharmacyID,
            amount: this.invoiceAmount,
            gst_amount: this.GSTamount,
            // product_returns_id:this.product_returns_id,
            // refund_amount:receivedamount,
            po_required: this.po_required
          }
        }
      }

      if (podataid == "" && this.po_required == "1") {
        this.toastr.error("Select purchase order number")
      } else {
        console.log("datat = " + JSON.stringify(send_data));
        var headers = new Headers();
        console.log("datat = " + JSON.stringify(send_data));
        headers.append('Content-Type', 'application/json');
        this.http.post(ipaddress.getIp + "inv/upprodex/", send_data, { headers: headers }).subscribe(
          data => {
            var obj = data.json();
            console.log("DATA = " + JSON.stringify(obj))
            if (obj != undefined && obj.key == "1") {
              this.toastr.success(Message_data.saveSuccess);
              this.messageservice.sendMessage("inventory_receivables");
            } else {
              this.toastr.error(obj.result);
            }
          })
      }
    }
    else {
      this.toastr.error(Message_data.addInventory)
    }
  }

  clear_data() {
    this.realignColumnsArray = [];
    this.inventory = [];
    this.columnArray = [];
    this.excelreader.nativeElement.value = "";
  }

  back_Nav() {
    this.messageservice.sendMessage("inventory_receivables");
  }

  updateTotalCost() {
    this.totalCostForCheckedItems = 0;
    this.GSTamount = 0;
    this.invoiceAmount = 0;
    this.disamt = 0;
    this.Cgst = 0;
    this.Cgstper = 0;
    this.Sgst = 0;
    this.Sgstper = 0;
    console.log("INVENTORY = " + JSON.stringify(this.inventory))
    for (let data of this.inventory) {
      var discgst = 0;
      var dissgst = 0;
      var cgstcal = 0;
      var sgstcal = 0;
      if (data.Rate != undefined) {
        this.totalCostForCheckedItems += data.Quantity * data.Rate;

      } else if (data.MRP != undefined) {
        this.totalCostForCheckedItems += data.Quantity * data.MRP;

      } else {
        this.totalCostForCheckedItems += data.Quantity * 0;
      }

      if (data.Rate != undefined) {
        var itemvalue = data.Quantity * data.Rate;

      } else if (data.MRP != undefined) {
        var itemvalue = data.Quantity * data.MRP;

      } else {
        var itemvalue = data.Quantity * 0;
      }

      if (data.cgst != undefined && data.cgst != null && data.cgst != "") {
        discgst = parseFloat(data.cgst)
      } else {
        discgst = 0
      }

      if (data.sgst != undefined && data.sgst != null && data.sgst != "") {
        dissgst = parseFloat(data.sgst)
      } else {
        dissgst = 0
      }
      var afterdisamt = itemvalue;
      this.Cgstper += discgst;
      this.Sgstper += dissgst;
      this.Cgst += afterdisamt * (discgst / 100)
      this.Sgst += afterdisamt * (dissgst / 100)
      var itemgst = (afterdisamt * (discgst / 100)) + (afterdisamt * (dissgst / 100));
      var total_item_value = afterdisamt + itemgst
      this.GSTamount += itemgst;
      this.GSTamount = parseFloat(this.GSTamount.toFixed(2))
      this.invoiceAmount += total_item_value;
      this.invoiceAmount = parseFloat(this.invoiceAmount.toFixed(2))

      this.refundamountcal()
      this.tcprice = (this.totalCostForCheckedItems).toFixed(2)
      this.constpriceinwords = convertNumberToWords(this.invoiceAmount, ipaddress.country_code);
    }
  }

  isDiscountOptionSelected(value) {
    if (value == "percentage") {
      this.discountpercentageflag = true
      this.discountpriceflag = false
      this.product_wise_discountflag = true
      for (var i = 0; i < this.inventory.length; i++) {
        this.inventory[i].discount = 0;
      }
      // this.GSTamount = 0;
      // this.invoiceAmount = 0;
    } else if (value == "price") {
      this.discountpriceflag = true
      this.discountpercentageflag = false
      this.product_wise_discountflag = true
      // this.GSTamount = 0;
      // this.invoiceAmount = 0;
    } else if (value == "product_wise") {
      this.product_wise_discountflag = false
      this.discountpercentageflag = false
      this.discountpriceflag = false
      // this.GSTamount = 0;
      // this.invoiceAmount = 0;
      this.discountcal()
    }
  }

  discountcal() {
    if (this.product_wise_discountflag == false) {
      this.totalCostForCheckedItems = 0;
      this.aftdiscamt = 0;
      this.GSTamount = 0;
      this.disamt = 0;
      this.invoiceAmount = 0;
      this.cgst = 0;
      this.sgst = 0;

      for (let data of this.inventory) {

        // if (data.checked) {

        if (data.Discount != undefined && data.Discount != "") {

          this.aftdiscamt = 0;
          var discgst = 0;
          var dissgst = 0;
          var cgstcal = 0;
          var sgstcal = 0;

          if (data.cgst != undefined && data.cgst != null && data.cgst != "") {
            discgst = parseFloat(data.cgst)
          } else {
            discgst = 0
          }
          if (data.sgst != undefined && data.sgst != null && data.sgst != "") {
            dissgst = parseFloat(data.sgst)
          } else {
            dissgst = 0
          }

          if (data.cost_price != undefined) {
            var itemvalue = data.Quantity * data.cost_price;
          } else if (data.MRP != undefined) {
            var itemvalue = data.Quantity * data.MRP
          }
          else {
            var itemvalue = data.Quantity * 0;
          }

          console.log("item value  = " + itemvalue)
          // var discamt = itemvalue*(data.dis  count/100)

          if (data.Discount != undefined) {
            var discamt = itemvalue * (parseFloat(data.Quantity) / 100);
          } else {
            var discamt = itemvalue * (1 / 100);
          }
          console.log("disc amt =- " + discamt)
          var afterdisamt = itemvalue - discamt
          var itemgst = (afterdisamt * (discgst / 100)) + (afterdisamt * (dissgst / 100))
          console.log("gst = " + itemgst)
          var total_item_value = afterdisamt + itemgst

          this.disamt += discamt;
          this.disamt = parseFloat(this.disamt.toFixed(2))
          this.cgst += (afterdisamt * (discgst / 100))
          this.sgst += (afterdisamt * (dissgst / 100))
          this.cgst = parseFloat(this.cgst.toFixed(2))
          this.sgst = parseFloat(this.sgst.toFixed(2))
          this.GSTamount += itemgst;
          this.GSTamount = parseFloat(this.GSTamount.toFixed(2))
          this.invoiceAmount += total_item_value;
          console.log("invoice = " + this.invoiceAmount)
          this.invoiceAmount = parseFloat(this.invoiceAmount.toFixed(2))
          this.refundamountcal()
          console.log("invoice 2 = " + this.invoiceAmount)
          this.disamt.toFixed(2);
          // this.aftdiscamt += (this.check_product_wise - disamt)
          // var cgstcal = (this.aftdiscamt * discgst / 100)
          // var sgstcal = (this.aftdiscamt * discgst / 100)
          // this.GSTamount = parseFloat((cgstcal + sgstcal).toFixed(2));
          // this.invoiceAmount += (this.aftdiscamt + cgstcal + sgstcal)
          // this.invoiceAmount = parseFloat(this.invoiceAmount.toFixed(2));
          console.log("PRICE CALCULATION " + this.aftdiscamt + " = " + cgstcal + " = " + sgstcal + " = " + this.GSTamount + " = " + (this.aftdiscamt + cgstcal + sgstcal))
        }
        // }
      }
    } else if (this.discountpercentageflag == true) {
      this.totalCostForCheckedItems = 0;
      this.aftdiscamt = 0;
      this.GSTamount = 0;
      this.disamt = 0;
      this.invoiceAmount = 0;
      this.cgst = 0;
      this.sgst = 0;
      var disamount = 0;
      for (let data of this.inventory) {
        if (this.Discount_value != undefined && this.Discount_value.length >= 1) {
          this.aftdiscamt = 0;
          var discgst = 0;
          var dissgst = 0;
          var cgstcal = 0;
          var sgstcal = 0;

          if (data.cgst != undefined && data.cgst != null && data.cgst != "") {
            discgst = parseFloat(data.cgst)
          } else {
            discgst = 0
          }
          if (data.sgst != undefined && data.sgst != null && data.sgst != "") {
            dissgst = parseFloat(data.sgst)
          } else {
            dissgst = 0
          }
          console.log("DETAILS " + data.quantity + " === " + data.cost_price + " === " + (data.quantity * data.cost_price))


          if (data.cost_price != undefined) {
            var itemvalue = data.Quantity * data.cost_price;
          } else if (data.MRP != undefined) {
            var itemvalue = data.Quantity * data.MRP
          }
          else {
            var itemvalue = data.Quantity * 0;
          }

          var discamt = itemvalue * (this.Discount_value / 100)
          var afterdisamt = itemvalue - discamt;
          var itemgst = (afterdisamt * (discgst / 100)) + (afterdisamt * (dissgst / 100));
          var total_item_value = afterdisamt + itemgst;

          this.disamt += discamt;
          this.disamt = parseFloat(this.disamt.toFixed(2))
          this.cgst += (afterdisamt * (discgst / 100))
          this.sgst += (afterdisamt * (dissgst / 100))
          this.cgst = parseFloat(this.cgst.toFixed(2))
          this.sgst = parseFloat(this.sgst.toFixed(2))
          this.GSTamount += itemgst;
          this.GSTamount = parseFloat(this.GSTamount.toFixed(2))
          this.invoiceAmount += total_item_value;
          this.invoiceAmount = parseFloat(this.invoiceAmount.toFixed(2))
          this.refundamountcal()

          this.disamt.toFixed(2);

          console.log("PRICE CALCULATION " + afterdisamt + " = " + discamt + " = " + itemgst + " = " + total_item_value)

        } else {
          this.updateTotalCost()
        }
      }
    }

  }

  spliceDecimal(number) {
    // Convert the number to a string
    let numberStr = number.toString();
    // Split the number into integer and decimal parts
    let parts = numberStr.split('.');
    // Check if there's a decimal part

    if (parts.length > 1) {
      // Take the first two digits of the decimal part
      let newDecimal = parts[1].slice(0, 2);
      // Combine the integer part with the truncated decimal part
      var newNumberStr = parts[0] + '.' + newDecimal;
      // Convert back to a number
      return parseFloat(newNumberStr);

    } else {
      // If there's no decimal part, return the original number
      return number;
    }
    // 
  }

  refundamountcal() {
    console.log("scenario 1= " + this.returnAmount + " " + this.invoiceAmount)

    if (this.returnAmount > this.invoiceAmount) {
      this.balance = this.invoiceAmount - this.returnAmount;
      this.balance = this.spliceDecimal(this.balance)
      if (this.invoiceAmount != 0) {
        this.receivedAmount = this.invoiceAmount;
      }
      this.invoiceAmountbe = 0;
    } else {
      console.log("scenario 2 = " + this.returnAmount + " " + this.invoiceAmount)
      this.invoiceAmountbe = (this.invoiceAmount - this.returnAmount)
      this.invoiceAmountbe = this.spliceDecimal(this.invoiceAmountbe)
      this.balance = 0;
    }
    console.log("return amount = " + this.returnAmount)
  }

  clearSupplier() {
    this.manufacturer = undefined;
    this.productdata_detal = [];
    this.productArray = [];
    this.poorder = [];
  }

  getSuppliers() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "inv/gsup/",
      JSON.stringify({
        pharmacy_id: this.pharmacyID,
        supp_name: this.manufacturer

      }), { headers: headers }).subscribe(
        data => {
          var obj = data.json();
          console.log("supplier name  = " + JSON.stringify(obj))
          for (var i = 0; i < obj.supplier_details.length; i++) {
            this.supplierList.push({
              name: obj.supplier_details[i].name,
              supp_id: obj.supplier_details[i].supp_id,
              supp_code: obj.supplier_details[i].supp_code,
              gst_no: obj.supplier_details[i].gst_no,
              refund_amount: obj.supplier_details[i].refund_amount,
              product_returns_id: obj.supplier_details[i].product_returns_id,
              received_amount: obj.supplier_details[i].received_amount,
              inv_col_order: obj.supplier_details[i].inv_col_order,
              inv_col_order_actual:obj.supplier_details[i].inv_col_order_actual
            })
          }
        },
        error => {
          this.toastr.error(Message_data.unabletofetsupl);
        }
      )
  }

  supplier_changee() {
    const selectedSupplier = this.supplierList.find(supplier => supplier.name === this.manufacturer);
    if (selectedSupplier) {
      this.suppID = selectedSupplier.supp_id;
      this.gst_no = selectedSupplier.gst_no;
      if (selectedSupplier.refund_amount != undefined) {
        this.returnAmount = parseFloat(selectedSupplier.refund_amount);
      } else {
        this.returnAmount = 0;
      }
      this.product_returns_id = selectedSupplier.product_returns_id;
      if (selectedSupplier.received_amount != undefined && selectedSupplier.received_amount != "") {
        this.receivedamoutsupp = parseFloat(selectedSupplier.refund_amount);
      } else {
        this.receivedamoutsupp = 0;
      }

      if (selectedSupplier.inv_col_order != undefined) {
        this.columnOrder(selectedSupplier.inv_col_order,selectedSupplier.inv_col_order_actual);
      }
    }

    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/gpbs",
      JSON.stringify({
        supp_id: this.suppID
      }), { headers: headers }).subscribe(
        data => {
          this.productArray = [];
          this.productdata_detal = [];
          // this.inventory = [];
          this.tcprice = "0";
          this.constpriceinwords = "zero";
          this.updateTotalCost()
          var obj = data.json();
          console.log("supplier change = " + JSON.stringify(obj));

          for (var i = 0; i < obj.po_details.length; i++) {
            this.productArray.push({
              po_id: obj.po_details[i].po_id,
              purchase_order_id: obj.po_details[i].purchase_order_id,
              date: obj.po_details[i].po_date,
              checked: false
            })
            this.productdata_detal.push({
              po_id: obj.po_details[i].po_id,
              date: obj.po_details[i].po_date,
              checked: false
            })
          }
          console.log("supplier data = " + JSON.stringify(obj))
        }, error => {
          console.log("error in retriveing the supplier name")
        }
      )
  }

  getPurchaseDetails() {
    this.purchasePoID = "";
    this.purchasePoID = this.poNO;

    if (this.poNO != undefined) {
      for (var j = 0; j < this.productArray.length; j++) {
        if (this.poNO == this.productArray[j].purchase_order_id) {
          this.pobase = this.productArray[j].po_base
        }
      }
    }
    this.inventrybase_department()
  }

  getsupplierid() {
    this.purchasePoID = "";
    this.purchasePoID = this.poNO;
    if (this.poNO != undefined) {
      for (var j = 0; j < this.productArray.length; j++) {
        if (this.poNO == this.productArray[j].po_id) {
          this.pobase = this.productArray[j].po_base
        }
      }
    }
    var inventorydatasenddata = {
      purchase_order_id: this.poNO, department_id: this.inventrydepartname,
      store_id: this.inventrystorename,
      po_base: this.pobase
    }
    console.log("inverntry " + JSON.stringify(inventorydatasenddata))
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/gpo/", JSON.stringify({
      purchase_order_id: this.poNO,

    }), { headers: headers }).subscribe(
      data => {
        if (data.json() != null) {
          var obj = data.json();
          console.log("porder" + JSON.stringify(obj))
          this.mgfID = obj.mfg_id;
          this.suppID = obj.supp_id;
          this.manufacturer = obj.name;
          this.gst_no = obj.gst_no;
          this.countryID = obj.country;
          var index = 0;
        }
      },
    )
  }

  inventrybase_department() {
    var senddata = {
      po_base: this.pobase,
      po_id: this.poNO
    }
    console.log("pharmacy Id =" + JSON.stringify(senddata))
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "gen/gdpo ", JSON.stringify({ po_base: this.pobase, po_id: this.poNO, type: this.user_type }), { headers: headers }).subscribe(
      response => {
        var obj = response.json()
        console.log(" DEPARTMENT DATA" + JSON.stringify(obj))
        this.inventdepartmentarray = obj.departments;
      }
    )
  }

  isAllSelectedPO(po, checked) {
    console.log("product details  = " + JSON.stringify(this.productdata_detal))
    var poorder: any = [];
    for (var i = 0; i < this.productdata_detal.length; i++) {
      if (po.po_id == this.productdata_detal[i].po_id) {
        if (checked == true) {

          this.poorder = this.poorder.filter(orderId => orderId !== po.po_id);
          if (this.poorder.length == 0) {
            this.inputsupplierflag = true;
            this.supplierflag = false;
          }

        } else {
          this.poorder.push(this.productdata_detal[i].po_id);
          this.podataid = this.productdata_detal[i].po_id;
          this.supplierflag = true;
          this.inputsupplierflag = false;
          console.log("SELECTED PO " + JSON.stringify(this.poorder))
        }
      }
    }
  }

  checkboxfunction() {
    if (this.filtercheckbox == true) {
      this.checkboxarry = this.productArray;
      this.productArray = [];
      this.productdata_detal = [];
      for (var i = 0; i < this.checkboxarry.length; i++) {
        this.productArray.push({
          purchase_order_id: this.checkboxarry[i].purchase_order_id,
          po_id: this.checkboxarry[i].po_id,
          date: this.checkboxarry[i].date,
          checked: true
        })
        this.productdata_detal.push({
          purchase_order_id: this.checkboxarry[i].purchase_order_id,
          po_id: this.checkboxarry[i].po_id,
          date: this.checkboxarry[i].date,
          checked: true
        })
      }
      this.selectallpoorder(true);

    } else if (this.filtercheckbox == false) {
      this.checkboxarry = this.productArray
      this.productArray = [];
      this.productdata_detal = [];
      for (var i = 0; i < this.checkboxarry.length; i++) {
        this.productArray.checked = false;
        this.productdata_detal.checked = false;

        this.productArray.push({
          purchase_order_id: this.checkboxarry[i].purchase_order_id,
          po_id: this.checkboxarry[i].po_id,
          date: this.checkboxarry[i].date,
          checked: false
        })

        this.productdata_detal.push({
          purchase_order_id: this.checkboxarry[i].purchase_order_id,
          po_id: this.checkboxarry[i].po_id,
          date: this.checkboxarry[i].date,
          checked: false
        })
      }
      this.selectallpoorder(false);
    }
  }

  selectallpoorder(value) {
    this.poorder = [];
    if (value == true) {
      for (var k = 0; k < this.productdata_detal.length; k++) {
        this.poorder.push(this.productdata_detal[k].po_id);
      }
    }
  }
}
