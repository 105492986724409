import { ReturnStatement } from "@angular/compiler";

export class Doc_Helper {
    //AppFlow
    static AppFlow;
    static setAppFlow(value: any) {
        this.AppFlow = null;
        this.AppFlow = value;
    }
    static getAppFlow() {
        return this.AppFlow;
    }

    static mrno;
    static setMrno(value: any) {
        this.mrno = null;
        this.mrno = value;
    }
    static getMrno() {
        return this.mrno;
    }

    //Medicare Name
    static medname;
    static setMedicare_name(value: any) {
        this.medname = null;
        this.medname = value;
    }
    static getMedicare_name() {
        return this.medname;
    }

    //Hospital_Logo
    static hospital_logo;
    static setHospital_logo(value: any) {
        this.hospital_logo = null;
        this.hospital_logo = value;
    }
    static getHospital_logo() {
        return this.hospital_logo;
    }

    //Hospital_Name
    static hospital_name;
    static setHospital_name(value: any) {
        this.hospital_name = null;
        this.hospital_name = value;
    }
    static getHospital_name() {
        return this.hospital_name;
    }

    //Hospital_Pres_Logo
    static hospital_pres_logo;
    static setHospital_pres_logo(value: any) {
        this.hospital_pres_logo = null;
        this.hospital_pres_logo = value;
    }
    static getHospital_pres_logo() {
        return this.hospital_pres_logo;
    }

    //Hospital_bg_image
    static hospital_bg_image;
    static setHospital_bg_image(value: any) {
        this.hospital_bg_image = null;
        this.hospital_bg_image = value;
    }
    static getHospital_bg_image() {
        return this.hospital_bg_image;
    }

    //Hospital_print_template
    static hospital_print_template;
    static setHospital_print_template(value: any) {
        this.hospital_print_template = null;
        this.hospital_print_template = value;
    }
    static getHospital_print_template() {
        return this.hospital_print_template;
    }
    //Hospital clinical id 
    static clinicalId;
    static setHospitalClinicalId(value: any) {
        this.clinicalId = null;
        this.clinicalId = value;
    }
    static getHospitalClinicalId() {
        return this.clinicalId;
    }
    //Hospital_location
    static hospital_location;
    static setHospital_location(value: any) {
        this.hospital_location = null;
        this.hospital_location = value;
    }
    static getHospital_location() {
        return this.hospital_location;
    }

    //Hospital_address
    static hospital_address;
    static setHospital_address(value: any) {
        this.hospital_address = null;
        this.hospital_address = value;
    }
    static getHospital_address() {
        return this.hospital_address;
    }

    //Hospital_city_zip
    static hospital_city_zip;
    static setHospital_city_zip(value: any) {
        this.hospital_city_zip = null;
        this.hospital_city_zip = value;
    }
    static getHospital_city_zip() {
        return this.hospital_city_zip;
    }

    //Hospital_state_country
    static hospital_state_country;
    static setHospital_state_country(value: any) {
        this.hospital_state_country = null;
        this.hospital_state_country = value;
    }
    static getHospital_state_country() {
        return this.hospital_state_country;
    }

    //Client_Info
    static client_info;
    static setClient_Info(value: any) {
        this.client_info = null;
        this.client_info = value;
    }
    static getClient_Info() {
        return this.client_info;
    }

    //Client_Name
    static client_name;
    static setClient_name(value: any) {
        this.client_info = null;
        this.client_info = value;
    }
    static getClient_name() {
        return this.client_info;
    }

    //Client_Age
    static client_age;
    static setClient_age(value: any) {
        this.client_age = null;
        this.client_age = value;
    }
    static getClient_age() {
        return this.client_age;
    }

    //Client_Age
    static client_gender;
    static setClient_gender(value: any) {
        this.client_gender = null;
        this.client_gender = value;
    }
    static getClient_gender() {
        return this.client_gender;
    }

    static Opnid;
    static setOpnid(value: any) {
        this.Opnid = null;
        this.Opnid = value;
    }
    static getOpnid() {
        return this.Opnid;
    }

    //App_id
    static app_id;
    static setApp_id(value: any) {
        this.app_id = null;
        this.app_id = value;
    }
    static getApp_id() {
        return this.app_id;
    }

    //Spec_id
    static spl_id;
    static setSpl_id(value: any) {
        this.spl_id = null;
        this.spl_id = value;
    }
    static getSpl_id() {
        return this.spl_id;
    }

    //Spl_name
    static spl_name;
    static setSpl_name(value: any) {
        this.spl_name = null;
        this.spl_name = value;
    }
    static getSpl_name() {
        return this.spl_name;
    }

    //Client_id
    static client_id;
    static setClient_id(value: any) {
        this.client_id = null;
        this.client_id = value;
    }
    static getClient_id() {
        return this.client_id;
    }

    //Rel_id
    static rel_id;
    static setRel_id(value: any) {
        this.rel_id = null;
        this.rel_id = value;
    }
    static getRel_id() {
        return this.rel_id;
    }

    //Sub_id
    static sub_id;
    static setSub_id(value: any) {
        this.sub_id = null;
        this.sub_id = value;
    }
    static getSub_id() {
        return this.sub_id;
    }

    //Walk-in-prescription
    static walkinpres;
    static setWalkinPrescription(value: any) {
        this.walkinpres = null;
        this.walkinpres = value;
    }
    static getWalkinPrescription() {
        return this.walkinpres;
    }

    //Walk-in-pass
    static walkin;
    static setWalkin(value: any) {
        this.walkin = null;
        this.walkin = value;
    }
    static getWalkin() {
        return this.walkin;
    }

    //refer_by
    static refer_by;
    static setRefer_by(value: any) {
        this.refer_by = null;
        this.refer_by = value;
    }
    static getRefer_by() {
        return this.refer_by;
    }

    static billno;
    static setdoctorbillno(value: any) {
        this.billno = null;
        this.billno = value;
    }
    static getdoctorbillno() {
        // this.billno=null;
        return this.billno
    }
    static finalamountbillcreate;

    static setfinalamountbillcreate(value: any) {
        this.finalamountbillcreate = null;
        this.finalamountbillcreate = value;
    }
    static getfinalamountbillcreate() {
        return this.finalamountbillcreate
    }
    static billid_create;
    static setbillid_create(value: any) {
        this.billid_create = null;
        this.billid_create = value;

    }
    static getbillid_create() {
        return this.billid_create
    }
    static viewbills;
    static setviewbills(value) {
        this.viewbills = null;
        this.viewbills = value;
    }
    static getviewbills() {
        return this.viewbills
    }

    //AppFlow




    //opn_doc_id
    static opn_doc_id;
    static setOpn_doc_id(value: any) {
        this.opn_doc_id = null;
        this.opn_doc_id = value;
    }
    static getOpn_doc_id() {
        return this.opn_doc_id;
    }

    // Appoint_Date
    static appt_date;
    static setAppoint_Date(value: any) {
        this.appt_date = null;
        this.appt_date = value;
    }
    static getAppoint_Date() {
        return this.appt_date;
    }

    // Disable_Discharge_Summary
    static dis_summary;
    static setDischarge_Summary(value: any) {
        this.dis_summary = null;
        this.dis_summary = value;
    }
    static getDischarge_Summary() {
        return this.dis_summary;
    }

    // Set_Home_care
    static home_care;
    static setHome_care(value: any) {
        this.home_care = null;
        this.home_care = value;
    }
    static getHome_care() {
        return this.home_care;
    }

    // Set_Symptoms_patient_list
    static symp;
    static setSymptoms(value: any) {
        this.symp = null;
        this.symp = value;
    }
    static getSymptoms() {
        return this.symp;
    }
    //IPAddress
    static ipaddress;
    static setIPAddress(value: any) {
        this.ipaddress = null;
        this.ipaddress = value;
    }
    static getIPAddress() {
        return this.ipaddress;
    }
    //Firstname
    static fname;
    static setFirstname(value: any) {
        this.fname = null;
        this.fname = value;
    }
    static getFirstname() {
        return this.fname;
    }

    //Middlename
    static mname;
    static setMiddlename(value: any) {
        this.mname = null;
        this.mname = value;
    }
    static getMiddlename() {
        return this.mname;
    }

    //lastname
    static lname;
    static setLastname(value: any) {
        this.lname = null;
        this.lname = value;
    }
    static getLastname() {
        return this.lname;
    }

    //Client_type
    static client_type;
    static setClient_type(value: any) {
        this.client_type = null;
        this.client_type = value;
    }
    static getClient_type() {
        return this.client_type;
    }

    //Mobile_no
    static mobile_no;
    static setMobile_no(value: any) {
        this.mobile_no = null;
        this.mobile_no = value;
    }
    static getMobile_no() {
        return this.mobile_no;
    }

    //setInpatientId
    static InpatientId;
    static setInpatientId(value: any) {
        this.InpatientId = null;
        this.InpatientId = value;
    }
    static getInpatientId() {
        return this.InpatientId;
    }

    //SearchFlag
    static searchFlag;
    static setSearchFlag(value: any) {
        this.searchFlag = null;
        this.searchFlag = value;
    }
    static getSearchFlag() {
        return this.searchFlag;
    }

    //Admission_id
    static admission_id;
    static setAdmission_id(value: any) {
        this.searchFlag = null;
        this.searchFlag = value;
    }
    static getAdmission_id() {
        return this.searchFlag;
    }

    //inpat-prescription
    static inpat_pres;
    static setInpatPrescription(value: any) {
        this.inpat_pres = null;
        this.inpat_pres = value;
    }
    static getInpatPrescription() {
        return this.inpat_pres;
    }

    //HomeCare
    static homecare;
    static setHomeCare(value: any) {
        this.homecare = null;
        this.homecare = value;
    }
    static getHomeCare() {
        return this.homecare;
    }

    static bgimage
    static getHospBgImage() {
        return this.bgimage
    }
    static setHospBgImage(value: any) {
        this.bgimage = null;
        this.bgimage = value;
    }


    static hospitalfootervalue;
    static getHospFooter() {
        return this.hospitalfootervalue
    }
    static setHospFooter(value: any) {
        this.hospitalfootervalue = null;
        this.hospitalfootervalue = value;
    }

    //Hospital_Pres_Logo
    static hosplogo;
    static setHospLogo(value: any) {
        this.hosplogo = null;
        this.hosplogo = value;
    }
    static getHospLogo() {
        return this.hosplogo;
    }


    // setgyna_fields
    static gyna_fields;
    static setGyna_fields(value: any) {
        this.gyna_fields = null;
        this.gyna_fields = value;
    }
    static getGyna_fields() {
        return this.gyna_fields;
    }

    // medical_hist
    static medical_hist;
    static setMedical_hist(value: any) {
        this.medical_hist = null;
        this.medical_hist = value;
    }
    static getMedical_hist() {
        return this.medical_hist;
    }

    //setSkin_types
    static skin_types;
    static setSkin_types(value: any) {
        this.skin_types = null;
        this.skin_types = value;
    }
    static getSkin_types() {
        return this.skin_types;
    }

    //setHearing_types
    static hearing_types;
    static setHearing_types(value: any) {
        this.hearing_types = null;
        this.hearing_types = value;
    }
    static getHearing_types() {
        return this.hearing_types;
    }

    //setAbdomen_types
    static abdomen_types;
    static setAbdomen_types(value: any) {
        this.abdomen_types = null;
        this.abdomen_types = value;
    }
    static getAbdomen_types() {
        return this.abdomen_types;
    }

    //setEye_types
    static eye_types;
    static setEye_types(value: any) {
        this.eye_types = null;
        this.eye_types = value;
    }
    static getEye_types() {
        return this.eye_types;
    }

    //setDoctorAddr
    static doctorAddr;
    static setDoctorAddr(value: any) {
        this.doctorAddr = null;
        this.doctorAddr = value;
    }
    static getDoctorAddr() {
        return this.doctorAddr;
    }

    //setPersonalHist
    static hist;
    static setPersonalHist(value: any) {
        this.hist = null;
        this.hist = value;
    }
    static getPersonalHist() {
        return this.hist;
    }
    // //Hospital_bg_image
    // static hospital_bg_image1;
    // static setHospBgImage(value:any){
    //     this.hospital_bg_image1 = null;
    //     this.hospital_bg_image1 = value;
    // }
    // static getHospBgImage(){
    //     return this.hospital_bg_image1;
    // }
    static dentalpersonal_spl_id;
    static setDen_spl_id(value: any) {
        this.dentalpersonal_spl_id = null;
        this.dentalpersonal_spl_id = value;

    }
    static getDen_spl_id(value: any) {
        return this.dentalpersonal_spl_id;

    }


    //diab reading
    static readingvalue;
    static setReading(value: any) {
        this.readingvalue = null;
        this.readingvalue = value;
    }
    static getReading() {
        return this.readingvalue
    }


    static casesheet_hist;
    static setCasesheetPersonal(value: any) {
        this.casesheet_hist = null;
        this.casesheet_hist = value;

    }
    static getCasesheetPersonal() {
        return this.casesheet_hist;
    }


    static send_dental_basic_data;
    static Set_send_dental_basic_data(value: any) {
        this.send_dental_basic_data = null;
        this.send_dental_basic_data = value;
    }
    static Get_send_dental_basic_data() {
        return this.send_dental_basic_data;
    }

    static perio_chart_data;
    static Set_perio_chart_data(value: any) {
        this.perio_chart_data = null;
        this.perio_chart_data = value;
    }
    static Get_perio_chart_data() {
        return this.perio_chart_data;
    }

    static diabfoot;
    static setdiabfoot(value: any) {
        this.diabfoot = null;
        this.diabfoot = value;
    }
    static getdiabfoot() {
        return this.diabfoot;
    }

    static complication;
    static setcomplication(value: any) {
        this.complication = null;
        this.complication = value;
    }
    static getcomplication() {
        return this.complication;
    }

    static diab_reading_data;
    static set_diab_reading_data(value: any) {
        this.diab_reading_data = null;
        this.diab_reading_data = value;
    }
    static get_diab_reading_data() {
        return this.diab_reading_data;
    }

    static dental_spl_name;
    static set_dental_spl_name(value: any) {
        this.dental_spl_name = null;
        this.dental_spl_name = value;
    }
    static get_dental_spl_name() {
        return this.dental_spl_name;
    }

    static den_cli_exam;
    static Set_den_cli_exam(value: any) {
        this.den_cli_exam = null;
        this.den_cli_exam = value;
    }
    static Get_den_cli_exam() {
        return this.den_cli_exam;
    }

    static den_ret1;
    static Set_den_ret1(value: any) {
        this.den_ret1 = null;
        this.den_ret1 = value;
    }
    static Get_den_ret1() {
        return this.den_ret1;
    }


    static client_view_app_data;
    static Set_client_view_app_data(value: any) {
        this.client_view_app_data = null;
        this.client_view_app_data = value;
    }
    static Get_client_view_app_data() {
        return this.client_view_app_data;
    }

    // static den_ret2;
    // static Set_den_ret2(value:any){
    //     this.den_ret2=null;
    //     this.den_ret2=value;
    // }
    // static Get_den_ret2(){
    //     return this.den_ret2;
    // }

    // static den_ret1;
    // static Set_den_ret1(value:any){
    //     this.den_ret1=null;
    //     this.den_ret1=value;
    // }
    // static Get_den_ret1(){
    //     return this.den_ret1;
    // }
    static physio_case_hist_id;
    static getCaseHistId() {
        return this.physio_case_hist_id;
    }
    static setCaseHistId(value: any) {
        this.physio_case_hist_id = null;
        this.physio_case_hist_id = value;
    }

    static life_style_id;
    static getLifeStyleId() {
        return this.life_style_id;
    }
    static setLifeStyleId(value) {
        this.life_style_id = null;
        this.life_style_id = value;
    }

    static life_id;
    static getLife_style_id() {
        return this.life_id;
    }
    static setLife_style_id(value) {
        this.life_id = null;
        this.life_id = value;
    }

    setNotificationCount
    static NotificationCount;
    static getNotificationCount() {
        return this.NotificationCount;
    }
    static setNotificationCount(value) {
        this.NotificationCount = null;
        this.NotificationCount = value;
    }

    //dental

    static spl;
    static Set_den_spl(value: any) {
        this.spl = null;
        this.spl = value;
    }
    static get_den_spl() {
        return this.spl;
    }

    static dental_reff;
    static Set_dental_reff_data(value: any) {
        this.dental_reff = null;
        this.dental_reff = value;
    }
    static Get_dental_reff_data() {
        return this.dental_reff;
    }

    static genden_spl;
    static get_dent_spl() {
        return this.genden_spl;
    }
    static Set_dent_spl(value: any) {
        this.genden_spl = null;
        this.genden_spl = value;
    }

    static per_hist: any;
    static Set_den_per_hist(value: any) {
        this.per_hist = null;
        this.per_hist = value;
    }
    static Get_den_per_hist() {
        return this.per_hist;
    }

    static den_treat_plan;
    static Set_den_treat_plan(value: any) {
        this.den_treat_plan = null;
        this.den_treat_plan = value;
    }
    static Get_den_treat_plan() {
        return this.den_treat_plan;
    }

    static material_data: any;
    static Set_material_data(value: any) {
        this.material_data = null;
        this.material_data = value;
    }
    static Get_material_data() {
        return this.material_data;
    }

    static den_spl_natal;
    static Set_den_spl_natal(value: any) {
        this.den_spl_natal = null;
        this.den_spl_natal = value;
    }
    static Get_den_spl_natal() {
        return this.den_spl_natal;
    }

    static casesumret;
    static setcasesumret(value: any) {
        this.casesumret = null;
        this.casesumret = value;
    }
    static getcasesumret() {
        return this.casesumret;
    }

    static dental_spl;
    static Set_den_spl_dental(value: any) {
        this.dental_spl = null;
        this.dental_spl = value;
    }
    static Get_den_spl_dental() {
        return this.dental_spl;
    }

    // static den_spl_birth;
    // static Set_den_spl_birth(value:any){
    //     this.den_spl_birth = null;
    //     this.den_spl_birth = value;
    // }
    // static Get_den_spl_birth() {
    //     return this.den_spl_birth;
    // }

    static den_spl_general;
    static Set_den_spl_general(value: any) {
        this.den_spl_general = null;
        this.den_spl_general = value;
    }
    static Get_den_spl_general() {
        return this.den_spl_general;
    }

    // static den_spl_gums;
    // static Set_den_spl_gums(value:any){
    //     this.den_spl_gums = null;
    //     this.den_spl_gums = value;
    // }
    // static Get_den_spl_gums() {
    //     return this.den_spl_gums;
    // }

    //setAnemic_array

    static anemic;
    static setAnemic_array(value: any) {
        this.anemic = null;
        this.anemic = value;
    }
    static getAnemic_array() {
        return this.anemic;
    }

    //setDelivery_array
    static delivery;
    static setDelivery_array(value: any) {
        this.delivery = null;
        this.delivery = value;
    }
    static getDelivery_array() {
        return this.delivery;
    }

    //m.p
    static pharmacyid;
    static setpharmaid(value: any) {
        this.pharmacyid = null;
        this.pharmacyid = value;
    }
    static getpharmaid() {
        return this.pharmacyid;
    }

    static clinic;
    static sethptl_clinic_id(value: any) {
        this.clinic = null;
        this.clinic = value;
    }
    static gethptl_clinic_id() {
        return this.clinic;
    }

    //setGynoInpatDS
    static gynoinpat;
    static setGynoInpatDS(value: any) {
        this.gynoinpat = null;
        this.gynoinpat = value;
    }
    static getGynoInpatDS() {
        return this.gynoinpat;
    }

    //setGynoMedPres
    static gynoinpatmed;
    static setGynoMedPres(value: any) {
        this.gynoinpatmed = null;
        this.gynoinpatmed = value;
    }
    static getGynoMedPres() {
        return this.gynoinpatmed;
    }
    //set doctor referal for other spaecility
    static Referal;
    static setReferal(value: any) {
        this.Referal = null;
        this.Referal = value;
    }
    static getReferal() {
        return this.Referal;
    }

    static cardiodis_array;
    static getCardioDis() {
        return this.cardiodis_array;
    }
    static setCardioDis(value) {
        this.cardiodis_array = null;
        this.cardiodis_array = value;
    }

    static medicalproc_array;
    static getMedicalProc() {
        return this.medicalproc_array;
    }
    static setMedicalProc(value) {
        this.medicalproc_array = null;
        this.medicalproc_array = value;
    }

    static physcial_exercise;
    static getPhysicalExercise() {
        return this.physcial_exercise;
    }
    static setPhysicalExercise(value) {
        this.physcial_exercise = null;
        this.physcial_exercise = value;
    }

    static yoga_array;
    static getYogaTypes() {
        return this.yoga_array;
    }
    static setYogaTypes(value) {
        this.yoga_array = null;
        this.yoga_array = value;
    }

    static Gyno_Get_diseases
    static getGyno_Get_diseases() {
        return this.Gyno_Get_diseases;
    }
    static setGyno_Get_diseases(value) {
        this.Gyno_Get_diseases = null;
        this.Gyno_Get_diseases = value;
    }

    static Diab_tratment_type
    static getDiab_tratment_type() {
        return this.Diab_tratment_type;
    }
    static setDiab_tratment_type(value) {
        this.Diab_tratment_type = null;
        this.Diab_tratment_type = value;
    }


    static spl_array
    static getSpecializationsMasterArray() {
        return this.spl_array;
    }
    static setSpecializationsMasterArray(value) {
        this.spl_array = null;
        this.spl_array = value;
    }

    static Others_tratment_type;
    static getOthers_tratment_type() {
        return this.Others_tratment_type;
    }
    static setOthers_tratment_type(value) {
        this.Others_tratment_type = null;
        this.Others_tratment_type = value;
    }


    static Gyno_Get_Stage;
    static getGyno_Get_Stage() {
        return this.Gyno_Get_Stage;
    }
    static setGyno_Get_Stage(value) {
        this.Gyno_Get_Stage = null;
        this.Gyno_Get_Stage = value;
    }


    static Get_character_ofpain;
    static getGyno_Get_character_ofpain() {
        return this.Get_character_ofpain;
    }
    static setGyno_Get_character_ofpain(value) {
        this.Get_character_ofpain = null;
        this.Get_character_ofpain = value;
    }

    static cpainloc_array;
    static setChestpainLocations(value) {
        this.cpainloc_array = null;
        this.cpainloc_array = value;
    }
    static getChestpainLocations() {
        return this.cpainloc_array;
    }

    static cpaincar_array;
    static getChestpainCharacters() {
        return this.cpaincar_array;
    }
    static setChestpainCharacters(value) {
        this.cpaincar_array = null;
        this.cpaincar_array = value;
    }

    static cpainrad_array;
    static getChestpainRadiations() {
        return this.cpainrad_array;
    }
    static setChestpainRadiations(value) {
        this.cpainrad_array = null;
        this.cpainrad_array = value;
    }

    static cpainagrel_array;
    static getChestpainAgrelFactors() {
        return this.cpainagrel_array;
    }
    static setChestpainAgrelFactors(value) {
        this.cpainagrel_array = null;
        this.cpainagrel_array = value;
    }

    static cpainasympt_array;
    static getChestpainAssociatedSymptoms() {
        return this.cpainasympt_array;
    }
    static setChestpainAssociatedSymptoms(value) {
        this.cpainasympt_array = null;
        this.cpainasympt_array = value;
    }

    static cpaincause_array;
    static getChestpainCauses() {
        return this.cpaincause_array;
    }
    static setChestpainCauses(value) {
        this.cpaincause_array = null;
        this.cpaincause_array = value;
    }

    static btrig_array;
    static getBreathingTriggers() {
        return this.btrig_array;
    }
    static setBreathingTriggers(value) {
        this.btrig_array = null;
        this.btrig_array = value;
    }

    static bonset_array;
    static getBreathingOnsets() {
        return this.bonset_array;
    }
    static setBreathingOnsets(value) {
        this.bonset_array = null;
        this.bonset_array = value;
    }

    static palp_array;
    static getPalpitationTypes() {
        return this.palp_array;
    }
    static setPalpitationTypes(value) {
        this.palp_array = null;
        this.palp_array = value;
    }

    static mucus_array;
    static getMucusTypes() {
        return this.mucus_array;
    }
    static setMucusTypes(value) {
        this.mucus_array = null;
        this.mucus_array = value;
    }


    static Get_frequency;
    static getGyno_Get_frequency() {
        return this.Get_frequency;
    }
    static setGyno_Get_frequency(value) {
        this.Get_frequency = null;
        this.Get_frequency = value;
    }

    static Get_type_contraceptive;
    static getGyno_Get_type_contraceptive() {
        return this.Get_type_contraceptive;
    }
    static setGyno_Get_type_contraceptive(value) {
        this.Get_type_contraceptive = null;
        this.Get_type_contraceptive = value;
    }

    static Gyno_Get_infection;
    static getGyno_Get_infection() {
        return this.Gyno_Get_infection;
    }
    static setGyno_Get_infection(value) {
        this.Gyno_Get_infection = null;
        this.Gyno_Get_infection = value;
    }

    static Get_Medical_history;
    static getGyno_Get_Medical_history() {
        return this.Get_Medical_history;
    }
    static setGyno_Get_Medical_history(value) {
        this.Get_Medical_history = null;
        this.Get_Medical_history = value;
    }

    static Get_present_illness;
    static getGyno_Get_present_illness() {
        return this.Get_present_illness;
    }
    static setGyno_Get_present_illness(value) {
        this.Get_present_illness = null;
        this.Get_present_illness = value;
    }

    //getDataArray
    static DataArray;
    static getDataArray() {
        return this.DataArray;
    }
    static setDataArray(value) {
        this.DataArray = null;
        this.DataArray = value;
    }

    //setSelectedDataList
    static SelectedDataList;
    static getSelectedDataList() {
        return this.SelectedDataList;
    }
    static setSelectedDataList(value) {
        this.SelectedDataList = null;
        this.SelectedDataList = value;
    }

    //setDiagAppflow
    static DiagAppflow;
    static getDiagAppflow() {
        return this.DiagAppflow;
    }
    static setDiagAppflow(value) {
        this.DiagAppflow = null;
        this.DiagAppflow = value;
    }

    //setViewCasesheet
    static ViewCasesheet;
    static getViewCasesheet() {
        return this.ViewCasesheet;
    }
    static setViewCasesheet(value) {
        this.ViewCasesheet = null;
        this.ViewCasesheet = value;
    }

    //setCasesheet_flag
    static Casesheet_flag;
    static getCasesheet_flag() {
        return this.Casesheet_flag;
    }
    static setCasesheet_flag(value) {
        this.Casesheet_flag = null;
        this.Casesheet_flag = value;
    }

    //setCasesumm_flag
    static Casesumm_flag;
    static getCasesumm_flag() {
        return this.Casesumm_flag;
    }
    static setCasesumm_flag(value) {
        this.Casesumm_flag = null;
        this.Casesumm_flag = value;
    }

    //setModuleList
    static ModuleList;
    static getModuleList() {
        return this.ModuleList;
    }
    static setModuleList(value) {
        this.ModuleList = null;
        this.ModuleList = value;
    }

    //setDiagSave
    static DiagSave;
    static getDiagSave() {
        return this.DiagSave;
    }
    static setDiagSave(value) {
        this.DiagSave = null;
        this.DiagSave = value;
    }

    //setMedSave
    static MedSave;
    static getMedSave() {
        return this.MedSave;
    }
    static setMedSave(value) {
        this.MedSave = null;
        this.MedSave = value;
    }

    //setMedDiag
    static MedDiag;
    static getMedDiag() {
        return this.MedDiag;
    }
    static setMedDiag(value) {
        this.MedDiag = null;
        this.MedDiag = value;
    }
    // get application date
    static appDate;
    static getAppDate() {

        return this.appDate;
    }
    static setAppDate(value) {
        this.appDate = null;
        this.appDate = value;
    }

    // get application date - 2
    static appDate_2;
    static getAppointmentDate() {

        return this.appDate_2;
    }
    static setAppointmentDate(value) {
        this.appDate_2 = null;
        this.appDate_2 = value;
    }

    //setMedicalHeading
    static MedicalHeading;
    static getMedicalHeading() {
        return this.MedicalHeading;
    }
    static setMedicalHeading(value) {
        this.MedicalHeading = null;
        this.MedicalHeading = value;
    }

    //send sugery details
    static sugeryDetails;
    static getSugeryDetails() {
        return this.sugeryDetails;
    }
    static setSugeryDetails(value) {
        this.sugeryDetails = null;
        this.sugeryDetails = value;
    }

    //setBillFromDate
    static BillFromDate;
    static getBillFromDate() {
        return this.BillFromDate;
    }
    static setBillFromDate(value) {
        this.BillFromDate = null;
        this.BillFromDate = value;
    }

    //setBillToDate
    static BillToDate;
    static getBillToDate() {
        return this.BillToDate;
    }
    static setBillToDate(value) {
        this.BillToDate = null;
        this.BillToDate = value;
    }

    //setHomecareDate
    static HomecareDate;
    static getHomecareDate() {
        return this.HomecareDate;
    }
    static setHomecareDate(value) {
        this.HomecareDate = null;
        this.HomecareDate = value;
    }

    //setDocAvailabitiyDate
    static DocAvailabitiyDate;
    static getDocAvailabitiyDate() {
        return this.DocAvailabitiyDate;
    }
    static setDocAvailabitiyDate(value) {
        this.DocAvailabitiyDate = null;
        this.DocAvailabitiyDate = value;
    }

    //setVideoLink
    static VideoLink;
    static getVideoLink() {
        return this.VideoLink;
    }
    static setVideoLink(value) {
        this.VideoLink = null;
        this.VideoLink = value;
    }

    //setVitals
    static Vitals;
    static getVitals() {
        return this.Vitals;
    }
    static setVitals(value) {
        this.Vitals = null;
        this.Vitals = value;
    }
    // inpatient flow

    static inpatient;
    static getInpatientFlow() {
        return this.inpatient;
    }
    static setInpatientFlow(value) {
        this.inpatient = null;
        this.inpatient = value;
    }
    // ipatient create billing 
    static ipbilling;
    static getIpCreateBill() {
        return this.ipbilling;
    }
    static setIpCreateBill(value) {
        this.ipbilling = null;
        this.ipbilling = value;
    }

    static receptbilleditinfo;
    static setreceptbilledit(value: any) {
        this.receptbilleditinfo = null;
        this.receptbilleditinfo = value;
    }

    static getreceptbilledit() {
        return this.receptbilleditinfo
    }

    //setMedForDischarge
    static MedForDischarge;
    static setMedForDischarge(value: any) {
        this.MedForDischarge = null;
        this.MedForDischarge = value;
    }
    static getMedForDischarge() {
        return this.MedForDischarge
    }

    //setImageFlag
    static ImageFlag;
    static setImageFlag(value: any) {
        this.ImageFlag = null;
        this.ImageFlag = value;
    }
    static getImageFlag() {
        return this.ImageFlag;
    }

    // get setDietDiease

    static DietDiease;
    static setDietDiease(value: any) {
        this.DietDiease = null;
        this.DietDiease = value;
    }
    static getDietDiease() {
        return this.DietDiease;
    }
    // get open card 

    static OpenCard;
    static setOpenCard(value: any) {
        this.OpenCard = null;
        this.OpenCard = value;
    }
    static getOpenCard() {
        return this.OpenCard;
    }

    // discharge summary print template
    static disprinttemp;
    static setDisPrintTemp(value: any) {
        this.disprinttemp = null;
        this.disprinttemp = value;
    }
    static getDisPrintTemp() {
        return this.disprinttemp;
    }

    // discharge summary header addl text
    static disprintheadtext;
    static setDisPrintHeaderText(value: any) {
        this.disprintheadtext = null;
        this.disprintheadtext = value;
    }
    static getDisPrintHeaderText() {
        return this.disprintheadtext;
    }

    // discharge summary footer addl text
    static disprintfoottext;
    static setDisPrintFooterText(value: any) {
        this.disprintfoottext = null;
        this.disprintfoottext = value;
    }
    static getDisPrintFooterText() {
        return this.disprintfoottext;
    }

    static finyear;
    static setFinYear(value: any) {
        this.finyear = null;
        this.finyear = value;
    }
    static getFinYear() {
        return this.finyear;
    }

    static relname;
    static setrelationName(value: any) {
        this.relname = null;
        this.relname = value;
    }
    static getrelationName() {
        return this.relname;
    }

    static billdocid;
    static setBillDocId(value: any) {
        this.billdocid = null;
        this.billdocid = value;
    }
    static getBillDocId() {
        return this.billdocid;
    }

    // to check from which component the patient info is called

    static flow_name;
    static setFlowName(value: any){
        this.flow_name = null;
        this.flow_name = value;
    }

    static getFlowName() {
        return this.flow_name;
    }
}