<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Followup Report</h5>
                    </div>
                    <div class="headerButtons">                      
                        <!-- <a (click)="print_area()" style="margin:0 5px;">
                        <img src="../../../assets/img/printer.svg" class="backimgbtn_inpatinfo" style="width: 25px; height: 25px;" />
                        </a> --> 
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <!-- <div class="cover_div" style="margin-top: 0px !important;">
                    <div class="header_lable">
                        Nextvisit Report
                    </div>
                    <div class="content_cover">
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom">
                                <mat-label class="matlabel" style="margin-top: 7px;">From
                                    <input type="date" class="ipcss_date " (change)="selectDate()"
                                        [(ngModel)]="fromDate" max="{{currentDate}}" #matInput style="width: 140px;">
                                </mat-label>
                            </div>  
                            <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom">
                                <mat-label class="matlabel" style="margin-top: 7px;">To
                                    <input type="date" class="ipcss_date " (change)="selectDate()"
                                        [(ngModel)]="toDate" max="{{currentDate}}" #matInput style="width: 140px;">
                                </mat-label>
                            </div>                                   
                        </div>
                    </div>
                </div> -->
                <div class="row" style="margin-top: 15px;">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <p class="nodata" *ngIf="nextVisitList == undefined || nextVisitList.length == 0">No nextvisit(s) found</p>
                        <table *ngIf="nextVisitList != undefined && nextVisitList.length != 0" mdbTable datatable [dtOptions]="dtOptions" class="table table-hover table-nowrap billlisttable table-sm table-responsive">
                            <thead>
                                <tr>
                                    <th>MR No</th>
                                    <th>Patient name</th>
                                    <th>Age/Gender</th>  
                                    <th>Mobile</th>  
                                    <th>Lastvisited Date</th>  
                                    <th>Nextvisit Date</th> 
                                    <th>Session</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let next of nextVisitList;">
                                    <td style="font-size: 12px;text-align: center;">{{next.mr_no}}</td>
                                    <td style="font-size: 12px;text-align: left;">{{next.client_name}}</td>
                                    <td style="font-size: 12px;text-align: center;">{{next.age_gender}}</td>
                                    <td style="font-size: 12px;text-align: center;">{{next.mobile}}</td>
                                    <td style="font-size: 12px;text-align: center;">{{next.last_visit_date}}</td>
                                    <td style="font-size: 12px;text-align: center;">{{next.next_visit_date}}</td>
                                    <td style="font-size: 12px;text-align: center;text-transform: capitalize;">{{next.session}}</td>
                                    <td style="font-size: 12px;"> 
                                        <div style="position: relative;top: 9px;">
                                          <img *ngIf="next.statustxt == 'Completed'" width="30px" height="auto" src="../../../assets/ui_icons/Completed_icon.svg" />
                                          <!-- <img *ngIf="next.statustxt == 'Cancelled'"  width="30px" height="auto" src="../../../assets/ui_icons/cancel_icon.svg" /> -->
                                          <img *ngIf="next.statustxt == 'Not visited'"  width="30px" height="auto" src="../../../assets/ui_icons/not_visited_icon.svg" />
                                          <img *ngIf="next.statustxt == 'Confirmed'"  width="30px" height="auto" src="../../../assets/ui_icons/confirmed_icon.svg" />
                                          <img *ngIf="next.statustxt == 'Open'"  width="30px" height="32px" src="../../../assets/ui_icons/opened_icon.svg" />
                                          <p>{{next.statustxt}}</p>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>
