<div class="row" style="width:100%;margin:0 auto;">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white ">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Create refunds</h5>
                    </div>
                    <div class="headerButtons">                      
                        <img src="../../../assets/ui_icons/buttons/Back_button.svg" (click)="back()"
                            class="addimgbtn_icd" /> &nbsp;
                        <img *ngIf="newbutton" src="../../../assets/ui_icons/buttons/save_button.svg" (click)="save()"
                            class="addimgbtn_icd" [hidden]="saveFlag" /> &nbsp;
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="Details">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 select_bottom"><br/>
                            <mat-label class="matlabel" style="margin-top: 34px !important;">Bill
                                <mat-checkbox color="primary" [(ngModel)]="bill" (change)="handleCheckboxChange('bill')"></mat-checkbox>
                            </mat-label>
                            &nbsp;&nbsp;
                            <mat-label class="matlabel" style="margin-top: 34px !important;">Advance
                                <mat-checkbox color="primary" [(ngModel)]="advance" (change)="handleCheckboxChange('advance')"></mat-checkbox>
                            </mat-label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-2 col-lg-2 col-xl-2 select_bottom">
                            <mat-label class="matlabel">Bill type <br>
                                <select required class="ipcss" [(ngModel)]="billtype">
                                    <option>Hospital</option>
                                    <option [hidden]="advance_no_flag">Diagnosis</option>
                                </select>
                            </mat-label>
                        </div>                  
                        <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 select_bottom">
                            <mat-label class="matlabel">Filter by<br>
                                <select required [(ngModel)]="filterType" class="ipcss" (change)="filterTypeChange()">
                                    <option selected>Date</option>
                                    <option>Client</option>
                                    <option>MR No</option>
                                    <option [hidden]="advance_no_flag">Bill number</option>
                                </select>
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 select_bottom" [hidden]="clientFlag">
                            <mat-label class="matlabel">Name<br>
                              <input type="text" class="ipcss order_width getChargeDesc" style="font-size: 12px !important;" required maxlength="50"
                                (keyup)="clientFilter()" maxlength="50" [(ngModel)]="clntName"
                                matInput [matAutocomplete]="auto2" />
                              <mat-autocomplete #auto2="matAutocomplete" [panelWidth]="'auto'">
                                <mat-option (click)="selectClient(item)" *ngFor="let item of clientList"
                                  value="{{item.name}}"> {{item.name}}                                          
                                </mat-option>
                              </mat-autocomplete>
                            </mat-label>                           
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 select_bottom" [hidden]="clientFlag">
                            <mat-label class="matlabel">Middle name<br>
                                <input matInput class="ipcss widthbilllist" type="text" disabled
                                    [(ngModel)]="middleName" style="margin-bottom: 10px;" />
                            </mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 select_bottom" [hidden]="clientFlag">
                            <mat-label class="matlabel">Last name<br>
                                <input matInput class="ipcss widthbilllist" type="text" required
                                    [(ngModel)]="lastName" style="margin-bottom: 10px;" />
                            </mat-label>
                        </div>
                        <div  class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 select_bottom" [hidden]="dateFlag">
                            <mat-label class="matlabel">From date<br>
                                <input type="date" class="ipcss_date widthbilllist datecss" required
                                    [(ngModel)]="fromDate" max="{{currentDate}}" matInput>
                            </mat-label>
                        </div>
                        <div  class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 select_bottom" [hidden]="dateFlag">
                            <mat-label class="matlabel">To date<br>
                                <input type="date" class="ipcss_date widthbilllist datecss" required
                                    [(ngModel)]="toDate" max="{{currentDate}}" matInput>
                            </mat-label>
                        </div>
                        <div  class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 select_bottom" [hidden]="billFlag">
                            <mat-label class="matlabel">Bill number<br>
                                <input type="text" class="ipcss" required [(ngModel)]="billingId" matInput>
                            </mat-label>
                        </div>
                        <div  class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 select_bottom" [hidden]="mr_no_flag">
                            <mat-label class="matlabel" >MR.No<br>
                              <input   class="ipcss"  type="text" required [(ngModel)]="patient_id" matInput/>
                            </mat-label>
                        </div>                       
                        <div class="col-1  p-0">
                            <mat-label class="matlabel">
                                <a *ngIf="viewbutton" (click)="searchBills()"><img src="../../../assets/img/search.png"
                                        class="searchButton" /></a>
                            </mat-label>
                        </div>              
                    </div>                      

                    <div class="col-12" *ngIf="createpage_flag">
                      <div class="cover_div" >
                        <div class="header_lable">Patient details</div>
                          <div class="content_cover">             
                            <div class="row">
                              <div class="col-2" style="text-align: center;">
                                <img src="{{this.profile_image}}" onerror="this.src='../../../assets/img/default.jpg';"
                                  class="profileimage">
                              </div>
                              <div class="col-10">
                                <div class="row">                               
                                  <div class="col-3">
                                    <table style="width: 100%;" class="head-table">
                                      <tbody>
                                        <tr class="head-tr">
                                          <td class="head-td" style="width: 95px; font-weight: 500;font-family: Arial;color: black;">
                                            <b>MR No</b></td>
                                          <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                          <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{patientId}}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  <div class="col-3">
                                    <table style="width: 100%;" class="head-table">
                                      <tbody>
                                        <tr class="head-tr">
                                          <td class="head-td" style="width: 95px; font-weight: 500;font-family: Arial;color: black;">
                                            <b>Patient name</b></td>
                                          <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                          <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                            {{salutation_desc}}{{fName}} <span *ngIf="mName != undefined">{{mName}}</span> {{lName}}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  <div class="col-3">
                                    <table style="width: 100%;" class="head-table">
                                      <tbody>
                                        <tr class="head-tr">
                                          <td class="head-td" style="width: 95px; font-weight: 500;font-family: Arial;color: black;">
                                            <b>Age/Gender</b></td>
                                          <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                          <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                            {{clntAge}} <span *ngIf="clntGenShort != '' && clntGenShort != undefined">/</span> {{clntGenShort}}
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  <div class="col-3">
                                    <table style="width: 100%;" class="head-table">
                                      <tbody>
                                        <tr class="head-tr">
                                          <td class="head-td" style="width: 95px; font-weight: 500;font-family: Arial;color: black;">
                                            <b>Mobile</b></td>
                                          <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                          <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{mobile_no}}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>      
                                  <div class="col-3" *ngIf="docName != ''">
                                    <table style="width: 100%;" class="head-table">
                                      <tbody>
                                        <tr class="head-tr">
                                          <td class="head-td" style="width: 95px; font-weight: 500;font-family: Arial;color: black;">
                                            <b>Doctor</b></td>
                                          <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                          <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{docName}}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div> 
                                  <div class="col-3">
                                    <table style="width: 100%;" class="head-table">
                                      <tbody>
                                        <tr class="head-tr">
                                          <td class="head-td" style="width: 95px; font-weight: 500;font-family: Arial;color: black;">
                                            <b>Bill No</b></td>
                                          <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                          <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                            {{bill_id}} 
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div> 
                                  <div class="col-3">
                                    <table style="width: 100%;" class="head-table">
                                      <tbody>
                                        <tr class="head-tr">
                                          <td class="head-td" style="width: 95px; font-weight: 500;font-family: Arial;color: black;">
                                            <b>Bill Date/Time</b></td>
                                          <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                          <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                            {{created_date | date : "dd-MM-yyyy"}} {{created_time}} 
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  <div class="col-3"  *ngIf="em_contact != '' && em_contact != undefined">
                                    <table style="width: 100%;" class="head-table">
                                      <tbody>
                                        <tr class="head-tr">
                                          <td class="head-td" style="width: 95px; font-weight: 500;font-family: Arial;color: black;">
                                            <b>Emerg contact</b></td>
                                          <td class="head-td" style="width: 10px;font-weight: 500;">:&nbsp;</td>
                                          <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{em_contact}}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>                                                                                                                                        
                                  <div class="col-6">
                                    <table style="width: 100%;" class="head-table">
                                        <tbody>
                                          <tr class="head-tr">
                                              <td class="head-td" style="width: 95px; font-weight: 500;font-family: Arial;color: black;">
                                                <b>Address</b></td>
                                              <td class="head-td" style="width: 10px; font-weight: 500;">:&nbsp;</td>
                                              <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                              {{clntAddress1}} {{clntAddress2}} <span *ngIf="clntLocation != undefined">{{clntLocation}}</span> {{clntCity}} {{clntZipcode}} {{clntState}} <span *ngIf="clntCountry != undefined">{{clntCountry}}</span>
                                            </td>
                                          </tr>                 
                                        </tbody>
                                    </table>
                                  </div>
                                </div>      
                              </div>
                            </div>               
                          </div>
                      </div>
                    </div> 

                    <div class="row">
                        <div class="col-6" *ngIf="bill_flag">
                            <div class="cover_div">
                                <div class="header_lable">
                                    Bill details
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div class="col-6">
                                            <mat-label class="matlabel">Bill number<br>
                                                <select class="ipcss" [(ngModel)]="billingId"
                                                    (change)="getBillDetails()">
                                                    <option>Select</option>
                                                    <option *ngFor="let bill of billList" value={{bill.bill_id}}>
                                                        {{bill.bill_id}}</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div class="col-6">
                                            <mat-label class="matlabel">Bill amount<br>
                                                <input type="text" class="ipcss" disabled [(ngModel)]="billAmount"
                                                    matInput>
                                            </mat-label>
                                        </div>
                                        <br>
                                        <div class="col-6">
                                            <mat-label class="matlabel">Refund amount<br>
                                                <input type="text" class="ipcss" disabled [(ngModel)]="refundAmount"
                                                    matInput>
                                            </mat-label>
                                        </div>
                                        <div class="col-6">
                                            <mat-label class="matlabel">Remarks<br>
                                                <textarea class="ipcss" name="" id="" cols="30" rows="5"
                                                    [(ngModel)]="billRemarks" required></textarea>
                                            </mat-label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        <div class="col-6" *ngIf="bill_flag">
                            <div class="cover_div">
                                <div class="header_lable">
                                    Client details
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 select_bottom">
                                            <mat-label class="matlabel">First name<br>
                                                <input type="text" class="ipcss" disabled [(ngModel)]="fName" matInput>
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 select_bottom">
                                            <mat-label class="matlabel">Middle name<br>
                                                <input type="text" class="ipcss" disabled [(ngModel)]="mName" matInput>
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 select_bottom">
                                            <mat-label class="matlabel">Last name<br>
                                                <input type="text" class="ipcss" disabled [(ngModel)]="lName" matInput>
                                            </mat-label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                   
                        <div class="col-6" *ngIf="advance_flag">
                            <div class="cover_div">
                                <div class="header_lable">
                                    Advance details
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div class="col-6">
                                            <mat-label class="matlabel">Advance id<br>
                                                <select class="ipcss" [(ngModel)]="advanceId"
                                                    (change)="getAdvanceDetails()">
                                                    <option>Select</option>
                                                    <option *ngFor="let advance of advanceList" value={{advance.bill_id}}>
                                                        {{advance.bill_id}}</option>
                                                </select>
                                            </mat-label>
                                        </div>
                                        <div class="col-6 ">
                                            <mat-label class="matlabel">Advance amount<br>
                                                <input type="text" class="ipcss" disabled [(ngModel)]="advanceAmount"
                                                    matInput>
                                            </mat-label>
                                        </div>
                                        <br>
                                        <div class="col-6">
                                            <mat-label class="matlabel">Refund amount<br>
                                                <input type="text" class="ipcss" [(ngModel)]="advanceRefundAmount"
                                                    matInput>
                                            </mat-label>
                                        </div>
                                        <div class="col-6">
                                            <mat-label class="matlabel">Remarks<br>
                                                <textarea class="ipcss" name="" id="" cols="30" rows="5"
                                                    [(ngModel)]="advanceRemarks" required></textarea>
                                            </mat-label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-6" *ngIf="advance_flag">
                            <div class="cover_div">
                                <div class="header_lable">
                                    Client details
                                </div>
                                <div class="content_cover">
                                    <div class="row">
                                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 select_bottom">
                                            <mat-label class="matlabel">First name<br>
                                                <input type="text" class="ipcss" disabled [(ngModel)]="fName" matInput>
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 select_bottom">
                                            <mat-label class="matlabel">Middle name<br>
                                                <input type="text" class="ipcss" disabled [(ngModel)]="mName" matInput>
                                            </mat-label>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 select_bottom">
                                            <mat-label class="matlabel">Last name<br>
                                                <input type="text" class="ipcss" disabled [(ngModel)]="lName" matInput>
                                            </mat-label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12">
                            <div *ngIf="billDetails.length != 0 && bill_flag" class="dig_table_overflow">
                                <table class="card_tbl table-dynamic">
                                    <thead>
                                        <tr>
                                            <th>Charge</th>
                                            <th>Rate</th>
                                            <th>Concession</th>
                                            <th>Amount</th>
                                            <th>Select</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let bill of billDetails">
                                            <td class="align_left">{{bill.charge_desc}}</td>
                                            <td class="align_right">{{bill.amount_txt}}</td>
                                            <td class="align_center" style="text-align: center !important;">{{bill.concession}}</td>
                                            <td class="align_right"> {{bill.fee}}</td>
                                            <td class="align_center" style="text-align: center !important;"><input  type="checkbox" (click)="calculateRefund(bill.charge_type)">
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
        
                <div [hidden]="true" #printbillbanner id="printbillbanner">
                  <div class="bill_border">       
                    <table style="width: 100%;">  
                      <thead>
                        <tr>
                          <th style="width:100%;">                                 
                            <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;margin-right: 30px; margin-left: 20px;"> 
                              <div style="width: 100%; height: 195px !important;"> 
                                <div style="width: 100%;">
                                  <img alt="image" src={{hospitalLogo}}  [style]="printlogostyle">
                                </div>                                                             
                                <table style="width: 100%;font-size: 12px;border: 1px solid black;margin-top: 15px;margin-bottom: 5px;border-radius: 10px;height: 95px;"> 
                                  <tbody>
                                    <tr>
                                      <td style="padding-left: 5px;"><strong>Patient Name</strong></td>
                                      <td>: {{salutation_desc}}{{fName}} <span *ngIf="mName != undefined">{{mName}}</span> {{lName}} </td>
                                      <td style="padding-left: 5px;"><strong>Address</strong></td>
                                      <td>: {{clntAddress1}} {{clntAddress2}}</td>
                                    </tr>
                                    <tr>
                                      <td style="padding-left: 5px;"><strong>Age/Gender</strong></td>
                                      <td>:  {{clntAge}} /<span> {{clntGenShort}}</span></td>
                                      <td style="padding-left: 5px;"><strong></strong></td>
                                      <td><span class="address_visibility">:</span> {{clntLocation}} {{clntCity}} {{clntZipcode}}</td>
                                    </tr>
                                    <tr>
                                      <td style="padding-left: 5px;"><strong>Bill No</strong></td>
                                      <td>: {{bill_id}}</td>
                                      <td style="padding-left: 5px;"><strong></strong></td>
                                      <td><span class="address_visibility">:</span> {{clntState}} {{clntCountry}}</td>
                                    </tr>
                                    <tr>
                                      <td style="padding-left: 5px;"><strong>Bill Date/Time</strong></td>
                                      <td>:  {{created_date | date : "dd-MM-yyyy"}} {{created_time}}</td>
                                    </tr>
                                    <tr>
                                      <td style="padding-left: 5px;"><strong>Refund Date/Time</strong></td>                             
                                      <td>: {{currentDate | date : "dd-MM-yyyy"}}&nbsp;{{timevalue}}</td>                                                   
                                    </tr>                                                 
                                    <tr>
                                      <td style="padding-left: 5px;"><strong></strong></td>
                                      <td>
                                      </td> 
                                      <td style="padding-left: 5px;"><strong></strong></td>
                                      <td *ngIf="barcode_flag">
                                        <img style="width: 157px; height: 37px !important; margin-left: 0px; margin-top: -35px;"
                                          src={{barcode_url}} /></td>
                                    </tr>                         
                                  </tbody>
                                </table>
                              </div>
                            </div>               
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <div class="main">
                          <p style="text-align: center;font-size: 16px;margin-top: -5px;margin-bottom: 5px"><b>{{title}}</b></p> 
                          <table style="margin-left: 20px; margin-top: 5px;border: 1px solid black;width: 93%;border-collapse: collapse; text-align:center;">
                            <thead>
                              <tr style="border: 1px solid black;border-collapse: collapse; text-align:center; ">
                                <th style="border: 1px solid black;border-collapse: collapse; text-align:center; "> Bill No </th>
                                <th style="border: 1px solid black;border-collapse: collapse; text-align:center; "> Bill amount </th>
                                <th style="border: 1px solid black;border-collapse: collapse; text-align:center; "> Refund amount </th>
                                <th style="border: 1px solid black;border-collapse: collapse; text-align:center; "> Remarks </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr style="border: 1px solid black;border-collapse: collapse; text-align:center; ">
                                <td style="border: 1px solid black;border-collapse: collapse; text-align:center; "> {{billingId}} </td>
                                <td style="border: 1px solid black;border-collapse: collapse; text-align:center; "> {{billAmount}} </td>
                                <td style="border: 1px solid black;border-collapse: collapse; text-align:center; "> {{refundAmount}}</td>
                                <td style="border: 1px solid black;border-collapse: collapse; text-align:center; "> {{billRemarks}}</td>
                              </tr>
                              <tr style="border: 1px solid black;border-collapse: collapse; text-align:center; "> 
                                <td colspan="2" style="border: 1px solid black;border-collapse: collapse; text-align:center; "> Refund amount </td >
                                <td style="border: 1px solid black;border-collapse: collapse; text-align:center; "> {{refundAmount}} </td> 
                              </tr>
                            </tbody>
                          </table>             
                          <table style="margin-left: 20px; margin-top: 5px;width: 93%;border-collapse: collapse; text-align:left;" *ngIf="numToWords != undefined && refundAmount != '0.00'">
                            <tr>
                              <td style="vertical-align: top;"><strong>In Words:</strong>&nbsp;Refunded Rupees&nbsp;{{numToWords}}&nbsp;Only</td>
                            </tr>
                          </table>  
                        </div>                      
                      </tbody>  
                      <tfoot style="margin-bottom: 10px;"> 
                        <tr>  
                          <td width="100%">  
                            <table style="width: 100%;">  
                              <tr>  
                                <td style="vertical-align: top;width:50%;">
                                  <table style="float: left !important;margin-left: 15px;">
                                    <tr>
                                      <td>&nbsp;</td>
                                    </tr>
                                  </table> 
                                </td>  
                                <td style="vertical-align: top;width:50%;">
                                  <table style="float: right !important;margin-left: 20px;margin-right: 32px;">
                                    <tr>
                                      <div style="float: right !important;margin-left: 20px;margin-right: 32px;">
                                        <p style="margin-bottom: 3px !important;text-align: center;">{{logUserName}}</p>
                                        <p style="text-align: center;margin-top: 6px;margin-bottom: 20px;"><strong>Created by</strong></p>
                                      </div>                       
                                    </tr>
                                  </table>                      
                                </td>
                              </tr>  
                            </table> 
                          </td>
                        </tr> 
                      </tfoot>        
                    </table>                                              
                  </div>                 
                </div>

                <div [hidden]="true" #printbillnoheader id="printbillnoheader">
                  <div class="bill_border">       
                    <table style="width: 100%;"> 
                      <thead>
                        <tr>
                          <th style="width:100%;">
                            <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;margin-right: 30px; margin-left: 20px;"> 
                              <div style="width: 100%; height: 100px;">
                                <div style="height: 5px !important;"></div>
                                <table style="width: 100%;font-size: 12px;border: 1px solid black;border-radius: 10px;height: 85px;"> 
                                  <tbody>
                                    <tr>
                                      <td style="padding-left: 5px;"><strong>Patient Name</strong></td>
                                      <td>: {{salutation_desc}}{{fName}} <span *ngIf="mName != undefined">{{mName}}</span> {{lName}} </td>
                                      <td style="padding-left: 5px;"><strong>Address</strong></td>
                                      <td>: {{clntAddress1}} {{clntAddress2}}</td>
                                    </tr>
                                    <tr>
                                      <td style="padding-left: 5px;"><strong>Age/Gender</strong></td>
                                      <td>:  {{clntAge}} /<span> {{clntGenShort}}</span></td>
                                      <td style="padding-left: 5px;"><strong></strong></td>
                                      <td><span class="address_visibility">:</span> {{clntLocation}} {{clntCity}} {{clntZipcode}}</td>
                                    </tr>
                                    <tr>
                                      <td style="padding-left: 5px;"><strong>Bill No</strong></td>
                                      <td>: {{bill_id}}</td>
                                      <td style="padding-left: 5px;"><strong></strong></td>
                                      <td><span class="address_visibility">:</span> {{clntState}} {{clntCountry}}</td>
                                    </tr>
                                    <tr>
                                      <td style="padding-left: 5px;"><strong>Bill Date/Time</strong></td>
                                      <td>:  {{created_date | date : "dd-MM-yyyy"}} {{created_time}}</td>
                                    </tr>
                                    <tr>
                                      <td style="padding-left: 5px;"><strong>Refund Date/Time</strong></td>                             
                                      <td>: {{currentDate | date : "dd-MM-yyyy"}}&nbsp;{{timevalue}}</td>                                                   
                                    </tr>                                                 
                                    <tr>
                                      <td style="padding-left: 5px;"><strong></strong></td>
                                      <td>
                                      </td> 
                                      <td style="padding-left: 5px;"><strong></strong></td>
                                      <td *ngIf="barcode_flag">
                                        <img style="width: 157px; height: 37px !important; margin-left: 0px; margin-top: -35px;"
                                          src={{barcode_url}} /></td>
                                    </tr>                         
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </th>
                        </tr>
                      </thead>   
                      <tbody>
                        <div class="main">
                          <table style="margin-left: 20px; margin-top: 5px;border: 1px solid black;width: 93%;border-collapse: collapse; text-align:center;">                         
                            <p style="text-align: center;font-size: 16px;margin-top: 0px;margin-bottom: 2px"><b>{{title}}</b></p>
                            <thead>
                              <tr style="border: 1px solid black;border-collapse: collapse; text-align:center; ">
                                  <th style="border: 1px solid black;border-collapse: collapse; text-align:center; "> Bill No </th>
                                  <th style="border: 1px solid black;border-collapse: collapse; text-align:center; "> Bill amount </th>
                                  <th style="border: 1px solid black;border-collapse: collapse; text-align:center; "> Refund amount </th>
                                  <th style="border: 1px solid black;border-collapse: collapse; text-align:center; "> Remarks </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr style="border: 1px solid black;border-collapse: collapse; text-align:center; ">
                                  <td style="border: 1px solid black;border-collapse: collapse; text-align:center; "> {{billingId}} </td>
                                  <td style="border: 1px solid black;border-collapse: collapse; text-align:center; "> {{billAmount}} </td>
                                  <td style="border: 1px solid black;border-collapse: collapse; text-align:center; "> {{refundAmount}}</td>
                                  <td style="border: 1px solid black;border-collapse: collapse; text-align:center; "> {{billRemarks}}</td>
                              </tr>
                              <tr style="border: 1px solid black;border-collapse: collapse; text-align:center; "> 
                                <td colspan="2" style="border: 1px solid black;border-collapse: collapse; text-align:center; "> Refund amount </td> 
                                <td style="border: 1px solid black;border-collapse: collapse; text-align:center; "> {{refundAmount}} </td> 
                              </tr>
                            </tbody>
                          </table>                 
                          <table style="margin-left: 20px; margin-top: 5px;width: 93%;border-collapse: collapse; text-align:left;" *ngIf="numToWords != undefined && refundAmount != '0.00'">
                            <tr>
                              <td style="vertical-align: top;"><strong>In Words:</strong>&nbsp;Refunded Rupees&nbsp;{{numToWords}}&nbsp;Only</td>
                            </tr>
                          </table>
                        </div>                       
                      </tbody> 
                      <tfoot style="margin-bottom: 10px;"> 
                        <tr>  
                          <td width="100%">  
                            <table style="width: 100%;">  
                              <tr>  
                                <td style="vertical-align: top;width:50%;">
                                  <table style="float: left !important;margin-left: 15px;">
                                    <tr>
                                      <td>&nbsp;</td>
                                    </tr>
                                  </table> 
                                </td>  
                                <td style="vertical-align: top;width:50%;">
                                  <table style="float: right !important;margin-left: 20px;margin-right: 32px;">
                                    <tr>
                                      <div style="float: right !important;margin-left: 20px;margin-right: 32px;">
                                        <p style="margin-bottom: 3px !important;text-align: center;">{{logUserName}}</p>
                                        <p style="text-align: center;margin-top: 6px;margin-bottom: 20px;"><strong>Created by</strong></p>
                                      </div>                       
                                    </tr>
                                  </table>                      
                                </td>
                              </tr>  
                            </table> 
                          </td>
                        </tr> 
                      </tfoot>                    
                    </table>                                 
                  </div>
                </div>

                <div [hidden]="true" #printadvancebanner id="printadvancebanner">
                  <div class="bill_border">       
                    <table style="width: 100%;">                        
                      <thead>
                        <tr>
                          <th style="width:100%;">                                 
                            <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;margin-right: 30px; margin-left: 20px;"> 
                              <div [style]="banner_style"> 
                                <div style="width: 100%;">
                                  <img alt="image" src={{hospitalLogo}}  [style]="printlogostyle">
                                </div>                                                             
                                <table style="width: 100%;font-size: 12px;border: 1px solid black;margin-top: 15px;margin-bottom: 5px;border-radius: 10px;height: 95px;"> 
                                  <tbody>
                                    <tr>
                                      <td style="padding-left: 5px;"><strong>Patient Name</strong></td>
                                      <td>:  {{salutation_desc}}{{fName}} <span *ngIf="mName != undefined">{{mName}}</span> {{lName}} </td>
                                      <td style="padding-left: 5px;"><strong>Address</strong></td>
                                      <td>: {{clntAddress1}}  {{clntAddress2}}</td>
                                    </tr>
                                    <tr>
                                      <td style="padding-left: 5px;"><strong>Age/Gender</strong></td>
                                      <td>:  {{clntAge}} /<span> {{clntGenShort}}</span></td>
                                      <td style="padding-left: 5px;"><strong></strong></td>
                                      <td><span class="address_visibility">:</span> {{clntLocation}} {{clntCity}} {{clntZipcode}}</td>
                                    </tr>
                                    <tr>
                                      <td style="padding-left: 5px;"><strong>Advance/Bill No</strong></td>
                                      <td>: {{bill_id}}</td>
                                      <td style="padding-left: 5px;"><strong></strong></td>
                                      <td><span class="address_visibility">:</span> {{clntState}} {{clntCountry}}</td>
                                    </tr>
                                    <tr>
                                      <td style="padding-left: 5px;"><strong>Advance Bill Date/Time</strong></td>
                                      <td>:  {{created_date | date : "dd-MM-yyyy"}} {{created_time}}</td>
                                    </tr>
                                    <tr>
                                      <td style="padding-left: 5px;"><strong>Refund Date/Time</strong></td>                             
                                      <td>:  {{currentDate | date : "dd-MM-yyyy"}}&nbsp;{{timevalue}}</td>                                                   
                                    </tr>                                                 
                                    <tr>
                                      <td style="padding-left: 5px;"><strong></strong></td>
                                      <td>
                                      </td> 
                                      <td style="padding-left: 5px;"><strong></strong></td>
                                      <td *ngIf="barcode_flag">
                                        <img style="width: 157px; height: 37px !important; margin-left: 0px; margin-top: -35px;"
                                          src={{barcode_url}} /></td>
                                    </tr>                         
                                  </tbody>
                                </table>
                              </div>
                            </div>               
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <div class="main">
                          <table style="margin-left: 20px;border: 1px solid black;width: 93%;border-collapse: collapse; text-align:center;">
                            <p  style="text-align: center;font-size: 16px;margin-top: -5px;margin-bottom: 5px"><b>Advance Refund Bill</b></p>
                            <thead>
                              <tr style="border: 1px solid black;border-collapse: collapse; text-align:center;">
                                <th style="border: 1px solid black;border-collapse: collapse; text-align:center;"> Advance/Bill no </th>
                                <th style="border: 1px solid black;border-collapse: collapse; text-align:center;"> Advance amount </th>
                                <th style="border: 1px solid black;border-collapse: collapse; text-align:center;"> Refund amount </th>
                                <th style="border: 1px solid black;border-collapse: collapse; text-align:center;"> Remarks </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr style="border: 1px solid black;border-collapse: collapse; text-align:center;">
                                <td style="border: 1px solid black;border-collapse: collapse; text-align:center;"> {{advanceId}} </td>
                                <td style="border: 1px solid black;border-collapse: collapse; text-align:center;"> {{advanceAmount}} </td>
                                <td style="border: 1px solid black;border-collapse: collapse; text-align:center;"> {{advanceRefundAmount}}</td>
                                <td style="border: 1px solid black;border-collapse: collapse; text-align:center;"> {{advanceRemarks}}</td>
                              </tr>
                              <tr style="border: 1px solid black;border-collapse: collapse; text-align:center;"> 
                                <td colspan="2" style="border: 1px solid black;border-collapse: collapse; text-align:center;"> Refund amount </td > 
                                <td style="border: 1px solid black;border-collapse: collapse; text-align:center;"> {{advanceRefundAmount}} </td> 
                              </tr>
                            </tbody>
                          </table>
                          <table style="margin-left: 20px; margin-top: 10px;width: 93%;border-collapse: collapse; text-align:left;" *ngIf="numToWords != undefined && advanceRefundAmount != '0.00'">
                            <tr>
                              <td style="vertical-align: top;"><strong>In Words:</strong>&nbsp;Refunded Rupees&nbsp;{{numToWords}}&nbsp;Only</td>
                            </tr>
                          </table>
                        </div>                         
                      </tbody>
                      <tfoot style="margin-bottom: 10px;"> 
                        <tr>  
                          <td width="100%">  
                            <table style="width: 100%;">  
                              <tr>  
                                <td style="vertical-align: top;width:50%;">
                                  <table style="float: left !important;margin-left: 15px;">
                                    <tr>
                                      <td>&nbsp;</td>
                                    </tr>
                                  </table> 
                                </td>  
                                <td style="vertical-align: top;width:50%;">
                                  <table style="float: right !important;margin-left: 20px;margin-right: 32px;">
                                    <tr>
                                      <div style="float: right !important;margin-left: 20px;margin-right: 32px;">
                                        <p style="margin-bottom: 3px !important;text-align: center;">{{logUserName}}</p>
                                        <p style="text-align: center;margin-top: 6px;margin-bottom: 20px;"><strong>Created by</strong></p>
                                      </div>                       
                                    </tr>
                                  </table>                      
                                </td>
                              </tr>  
                            </table> 
                          </td>
                        </tr> 
                      </tfoot>
                    </table>                                              
                  </div>                
                </div>

                <div [hidden]="true" #printadvancenoheader id="printadvancenoheader">
                  <div class="bill_border">       
                    <table style="width: 100%;">                      
                      <thead>
                        <tr>
                          <th style="width:100%;">
                            <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;margin-right: 30px; margin-left: 20px;"> 
                              <div style="width: 100%; height: 100px;">
                                <div style="height: 5px !important;"></div>
                                <table style="width: 100%;font-size: 12px;border: 1px solid black;border-radius: 10px;height: 85px;"> 
                                  <tbody>
                                    <tr>
                                      <td style="padding-left: 5px;"><strong>Patient Name</strong></td>
                                      <td>:  {{salutation_desc}}{{fName}} <span *ngIf="mName != undefined">{{mName}}</span> {{lName}} </td>
                                      <td style="padding-left: 5px;"><strong>Address</strong></td>
                                      <td>: {{clntAddress1}}  {{clntAddress2}}</td>
                                    </tr>
                                    <tr>
                                      <td style="padding-left: 5px;"><strong>Age/Gender</strong></td>
                                      <td>:  {{clntAge}} /<span> {{clntGenShort}}</span></td>
                                      <td style="padding-left: 5px;"><strong></strong></td>
                                      <td><span class="address_visibility">:</span> {{clntLocation}} {{clntCity}} {{clntZipcode}}</td>
                                    </tr>
                                    <tr>
                                      <td style="padding-left: 5px;"><strong>Advance/Bill No</strong></td>
                                      <td>: {{bill_id}}</td>
                                      <td style="padding-left: 5px;"><strong></strong></td>
                                      <td><span class="address_visibility">:</span> {{clntState}} {{clntCountry}}</td>
                                    </tr>
                                    <tr>
                                      <td style="padding-left: 5px;"><strong>Advance Bill Date/Time</strong></td>
                                      <td>:  {{created_date | date : "dd-MM-yyyy"}} {{created_time}}</td>
                                    </tr>
                                    <tr>
                                      <td style="padding-left: 5px;"><strong>Refund Date/Time</strong></td>                             
                                      <td>:  {{currentDate | date : "dd-MM-yyyy"}}&nbsp;{{timevalue}}</td>                                                   
                                    </tr>                                                 
                                    <tr>
                                      <td style="padding-left: 5px;"><strong></strong></td>
                                      <td>
                                      </td> 
                                      <td style="padding-left: 5px;"><strong></strong></td>
                                      <td *ngIf="barcode_flag">
                                        <img style="width: 157px; height: 37px !important; margin-left: 0px; margin-top: -35px;"
                                          src={{barcode_url}} /></td>
                                    </tr>                         
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </th>
                        </tr>
                      </thead> 
                      <tbody>   
                        <div class="main">
                          <table style="margin-left: 20px;border: 1px solid black;width: 93%;border-collapse: collapse; text-align:center;">
                            <p  style="text-align: center;font-size: 16px;margin-top: 0px;margin-bottom: 2px"><b>Advance Refund Bill</b></p>
                            <thead>
                              <tr style="border: 1px solid black;border-collapse: collapse; text-align:center; ">
                                  <th style="border: 1px solid black;border-collapse: collapse; text-align:center; "> Advance/Bill no </th>
                                  <th style="border: 1px solid black;border-collapse: collapse; text-align:center; "> Advance amount </th>
                                  <th style="border: 1px solid black;border-collapse: collapse; text-align:center; "> Refund amount </th>
                                  <th style="border: 1px solid black;border-collapse: collapse; text-align:center; "> Remarks </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr style="border: 1px solid black;border-collapse: collapse; text-align:center;">
                                <td style="border: 1px solid black;border-collapse: collapse; text-align:center; "> {{advanceId}} </td>
                                <td style="border: 1px solid black;border-collapse: collapse; text-align:center; "> {{advanceAmount}} </td>
                                <td style="border: 1px solid black;border-collapse: collapse; text-align:center; "> {{advanceRefundAmount}}</td>
                                <td style="border: 1px solid black;border-collapse: collapse; text-align:center; "> {{advanceRemarks}}</td>
                              </tr>
                              <tr style="border: 1px solid black;border-collapse: collapse; text-align:center; "> 
                                <td colspan="2" style="border: 1px solid black;border-collapse: collapse; text-align:center; "> Refund amount </td >
                                <td style="border: 1px solid black;border-collapse: collapse; text-align:center; "> {{advanceRefundAmount}} </td>
                              </tr>
                            </tbody>
                          </table>                 
                          <table style="margin-left: 20px; margin-top: 10px;width: 93%;border-collapse: collapse; text-align:left;" *ngIf="numToWords != undefined && advanceRefundAmount != '0.00'">
                            <tr>
                              <td style="vertical-align: top;"><strong>In Words:</strong>&nbsp;Refunded Rupees&nbsp;{{numToWords}}&nbsp;Only</td>
                            </tr>
                          </table>
                        </div>                                   
                      </tbody>  
                      <tfoot style="margin-bottom: 10px;"> 
                        <tr>  
                          <td width="100%">  
                            <table style="width: 100%;">  
                              <tr>  
                                <td style="vertical-align: top;width:50%;">
                                  <table style="float: left !important;margin-left: 15px;">
                                    <tr>
                                      <td>&nbsp;</td>
                                    </tr>
                                  </table> 
                                </td>  
                                <td style="vertical-align: top;width:50%;">
                                  <table style="float: right !important;margin-left: 20px;margin-right: 32px;">
                                    <tr>
                                      <div style="float: right !important;margin-left: 20px;margin-right: 32px;">
                                        <p style="margin-bottom: 3px !important;text-align: center;">{{logUserName}}</p>
                                        <p style="text-align: center;margin-top: 6px;margin-bottom: 20px;"><strong>Created by</strong></p>
                                      </div>                       
                                    </tr>
                                  </table>                      
                                </td>
                              </tr>  
                            </table> 
                          </td>
                        </tr> 
                      </tfoot>                   
                    </table>                
                  </div>                  
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>