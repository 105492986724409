<div class="row">
    <div class="col-12">
        <mdb-card>
            <mdb-card-header class="bg-white ">
                <div class="headerCover">
                    <div class="headerTilte app_list_header">
                        <h5 class="m-0" class="mainHeadingStyle">Dashboard</h5>
                    </div>
                    <div class="headerButtons">
                        <div class="col-12 col-sm-6 col-md-6  col-lg-2 col-xl-2 mx-2" style="margin-top: 5px;width: auto;">
                            <mat-label class="matlabel" style="font-size: 15px; font-weight: 600;">From :</mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 mx-2">
                            <input type="date" [(ngModel)]="from_date" class="ipcss widthappt">
                        </div>
                        <div class="col-12 col-sm-6 col-md-6  col-lg-2 col-xl-2 mx-2" style="margin-top: 5px;width: auto;">
                            <mat-label class="matlabel" style="font-size: 15px; font-weight: 600;">To :</mat-label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 mx-2">
                            <input type="date" [(ngModel)]="to_date" class="ipcss widthappt">
                        </div>
                        <img src="../../../assets/ui_icons/buttons/search_button.svg" (click)="getDashData()"
                            class="saveimgbtn_inpatinfo mx-2" />
                    </div>
                </div>
            </mdb-card-header>
            <mdb-card-body>
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 mb-2">
                            <h2>Pending Sample Collection</h2>
                            <div class="row">
                                <div class="col-1">
                                    <div
                                        class="card-container mb-1 d-flex flex-row justify-content-center align-items-center">
                                        <img src="../../../assets/ui_icons/pending_sample_collection.svg"
                                            style="width: 60px;" />
                                    </div>
                                </div>
                                <div class="col-2">
                                    <div class="card-container d-flex flex-row justify-content-center align-items-center"
                                        style="background-color: #90e0ef; color: white;">
                                        <div style="text-align: center;">
                                            <p class="mb-2 card-container-para"><b>Total test count</b></p>
                                            <h3 class="card-container-head" style="margin-bottom: 0px !important;">{{sample_Testcount}}</h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-2">
                                    <div class="card-container mb-1 d-flex flex-row justify-content-center align-items-center"
                                        style="background-color: #90e0ef; color: white;">
                                        <div style="text-align: center;">
                                            <p class="mb-2 card-container-para"><b>IP</b></p>
                                            <h3 class="card-container-head" style="margin-bottom: 0px !important;">{{sample_Ipcount}}</h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-2">
                                    <div class="card-container mb-1 d-flex flex-row justify-content-center align-items-center"
                                        style="background-color: #90e0ef; color: white;">
                                        <div style="text-align: center;">
                                            <p class="mb-2 card-container-para"><b>OP</b></p>
                                            <h3 class="card-container-head" style="margin-bottom: 0px !important;">{{sample_Opcount}}</h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-2">
                                    <div class="card-container mb-1 d-flex flex-row justify-content-center align-items-center"
                                        style="background-color: rgb(65 116 245); color: white;">
                                        <div style="text-align: center;">
                                            <!-- <p class="mb-1">{{dash_upd_date}} {{dash_upd_time}}</p>
                                            <h3 style="font-size: 14px; margin-bottom: 0px !important;">Last updated
                                                time</h3> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="col-1">
                                    <div class="card-container mb-1 d-flex flex-row justify-content-center align-items-center"
                                        style="background-color: #1c9800">
                                        <h2 class="card-container-head">{{sample_green}}</h2>
                                    </div>
                                </div>
                                <div class="col-1">
                                    <div class="card-container mb-1 d-flex flex-row justify-content-center align-items-center"
                                        style="background-color: #ff9500;">
                                        <h2 class="card-container-head">{{sample_orange}}</h2>
                                    </div>
                                </div>
                                <div class="col-1">
                                    <div class="card-container mb-1 d-flex flex-row justify-content-center align-items-center"
                                        style="background-color: #d00000;">
                                        <h2 class="card-container-head">{{sample_red}}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mb-2">
                            <h2>Pending Assertion</h2>
                            <div class="row">
                                <div class="col-1">
                                    <div
                                        class="card-container mb-1 d-flex flex-row justify-content-center align-items-center">
                                        <img src="../../../assets/ui_icons/pending_assertion.svg"
                                            style="width: 60px;" />
                                    </div>
                                </div>
                                <div class="col-2">
                                    <div class="card-container d-flex flex-row justify-content-center align-items-center"
                                        style="background-color: #abc4ff; color: white;">
                                        <div style="text-align: center;">
                                            <p class="mb-2 card-container-para"><b>Special Investigation</b></p>
                                            <h3 class="card-container-head" style="margin-bottom: 0px !important;">{{spl_Investigation}}</h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-2">
                                    <div class="card-container mb-1 d-flex flex-row justify-content-center align-items-center"
                                        style="background-color: #abc4ff; color: white;">
                                        <div style="text-align: center;">
                                            <p class="mb-2 card-container-para"><b>Microbiology</b></p>
                                            <h3 class="card-container-head" style="margin-bottom: 0px !important;">{{assert_Microbiology}}</h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-2">
                                    <div class="card-container mb-1 d-flex flex-row justify-content-center align-items-center"
                                        style="background-color:#abc4ff; color: white;">
                                        <div style="text-align: center;">
                                            <p class="mb-2 card-container-para"><b>Pathology</b></p>
                                            <h3 class="card-container-head" style="margin-bottom: 0px !important;">{{assert_Pathology}}</h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-2">
                                    <div class="card-container mb-1 d-flex flex-row justify-content-center align-items-center"
                                        style="background-color: #abc4ff; color: white;">
                                        <div style="text-align: center;">
                                            <p class="mb-2 card-container-para"><b>Radiology</b></p>
                                            <h3 class="card-container-head" style="margin-bottom: 0px !important;">{{assert_Radiology}}</h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-1">
                                    <div class="card-container mb-1 d-flex flex-row justify-content-center align-items-center"
                                        style="background-color: #1c9800;">
                                        <h2 class="card-container-head">{{assert_Green}}</h2>
                                    </div>
                                </div>
                                <div class="col-1">
                                    <div class="card-container mb-1 d-flex flex-row justify-content-center align-items-center"
                                        style="background-color: #ff9500;">
                                        <h2 class="card-container-head">{{assert_Orange}}</h2>
                                    </div>
                                </div>
                                <div class="col-1">
                                    <div class="card-container mb-1 d-flex flex-row justify-content-center align-items-center"
                                        style="background-color: #d00000">
                                        <h2 class="card-container-head">{{assert_Red}}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mb-2">
                            <h2>Pending Test</h2>
                            <div class="row">
                                <div class="col-1">
                                    <div
                                        class="card-container mb-1 d-flex flex-row justify-content-center align-items-center">
                                        <img src="../../../assets/ui_icons/pending_test.svg" style="width: 60px;" />
                                    </div>
                                </div>
                                <div class="col-2">
                                    <div
                                        class="card-container d-flex flex-row justify-content-center align-items-center">
                                        <div style="text-align: center;">
                                            <p class="mb-2 card-container-para"><b>Special Investigation</b></p>
                                            <h3 class="card-container-head"
                                                style="margin-bottom: 0px !important; color: black;">{{pending_spl_Investigation}}
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-2">
                                    <div
                                        class="card-container mb-1 d-flex flex-row justify-content-center align-items-center">
                                        <div style="text-align: center;">
                                            <p class="mb-2 card-container-para"><b>Microbiology</b></p>
                                            <h3 class="card-container-head"
                                                style="margin-bottom: 0px !important; color: black;">{{pending_Microbiology}}
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-2">
                                    <div
                                        class="card-container mb-1 d-flex flex-row justify-content-center align-items-center">
                                        <div style="text-align: center;">
                                            <p class="mb-2 card-container-para"><b>Pathology</b></p>
                                            <h3 class="card-container-head"
                                                style="margin-bottom: 0px !important; color: black;">{{pending_Pathology}}
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-2">
                                    <div
                                        class="card-container mb-1 d-flex flex-row justify-content-center align-items-center">
                                        <div style="text-align: center;">
                                            <p class="mb-2 card-container-para"><b>Radiology</b></p>
                                            <h3 class="card-container-head"
                                                style="margin-bottom: 0px !important; color: black;">{{pending_Radiology}}
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-1">
                                    <div class="card-container mb-1 d-flex flex-row justify-content-center align-items-center"
                                        style="background-color: #1c9800;">
                                        <h2 class="card-container-head">{{pending_Green}}</h2>
                                    </div>
                                </div>
                                <div class="col-1">
                                    <div class="card-container mb-1 d-flex flex-row justify-content-center align-items-center"
                                        style="background-color: #ff9500;">
                                        <h2 class="card-container-head">{{pending_Orange}}</h2>
                                    </div>
                                </div>
                                <div class="col-1">
                                    <div class="card-container mb-1 d-flex flex-row justify-content-center align-items-center"
                                        style="background-color: #d00000">
                                        <h2 class="card-container-head">{{pending_Red}}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mb-2">
                            <h2>Test Completed Pending Signed Off</h2>
                            <div class="row">
                                <div class="col-1">
                                    <div
                                        class="card-container mb-1 d-flex flex-row justify-content-center align-items-center">
                                        <img src="../../../assets/ui_icons/test_completed.svg" style="width: 60px;" />
                                    </div>
                                </div>
                                <div class="col-8">
                                    <div class="row">
                                        <div class="col-3">
                                            <div class="card-container d-flex flex-row justify-content-center align-items-center"
                                                style="background-color: #90e0ef; color: white;">
                                                <div style="text-align: center;">
                                                    <p class="mb-2 card-container-para"><b>Sample Not Collected</b></p>
                                                    <h3 class="card-container-head"
                                                        style="margin-bottom: 0px !important;">0
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <div class="card-container mb-1 d-flex flex-row justify-content-center align-items-center"
                                                style="background-color: #abc4ff; color: white;">
                                                <div style="text-align: center;">
                                                    <p class="mb-2 card-container-para"><b>With Pending Assertion</b>
                                                    </p>
                                                    <h3 class="card-container-head"
                                                        style="margin-bottom: 0px !important;">0
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-2">
                                            <div
                                                class="card-container mb-1 d-flex flex-row justify-content-center align-items-center">
                                                <div style="text-align: center;">
                                                    <p class="mb-2 card-container-para"><b>With Pending Test</b></p>
                                                    <h3 class="card-container-head"
                                                        style="margin-bottom: 0px !important; color: black;">0
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-2">
                                            <div class="card-container mb-1 d-flex flex-row justify-content-center align-items-center"
                                                style="background-color: #ff9500; color: white;">
                                                <div style="text-align: center;">
                                                    <p class="mb-2 card-container-para"><b>Test Not Completed</b></p>
                                                    <h3 class="card-container-head"
                                                        style="margin-bottom: 0px !important;">{{test_nt_completed}}
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-2">
                                            <div class="card-container mb-1 d-flex flex-row justify-content-center align-items-center"
                                                style="background-color: #d00000; color: white;">
                                                <div style="text-align: center;">
                                                    <p class="mb-2 card-container-para"><b> Not Signed Off</b></p>
                                                    <h3 class="card-container-head"
                                                        style="margin-bottom: 0px !important;">{{test_nt_signed}}
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="card-container mb-1 d-flex flex-row justify-content-center align-items-center"
                                                style="color: rgb(230, 5, 5);">
                                                <div style="text-align: center;">
                                                    <p class="mb-2 card-container-para"><b>Exceed TAT Previous Month</b>
                                                    </p>
                                                    <h3 class="card-container-head"
                                                        style="margin-bottom: 0px !important; color:rgb(230, 5, 5);">1
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="card-container mb-1 d-flex flex-row justify-content-center align-items-center"
                                                style="color: rgb(230, 5, 5);">
                                                <div style="text-align: center;">
                                                    <p class="mb-2 card-container-para"><b>Exceed TAT This Month</b></p>
                                                    <h3 class="card-container-head"
                                                        style="margin-bottom: 0px !important; color:rgb(230, 5, 5);">2
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-2">
                                    <div class="card-container mb-1 d-flex flex-row justify-content-center align-items-center"
                                        style="color: rgb(230, 5, 5);">
                                        <div style="text-align: center;">
                                            <p class="mb-2 card-container-para"><b>Exceed TAT Previous Month</b></p>
                                            <h3 class="card-container-head"
                                                style="margin-bottom: 0px !important; color:rgb(230, 5, 5);">2
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-2">
                                    <div class="card-container mb-1 d-flex flex-row justify-content-center align-items-center"
                                        style="color: rgb(230, 5, 5);">
                                        <div style="text-align: center;">
                                            <p class="mb-2 card-container-para"><b>Exceed TAT This Month</b></p>
                                            <h3 class="card-container-head"
                                                style="margin-bottom: 0px !important; color:rgb(230, 5, 5);">2
                                            </h3>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </mdb-card-body>
        </mdb-card>
    </div>
</div>