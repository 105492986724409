<div class="row">
  <div class="col-12">
      <mdb-card>
          <mdb-card-header class="bg-white">
              <div class="headerCover">
                  <div class="headerTilte app_list_header">
                      <h5 class="m-0" class="mainHeadingStyle">Discharge Summary</h5>
                  </div>
                  <div class="headerButtons">
                      <a (click)="goToPatientlist()"><img src="../../../assets/ui_icons/buttons/Back_button.svg"
                              class="saveimgbtn_inpatinfo" /></a>
                              <a style="margin:0 5px;" (click)="print_area()"><img src="../../../assets/img/printer.svg"
                                  class="backimgbtn_inpatinfo" style="width: 25px; height: 25px;" /></a>
                  </div>
              </div>

          </mdb-card-header>
          <mdb-card-body>

            <div class="row">
              <div class="col-12" style="margin-bottom: 5px">
                <div class="cover_div">
                  <div class="header_lable">Personal Details</div>
                  <div class="content_cover">
        
                    <div class="row">
                      <div class="col-12">
                        <div class="row">
                          <div class="col-3">
                            <table style="width: 100%;" class="head-table">
                              <tbody>
                                <tr class="head-tr">
                                  <td class="head-td" style="width: 90px; font-weight: 500 !important;">
                                    MR.No</td>
                                  <td class="head-td" style="width: 10px;font-weight: 500 !important;">:&nbsp;</td>
                                  <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{patient_identifier}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="col-3">
                            <table style="width: 100%;" class="head-table">
                              <tbody>
                                <tr class="head-tr">
                                  <td class="head-td" style="width: 90px; font-weight: 500 !important;">
                                    Patient Name</td>
                                  <td class="head-td" style="width: 10px;font-weight: 500 !important;">:&nbsp;</td>
                                  <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{patient_name}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="col-3">
                            <table style="width: 100%;" class="head-table">
                              <tbody>
                                <tr class="head-tr">
                                  <td class="head-td" style="width: 90px; font-weight: 500 !important;">
                                    Inpatient ID</td>
                                  <td class="head-td" style="width: 10px;font-weight: 500 !important;">:&nbsp;</td>
                                  <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{inpId}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="col-3">
                            <table style="width: 100%;" class="head-table">
                              <tbody>
                                <tr class="head-tr">
                                  <td class="head-td" style="width: 90px; font-weight: 500 !important;">
                                    Age/gender</td>
                                  <td class="head-td" style="width: 10px;font-weight: 500 !important;">:&nbsp;</td>
                                  <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{age}}/{{gender}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="col-3">
                            <table style="width: 100%;" class="head-table">
                              <tbody>
                                <tr class="head-tr">
                                  <td class="head-td" style="width: 90px; font-weight: 500 !important;">
                                    DOA</td>
                                  <td class="head-td" style="width: 10px;font-weight: 500 !important;">:&nbsp;</td>
                                  <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{admis_date}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="col-3">
                            <table style="width: 100%;" class="head-table">
                              <tbody>
                                <tr class="head-tr">
                                  <td class="head-td" style="width: 90px; font-weight: 500 !important;">
                                    DOD</td>
                                  <td class="head-td" style="width: 10px;font-weight: 500 !important;">:&nbsp;</td>
                                  <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{dis_date}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="col-3">
                            <table style="width: 100%;" class="head-table">
                              <tbody>
                                <tr class="head-tr">
                                  <td class="head-td" style="width: 90px; font-weight: 500 !important;">
                                    Consultant(s)</td>
                                  <td class="head-td" style="width: 10px;font-weight: 500 !important;">:&nbsp;</td>
                                  <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                      <div class="row" *ngFor="let name of consultarray">
                                        <p style="text-align: left;">{{name.name}}</p>
                                      </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="col-3" *ngIf="surgery_date != undefined">
                            <table style="width: 100%;" class="head-table">
                              <tbody>
                                <tr class="head-tr">
                                  <td class="head-td" style="width: 90px; font-weight: 500 !important;">
                                    Date of surgery</td>
                                  <td class="head-td" style="width: 10px;font-weight: 500 !important;">:&nbsp;</td>
                                  <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{surgery_date}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="col-3"  *ngIf="surgery_date != undefined">
                            <table style="width: 100%;" class="head-table">
                              <tbody>
                                <tr class="head-tr">
                                  <td class="head-td" style="width: 90px; font-weight: 500 !important;">
                                    Surgeon</td>
                                  <td class="head-td" style="width: 10px;font-weight: 500 !important;">:&nbsp;</td>
                                  <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                    <div class="row" *ngFor="let name of surgeon_list">
                                      <p style="text-align: left;">{{name.name}}</p>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                         <div class="col-3"  *ngIf="surgery_date != undefined">
                            <table style="width: 100%;" class="head-table">
                              <tbody>
                                <tr class="head-tr">
                                  <td class="head-td" style="width: 90px; font-weight: 500 !important;">
                                    Surgeon</td>
                                  <td class="head-td" style="width: 10px;font-weight: 500 !important;">:&nbsp;</td>
                                  <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                    <div class="row" *ngFor="let name of surgeon_list">
                                      <p style="text-align: left;">{{name.name}}</p>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="col-3"  *ngIf="surgery_date != undefined">
                            <table style="width: 100%;" class="head-table">
                              <tbody>
                                <tr class="head-tr">
                                  <td class="head-td" style="width: 90px; font-weight: 500 !important;">
                                    Surgery procedure</td>
                                  <td class="head-td" style="width: 10px;font-weight: 500 !important;">:&nbsp;</td>
                                  <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{surgery_proc}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="col-3"  *ngIf="surgery_date != undefined">
                            <table style="width: 100%;" class="head-table">
                              <tbody>
                                <tr class="head-tr">
                                  <td class="head-td" style="width: 90px; font-weight: 500 !important;">
                                    Anaestetist</td>
                                  <td class="head-td" style="width: 10px;font-weight: 500 !important;">:&nbsp;</td>
                                  <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">
                                    <div class="row" *ngFor="let name of anaestetist">
                                      <p style="text-align: left;">{{name.name}}</p>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="col-3"  *ngIf="surgery_date != undefined">
                            <table style="width: 100%;" class="head-table">
                              <tbody>
                                <tr class="head-tr">
                                  <td class="head-td" style="width: 90px; font-weight: 500 !important;">
                                    Anaestesia</td>
                                  <td class="head-td" style="width: 10px;font-weight: 500 !important;">:&nbsp;</td>
                                  <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{anaestetype}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="col-3"  *ngIf="surgery_date != undefined">
                            <table style="width: 100%;" class="head-table">
                              <tbody>
                                <tr class="head-tr">
                                  <td class="head-td" style="width: 90px; font-weight: 500 !important;">
                                    Anaestesia procedure</td>
                                  <td class="head-td" style="width: 10px;font-weight: 500 !important;">:&nbsp;</td>
                                  <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{anaest_proc}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" >
              <div class="col-sm-6 marginchange"  *ngIf="dis_diag != '' && dis_diag != undefined">
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title">Diagnosis</h5>
                    <div class="card-text">
                      <div class="cradvalue">                      
                      <div class="ipcss dischargeContent" [innerHTML]="dis_diag"> </div>
                    </div>
                  </div>                   
                  </div>
                </div>
              </div>
              <div class="col-sm-6 marginchange"  *ngIf="complaints != '' && complaints != undefined">
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title">Complaints</h5>
                    <div class="card-text"> <div class="ipcss dischargeContent" [innerHTML]="complaints"> </div></div>
                   
                  </div>
                </div>
              </div>
              <div class="col-sm-6 marginchange"  *ngIf="medical_hist != '' && medical_hist != undefined">
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title">Previous Medical History</h5>
                    <div class="card-text"> <div class="ipcss dischargeContent" [innerHTML]="medical_hist"> </div></div>
                   
                  </div>
                </div>
              </div>
              <div class="col-sm-6 marginchange"  *ngIf="physical_exam != '' && physical_exam != undefined">
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title">Physical Examination</h5>
                    <div class="card-text"> <div class="ipcss dischargeContent" [innerHTML]="physical_exam"> </div></div>
                   
                  </div>
                </div>
              </div>
              <div class="col-sm-6 marginchange"  *ngIf="Treatmetnt_done != '' && Treatmetnt_done != undefined">
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title">Treatment/Procedure done</h5>
                    <div class="card-text"> <div class="ipcss dischargeContent" [innerHTML]="Treatmetnt_done"> </div></div>
                   
                  </div>
                </div>
              </div>
              <div class="col-sm-6 marginchange"  *ngIf="hosp_course != '' && hosp_course != undefined">
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title">Summary Course in Hospital</h5>
                    <div class="card-text"> <div class="ipcss dischargeContent" [innerHTML]="hosp_course"> </div></div>
                   
                  </div>
                </div>
              </div>
              <div class="col-sm-6 marginchange"  *ngIf="investigation != '' && investigation != undefined">
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title">Investigation</h5>
                    <div class="card-text"> <div class="ipcss dischargeContent" [innerHTML]="investigation"> </div></div>
                   
                  </div>
                </div>
              </div>
              <div class="col-sm-6 marginchange"  *ngIf="ot_notes != '' && ot_notes != undefined">
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title">OT Notes</h5>
                    <div class="card-text"> <div class="ipcss dischargeContent" [innerHTML]="ot_notes"> </div></div>
                   
                  </div>
                </div>
              </div>
              <div class="col-sm-6 marginchange"  *ngIf="emergency_notes != '' && emergency_notes != undefined">
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title">In-case of Emergency</h5>
                    <div class="card-text"> <div class="ipcss dischargeContent" [innerHTML]="emergency_notes"> </div></div>
                   
                  </div>
                </div>
              </div>
              <div class="col-sm-6 marginchange"  *ngIf="hospital_followup != '' && hospital_followup != undefined">
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title">Discharge advice</h5>
                    <div class="card-text"> <div class="ipcss dischargeContent" [innerHTML]="hospital_followup"> </div></div>
                   
                  </div>
                </div>
              </div>
         
            </div>
            <div clas="row">
              <div class="col-3">
                <table style="width: 100%;" class="head-table">
                  <tbody>
                    <tr class="head-tr">
                      <td class="head-td" style="width: 90px; font-weight: 500 !important;">
                        Next Visit</td>
                      <td class="head-td" style="width: 10px;font-weight: 500 !important;">:&nbsp;</td>
                      <td class="head-td" style="font-family: Arial, Helvetica, sans-serif">{{next_data}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-12">
              <div class="row" *ngIf="druglist.length !=0">
                  <div class="col-12">
                      <table id="tbl" cellpadding="5" cellspacing="0">
                          <tr>
                              <th>Medicine</th>
                              <th>Dosage</th>
                              <th>Days</th>
                          </tr>
                          <tr *ngFor="let medicine of druglist">
                              <td>{{medicine.medicine_name}}</td>
                              <td>{{medicine.medicine_dosage}}</td>
                              <td>{{medicine.medicine_intakedays+" - "+medicine.medicine_food}}</td>
                          </tr>
                      </table>
                  </div>
              </div>
          </div>


           
          </mdb-card-body>
      </mdb-card>
  </div>
</div>

<!-- print section -->
<div id="print" hidden>
  <div #printlogowithname id="printlogowithname">
      <table style="border:'0';width:100%">  
          <thead>  
           <tr>  
            <th style="width:100%;height: 100px;">
              <table style="width: 100%;margin-top: -100px;">
                  <tr>
                      <td style="display: flex;justify-content: center;">
                          <div>
                              <img alt="image" src={{hospital_logo}} style='{{dis_style}}'>
                          </div>
                          <div style="position: relative;top: 20px;left: 10px;text-align: left;">
                              <span><b>{{hosp_name}}</b>,</span><br />
                              <span *ngIf="hosp_addr != ''">{{hosp_addr}}</span><br />
                              <span>{{hosp_csz}}</span><br />
                              <span *ngIf="hosp_tel != undefined"><img src="../../../assets/img/phone.png"
                                      width="15px" height="15px" />{{hosp_tel}}.</span><br>
                          </div>
                      </td>
                  </tr>
              </table>
          </th>  
          </tr>  
         </thead>  
         <tfoot> 
          <tr>  
           <td width="100%">  
            <table style="border:'0';width:100%">  
              <tr>  
                <td style="width:100%;height: 100px;">&nbsp;</td>  
              </tr>  
           </table>  
         </tfoot> 
         <tbody>  
           <tr>  
             <td width="100%">  
              <div class="bill_border">
                  <div>
                      <table style="width: 90%; margin: 0 auto;">
                          <tr>
                              <td style="width: 50%;">
                                  <div class="row">
                                      <div class="col-12 col-md-7 col-lg-8 col-xl-8">
                                          <span><strong>Patient name : </strong>{{patient_name}} </span>
                                          <br />
                                          <span><strong>Inpatient ID : </strong>{{inpId}} </span>
                                          <br />
                                          <span><strong> MR.No : </strong>{{patient_identifier}}</span>
                                          <br />
                                          <span><strong> Consultant(s) : </strong>
                                              <table class="table1" style="margin-left: 50px;">
                                                  <tr *ngFor="let name of consultarray">
                                                      <td style="text-align: left;">{{name.name}}</td>
                                                  </tr>
                                              </table>
                                          </span>
                                      </div>
                                  </div>
                              </td>
          
                              <td style="width: 50%;" valign="top">
                                  <div class="row" style="margin-left: 60px;">
                                      <div class="amount_due col-12 col-md-5 col-lg-4 col-xl-5">
                                          <span><strong>Date of admission: </strong>{{admis_date}} {{admis_time}}</span><br />
                                          <span><strong>Date of discharge : </strong>{{dis_date}} {{dis_time}}</span><br />
                                          <span><strong>Age/gender : </strong>{{age}}/{{gender}} </span>
                                      </div>
                                  </div>
                              </td>
                          </tr>
                      </table>
                      
                      <div class="block1_grid">
                          <div style="margin-bottom: 10px;" *ngIf="dis_diag_flag">
                              <mat-label class="matlabel" style="font-weight: 600;">Discharge Diagnosis :</mat-label>
                              <div style="margin-top:-15px;text-align: justify;" [innerHTML]="dis_diag">
                              </div>
                          </div>
          
                          <div style="margin-bottom: 10px;" *ngIf="treat_flag">
                              <mat-label class="matlabel" style="font-weight: 600;">Treatment/Procedure done : </mat-label>
                              <div style="margin-top:-15px;text-align: justify;"  [innerHTML]="Treatmetnt_done"></div>
                          </div>
                         
                          <div style="margin-bottom: 10px;" *ngIf="comp_flag">
                              <mat-label class="matlabel" style="font-weight: 600;">Complaints : </mat-label>
                              <div style="text-align: justify;"  [innerHTML]="complaints"></div>
                          </div>
          
                          <div style="margin-bottom: 10px;" *ngIf="prev_med_flag">
                              <mat-label class="matlabel" style="font-weight: 600;">Previous Medical History : </mat-label>
                              <div style="text-align: justify;" [innerHTML]="medical_hist">
                              </div>
                          </div>
                          
                          <div style="margin-bottom: 10px;" *ngIf="phy_exam_flag">
                              <mat-label class="matlabel" style="font-weight: 600;">Physical Examination : </mat-label>
                              <div style="text-align: justify;" [innerHTML]="physical_exam">
                              </div>
                          </div>
                      </div>
                      
                      <div class="block1_grid">
                          <div style="margin-bottom: 10px;" *ngIf="inv_flag">
                              <mat-label class="matlabel" style="font-weight: 600;">Investigation : </mat-label>
                              <div style="margin-top:-15px;text-align: justify;" [innerHTML]="investigation">
                              </div>
                          </div>
                         
                          <div style="margin-bottom: 10px;" *ngIf="surgery_date">
                              <mat-label class="matlabel" style="font-weight: 600;">Operative Notes : </mat-label>
                              <div style="margin-bottom: 5px;" >
                                  <span><strong>Surgery Date : </strong>{{surgery_date}} </span><br />
                                  <span><strong>Surgery : </strong>{{surgery_proc}} </span><br />
                                  <span><strong>Surgeon : </strong><table>
                                      <tr *ngFor="let surgeon of surgeon_list;">
                                          <td style="text-align: left;" class="matlabel">{{surgeon.name}}</td>
                                      </tr>
                                  </table></span><br />
                                  <span><strong>Anaestetist : </strong><table>
                                      <tr *ngFor="let anaest of anaestetist;">
                                          <td style="text-align: left;" class="matlabel">{{anaest.name}}</td>
                                      </tr>
                                  </table></span><br />
                                  <span><strong>Anaestesia : </strong>{{anaestetype}} </span><br />
                                  <span><strong>Findings : </strong>{{anaest_findings}} </span><br />
                                  <span><strong>Procedure done : </strong>{{anaest_proc}} </span><br />
                              </div>
                          </div>
                          
                          <div style="margin-bottom: 10px;" *ngIf="sum_course_flag">
                              <mat-label class="matlabel" style="font-weight: 600;"> Summary Course in Hospital : </mat-label>
                              <div style="margin-top:-15px;text-align: justify;" [innerHTML]="hosp_course">
                              </div>
                          </div>
                          
                          <div style="margin-bottom: 10px;" *ngIf="dis_adv_flag">
                              <mat-label class="matlabel" style="font-weight: 600;">Discharge Advice : </mat-label>
                              <div style="margin-top:-15px;text-align: justify;" [innerHTML]="hospital_followup">
                              </div>
                          </div>
                      </div>
                      <div style="margin-left: 40px;" *ngIf="druglist.length !=0">
                          <div class="col-12">
                              <table id="tbl" cellpadding="5" cellspacing="0">
                                  <tr>
                                      <th>Medicine</th>
                                      <th>Dosage</th>
                                      <th>Days</th>
                                  </tr>
                                  <tr *ngFor="let medicine of druglist">
                                      <td>{{medicine.medicine_name}}</td>
                                      <td>{{medicine.medicine_dosage}}</td>
                                      <td>{{medicine.medicine_intakedays+" - "+medicine.medicine_food}}</td>
                                  </tr>
                              </table>
                          </div>
                      </div>
                      <br/>
                      <div style="width: 90%; margin: 0 auto;">
                          <mat-label class="matlabel"> <b>Next Visit :</b> {{next_data}}</mat-label>
                          <br/>
                          <br/>
                          <mat-label class="matlabel"> <b>In case of emergency contact :</b> {{hosp_tel}}</mat-label>
                      </div>
                  </div>
              </div>
              <br />
              <table style="width: 100%; margin-top: 80px;">
                  <tr>
                      <td style="width: 50%;"></td>
                      <td style="width: 50%;">
                          <p style="text-align: center;font-size: 13px"><b>( Dr. {{doctor_name}} )</b></p>
                      </td>
                  </tr>
              </table>
             </td>  
          </tr> 
        </tbody>  
      </table>
  </div>

  <div #printbanner id="printbanner">
      <table style="border:'0';width:100%">  
          <thead>  
           <tr>  
            <th style="width:100%;height: 100px;">
              <table style="margin-left:10px;margin-top:-100px;margin-right: 10px;">
                  <tr>
                     <td>
                        <img alt="image" src={{hptl_dis_logo}} style='{{dis_style}}'>
                     </td>
                  </tr>
              </table>
            </th>  
          </tr>  
         </thead>  
         <tfoot> 
          <tr>  
           <td width="100%">  
            <table style="border:'0';width:100%">  
              <tr>  
                <td style="width:100%;height: 100px;">&nbsp;</td>  
             </tr>  
           </table>  
         </tfoot> 
         <tbody>  
           <tr>  
             <td width="100%">  
              <div >
                  
                   <table style="width: 90%; margin: 0 auto;">
                      <tr>
                          <td colspan="2">
                            <div [innerHTML]="headerstyle"></div>
                          </td>
                      </tr>
                      <tr>
                          <td style="width: 50%;">
                              <div class="row">
                                  <div class="col-12 col-md-7 col-lg-8 col-xl-8">
                                      <span><strong>Patient name : </strong>{{patient_name}} </span>
                                      <br />
                                      <span><strong>Inpatient ID : </strong>{{inpId}} </span>
                                      <br />
                                      <span><strong> MR.No : </strong>{{patient_identifier}}</span>
                                      <br />
                                      <span><strong> Consultant(s) : </strong>
                                          <table class="table1" style="margin-left: 50px;">
                                              <tr *ngFor="let name of consultarray">
                                                  <td style="text-align: left;">{{name.name}}</td>
                                              </tr>
                                          </table>
                                      </span>
                                  </div>
                              </div>
                          </td>
      
                          <td style="width: 50%;" valign="top">
                              <div class="row" style="margin-left: 60px;">
                                  <div class="amount_due col-12 col-md-5 col-lg-4 col-xl-5">
                                      <span><strong>DOA: </strong>{{admis_date}} {{admis_time}}</span><br />
                                      <span><strong>DOD : </strong>{{dis_date}} {{dis_time}}</span><br />
                                      <span><strong>Age/gender : </strong>{{age}}/{{gender}} </span>
                                  </div>
                              </div>
                          </td>
                      </tr>
                  </table>
      
                  <div class="block1_grid">
                      <div style="margin-bottom: 10px;" *ngIf="dis_diag_flag">
                          <mat-label class="matlabel" style="font-weight: 600;">Discharge Diagnosis :</mat-label>
                          <div style="margin-top:-15px;text-align: justify;" [innerHTML]="dis_diag">
                          </div>
                      </div>
      
                      <div style="margin-bottom: 10px;" *ngIf="treat_flag">
                          <mat-label class="matlabel" style="font-weight: 600;">Treatment/Procedure done : </mat-label>
                          <div style="margin-top:-15px;text-align: justify;"  [innerHTML]="Treatmetnt_done"></div>
                      </div>
                     
                      <div style="margin-bottom: 10px;" *ngIf="comp_flag">
                          <mat-label class="matlabel" style="font-weight: 600;">Complaints : </mat-label>
                          <div style="text-align: justify;"  [innerHTML]="complaints"></div>
                      </div>
      
                      <div style="margin-bottom: 10px;" *ngIf="prev_med_flag">
                          <mat-label class="matlabel" style="font-weight: 600;">Previous Medical History : </mat-label>
                          <div style="text-align: justify;" [innerHTML]="medical_hist">
                          </div>
                      </div>
                      
                      <div style="margin-bottom: 10px;" *ngIf="phy_exam_flag">
                          <mat-label class="matlabel" style="font-weight: 600;">Physical Examination : </mat-label>
                          <div style="text-align: justify;" [innerHTML]="physical_exam">
                          </div>
                      </div>
                  </div>
                  
                  <div class="block1_grid">
                      <div style="margin-bottom: 10px;" *ngIf="inv_flag">
                          <mat-label class="matlabel" style="font-weight: 600;">Investigation : </mat-label>
                          <div style="margin-top:-15px;text-align: justify;" [innerHTML]="investigation">
                          </div>
                      </div>
                     
                      <div style="margin-bottom: 10px;" *ngIf="surgery_date">
                          <mat-label class="matlabel" style="font-weight: 600;">Operative Notes : </mat-label>
                          <div style="margin-bottom: 5px;" >
                              <span><strong>Surgery Date : </strong>{{surgery_date}} </span><br />
                              <span><strong>Surgery : </strong>{{surgery}} </span><br />
                              <span><strong>Surgery procedure : </strong>{{surgery_proc}} </span><br />
                              <span><strong>Surgeon : </strong><table>
                                  <tr *ngFor="let surgeon of surgeon_list;">
                                      <td style="text-align: left;" class="matlabel">{{surgeon.name}}</td>
                                  </tr>
                              </table></span><br />
                              <span><strong>Anaestetist : </strong><table>
                                  <tr *ngFor="let anaest of anaestetist;">
                                      <td style="text-align: left;" class="matlabel">{{anaest.name}}</td>
                                  </tr>
                              </table> </span><br />
                              <span><strong>Anaestesia : </strong>{{anaestetype}} </span><br />
                              <span><strong>Findings : </strong>{{anaest_findings}} </span><br />
                              <span><strong>Procedure done : </strong>{{anaest_proc}} </span><br />
                          </div>
                      </div>
                      
                      <div style="margin-bottom: 10px;" *ngIf="sum_course_flag">
                          <mat-label class="matlabel" style="font-weight: 600;"> Summary Course in Hospital : </mat-label>
                          <div style="margin-top:-15px;text-align: justify;" [innerHTML]="hosp_course">
                          </div>
                      </div>
                      
                      <div style="margin-bottom: 10px;" *ngIf="dis_adv_flag">
                          <mat-label class="matlabel" style="font-weight: 600;">Discharge Advice : </mat-label>
                          <div style="margin-top:-15px;text-align: justify;" [innerHTML]="hospital_followup">
                          </div>
                      </div>
                  </div>
      
                  <div style="margin-left: 40px;" *ngIf="druglist.length !=0">
                      <div class="col-12">
                          <table id="tbl" cellpadding="5" cellspacing="0">
                              <tr>
                                  <th>Medicine</th>
                                  <th>Dosage</th>
                                  <th>Days</th>
                              </tr>
                              <tr *ngFor="let medicine of druglist">
                                  <td>{{medicine.medicine_name}}</td>
                                  <td>{{medicine.medicine_dosage}}</td>
                                  <td>{{medicine.medicine_intakedays+" - "+medicine.medicine_food}}</td>
                              </tr>
                          </table>
                      </div>
                  </div>
                  <br/>
                  <div style="width: 90%; margin: 0 auto;">
                      <mat-label class="matlabel"> <b>Next Visit :</b> {{next_data}}</mat-label>
                      <br/>
                      <br/>
                      <mat-label class="matlabel"> <b>In case of emergency contact :</b> {{hosp_tel}}</mat-label>
                  </div>
              </div>
              <br />
              <table style="width: 100%; margin-top: 80px;">
                  <tr>
                      <td style="width: 50%;"></td>
                      <td style="width: 50%;">
                          <p style="text-align: center;font-size: 13px"><b>( Dr. {{doctor_name}} )</b></p>
                      </td>
                  </tr>
              </table>
             </td>  
          </tr> 
        </tbody>  
      </table>
  </div>
  
  <div #printnoheader id="printnoheader">
      <table style="border:'0';width:100%">  
          <thead>  
           <tr>  
            <th style="width:100%;height: 100px;"></th>  
          </tr>  
         </thead>  
         <tfoot> 
          <tr>  
           <td width="100%">  
            <table style="border:'0';width:100%">  
              <tr>  
                <td style="width:100%;height: 100px;">&nbsp;</td>  
             </tr>  
           </table>  
         </tfoot> 
         <tbody>  
           <tr>  
             <td width="100%">  
              <div >
                  <table style="margin-left:10px;margin-top:-10px;margin-right: 10px;">
                      <tr style="margin-top: 80px;"></tr>
                   </table>
                   <br /><br />
                   <table style="width: 90%; margin: 0 auto;">
                      <tr>
                          <td colspan="2">
                            <div [innerHTML]="headerstyle"></div>
                          </td>
                      </tr>
                      <tr>
                          <td style="width: 50%;">
                              <div class="row">
                                  <div class="col-12 col-md-7 col-lg-8 col-xl-8">
                                      <span><strong>Patient name : </strong>{{patient_name}} </span>
                                      <br />
                                      <span><strong>Inpatient ID : </strong>{{inpId}} </span>
                                      <br />
                                      <span><strong>MR.No : </strong>{{patient_identifier}}</span>
                                      <br />
                                      <span><strong> Consultant(s) : </strong>
                                          <table class="table1" style="margin-left: 50px;">
                                              <tr *ngFor="let name of consultarray">
                                                  <td style="text-align: left;">{{name.name}}</td>
                                              </tr>
                                          </table>
                                      </span>
                                  </div>
                              </div>
                          </td>
      
                          <td style="width: 50%;" valign="top">
                              <div class="row" style="margin-left: 60px;">
                                  <div class="amount_due col-12 col-md-5 col-lg-4 col-xl-5">
                                      <span><strong>DOA: </strong>{{admis_date}} {{admis_time}}</span><br />
                                      <span><strong>DOD : </strong>{{dis_date}} {{dis_time}}</span><br />
                                      <span><strong>Age/gender : </strong>{{age}}/{{gender}} </span>
                                  </div>
                              </div>
                          </td>
                      </tr>
                  </table>
      
                  <div class="block1_grid">
                      <div style="margin-bottom: 10px;" *ngIf="dis_diag_flag">
                          <mat-label class="matlabel" style="font-weight: 600;">Discharge Diagnosis :</mat-label>
                          <div style="margin-top:-15px;text-align: justify;" [innerHTML]="dis_diag">
                          </div>
                      </div>
      
                      <div style="margin-bottom: 10px;" *ngIf="treat_flag">
                          <mat-label class="matlabel" style="font-weight: 600;">Treatment/Procedure done : </mat-label>
                          <div style="margin-top:-15px;text-align: justify;"  [innerHTML]="Treatmetnt_done"></div>
                      </div>
                     
                      <div style="margin-bottom: 10px;" *ngIf="comp_flag">
                          <mat-label class="matlabel" style="font-weight: 600;">Complaints : </mat-label>
                          <div style="text-align: justify;"  [innerHTML]="complaints"></div>
                      </div>
      
                      <div style="margin-bottom: 10px;" *ngIf="prev_med_flag">
                          <mat-label class="matlabel" style="font-weight: 600;">Previous Medical History : </mat-label>
                          <div style="text-align: justify;" [innerHTML]="medical_hist">
                          </div>
                      </div>
                      
                      <div style="margin-bottom: 10px;" *ngIf="phy_exam_flag">
                          <mat-label class="matlabel" style="font-weight: 600;">Physical Examination : </mat-label>
                          <div style="text-align: justify;" [innerHTML]="physical_exam">
                          </div>
                      </div>
                  </div>
                  
                  <div class="block1_grid">
                      <div style="margin-bottom: 10px;" *ngIf="inv_flag">
                          <mat-label class="matlabel" style="font-weight: 600;">Investigation : </mat-label>
                          <div style="margin-top:-15px;text-align: justify;" [innerHTML]="investigation">
                          </div>
                      </div>
                     
                      <div style="margin-bottom: 10px;" *ngIf="surgery_date">
                          <mat-label class="matlabel" style="font-weight: 600;">Operative Notes : </mat-label>
                          <div style="margin-bottom: 5px;" >
                              <span><strong>Surgery Date : </strong>{{surgery_date}} </span><br />
                              <span><strong>Surgery : </strong>{{surgery}} </span><br />
                              <span><strong>Surgery procedure : </strong>{{surgery_proc}} </span><br />
                              <span><strong>Surgeon : </strong><table>
                                  <tr *ngFor="let surgeon of surgeon_list;">
                                      <td style="text-align: left;" class="matlabel">{{surgeon.name}}</td>
                                  </tr>
                              </table></span><br />
                              <span><strong>Anaestetist : </strong><table>
                                  <tr *ngFor="let anaest of anaestetist;">
                                      <td style="text-align: left;" class="matlabel">{{anaest.name}}</td>
                                  </tr>
                              </table> </span><br />
                              <span><strong>Anaestesia : </strong>{{anaestetype}} </span><br />
                              <span><strong>Findings : </strong>{{anaest_findings}} </span><br />
                              <span><strong>Procedure done : </strong>{{anaest_proc}} </span><br />
                          </div>
                      </div>
                      
                      <div style="margin-bottom: 10px;" *ngIf="sum_course_flag">
                          <mat-label class="matlabel" style="font-weight: 600;"> Summary Course in Hospital : </mat-label>
                          <div style="margin-top:-15px;text-align: justify;" [innerHTML]="hosp_course">
                          </div>
                      </div>
                      
                      <div style="margin-bottom: 10px;" *ngIf="dis_adv_flag">
                          <mat-label class="matlabel" style="font-weight: 600;">Discharge Advice : </mat-label>
                          <div style="margin-top:-15px;text-align: justify;" [innerHTML]="hospital_followup">
                          </div>
                      </div>
                  </div>
      
                  <div style="margin-left: 40px;" *ngIf="druglist.length !=0">
                      <div class="col-12">
                          <table id="tbl" cellpadding="5" cellspacing="0">
                              <tr>
                                  <th>Medicine</th>
                                  <th>Dosage</th>
                                  <th>Days</th>
                              </tr>
                              <tr *ngFor="let medicine of druglist">
                                  <td>{{medicine.medicine_name}}</td>
                                  <td>{{medicine.medicine_dosage}}</td>
                                  <td>{{medicine.medicine_intakedays+" - "+medicine.medicine_food}}</td>
                              </tr>
                          </table>
                      </div>
                  </div>
                  <br/>
                  <div style="width: 90%; margin: 0 auto;">
                      <mat-label class="matlabel"> <b>Next Visit :</b> {{next_data}}</mat-label>
                      <br/>
                      <br/>
                      <mat-label class="matlabel"> <b>In case of emergency contact :</b> {{hosp_tel}}</mat-label>
                  </div>
              </div>
              <br />
              <table style="width: 100%; margin-top: 80px;">
                  <tr>
                      <td style="width: 50%;"></td>
                      <td style="width: 50%;">
                          <p style="text-align: center;font-size: 13px"><b>( Dr. {{doctor_name}} )</b></p>
                      </td>
                  </tr>
              </table>
             </td>  
          </tr> 
        </tbody>  
      </table>  
  </div>
</div>
<!-- end of print section -->