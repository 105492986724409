<div class="row" style="width:100%;margin:0 auto;">
  <div class="col-12">
    <mdb-card>
      <mdb-card-header class="bg-white">
        <div class="headerCover">
          <div class="headerTilte app_list_header">
            <h5 class="m-0" class="mainHeadingStyle">
              {{inwardpage_name}}
            </h5>
          </div>
          <div class="headerButtons">

            <img src="../../../assets/ui_icons/buttons/Back_button.svg" class="saveimgbtn_inpatinfo"
              (click)="backButton()" [hidden]="backbuttonflag" />

            <!-- <img src="../../../assets/ui_icons/buttons/Back_button.svg" [hidden]="inwardpage_name == 'Inward'"
                    class="saveimgbtn_inpatinfo"  (click)="pastorderview()"/>   -->
            <img *ngIf="saveFlag" src="../../../assets/ui_icons/buttons/save_button.svg" class="saveimgbtn_inpatinfo"
              (click)="save()">

            <img [hidden]="printerflag" printSectionId="print" (click)="printArea()"
              src="../../../assets/img/printer.svg" class="saveimgbtn_inpatinfo" style="width:25px">
            <img *ngIf="newbutton" class="saveimgbtn_inpatinfo" src="../../../assets/ui_icons/buttons/new_button.svg"
              (click)="neworder()" />

          </div>
        </div>
      </mdb-card-header>
      <mdb-card-body>

        <div>
          <div [hidden]="inwards_flag">
            <div class="row">


              <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom"
                [hidden]='this.inwardpage_name == "Past inwards view"'>
                <mat-label class="matlabel">Filter by<br>
                  <select required class="ipcss" [(ngModel)]="filter" required (change)="changeFilter()"
                    disableOptionCentering>
                    <!-- <option value="Doctor">Doctor</option> -->
                    <option value="bill_no">Bill no</option>
                    <option value="Date">Date</option>
                    <option value="Name">Name</option>
                    <option value="Patient_ID">MR no</option>
                    <option value="Mobile_No">Mobile No</option>
                  </select>
                </mat-label>
              </div>

              <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom"
                [hidden]='this.inwardpage_name == "Past inwards view"'>
                <!-- <mat-label class="matlabel" [hidden]="doctorFlag">Doctor<br>
                        <select required class="ipcss" [hidden]="doctorFlag" (change)="getAppointments()" [(ngModel)]="docRegId" disableOptionCentering>
                          <option *ngFor="let doctors of doctorList" value="{{doctors.doc_id}}"> {{doctors.doc_name}} </option>
                        </select>
                      </mat-label> -->
                <mat-label class="matlabel" [hidden]="billnoFlag">Bill Number
                  <input type="text" class="ipcss" [(ngModel)]="orderNo" matInput
                    (keyup.enter)="searchByOrderNo(this.orderNo)" (keyup.Space)="searchByOrderNo(this.orderNo)" />
                </mat-label>

                <mat-label class="matlabel" [hidden]="toFlag">From Date<br>
                  <input type="date" class="ipcss widthbilllist datecss" (change)="selectFromDate(fromDate)"
                    [hidden]="toFlag" [(ngModel)]="fromDate" #matInput max="{{currentDate}}">
                </mat-label>

                <!-- <mat-label class="matlabel" [hidden]="toFlag">To Date<br>
                        <input type="date" class="ipcss widthbilllist datecss" (change)="selectFromDate(fromDate)"
                          [hidden]="toFlag" [(ngModel)]="toDate" #matInput max="{{currentDate}}">
                      </mat-label> -->

                <mat-label class="matlabel" [hidden]="firstNameFlag">First name<br>
                  <input matInput class="ipcss widthbilllist" type="text" [hidden]="firstNameFlag" required
                    [(ngModel)]="firstName" (blur)="fnameToUpper()" style="margin-bottom: 10px;" />
                </mat-label>

                <mat-label class="matlabel" [hidden]="uhidFlag">MR.No<br>
                  <input matInput class="ipcss widthbilllist" type="text" [hidden]="uhidFlag" required
                    [(ngModel)]="patientId" (keyup.enter)="searchBill();openModal()"
                    (keyup.Space)="searchBill();openModal()" />
                  <!-- data-bs-toggle="modal" data-bs-target="#billdetails" -->
                </mat-label>

                <mat-label class="matlabel" [hidden]="mobileFlag">Mobile No<br>
                  <input matInput class="ipcss widthbilllist" type="text" [hidden]="mobileFlag" required
                    [(ngModel)]="mobileNo" (keyup.enter)="searchBill();openModal()"
                    (keyup.Space)="searchBill();openModal()" />
                </mat-label>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 col-xxl-2 select_bottom" [hidden]="firstNameFlag">
                <mat-label class="matlabel" [hidden]="firstNameFlag">Last name<br>
                  <input matInput class="ipcss widthbilllist" type="text" [hidden]="firstNameFlag" required
                    [(ngModel)]="lastName" (blur)="lnameToUpper()" />
                </mat-label>
              </div>



              <!-- <div class="col-12 col-sm-6 col-md-4 col-xl-3 col-lg-3" [hidden]='this.inwardpage_name == "Past inwards view"'>
                      <img src="../../assets/img/search.png" *ngIf="this.filter == 'bill_no'" style="position:relative;top:20px" (click)="searchByOrderNo(this.orderNo)" class="seacrhicon" width="25"
                          height="auto" class="searchIcon" />
                          <img src="../../assets/img/search.png" *ngIf="this.filter != 'bill_no'" style="position:relative;top:20px"  (click)="searchBill()" class="seacrhicon" width="25"
                          height="auto" class="searchIcon" data-bs-toggle="modal" data-bs-target="#billdetails"/>    
                      
                </div> -->


              <div class="col-12 col-sm-6 col-md-4 col-xl-3 col-lg-3"
                [hidden]='this.inwardpage_name == "Past inwards view"'>
                <!-- (keyup.enter)="searchByOrderNo(this.orderNo)" -->
                <a *ngIf="this.filter == 'bill_no'">
                  <!-- (click)="searchByOrderNo(this.orderNo)" -->
                  <img src="../../assets/img/search.png" *ngIf="this.filter == 'bill_no'"
                    (click)="searchByOrderNo(this.orderNo)" style="position:relative;top:20px" tabindex="0"
                    (keyup.enter)="searchByOrderNo(this.orderNo)" (keyup.Space)="searchByOrderNo(this.orderNo)"
                    class="seacrhicon" width="25" height="auto" class="searchIcon" />
                </a>

                <a *ngIf="this.filter == 'Date'">
                  <img src="../../assets/img/search.png" style="position:relative;top:20px" tabindex="0"
                    (click)="searchBill()" (keyup.enter)="searchBill();openModal()"
                    (keyup.Space)="searchBill();openModal()" class="seacrhicon" width="25" height="auto"
                    class="searchIcon" data-bs-toggle="modal" data-bs-target="#billdetails" />
                </a>

                <a *ngIf="this.filter != 'Date' && this.filter != 'bill_no'">
                  <img src="../../assets/img/search.png" *ngIf="this.filter != 'bill_no'"
                    style="position:relative;top:20px" tabindex="0" (click)="searchBill()"
                    (keyup.enter)="searchBill();openModal()" (keyup.Space)="searchBill();openModal()" class="seacrhicon"
                    width="25" height="auto" class="searchIcon" data-bs-toggle="modal" data-bs-target="#billdetails" />
                </a>

              </div>
              <div class="modal fade" id="billdetails" tabindex="0" role="dialog" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-md">
                  <div class="modal-content" style="width:165%">
                    <div class="modal-header">
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                      </button>
                    </div>
                    <div class="modal-body">
                      <div class="col-12">
                        <div class="patientdetails-responsive">
                          <table class="patientdetailstable">
                            <thead>
                              <tr style="background-color:#c1e6fb;color: rgba(0,0,0,.54);min-height: 56px;">
                                <th class="delete" style="width:15%">Bill no</th>
                                <th style="width:20%">Date</th>
                                <th style="width:20%">Client name</th>
                                <th style="width:20%">Pay type</th>
                                <th>Amount</th>
                                <th>Paid</th>
                                <th>Balance</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let bill of billList" (click)="searchByOrderNo(bill.bill_id)" tabindex="0"
                                (keyup.Space)=searchByOrderNo(bill.bill_id) (keyup.enter)=searchByOrderNo(bill.bill_id)
                                data-bs-dismiss="modal" aria-label="Close">
                                <td>

                                  {{bill.bill_id}}
                                </td>
                                <td>

                                  {{bill.date}}
                                </td>
                                <td>

                                  {{bill.salutation}} {{bill.client_name}}</td>
                                <td>
                                  {{bill.pay_type}}
                                </td>
                                <td>{{bill.bill_amount}}</td>
                                <td>{{bill.paid_amount}}</td>
                                <td>{{bill.balance}}</td>
                                <td>
                                  {{bill.paid_flag}}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div class="col-12" *ngIf="viewOrderFlag && drugDetailsArray.length != 0">
                <div class="cover_div">
                  <div class="header_lable">
                    Billing details
                  </div>
                  <div class="content_cover">
                    <div class="row">
                      <div class="col-3">
                        <table style="width: 100%;" class="head-table">
                          <tbody>
                            <tr class="head-tr">
                              <td class="head-td" style="vertical-align: top;width: 100px; font-weight: 400;"><strong>MR
                                  No</strong></td>
                              <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                              <td class="head-td">{{mr_no}}</td>
                            </tr>
                            <tr class="head-tr">
                              <td class="head-td" style="vertical-align: top;width: 100px; font-weight: 400;">
                                <strong>Patient name</strong>
                              </td>
                              <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                              <td class="head-td">{{salutation}}. {{OrderByName}}</td>
                            </tr>
                            
                          </tbody>
                        </table>
                      </div>
                      <div class="col-3">
                        <table style="width: 100%;" class="head-table">
                          <tbody>
                            <tr class="head-tr">
                              <td class="head-td" style="vertical-align: top;width: 100px; font-weight: 400;">
                                <strong>Bill no</strong>
                              </td>
                              <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                              <td class="head-td">{{orderNumber}}</td>
                            </tr>
                            <tr class="head-tr">
                              <td class="head-td" style="vertical-align: top;width: 100px; font-weight: 400;">
                                <strong>Bill type</strong>
                              </td>
                              <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                              <td class="head-td">{{PurchaseType}}</td>
                            </tr>
                           
                          </tbody>
                        </table>
                      </div>
                      <div class="col-3">
                        <table style="width: 100%;" class="head-table">
                          <tbody>
                            <tr class="head-tr">
                              <td class="head-td" style="vertical-align: top;width: 100px; font-weight: 400;">
                                <strong>Doctor</strong>
                              </td>
                              <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                              <td class="head-td"><span>{{drsal}} </span> {{doctorName}}</td>
                            </tr>
                            <tr class="head-tr">
                              <td class="head-td" style="vertical-align: top;width: 100px; font-weight: 400;">
                                <strong>Age
                                  /
                                  Gender</strong></td>
                              <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                              <td class="head-td">{{clntAge}} / {{clntGender}}</td>
                            </tr>
                            <tr class="head-tr" [hidden]="!paytypreq">
                              <td class="head-td" style="vertical-align: top;width: 100px; font-weight: 400;">
                                <strong>Payment type</strong>
                              </td>
                              <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                              <td class="head-td" [hidden]="this.inwardpage_name == 'Inward'">{{paytype}}</td>
                              <mat-label class="matlabel" [hidden]="this.inwardpage_name =='Past inward list'"><br>
                                <mat-select id="paytype" class="ipcss  widthbillcreate" [(ngModel)]="payType"
                                  (selectionChange)="changePay()" disableOptionCentering>
                                  <mat-option *ngFor="let pay of payTypeArray"
                                    value={{pay.pay_id}}>{{pay.pay_desc}}</mat-option>
                                </mat-select>
                              </mat-label>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div class="col-3">
                        <table style="width: 100%;" class="head-table">
                          <tbody>
                            <tr class="head-tr">
                              <td class="head-td" style="vertical-align: top;width: 160px; font-weight: 400;">
                                <strong>Bill date time</strong>
                              </td>
                              <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                              <td class="head-td" style="vertical-align: top;">{{orderDate}} {{orderTime}}</td>
                            </tr>
                            <tr class="head-tr">
                              <td class="head-td" style="vertical-align: top;width: 100px; font-weight: 400;">
                                <strong>Rate plan </strong>
                              </td>
                              <td class="head-td" style="width: 20px; vertical-align: top;">:&nbsp;</td>
                              <td class="head-td"><select style="width:125px" disableOptionCentering class="ipcss "
                                  required [(ngModel)]="discountType" (change)="discountChange(discountType)"
                                  [disable]="this.inwardpage_name == 'Past inwards view'">
                                  <option disabled>Select</option>
                                  <option *ngFor="let disc of discountDatasArray" value="{{disc.disc_type_id}}">
                                    {{disc.description}} ({{disc.discount_type}})</option>
                                </select></td>
                            </tr>


                            </tbody>
                            </table>
                        </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <p class="nodata" *ngIf="drugDetailsArray.length == 0">
              <span *ngIf="fullyrefundflag" style="display:block">No Inwards data(s) found</span>
              <span *ngIf="!fullyrefundflag" style="display:block">Bill has been fully refunded</span>
            </p>
            <div class="row" *ngIf="viewOrderFlag && drugDetailsArray.length != 0">

              <div class="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-2" [hidden]="insurFlag">
                <mat-label class="matlabel">Insurer<br>
                  <mat-select required class="ipcss  widthbillcreate" [(ngModel)]="insurerID" disableOptionCentering>
                    <mat-option *ngFor="let ins of insurerArray" value={{ins.insurer_id}}>
                      {{ins.insurer_desc}}</mat-option>
                  </mat-select>
                </mat-label>
              </div>
              <div class="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-2" [hidden]="cardFlag">
                <mat-label class="matlabel">Card No <br>
                  <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" />
                </mat-label>
              </div>
              <div class="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-2" [hidden]="cardFlag">
                <mat-label class="matlabel">Holder name<br>
                  <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardHolderName" />
                </mat-label>
              </div>
              <div class="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-2" [hidden]="tranFlag">
                <mat-label class="matlabel">Transaction ID<br>
                  <input class="ipcss  widthbillcreate" matInput [(ngModel)]="transactionID" />
                </mat-label>
              </div>
              <div class="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-2" [hidden]="chequeFlag">
                <mat-label class="matlabel">Cheque No <br>
                  <input class="ipcss  widthbillcreate" matInput [(ngModel)]="cardNumber" />
                </mat-label>
              </div>
              <div class="col-4 col-sm-4 col-md-4 col-lg-3 col-xl-2" [hidden]="chequeFlag">
                <mat-label class="matlabel">Bank Name <br>
                  <input class="ipcss  widthbillcreate" matInput [(ngModel)]="bankName" />
                </mat-label>
              </div>
              <input class="ipcss" [(ngModel)]="medfilter" (ngModelChange)="filterMedicines()"
                style="width:17%;position:relative;left:10px;top:10px" [hidden]='this.inwardpage_name == "Past inwards view"'>
              <div class="col-12" style="margin-top: 15px;" *ngIf="drugDetailsArray.length != 0">
                <div class="dig_table_overflow" style="margin-bottom: 20px;">
                  <div class="table-resposive">
                    <table cellspacing="0" cellpadding="0" class="table table-nowrap table-sm pharmacycurrentorder">
                      <thead class="tableheading">
                        <tr>
                          <th>Medicine type</th>
                          <th>Medicine name</th>
                          <th style="width: 12%;">Batch</th>
                          <th style="width: 8%;">Quantity</th>
                          <th>Price</th>
                          <th> Discount (%)</th>
                          <th>CGST</th>
                          <th>SGST</th>
                          <th> Amount</th>
                          <th [hidden]='this.inwardpage_name == "Past inwards view"'> Action</th>
                          <!-- [hidden]="!orderType" -->
                        </tr>
                      </thead>
                      <tbody>

                        <tr *ngFor="let medpre of drugDetailsArray">
                          <td style="text-align: left;">{{medpre.type_name}}</td>
                          <td style="text-align: left;">{{medpre.drug_name}}</td>
                          <td>
                            <p [hidden]="medpre.batch_hide">{{medpre.batch_no}}</p>
                            <!-- <select [(ngModel)]="medpre.batch_no" class="ipcss " required
                                                      style="margin-top: 10px;" (change)="retrieveBatch(medpre)">
                                                      <option *ngFor="let batch of medpre.batchnos" value={{batch}}>
                                                          {{batch}}</option>
                                                  </select> -->
                          </td>
                          <td>
                            <input type="text" matInput class="ipcss " [(ngModel)]="medpre.quantity"
                              (focus)="clearField(medpre)" style="width: 65%;margin: 0 auto !important;" maxlength="3"
                              [hidden]="confirmFlag" class="ipcss "
                              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                              (keyup)="CalculatePrice(medpre.drug_id,medpre.type_name,medpre.drug_name,medpre.quantity,medpre.price,medpre.per_unit,medpre.discount,medpre.prod_gst)">
                            <p [hidden]="!confirmFlag">{{medpre.quantity}} </p>
                          </td>
                          <td>
                            <p style="float: right; margin-right: 10px;">{{medpre.price}} </p>
                          </td>
                          <td>
                            <p style="float: right; margin-right: 10px;">{{medpre.discount}}
                            </p>
                          </td>
                          <td>
                            <p style="float: right; margin-right: 10px;">{{medpre.cgst}}
                            </p>
                          </td>
                          <td>
                            <p style="float: right; margin-right: 10px;">{{medpre.sgst}}
                            </p>
                          </td>
                          <td>
                            <p style="float: right; margin-right: 10px;">{{medpre.amount}}</p>
                          </td>
                          <td style="text-align: center;" [hidden]='this.inwardpage_name == "Past inwards view"'>
                            <!-- <a (click)="deleteMedicine(medpre.drug_id)">
                                                  <img src="../../../assets/ui_icons/buttons/trash_icon.svg"
                                                      width="20" height="20" /></a> -->
                            <img *ngIf="medpre.checked == false" (click)="isAllSelected(medpre,'select')"
                              src="../../../assets/ui_icons/yellow_box.svg" style="cursor: pointer;" width="15px" />
                            <img *ngIf="medpre.checked == true" (click)="isAllSelected(medpre,'unselect')"
                              src="../../../assets/ui_icons/popup_tick_mark.svg" style="cursor: pointer;"
                              width="15px" />
                          </td>
                        </tr>
                        <tr *ngFor="let med of returnsmeds" [hidden]="returnsmeds.length == 0" style="background-color:lightgrey">
                          <td style="text-align: left;">{{med.type_name}}</td>
                          <td style="text-align: left;">{{med.drug_name}}</td>
                          <td>
                            <p [hidden]="med.batch_hide">{{med.batch_no}}</p>

                          </td>
                          <td>

                            <p>{{med.quantity}} </p>
                          </td>
                          <td>
                            <p style="float: right; margin-right: 10px;">{{med.price}} </p>
                          </td>
                          <td>
                            <p style="float: right; margin-right: 10px;">{{med.discount}}
                            </p>
                          </td>
                          <td>
                            <p style="float: right; margin-right: 10px;">{{med.cgst}}
                            </p>
                          </td>
                          <td>
                            <p style="float: right; margin-right: 10px;">{{med.sgst}}
                            </p>
                          </td>
                          <td>
                            <p style="float: right; margin-right: 10px;">{{med.amount}}</p>
                          </td>
                          <td style="text-align:center">{{med.refund_date}} {{med.refund_time}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="viewOrderFlag && drugDetailsArray.length != 0">

              <div class="col-0 col-sm-3 col-md-7 col-lg-8 col-xl-8"
                [hidden]='this.inwardpage_name == "Past inwards view"'>

                <div class="cover_div" [hidden]="paytypreq">

                  <div class="row">
                    <div class="col-2">
                      <mat-label class="matlabel">Credit bill
                        <mat-checkbox (change)="showCreditOption()" color="primary" [(ngModel)]="credit_bill">
                        </mat-checkbox>
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-4" [hidden]="credit_flag">
                      <div class="row">
                        <div class="col-4">
                          <mat-label class="matlabel">Credit type </mat-label>
                        </div>
                        <div class="col-8">
                          <select class="ipcss" [(ngModel)]="credit_type" disableOptionCentering>

                            <option *ngFor="let credit of hosp_credits" value={{credit.credit_id}}>
                              {{credit.credit_desc}}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="col-5" [hidden]="credit_flag">
                      <input class="ipcss" [(ngModel)]="credit_person" placeholder="name of employee" matInput
                        maxlength="50" />
                    </div>
                  </div>
                  <div class="row" [hidden]="!credit_flag">
                    <div class="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-4 select_bottom">
                      <mat-label class="matlabel">Payment type<br>

                        <select class="ipcss " [(ngModel)]="payType" disableOptionCentering>
                          <option *ngFor="let pay of payTypeArray" value={{pay.pay_id}}>{{pay.pay_desc}}</option>
                        </select>
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-4">
                      <mat-label class="matlabel">Amount<br>

                        <input type=number class="ipcss billing_text_align" style="text-align:right"
                          [(ngModel)]="amountval" matInput maxlength="7" />
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-2 col-md-3 col-lg-2 col-xl-2">
                      <mat-label class="matlabel ">

                        <img src="../../../assets/ui_icons/buttons/add_button.svg" class="saveimgbtn_inpatinfo"
                          (click)="addPaymentDetails()" style="margin-top:19px" />
                      </mat-label>
                    </div>
                  </div>
                  <div class="row">
                    <table>
                      <tr *ngFor="let pay of paymentsarray">
                        <td style="width: 150px !important;">{{pay.pay_desc}}</td>
                        <td style="width: 150px !important;">{{pay.amount}}</td>
                        <td>

                          <img src="../../../assets/img/edit.png" class="billing_edit" (click)="editPayment(pay)">
                          &nbsp;&nbsp;
                          <img src="../../../assets/img/Delete.png" class="billing_edit" (click)="deletePayment(pay)">
                        </td>
                      </tr>
                    </table>
                  </div>


                </div>

              </div>

              <div class="col-0 col-sm-3 col-md-7 col-lg-8 col-xl-8" [hidden]='this.inwardpage_name == "Inward"'>

                <div [hidden]="paytypreq">

                  <div class="row">
                    <table style="width:50%">
                      <tr *ngFor="let pay of paymentsarray">
                        <td style="width: 150px !important;">{{pay.pay_desc}}</td>
                        <td style="width: 150px !important;">{{pay.amount}}</td>

                      </tr>
                    </table>

                  </div>

                </div>
              </div>


              <div class="col-0 col-sm-9 col-md-4 col-lg-4 col-xl-4">
                <table class="price_table" style="float: right;margin-top: 25px;margin-right: 30px;">
                  <tr style="position: relative;">
                    <td>
                      <mat-label>
                        <b>Total amount</b>
                        <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                          style="width: 10px; height: 10px;" />
                      </mat-label>
                    </td>
                    <td>
                      <mat-label class="matlabel">
                        <p class="bill_amt_style" style="text-align: center;position: relative;top: 8px;">
                          {{this.actualamount}}</p>
                      </mat-label>
                    </td>
                  </tr>
                  <tr style="position: relative;">
                    <td>
                      <mat-label>
                        <b>Discount</b>
                        <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                          style="width: 10px; height: 10px;" />
                      </mat-label>
                    </td>
                    <td>
                      <mat-label class="matlabel">
                        <p class="bill_amt_style" style="text-align: center;position: relative;top: 8px;">
                          {{this.prodDiscount}}</p>
                      </mat-label>
                    </td>
                  </tr>
                  <tr style="position: relative;">
                    <td>
                      <mat-label>
                        <b>GST</b>
                        <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                          style="width: 10px; height: 10px;" />
                      </mat-label>
                    </td>
                    <td>
                      <mat-label class="matlabel">
                        <p class="bill_amt_style" style="text-align: center;position: relative;top: 8px;">
                          {{this.GSTamount}}</p>
                      </mat-label>
                    </td>
                  </tr>
                  <tr style="position: relative;">
                    <td>
                      <mat-label>
                        <b>Refund amount</b>
                        <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                          style="width: 10px; height: 10px;" />
                      </mat-label>
                    </td>
                    <td>
                      <mat-label class="matlabel">
                        <p class="bill_amt_style" style="text-align: center;position: relative;top: 8px;">{{totalvalue}}
                        </p>
                      </mat-label>
                    </td>
                  </tr>

                  <tr style="position: relative;" *ngIf="this.pendingamt != 0.00">
                    <td>
                      <mat-label>
                        <b>Pending amount</b>
                        <img src="../../../assets/img/Button_Icons/indian-rupee-xl.png"
                          style="width: 10px; height: 10px;" />
                      </mat-label>
                    </td>
                    <td>
                      <mat-label class="matlabel">
                        <p class="bill_amt_style" style="text-align: center;position: relative;top: 8px;">
                          {{this.pendingamt}}</p>
                      </mat-label>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>

          <div [hidden]="pastinwards_list_flag">
            <div class="row">
              <div class="col-12">
                <div>

                  <div class="row">
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                      <mat-label class="matlabel" style="margin-top: 7px;">From
                        <input type="date" class="ipcss_date " [(ngModel)]="fromDate" max="{{currentDate}}" #matInput
                          style="width: 140px;">
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                      <mat-label class="matlabel" style="margin-top: 7px;">To
                        <input type="date" class="ipcss_date " [(ngModel)]="toDate" max="{{currentDate}}" #matInput
                          style="width: 140px;">
                      </mat-label>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                      <mat-label class="matlabel"><br>
                        <img type="button" src="../../../assets/ui_icons/buttons/search_button.svg"
                          class="saveimgbtn_inpatinfo" (keyup.enter)="getpastinwards()" (click)='getpastinwards()'
                          tabindex="0" />
                      </mat-label>
                    </div>

                  </div>
                  <div class="row">
                    <div class="col-12 col-lg-12 col-md-12 col-xl-12">
                      <p class="nodata" *ngIf="pastinwardsarray.length == 0">No Inwards data(s) found</p>
                      <table style="margin-top:10px" *ngIf="pastinwardsarray.length != 0" mdbTable datatable
                        [dtOptions]="dtOptions" class="table table-nowrap table-sm dataTable pharmacycurrentorder">
                        <thead class="tableheading">
                          <tr>
                            <th>S.no</th>

                            <th>Order Id</th>
                            <th>Name</th>
                            <th>Created date</th>
                            <th>Delivered_person</th>
                            <th>Amount</th>
                          </tr>
                        </thead>
                        <tbody>


                          <tr *ngFor="let list of pastinwardsarray" class="tdvalues" tabindex="0"
                            (keyup.enter)=getdetails(list) (click)=getdetails(list)>
                            <td style="font-size: 12px;">
                              {{list.sn}}
                            </td>
                            <td style="font-size: 12px;">
                              {{list.order_id}}
                            </td>
                            <td style="font-size: 12px;text-align: left !important;">
                              {{list.patient_name}}
                            </td>
                            <td style="font-size:12px;text-align: left !important;">
                              {{list.created_date}}
                            </td>
                            <td style="font-size:12px;text-align: left !important;">
                              {{list.delivered_person}}
                            </td>
                            <td style="font-size: 12px;text-align: left !important;">
                              {{list.amount}}
                            </td>
                          </tr>
                        </tbody>
                      </table>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div [hidden]="true">
            <div #logowithname id="logowithname"
              style="background-image: url({{bgImage}}); background-position: center;">
              <table class="report-container" style="width: 100%;">
                <thead class="report-header">
                  <tr>
                    <td style="display: flex;justify-content: center;">
                      <div>
                        <img alt="image" src={{pharmaLogo}} width="100px" height="100px"
                          style="vertical-align: middle;">
                      </div>
                      <div style="position: relative;top: 20px;left: 10px;text-align: left;">
                        <p><b>{{pharmaName}}</b>,</p>
                        <span *ngIf="pharmaAddress1 != undefined">{{pharmaAddress1}},</span><span
                          *ngIf="pharmaAddress2 != undefined">{{pharmaAddress2}},</span><br />
                        <span>{{location}},</span> <span>{{city}},</span>
                        <span>{{state}},</span><span>{{country}}.</span><br>
                        <p><span>DL No : {{dlNO}} &nbsp;&nbsp;</span><span>GST : {{GSTNo}}</span></p>
                      </div>
                    </td>
                  </tr>
                </thead>
                <tbody class="report-content">
                  <tr>
                    <td class="report-content-cell">
                      <div class="main">

                        <!-- <table style="margin-left: 75px;margin-top: 20px;">
                              <td>
                                <p>Name :{{clientName}}</p>
                                <p #billid id="billid"></p>
                                <p>Bill type :{{PurchaseType}}</p>
                              </td>
                              <td style="width: 250px;"></td>
                              <td>
                                <p>Bill date :{{orderDate | date: 'dd-MM-yyyy'}}</p>
                                <p>Bill time :{{orderTime}}</p>
                              </td>
                            </table> -->

                        <table style="margin-left:20px;width: 100%;">
                          <tr>
                            <td style="width: 50%;vertical-align: top;">
                              <div class="row">
                                <div class="col-12 col-md-7 col-lg-8 col-xl-8">
                                  <table>
                                    <tr>
                                      <th
                                        style="text-align: left !important;width: 104px !important;vertical-align: top;">
                                        <span><strong>Patient name</strong></span></th>
                                      <td
                                        style="text-align: left !important;width: 5px !important;vertical-align: top;">
                                        :
                                      </td>
                                      <td style="text-align: left !important;">
                                        {{OrderByName}}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th style="text-align: left !important;width: 104px !important;">
                                        <span><strong>Age/Gender</strong></span></th>
                                      <td style="text-align: left !important;width: 5px !important;">
                                        :
                                      </td>
                                      <td style="text-align: left !important;">
                                        {{clntAge}}/{{clntGender}}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th style="text-align: left !important;width: 104px !important;">
                                        <span><strong>Doctor</strong></span></th>
                                      <td style="text-align: left !important;width: 5px !important;">
                                        :
                                      </td>
                                      <td style="text-align: left !important;">
                                        {{this.drsal}} {{doctorName}}
                                      </td>
                                    </tr>



                                  </table>
                                </div>
                              </div>
                            </td>
                            <td style="width: 50%;vertical-align:top">
                              <div class="row">
                                <div class="amount_due col-12 col-md-5 col-lg-4 col-xl-4" style="margin-right: 20px;">
                                  <table>
                                    <tr>
                                      <th style="text-align: left !important;width: 104px !important;">
                                        <span><strong>Bill No</strong></span></th>
                                      <td style="text-align: left !important;width: 5px !important;">
                                        :
                                      </td>
                                      <td style="text-align: left !important;">
                                        {{this.orderNumber}}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th style="text-align: left !important;width: 110px !important;">
                                        <span><strong>Bill Date/Time</strong></span></th>
                                      <td style="text-align: left !important;width: 5px !important;">
                                        :
                                      </td>
                                      <td style="text-align: left !important;">
                                        {{this.orderDate}} {{this.orderTime}}
                                      </td>
                                    </tr>

                                    <tr *ngIf="patienttype != 'undefined'">
                                      <th style="text-align: left !important;width: 110px !important;">
                                        <span><strong>Patient type</strong></span></th>
                                      <td style="text-align: left !important;width: 5px !important;">
                                        :
                                      </td>
                                      <td style="text-align: left !important;">
                                        {{ this.patienttype}}
                                      </td>
                                    </tr>

                                    <tr [hidden]="mr_no == ''">
                                      <td style="text-align: left !important;">
                                        <img alt="image" [hidden]="barcode_logo == ''" src={{barcode_logo}}>
                                        <!-- <ngx-barcode class="barcode" [bc-value]="mr_no" [bc-display-value]="displayValue"
                                            [bc-line-color]="lineColor" [element-type]="elementType" [format]="format"
                                            [bc-width]="width" [bc-height]="barcodeheight" [bc-font-options]="fontOptions"
                                            [bc-font]="font" [bc-text-align]="textAlign" [bc-text-position]="textPosition"
                                            [bc-text-margin]="textMargin" [bc-font-size]="fontSize" [bc-background]="background"
                                            [bc-margin]="margin" [bc-margin-top]="marginTop" [bc-margin-bottom]="marginBottom"
                                            [bc-margin-left]="marginLeft" [bc-margin-right]="marginRight"></ngx-barcode> -->

                                      </td>
                                    </tr>

                                  </table>
                                </div>

                              </div>
                            </td>
                          </tr>
                        </table>

                        <br />
                        <p style="text-align:center"><b>Inwards</b></p>
                        <table
                          style="border: 1px solid black;border-collapse: collapse;width: 95%;margin-right: auto;margin-left:auto">
                          <thead>
                            <tr style="border:1px solid black;">
                              <th style="border:1px solid black;text-align:center;width:6%">Si.No</th>
                              <th style="border:1px solid black;text-align:center;width:15%">Medicine name</th>
                              <th style="border:1px solid black;text-align:center;width:10%">Qty</th>
                              <th style="border:1px solid black;text-align:center;width:10%">Batch No</th>
                              <th style="border:1px solid black;text-align:center;">Exp date</th>
                              <th style="border:1px solid black;text-align:center;">Price</th>
                              <th style="border:1px solid black;text-align:center;">CGST(%)</th>
                              <th style="border:1px solid black;text-align:center;">SGST(%)</th>
                              <th style="border:1px solid black;text-align:center;" [hidden]="individuladiscount">
                                Discount</th>
                              <th style="border:1px solid black;text-align:center;"> Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let billOrder of insurerArray" style="border:1px solid">
                              <td style="border:1px solid black;width:6%">{{billOrder.sno}}</td>
                              <td style="border:1px solid black;"> {{billOrder.drug_disp_name}} </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;"> {{billOrder.quantity}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;"> {{billOrder.batch_no}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 10px;"> {{billOrder.expiry_date }}</p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 0px;"> {{billOrder.price }}</p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 0px;"> {{billOrder.cgst}} </p>
                              </td>
                              <td style="border:1px solid black;">
                                <p style="float: right; margin-right: 0px;"> {{billOrder.sgst}} </p>
                              </td>
                              <td style="border:1px solid black;" [hidden]="individuladiscount">
                                <p style="float: right; margin-right: 0px;"> {{billOrder.discount}} </p>
                              </td>
                              <td style="border:1px solid black;text-align:right">
                                <p style="float: right; margin-right: 0px;"> {{billOrder.amount}} </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <div class="bill_border">
                          <div>


                            <div style="width: 100%;">

                              <div class="row">
                                <div class="col-6">
                                  <!-- class="col-3 col-md-5 col-lg-3 col-xl-3"  -->
                                  <table style="margin-left:30px">
                                    <tr>
                                      <td><strong>In Words:</strong>&nbsp;Received Rupees {{numToWords}}
                                        &nbsp;&nbsp;Only</td>
                                    </tr>
                                  </table>
                                </div>

                                <div class="col-6" style="margin-left:auto">
                                  <table>
                                    <tr>

                                      <td style="vertical-align: top;width: 31%;">
                                        <table style="float: right;margin-left: 110px;margin-right: 25px;">


                                          <tr [hidden]="actualamount == '0.00'">
                                            <th style="text-align: left;">Total (र)</th>
                                            <td style="text-align: right;width: 100px;" data-th="Previous balance र"
                                              class="fee_txt">
                                              {{this.actualamount}}
                                            </td>
                                          </tr>
                                          <tr [hidden]="prodDiscount == '0.00'">
                                            <th style="text-align: left;">Discount <span [hidden]=""></span> (र) </th>
                                            <td style="text-align: right;width: 100px;" data-th="Total र"
                                              class="fee_txt">
                                              {{this.prodDiscount}}
                                            </td>
                                          </tr>
                                          <tr [hidden]="GSTamount == '0.00'">
                                            <th style="text-align: left;">GST(र) </th>
                                            <td style="text-align: right;width: 100px;" data-th="Discount र"
                                              class="fee_txt">
                                              {{this.GSTamount}}
                                            </td>
                                          </tr>
                                          <tr [hidden]="totalvalue == '0.00'">
                                            <th style="text-align: left;">Refund Amount (र)</th>
                                            <td style="text-align: right;width: 100px;" data-th="Bill amount"
                                              class="fee_txt">
                                              {{totalvalue}}
                                            </td>
                                          </tr>
                                          <tr [hidden]="pendingamt == '0.00'">
                                            <th style="text-align: left;">Balance (र)</th>
                                            <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                              class="fee_txt">
                                              {{pendingamt}}
                                            </td>
                                          </tr>

                                        </table>

                                      </td>
                                    </tr>
                                  </table>
                                </div>
                              </div>

                            </div>
                            <!-- <div  style="width: 100%;margin-left: 20px;margin-top: 15px;">
                                  <div class="row">
                                  
                                  </div>
                                </div> -->

                          </div>
                        </div>

                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div>
              </div>
            </div>

            <div [hidden]="true" #printbanner id="printbanner">
              <table style="border:'0';width:100%">
                <thead>
                  <tr>
                    <th style="width:100%;height: 100px;">
                      <div>
                        <table style="width: 100%;">
                          <tr>
                            <td style="display: flex;justify-content: center;">
                              <div>
                                <img alt="image" src={{this.pharmaLogo}} [style]="printlogostyle">
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tfoot>
                  <tr>
                    <td width="100%">
                </tfoot>
                <tbody>
                  <!-- <tr>  
                         <td width="100%">  
                                               
                         </td>
                       </tr> -->
                  <div class="main">
                    <div
                      style="display: flex; justify-content: space-between; width: 100%;margin-left:15px;margin-top: 10px;border-radius: 10px;">


                      <table style="margin-left:20px;width: 100%;">
                        <tr>
                          <td style="width: 50%;vertical-align: top;">
                            <div class="row">
                              <div class="col-12 col-md-7 col-lg-8 col-xl-8">
                                <table>
                                  <tr>
                                    <th
                                      style="text-align: left !important;width: 104px !important;vertical-align: top;">
                                      <span><strong>Patient name</strong></span></th>
                                    <td style="text-align: left !important;width: 5px !important;vertical-align: top;">
                                      :
                                    </td>
                                    <td style="text-align: left !important;">
                                      {{OrderByName}}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th style="text-align: left !important;width: 104px !important;">
                                      <span><strong>Age/Gender</strong></span></th>
                                    <td style="text-align: left !important;width: 5px !important;">
                                      :
                                    </td>
                                    <td style="text-align: left !important;">
                                      {{clntAge}}/{{clntGender}}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th style="text-align: left !important;width: 104px !important;">
                                      <span><strong>Doctor</strong></span></th>
                                    <td style="text-align: left !important;width: 5px !important;">
                                      :
                                    </td>
                                    <td style="text-align: left !important;">
                                      {{this.drsal}} {{doctorName}}
                                    </td>
                                  </tr>



                                </table>
                              </div>
                            </div>
                          </td>
                          <td style="width: 50%;vertical-align:top">
                            <div class="row">
                              <div class="amount_due col-12 col-md-5 col-lg-4 col-xl-4" style="margin-right: 20px;">
                                <table>
                                  <tr>
                                    <th style="text-align: left !important;width: 104px !important;"><span><strong>Bill
                                          No</strong></span></th>
                                    <td style="text-align: left !important;width: 5px !important;">
                                      :
                                    </td>
                                    <td style="text-align: left !important;">
                                      {{this.orderNumber}}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th style="text-align: left !important;width: 110px !important;"><span><strong>Bill
                                          Date/Time</strong></span></th>
                                    <td style="text-align: left !important;width: 5px !important;">
                                      :
                                    </td>
                                    <td style="text-align: left !important;">
                                      {{this.orderDate}} {{this.orderTime}}
                                    </td>
                                  </tr>

                                  <tr *ngIf="patienttype != 'undefined'">
                                    <th style="text-align: left !important;width: 110px !important;">
                                      <span><strong>Patient type</strong></span></th>
                                    <td style="text-align: left !important;width: 5px !important;">
                                      :
                                    </td>
                                    <td style="text-align: left !important;">
                                      {{ this.patienttype}}
                                    </td>
                                  </tr>

                                  <tr [hidden]="mr_no == ''">
                                    <td style="text-align: left !important;">
                                      <img alt="image" [hidden]="barcode_logo == ''" src={{barcode_logo}}>
                                      <!-- <ngx-barcode class="barcode" [bc-value]="mr_no" [bc-display-value]="displayValue"
                                      [bc-line-color]="lineColor" [element-type]="elementType" [format]="format"
                                      [bc-width]="width" [bc-height]="barcodeheight" [bc-font-options]="fontOptions"
                                      [bc-font]="font" [bc-text-align]="textAlign" [bc-text-position]="textPosition"
                                      [bc-text-margin]="textMargin" [bc-font-size]="fontSize" [bc-background]="background"
                                      [bc-margin]="margin" [bc-margin-top]="marginTop" [bc-margin-bottom]="marginBottom"
                                      [bc-margin-left]="marginLeft" [bc-margin-right]="marginRight"></ngx-barcode> -->

                                    </td>
                                  </tr>

                                </table>
                              </div>

                            </div>
                          </td>
                        </tr>
                      </table>



                    </div>
                  </div>



                <tbody style="width: 100%;">
                  <p style="text-align:center"><b>Inwards</b></p>
                  <table
                    style="border: 1px solid black;border-collapse: collapse;width: 95%;margin-right: auto;margin-left:auto">
                    <thead>
                      <tr style="border:1px solid black;">
                        <th style="border:1px solid black;width:6%;text-align:center;">Si.No</th>
                        <th style="border:1px solid black;text-align:center;width:15%">Medicine name</th>
                        <th style="border:1px solid black;text-align:center;width:10%">Qty</th>
                        <th style="border:1px solid black;text-align:center;width:10%">Batch No</th>
                        <th style="border:1px solid black;text-align:center;">Exp date</th>
                        <th style="border:1px solid black;text-align:center;">Price</th>
                        <th style="border:1px solid black;text-align:center;">CGST(%)</th>
                        <th style="border:1px solid black;text-align:center;">SGST(%)</th>
                        <th style="border:1px solid black;text-align:center;" [hidden]="individuladiscount">Discount
                        </th>
                        <th style="border:1px solid black;text-align:center;"> Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let billOrder of insurerArray" style="border:1px solid">
                        <td style="border:1px solid black;width:6%">{{billOrder.sno}}</td>
                        <td style="border:1px solid black;"> {{billOrder.drug_disp_name}} </td>
                        <td style="border:1px solid black;">
                          <p style="float: right; margin-right: 10px;"> {{billOrder.quantity}} </p>
                        </td>
                        <td style="border:1px solid black;">
                          <p style="float: right; margin-right: 10px;"> {{billOrder.batch_no}} </p>
                        </td>
                        <td style="border:1px solid black;">
                          <p style="float: right; margin-right: 10px;"> {{billOrder.expiry_date }}</p>
                        </td>
                        <td style="border:1px solid black;">
                          <p style="float: right; margin-right: 0px;"> {{billOrder.price }}</p>
                        </td>
                        <td style="border:1px solid black;">
                          <p style="float: right; margin-right: 0px;"> {{billOrder.cgst}} </p>
                        </td>
                        <td style="border:1px solid black;">
                          <p style="float: right; margin-right: 0px;"> {{billOrder.sgst}} </p>
                        </td>
                        <td style="border:1px solid black;" [hidden]="individuladiscount">
                          <p style="float: right; margin-right: 0px;"> {{billOrder.discount}} </p>
                        </td>
                        <td style="border:1px solid black;text-align:right">
                          <p style="float: right; margin-right: 0px;"> {{billOrder.amount}} </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <div class="bill_border">
                    <div>


                      <div style="width: 100%;">

                        <div class="row">
                          <div class="col-6">
                            <!-- class="col-3 col-md-5 col-lg-3 col-xl-3"  -->
                            <table style="margin-left:30px">
                              <tr>
                                <td><strong>In Words:</strong>&nbsp;Received Rupees {{numToWords}} &nbsp;&nbsp;Only</td>
                              </tr>
                            </table>
                          </div>

                          <div class="col-6" style="margin-left:auto">
                            <table>
                              <tr>

                                <td style="vertical-align: top;width: 31%;">
                                  <table style="float: right;margin-left: 110px;margin-right: 25px;">
                                    <tr [hidden]="actualamount == '0.00'">
                                      <th style="text-align: left;">Total (र)</th>
                                      <td style="text-align: right;width: 100px;" data-th="Previous balance र"
                                        class="fee_txt">
                                        {{this.actualamount}}
                                      </td>
                                    </tr>
                                    <tr [hidden]="prodDiscount == '0.00'">
                                      <th style="text-align: left;">Discount <span [hidden]=""></span> (र) </th>
                                      <td style="text-align: right;width: 100px;" data-th="Total र" class="fee_txt">
                                        {{this.prodDiscount}}
                                      </td>
                                    </tr>
                                    <tr [hidden]="GSTamount == '0.00'">
                                      <th style="text-align: left;">GST(र) </th>
                                      <td style="text-align: right;width: 100px;" data-th="Discount र" class="fee_txt">
                                        {{this.GSTamount}}
                                      </td>
                                    </tr>
                                    <tr [hidden]="totalvalue == '0.00'">
                                      <th style="text-align: left;">Refund Amount (र)</th>
                                      <td style="text-align: right;width: 100px;" data-th="Bill amount" class="fee_txt">
                                        {{totalvalue}}
                                      </td>
                                    </tr>
                                    <tr [hidden]="pendingamt == '0.00'">
                                      <th style="text-align: left;">Balance (र)</th>
                                      <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                        class="fee_txt">
                                        {{pendingamt}}
                                      </td>
                                    </tr>
                                  </table>

                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>

                      </div>
                      <!-- <div  style="width: 100%;margin-left: 20px;margin-top: 15px;">
                              <div class="row">
                              
                              </div>
                            </div> -->

                    </div>
                  </div>

                </tbody>

              </table>
            </div>

            <div [hidden]="true" #printnoheader id="printnoheader">
              <div class="noheader_cls">
                <table style="border:'0';width:100%;">
                  <tbody border="1">
                    <div class="main">
                      <div
                        style="display: flex; justify-content: space-between; width: 90%;margin-left:15px;margin-top: 10px;border-radius: 10px;">
                        <table style="margin-left:20px;width: 100%;">
                          <tr>
                            <td style="width: 50%;vertical-align: top;">
                              <div class="row">
                                <div class="col-12 col-md-7 col-lg-8 col-xl-8">
                                  <table>
                                    <tr>
                                      <th
                                        style="text-align: left !important;width: 104px !important;vertical-align: top;">
                                        <span><strong>Patient name</strong></span></th>
                                      <td
                                        style="text-align: left !important;width: 5px !important;vertical-align: top;">
                                        :
                                      </td>
                                      <td style="text-align: left !important;">
                                        {{OrderByName}}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th style="text-align: left !important;width: 104px !important;">
                                        <span><strong>Age/Gender</strong></span></th>
                                      <td style="text-align: left !important;width: 5px !important;">
                                        :
                                      </td>
                                      <td style="text-align: left !important;">
                                        {{clntAge}}/{{clntGender}}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th style="text-align: left !important;width: 104px !important;">
                                        <span><strong>Doctor</strong></span></th>
                                      <td style="text-align: left !important;width: 5px !important;">
                                        :
                                      </td>
                                      <td style="text-align: left !important;">
                                        {{this.drsal}} {{doctorName}}
                                      </td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </td>
                            <td style="width: 50%;vertical-align:top">
                              <div class="row">
                                <div class="amount_due col-12 col-md-5 col-lg-4 col-xl-4" style="margin-right: 20px;">
                                  <table>
                                    <tr>
                                      <th style="text-align: left !important;width: 104px !important;">
                                        <span><strong>Bill No</strong></span></th>
                                      <td style="text-align: left !important;width: 5px !important;">
                                        :
                                      </td>
                                      <td style="text-align: left !important;">
                                        {{this.orderNumber}}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th style="text-align: left !important;width: 110px !important;">
                                        <span><strong>Bill Date/Time</strong></span></th>
                                      <td style="text-align: left !important;width: 5px !important;">
                                        :
                                      </td>
                                      <td style="text-align: left !important;">
                                        {{this.orderDate}} {{this.orderTime}}
                                      </td>
                                    </tr>

                                    <tr *ngIf="patienttype != 'undefined'">
                                      <th style="text-align: left !important;width: 110px !important;">
                                        <span><strong>Patient type</strong></span></th>
                                      <td style="text-align: left !important;width: 5px !important;">
                                        :
                                      </td>
                                      <td style="text-align: left !important;">
                                        {{ this.patienttype}}
                                      </td>
                                    </tr>

                                    <tr [hidden]="mr_no == ''">
                                      <td style="text-align: left !important;">
                                        <img alt="image" [hidden]="barcode_logo == ''" src={{barcode_logo}}>
                                        <!-- <ngx-barcode class="barcode" [bc-value]="mr_no" [bc-display-value]="displayValue"
                                          [bc-line-color]="lineColor" [element-type]="elementType" [format]="format"
                                          [bc-width]="width" [bc-height]="barcodeheight" [bc-font-options]="fontOptions"
                                          [bc-font]="font" [bc-text-align]="textAlign" [bc-text-position]="textPosition"
                                          [bc-text-margin]="textMargin" [bc-font-size]="fontSize" [bc-background]="background"
                                          [bc-margin]="margin" [bc-margin-top]="marginTop" [bc-margin-bottom]="marginBottom"
                                          [bc-margin-left]="marginLeft" [bc-margin-right]="marginRight"></ngx-barcode> -->

                                      </td>
                                    </tr>

                                  </table>
                                </div>

                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  <tbody style="width: 100%;">
                    <p style="text-align:center"><b>{{pagetype}}</b></p>
                    <table style="border: 1px solid black;border-collapse: collapse;margin-right: 20px;">
                      <thead>
                        <tr style="border:1px solid black;">
                          <th style="border:1px solid black;text-align:center;width:6%">Si.No</th>
                          <th style="border:1px solid black;text-align:center;width:15%">Medicine name</th>
                          <th style="border:1px solid black;text-align:center;width:10%">Qty</th>
                          <th style="border:1px solid black;text-align:center;width:10%">Batch No</th>
                          <th style="border:1px solid black;text-align:center;">Exp date</th>
                          <th style="border:1px solid black;text-align:center;">Price</th>
                          <th style="border:1px solid black;text-align:center;">CGST(%)</th>
                          <th style="border:1px solid black;text-align:center;">SGST(%)</th>
                          <th style="border:1px solid black;text-align:center;" [hidden]="individuladiscount">Discount
                          </th>
                          <th style="border:1px solid black;text-align:center;"> Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let billOrder of insurerArray" style="border:1px solid">
                          <td style="border:1px solid black;width:6%">{{billOrder.sno}}</td>
                          <td style="border:1px solid black;"> {{billOrder.drug_disp_name}}</td>
                          <td style="border:1px solid black;">
                            <p style="float: right; margin-right: 10px;"> {{billOrder.quantity}} </p>
                          </td>
                          <td style="border:1px solid black;">
                            <p style="float: right; margin-right: 10px;"> {{billOrder.batch_no}} </p>
                          </td>
                          <td style="border:1px solid black;">
                            <p style="float: right; margin-right: 10px;"> {{billOrder.expiry_date }}</p>
                          </td>
                          <td style="border:1px solid black;">
                            <p style="float: right; margin-right: 0px;"> {{billOrder.price }}</p>
                          </td>
                          <td style="border:1px solid black;">
                            <p style="float: right; margin-right: 0px;"> {{billOrder.cgst}} </p>
                          </td>
                          <td style="border:1px solid black;">
                            <p style="float: right; margin-right: 0px;"> {{billOrder.sgst}} </p>
                          </td>
                          <td style="border:1px solid black;" [hidden]="individuladiscount">
                            <p style="float: right; margin-right: 0px;"> {{billOrder.discount}} </p>
                          </td>
                          <td style="border:1px solid black;text-align:right">
                            <p style="float: right; margin-right: 0px;"> {{billOrder.amount}} </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="bill_border">
                      <div>
                        <div style="width: 100%;">
                          <div class="row">
                            <div class="col-6">
                              <!-- class="col-3 col-md-5 col-lg-3 col-xl-3"  -->
                              <table>
                                <tr>
                                  <td><strong>In Words:</strong>&nbsp;Received Rupees {{numToWords}} &nbsp;&nbsp;Only
                                  </td>
                                </tr>
                              </table>
                            </div>

                            <div class="col-6">
                              <table>
                                <tr>
                                  <td style="vertical-align: top;width: 31%;">
                                    <table style="float: right;margin-left: 110px;margin-right: 25px;">
                                      <tr [hidden]="actualamount == '0.00'">
                                        <th style="text-align: left;">Total (र)</th>
                                        <td style="text-align: right;width: 100px;" data-th="Previous balance र"
                                          class="fee_txt">
                                          {{this.actualamount}}
                                        </td>
                                      </tr>
                                      <tr [hidden]="prodDiscount == '0.00'">
                                        <th style="text-align: left;">Discount <span [hidden]=""></span> (र) </th>
                                        <td style="text-align: right;width: 100px;" data-th="Total र" class="fee_txt">
                                          {{this.prodDiscount}}
                                        </td>
                                      </tr>
                                      <tr [hidden]="GSTamount == '0.00'">
                                        <th style="text-align: left;">GST(र) </th>
                                        <td style="text-align: right;width: 100px;" data-th="Discount र"
                                          class="fee_txt">
                                          {{this.GSTamount}}
                                        </td>
                                      </tr>
                                      <tr [hidden]="totalvalue == '0.00'">
                                        <th style="text-align: left;">Refund Amount (र)</th>
                                        <td style="text-align: right;width: 100px;" data-th="Bill amount"
                                          class="fee_txt">
                                          {{totalvalue}}
                                        </td>
                                      </tr>
                                      <tr [hidden]="pendingamt == '0.00'">
                                        <th style="text-align: left;">Balance (र)</th>
                                        <td style="text-align: right;width: 100px;" data-th="Advance / Paid र"
                                          class="fee_txt">
                                          {{pendingamt}}
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                        <!-- <div  style="width: 100%;margin-left: 20px;margin-top: 15px;">
                                  <div class="row">
                                  
                                  </div>
                                </div> -->
                      </div>
                    </div>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>









      </mdb-card-body>
    </mdb-card>
  </div>
</div>