import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { ipaddress } from '../../ipaddress';
import { Helper_Class } from '../../helper_class';
import { Message_data } from 'src/assets/js/Message_data';
import * as fs from 'file-saver';
import { Workbook } from 'exceljs';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-diag-conduction-doc-payments',
  templateUrl: './diag-conduction-doc-payments.component.html',
  styleUrls: ['./diag-conduction-doc-payments.component.scss']
})
export class DiagConductionDocPaymentsComponent implements OnInit {
  @ViewChild('printbanner') public printbanner: ElementRef = {} as ElementRef;
  @ViewChild('printnoheader') public printnoheader: ElementRef = {} as ElementRef;

  public locationArray = [];
  public loadLocationList = [];
  public pharmacyFacID: any;
  public location: any;
  public locationIP = null;
  public invoicedetails: any = [];
  public pharmacistID;
  public userInfo;
  public pharmacyID;
  public suppId;
  public fromDate;
  public toDate;
  public currentDate;
  public currDate;
  public totalAmount;
  public pendingAmount;
  private tmr = new Date();
  public supplierList: any = [];
  dtOptions: DataTables.Settings = {};
  isDisabled: boolean = false;
  public cardFlag: boolean;
  public chequeFlag: boolean;
  public transactionFlag: boolean;
  public insurFlag: boolean;
  public showpayflag: boolean = true;
  public showimage: boolean = false;
  public showarray: boolean = true;
  public paid_amount: number = 0;
  public payTypeList: any = [];
  public insurerList: any = [];
  public payHistList: any = [];
  public payType;
  public insurerId;
  public cardHolderName;
  public transactionId;
  public cardNumber
  public bankName;
  private closeResult;
  public editbutton: boolean = false;
  public newbutton: boolean = false;
  public deletebutton: boolean = false;
  public printbutton: boolean = false;
  public viewbutton: boolean = false;
  public paytotal = 0;
  public paymentcheckbox;
  public accepted: Boolean;
  public checkboxflag: Boolean;
  private selectedInvoice: any = [];
  hptl_clinic_id: any;
  doctor_payments: any = [];
  public payment_type = "conduction";
  hospital_logo: any;
  printFlag: boolean;
  print_template: any;
  printlogostyle: any;
  selected_payments: any = [];

  constructor(public http: HttpClient, public routes: ActivatedRoute,
    public router: Router, public toastr: ToastrService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.checkboxflag = false
    this.userInfo = Helper_Class.getInfo();
    if (this.userInfo.user_type == "Admin") {
      this.hptl_clinic_id = Helper_Class.getInfo().diag_center_id;
    }
    else {
      this.hptl_clinic_id = Helper_Class.getInfo().hospitals[0].hptl_clinic_id
    }

    this.hospital_logo = ipaddress.Ip_with_img_address + Helper_Class.getInfo().bill_print_logo;
    this.printFlag = Helper_Class.getBillPrint() == "1" ? true : false;
    this.print_template = Helper_Class.getInfo().bill_print_template;
    this.printlogostyle = Helper_Class.getInfo().bill_print_logo_style;

    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 25,
      info: false,
      "lengthMenu": [[25, 50, 100, -1], [25, 50, 100, "All"]],
      order: [[0, 'desc']],
      "columnDefs": [
        { "orderable": false, "targets": [0, 1, 2, 3] },
      ],

      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search by doctor or test name"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };

    this.getDoctors();
    this.getPaymentType();
  }

  getDoctors() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "bill/gcdp", {
      hptl_clinic_id: Helper_Class.getInfo().hospitals[0].hosp_id,
      type: this.payment_type
    }, { headers: headers }).subscribe(
      data => {
        this.doctor_payments = [];
        var doc_data = JSON.parse(JSON.stringify(data));
        console.log("doc_data", doc_data)

        if (doc_data != undefined) {
          for (var i = 0; i < doc_data.doctor_payments.length; i++) {
            var doc_name;
            if (doc_data.doctor_payments[i].first_name != undefined && doc_data.doctor_payments[i].last_name != undefined) {
              doc_name = doc_data.doctor_payments[i].first_name + ' ' + doc_data.doctor_payments[i].last_name;
            } else if (doc_data.doctor_payments[i].first_name != undefined) {
              doc_name = doc_data.doctor_payments[i].first_name
            } else {
              doc_name = '';
            }

            var paid_flag;
            if (parseInt(doc_data.doctor_payments[i].balance) != 0) {
              if (parseInt(doc_data.doctor_payments[i].balance) == parseInt(doc_data.doctor_payments[i].cost)) {
                paid_flag = "Unpaid";
              } else if (parseInt(doc_data.doctor_payments[i].balance) < parseInt(doc_data.doctor_payments[i].cost)) {
                paid_flag = "Partially paid";
              }
            } else {
              paid_flag = "Fully paid";
            }

            this.doctor_payments.push({
              doctor_name: doc_name,
              diag_appointment_id: doc_data.doctor_payments[i].diag_appointment_id,
              diag_tests_id: doc_data.doctor_payments[i].diag_tests_id,
              doc_reg_id: doc_data.doctor_payments[i].doc_reg_id,
              test_name: doc_data.doctor_payments[i].test_name,
              cost: doc_data.doctor_payments[i].cost,
              balance: doc_data.doctor_payments[i].balance,
              type: doc_data.doctor_payments[i].type,
              selected_paytype: "1",
              paid_flag: paid_flag,
              paymentcheckbox: false
            })
          }
        }

        console.log("this.doctor_payments", this.doctor_payments)

        this.getDate();
      },
      error => {
        this.toastr.error(Message_data.unableToFetchsupp);
      }
    )
  }

  getDate() {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate', { country: ipaddress.country_code },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.tmr = new Date(obj.current_date);
          this.tmr.setDate(this.tmr.getDate());
          this.currentDate = this.getDateMatePicker1(this.tmr);
          this.currDate = obj.current_date;

          this.fromDate = obj.current_date;
          this.toDate = obj.current_date;
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  getDateMatePicker1(e) {
    var month = ((e.getMonth() + 1) < 10 ? '0' : '') + (e.getMonth() + 1);
    var day = (e.getDate() < 10 ? '0' : '') + e.getDate();
    var apt = e.getFullYear() + "-" + month + "-" + day;
    return apt.trim();
  }

  dateSelect(data, value) {
    if (data != null) {
      if (value == "1")
        this.fromDate = data;
      else
        this.toDate = data;
    }
  }


  calculateDaysDifference(inv_due_date: Date): number {
    var currentDate = new Date(this.currDate);
    var timeDifference = inv_due_date.getTime() - currentDate.getTime();
    return Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  }




  calculatePaidAmount(data) {
    var paidAmt = data.paid_amount;
    var totalAmt = data.cost;
    var balAmt = data.balance;

    if (paidAmt != undefined && paidAmt != "") {
      if (parseInt(balAmt) != 0) {
        if (parseInt(paidAmt) == parseInt(totalAmt)) {
          balAmt = 0;
        } else {
          balAmt = parseInt(totalAmt) - parseInt(paidAmt);
        }
      } else {
        if (parseInt(paidAmt) != parseInt(totalAmt)) {
          balAmt = parseInt(totalAmt) - parseInt(paidAmt);
        }
      }

      if (parseInt(balAmt) == 0) {
        data.paid_flag = "Fully paid"
      } else if (parseInt(balAmt) < parseInt(totalAmt)) {
        data.paid_flag = "Partially paid"
      } else if (parseInt(balAmt) == parseInt(totalAmt)) {
        data.paid_flag = "Unpaid"
      }

      data.balance = parseInt(balAmt);
    }


    console.log(data)
  }

  getExcelData(data) {
    var excel_data = [];
    var header = ['Appointment id', 'Doctor', 'Description', 'Amount', 'Payment', 'Balance', 'Status']
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Doctor payment report');
    worksheet.getColumn(1).width = 10;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 35;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 15;
    worksheet.getColumn(7).width = 15;
    let headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4167B8' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12
      }
    })
    for (let payment of this.doctor_payments) {
      if (payment.paymentcheckbox == true) {
        excel_data.push({
          diag_appointment_id: payment.diag_appointment_id,
          doctor_name: payment.doctor_name,
          test_name: payment.test_name,
          cost: payment.cost,
          paid_amount: payment.paid_amount,
          balance: payment.balance,
          paid_flag: payment.paid_flag
        })
      }
    }

    if (excel_data.length != 0) {
      for (let x1 of excel_data) {
        let x2 = Object.keys(x1);
        let temp = []
        for (let y of x2) {
          temp.push(x1[y])
        }
        let chilrow = worksheet.addRow(temp)
        let salesamt = chilrow.getCell(7);
        salesamt.alignment = {
          vertical: 'middle',
          horizontal: 'right'
        }
        let totamt = chilrow.getCell(4);
        totamt.alignment = {
          vertical: 'middle',
          horizontal: 'right'
        }
      }
      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, "supplier_payments" + '-' + new Date().valueOf() + '.xlsx');
      });
    } else {
      this.toastr.error("Select atleast one line item")
    }
  }

  save_data() {
    var paymentsArr = [];
    for (var i = 0; i < this.doctor_payments.length; i++) {
      if (this.doctor_payments[i].paymentcheckbox == true) {
        paymentsArr.push({
          doc_reg_id: this.doctor_payments[i].doc_reg_id,
          diag_app_id: this.doctor_payments[i].diag_appointment_id,
          test_id: this.doctor_payments[i].diag_tests_id,
          amount: this.doctor_payments[i].cost,
          payment_mode: this.doctor_payments[i].selected_paytype,
          paid_amount: this.doctor_payments[i].paid_amount,
          balance: this.doctor_payments[i].balance,
          paid_flag: this.doctor_payments[i].paid_flag,
        })
      }
    }

    var send_data = {
      paid_by: this.userInfo.user_id,
      country: "IN",
      payments: paymentsArr,
      type: this.payment_type
    };

    console.log("updated_data " + JSON.stringify(send_data))
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'bill/scdp', send_data,
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          console.log(obj)
          if (obj.key == "1") {
            this.toastr.success("Payment details update successfully");
          }
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  getPaymentType() {//Get payment type
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.http.get(ipaddress.getIp.toString() + 'bill/paytype',
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(JSON.stringify(response));
          this.payTypeList = obj.payments;
          this.payType = this.payTypeList[0].pay_id;
          // this.pay_change();

          console.log("payTypeList", this.payTypeList)
        },
        error => {
          this.toastr.error(Message_data.defaultErr);
        });
  }

  check_amt_to_payment(data) {
    console.log("_data", data)
    var balAmt = parseInt(data.balance);
    var paidAmt = data.paid_amount;

    if (data.paymentcheckbox == true) {
      if (paidAmt == undefined || paidAmt == "") {
        if (balAmt != 0) {
          data.paid_amount = balAmt;
          data.paid_flag = "Fully paid";
          data.balance = 0;
        }
      }
    }

    this.totalAmount = 0
    this.pendingAmount = 0
    for (var i = 0; i < this.doctor_payments.length; i++) {
      if (this.doctor_payments[i].paymentcheckbox == true) {
        this.pendingAmount += this.doctor_payments[i].balance;
        this.totalAmount += parseInt(this.doctor_payments[i].cost);
      }
    }

    if (data.paymentcheckbox == true) {
      this.selected_payments.push({
        diag_appointment_id: data.diag_appointment_id,
        doctor_name: data.doctor_name,
        test_name: data.test_name,
        cost: data.cost,
        paid_amount: data.paid_amount,
        balance: data.balance,
        paid_flag: data.paid_flag,
        payment_mode: data.selected_paytype
      })
    } else {
      for (var i = 0; i < this.selected_payments.length; i++) {
        if (data.diag_appointment_id == this.selected_payments[i].diag_appointment_id) {
          this.selected_payments.splice(i, 1);
        }
      }
    }

  }

  print_area() {
    if (this.selected_payments.length != 0) {
      var margin_top;
      let printContents, popupWin;
      if (this.print_template != undefined && this.print_template == 'banner') {
        printContents = this.printbanner.nativeElement.innerHTML;
        margin_top = '0px';
      } else {
        printContents = this.printnoheader.nativeElement.innerHTML;
        margin_top = '122px';
      }
      popupWin = window.open(
        '',
        '_blank',
        'top=20,left=10,height=100%,width=auto'
      );
      popupWin.document.open();
      popupWin.document.write(`
    <html>
      <head>
        <title>Bill</title>
        <link rel="stylesheet" media="screen" href="">
        <style>                        
          @page {
            size: auto;
            margin-top: ${margin_top};
            margin-right: 0px;
            margin-left: 0px;
            margin-bottom: 0px;
            overflow: visible;
          } 
          * {
              font-size: 12px !important;
              line-height: 1 !important;
              page-break-inside: always;
              overflow: visible;
            }                                     
          @media print {   
            body {
              height: auto; /* Allow body height to adjust dynamically */
            }          
            .page-break {
              page-break-before: always; /* or page-break-after: always; */
              page-break-inside: always;
            }
          }           
          .address_visibility {
            visibility: hidden;
          }        
          table.report-container {
            page-break-after: always;
          }
          thead.report-header {
            display: table-header-group;
          }
          tfoot.report-footer {
            display: table-footer-group;
          }
          .print:last-child {
            page-break-after: auto;
          }
          .alignRight {
            text-align: right;
          }
          .col-print-1 {width:8%;  float:left;}
          .col-print-2 {width:16%; float:left;}
          .col-print-3 {width:25%; float:left;}
          .col-print-4 {width:33%; float:left;}
          .col-print-5 {width:42%; float:left;}
          .col-print-6 {width:50%; float:left;}
          .col-print-7 {width:58%; float:left;}
          .col-print-8 {width:66%; float:left;}
          .col-print-9 {width:75%; float:left;}
          .col-print-10 {width:83%; float:left;}
          .col-print-11 {width:92%; float:left;}
          .col-print-12 {width:100%; float:left;}
        </style>
      </head>
      <body onload="window.print(); window.onfocus=function(){ window.close();}">${printContents}</body>
    </html>`);
      popupWin.document.close();
    } else {
      this.toastr.error("Select atleast one line item to print")
    }
  }


}
