import { Component, OnInit } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { MatDialog } from '@angular/material/dialog';
import { encrypt_decript } from '../../../assets/js/Encription_Decription';
import { CommonDataService } from '../../providers/common-data.service';
import { Router } from '@angular/router';
import { Helper_Class } from '../../helper_class';
import { FilterpageComponent } from '../filterpage/filterpage.component';
import { pharmacy_helper } from '../Pharmcy_Helper';
import { ipaddress } from '../../ipaddress';
import { MenuViewService } from '../../../app/common-module/menu-view/menu-view.service';
import { speak } from '../../../assets/js/common';
import { Time_Formate } from '../../../assets/js/common';

@Component({
  selector: 'app-pharmacypastinformation',
  templateUrl: './pharmacypastinformation.component.html',
  styleUrls: ['./pharmacypastinformation.component.css']
})
export class PharmacypastinformationComponent implements OnInit {
  order_listarray = [];
  no_datacurrent: boolean = false;
  dtOptions: any = {};
  persons: any = [];
  masterSelected: boolean = false;
  checklist: any;
  checkedList: any;
  i = 0;
  public imei;
  public userinfo;
  public user_id;
  public hospitalid;
  public inpatientFlag;
  public App_type;
  public pharmacy_id: string;
  public ynopt1: boolean = true;
  public billEdit: boolean = false;
  public currentDate;
  public fromDate;
  public toDate;
  public filtertype;
  public filter_flag: boolean = true;
  public nameList:any=[];
  public patName:string;
  public ordertype: string;
  public fname:string;
  public billingmoduleid;
  public editbutton:boolean=false;
  public newbutton:boolean=false;
  public deletebutton:boolean=false;
  public printbutton:boolean=false;
  public viewbutton:boolean=false;
  public pharmacyID: string;
  public discURl;
  public patienttype;
  constructor(public gservice: CommonDataService, public messageservice: MenuViewService, public http: Http, public router: Router, public dialog: MatDialog, public adminService: MenuViewService) {
  }

  ngOnInit(): void {
    this.discURl = ipaddress.getIp.toString() + "pharmacontrol/gdis";
    this.imei = Helper_Class.getIPAddress();
    this.userinfo = Helper_Class.getInfo();
    this.billingmoduleid=Helper_Class.getInfo().both_modules;
    console.log("modules"+JSON.stringify(this.billingmoduleid));
    var getvalue2 = Helper_Class.getInfo().hospitals;
    for (var i = 0; i < getvalue2.length; i++) {
      this.hospitalid = getvalue2[0].hptl_clinic_id
    }

    this.filtertype = "date";
    this.ordertype="otc";
    this.patienttype="outpatient"
    this.getCurrentDate();
    this.getDiscTypes();
    this.dtOptions = {
      pagingType: 'simple_numbers_no_ellipses',
      pageLength: 10,
      "lengthMenu": [[5, 10, 25, 50, -1], [5, 10, 25, 50, "All"]],
      "columnDefs": [
      ],
      "language": {
        "search": "<img src='../../../assets/img/search.png' width='18px'/>",
        searchPlaceholder: "Search by bill no, name, delivery type, order type"
      },
      dom: '<"row"<"col-sm-6 col-xs-12"f><"col-sm-6 col-xs-12 d-flex justify-content-end"l>><"table-responsive"t><"row mt-1 d-flex"<"col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 pad_top_10"><"col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 text-center sm-order-last"i><"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center"p>>'
    };
    
    if(this.billingmoduleid != undefined){
      for(var q=0;q<this.billingmoduleid.length;q++){
        if (this.billingmoduleid[q].module_id == "46") {
          if(this.billingmoduleid[q].edit == "1"){
            this.editbutton=true;
          }
          if(this.billingmoduleid[q].create == "1"){
            this.newbutton=true;
          }
          if(this.billingmoduleid[q].delete == "1"){
            this.deletebutton=true;
          }
          if(this.billingmoduleid[q].print == "1"){
            this.printbutton=true;
          }
          if(this.billingmoduleid[q].view == "1"){
            this.viewbutton=true;
          }
        }
      }
    }

    var pharmacypastorder = "pastorder";
    pharmacy_helper.setpharmacyflow(pharmacypastorder);
    this.App_type = "out";
  }

  getDiscTypes() {
    if (Helper_Class.getIsAdmin() != undefined) {
      this.pharmacyID = Helper_Class.getInfo().pharma_id;
    } else {
      this.pharmacyID = Helper_Class.getInfo().hospitals[0].hptl_clinic_id;
    }

    var sendData = {
      center_id: this.pharmacyID
    }
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(this.discURl, sendData, { headers: headers }).subscribe(
      data => {
        var distypes = data.json();
        Helper_Class.setDiscountTypes(distypes.disc_types);
        
      }, error => { });
  }



  getCurrentDate() {
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp.toString() + 'adm/curdate', { country: ipaddress.country_code },
      { headers: headers })
      .subscribe(
        response => {
          var obj = JSON.parse(response["_body"]);
          this.currentDate = obj.current_date;//display in html for maximum date
          this.fromDate = obj.current_date;
          this.toDate = obj.current_date;
          if (Helper_Class.getIsAdmin() != undefined) {
            this.pharmacy_id = this.userinfo.pharma_id;
            this.userTypeSelect("out");
          } else {
            this.PharmacyBranchList();
          }
        })
  }

  changeFilterType() {
    if (this.filtertype == "date") {
      this.filter_flag = true;
    } else {
      this.filter_flag = false;
    }
  }

  getPatientDetails() {
    if(this.patName.length >2){
      var send_data = JSON.stringify({
        pharma_id: this.pharmacy_id,
        name:this.patName.toUpperCase(),
        orderType:this.ordertype
      })
      var headers = new Headers();
      headers.append('Content-Type', 'application/json');
      this.http.post(ipaddress.getIp + "pharmacontrol/gcbp/", send_data,
        { headers: headers }).subscribe(
          response => {
            var obj = response.json();
            if (obj.names != null) {
              this.nameList=[];
              for(var i=0;i<obj.names.length;i++){
                var lname=obj.names[i].last_name != undefined?encrypt_decript.Decript(obj.names[i].last_name):"";
                this.nameList.push({
                  name:encrypt_decript.Decript(obj.names[i].first_name)+" "+lname,
                  first_name: encrypt_decript.Decript(obj.names[i].first_name),
                  client_reg_id:obj.names[i].client_reg_id,
                });
              }
            }
          });
    }
  }

  selectPatient(name){
    this.fname=name.first_name;
    this.currentOrder(this.pharmacy_id, name.client_reg_id,name.first_name)
  }

  PharmacyBranchList() {
    this.user_id = this.userinfo.user_id
    var send_data = JSON.stringify({
      pharma_reg_id: this.user_id,
      imei: this.imei
    })
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/locbyregid/", send_data,
      { headers: headers }).subscribe(
        response => {
          //order_details
          var obj = response.json();
          if (obj.pharma != null) {
            this.pharmacy_id = obj.pharma[0].pharma_id;
            this.userTypeSelect("out");
            localStorage.setItem("pharma_id", obj.pharma[0].pharma_id);
          }
        });
  }

  currentOrder(pharma_id, clientid, fname) {//Current order
    var clnid;
    if(clientid != ''){
      clnid = clientid;
    }
    var send_data;
    var send_data;
    if(this.ordertype=="otc" && this.filtertype=="client"){
      send_data={
        pharma_id: pharma_id,
        flag: "delivered",
        inpatient_flag: this.inpatientFlag,
        fname:fname,
      }
    } else if(this.ordertype=="notc" && this.filtertype=="client"){
      send_data={
        pharma_id: pharma_id,
        flag: "delivered",
        inpatient_flag: this.inpatientFlag,
        client_reg_id:clnid,
      }

    } else {
      send_data={
        pharma_id: pharma_id,
        flag: "delivered",
        inpatient_flag: this.inpatientFlag,
        start_date: this.fromDate,
        end_date: this.toDate,
      }
    }
   
    console.log("send daa"+JSON.stringify(send_data))
    this.order_listarray = [];
    var headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.http.post(ipaddress.getIp + "pharmacontrol/viewordersbyphar", JSON.stringify(send_data),
      { headers: headers }).subscribe(
        response => {
          //order_details
          var obj = response.json();
          console.log("pastorder = "+JSON.stringify(obj));


          if (obj.order_details != null) {
            this.no_datacurrent = false;
            for (var i = 0; i < obj.order_details.length; i++) {
              if (obj.order_details[i].first_name != null) {
                if (obj.order_details[i].description == "Door Delivery") {
                  var home_deltxt = "Home delivery";
                } else {
                  home_deltxt = obj.order_details[i].description;
                }

                if (obj.order_details[i].drug_details != null) {
                  var tabletarray = [];
                  for (var k = 0; k < obj.order_details[i].drug_details.length; k++) {
                    tabletarray.push({
                      tablet_name: obj.order_details[i].drug_details[k].type_name + " - " + obj.order_details[i].drug_details[k].drug_name + " " + obj.order_details[i].drug_details[k].quantity + " nos"
                    });
                  }
                }

                if (obj.order_details[i].middle_name != undefined && obj.order_details[i].middle_name != null) {
                  var m_name = encrypt_decript.Decript(obj.order_details[i].middle_name).toString();
                } else {
                  m_name = "";
                }

                if (obj.order_details[i].first_name != undefined && obj.order_details[i].first_name != null) {
                  var f_name = encrypt_decript.Decript(obj.order_details[i].first_name).toString();
                } else {
                  f_name = obj.order_details[i].del_name
                }

                if (obj.order_details[i].last_name != undefined && obj.order_details[i].last_name != null) {
                  var l_name = encrypt_decript.Decript(obj.order_details[i].last_name).toString();
                }

                if(obj.order_details[i].order_time != undefined){
                  var ordertime=Time_Formate(obj.order_details[i].order_time)
                }else{
                  var ordertime='-'
                }

                this.order_listarray.push({
                  drug_purchase_id: obj.order_details[i].drug_purchase_id,
                  tot_name: f_name + " " + m_name + " " + l_name,
                  home_delivery: home_deltxt,
                  order_date: this.Date_Formate(obj.order_details[i].order_date),
                  tablet_data: tabletarray,
                  fin_year: obj.order_details[i].fin_year,
                  tokenno:obj.order_details[i].token,
                  ordertime:ordertime
                });
                pharmacy_helper.setorderview(this.order_listarray);
              } else {
                if (obj.order_details[i].description == "Door Delivery") {
                  var home_deltxt = "Home delivery";
                } else {
                  home_deltxt = obj.order_details[i].description;
                }

                if (obj.order_details[i].drug_details != null) {
                  var tabletarray = [];
                  for (var k = 0; k < obj.order_details[i].drug_details.length; k++) {
                    tabletarray.push({
                      tablet_name: obj.order_details[i].drug_details[k].type_name + " - " + obj.order_details[i].drug_details[k].drug_name + " " + obj.order_details[i].drug_details[k].quantity + " nos"
                    });
                  }
                }

                if (obj.order_details[i].del_person != null) {
                  var del_name = obj.order_details[i].del_person;
                }

                if(obj.order_details[i].order_time != undefined){
                  var ordertime=Time_Formate(obj.order_details[i].order_time)
                }else{
                  var ordertime='-'
                }

                this.order_listarray.push({
                  drug_purchase_id: obj.order_details[i].drug_purchase_id,
                  tot_name: del_name,
                  home_delivery: home_deltxt,
                  order_date: this.Date_Formate(obj.order_details[i].order_date),
                  tablet_data: tabletarray,
                  fin_year: obj.order_details[i].fin_year,
                  tokenno:obj.order_details[i].token,
                  ordertime:ordertime
                });
              }
            }
          } else {
            this.no_datacurrent = true;
          }
        });
  }
  
  tokennospeech(token){
    if (token != undefined && token != "") {
      speak("token number"+token)
    }
  }


  selectDate(data, value) {
    if (data != null) {
      if (value == "1") {
        this.fromDate = data;
      } else {
        this.toDate = data;
      }
    }
  }

  Date_Formate(data) {
    var Splitter = data.split("-");
    return Splitter[2] + "-" + Splitter[1] + "-" + Splitter[0];
  }

  order_View(Order_id, finyear) {
    var order_View = {
      patienttype:this.patienttype,
      AppFlow: "past_orders",
      "orderid": Order_id,
      value: "past",
      fin_year: finyear,
    }
    pharmacy_helper.setorderview(order_View);
    if (Helper_Class.getIsAdmin() != undefined) {
      this.adminService.sendMessage("pharmaBillingView");
    } else {
      Helper_Class.setBillPrint("1");
      this.messageservice.sendMessage('pharmacymoduleorderdetailview');
    }
  }

  editMedicine(Order_id, finyear) {
    var order_View = {
      AppFlow: "past_orders_edit",
      "orderid": Order_id,
      status: "2",
      value: "past_edit",
      fin_year: finyear
    }
    pharmacy_helper.setorderview(order_View);
    if (Helper_Class.getIsAdmin() != undefined) {
      this.adminService.sendMessage("pharmaBillingView");
    } else {
      this.messageservice.sendMessage('pharmacymoduleorderdetailview');
    }
  }

  optionsPopover() {
    const dialogRef = this.dialog.open(FilterpageComponent, {
      width: '25%',
      height: '180px',
    });
    dialogRef.afterClosed().subscribe(data => { });
  }

  userTypeSelect(page) {
    this.App_type = page;
    this.inpatientFlag = this.App_type == "in" ? true : false;
    this.currentOrder(this.pharmacy_id,'','');
  }

  change_type(id, value) {
    if (value == "yes") {
      this.patienttype="outpatient"
      this.ynopt1 = true;
      this.userTypeSelect('out');
    } else {
      this.patienttype="inpatient"
      this.ynopt1 = false;
      this.userTypeSelect('in');
    }
   
  }
}